import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from 'react-router-dom';
import TextInputOutlined from 'libraries/TextInput';
import Dialog from 'libraries/Dialog';
import Tooltip from 'libraries/Tooltip';
import PlanEvents from 'events/PlanEvents';

import Zendesk from 'helper/zendeskFunctions';
import PauseSubscriptionDialog from 'components/dialogs/PauseSubscriptionDialog';

import { cancelCustomerSubscription } from 'requests/SubscriptionRequests';

import { MAX_PAUSES_COUNT } from 'helper/constants';

import cancellationOptions from './cancellationOptions';
import styles from './styles';

const CancelSubscriptionDialog = ({
    classes, open, getCompanyPlan, isSubscriptionPaused,
    subscriptionId, onClose, history, period, pausesCount
}) => {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState(null);
    const [feedback, setFeedback] = useState('');
    const [pauseSubscriptionDialogOpen, setPauseSubscriptionDialogOpen] = useState(false);

    const handlePauseSubscriptionDialog = () => {
        setPauseSubscriptionDialogOpen(!pauseSubscriptionDialogOpen);
    };

    const cancelSubscription = () => {
        setLoading(true);
        const reasonToCancel = cancellationOptions().find(option => option.id === reason).reasonText
            || cancellationOptions().find(option => option.id === reason).buttonText;
        cancelCustomerSubscription(subscriptionId, reasonToCancel, feedback)
            .then((data) => {
                if (data.success) {
                    getCompanyPlan();
                } else {
                    const { flashMessage } = this.props;
                    flashMessage(data.message);
                }
            })
            .finally(() => {
                setLoading(false);
                onClose();
                setFeedback('');
            });
    };

    const openZendesk = (message) => {
        Zendesk.open(true, message);
    };

    const handleClick = (id, link, actionButtonText, zendeskMessage) => {
        switch (id) {
            case 1:
                history.push(link);
                PlanEvents.PLAN_CANCEL_ABANDONED(link);
                break;
            case 3:
                onClose();
                handlePauseSubscriptionDialog();
                PlanEvents.PLAN_CANCEL_ABANDONED(actionButtonText);
                break;
            case 4:
                onClose();
                openZendesk(zendeskMessage);
                PlanEvents.PLAN_CANCEL_ABANDONED(actionButtonText);
                break;
            case 2:
            case 5:
                onClose();
                PlanEvents.PLAN_CANCEL_ABANDONED('CS', feedback);
                openZendesk();
                setFeedback('');
                break;
            default:
                onClose();
                break;
        }
    };

    const cannotPause = period === 'year' || isSubscriptionPaused || pausesCount >= MAX_PAUSES_COUNT;

    return (
        <>
            <Dialog
                titleComponent="Cancel my account"
                open={open}
                handleClose={onClose}
                onClose={onClose}
            >
                Clicking cancel will remove your account at the end of the billing period. Your data including candidate details, scores and assessment content will be deleted at this time.
                <br />
                <br />
                Please tell us the reason for cancelling your account today.
                <div className={classes.container}>
                    {cancellationOptions().map(({ id, buttonText }) => (
                        <Button
                            variant="contained"
                            color="primary"
                            key={id}
                            className={clsx(classes.optionButton, id === reason && classes.optionButtonSelected)}
                            onClick={() => setReason(id)}
                        >
                            {buttonText}
                        </Button>
                    ))}
                </div>
                {reason && cancellationOptions().filter(option => option.id === reason).map(({ id, mainText, actionButtonText, link, zendeskMessage }) => (
                    <div key={id} className={classes.actionsBlock} style={(reason === 5 || reason === 2) ? { padding: 30, textAlign: 'start' } : null}>
                        {mainText}
                        {(reason === 5 || reason === 2) && (
                            <TextInputOutlined
                                maxLength={4096}
                                hasCounter
                                multiline
                                fullWidth
                                minRows={3}
                                variant="outlined"
                                value={feedback}
                                margin="none"
                                onChange={e => setFeedback(e.target.value)}
                                InputProps={{ classes: { root: classes.inputRoot } }}
                            />
                        ) }
                        <div className={classes.buttonsBlock} style={(reason === 5 || reason === 2) ? { margin: '20px 0 0' } : null}>
                            {
                                cannotPause && reason === 3 ? (
                                    <Tooltip
                                        label="Subscription pausing is not available."
                                    >
                                        <span>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className={classes.actionButton}
                                                disabled
                                            >
                                                {actionButtonText}
                                            </Button>
                                        </span>
                                    </Tooltip>

                                ) : (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={() => handleClick(id, link, actionButtonText, zendeskMessage)}
                                    >
                                        {actionButtonText}
                                    </Button>
                                )
                            }
                            <Button
                                variant="text"
                                disabled={loading || ((reason === 5 || reason === 2) && !feedback.trim().length)}
                                className={classes.cancelButton}
                                onClick={cancelSubscription}
                            >
                                {
                                    loading ? (
                                        <CircularProgress
                                            size={24}
                                            thickness={3}
                                            classes={{ root: classes.loader }}
                                        />
                                    ) : 'No thanks, I’d still like to cancel my account'
                                }
                            </Button>
                        </div>
                    </div>
                )) }
            </Dialog>

            <PauseSubscriptionDialog
                pauseSubscriptionDialogOpen={pauseSubscriptionDialogOpen}
                handlePauseSubscriptionDialog={handlePauseSubscriptionDialog}
                getCompanyPlan={getCompanyPlan}
            />
        </>
    );
};

export default withRouter(withStyles(styles)(CancelSubscriptionDialog));

import React, { useContext, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import { errorMessage } from 'helper/constants';
import { observer } from 'mobx-react-lite';
import { appCtx } from '../../appStore';

const styles = {
    button: {
        minHeight: 36,
        minWidth: 182,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold'
    },
    progress: {
        color: 'white',
        '& svg': {
            margin: 0
        }
    }
};

const ResetAuditionDialog = observer(({
    open, callback, classes,
    isInvited, requestFunc,
    user, handleClose, audition
}) => {
    const [disabled, setDisabled] = useState(false);
    const { flashMessage } = useContext(appCtx);
    const { containsCyberSimulatorQuestions } = audition;

    const resetAudition = async () => {
        setDisabled(true);
        try {
            const data = await requestFunc();
            if (data.success) {
                setDisabled(false);
                handleClose();
                flashMessage('Extended successfully', 'done');
                if (callback && data.candidates) callback(data.candidates);
                if (callback && data.data && data.data.candidates) callback(data.data.candidates);
            } else {
                handleClose();
            }
        } catch (e) {
            console.log(e);
            flashMessage(e?.response?.data?.msg || errorMessage, 'error');
            setDisabled(false);
        }
    };

    let { fullName } = user;
    if (!fullName) {
        fullName = user ? `${user.first_name} ${user.last_name ? `${user.last_name} ` : ''}` : '';
    }
    return (
        <Dialog
            open={open}
            maxWidth="sm"
            handleClose={handleClose}
            titleComponent={isInvited ? 'Re-invite Candidate' : 'Extend Deadline for Candidate'}
            actionComponent={(
                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={resetAudition}
                    disabled={disabled}
                >
                    {
                        disabled ? (
                            <CircularProgress
                                size={22}
                                className={classes.progress}
                            />
                        ) : (
                            <>
                                { isInvited ? 'Re-invite' : 'Extend deadline' }
                            </>
                        )
                    }
                </Button>
            )}
        >
            {
                isInvited ? (
                    <>
                        By re-inviting {fullName} you'll be notifying them by email and giving them extra time of access to complete the Skills Assessment.
                    </>
                ) : (
                    <>
                        <p>By extending the deadline for {fullName} you&apos;ll be notifying them of this extension and giving them extra time of access to complete the Skills Assessment.&nbsp;
                            {
                                containsCyberSimulatorQuestions ? (
                                    "Extending the deadline will reset a candidate's input for all terminal based question types (like Cyber Simulations), but other questions will retain their input."
                                ) : 'Extending the deadline will NOT reset answers inputted by a candidate.'
                            }
                        </p>
                        <p>If the Skills Assessment had any timers, the timers will be reset giving the applicant the original length again.</p>
                    </>
                )

            }
        </Dialog>
    );
});

export default withStyles(styles)(ResetAuditionDialog);

import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import GeneratorTab from './GeneratorTab';
import TestCasesTab from './TestCasesTab';

import styles from './styles';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && children}
        </Typography>
    );
};

const GeneratorAndTestCasesPanel = ({
    classes, question, isCMS,
    codeGeneratorCallback,
    cases, setTestData,
    editTestCase, setDeleteTestCaseDialogOpen,
    codeChallengeIsNotEmpty,
    dataForEvents, editedCase,
    runTest, runningTest, handleDrawerOpen,
    setEditedCase, chooseCase, currentCase
}) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabList = [{
        label: 'Code Generator',
        value: 0
    }, {
        label: 'Test Cases',
        value: 1
    }];

    return (
        <div className={classes.container}>
            <Tabs
                value={value}
                classes={{ root: classes.tabs, indicator: classes.indicator }}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                {
                    tabList.map(({ label, value: tabValue }, index) => (
                        <Tab
                            disableRipple
                            key={index}
                            value={tabValue}
                            label={label}
                            classes={{
                                root: classes.tab,
                                selected: classes.tabSelected
                            }}
                        />
                    ))
                }
            </Tabs>
            <TabPanel value={value} index={0}>
                <GeneratorTab {...{
                    isCMS,
                    question,
                    codeGeneratorCallback,
                    codeChallengeIsNotEmpty,
                    dataForEvents
                }}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TestCasesTab {...{
                    cases,
                    setTestData,
                    editTestCase,
                    setDeleteTestCaseDialogOpen,
                    editedCase,
                    setEditedCase,
                    runTest,
                    runningTest,
                    handleDrawerOpen,
                    chooseCase,
                    currentCase
                }}
                />
            </TabPanel>
        </div>
    );
};

export default withStyles(styles)(GeneratorAndTestCasesPanel);

export default theme => ({
    tableRow: {
        fontWeight: 400,
        borderRadius: 20
    },
    hoverable: {
        cursor: 'pointer',
        '&:not($opened):hover': {
            backgroundColor: theme.palette.grey[50]
        }
    },
    opened: {
        backgroundColor: theme.palette.grey[200],
        verticalAlign: 'top'
    },
    reasonColumn: {
        width: 400,
        whiteSpace: 'break-spaces'
    },
    actionsColumn: {
        width: 100
    },
    tableCell: {
        border: 'none !important',
        fontWeight: 400,
        paddingTop: 10,
        paddingBottom: 10,
        '&:first-child': {
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20
        },
        '&:last-child': {
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20
        }
    },
    icon: {
        color: theme.palette.grey[600]
    },
    openedIcon: {
        transform: 'rotate(180deg)'
    }
});

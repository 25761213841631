export default theme => ({
    textFieldRoot: {
        marginTop: 0,
        marginBottom: 20,
        fontSize: '14px',
        color: '#3a3c43',
        letterSpacing: '0.16px',
        lineHeight: '1.2',
        '&>div::after': {
            display: 'none'
        },
        '&>div::before': {
            display: 'none'
        }
    },
    button: {
        fontSize: 12,
        padding: '0 3px'
    },
    iconButton: {
        position: 'absolute',
        top: -14,
        right: -11,
        padding: 0,
        zIndex: 3
    },
    icon: {
        width: 32,
        height: 32,
        boxShadow: '0 2px 6px 0 rgba(207, 207, 207, 0.5)',
        backgroundColor: '#ffffff',
        borderRadius: 50
    },
    wrapper: {
        padding: '14px',
        border: '2px solid #ebebeb',
        borderRadius: '3px',
        position: 'relative',
        marginTop: '10px',
        marginBottom: '50px'
    },
    wrapperError: {
        borderColor: theme.palette.red.primary
    },
    label: {
        position: 'absolute',
        top: '-9px',
        left: '14px',
        fontSize: '10.5px',
        color: '#979797',
        background: 'white',
        fontWeight: 600,
        padding: '0 3px'
    }
});

export default theme => ({
    btnLink: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginTop: 7,
        marginBottom: 0,
        textTransform: 'none',
        lineHeight: '14px'
    },
    uploadExist: {
        textTransform: 'none',
        lineHeight: '14px',
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        padding: 0,
        paddingRight: 5,
        minHeight: 'auto',
        '&:hover': {
            background: 'white'
        }
    },
    linkWrapper: {
        marginTop: 10,
        fontSize: '14px',
        lineHeight: '14px',
        color: '#1a1b1e',
        fontFamily: 'Open Sans',
        display: 'flex',
        alignItems: 'center'
    }
});

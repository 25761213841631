import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import EditPanelSection from 'components/assessments_pages/asessment_settings_components/EditPanelSection';
import DeleteContentLibraryAssessmentDialog
    from 'components/dialogs/DeleteContentLibraryAssessmentDialog';

const DetailSettings = ({ audition, history }) => {
    const [openDeleteScript, setOpenDeleteScript] = useState(false);
    const { uuid } = audition;

    const handleDeleteAssessment = (e) => {
        e.stopPropagation();
        setOpenDeleteScript(!openDeleteScript);
    };

    const onDelete = () => {
        history.push('/content-library');
    };

    return (
        <EditPanelSection>
            <Button
                color="secondary"
                className="u-txt--bold"
                onClick={handleDeleteAssessment}
            >
                Delete Assessment
            </Button>
            <DeleteContentLibraryAssessmentDialog
                open={openDeleteScript}
                uuid={uuid}
                onDelete={onDelete}
                onClose={handleDeleteAssessment}
            />
        </EditPanelSection>
    );
};

export default withRouter(DetailSettings);

import React, { useContext } from 'react';
import { createCompanyQuestion, createCompanyScreeningQuestion } from 'requests/QuestionsRequests';
import { observer } from 'mobx-react-lite';

import { QA_SIMULATOR, SALES_SIMULATOR, MARKETING_SIMULATOR } from 'helper/constants';
import AddToSavedButton from 'pages/assessment/components/AddToSavedButton';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';

const AddToBankIcon = observer(({ question, activeQuestion }) => {
    if (!question) return null;
    const {
        missingAnswer, answerType,
        isAuthorLogged, missingQuestion, id,
        timerValid, questionGroup, screeningQuestion,
        valid
    } = question;
    const { company } = useContext(appCtx);
    const hasQuestionBanks = checkHasCompanyFeature(company, 'QUESTION_BANKS');

    const ifMissingForAnswer = (missingAnswer) || (!answerType && !isAuthorLogged);
    const isInvalid = ifMissingForAnswer || !timerValid || missingQuestion || !questionGroup || !hasQuestionBanks;
    const isScreeningInvalid = !valid;

    if ([SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR].includes(answerType)) return null;

    return (
        <AddToSavedButton
            onSave={() => (screeningQuestion ? createCompanyScreeningQuestion(id) : createCompanyQuestion(id))}
            question={question}
            activeQuestion={activeQuestion}
            disabled={screeningQuestion ? isScreeningInvalid : isInvalid}
            missFeature={!hasQuestionBanks}
        />
    );
});

export default AddToBankIcon;

import React from 'react';
import { AUDIO } from 'helper/constants';

const Svg = (onClick, className) => (
    <svg
        className={className}
        onClick={e => onClick(e, AUDIO)}
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M4 1L4 17" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 4L7 15" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 7L1 12" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 7L10 12" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 1L13 17" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 4L16 15" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19 7L19 12" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default Svg;

import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import PhoneInput from 'react-phone-input-2';
import { observer } from 'mobx-react-lite';
import useRect from 'hooks/useRect';
import { appCtx } from '../../appStore';

import './styles.scss';
import useStyles from './styles';


const PhoneInputWithCountries = observer(({ isValid, messageClassname, ...other }) => {
    const [phoneFocused, setPhoneFocused] = useState(false);
    const inputRef = useRef();
    const inputRect = useRect(inputRef);
    const propsTop = other.top || inputRect.top;

    const { phoneInputContainer, phoneInput, phoneInputFocused, phoneInvalid, phoneHelperText } = useStyles({ ...inputRect, top: propsTop })();

    const { company } = useContext(appCtx);

    return (
        <span ref={inputRef}>
            <PhoneInput
                type="tel"
                containerClass={clsx(
                    phoneInputContainer,
                    phoneFocused && phoneInputFocused,
                    !isValid && !phoneFocused && phoneInvalid
                )}
                inputClass={phoneInput}
                onFocus={() => setPhoneFocused(true)}
                onBlur={() => setTimeout(() => setPhoneFocused(false), 100)}
                preferredCountries={['au', 'gb', 'us']}
                country={company?.country?.code.toLowerCase()}
                {...other}
            />
            {!isValid && !phoneFocused && <div className={messageClassname || phoneHelperText}>Please enter a valid phone number</div>}
        </span>
    );
});

export default PhoneInputWithCountries;

export default theme => ({
    button: {
        whiteSpace: 'nowrap',
        color: theme.palette.grey[500],
        fontWeight: 700,
        marginRight: 5,
        textTransform: 'capitalize',
        '&:last-of-type': {
            marginRight: 15
        }
    },
    buttonActive: {
        color: theme.palette.primary.main
    }
});

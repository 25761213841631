export default theme => ({
    shareLinkLabelDisabled: {
        userSelect: 'none',
        cursor: 'default'
    },
    shareLinkActions: {
        backgroundColor: '#fff',
        width: '0',
        position: 'absolute',
        right: '135px',
        height: '36px',
        justifyContent: 'space-between',
        overflow: 'hidden',
        transition: 'width 500ms',
        whiteSpace: 'nowrap',
        boxShadow: '0 0 30px 20px #fff'
    },
    shareLinkLabel: {
        fontSize: '16px',
        lineHeight: '24px',
        color: 'rgb(61, 63, 71)',
        fontWeight: '600',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        position: 'relative',
        padding: '12px 0',
        boxSizing: 'border-box',
        margin: '0',
        width: 'calc(100% - 130px)',
        '&:after': {
            content: "''",
            position: 'absolute',
            boxShadow: '0 0 30px 20px #fff',
            right: '0',
            width: '0',
            height: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '21px'
        }
    },
    shareLinkActionItem: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '11px',
        lineHeight: '32px',
        '&:last-child': {
            marginRight: 0
        },
        '&:first-child': {
            marginLeft: 5
        }
    },
    shareLink: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&:hover': {
            '& $shareLinkActions': {
                width: 85
            },
            '& $shareLinkLabel': {
                color: theme.palette.grey[900]
            }
        }
    },
    shareLinkActionIcon: {
        width: '32px',
        height: '32px',
        boxShadow: '0 2px 6px 0 rgba(207, 207, 207, 0.5)',
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        textAlign: 'center',
        cursor: 'pointer',
        '& i': {
            lineHeight: '32px',
            fontSize: '18px'
        }
    },
    shareLinkLabelWrapper: {
        width: 'calc(100% - 30px)',
        position: 'relative',
        cursor: 'pointer'
    },
    copyLinkButton: {
        width: '120px',
        minWidth: '120px',
        minHeight: '30px',
        position: 'absolute',
        right: '5px',
        top: '8px',
        fontSize: 14,
        padding: '3px',
        zIndex: 2
    },
    iconButton: {
        color: theme.palette.primary.main,
        borderWidth: 0,
        '& img': {
            marginRight: 5
        },
        '&:hover': {
            borderWidth: 0,
            color: theme.palette.primary.main
        },
        '&:focus': {
            color: theme.palette.primary.main
        }
    },
    iconButtonDisable: {
        color: '#C0C3C4',
        borderWidth: 0,
        '& img': {
            marginRight: 5
        },
        '&:hover': {
            color: theme.palette.grey[500],
            borderWidth: 0
        },
        '&:focus': {
            color: theme.palette.grey[500]
        }
    },
    copyLinkButtonDisabled: {
        opacity: 0.6
    },
    shareLinkIcon: {
        width: '30px',
        margin: '0'
    }
});

export default (theme => ({
    secondaryButton: {
        minHeight: 36,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold',
        color: theme.palette.grey[900]
    },
    mainButton: {
        minHeight: 36,
        paddingLeft: 90,
        paddingRight: 90,
        fontWeight: 'bold',
        outline: 'none',
        color: '#fff',
        '&:hover, &:active, &:focus': {
            color: '#fff',
            outline: 'none'
        }
    },
    contentClassName: {
        padding: '65px 25px 40px',
        textAlign: 'center'
    },
    contentTitle: {
        color: theme.palette.grey[900],
        fontSize: 20,
        fontWeight: 700,
        marginTop: 15,
        marginBottom: 20
    },
    contentText: {
        maxWidth: 415,
        margin: 'auto',
        color: theme.palette.grey[700]
    }
}));

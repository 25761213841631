import React, { useContext } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { nth } from 'helper/commonFunctions';
import TooltipNew from 'libraries/Tooltip';
import { appCtx } from 'components/appStore';

import Trophy from '../../Trophy';


import useStyles from './styles';


const SkillScoreRow = observer(({
    skillScore, rejected,
    classNameWrapper = '', classNameRow = '',
    isTask
}) => {
    const { company } = useContext(appCtx);
    const {
        groupName, scorePercentage,
        rank, isManualScore, scoreColorRange,
        rankTotal
    } = skillScore;

    const {
        progress, definition, rowContainer,
        tooltipSpan, trophy, rankContainer,
        root, colorPrimary, bar
    } = useStyles({ scoreColorRange, rejected });

    let apiIntegration = false;
    if (company.companySettings) {
        apiIntegration = company.companySettings.apiIntegration;
    }

    return (
        <>
            <div className={clsx(definition, classNameWrapper)}>{groupName}</div>
            <div className={clsx(rowContainer, classNameRow)}>
                <TooltipNew
                    label={
                        <>
                            {
                                !isTask && (
                                    <div>
                                        Rank <span className={tooltipSpan}>{rank || '-'}/{rankTotal || '-'}</span>
                                    </div>
                                )
                            }
                            {
                                (isManualScore || apiIntegration || isTask) && (
                                    <div>Score&nbsp;
                                        <span className={tooltipSpan}>
                                            {scorePercentage}%
                                        </span>
                                    </div>
                                )
                            }
                        </>
                    }
                >
                    <div className={progress}>
                        <LinearProgress
                            classes={{ root, colorPrimary, bar }}
                            variant="determinate"
                            value={scorePercentage}
                        />
                    </div>
                </TooltipNew>
                {
                    (rank || isTask) && (
                        <div className={rankContainer}>
                            {(apiIntegration || isTask) ? <span><b>{scorePercentage}%</b></span> : <span>{nth(rank)}</span>}
                            {
                                rank && (
                                    <Trophy
                                        rank={rank}
                                        className={trophy}
                                        scorePercentage={scorePercentage}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </div>
        </>
    );
});

export default SkillScoreRow;

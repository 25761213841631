import React from 'react';

const NavIcon = ({ ...other }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M3.5 18H15.5" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.5 12L15.5 12" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.5 6L18.5 6" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.5 3.3999L20 6.05142L16.5 8.70293" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default NavIcon;

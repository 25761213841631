import { createContext } from 'react';
import { observable, action } from 'mobx';
import { getAFSetupGuide } from 'requests/AssessmentRequests';

class SetupGuideStore {
    @observable needInvitations = false;

    @observable needGrade = false;

    @observable needInviteTM = false;

    @observable needJobWithAssessment = false;

    @observable loading = true;

    @observable opportunityToInvite = '';

    @observable opportunityToGrade = '';

    @observable percentage = 100;

    @observable popover = null;

    @observable candidateCompletedNotScoredManually = null;

    @observable numberOfAssessments = null;

    @action updateAssessmentInfo = () => {
        if (this.needJobWithAssessment) {
            this.setSetting();
        }
    };

    @action updateTMInfo = () => {
        if (this.needInviteTM) {
            this.setSetting();
        }
    };

    @action updateInvitationInfo = () => {
        if (this.needInvitations) {
            this.setSetting();
        }
    };

    @action updateGradeInfo = () => {
        if (this.needGrade) {
            this.setSetting();
        }
    };

    @action setSetting = async () => {
        try {
            const { success, data } = await getAFSetupGuide();
            let newPercentage = 100;
            if (success) {
                const {
                    archivedExist,
                    withNoCandidates,
                    completedNotScored,
                    companyHasColumnUser,
                    withAssessmentExist,
                    companyHasGradedUserAudition,
                    numberOfAssessments,
                    companyHasTeamMemberInvitations,
                    candidateCompletedNotScoredManually
                } = data;

                this.numberOfAssessments = numberOfAssessments;

                if (archivedExist) {
                    this.percentage = newPercentage;
                    this.loading = false;
                    return;
                }

                this.candidateCompletedNotScoredManually = candidateCompletedNotScoredManually;

                this.needInvitations = !companyHasColumnUser;
                if (!companyHasColumnUser) {
                    newPercentage -= 25;
                    this.opportunityToInvite = withNoCandidates || '';
                }

                this.needGrade = !companyHasGradedUserAudition;
                if (!companyHasGradedUserAudition) {
                    newPercentage -= 25;
                    this.opportunityToGrade = completedNotScored || '';
                }

                this.needInviteTM = !companyHasTeamMemberInvitations;
                if (!companyHasTeamMemberInvitations) {
                    newPercentage -= 25;
                }

                this.needJobWithAssessment = !withAssessmentExist;
                if (!withAssessmentExist) {
                    newPercentage -= 25;
                }
            }

            this.percentage = newPercentage;
            this.loading = false;
        } catch (e) {
            this.loading = false;
        }
    };

    @action setPopover = (callbacks) => {
        this.popover = callbacks;
    }
}

export const setupGuideStore = new SetupGuideStore();
export const setupGuideCtx = createContext(setupGuideStore);

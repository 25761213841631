import React from 'react';
import { withStyles } from '@mui/styles';
import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';

const styles = theme => ({
    tooltip: {
        minWidth: 360,
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.grey[300]
    },
    heading: {
        fontWeight: 700,
        marginBottom: 1,
        marginTop: 5,
        color: '#fff'
    },
    text: {
        marginBottom: 25
    }
});

const RoleTooltip = ({ classes }) => (
    <QuestionTooltipBlack
        className={classes.tooltip}
        placement="left"
        label={(
            <>
                <p className={classes.heading}>Admin</p>
                <p className={classes.text}>Admin roles manage the account and setting and are able to see and edit all assessments created.</p>
                <p className={classes.heading}>Team</p>
                <p>Team members are able to create assessments but only able to view others they are invited to.</p>
            </>
        )}
    />
);

export default withStyles(styles)(RoleTooltip);

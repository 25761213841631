import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const styles = theme => ({
    section: {
        padding: '25px 0 30px',
        lineHeight: '24px',
        '&:not(:last-child)': {
            borderBottom: `2px solid ${theme.palette.grey[200]}`
        }
    },
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    }
});

const EditPanelSection = ({ classes, className = '', header, children }) => (
    <div className={clsx(classes.section, className)}>
        {
            header && (
                <Typography variant="h5" className={classes.sectionHeader}>
                    {header}
                </Typography>
            )
        }
        {children}
    </div>
);

export default withRouter(withStyles(styles)(EditPanelSection));

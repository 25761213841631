import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { getCandidatesForCompare } from 'requests/AssessmentRequests';
import CandidatesSearch from 'components/job_or_assessment_settings/CandidatesSearch';
import AssessmentEvents from 'events/AssessmentEvents';
import TableFilter from '../../common/ResultsTableFilter';
import TableSort from '../../../components/TableSort';
import CompareButton from '../CompareButton';
import CompareDialog from '../CompareDialog';

import { candidateResultCtx } from '../../common/CandidateListWrapper/candidatesStore';
import { assessmentSettingsCtx } from '../../../store';
import { shortlistCtx } from '../shortlistStore';

import useStyles from './styles';


const SearchFilterBlock = observer(({ className = '', history }) => {
    const classes = useStyles();
    const [dialogOpened, setDialogOpened] = useState(false);
    const {
        query, handleChangeFilterSelect,
        filterCategory, handleChangeFilter
    } = useContext(candidateResultCtx);
    const { candidatesIdsToCompare, setCandidatesIdsToCompare } = useContext(shortlistCtx);
    const { audition, loadEditScript } = useContext(assessmentSettingsCtx);
    const { canCompareCandidates, slug, uuid: ttId, name: ttName } = audition;
    const compareLength = Object.keys(candidatesIdsToCompare).length;

    useEffect(() => {
        loadEditScript(history);
        loadCandidatesForCompare();
    }, []);

    useEffect(() => {
        if (!compareLength && dialogOpened) {
            handleDialog();
        }
    }, [compareLength]);

    const handleDialog = () => {
        setDialogOpened(!dialogOpened);
        AssessmentEvents.CUSTOM_SHORTLIST_COMPARED({ ttId, ttName, numberCandidatesCompared: compareLength });
    };

    const loadCandidatesForCompare = () => {
        getCandidatesForCompare(slug)
            .then(({ success, data }) => {
                if (success && data.length) {
                    const candidatesIdsCollection = {};
                    data.forEach((candidatesId) => { candidatesIdsCollection[`+${candidatesId}`] = +candidatesId; });
                    setCandidatesIdsToCompare(candidatesIdsCollection);
                }
            });
    };

    return (
        <div className={clsx(classes.infoWrapper, className)}>
            <CandidatesSearch
                className={classes.searchField}
                onChange={handleChangeFilter}
                value={query}
            />
            <div className="u-dsp--f u-ai--center">
                <CompareButton
                    isActive={Boolean(compareLength)}
                    count={compareLength}
                    disabled={!compareLength}
                    onClick={handleDialog}
                    canCompareCandidates={canCompareCandidates}
                    showCount
                />
                <TableFilter
                    isShortList
                    filterCategory={filterCategory}
                    handleChangeFilterSelect={handleChangeFilterSelect}
                />
                <TableSort />
            </div>
            <CompareDialog
                handleClose={handleDialog}
                open={dialogOpened}
            />
        </div>
    );
});

export default withRouter(SearchFilterBlock);

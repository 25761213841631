import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { withStyles } from '@mui/styles';
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextInput from 'libraries/TextInput';
import AssessmentEvents from 'events/AssessmentEvents';
import { assessmentSettingsCtx } from 'pages/assessment/store';

import styles from './styles';

let timerId = null;

const DateTimeDeadline = observer(({ handleChangeDeadlineDate, expirationDate, classes }) => {
    const offset = (new Date().getTimezoneOffset() / 60).toString();
    const timeZone = offset[0] === '-' ? offset.replace('-', '+') : `-${+offset}`;

    const { audition: { uuid, name }, audition, setAudition } = useContext(assessmentSettingsCtx);

    const minDateTime = (Math.floor(Date.now() / 60000) * 60000) + 60000;

    const handleChangeDate = (newValue) => {
        const isExpired = moment(newValue).format() < moment(minDateTime).format();
        handleChangeDeadlineDate(newValue, isExpired);
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            AssessmentEvents.DEADLINE_DATE_SET({
                ttUUID: uuid,
                ttName: name,
                deadlineDate: newValue
            });
            if (!isExpired) {
                setAudition({ ...audition, daysToExpiration: -1, expirationDate: newValue, expired: isExpired });
            }
        }, 3000);
    };

    return (
        <div className="u-mrg--tx2">
            <TextInput
                disabled
                label="Timezone"
                variant="outlined"
                value={`UTC ${timeZone}`}
                name="timezone"
                className="u-mrg--bx3"
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    ampm={false}
                    inputFormat="dd/MM/yyyy hh:mm a"
                    minDateTime={minDateTime}
                    minutesStep={5}
                    renderInput={props => <TextField fullWidth {...props} />}
                    label="Add deadline date and time"
                    value={expirationDate}
                    onChange={newValue => handleChangeDate(newValue)}
                />
                {(!expirationDate || moment(expirationDate).format() < moment(minDateTime).format()) && <p className={classes.errorStyle}>Please add valid date and time</p>}
            </LocalizationProvider>
        </div>
    );
});

export default withStyles(styles)(DateTimeDeadline);

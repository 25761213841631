import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        minHeight: 200,
        width: 435,
        padding: '20px 24px',
        position: 'relative',
        minWidth: 435,
        borderRight: `2px solid ${theme.palette.grey[200]}`
    },
    noAnswerWrapper: {
        border: 'none',
        width: '100%',
        height: 'calc(100% - 57px)'
    },
    noAnswerIcon: {
        maxWidth: 30,
        marginBottom: 7
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto'
    }
}));

import React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';

import TasksQuestionContent from './TasksQuestionContent';
import GradeCardTaskNavigation from './GradeCardTaskNavigation';

const useStyles = makeStyles(() => ({
    resultsWrapper: {
        maxWidth: '100%',
        overflow: 'auto',
        width: '100%'
    }
}));


const TasksGradeBlock = observer(({
    candidate, audition, updateUserAuditionAndBox,
    gradeableCard, propPublic, gotoQuestion, isIntegration,
    saveGrade, gradeLoading, canGrade, currentGrade, currentQuestion,
    fullName, questionResults, questionNumber, setGradeQuestion,
    taskNumber, setTaskNumber, setGradeLoading
}) => {
    const classes = useStyles();


    const goToTask = (key) => {
        setTaskNumber(parseInt(key, 10));
    };

    const goToNextQuestion = () => {
        if (taskNumber + 1 < currentQuestion.candidateTasks.length) {
            setTaskNumber(taskNumber + 1);
            return;
        }
        gotoQuestion(questionNumber + 1);
    };

    const goToPreviousQuestion = () => {
        if (taskNumber - 1 >= 0) {
            setTaskNumber(taskNumber - 1);
            return;
        }
        gotoQuestion(questionNumber - 1);
    };


    return (
        <>
            <GradeCardTaskNavigation
                goToTask={goToTask}
                tasks={currentQuestion?.candidateTasks}
                taskNumber={taskNumber}
            />
            <div className={classes.resultsWrapper}>
                <TasksQuestionContent
                    userName={fullName}
                    canGrade={canGrade}
                    questionNumber={questionNumber}
                    question={currentQuestion}
                    currentGrade={currentGrade}
                    {...{ gradeableCard,
                        propPublic,
                        isIntegration,
                        updateUserAuditionAndBox,
                        candidate,
                        audition,
                        questionCount: questionResults.length,
                        setGradeQuestion,
                        gradeLoading,
                        goToPreviousQuestion,
                        goToNextQuestion,
                        saveGrade,
                        taskNumber,
                        setGradeLoading
                    }}
                />
            </div>
        </>
    );
});

export default TasksGradeBlock;

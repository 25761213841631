import React, { useState } from 'react';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import PhoneInputWithCountries from 'components/inputs/PhoneInputWithCountries';
import CrossIcon from './crossIcon.svg';
import PhoneButton from './PhoneButton';
import useStyles from './styles';

const PhoneInputComponent = ({
    inputClassName = '', indexKey, expired,
    onHandleChange, company, value, isPhoneValid, buttonClassName,
    listCountriesTopOffset
}) => {
    const [phoneClicked, setPhoneClicked] = useState(false);

    const { deleteButton, phoneHelperText, fieldWrapper } = useStyles({});

    const handleChangePhone = (phone) => {
        if (!phoneClicked) setPhoneClicked(true);
        onHandleChange('phone', phone, indexKey);
    };

    const openPhoneInput = () => {
        setPhoneClicked(true);
    };

    const deletePhone = () => {
        setPhoneClicked(false);
        onHandleChange('phone', '', indexKey);
    };

    return phoneClicked || Boolean(value) ? (
        <div className={clsx(fieldWrapper, 'u-dsp--f phone-input u-mrg--tx1', inputClassName)}>
            <div className="u-dsp--f u-fdir--column u-wdt--100p u-pos--relative">
                <PhoneInputWithCountries
                    value={value}
                    placeholder="Phone Number"
                    messageClassname={phoneHelperText}
                    isValid={isPhoneValid}
                    name={`phone[${indexKey}]`}
                    onChange={handleChangePhone}
                    disabled={expired}
                    top={listCountriesTopOffset}
                />
            </div>
            <IconButton
                className={deleteButton}
                onClick={deletePhone}
            >
                <img src={CrossIcon} alt="x" />
            </IconButton>
        </div>
    ) : (
        <PhoneButton
            company={company}
            buttonClassName={buttonClassName}
            openPhoneInput={openPhoneInput}
            expired={expired}
        />
    );
};

export default PhoneInputComponent;

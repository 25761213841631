import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    popoverRoot: {
        width: 260,
        padding: 0,
        borderRadius: 8
    },
    button: {
        color: theme.palette.grey[600],
        fontSize: 12,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        paddingRight: 0,
        '&:hover': {
            color: theme.palette.grey[900]
        },
        '&:hover, &:focus, &:active': {
            background: 'none'
        }
    },
    nameSpan: {
        marginRight: 7
    },
    popoverHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '18px 24px 15px'
    },
    userInfo: {
        marginLeft: 10,
        fontSize: 14,
        color: theme.palette.grey[700]
    },
    userInfoName: {
        color: theme.palette.grey[900],
        fontSize: 16,
        fontWeight: 'bold'
    },
    menuList: {
        padding: '16px 0'
    },
    menuItem: {
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: 600,
        padding: '8px 24px',
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[900]
        }
    },
    userInfoEmail: {
        color: theme.palette.grey[700],
        fontWeight: 600,
        fontSize: 12,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 155
    }
}));

export default useStyles;

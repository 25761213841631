import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import { deleteInterviewScript } from 'requests/ScriptRequests';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import LibraryEvents from 'events/LibraryEvents';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { appCtx } from '../../appStore';
import { foldersCtx } from '../../assessments_pages/AssessmentFolders/store';

const styles = () => ({
    actionButton: {
        fontWeight: 700,
        paddingRight: 20,
        paddingLeft: 20
    },
    deleteButton: {
        paddingRight: 40,
        paddingLeft: 40,
        fontWeight: 700,
        minWidth: 213
    },
    loader: {
        color: '#fff'
    }
});

const DeleteAssessmentDialog = observer(({ onDelete, classes, open, interview, onClose }) => {
    const [openDeleteScriptReject, setOpenDeleteScriptReject] = useState(false);
    const [loading, setLoading] = useState(false);
    const { flashMessage, company } = useContext(appCtx);
    const { getFolders } = useContext(foldersCtx);
    const { uuid, name, countQuestions, questionGroups, numberOfCompletions, totalCandidates } = interview;
    const hasFolders = checkHasCompanyFeature(company, 'AUDITION_FOLDERS');


    const deleteScriptAction = () => {
        setLoading(true);
        deleteInterviewScript(interview.slug)
            .then((data) => {
                onClose();
                setLoading(false);
                if (data.success) {
                    if (onDelete) {
                        onDelete();
                    }
                    if (hasFolders) getFolders();
                    flashMessage('Assessment Deleted', 'done');
                    const eventData = {
                        ttId: uuid,
                        ttName: name,
                        questionsNumber: countQuestions,
                        questionGroupsNumber: questionGroups.length
                    };
                    eventData.totalCompletionsNumber = numberOfCompletions;
                    eventData.totalCandidatesNumber = totalCandidates;
                    if (questionGroups.length) {
                        eventData.questionGroups = questionGroups.map(item => item.title);
                    }
                    LibraryEvents.PRIVATE_TT_DELETED(eventData);
                } else {
                    setOpenDeleteScriptReject(true);
                }
            });
    };

    const closeScriptRejectModal = () => {
        setOpenDeleteScriptReject(false);
    };

    return (
        <>
            <Dialog
                maxWidth="sm"
                titleComponent="Delete Assessment"
                actionComponent={(
                    <>
                        <Button
                            color="primary"
                            className={classes.actionButton}
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            className={classes.deleteButton}
                            onClick={deleteScriptAction}
                        >
                            {
                                loading ? (
                                    <CircularProgress
                                        size={24}
                                        thickness={3}
                                        classes={{ root: classes.loader }}
                                    />
                                ) : 'Delete Assessment'
                            }
                        </Button>
                    </>
                )}
                open={open}
                onClose={onClose}
                handleClose={onClose}
            >
                Are you sure you want to delete this assessment? This action is not recoverable and all data will be lost.
            </Dialog>
            <Dialog
                maxWidth="sm"
                titleComponent="Delete Assessment"
                actionComponent={(
                    <Button
                        color="primary"
                        className={classes.actionButton}
                        onClick={closeScriptRejectModal}
                    >
                        Close
                    </Button>
                )}
                open={openDeleteScriptReject}
                onClose={closeScriptRejectModal}
            >
                You cannot delete this assessment which is currently being used by an employer.
            </Dialog>
        </>
    );
});

export default withStyles(styles)(DeleteAssessmentDialog);

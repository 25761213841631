import React, { useContext, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import Select from 'libraries/SelectValidated';
import TextInputValidated from 'libraries/TextInputValidated';
import SettingsEvents from 'events/SettingsEvents';
import { setupGuideStore } from 'components/layout/SetupGuide/store';
import { appCtx } from 'components/appStore';
import { getAssessmentsForTeamInvite, inviteTeamMember } from 'requests/APIrequests';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { PERMISSIONS } from 'helper/constants';

import styles from './styles';
import PermissionAssessments from '../../PermissionAssessments';

const InviteDialog = observer(({ classes, open, onClose, loadTeamMembers }) => {
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({ fullName: '', email: '', role: 'team' });
    const [errorValues, setErrorValues] = useState({});
    const [assessmentList, setAssessmentList] = useState([]);
    const { flashMessage, company } = useContext(appCtx);
    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);
    const { plan } = company;
    const formRef = useRef();

    useEffect(() => {
        setDefaultValues();

        if (open) {
            getAssessmentsForTeamInvite().then(({ data }) => {
                setAssessmentList(data.map(el => ({
                    ...el,
                    checked: false,
                    role: null
                })));
            });
        }
    }, [open]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
        setErrorValues({ ...errorValues, [name]: '' });
    };

    const setDefaultValues = () => {
        setValues({ fullName: '', email: '', role: 'team' });
        setErrorValues({});
    };

    const onSubmitValidate = () => {
        formRef.current.errors = [];

        formRef.current.walk(formRef.current.childs).then((result) => {
            if (formRef.current.errors.length) {
                formRef.current.props.onError(formRef.current.errors);
            }

            if (result) {
                invite();
            }

            return result;
        });
    };

    const { fullName, email, role } = values;

    const invite = () => {
        setLoading(true);
        const auditions = assessmentList.filter(el => el.role && el.checked).map(assessment => ({ role: assessment.role, slug: assessment.slug, uuid: assessment.uuid }));
        inviteTeamMember(JSON.stringify({ name: fullName, email, role, auditions }))
            .then(({ success, data }) => {
                if (success) {
                    SettingsEvents.TEAM_MEMBER_INVITED({ roleType: role });
                    onClose();
                    loadTeamMembers();
                    flashMessage('Invitation sent', 'done');
                    setupGuideStore.updateTMInfo();

                    auditions.forEach((audition) => {
                        SettingsEvents.ASSESSMENT_TEAM_ADDED({
                            ttId: audition.uuid,
                            ttName: audition.name,
                            userId: data.id,
                            assessmentRole: audition.role
                        });
                    });
                }
                setLoading(false);
            })
            .catch(({ response: { data } }) => {
                if (data && data.errors) {
                    if (typeof data.errors === 'string') {
                        flashMessage(data.errors);
                    } else {
                        const { email: errEmail, name: errName, role: errRole } = data.errors;
                        setErrorValues({
                            email: errEmail ? errEmail[0].message : '',
                            fullName: errName ? errName[0].message : '',
                            role: errRole ? errRole[0].message : ''
                        });
                    }
                } else {
                    flashMessage('Something went wrong');
                }
                setLoading(false);
            });
    };

    const renderError = (formParam) => {
        if (errorValues) {
            return errorValues[formParam] || '';
        }
        return '';
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            color="primary"
            onClose={onClose}
            handleClose={onClose}
            titleComponent="Invite a new team member"
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        onClick={onClose}
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={onSubmitValidate}
                        className={classes.sendButton}
                    >

                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : 'Send Invitation'
                        }
                    </Button>
                </>
            )}
        >
            {
                // eslint-disable-next-line no-nested-ternary
                plan.name === 'Free Trial' ? 'This is a temporary seat that will be removed upon upgrading.' : (
                    hasPermissionsFeature
                        ? 'The new team member will have the ability to create, edit and review their assessments as well as be added to other team members assessments as an editor or a viewer.'
                        : 'The new team member will have the ability to create, edit and review assessments.'
                )
            }
            <br />
            <ValidatorForm
                id="inviteTeamMember"
                ref={formRef}
            >
                <div className={classes.inputWrapper}>
                    <TextInputValidated
                        name="fullName"
                        variant="outlined"
                        label="Full Name"
                        placeholder="e.g Mel Ming"
                        formerror={renderError('fullName')}
                        value={fullName}
                        onChange={handleChange}
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                    {
                        hasPermissionsFeature && (
                            <Select
                                name="role"
                                variant="outlined"
                                className={classes.select}
                                options={[
                                    { value: 'team', label: 'Team' },
                                    { value: 'admin', label: 'Admin' }
                                ]}
                                onChange={handleChange}
                                formerror={renderError('role')}
                                value={role}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        )
                    }
                </div>
                <TextInputValidated
                    variant="outlined"
                    label="Email"
                    name="email"
                    value={email}
                    formerror={renderError('email')}
                    onChange={handleChange}
                    placeholder="e.g mel@company.com"
                    validators={['required', 'isEmail']}
                    errorMessages={[
                        'This field is required',
                        'Please enter a valid email address'
                    ]}
                />
                <PermissionAssessments
                    show={values.role === 'team' && hasPermissionsFeature}
                    assessmentList={assessmentList}
                    setAssessmentList={setAssessmentList}
                />
            </ValidatorForm>
        </Dialog>
    );
});

export default withStyles(styles)(InviteDialog);

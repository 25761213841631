import React from 'react';
import { makeStyles } from '@mui/styles';
import DoneIcon from '../icons/done.svg';
import FailIcon from '../icons/fail.svg';
import TruncateWithTooltip from '../../../tooltips/TruncateWithTooltip';

const useStyles = makeStyles(theme => ({
    label: {
        backgroundColor: ({ isCorrectChoice }) => (isCorrectChoice ? theme.palette.green[50] : theme.palette.red[50]),
        padding: '10px 15px',
        borderRadius: 4,
        border: `2px solid ${theme.palette.grey[300]}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 200,
        fontWeight: 600
    },
    before: {
        color: theme.palette.grey[600],
        margin: 4,
        fontWeight: 600,
        fontSize: 12
    },
    wrapper: {
        margin: '0 15px'
    }
}));

const WorkflowAnswer = ({ text, index, questionResults }) => {
    const answer = questionResults[index].answerResults.find(({ checked }) => checked);
    const isCorrectChoice = answer?.correctChoice;
    const { label, before, wrapper } = useStyles({ isCorrectChoice });

    return (
        <div className={wrapper}>
            <div className={before}>
                {text}
            </div>
            <div className={label}>
                <TruncateWithTooltip text={answer?.title || ' '} width={165} />
                <img src={isCorrectChoice ? DoneIcon : FailIcon} alt="*" />
            </div>
            <div className={before}>
                * Required
            </div>
        </div>
    );
};

export default WorkflowAnswer;

import React from 'react';
import { makeStyles } from '@mui/styles';
import RetinaImage from 'react-retina-image';

import { getInitials } from 'helper/commonFunctions';

const useStyles = makeStyles(theme => ({
    initialsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        height: '100%',
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        fontWeight: 800,
        fontSize: 12,
        textTransform: 'uppercase'
    }
}));

const Photo = ({ user = {}, size }) => {
    const { initialsWrapper } = useStyles();
    const photoSource = user.image_url;
    const { first_name, last_name, name } = user;
    const userName = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : name;

    const initials = getInitials(userName);
    return (
        <div
            role="presentation"
            style={{ width: size, height: size }}
        >
            {
                photoSource
                    ? (
                        <RetinaImage
                            alt={userName}
                            src={photoSource}
                        />
                    ) : (
                        <div className={initialsWrapper}>
                            {initials}
                        </div>
                    )
            }
        </div>
    );
};

export default Photo;

export default theme => ({
    inviteButton: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        cursor: 'pointer'
    },
    wrapper: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 475,
        backgroundColor: theme.palette.grey[200]
    }
});

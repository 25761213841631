import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');

require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');

const codeMirrorOptions = {
    lineNumbers: true,
    theme: 'material',
    mode: 'javascript'
};

const CodeQuestion = ({ question }) => (
    <div>
        <CodeMirror
            value={question.codeEditor}
            options={codeMirrorOptions}
        />
    </div>
);

export default CodeQuestion;

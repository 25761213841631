import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Layout from 'components/layout';
import InnerHeader from 'components/layout/InnerHeader';
import DrawerPageWrapper from 'components/layout/DrawerPageWrapper';

import ContentLibraryTabs from '../common/ContentLibraryTabs';
import ContentQuestions from './content_questions';
import QuestionDrawer from '../common/QuestionDrawer';


import styles from './styles';

const ContentLibrary = observer(({ classes }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedSet, setSelectedSet] = useState(null);
    const [addToSelectedSkill, setAddToSelectedSkill] = useState(false);

    const toggleDrawer = () => {
        setAddToSelectedSkill(false);
        setOpenDrawer(!openDrawer);
    };

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleSelectedSkillId = (id) => {
        handleOpenDrawer();
        setSelectedQuestionId(id);
    };

    return (
        <Layout>
            <InnerHeader
                header="Question Library"
                className={classes.wrapper}
                rightPart={(
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenDrawer}
                    >
                        Create New Question
                    </Button>
                )}
            />
            <DrawerPageWrapper contentWrapperCls="u-fdir--column" className="u-pdn--bx5">
                <ContentLibraryTabs />
                <ContentQuestions
                    openDrawer={openDrawer}
                    setSelectedQuestionId={handleSelectedSkillId}
                    setSelectedSkill={setSelectedSkill}
                    selectedSkill={selectedSkill}
                    selectedSet={selectedSet}
                    setSelectedSet={setSelectedSet}
                    setAddToSelectedSkill={setAddToSelectedSkill}
                    handleOpenDrawer={handleOpenDrawer}
                    selectedQuestionId={selectedQuestionId}
                />
                <QuestionDrawer
                    open={openDrawer}
                    selectedSet={selectedSet}
                    selectedSkill={selectedSkill}
                    addToSelectedSkill={addToSelectedSkill}
                    selectedQuestionId={selectedQuestionId}
                    setSelectedQuestionId={setSelectedQuestionId}
                    toggleDrawer={toggleDrawer}
                />
            </DrawerPageWrapper>
        </Layout>
    );
});

export default withRouter(withStyles(styles)(ContentLibrary));

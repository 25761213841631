import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { appCtx } from 'components/appStore';
import Dialog from 'libraries/Dialog';

import AssessmentEvents from 'events/AssessmentEvents';
import { extendCandidates } from 'requests/AssessmentRequests';
import { checkHasCompanyFeature, clone } from 'helper/commonFunctions';
import { BATCH_ACTIONS, BATCH_STEP } from 'helper/constants';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';

import ExtentionRequestsRow from './ExtensionRequestsRow';

import styles from './styles';


const ExtensionsRequestsDialog = observer(({
    classes, requestedMoreTimeCount, extensionRequestedCandidates,
    setExtensionRequestedCandidates, loadExtensionsRequests,
    onExtendRequestSuccess, handleClose, open, audition
}) => {
    const { flashMessage, company } = useContext(appCtx);

    const [disabled, setDisabled] = useState(false);

    const hasCompanyFeature = checkHasCompanyFeature(company, BATCH_ACTIONS);

    if (!audition) return null;

    const { opportunitySlug, uuid: ttId, name: ttName, expired, valid } = audition;

    const approvedCandidates = extensionRequestedCandidates.map(candidate => candidate.userAuditionId);

    useEffect(() => {
        loadExtensionsRequests();
    }, []);

    const extendCandidatesFunc = (currentStep = 1, stepsNeeded) => {
        const nextStartIndex = (currentStep - 1) * BATCH_STEP;
        const nextLastIndex = nextStartIndex + BATCH_STEP;
        return extendCandidates(opportunitySlug, approvedCandidates.slice(nextStartIndex, nextLastIndex))
            .then(({ success }) => {
                if (success) {
                    if (currentStep === stepsNeeded) {
                        setExtensionRequestedCandidates([]);
                        if (onExtendRequestSuccess) onExtendRequestSuccess();
                        AssessmentEvents.CANDIDATES_BATCH_ACTIONED({
                            actionType: 'extend',
                            numberCandidatesActioned: approvedCandidates.length,
                            ttId,
                            ttName,
                            context: 'extension requests'
                        });
                        flashMessage('All requests approved', 'done');
                    } else if (currentStep < stepsNeeded) {
                        extendCandidatesFunc(currentStep + 1, stepsNeeded);
                    }
                } else {
                    flashMessage('Something went wrong', 'error');
                }
            })
            .catch((err) => {
                flashMessage(err?.response?.data?.msg || 'Something went wrong', 'error');
            });
    };

    const approveAllExtensions = async () => {
        const stepsNeeded = Math.ceil(approvedCandidates.length / BATCH_STEP);
        extendCandidatesFunc(1, stepsNeeded);
    };

    const onSingleRequestSuccess = (userAuditionId) => {
        const newCandidates = clone(extensionRequestedCandidates);
        const index = extensionRequestedCandidates.findIndex(candidate => candidate.userAuditionId === userAuditionId);
        newCandidates.splice(index, 1);
        setExtensionRequestedCandidates(newCandidates);
        onExtendRequestSuccess();
        if (!newCandidates.length) handleClose();
    };

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.popoverPaper }}
            onClose={handleClose}
            handleClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            titleClassName={classes.dialogTitle}
            titleComponent={(
                <div className="u-dsp--f u-jc--sb u-ai--center u-wdt--100p">
                    <div>
                        Extension Requests <span className={classes.blueLabel}>({requestedMoreTimeCount})</span>
                    </div>
                    {hasCompanyFeature && (
                        <Button
                            onClick={approveAllExtensions}
                            variant="text"
                            color="primary"
                            disabled={disabled || expired || !valid}
                        >Approve all
                        </Button>
                    )}
                </div>
            )}
        >

            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCellHead className={classes.tableCellHead} label="Candidate Name" hideSort />
                            <TableCellHead className={classes.tableCellHead} label="Reason" hideSort />
                            <TableCellHead className={classes.tableCellHead} label="Approve/Deny" align="right" hideSort />
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {
                            extensionRequestedCandidates.map(candidate => (
                                <ExtentionRequestsRow
                                    key={candidate.candidateUuid}
                                    candidate={candidate}
                                    disabled={disabled}
                                    audition={audition}
                                    setDisabled={setDisabled}
                                    onSingleRequestSuccess={onSingleRequestSuccess}
                                />
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Dialog>
    );
});

export default withStyles(styles)(ExtensionsRequestsDialog);

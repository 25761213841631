import React from 'react';
import TextInput from 'libraries/TextInput';
import Switch from 'libraries/SwitchBrandWithLabel';
import Tooltip from 'libraries/TooltipWhite';
import CopyToClipboard from 'react-copy-to-clipboard';
import Loader from 'components/loaders/Loader';
import { withStyles } from '@mui/styles';

import WarningIcon from 'img/icons/warning.svg';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';

import SettingsEvents from 'events/SettingsEvents';
import { getPageUpCredentials, updatePageUpIntegration } from 'requests/SettingsRequests';

import styles from './styles';


const warningMessages = [
    'Do not perform any actions on candidates from Vervoe, this might cause the integration to fail. Please use the PageUp ATS to manage candidates.',
    'Once this integration is enabled all candidate facing emails will be sent through PageUp. This will allow you to manage communication from one platform.',
    'Skills Assessments will be defaulted to not have an expiration date. You should control expiration via PageUp interface so that extensions can be granted from PageUp.'
];


const warningComponent = (
    <>
        {
            warningMessages.map((label, index) => (
                <div
                    className="c-settings__warning"
                    key={index}
                >
                    <img src={WarningIcon} alt="!" />
                    <div>
                        { label }
                    </div>
                </div>
            ))
        }
    </>
);

class PageUpContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientId: '',
            privateKey: '',
            enabled: false,
            openDialog: false,
            dataCame: false,
            webHookLink: '',
            errForm: {}
        };
    }

    componentDidMount() {
        getPageUpCredentials()
            .then(({ data, success }) => {
                if (success) {
                    this.setState({ dataCame: true });
                    if (data) {
                        const { clientId, enabled, webHookLink, privateKey } = data;
                        this.setState({
                            clientId,
                            enabled,
                            privateKey,
                            webHookLink
                        });
                    }
                }
            });
    }

    handleChangeSecret = (e) => {
        const { errForm } = this.state;
        const targetValue = e.target.value;
        this.setState({ privateKey: targetValue, errForm: { ...errForm, privateKey: '' } });
    };

    handleChangeClientId = (e) => {
        const { errForm } = this.state;
        const targetValue = e.target.value;
        this.setState({ clientId: targetValue, errForm: { ...errForm, clientId: '' } });
    };

    enableIntegration = () => {
        const { flashMessage, company, updateCompany } = this.props;
        const { clientId, privateKey: statePrivateKey } = this.state;
        SettingsEvents.PUP_ENABLED({ enabled: true });
        updatePageUpIntegration(clientId, statePrivateKey, true)
            .then(({ success, data }) => {
                if (success) {
                    const { webHookLink, enabled, privateKey } = data;
                    flashMessage('Integration enabled', 'done');
                    this.setState({
                        enabled,
                        openDialog: false,
                        errForm: {},
                        privateKey,
                        webHookLink
                    });
                    const newCompany = { ...company, isAtsEnabled: true };
                    updateCompany(newCompany);
                } else {
                    flashMessage('Something went wrong', '');
                    this.setState({ openDialog: false });
                }
            })
            .catch(({ response: { data } }) => {
                this.setState({
                    enabled: false,
                    openDialog: false
                });
                if (data && data.errors) {
                    const { clientId: errClientId, privateKey: errPrivateKey } = data.errors;
                    this.setState({
                        errForm: {
                            clientId: errClientId ? errClientId[0].message : '',
                            privateKey: errPrivateKey ? errPrivateKey[0].message : ''
                        }
                    });
                }
            });
    };

    handleChangeSwitch = () => {
        const { enabled, clientId, privateKey } = this.state;
        const { flashMessage, updateCompany, company } = this.props;
        if (!enabled) {
            this.setState({ openDialog: true });
        } else {
            SettingsEvents.PUP_ENABLED({ enabled: false });
            updatePageUpIntegration(clientId, privateKey, false)
                .then(({ success }) => {
                    if (success) {
                        const newCompany = { ...company, isAtsEnabled: false };
                        flashMessage('Integration disabled', 'done');
                        updateCompany(newCompany);
                        this.setState({
                            enabled: false
                        });
                    } else {
                        flashMessage('Something went wrong', '');
                    }
                });
        }
    };

    getErrorField = (field) => {
        const { errForm } = this.state;
        if (errForm && errForm[field]) {
            return errForm[field];
        }
        return '';
    };

    handleDialogClose = () => {
        this.setState({
            openDialog: false,
            enabled: false
        });
    };

    onCopyLink = () => {
        const { flashMessage } = this.props;
        flashMessage('Launch URL copied to clipboard');
    };

    render() {
        const { classes } = this.props;
        const {
            enabled, clientId,
            privateKey, openDialog,
            dataCame, webHookLink
        } = this.state;

        return (
            <div>
                <div className="c-settings__additional-info">
                    <div className="c-settings__additional-info-header">About the integration</div>
                    <div>
                        <p>The integration enables you to completely streamline your skill testing from PageUp.</p>

                        With the integration you’ll be able to do the following from PageUp:

                        <ul className="c-settings__additional-info-list">
                            <li>Invite candidates to complete a Skills Assessment</li>
                            <li>View candidate results</li>
                            <li>Manually grade candidate results</li>
                            <li>Let recruiters order Skills Assessments from PageUp</li>
                        </ul>

                        Learn more <a target="_blank" rel="noopener noreferrer" className={classes.pageUpLink} href="https://vervoe.zendesk.com/hc/en-us/articles/360042635612">here</a>.
                    </div>
                </div>
                {
                    !dataCame ? (
                        <Loader show style={{ backgroundColor: 'none', position: 'relative', marginTop: 70 }} />
                    ) : (
                        <div className="c-settings__pageup-settings">
                            <div className="c-settings__input-wrapper">
                                <div
                                    data-tip
                                    data-for="page-up-disabled-tooltip"
                                >
                                    <TextInput
                                        label="PageUp Client ID"
                                        fullWidth
                                        data-tip
                                        data-for="page-up-disabled-tooltip"
                                        name="clientId"
                                        variant="outlined"
                                        type="text"
                                        value={clientId}
                                        isError={Boolean(this.getErrorField('clientId'))}
                                        helperText={this.getErrorField('clientId')}
                                        onChange={this.handleChangeClientId}
                                        disabled={enabled}
                                        className={classes.input}
                                    />
                                    <p className="u-txt--10">Please contact your PageUp Technical Account Manager to get your Client ID.</p>
                                </div>
                                <div
                                    data-tip
                                    data-for="page-up-disabled-tooltip"
                                >
                                    <TextInput
                                        label="PageUp Secret"
                                        fullWidth
                                        name="privateKey"
                                        type="text"
                                        variant="outlined"
                                        isError={Boolean(this.getErrorField('privateKey'))}
                                        helperText={this.getErrorField('privateKey')}
                                        onChange={this.handleChangeSecret}
                                        value={privateKey}
                                        disabled={enabled}
                                        className={classes.input}
                                    />
                                    <p className="u-txt--10">Please contact your PageUp Technical Account Manager to get your PageUp Secret.</p>
                                </div>
                                <div className={classes.textFieldWrapper}>
                                    <CopyToClipboard
                                        text={webHookLink}
                                        className={classes.buttonRoot}
                                        onCopy={this.onCopyLink}
                                    >
                                        <Button>
                                            COPY LINK
                                        </Button>
                                    </CopyToClipboard>
                                    <TextInput
                                        label="Launch URL"
                                        fullWidth
                                        name="link"
                                        type="text"
                                        variant="outlined"
                                        value={webHookLink}
                                        className={classes.input}
                                        disabled
                                    />
                                    <p className="u-txt--10">Copy this link into the &apos;Launch URL&apos; and &apos;UAT Launch URL&apos; fields of every assessment you are adding to PageUp.</p>
                                </div>
                            </div>
                            {
                                enabled && (
                                    <Tooltip
                                        id="page-up-disabled-tooltip"
                                        position="right"
                                        className="c-settings__pageup-tooltip"
                                        delayHide={300}
                                        delayShow={300}
                                    >
                                        <p className="u-mrg--bx0 u-pdn--rx3">
                                            To edit this field, first disable the integration, then re-enable it.
                                        </p>
                                    </Tooltip>
                                )
                            }
                            <br />
                            <Switch
                                label="Enable PageUp Integration"
                                checked={enabled}
                                value={enabled}
                                onChange={this.handleChangeSwitch}
                            />
                            <Dialog
                                open={openDialog}
                                maxWidth="sm"
                                handleClose={this.handleDialogClose}
                                titleComponent="Enable PageUp Integration"
                                actionComponent={(
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.dialogButton}
                                        onClick={this.enableIntegration}
                                    >
                                        Enable Integration
                                    </Button>
                                )}
                            >
                                { warningComponent }
                            </Dialog>
                            { enabled && warningComponent }
                        </div>
                    )
                }
            </div>
        );
    }
}

export default withStyles(styles)(PageUpContent);

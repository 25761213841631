import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    iconButton: {
        padding: 5,
        color: theme.palette.grey[600],
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
        borderRadius: '50%'
    },
    navLabel: {
        marginLeft: 10,
        marginRight: 10,
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: 600
    },
    navWrapper: {
        marginTop: -11,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20
    }
}));

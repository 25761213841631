import React from 'react';

// material components
import { withStyles } from '@mui/styles';
import { changeSimulatorOrder } from 'requests/QuestionsRequests';
import Button from '@mui/material/Button';
import AddIcon from 'img/add.svg';
import Typography from '@mui/material/Typography';

import OrderItem from './OrderItem';
import styles from './styles';

let timerId = null;

const OrdersList = ({
    classes, setSaving,
    isCMS, companyQuestionCsSimulatorOrders = [],
    addOrder, removeOrder, ticket, setTicket
}) => {
    const handleChangeOrder = (index, name, value) => {
        const { id: ticketId } = companyQuestionCsSimulatorOrders[index];
        clearTimeout(timerId);
        setSaving(true);
        timerId = setTimeout(() => {
            changeSimulatorOrder(ticketId, { [name]: value }, isCMS)
                .then(({ data, success }) => {
                    if (data && success) {
                        const newCompanyQuestionCsSimulatorOrders = companyQuestionCsSimulatorOrders.slice();
                        newCompanyQuestionCsSimulatorOrders[index].valid = data.valid;
                        setTicket({
                            ...ticket,
                            companyQuestionCsSimulatorOrders: newCompanyQuestionCsSimulatorOrders
                        });
                    }
                    setSaving(false);
                })
                .catch(() => {
                    setSaving(false);
                });
        }, 500);
    };

    return (
        <>
            <Typography variant="h6" className="u-mrg--bx2 u-mrg--tx4">Orders</Typography>
            {
                !companyQuestionCsSimulatorOrders.length && (
                    <p className={classes.noTicketsLabel}>No ticket orders created yet</p>
                )
            }
            {
                companyQuestionCsSimulatorOrders.map((answerItem, index) => (
                    <OrderItem
                        key={answerItem.id}
                        {...{
                            answerItem,
                            handleChangeOrder,
                            removeOrder,
                            index
                        }}
                    />
                ))
            }
            <div className="u-mrg--bx6">
                <Button
                    onClick={addOrder}
                    id="qa-add-mlc-ans"
                    color="primary"
                    className="u-txt--bold u-mrg--bx2"
                >
                    <img src={AddIcon} alt="x" />&nbsp; Add Order
                </Button>
            </div>
        </>
    );
};

export default withStyles(styles)(OrdersList);

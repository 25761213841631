import React from 'react';

// material components
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import Dialog from 'libraries/Dialog';

// local files and components
import check from 'img/candidateCard/check.svg';
import lock from 'img/candidateCard/lockkk.svg';
import close from 'img/candidateCard/close.svg';
import eye from 'img/candidateCard/eye.svg';

import AddIcon from './add.svg';

import styles from './styles';


const ChooseNewTestCaseTypeDialog = ({ open, classes, handleClose, chooseCase }) => (
    <Dialog
        open={open}
        handleClose={handleClose}
        maxWidth="md"
        titleComponent="Choose type of test case"
        contentClassName={classes.content}
    >
        <div style={{ position: 'relative', margin: '12px' }}>
            <Paper classes={{ root: classes.paper }}>
                <Typography classes={{ root: classes.typeH5 }} variant="h4" component="h3">
                    Autograded test case
                </Typography>
                <Typography classes={{ root: classes.type }}>
                    {"Used to assess candidates' ability and automatically assign scores."}
                </Typography>
                <Typography classes={{ root: classes.type }}>
                    <img style={{ marginRight: '6px' }} src={check} alt="" /> Test will affect score and is autograded
                </Typography>
                <Typography classes={{ root: classes.type }}>
                    <img style={{ marginLeft: '-2px', marginRight: '12px' }} src={lock} alt="" /> Input & expected output will be hidden from candidates
                </Typography>
            </Paper>
            <Button
                onClick={() => chooseCase('autograded', null)}
                classes={{ root: classes.addBtn }}
            >
                <img src={AddIcon} alt="add" />
                <br />
                Choose autograded  <br /> test case
            </Button>
        </div>
        <div style={{ position: 'relative', margin: '12px' }}>
            <Paper classes={{ root: classes.paper }}>
                <Typography classes={{ root: classes.typeH5 }} variant="h4" component="h3">
                    Example test case
                </Typography>
                <Typography classes={{ root: classes.type }}>
                    Used to help candidates understand the problem and the types of inputs and outputs.
                </Typography>
                <Typography classes={{ root: classes.type }}>
                    <img style={{ marginRight: '6px' }} src={close} alt="" /> Test won’t affect score
                </Typography>
                <Typography classes={{ root: classes.type }}>
                    <img style={{ marginRight: '12px' }} src={eye} alt="" /> Input & expected output will be visible to candidates
                </Typography>
            </Paper>
            <Button
                onClick={() => chooseCase('example', null)}
                classes={{ root: classes.addBtn }}
            >
                <img src={AddIcon} alt="add" />
                <br />
                Choose example <br /> test case
            </Button>
        </div>
    </Dialog>
);

export default withStyles(styles)(ChooseNewTestCaseTypeDialog);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import BackIcon from 'img/back.svg';

const styles = ({
    backIconButton: {
        padding: 0,
        marginRight: 12
    }
});


const BackIconButton = ({ onClick, classes }) => (
    <IconButton onClick={onClick} className={classes.backIconButton} size="large">
        <img src={BackIcon} alt="<" />
    </IconButton>
);

export default withRouter(withStyles(styles)(BackIconButton));

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { requestExportCsvPerAssessment } from 'requests/AssessmentRequests';
import ExportSettingsForm from '../ExportSettingsForm';
import DialogWrapper from '../DialogWrapper';
import { checkHasUserPermission } from '../../../../helper/commonFunctions';
import { appCtx } from '../../../appStore';

const styles = {
    advancedLabel: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: 0.8,
        color: '#3a3c43',
        marginBottom: 10,
        textTransform: 'uppercase'
    }
};

const AssessmentExportDialog = observer(({
    classes, usersCount: usersCountProps,
    open, onClose, slug, opportunity,
    segmentEventContext
}) => {
    const { company } = useContext(appCtx);
    if (!opportunity) return null;
    const { permissions } = opportunity;
    const grade = checkHasUserPermission(company, permissions, 'grade');

    const requestExportCSVFunc = (...other) => requestExportCsvPerAssessment(slug, ...other);

    return (
        <DialogWrapper
            open={open}
            slug={slug}
            onClose={onClose}
            usersCount={usersCountProps}
            fileName={slug}
            requestExportCSVFunc={requestExportCSVFunc}
            segmentEventContext={segmentEventContext}
        >
            <div
                className={classes.advancedLabel}
            >
                Advanced Settings
            </div>
            <ExportSettingsForm
                disabledExportScoring={!grade}
                canIncludeRank
            />
        </DialogWrapper>
    );
});

export default withStyles(styles)(AssessmentExportDialog);

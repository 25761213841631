import React, { useEffect, useMemo } from 'react';
import { withStyles } from '@mui/styles';

import clsx from 'clsx';
import Checkbox from 'libraries/Checkbox';
import TextInput from 'libraries/TextInput';
import UploadIcon from 'img/upload.svg';
import CloseIcon from 'img/close.svg';
import styles from '../../styles';
import { getInitialData, getConfig } from '../infoFormConfig';

const FormRequestInfo = ({
    classes, selectedCategory, setRequestFormData, requestFormData, flashMessage
}) => {
    useEffect(() => {
        setRequestFormData(getInitialData(selectedCategory));
    }, [selectedCategory]);

    const handleTextValueChange = (e, field) => {
        setRequestFormData((prev) => {
            const newData = { ...prev };
            newData[field].text = e.target.value;
            return newData;
        });
    };

    const handleOptionValueChange = (e, field, label) => {
        const { checked } = e.target;
        if (checked) {
            setRequestFormData((prev) => {
                const newData = { ...prev };
                newData[field].options.push({
                    title: label
                });
                return newData;
            });
        } else {
            setRequestFormData((prev) => {
                const newData = { ...prev };
                newData[field].options = newData[field].options.filter(el => el.title !== label);
                return newData;
            });
        }
    };

    const handleOptionTextValueChange = (e, field, label) => {
        setRequestFormData((prev) => {
            const newData = { ...prev };
            newData[field].options = newData[field].options.map((opt) => {
                if (opt.title === label) {
                    opt.text = e.target.value;
                }
                return opt;
            });
            return newData;
        });
    };

    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
        if (file) {
            const maxFileSize = 10 * 1024 * 1024;
            if (file.size > maxFileSize) {
                flashMessage('The file should not be empty and its size should be 10MB or less', 'error');
                return;
            }
            setRequestFormData((prev) => {
                const newData = { ...prev };
                newData[field].file = file;
                return newData;
            });
        }
    };

    const removeFile = (field) => {
        setRequestFormData((prev) => {
            const newData = { ...prev };
            newData[field].file = null;
            return newData;
        });
    };

    const config = useMemo(() => getConfig(selectedCategory), [selectedCategory]);

    const formatFileName = (fileName) => {
        if (fileName.length > 80) {
            return `${fileName.slice(0, 70)}... ${fileName.slice(-4)}`;
        }
        return fileName;
    };

    return (
        <form className={classes.wrapper}>
            {config.map((configField, idx) => {
                if (configField.options) {
                    return (
                        <div className="u-mrg--tx5" key={configField.id}>
                            <div className={clsx(classes.title, 'u-txt--left')}>{configField.title}</div>
                            {configField.subtitle && <div className={clsx(classes.subtitle, 'u-txt--left')}>{configField.subtitle}</div>}
                            {
                                configField.options.map((option) => {
                                    const currentOptions = requestFormData[configField.id]?.options;
                                    const currentOption = currentOptions?.find(opt => opt.title === option.label);
                                    const disabled = configField.max
                                        && !currentOptions?.some(opt => opt.title === option.label)
                                        && currentOptions?.length >= configField.max;
                                    return (
                                        <div className="u-dsp--f">
                                            <Checkbox
                                                className={clsx(option.withTextField && 'u-wdt--200')}
                                                label={option.label}
                                                value={option.label}
                                                onChange={e => handleOptionValueChange(e, configField.id, option.label)}
                                                disabled={disabled}
                                            />
                                            {option.withTextField && (
                                                <TextInput
                                                    onChange={e => handleOptionTextValueChange(e, configField.id, option.label)}
                                                    value={currentOption?.text || ''}
                                                    disabled={!currentOption}
                                                    placeholder={option.placeholder || ''}
                                                    className={clsx(option.placeholder && classes.placeholder)}
                                                    maxLength={250}
                                                    hasCounter={currentOption}
                                                />
                                            )}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    );
                }

                return (
                    <div className="u-mrg--tx5 u-pos--relative" key={configField.id}>
                        <div className={clsx(classes.title, 'u-txt--left')}>{configField.title}</div>
                        <TextInput
                            className={clsx(classes.textField, classes.placeholder)}
                            counterClassName={classes.inputCounterLabel}
                            multiline
                            rows={5}
                            maxLength={3000}
                            hasCounter
                            placeholder={configField.placeholder}
                            onChange={e => handleTextValueChange(e, configField.id)}
                            value={requestFormData[configField.id]?.text || ''}
                        />

                        {
                            requestFormData[configField.id]?.file ? (
                                <div className={classes.textFieldUpload}>
                                    {formatFileName(requestFormData[configField.id]?.file.name)}
                                    <img src={CloseIcon} alt="" onClick={() => removeFile(configField.id)} />
                                </div>
                            ) : (
                                <label htmlFor={`file-upload${idx}`} className={classes.textFieldUpload}>
                                    <img src={UploadIcon} alt="" />
                                    <input
                                        className={classes.invisible}
                                        type="file"
                                        accept=".pdf,.doc,.docx,application/msword,application/pdf"
                                        onChange={e => handleFileChange(e, configField.id)}
                                        hidden
                                        id={`file-upload${idx}`}
                                    />
                                </label>
                            )
                        }
                    </div>
                );
            })}
        </form>
    );
};

export default withStyles(styles)(FormRequestInfo);

export default theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: 16,
        marginRight: 10,
        cursor: 'pointer',
        fontSize: 12,
        color: theme.palette.yellow[800],
        fontWeight: 'bold',
        lineHeight: '27px',
        '&:hover': {
            color: theme.palette.grey[900],
            background: 'none'
        }
    },
    circular: {
        backgroundColor: 'red'
    },
    guide: {
        minWidth: 375,
        fontSize: 14,

        '& > li': {
            position: 'relative',
            lineHeight: 1.5,
            padding: 30,
            transition: '0.3s all',

            '&$listItemActive': {
                cursor: 'pointer',
                '& $guideDesc': {
                    color: theme.palette.grey[800]
                },
                '& $guideTitle': {
                    color: theme.palette.primary.main
                },
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.06)'
                }
            },

            '&:first-child': {
                borderBottom: `2px solid ${theme.palette.grey[300]}`
            },
            '&:last-child': {
                paddingTop: 20
            },
            '&:nth-child(2)': {
                paddingBottom: 20
            },
            '&:nth-child(3n)': {
                paddingTop: 20,
                paddingBottom: 20
            },
            '&:nth-child(4n)': {
                paddingTop: 20,
                paddingBottom: 20
            }
        }
    },
    listItemActive: {},
    guideHeader: {
        position: 'relative',
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 1.8,
        letterSpacing: 'normal',
        color: theme.palette.grey[900],
        marginBottom: 3
    },
    guideHeaderDesc: {
        color: theme.palette.grey[700],
        letterSpacing: 0.2,
        marginBottom: 0
    },
    guideTitle: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        letterSpacing: 0.2,
        color: theme.palette.grey[600],
        fontWeight: 'bold',
        marginBottom: 5
    },
    guideDesc: {
        maxWidth: 276,
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '21px',
        letterSpacing: 0.2,
        color: theme.palette.grey[600],
        marginBottom: 0
    },

    listIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: 24
    },
    popover: {
        padding: 0,
        borderRadius: 8
    },
    popoverRoot: {
        zIndex: 1299
    },
    tooltip: {
        width: 280
    }
});

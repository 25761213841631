import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Masonry from 'react-masonry-component';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import CandidateListWrapper from '../common/CandidateListWrapper';
import SearchFilterBlock from './SearchFilterBlock';
import ShortlistCard from './ShortlistCard';
import { candidateResultCtx } from '../common/CandidateListWrapper/candidatesStore';
import NotFoundContent from '../../../../components/assessments_pages/NotFoundContent';
import BoardNoContent from '../../../../components/board/board_no_content';
import EmptyIcon from '../common/NoInvitationsContent/icon.svg';
import TagsList from '../../components/TagsList';
import SortLabels from '../common/SortLabels';


import { assessmentSettingsCtx } from '../../store';
import { shortlistCtx } from './shortlistStore';
import styles from './styles';

const Shortlist = observer(({ classes }) => {
    const { audition } = useContext(assessmentSettingsCtx);
    const {
        totalCount, allCandidates,
        query, hasMore, isLoadingLocal,
        page, handleChangePage,
        filterTagsCategory, removedCandidateByIndex,
        filterCategory, handleRemoveFilterSelect,
        handleChangeFilterSelect,
        sortLoading, sortProgress
    } = useContext(candidateResultCtx);

    const { cleanScript } = useContext(shortlistCtx);
    const { slug } = audition;

    useEffect(() => {
        cleanScript();
    }, [slug]);

    const loadMore = () => {
        handleChangePage(page + 1);
    };

    return (
        <CandidateListWrapper isShortList>
            <SearchFilterBlock className="u-mrg--tx4" />
            <div className="u-dsp--f u-ai--start u-jc--sb">
                <div className="u-dsp--f u-mrg--bx2 u-ai--start">
                    <Typography variant="h5" className="u-mrg--rx2">
                        Candidate shortlist
                    </Typography>
                    <TagsList {...{ filterTagsCategory,
                        filterCategory,
                        handleRemoveFilterSelect,
                        handleChangeFilterSelect }}
                    />
                </div>
                <SortLabels />
            </div>
            <InfiniteScroll
                useWindow={false}
                pageStart={0}
                threshold={0}
                loadMore={loadMore}
                hasMore={hasMore && !isLoadingLocal}
                className={classes.infiniteScroll}
                loader={<CircularProgress key="loader" className={classes.loader} />}
            >
                <Masonry component="div" className={classes.masonryWrapper}>
                    {Boolean(allCandidates) && allCandidates.map((candidate, index) => (
                        <ShortlistCard
                            key={candidate.candidateId}
                            candidate={candidate}
                            onRemoveFromShortlist={() => removedCandidateByIndex(index)}
                        />
                    ))}
                </Masonry>
                {
                    sortLoading && (
                        <div className={classes.loadingWrapper}>
                            <LinearProgress
                                variant="determinate"
                                value={sortProgress}
                                className={classes.linearProgressRoot}
                            />
                        </div>
                    )
                }
            </InfiniteScroll>
            {
                isLoadingLocal && <CircularProgress key="loader" className={classes.loader} />
            }
            {
                Boolean(query && !totalCount && !isLoadingLocal) && (
                    <NotFoundContent searchValue={query} />
                )
            }

            {
                Boolean(!query && !totalCount && Object.keys(filterTagsCategory).length && !isLoadingLocal) && (
                    <BoardNoContent
                        header="No Candidates"
                        hideIconWrapper
                        className={classes.board}
                        icon={<img src={EmptyIcon} alt="empty-content" />}
                    />
                )
            }
        </CandidateListWrapper>
    );
});

export default withStyles(styles)(Shortlist);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import VervoeLogo from 'components/layout/Header/components/VervoeLogo';
import vervoeIcon from 'components/dialogs/SsoLoginDialog/vervoeIcon.svg';

import styles from './styles';

const PreviewHeader = ({ classes }) => (
    <div className={classes.headerPanel}>
        <div
            className={classes.logoWrapper}
        >
            <img src={vervoeIcon} alt="vervoe" style={{ width: 40, marginRight: 7 }} />
            <VervoeLogo />
        </div>
    </div>
);

export default withRouter(withStyles(styles)(PreviewHeader));

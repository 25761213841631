import React, { Component } from 'react';
import { withStyles } from '@mui/styles';

import {
    GOOGLE_DOC, SPREADSHEET,
    PRESENTATION, SPREADSHEET_AUTO_GRADED, EXCEL_AUTO_GRADED
} from 'helper/constants';

import ImmersiveDialog from 'components/dialogs/ImmersiveDialog';
import GoogleDocImg from 'img/previewImmersive/document/document.svg';
import PresentationImg from 'img/previewImmersive/presentation/presentation.svg';
import SpreadsheetImg from 'img/previewImmersive/spreadsheet/spreadsheet.svg';

import styles from './styles';

class ImmersiveAnswer extends Component {
    state = {
        dialogOpened: false
    };

    handleDialog = () => {
        const { dialogOpened } = this.state;
        this.setState({ dialogOpened: !dialogOpened });
    };

    returnCorrectImage = () => {
        const { question, classes } = this.props;
        const { answerType } = question;
        const commonProps = {
            className: classes.immersiveImg,
            onClick: this.handleDialog
        };
        switch (answerType) {
            case GOOGLE_DOC:
                return (
                    <img
                        alt="immersive_preview"
                        src={GoogleDocImg}
                        {...commonProps}
                    />
                );
            case PRESENTATION:
                return (
                    <img
                        alt="immersive_preview"
                        src={PresentationImg}
                        {...commonProps}
                    />
                );
            case SPREADSHEET:
            case SPREADSHEET_AUTO_GRADED:
                return (
                    <img
                        alt="immersive_preview"
                        src={SpreadsheetImg}
                        {...commonProps}
                    />
                );
            case EXCEL_AUTO_GRADED:
                return (
                    <img
                        alt="immersive_preview"
                        src={SpreadsheetImg}
                        {...commonProps}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        const { dialogOpened } = this.state;
        const { question, questionNumber, previewLink } = this.props;

        return (
            <>
                {this.returnCorrectImage()}
                <ImmersiveDialog
                    onClose={this.handleDialog}
                    open={dialogOpened}
                    question={question}
                    previewLink={previewLink}
                    questionNumber={questionNumber}
                />
            </>
        );
    }
}

export default withStyles(styles)(ImmersiveAnswer);

export default (theme => ({
    text: {
        fontWeight: 600,
        color: theme.palette.grey[700],
        fontSize: 14
    },
    link: {
        color: theme.palette.primary.main
    },
    content: {
        textAlign: 'center',
        paddingTop: 65,
        paddingBottom: 35
    }
}));

import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import styles from './styles';
import NoInvitationsBoard from './NoInvitationsBoard';
import AssessmentStats from '../../components/AssessmentStats';
import { assessmentSettingsCtx } from '../../store';
import InviteCandidatesList from './InviteCandidatesList';
import { invitationsCtx } from '../invitationsStore';
import { statsCtx } from '../../statsStore';

const InviteCandidates = observer(({ classes, match }) => {
    const { scriptSlug } = match.params;
    const { audition } = useContext(assessmentSettingsCtx);
    const {
        isLoading: invitationsLoading,
        setSlug, cleanState,
        fetchInvitationStats,
        setFilterCategory, loadCandidates
    } = useContext(invitationsCtx);

    const {
        loading: statsLoading, totalCount, loadAssessmentStats,
        statsArr, requestedMoreTimeCount, activeLearningStatistic,
        setExtensionRequestedCandidates, loadExtensionsRequests,
        extensionRequestedCandidates
    } = useContext(statsCtx);

    useEffect(() => {
        setSlug(scriptSlug);
    }, [scriptSlug]);

    useEffect(() => {
        setFilterCategory();
        fetchInvitationStats();
        loadCandidates();
        return () => cleanState();
    }, []);

    const onExtendRequestSuccess = () => {
        loadAssessmentStats();
        loadCandidates();
        fetchInvitationStats();
    };

    return (<>
        { statsLoading || invitationsLoading ? (
            <div className={classes.loaderWrapper}>
                <CircularProgress
                    color="primary"
                    className={classes.loaderBlock}
                />
            </div>
        ) : (
            <div className={classes.wrapper}>
                {totalCount
                    ? <>
                        <AssessmentStats
                            loading={statsLoading}
                            statsArr={statsArr}
                            audition={audition}
                            requestedMoreTimeCount={requestedMoreTimeCount}
                            activeLearningStatistic={activeLearningStatistic}
                            extensionRequestedCandidates={extensionRequestedCandidates}
                            setExtensionRequestedCandidates={setExtensionRequestedCandidates}
                            loadExtensionsRequests={loadExtensionsRequests}
                            onExtendRequestSuccess={onExtendRequestSuccess}
                        />
                        <InviteCandidatesList />
                      </>
                    : (
                        <NoInvitationsBoard
                            slug={scriptSlug}
                            audition={audition}
                        />
                    )}
            </div>
        ) }</>
    );
});

export default withStyles(styles)(withRouter(InviteCandidates));

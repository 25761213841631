export default theme => ({
    timerWrapper: {
        marginTop: 20,
        alignItems: 'center',
        display: 'flex'
    },
    timeLimitWrapper: {
        display: 'flex',
        paddingLeft: 10,
        fontWeight: 600,
        letterSpacing: '0.15px',
        alignItems: 'center',
        color: theme.palette.grey[600],
        '& > svg': {
            marginRight: 7
        },
        '&:nth-child(3)': {
            paddingLeft: 17,
            marginLeft: 15,
            borderLeft: `1px solid ${theme.palette.grey[300]}`
        }
    },
    timeLimitValue: {
        fontSize: 13,
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    timeLimitError: {
        color: theme.palette.yellow[900]
    }
});

export default theme => ({
    previewButton: {
        minWidth: 190,
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginBottom: 15,
            marginRight: 0
        }
    }
});

import React, { useEffect } from 'react';

import { withStyles } from '@mui/styles';

import ItemStatus from 'components/assessments_pages/ItemStatus';
import ItemSynchronizeStatus from 'components/assessments_pages/ItemSynchronizeStatus';
import styles from './styles';

const EditQuestion = ({ question, setQuestion, flashMessage, getAssessment }) => {
    const { synchronized, status } = question;

    useEffect(() => {
        if (getAssessment) getAssessment();
    }, [synchronized, status]);

    return (
        <>
            {
                question.status !== 'draft' && (
                    <ItemSynchronizeStatus
                        isInteractive
                        flashMessage={flashMessage}
                        uuid={question.uuid}
                        setQuestion={setQuestion}
                        synchronized={question.synchronized}
                    />
                )
            }
            <ItemStatus
                className="u-mrg--lx2"
                status={question.status}
                uuid={question.uuid}
                isInteractive
                setQuestion={setQuestion}
                flashMessage={flashMessage}
            />
        </>
    );
};

export default withStyles(styles)(EditQuestion);

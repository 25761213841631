export default theme => ({
    loaderWrapper: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center'
    },
    wrapper: {
        display: 'flex',
        backgroundColor: 'white',
        marginTop: 15,
        marginBottom: 36,
        width: '100%',
        fontSize: 14,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        minHeight: 85,
        paddingBottom: 25
    },
    content: {
        width: '100%',
        display: 'flex',
        overflowX: 'auto',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        position: 'relative'
    }
});

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'fixed',
        textAlign: 'right',
        padding: '14px 5px 17px 9px',
        right: 18,
        top: 160,
        borderTopLeftRadius: 13,
        width: 149,
        borderBottomLeftRadius: 13,
        transition: 'width 0.3s',
        zIndex: 1000,
        overflowX: 'hidden',
        '&:hover': {
            boxShadow: '0 2px 30px 0 rgba(0, 0, 0, 0.14)',
            width: 325,
            backgroundColor: '#fff'
        },
        '@media only screen and (max-width: 989px)': {
            display: 'none'
        }
    },
    wrapperAbsolute: {
        position: 'absolute',
        top: 100
    },
    overflowWrapper: {
        display: 'block',
        maxHeight: 'calc(100vh - 220px)',
        overflow: 'auto',
        overflowX: 'hidden',
        paddingRight: 20,
        textAlign: 'right'
    },
    stepperLabel: {
        fontSize: 12,
        color: '#3a3c43',
        lineHeight: 2.17,
        marginBottom: 16,
        paddingRight: 20
    },
    item: {
        color: 'black'
    }
});

export default useStyles;

import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { getListOfQuestions } from 'requests/CMSRequests';
import { appCtx } from 'components/appStore';
import SearchField from 'components/inputs/SearchField';
import NoResultsBanner
    from 'pages/assessment/edit_assessment/edit_questions/components/QuestionContent/common/NoResultsBanner';
import QuestionsList from '../../../../common/QuestionsList';
import EmptyQuestionsList from '../../../../common/EmptyQuestionsList';

let timerId = null;

const SavedQuestionContent = observer(({
    openDrawer, selectedSet, selectedSkill,
    getAssessment, setSelectedSet, ...other
}) => {
    const [loading, setLoading] = useState(false);
    const [questionsData, setQuestionsData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState('');

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        if (!selectedSkill) return;
        setLoading(true);
        setQuestionsData({});
        clearTimeout(timerId);
        setCurrentPage(1);
        getQuestionList();
    }, [query, selectedSkill]);

    useEffect(() => {
        getQuestionList();
    }, [currentPage]);

    const getQuestionList = () => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            loadQuestionList(query, currentPage);
        }, 300);
    };

    const loadQuestionList = (searchTerm = query, page = currentPage) => {
        if (!selectedSkill) return;
        setLoading(true);
        getListOfQuestions(searchTerm, selectedSkill.id, page)
            .then(({ success, data }) => {
                if (success && data) {
                    let newItems = data.items;
                    if (page !== 1) {
                        newItems = [...questionsData.items, ...data.items];
                    }
                    setQuestionsData({ ...data, items: newItems });
                }
            })
            .catch(({ response }) => {
                if (response && response.data && response.data.msg) {
                    const { data: { msg } } = response;
                    flashMessage(msg, 'error');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChangeSearch = (e) => {
        const { value } = e.target;
        setLoading(true);
        setQuery(value);
    };

    const handleNextButtonClick = () => {
        setQuestionsData({ ...questionsData, hasMorePages: false });
        setCurrentPage(currentPage + 1);
    };

    const questions = (questionsData && questionsData.items) ? questionsData.items : [];

    return (
        <>
            {
                (questions.length || loading || query) && (
                    <SearchField
                        margin="none"
                        className="u-mrg--tx0 u-mrg--bx0"
                        placeholder="Search Questions"
                        onChange={onChangeSearch}
                        value={query}
                    />
                )
            }
            <div>
                <QuestionsList
                    hideDuplicate
                    hideReorder
                    hideEdit
                    showDone
                    showOnFocus
                    loading={loading}
                    selectedSkill={selectedSkill}
                    questions={questions}
                    currentPage={currentPage}
                    hasMorePages={questionsData.hasMorePages}
                    loadMore={handleNextButtonClick}
                    loadQuestions={loadQuestionList}
                    selectedSet={selectedSet}
                    setCurrentPage={setCurrentPage}
                    {...other}
                />
                {
                    Boolean(!loading && (!questions || !questions.length)) && (
                        <>
                            { Boolean(selectedSet && !query) && <EmptyQuestionsList /> }
                            { Boolean(query) && (
                                <NoResultsBanner
                                    header={`We couldn't find a match for "${query}"`}
                                    text="Check your spelling or try a new search"
                                />
                            ) }
                        </>
                    )
                }
            </div>
        </>
    );
});

export default withRouter(SavedQuestionContent);

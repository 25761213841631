import React from 'react';

export default ({ question: { file }, NoAnswerComponent }) => (
    file ? (
        <p>
            {/* {answer} */}
            <br />
            <a href={process.env.REACT_APP_API_URL + file.fileUrl} className="c-download-file">Download file</a>
        </p>
    ) : <NoAnswerComponent />
);

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { withStyles, withTheme } from '@mui/styles';
import styles from './styles';

const SmartRecruitersIcon = withStyles(styles)(withTheme(({ theme, isActive, ...other }) => {
    const fillColor = isActive ? theme.palette.primary.main : '#A9AAAE';
    return (
        <SvgIcon
            width="149"
            height="28"
            viewBox="0 0 149 28"
            fill="none"
            {...other}
        >
            <path d="M9.54472 0.109101C10.091 0.0346056 10.6373 0.00977681 11.1588 0.0346086C11.4568 -0.015055 11.7547 -0.0150459 12.0527 0.0594495C15.3057 0.332599 18.2855 1.99633 20.1975 4.65333C21.762 6.81369 22.4076 9.52035 22.0351 12.1525C21.6626 14.4122 20.5949 16.4981 18.956 18.1121C17.3916 19.6765 16.3238 21.6631 15.852 23.8235C15.7775 24.4194 15.6285 24.9906 15.4298 25.5368C15.0325 26.2073 14.4117 26.7039 13.6668 26.9026C13.0708 27.0764 12.4749 27.2006 11.8541 27.2502C11.3078 27.2006 10.7615 27.2006 10.2152 27.2254C9.17224 27.2006 8.15414 26.8778 7.30986 26.2818C6.81322 25.8845 6.51524 25.2885 6.44074 24.6677C6.0931 22.2094 4.95084 19.9249 3.21261 18.1618C1.49922 16.4981 0.406621 14.3377 0.0838079 11.9787C0.00931255 11.5566 -0.01552 11.1344 0.0093118 10.7123C0.0093118 8.20426 0.903257 5.79559 2.54215 3.90838C4.33004 1.82251 6.83805 0.456746 9.54472 0.109101Z" fill={fillColor} />
            <path d="M10.7118 4.95163C11.9037 4.85231 13.0708 5.07579 14.1386 5.62209C14.8587 6.0194 15.3802 6.68987 15.554 7.50932C15.6285 7.93146 15.6285 8.37842 15.6037 8.80056C15.5788 8.94955 15.4547 9.07372 15.3057 9.07372H13.7661C13.642 9.07372 13.5178 8.99922 13.4681 8.87507C13.344 8.42809 13.5426 7.93146 13.3191 7.50932C13.1205 7.1865 12.8225 6.93818 12.45 6.83886C11.8044 6.66503 11.1339 6.61536 10.4635 6.68986C9.96685 6.68986 9.47021 6.86369 9.04807 7.16167C8.79976 7.38515 8.6756 7.68314 8.6756 8.00595C8.62593 8.30394 8.6756 8.60191 8.77492 8.89989C8.97358 9.24754 9.32122 9.49585 9.6937 9.59518C11.0098 10.067 12.3507 10.514 13.6668 10.9858C14.2876 11.2093 14.8587 11.5817 15.3057 12.0784C15.6037 12.4757 15.7775 12.9475 15.7775 13.4441V14.8595C15.7775 15.4803 15.5292 16.1011 15.107 16.5481C14.6849 16.995 14.1386 17.3179 13.5675 17.4917C12.6238 17.7897 11.6306 17.9138 10.6373 17.8393C9.51988 17.8393 8.45211 17.5662 7.48367 16.995C6.81321 16.5729 6.36624 15.8528 6.31658 15.0333C6.31658 14.7105 6.29175 14.3629 6.31658 14.0152C6.34141 13.7917 6.58973 13.7421 6.78838 13.7421H8.0548C8.22863 13.7173 8.40245 13.8414 8.42728 14.0152C8.50178 14.4622 8.32795 15.0085 8.65077 15.381C9.09774 15.8031 9.6937 16.0266 10.3145 16.0514C11.1091 16.1508 11.9286 16.0763 12.7232 15.828C13.1205 15.7038 13.4433 15.381 13.5426 14.9837C13.5675 14.636 13.5675 14.3132 13.5675 13.9407C13.5426 13.6924 13.4185 13.4441 13.2198 13.2703C12.9467 13.0716 12.6239 12.8978 12.301 12.8233C11.0346 12.3763 9.7682 11.9542 8.50178 11.5072C7.60783 11.2092 6.93737 10.514 6.61456 9.62002C6.51523 9.24754 6.4904 8.87507 6.51523 8.50259C6.4904 8.05562 6.51523 7.60864 6.63939 7.16167C6.88771 6.41671 7.43401 5.82075 8.15413 5.49793C8.92392 5.15029 9.81786 4.95163 10.7118 4.95163Z" fill={fillColor} />
            <path d="M30.7262 8.45255C31.9181 8.30356 33.11 8.42772 34.2275 8.82503C34.9972 9.09818 35.5932 9.69414 35.8912 10.4391C36.065 11.0847 36.1395 11.7552 36.0898 12.4256C36.1147 12.5995 36.0153 12.7733 35.8415 12.7981C35.8415 12.7981 35.8167 12.7981 35.7919 12.7981C35.3946 12.8229 34.9972 12.7981 34.5751 12.8229C34.4013 12.8478 34.2275 12.7485 34.153 12.5995C34.1778 12.1028 34.1281 11.631 34.0288 11.1344C33.8798 10.7122 33.557 10.4143 33.1349 10.2653C32.4892 10.0666 31.8188 10.017 31.1483 10.0666C30.602 10.0914 30.0806 10.2901 29.6584 10.6626C29.3604 11.035 29.2114 11.482 29.2611 11.9538C29.2363 12.3015 29.3108 12.6491 29.4846 12.9719C29.6832 13.2203 29.9316 13.4189 30.2295 13.5182C31.496 14.0149 32.7872 14.4867 34.0536 14.9833C34.7986 15.2316 35.4194 15.7283 35.8664 16.3739C36.1147 16.7712 36.214 17.243 36.1892 17.7148V19.4282C36.1892 20.198 35.8664 20.9181 35.3201 21.4396C34.5006 22.0604 33.5073 22.408 32.4644 22.4577C31.1732 22.6067 29.8819 22.408 28.7148 21.9114C28.0195 21.5886 27.498 21.0174 27.2497 20.2973C27.1256 19.7758 27.0759 19.2295 27.1007 18.6832C27.0759 18.4598 27.1007 18.2363 27.1256 18.0128C27.2001 17.7893 27.4732 17.7893 27.6719 17.7893C28.0443 17.8141 28.4168 17.8141 28.7893 17.8141C28.988 17.8141 29.0376 18.0376 29.0128 18.1866C29.0376 18.8571 28.8886 19.5772 29.3356 20.1235C29.8322 20.5953 30.4779 20.8436 31.1483 20.8188C31.9181 20.8933 32.7127 20.7939 33.4328 20.4711C33.8798 20.2228 34.153 19.7758 34.1778 19.2792V18.1369C34.1778 17.7396 33.9543 17.3672 33.6315 17.1188C33.259 16.8457 32.8369 16.647 32.3899 16.498C31.3966 16.1256 30.4282 15.7531 29.4598 15.3806C29.1866 15.2813 28.9135 15.1571 28.6403 15.033C27.8457 14.586 27.3242 13.7666 27.2746 12.8478V11.4572C27.2746 10.7122 27.5725 10.017 28.094 9.49549C28.839 8.8747 29.7577 8.50222 30.7262 8.45255Z" fill={fillColor} />
            <path d="M119.475 8.47761C119.723 8.45278 119.972 8.45278 120.195 8.47761C120.294 8.47761 120.369 8.57693 120.369 8.67626C120.369 8.70109 120.369 8.70109 120.369 8.72593V10.7373C120.344 10.9608 120.071 10.9111 119.897 10.9111C119.674 10.8863 119.351 10.9856 119.301 10.6876C119.276 10.0668 119.301 9.47089 119.276 8.82526C119.301 8.72593 119.301 8.50244 119.475 8.47761Z" fill={fillColor} />
            <path d="M84.4125 18.3606C84.1394 17.7398 83.7917 17.1935 83.5434 16.5478C83.9655 16.4733 84.3877 16.3244 84.785 16.1257C85.3313 15.8525 85.7534 15.3808 85.9521 14.7848C86.1011 14.0647 86.1507 13.3197 86.1011 12.5748C86.1756 11.7801 86.1011 11.0104 85.8776 10.2406C85.5548 9.61978 85.0085 9.14797 84.338 8.92448C83.2454 8.60167 82.1031 8.47752 80.9609 8.57684H77.9562C77.7824 8.55201 77.7328 8.72583 77.7328 8.84999V21.986C77.7328 22.1102 77.7079 22.2343 77.8569 22.284C78.0556 22.3088 78.2542 22.3336 78.4777 22.3088C78.6019 22.3336 78.7012 22.3088 78.8005 22.2343C78.8253 22.0605 78.8254 21.9115 78.8254 21.7377V16.6472C80.0421 16.5975 81.2589 16.6472 82.5253 16.6223C83.4441 18.4847 84.338 20.3471 85.2568 22.1847C85.3064 22.3585 85.5051 22.3088 85.6541 22.3088C85.9024 22.284 86.2004 22.3833 86.399 22.1847C85.7286 20.9431 85.0581 19.6518 84.4125 18.3606ZM80.5636 15.7532C79.9676 15.7284 79.3965 15.7781 78.8005 15.7284V9.57012H82.0286C82.6991 9.54528 83.3696 9.66943 84.0152 9.91775C84.487 10.1164 84.8346 10.5385 84.9836 11.0352C85.0581 11.6063 85.083 12.2023 85.0581 12.7734C85.1326 13.3942 85.0581 14.015 84.8346 14.611C84.5367 15.0579 84.1145 15.3808 83.5931 15.5297C82.575 15.7532 81.5568 15.8277 80.5636 15.7532Z" fill={fillColor} />
            <path d="M71.6738 9.99199C71.9221 9.94232 72.1953 9.91748 72.4436 9.91748C72.7416 9.91748 73.0395 9.91748 73.3375 9.96715C73.4369 10.1658 73.4617 10.4141 73.4369 10.6376V12.4007H75.6221C75.7959 12.4007 75.92 12.5497 75.8952 12.7235V12.7483V13.7168C75.8704 13.8906 75.7214 14.0147 75.5476 13.9899H73.4369V19.3784C73.412 19.7757 73.5859 20.1482 73.9087 20.3965C74.455 20.6696 75.0758 20.7938 75.6717 20.7193C75.8704 20.7193 76.0939 20.6696 76.1932 20.8931C76.2428 21.2159 76.2677 21.5388 76.2428 21.8616C76.2428 22.0602 76.2428 22.3334 75.9945 22.3582C75.1503 22.4079 74.3308 22.3582 73.5114 22.2092C72.9651 22.1099 72.4436 21.8367 72.0463 21.4394C71.649 20.9428 71.4503 20.322 71.4751 19.6764V13.9651C71.0778 13.9402 70.7054 13.9651 70.3081 13.9651C70.1342 13.9899 69.9852 13.8657 69.9852 13.6919C69.9852 13.6919 69.9852 13.6919 69.9852 13.6671C69.9604 13.3443 69.9604 13.0215 69.9852 12.6986C69.9852 12.5 70.1839 12.4503 70.3329 12.4255C70.7054 12.4007 71.0778 12.351 71.4503 12.3262V10.3396C71.5 10.2155 71.5248 10.0417 71.6738 9.99199Z" fill={fillColor} />
            <path d="M123.249 10.2403C123.498 10.2155 123.746 10.2155 123.97 10.2403C124.119 10.2651 124.119 10.4638 124.143 10.5631V12.649C124.988 12.7235 125.857 12.649 126.701 12.6986C126.8 12.9718 126.8 13.2698 126.676 13.5181C125.832 13.5926 124.988 13.4933 124.143 13.5678V20.1233C124.143 20.5207 124.317 20.8931 124.615 21.1663C125.037 21.4891 125.559 21.6629 126.105 21.6629C126.453 21.7126 126.8 21.6629 127.173 21.7126C127.297 21.7622 127.272 21.9112 127.272 21.9857C127.297 22.1844 127.272 22.383 127.223 22.5817C126.949 22.6065 126.651 22.6314 126.378 22.6065C125.658 22.6314 124.963 22.5072 124.292 22.2341C123.845 22.0354 123.473 21.6629 123.274 21.1911C123.125 20.62 123.051 20.024 123.1 19.4281V13.5678C122.629 13.5678 122.157 13.5926 121.685 13.5429C121.536 13.4436 121.586 13.2698 121.586 13.1208C121.586 12.9966 121.561 12.8228 121.735 12.7731C122.206 12.7235 122.653 12.6986 123.125 12.6738C123.15 11.9537 123.125 11.2087 123.125 10.4886C123.076 10.4141 123.076 10.3396 123.15 10.2651C123.175 10.2651 123.225 10.2403 123.249 10.2403Z" fill={fillColor} />
            <path d="M61.8652 14.6355C61.8901 13.9154 61.5921 13.2201 61.0706 12.7483C60.3008 12.2269 59.3572 11.9289 58.4136 11.9537C57.6438 11.904 56.8492 12.0034 56.1043 12.1772C55.4835 12.351 54.9372 12.7235 54.5647 13.2698C54.2419 13.8657 54.1177 14.5362 54.2419 15.2067C54.3164 15.4302 54.5895 15.4053 54.7882 15.4302C55.1607 15.455 55.5331 15.4302 55.9056 15.3557C56.1043 15.2563 56.0546 15.008 56.0794 14.8342C56.0298 14.4369 56.2036 14.0644 56.5264 13.8409C56.9734 13.5926 57.47 13.4684 57.9666 13.5181C58.5129 13.4684 59.0592 13.5926 59.5559 13.8161C59.8787 14.0396 60.0525 14.412 60.0525 14.8094V16.3986H56.7499C55.9801 16.3738 55.2351 16.6469 54.6888 17.1684C54.2667 17.6153 54.0184 18.2361 54.0432 18.8569C53.9936 19.4529 54.0432 20.0489 54.1922 20.6448C54.515 21.4394 55.2103 22.0106 56.0546 22.1844C57.2465 22.4079 58.4633 22.1844 59.4814 21.5388C59.7049 21.4146 59.8787 21.2656 60.1022 21.1663C60.1518 21.4643 60.0525 21.8616 60.3257 22.1099H61.6914C61.8901 22.1099 61.9646 21.9112 61.9646 21.7623C61.8901 19.4281 61.8652 17.0194 61.8652 14.6355ZM59.978 19.6764C59.2331 20.322 58.2646 20.6697 57.2714 20.6945C56.8244 20.769 56.3526 20.5952 56.0546 20.2723C55.8808 19.9495 55.8311 19.5522 55.8808 19.1798C55.8559 18.8569 55.9553 18.559 56.1291 18.3106C56.4022 18.0623 56.7747 17.9382 57.1472 17.963H60.0029C59.978 18.5341 59.978 19.1053 59.978 19.6764Z" fill={fillColor} />
            <path d="M65.8631 13.3693C66.5584 12.6492 67.4772 12.1774 68.4457 12.0035C68.6691 11.9539 68.9175 11.9539 69.1409 11.9787C69.2899 12.0035 69.4141 12.1277 69.4141 12.2767V13.3941C69.3893 13.5928 69.2154 13.7169 69.0168 13.6921C67.8745 13.7914 66.7819 14.2384 65.9128 14.9585V20.1732C65.9128 20.5208 65.9128 20.8436 65.888 21.2161C65.9128 21.4396 65.9128 21.6879 65.8631 21.9114C65.8383 22.0356 65.739 22.1349 65.6148 22.1349C65.1182 22.1597 64.6216 22.1349 64.1249 22.1349C64.0008 22.0604 63.9263 21.9363 63.9263 21.7873V12.5498C63.9263 12.4257 63.9511 12.3264 64.0008 12.227C64.1001 12.1774 64.2243 12.1525 64.3236 12.1277H65.4162C65.5652 12.1277 65.7142 12.227 65.739 12.3512C65.8135 12.6988 65.8383 13.0216 65.8631 13.3693Z" fill={fillColor} />
            <path d="M42.2233 12.1775C43.1917 11.9292 44.2098 12.0782 45.079 12.5748C45.4018 12.7983 45.6501 13.0963 45.8487 13.4439C46.5937 12.7486 47.5373 12.2768 48.5554 12.1278C49.4245 11.954 50.3185 12.103 51.0882 12.55C51.7339 12.9969 52.1312 13.7419 52.156 14.5365V21.8619C52.156 21.986 52.1312 22.0854 52.0815 22.1847C51.9822 22.2095 51.858 22.2343 51.7587 22.2592H50.6164C50.4923 22.284 50.3681 22.2095 50.2936 22.1102C50.2191 21.9364 50.1943 21.7625 50.2191 21.5887V15.9767C50.244 15.5546 50.2191 15.1076 50.1695 14.6855C50.0701 14.2385 49.7225 13.9157 49.2755 13.8412C48.1333 13.6674 46.9662 14.015 46.1219 14.8096C46.0971 17.1935 46.1219 19.5277 46.1219 21.9115C46.1219 22.0854 45.9977 22.2592 45.8239 22.2592H44.5078C44.334 22.2343 44.2098 22.1102 44.2098 21.9364V15.1573C44.2347 14.76 44.0857 14.3875 43.8125 14.0895C43.4152 13.8412 42.9434 13.7419 42.4965 13.8164C41.6025 13.8909 40.7831 14.2634 40.1126 14.8593V21.9364C40.1374 22.0854 40.0133 22.2343 39.8643 22.2592C39.8395 22.2592 39.8146 22.2592 39.8146 22.2592H38.5482C38.3744 22.284 38.2254 22.1599 38.2006 21.9612C38.2006 21.9364 38.2006 21.9115 38.2006 21.8867V12.5748C38.1757 12.401 38.275 12.252 38.4489 12.2272C38.4737 12.2272 38.5234 12.2272 38.5482 12.2272C38.9952 12.2023 39.4173 12.2271 39.8643 12.252C40.1623 12.4755 39.9884 12.9224 40.0878 13.2204C40.6589 12.699 41.4039 12.3265 42.2233 12.1775Z" fill={fillColor} />
            <path d="M94.3701 19.3539C94.0473 19.3042 93.7493 19.3042 93.4265 19.3539C93.3768 19.7015 93.4265 20.0243 93.352 20.3968C93.2775 20.7941 93.054 21.1169 92.6815 21.3156C92.0111 21.6136 91.2909 21.7377 90.5708 21.6632C89.95 21.6881 89.3292 21.5142 88.7829 21.1914C88.4353 20.8934 88.2366 20.4465 88.2615 19.9747C88.2615 19.1552 88.2366 18.3358 88.2615 17.5163H93.6748C93.8983 17.5412 94.1466 17.5163 94.3453 17.4418C94.3701 16.5231 94.3453 15.5794 94.3453 14.6607C94.3701 14.1392 94.1963 13.6177 93.8486 13.1956C93.501 12.7983 93.0292 12.55 92.5077 12.4506C91.6634 12.2768 90.7943 12.2023 89.95 12.2768C89.1802 12.3016 88.4353 12.5748 87.8393 13.0466C87.4172 13.4687 87.1689 14.0399 87.1689 14.6358V19.4035C87.1192 19.9002 87.1689 20.3968 87.293 20.8686C87.4917 21.4646 87.9387 21.9364 88.5346 22.1599C89.2547 22.4082 89.9997 22.5323 90.7695 22.5075C91.4896 22.5323 92.1849 22.433 92.8553 22.2095C93.3768 22.0357 93.8238 21.6881 94.0721 21.1914C94.3701 20.6203 94.4446 19.9747 94.3701 19.3539ZM88.2615 14.909C88.2366 14.5117 88.336 14.1392 88.5843 13.8164C89.0064 13.3942 89.5776 13.1708 90.1735 13.1459C90.9185 13.0714 91.6634 13.1459 92.3835 13.3694C92.8305 13.4936 93.1782 13.8412 93.3023 14.2882C93.3768 14.76 93.4016 15.2318 93.3768 15.7036C93.4016 16.0264 93.3768 16.3741 93.3272 16.6969C91.6386 16.672 89.95 16.6969 88.2366 16.6969C88.2615 16.0761 88.2615 15.505 88.2615 14.909Z" fill={fillColor} />
            <path d="M98.4425 12.5251C99.1378 12.4506 99.8331 12.4506 100.504 12.5251C101.273 12.5748 101.993 12.8976 102.515 13.4439C102.937 14.0647 103.086 14.8096 102.937 15.5298C102.788 15.6539 102.614 15.5794 102.465 15.6043C102.291 15.5794 102.118 15.6539 101.993 15.5298C101.919 15.3559 101.919 15.1573 101.944 14.9835C101.993 14.4868 101.77 14.015 101.348 13.7419C100.777 13.4191 100.131 13.2949 99.4606 13.3446C98.815 13.2949 98.1445 13.4191 97.5486 13.717C97.1264 13.9902 96.9029 14.4372 96.9029 14.9338V20.1981C96.8781 20.6948 97.1016 21.1418 97.5237 21.4149C98.1197 21.7129 98.7902 21.8619 99.4606 21.8122C100.156 21.8619 100.851 21.688 101.447 21.3404C101.82 21.0176 101.993 20.5458 101.944 20.074C101.969 19.8505 101.869 19.6022 102.018 19.4284C102.267 19.4035 102.54 19.3787 102.788 19.4035C102.887 19.4035 102.962 19.478 102.962 19.5525C102.962 19.5773 102.962 19.5773 102.962 19.6022C103.012 20.1236 102.962 20.6451 102.813 21.1417C102.639 21.6135 102.316 21.986 101.869 22.2343C101.249 22.5323 100.578 22.6813 99.8828 22.7061C99.0633 22.7806 98.2439 22.6813 97.4492 22.4827C96.9029 22.3337 96.456 21.986 96.158 21.4894C95.9345 21.0176 95.8352 20.4961 95.8848 19.9498V14.909C95.86 14.2882 96.0835 13.6922 96.5056 13.2452C97.0768 12.8479 97.7472 12.5748 98.4425 12.5251Z" fill={fillColor} />
            <path d="M105.47 13.9402C106.389 13.0711 107.605 12.5744 108.872 12.4999C109.095 12.4751 109.17 12.6986 109.12 12.8724C109.12 13.0959 109.195 13.4187 108.897 13.4684C107.63 13.5677 106.413 14.0644 105.445 14.9086V22.2588C105.445 22.383 105.47 22.532 105.346 22.6065C105.048 22.6313 104.725 22.6313 104.427 22.6065C104.377 22.3085 104.352 22.0354 104.377 21.7374V13.4436C104.352 13.1952 104.352 12.9469 104.427 12.7234C104.551 12.5993 104.725 12.6738 104.899 12.6489C105.073 12.6738 105.271 12.5993 105.371 12.7731C105.42 13.1704 105.445 13.5429 105.47 13.9402Z" fill={fillColor} />
            <path d="M134.871 19.5273C134.647 19.5025 134.449 19.5025 134.225 19.5273C134.002 19.577 134.076 19.8253 134.051 19.9743C134.126 20.4709 133.952 20.9675 133.604 21.34C133.058 21.6877 132.437 21.8863 131.792 21.8615C131.071 21.936 130.326 21.8118 129.656 21.5387C129.209 21.2904 128.936 20.8434 128.961 20.3467C128.936 19.4776 128.961 18.6085 128.961 17.7146H134.821C134.945 17.7394 135.069 17.6649 135.069 17.5408V17.5159C135.094 17.0193 135.069 16.5227 135.069 16.026C135.119 15.4052 135.069 14.7844 134.97 14.1636C134.821 13.6422 134.473 13.1952 134.002 12.9469C133.381 12.6489 132.685 12.4751 132.015 12.4751C131.345 12.4254 130.699 12.4751 130.028 12.5992C129.408 12.6986 128.837 12.9965 128.39 13.4435C128.042 13.8905 127.868 14.4616 127.893 15.0079V20.0488C127.868 20.5454 127.967 21.0669 128.191 21.5138C128.464 21.9608 128.886 22.2836 129.358 22.4574C130.177 22.7058 131.047 22.8051 131.916 22.7554C132.561 22.7306 133.182 22.6064 133.778 22.383C134.2 22.2091 134.548 21.9112 134.771 21.5138C135.02 20.9924 135.119 20.3964 135.045 19.8253C135.119 19.6763 135.02 19.5521 134.871 19.5273ZM128.961 15.0576C128.936 14.6851 129.06 14.3126 129.308 14.0146C129.656 13.6918 130.103 13.4932 130.575 13.4435C131.27 13.3442 131.965 13.369 132.661 13.4932C133.108 13.5677 133.505 13.816 133.778 14.1636C133.952 14.4616 134.026 14.8093 134.002 15.1569V16.8951H128.886C128.961 16.2992 128.961 15.6535 128.961 15.0576Z" fill={fillColor} />
            <path d="M137.9 13.9403C138.769 13.1457 139.887 12.6242 141.054 12.5C141.228 12.5 141.401 12.4504 141.526 12.5745C141.6 12.798 141.6 13.0215 141.575 13.245C141.575 13.3692 141.451 13.4933 141.327 13.4685C140.16 13.5678 139.018 14.0148 138.099 14.7349C138.024 14.8094 137.9 14.8591 137.925 14.9584V22.2838C137.925 22.3831 137.9 22.5072 137.826 22.5817C137.503 22.6066 137.205 22.5817 136.882 22.5569C136.832 22.3334 136.832 22.1099 136.857 21.8865V12.8229C136.832 12.5497 137.155 12.5994 137.354 12.5994C137.528 12.6242 137.751 12.5497 137.826 12.7235C137.875 13.1457 137.9 13.543 137.9 13.9403Z" fill={fillColor} />
            <path d="M143.463 12.7729C144.257 12.5246 145.101 12.4253 145.921 12.4998C146.591 12.5246 147.237 12.6984 147.833 12.9964C148.28 13.2447 148.603 13.6172 148.752 14.1138C148.851 14.5857 148.876 15.0575 148.826 15.5293C148.503 15.6038 148.181 15.6038 147.858 15.5293C147.883 15.1071 147.833 14.685 147.758 14.2877C147.585 13.8904 147.237 13.6172 146.815 13.5179C146.293 13.3689 145.747 13.3192 145.226 13.3192C144.63 13.2944 144.058 13.4434 143.537 13.7414C143.214 13.94 143.04 14.2877 143.016 14.6601V15.3554C143.04 15.7279 143.239 16.0756 143.562 16.2742C143.885 16.4729 144.232 16.6219 144.605 16.7212C145.35 16.9447 146.095 17.193 146.84 17.4165C147.411 17.5406 147.932 17.789 148.379 18.1366C148.702 18.4097 148.926 18.7822 149 19.2044V20.7439C148.95 21.1909 148.752 21.6131 148.429 21.911C147.634 22.507 146.641 22.805 145.623 22.7553C144.828 22.805 144.034 22.6808 143.289 22.4325C142.742 22.2587 142.295 21.8862 142.047 21.3647C141.873 20.9178 141.824 20.4211 141.873 19.9245C141.898 19.701 142.122 19.7755 142.271 19.7507C142.444 19.7755 142.643 19.701 142.792 19.8252C142.867 20.1231 142.792 20.446 142.867 20.7439C142.966 21.1412 143.239 21.4641 143.612 21.613C144.307 21.8862 145.052 21.9855 145.772 21.911C146.343 21.911 146.889 21.762 147.386 21.4889C147.709 21.3151 147.932 20.9674 147.957 20.5949C147.982 20.2721 147.957 19.9245 147.957 19.5768C147.932 19.254 147.758 18.9312 147.485 18.7574C147.113 18.5339 146.691 18.3601 146.269 18.2359C145.375 17.9628 144.505 17.6648 143.612 17.3916C142.718 17.1682 142.072 16.3984 141.973 15.4796C141.898 14.8836 141.973 14.2877 142.171 13.7414C142.494 13.3192 142.941 12.9467 143.463 12.7729Z" fill={fillColor} />
            <path d="M110.089 12.6738C110.387 12.649 110.709 12.6241 111.007 12.649C111.082 12.8476 111.107 13.0959 111.082 13.3194V20.0985C111.057 20.5455 111.206 20.9676 111.479 21.3153C111.827 21.6381 112.274 21.8119 112.746 21.8367C113.689 21.8864 114.633 21.6629 115.452 21.1663C115.676 20.9676 116.023 20.8683 116.123 20.5703V12.9718C116.123 12.8725 116.098 12.7483 116.222 12.7235C116.47 12.6986 116.743 12.6986 116.992 12.7235C117.141 12.7235 117.166 12.8973 117.166 12.9966V22.383C117.166 22.4823 117.141 22.5817 117.091 22.681C116.793 22.7058 116.52 22.681 116.222 22.6562C116.172 22.6065 116.148 22.532 116.123 22.4327C116.098 22.1844 116.098 21.9112 116.098 21.6877C115.775 21.8864 115.452 22.1347 115.105 22.3085C114.335 22.7058 113.491 22.8797 112.646 22.8548C111.951 22.8797 111.256 22.6562 110.685 22.234C110.287 21.8864 110.039 21.4146 109.989 20.8931C109.94 20.5703 109.94 20.2475 109.94 19.8998V13.0711C109.964 12.9221 110.014 12.798 110.089 12.6738Z" fill={fillColor} />
            <path d="M119.376 12.6739C119.674 12.6491 119.996 12.6491 120.294 12.6739C120.344 12.7484 120.369 12.8477 120.369 12.947V22.3335C120.369 22.4328 120.369 22.5569 120.27 22.6066C119.972 22.6314 119.649 22.6314 119.351 22.6066C119.301 22.3086 119.276 22.0355 119.276 21.7375V13.5678C119.276 13.2699 119.301 12.9719 119.376 12.6739Z" fill={fillColor} />
        </SvgIcon>
    );
}));

export default SmartRecruitersIcon;

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'libraries/Tooltip';
import { toggleAssessmentNotifications } from 'requests/AssessmentRequests';
import NotificationIcon from './notificationIcon';

import { appCtx } from '../../appStore';

const styles = {
    bellIcon: {
        padding: 6
    },
    tooltip: {
        fontSize: 12,
        textAlign: 'center',
        maxWidth: 210
    }
};

const NotificationButton = observer(({
    enabled, classes, disabled, slug, onNotificationToggle,
    className = ''
}) => {
    const [active, setActive] = useState(enabled && !disabled);
    const { flashMessage } = useContext(appCtx);
    const onClickButton = (e) => {
        e.stopPropagation();
        assessmentNotificationsActivate();
    };

    const assessmentNotificationsActivate = async () => {
        try {
            const response = await toggleAssessmentNotifications(slug, !active);
            const { success, data } = response;
            if (success && data) {
                const { notificationsEnabled } = data;
                setActive(notificationsEnabled);
                if (onNotificationToggle) onNotificationToggle(notificationsEnabled);
                flashMessage(`Assessment notifications ${notificationsEnabled ? 'activated' : 'deactivated'}`, 'done');
            }
        } catch (err) {
            flashMessage('Something went wrong');
        }
    };

    return (
        <div className={className}>
            {
                !disabled ? (
                    <>
                        <Tooltip
                            tooltipClass={classes.tooltip}
                            label={<>Turn <b>{ active ? 'OFF' : 'ON'}</b> candidate activity notification emails</>}
                        >
                            <IconButton onClick={onClickButton} className={classes.bellIcon} size="large">
                                <NotificationIcon active={active} />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : (
                    <IconButton disabled className={classes.bellIcon} size="large">
                        <NotificationIcon active={!disabled} />
                    </IconButton>
                )
            }
        </div>
    );
});

export default withStyles(styles)(NotificationButton);

import React from 'react';
import { withStyles } from '@mui/styles';
import ImmersiveDialog from 'components/dialogs/ImmersiveDialog';

import { GOOGLE_DOC, PRESENTATION, SPREADSHEET, SPREADSHEET_AUTO_GRADED, EXCEL_AUTO_GRADED } from 'helper/constants';

import GoogleDocImg from 'img/previewImmersive/document/document.svg';
import PresentationImg from 'img/previewImmersive/presentation/presentation.svg';
import SpreadsheetImg from 'img/previewImmersive/spreadsheet/spreadsheet.svg';

import ErrorIcon from 'img/candidateCard/errorIcon.svg';
import TestCase from './TestCase';

import styles from './styles';

class GoogleQuestion extends React.Component {
    state = {
        dialogOpened: false
    };

    toggleDialogOpen = () => {
        const { dialogOpened } = this.state;
        this.setState({ dialogOpened: !dialogOpened });
    };

    returnCorrectImage = () => {
        const { question } = this.props;
        const { answerType } = question;
        switch (answerType) {
            case GOOGLE_DOC:
                return (
                    <img
                        alt="immersive_preview"
                        src={GoogleDocImg}
                    />
                );
            case PRESENTATION:
                return (
                    <img
                        alt="immersive_preview"
                        src={PresentationImg}
                    />
                );
            case SPREADSHEET:
            case SPREADSHEET_AUTO_GRADED:
            case EXCEL_AUTO_GRADED:
                return (
                    <img
                        alt="immersive_preview"
                        src={SpreadsheetImg}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        const { dialogOpened } = this.state;
        const { question, questionNumber, setGradeQuestion, candidate,
            audition, userName, propPublic, updateUserAuditionAndBox,
            canGrade, isIntegration, classes, currentQuestion
        } = this.props;
        const { openedExternalFile, answerType, spreadsheetTestCasesResults, googleDriveFile, oneDriveFile } = question;

        const returnSpreadsheetOnlyPreviewLink = () => {
            if ((answerType === SPREADSHEET || answerType === SPREADSHEET_AUTO_GRADED)
                && googleDriveFile && googleDriveFile.viewLink) {
                return `${googleDriveFile.viewLink}&rm=embedded`;
            }
            if (answerType === EXCEL_AUTO_GRADED) return oneDriveFile?.previewUrl;
        };

        return (
            <div>
                {
                    !openedExternalFile && (
                        <div className={classes.error}>
                            <img src={ErrorIcon} alt="error_icon" />
                            No answer was provided by the candidate
                        </div>
                    )
                }
                <div
                    role="presentation"
                    onClick={this.toggleDialogOpen}
                    className={classes.immersiveWrapper}
                >
                    {this.returnCorrectImage()}
                </div>
                {(answerType === SPREADSHEET_AUTO_GRADED || answerType === EXCEL_AUTO_GRADED)
                    && openedExternalFile
                    && spreadsheetTestCasesResults.some(({ testPassed }) => testPassed !== null)
                    && (
                        <div className="u-mrg--tx4">
                            <div className={classes.tableHead}>
                                {['Test Case', 'Actual Value', 'Correct Value', 'Result'].map(cell => <div className={classes.cell}>{cell}</div>)}
                            </div>
                            {spreadsheetTestCasesResults.map(item => (
                                <TestCase
                                    isIntegration={isIntegration}
                                    updateUserAuditionAndBox={updateUserAuditionAndBox}
                                    propPublic={propPublic}
                                    candidate={candidate}
                                    audition={audition}
                                    setGradeQuestion={setGradeQuestion}
                                    currentQuestion={currentQuestion}
                                    item={item}
                                    canGrade={canGrade}
                                    key={item.id}
                                />
                            ))}
                        </div>
                    )}
                <ImmersiveDialog
                    onClose={this.toggleDialogOpen}
                    open={dialogOpened}
                    userName={userName}
                    question={question}
                    questionNumber={questionNumber}
                    previewLink={returnSpreadsheetOnlyPreviewLink()}
                />
            </div>
        );
    }
}

export default withStyles(styles)(GoogleQuestion);

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: props => props.backgroundColor,
        padding: '20px 20px 18px 25px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    auxiliaryText: {
        color: theme.palette.grey[900],
        fontSize: 14,
        fontWeight: 600
    },
    statusLabel: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 800,
        fontSize: 20,
        color: theme.palette.grey[900]
    },
    mainLabel: {
        marginTop: 2
    }
}));

export default useStyles;

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { withRouter } from 'react-router-dom';
import { removeMember } from 'requests/SettingsRequests';
import { appCtx } from 'components/appStore';

import TeamEvents from 'events/SettingsEvents';
import RemoveMemberDialog from './RemoveMemberDialog';
import ActiveMemberActions from './ActiveMemberActions';

const styles = theme => ({
    cancelInvitation: {
        fontWeight: 600,
        color: theme.palette.grey[500],
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

const RoleActions = observer(({ classes, user, loadTeamMembers }) => {
    const { flashMessage } = useContext(appCtx);
    const [removeDialog, setRemoveDialog] = useState(false);
    const [removing, setRemoving] = useState(false);
    const { invited, email, roleType, uuid } = user;

    const handleDialog = () => {
        setRemoveDialog(!removeDialog);
    };

    const onRemove = () => {
        setRemoving(true);
        removeMember(email)
            .then(() => {
                setRemoveDialog(false);
                loadTeamMembers();
                flashMessage(invited ? 'Invitation cancelled' : 'Team member was removed', 'done');
                if (invited) {
                    TeamEvents.TEAM_MEMBER_INVITATION_CANCELLED({ userId: uuid, roleType });
                } else {
                    TeamEvents.TEAM_MEMBER_REMOVED({ userId: uuid, roleType });
                }
                setRemoving(false);
            })
            .catch(() => {
                setRemoving(false);
            });
    };

    return (
        <>
            {
                invited ? (
                    <Button
                        className={classes.cancelInvitation}
                        onClick={handleDialog}
                    >
                        Cancel Invitation
                    </Button>
                ) : (
                    <ActiveMemberActions
                        user={user}
                        loadTeamMembers={loadTeamMembers}
                        handleDialog={handleDialog}
                    />
                )
            }
            <RemoveMemberDialog
                user={user}
                open={removeDialog}
                onRemove={onRemove}
                removing={removing}
                onClose={handleDialog}
            />
        </>
    );
});

export default withRouter(withStyles(styles)(RoleActions));

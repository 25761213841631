import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { cloneChildrenWithNewProps } from 'helper/commonFunctions';

import PanelHeader from '../asessment_settings_components/SectionHeader';

import styles from './styles';

const AssessmentSettingsWrapper = ({
    classes, setLoading, setSaving, isCMS,
    leftPanel = null, rightPanel = null, loadAudition,
    saveAssessmentSettings, loadAssessmentSettings
}) => {
    const [settings, setSettings] = useState(null);
    const [audition, setAudition] = useState(null);

    useEffect(() => {
        getAudition();
        loadEditScript();
    }, []);

    const getAudition = () => {
        loadAudition()
            .then(({ success, data }) => {
                if (success && data) {
                    setAudition(data);
                }
            });
    };

    const loadEditScript = () => {
        setLoading(true);
        loadAssessmentSettings()
            .then(({ data, success }) => {
                if (success) {
                    setSettings(data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });
    };

    const changeAssessmentSettings = (form) => {
        setSaving(true);
        return saveAssessmentSettings(form)
            .then((response) => {
                const { success, data } = response;
                if (success && data) {
                    setSettings(data);
                }
                setSaving(false);
                return response;
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const childProps = {
        isCMS,
        changeAssessmentSettings,
        setSaving,
        audition,
        setAudition,
        settings
    };

    if (!settings || !audition) return null;

    return (
        <>
            <div className={classes.leftPanel}>
                <PanelHeader>Assessment Settings</PanelHeader>
                {Boolean(leftPanel) && cloneChildrenWithNewProps(leftPanel.props.children, childProps)}
            </div>
            <div className={classes.contentPanel}>
                <PanelHeader>Assessment Details</PanelHeader>
                {Boolean(rightPanel) && cloneChildrenWithNewProps(rightPanel.props.children, childProps)}
            </div>
        </>
    );
};

export default withStyles(styles)(AssessmentSettingsWrapper);

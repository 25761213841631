import { action, observable } from 'mobx';
import { loadAssessmentFilters } from 'requests/ScriptRequests';
import { createContext } from 'react';


export class AssessmentTagsListStore {
    @observable allFilterCount = null;

    @observable openFilterCount = null;

    @observable closedFilterCount = null;

    @observable assessmentsFilter = '';

    @action getAssessmentFilterNumbers = () => {
        loadAssessmentFilters()
            .then(({ data, success }) => {
                if (success && data) {
                    this.allFilterCount = data.all;
                    this.openFilterCount = data.open;
                    this.closedFilterCount = data.closed;
                }
            });
    }

    @action setAssessmentsFilter = (newValue) => {
        this.assessmentsFilter = newValue;
        localStorage.setItem('assessmentsFilter', newValue);
    }

    @action cleanState = () => {
        this.allFilterCount = null;
        this.openFilterCount = null;
        this.closedFilterCount = null;
        this.assessmentsFilter = '';
    }
}

export const assessmentTagsListStore = new AssessmentTagsListStore();
export const AssessmentTagsListCtx = createContext(assessmentTagsListStore);

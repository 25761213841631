import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import { deleteAllAssessmentsFromJobAdder } from 'requests/SubscriptionRequests';

const styles = () => ({
    loader: {
        color: '#fff',
        '& svg': {
            margin: 0
        }
    }
});

const RemoveJobAdderDialog = ({
    open, onClose, flashMessage, classes
}) => {
    const [loading, setLoading] = useState(false);

    const resetIntegration = () => {
        setLoading(true);
        deleteAllAssessmentsFromJobAdder()
            .then(() => {
                flashMessage('JobAdder integration has been reset', 'done');
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                onClose();
                setLoading(false);
            });
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClick={e => e.stopPropagation()}
            onClose={onClose}
            titleComponent="Reset JobAdder Integration"
            handleClose={onClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ width: 120 }}
                        onClick={resetIntegration}
                        disabled={loading}
                        className="u-pdn--lx5 u-pdn--rx5"
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : 'Remove'
                        }
                    </Button>
                </>
            )}
        >
            With reset JobAdder will stop receiving candidate updates. All assessments will be removed, but the integration will remain enabled.
        </Dialog>
    );
}

export default withStyles(styles)(RemoveJobAdderDialog);

const styles = () => ({
    boardWrapper: {
        zIndex: '1',
        position: 'relative',
        margin: '30px auto 0',
        width: '100%',
        paddingBottom: '30px',
        minHeight: '450px'
    },
    jobCount: {
        fontWeight: 600,
        marginBottom: 32
    },
    infiniteScroll: {
        marginBottom: 50,
        textAlign: 'center'
    },
    searchField: {
        marginTop: 33,
        marginBottom: 24
    },
    mansoryWrapper: {
        border: '1px solid #EAECED',
        borderRadius: 4,
        backgroundColor: 'white',
        padding: '30px 15px 0',
        margin: '0 0 30px',
        '& > div': {
            margin: '0 15px 30px'
        }
    },
    helpFooter: {
        maxWidth: 1137
    },
    button: {
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '21px',
        textAlign: 'center',
        letterSpacing: '0.2px',
        color: '#FFFFFF',
        padding: '7px 24px',
        boxShadow: 'none'
    },
    searchLoaderWrapper: {
        height: 160,
        textAlign: 'center',
        position: 'relative'
    }
});

export default styles;

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from 'libraries/Checkbox';
import { appCtx } from 'components/appStore';

import IncludeShareLinkCheckbox from './IncludeShareLinkCheckbox';
import ExportQuestionsAndAnswersCheckbox from './ExportQuestionsAndAnswersCheckbox';
import IncludeRankCheckbox from './IncludeRankCheckbox';
import { exportSettingsFormCtx } from './store';


const styles = theme => ({
    checkboxLabel: {
        fontSize: 14,
        color: '#3a3c43'
    },
    talkToUsLabel: {
        display: 'block',
        textDecoration: 'underline',
        marginTop: 12,
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: 0.2,
        color: theme.palette.primary.main
    }
});

const ExportUsers = observer(({
    classes, hideExportQuestions = false, disableExportQuestions,
    disabledExportScoring, canIncludeRank
}) => {
    const { company } = useContext(appCtx);
    const {
        excludeExported, exportQuestions,
        includeShareLink, enabledScoring,
        includeRank, setExcludeExported,
        setExportQuestions, setIncludeShareLink,
        setEnabledScoring, setIncludeRank
    } = useContext(exportSettingsFormCtx);

    const onChangeExcludeExported = () => {
        setExcludeExported(!excludeExported);
    };

    const onChangeExportQuestions = () => {
        setExportQuestions(!exportQuestions);
    };

    const onChangeIncludeShareLink = () => {
        setIncludeShareLink(!includeShareLink);
    };

    const onChangeIncludeRank = () => {
        setIncludeRank(!includeRank);
    };

    const handleChangeSwitch = () => {
        if (!enabledScoring && !includeShareLink) {
            setIncludeShareLink(true);
        }
        setEnabledScoring(!enabledScoring);
    };

    return (
        <FormGroup>
            <Checkbox
                label="Exclude previously exported candidates"
                name="exclude"
                checked={excludeExported}
                styles={{ label: classes.checkboxLabel }}
                value="exclude"
                onChange={onChangeExcludeExported}
                helperText="Use this to avoid duplicates when importing"
            />
            <IncludeShareLinkCheckbox
                checked={includeShareLink}
                disabledExportScoring={disabledExportScoring}
                enabledScoring={enabledScoring}
                onChange={onChangeIncludeShareLink}
                handleChangeSwitch={handleChangeSwitch}
            />
            {
                !hideExportQuestions && (
                    <ExportQuestionsAndAnswersCheckbox
                        checked={exportQuestions}
                        classes={classes}
                        disableExportQuestions={disableExportQuestions}
                        company={company}
                        onChange={onChangeExportQuestions}
                    />
                )
            }
            {
                canIncludeRank && (
                    <IncludeRankCheckbox
                        checked={includeRank}
                        onChange={onChangeIncludeRank}
                    />
                )
            }
        </FormGroup>
    );
});

export default withStyles(styles)(ExportUsers);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        width: 435,
        minWidth: 435,
        borderRight: `2px solid ${theme.palette.grey[200]}`
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto'
    },
    scrolledContainer: {
        overflowY: 'auto',
        maxHeight: 180
    }
}));

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    contentWrapper: {
        width: '100%',
        height: '100%',
        minWidth: 1000,
        overflow: 'auto hidden'
    },
    content: {
        display: 'flex',
        maxHeight: 'calc(100% - 76px)'
    }
}));

import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import { SINGLE_CHOICE_AUTO_GRADED } from 'helper/constants';

import styles from './styles';

const TagsList = ({ classes, options, handleRemove }) => {
    const tag = item => (
        <div key={item} className={classes.tag}>
            <TruncateWithTooltip
                capitalize
                text={
                    Number(item) !== -1
                        ? getQuestionTypeInfo(item, (Icon, label, labelShort = label) => (
                            Number(item) === SINGLE_CHOICE_AUTO_GRADED
                                ? 'Single Choice'
                                : labelShort
                        ))
                        : 'No type'
                }
                width={130}
            />
            <IconButton
                className={classes.closeButton}
                onClick={() => handleRemove(item)}
                size="large"
            >
                <CloseIcon className={classes.closeIcon} />
            </IconButton>
        </div>
    );

    if (!options) return null;

    return (
        <div>
            { Object.keys(options).map(item => tag(item)) }
        </div>
    );
};

export default withStyles(styles)(TagsList);

import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { getAutoRejectSettings, setAutoRejectSettings } from 'requests/ScriptRequests';
import TextInput from 'libraries/TextInput';
import { appCtx } from 'components/appStore';

import AssessmentEvents from 'events/AssessmentEvents';
import RejectionEmailFooter from 'pages/assessment/components/RejectionEmailFooter';
import { checkHasCompanyFeature } from 'helper/commonFunctions';

import EditPanelSection from '../EditPanelSection';

import styles from '../styles';

let timedIdForPercentage = null;

const AutoRejectSettings = observer(({ classes, audition, setSaving }) => {
    const { flashMessage, company } = useContext(appCtx);

    const hasAutoReject = checkHasCompanyFeature(company, 'AUTO_REJECT');

    const { slug, active, pureAutoGrade, name, uuid } = audition;

    const [autoReject, setAutoReject] = useState(false);
    const [autoRejectPercentage, setAutoRejectPercentage] = useState(25);

    const hide = !hasAutoReject || !active || !pureAutoGrade;

    useEffect(() => {
        if (hide) return;
        getAutoRejectSettings(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setNewData(data);
                }
            });
    }, [slug]);

    const setNewData = (data) => {
        const {
            autoReject: newAutoReject,
            autoRejectPercentage: newPercentage
        } = data;
        setAutoReject(newAutoReject);
        setAutoRejectPercentage(newPercentage === null ? 25 : newPercentage);
    };

    const saveNewAutoReject = (newAutoReject) => {
        setSaving(true);
        setAutoRejectSettings(slug, {
            autoReject: newAutoReject,
            autoRejectPercentage
        })
            .then(({ data, success }) => {
                if (data && success) {
                    setAutoReject(newAutoReject);
                    flashMessage(`Auto Reject has been ${newAutoReject ? 'enabled.' : 'disabled.'}`, 'done');
                }
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
                flashMessage('Something went wrong.');
            });
    };

    const saveNewAutoRejectPercentage = (newAutoRejectPercentage) => {
        setSaving(true);
        setAutoRejectSettings(slug, {
            autoReject,
            autoRejectPercentage: newAutoRejectPercentage
        })
            .then(({ data, success }) => {
                if (data && success && autoReject) {
                    flashMessage(`Candidates with score below ${newAutoRejectPercentage}% will be Automatically Rejected.`, 'done');
                }
                setSaving(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    flashMessage('Cannot set Auto Reject condition for 100% score.');
                } else {
                    flashMessage('Something went wrong.');
                }
                setSaving(false);
            });
    };

    const handleChangeSwitch = (e) => {
        const { checked } = e.target;
        saveNewAutoReject(checked);

        AssessmentEvents.AUTOREJECT_ENABLED({
            enabled: checked,
            ttUUID: uuid,
            ttName: name
        });
    };

    const handleChangeRejectionScore = (e) => {
        const { value } = e.target;
        let newValue = parseInt(value, 10);
        if (Number.isNaN(newValue) || newValue <= 0) {
            newValue = 0;
        } else if (newValue > 100) {
            newValue = 100;
        }
        setAutoRejectPercentage(newValue);
        clearTimeout(timedIdForPercentage);
        timedIdForPercentage = setTimeout(() => {
            saveNewAutoRejectPercentage(newValue);
        }, 1000);
    };

    if (hide) return null;

    return (
        <EditPanelSection
            header={(
                <>
                    Auto Reject
                    <Switch
                        className={classes.switch}
                        checked={autoReject}
                        onChange={handleChangeSwitch}
                    />
                </>
            )}
        >
            <p>Automatically reject candidates from the process based on these conditions.</p>
            <Typography variant="h6" className={classes.text}>Condition</Typography>
            <div className={classes.condition}>
                <span className={classes.conditionLabel}>IF</span>
                <span>candidate score is</span>
                <span className={clsx(classes.conditionLabel, classes.redLabel)}>Less Than</span>
                <TextInput
                    disabled={!autoReject}
                    type="number"
                    min="0"
                    max="100"
                    label="Enter score"
                    variant="outlined"
                    value={autoRejectPercentage}
                    className={classes.percentageInput}
                    onChange={handleChangeRejectionScore}
                />
                <span className={classes.conditionLabel}>Then</span>
                <span>Automatically Reject</span>
            </div>
            <RejectionEmailFooter audition={audition} />
        </EditPanelSection>
    );
});

export default withStyles(styles)(AutoRejectSettings);

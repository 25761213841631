import React, { useState } from 'react';
import nl2br from 'react-nl2br';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';

import EmptyState from '../EmptyState';

import useStyles from './styles';

const CorrectAnswerContent = observer(({ exampleAnswers }) => {
    const classes = useStyles({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    if (!exampleAnswers || !exampleAnswers.length) {
        return <EmptyState header="correct answers" />;
    }

    if (exampleAnswers.length === 1) {
        return (
            <div>{nl2br(exampleAnswers[0])}</div>
        );
    }

    const handleGoPrevious = () => {
        const nextIndex = currentQuestionIndex - 1;
        if (nextIndex >= 0) {
            setCurrentQuestionIndex(nextIndex);
        }
    };

    const handleGoNext = () => {
        const nextIndex = currentQuestionIndex + 1;
        if (nextIndex <= exampleAnswers.length - 1) {
            setCurrentQuestionIndex(nextIndex);
        }
    };

    return (
        <div>
            <div className={classes.navWrapper}>
                <IconButton
                    onClick={handleGoPrevious}
                    disabled={!currentQuestionIndex}
                    className={classes.iconButton}
                    size="large"
                >
                    <NavigateBeforeIcon />
                </IconButton>
                <div className={classes.navLabel}>{currentQuestionIndex + 1} / {exampleAnswers.length}</div>
                <IconButton
                    onClick={handleGoNext}
                    disabled={currentQuestionIndex === exampleAnswers.length - 1}
                    className={classes.iconButton}
                    size="large"
                >
                    <NavigateNextIcon />
                </IconButton>
            </div>
            <div>
                {nl2br(exampleAnswers[currentQuestionIndex])}
            </div>
        </div>
    );
});

export default CorrectAnswerContent;

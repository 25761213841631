import React from 'react';


const BookmarkEmptyIcon = ({ color = '#6B6B70', ...other }) => (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M19 22L12 16.4444L5 22V5.22222C5 4.63285 5.21071 4.06762 5.58579 3.65087C5.96086 3.23413 6.46957 3 7 3H17C17.5304 3 18.0391 3.23413 18.4142 3.65087C18.7893 4.06762 19 4.63285 19 5.22222V22Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default BookmarkEmptyIcon;

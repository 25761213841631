import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import CandidatePhoto from './CandidatePhoto';
import AvatarSvg from './avatar.svg';

import stylesJS from './styles';

const BoardItemPhotos = ({ classes, users, cutLength = 3, extended, ...other }) => {
    let restOfEmployers = 0;
    let employerInvolved = false;
    if (users && users.length) {
        const shownCandidates = users.slice(0, cutLength);
        restOfEmployers = users.length - cutLength;
        employerInvolved = shownCandidates.map((item, index) => (
            item ? (
                <CandidatePhoto
                    className={clsx(classes.cardJudgeCommon, classes.cardJudge, 'candidate-photo')}
                    key={`${item.id}_${index}`}
                    rounded
                    user={item}
                />
            ) : null
        ));
    }
    if (!employerInvolved) return null;

    return (
        <div className={classes.cardJudges} {...other}>
            {employerInvolved}
            {
                restOfEmployers > 0 && (
                    extended ? (
                        <div className={clsx(classes.cardJudgeRestNew)}>
                            <img src={AvatarSvg} alt="avatar" width={16} />
                            + Team
                        </div>
                    ) : (
                        <div className={clsx(classes.cardJudgeCommon, classes.cardJudgeRest)}>
                            +{restOfEmployers}
                        </div>
                    )
                )
            }
        </div>
    );
};

export default withStyles(stylesJS)(withRouter(BoardItemPhotos));

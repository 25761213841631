import React from 'react';


const UploadIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.33437 3.26641C6.56118 3.26641 5.93438 3.89321 5.93438 4.6664V27.3331C5.93438 28.1063 6.56118 28.7331 7.33437 28.7331H11.3344C11.6657 28.7331 11.9344 29.0017 11.9344 29.3331C11.9344 29.6644 11.6657 29.9331 11.3344 29.9331H7.33437C5.89843 29.9331 4.73438 28.769 4.73438 27.3331V4.6664C4.73438 3.23046 5.89844 2.06641 7.33437 2.06641H20.9425C21.6118 2.06641 22.2553 2.32449 22.7391 2.78694L26.4643 6.34779C26.9774 6.83833 27.2677 7.51736 27.2677 8.22726V27.3331C27.2677 28.769 26.1036 29.9331 24.6677 29.9331H20.6677C20.3363 29.9331 20.0677 29.6644 20.0677 29.3331C20.0677 29.0017 20.3363 28.7331 20.6677 28.7331H24.6677C25.4409 28.7331 26.0677 28.1063 26.0677 27.3331V8.22726C26.0677 7.84501 25.9114 7.47937 25.6351 7.21524L21.9099 3.65439C21.6494 3.40537 21.3029 3.26641 20.9425 3.26641H7.33437Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.006 14.0674C16.1938 14.0674 16.3708 14.1553 16.4843 14.3051L20.4918 19.595C20.6919 19.8592 20.64 20.2355 20.3759 20.4356C20.1117 20.6357 19.7354 20.5838 19.5353 20.3196L16.606 16.453L16.606 31.3341C16.606 31.6654 16.3374 31.9341 16.006 31.9341C15.6746 31.9341 15.406 31.6654 15.406 31.3341L15.406 16.453L12.4767 20.3196C12.2766 20.5838 11.9003 20.6357 11.6362 20.4356C11.372 20.2355 11.3201 19.8592 11.5202 19.595L15.5278 14.3051C15.6412 14.1553 15.8182 14.0674 16.006 14.0674Z" fill={color} />
    </svg>

);

export default UploadIcon;

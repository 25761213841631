import React, { useState } from 'react';

// material components
import TextInput from 'libraries/TextInput';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from 'img/remove.svg';
import clsx from 'clsx';
import SheetNameSelect from './SheetNameSelect';

import CheckButton from '../CheckButton';
import styles from './styles';
import CorrectAnswersMenu from './CorrectAnswersMenu';

const SpreadsheetTestCase = ({
    question, classes, onChange,
    testCase, testCases, removeTestCase,
    row, sheetNames, sheetRange, isCMS
}) => {
    const [actualResult, setActualResult] = useState(null);

    const renderValue = (name) => {
        if (!testCase || (!testCase[name] && testCase[name] !== 0)) return '';
        return testCase[name];
    };

    const handleChange = (e) => {
        onChange(e, row);
    };

    const inputProps = {
        onChange: handleChange,
        maxLength: 500,
        margin: 'none',
        variant: 'outlined',
        isError: !testCase.valid,
        className: classes.textInput,
        InputLabelProps: {
            classes: {
                root: classes.textInputLabel
            }
        }
    };

    const sheetName = renderValue('sheetName');

    return (
        <div className="c-multicol u-mrg--tx2">
            <div className="c-multicol__col">
                <div className={clsx('u-dsp--f', 'u-jc--start', 'u-wdt--100p', 'u-pos--relative', 'u-mrg--bx1', classes.baseInputHeight)}>
                    <SheetNameSelect
                        {...inputProps}
                        row={row}
                        sheetNames={sheetNames}
                        onChange={onChange}
                        question={question}
                        value={sheetName}
                    />
                    <TextInput
                        value={renderValue('resultCellColumn')}
                        label="Cell column"
                        placeholder="D"
                        name="resultCellColumn"
                        helperText={sheetRange ? `Range: ${sheetRange.columnRange}` : ' '}
                        {...inputProps}
                    />
                    <TextInput
                        value={renderValue('resultCellRow')}
                        label="Cell row"
                        placeholder="5"
                        name="resultCellRow"
                        helperText={sheetRange ? `Range: ${sheetRange.rowRange}` : ' '}
                        {...inputProps}
                    />
                    <CorrectAnswersMenu
                        testCase={testCase}
                        testCases={testCases}
                        helperText={actualResult !== null ? `Current value: '${actualResult}'` : ''}
                        {...inputProps}
                    />
                    <TextInput
                        value={renderValue('score')}
                        label="Points (max 10)"
                        name="score"
                        type="number"
                        min="0"
                        max="10"
                        {...inputProps}
                    />
                    {
                        testCase.valid && (
                            <CheckButton
                                isCMS={isCMS}
                                questionId={question.id}
                                testCase={testCase}
                                setActualResult={setActualResult}
                            />
                        )
                    }
                    {
                        testCases.length > 1 && (
                            <IconButton
                                type="button"
                                onClick={e => removeTestCase(e, row)}
                                className={classes.closeIcon}
                                size="large"
                            >
                                <img src={RemoveIcon} alt="x" />
                            </IconButton>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(SpreadsheetTestCase);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        minHeight: 100,
        width: ({ compareNumber }) => `${compareNumber * 435}px`,
        minWidth: ({ compareNumber }) => `${compareNumber * 435}px`
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto'
    },
    closeIcon: {
        color: theme.palette.grey[500]
    },
    closeIconButton: {
        borderRadius: 4,
        padding: 6
    },
    scrolledContainer: {
        overflowY: 'auto',
        maxHeight: 180
    },
    tagsWrapper: {
        padding: '17px 19px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    commentsWrapper: {
        padding: '0 19px 17px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`,
        height: 220,
        overflow: 'auto'
    },
    tag: {
        display: 'inline-block !important',
        marginBottom: '0 !important'
    },
    tagContainer: {
        display: 'block !important',
        overflow: 'auto',
        whiteSpace: 'nowrap'
    }
}));

export default theme => ({
    wrapper: {
        backgroundColor: theme.palette.grey[50],
        padding: '12px 21px 24px',
        zIndex: 100,
        position: 'absolute',
        left: -33,
        bottom: 0,
        width: 'calc(100% + 66px)',
        boxShadow: '0px -10px 9px rgba(41, 42, 45, 0.06)'
    },
    popoverRoot: {
        padding: 0,
        width: 260
    },
    popoverHeader: {
        padding: '11px 19px 5px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    popoverContent: {
        position: 'relative',
        padding: '15px 2px 12px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    addSection: {
        padding: '15px 19px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    footer: {
        fontWeight: 600,
        fontSize: 12,
        padding: '11px 18px 14px',
        backgroundColor: theme.palette.grey[200],
        '& a': {
            color: theme.palette.primary.main
        }
    },
    skillInput: {
        margin: 0,
        marginRight: 10,
        '& input': {
            padding: 15
        }
    },
    loaderWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    iconStar: {
        borderRadius: 4,
        padding: 6,
        marginRight: 5
    },
    emptyTags: {
        padding: '15px 0',
        textAlign: 'center'
    },
    list: {
        maxHeight: 210,
        overflow: 'auto'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    tag: {
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.grey[300],
        marginRight: 12,
        marginBottom: 12,
        padding: '5px 15px 6px',
        fontWeight: 700,
        fontSize: 11,
        textTransform: 'capitalize',
        borderRadius: 4,
        maxWidth: 150,
        position: 'relative'
    },
    closeButton: {
        padding: 0,
        position: 'absolute',
        top: 11,
        right: 6
    },
    closeIcon: {
        width: 13,
        height: 13,
        color: theme.palette.grey[500]
    },
    noTagsLabel: {
        fontSize: 13,
        fontWeight: 700,
        minHeight: 30,
        display: 'flex',
        alignItems: 'center'
    }
});

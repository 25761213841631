const styles = theme => ({
    wrapper: {
        marginTop: 25,
        marginBottom: 25
    },
    skill: {
        position: 'relative',
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        display: 'inline-block',
        marginRight: 10,
        marginTop: 8,
        padding: '8px 29px 8px 13px',
        textTransform: 'uppercase',
        borderRadius: 16,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff'
    },
    skillRemoveIconButton: {
        padding: 0,
        position: 'absolute',
        top: 8,
        right: 8
    },
    skillRemoveIcon: {
        color: '#d1d3d9',
        width: 16,
        height: 16
    },
    container: {
        flexGrow: 1,
        position: 'relative'
    },
    paper: {
        position: 'absolute',
        zIndex: 10,
        marginTop: 16,
        left: 0,
        top: 46,
        right: 0
    },
    chip: {
        margin: '8px 4px'
    },
    selectPaper: {
        maxWidth: 550,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '90%'
        }
    },
    selectMenu: {
        textTransform: 'capitalize'
    },
    selectMenuItem: {
        fontSize: 14,
        textTransform: 'capitalize',
        color: '#424242',
        padding: '10px 16px',
        letterSpacing: 0.5
    },
    selectMenuItemSelected: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    searchIcon: {
        color: theme.palette.primary.main
    },
    selectOutlined: {
        textTransform: 'capitalise'
    }
});

export default styles;

import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import OnboardingEvents from 'events/OnboardingEvents';

import OnboardingDialog from '../onboarding_dialog';

const OnboardingAssessmentStart = observer(() => {
    const [dialogOpened, setDialogOpened] = useState(true);
    const { company, loggedUser } = useContext(appCtx);
    const { onboarding } = company;
    if (!onboarding) return null;
    const { candidateUrl, auditionName, auditionSlug: ttSlug, auditionUuid: ttUUID } = onboarding;
    const { first_name } = loggedUser;

    useEffect(() => {
        OnboardingEvents.TT_STARTED({ ttSlug, ttName: auditionName, ttUUID });
    }, []);

    const handleDialog = () => {
        setDialogOpened(false);
    };

    return (
        <>
            <OnboardingDialog
                open={dialogOpened}
                onClose={handleDialog}
            >
                Great choice {first_name}!<br />
                You’re about to start the {auditionName}. You will experience it exactly how a candidate would. Feel free to enter your own responses to see Vervoe in action or jump to the results to see our powerful ranking by clicking submit. 🚀
            </OnboardingDialog>
            <iframe
                title="Onboarding assessement"
                frameBorder="0"
                src={candidateUrl}
                width="100%"
                height="100%"
                allowFullScreen
                allow={`camera ${candidateUrl}; microphone ${candidateUrl}`}
                style={{ position: 'relative', left: '0', height: 'calc(100vh - 90px)' }}
            />
        </>
    );
});

export default withRouter(OnboardingAssessmentStart);

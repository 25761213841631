export default theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.grey[400],
        boxSizing: 'border-box',
        padding: 20,
        height: '100%',
        width: '100%',
        borderRadius: 4
    },
    downloadButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary.main,
        padding: '0 8px',
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: 200,
        boxShadow: '0 3px 15px 0 rgba(144, 144, 144, 0.17)',
        height: 40,
        borderRadius: 4,
        transition: 'all 0.3s',
        fontWeight: 700,
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey[400]
        }
    },
    fileNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 600,
        color: '#3a3c43',
        width: '100%'
    },
    fileNameLabel: {
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fileNameLabelImg: {
        marginRight: 12
    },
    fileSizeLabel: {
        fontWeight: 600,
        fontSize: 14,
        color: 'textSecondary'
    }
});

export default theme => ({
    heading: {
        fontWeight: 700,
        fontSize: 16
    },
    button: {
        fontWeight: 700,
        textDecoration: 'underline',
        padding: '2px 2px'
    },
    accordion: {
        boxShadow: 'none',
        borderBottom: '2px solid #EBECEE',
        '&::before': {
            display: 'none'
        }
    },
    summary: {
        padding: 0,
        cursor: 'initial!important'
    },
    summaryWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    details: {
        padding: '0px 0px 16px',
        '& ::-webkit-scrollbar': {
            height: 8
        },
        '& ::-webkit-scrollbar-track': {
            background: theme.palette.grey[100],
            borderRadius: 4
        },
        '& ::-webkit-scrollbar-thumb': {
            background: theme.palette.grey[400],
            borderRadius: 4,
            '&:hover': {
                background: theme.palette.grey[500]
            }
        }
    },
    expanded: {
        margin: '0 !important'
    },
    foldersContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'auto',
        paddingBottom: 16
    },
    addButton: {
        padding: 3
    },
    card: {
        cursor: 'pointer',
        maxHeight: 65,
        minWidth: 183,
        backgroundColor: theme.palette.grey[200],
        border: `2px solid ${theme.palette.grey[200]}`,
        padding: '8px 14px',
        fontSize: 12,
        fontWeight: 700,
        borderRadius: 4,
        '&:not(:last-child)': {
            marginRight: 16
        },
        '&:hover': {
            backgroundColor: '#ffffff',
            borderColor: theme.palette.grey[300]
        }
    },
    active: {
        borderColor: `${theme.palette.primary.main}!important`
    },
    header: {
        minHeight: 24,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2
    },
    menu: {
        display: 'flex',
        flexWrap: 'nowrap'
    },
    menuIcon: {
        transform: 'rotate(90deg)',
        padding: 1,
        marginRight: 2
    },
    organiseButton: {
        padding: 4
    },
    footer: {
        color: theme.palette.grey[600],
        '& span': {
            fontWeight: 600
        }
    }
});

import React, { useEffect, useState, useRef } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { withStyles, withTheme } from '@mui/styles';
import clsx from 'clsx';

import styles from './styles';
import CheckIcon from './check.svg';

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const GradeLoader = ({ classes, hideCheck, theme, isActive, isLoading }) => {
    const [showCheckbox, setShowCheckbox] = useState(false);
    const prevLoading = usePrevious(isLoading);

    useEffect(() => {
        if (isActive && !isLoading && prevLoading) {
            setShowCheckbox(true);
            setTimeout(() => { setShowCheckbox(false); }, 1000);
        }
    }, [isLoading, isActive]);

    if ((!isLoading && !showCheckbox) || (showCheckbox && hideCheck)) return null;

    return (
        <div className={clsx(classes.wrapper, showCheckbox && classes.wrapperChecked)}>
            {
                isLoading && (
                    <CircularProgressbar
                        value={25}
                        styles={{
                            root: {
                                width: 32
                            },
                            path: {
                                stroke: theme.palette.primary.main,
                                strokeLinecap: 'butt'
                            },
                            trail: {
                                stroke: theme.palette.grey[300]
                            }
                        }}
                        className={classes.gradeLoading}
                    />
                )
            }
            {
                showCheckbox && (
                    <img src={CheckIcon} alt="check" />
                )
            }
        </div>
    );
};

export default withTheme(withStyles(styles)(GradeLoader));

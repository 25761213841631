import React, { useEffect, useState } from 'react';

import { withStyles } from '@mui/styles';

import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import { ALL_MANUAL_GRADED_TYPES } from 'helper/constants';
import styles from '../../styles';

const ManualGradesRequiredSwitch = ({ question, changeQuestionSettings }) => {
    const { manualGradesRequired, id, answerType } = question;
    const [manualGradesRequiredQuestion, setManualGradesRequiredQuestion] = useState(manualGradesRequired);

    useEffect(() => {
        setManualGradesRequiredQuestion(manualGradesRequired);
    }, [id, answerType]);

    const handleChangeManualGradesRequired = () => {
        const newValue = !manualGradesRequiredQuestion;
        setManualGradesRequiredQuestion(newValue);
        changeQuestionSettings({ manualGradesRequired: newValue });
    };

    return (
        <>
            <SwitchBrandWithLabel
                label="Grade Manually"
                checked={Boolean(manualGradesRequired)}
                className="u-mrg--bx0"
                onChange={handleChangeManualGradesRequired}
                disabled={ALL_MANUAL_GRADED_TYPES.includes(answerType)}
            />
        </>
    );
};

export default withStyles(styles)(ManualGradesRequiredSwitch);

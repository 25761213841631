import React from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch, withRouter } from 'react-router-dom';
import LoaderBounce from 'components/loaders/LoaderBounce';
import PanelHeader from 'components/assessments_pages/asessment_settings_components/SectionHeader';

import useStyles from './styles';

const AssessmentSettingsContent = observer(({
    children, history, location,
    match, routes = [], isLoading, ...other
}) => {
    const classes = useStyles();

    const hasData = other.audition && other.settings;

    return (
        <div className={classes.contentPanel}>
            <Switch>
                {
                    routes.map(({ path, header, headerPage = header, Component, disabled, Type = Route }) => (!disabled ? (
                        <Type
                            exact
                            path={path}
                            render={props => (
                                <>
                                    <PanelHeader className="u-txt--20 u-mrg--bx5">{headerPage}</PanelHeader>
                                    {
                                        isLoading && (
                                            <div className={classes.contentPanel}>
                                                <div className="u-txt--center u-pdn--tx10 u-pdn--bx10">
                                                    <LoaderBounce />
                                                </div>
                                            </div>
                                        )
                                    }
                                    { Component && hasData && <Component {...props} {...other} /> }
                                </>
                            )}
                        />
                    ) : null))
                }
            </Switch>
        </div>
    );
});

export default withRouter(AssessmentSettingsContent);

import React from 'react';

import { withStyles } from '@mui/styles';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import Tooltip from 'libraries/Tooltip';
import TextInputOutlined from 'libraries/TextInput';

import styles from '../TimerLimitSwitch/styles';

const TypingTestTimerLimitSwitch = ({ classes, question: { typingTestTimerLimit } }) => (
    <Tooltip
        tooltipClass={classes.tooltip}
        label={<>Time limit is unable to be<br />changed for typing tests</>}
    >
        <div className={classes.wrapper}>
            <SwitchBrandWithLabel
                label="Set typing test time limit"
                checked
                disabled
                className={classes.formLabelSwitch}
            />
            <br />
            <TextInputOutlined
                className={classes.timeInput}
                variant="outlined"
                value={parseInt(typingTestTimerLimit / 60, 10)}
                label="Answer time limit (min)"
                disabled
                type="number"
            />
        </div>
    </Tooltip>
);

export default withStyles(styles)(TypingTestTimerLimitSwitch);

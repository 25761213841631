import React from 'react';
import { withStyles } from '@mui/styles';
import {
    AUDIO, VIDEO, ANY_FILE,
    IMAGE, CODE_TEST, FEEDBACK
} from 'helper/constants';
import LinkifyComponent from 'libraries/LinkifyComponent';
import MediaPlayer from 'components/media_player';

import CodeQuestion from './CodeQuestion';
import FileQuestion from './FileQuestion';
import ImageQuestion from './ImageQuestion';
import FeedbackQuestion from './FeedbackQuestion';


const styles = theme => ({
    skillWrapper: {
        display: 'inline-block',
        marginRight: 19,
        marginLeft: -3,
        marginBottom: 16,
        borderRadius: 16,
        padding: '8px 16px',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        textTransform: 'uppercase'
    },
    description: {
        whiteSpace: 'pre-line',
        '& ul li': {
            listStyleType: 'disc',
            marginLeft: 40
        },
        '& ul > br, ol > br': {
            display: 'none'
        },
        '& code': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[900],
            fontSize: 16
        },
        '& blockquote': {
            fontStyle: 'italic',
            color: theme.palette.grey[700],
            borderLeft: '5px solid #eee'
        },
        '& a': {
            color: theme.palette.primary.main,
            fontWeight: 700,
            '&:hover, &:active, &:focus': {
                color: theme.palette.primary.main
            }
        }
    }
});

const QuestionContent = ({ question, classes }) => {
    if (!question) return null;

    const { type: questionType, description, employerDescription } = question;

    if (questionType === FEEDBACK) return <FeedbackQuestion />;

    let returnedQuestion;
    switch (questionType) {
        case CODE_TEST:
            returnedQuestion = <CodeQuestion question={question} />;
            break;
        case AUDIO:
        case VIDEO:
            returnedQuestion = (
                <MediaPlayer
                    ziggeo={question.ziggeo}
                    audio={questionType === AUDIO}
                />
            );
            break;
        case ANY_FILE:
            returnedQuestion = <FileQuestion question={question} />;
            break;
        case IMAGE:
            returnedQuestion = <ImageQuestion question={question} />;
            break;
        default:
            returnedQuestion = null;
    }

    return (
        <div>
            {
                description && (
                    <LinkifyComponent className={classes.description}>
                        <div dangerouslySetInnerHTML={{ __html: description.toString('html') }} />
                    </LinkifyComponent>
                )
            }
            {
                employerDescription && (
                    <LinkifyComponent className={classes.description}>
                        <div dangerouslySetInnerHTML={{ __html: employerDescription.toString('html') }} />
                    </LinkifyComponent>
                )
            }
            {returnedQuestion}
        </div>
    );
};

export default withStyles(styles)(QuestionContent);

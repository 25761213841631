export default (() => ({
    closeIcon: {
        padding: 0,
        height: 34,
        margin: '8px 0 0 5px'
    },
    textInput: {
        '&:not(:first-child)': {
            marginLeft: 8
        }
    },
    textInputLabel: {
        fontSize: 12
    },
    baseInputHeight: {
        height: 75
    }
}));

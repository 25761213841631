import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import CandidatesSearch from 'components/job_or_assessment_settings/CandidatesSearch';
import TableFilter from 'components/table_components/table_filter';
import ExportButton from 'pages/assessment/components/ExportButton';
import SortIcon from 'components/icons/SortIcon';

import useStyles from './styles';


const SearchFilterBlock = observer(({ match, className = '' }) => {
    const classes = useStyles();
    const { scriptSlug } = match.params;

    return (
        <div className={clsx(classes.infoWrapper, className)}>
            <CandidatesSearch
                className={classes.search}
                disabled
            />
            <div className="u-dsp--f u-ai--center">
                <ExportButton
                    isAssessment
                    slug={scriptSlug}
                    disabled
                />
                <TableFilter disabled />
                <div
                    role="presentation"
                    className={classes.sortRoot}
                >
                    <SortIcon /> Sort
                </div>
            </div>
        </div>
    );
});

export default withRouter(SearchFilterBlock);

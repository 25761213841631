import { makeStyles } from '@mui/styles';
import { getScoreColor } from 'helper/commonFunctions';

export default makeStyles(theme => ({
    skillName: {
        marginLeft: 0
    },
    root: {
        width: 'calc(100% - 20px)',
        margin: '0 10px',
        height: 8,
        borderRadius: 56
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[300]
    },
    tableCell: {
        fontSize: 14,
        fontWeight: 700
    },
    tableCellProgress: {
        borderLeft: `1px solid ${theme.palette.grey[300]}`
    },
    bar: {
        borderRadius: 5,
        backgroundColor: props => getScoreColor(props.score)
    },
    contentButton: {
        borderLeft: 'none',
        marginLeft: 0,
        marginRight: 10
    }
}));

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import TruncateMarkup from 'react-truncate-markup';
import Zendesk from 'helper/zendeskFunctions';
import ReactHtmlParser from 'react-html-parser';

// material components
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const styles = theme => ({
    headerWrapper: {
        color: theme.palette.grey[700],
        fontSize: '14px',
        lineHeight: '16px',
        padding: '16px 20px 11px'
    },
    header: {
        color: theme.palette.grey[900],
        marginBottom: '10px',
        lineHeight: '21px',
        fontSize: 18,
        fontWeight: 600
    },
    iframe: {
        position: 'relative',
        left: '0',
        height: 'calc(100vh - 90px)'
    },
    collapse: {
        maxWidth: 700
    },
    iconButton: {
        height: 55
    }
});

const DocDialog = ({
    isOpen, classes,
    onClose, question, sourceLink
}) => {
    useEffect(() => {
        setTitleOpened(false);
    }, [question]);

    useEffect(() => {
        if (isOpen) Zendesk.hide();
        return () => Zendesk.show();
    }, [isOpen]);

    const [titleOpened, setTitleOpened] = useState(false);
    const [truncated, setTruncated] = useState(false);
    if (!sourceLink) return null;

    const handleTruncate = (newTruncated) => {
        if (newTruncated && !truncated) {
            setTruncated(newTruncated);
            setTitleOpened(!newTruncated);
        } else if (!newTruncated) {
            setTitleOpened(!newTruncated);
        }
    };

    const toggleTitleOpen = (e) => {
        e.preventDefault();
        setTitleOpened(!titleOpened);
    };

    return (
        <Dialog
            fullScreen
            open={isOpen}
        >
            <div
                className={`c-reg-step-dialog ${classes.headerWrapper}`}
            >
                <Collapse
                    in={titleOpened || !truncated}
                    className={classes.collapse}
                    collapsedSize={truncated ? '66px' : '0px'}
                >
                    <div className={classes.header}>
                        Question {question.sort}
                    </div>
                    {!titleOpened && (
                        <div>
                            <TruncateMarkup
                                lines={2}
                                ellipsis={(
                                    <span>...
                                        <button
                                            type="button"
                                            onClick={toggleTitleOpen}
                                            className="c-button-link t-txt--brand"
                                        >
                                                (show more)
                                        </button>
                                    </span>
                                )}
                                onTruncate={handleTruncate}
                            >
                                <div>
                                    {question.description && ReactHtmlParser(question.description)}
                                </div>
                            </TruncateMarkup>
                        </div>
                    )}
                    {titleOpened && (question.description && ReactHtmlParser(question.description))}
                    {titleOpened && truncated && (
                        <button
                            type="button"
                            onClick={toggleTitleOpen}
                            className="c-button-link t-txt--brand"
                        >
                            {' (show less)'}
                        </button>
                    )}
                    {!titleOpened && !truncated && (
                        question.description && ReactHtmlParser(question.description)
                    )}
                </Collapse>
                <IconButton onClick={onClose} className={classes.iconButton} size="large">
                    <CloseIcon fontSize="large" />
                </IconButton>
            </div>
            <iframe
                title="Edit question"
                frameBorder="0"
                src={sourceLink}
                width="100%"
                height="100%"
                className={classes.iframe}
            />
        </Dialog>
    );
};

export default withStyles(styles)(DocDialog);

import React from 'react';
import TextInputOutlined from 'libraries/TextInput';

const NotesField = ({ value, onChangeTicket }) => {
    const onChange = (e) => {
        onChangeTicket('note', e.target.value);
    };

    return (
        <TextInputOutlined
            value={value}
            name="note"
            variant="outlined"
            label="Notes/Skills (optional)"
            onChange={onChange}
            fullWidth
            maxLength={255}
        />
    );
};

export default NotesField;

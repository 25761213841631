export default theme => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginLeft: '15px',
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.yellow[800],
        '& img': {
            marginRight: 5
        }
    },
    autogradedLabel: {
        color: theme.palette.green[600]
    },
    header: {
        width: 'fit-content',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        padding: '3px 10px',
        fontSize: 14,
        fontWeight: 700,
        borderRadius: 4,
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.grey[300]
    }
});

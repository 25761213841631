import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        background: 'white',
        borderWidth: 2,
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        marginTop: 16,
        padding: 20,
        borderRadius: 8,
        cursor: 'pointer'
    },
    active: {
        borderColor: theme.palette.blue[600]
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    ticketStatus: {
        display: 'flex',
        alignItems: 'center'
    },
    ticketName: {
        fontSize: 14,
        fontWeight: 700,
        maxWidth: 160,
        textOverflow: 'ellipsis',
        display: 'box',
        lineClamp: 1,
        boxOrient: 'vertical',
        overflow: 'hidden'
    },
    ticketDateText: {
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.grey[700]
    },
    doneIcon: {
        marginLeft: 7,
        width: 20,
        height: 20
    },
    newTicket: {
        color: 'white',
        padding: '2px 14px',
        backgroundColor: theme.palette.green[600],
        marginLeft: 7,
        fontSize: 12,
        borderRadius: 4
    },
    ticketSource: {
        fontSize: 13,
        color: theme.palette.blue[600],
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        margin: '7px 0 13px',
        '& > img': {
            width: 20,
            height: 20,
            marginRight: 5
        },
        '& > span': {
            display: 'box',
            lineClamp: 1,
            boxOrient: 'vertical',
            overflow: 'hidden'
        }
    },
    ticketText: {
        fontSize: 13
    }
}));

export default theme => ({
    listWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 20
    },
    listItem: {
        padding: '5px 12px',
        borderRadius: 20,
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        marginBottom: 10,
        fontSize: 14,
        color: 'rgb(58, 60, 67)'
    },
    title: {
        marginTop: 15,
        fontSize: 14,
        marginBottom: 20,
        fontWeight: 600,
        color: theme.palette.grey[900]
    }
});

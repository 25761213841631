import React from 'react';
import { withStyles } from '@mui/styles';
import { CODE_TEST_AUTOGRADED } from 'helper/constants';

import styles from './styles';

const QuestionErrors = ({
    question, classes, hasErrors, hasNoExampleAnswers,
    ifMissingForAnswer, missingSimulationInstruction,
    isScreening
}) => {
    const { answerType, timerValid, missingQuestion, template } = question;

    const errorText = answerType === CODE_TEST_AUTOGRADED ? 'Add code and test cases' : 'Unfinished - won\'t be seen';

    const missingAnswerText = (ifMissingForAnswer) ? errorText : '';

    if (!hasErrors || template) return null;

    if (isScreening) {
        return (
            <div className={classes.wrapper}>
                {errorText}
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            { missingQuestion && <div>No question text or media</div> }
            { !timerValid && <>
                <div>Question timer is too short</div>
                <div>Question won’t be seen</div>
            </> }
            { missingAnswerText && <div>{missingAnswerText}</div> }
            { hasNoExampleAnswers && <div>Question requires correct answer examples</div> }
            { missingSimulationInstruction && <div>No candidate instructions text</div> }
        </div>
    );
};
export default withStyles(styles)(QuestionErrors);

export default theme => ({
    errorArrayItem: {
        marginBottom: 2,
        display: 'block'
    },
    counterLabel: {
        fontWeight: 600,
        fontSize: 10,
        textAlign: 'right',
        width: '100%',
        color: theme.palette.grey[500]
    },
    counterLabelOutlined: {
        marginRight: -13
    },
    counterLabelError: {
        color: theme.palette.red.primary
    }
});

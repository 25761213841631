import React from 'react';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    customInput: {
        height: 142,
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 3,
        padding: 10,
        fontWeight: 600,
        backgroundColor: '#fff',
        fontSize: 14,
        letterSpacing: '0.2px',
        color: theme.palette.grey[500]
    }
});

const TextAnswer = ({ classes, ...other }) => (
    <div className={classes.customInput} {...other}>
        Type your answer here...
    </div>
);

export default withStyles(styles)(TextAnswer);

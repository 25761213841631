import { createContext } from 'react';
import { action, observable, computed } from 'mobx';
import { getCompanyInsights, getSkillsBreakdown, getInsightAuditions, getHireData, getCandidateCsat, getCandidateCompletions,
    getScoreDistribution, getShareDates, getEeocDistribution } from 'requests/AccountInsightsRequests';
import { CSV_EXPORT, DATE_RANGES } from 'helper/constants';
import { appStore } from 'components/appStore';
import { checkHasCompanyFeature } from 'helper/commonFunctions';


class AccountInsightsStore {
    @observable dateFilterType = DATE_RANGES.LAST_30_DAYS;

    @observable timeRange = null;

    @observable selectedAssessment = 'all';

    @observable companyInsights = null;

    @observable skillsBreakdown = null;

    @observable hireData = null;

    @observable candidateCsat = null;

    @observable insightAuditions = null;

    @observable completionsChartData = null;

    @observable scoreDistributionData = null;

    @observable eeocDistributionData = null;

    @observable assessmentsTablePage = 1;

    @observable publicLinkUuid = null;

    @observable isTeamUser = false;

    @observable canCompanyExportCSV = false;

    @observable loading = false;

    @computed get baseParams() {
        if (!this.timeRange) {
            return {};
        }

        const result = {
            from: this.timeRange[0],
            to: this.timeRange[1]
        };

        if (this.selectedAssessment !== 'all') {
            result.audition = this.selectedAssessment;
        }
        return result;
    }

    @action setTimeRange = (newTimeRange, dateRange) => {
        this.timeRange = newTimeRange;
        this.dateFilterType = dateRange;
    }

    @action setSelectedAssessment = (newSelectedAssessment) => {
        this.selectedAssessment = newSelectedAssessment;
    }

    @action loadPublicData = (linkUuid) => {
        this.publicLinkUuid = linkUuid;
        getShareDates(linkUuid).then(({ from, to }) => {
            this.setTimeRange([from, to], DATE_RANGES.LAST_30_DAYS);
        });
        this.loadData(linkUuid);
    }

    @action setLimitations = () => {
        this.isTeamUser = appStore.loggedUser.roleType === 'team';
        this.canCompanyExportCSV = checkHasCompanyFeature(appStore.company, CSV_EXPORT);
    }

    @action loadData = async (publicLinkUuid) => {
        if (this.loading) {
            return;
        }

        this.loading = true;
        const params = this.baseParams;

        const handleResult = (result, successCallback) => {
            if (result.status === 'fulfilled' && result.value.success) {
                successCallback(result.value.data);
            } else {
                successCallback(null);
                const errors = result?.reason?.errors;
                if (errors) {
                    appStore.flashMessage(errors || 'Something went wrong');
                }
            }
        };

        try {
            const [
                companyInsightsResult,
                skillsBreakdownResult,
                hireDataResult,
                candidateCsatResult,
                insightAuditionsResult,
                completionsDataResult,
                scoreDistributionResult,
                eeocDistributionResult
            ] = await Promise.allSettled([
                getCompanyInsights(params, publicLinkUuid),
                getSkillsBreakdown(params, publicLinkUuid),
                getHireData(params, publicLinkUuid),
                getCandidateCsat(params, publicLinkUuid),
                getInsightAuditions(params, publicLinkUuid),
                getCandidateCompletions(params, publicLinkUuid),
                getScoreDistribution(params, publicLinkUuid),
                getEeocDistribution(params, publicLinkUuid)
            ]);

            handleResult(companyInsightsResult, (data) => {
                this.companyInsights = data;
            });
            handleResult(skillsBreakdownResult, (data) => {
                this.skillsBreakdown = data;
            });
            handleResult(hireDataResult, (data) => {
                this.hireData = data;
            });
            handleResult(candidateCsatResult, (data) => {
                this.candidateCsat = data;
            });
            handleResult(insightAuditionsResult, (data) => {
                this.insightAuditions = data;
            });
            handleResult(completionsDataResult, (data) => {
                this.completionsChartData = data;
            });
            handleResult(scoreDistributionResult, (data) => {
                this.scoreDistributionData = data;
            });
            handleResult(eeocDistributionResult, (data) => {
                this.eeocDistributionData = data;
            });
        } finally {
            this.setLimitations();
            this.loading = false;
        }
    }

    @action loadInsightAssessments = async () => {
        const params = this.baseParams;
        const result = await getInsightAuditions({ ...params, page: this.assessmentsTablePage }, this.publicLinkUuid);
        if (result.success) {
            this.insightAuditions = result.data;
        }
    }

    @action handleChangeAssessmentsPage = (page) => {
        this.assessmentsTablePage = page;
        this.loadInsightAssessments();
    };
}

export const accountInsightsStore = new AccountInsightsStore();
export const accountInsightsStoreCtx = createContext(accountInsightsStore);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    testCaseOrder: {
        minWidth: 30,
        backgroundColor: theme.palette.blue[900],
        borderRadius: 8,
        color: 'white'
    },
    scoreText: {
        color: theme.palette.blue[500]
    },
    workFlowWrapper: {
        userSelect: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > img': {
            maxWidth: 'initial!important'
        }
    },
    answeredText: {
        margin: '20px auto 60px',
        maxWidth: 260
    },
    instructions: {
        marginBottom: 25,
        fontSize: 12
    },
    simStrategyDescription: {
        '& h2': {
            display: 'none'
        }
    }
}));

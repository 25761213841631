import React from 'react';
import { withTheme } from '@mui/styles';

const NotificationIcon = ({ active, theme }) => (
    active ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3431 2.99824C7.91289 3.32981 5.2248 6.42209 5.2248 10.1878C5.2248 12.5523 5.00298 14.3633 4.63799 15.69C4.42994 16.4462 4.23552 16.8594 4.13121 17.0023C3.81681 17.433 4.10293 18.0631 4.61297 18.0631H19.297C19.8748 18.0631 20.1306 17.283 19.6799 16.895C19.6893 16.9031 19.6856 16.8971 19.6705 16.8732L19.6705 16.8732C19.66 16.8565 19.6441 16.8312 19.6233 16.7958C19.5089 16.6013 19.387 16.3119 19.2683 15.9109C18.9068 14.689 18.6851 12.8226 18.6851 10.1878C18.6851 6.4221 15.997 3.32983 12.5668 2.99825V1.65627C12.5668 1.29382 12.2929 1 11.955 1C11.6171 1 11.3431 1.29382 11.3431 1.65627V2.99824ZM9.73636 20.2168C9.64118 19.869 9.82686 19.5043 10.1511 19.4022C10.4753 19.3001 10.8153 19.4993 10.9105 19.8471C11.0468 20.3453 11.473 20.6875 11.957 20.6875C12.4411 20.6875 12.8672 20.3453 13.0035 19.8471C13.0987 19.4993 13.4387 19.3001 13.7629 19.4022C14.0872 19.5043 14.2728 19.869 14.1777 20.2168C13.8884 21.2739 12.9841 22 11.957 22C10.9299 22 10.0257 21.2739 9.73636 20.2168ZM18.1019 16.3079C17.6986 14.9446 17.4615 12.9479 17.4615 10.1878C17.4615 6.92579 14.9961 4.28138 11.955 4.28138C8.91381 4.28138 6.44847 6.92579 6.44847 10.1878C6.44847 12.673 6.21141 14.6083 5.81162 16.0615C5.74091 16.3185 5.66912 16.5477 5.59796 16.7506H18.2464C18.1976 16.6152 18.1493 16.4678 18.1019 16.3079Z"
                fill={theme.palette.primary.main}
            />
        </svg>

    ) : (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.65599 10.1878C5.65599 6.42209 8.34408 3.32981 11.7743 2.99824V1.65627C11.7743 1.29382 12.0483 1 12.3862 1C12.7241 1 12.998 1.29382 12.998 1.65627V2.99825C14.2998 3.12408 15.4947 3.64754 16.4764 4.45452L15.5659 5.36505C14.6677 4.68252 13.5706 4.28138 12.3862 4.28138C9.345 4.28138 6.87966 6.92579 6.87966 10.1878C6.87966 11.79 6.78113 13.1637 6.60398 14.3269L5 15.9309C5.02282 15.8548 5.04591 15.7746 5.06918 15.69C5.43417 14.3633 5.65599 12.5523 5.65599 10.1878ZM7.95899 18.0631H19.7281C20.306 18.0631 20.5618 17.283 20.1111 16.895C20.1205 16.9031 20.1168 16.8971 20.1017 16.8732L20.1017 16.8732C20.0912 16.8565 20.0753 16.8312 20.0545 16.7958C19.9401 16.6013 19.8181 16.3119 19.6995 15.9109C19.338 14.689 19.1163 12.8226 19.1163 10.1878C19.1163 9.20677 18.9339 8.27143 18.6034 7.4187L17.6327 8.38936C17.8015 8.95643 17.8927 9.56072 17.8927 10.1878C17.8927 12.9479 18.1298 14.9446 18.5331 16.3079C18.5805 16.4678 18.6288 16.6152 18.6776 16.7506H9.27152L7.95899 18.0631ZM10.5823 19.4022C10.258 19.5043 10.0724 19.869 10.1675 20.2168C10.4569 21.2739 11.3611 22 12.3882 22C13.4153 22 14.3195 21.2739 14.6089 20.2168C14.704 19.869 14.5184 19.5043 14.1941 19.4022C13.8699 19.3001 13.5299 19.4993 13.4347 19.8471C13.2984 20.3453 12.8723 20.6875 12.3882 20.6875C11.9042 20.6875 11.478 20.3453 11.3417 19.8471C11.2465 19.4993 10.9065 19.3001 10.5823 19.4022Z" fill="#A9AAAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.0243 3.17574C20.2586 3.41005 20.2586 3.78995 20.0243 4.02426L4.02426 20.0243C3.78995 20.2586 3.41005 20.2586 3.17574 20.0243C2.94142 19.7899 2.94142 19.41 3.17574 19.1757L19.1757 3.17574C19.41 2.94142 19.7899 2.94142 20.0243 3.17574Z" fill="#A9AAAE" />
        </svg>
    )
);

export default withTheme(NotificationIcon);

import React from 'react';

const ReorderIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 14L7.5 3M7.5 3L5 6.3M7.5 3L10 6.3" stroke="#C7C8CC" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.5 9L16.5 20M16.5 20L19 16.7M16.5 20L14 16.7" stroke="#C7C8CC" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="7.5" cy="18.5" r="1.5" stroke="#C7C8CC" strokeWidth="1.2" />
        <circle cx="16.5" cy="4.5" r="1.5" transform="rotate(-180 16.5 4.5)" stroke="#C7C8CC" strokeWidth="1.2" />
    </svg>
);

export default ReorderIcon;

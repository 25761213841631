import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { AUDIO, VIDEO, ANY_FILE, IMAGE, TEXT } from 'helper/constants';
import { deleteFileFromSimulatorTicket, deleteZiggeoFromSimulatorTicket, uploadFileForSimulatorTicket } from 'requests/QuestionsRequests';

// local files and components
import { appCtx } from 'components/appStore';
import DescriptionField from '../../../../../../QuestionType/DescriptionField';
import AnswerAttachment from '../../../../../../AnswerAttachment';

const ALLOW_SYMBOLS = 25000;

const LABEL = 'Ticket content';
const PLACEHOLDER = 'Ticket content';

const SimulatorDescriptionField = observer(({ isCMS, question, onChange }) => {
    const {
        id, text, ziggeo: questionZiggeo,
        codeEditor, file: questionFile,
        type: questionType
    } = question;

    const [value, setValue] = useState('');
    const [codeEditorValue, setCodeEditorValue] = useState('');
    const [ziggeo, setZiggeo] = useState(null);
    const [file, setFile] = useState(null);
    const [type, setType] = useState(null);
    const { flashMessage } = useContext(appCtx);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    useEffect(() => {
        setValue(text || '');
        setZiggeo(questionZiggeo);
        setFile(questionFile);
        setCodeEditorValue(codeEditor);
        setType(questionType);
    }, [id]);

    const handleValue = (e) => {
        const { value: targetValue } = e.target;
        setValue(targetValue);
        onChange('text', targetValue);
    };

    const handleChangeType = (e, newType) => {
        if (newType === TEXT && (type === AUDIO || type === VIDEO) && ziggeo) {
            deleteFile(true);
        } else if (newType === TEXT && (type === ANY_FILE || type === IMAGE) && file) {
            deleteFile();
        } else {
            setType(+newType);
            onChange('type', +newType);
        }
    };

    const deleteFile = (av = false) => {
        const requestFunc = av ? deleteZiggeoFromSimulatorTicket : deleteFileFromSimulatorTicket;
        requestFunc(id, isCMS)
            .then((response) => {
                if (response.status === 204) {
                    if (av) {
                        setZiggeo(null);
                    } else {
                        setFile(null);
                    }
                    setType(TEXT);
                    onChange('type', TEXT);
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    const onDrop = (files) => {
        const formData = new FormData();
        if (!files.length) {
            flashMessage('The file should not be empty and its size should be 10MB or less', 'error');
            return;
        }
        files.forEach((f, index) => formData.append(index, f));
        setIsUploadingFile(true);

        uploadFileForSimulatorTicket(id, formData, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    const { fileName, fileUrl } = data;
                    setFile({ fileName, fileUrl });
                }
            })
            .catch(({ response }) => {
                if (response) {
                    const { msg } = response.data;
                    if (msg) {
                        flashMessage(msg, 'error');
                    } else {
                        flashMessage('Something went wrong', 'error');
                    }
                }
            })
            .finally(() => {
                setIsUploadingFile(false);
            });
    };

    const handleChangeCodeEditor = (event, codeEditorValue) => {
        onChange('codeEditor', codeEditorValue);
        setCodeEditorValue(codeEditorValue);
    };

    const handleChangeToken = (token) => {
        onChange('ziggeo', token);
        setZiggeo({ token });
    };

    if (type === 1 || !type) {
        return (
            <DescriptionField
                {...{
                    question,
                    value,
                    isError: !value,
                    label: LABEL,
                    placeholder: PLACEHOLDER,
                    handleValue,
                    handleChangeType,
                    allowedLength: ALLOW_SYMBOLS
                }}
            />
        );
    }

    return (
        <AnswerAttachment
            file={file}
            isError={!value}
            id={question.id}
            ziggeo={ziggeo}
            type={type}
            onDrop={onDrop}
            allowedLength={ALLOW_SYMBOLS}
            label={LABEL}
            placeholder={PLACEHOLDER}
            isUploadingFile={isUploadingFile}
            handleChangeToken={handleChangeToken}
            codeEditorValue={codeEditorValue}
            handleChangeCodeEditor={handleChangeCodeEditor}
            onChange={handleValue}
            handleChangeType={handleChangeType}
            value={value}
        />
    );
});

export default SimulatorDescriptionField;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import DownloadImg from 'img/download.svg';
import ExportingPopup from 'libraries/SnackbarProgress';
import { downloadCSV } from 'helper/commonFunctions';
import styles from './styles';

const DownloadCSV = ({ classes, onExportCSV, request, params, filename, exportMessage, disabled }) => {
    const [CSVPercents, setCSVPercents] = useState(0);
    const [cancelImporting, setCancelImporting] = useState(false);
    const [dialog_exporting_error, setDialogExportingError] = useState(false);
    const [dialogExporting, setDialogExporting] = useState(false);

    const handleError = () => {
        setDialogExportingError(true);
        setCancelImporting(false);
        closeDialogWithDelay(5000);
    };

    const closeDialogWithDelay = (delay) => {
        setTimeout(() => {
            setDialogExporting(false);
        }, delay);
    };

    const handleSuccess = (fullString) => {
        if (!cancelImporting) {
            setCSVPercents(100);
            downloadCSV(fullString, filename);
        }
        closeDialogWithDelay(1000);
    };

    const calculateProgress = (page, offset, totalItemsCount) => parseInt((offset * (page - 1) * 100) / totalItemsCount, 10);

    const exportCSV = () => {
        if (onExportCSV) onExportCSV();

        setDialogExporting(true);
        setCancelImporting(false);
        setDialogExportingError(false);
        setCSVPercents(0);

        downloadData();
    };

    const downloadData = () => {
        request({ ...params, page: 1 })
            .then(({ success, ...response }) => {
                const responseData = response.data || response;
                const { csv, totalItemsCount, offset } = responseData;

                if (success) {
                    const maxPage = Math.ceil(totalItemsCount / offset);
                    let page = 2;
                    let fullString = csv;

                    if (totalItemsCount > offset) {
                        const makeRequest = () => {
                            request({ ...params, page })
                                .then((newResponse) => {
                                    const newRespData = newResponse.data || newResponse;

                                    if (newResponse.success) {
                                        fullString += `\n${newRespData.csv}`;
                                        page += 1;

                                        if (page <= maxPage) {
                                            setCSVPercents(calculateProgress(page, offset, totalItemsCount));
                                            makeRequest();
                                        } else {
                                            handleSuccess(fullString);
                                        }
                                    } else {
                                        handleError();
                                    }
                                });
                        };
                        setCSVPercents(calculateProgress(page, offset, totalItemsCount));
                        makeRequest();
                    } else {
                        handleSuccess(csv);
                    }
                } else {
                    handleError();
                }
            })
            .catch(handleError);
    };

    const cancelExporting = () => {
        setCancelImporting(true);
        closeDialogWithDelay(1000);
    };

    const retryCSV = () => {
        setDialogExportingError(false);
        setCSVPercents(0);
        downloadData();
    };

    return (
        <>
            <div className="u-dsp--f u-ai--center u-jc--end u-mrg--tx2">
                <Button
                    className={classes.downloadButton}
                    onClick={exportCSV}
                    disabled={disabled}
                >
                    {!disabled && <img className="u-mrg--rx1" src={DownloadImg} alt="|" />}
                    Download CSV
                </Button>
            </div>
            <ExportingPopup
                open={dialogExporting}
                message={exportMessage || 'Exporting candidates to CSV…'}
                onCancel={cancelExporting}
                error={dialog_exporting_error}
                onRetry={retryCSV}
                canceled={cancelImporting}
                percents={CSVPercents}
            />
        </>
    );
};

export default withStyles(styles)(DownloadCSV);

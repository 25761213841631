import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import ContentAssessmentsView from './ContentAssessmentsView';

import styles from './styles';
import { assessmentListCtx } from './store';


const AssessmentList = observer(({
    classes, handleCreateAssessment,
    getCategories
}) => {
    const { assessments, totalCount,
        setTotalCount, setAssessments,
        setLoadingSearchAssessments, setCurrentPage,
        getPrivateAssessments, loadingSearchAssessments,
        currentPage, query
    } = useContext(assessmentListCtx);


    const handleDuplicate = () => {
        setCurrentPage(currentPage - 1);
        getPrivateAssessments(query);
        window.scrollTo(0, 0);
    };

    const changePage = (page) => {
        setCurrentPage(page - 1);
        setLoadingSearchAssessments(true);
        getPrivateAssessments(query);
    };

    const removeAssessment = (opportunityIndex) => {
        const newAssessments = JSON.parse(JSON.stringify(assessments));
        newAssessments.splice(opportunityIndex, 1);
        setAssessments(newAssessments);
        getCategories();
        setTotalCount(totalCount - 1);
    };

    const otherProps = {
        removeAssessment,
        changePage,
        getCategories,
        handleDuplicate,
        query
    };

    return (
        <div className={classes.boardWrapper}>
            <ContentAssessmentsView
                changePage={changePage}
                handleCreateAssessment={handleCreateAssessment}
                {...otherProps}
            />
            {
                loadingSearchAssessments && (
                    <div className={classes.searchLoaderWrapper}>
                        <CircularProgress size={25} className={classes.loader} />
                    </div>
                )
            }
        </div>
    );
});

export default withRouter(withStyles(styles)(AssessmentList));

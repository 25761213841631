import React from 'react';
import { withTheme } from '@mui/styles';

const TimerIcon = ({ isError, theme, fill = isError ? theme.palette.yellow[900] : '#676a78' }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0013 18.3332C13.9133 18.3332 17.0846 15.1619 17.0846 11.2498C17.0846 7.33782 13.9133 4.1665 10.0013 4.1665C6.08929 4.1665 2.91797 7.33782 2.91797 11.2498C2.91797 15.1619 6.08929 18.3332 10.0013 18.3332Z" stroke={fill} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.67414 8.33313L10.2097 11.1827" stroke={fill} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 1.6665V4.1665" stroke={fill} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.25 5L15 6.24999" stroke={fill} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.082 5.4165L15.832 4.16652" stroke={fill} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.668 1.6665L8.33464 1.6665" stroke={fill} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(TimerIcon);

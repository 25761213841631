import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import TextInput from 'libraries/TextInput';

import styles from './styles';

const Select = (props) => {
    const {
        classes,
        options = [],
        renderValue,
        children,
        childrenAfter,
        maxHeight,
        selectMenuClassName = '',
        menuPaperClassName = '',
        selectIconClassName = '',
        onOpen, onClose,
        ...other
    } = props;
    return (
        <TextInput
            {...other}
            select
            SelectProps={{
                renderValue,
                onOpen,
                onClose,
                classes: {
                    selectMenu: clsx(classes.selectMenu, selectMenuClassName),
                    icon: clsx(classes.icon, selectIconClassName)
                },
                MenuProps: {
                    getContentAnchorEl: null,
                    classes: {
                        paper: clsx(classes.menuPaper, menuPaperClassName)
                    },
                    PaperProps: {
                        style: {
                            maxHeight
                        }
                    }
                },
                IconComponent: KeyboardArrowDown
            }}
        >
            {children}
            {
                options.map(({ value, disabled, label, className = '', ...otherProps }) => (
                    <MenuItem
                        key={value}
                        value={value}
                        disabled={disabled}
                        classes={{
                            root: clsx(classes.menuItem, className),
                            selected: classes.selectedMenuItem
                        }}
                        {...otherProps}
                    >
                        <Typography variant="inherit" noWrap>
                            {label}
                        </Typography>
                    </MenuItem>
                ))
            }
            {childrenAfter}
        </TextInput>
    );
};

export default withStyles(styles)(Select);

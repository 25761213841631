import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import TextInputOutlined from 'libraries/TextInput';

const styles = {
    loadingClass: {
        cursor: 'wait'
    }
};

class AddressAutocomplete extends Component {
    state = {
        value: '',
        loadingGoogleLocation: true
    };

    componentDidMount() {
        const { value, googleAutocomplete } = this.props;
        this.setState({ value: value || '' });
        this.setAutoCompleteOptions(googleAutocomplete);
    }

    componentWillReceiveProps(nextProps) {
        const { googleAutocomplete, value } = this.props;
        if (!value && nextProps.value) {
            this.setState({ value: nextProps.value });
        }
        if (!googleAutocomplete && nextProps.googleAutocomplete) {
            this.setAutoCompleteOptions(nextProps.googleAutocomplete);
        }
    }

    setAutoCompleteOptions = (googleAutocomplete) => {
        if (!googleAutocomplete) return;

        const { onChange } = this.props;

        this.setState({ loadingGoogleLocation: false });

        const input = document.getElementById('addressAutocompleteField');
        const geoAutocomplete = new googleAutocomplete.maps.places.Autocomplete(input);
        geoAutocomplete.addListener('place_changed', () => {
            const selectedPlace = geoAutocomplete.getPlace();
            if (selectedPlace.formatted_address) {
                input.value = `${selectedPlace.formatted_address}`;
                this.setState({ value: selectedPlace.formatted_address });
                onChange(selectedPlace.formatted_address, selectedPlace);
            } else {
                input.value = '';
                this.setState({ value: '' });
                onChange('');
            }
        });
    };

    handleChange = (e) => {
        const { onChange } = this.props;
        const { value } = e.target;
        this.setState({ value });
        onChange(value);
    };

    render() {
        const { value, loadingGoogleLocation } = this.state;
        const {
            classes, placeholder = '',
            className, onChange,
            value: valueProps,
            googleAutocomplete,
            ...other
        } = this.props;
        return (
            <TextInputOutlined
                id="addressAutocompleteField"
                className={`${loadingGoogleLocation ? classes.loadingClass : ''} ${className}`}
                value={value}
                onChange={this.handleChange}
                placeholder={placeholder}
                autocomplete="off"
                {...other}
            />
        );
    }
}

export default withStyles(styles)(AddressAutocomplete);

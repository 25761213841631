import { createContext } from 'react';
import { action, observable } from 'mobx';

class CandidatesStore {
    @observable candidateTabs = [];

    @action setCandidateTabs = () => {
        this.candidateTabs = JSON.parse(localStorage.getItem('candidatesTabs')) || [];
    }

    @action addCandidateTab = (id, name) => {
        if (this.candidateTabs.find(({ id: candidateId }) => candidateId === id)) return;
        const newTab = { name, id };
        this.candidateTabs.push(newTab);
        let storedAssessmentTabs = JSON.parse(localStorage.getItem('candidatesTabs')) || [];
        if (!storedAssessmentTabs) storedAssessmentTabs = [];
        if (!storedAssessmentTabs.find(({ id: candidateId }) => candidateId === id)) {
            storedAssessmentTabs.push(newTab);
        }
        localStorage.setItem('candidatesTabs', JSON.stringify(storedAssessmentTabs));
    };

    @action removeCandidateTab = (id) => {
        const index = this.candidateTabs.findIndex(tab => tab.id === +id);
        if (index !== -1) this.candidateTabs.splice(index, 1);
        let storedAssessmentTabs = JSON.parse(localStorage.getItem('candidatesTabs')) || [];
        if (!storedAssessmentTabs) storedAssessmentTabs = [];
        const storedIndex = storedAssessmentTabs.findIndex(({ id: candidateId }) => candidateId === id);
        storedAssessmentTabs.splice(storedIndex, 1);
        localStorage.setItem('candidatesTabs', JSON.stringify(storedAssessmentTabs));
    };

    @action cleanScript = () => {
        this.candidateTabs = [];
    }
}

export const candidatesStore = new CandidatesStore();
export const candidatesCtx = createContext(candidatesStore);

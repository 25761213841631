import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import TextInputOutlined from 'libraries/TextInput';
import { saveAssessmentSettings } from 'requests/ScriptRequests';
import { appCtx } from 'components/appStore';

import styles from './styles';

let timedIdForJobInput = null;

const InstructionToCandidates = observer(({ classes, slug, defaultValue, setSaving }) => {
    const [instructionToCandidates, setInstructionToCandidates] = useState('');
    const [errorInstructionToCandidates, setErrorInstructionToCandidates] = useState('');

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setInstructionToCandidates(defaultValue || '');
    }, [defaultValue]);

    const onChangeJobDescription = (e) => {
        const { value } = e.target;
        clearTimeout(timedIdForJobInput);
        setInstructionToCandidates(value);
        setErrorInstructionToCandidates('');
        timedIdForJobInput = setTimeout(() => {
            setSaving(true);
            saveAssessmentSettings(slug, { message: value })
                .then(({ success }) => {
                    if (success) {
                        setErrorInstructionToCandidates('');
                        setSaving(false);
                    }
                })
                .catch(({ response }) => {
                    if (response && response.data && response.data.errors) {
                        const { description: errDesctiption } = response.data.errors;
                        setErrorInstructionToCandidates(errDesctiption ? errDesctiption[0].message : '');
                    } else {
                        flashMessage('Something went wrong');
                    }
                });
        }, 500);
    };

    return (
        <div>
            The instructions will appear in the email invitation and the assessment home screen for your candidates.
            <TextInputOutlined
                label="Instructions to Candidate"
                className="u-mrg--tx5 u-mrg--bx0"
                hasCounter
                variant="outlined"
                InputProps={{ classes: { input: classes.instructionsField } }}
                maxLength={300}
                multiline
                helperText={errorInstructionToCandidates}
                isError={Boolean(errorInstructionToCandidates)}
                value={instructionToCandidates}
                onChange={onChangeJobDescription}
                placeholder="Tell your candidate how you would like them to complete the assessment as well as what is expected."
            />
        </div>
    );
});

export default withStyles(styles)(InstructionToCandidates);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Layout from 'components/layout';
import WarningBanner from 'components/assessments_pages/WarningBanner';
import Header from 'components/assessments_pages/MyAssessmentsHeader';
import AssessmentList from 'components/assessments_pages/AssessmentList';
import AssessmentFolders from 'components/assessments_pages/AssessmentFolders';
import { checkHasCompanyFeature } from 'helper/commonFunctions';

import { foldersCtx } from 'components/assessments_pages/AssessmentFolders/store';
import { AssessmentQueryCtx } from 'components/assessments_pages/AssessmentList/queryStore';

import { appCtx } from 'components/appStore';


const MyAssessments = observer(({ company }) => {
    const { activeFolderId, folders, getFolders, addFolder, setActiveFolder, deleteFolder, renameFolder } = useContext(foldersCtx);
    const { flashMessage, loggedUser } = useContext(appCtx);
    const { query } = useContext(AssessmentQueryCtx);
    const hasFolders = checkHasCompanyFeature(company, 'AUDITION_FOLDERS');
    const { isShowLeverUnauthorizedBanner } = company;
    const { roleType } = loggedUser;

    return (
        <Layout>
            {
                isShowLeverUnauthorizedBanner && roleType !== 'team' && (
                    <WarningBanner
                        label={(
                            <>
                                <b>Lever is unauthorized.</b>
                                <Link to="/integrations-management?integration=Lever">Review the integration page</Link>
                            </>
                        )}
                    />
                )
            }
            <Header />
            {
                hasFolders && (
                    <AssessmentFolders
                        folders={folders}
                        getFolders={getFolders}
                        addFolder={addFolder}
                        query={query}
                        privateAssessments
                        deleteFolder={deleteFolder}
                        activeFolderId={activeFolderId}
                        renameFolder={renameFolder}
                        setActiveFolder={setActiveFolder}
                    />
                )
            }
            <Container>
                <AssessmentList key="private" {...{ company, activeFolderId, flashMessage }} />
            </Container>
        </Layout>
    );
});

export default MyAssessments;

import React from 'react';
import { Redirect } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { changeForgottenPassword } from 'requests/LoginRequests';
import TextInputValidated from 'libraries/TextInputValidated';
import { passwordValidateFunction } from 'helper/validationFunctions';
import { withStyles } from '@mui/styles';
import { removeTokensFromLocalStorage } from 'helper/commonFunctions';

import HomepageWrapper from '../common/HomepageWrapper';

import styles from '../common/styles';

const MIN_PASSWORD_LENGTH = 8;

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {},
            errorForm: {}
        };
    }

    componentDidMount() {
        removeTokensFromLocalStorage();
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            const { form: { password } } = this.state;
            return value === password;
        });
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    renderError = (formParam) => {
        const { errorForm } = this.state;
        if (errorForm && errorForm[formParam]) {
            return errorForm[formParam];
        }
        return '';
    };

    renderValue = (value) => {
        const { form } = this.state;
        if (form && form[value]) {
            return form[value];
        }
        return '';
    };

    handleChange = (e) => {
        const { form } = this.state;
        const targetName = e.target.name;
        const targetValue = e.target.value;
        if (targetName === 'password') {
            this.customPasswordValidate(e); // validate password simultaneously
        }
        const updateForm = { ...form, [targetName]: targetValue };
        this.setState({ form: updateForm });
    };

    changePassword = (e) => {
        const { match, history } = this.props;
        const { form } = this.state;
        const { token } = match.params;
        e.preventDefault();
        changeForgottenPassword(JSON.stringify(form), token)
            .then((data) => {
                const { emailConfirmationExpiresAfterMinutes, email, token: newToken, errorForm } = data;
                if (data.mfaToken) {
                    history.push('/mfa-code-verification', { mfaToken: data.mfaToken, emailConfirmationExpiresAfterMinutes });
                } else if (data.success) {
                    history.push(`/welcome?token=${newToken}`);
                } else {
                    const { email: errorEmail, confirm_password, password } = errorForm;
                    if (errorEmail === 'Email was not confirmed' && !confirm_password.length && !password.length) {
                        history.push('/code-verification', { enteredEmail: email, emailConfirmationExpiresAfterMinutes });
                    }
                    this.setState({ errorForm });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) history.push('/login');
            });
    };

    validate = (e) => {
        this.refs[e.target.name].validate(e.target.value);
    };

    customPasswordValidate = (e) => {
        const { value } = e.target;
        const { errorForm } = this.state;
        const newErrorForm = JSON.parse(JSON.stringify(errorForm));
        newErrorForm.password = passwordValidateFunction(value, MIN_PASSWORD_LENGTH);
        this.setState({ errorForm: newErrorForm });
    };

    render() {
        const { classes } = this.props;

        return (
            <HomepageWrapper>
                <h1 className={classes.heading}>Choose new password</h1>
                <ValidatorForm
                    instantValidate={false}
                    onSubmit={this.changePassword}
                >
                    <TextInputValidated
                        variant="outlined"
                        label="Enter new password"
                        fullWidth
                        name="password"
                        type="password"
                        ref="password"
                        value={this.renderValue('password')}
                        formerror={this.renderError('password')}
                        onChange={this.handleChange}
                        onBlur={this.validate}
                        validators={[
                            'required'
                        ]}
                        errorMessages={[
                            'Please enter the new password.'
                        ]}
                    />
                    <TextInputValidated
                        variant="outlined"
                        label="Confirm password"
                        fullWidth
                        name="confirm_password"
                        type="password"
                        ref="confirm_password"
                        onBlur={this.validate}
                        value={this.renderValue('confirm_password')}
                        formerror={this.renderError('confirm_password')}
                        onChange={this.handleChange}
                        validators={['required', 'isPasswordMatch']}
                        errorMessages={['Please confirm the new password.', 'Passwords do not match.']}
                    />
                    <Button
                        className={classes.signInButton}
                        color="primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        onClick={this.changePassword}
                    >
                        Submit
                    </Button>
                </ValidatorForm>
            </HomepageWrapper>
        );
    }
}

export default withStyles(styles)(ChangePassword);

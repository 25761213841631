import React from 'react';
import MediaPlayer from 'components/media_player';
import { withStyles } from '@mui/styles';
import styles from './styles';

const AnswerOptionMedia = ({ answer: { ziggeo, type }, classes }) => {
    const handleClick = (e) => {
        e.stopPropagation();
    };

    if (!ziggeo) return null;

    return (
        <div role="contentinfo" className={classes.wrapper} onClick={handleClick}>
            <MediaPlayer ziggeo={ziggeo} audio={type === 3} classNameWrapper={classes.player} />
        </div>
    );
};

export default withStyles(styles)(AnswerOptionMedia);

import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import TextInput from 'libraries/TextInput';
import Button from '@mui/material/Button';
import { addHiringManager, editHiringManager } from 'requests/CandidatesRequests';
import HiredEvents from 'events/HiredEvents';
import { appCtx } from '../../../../appStore';

import styles from '../../styles';

const EditManagerForm = observer(({ classes, setEditOpen,
    hiringManagers, setHiringManagers, assessmentSlug, dataForEvents,
    existingManager, getHiringManagers, setSelected, setManagerDeleted }) => {
    const { flashMessage, loggedUser: { email: loggedEmail } } = useContext(appCtx);
    const [loading, setLoading] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [addingManagerErr, setAddingManagerErr] = useState({});

    useEffect(() => {
        if (existingManager) {
            setFullName(existingManager.fullName);
            setEmail(existingManager.email);
        }
    }, [existingManager]);

    const { ttName, ttId } = dataForEvents;
    const dataForEvent = { ttName, ttId };

    const addNewHiringManager = (e) => {
        e.stopPropagation();
        if (email.trim() === loggedEmail) {
            flashMessage(`Hiring manager with email ${email} already exists!`, 'error');
            return;
        }
        setLoading(true);
        addHiringManager(assessmentSlug, email, fullName)
            .then(({ data, success }) => {
                if (data && success) {
                    setHiringManagers([...hiringManagers, data]);
                    setEditOpen(false);
                    setSelected(data);
                }
                dataForEvent.hiringManagerName = fullName;
                dataForEvent.hiringManagerEmail = email;
                HiredEvents.HIRING_MANAGER_ADDED(dataForEvent);
            })
            .catch(({ response }) => {
                const { data: { msg } } = response;
                flashMessage(msg, 'error');
            })
            .finally(() => setLoading(false));
    };

    const editManager = (e) => {
        e.stopPropagation();
        setLoading(true);
        const { uuid } = existingManager;
        editHiringManager(uuid, email, fullName)
            .then(({ data, success }) => {
                if (data && success) {
                    if (getHiringManagers) getHiringManagers();
                    setEditOpen(false);
                    setManagerDeleted(true); // this is for refreshing page after onClose of AssignHiringManager dialog;
                    flashMessage('Hiring Manager details updated');
                    dataForEvent.oldHiringManagerName = existingManager.fullName;
                    dataForEvent.newHiringManagerName = fullName;
                    dataForEvent.oldHiringManagerEmail = existingManager.email;
                    dataForEvent.newHiringManagerEmail = email;
                    dataForEvent.hiringManagerId = existingManager.uuid;
                    HiredEvents.HIRING_MANAGER_EDITED(dataForEvent);
                }
            })
            .catch(({ response }) => {
                const { data: { msg } } = response;
                flashMessage(msg, 'error');
            })
            .finally(() => setLoading(false));
    };

    const handleCancel = (e) => {
        e.stopPropagation();
        setEditOpen(false);
    };

    return (
        <div className={clsx(classes.inputsContainer, existingManager && classes.editManagerForm)}>
            <TextInput
                label="Full Name"
                variant="outlined"
                value={fullName}
                onClick={e => e.stopPropagation()}
                onChange={(e) => { setFullName(e.target.value); }}
                fullWidth
                isError={Boolean(addingManagerErr.fullName)}
                helperText={Boolean(addingManagerErr.fullName) && addingManagerErr.fullName[0].message}
                name="name"
                margin="none"
            />
            <TextInput
                label="Email"
                variant="outlined"
                value={email}
                onClick={e => e.stopPropagation()}
                onChange={(e) => { setEmail(e.target.value); }}
                fullWidth
                isError={Boolean(addingManagerErr.email)}
                helperText={Boolean(addingManagerErr.email) && addingManagerErr.email[0].message}
                name="email"
                margin="none"
            />
            <div className="u-dsp--f u-jc--end">
                { existingManager
                    ? <>
                        <Button
                            className={classes.cancelButton}
                            color="primary"
                            onClick={handleCancel}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="u-wdt--100"
                            color="primary"
                            variant="contained"
                            onClick={editManager}
                            disabled={loading || !email || !fullName}
                        >
                            Save
                        </Button>
                    </> : (
                        <Button
                            className="u-wdt--100"
                            variant="outlined"
                            onClick={addNewHiringManager}
                            disabled={loading || !email || !fullName}
                        >
                        Add
                        </Button>
                    )}
            </div>
        </div>
    );
});

export default withStyles(styles)(EditManagerForm);

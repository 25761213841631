import React from 'react';
import TextInputOutlined from 'libraries/TextInput';
import { returnHelperTextForArray } from 'helper/codeChallenge';

const ArrayInput = ({ type, paramIndex, value, onChangeValue, isOutput }) => {
    const splitted = value ? value.split('\n') : [''];
    let values = [];
    if (isOutput) {
        values = [splitted.join(' ')];
    } else {
        const firstValue = splitted[0];
        splitted.shift();
        values = [firstValue, splitted.join(' ')];
    }


    const handleChange = (e) => {
        if (e.target.name === 'length') {
            onChangeValue(`${e.target.value}\n${values[1].replace(/ /g, '\n')}`, paramIndex);
        } else {
            onChangeValue(`${isOutput ? '' : `${values[0]}\n`}${e.target.value.replace(/ /g, '\n')}`, paramIndex);
        }
    };

    return (
        <div className="u-dsp--f u-jc--sb u-ai--start">
            {!isOutput && (
                <TextInputOutlined
                    name="length"
                    label="Array Length"
                    className="u-mrg--rx4"
                    style={{ maxWidth: 130 }}
                    variant="outlined"
                    value={values[0]}
                    type="number"
                    min={1}
                    maxLength={2}
                    onChange={handleChange}
                />
            )}
            <TextInputOutlined
                name="array"
                label="Array"
                value={values[isOutput ? 0 : 1]}
                variant="outlined"
                helperText={returnHelperTextForArray(type)}
                onChange={handleChange}
            />
        </div>
    );
};

export default ArrayInput;

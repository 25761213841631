import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import VideoPlayer from './video_player';
import Player from '../ziggeo/player';


const PREFIX = 'MediaPlayer';

const classes = {
    wrapperForSmall: `${PREFIX}-wrapperForSmall`
};

const Root = styled('div')(({ theme }) => ({
    textAlign: 'center',
    [`&.${classes.wrapperForSmall}`]: {

        '& .ziggeo_player': {
            minHeight: 'auto !important',
            maxHeight: '260px !important',
            '& .ba-videoplayer-video': {
                maxHeight: 259,
                maxWidth: '100%',
                [theme.breakpoints.down('lg')]: {
                    maxHeight: 163
                },
                [theme.breakpoints.down('sm')]: {
                    maxHeight: 173
                }
            }
        },

        '& .ziggeo_player_audio': {
            maxHeight: 259,
            width: '302px !important',
            [theme.breakpoints.down('lg')]: {
                maxHeight: 163,
                width: '208px !important'
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight: 173,
                width: '234px !important'
            },
            maxWidth: '100%',
            '& .ba-videoplayer-video': {
                display: 'none'
            }
        },

        '& .video_player': {
            maxHeight: 260,
            '& video': {
                maxHeight: 260
            },
            '&.audioType': {
                '& video': {
                    minWidth: 260,
                    [theme.breakpoints.down('lg')]: {
                        minWidth: 225
                    }
                }
            },
            '& audio': {
                minWidth: 300
            },
            [theme.breakpoints.down('lg')]: {
                maxHeight: 163,
                '& video': {
                    maxHeight: 163
                },
                '& audio': {
                    minWidth: 220
                }
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight: 173,
                '& video': {
                    maxHeight: 173
                },
                '& audio': {
                    minWidth: 245
                }
            }
        },
        '& .ba-audioplayer-container.ba-player-no-title': {
            minWidth: 'auto'
        }
    }
}));


const MediaPlayer = ({ ziggeo, audio, small, className, classNameWrapper, height, width }) => {
    if (!ziggeo || !(ziggeo.media_file_url || ziggeo.token)) return null;

    const classNames = clsx(audio && 'audioType', className);

    return (
        <Root className={clsx(small && classes.wrapperForSmall)}>
            {ziggeo.media_file_url ? (
                <VideoPlayer
                    audio={audio}
                    ziggeo={ziggeo}
                    className={classNames}
                    classNameWrapper={classNameWrapper}
                />
            ) : (
                <Player
                    small={small}
                    audio={audio}
                    width={width}
                    height={height}
                    className={classNames}
                    ziggeo_token={ziggeo.token}
                    classNameWrapper={classNameWrapper}
                />
            )
            }
        </Root>
    );
};

export default MediaPlayer;

import { tracking } from 'helper/eventSegment';

export default {
    GUIDE_CLICKED: () => {
        tracking('SG-guide-clicked');
    },
    INVITE_CANDIDATES_CLICKED: () => {
        tracking('SG-invite-candidates-clicked');
    },
    INVITE_TEAM_CLICKED: () => {
        tracking('SG-invite-team-clicked');
    },
    GRADE_CLICKED: () => {
        tracking('SG-grade-clicked');
    }
};

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/Tooltip';

import CheatIcon from 'img/icons/cheatFlag.svg';

import { ButtonBase } from '@mui/material';
import PlagiarismDialog from 'components/dialogs/PlagiarismDialog';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { PLAGIARISM_DETECTION } from 'helper/constants';
import QuestionIcon from 'img/icons/questionIcon.svg';
import { getCandidatePlagiarismDetectionData } from 'requests/AssessmentRequests';
import clsx from 'clsx';
import styles from '../styles';

const CheatDetectedBanner = observer(({ candidate, classes, audition, company, getUserInfo }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [plagiarismData, setPlagiarismData] = useState([]);
    const { plagiarismDetected, userAuditionId } = candidate;

    const hasCompanyPlagiarismDetection = checkHasCompanyFeature(company, PLAGIARISM_DETECTION);

    useEffect(() => {
        if (plagiarismDetected) {
            getCandidatePlagiarismDetectionData(userAuditionId).then(({ data }) => {
                setPlagiarismData(data);
            });
        }
    }, [candidate]);

    if (!plagiarismDetected || !hasCompanyPlagiarismDetection || !plagiarismData.length) return null;

    const toggleDialog = () => {
        setOpenDialog(!openDialog);
    };

    return (
        <>
            <div className={clsx(classes.wrapper, 'red-bg')}>
                <img src={CheatIcon} alt="!" />
                <div>
                    {plagiarismData.map((el, idx) => <>{idx !== 0 && ', '}Question {el.questionResult.sort}</>)} has been flagged as plagiarism.
                    <ButtonBase classes={{ root: classes.action }} onClick={toggleDialog}>
                        Review Answers
                    </ButtonBase>
                </div>
                <Tooltip label="Similar Answer detection is activated if Vervoe detects a string of words exactly matching another candidates answer.">
                    <img src={QuestionIcon} alt="?" />
                </Tooltip>
            </div>
            <PlagiarismDialog
                open={openDialog}
                audition={audition}
                candidate={candidate}
                handleClose={toggleDialog}
                plagiarismData={plagiarismData}
                getUserInfo={getUserInfo}
            />
        </>
    );
});

export default withStyles(styles)(withRouter(CheatDetectedBanner));

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'static',
        height: 64,
        background: '#f5f5f5',
        boxShadow: 'none',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    toolbar: {
        justifyContent: 'space-between'
    },
    left: {
        display: 'flex',
        alignItems: 'center'
    },
    right: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export default useStyles;

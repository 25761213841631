import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TextInputOutlined from 'libraries/TextInput';
import ContentAccordition from '../ContentAccordition';

const styles = () => ({
    wrapper: {
        position: 'relative',
        marginBottom: 30
    },
    tooltip: {
        textAlign: 'center',
        width: 210
    },
    warningText: {
        fontWeight: 500,
        fontSize: 12,
        margin: '7px 0'
    }
});

let timerId = null;

const ScoringInstructions = ({
    classes, isCMS, changeQuestion, question: {
        scoringInstruction: questionScoringInstruction
    }, disabled
}) => {
    const [scoringInstruction, setScoringInstruction] = useState(questionScoringInstruction);

    const onChange = (e) => {
        const { value } = e.target;
        setScoringInstruction(value);
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            changeQuestion({ scoringInstruction: value });
        }, 300);
    };

    const header = <Typography variant="h6">Scoring instructions</Typography>;
    const content = (
        <>
            <div className={classes.warningText}>
                Scoring Instructions give your team guidance on what you value in a candidates answer. These will be visible in question grading
            </div>
            <TextInputOutlined
                label="Team scoring instructions"
                value={scoringInstruction}
                multiline
                variant="outlined"
                maxRows={6}
                onChange={onChange}
                disabled={disabled}
                maxLength={5000}
                placeholder="e.g “If answer doesn’t mention inbound sales, should get below a 4.”"
            />
        </>
    );

    if (isCMS) {
        return (
            <ContentAccordition
                header={header}
                content={content}
                className={classes.accordion}
            />
        );
    }

    return (
        <div className={classes.wrapper}>
            {header}
            <br />
            {content}
        </div>
    );
};

export default withStyles(styles)(ScoringInstructions);

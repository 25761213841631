export const skillChipStyles = theme => ({
    chip: {
        paddingLeft: 14,
        paddingRight: 14,
        height: 32,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        backgroundColor: theme.palette.blue[600],
        cursor: 'pointer',
        '& span': {
            padding: 0,
            fontSize: 12,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: '#fff',
            display: 'flex',
            alignItems: 'center'
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        },
        '&:focus': {
            backgroundColor: theme.palette.primary.dark
        },
        '&$chipDisabled': {
            opacity: 1
        }
    },
    chipDisabled: {},
    title: {
        maxWidth: 220,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
});

export const serverChipStyle = theme => ({
    serverChip: {
        lineHeight: 1,
        marginBottom: 10,
        marginRight: 10,
        fontSize: 12,
        color: '#fff',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        backgroundColor: '#1f243d',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(31, 36, 61, 0.7)',
            color: '#708bed',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'
        },
        '&:focus': {
            backgroundColor: '#1f243d'
        },
        '& span': {
            color: '#ffffff',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center'
        }
    },
    serverChipLight: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[900],
        '&:hover': {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.grey[900],
            boxShadow: 'none'
        },
        '&:focus': {
            backgroundColor: '#f3f3f3'
        },
        '& span': {
            color: theme.palette.grey[900],
            textTransform: 'uppercase',
            fontWeight: 'bold'
        }
    }
});

export const addChipStyle = {
    addChip: {
        cursor: 'pointer',
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: '#f3f3f3',
        color: '#000',
        '& span': {
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            color: '#000',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'
        },
        '&:focus': {
            backgroundColor: '#f3f3f3'
        }
    }
};

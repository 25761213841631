import React from 'react';
import EditPanelSection from 'components/assessments_pages/asessment_settings_components/EditPanelSection';
import GroupRandOption from './GroupRandOption';


const SkillGroupRandomization = ({ questionsGroups, changeSkillGroup, editSkillGroup }) => (
    <EditPanelSection header="Skill Group Randomization" className="u-brdr--none">
        <p>Randomize a number of questions for each skill group</p>
        {
            questionsGroups.map((group, index) => (
                <GroupRandOption
                    groupIndex={index}
                    changeSkillGroup={changeSkillGroup}
                    key={group.id}
                    group={group}
                    editSkillGroup={editSkillGroup}
                />
            ))
        }
    </EditPanelSection>
);

export default SkillGroupRandomization;

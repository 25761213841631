export default {
    editButton: {
        position: 'absolute',
        width: 160,
        height: 40,
        borderRadius: 4,
        boxShadow: '0 3px 15px 0 rgba(144, 144, 144, 0.17);',
        backgroundColor: '#ffffff',
        color: '#1a1b1e',
        fontSize: 14,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        bottom: 15,
        right: 16,
        paddingLeft: 10,
        zIndex: 2
    },
    codeEditorWrapper: {
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
        height: 406,
        backgroundColor: '#282b34',
        padding: '50px 20px',
        '@media only screen and (max-width: 767px)': {
            height: 260
        }
    }
};

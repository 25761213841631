import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import TextInput from 'libraries/TextInput';
import Select from 'libraries/Select';
import { createContentLibraryAssessment } from 'requests/CMSRequests';

import styles from './styles';

const MAX_NAME_LENGTH = 100;


const ActivateAssessmentDialog = ({ open, classes, onClose, history, categories }) => {
    const [nameValue, setNameValue] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('-');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedCategoryId('-');
        setNameValue('');
    }, [open]);

    const createAssessment = () => {
        setLoading(true);
        createContentLibraryAssessment(nameValue, selectedCategoryId === '-' ? null : selectedCategoryId)
            .then(({ success, data }) => {
                if (success && data) {
                    const { uuid } = data;
                    history.push(`/content-library/${uuid}/questions`);
                    onClose();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleName = (e) => {
        const { value } = e.target;
        setNameValue(value);
    };

    const handleCategory = (e) => {
        const { value } = e.target;
        setSelectedCategoryId(value);
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}
            handleClose={onClose}
            contentClassName={classes.contentClassName}
            titleComponent="Create Library Assessment"
            actionComponent={(
                <>
                    <Button
                        className={classes.button}
                        color="primary"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <Button
                        className={classes.duplicateButton}
                        color="primary"
                        disabled={loading || nameValue.length < 2}
                        variant="contained"
                        onClick={createAssessment}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : 'Create Assessment'
                        }
                    </Button>
                </>
            )}
        >
            <TextInput
                value={nameValue}
                label="Assessment name"
                onChange={handleName}
                maxLength={MAX_NAME_LENGTH}
                hasCounter
                placeholder="What is the assessment name"
            />
            <Select
                value={selectedCategoryId}
                name="size"
                onChange={handleCategory}
                label="Category"
                placeholder="Select category"
                fullWidth
                variant="outlined"
                menuPaperClassName={classes.selectPaper}
                options={[{ label: 'No category', value: '-' }]
                    .concat(
                        categories
                            .filter(({ id }) => id !== -1)
                            .map(({ title, uuid }) => ({ label: title, value: uuid }))
                    )
                }
            />
        </Dialog>
    );
}

export default withStyles(styles)(withRouter(ActivateAssessmentDialog));

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from 'libraries/Dialog';
import { checkPluralNew } from 'helper/commonFunctions';

import Icon from './icon.svg';

import styles from './styles';


const FinishDialog = observer(({ classes, open, handleClose, candidatesLength }) => (
    <Dialog
        open={open}
        maxWidth="sm"
        onClose={handleClose}
        handleClose={handleClose}
        contentClassName={classes.content}
    >
        <img src={Icon} alt="done" className="u-mrg--bx2" />
        <Typography variant="h3" className="u-mrg--bx2">
            New {checkPluralNew(candidatesLength, 'candidate')} hired!
        </Typography>
        <p className={classes.text}>
            Your hired candidates can be found in <Link to="/hired" className={classes.link}>Hired</Link>.&nbsp;
            You can<br />update or add your candidate hired information anytime.
        </p>
        <Button
            color="primary"
            className="u-txt--bold"
            onClick={handleClose}
        >
            Close
        </Button>
    </Dialog>
));

export default withStyles(styles)(withRouter(FinishDialog));

import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import DrawerPageWrapper from 'components/layout/DrawerPageWrapper';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';
import { appCtx } from 'components/appStore';
import { checkHasUserPermission } from 'helper/commonFunctions';
import EditQuestions from './edit_questions';


const EditAssessmentWrapper = observer(({
    children, match, ...other
}) => {
    const [loading, setLoading] = useState(false);
    const { company } = useContext(appCtx);
    const { audition } = useContext(assessmentSettingsCtx);
    const { permissions } = audition;
    const edit = checkHasUserPermission(company, permissions, 'edit');

    return (
        <DrawerPageWrapper
            {...other}
            loading={loading}
            setLoading={setLoading}
        >
            {
                (!audition.archived && edit) ? (
                    <EditQuestions />
                ) : (
                    <PreviewScript
                        isDialog={false}
                        hideHeader
                        interviewSlug={match.params.scriptSlug}
                        isClosedAssessment
                    />
                )
            }

        </DrawerPageWrapper>
    );
});

export default withRouter(EditAssessmentWrapper);

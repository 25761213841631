import { createContext } from 'react';
import { action, observable } from 'mobx';
import { getListOfPopularSkills, updateSkill } from 'requests/CMSRequests';
import { appStore } from '../../../components/appStore';

const getDataOfSkills = (page, filterCategory, query) => {
    if (filterCategory !== 'all') {
        return { page, popular: filterCategory === 'popular' ? 1 : 0, query };
    }
    return { page, query };
};

class SkillsStore {
    @observable allSkills = [];

    @observable totalCount = 0;

    @observable filterCategory = 'all';

    @observable query = '';

    @observable page = 1;

    @observable isLoading = false;

    @observable timerId = null;

    constructor() {
        this.initFilterFromLocalStorage();
    }

    @action setLoading = (value) => {
        this.isLoading = value;
    }

    @action loadSkills = () => {
        getListOfPopularSkills(getDataOfSkills(this.page, this.filterCategory, this.query)).then(({ success, data }) => {
            if (success && data) {
                const { items, total, currentPage } = data;
                this.allSkills = items;
                this.totalCount = total;
                this.page = currentPage;
            }
        }).finally(() => {
            this.isLoading = false;
        });
    }

    @action handleClickPopular = async (id, isPopular) => {
        const { data } = await updateSkill(id, { popular: isPopular });
        if (data?.id) {
            this.allSkills = this.allSkills.map(skill => (skill.id === data.id ? data : skill));
            const text = data.popular ? 'added to' : ' removed from';
            appStore.flashMessage(`Skill ${text} popular `, 'done');
        }
    }

    @action handleChangeSearch = (e) => {
        const { value } = e.target;
        this.query = value;
        this.page = 1;
        this.isLoading = true;
        clearTimeout(this.timerId);
        this.timerId = setTimeout(async () => {
            await this.loadSkills();
        }, 300);
    }

    @action handleChangeFilterSelect = (filterCategory = 'all') => {
        this.isLoading = true;
        this.filterCategory = filterCategory;
        this.page = 1;
        this.loadSkills();
        this.saveFilterToStorage(filterCategory);
    }

    @action handleChangePage = (page) => {
        this.isLoading = true;
        this.page = page;
        this.isLoading = true;
        this.loadSkills();
    }

    @action cleanStore = () => {
        this.allSkills = [];
        this.totalCount = 0;
        this.page = 1;
        this.isLoading = false;
    }

    initFilterFromLocalStorage = () => {
        const value = localStorage.getItem('popular_skills_filter');
        this.filterCategory = value || 'all';
    }

    saveFilterToStorage = (filterCategory) => {
        localStorage.setItem('popular_skills_filter', filterCategory);
    }
}

export const skillsStore = new SkillsStore();
export const skillsCtx = createContext(skillsStore);

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStyles } from './styles';
import FakeHeader from './components/fake_header';
import DialogHeader from './components/dialog_header';
import Tickets from './components/tickets';
import ActiveTicket from './components/active_ticket';
import TicketInfoPanel from './components/ticket_info_panel';


const DialogContent = observer(({
    question, toggleDialogOpen, simulatorOpen,
    candidateName, interview
}) => {
    const { wrapper, contentWrapper, content } = useStyles({});
    const [activeTicketIndex, setActiveTicketIndex] = useState(0);
    const { validCsSimulatorTickets, validKnowledgeBaseArticles } = question;

    useEffect(() => {
        if (!validCsSimulatorTickets) return;
        let activeIndex = validCsSimulatorTickets.findIndex(el => !el.hasCheckedAnswer);
        if (activeIndex === -1) activeIndex = 0;
        setActiveTicketIndex(activeIndex);
    }, [simulatorOpen]);

    if (!validCsSimulatorTickets) return null;

    const activeTicket = validCsSimulatorTickets[activeTicketIndex];

    return (
        <div className={wrapper}>
            <FakeHeader />
            <div className={contentWrapper}>
                <DialogHeader
                    toggleDialogOpen={toggleDialogOpen}
                    question={question}
                    interview={interview}
                />
                <div className={content}>
                    <Tickets
                        tickets={validCsSimulatorTickets}
                        activeTicket={activeTicket ? activeTicket.id : null}
                        setActiveTicketIndex={setActiveTicketIndex}
                    />
                    <ActiveTicket
                        candidate={candidateName}
                        ticket={activeTicket}
                    />
                    <TicketInfoPanel
                        articles={validKnowledgeBaseArticles}
                        customer={activeTicket ? activeTicket.customer : {}}
                        orders={activeTicket ? activeTicket.csSimulatorOrders : []}
                    />
                </div>
            </div>
        </div>
    );
});

export default DialogContent;

export default ({
    dialog: {},
    icon: {
        marginBottom: 20
    },
    dialogContent: {
        paddingTop: 70,
        paddingBottom: 60,
        textAlign: 'center',
        lineHeight: '28px'
    },
    successHeader: {
        marginBottom: 10
    }
});

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';

import styles from './styles';

const ContentWrapper = ({
    children, classes, className, loading, ...other
}) => (
    <div className={clsx(classes.contentWrapper, loading && classes.contentWrapperEmpty, className)} {...other}>
        {children}
    </div>
);

export default withStyles(styles)(ContentWrapper);

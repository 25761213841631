import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    tableRow: {
        cursor: 'pointer'
    },
    statusDate: {
        color: theme.palette.grey[500],
        fontSize: 12,
        marginLeft: 10,
        display: 'inline-block',
        minWidth: 30
    },
    tableCell: {
        borderColor: `${theme.palette.grey[300]}!important`
    }
}));

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';

import { reverseAutoGradedSpreadsheetTestCaseResultOutcome } from 'requests/JobOpportunityRequests';
import GeneralEvents from 'events/GeneralEvents';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import DoneIcon from 'components/icons/DoneIcon';
import CloseIcon from 'components/icons/CloseIcon';
import CrossIcon from 'img/close_round.svg';
import CheckIcon from 'img/check_round.svg';
import { appCtx } from '../../../../appStore';


const styles = {
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '20px',
        letterSpacing: '0.15px',
        padding: '8px 5px',
        marginRight: 7
    },
    scoreWrapper: {
        fontSize: 12,
        textAlign: 'right',
        whiteSpace: 'nowrap',
        marginLeft: 7
    },
    loaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: 4
    },
    rowHead: {
        width: '25%'
    },
    value: {
        width: '25%',
        paddingRight: 7,
        paddingLeft: 15,
        fontWeight: 500,
        '& > *': {
            lineBreak: 'anywhere'
        }
    },
    resultBlock: {
        display: 'flex',
        alignItems: 'center',
        width: '25%'
    }
};

const TestCase = observer(({
    item, classes, setGradeQuestion, audition,
    candidate, propPublic, updateUserAuditionAndBox, canGrade,
    isIntegration
}) => {
    if (!item) return null;
    const { testPassed, resultCellColumn,
        resultCellRow, sheetName, score, id,
        expectedResults, actualResult
    } = item;
    if (testPassed === null) return null;

    const [loading, setLoading] = useState(false);
    const [testDone, setTestDone] = useState(null);
    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setTestDone(testPassed);
    }, [testPassed]);

    const reverseTestPassedResult = () => {
        setLoading(true);
        reverseAutoGradedSpreadsheetTestCaseResultOutcome(id)
            .then(({ success, data }) => {
                if (success && data) {
                    const { question: { questionUuid, sort } } = data;
                    setTestDone(!testDone);
                    flashMessage('Value changed', 'done');
                    GeneralEvents.CANDIDATE_SPREADSHEET_TEST_CASES_RESULT_CHANGED({
                        candidateUUID: candidate.uuid,
                        ttUUID: audition.uuid,
                        ttName: audition.name,
                        questionUUID: questionUuid,
                        questionNumber: sort,
                        oldTestCaseScore: testDone ? score : 0,
                        newTestCaseScore: testDone ? 0 : score,
                        sheetName
                    });
                    if (setGradeQuestion) setGradeQuestion(data);
                    if (updateUserAuditionAndBox) updateUserAuditionAndBox(candidate.id);
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const returnStatusIcon = () => {
        if (loading) return <div className={classes.loaderWrapper}><CircularProgress size={16} /></div>;
        if (testDone) return <DoneIcon color="#00B898" />;
        return <CloseIcon />;
    };

    const returnStatusColor = () => {
        if (loading) return '#a9aaae';
        if (testDone) return '#00B898';
        return '#ff5e5e';
    };

    return (
        <ListItem className={classes.item}>
            <div className={classes.rowHead}>
                <span>{sheetName}</span>
                <span className="u-mrg--lx2">{resultCellColumn + resultCellRow}</span>
            </div>
            <div className={classes.value}>
                {actualResult}
            </div>
            <div className={classes.value}>
                {expectedResults?.map(expectedResult => <div>{expectedResult}</div>)}
            </div>
            <div className={classes.resultBlock}>
                {returnStatusIcon()}
                <div
                    className={classes.scoreWrapper}
                    style={{ color: returnStatusColor() }}
                >
                    {`+${testDone ? score : 0}/${score} PTS`}
                </div>
                {!propPublic && canGrade && (
                    <BoardItemMenu
                        disabled={loading}
                        iconMenuClassName="u-mrg--lx1"
                        options={[
                            {
                                label: <>
                                    <img src={testDone ? CrossIcon : CheckIcon} alt="" />
                                    {testDone ? 'Mark as incorrect' : 'Mark as correct'}
                            </>,
                                onClick: reverseTestPassedResult,
                                disabled: loading
                            }]}
                    />
                )}
            </div>
        </ListItem>
    );
});

export default withStyles(styles)(TestCase);

import React from 'react';

// material components
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';

// local files
import styles from './styles';

const EmptyResult = ({ classes: { wrapperStyle } }) => (
    <div className={wrapperStyle}>
        <IconButton size="large">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <path fill="#00A0FC" fillRule="evenodd" d="M35.938 50.008c-7.767 0-14.063-6.296-14.063-14.062 0-7.767 6.296-14.063 14.063-14.063C43.703 21.883 50 28.18 50 35.946c0 7.766-6.296 14.062-14.063 14.062zm0-3.125c6.04 0 10.937-4.897 10.937-10.937 0-6.04-4.897-10.938-10.938-10.938-6.04 0-10.937 4.897-10.937 10.938 0 6.04 4.897 10.937 10.938 10.937zm4.32-15.51a1.563 1.563 0 0 1 2.5 1.875l-6.052 8.072a3.125 3.125 0 0 1-4.709.333l-3.125-3.125a1.563 1.563 0 0 1 2.21-2.21l3.125 3.125 6.051-8.07zm-23.07 9.26a1.563 1.563 0 0 1 0 3.125h-12.5A4.687 4.687 0 0 1 0 39.071V4.696A4.688 4.688 0 0 1 4.688.008H26.83c1.243 0 2.436.493 3.316 1.373l5.981 5.981a4.687 4.687 0 0 1 1.372 3.315v6.519a1.562 1.562 0 1 1-3.125 0v-6.52c0-.414-.164-.812-.457-1.104l-5.98-5.981a1.563 1.563 0 0 0-1.107-.458H4.688c-.863 0-1.563.7-1.563 1.563V39.07c0 .863.7 1.562 1.563 1.562h12.5zm.457-27.02a1.562 1.562 0 1 1 2.21-2.21l4.687 4.688c.61.61.61 1.6 0 2.21l-4.687 4.687a1.562 1.562 0 1 1-2.21-2.21l3.583-3.582-3.583-3.583zm-4.04 7.165a1.562 1.562 0 1 1-2.21 2.21l-4.687-4.687c-.61-.61-.61-1.6 0-2.21l4.687-4.688a1.562 1.562 0 1 1 2.21 2.21l-3.583 3.583 3.583 3.582z" />
            </svg>
        </IconButton>
        <h3
            className="u-txt--center t-txt--white u-txt--bold u-mrg--x0 u-txt--16"
        >
            You have no test cases. Add some.
        </h3>
        <p
            className="u-txt--center t-txt--white u-txt--14"
        >
            Autograde candidate code using unit tests.
        </p>
    </div>
);

export default withStyles(styles)(EmptyResult);

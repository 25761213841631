import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import ReactPlayer from 'react-player/lazy';
import { styled } from '@mui/material/styles';

const PREFIX = 'VideoPlayer';
const classes = {
    videoWrapper: `${PREFIX}-videoWrapper`,
    audioWrapper: `${PREFIX}-audioWrapper`
};

const Root = styled('div')(() => ({
    position: 'relative',
    '& .react-player__preview': {
        backgroundSize: 'contain !important',
        backgroundRepeat: 'no-repeat'
    },

    [`& .${classes.videoWrapper}`]: {
        '& video': {
            maxWidth: '100%'
        }
    },
    [`& .${classes.audioWrapper}`]: {
        '& video': {
            minWidth: 400,
            border: '2px solid #EBECEE',
            borderRadius: 4
        },
        '& audio': {
            minWidth: 400,
            minHeight: 50
        }
    }
}));


const VideoPlayer = ({ ziggeo, audio, className = '', classNameWrapper = '' }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => () => {
        setIsPlaying(false);
    }, [ziggeo?.media_file_url]);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    if (!ziggeo?.media_file_url) return null;

    const { media_file_url, cover_url } = ziggeo;

    const coverShown = cover_url && !isPlaying;

    return (
        <Root
            sx={coverShown ? {
                width: '100%',
                paddingTop: '56.25%'
            } : {}}
            className={classNameWrapper}
        >
            <ReactPlayer
                url={media_file_url}
                controls
                width={coverShown ? '100%' : 'auto'}
                height="100%"
                playing={isPlaying}
                light={cover_url || false}
                onClickPreview={handlePlay}
                className={clsx(classes.videoWrapper, audio && classes.audioWrapper, 'video_player', className)}
                config={{
                    file: {
                        attributes: { controlsList: 'nodownload' }
                    }
                }}
                style={coverShown ? { position: 'absolute', top: 0, left: 0 } : {}}
            />
        </Root>
    );
};

export default VideoPlayer;

export default theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    title: {
        marginRight: 10,
        fontSize: 10,
        lineHeight: '24px'
    },
    closeButton: {
        padding: 0,
        position: 'absolute',
        right: 10,
        cursor: 'pointer',
        top: 8,
        '& svg': {
            width: 10,
            height: 10
        }
    },
    filterParam: {
        padding: '4px 31px 4px 16px',
        background: theme.palette.grey[300],
        marginRight: 10,
        marginBottom: 20,
        borderRadius: 4,
        '& > span': {
            fontWeight: 700,
            fontSize: 11,
            color: theme.palette.grey[900]
        }
    }
});

export default theme => ({
    item: {
        borderRadius: 6,
        cursor: 'pointer',
        margin: '0px 2.5% 20px',
        display: 'flex',
        alignItems: 'flex-start',
        color: theme.palette.grey[900],
        flexDirection: 'column',
        width: '45%',
        backgroundColor: theme.palette.grey[200],
        border: `2px solid ${theme.palette.grey[200]}`,
        overflow: 'hidden',
        minWidth: 290,
        padding: 20,
        '&:hover': {
            backgroundColor: theme.palette.grey[300]
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: '0px 0 20px'
        }
    },
    itemSelected: {
        backgroundColor: theme.palette.blue[50],
        '&:hover': {
            backgroundColor: theme.palette.blue[50]
        },
        border: `2px solid ${theme.palette.blue[600]}`
    },
    price: {
        fontSize: 40,
        fontWeight: 700
    },
    priceWrapper: {
        position: 'relative',
        fontSize: 35,
        paddingLeft: 30
    },
    discountLabel: {
        position: 'absolute',
        bottom: 8,
        left: 'calc(100% + 10px)',
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.red[600]
    },
    productPricingWrapper: {
        padding: '22px 60px 0',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 -2.5% 15px',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
});

export default theme => ({
    loaderWrapper: {
        textAlign: 'center',
        marginTop: 35
    },
    allCandidatesTab: {
        width: 240,
        paddingLeft: 0,
        paddingRight: 0
    },
    shortlistTab: {
        width: 180,
        paddingLeft: 0,
        paddingRight: 0
    },
    pageHeader: {
        color: theme.palette.grey[600],
        fontSize: 14,
        fontWeight: 600
    },
    contentWrapper: {
        padding: 0,
        marginBottom: 0
    },
    innerTabs: {
        width: '100%',
        margin: 0
    },
    content: {
        overflow: 'auto',
        padding: '0 30px',
        minHeight: 250
    },
    selectHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 80,
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            paddingBottom: 12
        }
    },
    headerClass: {
        whiteSpace: 'pre-wrap',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 15
        }
    }
});

import { makeStyles } from '@mui/styles';


export default makeStyles(theme => ({
    loaderWrapper: {
        textAlign: 'center',
        marginTop: 35
    },
    wrapper: {
        overflow: 'hidden',
        minHeight: 400,
        width: 'calc(100% - 370px)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'block'
        }
    },
    contentLoaderWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: 100
        }
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: '0 !important'
    }
}));

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    root: {
        position: 'relative',
        padding: '26px 33px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    wrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    userWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 'calc(100% - 50px)'
    },
    userInfo: {
        marginLeft: 3,
        fontSize: 14,
        color: theme.palette.grey[700],
        maxWidth: 'calc(100% - 50px)'
    },
    userInfoName: {
        color: theme.palette.grey[900],
        fontSize: 16,
        fontWeight: 'bold',
        wordBreak: 'break-word'
    },
    userInfoEmail: {
        color: theme.palette.grey[700],
        fontWeight: 600,
        fontSize: 12,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 170
    },
    hiddenBlock: {
        height: 18,
        borderRadius: 30,
        backgroundColor: theme.palette.grey[300],
        width: '85%',
        marginTop: 13,
        marginBottom: 5
    },
    hiddenBlockShorter: {
        width: '60%'
    }
}));

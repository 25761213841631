export default theme => ({
    popoverRoot: {
        padding: 0,
        width: 360
    },
    popoverFooter: {
        padding: '23px 18px'
    },
    addSkillWrapper: {
        padding: '10px 19px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    addButton: {
        marginTop: 15,
        fontWeight: 700,
        color: theme.palette.grey[700]
    },
    emptyWrapper: {
        textAlign: 'center',
        padding: '0 10px 38px'
    },
    listItem: {
        textAlign: 'left',
        cursor: 'pointer',
        padding: '5px 19px',
        marginBottom: 0,
        fontSize: 13,
        '&:hover': {
            backgroundColor: theme.palette.grey[50]
        },
        '&:last-child': {
            marginBottom: 15
        }
    },
    paper: {
        minHeight: 100,
        padding: '5px 0',
        width: '100%'
    },
    paperLoading: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

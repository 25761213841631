import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';

import Pagination from 'components/board/board_pagination';
import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';

import { candidateResultCtx } from 'pages/assessment/results_assessment/common/CandidateListWrapper/candidatesStore';
import { assessmentSettingsCtx } from 'pages/assessment/store';

import { BATCH_ACTIONS, CANDIDATES_PER_RESULTS_PAGE } from 'helper/constants';
import { checkHasCompanyFeature, checkHasUserPermission } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';

import CandidateRow from './CandidateRow';

import useStyles from './styles';


const CandidatesTable = observer(() => {
    const {
        handleChangePage, allCandidates,
        totalCount, page,
        handleChangeSortCategory,
        sortOrder, sortCategory,
        toggleSelectAll, sortLoading, sortProgress, isLoading, isLoadingLocal,
        selectedCandidates
    } = useContext(candidateResultCtx);
    const { company } = useContext(appCtx);
    const { audition, companyTags } = useContext(assessmentSettingsCtx);
    const classes = useStyles();
    const totalPagesCount = Math.ceil(totalCount / CANDIDATES_PER_RESULTS_PAGE);

    const sortProps = {
        onSort: handleChangeSortCategory,
        sortOrder,
        sortCategory,
        hideSort: !totalCount
    };

    const hasGroups = audition && audition.scoreType === 3;
    const hasCompanyFeature = checkHasCompanyFeature(company, BATCH_ACTIONS);

    const message = checkHasUserPermission(company, audition.permissions, 'sendMessage');
    const reject = checkHasUserPermission(company, audition.permissions, 'reject');
    const reset = checkHasUserPermission(company, audition.permissions, 'reset');

    const allSelected = Object.keys(allCandidates).every(key => Boolean(selectedCandidates[allCandidates[key].candidateId]));

    const onSelect = (e) => {
        e.stopPropagation();
        toggleSelectAll(allSelected);
    };

    return (
        <TableContainer className={classes.tableContainer}>
            {
                sortLoading && (
                    <div className={classes.loadingWrapper}>
                        <LinearProgress
                            variant="determinate"
                            value={sortProgress}
                            className={classes.linearProgressRoot}
                        />
                    </div>
                )
            }
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCellHead label="Rank" className={clsx(classes.tableCellHead, classes.tableCellRank)} {...sortProps} />
                        <TableCellHead
                            label="Name"
                            className={clsx(classes.tableCellHead, classes.tableCellName)}
                            labelComponent={(
                                <>
                                    {
                                        Boolean(Object.keys(allCandidates).length)
                                        && hasCompanyFeature
                                        && (message || reject || reset)
                                        && (
                                            <Checkbox
                                                color="primary"
                                                onClick={onSelect}
                                                checked={allSelected}
                                                className={classes.checkbox}
                                            />
                                        )
                                    }
                                    Candidate
                                </>
                            )}
                            {...sortProps}
                        />
                        <TableCellHead className={classes.tableCellHead} label="Completed" {...sortProps} />
                        <TableCellHead className={classes.tableCellHead} label="Score" {...sortProps} />
                        {
                            hasGroups && (
                                <TableCellHead className={classes.tableCellHead} label="Skill Scores" hideSort />
                            )
                        }
                        <TableCellHead className={classes.tableCellHead} label=" " hideSort align="right" />
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {
                        (isLoading || isLoadingLocal) && (
                            <div className={classes.loaderWrapper}>
                                <CircularProgress color="primary" />
                            </div>
                        )
                    }
                    {
                        allCandidates && allCandidates.map(candidate => (
                            <CandidateRow
                                key={candidate.candidateId}
                                companyTags={companyTags}
                                candidate={candidate}
                            />
                        ))
                    }
                </TableBody>
            </Table>
            <Pagination
                currentPage={page}
                totalPages={totalPagesCount}
                handleChangePage={handleChangePage}
            />
        </TableContainer>
    );
});

export default CandidatesTable;

export default theme => ({
    infoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 30px',
        alignItems: 'center',
        '@media only screen and (max-width: 800px)': {
            flexDirection: 'column',
            marginTop: 35
        }
    },
    wrap: {
        display: 'flex',
        '@media only screen and (max-width: 800px)': {
            width: '100%'
        }
    },
    header: {
        margin: 0
    },
    countLabel: {
        fontSize: 14,
        color: theme.palette.grey[500]
    }
});

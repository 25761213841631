import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/Tooltip';
import TrashIcon from 'components/icons/TrashIcon';
import SettingsIcon from 'components/icons/SettingsIcon';
import Typography from '@mui/material/Typography';
import AddToBankIcon from 'pages/assessment/edit_assessment/edit_questions/components/AddToBankIcon';
import DeleteQuestionDialog from 'pages/assessment/edit_assessment/edit_questions/components/DeleteQuestionDialog';
import EditIcon from 'components/icons/EditIcon';
import PreviewQuestionIcon from 'components/icons/PreviewQuestionIcon';

import { NO_ANSWER } from 'helper/constants';

import styles from './styles';

const EditQuestionHeader = ({
    activeQuestion, question, totalQuestionsNumber,
    classes, deleteQuestion, handlePreviewMode,
    isPreviewMode
}) => {
    const [deleteQuestionDialogOpen, setDeleteQuestionDialog] = useState(false);

    const handleDeleteQuestionDialog = () => {
        setDeleteQuestionDialog(!deleteQuestionDialogOpen);
    };

    const scrollToElement = () => {
        if (document.getElementById('question-settings')) {
            document.getElementById('question-settings').scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (!question) return null;

    return (
        <div className={classes.questionHeader}>
            <div className={classes.questionNumber}>
                <Typography variant="h3">Question {activeQuestion + 1}</Typography>
                {question.manualGradesRequired && (<span className={classes.manuallyGradedLabel}>Manually Graded</span>)}
            </div>
            <div className="u-dsp--centered">
                {
                    handlePreviewMode && (
                        <Tooltip
                            label={isPreviewMode ? 'Edit Question' : `Preview Question${!question.valid ? '. Question is invalid' : ''}`}
                        >
                            <div>
                                <IconButton
                                    onClick={handlePreviewMode}
                                    size="medium"
                                    className={classes.iconButton}
                                    disabled={!isPreviewMode && !question.valid}
                                >
                                    { isPreviewMode ? (
                                        <EditIcon color="#7F8084" />
                                    ) : (
                                        <PreviewQuestionIcon
                                            color={!question.valid ? '#C7C8CC' : '#7F8084'}
                                            size={28}
                                        />
                                    )}
                                </IconButton>
                            </div>
                        </Tooltip>
                    )
                }
                {
                    (question.answerType !== NO_ANSWER && !isPreviewMode && !question.screeningQuestion) && (
                        <Tooltip
                            label="Question Settings"
                        >
                            <div>
                                <IconButton
                                    size="medium"
                                    onClick={scrollToElement}
                                    className={classes.iconButton}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                    )
                }
                <AddToBankIcon {...{ question, activeQuestion }} />
                {
                    totalQuestionsNumber > 1 && (
                        <Tooltip
                            label="Delete question"
                        >
                            <div>
                                <IconButton
                                    size="medium"
                                    className={classes.iconButton}
                                    onClick={handleDeleteQuestionDialog}
                                >
                                    <TrashIcon size={24} />
                                </IconButton>
                            </div>
                        </Tooltip>
                    )
                }
            </div>
            <DeleteQuestionDialog
                open={deleteQuestionDialogOpen}
                deleteQuestion={deleteQuestion}
                questionIndex={activeQuestion}
                onClose={handleDeleteQuestionDialog}
            />
        </div>
    );
};

export default withStyles(styles)(EditQuestionHeader);

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { withStyles, withTheme } from '@mui/styles';
import styles from './styles';

const WorkdayIcon = withStyles(styles)(withTheme(({ theme, isActive, ...other }) => {
    const fillColor = isActive ? theme.palette.primary.main : '#A9AAAE';
    return (
        <SvgIcon
            width="129"
            height="30"
            viewBox="0 0 129 30"
            fill="none"
            {...other}
        >
            <path d="M49.8512 0.0167522C53.3358 -0.103195 56.8432 0.420929 60.1298 1.58717C65.0742 3.32285 69.4931 6.50363 72.7265 10.6238C74.1488 12.4314 75.3402 14.4183 76.2739 16.5204C76.4394 16.8702 76.567 17.2634 76.4783 17.6532C76.3698 18.3075 75.7962 18.8483 75.1366 18.9137C74.4515 19.0139 73.7435 18.5864 73.4992 17.9409C71.6425 13.5717 68.4738 9.7766 64.5211 7.14955C62.0775 5.51668 59.3371 4.32763 56.474 3.66193C52.782 2.79833 48.8948 2.79833 45.202 3.6593C39.9293 4.87348 35.1015 7.89788 31.6965 12.1003C30.2527 13.871 29.0607 15.8448 28.162 17.9447C27.9675 18.4327 27.5206 18.8152 26.9992 18.9009C26.4965 18.9979 25.9491 18.8243 25.5986 18.4498C25.1852 18.0357 25.0501 17.3738 25.2632 16.8303C27.7365 10.9828 32.3161 6.07052 37.9466 3.14658C41.6139 1.22713 45.7162 0.156479 49.8512 0.0167522Z" fill={fillColor} />
            <path d="M45.2465 18.0212C45.2257 17.7175 45.513 17.4902 45.7978 17.483C46.1677 17.4731 46.5388 17.4762 46.9095 17.4815C47.1672 17.4902 47.4679 17.6619 47.4668 17.9512C47.48 21.349 47.4588 24.7479 47.4782 28.1476C48.8549 26.6167 50.2434 25.0943 51.625 23.568C51.8971 23.2996 52.1122 22.8931 52.5354 22.8581C53.1057 22.8346 53.6773 22.8486 54.2482 22.8494C54.4682 22.8296 54.7138 23.0313 54.6365 23.2661C54.5463 23.4474 54.3807 23.5718 54.2456 23.7157C52.7517 25.2085 51.252 26.696 49.7657 28.1951C51.2014 29.8341 52.6173 31.4898 54.0465 33.1349C54.2304 33.3689 54.4606 33.5676 54.6148 33.8257C54.7278 34.0274 54.5405 34.2729 54.3243 34.2824C53.8497 34.3091 53.374 34.2824 52.8982 34.2939C52.6508 34.2881 52.3832 34.3243 52.1586 34.1968C51.9219 34.0609 51.7781 33.8181 51.6086 33.6137C50.23 31.8777 48.8605 30.1348 47.477 28.4018C47.4629 30.1108 47.4728 31.8191 47.4717 33.5269C47.4664 33.7241 47.5067 33.9616 47.335 34.1054C47.0617 34.3692 46.6549 34.2767 46.3139 34.2939C46.0112 34.2836 45.6572 34.3498 45.4114 34.1264C45.2744 34.0198 45.2386 33.8379 45.2439 33.6761C45.2477 28.4571 45.2424 23.2387 45.2465 18.0212Z" fill={fillColor} />
            <path d="M38.9667 22.8208C39.6579 22.5986 40.437 22.5563 41.1141 22.8494C41.3517 22.9609 41.6276 23.1988 41.5203 23.4919C41.4118 23.8599 41.3193 24.2321 41.19 24.5937C41.0944 24.8533 40.7933 24.9497 40.5398 24.899C39.9696 24.8012 39.4097 24.6128 38.8251 24.6318C38.4171 24.6223 38.0406 24.8411 37.7617 25.1248C37.3114 25.5651 37.0784 26.1809 36.9738 26.7918C36.8908 27.2333 36.9144 27.6844 36.9106 28.1304C36.9106 30.005 36.9113 31.8807 36.9103 33.7553C36.9369 34.0636 36.6179 34.2729 36.3397 34.2836C35.9575 34.2889 35.5761 34.2939 35.1944 34.2805C34.9184 34.2661 34.6079 34.0647 34.6436 33.7541C34.6421 30.2829 34.6398 26.8128 34.6444 23.3415C34.6208 22.9963 35.0033 22.8086 35.3033 22.841C35.6797 22.8657 36.1935 22.7242 36.4383 23.1029C36.624 23.5421 36.7123 24.0164 36.8867 24.4594C37.4115 23.7393 38.1073 23.1044 38.9667 22.8208Z" fill={fillColor} />
            <path d="M0 23.0858C0.106193 22.964 0.230275 22.8322 0.407263 22.8448C0.925667 22.8398 1.44559 22.8284 1.96361 22.8524C2.22663 22.86 2.4078 23.0911 2.44625 23.3358C2.95589 25.4083 3.45641 27.4839 3.96378 29.5571C4.11259 30.1973 4.29377 30.831 4.39083 31.4822C4.49093 32.0672 4.51073 32.661 4.60588 33.2472C4.73681 32.4106 4.85404 31.5686 5.07975 30.7514C5.74317 28.277 6.40621 25.803 7.07039 23.3294C7.12216 23.0473 7.38745 22.8468 7.66948 22.8468C8.24651 22.8322 8.82467 22.8422 9.40169 22.8422C9.71114 22.8277 10.0423 23.0085 10.1169 23.3256C10.6124 25.1294 11.102 26.9364 11.5956 28.7418C11.8951 29.911 12.2875 31.0605 12.4569 32.2602C12.5037 32.5906 12.5494 32.9217 12.6175 33.2498C12.8036 31.365 13.2886 29.5312 13.6875 27.6856C14.0167 26.2172 14.3387 24.7472 14.6717 23.2787C14.7208 22.9838 15.0329 22.8354 15.3078 22.8436C15.7744 22.841 16.2419 22.8277 16.7077 22.8513C16.9844 22.8593 17.1435 23.1653 17.046 23.412C16.1097 26.8669 15.1769 30.324 14.2402 33.7789C14.1899 34.056 13.9485 34.2722 13.6658 34.2767C12.9426 34.3003 12.2179 34.2824 11.4947 34.2863C11.2356 34.3022 10.9615 34.1633 10.9158 33.8904C10.2684 31.5146 9.63387 29.1346 8.99253 26.7569C8.76035 25.9297 8.66633 25.0723 8.56889 24.2215C8.44976 24.979 8.39077 25.749 8.18904 26.4931C7.53247 28.9413 6.87209 31.3905 6.21399 33.839C6.13978 34.2356 5.68646 34.3148 5.34923 34.2889C4.67668 34.2767 4.00413 34.3045 3.33232 34.2767C3.09292 34.2775 2.90832 34.091 2.86531 33.8649C1.92137 30.4237 0.980476 26.981 0.0357783 23.5406C0.00304496 23.3903 0.00723178 23.2369 0 23.0858Z" fill={fillColor} />
            <path d="M84.8158 22.8513C85.3068 22.8429 85.7997 22.841 86.2907 22.8513C86.5895 22.8555 86.8704 23.0598 86.9644 23.3453C87.8124 25.5613 88.6544 27.7814 89.5012 29.9986C89.6782 30.4485 89.8019 30.9178 89.8812 31.3943C90.2313 30.0792 90.6975 28.7996 91.1067 27.5017C91.5623 26.0984 92.0183 24.6947 92.4757 23.2909C92.5436 23.0466 92.7764 22.8799 93.0238 22.8593C93.4247 22.8296 93.8281 22.8555 94.2296 22.8448C94.4646 22.8475 94.7443 22.82 94.9232 23.0085C95.0393 23.1067 95.0145 23.2699 94.9662 23.3975C93.6112 27.2627 92.26 31.1298 90.9069 34.9961C90.6717 35.6401 90.4908 36.3142 90.1122 36.8935C89.569 37.7758 88.6886 38.411 87.7211 38.7547C87.3831 38.8594 87.0341 39.0916 86.6705 38.9614C86.4125 38.8955 86.3135 38.6272 86.1758 38.43C86.0769 38.2512 85.8942 38.0856 85.9322 37.8629C85.9501 37.6422 86.127 37.4625 86.3357 37.4035C86.7436 37.2627 87.1585 37.1074 87.4889 36.8193C88.292 36.1399 88.7888 35.135 88.9779 34.1123C88.8497 33.6582 88.6125 33.2407 88.4404 32.8011C87.1483 29.798 85.8561 26.7938 84.5608 23.7918C84.4828 23.5954 84.3678 23.4081 84.3458 23.195C84.3488 22.9609 84.6171 22.8581 84.8158 22.8513Z" fill={fillColor} />
            <path d="M24.5659 24.3037C23.861 24.4347 23.2181 24.8736 22.8603 25.4985C22.2624 26.5121 22.1936 27.735 22.2205 28.8845C22.2631 29.9251 22.4302 31.035 23.0941 31.8762C23.5893 32.5087 24.403 32.8266 25.194 32.8144C25.9548 32.8387 26.7518 32.5811 27.2676 32.0025C27.9264 31.282 28.138 30.2821 28.2286 29.3393C28.2686 28.4133 28.2873 27.4705 28.0471 26.5662C27.856 25.8022 27.4525 25.0383 26.7545 24.6268C26.1047 24.2283 25.302 24.1911 24.5659 24.3037ZM24.8636 22.6557C26.139 22.5772 27.4754 22.8422 28.5281 23.5977C29.4698 24.2676 30.1093 25.2999 30.408 26.4067C30.7632 27.7281 30.7616 29.1384 30.4396 30.4667C30.1363 31.6912 29.4268 32.8456 28.3566 33.5414C27.2127 34.3003 25.7759 34.4975 24.4335 34.3357C23.2897 34.1949 22.1752 33.6932 21.379 32.8505C20.4431 31.8701 19.9783 30.5192 19.8816 29.184C19.764 27.7034 19.9909 26.1361 20.8145 24.8716C21.4239 23.92 22.3926 23.2209 23.4731 22.9019C23.9238 22.7611 24.3939 22.6956 24.8636 22.6557Z" fill={fillColor} />
            <path d="M62.4867 24.2436C61.7875 24.2886 61.1127 24.6909 60.8006 25.3291C60.1908 26.5095 60.1783 27.8831 60.2087 29.1811C60.2574 30.029 60.3495 30.9045 60.7446 31.6703C61.0118 32.186 61.4972 32.5933 62.0711 32.7181C62.9492 32.9294 63.9826 32.7505 64.6068 32.0566C65.2082 31.3719 65.4061 30.4409 65.4986 29.5597C65.5621 28.5553 65.566 27.5302 65.3167 26.5471C65.1454 25.8898 64.8554 25.2332 64.3347 24.7764C63.8353 24.3369 63.1395 24.1674 62.4867 24.2436ZM65.4956 17.8758C65.4796 17.6094 65.7517 17.4393 65.9884 17.4187C66.3671 17.3982 66.7471 17.408 67.1265 17.4122C67.4158 17.4096 67.7393 17.6246 67.6975 17.9459C67.6975 23.1886 67.6985 28.4304 67.6967 33.6723C67.7165 33.9429 67.45 34.153 67.1999 34.1694C66.8981 34.1854 66.5955 34.1854 66.2948 34.1645C66.0828 34.1511 65.8773 34.0153 65.805 33.8124C65.6916 33.4717 65.6325 33.1178 65.5461 32.7714C65.1275 33.3393 64.5877 33.8417 63.9171 34.091C62.9606 34.4431 61.896 34.4488 60.9129 34.209C59.9252 33.9715 59.065 33.2928 58.5884 32.3995C57.8405 31.0358 57.7518 29.4315 57.7987 27.9102C57.8253 26.6103 58.0735 25.239 58.9203 24.2075C59.559 23.3332 60.6057 22.8315 61.6627 22.7002C62.6466 22.5563 63.7135 22.7218 64.528 23.318C64.9132 23.5889 65.2215 23.9524 65.4967 24.3323C65.489 22.1807 65.491 20.0282 65.4956 17.8758Z" fill={fillColor} />
            <path d="M76.0824 24.3091C75.7316 24.3806 75.3916 24.5214 75.1081 24.741C74.541 25.1825 74.2593 25.8841 74.1089 26.5654C73.8901 27.6464 73.8931 28.7627 74.0062 29.8558C74.112 30.6589 74.3137 31.5161 74.9026 32.1099C75.3822 32.6134 76.1053 32.798 76.7821 32.7649C77.5098 32.7912 78.2672 32.5202 78.7366 31.9485C79.3234 31.2386 79.489 30.2943 79.5746 29.4048C79.6291 28.3588 79.6298 27.2878 79.336 26.2723C79.1503 25.6397 78.8107 25.0106 78.2379 24.6501C77.6069 24.236 76.8086 24.1979 76.0824 24.3091ZM75.1081 22.8733C76.1179 22.5936 77.1988 22.5772 78.2189 22.8106C79.1667 23.0302 80.0542 23.5622 80.6366 24.3501C81.2524 25.1578 81.5634 26.1593 81.6928 27.1572C81.8051 27.9268 81.7728 28.7063 81.7738 29.4817C81.7738 30.8808 81.7738 32.2804 81.77 33.6792C81.7853 33.8497 81.6776 34.0008 81.5386 34.0895C81.3038 34.2341 81.0126 34.1702 80.7507 34.1865C80.457 34.1842 80.0706 34.2101 79.9107 33.9045C79.7547 33.5452 79.7224 33.1444 79.6222 32.7664C79.2282 33.2453 78.8203 33.7393 78.2501 34.016C77.3815 34.4453 76.366 34.4202 75.4365 34.2387C74.2992 34.0141 73.2517 33.3415 72.6086 32.3725C71.8424 31.2439 71.5645 29.8509 71.5751 28.5046C71.5657 27.1922 71.8225 25.8354 72.5522 24.7251C73.1357 23.8173 74.072 23.16 75.1081 22.8733Z" fill={fillColor} />
            <path d="M95.4484 31.4697C94.871 31.6817 94.5075 32.3477 94.6548 32.9491C94.8052 33.6552 95.6265 34.137 96.3117 33.8759C96.9435 33.683 97.342 32.9625 97.146 32.3272C96.9739 31.6124 96.1259 31.1899 95.4484 31.4697ZM95.5284 31.1678C95.8709 31.1138 96.2431 31.11 96.5609 31.2687C97.1022 31.5146 97.4913 32.0958 97.4326 32.7002C97.4776 33.4211 96.8712 34.0655 96.1792 34.1816C95.4001 34.3471 94.5616 33.7827 94.4029 33.0055C94.1981 32.2233 94.7416 31.3429 95.5284 31.1678Z" fill={fillColor} />
            <path d="M95.6893 32.0463C95.6881 32.2099 95.6873 32.3736 95.6893 32.5384C95.8328 32.5296 95.9804 32.5384 96.122 32.4992C96.2552 32.444 96.242 32.2746 96.2058 32.1612C96.068 32.0265 95.8632 32.0615 95.6893 32.0463ZM95.2562 31.7798C95.6703 31.8034 96.1346 31.6866 96.5076 31.9176C96.8083 32.1433 96.6625 32.6343 96.323 32.739C96.4208 32.9978 96.5571 33.2384 96.6815 33.485C96.5247 33.488 96.3706 33.4907 96.2164 33.4934C96.1049 33.265 95.9956 33.034 95.8891 32.8037C95.8213 32.8018 95.7548 32.801 95.6893 32.7999C95.6881 33.0302 95.6881 33.2604 95.6881 33.4907C95.5416 33.4888 95.3981 33.4869 95.2542 33.4858C95.2554 32.9167 95.2534 32.3477 95.2562 31.7798Z" fill={fillColor} />
        </SvgIcon>
    );
}));

export default WorkdayIcon;

import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import PlanActions from './PlanActions';

import styles from './styles';
import { subscriptionDialogStoreCtx } from '../../store';

const PlanInfoBlock = observer(({ classes }) => {
    const { selectedPlan, isSelectedCurrent } = useContext(subscriptionDialogStoreCtx);

    if (!selectedPlan) return;

    const { name, level, slug } = selectedPlan;

    return (
        <div className={clsx(classes.wrapper, isSelectedCurrent && classes.wrapperCurrent)}>
            <div className="u-fdir--column u-dsp--f">
                {isSelectedCurrent && <Typography variant="h4" color="#fff" className="u-txt--normal u-txt--20">Current Plan</Typography>}
                <div className={classes.planName}>
                    { level === 1 && slug !== 'premium' ? 'Limited Access' : <>{name}</> }
                </div>
                <div>
                    {{
                        'candidate pack': '*valid for 12 months from the date of purchase'
                    }[name.toLowerCase()]}
                </div>
            </div>
            <PlanActions />
        </div>
    );
});

export default withStyles(styles)(PlanInfoBlock);

export default theme => ({
    wrapper: {
        width: '100%',
        '& .CodeMirror': {
            maxHeight: 260,
            borderRadius: 4,
            [theme.breakpoints.down('lg')]: {
                maxHeight: 164
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight: 173
            }
        }
    },
    codeMirrorStyle: {
        '& .CodeMirror': {
            height: '70vh !important',
            maxHeight: 'calc(100vh - 105px)',
            borderRadius: 4
        }
    },
    dialogPaper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        margin: 20
    },
    expandButton: {
        position: 'absolute',
        bottom: -5,
        right: 19,
        height: 30,
        paddingLeft: 5,
        paddingRight: 5,
        boxSizing: 'border-box',
        minWidth: 30,
        minHeight: 30,
        marginBottom: 15
    }
});

import React from 'react';

// material components
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';


import styles from './styles';

const DropDownLanguages = ({ languages, activeLanguage, classes, setLangParams }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setLanguage = (e, i) => {
        setLangParams(languages[i]);
        handleClose(e);
    };


    return (
        <div>
            <Button
                classes={{
                    root: classes.btnLang,
                    label: classes.btnLangLabel,
                    disabled: classes.btnLangDis
                }}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disabled={!(languages.length > 1)}
                onClick={handleClick}
            >
                { activeLanguage }
                {languages.length > 1 && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="white" fillRule="evenodd" d="M7.402 9L6 10.342 12 16l6-5.658L16.598 9 12 13.316z" />
                    </svg>
                )}
            </Button>
            {
                languages.length > 1 && (
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        classes={{ paper: classes.menu }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        {
                            languages.map((item, i) => {
                                if (activeLanguage !== item.toLowerCase()) {
                                    return (
                                        <MenuItem
                                            key={item}
                                            onClick={e => setLanguage(e, i)}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                }
                                return null;
                            })
                        }
                    </Menu>
                )
            }
        </div>
    );
};

export default withStyles(styles)(DropDownLanguages);

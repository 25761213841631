export default theme => ({
    inviteButtonsWrapper: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
        paddingBottom: 30,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    inviteByEmailActionRoot: {
        textAlign: 'center',
        '@media (max-width: 767px)': {
            width: '100%'
        }
    },
    errorMessage: {
        color: theme.palette.red.primary,
        fontSize: 12,
        marginRight: 10,
        display: 'inline-block',

        '@media (max-width: 767px)': {
            marginRight: 0,
            padding: '0 0 5px'
        },
        '& i': {
            verticalAlign: 'middle',
            paddingBottom: 3,
            fontSize: 18
        }
    },
    loader: {
        color: 'white'
    },
    loaderBlock: {
        margin: 0
    },
    inviteButton: {
        minWidth: 150,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    resultsButton: {
        fontWeight: 700,
        marginTop: 20
    },
    inviteWrapper: {
        paddingTop: 15,
        paddingBottom: 15,
        overflowY: 'auto',
        overflowX: 'hidden'
    }
});

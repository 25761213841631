import React, { useContext } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import PhoneNumber from 'awesome-phonenumber';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { CSV_IMPORT, APP_SUMO, SEND_SMS_INVITATION } from 'helper/constants';
import { checkHasCompanyFeature, clone } from 'helper/commonFunctions';
import Popper from 'libraries/Popper';
import CsvImage from './csv-image.png';
import CsvImageWithPhone from './csv-image-with-phone.png';

import stylesJs from './styles';
import { appCtx } from '../../../appStore';

const ImportCSVForm = observer(({
    classes, className = '', context,
    assessment, csvError, onUpload
}) => {
    const { company } = useContext(appCtx);
    const {
        loading, setIsCSVInvitation, defaultFormInvite
    } = useContext(context);

    const { companySettings: { importSeekCandidateList } } = company;

    const isAppSumo = checkHasCompanyFeature(company, APP_SUMO);
    const hasSMSFeature = checkHasCompanyFeature(company, SEND_SMS_INVITATION);

    const fileInput = React.createRef();
    const fileInputSeek = React.createRef();

    const processRow = (row, keys) => row.reduce((map, val, i) => {
        let fieldName;
        if (typeof keys[i] === 'undefined') {
            fieldName = 'phone';
        } else {
            fieldName = keys[i].toLowerCase();
        }
        const formInvite = clone(defaultFormInvite[fieldName]);
        const newValue = clone(map);

        formInvite.value = val;
        newValue[fieldName] = formInvite;
        if (fieldName === 'phone') {
            const phone = val[0] === '+' ? val.trim() : `+${val.trim()}`;
            newValue.isPhoneValid = { value: val ? PhoneNumber(`${phone}`).isValid() : true };
        } else if (fieldName === 'tags') {
            const tags = val.split(';').map(tag => tag.trim()).filter(tag => tag);
            newValue.tags = { value: [...new Set(tags)] };
        }

        return newValue;
    }, {});

    const parseCSV = (input, isSeek) => {
        const result = {};
        result.error = '';
        const rows = input.split(/\r?\n/).filter(row => row !== ''); // Split rows and remove empty ones

        let keys = rows.shift().split(',');
        keys = keys.map(key => key.trim()); // trim each element

        if (isSeek) {
            keys = keys.map(el => el.toLowerCase());
            const isColumnTitleError = !keys.includes('first name') || !keys.includes('surname') || !keys.includes('contact email');

            if (isColumnTitleError || (hasSMSFeature && !keys.includes('phone 1'))) {
                result.error = `Invalid header type (there must be First Name, Surname, Contact Email${hasSMSFeature && ', Phone 1'})`;
                return result;
            }

            const preparedRows = rows.map((row) => {
                const rowValues = row.split(',');
                return [`${rowValues[1]} ${rowValues[2]}`, rowValues[5], rowValues[3]];
            });

            const defaultKeys = ['name', 'email', 'phone'];

            const rowsToReturn = preparedRows
                .map(row => processRow(row, defaultKeys))
                .filter(item => (item.name && item.name.value) || (item.email && item.email.value) || (item.phone && item.phone.value));

            if (hasSMSFeature) {
                rowsToReturn.forEach((row) => {
                    if (!row.phone) {
                        row.phone = { value: '' };
                        row.isPhoneValid = { value: true };
                    }
                });
            }


            return rowsToReturn;
        }

        for (let i = 1; i < rows.length; i += 1) {
            if (rows[i].length > 1 && !rows[i].match(/[а-яА-Яa-zA-Z\x80-\xA5\xE0-\xFF0-9_ ]+,(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi)) {
                result.error = `Please, check syntax on line ${i + 1} in the file.`;
                return result;
            }
        }

        const isColumnTitleError = typeof keys[0] === 'undefined'
                || typeof keys[1] === 'undefined'
                || keys[0].toLowerCase() !== 'name'
                || keys[1].toLowerCase() !== 'email';

        if (isColumnTitleError) {
            result.error = 'Invalid header type (first row must be Name, Email)';
            return result;
        }

        const rowsToReturn = rows
            .map(row => row.split(','))
            .map(row => processRow(row, keys))
            .filter(item => (item.name && item.name.value)
                || (item.email && item.email.value)
                || (item.phone && item.phone.value)
                || (item.tags && item.tags.value));

        if (hasSMSFeature) {
            rowsToReturn.forEach((row) => {
                if (!row.phone) {
                    row.phone = { value: '' };
                    row.isPhoneValid = { value: true };
                }
            });
        }

        return rowsToReturn;
    };

    const clickImportCSVLink = (e, isSeek = false) => {
        e.preventDefault();
        if (isSeek) {
            fileInputSeek.current.click();
        } else {
            fileInput.current.click();
        }
    };

    const handleTags = (csvResult) => {
        const tags = new Set();
        const error = null;

        for (let i = 0; i < csvResult.length; i++) {
            const row = csvResult[i];
            if (!row.tags) {
                // eslint-disable-next-line no-continue
                continue;
            }
            if (row.tags.value?.length > 10) {
                return { error: 'You can add up to 10 tags per candidate' };
            }
            if (row.tags.value?.length) {
                // eslint-disable-next-line no-restricted-syntax
                for (const tag of row.tags.value) {
                    const trimmedTag = tag.trim();
                    if (trimmedTag.length > 150) {
                        return { error: `Please, check syntax on line ${i + 1} in the file. Invalid tags detected. Please review or rename them.` };
                    }
                    if (trimmedTag.length) {
                        tags.add(trimmedTag);
                    }
                }
            }
        }

        return { error, tags: Array.from(tags) };
    };

    const onFileCSVLoad = (e, isSeek) => {
        const file = isSeek ? fileInputSeek.current.files[0] : fileInput.current.files[0];
        const csvType = /^.+\.csv$/;
        let result = {};
        if (file && file.name.match(csvType)) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                result = parseCSV(reader.result, isSeek);
                const { error, tags } = handleTags(result);

                if (error) {
                    result.error = error;
                }
                if (fileInput.current) {
                    fileInput.current.value = '';
                }
                setIsCSVInvitation(true);
                onUpload(result, tags);
            };
        } else {
            result.error = 'Not csv file';
            onUpload(result);
        }
    };

    if (!checkHasCompanyFeature(company, CSV_IMPORT)) return null;

    return (
        <div className={clsx(classes.wrapper, className)}>
            <div className={clsx('u-txt--nowrap', classes.importCsv)}>
                <div className={classes.importCsvButton}>
                    <Button
                        variant="outlined"
                        className={classes.importCsvBtn}
                        onClick={clickImportCSVLink}
                        disabled={assessment?.expired || !assessment?.valid}
                    >
                        Import CSV file
                    </Button>
                </div>
                <Popper
                    hover
                    closeButton
                    closeButtonLabel="Got it"
                    placement="top-start"
                    styles={{ paperStyle: { marginTop: 0 } }}
                    label={<span className={classes.importCsvPopover}><i className="material-icons">info_outline</i> <span>CSV format</span></span>}
                >
                    <span className={classes.importCsvPopoverContent}>
                        {(!isAppSumo && hasSMSFeature)
                            ? <>
                                Format your CSV file with four columns, one titled <b>‘Name’</b>, the second titled <b>‘Email’</b>, the third titled <b>‘Phone’</b> and the fourth titled <b>‘Tags’</b> as shown below. (Include country code with phone number; tags are optional)
                                <img src={CsvImageWithPhone} alt="CSV example" />
                            </> : <>
                                Format your CSV file with three columns, one titled ‘<b>Name</b>’, the second titled ‘<b>Email</b>’ and the third titled <b>‘Tags’</b> as shown below. (Tags are optional)
                                <img src={CsvImage} alt="CSV example" />
                            </>
                        }
                    </span>
                    <div className="u-clear" />
                </Popper>
                {importSeekCandidateList && (
                    <div className={clsx(classes.importCsvButton, 'u-mrg--lx3')}>
                        <Button
                            variant="outlined"
                            className={classes.importCsvBtn}
                            onClick={e => clickImportCSVLink(e, true)}
                            disabled={assessment?.expired || !assessment?.valid}
                        >
                            Import Seek candidate list
                        </Button>
                    </div>
                )}
            </div>
            { !loading && <input type="file" ref={fileInput} onChange={e => onFileCSVLoad(e, false)} style={{ display: 'none' }} /> }
            { !loading && importSeekCandidateList && <input type="file" ref={fileInputSeek} onChange={e => onFileCSVLoad(e, true)} style={{ display: 'none' }} /> }
            { csvError && <div className={classes.csvError}>{csvError}</div> }
        </div>
    );
});

export default withStyles(stylesJs)(ImportCSVForm);

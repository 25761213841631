import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import AssignHiringManagerDialog from 'components/dialogs/AssignHiringManagerDialog';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
import { assignHiringManagerOrDate } from 'requests/CandidatesRequests';
import { appCtx } from 'components/appStore';
import { hiredCandidateCtx } from 'pages/candidates/HiredCandidates/hiredCandidateStore';
import HiredEvents from 'events/HiredEvents';

import Tooltip from 'libraries/Tooltip';
import PlusIcon from './plus.svg';
import CheckIcon from './check.svg';
import DeleteIcon from '../deleteIcon.svg';

import useStyles from './styles';


const HiringManagerButton = observer(({
    candidate, assessmentSlug, canEdit,
    dataForEvents, canAssignHiringManager,
    onSuccess, showHireBanner, setShowHireBanner
}) => {
    const { button, buttongroup, activeButton, buttonText } = useStyles();
    const { flashMessage } = useContext(appCtx);
    const { fetchFiltersForHired } = useContext(hiredCandidateCtx);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [hiringManager, setHiringManager] = useState(candidate.hiringManager || {});

    useEffect(() => {
        setHiringManager(candidate.hiringManager || {});
    }, [candidate.hiringManager]);

    const handleClick = (e) => {
        if (showHireBanner) setShowHireBanner(false);
        e.stopPropagation();
        setOpen(!open);
    };

    const disabled = !canEdit || !canAssignHiringManager;

    const { hiredDate, columnUserUuid } = candidate;

    const returnTooltipLabel = () => {
        if (!canAssignHiringManager) return 'Survey was sent';
        if (!canEdit) return 'You don\'t have permission to edit these details';
        return '';
    };

    const removeHiringManager = (e) => {
        e.stopPropagation();
        setLoading(true);
        assignHiringManagerOrDate(assessmentSlug, hiredDate, null, [columnUserUuid])
            .then(({ success, data }) => {
                if (success && data) {
                    flashMessage('Hiring manager was unassigned', 'done');
                    setHiringManager({});
                    if (onSuccess) {
                        onSuccess({ hiringManager: null });
                    }
                    fetchFiltersForHired();
                    dataForEvents.hiringManagerName = hiringManager.fullName;
                    dataForEvents.hiringManagerId = hiringManager.uuid;
                    dataForEvents.hiringManagerEmail = hiringManager.email;
                    HiredEvents.CANDIDATE_HIRING_MANAGER_REMOVED(dataForEvents);
                }
            })
            .catch(({ response: { status } }) => {
                flashMessage(status === 403
                    ? 'You don’t have permission for this action. Please contact company admin.'
                    : 'Something went wrong',
                'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const returnIcon = () => {
        if (!hiringManager.fullName) return PlusIcon;
        if (hovered) return DeleteIcon;
        return CheckIcon;
    };

    return (
        <>
            <Tooltip
                label={returnTooltipLabel()}
            >
                <ButtonGroup
                    classes={{ root: buttongroup }}
                    disabled={loading || disabled}
                >
                    <Button
                        classes={{ root: clsx(button, 'u-pdn--bx0', hiringManager.fullName && activeButton) }}
                        onClick={handleClick}
                    >
                        <span className={buttonText}>
                            <TruncateWithTooltip width={145} text={hiringManager.fullName || 'Add Hiring Manager'} />
                        </span>
                    </Button>
                    <Button
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        onClick={hiringManager.fullName ? removeHiringManager : handleClick}
                        className={clsx(button, hiringManager.fullName && activeButton)}
                    >
                        <img src={returnIcon()} alt="+" />
                    </Button>
                </ButtonGroup>
            </Tooltip>
            <AssignHiringManagerDialog
                open={open}
                onClose={() => setOpen(false)}
                candidate={candidate}
                assessmentSlug={assessmentSlug}
                hiringManagerFullName={hiringManager.fullName}
                setFullName={newFullName => setHiringManager({ ...hiringManager, newFullName })}
                hiringManager={hiringManager}
                setHiringManager={setHiringManager}
                dataForEvents={dataForEvents}
                onSuccess={onSuccess}
            />
        </>
    );
});

export default HiringManagerButton;

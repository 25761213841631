import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    attachment: {
        display: 'flex',
        margin: '5px 0 10px 25px',
        '& > img': {
            marginRight: 10
        },
        '& > a': {
            fontSize: 13,
            color: theme.palette.blue[600]
        }
    }
}));

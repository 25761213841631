import React from 'react';

// material components
import ExcelCase from '../ExcelCase';
import AutoGraded from '../../../AutoGraded';

const ExcelAutoGraded = ({
    question, fetchQuestion, setSaving, classes,
    excelLink, flashMessage, excelDialogOpen,
    isCMS, ...other
}) => {
    const { oneDriveFile } = question;
    const excelViewLink = oneDriveFile && oneDriveFile.previewUrl;

    return (
        <div>
            <ExcelCase
                excelLink={excelLink}
                {...other}
            />
            {
                excelViewLink && (
                    <AutoGraded
                        viewLink={excelViewLink}
                        question={question}
                        isOpen={excelDialogOpen}
                        fetchQuestion={fetchQuestion}
                        isCMS={isCMS}
                        setSaving={setSaving}
                        flashMessage={flashMessage}
                    />
                )
            }
        </div>
    );
};

export default ExcelAutoGraded;

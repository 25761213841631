import React from 'react';
import { observer } from 'mobx-react-lite';

// material components
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import { CODE_TEST, AUDIO, IMAGE, VIDEO, ANY_FILE, TEXT } from 'helper/constants';

import styles from './styles';


const MEDIA_TYPES = [CODE_TEST, IMAGE, AUDIO, VIDEO, ANY_FILE];

const MediaChoiceTypes = observer(({
    classes, type, onChangeType
}) => {
    const renderIcon = (Icon, label, labelShort, newType) => (
        <IconButton
            className={classes.iconButton}
            onClick={() => onChangeType(newType)}
            size="large"
        >
            { Icon && <Icon className={classes.icon} /> }
        </IconButton>
    );

    if (type !== TEXT) return null;

    return (
        <div className={classes.iconWrapper}>
            Add Media Choice
            {
                MEDIA_TYPES.map(kind => (
                    getQuestionTypeInfo(kind, renderIcon)
                ))
            }
        </div>
    );
});

export default withStyles(styles)(MediaChoiceTypes);

import React from 'react';
import { highlightTextByCoordinates } from 'helper/commonFunctions';
import { withStyles } from '@mui/styles';
import AnswersHeader from '../AnswersHeader';

import styles from '../styles';

const CompareAnswers = ({ classes, plagiarism, candidateName }) => {
    const { copyleaksScan, plagiarismQuestionResult, openAiAnswer, sourceSpanEnd, sourceSpanStart, textToCheckSpanEnd, textToCheckSpanStart, questionResult, typeSlug } = plagiarism;

    let plagiaristContent = '';
    let plagiarismTitle = '';
    let plagiarismSource = '';

    switch (typeSlug) {
        case 'open_ai':
            plagiaristContent = (
                <p>
                    {highlightTextByCoordinates(openAiAnswer.answer, sourceSpanStart, sourceSpanEnd)}
                </p>
            );
            plagiarismSource = 'Chat GPT';
            break;
        case 'default':
            plagiaristContent = (
                <p>
                    {highlightTextByCoordinates(plagiarismQuestionResult.answeredText, sourceSpanStart, sourceSpanEnd)}
                </p>
            );
            plagiarismTitle = 'Anonymous';
            plagiarismSource = 'Candidate Answer';
            break;
        case 'web':
            plagiaristContent = (
                <>
                    <p>
                        Link: <a href={copyleaksScan.url} target="_blank" rel="noopener noreferrer">{copyleaksScan.title}</a>
                    </p>
                    {copyleaksScan.introduction}
                </>
            );
            plagiarismSource = 'Internet';
            break;
        default:
    }

    return (
        <>
            <AnswersHeader
                candidateName={candidateName}
                plagiarismSource={plagiarismSource}
                plagiarismTitle={plagiarismTitle}
            />
            <div className={classes.answersWrapper}>
                <div className={classes.text}>
                    <p>
                        {highlightTextByCoordinates(questionResult.answeredText, textToCheckSpanStart, textToCheckSpanEnd)}
                    </p>
                </div>
                <div className={classes.text}>
                    {plagiaristContent}
                </div>
            </div>
        </>
    );
};

export default withStyles(styles)(CompareAnswers);

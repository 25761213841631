import React, { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import styles from 'pages/assessment/components/TagsList/styles';
import { observer } from 'mobx-react-lite';
import { hiredCandidateCtx } from '../../HiredCandidates/hiredCandidateStore';

const HiredTagsList = observer(({ classes }) => {
    const { filterAudition, filterHiringManager,
        auditions, hiringManagers, handleChangeFilterSelect
    } = useContext(hiredCandidateCtx);

    const returnFilterName = (uuid, type) => {
        const object = type === 'audition' ? auditions : hiringManagers;
        const place = Object.keys(object).find(key => object[key].uuid === uuid);
        return object[place] ? object[place].name : '';
    };

    const tag = (uuid, type) => {
        if (!uuid) return null;
        return (
            <div key={uuid} className={classes.tag}>
                <TruncateWithTooltip capitalize text={returnFilterName(uuid, type)} width={130} />
                <IconButton
                    className={classes.closeButton}
                    onClick={() => handleChangeFilterSelect(null, type)}
                    size="large">
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </div>
        );
    };

    return (
        <div className="u-mrg--lx3">
            { tag(filterAudition, 'audition') }
            { tag(filterHiringManager, 'hiringManager') }
        </div>
    );
});

export default withStyles(styles)(HiredTagsList);

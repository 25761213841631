export default theme => ({
    innerWrapper: {
        maxWidth: 500
    },
    submitButton: {
        padding: '13px 48px',
        marginTop: 36,
        marginBottom: 48
    },
    photoWrapper: {
        width: 90,
        marginBottom: 25
    },
    passwordHeader: {
        marginBottom: 20,
        paddingBottom: 20,
        fontWeight: 700,
        fontSize: 18,
        color: theme.palette.grey[900],
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    phoneHelperText: {
        margin: '5px 14px',
        fontSize: 10,
        fontWeight: 600,
        color: theme.palette.red[700]
    }
});

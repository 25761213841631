import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import clsx from 'clsx';


const styles = theme => ({
    wrapper: {
        width: 80,
        position: 'absolute',
        left: 86,
        top: 0,
        zIndex: 10,
        height: '100%',
        display: 'flex',
        padding: '30px 5px',
        flexDirection: 'column',
        backgroundColor: theme.palette.grey[100],
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4
    },
    item: {
        background: theme.palette.grey[300],
        fontSize: 14,
        marginBottom: 4,
        padding: '8px 0',
        fontWeight: 700,
        minWidth: 60,
        minHeight: 65,
        height: 65,
        borderRadius: 4,
        color: theme.palette.grey[800],
        '&:hover': {
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.grey[900]
        }
    },
    itemActive: {
        color: theme.palette.primary.main,
        backgroundColor: '#fff'
    }
});

export default withStyles(styles)(({ classes, taskNumber, tasks = [], goToTask }) => (
    <div className={classes.wrapper}>
        {
            tasks.map((item, key) => (
                <Button
                    key={key}
                    onClick={() => goToTask(key)}
                    className={clsx(classes.item, taskNumber === parseInt(key, 10) && classes.itemActive)}
                >
                    T{key + 1}
                </Button>
            ))
        }
    </div>
));

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import PhoneInputWithCountries from 'components/inputs/PhoneInputWithCountries';
import Tooltip from 'libraries/Tooltip';
import Link from 'react-router-dom/Link';

import CircularProgress from '@mui/material/CircularProgress';
import useStyles from '../styles';

const ResendSMSDialog = ({
    open, onClose, candidate, phoneValue, phoneValid, company, isLoading, onResend, handlePhoneChange, oldPhone
}) => {
    const { saveButton, resendText, link, tooltipBox, tooltipText, loader } = useStyles({});
    if (!company.smsLimitPeriod) return null;
    const smsLimitPeriodToShow = company.smsLimitPeriod.split(' ')[1];

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}
            handleClose={onClose}
            onClick={e => e.stopPropagation()}
            titleComponent="Resend SMS"
            actionComponent={(
                <>
                    {company.countSmsLeft > 0 ? (
                        <Button
                            className={saveButton}
                            color="primary"
                            variant="contained"
                            onClick={onResend}
                            disabled={!phoneValid || isLoading}
                        >
                            {
                                isLoading ? (
                                    <CircularProgress
                                        className={loader}
                                        size={25}
                                        thickness={3}
                                    />
                                ) : (
                                    <>Resend SMS</>
                                )
                            }

                        </Button>
                    ) : (
                        <Tooltip
                            white
                            boxShadow
                            placement="left"
                            tooltipClass={tooltipBox}
                            label={(
                                <div className={tooltipText}>
                                    You have <b>{company.countSmsLeft}/{company.countSmsPerCompanyQuota}</b> SMS notifications remaining of
                                    <br />
                                    your plan quota this {smsLimitPeriodToShow}.&nbsp;
                                    <Link to="/billing" className={link}>
                                        My account
                                    </Link>
                                </div>
                            )}
                        >
                            <span>
                                <Button
                                    className={saveButton}
                                    color="primary"
                                    variant="contained"
                                    disabled
                                >
                                    Resend SMS
                                </Button>
                            </span>
                        </Tooltip>
                    )}

                </>
            )}
        >
            <div className={resendText}>
                The SMS sent to <b>{candidate.fullName}</b> on <b>{oldPhone}</b> was not delivered. Please update the number below and resend.
            </div>
            <PhoneInputWithCountries
                value={phoneValue}
                placeholder="Phone Number"
                isValid={phoneValid}
                onChange={handlePhoneChange}
            />
        </Dialog>
    );
};

export default ResendSMSDialog;

export default theme => ({
    blockWrapper: {
        padding: '20px 33px',
        borderTop: `1px solid ${theme.palette.grey[300]}`
    },
    firstCommentWrapper: {
        marginTop: 15,
        marginBottom: 15,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    firstCommentImage: {
        width: 110,
        height: 110,
        marginBottom: 18
    },
    firstCommentLabel: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 1.22,
        marginBottom: 18,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    firstCommentMessage: {
        maxWidth: 420,
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.2,
        textAlign: 'center',
        color: 'rgba(61, 63, 71, 0.9)'
    },
    cancelButton: {
        marginRight: 10
    },
    employerPhoto: {
        fontSize: 14,
        marginRight: 7,
        fontWeight: 'normal'
    },
    commentWrapper: {
        '&:not(:last-child)': {
            marginBottom: 40
        }
    },
    commentHeaderWrapper: {
        marginTop: 15,
        marginBottom: 10,
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    }
});

import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';
import { getSatisfactionFeedback } from 'requests/AssessmentRequests';
import Tooltip from 'libraries/Tooltip';

import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        zIndex: 100,
        borderRadius: 4,
        border: `2px solid ${theme.palette.grey[200]}`,
        marginBottom: 40
    },
    header: {
        background: theme.palette.grey[200],
        textTransform: 'uppercase',
        padding: '15px 25px',
        fontWeight: 700
    },
    comment: {
        padding: 25
    },
    ratingLabel: {
        color: theme.palette.grey[600],
        fontWeight: 700,
        textTransform: 'uppercase',
        marginRight: 5
    },
    ratingScore: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 16
    }
}));


const SatisfactionBlock = observer(({ candidate, audition, propPublic }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [feedbackData, setFeebackData] = useState(null);

    const classes = useStyles({});
    const { uuid } = candidate;
    const { slug } = audition;

    useEffect(() => {
        if (!slug || !uuid) {
            setFeebackData(null);
            return;
        }
        getResults();
    }, [slug, uuid]);

    const getResults = () => {
        setIsLoading(true);
        getSatisfactionFeedback(slug, uuid, propPublic)
            .then(({ success, data }) => {
                if (success) {
                    setFeebackData(data);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (isLoading || !feedbackData) return null;

    return (
        <>
            <div className="u-dsp--distribute">
                <Typography
                    variant="h5"
                    className="u-mrg--bx2"
                >
                    Candidate Satisfaction Rating
                </Typography>
                {
                    feedbackData.score && (
                        <Tooltip label={<Moment format="D MMM YYYY">{feedbackData.createdAt}</Moment>}>
                            <div>
                                <span className={classes.ratingLabel}>Rating</span>
                                <span className={classes.ratingScore}>{feedbackData.score}</span>
                            </div>
                        </Tooltip>
                    )
                }
            </div>
            <div className={classes.wrapper}>
                <div className={classes.header}>Feedback</div>
                <div className={classes.comment}>
                    {feedbackData.comment || 'No feedback given'}
                </div>
            </div>
        </>
    );
});

export default SatisfactionBlock;

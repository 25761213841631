import React from 'react';
import {
    AI_HEALTH_STATUS_LOW,
    AI_HEALTH_STATUS_MEDIUM,
    AI_HEALTH_STATUS_OPTIMIZED
} from 'helper/constants';
import Tick from './tick';
import Line from './line';
import Circle from './circle';


const ALProgressBar = ({ color, status }) => (
    <div className="u-dsp--f u-ai--center u-mrg--tx1">
        {status === AI_HEALTH_STATUS_LOW ? <Circle /> : <Tick color={color} />}
        <Line color={status === AI_HEALTH_STATUS_LOW ? '#C7C8CC' : color} />
        {status === AI_HEALTH_STATUS_MEDIUM || status === AI_HEALTH_STATUS_LOW ? <Circle /> : <Tick color={color} />}
        <Line color={status === AI_HEALTH_STATUS_MEDIUM || status === AI_HEALTH_STATUS_LOW ? '#C7C8CC' : color} />
        {status === AI_HEALTH_STATUS_OPTIMIZED ? <Tick color={color} /> : <Circle />}
    </div>
);


export default ALProgressBar;

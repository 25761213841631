import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from 'libraries/Dialog';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { removeContentLibraryAssessment } from 'requests/CMSRequests';

const styles = () => ({
    actionButton: {
        fontWeight: 700,
        paddingRight: 20,
        paddingLeft: 20
    },
    deleteButton: {
        paddingRight: 40,
        paddingLeft: 40,
        fontWeight: 700,
        minWidth: 213
    },
    loader: {
        color: '#fff'
    }
});

const DeleteContentLibraryAssessmentDialog = observer(({
    onDelete, classes, open, onClose, uuid
}) => {
    const [loading, setLoading] = useState(false);
    const { flashMessage } = useContext(appCtx);


    const deleteScriptAction = (e) => {
        setLoading(true);
        removeContentLibraryAssessment(uuid)
            .then(() => {
                onClose(e);
                flashMessage('Assessment deleted', 'done');
                setLoading(false);
                if (onDelete) {
                    onDelete();
                }
            });
    };

    return (
        <>
            <Dialog
                maxWidth="sm"
                titleComponent="Are you sure you want to delete this assessment?"
                actionComponent={(
                    <>
                        <Button
                            className={classes.actionButton}
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            className={classes.deleteButton}
                            onClick={deleteScriptAction}
                        >
                            {
                                loading ? (
                                    <CircularProgress
                                        size={24}
                                        thickness={3}
                                        classes={{ root: classes.loader }}
                                    />
                                ) : 'Delete Assessment'
                            }
                        </Button>
                    </>
                )}
                open={open}
                onClose={onClose}
                handleClose={onClose}
            >
                Questions and sets will not be deleted from library. You cannot recover this action.
            </Dialog>
        </>
    );
});

export default withStyles(styles)(DeleteContentLibraryAssessmentDialog);

import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import BoardItem from 'components/board/board_item';
import BookmarkIcon from 'components/icons/BookmarkIcon';
import { returnScoreLabel } from 'helper/assessmentFunctions';
import { returnCandidateLabel } from 'helper/commonFunctions';
import Tooltip from 'libraries/TooltipWhite';

import TagsContainer from 'components/candidate_cards/TagsContainer';
import ScoreContainer from 'components/candidate_cards/ScoreContainer';
import { candidateResultCtx } from '../../CandidateListWrapper/candidatesStore';

import styles from './styles';


const PreviewCandidateCard = observer(({ candidate, classes }) => {
    const { tooltip, card, container, wrapperBorder,
        name, shortlistedIcon, description, removeTooltipArrow,
        scoreLabel, scoresTitle, footer, scrollerContainer } = classes;

    const { isEmployerSortEnabled } = useContext(candidateResultCtx);

    const {
        fullName, rank, rankTotal, score, shortlisted,
        tags, topPerformer,
        candidateId, rejected, candidateStatus
    } = candidate;

    const hired = candidateStatus === 'Hired';

    const renderedScoreLabel = returnScoreLabel(score, candidate, isEmployerSortEnabled);

    if (rejected) return null;

    return (
        <div className={removeTooltipArrow}>
            <Tooltip
                id={`groups_${candidateId}`}
                className={tooltip}
                effect="float"
                delayShow={500}
                delayHide={500}
            >
                <BoardItem className={card} onClick={e => e.stopPropagation()}>
                    <div className={container}>
                        <div className="u-dsp--f u-jc--sb">
                            <div className={name}>{fullName}</div>
                            {shortlisted && <BookmarkIcon className={shortlistedIcon} color="#00B898" />}
                        </div>
                        <div className={description}>
                            <div>
                                <span className={scoreLabel}>Rank</span> {rank || '-'} / {rankTotal || '-'}
                            </div>
                            {
                                score && score.score !== null && (
                                    <div className="u-dsp--f">
                                        <span className={scoreLabel}>{renderedScoreLabel}</span>{score.score}%
                                    </div>
                                )
                            }
                            {returnCandidateLabel(hired, topPerformer)}
                        </div>
                    </div>
                    <div className={wrapperBorder}>
                        <ScoreContainer
                            smallCard
                            className={scrollerContainer}
                            candidate={candidate}
                        />
                    </div>
                    {tags.length ? (
                        <div className={footer}>
                            <div className={scoresTitle}>Tags</div>
                            <TagsContainer tags={tags} />
                        </div>
                    ) : null}
                </BoardItem>
            </Tooltip>
        </div>
    );
});

export default withStyles(styles)(PreviewCandidateCard);

import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';

const styles = {
    submitButton: {
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 180
    },
    keepButton: {
        fontWeight: 700
    },
    loader: {
        color: '#fff'
    }
};

const ConfirmGenerateDialog = ({ open, classes, onClose, generateCode }) => (
    <Dialog
        maxWidth="sm"
        titleComponent="Are you sure you want to generate new code?"
        actionComponent={(
                <>
                    <Button
                        color="primary"
                        className={classes.keepButton}
                        onClick={() => onClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.submitButton}
                        variant="contained"
                        color="primary"
                        onClick={() => { generateCode(); onClose(); }}
                    >
                        Generate Code
                    </Button>
                </>
        )}
        open={open}
        handleClose={onClose}
        onClose={onClose}
    >
        <p>
            Existing code will be removed from question
        </p>
    </Dialog>
);

export default withStyles(styles)(ConfirmGenerateDialog);

import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { appCtx } from 'components/appStore';
import Tooltip from 'libraries/Tooltip';
import { checkHasUserPermission } from 'helper/commonFunctions';
import RemoveFraudDetectionFlagDialog from 'components/dialogs/RemoveFraudDetectionFlagDialog';

import FraudIcon from 'img/icons/fraudFlag.svg';
import QuestionIcon from 'img/icons/questionIcon.svg';


import styles from '../styles';

const FraudDetectedBanner = observer(({ candidate, audition, classes, getUserInfo }) => {
    const [open, setOpen] = useState(false);
    const { fraudDetected, fraudDetectedCities } = candidate;
    const { company: { fraudDetectionPeriodInMinutes }, company } = useContext(appCtx);
    const edit = checkHasUserPermission(company, permissions, 'edit');
    const { permissions } = audition;

    const handleDialog = () => {
        setOpen(!open);
    };

    if (!fraudDetected || !fraudDetectedCities || !fraudDetectedCities.length) return null;

    return (
        <>
            <div className={classes.wrapper}>
                <img src={FraudIcon} alt="!" />
                <div>
                    Candidate identified in multiple locations during assessment
                    <span>
                (
                        { fraudDetectedCities.map((city, index) => {
                            if (index === fraudDetectedCities.length - 1) return <>{city}</>;
                            return <>{`${city} / `}</>;
                        })}
                )
                    </span>
                </div>
                <Tooltip label={`Location detection is activated if Vervoe detects a candidate has undertaken an assessment in multiple locations within ${fraudDetectionPeriodInMinutes} minutes.`}>
                    <img src={QuestionIcon} alt="?" />
                </Tooltip>
                {
                    edit && (
                        <Button
                            color="primary"
                            onClick={handleDialog}
                            sx={{ fontSize: 12, whiteSpace: 'nowrap' }}
                        >
                            Remove Flag
                        </Button>
                    )
                }

            </div>
            <RemoveFraudDetectionFlagDialog
                open={open}
                audition={audition}
                candidate={candidate}
                onClose={handleDialog}
                getUserInfo={getUserInfo}
            />
        </>
    );
});

export default withStyles(styles)(withRouter(FraudDetectedBanner));

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import LoaderBounce from 'components/loaders/LoaderBounce';
import CandidatesListPublicCard from './CandidatesListPublicCard';
import CandidateContent from './CandidateContent';
import CompareContent from './CompareContent';
import useStyles from './styles';


const MultipleCardsMode = observer(({
    className = '', getUserInfo, company, isLoading, isLoadingAudition,
    user, opportunitySlug, audition, candidatesInfo,
    loadCandidatePosts, propPublic, authorizeGuest, gradeableCard,
    addCandidateComment, loadGradeQuestion, gradeFunction, selectedCandidateUuid,
    getAuditionQuestionResults, hideCandidateDetails, onChangePublicUser,
    getAuditionScreeningQuestionResults: getAuditionScreeningResults
}) => {
    const [isCompare, setIsCompare] = useState(false);

    const classes = useStyles();

    const onChangeUser = (uuid) => {
        setIsCompare(false);
        onChangePublicUser(uuid);
    };

    const toggleCompareMode = () => {
        setIsCompare(!isCompare);
    };

    if (isLoading) {
        return (
            <div className="u-txt--center u-pdn--tx10 u-pdn--bx10">
                <LoaderBounce />
            </div>
        );
    }
    const candidates = Object.values(candidatesInfo).map(({ candidate }) => candidate);

    return (
        <div className={clsx(classes.wrapper, className)}>
            <div className={classes.leftPanel}>
                <CandidatesListPublicCard
                    isCompare={isCompare}
                    candidates={candidates}
                    onChangePublicUser={onChangeUser}
                    selectedCandidateUuid={selectedCandidateUuid}
                    toggleCompareMode={toggleCompareMode}
                    hideCandidateDetails={hideCandidateDetails}
                />
            </div>
            <div className={clsx(classes.contentPanel, isCompare && 'u-pdn--x0')}>
                {
                    isCompare ? (
                        <CompareContent
                            audition={audition}
                            gradeableCard={gradeableCard}
                            handleClose={toggleCompareMode}
                            candidatesInfo={candidatesInfo}
                            opportunitySlug={opportunitySlug}
                            hideCandidateDetails={hideCandidateDetails}
                            candidatesIdsToCompare={Object.keys(candidatesInfo).reduce((prevObj, key) => ({ ...prevObj, [key]: candidatesInfo[key].opportunities[0].columns[0].userAudition.uuid }), {})}
                        />
                    ) : (
                        <CandidateContent
                            {...{
                                user,
                                audition,
                                company,
                                propPublic,
                                getUserInfo,
                                opportunitySlug,
                                hideCandidateDetails,
                                loadCandidatePosts,
                                authorizeGuest,
                                gradeFunction,
                                addCandidateComment,
                                loadGradeQuestion,
                                gradeableCard,
                                isLoadingAudition,
                                getAuditionQuestionResults,
                                getAuditionScreeningResults
                            }}
                        />
                    )
                }
            </div>
        </div>
    );
});

export default withRouter(MultipleCardsMode);

import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { resendEmailConfirmation, confirmEmail } from 'requests/LoginRequests';
import OnboardingEvents from 'events/OnboardingEvents';
import VerifyCodeForm from '../common/VerifyCodeForm';
import useTimeout from '../../../hooks/useTimeout';

const CodeVerification = ({ location, flashMessage }) => {
    const [resendLoading, setResendLoading] = useState(false);
    const [incorrect, setIncorrect] = useState(false);
    const [loading, setLoading] = useState(false);

    if (!location.state) return <Redirect to="/forbidden" />;

    const { enteredEmail, emailConfirmationExpiresAfterMinutes } = location.state;

    const { restartWithTime, expired, setExpired } = useTimeout(() => {
        OnboardingEvents.FAILED_SUBMIT_SIGNUP('Verification session expired');
    }, emailConfirmationExpiresAfterMinutes * 60 * 1_000);

    useEffect(() => {
        OnboardingEvents.VERIFY_STARTED();
    }, []);

    const handleResendClick = () => {
        OnboardingEvents.ACCOUNT_CODE_RESENT();
        setResendLoading(true);
        resendEmailConfirmation(enteredEmail)
            .then(({ success, data }) => {
                if (success && data) {
                    const { emailConfirmationExpiresAfterMinutes: newExpirationTime } = data;
                    flashMessage(`New code has been sent to ${enteredEmail}`, 'done');
                    setIncorrect(false);
                    restartWithTime(newExpirationTime * 60 * 1_000);
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setResendLoading(false);
            });
    };

    const verify = (code, callback) => {
        setLoading(true);
        OnboardingEvents.VERIFY_SUBMITTED();
        confirmEmail(enteredEmail, code)
            .then(({ success, data }) => {
                if (success && data.redirection_url) {
                    window.location = data.redirection_url;
                } else {
                    callback(data);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.errors === 'Email confirmation expired') setExpired(true);
                if (err.response && err.response.data.errors === 'Email confirmation code is incorrect') setIncorrect(true);
                if (err.response && err.response.data.errors === 'Email already confirmed') flashMessage(err.response.data.errors);
                OnboardingEvents.FAILED_SUBMIT_SIGNUP(err.response.data.errors);
            })
            .finally(() => setLoading(false));
    };

    return (
        <VerifyCodeForm
            flashMessage={flashMessage}
            locationState={location.state}
            resendLoading={resendLoading}
            incorrect={incorrect}
            loading={loading}
            expired={expired}
            onResend={handleResendClick}
            onVerify={verify}
            showSignIn
        />
    );
};

export default withRouter(CodeVerification);

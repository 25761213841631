export default theme => ({
    sectionRow: {
        display: 'flex',
        padding: '15px 0',
        fontWeight: 'bold',
        fontSize: 16,
        flexWrap: 'wrap'
    },
    sectionItem: {
        width: 130
    },
    sectionItemHeader: {
        color: theme.palette.grey[500],
        fontWeight: 'bold',
        fontSize: 10,
        textTransform: 'uppercase',
        marginBottom: -2
    },
    sectionItemNumber: {
        fontSize: '16px',
        lineHeight: '28px',
        whiteSpace: 'nowrap',
        color: theme.palette.grey[900],

        '&.smaller': {
            fontSize: '12px'
        }
    },
    rate: {
        marginLeft: 5,
        fontWeight: 'bold',
        fontSize: 12,
        color: theme.palette.grey[500]
    },
    lastTime: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.grey[500],

        '&.smaller': {
            fontSize: '11px'
        }
    },
    smWidth: {
        width: 80,
        '@media only screen and (max-width: 790px)': {
            width: 70
        }
    },
    mdWidth: {
        width: 150
    }
});

import React, { useEffect, useState } from 'react';
import EditVideoPreview from 'components/job_or_assessment_settings/EditVideoPreview';
import { loadWelcomeScreenData, changeWelcomeScreenData } from 'requests/AssessmentRequests';
import { createEmptyValue, createValueFromString } from 'react-rte';
import RichTextEditorCustom from 'components/inputs/RichTextEditorCustom';
import InnerContentWrapper from 'pages/assessment/components/InnerContentWrapper';
import PreviewButton from './PreviewButton';


let timedIdForJobInput = null;
const maxCharacters = 20000;

const WelcomeSection = ({ slug, setSaving }) => {
    const [jobDescription, setJobDescription] = useState(createEmptyValue());
    const [symbolsLeft, setSymbolsLeft] = useState(maxCharacters);
    const [ziggeo, setZiggeo] = useState(null);

    useEffect(() => {
        loadWelcomeScreenData(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setNewData(data);
                }
            });
    }, []);

    const setNewData = (data) => {
        const { jobDescription: newJobDescription, ziggeo: newZiggeo } = data;
        setJobDescription(createValueFromString(newJobDescription, 'html') || createEmptyValue());
        setZiggeo(newZiggeo);
        setSymbolsLeft(maxCharacters - newJobDescription.toString('html').replace(/<(?:.|\n)*?>/gm, '').length);
    };

    const onChangeJobDescription = (value) => {
        const leftSymbols = maxCharacters - value.toString('html').replace(/<(?:.|\n)*?>/gm, '').length;
        setSymbolsLeft(leftSymbols);
        setJobDescription(value);
        if (leftSymbols >= 0) {
            clearTimeout(timedIdForJobInput);
            timedIdForJobInput = setTimeout(() => {
                // line below prevents from sending '<p><br></p>' instead of empty line
                const desc = value.toString('html') === '<p><br></p>' ? '' : value.toString('html');
                saveNewSettings({ jobDescription: desc });
            }, 300);
        }
    };

    const saveNewSettings = (newData) => {
        setSaving(true);
        changeWelcomeScreenData(slug, newData).then(() => setSaving(false));
    };

    const recordingPublished = (token) => {
        if (!token) return;
        const newZiggeo = { token };
        setZiggeo(token);
        changeWelcomeScreenData(slug, { ziggeo: newZiggeo });
    };

    const removeVideoDescription = () => {
        const newZiggeo = null;
        setZiggeo(newZiggeo);
        changeWelcomeScreenData(slug, { ziggeo: newZiggeo });
    };

    return (
        <InnerContentWrapper
            header="Welcome screen (for invitation via link)"
            className="u-mrg--x0 u-mrg--tx5 u-pdn--x0 u-brdr--none"
            headerActions={(
                <PreviewButton slug={slug} />
            )}
        >
            If you are sharing to your network or via a link, this introduction will give your candidates details about the assessment before signing up.
            <br />
            <br />
            Welcome screen message can contain up to 20000 characters.
            <div className="u-mrg--bx8">
                <RichTextEditorCustom
                    value={jobDescription}
                    onChange={onChangeJobDescription}
                    error={symbolsLeft <= 0 && <span className="t-txt--red u-txt--11">{`Welcome screen message can contain up to ${maxCharacters} characters.`}</span>}
                    placeholder="Give your candidates some guidance on the welcome screen."
                />
            </div>
            <EditVideoPreview
                ziggeoToken={ziggeo}
                recordingPublished={recordingPublished}
                removeVideoDescription={removeVideoDescription}
            />
        </InnerContentWrapper>
    );
};

export default WelcomeSection;

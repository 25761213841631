import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import CheckedImg from 'components/candidate_cards/CandidateCardWrapper/GradeBlock/components/QuestionsContent/QuestionResultHeader/GradingLabel/check.svg';

import stylesJS from './styles';

const ScreeningQuestionResultHeader = ({ classes, onlyMultipleChoice, className = '', label }) => (
    <div className={`${className}`}>
        <div className="u-dsp--distribute">
            <div className="u-dsp--f u-ai--center">
                <div className={classes.header}>
                    Screening
                </div>
                {
                    !onlyMultipleChoice && (
                        <div className={clsx(classes.label, classes.autogradedLabel)}>
                            <img src={CheckedImg} alt=" " /> AUTOGRADED
                        </div>
                    )
                }
            </div>
            <div>{!onlyMultipleChoice && label}</div>
        </div>
    </div>
);

export default withStyles(stylesJS)(ScreeningQuestionResultHeader);

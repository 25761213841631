export default {
    fab: {
        width: 72,
        height: 68,
        position: 'absolute',
        top: -34,
        left: '50%',
        transform: 'translate(-50%,0)',
        backgroundColor: '#202328',
        '&:hover': {
            backgroundColor: '#2023287d'
        },
        boxShadow: 'none'
    },
    fabOpen: {
        zIndex: -1
    }
};

const styles = {
    title: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        '&>h2': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 'bold',
            fontSize: '24px',
            color: '#1a1b1e'
        }
    },
    paper: {
        padding: '24px',
        width: '352px',
        boxShadow: '0 1px 15px 0 rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover+$addBtn': {
            zIndex: 1,
            opacity: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.94)'
        }
    },
    type: {
        fontSize: '14px',
        color: '#3a3c43',
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'flex-start',
        '&:nth-child(2)': {
            marginBottom: 30
        }
    },
    typeH5: {
        fontSize: '24px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.17',
        color: '#3d3f47',
        marginBottom: '15px'
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 0
    },
    addBtn: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        fontSize: '24px',
        fontFamily: 'Open Sans !important',
        fontWeight: 'bold',
        color: '#1247F6',
        display: 'block',
        backgroundColor: 'rgba(255, 255, 255, 0.94)',
        zIndex: -1,
        transition: 'all 0.4s',
        opacity: 0,
        textTransform: 'none !important',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.94)',
            zIndex: 1,
            opacity: 1
        }
    },
    helpTextLearnMore: {
        color: '#3a3c43',
        fontSize: 14,
        '& a': {
            color: '#3a3c43',
            fontSize: 14,
            textDecoration: 'underline'
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

export default styles;

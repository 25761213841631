export default theme => ({
    addButton: {
        border: `2px solid ${theme.palette.grey[300]}`,
        color: theme.palette.primary.main,
        background: '#ffffff',
        borderRadius: 40,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '20px',
        padding: '5px 20px',
        marginRight: 5,
        width: 70,
        '& img': {
            width: 15
        }
    },
    tooltipAction: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginTop: -2
    }
});

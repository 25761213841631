import React from 'react';
import { withStyles } from '@mui/styles';
import CompanyLogo from 'components/logos/company_logo';
import RichTextEditorCustom from 'components/inputs/RichTextEditorCustom';
import MessageFooterElement from '../MessageFooterElement';

import styles from './styles';

class MessageEdit extends React.Component {
    handleChange = (e, index) => {
        const { setForm } = this.props;
        const updateForm = {};
        const targetName = e.target.name;
        updateForm[targetName] = index;
        setForm(updateForm);
    };

    handleChangeRte = (value) => {
        const { form, setForm, maxCharacters } = this.props;
        const left = maxCharacters - value.toString('html').replace(/<(?:.|\n)*?>/gm, '').length;
        const updateForm = { ...form, description: value, left };
        setForm(updateForm);
    };

    renderError = (formParam) => {
        const { errorForm } = this.props;
        if (errorForm && errorForm[formParam]) {
            return errorForm[formParam];
        }
        return '';
    };

    render() {
        const {
            type, opportunity, form,
            columnType, classes,
            maxCharacters, company,
            assessmentBrand
        } = this.props;

        if (!form || !opportunity) return null;
        const isBodyEmpty = form.left === maxCharacters;

        return (
            <div className=" u-clear  u-pdn--bx0">
                <div className={classes.emailLogoWrapper}>
                    <CompanyLogo
                        className="u-txt--20 u-mrg--tx3 u-mrg--bx3 u-dsp--center"
                        size={220}
                        company={company}
                        assessmentImageUrl={assessmentBrand?.logoUrl}
                    />
                </div>
                <div className={classes.emailContent}>
                    <form className="u-mrg--bx9  u-txt--left">
                        <RichTextEditorCustom
                            placeholder="Your message goes here..."
                            value={form.description}
                            onChange={this.handleChangeRte}
                            required
                            error={isBodyEmpty && 'Email body is required.'}
                        />
                        <span className="t-txt--red  u-txt--11">
                            {this.renderError('description')}
                        </span>
                    </form>
                    <MessageFooterElement
                        opportunity={opportunity}
                        type={type}
                        columnType={columnType}
                    />
                </div>
            </div>
        );
    }
}
export default withStyles(styles)(MessageEdit);

import React from 'react';


const DocsIcon = ({ color = '#6B6B70', ...other }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M14.0791 17.7333H5.33399C4.56079 17.7333 3.93398 17.1065 3.93398 16.3333V3.66663C3.93398 2.89343 4.56079 2.26663 5.33398 2.26663H12.3095C12.6808 2.26663 13.0369 2.41413 13.2994 2.67668L15.069 4.44628C15.3316 4.70883 15.4791 5.06493 15.4791 5.43623V16.3333C15.4791 17.1065 14.8523 17.7333 14.0791 17.7333Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.1582 2.08325V5.58819H15.418" stroke={color} strokeWidth="1.2" strokeLinejoin="round" />
        <path d="M6.27539 8.52942H13.1381" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.27539 10.4901H13.1381" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.27539 12.4509H13.1381" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.27539 14.4117H10.197" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default DocsIcon;

import React from 'react';
import { withStyles } from '@mui/styles';
import { useMediaQuery, useTheme } from '@mui/material';
import DownloadIcon from './download.svg';

import styles from './styles';

const AnswerOptionFileDownload = ({ answer: { file }, classes }) => {
    if (!file) return null;
    const { fileUrl, fileName, fileSize } = file;
    const {
        wrapper, fileNameWrapper,
        fileNameLabelImg, downloadButton,
        fileNameLabel, fileSizeLabel
    } = classes;

    const theme = useTheme();

    const mediaFullPath = process.env.REACT_APP_API_URL + fileUrl;
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

    const getFileSize = () => {
        if (!fileSize) return null;
        const mbCount = fileSize / 1000000;
        const kbCount = fileSize / 1000;
        if (mbCount > 1) {
            return `${Math.round(mbCount)}mb`;
        }
        if (kbCount > 1) {
            return `${Math.round(kbCount)}kb`;
        }
        return `${fileSize}b`;
    };

    return (
        <div className={wrapper}>
            <div className={fileNameWrapper}>
                <img
                    className={fileNameLabelImg}
                    src={DownloadIcon}
                    alt="Download"
                />
                <div className={fileNameLabel}>
                    { fileName }
                </div>
                {fileSize && <div className={fileSizeLabel}>&nbsp;({getFileSize()})</div>}
            </div>
            <a
                className={downloadButton}
                href={mediaFullPath}
                target={isMobile ? '_blank' : undefined}
                rel="noopener noreferrer"
            >
                Download file
            </a>
        </div>
    );
};

export default withStyles(styles)(AnswerOptionFileDownload);

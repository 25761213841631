import { Component } from 'react';
import { applyCompany } from 'requests/LoginRequests';
import * as qs from 'query-string';

class CompanyApply extends Component {
    componentDidMount() {
        const { location, history } = this.props;
        const parsed = qs.parse(location.search);
        const { hashCode, email } = parsed;
        const localStorageToken = localStorage.getItem('token');
        applyCompany(hashCode, email)
            .then((data) => {
                const { success, isNewUser, token } = data;
                if (success) {
                    if (isNewUser) {
                        history.push(`/registration?${Object.keys(parsed).map(key => `${key}=${parsed[key]}`).join('&')}`);
                    } else {
                        history.push(`/welcome?token=${token}`);
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 418) {
                    window.location = localStorageToken ? '/welcome' : '/login';
                } else {
                    window.location = '/forbidden';
                }
            });
    }

    render() {
        return null;
    }
}

export default CompanyApply;

import React from 'react';

import { withStyles } from '@mui/styles';

import styles from './styles';

const LanguageList = ({ list, classes }) => (
    <>
        <h2 className={classes.title}>For this question you can code the following languages:</h2>
        <div className={classes.listWrapper}>
            {
                list.map(item => (
                    <div key={item} className={classes.listItem}>
                        {item[0].toUpperCase() + item.slice(1)}
                    </div>
                ))
            }
        </div>
    </>
);

export default withStyles(styles)(LanguageList);

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import EditSkillDialog from '../EditSkillDialog';

const CreateSkill = observer(({ onSuccess }) => {
    const [openedDialog, setOpenedDialog] = useState(false);

    const handleDialog = () => {
        setOpenedDialog(!openedDialog);
    };

    return (
        <>
            <Button
                color="primary"
                className="u-txt--bold"
                onClick={handleDialog}
            >
                Create new skill
            </Button>
            <EditSkillDialog
                handleDialog={handleDialog}
                openedDialog={openedDialog}
            />
        </>
    );
});

export default CreateSkill;

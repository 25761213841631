import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import CandidatesCount from 'components/table_components/candidates_count';
import { CANDIDATES_PER_ALL_CANDIDATES_PAGE } from 'helper/constants';
import BoardNoContent from 'components/board/board_no_content';
import CandidatesTable from './components/CandidatesTable';
import { candidateCtx } from '../../AllCandidates/candidateStore';

import EmptyIcon from '../../../assessment/candidate_invite/InviteCandidates/InviteCandidatesList/icon.svg';
import NotFoundContent from '../../../../components/assessments_pages/NotFoundContent';

import useStyles from './styles';
import TagsList from '../../../assessment/components/TagsList';
import { hiredCandidateCtx } from '../../HiredCandidates/hiredCandidateStore';
import HiredCandidatesTable from './components/HiredCandidatesTable';
import HiredTagsList from '../HiredTagsList';


const CandidatesList = observer(({ hired }) => {
    const classes = useStyles();
    const ctx = hired ? hiredCandidateCtx : candidateCtx;
    const {
        totalCount: candidatesLength,
        allCandidates, hiredCandidates, query,
        isLoading, page, isNoCandidates,
        filterCategory, handleChangeFilterSelect,
        isLoadingLocal
    } = useContext(ctx);

    if (isLoading || isLoadingLocal) {
        return (
            <div className={classes.wrapper}>
                <div className={classes.loaderWrapper}>
                    <CircularProgress color="primary" />
                </div>
            </div>
        );
    }

    if (query && !candidatesLength) {
        return <NotFoundContent searchValue={query} />;
    }

    if (isNoCandidates) {
        return (
            <BoardNoContent
                header={hired ? 'No Hired Candidates' : 'No Candidates'}
                hideIconWrapper
                className={classes.board}
                icon={<img src={EmptyIcon} alt="empty-content" />}
            />
        );
    }

    return (
        <>
            <div className={classes.infoWrapper}>
                <CandidatesCount
                    candidatesPerPage={CANDIDATES_PER_ALL_CANDIDATES_PAGE}
                    page={page}
                    candidatesLength={candidatesLength}
                    candidatesFound={hired ? hiredCandidates.length : allCandidates.length}
                />
                {hired
                    ? <HiredTagsList />
                    : <TagsList {...{ filterCategory, handleChangeFilterSelect }} />}
            </div>
            {hired ? <HiredCandidatesTable /> : <CandidatesTable />}
        </>
    );
});

export default CandidatesList;

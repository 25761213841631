import React from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import GradingResultsStatus from '../GradingResultsStatus';
import Questions from './Questions';

import styles from './styles';

const QuestionsList = observer(({
    classes, questionList, setSelectedQuestionIndex,
    selectedQuestionIndex, status
}) => (
    <div className={classes.wrapper}>
        <GradingResultsStatus status={status} />
        <Questions
            questionList={questionList}
            selectedQuestionIndex={selectedQuestionIndex}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
        />
    </div>
));

export default withStyles(styles)(QuestionsList);

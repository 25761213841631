import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: 'white',
        flex: 1,
        overflow: 'hidden'
    },
    loader: {
        background: 'rgba(255,255,255,0.8)',
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    spinnerRoot: {
        marginLeft: -700,
        '& > svg': {
            width: 65,
            height: 65
        }
    },
    spinnerColor: {
        color: theme.palette.blue[600]
    }
}));

import React from 'react';
import { withStyles } from '@mui/styles';
import QuestionWrapper from 'components/marketplace/Preview/common/QuestionWrapper';
import RemainingTimeLabel from 'components/job_or_assessment_settings/RemainingTimeLabel';
import DiamondTooltip from 'components/subscription/DiamondTooltip';


const styles = theme => ({
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media only screen and (max-width: 767px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    numberQuestion: {
        fontWeight: 'bold',
        color: theme.palette.grey[900],
        fontSize: 16,
        '& span:first-child': {
            fontSize: 12,
            color: '#3a3c43',
            fontWeight: 600,
            marginLeft: 10,
            display: 'inline-block'
        }
    },
    requiredLabel: {
        display: 'inline-block',
        padding: '5px 10px',
        borderRadius: '12px',
        backgroundColor: '#e6e6e6',
        fontSize: 10,
        color: '#1a1b1e',
        letterSpacing: '.8',
        marginLeft: 10,
        textTransform: 'uppercase'
    }
});

const QuestionHeader = withStyles(styles)(({
    index, classes,
    question, interview: { timerEnabled }
}) => (
    <div className={classes.headerWrapper}>
        <div className={classes.numberQuestion}>
            Question {index + 1}
            <RemainingTimeLabel
                question={question}
                auditionTimerEnabled={timerEnabled}
            />
            {
                question.required && (
                    <span className={classes.requiredLabel}>
                        Required
                    </span>
                )
            }
        </div>
        <DiamondTooltip />
    </div>
));

const QuestionPreviewLocked = ({ audition, question, activeQuestionIndex }) => (
    <QuestionWrapper
        QuestionHeaderComp={QuestionHeader}
        interview={audition}
        questions={[question]}
        index={activeQuestionIndex}
    />
);

export default QuestionPreviewLocked;

import React from 'react';


const NumberIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M5.33334 12H26.6667" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.33334 20H26.6667" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.3333 4L10.6667 28" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.3334 4L18.6667 28" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default NumberIcon;

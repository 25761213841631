import React from 'react';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import styles from './styles';

const NumberInput = ({
    classes,
    min,
    max,
    InputProps = { classes: {} },
    ...other
}) => (
    <TextField
        variant="outlined"
        type="number"
        InputProps={{
            classes: {
                root: classes.root,
                input: classes.input,
                disabled: classes.disabled,
                notchedOutline: classes.notchedOutline,
                ...InputProps.classes
            },
            inputProps: { min, max }
        }}
        {...other}
    />
);

export default withStyles(styles)(NumberInput);

export default theme => ({
    header: {
        width: 'fit-content',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        padding: '3px 10px',
        fontSize: 14,
        fontWeight: 700,
        borderRadius: 4,
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.grey[300]
    },
    iconWrapper: {
        lineHeight: '5px'
    },
    svgIconStyle: {
        maxWidth: 17,
        maxHeight: 17,
        marginLeft: 6
    }
});

import React, { Fragment } from 'react';

// material components
import Button from '@mui/material/Button';
import { withStyles, withTheme } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

// local files and components
import DropZone from '../../../../../DropZone';
import styles from './styles';

const GoogleDocCase = ({
    googleLink,
    title, loading,
    handleGoogleDialog,
    createGoogleDoc,
    onDrop, theme,
    classes
}) => {
    const link = googleLink && googleLink.previewLink;

    const onClick = () => {
        if (loading) return;
        if (link) {
            handleGoogleDialog();
        } else {
            createGoogleDoc();
        }
    };

    return (
        <Fragment>
            <div
                className="o-box o-box--whitefull u-cursor--p u-mrg--bx1 c-img-upload-box"
                onClick={onClick}
                style={{
                    marginTop: '25px',
                    boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.13)'
                }}
                role="presentation"
            >
                <div className="u-txt--center">
                    {
                        loading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <>
                                {
                                    link
                                        ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g fill={theme.palette.primary.main} fillRule="evenodd">
                                                    <path d="M22.72 1.279a4.389 4.389 0 0 1-.035 6.23l-14.5 14.5a.75.75 0 0 1-.344.196L.936 23.976a.75.75 0 0 1-.913-.912l1.77-6.905a.75.75 0 0 1 .197-.344L16.484 1.32a4.389 4.389 0 0 1 6.235-.042zm-3.117.22a2.894 2.894 0 0 0-2.053.876L3.196 16.73 1.79 22.21l5.479-1.405L21.63 6.442a2.887 2.887 0 0 0 .03-4.102 2.889 2.889 0 0 0-2.057-.84z" />
                                                    <path d="M16.076 2.79a.75.75 0 0 1 1.06-1.06l5.134 5.134a.75.75 0 0 1-1.06 1.06L16.076 2.79zM1.991 16.875a.75.75 0 0 1 1.06-1.062l5.139 5.13a.75.75 0 0 1-1.06 1.062l-5.139-5.13z" />
                                                </g>
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path fill={theme.palette.primary.main} fillRule="evenodd" d="M12.75 12.75v3.75a.75.75 0 0 1-1.5 0v-3.75H7.5a.75.75 0 0 1 0-1.5h3.75V7.5a.75.75 0 1 1 1.5 0v3.75h3.75a.75.75 0 1 1 0 1.5h-3.75zm-9.235 7.735c-4.687-4.686-4.687-12.284 0-16.97 4.686-4.687 12.284-4.687 16.97 0 4.687 4.686 4.687 12.284 0 16.97-4.686 4.687-12.284 4.687-16.97 0zm1.06-1.06c4.1 4.1 10.75 4.1 14.85 0s4.1-10.75 0-14.85-10.75-4.1-14.85 0-4.1 10.75 0 14.85z" />
                                            </svg>
                                        )
                                }
                            </>
                        )
                    }
                    <br />
                    <p className={classes.btnLink}>
                        {`${link ? 'Edit ' : 'Create new '} ${title}`}
                    </p>
                </div>
            </div>
            {
                (!link) && (
                    <DropZone
                        onDrop={onDrop}
                        disabled={loading}
                        minSize={1}
                        maxSize={10485760}
                        label={(
                            <p className={classes.linkWrapper}>
                                <Button
                                    color="primary"
                                    className={classes.uploadExist}
                                >
                                    {`or upload existing ${title}`}
                                </Button>
                                (max file size 10MB)
                            </p>
                        )}
                    />
                )
            }
        </Fragment>
    );
};

export default withTheme(withStyles(styles)(GoogleDocCase));

import React from 'react';
import { observer } from 'mobx-react-lite';
import AFDialog from './AFDialog';

const ShareCandidateDialog = observer(({
    disabledScoring, open, onClose, company,
    flashMessage, shareCandidate, user,
    userUuid, assessmentSlug, assessment, userIds
}) => (
    <AFDialog
        open={open}
        user={user}
        userIds={userIds}
        company={company}
        onClose={onClose}
        userUuid={userUuid}
        flashMessage={flashMessage}
        shareCandidate={shareCandidate}
        assessmentSlug={assessmentSlug}
        disabledScoring={disabledScoring}
        assessment={assessment}
    />
));

export default ShareCandidateDialog;

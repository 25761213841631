import React from 'react';
import { withTheme } from '@mui/styles';

const CatstompIcon = ({ theme, size = 32, active, ...other }) => (active ? (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect width="32" height="32" rx="4" fill={active ? theme.palette.primary.main : theme.palette.grey[400]} />
        <rect x="8.83337" y="24.8334" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="9.66675" y="12.3334" width="11.6667" height="10" fill="#C4C4C4" />
        <rect x="8.83337" y="24.0001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="8.83337" y="19.8333" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="8.83337" y="19.0001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="8.83337" y="13.9999" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="8.83337" y="13.1667" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="8.83337" y="12.3334" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="18.8333" y="11.5001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="9.66675" y="12.3334" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="19.6666" y="11.5001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="19.6666" y="13.9999" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="19.6666" y="14.8333" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="19.6666" y="16.5" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="20.5" y="25.6667" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="18.8333" y="17.3334" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="21.3334" y="16.5" width="1.66667" height="0.833333" fill="#6B6B70" />
        <rect x="20.5" y="19.0001" width="2.5" height="0.833333" fill="#6B6B70" />
        <rect x="20.5" y="21.4999" width="2.5" height="0.833333" fill="#6B6B70" />
        <rect x="18.8333" y="19.8333" width="2.5" height="0.833333" fill="#6B6B70" />
        <rect x="18.8333" y="22.3333" width="1.66667" height="0.833333" fill="#6B6B70" />
        <rect x="11.3335" y="21.4999" width="1.66667" height="0.833333" fill="#6B6B70" />
        <rect x="17.1665" y="27.3333" width="4.16667" height="0.833333" fill="#6B6B70" />
        <rect x="19.6666" y="24.0001" width="1.66667" height="0.833333" fill="#6B6B70" />
        <rect x="13.8335" y="23.1667" width="2.5" height="0.833333" fill="#6B6B70" />
        <rect x="20.5" y="13.9999" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="18.8333" y="13.1667" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="10.5001" y="12.3334" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="20.5" y="11.5001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="21.3334" y="12.3334" width="0.833333" height="1.66667" fill="#6B6B70" />
        <rect x="22.1667" y="13.9999" width="0.833333" height="1.66667" fill="#6B6B70" />
        <rect x="23.0001" y="15.6666" width="0.833333" height="4.16667" fill="#6B6B70" />
        <rect x="22.1667" y="19.8333" width="0.833333" height="3.33333" fill="#6B6B70" />
        <rect x="15.4998" y="4.83337" width="4.16667" height="7.5" fill="#C4C4C4" />
        <rect x="10.5001" y="5.66675" width="1.66667" height="6.66667" fill="#C4C4C4" />
        <rect x="21.3334" y="23.1667" width="0.833333" height="2.5" fill="#6B6B70" />
        <rect x="21.3334" y="26.4999" width="0.833333" height="1.66667" fill="#6B6B70" />
        <rect x="17.1665" y="26.4999" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="16.3331" y="24.0001" width="0.833333" height="2.5" fill="#6B6B70" />
        <rect x="8.83337" y="11.5001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="9.66675" y="11.5001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="19.6666" y="10.6667" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="9.66675" y="10.6667" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="10.5001" y="10.6667" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="10.5001" y="5.66675" width="0.833333" height="5" fill="#6B6B70" />
        <rect x="19.6666" y="5.66675" width="0.833333" height="5" fill="#6B6B70" />
        <rect x="11.3335" y="5.66675" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="12.1669" y="6.50012" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="17.1665" y="6.50012" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="14.6665" y="6.50012" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="18.8333" y="6.50012" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="17.9999" y="5.66675" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="15.4998" y="4.83337" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="18.8333" y="4.83337" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="14.6665" y="5.66675" width="2.5" height="0.833333" fill="#6B6B70" />
        <rect x="16.3331" y="4" width="3.33333" height="0.833333" fill="#6B6B70" />
        <rect x="13.8335" y="8.16656" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="15.4998" y="8.16656" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="17.1665" y="8.16656" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="17.9999" y="7.33325" width="0.833333" height="1.66667" fill="#6B6B70" />
        <rect x="14.6665" y="8.16656" width="0.833333" height="3.33333" fill="#6B6B70" />
        <rect x="13.0002" y="7.33325" width="0.833333" height="3.33333" fill="#6B6B70" />
        <rect x="16.3331" y="8.16656" width="0.833333" height="2.5" fill="#6B6B70" />
        <rect x="9.66675" y="19.0001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="11.3335" y="19.0001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="13.0002" y="22.3333" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="11.3335" y="16.5" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="14.6665" y="15.6666" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="9.66675" y="18.1667" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="10.5001" y="18.1667" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="10.5001" y="22.3333" width="0.833333" height="1.66667" fill="#6B6B70" />
        <rect x="9.66675" y="17.3334" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="9.66675" y="14.8333" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="13.8335" y="14.8333" width="2.5" height="0.833333" fill="#6B6B70" />
        <rect x="13.0002" y="18.1667" width="5" height="0.833333" fill="#6B6B70" />
        <rect x="10.5001" y="16.5" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="8" y="19.8333" width="0.833333" height="4.16667" fill="#6B6B70" />
        <rect x="8" y="24.0001" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="10.5001" y="24.0001" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="11.3335" y="23.1667" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="11.3335" y="22.3333" width="1.66667" height="0.833333" fill="#C4C4C4" />
        <rect x="13.0002" y="24.0001" width="3.33333" height="0.833333" fill="#C4C4C4" />
        <rect x="16.3331" y="26.4999" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="9.66675" y="24.8334" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="9.66675" y="15.6666" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="8.83337" y="14.8333" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="12.1669" y="5.66675" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="13.0002" y="6.50012" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="13.8335" y="7.33325" width="1.66667" height="0.833333" fill="#C4C4C4" />
        <rect x="12.1669" y="11.5001" width="0.833333" height="0.833333" fill="white" />
        <rect x="16.3331" y="11.5001" width="0.833333" height="0.833333" fill="white" />
        <rect x="12.1669" y="13.9999" width="0.833333" height="0.833333" fill="white" />
        <rect x="16.3331" y="13.9999" width="0.833333" height="0.833333" fill="white" />
        <rect x="11.3335" y="12.3334" width="0.833333" height="0.833333" fill="white" />
        <rect x="15.4998" y="12.3334" width="0.833333" height="0.833333" fill="white" />
        <rect x="16.3331" y="12.3334" width="1.66667" height="1.66667" fill="black" />
        <rect x="12.1669" y="12.3334" width="1.66667" height="1.66667" fill="black" />
        <rect x="13.8335" y="12.3334" width="0.833333" height="0.833333" fill="white" />
        <rect x="17.9999" y="12.3334" width="0.833333" height="0.833333" fill="white" />
        <rect x="13.0002" y="11.5001" width="0.833333" height="0.833333" fill="white" />
        <rect x="17.1665" y="11.5001" width="0.833333" height="0.833333" fill="white" />
        <rect x="13.0002" y="13.9999" width="0.833333" height="0.833333" fill="white" />
        <rect x="17.1665" y="13.9999" width="0.833333" height="0.833333" fill="white" />
        <rect x="11.3335" y="13.1667" width="0.833333" height="0.833333" fill="white" />
        <rect x="15.4998" y="13.1667" width="0.833333" height="0.833333" fill="white" />
        <rect x="13.8335" y="13.1667" width="0.833333" height="0.833333" fill="white" />
        <rect x="17.9999" y="13.1667" width="0.833333" height="0.833333" fill="white" />
        <rect x="19.6666" y="4" width="0.833333" height="1.66667" fill="#C4C4C4" />
        <rect x="20.5" y="5.66675" width="0.833333" height="5.83333" fill="#C4C4C4" />
        <rect x="12.1669" y="7.33325" width="0.833333" height="4.16667" fill="#C4C4C4" />
        <rect x="13.8335" y="8.99994" width="0.833333" height="3.33333" fill="#C4C4C4" />
        <rect x="21.3334" y="11.5001" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="22.1667" y="12.3334" width="0.833333" height="1.66667" fill="#C4C4C4" />
        <rect x="22.1667" y="23.1667" width="0.833333" height="2.5" fill="#C4C4C4" />
        <rect x="22.1667" y="26.4999" width="0.833333" height="2.5" fill="#C4C4C4" />
        <rect x="22.1667" y="28.1666" width="0.833333" height="5" transform="rotate(90 22.1667 28.1666)" fill="#C4C4C4" />
        <rect x="21.3334" y="25.6667" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="23.0001" y="13.9999" width="0.833333" height="1.66667" fill="#C4C4C4" />
        <rect x="23.0001" y="19.8333" width="0.833333" height="3.33333" fill="#C4C4C4" />
        <rect x="23.8335" y="15.6666" width="0.833333" height="4.16667" fill="#C4C4C4" />
        <rect x="14.6665" y="11.5001" width="1.66667" height="0.833333" fill="#C4C4C4" />
        <rect x="13.0002" y="10.6667" width="0.833333" height="0.833333" fill="#C4C4C4" />
        <rect x="9.66675" y="24.0001" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="13.0002" y="23.1667" width="0.833333" height="0.833333" fill="#6B6B70" />
        <rect x="21.3334" y="13.9999" width="0.833333" height="2.5" fill="#C4C4C4" />
        <rect x="21.3334" y="17.3334" width="0.833333" height="1.66667" fill="#C4C4C4" />
        <rect x="21.3334" y="19.8333" width="0.833333" height="1.66667" fill="#C4C4C4" />
        <rect x="20.5" y="22.3333" width="1.66667" height="0.833333" fill="#C4C4C4" />
        <rect x="19.6666" y="23.1667" width="1.66667" height="0.833333" fill="#C4C4C4" />
        <rect x="16.3331" y="23.1667" width="3.33333" height="0.833333" fill="white" />
        <rect x="17.1665" y="24.0001" width="2.5" height="0.833333" fill="white" />
        <rect x="17.1665" y="24.8334" width="4.16667" height="0.833333" fill="white" />
        <rect x="17.1665" y="25.6667" width="3.33333" height="0.833333" fill="white" />
        <rect x="17.9999" y="26.4999" width="3.33333" height="0.833333" fill="white" />
        <rect x="13.8335" y="22.3333" width="5" height="0.833333" fill="white" />
        <rect x="8.83337" y="22.3333" width="1.66667" height="0.833333" fill="white" />
        <rect x="8.83337" y="20.6666" width="2.5" height="1.66667" fill="white" />
        <rect x="11.3335" y="20.6666" width="6.66667" height="0.833333" fill="white" />
        <rect x="13.0002" y="21.4999" width="5.83333" height="0.833333" fill="white" />
        <rect x="12.1669" y="16.5" width="7.5" height="0.833333" fill="white" />
        <rect x="15.4998" y="15.6666" width="5" height="0.833333" fill="white" />
        <rect x="10.5001" y="15.6666" width="4.16667" height="0.833333" fill="white" />
        <rect x="10.5001" y="14.8333" width="3.33333" height="0.833333" fill="white" />
        <rect x="16.3331" y="14.8333" width="3.33333" height="0.833333" fill="white" />
        <rect x="12.1669" y="17.3334" width="6.66667" height="0.833333" fill="white" />
        <rect x="13.8335" y="19.8333" width="2.5" height="0.833333" fill="white" />
        <rect x="9.66675" y="19.8333" width="1.66667" height="0.833333" fill="white" />
        <rect x="10.5001" y="19.0001" width="0.833333" height="0.833333" fill="white" />
        <rect x="8.83337" y="23.1667" width="1.66667" height="0.833333" fill="white" />
        <rect x="22.1667" y="17.3334" width="0.833333" height="1.66667" fill="#C4C4C4" />
        <rect x="22.1667" y="15.6666" width="0.833333" height="0.833333" fill="#C4C4C4" />
    </svg>
) : (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...other}>
        <rect width="32" height="32" rx="4" fill="#C7C8CC" />
        <rect x="2" y="2" width="28" height="28" fill="url(#pattern0)" />
        <rect x="2" y="2" width="28" height="28" fill="white" style={{ mixBlendMode: 'color' }} />
        <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#image0_4011_30933" transform="scale(0.0285714)" />
            </pattern>
            <image id="image0_4011_30933" width="35" height="35" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAACGUlEQVRYR92Xu1rCQBCFl848gqUlJXZJSTpLy1jCG0BpaalvgCUpKe1iKZ128gg8AnTxOxsnDGuWnYFw+dyGkCx7/j1zydIxFzQ6F8Ri/ifMdGBK7nI3K8xtmqo2q5rsCylA7gej+vHs9cUcHYZ2z4W4IwAikEWeqoFUzpADfOfcFQ4Cm7Tu7AUDIRJ2QwdHuEOavDkIxpdDZ4EhUf5JgPsk8UHOnB0GO3fLmH8/iTNUwlY4Xlaa8+tN2rB7CBENTUWJwuSCRJ3caq3KrAKKl8a9ByBtIqtgbIgm2R9hF2Y2zOtQahJZDLOVF4EwUR+i/JICqWGu4uc6H94f0vq6Py3q6/V8bJsibaBVmM+iKNFZs8nWi9mKA4iD8EYIKE11iZzBggDq9fvys1gyNl9PdwabkFaWGAYLoqqy75EV2Qn2C9KN3o7jDD8+QKH3uBHidgEUg4NQQod6jtiZrV5jjFmsKtGmQSC8+VnAwOlPBMNBeJXwynKTlSA1jS8IQyBUSSTKQUiYA3HHWittm7QNJe0Lkc+hUIiwnsgZDpMPq5/sAqQ5rTc97gxEsEMMXxN0QyYNkdoZgsG5lroyibtOkTuSKqI1gmHiom7c8YwW8jkFqJvBh0mSJKgVnAAxEt110m9KdO6ktymxByIYyUK+l6kGqDUYALv/t2kTkrIWJbDEFZrDcwj31lFkH0nypXUYDXjT3FbDdCjMD+TvejM+4NdRAAAAAElFTkSuQmCC" />
        </defs>
    </svg>
));

export default withTheme(CatstompIcon);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { convertMinutes } from 'components/marketplace/Preview/common/commonFunc';

import RetinaImage from 'react-retina-image';
import styles from './styles';

const PreviewHeader = ({
    assessment, company, classes
}) => {
    const renderLogo = () => {
        const { logo, color, companyName } = company;

        if (logo && logo.length > 0) {
            return (
                <div className={classes.logo}>
                    <RetinaImage
                        alt={companyName}
                        src={logo}
                    />
                </div>
            );
        }

        return (
            <p className={classes.companyLogo} style={{ color }}>{companyName}</p>
        );
    };


    if (!assessment) {
        return (
            <div className={classes.headerPanel}>
                <div
                    className={classes.logoWrapper}
                >
                    {renderLogo()}
                </div>
            </div>
        );
    }
    const { timerCount, timerEnabled } = assessment;
    const timeLabel = timerEnabled ? `${convertMinutes(timerCount)} remaining` : '';

    return (
        <div className={classes.headerPanel}>
            <div
                className={classes.logoWrapper}
            >
                {renderLogo()}
                <span className={classes.timeLimit}>
                    {timeLabel}
                </span>
            </div>
        </div>
    );
};

export default withRouter(withStyles(styles)(PreviewHeader));

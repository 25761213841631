import React from 'react';
import RetinaImage from 'react-retina-image';

import { getInitials } from 'helper/commonFunctions';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    initialsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontSize: 16,
        width: '100%',
        fontWeight: 800,
        height: '100%'
    }
}));

const Photo = ({ user = {}, profile = {}, className, onClick, size, rounded, isActionRequired }) => {
    const { initialsWrapper } = useStyles();
    let photoSource;
    if (profile.image_url || profile.photo) {
        photoSource = profile.image_url || profile.photo;
    } else {
        photoSource = user.image_url || user.imageUrl;
    }
    const { first_name, last_name, name } = user;
    const userName = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : name;

    const initials = getInitials(userName);
    return (
        <div
            role="presentation"
            className={className}
            onClick={onClick}
            style={{ width: size, height: size }}
        >
            {
                photoSource
                    ? (
                        <RetinaImage
                            alt={userName}
                            src={photoSource}
                            style={rounded && { borderRadius: '50%', width: '100%', height: '100%' }}
                        />
                    ) : (
                        <div className={initialsWrapper} style={{ fontSize: Math.round(size / 3.4) }}>
                            {initials}
                        </div>
                    )}
            {
                isActionRequired
                && <div className="c-card__label" />
            }
        </div>
    );
};

export default Photo;

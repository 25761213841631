import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        width: '100%',
        padding: 14,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[200]
    },
    moreButton: {
        borderRadius: 4,
        padding: 5,
        marginRight: 5
    },
    moreButtonIcon: {
        color: theme.palette.grey[500]
    },
    moreButtonIconOpened: {
        transform: 'rotate(180deg)'
    },
    skillName: {
        maxWidth: 'calc(100% - 250px)'
    }
}));

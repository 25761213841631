import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';


const stylesJS = theme => ({
    skillWrapper: {
        display: 'inline-block',
        marginLeft: 15,
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.palette.grey[500],
        textTransform: 'uppercase',
        maxWidth: 350
    }
});


const QuestionSkillLabel = ({ classes, skill, className }) => (
    <div className={clsx('u-txt--truncate', classes.skillWrapper, className)}>{skill}</div>
);

export default withStyles(stylesJS)(QuestionSkillLabel);

const stylesUI = theme => ({
    switchRoot: {
        marginBottom: 20,
        marginLeft: -11
    },
    switchLabel: {
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.2px',
        color: theme.palette.grey[900],
        paddingBottom: 4
    }
});

export default stylesUI;

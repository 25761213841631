export default theme => ({
    infoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 30px',
        alignItems: 'center'
    },
    countLabel: {
        fontSize: 14,
        color: theme.palette.grey[500]
    }
});

import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import CandidateTabEvents from 'events/CandidateTabEvents';
import Typography from '@mui/material/Typography';
import TableFilter from 'components/table_components/table_filter';
import TableFilterItem from 'components/table_components/table_filter/table_filter_item';
import { hiredCandidateCtx } from '../../HiredCandidates/hiredCandidateStore';

const useStyles = makeStyles(theme => ({
    filterHeader: {
        color: theme.palette.grey[800],
        padding: '9px 15px',
        '&:not(:first-child)': {
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            marginTop: 10
        }
    }
}));

const HiredTableFilter = observer(() => {
    const classes = useStyles();

    const {
        auditions, hiringManagers,
        filterAudition, filterHiringManager, handleChangeFilterSelect
    } = useContext(hiredCandidateCtx);

    const handleChange = (uuid, name, filterType) => {
        handleChangeFilterSelect(uuid, filterType);
        CandidateTabEvents.CANDIDATES_FILTERED(name, 'hired page');
    };

    const isRadioChecked = (filterType, uuid) => {
        if (filterType === 'audition') return filterAudition === uuid;
        return filterHiringManager === uuid;
    };

    const menuItem = (uuid, name, count, filterType) => {
        if (!name) return null;
        return (
            <TableFilterItem
                key={name}
                value={name}
                isCheckbox={false}
                label={name}
                showCountNumber
                count={count}
                checked={isRadioChecked(filterType, uuid)}
                handleChange={value => handleChange(uuid, value, filterType)}
            />
        );
    };

    const showAllFilterAuditionsCount = auditions.reduce((sum, current) => sum + current.count, 0);

    return (
        <TableFilter
            isFilterActive={filterAudition || filterHiringManager}
        >
            <Typography variant="h6" className={classes.filterHeader}>Assessments</Typography>
            {menuItem(null, 'Show All', showAllFilterAuditionsCount, 'audition')}
            {
                Object.keys(auditions)
                    .map(key => menuItem(auditions[key].uuid, auditions[key].name, auditions[key].count, 'audition'))}
            {
                Object.keys(hiringManagers).length > 0
                    && <>
                        <Typography variant="h6" className={classes.filterHeader}>Hiring Managers</Typography>
                        {menuItem(null, 'Show All', showAllFilterAuditionsCount, 'hiringManager')}
                        {Object.keys(hiringManagers)
                            .map(key => menuItem(hiringManagers[key].uuid, hiringManagers[key].name, hiringManagers[key].count, 'hiringManager'))}
                    </>
            }
        </TableFilter>
    );
});

export default HiredTableFilter;

import React, { useContext, useEffect, useState } from 'react';

import { withStyles } from '@mui/styles';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';

import infoIconGray from 'img/candidateCard/infoGray.svg';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { CODE_CHALLENGE, CODE_TEST, CODE_TEST_AUTOGRADED } from 'helper/constants';
import styles from '../CodeEditorPerViewAnswer/styles';

const SwitchAutoGraded = observer(({ classes, question: { answerType, id }, onChangeType }) => {
    const { company } = useContext(appCtx);
    const [autoGrade, setAutoGrade] = useState(false);
    const disabled = !checkHasCompanyFeature(company, CODE_CHALLENGE);

    useEffect(() => {
        setAutoGrade(answerType === CODE_TEST_AUTOGRADED);
    }, [answerType, id]);

    const onChange = disabled ? () => {} : ({ target: { checked } }) => {
        const newValue = checked ? CODE_TEST_AUTOGRADED : CODE_TEST;
        setAutoGrade(newValue === CODE_TEST_AUTOGRADED);
        onChangeType({ answerType: newValue });
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginTop: 18
            }}
        >
            <SwitchBrandWithLabel
                label="Automatically grade candidate code with test cases"
                checked={autoGrade}
                className={classes.switch}
                onChange={onChange}
                disabled={disabled}
            />
            <div
                className={classes.infoIconPopUp}
            >
                <img src={infoIconGray} alt="error" />
                <div className={classes.infoPopUp}>
                    <p>
                        Automatic grading allows you to add test cases and assign point values to them.
                    </p>
                    <div>
                        <h2>
                            3/5 test cases passed
                        </h2>
                        <span>Compiled succesfully</span>
                        <div className={classes.caseActive}>
                            <div className={classes.caseText}>
                                <svg
                                    style={{ marginRight: 7 }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="24"
                                    viewBox="0 0 26 24"
                                >
                                    <path fill="#fff" fillRule="evenodd" d="M10.146 9V6c0-3.322 2.81-6 6.256-6 3.448 0 6.257 2.678 6.257 6v3h.896a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25H9.25A2.25 2.25 0 0 1 7 21.75v-10.5A2.25 2.25 0 0 1 9.25 9h.896zm1.5 0h9.513V6c0-2.477-2.122-4.5-4.757-4.5-2.634 0-4.756 2.023-4.756 4.5v3zM9.25 10.5a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h14.305a.75.75 0 0 0 .75-.75v-10.5a.75.75 0 0 0-.75-.75H9.25zm7.152 9c-1.71 0-3.11-1.335-3.11-3s1.4-3 3.11-3 3.11 1.335 3.11 3-1.4 3-3.11 3zm0-1.5c.897 0 1.61-.68 1.61-1.5S17.3 15 16.402 15c-.896 0-1.61.68-1.61 1.5s.714 1.5 1.61 1.5z" />
                                </svg>
                                ALPHABETICAL
                            </div>
                            <div className={classes.casePoint}>
                                <svg
                                    style={{ marginRight: 14 }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <defs>
                                        <path id="a" d="M9.662 15.516l7.605-8.196a1 1 0 1 1 1.466 1.36l-8.35 9a1 1 0 0 1-1.477-.012l-2.65-2.954a1 1 0 1 1 1.488-1.336l1.918 2.138z" />
                                    </defs>
                                    <use fill="#2fd1b8" fillRule="evenodd" xlinkHref="#a" />
                                </svg>
                                + 10/10 PTS
                            </div>
                        </div>
                        <div className={classes.caseSimple}>
                            <div className={classes.caseText}>
                                <svg
                                    style={{ marginRight: 14 }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="24"
                                    viewBox="0 0 26 24"
                                >
                                    <path fill="#fff" fillRule="evenodd" d="M10.146 9V6c0-3.322 2.81-6 6.256-6 3.448 0 6.257 2.678 6.257 6v3h.896a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25H9.25A2.25 2.25 0 0 1 7 21.75v-10.5A2.25 2.25 0 0 1 9.25 9h.896zm1.5 0h9.513V6c0-2.477-2.122-4.5-4.757-4.5-2.634 0-4.756 2.023-4.756 4.5v3zM9.25 10.5a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h14.305a.75.75 0 0 0 .75-.75v-10.5a.75.75 0 0 0-.75-.75H9.25zm7.152 9c-1.71 0-3.11-1.335-3.11-3s1.4-3 3.11-3 3.11 1.335 3.11 3-1.4 3-3.11 3zm0-1.5c.897 0 1.61-.68 1.61-1.5S17.3 15 16.402 15c-.896 0-1.61.68-1.61 1.5s.714 1.5 1.61 1.5z" />
                                </svg>
                                NUMERICAL
                            </div>
                            <div className={classes.casePointError}>
                                <svg
                                    style={{ marginRight: 14 }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path fill="#ff5e5e" fillRule="evenodd" d="M10.94 12L3.97 5.03a.75.75 0 0 1 1.06-1.06L12 10.94l6.97-6.97a.75.75 0 0 1 1.06 1.06L13.06 12l6.97 6.97a.75.75 0 0 1-1.06 1.06L12 13.06l-6.97 6.97a.75.75 0 0 1-1.06-1.06L10.94 12z" />
                                </svg>
                                + 0 PTS
                            </div>
                        </div>
                        <div className={classes.caseScore}>
                            {'TOTAL SCORE:   30 / 70 PTS'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});


export default withStyles(styles)(SwitchAutoGraded);

import React from 'react';


const MarketingSimulationIcon = ({ color = '#A9AAAE', size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M3.71094 11.7109H1.33594V15.5703H3.71094V11.7109Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.2109 11.4141H10.8359V15.5703H13.2109V11.4141Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.46094 6.66406H6.08594V15.5703H8.46094V6.66406Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.9609 6.66406H15.5859V15.5703H17.9609V6.66406Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.52344 7.85156C3.17928 7.85156 3.71094 7.3199 3.71094 6.66406C3.71094 6.00822 3.17928 5.47656 2.52344 5.47656C1.8676 5.47656 1.33594 6.00822 1.33594 6.66406C1.33594 7.3199 1.8676 7.85156 2.52344 7.85156Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.0234 7.85156C12.6793 7.85156 13.2109 7.3199 13.2109 6.66406C13.2109 6.00822 12.6793 5.47656 12.0234 5.47656C11.3676 5.47656 10.8359 6.00822 10.8359 6.66406C10.8359 7.3199 11.3676 7.85156 12.0234 7.85156Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.27344 3.10156C7.92928 3.10156 8.46094 2.5699 8.46094 1.91406C8.46094 1.25822 7.92928 0.726562 7.27344 0.726562C6.6176 0.726562 6.08594 1.25822 6.08594 1.91406C6.08594 2.5699 6.6176 3.10156 7.27344 3.10156Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.7734 3.10156C17.4293 3.10156 17.9609 2.5699 17.9609 1.91406C17.9609 1.25822 17.4293 0.726562 16.7734 0.726562C16.1176 0.726562 15.5859 1.25822 15.5859 1.91406C15.5859 2.5699 16.1176 3.10156 16.7734 3.10156Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.36304 5.82454L6.43391 2.75366" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.1839 5.82454L8.11304 2.75366" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.863 5.82454L15.9339 2.75366" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default MarketingSimulationIcon;

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import styles from '../footerStyles';

const FooterTTBuilder = ({
    classes, submitDescription, loading, step, setStep, disabled, lastStep, onClose
}) => {
    const handleStep = (goTo) => {
        setStep(goTo);
    };

    const nextButtonText = () => {
        switch (step) {
            case 1:
                return 'Next: Role Name';
            case 2:
                return 'Next: Skills';
            case 3:
                return 'Next: Update Role Name';
            case 4:
                return 'Next: Finalise Assessment';
            default:
                return 'Finish';
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.actionWrapper}>
                <Button
                    color="primary"
                    variant="text"
                    className={classes.nextButton}
                    onClick={() => (step === 1 ? onClose() : handleStep(step - 1))}
                    disabled={loading}
                >
                    {step === 1 ? 'Cancel' : 'Back'}
                    {
                        loading && (
                            <CircularProgress
                                className={classes.loader}
                                size={25}
                                thickness={3}
                            />
                        )
                    }
                </Button>
                <div
                    data-tip
                    data-for="complete_all_steps"
                    role="presentation"
                    className={classes.nextButtonWrapper}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.nextButton}
                        onClick={() => (lastStep ? submitDescription() : handleStep(step + 1))}
                        disabled={disabled || loading}
                    >
                        {nextButtonText()}
                        {
                            loading && (
                                <CircularProgress
                                    className={classes.loader}
                                    size={25}
                                    thickness={3}
                                />
                            )
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(FooterTTBuilder);

export default theme => ({
    filterBar: {
        backgroundColor: theme.palette.background.paper,
        padding: '0 25px',
        width: 300,
        minWidth: 300,
        minHeight: 'calc(100vh - 160px)',
        '@media only screen and (min-width: 1600px)': {
            width: 400,
            minWidth: 400
        }
    },
    filterHeaderWrapper: {
        height: 110,
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        color: theme.palette.grey[600],
        fontFamily: 'Open Sans',
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '26px'
    },
    clearButton: {
        background: 'none',
        border: 'none',
        outline: 'none',
        color: theme.palette.blue[500],
        fontWeight: 600,
        fontSize: 10,
        '&:disabled': {
            color: theme.palette.grey[500]
        }
    },
    divider: {
        width: '100%',
        height: 1,
        background: theme.palette.grey[400],
        marginBottom: 12
    }
});

export default {
    textFieldWrapper: {
        position: 'relative'
    },
    buttonRoot: {
        backgroundColor: 'rgb(245, 245, 245)',
        position: 'absolute',
        right: 0,
        top: 35,
        zIndex: 1,
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 1.17,
        letterSpacing: 0.2,
        color: '#676a78',
        '&:hover': {
            backgroundColor: 'rgb(245, 245, 245)'
        }
    },
    dialogButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        padding: '8px 25px'
    },
    pageUpLink: {
        color: 'rgba(61, 63, 71, 0.9)',
        textDecoration: 'underline'
    }
};

export default theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative',
        padding: '14px 20px 14px 14px',
        borderRadius: 8,
        backgroundColor: '#ffffff',
        fontSize: '11px',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.45',
        letterSpacing: 'normal',
        color: theme.palette.grey[900],
        fontWeight: 600,
        cursor: 'pointer',
        border: `2px solid ${theme.palette.grey[300]}`,
        marginBottom: 16,
        '&:hover': {
            backgroundColor: theme.palette.grey[50]
        },
        '&:hover $gradient': {
            background: 'linear-gradient(90deg, rgba(251, 251, 253, 0) 0%, rgba(251, 251, 253, 1) 50%)'
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 270,
            '&:not(:last-child)':
                {
                    marginRight: 15
                }
        },
    },
    wrapperActive: {
        borderColor: theme.palette.primary.main
    },
    questionLabel: {
        marginRight: 13,
        height: 32,
        padding: '4px 9px',
        borderRadius: 4,
        fontSize: 14,
        lineHeight: 1,
        display: 'flex',
        color: theme.palette.grey[900],
        fontWeight: 700,
        alignItems: 'center',
        backgroundColor: theme.palette.grey[300]
    },
    questionLabelActive: {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        color: theme.palette.grey[900],
        fontSize: 14,
        fontWeight: 700
    },
    svgIconStyle: {
        maxWidth: 20,
        marginLeft: 4,
        width: 24,
        height: 24,
        marginBottom: 0
    },
    tooltipClass: {
        padding: '5px 15px 7px'
    },
    lowerContainer: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center'
    },
    suggestedAnswersLabel: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[300],
        borderRadius: 10,
        marginRight: 19,
        padding: '4px 4px 4px 7px'
    },
    skillGroup: {
        color: theme.palette.grey[500],
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 12
    },
    questionStatusChip: {
        minWidth: 67
    }
});

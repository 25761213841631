import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { DIRECT_MESSAGING } from 'helper/constants';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import ProfileMessageModal from './MessageModal';
import { appCtx } from '../../appStore';

const CandidateMessaging = observer(({
    handleClickMessageModalClose, sendCallback,
    onSuccess, targetCandidates, open, sentMessage
}) => {
    const [dialogMessageEnabled, setDialogMessageEnabled] = useState(false);
    const { audition } = useContext(assessmentSettingsCtx);

    useEffect(() => {
        processMessageToCandidate();
    }, []);

    const { flashMessage, loggedUser, company } = useContext(appCtx);

    const processMessageToCandidate = () => {
        if (checkHasCompanyFeature(company, DIRECT_MESSAGING)) {
            setDialogMessageEnabled(true);
        } else {
            flashMessage('Unable to message candidates on current plan');
        }
    };

    return (
        <ProfileMessageModal
            company={company}
            open={open && dialogMessageEnabled}
            targetCandidates={targetCandidates}
            loggedUser={loggedUser}
            audition={audition}
            sendCallback={sendCallback ? data => sendCallback({
                ...data,
                companyUUID: company.uuid,
                companyName: company.name,
                companySlug: company.slug,
                company_planType: company.plan.name
            }) : null}
            sentMessage={sentMessage}
            showFlashMessage={flashMessage}
            handleClickMessageModalClose={handleClickMessageModalClose}
            onSuccess={onSuccess}
        />
    );
});

export default CandidateMessaging;

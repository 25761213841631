import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@mui/styles';
import Select from 'libraries/SelectValidated';
import Checkbox from 'libraries/Checkbox';
import InfoIcon from 'img/icons/icon-info.svg';
import styles from './styles';

const options = [
    { value: 'select', label: 'Select role', disabled: true },
    { value: 'viewer', label: 'Viewer' },
    { value: 'admin', label: 'Editor' }
];

const PermissionAssessments = (props) => {
    const { classes, assessmentList, setAssessmentList, show = true, onChangeRole, onRemoveTeam, topOffset = true, closedCount } = props;

    const handleChangeAssessmentProp = useCallback((e) => {
        const { name, value, checked } = e.target;
        setAssessmentList(prev => prev.map((assessment) => {
            if (name === assessment.slug && checked !== undefined) {
                assessment.checked = checked;
                if (onRemoveTeam && !checked) {
                    onRemoveTeam({ ...assessment });
                }
                assessment.role = null;
                return assessment;
            }
            if (name === assessment.slug) {
                assessment.role = value;
                if (onChangeRole) {
                    onChangeRole({ ...assessment });
                }
                return assessment;
            }
            return assessment;
        }));
    }, [setAssessmentList]);

    const handleCheckAll = useCallback((e) => {
        const { checked } = e.target;
        setAssessmentList(prev => prev.map((assessment) => {
            assessment.checked = checked;
            return assessment;
        }));
        if (onRemoveTeam && !checked) {
            onRemoveTeam();
        }
    }, [setAssessmentList]);

    const handleSelectAll = useCallback((e) => {
        setAssessmentList(prev => prev.map((assessment) => {
            assessment.role = e.target.value;
            return assessment;
        }));
        if (onChangeRole) {
            onChangeRole();
        }
    }, [setAssessmentList]);

    const allAssessmentValue = useMemo(() => {
        if (assessmentList.every(el => el.role === 'viewer')) return 'viewer';
        if (assessmentList.every(el => el.role === 'admin')) return 'admin';
        return 'select';
    }, [assessmentList]);

    if (!show) return null;

    return (
        <>
            {
                assessmentList.length > 0 && (
                    <div className={clsx(classes.checkboxRow, 'u-dsp--f', 'u-ai--center', topOffset && 'u-mrg--tx4')}>
                        <Checkbox
                            label="ALL OPEN ASSESSMENTS"
                            name="all"
                            checked={assessmentList.every(el => el.checked)}
                            styles={{ label: classes.checkboxLabel, root: classes.checkboxRoot }}
                            onChange={handleCheckAll}
                        />
                        <Select
                            name="all select"
                            variant="outlined"
                            className={classes.select}
                            menuPaperClassName={classes.selectMenuPaper}
                            options={options}
                            onChange={handleSelectAll}
                            value={allAssessmentValue}
                            disabled={assessmentList.some(el => !el.checked)}
                        />
                    </div>
                )
            }
            {
                assessmentList.map((item, index) => (
                    <div className={clsx(classes.checkboxRow, 'u-dsp--f', 'u-ai--center', 'u-jc--sb')} key={index}>
                        <Checkbox
                            label={item.name}
                            name={item.slug}
                            checked={item.checked}
                            styles={{ label: classes.checkboxLabel, root: classes.checkboxRoot }}
                            onChange={handleChangeAssessmentProp}
                        />
                        <Select
                            name={item.slug}
                            variant="outlined"
                            className={classes.select}
                            menuPaperClassName={classes.selectMenuPaper}
                            options={options}
                            onChange={handleChangeAssessmentProp}
                            value={item.role || 'select'}
                            disabled={!item.checked}
                        />
                    </div>
                ))
            }
            {('closedCount' in props) && (
                <div className={clsx('u-mrg--tx3', classes.closedText)}>
                    <img src={InfoIcon} alt="" className="u-mrg--rx1" />
                    This Team Member has access to <span>{closedCount} closed assessments</span>
                </div>
            )}
        </>
    );
};

export default withStyles(styles)(PermissionAssessments);

import React from 'react';

const PaperPlaneIcon = ({ color, hovered }) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z" style={{ opacity: hovered ? 1 : 0 }} fill="#EBECEE" />
        <path d="M21.2747 7.12824L6.5433 13.61L12.4359 15.9671L14.7929 21.8596L21.2747 7.12824Z" stroke={color} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.2749 7.12825L12.4361 15.9671" stroke={color} strokeWidth="1.1" />
        <path d="M10.668 17.7349L7.13243 21.2704" stroke={color} strokeWidth="1.1" strokeLinecap="round" />
        <path d="M11.2573 19.5026L9.48956 21.2704" stroke={color} strokeWidth="1.1" strokeLinecap="round" />
        <path d="M8.90039 17.1455L8.31114 17.7348" stroke={color} strokeWidth="1.1" strokeLinecap="round" />
    </svg>
);

export default PaperPlaneIcon;

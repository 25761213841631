import React, { useEffect } from 'react';
import * as qs from 'query-string';
import { observer } from 'mobx-react-lite';
import { sendIcimsData } from 'requests/SettingsRequests';

const Icims = observer(() => {
    useEffect(() => {
        const parsed = qs.parse(window.location.search);
        sendIcimsData({
            query: parsed
        });
    }, []);

    return (
        <div>
            Success
        </div>
    );
});

export default Icims;

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';

import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { PERMISSIONS } from 'helper/constants';
import { appCtx } from 'components/appStore';
import RoleTooltip from './RoleTooltip';
import TeamMemberRow from './TeamMemberRow';

const TeamMembersTable = observer(({ teamMembers, pendingTeamMembers, loadTeamMembers }) => {
    const { company } = useContext(appCtx);
    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);
    return (
        <TableContainer>
            <Table className="u-mrg--bx0">
                <TableHead>
                    <TableRow>
                        <TableCellHead label="Name" hideSort />
                        <TableCellHead label="Email" hideSort />
                        {
                            hasPermissionsFeature ? (
                                <>
                                    <TableCellHead label="Team Permissions" hideSort />
                                    <TableCellHead
                                        labelComponent={(
                                            <div>
                                                Role
                                                <RoleTooltip />
                                            </div>
                                        )}
                                        label="Role"
                                        hideSort
                                        align="right"
                                    />
                                </>
                            ) : (
                                <>
                                    <TableCellHead
                                        hideSort
                                        align="right"
                                    />
                                    <TableCellHead
                                        hideSort
                                        align="right"
                                    />
                                </>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    { teamMembers.map(item => <TeamMemberRow loadTeamMembers={loadTeamMembers} user={item} key={item.email} />) }
                    { pendingTeamMembers.map(item => <TeamMemberRow loadTeamMembers={loadTeamMembers} user={item} key={item.email} isPending />) }
                </TableBody>
            </Table>
        </TableContainer>
    );
});

export default TeamMembersTable;

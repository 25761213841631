import React from 'react';


const TypingTestIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M1.33335 7.3335L1.33335 24.6668C1.33335 25.7714 2.22878 26.6668 3.33335 26.6668L25.4902 26.6668L28.6667 26.6668C29.7713 26.6668 30.6667 25.7714 30.6667 24.6668L30.6667 7.3335C30.6667 6.22893 29.7713 5.3335 28.6667 5.3335L3.33335 5.33349C2.22878 5.33349 1.33335 6.22893 1.33335 7.3335Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="4" y="9.3335" width="3.33333" height="3.33333" rx="0.4" fill={color} />
        <rect x="9.20001" y="9.3335" width="3.33333" height="3.33333" rx="0.4" fill={color} />
        <rect x="14.4" y="9.3335" width="3.33333" height="3.33333" rx="0.4" fill={color} />
        <rect x="19.6" y="9.3335" width="3.33334" height="3.33333" rx="0.4" fill={color} />
        <rect x="24.8" y="9.3335" width="3.33333" height="3.33333" rx="0.4" fill={color} />
        <rect x="4" y="14.5332" width="3.33333" height="3.33333" rx="0.4" fill={color} />
        <rect x="9.20001" y="14.5332" width="3.33333" height="3.33333" rx="0.4" fill={color} />
        <rect x="14.4" y="14.5332" width="3.33333" height="3.33333" rx="0.4" fill={color} />
        <rect x="19.6" y="14.5332" width="3.33334" height="3.33333" rx="0.4" fill={color} />
        <rect x="24.8" y="14.5332" width="3.33333" height="3.33333" rx="0.4" fill={color} />
        <rect x="7.39999" y="20" width="17.3333" height="2.66667" rx="0.4" fill={color} />
    </svg>


);

export default TypingTestIcon;

import React from 'react';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import AlertIcon from 'img/alert.svg';
import RedAlertIcon from 'img/alertRed.svg';
import CloseIcon from './icon.svg';

import useStyles from './styles';


const WarningBanner = ({ className, label, onClose, red }) => {
    if (!label) return null;

    const classes = useStyles({ red });

    return (
        <div className={clsx(classes.wrapper, className)}>
            <img src={red ? RedAlertIcon : AlertIcon} alt="!" />
            <div className={clsx(classes.warningText, 'u-dsp--f u-ai--center')}>
                {label}
            </div>
            {
                onClose && (
                    <IconButton onClick={onClose} className={classes.closeIcon} size="large">
                        <img src={CloseIcon} alt="close" />
                    </IconButton>
                )
            }
        </div>
    );
};

export default WarningBanner;

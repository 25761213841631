import React from 'react';
import clsx from 'clsx';

// material components
import { withStyles, withTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import DuplicateIcon from 'img/duplicate.svg';
import Tooltip from 'libraries/Tooltip';
import CloseIcon from 'components/icons/CloseIcon';
import ReorderIcon from 'img/reorder.svg';

import styles from './styles';

const TicketMenu = ({
    classes, onReorder, hideReorder,
    theme, onDuplicate, onRemove, className
}) => (
    <div className={clsx(classes.wrapper, className)}>
        <Tooltip label="Remove">
            <IconButton
                onClick={onRemove}
                className={classes.icon}
                size="large"
            >
                <CloseIcon size={23} color={theme.palette.grey[400]} />
            </IconButton>
        </Tooltip>
        {
            !hideReorder && (
                <Tooltip label="Reorder">
                    <IconButton
                        onClick={onReorder}
                        className={classes.icon}
                        size="large"
                    >
                        <img src={ReorderIcon} alt="reorder" />
                    </IconButton>
                </Tooltip>
            )
        }
        <Tooltip label="Duplicate">
            <IconButton className={classes.icon} onClick={onDuplicate} size="large">
                <img src={DuplicateIcon} alt="duplicate" />
            </IconButton>
        </Tooltip>
    </div>
);

export default withTheme(withStyles(styles)(TicketMenu));

import React, { useContext, useEffect, useState } from 'react';

import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import { getFilterList } from 'requests/ScriptRequests';
import styles from './styles';
import FilterSelect from './FilterSelect';
import { objectLength } from '../../../helper/commonFunctions';
import { FilterCtx } from '../filter-store';

const sortOptions = [
    {
        label: 'Alphabetically',
        value: 'title'
    },
    {
        label: 'Newest',
        value: 'newest'
    },
    {
        label: 'Popular',
        value: 'popular'
    }
];

const FilterBar = observer(({ classes }) => {
    const [categoriesFilter, setCategoriesFilter] = useState([]);
    const [skillsFilter, setSkillsFilter] = useState([]);
    const [levelFilter, setLevelFilter] = useState([]);

    const { filterData, filterHistory, handleFilter, clearFilter } = useContext(FilterCtx);

    useEffect(() => {
        getFilterList().then((data) => {
            setCategoriesFilter(data.categories);
            setSkillsFilter(data.popularSkills);
            setLevelFilter(data.level);
        });
    }, []);

    const hasFilters = Boolean(objectLength(filterHistory)) || Boolean(filterData.tag);

    return (
        <div className={classes.filterBar}>
            <div className={classes.filterHeaderWrapper}>
                {
                    !hasFilters && (
                        <Typography variant="h7" className={classes.title}>
                            All Assessments
                        </Typography>
                    )
                }
            </div>
            {
                categoriesFilter && (
                    <FilterSelect
                        options={categoriesFilter.map(item => ({
                            label: item.title,
                            value: item.title,
                            count: item.publicAuditionsCount
                        }))}
                        filterData={filterData.categories || []}
                        title="Category"
                        handleChange={val => handleFilter('categories', val)}
                    />
                )
            }
            {
                skillsFilter && (
                    <FilterSelect
                        options={skillsFilter.map(item => ({
                            label: item.title,
                            value: item.title,
                            count: item.publicAuditionsCount
                        }))}
                        filterData={filterData.skills || []}
                        title="Popular skills"
                        handleChange={val => handleFilter('skills', val)}
                    />
                )
            }
            {
                levelFilter && (
                    <FilterSelect
                        options={levelFilter.map(item => ({
                            label: item.title,
                            value: item.title,
                            count: item.publicAuditionsCount
                        }))}
                        filterData={filterData.levels || []}
                        title="Level"
                        handleChange={val => handleFilter('levels', val)}
                    />
                )
            }
            <div className="u-dsp--f u-jc--end u-mrg--bx3">
                <button className={classes.clearButton} onClick={clearFilter} disabled={!hasFilters}>CLEAR ALL FILTERS</button>
            </div>
            <div className={classes.divider} />
            <FilterSelect
                showValue
                multiSelect={false}
                withIcon={false}
                options={sortOptions}
                filterData={filterData.sort}
                title="Sort By"
                handleChange={val => handleFilter('sort', val)}
            />
        </div>
    );
});

export default withRouter(withStyles(styles)(FilterBar));

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    menuItem: {
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[200]}`
        },
        padding: '6px 10px'
    },
    filterOption: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textTransform: 'capitalize',
        marginBottom: 0,
        width: '100%',
        color: '#3A3C43',
        fontWeight: 600
    },
    filterOptionLabel: {
        display: 'flex',
        alignItems: 'center'
    },
    checkbox: {
        padding: 0,
        marginRight: 10
    }
}));

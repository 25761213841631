import React from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import List from '@mui/material/List';
import { AI_LIGHT_OPTIMIZED } from 'helper/constants';
import ListItemCustom from './Item';

import styles from './styles';


const Questions = observer(({ classes, selectedQuestionIndex, questionList, setSelectedQuestionIndex }) => {
    const sortedQuestionList = [...questionList].sort((question1, question2) => {
        const { status: status1 } = question1;
        const { status: status2 } = question2;

        if (status1 !== status2) {
            if (status1 === AI_LIGHT_OPTIMIZED) {
                return 1;
            }
            if (status2 === AI_LIGHT_OPTIMIZED) {
                return -1;
            }
        }
        return 0;
    });

    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>Questions</div>
            <List
                component="nav"
                className={classes.questionList}
                aria-label="secondary mailbox folders"
            >
                {
                    sortedQuestionList.map((item, index) => (
                        <ListItemCustom
                            key={index}
                            question={item}
                            selectedQuestionIndex={selectedQuestionIndex}
                            setSelectedQuestionIndex={setSelectedQuestionIndex}
                            questionNumber={item.questionIndex}
                        />
                    ))
                }
            </List>
        </div>
    );
});

export default withStyles(styles)(Questions);

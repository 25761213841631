import React from 'react';
import clsx from 'clsx';
import { withStyles, withTheme } from '@mui/styles';
import ListItemButton from '@mui/material/ListItemButton';

import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import {
    AI_HEALTH_STATUS_LOW, AI_HEALTH_STATUS_MEDIUM, MANUAL_GRADING_REQUIRED,
    AI_HEALTH_STATUS_HIGH, AI_HEALTH_STATUS_OPTIMIZED, MANUAL_GRADED, AI_LIGHT_OPTIMIZED, AI_LIGHT_INITIAL
} from 'helper/constants';
import Tooltip from 'libraries/Tooltip';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import Chip from 'components/job_or_assessment_settings/Chip';

import CompletedIcon from './assets/completed.svg';
import NoResponsesIcon from './assets/no-responses.svg';

import styles from './styles';

const ListItemCustom = withTheme(withStyles(styles)(({
    classes, setSelectedQuestionIndex, theme,
    questionNumber, question, selectedQuestionIndex, ...other
}) => {
    const { answerType, questionGroup, suggestedAnswerAdded, status, questionOrder, manualGradesRequired } = question;
    const selected = selectedQuestionIndex === questionNumber - 1;

    const returnTypeImg = () => {
        if (!answerType) return null;
        return getQuestionTypeInfo(answerType, renderIconAndLabel);
    };

    const renderIconAndLabel = (Icon, label, labelShort = label) => (
        <Tooltip
            label={labelShort}
            tooltipClass={classes.tooltipClass}
        >
            {
                Icon && (
                    <span>
                        <Icon color={selected ? '#ffffff' : '#292A2D'} className={classes.svgIconStyle} />
                    </span>
                )
            }
        </Tooltip>
    );

    const returnQuestionStatusChipObj = () => {
        switch (status) {
            case AI_HEALTH_STATUS_LOW:
                return {
                    text: 'Low',
                    color: theme.palette.red[600],
                    backgroundColor: theme.palette.red[50]
                };
            case AI_HEALTH_STATUS_MEDIUM:
                return {
                    text: 'Medium',
                    color: theme.palette.green[900],
                    backgroundColor: theme.palette.green[50]
                };
            case MANUAL_GRADING_REQUIRED:
                return {
                    text: 'Manual Grades Required',
                    color: theme.palette.yellow[900],
                    backgroundColor: theme.palette.yellow[50]
                };
            case MANUAL_GRADED:
                return {
                    text: 'Manually graded',
                    color: theme.palette.yellow[900],
                    backgroundColor: theme.palette.yellow[50]
                };
            case AI_LIGHT_OPTIMIZED:
                return {
                    text: 'Optimized',
                    color: theme.palette.blue[700],
                    backgroundColor: theme.palette.blue[50]
                };
            case AI_HEALTH_STATUS_HIGH:
            case AI_HEALTH_STATUS_OPTIMIZED:
                return {
                    text: status === AI_HEALTH_STATUS_HIGH ? 'High' : 'Optimized',
                    color: theme.palette.green[900],
                    backgroundColor: theme.palette.green[50]
                };
            default: return null;
        }
    };

    const questionStatusChipObj = returnQuestionStatusChipObj();

    return (
        <ListItemButton
            onClick={() => setSelectedQuestionIndex(questionNumber - 1)}
            className={clsx(classes.wrapper, selected && classes.wrapperActive)}
            disabled={[AI_LIGHT_INITIAL, AI_LIGHT_OPTIMIZED].includes(status)}
            {...other}
        >
            <div className={classes.header}>
                <div className={clsx(classes.questionLabel, selected && classes.questionLabelActive)}>
                    Q{questionOrder} {returnTypeImg()}
                </div>
                <TruncateWithTooltip className={classes.skillGroup} text={questionGroup?.title || '-'} />
            </div>
            <div className={classes.lowerContainer}>
                {!manualGradesRequired && (
                    <div className={classes.suggestedAnswersLabel}>
                        Correct Answer Examples
                        <img src={suggestedAnswerAdded ? CompletedIcon : NoResponsesIcon} className="u-mrg--lx1" alt="!" />
                    </div>
                )}
                {questionStatusChipObj && <Chip {...questionStatusChipObj} className={classes.questionStatusChip} />}
            </div>
        </ListItemButton>
    );
}));

export default ListItemCustom;

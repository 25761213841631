import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { ZiggeoPlayer } from 'react-ziggeo';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    playerWrapper: {
        width: ({ width }) => width + 4,
        height: ({ height }) => height + 4,
        borderRadius: 4,
        border: `2px solid ${theme.palette.grey[300]}`
    },
    playerWrapperAudioSmall: {
        border: 0
    },
    player: {
        maxWidth: '100%',
        display: 'block',
        textAlign: 'center',
        height: '100%',
        maxHeight: '500px !important',
        minHeight: '320px !important',
        minWidth: 200
    },
    playerAudio: {
        height: 400,
        width: '100%'
    },
    playerSmall: {
        minHeight: 'auto !important',
        maxHeight: '260px !important',
        '& .ba-videoplayer-video': {
            maxHeight: 259,
            maxWidth: '100%',
            [theme.breakpoints.down('lg')]: {
                maxHeight: 163
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight: 173
            }
        }
    },
    playerAudioSmall: {
        width: '302px !important',
        [theme.breakpoints.down('md')]: {
            maxHeight: 163,
            width: '208px !important'
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: 173,
            width: '234px !important'
        },
        maxWidth: '100%',
        '& .ba-videoplayer-video': {
            display: 'none'
        }
    }
}));


const Player = ({ ziggeo_token, audio, small }) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        setShow(false);
        setTimeout(() => {
            setShow(true);
        }, 500);
    }, [ziggeo_token]);

    const width = audio ? '100%' : 'auto';
    const height = audio ? '100%' : '100%';

    const { playerWrapper, playerWrapperAudioSmall,
        player, playerAudio, playerAudioSmall,
        playerSmall } = useStyles({ width, height });

    if (!show) return null;

    if (!ziggeo_token) return null;

    return (
        <div className={clsx(audio && playerWrapper, small && audio && playerWrapperAudioSmall)}>
            <ZiggeoPlayer
                id={ziggeo_token}
                apiKey={process.env.REACT_APP_APPLICATION_TOKEN}
                video={ziggeo_token}
                width={width}
                height={height}
                theme="minimalist"
                themecolor="minimalist"
                preventReRenderOnUpdate
                className={clsx(player,
                    audio && playerAudio,
                    small && playerSmall,
                    audio && small && playerAudioSmall)}
            />
        </div>
    );
};

export default Player;

export default theme => ({
    wrapper: {
        borderWidth: 1
    },
    searchField: {
        background: theme.palette.grey[200],
        border: `2px solid ${theme.palette.grey[200]}`,
        borderRadius: 4,
        margin: 0,
        minWidth: 370,
        color: theme.palette.grey[900]
    },
    searchFieldFocused: {
        background: '#fff',
        border: `2px solid ${theme.palette.primary.main}`
    },
    searchLabel: {
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 0
    },
    content: {
        overflow: 'auto',
        padding: '0 30px',
        minHeight: 250
    },
    contentWrapper: {
        padding: 0,
        overflowX: 'hidden',
        marginBottom: 0
    }
});

export default theme => ({
    chartTooltipWrapper: {
        boxShadow: '0 3px 20px 0 rgba(0, 0, 0, 0.14), 0 5px 17px 0 rgba(0, 0, 0, 0.1)',
        borderRadius: 2,
        fontSize: 13,
        color: 'rgba(0, 0, 0, 0.5)',
        whiteSpace: 'nowrap',
        backgroundColor: '#fff',
        padding: 12,
        fontWeight: 600
    },
    chartTooltipValue: {
        color: theme.palette.grey[900]
    },
    chartTooltipPercentage: {
        fontWeight: 700,
        marginLeft: 7,
        color: theme.palette.grey[600]
    },
    chartWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        position: 'relative'
    },
    tootlipRange: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.grey[500]
    }
});

import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    wrapper: {
        marginTop: '10px'
    },
    invitationAnswerDescription: {
        margin: 'auto',
        maxWidth: 390
    }
}));

/*
    Shows the list of approved/unapproved candidates
    after invitation in Dialog
*/
const InvitationAnswerDialog = ({ message, value }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <div className={classes.invitationAnswerDescription}>
                { message }
            </div>
            {
                value.map((item, index) => (
                    <>
                        {Boolean(index) && ', '}
                        <b key={index}>
                            { item.inviteUser.email }
                        </b>
                    </>
                ))
            }
        </div>
    );
};

export default InvitationAnswerDialog;

import React, { useContext, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { appCtx } from 'components/appStore';
import CandidatesList from '../components/CandidatesList';
import { checkHasCompanyFeature } from '../../../helper/commonFunctions';
import SearchFilterBlock from '../components/CandidatesSearchFilterBlock';
import { candidateCtx } from './candidateStore';

const styles = {
    wrapper: {
        padding: '0 30px',
        marginTop: 20
    }
};

const Candidates = observer(({ classes }) => {
    const { company } = useContext(appCtx);

    if (!checkHasCompanyFeature(company, 'ALL_CANDIDATES_PAGE')) return <Redirect to="not-found" />;

    const {
        loadCandidates, cleanState,
        setLoading, isNoCandidates,
        setFilterCategory, fetchAllCandidatesStats
    } = useContext(candidateCtx);

    useEffect(() => {
        setLoading(true);
        setFilterCategory();
        loadCandidates();
        fetchAllCandidatesStats();
        return () => {
            cleanState();
        };
    }, []);

    return (
        <div className={classes.wrapper}>
            { !isNoCandidates && <SearchFilterBlock /> }
            <CandidatesList />
        </div>
    );
});

export default withStyles(styles)(Candidates);

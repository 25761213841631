import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import TextInputOutlined from 'libraries/TextInput';
import CircularProgress from '@mui/material/CircularProgress';
import { appCtx } from '../../../appStore';

const CreateFolderDialog = ({ open, onClose, addFolder, privateAssessments }) => {
    const [newName, setNewName] = useState('');
    const [loading, setLoading] = useState(false);
    const { flashMessage } = useContext(appCtx);

    const handleChange = (e) => {
        setNewName(e.target.value);
    };

    const createFolder = () => {
        setLoading(true);
        addFolder(newName)
            .then(() => {
                onClose();
                setNewName('');
                flashMessage(`${newName} Folder created`);
            })
            .catch(() => {})
            .finally(() => setLoading(false));
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            handleClose={onClose}
            titleComponent={`Create new ${privateAssessments ? 'assessment folder' : 'category'}`}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={() => { onClose(); setNewName(''); }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        variant="contained"
                        disabled={newName.length < 1 || loading}
                        onClick={createFolder}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={22}
                                    thickness={3}
                                />
                            ) : 'Create'
                        }
                    </Button>
                </>
            )}
        >
            <TextInputOutlined
                label={`${privateAssessments ? 'Folder' : 'Category'} name`}
                variant="outlined"
                maxLength={150}
                fullWidth
                value={newName}
                onChange={handleChange}
            />
        </Dialog>
    );
};

export default CreateFolderDialog;

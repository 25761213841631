import { tracking } from 'helper/eventSegment';

const GENERAL_WORK_SKILLS = [
    'attention to detail',
    'initiative',
    'following directions',
    'open to feedback',
    'adaptable',
    'quick learner',
    'being on time',
    'empathy',
    'effort',
    'energetic',
    'passionate',
    'coachable',
    'doing extra',
    'deliver work on time',
    'prepared',
    'attitude',
    'showing up'
];

export default {
    DESCRIPTION_SAVED: (data) => {
        tracking('AB-description-saved', data);
    },
    BLANK_TT_CREATED: (data) => {
        tracking('AB-blank-tt-created', data);
    },
    SKILL_ADDED: (data) => {
        tracking('AB-skill-added', {
            ...data,
            generalWorkSkill: GENERAL_WORK_SKILLS.includes(data.skill.toLowerCase())
        });
    },
    QUESTION_SWAP_CONSIDERED: (data) => {
        tracking('AB-question-swap-considered', data);
    },
    QUESTION_SWAPPED: (data) => {
        tracking('AB-question-swapped', data);
    },
    AI_TT_EDITED: (data) => {
        tracking('AB-ai-tt-edited', data);
    },
    AI_TT_EDITS_SAVED: (data) => {
        tracking('AB-ai-tt-edits-saved', data);
    },
    QUESTION_ADDED: (data) => {
        tracking('AB-question-added', data);
    },
    QUESTION_DELETED: (data) => {
        tracking('AB-question-deleted', data);
    },
    SWAP_REASON_SAVED: (data) => {
        tracking('AB-swap-reason-saved', data);
    },
    AI_TT_ACCEPTED: (data) => {
        tracking('AB-ai-tt-accepted', data);
    },
    AI_TT_COMPLETE: (data) => {
        tracking('AB-ai-tt-complete', data);
    },
    PREVIEW_VIEW: () => {
        tracking('AB-preview-view');
    },
    PREVIEW_VIEW_FAILED: () => {
        tracking('AB-preview-view-failed');
    },
    INSPIRATION_JD_SELECTED: (data) => {
        tracking('AB-inspiration-jd-selected', data);
    },
    DESCRIPTION_EDITED: () => {
        tracking('AB-description-edited');
    },
    CLEAR_ALL_CLICKED: () => {
        tracking('AB-clear-all-clicked');
    },
    ROLE_EDIT: () => {
        tracking('AB-role-edit');
    },
    ROLE_SAVE: (data) => {
        tracking('AB-role-save', data);
    },
    SKILLS_SEEMORE_CLICKED: () => {
        tracking('AB-skills-seemore-clicked');
    },
    BACK_CLICKED: () => {
        tracking('AB-back-clicked');
    },
    INPUT_SCREEN_VIEW: () => {
        tracking('AB-input-screen-view');
    },
    CREATE_SCRATCH_CLICK: () => {
        tracking('AB-create-scratch-click');
    },
    CHOOSE_EXISTING_CLICK: () => {
        tracking('AB-choose-existing-click');
    },
    QE_ASSESSMENT_NAME_EDIT: () => {
        tracking('AB-QE-assessmentName-edit');
    },
    QE_ASSESSMENT_NAME_SAVE: (data) => {
        tracking('AB-QE-assessmentName-save', data);
    },
    QE_QUESTION_DELETED: (data) => {
        tracking('AB-QE-question-deleted', data);
    },
    QE_QUESTION_ADDED: (data) => {
        tracking('AB-QE-question-added', data);
    },
    QE_QUESTION_REORDERED: (data) => {
        tracking('AB-QE-question-reordered', data);
    },
    QE_SKILL_ADDED: (data) => {
        tracking('AB-QE-skill-added', data);
    },
    QE_SKILL_DELETED: (data) => {
        tracking('AB-QE-skill-deleted', data);
    },
    QE_QUESTION_ADDED_FAILED: (data) => {
        tracking('AB-QE-question-added-failed', data);
    },
    QE_SKILL_ADDED_FAILED: (data) => {
        tracking('AB-QE-skill-added-failed', data);
    },
    QE_SKILL_SWAPPED_FAILED: (data) => {
        tracking('AB-QE-skill-swapped-failed', data);
    },
    DESCRIPTION_SAVED_FAILED: (data) => {
        tracking('AB-description-save-failed', data);
    },
    CATEGORY_ADDED: (data) => {
        tracking('AB-category-added', data);
    },
    ROLE_ADDED: (data) => {
        tracking('AB-role-added', data);
    }
};

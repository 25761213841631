import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getCandidateInfoOLD } from 'requests/APIrequests';
import {
    getAuditionQuestionResults, getCandidateAudition,
    getCandidatePosts, getEmployerGradeQuestion,
    gradeEmployerAnswer, addCandidateComment,
    getAuditionScreeningQuestionResults
} from 'requests/JobOpportunityRequests';
import { getCompanyTags } from 'requests/SettingsRequests';
import CandidateCardWrapper from 'components/candidate_cards/CandidateCardWrapper';
import { observer } from 'mobx-react-lite';
import * as qs from 'query-string';
import AuditionListSelect from '../CandidateCardWrapper/AuditionListSelect';

const EmployerCardContent = observer(({
    match, opportunitiesList, onLoadCandidate,
    company, userId: userIdProp, context, location,
    opportunitySlug: opportunitySlugProps,
    onHireChanges, removeCandidateTab
}) => {
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [selectedOpportunityIndex, setSelectedOpportunityIndex] = useState(0);
    const [selectedAuditionIndex, setSelectedAuditionIndex] = useState(0);
    const [isLoadingAudition, setLoadingAudition] = useState(false);
    const [companyTags, setCompanyTags] = useState([]);

    const { user_id } = match.params;
    const userId = user_id || userIdProp;

    const parsed = qs.parse(location.search);
    const { assessment: assessmentSlug, opportunity: opportunityParsed } = parsed;
    const opportunitySlug = opportunitySlugProps || opportunityParsed;

    useEffect(() => {
        setLoading(true);
        getUserInfo();
        getTags();
    }, [userId]);

    useEffect(() => {
        if (!opportunitiesList.length) {
            setSelectedOpportunityIndex(0);
            setSelectedAuditionIndex(0);
            return;
        }
        setInitialIndexes();
    }, [opportunitiesList]);

    const setInitialIndexes = () => {
        let opportunityIndex = 0;
        let columnIndex = 0;
        if (opportunitySlug) {
            const oppIndex = opportunitiesList.findIndex(({ opportunity: { slug } }) => slug === opportunitySlug);
            if (oppIndex !== -1) opportunityIndex = oppIndex;
            if (assessmentSlug && opportunityIndex !== -1) {
                const index = opportunitiesList[opportunityIndex].columns.findIndex(({ audition }) => audition.slug === assessmentSlug);
                if (index !== -1) columnIndex = index;
            }
        } else if (assessmentSlug) {
            for (let i = 0; i < opportunitiesList.length; i++) {
                const index = opportunitiesList[i].columns.findIndex(({ audition }) => audition.slug === assessmentSlug);
                if (index !== -1) {
                    columnIndex = index;
                    opportunityIndex = i;
                    break;
                }
            }
        }
        setSelectedIndexes(opportunityIndex, columnIndex);
    };

    const setSelectedIndexes = (opportunityIndex = selectedOpportunityIndex, assessmentIndex = selectedAuditionIndex) => {
        if (!opportunitiesList) return;
        setSelectedOpportunityIndex(opportunityIndex);
        setSelectedAuditionIndex(assessmentIndex);
        if (opportunitiesList[opportunityIndex].columns[assessmentIndex]) {
            setLoadingAudition(true);
            getCandidateAudition(opportunitiesList[opportunityIndex].columns[assessmentIndex].userAudition.uuid)
                .then(({ success, data }) => {
                    if (success && data) {
                        setUser({ ...user, ...data });
                    }
                })
                .finally(() => {
                    setLoadingAudition(false);
                });
        }
    };

    const getTags = () => {
        getCompanyTags()
            .then(({ success, data }) => {
                if (success && data) {
                    setCompanyTags(data);
                }
            });
    };

    const getUserInfo = () => {
        getCandidateInfoOLD(userId, false)
            .then((response) => {
                const { success, candidate } = response;
                if (success && candidate) {
                    setUser(candidate);
                    if (onLoadCandidate) {
                        onLoadCandidate(candidate)
                            .finally(() => {
                                setLoading(false);
                            });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                if (removeCandidateTab) removeCandidateTab(userId);
            });
    };

    let selectedAudition = {};
    let returnedOpportunitySlug = opportunitySlug;
    if (opportunitiesList.length && opportunitiesList[selectedOpportunityIndex] && opportunitiesList[selectedOpportunityIndex].columns[selectedAuditionIndex]) {
        selectedAudition = opportunitiesList[selectedOpportunityIndex].columns[selectedAuditionIndex].audition;
        returnedOpportunitySlug = selectedAudition.opportunitySlug;
    }

    return (
        <CandidateCardWrapper
            user={user}
            context={context}
            setUser={setUser}
            company={company}
            isLoading={isLoading}
            getTags={getTags}
            setTags={setCompanyTags}
            getUserInfo={() => setSelectedIndexes()}
            opportunitySlug={returnedOpportunitySlug}
            audition={selectedAudition}
            isLoadingAudition={isLoadingAudition}
            loadCandidatePosts={getCandidatePosts}
            addCandidateComment={addCandidateComment}
            loadGradeQuestion={getEmployerGradeQuestion}
            getAuditionQuestionResults={getAuditionQuestionResults}
            getAuditionScreeningQuestionResults={getAuditionScreeningQuestionResults}
            gradeFunction={gradeEmployerAnswer}
            companyTags={companyTags}
            onHireChanges={onHireChanges}
            ReportHeaderComp={(
                <AuditionListSelect
                    opportunitiesList={opportunitiesList}
                    selectedOpportunityIndex={selectedOpportunityIndex}
                    selectedAuditionIndex={selectedAuditionIndex}
                    setSelectedIndexes={setSelectedIndexes}
                    isLoadingAudition={isLoadingAudition}
                />
            )}
            onDeleteCallback={getUserInfo}
        />
    );
});

export default withRouter(EmployerCardContent);

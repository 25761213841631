import React from 'react';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import clsx from 'clsx';

const styles = {
    table: {
        position: 'relative',
        minWidth: 700,
        width: 'calc(100% - 1px)',
        marginBottom: 25
    }
};

const CustomTable = ({ classes, children, className = '', ...other }) => (
    <Table className={clsx(classes.table, className)} {...other}>
        {children}
    </Table>
);

export default withStyles(styles)(CustomTable);

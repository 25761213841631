import React from 'react';
import QuestionContent from 'components/grade_components/QuestionContent';
import ContentAccordion from 'libraries/ContentAccordion';
import Icon from './icon.svg';
import QuestionHeader from './QuestionHeader';

const QuestionContentAccordion = ({ question }) => (
    <ContentAccordion
        header={(
            <>
                <img src={Icon} className="u-mrg--rx1" alt="" />
                <span className="u-mrg--rx2">Question</span>
                <QuestionHeader question={question} />
            </>
        )}
        content={<QuestionContent question={question} />}
    />
);

export default QuestionContentAccordion;

import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';

const styles = {
    popoverPaper: {
        padding: 18,
        maxWidth: 375,
        fontSize: 14
    }
};

class SimplePopover extends Component {
    state = {
        anchorEl: null
    };

    myRef = React.createRef();

    componentDidMount() {
        const { onMounted } = this.props;
        if (onMounted) {
            onMounted({
                handleClose: this.handleClose,
                handleOpen: this.setAnchor
            });
        }
    }

    handleClick = (e) => {
        const { onClickEvent, disabled } = this.props;
        if (e) e.stopPropagation();
        if (disabled) return;
        if (onClickEvent) {
            onClickEvent();
        }
        this.setAnchor();
    };

    handleClose = (e) => {
        const { onClose } = this.props;
        if (e) e.stopPropagation();
        if (onClose) {
            onClose();
        }
        this.setState({ anchorEl: null });
    };

    setAnchor = () => {
        this.setState({ anchorEl: this.myRef.current });
    };

    render() {
        const { anchorEl } = this.state;
        const {
            children, classes, label,
            className = '', classNameRoot = '',
            anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
            transformOrigin = { vertical: 'top', horizontal: 'center' }
        } = this.props;
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <>
                <span
                    ref={this.myRef}
                    role="presentation"
                    aria-describedby={id}
                    onClick={this.handleClick}
                >
                    {label}
                </span>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    className={classNameRoot}
                    classes={{
                        paper: `${classes.popoverPaper} ${className}`
                    }}
                    onClick={e => e.stopPropagation()}
                    onClose={this.handleClose}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                >
                    {children}
                </Popover>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SimplePopover);

import React from 'react';

const LoaderBounce = ({ style, backgroundColor = 'rgba(#000, .20)' }) => (
    <div className="spinner" style={style}>
        <div className="bounce1" style={{ backgroundColor }} />
        <div className="bounce2" style={{ backgroundColor }} />
        <div className="bounce3" style={{ backgroundColor }} />
    </div>
);

export default LoaderBounce;

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Zendesk from 'helper/zendeskFunctions';
import Icon from './icon.svg';

import styles from '../styles';

const SsoFailureScreen = ({ classes, handleClose }) => {
    const openZendesk = () => {
        Zendesk.open(true);
    };

    return <>
        <img src={Icon} alt="X" className="u-pdn--x4" />
        <h1 className={classes.failHeading}>SSO unavailable for your account</h1>
        <div className="u-mrg--tx2">
            SSO sign in has not been activated for your account.
        </div>
        <div className="u-mrg--tx1">
            <span role="presentation" onClick={openZendesk} className={classes.link}>Contact sales </span>
            to organize set up or&nbsp;
            <span role="presentation" onClick={handleClose} className={classes.link}>go back </span>
            to sign in.
        </div>
        <Button className={classes.goBackButton} onClick={handleClose}>
            Go back to sign in
        </Button>
    </>;
};

export default withStyles(styles)(SsoFailureScreen);

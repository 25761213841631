import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { getCompanyQuestionDuplicates } from 'requests/CMSRequests';
import QuestionsList from '../../../common/QuestionsList';
import EmptyQuestionsList from '../../../common/EmptyQuestionsList';
import EmptyDuplicatesList from './EmptyDuplicatesList';


const PAGE_STEP = 12;

const styles = theme => ({
    header: {
        padding: '0 30px 16px',
        marginBottom: 20,
        height: 54.5,
        display: 'flex',
        alignItems: 'center',
        borderBottom: `2px solid ${theme.palette.grey[300]}`
    },
    counter: {
        fontSize: 15
    },
    questionsWrapper: {
        padding: '0 30px'
    },
    selectedSkillWrapper: {
        padding: '0 30px'
    }
});

const DuplicatesContent = ({
    classes, openDrawer, setSelectedQuestionId, setSaving,
    selectedDuplicatedQuestionId, setSelectedSet, handleOpenDrawer,
    setAddToSelectedSkill, selectedSkill, ...other
}) => {
    const [loading, setLoading] = useState(false);
    const [loadingLocal, setLoadingLocal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [questionsData, setQuestionsData] = useState({});

    useEffect(() => {
        if (!openDrawer) {
            loadQuestionDuplicates();
        }
    }, [openDrawer]);

    useEffect(() => {
        setQuestionsData({});
        if (!selectedDuplicatedQuestionId) return;
        setLoading(true);
        loadQuestionDuplicates();
    }, [selectedDuplicatedQuestionId]);

    useEffect(() => {
        loadQuestionDuplicates();
    }, [currentPage]);

    const loadQuestionDuplicates = (page = currentPage) => {
        if (!selectedDuplicatedQuestionId) return;
        setLoadingLocal(true);
        return getCompanyQuestionDuplicates(selectedDuplicatedQuestionId)
            .then(({ success, data }) => {
                if (success && data) {
                    let newItems = data.items;
                    if (page !== 1) {
                        const slicedQuestions = questionsData.items.slice(0, PAGE_STEP * (page - 1));
                        newItems = [...slicedQuestions, ...data.items];
                    }
                    setQuestionsData({ ...data, items: newItems });
                }
            })
            .finally(() => {
                setLoading(false);
                setLoadingLocal(false);
            });
    };

    const onEmptyClick = () => {
        setAddToSelectedSkill(true);
        handleOpenDrawer();
    };

    const handleNextButtonClick = () => {
        if (!questionsData.hasMorePages) return;
        setQuestionsData({ ...questionsData, hasMorePages: false });
        setCurrentPage(currentPage + 1);
    };

    const questions = (questionsData && questionsData.items) ? questionsData.items : [];

    return (
        <>
            <div className={classes.header}>
                <Typography
                    variant="h4"
                    className="u-mrg--rx2"
                >
                    Duplicates &nbsp;
                    {(!loading && selectedDuplicatedQuestionId) && (
                        <span className="u-txt--14">
                            ({questionsData.total})
                        </span>
                    )}
                </Typography>
            </div>
            <div className={classes.questionsWrapper}>
                <QuestionsList
                    hideAdd
                    hideReorder
                    loading={loadingLocal}
                    loadQuestions={loadQuestionDuplicates}
                    setSelectedQuestionId={setSelectedQuestionId}
                    questions={questions}
                    currentPage={currentPage}
                    hasMorePages={questionsData.hasMorePages}
                    loadMore={handleNextButtonClick}
                    setCurrentPage={setCurrentPage}
                    {...other}
                />
                {
                    !loading && (!questions || !questions.length) && (
                        <>
                            {
                                selectedDuplicatedQuestionId ? (
                                    <EmptyQuestionsList
                                        onClick={onEmptyClick}
                                        handleOpenDrawer={handleOpenDrawer}
                                        setAddToSelectedSkill={setAddToSelectedSkill}
                                    />
                                ) : (
                                    <EmptyDuplicatesList />
                                )
                            }
                        </>
                    )
                }
            </div>
        </>
    );
};

export default withStyles(styles)(DuplicatesContent);

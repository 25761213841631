import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import HiringFlowDialog from 'components/dialogs/HiringFlowDialog';
import FinishDialog from 'components/dialogs/HiringFlowDialog/FinishDialog';

import useStyles from './styles';

const HireBanner = observer(({ showBanner, audition, user, handleShow, updateUserFields }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const handleDialog = () => {
        setOpen(!open);
    };

    const addInfo = () => {
        handleDialog();
        handleShow();
    };

    const handleSuccessDialog = () => {
        setShowSuccessDialog(!showSuccessDialog);
    };

    const later = () => {
        handleSuccessDialog();
        handleShow();
    };

    return (
        <>
            {
                showBanner && (
                    <div className={classes.hireBanner}>
                        <p className={classes.hireBannerHeader}>
                            Would you like to record the hired<br />information for this candidate?
                        </p>
                        <div className="u-dsp--distribute">
                            <Button
                                onClick={later}
                                className={classes.hireBannerButton}
                            >
                                Maybe later
                            </Button>
                            <Button
                                onClick={addInfo}
                                color="primary"
                                className={classes.hireBannerButton}
                            >
                                Add hired information
                            </Button>
                        </div>
                    </div>
                )
            }
            <HiringFlowDialog
                open={open}
                onClose={handleDialog}
                candidates={[user]}
                updateUserFields={updateUserFields}
                assessment={audition}
            />
            <FinishDialog
                open={showSuccessDialog}
                candidatesLength={1}
                handleClose={handleSuccessDialog}
            />
        </>
    );
});

export default HireBanner;

import { tracking } from 'helper/eventSegment';

export default {
    INVITED_CANDIDATES: (data) => {
        tracking('J-candidate-invited', data);
    },
    CANDIDATE_INVITED_CSV: (data) => {
        tracking('J-candidate-invited-CSV', data);
    },
    CANDIDATE_IMPORTED_CSV: (data) => {
        tracking('J-candidate-imported-CSV', data);
    }
};

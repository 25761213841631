export default theme => ({
    wrapper: {
        borderRadius: 4,
        fontSize: 12,
        padding: '11px 16px',
        fontWeight: 600,
        backgroundColor: theme.palette.grey[200],
        marginBottom: 15,
        '& img': {
            marginRight: 10
        }
    },
    skill: {
        color: theme.palette.primary.main
    }
});

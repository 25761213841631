export default theme => ({
    wrapper: {
        minWidth: 200,
        height: '100%',
        paddingBottom: 3,
        marginRight: 30
    },
    button: {
        textDecoration: 'underline',
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.green[600]
    },
    buttonActive: {
        cursor: 'pointer'
    },
    img: {
        marginBottom: -3,
        marginRight: 12
    }
});

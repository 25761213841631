export default theme => ({
    accordion: {
        border: `2px solid ${theme.palette.grey[200]}`,
        marginBottom: 18,

        '& .MuiButtonBase-root': {
            '& p': {
                margin: '0!important',
                fontWeight: 700,
                textTransform: 'capitalize',
                fontSize: 16
            }
        }
    },
    historyRow: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        marginBottom: 9,
        fontWeight: 400,
        fontSize: 13,
        height: 18,
        lineHeight: '18px'
    },
    headerRow: {
        height: 28,
        lineHeight: '28px',
        '& span': {
            backgroundColor: theme.palette.grey[300],
            fontWeight: 700,
            fontSize: 14
        }
    },
    timeColumn: {
        width: '30%'
    },
    statusColumn: {
        width: '68%',
        fontWeight: 600
    }
});

import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { getAssessmentCategories } from 'requests/ScriptRequests';
import AssessmentFolders from 'components/assessments_pages/AssessmentFolders';

import { editCategory, addCategory, removeCategory } from 'requests/CMSRequests';
import MiscellaneousEvents from 'events/MiscellaneousEvents';
import { clone } from 'helper/commonFunctions';

import AssessmentList from './AssessmentList';
import { assessmentListCtx } from './AssessmentList/store';
import NoAssessmentsContent from './NoAssessmentsContent';
import NotFoundAssessmentsContent from './NotFoundAssessmentsContent';
import ContentAssessmentAddDialog from './ContentAssessmentAddDialog';


const styles = ({
    searchLoaderWrapper: {
        height: 160,
        position: 'relative'
    },
    loader: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto'
    },
    folders: {
        marginLeft: -24,
        marginRight: -24
    }
});

const ContentAssessments = observer(({ classes }) => {
    const [createAssessmentOpened, setCreateAssessmentOpened] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);

    const {
        totalCount,
        getPrivateAssessments, setTotalCount,
        setAssessments, setLoadingAssessments,
        setLoadingSearchAssessments, setCurrentPage,
        loadingAssessments, loadingSearchAssessments,
        activeFolderId, query, setActiveFolderId
    } = useContext(assessmentListCtx);

    useEffect(() => {
        setLoadingAssessments(true);
        getAssessments();
        getCategories();
    }, []);


    useEffect(() => {
        getAssessments();
    }, [activeFolderId]);

    useEffect(() => {
        setLoadingSearchAssessments(true);
        setTotalCount(0);
        setCurrentPage(0);
        setAssessments([]);
        getAssessments();
    }, [query]);

    const getAssessments = () => {
        setCurrentPage(0);
        setAssessments([]);
        getPrivateAssessments(query);
    };

    const getCategories = () => {
        getAssessmentCategories()
            .then(({ success, data }) => {
                if (success && data) {
                    setCategoriesList(data);
                }
            });
    };

    const setActiveFolder = (id) => {
        setActiveFolderId(id);
    };

    const handleCreateAssessmentOpened = () => {
        setCreateAssessmentOpened(!createAssessmentOpened);
    };

    const renameCategory = (uuid, title) => editCategory(uuid, { title })
        .then(({ success, data }) => {
            if (success && data) {
                const index = categoriesList.findIndex(folder => uuid === folder.uuid);
                const newCategoriesList = clone(categoriesList);
                newCategoriesList[index] = {
                    ...data,
                    auditionsCount: categoriesList[index].auditionsCount
                };
                setCategoriesList(newCategoriesList);
            }
        });


    const deleteCategoryFunc = id => removeCategory(id)
        .then(() => {
            getCategories();
        });

    const addCategoryFunc = title => addCategory(title)
        .then(({ success, data }) => {
            if (success && data) {
                setCategoriesList([...categoriesList, { auditionsCount: 0, ...data }]);
                MiscellaneousEvents.FOLDER_CREATED({ folderName: title });
            }
            return ({ success, data });
        });

    if (loadingAssessments) {
        return (
            <div className={classes.searchLoaderWrapper}>
                <CircularProgress size={25} className={classes.loader} />
            </div>
        );
    }

    if (!totalCount && !loadingAssessments && !loadingSearchAssessments && !query && !activeFolderId) {
        return (
            <>
                <NoAssessmentsContent
                    handleCreateAssessment={handleCreateAssessmentOpened}
                />
                <ContentAssessmentAddDialog
                    open={createAssessmentOpened}
                    categories={categoriesList}
                    onClose={handleCreateAssessmentOpened}
                />
            </>
        );
    }

    return (
        <>
            {
                Boolean(categoriesList.length) && (
                    <div className={classes.folders}>
                        <AssessmentFolders
                            query={query}
                            renameFolder={renameCategory}
                            deleteFolder={deleteCategoryFunc}
                            getFolders={getCategories}
                            folders={categoriesList}
                            activeFolderId={activeFolderId}
                            setActiveFolder={setActiveFolder}
                            addFolder={addCategoryFunc}
                        />
                    </div>
                )
            }
            {
                (!totalCount && !loadingAssessments && !loadingSearchAssessments) ? (
                    <NotFoundAssessmentsContent
                        query={query}
                        activeFolderId={activeFolderId}
                        handleCreateAssessment={handleCreateAssessmentOpened}
                    />
                ) : (
                    <AssessmentList
                        handleCreateAssessment={handleCreateAssessmentOpened}
                        getCategories={getCategories}
                    />
                )
            }
            <ContentAssessmentAddDialog
                open={createAssessmentOpened}
                categories={categoriesList}
                onClose={handleCreateAssessmentOpened}
            />
        </>
    );
});

export default withStyles(styles)(ContentAssessments);

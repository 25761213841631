import React from 'react';
import { withRouter } from 'react-router-dom';
import BoardNoContent from 'components/board/board_no_content';
import EmptyIcon from './icon.svg';
import CreateNewAssessment from '../../../CreateNewAssessment';

const NoAssessmentsContent = ({ query }) => (
    <BoardNoContent
        header="Can’t find what you’re looking for?"
        icon={<img src={EmptyIcon} alt="empty-content" />}
        text={(
            <>
                Sorry we didn’t find any results for “{query}”.<br />
                Check the spelling, or try a different search.<br /><br />
                <CreateNewAssessment
                    light
                    buttonLabel="Create a skill assessment"
                />
            </>
        )}
    />
);


export default withRouter(NoAssessmentsContent);

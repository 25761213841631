import React from 'react';
import clsx from 'clsx';
import Tooltip from 'libraries/Tooltip';
import { makeStyles } from '@mui/styles';
import ButtonBase from '@mui/material/ButtonBase';

const useStyles = makeStyles(theme => ({
    tooltipClass: {
        userSelect: 'none'
    },
    tooltipAction: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginTop: -2,
        textDecoration: 'underline'
    }
}));

const UpgradeToUnlockTooltip = ({
    explanationText, additionalText, tooltipClassName = '',
    children, showTextToUnlock = true, ...other
}) => {
    const classes = useStyles();
    return (
        <Tooltip
            label={(
                <div className={explanationText && 'u-mrg--tx1 u-mrg--bx1'}>
                    {explanationText && <>{explanationText}<br /><br /></>}
                    <ButtonBase
                        className={classes.tooltipAction}
                        onClick={() => window.open('/subscriptions', '_blank')}
                    >
                        Upgrade
                    </ButtonBase>
                    {showTextToUnlock && <>&nbsp;to unlock</>}
                    {additionalText && ` ${additionalText}`}
                </div>
            )}
            tooltipClass={clsx(classes.tooltipClass, tooltipClassName)}
            {...other}
        >
            {children}
        </Tooltip>
    );
};

export default UpgradeToUnlockTooltip;

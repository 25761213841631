import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Typography from '@mui/material/Typography';
import TagIcon from 'components/icons/TagIcon';

import TagsContainer from 'components/candidate_cards/TagsContainer';
import useStyles from './styles';

const SkillsContent = observer(({ candidate, isLoading }) => {
    const classes = useStyles();

    if (isLoading) {
        return (
            <CircularProgress
                size={15}
                className={classes.loader}
                color="primary"
            />
        );
    }

    if (!candidate) return null;
    const { tags } = candidate;

    if (!tags || !tags.length) {
        return (
            <div className="u-dsp--centered u-fdir--column u-hgt--100p u-hgt-min--120">
                <TagIcon width={30} height={30} className="u-mrg--bx1" />
                <Typography variant="h6">No Tags</Typography>
            </div>
        );
    }

    return (
        <div>
            <TagsContainer
                tags={tags}
            />
        </div>
    );
});

export default withRouter(SkillsContent);

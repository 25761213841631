import React from 'react';
import { withRouter } from 'react-router-dom';
import WelcomeSection from 'components/assessments_pages/asessment_settings_components/WelcomeSection';
import InstructionToCandidatesSection from 'components/assessments_pages/asessment_settings_components/InstructionToCandidatesSection';

const InvitationPreviewPage = ({ setSaving, setAudition, audition }) => (
    <div className="u-wdt--100p">
        <InstructionToCandidatesSection
            slug={audition.slug}
            audition={audition}
            setSaving={setSaving}
            setAudition={setAudition}
        />
        <WelcomeSection
            slug={audition.slug}
            setSaving={setSaving}
        />
    </div>
);

export default withRouter(InvitationPreviewPage);

export default theme => ({
    wrapper: {
        width: '100%',
        backgroundColor: theme.palette.blue[800],
        padding: '66px 33px 40px',
        borderRadius: '4px 0px 0px 0px',
        color: '#fff'
    },
    planName: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 18
    },
    button: {
        borderColor: '#fff',
        borderRadius: 4,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontSize: 14,
        fontWeight: 700,
        marginBottom: 18,
        width: 300,
        '&:disabled': {
            borderColor: '#fff',
            color: '#fff',
            opacity: 0.5
        },
        '&:hover': {
            backgroundColor: theme.palette.blue[400],
            color: '#fff'
        },
        '@media only screen and (max-width: 1200px)': {
            marginBottom: 5
        }
    },
    buyButtonCurrent: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        '&:disabled': {
            color: theme.palette.blue[400]
        }
    },
    talkButton: {
        borderRadius: 4,
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 700,
        width: 300,
        '&:hover': {
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none'
        }
    },
    talkButtonEnterprise: {
        backgroundColor: theme.palette.blue[500],
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.blue[400]
        }
    },
    talkButtonCurrent: {
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#fff'
        }
    }
});

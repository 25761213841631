import React, { useState } from 'react';
import nl2br from 'react-nl2br';
import Truncate from 'react-truncate';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.grey[900],
        fontWeight: 700,
        padding: '0 5px'
    }
}));

export default ({ question: { answeredText }, NoAnswerComponent, hasTruncate }) => {
    if (!answeredText) return <NoAnswerComponent />;
    const { button } = useStyles();
    const [titleOpened, setTitleOpened] = useState(false);
    const [truncated, setTruncated] = useState(false);

    const handleTruncate = (newTruncated) => {
        if (newTruncated && !truncated) {
            setTruncated(newTruncated);
            setTitleOpened(!newTruncated);
        } else if (!newTruncated) {
            setTitleOpened(!newTruncated);
        }
    };

    const toggleTitleOpen = (e) => {
        e.preventDefault();
        setTitleOpened(!titleOpened);
    };

    return (
        hasTruncate ? (
            <>
                <Collapse
                    in={titleOpened || !truncated}
                    className="u-wdt--100p"
                    collapsedSize={truncated ? 145 : '0px'}
                >
                    {!titleOpened && (
                        <div>
                            <Truncate
                                lines={6}
                                onTruncate={handleTruncate}
                            >
                                {nl2br(answeredText)}
                            </Truncate>
                            <Button
                                className={button}
                                onClick={toggleTitleOpen}
                            >
                                Show more
                            </Button>
                        </div>
                    )}
                    {titleOpened && nl2br(answeredText)}
                    {titleOpened && truncated && (
                        <Button
                            onClick={toggleTitleOpen}
                            className={clsx(button, 'u-mrg--lx1')}
                        >
                            Show less
                        </Button>
                    )}
                    {!titleOpened && !truncated && nl2br(answeredText)}
                </Collapse>
            </>
        ) : <p>{ nl2br(answeredText) }</p>
    );
};

import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    manualGradesRequiredLabel: {
        color: theme.palette.yellow[900],
        fontSize: 12,
        fontWeight: 700,
        background: theme.palette.yellow[50],
        borderRadius: 4,
        padding: '4px 14px',
        marginRight: 5
    }
}));

const ManualGradesRequiredLabel = ({ className }) => {
    const { manualGradesRequiredLabel } = useStyles();

    return (
        <span className={clsx(manualGradesRequiredLabel, className)}>Manual Grades Required</span>
    );
};

export default ManualGradesRequiredLabel;

import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import CandidatePhoto from 'components/photos/candidate_photo';
import TableCellCustom from 'components/table_components/table_cell';

import Tooltip from 'libraries/Tooltip';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import CandidateActions from 'pages/assessment/results_assessment/common/CandidateActions';
import HiredLabel from 'pages/assessment/results_assessment/common/HiredLabel';

import Score from 'pages/assessment/results_assessment/candidates_list/CandidatesList/components/CandidatesTable/CandidateRow/Score';
import RankCell from 'pages/assessment/results_assessment/candidates_list/CandidatesList/components/CandidatesTable/CandidateRow/RankCell';
import GroupScore from 'pages/assessment/results_assessment/candidates_list/CandidatesList/components/CandidatesTable/CandidateRow/GroupScore';
import CandidateLabels from 'pages/assessment/results_assessment/candidates_list/CandidatesList/components/CandidatesTable/CandidateRow/CandidateLabels';

import useStyles from './styles';


const CandidateRow = observer(({
    match, candidate, history, companyTags,

    addCandidateTab, audition
}) => {
    const {
        tableRow, statusDate, tableRowDisabled,
        tableCell, tableRowSelected
    } = useStyles();

    const {
        score, candidateId, candidateUuid, fullName,
        scoreType, tags, rank, rankDifference, completedAgoActualDate,
        candidateStatus, realCandidate
    } = candidate;

    const [candidateTags, setCandidateTags] = useState(tags);

    useEffect(() => {
        setCandidateTags(candidateTags);
    }, [tags]);

    if (!audition) return null;

    const { slug } = audition;

    const hired = candidateStatus === 'Hired';

    const goToCard = () => {
        if (disabled) return;
        if (addCandidateTab) addCandidateTab(candidateId, fullName, slug);
        history.push(`${match.url}/candidate/${candidateId}`);
    };

    const scoreObj = score;
    const hasGroupsCandidate = scoreType === 3;
    const hasGroupsAudition = audition.scoreType === 3;
    const disabled = !realCandidate;


    return (
        <Tooltip label={realCandidate ? 'Click to see more detail' : null}>
            <TableRow
                onClick={goToCard}
                classes={{
                    root: clsx(tableRow, disabled && tableRowDisabled),
                    selected: tableRowSelected
                }}
                selected={realCandidate}
                hover={!disabled}
            >
                <TableCellCustom
                    align="left"
                    className={clsx(tableCell, 'u-txt--bold u-pdn--lx4')}
                >
                    <RankCell rank={rank} rankDifference={rankDifference} />
                </TableCellCustom>
                <TableCellCustom align="left" className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')}>
                    <div className="u-dsp--centered u-jc--start">
                        <CandidatePhoto
                            user={candidate}
                        />
                        <TruncateWithTooltip text={fullName} />
                    </div>
                </TableCellCustom>
                <TableCell className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')}>
                    <Tooltip
                        label={<Moment format="MMM D, YYYY">{completedAgoActualDate}</Moment>}
                    >
                        <span className={statusDate}>
                            <Moment fromNow>
                                {completedAgoActualDate}
                            </Moment>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCellCustom className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')} align="left">
                    <div className="u-dsp--f u-ai--center">
                        <Score
                            candidateUuid={candidateUuid}
                            candidate={{ ...candidate, score: scoreObj }}
                            audition={audition}
                            hired={hired}
                        />
                        {hired && <HiredLabel />}
                        <CandidateLabels
                            candidate={{ ...candidate, score: scoreObj }}
                            isOnBoarding
                        />
                    </div>
                </TableCellCustom>
                {
                    hasGroupsAudition && (
                        <>
                            {
                                hasGroupsCandidate ? (
                                    <TableCell className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')}>
                                        <GroupScore
                                            candidate={{ ...candidate, score: scoreObj }}
                                        />
                                    </TableCell>
                                ) : <TableCell />
                            }
                        </>
                    )
                }
                <TableCellCustom align="right" className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')}>
                    {
                        !realCandidate && (
                            <CandidateActions
                                companyTags={companyTags}
                                candidate={{ ...candidate, score: scoreObj }}
                                dataForEvents={{}}
                                shortlistDisabled={disabled}
                                commentsDisabled={disabled}
                                teamScoreDisabled={disabled}
                            />
                        )
                    }
                </TableCellCustom>
            </TableRow>
        </Tooltip>
    );
});

export default withRouter(CandidateRow);

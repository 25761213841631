import React from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/Tooltip';
import CompareIcon from 'components/icons/CompareIcon';
import { MAX_CANDIDATES_COMPARE_COUNT } from 'helper/constants';
import styles from './styles';


const CompareButton = ({ classes, onClick, count, isActive, showCount, disabled, canCompareCandidates }) => {
    let buttonDisabled = disabled;
    let tooltipLabel = <>Add candidates to compare <br /> results side by side</>;
    if (!canCompareCandidates) {
        buttonDisabled = true;
        tooltipLabel = <>Compare is not available for assessments with turned on group randomization with the limit number of questions.</>;
    } else if (count >= MAX_CANDIDATES_COMPARE_COUNT && !showCount && !isActive) {
        buttonDisabled = true;
        tooltipLabel = <>Maximum candidates limit in compare reached. Remove candidate from compare to add new.</>;
    }

    return (
        <Tooltip
            label={tooltipLabel}
            delayHide={300}
            delayShow={300}
        >
            <div>
                <Button
                    onClick={onClick}
                    disabled={buttonDisabled}
                    className={classes.compareButton}
                >
                    <CompareIcon isActive={isActive} />&nbsp;
                    Compare {Boolean(count) && showCount && `(${count})`}
                </Button>
            </div>
        </Tooltip>
    );
}

export default withStyles(styles)(CompareButton);

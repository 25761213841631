import React from 'react';

const CalendarIcon = ({ color = '#FC9935' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1926 2.53333H3.27407C2.57042 2.53333 2 3.10375 2 3.8074V12.7259C2 13.4296 2.57042 14 3.27407 14H12.1926C12.8962 14 13.4667 13.4296 13.4667 12.7259V3.8074C13.4667 3.10375 12.8962 2.53333 12.1926 2.53333Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.2815 1.25928V3.80743" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.18518 1.25928V3.80743" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 6.35559H13.4667" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);


export default CalendarIcon;

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        width: '100%',
        padding: 14,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[200]
    },
    moreButton: {
        borderRadius: 4,
        padding: 5,
        marginRight: 5,
        marginLeft: 15,
        borderLeft: `1px solid ${theme.palette.grey[300]}`
    },
    titleClassName: {
        alignItems: 'center',
        lineHeight: 'normal',
        justifyContent: 'flex-start'
    },
    dialogPaper: {
        maxWidth: 670
    }
}));

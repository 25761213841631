import React, { useContext } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import CandidatePhoto from 'components/photos/candidate_photo';
import { appCtx } from 'components/appStore';
import DetailEmailInfo from './DetailEmailInfo';

import useStyles from './styles';

const CandidatePhotoEmail = observer(({
    user, hideCandidateDetails, hideCopyEvent,
    classNameInfo = '', classNameInfoName = '',
    classNameInfoEmail = '', photoSize
}) => {
    const classes = useStyles();
    const { flashMessage } = useContext(appCtx);

    const { fullName, isTest } = user;

    const onCopyEmail = () => {
        flashMessage('Email address copied to clipboard', '');
    };

    if (hideCandidateDetails) {
        return (
            <div className={classes.root}>
                <div className={classes.hiddenBlock} />
                <div className={clsx(classes.hiddenBlock, classes.hiddenBlockShorter)} />
            </div>
        );
    }

    return (
        <div className={clsx(classes.userWrapper)}>
            <CandidatePhoto
                user={user}
                size={photoSize}
                className="c-img--circle"
            />
            <div className={clsx(classes.userInfo, classNameInfo)}>
                <div className={clsx(classes.userInfoName, classNameInfoName)}>
                    {fullName}
                    {
                        isTest && (
                            <span className="c-card-big__header-test">Test</span>
                        )
                    }
                </div>
                <DetailEmailInfo
                    value={user && user.email}
                    className={clsx(classes.userInfoEmail, classNameInfoEmail)}
                    onCopy={!hideCopyEvent && onCopyEmail}
                />
            </div>
        </div>
    );
});

export default CandidatePhotoEmail;

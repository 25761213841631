export default theme => ({
    listItem: {
        marginBottom: 16,
        color: '#3A3C43'
    },
    listItemHeader: {
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '33px',
        letterSpacing: 0.6,
        textTransform: 'uppercase',
        color: '#9FA0A5'
    },
    text: {
        marginBottom: 15
    },
    contactLink: {
        cursor: 'pointer',
        color: theme.palette.primary.main
    },
    progressWrapper: {
        textAlign: 'center',
        marginTop: 25
    },
    progress: {
        color: 'rgba(0, 0, 0, 0.5)'
    },
    webhookWrapper: {
        maxWidth: 500,
        position: 'relative'
    },
    webhookSaveButton: {
        position: 'absolute',
        right: 0,
        top: 25,
        zIndex: 1,
        '&:disabled': {
            opacity: 1
        }
    },
    input: {
        maxWidth: 500
    }
});

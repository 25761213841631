export default (theme => ({
    button: {
        color: theme.palette.yellow[900],
        '& img': {
            marginRight: 10,
            width: 20
        }
    },
    topBlock: {
        position: 'relative',
        backgroundColor: theme.palette.blue[700],
        color: '#fff',
        padding: '55px 37px 30px'
    },
    bottomBlock: {
        color: theme.palette.grey[900],
        fontSize: 16,
        padding: '36px 37px 45px'
    },
    feature: {
        fontWeight: 700,
        marginBottom: 8,
        '& img': {
            marginRight: 14
        }
    },
    planName: {
        fontWeight: 600,
        marginBottom: 15
    },
    buttonActions: {
        marginTop: 10
    },
    upgradeButton: {
        marginRight: 15,
        width: 215,
        '&:hover, &:active, &:focus': {
            color: '#fff',
            outline: 'none'
        }
    },
    cancelButton: {
        fontWeight: 700,
        width: 115
    },
    header: {
        fontWeight: 700,
        marginBottom: 15,
        marginTop: 20,
        fontSize: 20
    },
    subheader: {
        fontSize: 16,
        maxWidth: 485
    },
    lockIcon: {
        marginLeft: -10
    },
    closeButton: {
        position: 'abosulu'
    },
    closeIcon: {
        position: 'absolute',
        cursor: 'pointer',
        right: '25px',
        top: 22,
        zIndex: 101
    }
}));

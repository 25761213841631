import React from 'react';
import RichTextEditor from 'react-rte';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CompanyLogo from 'components/logos/company_logo';
import RichTextEditorCustom from 'components/inputs/RichTextEditorCustom';
import { requestMessageCandidate } from 'requests/JobOpportunityRequests';
import { errorMessage, BATCH_STEP } from 'helper/constants';
import { checkPluralNew } from 'helper/commonFunctions';
import Dialog from 'libraries/Dialog';
import TextInput from 'libraries/TextInput';
import SelectOutlined from 'libraries/Select';

const charactersLimit = 500;

const styles = theme => ({
    emailLogoWrapper: {
        padding: '20px 0',
        textAlign: 'center',
        borderTop: `2px solid ${theme.palette.grey[300]}`,
        borderLeft: `2px solid ${theme.palette.grey[300]}`,
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    replyLabel: {
        fontSize: 16,
        marginBottom: 20
    },
    emailContent: {
        fontSize: 16,
        fontWeight: 'normal',
        letterSpacing: 0.2,
        marginBottom: 30
    }
});

class ProfileMessageModal extends React.Component {
    constructor(props) {
        super(props);
        const { loggedUser, targetCandidates } = this.props;
        const companyName = loggedUser.company_name;
        const subjectDefault = `Message from ${companyName}`;
        const candidatesName = targetCandidates.length > 1 ? '{first-name}' : (targetCandidates[0].first_name || targetCandidates[0].fullName);
        const messageDefault = RichTextEditor.createValueFromString(`Hi ${candidatesName},<br><br><br><br><br><br><br><br>The ${companyName} Team<br>`, 'html');
        this.state = {
            subject: subjectDefault,
            showSenderEmail: true,
            message: messageDefault
        };
    }


    handleChangeSubject = (e) => {
        const updatedSubject = e.target.value;
        const updatedSubjectLimit = charactersLimit - updatedSubject.length;
        if (updatedSubjectLimit >= 0) {
            this.setState({ subject: updatedSubject });
        }
    };

    handleChangeMessage = (value) => {
        this.setState({ message: value });
    };

    getEventContext = () => {
        let res;
        const { pathname } = window.location;
        const page = pathname.split('/')[2];

        if (pathname.includes('shortlist')) res = 'shortlist';
        else if (pathname.includes('/candidate/')) res = 'candidate report card';
        else res = page;

        return res;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { subject, showSenderEmail } = this.state;
        let { message } = this.state;
        const { targetCandidates, showFlashMessage, handleClickMessageModalClose, onSuccess, sendCallback, audition, sentMessage } = this.props;
        if (!message) {
            message = RichTextEditor.createEmptyValue();
        }
        message = message.toString('html');
        handleClickMessageModalClose();
        showFlashMessage('Sending message...', 'pending');

        const candidatesIds = targetCandidates.map(candidate => candidate.id);
        const candidatesUuids = targetCandidates.map(candidate => candidate.candidateUuid);

        if (sendCallback && targetCandidates.length === 1) {
            let eventData = {
                candidateUUID: candidatesUuids[0],
                content: message,
                subject
            };

            if (audition) {
                eventData = {
                    ...eventData,
                    ttName: audition.name,
                    ttSlug: audition.slug,
                    ttId: audition.uuid,
                    context: this.getEventContext()
                };
            } else {
                eventData = {
                    ...eventData,
                    context: 'all candidates page'
                };
            }

            sendCallback(eventData);
        }

        if (sentMessage) {
            sentMessage(message);
        }

        const max = Math.ceil(targetCandidates.length / BATCH_STEP);
        let i = 0;
        const requestCycle = async () => {
            if (i < max) {
                const startPos = i * BATCH_STEP;
                const endPos = startPos + BATCH_STEP;
                try {
                    const result = await requestMessageCandidate(candidatesIds.slice(startPos, endPos), subject, message, showSenderEmail);
                    if (result.success) {
                        i += 1;
                        requestCycle();
                    } else if (result.message) {
                        showFlashMessage(result.message, 'error');
                    } else {
                        showFlashMessage(errorMessage, 'error');
                    }
                } catch (err) {
                    showFlashMessage(errorMessage, 'error');
                }
            } else {
                showFlashMessage('Message sent', 'done');
                if (onSuccess) {
                    onSuccess();
                }
            }
        };
        requestCycle();
    };

    getCandidateName = (candidate) => {
        const { email: candidateEmail, first_name: candidateFN, last_name: candidateLN, fullName } = candidate;
        return (
            `${fullName || `${candidateFN || ''} ${candidateLN || ''}`} <${candidateEmail}>`
        );
    };

    renderEmails = () => {
        const { targetCandidates } = this.props;
        if (targetCandidates.length > 1) {
            const moreCount = targetCandidates.length - 2;
            return (
                `${this.getCandidateName(targetCandidates[0])}, ${this.getCandidateName(targetCandidates[1])}${moreCount > 0 ? ` + ${moreCount} others` : ''}`
            );
        }

        return this.getCandidateName(targetCandidates[0]);
    }

    render() {
        const { subject, message, showSenderEmail } = this.state;
        const { loggedUser, handleClickMessageModalClose, targetCandidates, open, classes, company } = this.props;
        const { email: employerEmail, first_name: employerFN, last_name: employerLN } = loggedUser;

        return (
            <Dialog
                actionComponent={(
                    <>
                        <Button
                            color="primary"
                            className="u-txt--bold"
                            onClick={handleClickMessageModalClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="u-pdn--lx4 u-pdn--rx4"
                            onClick={this.handleSubmit}
                        >
                            {targetCandidates.length > 1 ? `Send to ${targetCandidates.length} candidates` : 'Send'}
                        </Button>
                    </>
                )}
                titleComponent={`Message ${checkPluralNew(targetCandidates.length, 'Candidate')}`}
                handleClose={handleClickMessageModalClose}
                onClose={handleClickMessageModalClose}
                open={open}
            >
                <p className={classes.replyLabel}>
                    Send a private message via email to a candidate
                </p>
                <TextInput
                    value={this.renderEmails()}
                    label={`Candidate ${checkPluralNew(targetCandidates.length, 'email')}`}
                    fullWidth
                    className="u-mrg--bx5"
                    margin="none"
                    variant="outlined"
                    disabled
                />
                <SelectOutlined
                    fullwidth
                    className="u-mrg--bx5 u-mrg-tx0"
                    variant="outlined"
                    label="From email address"
                    value={showSenderEmail}
                    options={[
                        { value: true, label: `${employerFN} ${employerLN} <${employerEmail}>` },
                        { value: false, label: 'no-reply' }
                    ]}
                    onChange={e => this.setState({ showSenderEmail: e.target.value })}
                />
                <TextInput
                    value={subject}
                    label="Email Subject line"
                    onChange={this.handleChangeSubject}
                    fullWidth
                    className="u-mrg--bx5"
                    margin="none"
                    maxLength={500}
                    hasCounter
                    variant="outlined"
                />
                <div className=" u-clear  u-pdn--bx0">
                    <div className={classes.emailLogoWrapper}>
                        <CompanyLogo
                            className="u-txt--20 u-dsp--center"
                            size={220}
                            company={company}
                        />
                    </div>
                    <div className={classes.emailContent}>
                        <form className="u-mrg--bx9  u-txt--left">
                            <RichTextEditorCustom
                                onChange={this.handleChangeMessage}
                                value={message}
                                placeholder="Your message goes here..."
                            />
                        </form>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ProfileMessageModal);

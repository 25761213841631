import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import TextField from 'libraries/TextInput';
import { changeAssessmentLink, addAssessmentLink } from 'requests/AssessmentRequests';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import SocialMediaIcons from 'components/icons/social_media_icons';
import AssessmentEvents from 'events/AssessmentEvents';
import ShareJobLinkDialog from './shareJobLinkDialog';

const styles = {
    loader: {
        '& svg': { marginRight: 0 }
    }
};

class ApplicationLinkDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ...this.setInitialParams(props)
        };
    }

    componentWillReceiveProps(nextProps) {
        const { formLink: prevFormLink } = this.props;
        if (nextProps.formLink !== prevFormLink) {
            const newState = this.setInitialParams(nextProps);
            this.setState(newState);
        }
    }

    onClose = () => {
        const { handleClose } = this.props;
        const newState = this.setInitialParams();
        this.setState(newState);
        handleClose();
    };

    setInitialParams = (props = this.props) => {
        if (!props) return;
        const { formLink } = props;
        let formListing = {
            name: '',
            id: 0,
            description: '',
            url: '',
            source: ''
        };
        if (formLink) {
            const { name, id, description, url, source } = formLink;
            formListing = {
                name,
                id,
                description,
                url,
                source
            };
        }
        return ({
            formListing,
            formErrorListing: {},
            shareLinkModalOpen: false,
            shareLink: ''
        });
    };

    setDialogParameters = () => {
        const { formListing } = this.state;
        const parameters = {};
        if (formListing.id === 0) {
            parameters.title = 'Add Assessment Application Link';
            parameters.confirmAction = 'Add';
        } else {
            parameters.title = 'Edit listing link';
            parameters.confirmAction = 'Save';
        }
        return parameters;
    };

    onHandleChange = (e) => {
        const { formListing } = this.state;
        const targetName = e.target.name ? e.target.name : 'name';
        const updateForm = { ...formListing, [targetName]: e.target.value };
        this.setState({ formListing: updateForm, formErrorListing: {} });
    };

    renderCopyLink = () => {
        const { flashMessage } = this.props;
        const {
            formListing
        } = this.state;
        if (formListing.id !== 0) {
            return (
                <div className="u-mrg--tx4 u-txt--lh18">
                    <span className="t-txt--brand-dark">Listing link:</span>
                    <br />
                    <CopyToClipboard text={formListing.url} onCopy={() => flashMessage('Link Copied')}>
                        <div className="u-mrg--tx1 u-cursor--p u-dsp--f u-ai--center u-jc--start">
                            {formListing.url} &nbsp;
                            <Button color="primary" className="u-txt--bold">Copy</Button>
                        </div>
                    </CopyToClipboard>
                </div>
            );
        }
        return '';
    };

    onHandleChangeRadio = (value) => {
        const { formListing } = this.state;
        const updateForm = { ...formListing, source: value };
        this.setState({ formListing: updateForm });
    };

    handleShareLinkModalOpen = () => {
        const { shareLinkModalOpen } = this.state;
        this.setState({ shareLinkModalOpen: !shareLinkModalOpen });
    };

    changeLinkForAssessment = () => {
        const { slug, updateLink } = this.props;
        const { formListing } = this.state;
        this.setState({ loading: true });
        changeAssessmentLink(slug, formListing)
            .then(({ data, success }) => {
                if (success) {
                    if (updateLink) updateLink(data);
                    this.onClose();
                }
            })
            .catch(this.editLinkCallback)
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    editLinkCallback = ({ response }) => {
        const { flashMessage } = this.props;
        if (response.data && response.data.msg) {
            const { msg } = response.data;
            const { formErrorListing } = this.state;
            this.setState({ formErrorListing: { ...formErrorListing, name: msg } });
        } else {
            flashMessage('Something went wrong.');
        }
    }

    addLinkToAssessment = () => {
        const { slug, updateLink, audition } = this.props;
        const { formListing } = this.state;
        const { name, source } = formListing;

        this.setState({ loading: true });
        addAssessmentLink(slug, { name, source })
            .then(({ success, data }) => {
                if (success) {
                    const { uuid, name: ttName } = audition;
                    AssessmentEvents.LINK_CREATED({ ttId: uuid, ttName, linkName: name });
                    this.setState({ shareLink: data.url });
                    this.handleShareLinkModalOpen();
                    if (updateLink) updateLink(data);
                    this.onClose();
                }
            })
            .catch(this.editLinkCallback)
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    submitLinkForm = (param = false) => {
        if (param === 'Add') {
            return this.addLinkToAssessment(param);
        }
        return this.changeLinkForAssessment(param);
    };

    renderError = (formParam) => {
        const { formErrorListing } = this.state;
        if (formErrorListing && formErrorListing[formParam]) {
            return formErrorListing[formParam];
        }
        return '';
    };

    render() {
        const { listingLinksDialogOpen, classes } = this.props;
        const { formListing, shareLinkModalOpen, shareLink, loading } = this.state;
        const { name, source } = formListing;
        const parameters = this.setDialogParameters();
        const actionsMain = [
            <Button
                key={0}
                onClick={this.onClose}
                color="primary"
                className="u-txt--bold"
            >
                Cancel
            </Button>,
            <Button
                key={1}
                color="primary"
                variant="contained"
                className="u-txt--bold u-pdn--rx7 u-pdn--lx7"
                onClick={() => this.submitLinkForm(parameters.confirmAction)}
                disabled={loading || !name}
            >
                {loading
                    ? (
                        <CircularProgress
                            size={22}
                            className={classes.loader}
                        />)
                    : parameters.confirmAction}
            </Button>
        ];

        const socialMedia = ['seek', 'vervoe', 'linkedin', 'facebook', 'indeed', 'twitter', 'whatsapp', 'tiktok', 'catstomp'];

        return (
            <>
                <Dialog
                    maxWidth="sm"
                    titleComponent={parameters.title}
                    actionComponent={actionsMain}
                    open={listingLinksDialogOpen}
                    handleClose={this.onClose}
                >
                    <TextField
                        id="qa-listing-name-txt"
                        label="Listing Name"
                        isError={Boolean(this.renderError('name'))}
                        helperText={this.renderError('name')}
                        onChange={this.onHandleChange}
                        fullWidth
                        name="name"
                        value={name}
                    />

                    {/* Select social icon */}
                    <div className="u-mrg--tx4  u-mrg--bx2">
                        {
                            socialMedia.map(item => (
                                <SocialMediaIcons
                                    socialMedia={item}
                                    onClick={() => this.onHandleChangeRadio(item)}
                                    active={source === item}
                                    className="u-mrg--rx1 u-cursor--p"
                                />
                            ))
                        }
                    </div>
                    {/* listing link */}
                    {this.renderCopyLink()}
                </Dialog>
                <ShareJobLinkDialog
                    onClose={this.handleShareLinkModalOpen}
                    open={shareLinkModalOpen}
                    shareLink={shareLink}
                />
            </>
        );
    }
}

export default withStyles(styles)(ApplicationLinkDialog);

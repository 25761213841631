export default theme => ({
    checkboxRow: {
        height: 64,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        '&:last-child': {
            border: 'none'
        }
    },
    select: {
        width: 120,
        '& .MuiSelect-select': {
            fontSize: 12,
            paddingTop: 6,
            paddingBottom: 6
        }
    },
    selectMenuPaper: {
        '& .MuiMenu-list > li:first-child': {
            display: 'none'
        }
    },
    checkboxLabel: {
        fontSize: 14,
        fontWeight: 600
    },
    checkboxRoot: {
        margin: 0
    },
    closedText: {
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: 600,
        '& > img': {
            marginBottom: 4
        },
        '& > span': {
            color: theme.palette.blue[500]
        }
    }
});

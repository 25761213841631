import React from 'react';
import { withTheme } from '@mui/styles';

const CheckIcon = ({ theme, color = theme.palette.green[400], ...other }) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.9998 1.7998C5.0254 1.7998 1.7998 5.0254 1.7998 8.9998C1.7998 12.9742 5.0254 16.1998 8.9998 16.1998C12.9742 16.1998 16.1998 12.9742 16.1998 8.9998C16.1998 5.0254 12.9742 1.7998 8.9998 1.7998Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.11383 10.9742L12.1679 5.60927C12.4175 5.34426 12.838 5.32875 13.1071 5.57461C13.3762 5.82048 13.3919 6.23462 13.1423 6.49963L7.59282 12.3905C7.32685 12.6729 6.87206 12.6691 6.61091 12.3825L4.84961 10.4491C4.60449 10.18 4.62726 9.76619 4.90047 9.52478C5.17369 9.28337 5.59388 9.30579 5.839 9.57487L7.11383 10.9742Z" fill="white" />
    </svg>
);

export default withTheme(CheckIcon);

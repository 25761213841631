import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Button, CircularProgress } from '@mui/material';

import PlusIcon from 'img/plus.svg';
import ChooseNewTestCaseTypeDialog from '../../../../ModalWindows/ChooseNewTestCaseTypeDialog';

import styles from '../../../styles';

const TestCasesList = ({ classes, cases, runTest, runningTest, handleDrawerOpen, chooseCase }) => {
    const [openChooseNewTestCaseTypeDialog, setOpenChooseNewTestCaseTypeDialog] = useState(false);
    return <>
        <div className={classes.content}>
            {cases.map(testCase => (
                <Typography
                    onClick={() => { chooseCase('edit', testCase.id); }}
                    className={classes.testCase}
                >
                    {testCase.name}
                </Typography>
            ))}
            <Button
                className={classes.secondaryNoBorderButton}
                onClick={() => setOpenChooseNewTestCaseTypeDialog(true)}
            >
                <img src={PlusIcon} alt="+" />
                Add a Test Case
            </Button>
        </div>
        <div className={classes.footer}>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    runTest();
                    handleDrawerOpen();
                }}
                disabled={runningTest || !cases.length}
            >
                {runningTest
                    ? <>
                        Running tests...
                        <CircularProgress
                            size={24}
                            thickness={2}
                            classes={{ root: classes.loader }}
                        />
                    </>
                    : 'Run Tests'
                }
            </Button>
        </div>
        <ChooseNewTestCaseTypeDialog
            open={openChooseNewTestCaseTypeDialog}
            handleClose={() => setOpenChooseNewTestCaseTypeDialog(false)}
            chooseCase={chooseCase}
        />
    </>;
};

export default withStyles(styles)(TestCasesList);

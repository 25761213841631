import clsx from 'clsx';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { withStyles } from '@mui/styles';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import styles from '../../styles';

const CandidateOption = ({ candidate, hiredCandidates, handleHired, classes }) => {
    if (!candidate) return null;

    const { candidateId, fullName, score, completionsPerOpportunityLimitExceeded, rejected } = candidate;

    if (completionsPerOpportunityLimitExceeded || rejected) return null;

    const scoreNumber = score && score.score !== undefined && Number.isInteger(score.score) ? `${score.score}%` : '-';

    const active = Boolean(hiredCandidates.find(item => candidateId === item.candidateId));

    return (
        <div
            role="presentation"
            className={clsx('u-jc--sb', classes.option, active && classes.activeOption)}
            key={candidateId}
            onClick={() => handleHired(candidate)}
        >
            <div className="u-dsp--f u-ai--center">
                <Checkbox
                    checked={active}
                />
                <TruncateWithTooltip width={370} text={fullName} />
            </div>
            <div>
                <span className={classes.score}>Score</span> {scoreNumber}
            </div>
        </div>
    );
};

export default withStyles(styles)(CandidateOption);

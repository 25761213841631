import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';

import HireIcon from './icon.svg';
import styles from './styles';
import NoHireReasonDialog from './NoHireReasonDialog';
import SelectHiredDialog from './SelectHiredDialog';

const DeactivateSurveyDialog = observer(({
    classes, open, onClose, assessment, onSuccess
}) => {
    const [noHireReasonDialogOpen, setNoHireReasonDialogOpen] = useState(false);
    const [selectHiredDialogOpen, setSelectHiredDialogOpen] = useState(false);

    const openNoHireDialog = () => {
        setNoHireReasonDialogOpen(true);
        onClose();
    };

    const openSelectHiredDialog = () => {
        setSelectHiredDialogOpen(true);
        onClose();
    };

    return (
        <>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={onClose}
                handleClose={onClose}
                contentClassName={classes.contentClassName}
                actionComponent={(
            <>
                <Button
                    className={classes.secondaryButton}
                    onClick={openNoHireDialog}
                >
                    No
                </Button>
                <Button
                    className={classes.mainButton}
                    color="primary"
                    variant="contained"
                    onClick={openSelectHiredDialog}
                >
                    Yes
                </Button>
            </>
                )}
            >
                <img src={HireIcon} alt="!" className="u-mrg--bx2" />
                <div className={classes.contentTitle}>
                    Did you make a hire?
                </div>
                <div className={classes.contentText}>
                Marking candidates as hired will allow you to find and filter them in future from all candidates.
                </div>
            </Dialog>
            <NoHireReasonDialog
                open={noHireReasonDialogOpen}
                onClose={() => setNoHireReasonDialogOpen(false)}
                assessment={assessment}
                onSuccess={onSuccess}
            />
            <SelectHiredDialog
                open={selectHiredDialogOpen}
                onClose={() => setSelectHiredDialogOpen(false)}
                assessment={assessment}
                onSuccess={onSuccess}
            />
        </>
    );
});

export default withStyles(styles)(withRouter(DeactivateSurveyDialog));

import React from 'react';
import { withTheme } from '@mui/styles';


const ExportIcon = ({ theme, color = theme.palette.grey[400], ...other }) => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M3 13.4999L12.6667 3.83325" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.66675 3.83325H12.6667V11.1666" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(ExportIcon);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { skipOnBoarding } from 'requests/APIrequests';
import VervoeLogo from 'components/layout/Header/components/VervoeLogo';
import OnboardingEvents from 'events/OnboardingEvents';
import { appCtx } from 'components/appStore';
import { getOnboardingLocation } from 'helper/commonFunctions';
import { submitOnboardingAssessment } from 'requests/AssessmentRequests';

import useStyles from './styles';

const OnboardingHeader = observer(({ history, location }) => {
    const { company, updateCompany } = useContext(appCtx);
    const classes = useStyles();
    const { onboarding } = company;
    const { activeStage, auditionSlug, userAudition } = onboarding;

    const handleSkip = () => {
        if (activeStage === 'candidate_experience') {
            OnboardingEvents.OB_SKIPPED_CANDIDATE_EXPERIENCE();
            submitOnboardingAssessment(userAudition.id)
                .then(() => {
                    updateCompany({ ...company, onboarding: { ...onboarding, activeStage: 'faked_select' } });
                    history.push(`/onboarding/select/${auditionSlug}`);
                });
            return;
        }
        onSkip();
    };

    const onSkip = () => {
        if (activeStage === 'assessment_list') {
            OnboardingEvents.OB_SKIPPED_ONBOARDING();
        } else {
            OnboardingEvents.OB_SKIPPED_SELECT({ step: getOnboardingLocation(location.pathname) });
        }
        skipOnBoarding()
            .then(() => {
                updateCompany({
                    ...company,
                    onboarding: null
                });
                history.push('/marketplace');
            });
    };

    return (
        <AppBar
            enableColorOnDark
            className={classes.appBar}
        >
            <Toolbar className={classes.toolbar}>
                <div className={classes.left}>
                    <VervoeLogo />
                </div>
                <div className={classes.right}>
                    <Button
                        color="primary"
                        onClick={handleSkip}
                    >
                        { activeStage === 'assessment_list' ? 'Exit onboarding' : 'Skip' }
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    );
});

export default withRouter(OnboardingHeader);

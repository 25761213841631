import React from 'react';
import { NavLink } from 'react-router-dom';
import NotFoundComponent from 'components/not_found';
import { withStyles } from '@mui/styles';
import Icon from './icon.svg';

const styles = theme => ({
    link: {
        fontWeight: 'bold',
        color: theme.palette.blue[600]
    }
});

const NotFound = ({ classes }) => (
    <NotFoundComponent
        icon={Icon}
        mainText="Oops! We can’t seem to find the page you’re looking for."
        additionalText={(
            <>
                <p>You may have mistyped the address or the page may have been moved.</p>
                <p>Go to&nbsp;
                    {
                        localStorage.getItem('token') ? (
                            <NavLink
                                to="/my-assessments"
                                className={classes.link}
                            >
                                my assessments&nbsp;
                            </NavLink>
                        ) : (
                            <NavLink
                                to="/login"
                                className={classes.link}
                            >
                                login page&nbsp;
                            </NavLink>
                        )
                    }
                    or try our&nbsp;
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://vervoe.zendesk.com/hc/en-us"
                        className={classes.link}
                    >
                        Help Centre
                    </a>
                </p>
            </>
        )}
    />
);

export default withStyles(styles)(NotFound);

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { clone } from 'helper/commonFunctions';
import Dialog from 'libraries/Dialog';
import { deleteHiringManager } from 'requests/CandidatesRequests';
import HiredEvents from 'events/HiredEvents';
import { appCtx } from '../../../../../appStore';

import styles from './styles';


const DeleteManagerDialog = observer(({ manager, onClose, open, setHiringManagers, dataForEvents,
    hiringManagers, hiringManagerFullName, setFullName, setManagerDeleted, setHiringManager }) => {
    if (!manager) return null;
    const [loading, setLoading] = useState(false);
    const { flashMessage } = useContext(appCtx);
    const { fullName, uuid, email } = manager;

    const handleDelete = () => {
        setLoading(true);
        deleteHiringManager(uuid)
            .then(() => {
                const cloneManagers = clone(hiringManagers);
                const index = cloneManagers.findIndex(man => man.uuid === uuid);
                cloneManagers.splice(index, 1);
                setHiringManagers(cloneManagers);
                if (fullName === hiringManagerFullName) setFullName(null);
                onClose();
                setManagerDeleted(true);
                setHiringManager({});
                flashMessage('Hiring Manager was removed', 'done');
                const { ttName, ttId } = dataForEvents;
                const dataForEvent = { ttName, ttId };
                dataForEvent.hiringManagerName = fullName;
                dataForEvent.hiringManagerEmail = email;
                dataForEvent.hiringManagerId = uuid;
                HiredEvents.HIRING_MANAGER_REMOVED(dataForEvent);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClick={e => e.stopPropagation()}
            onClose={onClose}
            titleComponent="Remove Hiring Manager"
            handleClose={onClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleDelete}
                        className="u-pdn--lx5 u-pdn--rx5"
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={22}
                                    style={{ color: '#fff' }}
                                />
                            ) : 'Remove'
                        }
                    </Button>
                </>
            )}
        >
            Are you sure you want to remove <b>{fullName}</b>?
        </Dialog>
    );
});

export default withStyles(styles)(DeleteManagerDialog);

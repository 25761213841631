import React from 'react';

export default () => [
    {
        id: 1,
        buttonText: 'Cost',
        mainText: <>Did you know we have power priced plans that might suit <br /> your hiring needs?</>,
        actionButtonText: 'Show me plan options',
        link: '/subscriptions'
    },
    {
        id: 2,
        buttonText: 'Don’t have the features I need',
        reasonText: 'No Features',
        mainText: 'We’re sad to see you leave. Please tell us your reason so we can improve Vervoe.',
        actionButtonText: 'Find my feature',
        link: '/integrations-management'
    },
    {
        id: 3,
        buttonText: 'Not hiring right now',
        reasonText: 'Not Hiring',
        mainText: <>You don’t have to say goodbye! You can pause your account payments <br /> for up to 3 months and save your data.</>,
        actionButtonText: 'Pause payments',
        zendeskMessage: 'I would like to pause my account'
    },
    {
        id: 4,
        buttonText: <>It’s too hard<br />to use</>,
        reasonText: 'Too Hard',
        mainText: <>Setting up an assessment doesn’t need to feel overwhelming, our <br /> friendly support team is just a click away.</>,
        actionButtonText: 'Help me!',
        zendeskMessage: 'Can I please have help with my account?'
    },
    {
        id: 5,
        buttonText: 'Other',
        mainText: 'We’re sad to see you leave. Please tell us your reason so we can improve Vervoe.',
        actionButtonText: 'Contact Support',
        link: ''
    }
];

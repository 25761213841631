import { capitalizeFirstLetter, checkPluralNew } from './commonFunctions';

const ERROR_MIN_LENGTH = 'min_length';
const ERROR_MAX_LENGTH = 'max_length';
const ERROR_SPECIAL_CHARACTERS = 'special_character';
const ERROR_UNAVAILABLE = 'unavailable';
const ERROR_ALREADY_EXISTS = 'Company with this slug already exists' ;
const ERROR_ASSESSMENT_ALREADY_EXISTS = 'Assessment with this slug already exists';
const ERROR_VERVOE_WORD = 'The use of the word Vervoe as your company slug is not authorised';
const ERROR_ASSESSMENT_VERVOE_WORD = 'The use of the word Vervoe as your assessment slug is not authorised';

export function getErrorMessageByType(type, title = '', params = {}) {
    const limit = params.limit || 0;

    switch (type) {
        case ERROR_MIN_LENGTH:
            return `${capitalizeFirstLetter(title)} slug must be at least ${limit} ${checkPluralNew(limit, 'character')} long.`;
        case ERROR_MAX_LENGTH:
            return `Your ${capitalizeFirstLetter(title)} slug URL can't exceed ${limit} ${checkPluralNew(limit, 'character')}.`;
        case ERROR_SPECIAL_CHARACTERS:
            return `${capitalizeFirstLetter(title)} slug cannot contain special characters and blank characters.`;
        case ERROR_UNAVAILABLE:
            return `${capitalizeFirstLetter(title)} slug you have entered is not available.`;
        case ERROR_ALREADY_EXISTS:
        case ERROR_ASSESSMENT_ALREADY_EXISTS:
        case ERROR_VERVOE_WORD:
        case ERROR_ASSESSMENT_VERVOE_WORD:
            return type;
        default:
            return '';
    }
}

export function getErrorMessagesToField(errors, fieldName = '', title = '') {
    const messages = [];
    if (errors && fieldName in errors && errors[fieldName].length > 0) {
        const fieldErrors = errors[fieldName];
        Object.keys(fieldErrors)
            .forEach((key) => {
                if (key in fieldErrors) {
                    messages.push(getErrorMessageByType(fieldErrors[key].message, title, fieldErrors[key].params));
                }
            });
    }

    return messages;
}

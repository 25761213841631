import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Select from 'libraries/Select';

import { changeTeamRole, changeCompanyOwner } from 'requests/SettingsRequests';

import TrashIcon from 'img/trash.svg';
import { appCtx } from 'components/appStore';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { PERMISSIONS } from 'helper/constants';

import TeamEvents from 'events/SettingsEvents';
import styles from './styles';

const ActiveMemberActions = observer(({
    classes, user: { id, owner, email, roleType, fullName, uuid }, handleDialog, loadTeamMembers
}) => {
    const { company, flashMessage,
        loggedUser: { id: loggedUserId, roleType: userRoleType, uuid: loggedUserUuid,
            email: loggedUserEmail, userSegmentTraits: { name: loggedUserFullName } },
        loggedUser, updateLoggedUser
    } = useContext(appCtx);
    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState(roleType);

    if (!owner && loggedUserId === id && !hasPermissionsFeature) return null;

    if (owner || loggedUserId === id || roleType === 'integration') {
        return (
            <div className={classes.nonChangeableWrapper}>{roleType}</div>
        );
    }

    const hasEditPermissions = (userRoleType === 'owner' || userRoleType === 'admin');

    const roleTypeOptions = [];

    if (userRoleType === 'owner') {
        roleTypeOptions.push({ value: 'owner', label: 'Owner' });
    }

    if (userRoleType === 'owner' && !hasPermissionsFeature) roleTypeOptions.push({ value: 'admin', label: 'Select role' });

    if (hasEditPermissions && hasPermissionsFeature) {
        roleTypeOptions.push(
            { value: 'team', label: 'Team' },
            { value: 'admin', label: 'Admin' }
        );
    }

    const onChange = (e) => {
        const { value } = e.target;
        const oldValue = role;
        if (oldValue === value) return;
        setRole(value);
        setLoading(true);

        if (value === 'owner') {
            changeCompanyOwner(uuid)
                .then(({ success }) => {
                    if (success) {
                        flashMessage('Owner has been changed', 'done');
                        loadTeamMembers();
                        TeamEvents.OWNER_CHANGED({
                            userUUID: loggedUserUuid,
                            ownerEmail: loggedUserEmail,
                            ownerName: loggedUserFullName,
                            newOwnerUUID: uuid,
                            newOwnerName: fullName,
                            newOwnerEmail: email
                        });
                    }
                    updateLoggedUser({ ...loggedUser, roleType: 'admin' });
                })
                .catch(() => {
                    setRole(oldValue);
                    flashMessage('Something went wrong', 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            changeTeamRole(uuid, value)
                .then(({ success }) => {
                    if (success) {
                        flashMessage(value !== 'team'
                            ? 'Team member has been upgraded to Admin'
                            : 'Admin has been downgraded to Team', 'done');

                        loadTeamMembers();
                    }
                    if (value !== 'team') {
                        TeamEvents.TEAM_MEMBER_UPGRADED({ userId: uuid });
                    } else {
                        TeamEvents.TEAM_MEMBER_DOWNGRADED({ userId: uuid });
                    }
                })
                .catch(() => {
                    setRole(oldValue);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div className="u-dsp--f u-ai--center u-jc--end">
            <IconButton onClick={handleDialog} className={classes.iconButton} size="large">
                <img src={TrashIcon} alt="trash" />
            </IconButton>
            {
                !!roleTypeOptions.length && (
                    <Select
                        margin="none"
                        value={role}
                        className={classes.select}
                        onChange={onChange}
                        selectMenuClassName={classes.selectedMenu}
                        variant="outlined"
                        disabled={!hasEditPermissions || loading}
                        options={roleTypeOptions}
                    />
                )
            }
        </div>
    );
});

export default withRouter(withStyles(styles)(ActiveMemberActions));

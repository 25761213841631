import React from 'react';
import RetinaImage from 'react-retina-image';

const Photo = (props) => {
    const { user = {}, className, size } = props;
    const photoSource = user.imageUrl;
    const { first_name, last_name, initials, name, userTypeName } = user;
    const username = `${first_name} ${last_name}`;
    return (
        <div
            className={`c-card-big__judge ${userTypeName === 'Guest' ? 'guest' : ''} ${className || ''}`}
            style={{ width: size, height: size, overflow: 'hidden' }}
        >
            {
                photoSource
                    ? (
                        <RetinaImage
                            alt={username}
                            src={photoSource}
                        />
                    ) : (
                        <React.Fragment>
                            {initials}

                            { (!initials && name && name.match(/\b\w/g)) && (name.match(/\b\w/g).join('')) }
                        </React.Fragment>
                    )
            }
        </div>
    );
};

export default Photo;

export default theme => ({
    btnEdit: {
        background: 'white',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#3a3c43',
        textTransform: 'none',
        width: 250,
        height: 40,
        '&:hover': {
            background: 'rgba(255,255,255, 0.4)'
        }
    },
    switch: {
        marginBottom: 0
    },
    bar: {
        width: '34px',
        height: '18px',
        borderRadius: '9px',
        backgroundColor: '#b1b1b1'
    },
    icon: {
        width: '16px',
        height: '16px',
        backgroundColor: 'white',
        marginTop: '4px',
        marginRight: '2px'
    },
    iconChecked: {
        marginRight: '-2px',
        backgroundColor: 'white',
        boxShadow: 'none'
    },
    infoIconPopUp: {
        position: 'relative',
        display: 'block',
        width: '16px',
        height: '16px',
        cursor: 'pointer',
        '& img': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: -1
        },
        '&:hover > $infoPopUp': {
            zIndex: 10000,
            display: 'block'
        }
    },
    infoPopUp: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: -265,
        top: 45,
        left: -22,
        borderRadius: 3,
        background: 'white',
        boxSizing: 'border-box',
        fontSize: 14,
        boxShadow: '0 0px 50px 0 rgba(0, 0, 0, 0.25)',
        transition: 'all 0.3s',
        lineHeight: '1.5',
        display: 'none',
        width: 340,
        height: 334,
        padding: 14,
        overflow: 'visible',
        '&::after': {
            content: "''",
            position: 'absolute !important',
            left: '14px !important',
            top: '-30px !important',
            border: '15px solid transparent !important',
            borderBottom: '15px solid white !important'
        },
        '& > p': {
            color: 'rgba(61, 63, 71, 0.9)',
            fontFamily: 'Open Sans',
            marginBottom: 18,
            marginTop: 26
        },
        '& > div': {
            position: 'relative',
            height: 220,
            borderRadius: 3,
            background: '#2f3545',
            padding: '20px 15px',
            '& > h2': {
                color: '#fff',
                fontWeight: 'bold',
                fontFamily: 'Open Sans',
                fontSize: 14,
                margin: 0
            },
            '& > span': {
                fontWeight: 'bold',
                fontSize: 11,
                fontFamily: 'Open Sans',
                color: 'rgba(255,255,255, 0.37)',
                textTransform: 'uppercase'
            }
        }
    },
    caseSimple: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 42,
        alignItems: 'center',
        background: 'transparent',
        borderBottom: '1px solid rgba(0, 0, 0, 0.9)',
        paddingRight: 10,
        marginBottom: 12
    },
    caseActive: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 42,
        background: 'rgba(0, 0, 0, 0.1)',
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
        paddingRight: 10,
        marginTop: 20
    },
    caseText: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        color: '#fff',
        fontSize: 11,
        fontFamily: 'Open Sans'
    },
    casePoint: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: '#2fd1b8',
        fontWeight: 'bold',
        fontSize: 11,
        fontFamily: 'Open Sans',
        width: 100
    },
    casePointError: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 11,
        fontFamily: 'Open Sans',
        color: '#ff5e5e',
        width: 100
    },
    caseScore: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        height: 45,
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 12,
        fontFamily: 'Open Sans',
        boxShadow: '0 2px 25px 0 rgba(0, 0, 0, 0.2) !important',
        paddingRight: 25
    },
    error: {
        display: 'flex',
        color: theme.palette.red.primary,
        fontSize: '11px',
        marginTop: '20px',
    }
});

import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { addQuestionGroup } from 'requests/QuestionsRequests';
import { appCtx } from 'components/appStore';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from 'libraries/Popover';
import TextInput from 'libraries/TextInput';
import SkillsOrTagsMenuItem from 'components/assessments_pages/asessment_settings_components/SkillsOrTagsMenuItem';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import { assessmentEditCtx } from '../../../store';
import CreateBox from '../CreateBox';
import UpgradeBox from './UpgradeBox';

import styles from './styles';

const SidebarFooter = observer(({ classes, history, location, match }) => {
    const [selectedSkillName, setSelectedSkillName] = useState(null);
    const [loadingName, setLoadingName] = useState(null);
    const [loadingAddingGroup, setLoadingAddingGroup] = useState(false);
    const [skillName, setSkillName] = useState('');
    const [popover, setPopover] = useState(null);
    const { company } = useContext(appCtx);
    const { changeAuditionParameter, audition } = useContext(assessmentSettingsCtx);
    const { addNewQuestion, selectedSkill } = useContext(assessmentEditCtx);

    const { scriptSlug } = match.params;
    const { scoreType, questionGroups, slug } = audition;

    const createNewQuestion = groupTitle => addNewQuestion(scriptSlug, groupTitle, false, handleClickScrollPosition);

    const handleClickScrollPosition = () => {
        const activeBox = document.getElementById('active_question');
        if (!activeBox) return;
        activeBox.scrollIntoView({ behavior: 'smooth' });
    };

    const onChange = (e) => {
        const { value } = e.target;
        setSkillName(value);
    };

    const addSkill = () => {
        setLoadingAddingGroup(true);
        addQuestionGroup(slug, { title: skillName.toLowerCase() })
            .then(({ success, data }) => {
                if (data && success) {
                    const { title } = data;
                    addQuestion(title);
                    const { questionGroups: auditionQuestionGroups } = audition;
                    let auditionHasGroup = true;
                    if (auditionQuestionGroups) { // if adding question of non-existent group
                        auditionHasGroup = data && auditionQuestionGroups.some(item => item.id === data.id);
                    }
                    if (!auditionHasGroup) {
                        changeAuditionParameter('questionGroups', auditionQuestionGroups.concat([data]));
                    }
                }
                setLoadingAddingGroup(false);
            })
            .catch(() => {
                setLoadingAddingGroup(false);
            });
    };

    const onClose = () => {
        setSelectedSkillName(null);
        setSkillName('');
    };

    const handleClose = () => {
        if (popover) popover.handleClose();
    };

    const popoverMounted = (callbacks) => {
        setPopover(callbacks);
    };

    const addQuestion = async (title) => {
        setSelectedSkillName(title);
        setLoadingName(title);
        await createNewQuestion(title);
        handleClose();
        setLoadingName(null);
        setSelectedSkillName(null);
    };

    const goToSettings = () => {
        history.push({ pathname: `/script/settings/${scriptSlug}`, state: { from: location.pathname } });
    };

    let buttonComp = null;
    const addedManuallyQuestionsCountSettings = company.companySettings.countAddedManuallyQuestions;
    const addingQuestionUnavailable = addedManuallyQuestionsCountSettings !== -1
        && addedManuallyQuestionsCountSettings <= audition.countManuallyAddedQuestions;

    if (addingQuestionUnavailable) {
        buttonComp = <UpgradeBox onClick={() => window.open('/subscriptions', '_blank')} />;
    } else if (scoreType === 3) {
        buttonComp = selectedSkill !== 'all' ? (
            <CreateBox onClick={() => createNewQuestion(selectedSkill)} />
        ) : (
            <Popover
                onMounted={callbacks => popoverMounted(callbacks)}
                className={classes.popoverRoot}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                onClose={onClose}
                label={<CreateBox />}
            >
                <div className={classes.popoverHeader}>
                    <Typography variant="h6">Add Question to Skill</Typography>
                </div>
                <div className={classes.popoverContent}>
                    {
                        loadingAddingGroup && (
                            <div className={classes.loaderWrapper}>
                                <CircularProgress size={35} color="primary" />
                            </div>
                        )
                    }
                    <List>
                        {
                            questionGroups.map((group) => {
                                const { id, title } = group;
                                const isActive = selectedSkillName === title;
                                return (
                                    <SkillsOrTagsMenuItem
                                        key={id}
                                        group={group}
                                        isActive={isActive}
                                        disabled={isActive}
                                        loadingName={loadingName}
                                        onClick={() => addQuestion(title)}
                                    />
                                );
                            })
                        }
                    </List>
                </div>
                <div className={classes.popoverFooter}>
                    <TextInput
                        variant="outlined"
                        placeholder="Add new skill"
                        className={classes.skillInput}
                        onChange={onChange}
                        value={skillName}
                    />
                    <Button
                        disabled={loadingAddingGroup || !skillName}
                        onClick={addSkill}
                        color="primary"
                    >
                        Add
                    </Button>
                </div>
            </Popover>
        );
    } else {
        buttonComp = <CreateBox onClick={() => createNewQuestion()} />;
    }

    return (
        <div className={classes.wrapper}>
            { buttonComp }
            <Button
                color="primary"
                className="u-mrg--tx2"
                onClick={goToSettings}
            >
                Assessment Settings
            </Button>
        </div>
    );
});

export default withRouter(withStyles(styles)(SidebarFooter));

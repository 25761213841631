import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import LoaderCalibration from 'components/loaders/LoaderCalibration';
import TTBuilderEvents from 'events/TTBuilderEvents';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { capitalizeEachFirstLetter, checkHasCompanyFeature } from 'helper/commonFunctions';
import QuestionWrapper from 'components/marketplace/Preview/common/QuestionWrapper';
import {
    replaceQuestionWithSuggestedQuestion,
    replaceScreeningQuestionWithSuggestedQuestion,
    getQuestionsBySkill, getSuggestedScreeningQuestions
} from 'requests/QuestionsRequests';
import SearchField from 'components/inputs/SearchField';

import { assessmentSettingsCtx } from 'pages/assessment/store';
import { assessmentEditCtx } from '../../../store';

import QuestionHeader from './QuestionHeader';
import NoResultsBanner from '../common/NoResultsBanner';
import QuestionGroupsDropdown from '../common/QuestionGroupsDropdown';
import styles from '../QuestionBanks/components/CommonContent/styles';

const PAGE_STEP = 10;
let timedId = null;

const SuggestedList = observer(({ classes, question, setActiveTab, isScreening, noQuestionsCallback = null }) => {
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [questionsList, setQuestionsList] = useState([]);
    const [loadingAddButton, setLoadingAddButton] = useState(false);
    const [questionGroup, setQuestionGroup] = useState({});
    const [loadingSaveButton, setLoadingSaveButton] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState();
    const [searchFocused, setSearchFocused] = useState(false);

    const {
        updateQuestion, assessmentHasTypingTest, activeQuestionIndex,
        questions: assessmentQuestions, selectQuestion
    } = useContext(assessmentEditCtx);

    const { loadEditScript, audition } = useContext(assessmentSettingsCtx);
    const { company } = useContext(appCtx);

    const { title: questionGroupName } = questionGroup;
    const { questionGroups = [], categoryName: auditionCategory } = audition;

    useEffect(() => {
        if (!questionGroups.length || isScreening) return;
        if (question.questionGroup && questionGroups.some(({ id }) => id === question.questionGroup.id)) {
            setQuestionGroup(question.questionGroup);
        } else {
            setQuestionGroup(questionGroups[0]);
        }
    }, [questionGroups.length, question]);

    useEffect(() => {
        setLoading(true);
    }, [questionGroupName]);

    useEffect(() => {
        if (!questionGroupName && !isScreening) return;
        setLoadingQuestions(true);
        clearTimeout(timedId);
        timedId = setTimeout(() => { loadQuestionsListByGroup(); }, 400);
    }, [currentPage, query, questionGroupName, isScreening]);


    const loadQuestionsListByGroup = (skill = questionGroupName, term = query) => {
        if (!skill && !isScreening) return;
        setLoadingQuestions(true);
        const promise = isScreening
            ? getSuggestedScreeningQuestions({ query: term, page: currentPage, offset: PAGE_STEP })
            : getQuestionsBySkill({ skill, term, page: currentPage, offset: PAGE_STEP, category: auditionCategory });

        promise
            .then(({ success, data }) => {
                if (success && data) {
                    const { hasMorePages, items } = data;

                    if (!items.length && noQuestionsCallback && !term) noQuestionsCallback();

                    setHasMore(hasMorePages);
                    setQuestionsList(currentPage === 1 ? items : questionsList.concat(items));
                }
            })
            .catch((error) => {
                if (error.response) {
                    const { status } = error.response;
                    if (status === 400 || status === 500) {
                        TTBuilderEvents.PREVIEW_VIEW_FAILED();
                    }
                }
            })
            .finally(() => {
                setLoading(false);
                setLoadingQuestions(false);
            });
    };

    const loadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleChangeQuestionGroup = (group) => {
        setQuestionsList([]);
        setCurrentPage(1);
        setQuestionGroup(group);
    };

    const onChangeSearch = (e) => {
        if (questionsList && questionsList.length) setLoadingQuestions(true);
        const { value } = e.target;
        setQuestionsList([]);
        setCurrentPage(1);
        setQuery(value);
    };

    const replaceFunc = newQuestion => (isScreening ? replaceScreeningQuestionWithSuggestedQuestion(newQuestion.id, question.id) : replaceQuestionWithSuggestedQuestion(newQuestion.uuid, question.id, { questionGroupName }));

    const hasFeature = checkHasCompanyFeature(company, 'UNLIMITED_QUESTIONS_PER_SWAP');
    const header = <h1 className={classes.title}>Suggested {isScreening ? 'Screening ' : ''}Questions</h1>;

    if (loading) {
        return (
            <div className={classes.wrapper}>
                {header}
                {
                    loading && (
                        <LoaderCalibration
                            className="u-mrg--tx20"
                            widthOuter={100}
                        />
                    )
                }
            </div>
        );
    }

    if (!questionGroups.length && !isScreening) {
        return (
            <div className={classes.wrapper}>
                {header}
                <NoResultsBanner
                    header="Add skills to assessment"
                />
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            {header}
            <div className={clsx(classes.searchBlock, isScreening && 'u-jc--end')}>
                {
                    !isScreening && (
                        <QuestionGroupsDropdown
                            setQuery={setQuery}
                            questionGroups={questionGroups}
                            setQuestionGroup={handleChangeQuestionGroup}
                            questionGroupId={questionGroup.id}
                            loadQuestionsListByGroup={loadQuestionsListByGroup}
                        />
                    )
                }
                <SearchField
                    className={clsx(classes.searchField, searchFocused && classes.searchFieldFocused)}
                    placeholder="Search"
                    onChange={onChangeSearch}
                    value={query}
                    onFocus={() => { setSearchFocused(true); }}
                    onBlur={() => { setSearchFocused(false); }}
                />
            </div>
            <div className={classes.previewContentWrapper}>
                {!loadingQuestions && !questionsList.length && query && (
                    <div className={classes.resultsLine}>
                        <span>{questionsList.length}</span> Results for <span>{`'${query}'`}</span>
                    </div>
                )}
                {!loadingQuestions && !questionsList.length && (
                    <NoResultsBanner
                        header={query
                            ? `We couldn't find a match for "${query}"`
                            : `We couldn't find any suggestions ${questionGroupName ? `for ‘${capitalizeEachFirstLetter(questionGroupName)}’` : ''}`
                        }
                        text={query
                            ? 'Check your spelling or try a new search'
                            : 'Try creating your own question'
                        }
                    />
                )}
                {
                    Boolean(questionsList.length) && (
                        <QuestionWrapper
                            QuestionHeaderComp={QuestionHeader}
                            replaceFunc={replaceFunc}
                            questions={questionsList}
                            interview={audition}
                            setActiveTab={setActiveTab}
                            selectQuestion={selectQuestion}
                            updateQuestion={updateQuestion}
                            activeQuestion={question}
                            assessmentQuestions={assessmentQuestions}
                            activeQuestionIndex={activeQuestionIndex}
                            loadingAddButton={loadingAddButton}
                            setLoadingAddButton={setLoadingAddButton}
                            loadingSaveButton={loadingSaveButton}
                            setLoadingSaveButton={setLoadingSaveButton}
                            assessmentHasTypingTest={assessmentHasTypingTest}
                            onReplace={loadEditScript}
                            questionGroupName={questionGroupName}
                            isScreening={isScreening}
                        />
                    )}
                {
                    Boolean(hasFeature && hasMore && questionsList.length) && (
                        <div className="u-txt--center u-mrg--tx1 u-mrg--bx2">
                            <Button
                                color="primary"
                                onClick={loadMore}
                                disabled={loadingQuestions}
                                variant="contained"
                            >
                                Load more questions
                            </Button>
                        </div>
                    )
                }
                <div className="u-txt--center u-mrg--bx2">
                    {
                        loadingQuestions && (
                            <CircularProgress
                                size={22}
                                thickness={3}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
});

export default withStyles(styles)(SuggestedList);

import React from 'react';
import { makeStyles } from '@mui/styles';
import ContentAccordion from 'libraries/ContentAccordion';
import Icon from './icon.svg';

const useStyles = makeStyles(() => ({
    accordion: {
        marginTop: '-30px !important',
        marginLeft: '-25px !important',
        marginRight: '-25px !important'
    },
    noBorder: {
        borderBottom: 'none !important'
    }
}));

const Transcript = ({ value }) => {
    const { accordion, noBorder } = useStyles();

    return (
        <ContentAccordion
            className={accordion}
            header={(
                <>
                    <img src={Icon} className="u-mrg--rx1" alt="" />
                    Transcript
                </>
            )}
            content={value}
            classes={{
                expansionPanelDetails: noBorder
            }}
        />
    );
};

export default Transcript;

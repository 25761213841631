export default theme => ({
    wrapper: {
        backgroundColor: theme.palette.grey[50],
        padding: '12px 21px 24px',
        zIndex: 100,
        position: 'absolute',
        left: -33,
        bottom: 0,
        width: 'calc(100% + 66px)',
        boxShadow: '0px -10px 9px rgba(41, 42, 45, 0.06)'
    },
    popoverRoot: {
        padding: 0,
        width: 360
    },
    popoverHeader: {
        padding: '11px 19px 5px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    popoverContent: {
        position: 'relative',
        padding: '15px 2px 12px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    popoverFooter: {
        padding: '15px 19px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    skillInput: {
        margin: 0,
        marginRight: 10
    },
    loaderWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

import React from 'react';
import { withStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import clsx from 'clsx';

import styles from './styles';

const TableCellCustom = ({ classes, className, children, ...other }) => (
    <TableCell
        className={clsx(classes.tableCell, className)}
        {...other}
    >
        {children}
    </TableCell>
);

export default withStyles(styles)(TableCellCustom);

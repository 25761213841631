import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route } from 'react-router-dom';
import * as qs from 'query-string';
import { appCtx } from '../../appStore';

const ProtectedRoute = observer(({ component: Component, location, ...other }) => {
    const { flashMessage } = useContext(appCtx);
    return (
        <Route
            {...other}
            render={props => (
                (sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')) || qs.parse(location.search).token
                    ? <Component location={location} {...props} flashMessage={flashMessage} />
                    : <Redirect to="/login" />
            )}
        />
    );
});

export default ProtectedRoute;

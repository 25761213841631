import { createContext } from 'react';
import { action, observable } from 'mobx';
import {
    changeAssessmentRequestStatus,
    getCustomAssessmentRequests,
    getFilterData
} from 'requests/CustomAssessmentRequests';
import { appStore } from '../../components/appStore';

class Store {
    @observable allRequests = [];

    @observable allStats = {};

    @observable totalCount = 0;

    @observable allCount = 0;

    @observable page = 1;

    @observable hasMore = false;

    @observable filterCategory = null;

    @observable filterLabel = '';

    @observable isLoading = true;

    @observable isLoadingLocal = false;

    @observable showRequestModal = false;

    @observable requestInfoId = null;

    @action onShowInfo = (id) => {
        this.showRequestModal = true;
        this.requestInfoId = id;
    }

    @action onHideInfo = () => {
        this.showRequestModal = false;
        this.requestInfoId = null;
    }

    @action loadRequests = () => {
        if (this.filterCategory == null) {
            this.filterCategory = localStorage.getItem('requestsFilterCategory') || 'all';
            this.filterLabel = localStorage.getItem('requestsFilterLabel') || '';
        }

        getCustomAssessmentRequests({ page: this.page, status: this.filterCategory })
            .then(({ success, data }) => {
                if (success && data) {
                    const { items, hasMorePages, total, currentPage } = data;
                    this.allRequests = items;
                    this.totalCount = total;
                    this.page = currentPage;
                    this.hasMore = hasMorePages;
                    this.allCount = total;
                    this.getFilterStats();
                }
            })
            .finally(() => {
                this.isLoading = false;
                this.isLoadingLocal = false;
            });
    };

    @action changeStatus = (id, status, callbackMessage = '') => {
        changeAssessmentRequestStatus(id, { status })
            .then(({ success }) => {
                if (success) {
                    this.loadRequests();
                    this.getFilterStats();
                    if (callbackMessage) {
                        appStore.flashMessage(callbackMessage);
                    }
                }
            });
    };

    @action getFilterStats = () => {
        getFilterData().then(({ success, data }) => {
            if (success) {
                this.allStats = data;
            }
        });
    };

    @action handleFilter = (value = 'all', label = '') => {
        this.filterCategory = value;
        this.filterLabel = label;
        this.page = 1;
        localStorage.setItem('requestsFilterCategory', value);
        localStorage.setItem('requestsFilterLabel', label);
        this.loadRequests();
    }

    @action handleChangePage = (page) => {
        this.page = page;
        this.isLoadingLocal = true;
        this.loadRequests();
    };

    @action cleanState = () => {
        this.allRequests = [];
        this.totalCount = 0;
        this.page = 1;
        this.hasMore = false;
        this.filterCategory = 'all';
        this.isLoading = true;
        this.isNoCandidates = false;
    }
}

export const customRequestsStore = new Store();
export const customRequestsStoreCtx = createContext(customRequestsStore);

export default theme => ({
    registrationPageHeader: {
        '@media only screen and (max-width: 767px)': {
            maxWidth: 275
        }
    },
    link: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        '&:hover, &:focus': {
            color: theme.palette.primary.dark
        }
    },
    invitationInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 25,
        marginTop: 30,
        alignItems: 'center'
    },
    companyLogo: {
        marginBottom: 21,
        fontSize: 30,
        fontWeight: 800,
        color: 'rgba(46, 54, 85, 0.9)'
    },
    wrapper: {
        '@media only screen and (max-width: 767px)': {
            paddingTop: 50
        }
    },
    contentWrapper: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'left',
        '@media only screen and (min-width: 768px)': {
            paddingTop: 50,
            paddingBottom: 20
        },
        '@media only screen and (max-width: 767px)': {
            minHeight: 'calc(100vh - 50px)'
        }
    },
    pageHeader: {
        zIndex: 3,
        position: 'absolute',
        width: '100%',
        right: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '35px 45px',
        '@media only screen and (max-width: 767px)': {
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
            padding: 15,
            position: 'fixed',
            backgroundColor: '#fff'
        }
    },
    logo: {
        lineHeight: 1.17,
        '@media only screen and (max-width: 767px)': {
            width: 80
        }
    },
    header: {
        maxWidth: 605,
        fontSize: 24,
        lineHeight: 1.1,
        textAlign: 'center',
        color: theme.palette.grey[900],
        fontWeight: 800,
        margin: '0 auto 20px',
        '@media only screen and (max-width: 767px)': {
            margin: '30px auto 24px',
            fontSize: 24,
            '& br': {
                display: 'none'
            }
        }
    },
    subheader: {
        marginBottom: 20,
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: 'normal',
        lineHeight: 1.5,
        textAlign: 'center',
        color: theme.palette.grey[700],
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media only screen and (max-width: 767px)': {
            fontSize: 14
        }
    },
    content: {
        width: '100%',
        fontWeight: 600,
        color: theme.palette.grey[900],
        '@media only screen and (min-width: 768px)': {
            maxWidth: 430,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    loginLabel: {
        textAlign: 'right',
        margin: '20px 0'
    },
    checkbox: {
        '& span': {
            fontWeight: 600
        }
    },
    showPasswordButton: {
        cursor: 'pointer',
        minWidth: 105,
        borderRadius: 4,
        backgroundColor: theme.palette.grey[200],
        borderLeft: `2px solid ${theme.palette.grey[300]}`,
        padding: '0px 35px 0px 20px',
        marginLeft: 0,
        height: 53.5,
        maxHeight: 53.5,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        color: theme.palette.grey[700],
        fontSize: 12
    },
    adornedEnd: {
        '& .MuiOutlinedInput-root': {
            paddingRight: '0px!important'
        }
    },
    showIcon: {
        marginRight: 6,
        marginBottom: 3
    },
    hideIcon: {
        marginRight: 8,
        marginBottom: 3
    }
});

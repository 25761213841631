export default theme => ({
    header: {
        color: theme.palette.grey[900],
        textTransform: 'uppercase',
        marginBottom: 28,
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '17px'
    },
    answerWrapper: {
        overflow: 'auto',
        minHeight: 100,
        paddingBottom: 70,
        maxHeight: 600,
        [theme.breakpoints.down('md')]: {
            maxHeight: 400
        }
    }
});

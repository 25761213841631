import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Switch from 'libraries/SwitchBrandWithLabel';
import { getCompanyAccount, loadCompany } from 'requests/APIrequests';
import { appCtx } from 'components/appStore';
import SettingsEvents from 'events/SettingsEvents';

const styles = theme => ({
    container: {
        borderBottom: '2px solid #F3F3F3',
        marginBottom: 40,
        paddingBottom: 25
    },
    switchFormLabel: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.grey[900]
    },
    description: {
        fontSize: 14,
        color: '#3A3C43'
    }
});

const SecuritySettings = observer(({ classes, location }) => {
    const [mfaValue, setMfaValue] = useState(false);
    const [pending, setPending] = useState(false);
    const { flashMessage, updateCompanyProperty } = useContext(appCtx);

    useEffect(() => {
        if (location.pathname.includes('team')) {
            loadCompany().then(({ data }) => {
                if (data.teamMembersMFA) {
                    updateCompanyProperty('teamMembersMFA', data.teamMembersMFA);
                    setMfaValue(data.teamMembersMFA);
                }
            });
        }
    }, [location]);

    const handleMFASwitch = (e) => {
        setPending(true);
        const { checked } = e.target;
        setMfaValue(checked);
        getCompanyAccount({ teamMembersMFA: checked }).then(({ success }) => {
            if (success) {
                flashMessage(`Multi-Factor Authentication ${checked ? 'enabled' : 'disabled'}`, 'done');
                SettingsEvents.MFA_ENABLED({ enabled: checked });
            }
        }).finally(() => setPending(false));
    };

    return (
        <div className={classes.container}>
            <Switch
                label="Enable Multi-Factor Authentication"
                classNameLabel={classes.switchFormLabel}
                checked={mfaValue}
                disabled={pending}
                onChange={handleMFASwitch}
            />
            <div className={classes.description}>
                When this setting is enabled, all team members are required to use Multi-Factor Authentication to login.
            </div>
        </div>
    );
});

export default withRouter(withStyles(styles)(SecuritySettings));

import { tracking } from 'helper/eventSegment';

export default {
    FEEDBACKS_EXPORTED: (data) => {
        tracking('TT-candidate-feedback-downloaded', data);
    },
    ACCOUNT_INSIGHTS_SHARED: () => {
        tracking('G-account-insights-shared');
    }
};

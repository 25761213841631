import React from 'react';
import clsx from 'clsx';

// material components
import { withStyles } from '@mui/styles';

// local files and components
import Tooltip from 'libraries/Tooltip';
import videoSvg from './svgWrappers/video';
import audioSvg from './svgWrappers/audio';
import imageSvg from './svgWrappers/image';
import codeSvg from './svgWrappers/code';
import fileSvg from './svgWrappers/file';
import styles from './styles';

const IconsBar = ({ classes, allowedLength, selectQuestionType, questionValue, screeningQuestion }) => {
    const characters = allowedLength - questionValue.toString().length;
    return (
        <div className={classes.iconsBar}>
            <div>
                {
                    allowedLength && (
                        <p
                            className={
                                clsx(
                                    classes.charactersHelper,
                                    characters <= 0 && classes.charactersHelperActive
                                )
                            }
                        >
                            {characters} characters remaining
                        </p>
                    )
                }
            </div>
            <div className="u-dsp--f u-ai--center">
                {
                    !screeningQuestion && (
                        <Tooltip label="Insert code for candidates to view">
                            {codeSvg(selectQuestionType, classes.svg)}
                        </Tooltip>
                    )
                }
                <Tooltip label="Insert image for candidates to view">
                    {imageSvg(selectQuestionType, classes.svg)}
                </Tooltip>
                <Tooltip label="Insert audio for candidates to listen to">
                    {audioSvg(selectQuestionType, classes.svg)}
                </Tooltip>
                <Tooltip label="Insert video for candidates to view">
                    {videoSvg(selectQuestionType, classes.svg)}
                </Tooltip>
                <Tooltip label="Insert a file for candidates to view">
                    {fileSvg(selectQuestionType, classes.svg)}
                </Tooltip>
            </div>
        </div>
    );
};

export default withStyles(styles)(IconsBar);

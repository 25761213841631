import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from 'img/download.svg';
import UpgradeToUnlockTooltip from 'components/tooltips/UpgradeToUnlockTooltip';
import { exportInconsistencyScoreCSV } from 'requests/ActiveLearningRequests';
import ExportingPopup from 'libraries/SnackbarProgress';
import { downloadCSV } from 'helper/commonFunctions';
import MiscellaneousEvents from 'events/MiscellaneousEvents';

const ExportInconsistentScoresButton = ({
    slug, hasCSVExport, scores, audition = {}
}) => {
    const [CSVPercents, setCSVPercents] = useState(0);
    const [dialog_exporting_error, setDialogExportingError] = useState(false);
    const [dialogExporting, setDialogExporting] = useState(false);

    const exportCSV = () => {
        setDialogExporting(true);
        exportInconsistencyScoreCSV(slug)
            .then(({ data: { csv } }) => {
                setCSVPercents(100);
                downloadCSV(csv, `Scores-${slug}`);
                setTimeout(() => {
                    setDialogExporting(false);
                }, 1000);
                setTimeout(() => {
                    setCSVPercents(0);
                }, 1500);
                MiscellaneousEvents.AI_HEALTH_SCORES_EXPORTED({
                    ttName: audition.name,
                    ttUUID: audition.uuid
                });
            })
            .catch(() => {
                setDialogExportingError(true);
                setTimeout(() => {
                    setDialogExporting(false);
                    setCSVPercents(0);
                }, 5000);
            });
    };

    return <>
        {hasCSVExport ? (
            <Button
                color="primary"
                onClick={() => exportCSV()}
                className="u-txt--bold"
                disabled={!scores?.length}
            >
                <img className="u-mrg--r/2" src={DownloadIcon} alt="download" />
                Download CSV
            </Button>
        ) : (
            <UpgradeToUnlockTooltip
                explanationText="The ability to export answers to CSV
                    is not available on the current plan."
                showTextToUnlock={false}
            >
                <Button
                    color="primary"
                    className="u-txt--bold"
                >
                    <img className="u-mrg--r/2" src={DownloadIcon} alt="download" />
                    Download CSV
                </Button>
            </UpgradeToUnlockTooltip>
        )}
        <ExportingPopup
            open={dialogExporting}
            message="Exporting answers to CSV…"
            error={dialog_exporting_error}
            percents={CSVPercents}
        />
    </>;
};

export default ExportInconsistentScoresButton;

import React from 'react';

import Dialog from 'libraries/Dialog';

// material components
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';

// local files and components
import styles from '../styles';

const DeleteTestCaseConfirmationDialog = ({
    open,
    handleClose,
    caseToDelete,
    removeTestCase,
    changeActiveCase,
    classes,
    question
}) => {
    const handleDeleteClick = () => {
        changeActiveCase(0);
        removeTestCase(caseToDelete, question.id);
        handleClose();
    };

    return (
        <Dialog
            open={open}
            scroll="paper"
            maxWidth="sm"
            handleClose={handleClose}
            titleComponent={`Delete ${caseToDelete && caseToDelete.type} test case`}
            actionComponent={(
            <>
                <Button
                    classes={{ root: classes.btnCancelDelete }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    classes={{ root: classes.btnDelete }}
                    onClick={handleDeleteClick}
                >
                    Delete test case
                </Button>
            </>
            )}
        >
        You are about to delete the {caseToDelete && caseToDelete.type} test case {`'${caseToDelete && caseToDelete.name}'`}. <br />
        Are you sure you want to delete this test case?
        </Dialog>
    );
};

export default withStyles(styles)(DeleteTestCaseConfirmationDialog);

import React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';

import QuestionResult from '../QuestionsContent';

const useStyles = makeStyles(() => ({
    resultsWrapper: {
        maxWidth: '100%',
        overflow: 'auto',
        width: '100%'
    }
}));


const SimpleGradeBlock = observer(({
    candidate, audition, updateUserAuditionAndBox,
    gradeableCard, propPublic, gotoQuestion, isIntegration,
    saveGrade, gradeLoading, canGrade, currentGrade, currentQuestion,
    fullName, questionResults, questionNumber, setGradeQuestion
}) => {
    const classes = useStyles();

    const goToNextQuestion = () => {
        gotoQuestion(questionNumber + 1);
    };

    const goToPreviousQuestion = () => {
        gotoQuestion(questionNumber - 1);
    };


    return (
        <div className={classes.resultsWrapper}>
            <QuestionResult
                userName={fullName}
                canGrade={canGrade}
                questionNumber={questionNumber}
                question={currentQuestion}
                currentGrade={currentGrade}
                {...{ gradeableCard,
                    propPublic,
                    isIntegration,
                    updateUserAuditionAndBox,
                    candidate,
                    audition,
                    questionCount: questionResults.length,
                    setGradeQuestion,
                    gradeLoading,
                    goToPreviousQuestion,
                    goToNextQuestion,
                    saveGrade
                }}
            />
        </div>
    );
});

export default SimpleGradeBlock;

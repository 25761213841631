import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        backgroundColor: '#fff',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    },
    leftPanel: {
        minWidth: 420,
        width: 420,
        paddingBottom: 50,
        backgroundColor: theme.palette.grey[50],
        position: 'relative',
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderRight: 'none',
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        }
    },
    contentPanel: {
        position: 'relative',
        padding: '26px 60px 40px',
        width: 'calc(100% - 400px)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '26px 30px 40px'
        }
    },
}));

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Select from 'libraries/Select';
import { VIDEO, AUDIO } from 'helper/constants';
import { checkPluralNew } from 'helper/commonFunctions';

const styles = {
    selectValuesSpan: {
        textTransform: 'lowercase'
    }
};

const selectValues = [1, 2, 3, 4, 5];

const RecordingTimeLimitInput = ({
    question, classes, changeQuestionSettings
}) => {
    const [limit, setLimit] = useState(3);
    const { answerType, id, questionSettings: { recordingTimeLimit } } = question;
    useEffect(() => {
        setLimit(recordingTimeLimit);
    }, [id, recordingTimeLimit]);

    const onChange = (e) => {
        const newValue = +e.target.value;
        setLimit(newValue);
        changeQuestionSettings({ recordingTimeLimit: newValue });
    };

    if (answerType !== VIDEO && answerType !== AUDIO) return null;

    return (
        <Select
            className="u-mrg--bx3"
            variant="outlined"
            value={limit}
            onChange={onChange}
            options={selectValues.map(value => ({
                label: (
                    <span className={classes.selectValuesSpan}>
                        {value} {checkPluralNew(value, 'minute')}
                    </span>
                ),
                value
            }))}
            label="Recording time limit (max)"
            placeholder="Recording time limit (max)"
            helperText="Set a recording time limit on your candidate's response. This is to avoid large file size and long answers."
        />
    );
};

export default withStyles(styles)(RecordingTimeLimitInput);

import React from 'react';
import { withStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import VervoeLogo from 'components/layout/Header/components/VervoeLogo';
import Icon from './icon.svg';

import styles from './styles';


const ExpiredLink = ({ classes }) => (
    <>
        <NavLink className={classes.vervoeLogo} to="/">
            <VervoeLogo />
        </NavLink>
        <div className={classes.content}>
            <img src={Icon} className={classes.icon} alt="" />
            <p className={classes.mainText}>The link has expired.</p>
            <div className={classes.additionalText}>
                <p>
                The link for the review has been expired. Contact the company admin or<br />try our&nbsp;
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://vervoe.zendesk.com/hc/en-us"
                        className={classes.link}
                    >
                    Help Centre
                    </a>
                </p>
            </div>
        </div>
    </>
);

export default withStyles(styles)(ExpiredLink);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    root: {
        position: 'relative',
        padding: '26px 33px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    wrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    hiddenBlock: {
        height: 18,
        borderRadius: 30,
        backgroundColor: theme.palette.grey[300],
        width: '85%',
        marginTop: 13,
        marginBottom: 5
    },
    hiddenBlockShorter: {
        width: '60%'
    }
}));

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { deleteAssessmentLink } from 'requests/AssessmentRequests';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import { appCtx } from 'components/appStore';

const DeleteLinkDialog = observer(({
    onClose, open,
    removeLink, linkId,
    slug
}) => {
    const { flashMessage } = useContext(appCtx);

    const deleteLinkForAssessment = () => {
        deleteAssessmentLink(slug, linkId)
            .then((data) => {
                removeLink(linkId, data);
                flashMessage('Share link deleted', 'done');
                onClose();
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            titleComponent="Confirm delete"
            actionComponent={(
                <>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        onClick={deleteLinkForAssessment}
                    >
                        Delete
                    </Button>
                </>
            )}
            open={open}
            handleClose={onClose}
        >
            Are you sure you want to delete this link?
        </Dialog>
    );
});

export default DeleteLinkDialog;

const styles = () => ({
    cases: {
        margin: 0,
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        fontSize: '16px'
    },
    errorCases: {
        color: '#FF5E5E'
    },
    casesFunctionality: {
        display: 'flex',
        flexDirection: 'column'
    },
    casesFunctionalityOpen: {
        marginTop: '20px'
    },
    runBtn: {
        border: '1px solid white',
        color: 'white',
        width: '200px',
        height: '40px',
        textTransform: 'none',
        fontSize: '14px',
        '&[disabled]': {
            opacity: 1,
            color: 'white'
        }
    },
    runningBtn: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    wrapperPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '0 20px',
        '@media only screen and (max-width: 767px)': {
            paddingRight: 20,
            marginTop: 15
        }
    },
    wrapperTestSection: {
        display: 'flex',
        alignItems: 'center'
    },
    formLabel: {
        color: 'white',
        opacity: 0.6,
        fontWeight: 600,
        fontSize: '14px'
    },
    checked: {
        '& + $bar': {
            opacity: '1 !important',
            backgroundColor: 'white !important'
        }
    },
    bar: {
        width: '34px',
        height: '18px',
        borderRadius: '9px',
        backgroundColor: '#b1b1b1'
    },
    icon: {
        width: '16px',
        height: '16px',
        backgroundColor: 'white',
        marginTop: '4px',
        marginRight: '2px'
    },
    iconChecked: {
        marginRight: '-1px',
        backgroundColor: '#b1b1b1',
        boxShadow: 'none'
    },
    loader: {
        marginLeft: 10,
        color: '#ffffff'
    }
});

export default styles;

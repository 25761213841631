import React from 'react';
import { withTheme } from '@mui/styles';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: ({ widthOuter }) => widthOuter,
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loaderWrapper: {
        width: 90,
        height: ({ widthOuter }) => widthOuter,
        transform: 'rotate(-180deg)'
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto'
    },
    loaderOuter: {
        animation: 'circle 2s linear infinite'
    },
    loaderInner: {
        animation: 'circle-counterclock-wise 2s linear infinite'
    }
}));

const Loader = ({ className, theme, widthOuter = 140, widthInner = widthOuter * 0.61 }) => {
    const classes = useStyles({ widthOuter });

    const commonProps = {
        path: {
            stroke: theme.palette.primary.main,
            strokeLinecap: 'butt'
        },
        trail: {
            stroke: '#e9e9e9'
        },
        text: {
            fontSize: 22,
            fontWeight: 'bold',
            fill: '#fff'
        }
    };

    return (
        <div className={clsx(classes.wrapper, className)}>
            <CircularProgressbar
                value={25}
                styles={{
                    root: {
                        width: widthOuter
                    },
                    ...commonProps
                }}
                className={clsx(classes.loader, classes.loaderOuter)}
            />
            <div className={classes.loaderWrapper}>
                <CircularProgressbar
                    value={25}
                    styles={{
                        root: {
                            width: widthInner
                        },
                        ...commonProps
                    }}
                    className={clsx(classes.loader, classes.loaderInner)}
                />
            </div>
        </div>
    );
};

export default withTheme(Loader);

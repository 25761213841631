import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import RetinaImage from 'react-retina-image';
import LogoGraphic from 'components/logos/logo_graphic';
import clsx from 'clsx';

const styles = {
    wrapper: {
        borderRadius: '50%',
        overflow: 'hidden',
        height: 22,
        minWidth: 22,
        width: 22,
        marginRight: 6
    },
    logo: {
        width: 22,
        minWidth: 22,
        maxHeight: '100%'
    }
};

const CompanyLogo = ({
    classes,
    theme,
    onClick,
    companyImage,
    companyName
}) => {
    if (companyImage && companyImage.length) {
        return (
            <div
                className={clsx(classes.wrapper)}
                onClick={onClick}
                role="presentation"
            >
                <RetinaImage
                    alt={companyName}
                    className={classes.logo}
                    src={companyImage}
                />
            </div>
        );
    }

    return (
        <LogoGraphic
            fill={theme.palette.primary.main}
            className={clsx('u-mrg--rx1', classes.logo)}
            width={22}
            height={22}
        />
    );
};

export default withTheme(withStyles(styles)(CompanyLogo));

export default () => ({
    tabsWrapper: {
        position: 'relative',
        width: '100%'
    },
    loaderWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1000,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
    }
});

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { requestExportCsvPerCompany, requestExportCsvHired } from 'requests/APIrequests';
import { checkHasUserPermission } from 'helper/commonFunctions';
import ExportSettingsForm from '../ExportSettingsForm';
import DialogWrapper from '../DialogWrapper';

import styles from './styles';
import { appCtx } from '../../../appStore';

const AllCandidatesExportDialog = observer(({
    classes, usersCount: usersCountProps,
    open, onClose, isHired
}) => {
    const { company, loggedUser } = useContext(appCtx);
    const { permissions } = loggedUser;
    const disabledExportScoring = !checkHasUserPermission(company, permissions, 'share');

    const exportCsv = (options, page) => requestExportCsvHired(page);

    return (
        <DialogWrapper
            open={open}
            onClose={onClose}
            isHired={isHired}
            usersCount={usersCountProps}
            requestExportCSVFunc={isHired ? exportCsv : requestExportCsvPerCompany}
            segmentEventContext={isHired ? 'hired candidates page' : 'all candidates page'}
            fileName={isHired ? 'Hired-candidates' : 'All-candidates'}
        >
            <div className="c-export__description">
                This will export {isHired ? 'hired' : 'all'} candidates in your system to a CSV.
            </div>
            {
                !isHired && (
                    <Accordion className={classes.expansionPanel}>
                        <AccordionSummary classes={{ root: classes.expansionPanelSummary, expandIcon: classes.expandIcon }} expandIcon={<ExpandMoreIcon />}>
                            Advanced Settings
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.expansionPanelDetails }}>
                            <ExportSettingsForm
                                disabledExportScoring={disabledExportScoring}
                                hideExportQuestions
                            />
                        </AccordionDetails>
                    </Accordion>
                )
            }
        </DialogWrapper>
    );
});

export default withStyles(styles)(AllCandidatesExportDialog);

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import {
    publishQuestion, unPublishQuestion,
    getAuditionSetsToPublish, getAuditionSetsToUnpublish,
    publishSet, unPublishSet, getAuditionsToUnpublishSet,
    getAuditionsToPublishSet
} from 'requests/CMSRequests';
import Checkbox from 'libraries/Checkbox';

import stylesJS from './styles';

const DropdownItem = ({ classes, checked, onChange, isDraft, name }) => (
    <Checkbox
        label={<>{name} {isDraft && <span className={classes.draftLabel}>(draft)</span>}</>}
        checked={checked}
        className="u-mrg--bx0"
        classNameLabel="u-txt--semibold"
        onChange={onChange}
    />
);

const ItemStatus = ({
    classes, className = '', flashMessage, isSet,
    isInteractive, uuid, status = '', setQuestion
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [statusLabel, setStatusLabel] = useState(status);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [auditionList, setAuditionList] = useState([]);
    const [setsList, setSetsList] = useState([]);
    const [setsToSend, setSetsToSend] = useState([]);
    const [auditionsToSend, setAuditionsToSend] = useState([]);

    const myRef = React.createRef();

    useEffect(() => {
        setStatusLabel(status || '');
    }, [status]);

    const setAnchor = () => {
        setAnchorEl(myRef.current);
    };

    const onHover = () => {
        if (!isInteractive) return;
        if (status === 'unpublished' || status === 'draft') {
            setStatusLabel('publish');
        }
        if (status === 'published') {
            setStatusLabel('unpublish');
        }
    };

    const onSubmit = () => {
        const dataToSend = {};
        let request = isSet ? publishSet : publishQuestion;
        if (statusLabel === 'publish') {
            dataToSend.setsToPublish = setsToSend;
            dataToSend.auditionsToPublish = auditionsToSend;
        } else {
            request = isSet ? unPublishSet : unPublishQuestion;
            dataToSend.setsToUnpublish = setsToSend;
            dataToSend.auditionsToUnpublish = auditionsToSend;
        }
        setIsLoadingSubmit(true);
        request(uuid, dataToSend)
            .then(({ success, data }) => {
                if (data && success) {
                    setQuestion(data);
                    handleClose();
                    setStatusLabel(data.status);
                    flashMessage(`Question ${isSet ? 'set ' : ''}${statusLabel === 'publish' ? '' : 'un'}published`, 'done');
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
            })
            .finally(() => {
                setIsLoadingSubmit(false);
            });
    };

    const onClick = () => {
        if (!isInteractive) return;
        setClicked(true);
        setAnchor();
        let request = isSet ? getAuditionsToUnpublishSet : getAuditionSetsToUnpublish;
        if (statusLabel === 'unpublish') {
            request = isSet ? getAuditionsToPublishSet : getAuditionSetsToPublish;
        }

        setIsLoading(true);
        request(uuid)
            .then(({ success, data }) => {
                if (success && data) {
                    if (isSet) {
                        setAuditionList(data);
                    } else {
                        setAuditionList(data.auditions);
                        setSetsList(data.sets);
                    }
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onUnHover = () => {
        if (!isInteractive) return;
        if (!clicked) {
            setStatusLabel(status);
        }
    };

    const handleClose = (e) => {
        if (e) e.stopPropagation();
        setAnchorEl(null);
        setClicked(false);
        setAuditionsToSend([]);
        setSetsToSend([]);
        setStatusLabel(status);
        setAuditionList([]);
        setSetsList([]);
    };

    const handleAuditions = (itemUuid) => {
        const index = auditionsToSend.indexOf(itemUuid);
        if (index === -1) {
            setAuditionsToSend([...auditionsToSend, itemUuid]);
        } else {
            const newAuditionsToSend = [...auditionsToSend];
            newAuditionsToSend.splice(index, 1);
            setAuditionsToSend(newAuditionsToSend);
        }
    };

    const handleSets = (itemUuid) => {
        const index = setsToSend.indexOf(itemUuid);
        if (index === -1) {
            setSetsToSend([...setsToSend, itemUuid]);
        } else {
            const newSetsToSend = [...setsToSend];
            newSetsToSend.splice(index, 1);
            setSetsToSend(newSetsToSend);
        }
    };

    return (
        <>
            <div
                onMouseOver={onHover}
                onFocus={onHover}
                onClick={onClick}
                onMouseLeave={onUnHover}
                role="presentation"
                ref={myRef}
                className={clsx(
                    classes.wrapper,
                    isInteractive && 'u-cursor--p',
                    (statusLabel === 'unpublished') && classes.wrapperUnpublished,
                    (statusLabel === 'published') && classes.wrapperPublished,
                    (statusLabel === 'draft') && classes.wrapperDraft,
                    className
                )}
            >
                {statusLabel}
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                classes={{
                    paper: `${classes.popoverPaper} ${className}`
                }}
                onClick={e => e.stopPropagation()}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Typography variant="h6" className="u-txt--cap u-mrg--bx1">
                    {
                        isSet ? (
                            <>{statusLabel} Assessments with set</>
                        ) : (
                            <>{statusLabel} Assessments and Sets with Question</>
                        )
                    }
                </Typography>
                {
                    isLoading ? (
                        <div className="u-txt--center">
                            <CircularProgress color="primary" size={23} />
                        </div>
                    ) : (
                        <>
                            {
                                Boolean(auditionList && auditionList.length) && (
                                    <>
                                        {
                                            !isSet && (
                                                <p className="u-mrg--bx0 u-txt--semibold u-txt--12">
                                                    Assessments:
                                                </p>
                                            )
                                        }
                                        {
                                            auditionList.map(({
                                                auditionSettings: { name },
                                                uuid: itemUuid,
                                                status: itemStatus,
                                                id
                                            }) => (
                                                <DropdownItem
                                                    classes={classes}
                                                    name={name}
                                                    checked={auditionsToSend.includes(itemUuid)}
                                                    onChange={() => { handleAuditions(itemUuid); }}
                                                    isDraft={itemStatus === 'draft'}
                                                    key={id}
                                                />
                                            ))
                                        }
                                    </>
                                )
                            }
                            {
                                Boolean(setsList && setsList.length) && (
                                    <>
                                        <p className="u-mrg--bx0 u-mrg--tx1 u-txt--semibold u-txt--12">Sets:</p>
                                        {
                                            setsList.map(({
                                                title,
                                                uuid: itemUuid,
                                                status: itemStatus,
                                                id
                                            }) => (
                                                <DropdownItem
                                                    classes={classes}
                                                    name={title}
                                                    key={id}
                                                    checked={setsToSend.includes(itemUuid)}
                                                    onChange={() => { handleSets(itemUuid); }}
                                                    isDraft={itemStatus === 'draft'}
                                                />
                                            ))
                                        }
                                    </>
                                )
                            }
                            {(!setsList.length && !auditionList.length) && (
                                <>No auditions {isSet ? '' : 'and sets '}to {statusLabel}</>
                            )}
                            <div className="u-txt--center u-mrg--tx2">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onSubmit}
                                    disabled={isLoadingSubmit}
                                    className="u-pdn--lx5 u-pdn--rx5 u-txt--cap"
                                >
                                    {
                                        isLoadingSubmit ? (
                                            <CircularProgress
                                                size={22}
                                                thickness={3}
                                            />
                                        ) : statusLabel
                                    }
                                </Button>
                            </div>
                        </>
                    )
                }
            </Popover>
        </>
    );
};

export default withStyles(stylesJS)(ItemStatus);

import React from 'react';

const InstructionsIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2941 21.3H6C5.28203 21.3 4.7 20.718 4.7 20V4C4.7 3.28203 5.28203 2.7 6 2.7H14.9363C15.2811 2.7 15.6117 2.83696 15.8555 3.08076L18.2134 5.4386C18.4572 5.6824 18.5941 6.01306 18.5941 6.35784V20C18.5941 20.718 18.0121 21.3 17.2941 21.3Z" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5884 2.5V6.70592H18.5001" stroke="#6B6B70" strokeWidth="1.4" strokeLinejoin="round" />
        <path d="M7.5293 10.5883H15.7646" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5293 13.9412H15.7646" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5293 17.2942H12.2352" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default InstructionsIcon;

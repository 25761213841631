import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import Popover from 'libraries/Popover';
import Tooltip from 'libraries/TooltipWhite';
import SetupGuideEvents from 'events/SetupGuideEvents';
import CompleteSvg from './complete.svg';
import ArrowSvg from './arrow.svg';

import { setupGuideCtx } from './store';

import styles from './styles';
import { appCtx } from '../../appStore';

const SetupGuide = observer(({ classes, history }) => {
    const {
        setSetting, loading, percentage,
        needInviteTM, needInvitations,
        needGrade, opportunityToInvite,
        opportunityToGrade, needJobWithAssessment,
        popover, setPopover, candidateCompletedNotScoredManually
    } = useContext(setupGuideCtx);
    const { company, loggedUser } = useContext(appCtx);
    const { roleType } = loggedUser;

    const DoneIcon = <img src={CompleteSvg} className={classes.listIcon} alt="✔" />;
    const ArrowIcon = <img src={ArrowSvg} className={classes.listIcon} alt="➡" />;

    const getItemClass = (condition) => {
        if (condition) {
            return classes.listItemActive;
        }
        return '';
    };

    const closePopover = () => {
        if (popover) {
            popover.handleClose();
        }
    };

    const onClickEvent = () => {
        SetupGuideEvents.GUIDE_CLICKED();
        setSetting();
    };

    const { active, freeTrialExpired, isSubscriptionPaused } = company;

    if (!active || freeTrialExpired || isSubscriptionPaused) return null;

    if (loading || (!loading && percentage === 100) || roleType === 'team') return null;

    const inviteButtonActive = needInvitations && opportunityToInvite;
    const gradeButtonActive = needGrade && opportunityToGrade && candidateCompletedNotScoredManually;

    return (
        <div>
            <Popover
                classNameRoot={classes.popoverRoot}
                className={classes.popover}
                onMounted={callbacks => setPopover(callbacks)}
                onClickEvent={onClickEvent}
                label={(
                    <Button className={classes.wrapper} disableRipple>
                        Setup Guide
                    </Button>
                )}
            >
                <ul className={classes.guide}>
                    <li>
                        <p className={classes.guideHeader}>
                            Setup Guide
                        </p>
                        <p className={classes.guideHeaderDesc}>
                            Set up your account easily by following the steps below.
                        </p>
                    </li>
                    <li
                        className={getItemClass(needJobWithAssessment)}
                        onClick={() => {
                            history.push('/marketplace');
                            closePopover();
                        }}
                    >
                        <p className={classes.guideTitle}>
                            Choose an assessment
                            { !needJobWithAssessment ? DoneIcon : ArrowIcon }
                        </p>
                        <p className={classes.guideDesc}>
                            Select a skill assessment to suit your needs based on a description of the role.
                        </p>
                    </li>
                    <li
                        className={getItemClass(inviteButtonActive)}
                        onClick={() => {
                            if (inviteButtonActive) {
                                closePopover();
                                SetupGuideEvents.INVITE_CANDIDATES_CLICKED();
                                history.push(`/script/invite/${opportunityToInvite}/invitation`);
                            }
                        }}
                    >
                        <p className={classes.guideTitle}>
                            Invite candidates
                            { !needInvitations && DoneIcon }
                            { inviteButtonActive && ArrowIcon }
                        </p>
                        <p className={classes.guideDesc}>
                            Invite candidates to take your skills assessment.
                        </p>
                    </li>
                    <li
                        className={getItemClass(needInviteTM)}
                        onClick={() => {
                            if (needInviteTM) {
                                closePopover();
                                SetupGuideEvents.INVITE_TEAM_CLICKED();
                                history.push('/team-settings', { openTMPopover: true });
                            }
                        }}
                    >
                        <p className={classes.guideTitle}>
                            Invite team members
                            { !needInviteTM ? DoneIcon : ArrowIcon }
                        </p>
                        <p className={classes.guideDesc}>
                            Invite members of your team to Vervoe, so they can create their own assessments, view and grade candidates.
                        </p>
                    </li>
                    <li
                        data-tip
                        data-for="setup_guide_grade"
                        className={getItemClass(gradeButtonActive)}
                        onClick={() => {
                            if (gradeButtonActive) {
                                closePopover();
                                SetupGuideEvents.GRADE_CLICKED();
                                history.push(`/script/select/${opportunityToGrade}`);
                            }
                        }}
                    >
                        <p className={classes.guideTitle}>
                            Grade candidates
                            { !needGrade && DoneIcon }
                            { gradeButtonActive && ArrowIcon }
                        </p>
                        <p className={classes.guideDesc}>
                            Grade completed candidate assessments to increase AI predictability.
                        </p>
                    </li>
                    {
                        needGrade && !opportunityToGrade && (
                            <Tooltip
                                id="setup_guide_grade"
                                position="top"
                                offset={{ top: -10 }}
                                className={classes.tooltip}
                            >
                                Sit tight, no candidates have completed an assessment yet.
                                You'll be able to grade candidates once they submit an assessment.
                            </Tooltip>
                        )
                    }
                </ul>
            </Popover>
        </div>
    );
});

export default withRouter(withStyles(styles)(SetupGuide));

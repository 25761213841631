import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    mainWrapper: {
        overflowX: 'auto'
    },
    category: {
        backgroundColor: theme.palette.grey[50],
        boxShadow: `5px 2px 13px 5px ${theme.palette.grey[100]}`,
        color: theme.palette.grey[900],
        borderRight: `2px solid ${theme.palette.grey[200]}`,
        fontSize: 14,
        fontWeight: 700,
        width: 130,
        minWidth: 130,
        textAlign: 'right',
        padding: '22px 21px',
        '&:not(:first-child)': {
            borderTop: `2px solid ${theme.palette.grey[200]}`
        }
    },
    candidateCategory: {
        height: 120,
        paddingTop: 40,
        borderTop: 'none',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    candidateHeaderName: {
        position: 'sticky'
    },
    leftPanel: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        borderRight: `2px solid ${theme.palette.grey[200]}`,
        fontSize: 14,
        fontWeight: 700,
        width: 130,
        minWidth: 130,
        textAlign: 'right'
    },
    wrapper: {
        height: 'calc(100vh - 140px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: ({ compareNumber }) => `${compareNumber * 435 + 120}px`,
        minWidth: ({ compareNumber }) => `${compareNumber * 435 + 120}px`
    },
    contentWrapper: {
        display: 'flex',
        minHeight: 'calc(229px + 66px + 220px)'
    },
    candidateHeader: {
        display: 'inline-block',
        position: 'sticky',
        width: 435,
        minWidth: 435,
        height: 120,
        padding: '43px 24px 10px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`,
        borderRight: `2px solid ${theme.palette.grey[200]}`
    },
    candidateWrapper: {
        position: 'relative',
        width: 435,
        minWidth: 435,
        padding: '17px 19px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`,
        borderRight: `2px solid ${theme.palette.grey[200]}`
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto'
    },
    dialogTitle: {
        '&:after': {
            display: 'none'
        },
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    }
}));

import React from 'react';
import { withTheme } from '@mui/styles';


const FilterIcon = ({ theme, color: propsColor, ...other }) => {
    const color = propsColor || theme.palette.grey[400];
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <ellipse cx="14.6667" cy="8.08329" rx="1.83333" ry="1.83333" transform="rotate(-180 14.6667 8.08329)" stroke={color} strokeWidth="1.2" />
            <ellipse rx="1.83333" ry="1.83333" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 7.33333 15.4167)" stroke={color} strokeWidth="1.2" />
            <path d="M1.83325 8.08337H12.8333" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.1667 15.4166H9.16675" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.5 8.08337L20.1667 8.08337" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.5 15.4166L1.83333 15.4166" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export default withTheme(FilterIcon);

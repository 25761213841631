import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { getCandidateCardInfo } from 'requests/AssessmentRequests';
import { appCtx } from 'components/appStore';
import CandidateCardWrapper from 'components/candidate_cards/CandidateCardWrapper';
import { getCandidatePosts, addCandidateComment } from 'requests/JobOpportunityRequests';
import { resultsCtx } from 'pages/assessment/results_assessment/store';
import { getCompanyTags } from 'requests/SettingsRequests';

const styles = {
    wrapper: {
        width: 'calc(100% + 60px)',
        margin: '0 -30px'
    }
};


const CandidatePage = observer(({ classes, match, history, opportunitySlug, audition }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [companyTags, setCompanyTags] = useState([]);
    const { company } = useContext(appCtx);
    const { candidateTabs, addCandidateTab, removeCandidateTab } = useContext(resultsCtx);

    const { user_id: userId, scriptSlug } = match.params;

    useEffect(() => {
        setIsLoading(true);
        setUser(null);
        getUserInfo();
        getTags();
    }, [userId]);

    const getTags = () => {
        getCompanyTags()
            .then(({ success, data }) => {
                if (success && data) {
                    setCompanyTags(data);
                }
            });
    };

    const handleCloseCard = () => {
        if (removeCandidateTab) removeCandidateTab(userId, scriptSlug);
        history.push(`/onboarding/select/${scriptSlug}`);
    };

    const getUserInfo = () => {
        getCandidateCardInfo(scriptSlug, userId)
            .then(({ success, data }) => {
                if (success) {
                    const { fullName, candidateId: id, candidateUuid } = data;
                    setUser({ ...data, uuid: candidateUuid });
                    const index = candidateTabs.findIndex(({ id: candidateId }) => candidateId === id);
                    if (index === -1) addCandidateTab(id, fullName, scriptSlug);
                }
            })
            .catch(() => {
                handleCloseCard();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (!user) return null;

    return (
        <CandidateCardWrapper
            className={classes.wrapper}
            user={user}
            setUser={setUser}
            company={company}
            isLoading={isLoading}
            getUserInfo={getUserInfo}
            handleCloseCard={handleCloseCard}
            opportunitySlug={opportunitySlug}
            audition={audition}
            context="onboarding candidate report card"
            companyTags={companyTags}
            getTags={getTags}
            setTags={setCompanyTags}
            loadCandidatePosts={getCandidatePosts}
            addCandidateComment={addCandidateComment}
        />
    );
});

export default withStyles(styles)(withRouter(CandidatePage));

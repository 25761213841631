import React from 'react';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const styles = () => ({
    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        margin: 'auto'
    }
});

const loaderStyle = {
    backgroundColor: 'rgba(100, 100, 100, 0.05)',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 100
};

const Loader = ({ classes, show, style = {}, className, size, thickness }) => (
    <div style={{ ...loaderStyle, ...style, display: show ? (style.display || '') : 'none' }}>
        <CircularProgress
            color="primary"
            size={size}
            className={`${classes.loader} ${className}`}
            thickness={thickness}
        />
    </div>
);

export default withStyles(styles)(Loader);

export default theme => ({
    search: {
        width: 366,
        height: 40,
        marginBottom: 30,
        marginTop: 30
    },
    searchField: {
        background: theme.palette.grey[200],
        border: `2px solid ${theme.palette.grey[200]}`,
        borderRadius: 4,
        margin: 0,
        minWidth: 370,
        color: theme.palette.grey[900]
    },
    searchFieldFocused: {
        background: '#fff',
        border: `2px solid ${theme.palette.primary.main}`
    }
});

import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { appCtx } from 'components/appStore';
import { observer } from 'mobx-react-lite';

import styles from './styles';

const TeamMembersStatistics = observer(({ classes, countTeamMembers = 0, countActiveMembers = 0, countPendingTeamMembers = 0 }) => {
    const { company } = useContext(appCtx);
    const { companySettings } = company;

    if (!companySettings) return null;
    const { teamMembers } = companySettings;

    const renderValue = value => (
        <span className={classes.categoryValue}>
            ({value})
        </span>
    );

    const totalCount = countActiveMembers + countPendingTeamMembers;

    return (
        <div className={classes.wrapper}>
            {
                teamMembers !== -1 && (
                    <div className={classes.category}>Team seats {renderValue(teamMembers)}</div>
                )
            }
            <div className={classes.category}>Active {renderValue(countTeamMembers)}</div>
            <div className={classes.category}>Pending {renderValue(countPendingTeamMembers)}</div>
            {
                teamMembers !== -1 && (
                    <div className={classes.category}>
                        Available Invitations {renderValue((teamMembers - totalCount) < 0 ? 0 : teamMembers - totalCount)}
                    </div>
                )
            }
        </div>
    );
});

export default withStyles(styles)(TeamMembersStatistics);

import React from 'react';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import clsx from 'clsx';
import CandidatePhotoEmail from 'components/candidate_cards/CandidateCardWrapper/CandidatePhotoInfo/CandidatePhotoEmail';

import useStyles from './styles';

const CandidatesListPublicCard = observer(({
    candidates, hideCandidateDetails, isCompare,
    selectedCandidateUuid, onChangePublicUser, toggleCompareMode
}) => {
    const classes = useStyles();

    return (
        <div>
            <Typography variant="h4" className={classes.header}>
                Candidates
            </Typography>
            <div
                role="presentation"
                className={clsx(classes.compareWrapper, isCompare && classes.compareWrapperActive)}
                onClick={toggleCompareMode}
            >
                Compare all Candidates
                <IconButton>
                    <ArrowForwardIosIcon className={classes.icon} />
                </IconButton>
            </div>
            <div className={classes.list}>
                {
                    candidates.map((candidate, index) => {
                        const isActive = candidate.uuid === selectedCandidateUuid && !isCompare;
                        return (
                            <div
                                key={candidate.uuid}
                                role="presentation"
                                onClick={() => onChangePublicUser(candidate.uuid)}
                                className={clsx(classes.wrapper, isActive && classes.selectedWrapper)}
                            >
                                {
                                    hideCandidateDetails ? (
                                        <div>
                                            <Typography variant="h6" className={clsx(!isActive && classes.itemNameInactive)}>Candidate {index + 1}</Typography>
                                            <div className={classes.hiddenBlock} />
                                        </div>
                                    ) : (
                                        <CandidatePhotoEmail
                                            hideCopyEvent
                                            photoSize={32}
                                            user={{ ...candidate, invited: !isActive }}
                                            classNameInfo={classes.item}
                                            classNameInfoName={clsx('u-txt--14', !isActive && classes.itemNameInactive)}
                                            classNameInfoEmail={clsx(classes.itemEmail, !isActive && classes.itemEmailInactive)}
                                            hideCandidateDetails={hideCandidateDetails}
                                        />
                                    )
                                }
                                <IconButton>
                                    <ArrowForwardIosIcon className={classes.icon} />
                                </IconButton>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
});

export default withRouter(CandidatesListPublicCard);

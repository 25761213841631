export default theme => ({
    wrapper: {
        marginTop: 120,
        fontSize: 16,
        marginBottom: 120,
        textAlign: 'center',
        '& button': {
            fontSize: 16
        }
    },
    iconStyles: {
        width: 60,
        height: 60
    },
    subheader: {
        fontWeight: 600,
        marginBottom: 22,
        color: theme.palette.grey[700]
    },
    header: {
        marginTop: 10,
        marginBottom: 15
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.grey[900],
        fontWeight: 700,
        '& button': {
            color: theme.palette.primary.main
        }
    },
    questionHeader: {
        marginTop: 34,
        paddingBottom: 22,
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    }
});

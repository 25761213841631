import React from 'react';
import TextInputOutlined from 'libraries/TextInput';

const TicketNumberField = ({ value, onChangeTicket }) => {
    const onChange = (e) => {
        onChangeTicket('ticketNumber', e.target.value);
    };

    return (
        <TextInputOutlined
            value={value}
            name="ticketNumber"
            variant="outlined"
            label="Ticket Number (optional)"
            onChange={onChange}
            fullWidth
            maxLength={255}
        />
    );
};

export default TicketNumberField;

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Masonry from 'react-masonry-component';
import AddImg from 'img/add.svg';

import ContentBoardItem from './ContentBoardItem';

import styles from './styles';
import { assessmentListCtx } from '../store';


const ContentAssessmentsView = observer(({
    classes, removeAssessment,
    changePage, query, handleCreateAssessment,
    handleDuplicate, getCategories
}) => {
    const {
        assessments, totalCount,
        loadingAssessments, loadingSearchAssessments,
        currentPage
    } = useContext(assessmentListCtx);

    const hasFoundAssessments = Boolean(assessments && assessments.length);
    const totalPages = Math.ceil(totalCount / 12);

    const returnNumberOfAssessments = () => {
        if (loadingAssessments || loadingSearchAssessments) return null;
        return (
            <>&nbsp;<span className={classes.countLabel}>({totalCount || 0})</span></>
        );
    };

    const handleNextButtonClick = () => {
        if (currentPage === totalPages) return;
        changePage(currentPage + 1);
    };

    if (loadingAssessments) return null;

    return (
        <>
            <div className={classes.wrapper}>
                <div className="u-dsp--distribute u-mrg--bx5">
                    {
                        query ? (
                            <Typography variant="h5" className={classes.listHeaderWrapper}>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                Assessments with '{query}' Skills{returnNumberOfAssessments()}
                            </Typography>
                        ) : (
                            <Typography variant="h5" className={classes.listHeaderWrapper}>
                                Assessments{returnNumberOfAssessments()}
                            </Typography>
                        )
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateAssessment}
                    >
                        Create Library Assessment
                    </Button>
                </div>
                <Masonry component="div" className={classes.mansoryWrapper}>
                    {
                        !((loadingAssessments || loadingSearchAssessments) && currentPage === 0)
                        && hasFoundAssessments
                        && assessments.map((item, index) => (
                            <ContentBoardItem
                                assessment={item}
                                key={`${item.slug}_${index}`}
                                opportunityIndex={index}
                                removeAssessment={() => removeAssessment(index)}
                                query={query}
                                handleDuplicate={handleDuplicate}
                                getCategories={getCategories}
                            />
                        ))
                    }
                </Masonry>
                {
                    !(loadingAssessments || loadingSearchAssessments) && (
                        <div className="u-txt--center u-mrg--bx5">
                            {
                                currentPage < totalPages && (
                                    <Button
                                        color="primary"
                                        onClick={handleNextButtonClick}
                                        className="u-txt--bold"
                                    >
                                        <img src={AddImg} className="u-mrg--rx1" alt="+" /> Load more
                                    </Button>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </>
    );
});

export default withRouter(withStyles(styles)(ContentAssessmentsView));

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing.unit / 2
    },
    chipRoot: {
        marginRight: '10px',
        background: 'white',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        marginBottom: '10px',
        '&:focus': {
            background: 'white'
        }
    },
    chipRootActive: {
        marginRight: '10px',
        background: '#436cff',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        marginBottom: '10px',
        '&:focus': {
            background: '#436cff'
        }
    },
    btnLink: {
        fontSize: '12px',
        color: '#9fa0a5',
        lineHeight: '14px',
        textTransform: 'none',
        textDecoration: 'underline',
        fontWeight: 'normal',
        padding: '0',
        minHeight: 'auto',
        marginLeft: '6px',
        '&:hover': {
            background: 'transparent'
        }
    },
    errorLanguage: {
        color: '#f02e2e',
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: 600
    },
    helperText: {
        fontSize: '12px',
        color: '#9fa0a5',
        fontFamily: 'Open Sans',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        marginTop: '30px',
        fontSize: 14,
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'Open Sans'
    },
    chipsWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14
    }
});

export default styles;

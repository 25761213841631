import React, { useState } from 'react';

// material components
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DocsIcon from 'components/icons/DocsIcon';
import BookmarkIcon from 'components/icons/BookmarkEmptyIcon';
import SimulatorTicketsContent from './SimulatorTicketsContent';
import SimulatorArticlesContent from './SimulatorArticlesContent';

import styles from './styles';

const SimulatorAnswerType = observer(({ classes, ...props }) => {
    const [selectedTabValue, setSelectedTabValue] = useState('tickets');
    if (!props.isCMS) return null;

    const handleChangeTabs = (event, newValue) => {
        setSelectedTabValue(newValue);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.tabsWrapper}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={selectedTabValue}
                    classes={{
                        root: classes.tabs,
                        indicator: classes.indicator,
                        scrollButtons: classes.scrollButtons,
                        flexContainer: classes.flexContainer
                    }}
                    onChange={handleChangeTabs}
                >
                    <Tab
                        label={<><BookmarkIcon /> Tickets</>}
                        value="tickets"
                        classes={{
                            root: classes.tab,
                            wrapper: classes.tabWrapper
                        }}
                    />
                    <Tab
                        label={<><DocsIcon /> Knowledge Base</>}
                        value="articles"
                        classes={{
                            root: classes.tab,
                            wrapper: classes.tabWrapper
                        }}
                    />
                </Tabs>
            </div>
            {
                selectedTabValue === 'tickets' && (
                    <SimulatorTicketsContent {...props} />
                )
            }
            {
                selectedTabValue === 'articles' && (
                    <SimulatorArticlesContent {...props} />
                )
            }
        </div>
    );
});

export default withStyles(styles)(SimulatorAnswerType);

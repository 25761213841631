const styles = theme => ({
    nextButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        paddingLeft: 40,
        paddingRight: 40,
        height: 50
    },
    root: {
        overflowX: 'hidden'
    },
    subHeading: {
        fontSize: 16,
        marginBottom: 12,
        color: theme.palette.grey[600]
    },
    actions: {
        padding: '20px 70px',
        [theme.breakpoints.down('md')]: {
            padding: '30px'
        }
    },
    loaderWrapper: {
        minHeight: 250
    },
    content: {
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    contentWrapper: {
        width: '100%',
        maxWidth: 800
    },
    title: {
        fontSize: 24,
        fontWeight: 800,
        color: theme.palette.grey[900],
        textAlign: 'center'
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.grey[500],
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 15
    },
    roleHighlight: {
        color: theme.palette.blue[600]
    },
    baseButton: {
        color: theme.palette.blue[800],
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        display: 'block',
        cursor: 'pointer',
        margin: '0 auto'
    },
    helperText: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.grey[500],
        textAlign: 'center',
        marginTop: 20
    },
    invisible: {
        opacity: 0,
        position: 'absolute',
        left: -9999
    },
    textField: {
        '& .MuiInputBase-root': {
            paddingBottom: 50
        }
    },
    textFieldUpload: {
        position: 'absolute',
        right: 20,
        bottom: 20,
        cursor: 'pointer',
        color: theme.palette.grey[400],
        fontSize: 14,
        '& > img': {
            marginLeft: 10
        }
    },
    successWrapper: {
        '& > img': {
            width: 64,
            height: 64
        },
        '& .MuiButtonBase-root': {
            color: theme.palette.blue[500],
            fontWeight: 700
        }
    },
    placeholder: {
        '& input:not(:disabled)': {
            '&::placeholder': {
                color: `${theme.palette.grey[500]}!important`
            }
        }
    },
    inputCounterLabel: {
        position: 'absolute',
        left: 14,
        bottom: 18,
        textAlign: 'left',
        opacity: 0.5,
        fontSize: 12
    }
});

export default styles;

import React from 'react';

const InfoIcon = ({ color = '#4a4b4f', className = '', ...other }) => (
    <svg className={className} width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.88365 13.6667C3.15894 13.6667 0.139465 10.6819 0.139465 7.00004C0.139465 3.31814 3.15894 0.333374 6.88365 0.333374C10.6084 0.333374 13.6278 3.31814 13.6278 7.00004C13.6278 10.6819 10.6084 13.6667 6.88365 13.6667ZM6.88365 12.5556C9.98758 12.5556 12.5038 10.0683 12.5038 7.00004C12.5038 3.93179 9.98758 1.44449 6.88365 1.44449C3.77973 1.44449 1.2635 3.93179 1.2635 7.00004C1.2635 10.0683 3.77973 12.5556 6.88365 12.5556ZM6.32164 6.17317C6.32164 5.86277 6.57326 5.61115 6.88365 5.61115C7.19405 5.61115 7.44567 5.86277 7.44567 6.17317V9.2158C7.44567 9.52619 7.19405 9.77782 6.88365 9.77782C6.57326 9.77782 6.32164 9.52619 6.32164 9.2158V6.17317ZM6.87719 3.66671C6.57037 3.66671 6.32164 3.91544 6.32164 4.22226C6.32164 4.52909 6.57037 4.77782 6.87719 4.77782H6.89011C7.19694 4.77782 7.44567 4.52909 7.44567 4.22226C7.44567 3.91544 7.19694 3.66671 6.89011 3.66671H6.87719Z"
            fill={color}
        />
    </svg>
);

export default InfoIcon;

export default theme => ({
    icon: {
        marginBottom: 20
    },
    dialogContent: {
        paddingTop: 70,
        paddingBottom: 60,
        textAlign: 'center',
        lineHeight: '28px'
    },
    subHeader: {
        color: theme.palette.grey[700],
        fontWeight: 600,
        marginBottom: 10
    },
    progressWrapper: {
        marginTop: '15px',
        padding: '10px 60px',
        textAlign: 'center'
    },
    linearProgressRoot: {
        marginBottom: '10px',
        height: 6,
        backgroundColor: theme.palette.grey[300]
    }
});

import React, { useContext } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/Tooltip';

// local files and component
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import HeaderIcon from './HeaderIcon';
import HeaderActions from './HeaderActions';
import styles from './styles';

const SidebarBoxHeader = observer(({
    question, classes,
    isActive, questionIndex,
    hasErrors, scoreType, className,
    isScreening
}) => {
    const { company } = useContext(appCtx);
    const { answerType, template, questionGroup } = question;
    const skillName = questionGroup ? questionGroup.title : '';
    const canEditQuestionsFromPublicLibrary = checkHasCompanyFeature(company, 'EDIT_QUESTIONS_FROM_PUBLIC_LIBRARY');
    const questionBlocked = question?.fromPublicLibrary && !canEditQuestionsFromPublicLibrary;
    const returnTypeImg = () => {
        if (!answerType) return null;
        return getQuestionTypeInfo(answerType, renderIconAndLabel);
    };

    const renderIconAndLabel = (Icon, label, labelShort = label) => (
        <Tooltip
            label={labelShort}
            tooltipClass={classes.tooltipClass}
        >
            {
                Icon && (
                    <span>
                        <Icon color="#fff" className={classes.svgIconStyle} />
                    </span>
                )
            }
        </Tooltip>
    );

    return (
        <div className={classes.header}>
            <div className={clsx(classes.questionLabel, !hasErrors && classes.questionLabelCorrect, isActive && classes.questionLabelActive, hasErrors && !template && classes.questionLabelError)}>
                Q{questionIndex + 1} {returnTypeImg()}
            </div>
            <HeaderIcon
                isTemplate={template}
                hasErrors={hasErrors}
                blocked={questionBlocked}
            />
            {
                skillName && (scoreType === 3) && (
                    <div className={classes.skillName}>
                        <TruncateWithTooltip
                            capitalize
                            text={skillName}
                            className="u-txt--up"
                            width={170}
                        />
                    </div>
                )
            }
            <HeaderActions
                isActive={isActive}
                questionIndex={questionIndex}
                question={question}
                className={className}
                isScreening={isScreening}
            />
        </div>
    );
});

export default withStyles(styles)(SidebarBoxHeader);

import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import Dialog from 'libraries/Dialog';
import TextInputOutlined from 'libraries/TextInput';
import { createSkill, updateSkill } from 'requests/CMSRequests';
import CircularProgress from '@mui/material/CircularProgress';
import { appCtx } from 'components/appStore';
import { MAX_SKILL_NAME_LENGTH } from 'helper/constants';

const EditSkillDialog = observer(({ onSuccess, skill = null, openedDialog, handleDialog }) => {
    const [loading, setLoading] = useState(false);
    const [skillName, setSkillName] = useState(skill ? skill.title : '');
    const [errorMessage, setErrorMessage] = useState('');
    const [skillDescription, setSkillDescription] = useState(skill ? skill.description : '');

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        if (openedDialog) {
            setSkillName(skill ? skill.title : '');
            setErrorMessage('');
            setSkillDescription(skill ? skill.description : '');
        } else {
            setSkillName('');
            setErrorMessage('');
            setSkillDescription('');
        }
    }, [openedDialog, skill]);


    const handleSkillName = (e) => {
        setSkillName(e.target.value);
        setErrorMessage('');
    };

    const submit = () => {
        setLoading(true);
        const promise = !skill ? createSkill(skillName, skillDescription) : updateSkill(skill.id, { title: skillName, description: skillDescription });
        promise
            .then(({ success, data }) => {
                if (success && data) {
                    handleDialog();
                    flashMessage(skill ? 'Skill updated' : 'New skill created', 'done');
                    if (onSuccess) onSuccess(data);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    setErrorMessage(err.response.data.msg);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSkillDescription = (e) => {
        setSkillDescription(e.target.value);
    };

    return (
        <Dialog
            onClose={handleDialog}
            handleClose={handleDialog}
            titleComponent={skill ? 'Edit Skill' : 'Create new skill'}
            maxWidth="sm"
            actionComponent={(
                    <>
                        <Button
                            className="u-txt--bold"
                            onClick={handleDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="u-pdn--rx3 u-pdn--lx3"
                            disabled={!skillName || !skillName.trim() || loading}
                            onClick={submit}
                        >
                            {
                                loading ? (
                                    <CircularProgress
                                        size={24}
                                        thickness={3}
                                    />
                                ) : 'Save'
                            }
                        </Button>
                    </>
            )}
            open={openedDialog}
        >

            <TextInputOutlined
                onChange={handleSkillName}
                value={skillName}
                variant="outlined"
                maxLength={MAX_SKILL_NAME_LENGTH}
                minLength={1}
                hasCounter
                placeholder="Enter your new skill name"
                label="Skill Name"
                isError={Boolean(errorMessage)}
                helperText={errorMessage}
            />
            <TextInputOutlined
                onChange={handleSkillDescription}
                value={skillDescription}
                variant="outlined"
                maxLength={500}
                hasCounter
                multiline
                minRows={4}
                placeholder="Enter the description for the skill"
                label="Skill Description"
            />
        </Dialog>
    );
});

export default EditSkillDialog;

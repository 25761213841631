import React from 'react';


const BookmarkIcon = ({ color = '#6B6B70', ...other }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M15.8334 7.5V18.3333L10.0001 13.7037L4.16675 18.3333V4.35185C4.16675 3.86071 4.34234 3.38968 4.6549 3.04239C4.96746 2.69511 5.39139 2.5 5.83341 2.5H12.9167" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 8.03125L9.61538 10L16.6667 2.5" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default BookmarkIcon;

import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import useStyles from './styles';

const InnerHeader = ({
    match, header, rightPart, history,
    tabList = [], stepsList = [], className = '',
    StepperComp = null, stepperProps = {}
}) => {
    const {
        wrapper, tabs, innerContainer,
        tab, headerClass, wrapperWithStepper
    } = useStyles({ length: stepsList.length });

    return <>
        <div className={clsx(wrapper, { [wrapperWithStepper]: Boolean(stepsList.length) }, className)} id="inner_header">
            <Container disableGutters>
                {
                    (header || rightPart) && (
                        <div className={innerContainer}>
                            <Typography variant="h2" className={headerClass}>
                                {header}
                            </Typography>
                            {rightPart}
                        </div>
                    )
                }
                {
                    Boolean(tabList.length) && (
                        <Tabs
                            className={tabs}
                            value={match.url}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons={false}
                        >
                            {
                                tabList.map(({ label, url, value = url, onClick, disabled = false }, index) => (
                                    <Tab
                                        selected
                                        value={value}
                                        className={tab}
                                        disableRipple
                                        key={index}
                                        label={label}
                                        onClick={() => { if (onClick) onClick(); history.push(url); }}
                                        disabled={disabled}
                                    />
                                ))
                            }
                        </Tabs>
                    )
                }
                { StepperComp && <StepperComp {...stepperProps} /> }
            </Container>
        </div>
    </>;
};

export default withRouter(InnerHeader);

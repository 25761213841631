import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import LoaderCalibration from 'components/loaders/LoaderCalibration';
import Pagination from 'components/board/board_pagination';
import BoardNoContent from 'components/board/board_no_content';
import AnswerContent from 'components/grade_components/AnswerContent';
import { getQuestionScores, getQuestionScoresStats } from 'requests/ActiveLearningRequests';
import EmptyIcon from 'img/add_to_assessments.svg';
import NoAnswerIcon from 'img/no_answer.svg';
import ScoresFilter from './ScoresFilter';
import TeamMemberScore from './TeamMemberScore';
import TagsList from './TagsList';
import ExportScoresButton from './ExportScoresButton';

import styles from './styles';


const ScoresBlock = ({
    classes, scoresSectionOpen, flashMessage,
    audition, audition: { slug }, questionHashUuid,
    questionNumber, hasCSVExport
}) => {
    const [loadingScores, setLoadingScores] = useState(false);
    const [page, setPage] = useState(1);
    const [filterSection, setFilterSection] = useState('all');
    const [filterFlagged, setFilterFlagged] = useState(0);
    const [filterStats, setFilterStats] = useState({});
    const [questionScores, setQuestionScores] = useState(false);

    useEffect(() => {
        if (scoresSectionOpen) {
            getStats().then(() => getScores());
        }
    }, [scoresSectionOpen]);

    useEffect(() => {
        getScores();
    }, [filterFlagged, filterSection, page]);

    const getScores = async () => {
        setLoadingScores(true);
        try {
            const { data } = await getQuestionScores(slug, questionHashUuid, page, filterSection, filterFlagged);
            setQuestionScores(data);
        } catch {
            flashMessage('Something went wrong', 'error');
        } finally {
            setLoadingScores(false);
        }
    };

    const getStats = async () => {
        try {
            const { data } = await getQuestionScoresStats(slug, questionHashUuid);
            setFilterStats(data);
        } catch {
            flashMessage('Something went wrong', 'error');
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
        const elemToScroll = document.getElementsByClassName('MuiDialogContent-root')[0];
        if (elemToScroll) {
            elemToScroll.scrollTop = 0;
        }
    };

    const { items, total } = questionScores;

    return (
        <div className={classes.wrapper}>
            <div className="u-dsp--f u-jc--sb u-ai--center u-mrg--bx2">
                <div className={classes.header}>
                    <span className="u-mrg--rx3">Grades</span>
                    <TagsList {...{
                        filterSection,
                        setFilterSection,
                        filterFlagged,
                        setFilterFlagged
                    }}
                    />
                </div>
                <div className="u-dsp--f">
                    {items?.length && (
                        <ExportScoresButton
                            hasCSVExport={hasCSVExport}
                            slug={slug}
                            scores={items}
                            audition={audition}
                        />
                    )}
                    <ScoresFilter {...{
                        filterStats,
                        filterFlagged,
                        setFilterFlagged,
                        filterSection,
                        setFilterSection,
                        setPage,
                        questionNumber
                    }}
                    />
                </div>
            </div>
            {loadingScores ? (
                <div className={classes.loaderWrapper}>
                    <LoaderCalibration />
                </div>
            ) : <>
                {items?.length ? items.map(item => (
                    <div className={classes.answerWrapper}>
                        <Typography variant="h6">Candidate Answer</Typography>
                        <div className={classes.answer}>
                            <AnswerContent
                                question={item}
                                NoAnswerComponent={() => <>
                                    <img src={NoAnswerIcon} alt="no_answer" className="u-mrg--rx2" />
                                    No candidate answer
                                </>}
                            />
                        </div>
                        <Typography variant="h6">Team Grades</Typography>
                        {item?.questionResultGrades?.map(
                            resultGrade => <TeamMemberScore {...{ ...resultGrade, flashMessage, filterStats, setFilterStats, audition, questionNumber }} />
                        )}
                    </div>
                )) : (
                    <BoardNoContent
                        header="No Grades"
                        hideIconWrapper
                        className={classes.boardNoContent}
                        icon={<img className={classes.boardImg} src={EmptyIcon} alt="empty-content" />}
                    />
                )}
                <Pagination
                    currentPage={page}
                    totalPages={Math.ceil(total / 12)}
                    handleChangePage={handleChangePage}
                />
            </>}
        </div>
    );
};

export default withStyles(styles)(ScoresBlock);

export default theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: 1020,
        width: '100%',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column-reverse'
        }
    },
    actionWrapper: {
        display: 'flex',
        gap: 40,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    nextButtonWrapper: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    nextButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        paddingLeft: 50,
        paddingRight: 50,
        height: 50,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            fontSize: 16,
            marginBottom: 20
        }
    },
    nextButtonDisabled: {
        opacity: 0.5
    },
    footerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '@media only screen and (max-width: 1250px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    tooltipHeader: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#1a1b1e',
        marginBottom: 10
    },
    tooltipLink: {
        display: 'block',
        marginTop: 15,
        textDecoration: 'underline',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'underline'
        }
    },
    tooltip: {
        width: '325px !important',
        '@media only screen and (max-width: 959px)': {
            display: 'none !important'
        }
    },
    popoverPaper: {
        maxWidth: 325,
        top: -30
    },
    loader: {
        position: 'absolute',
        top: 12,
        right: 17,
        color: '#fff',
        '& svg': {
            margin: 0
        }
    }
});

import React from 'react';
import { withStyles } from '@mui/styles';

import EmptyDataBlock from 'components/insight_components/insight_section_wrapper/EmptyDataBlock';
import CompletionImg from 'img/insight_completion.svg';
import AreaChart from './AreaChart';

const styles = {
    insightsChart: {
        position: 'relative',
        flexBasis: '100%',
        width: '100%',
        borderRadius: 6,
        backgroundColor: '#ffffff',
        padding: '30px 0 0',
        marginBottom: 8
    }
};

const CompletionsChart = ({ classes, tooltipLabel, value, audition }) => (
    value ? (
        <div
            className={classes.insightsChart}
        >
            <AreaChart
                value={value}
                tooltipLabel={tooltipLabel}
            />
        </div>
    ) : (
        <EmptyDataBlock
            customHeader="No completions on selected dates"
            imgSrc={CompletionImg}
            audition={audition}
            customEmptyLabel="Select another date range"
        />
    )
);

export default withStyles(styles)(CompletionsChart);

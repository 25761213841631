import React, { useEffect, useContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { withRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import { appCtx } from 'components/appStore';
import { removeTokensFromLocalStorage } from 'helper/commonFunctions';
import MobileAppBar from './components/MobileAppBar';
import DesktopAppBar from './components/DesktopAppBar';

import { setupGuideCtx } from '../SetupGuide/store';

const AppHeader = ({ history }) => {
    const { isLoadingCompany } = useContext(appCtx);
    const { setSetting } = useContext(setupGuideCtx);

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

    useEffect(() => {
        if (!isLoadingCompany) {
            setSetting();
        }
    }, [isLoadingCompany]);

    const logoutUser = () => {
        removeTokensFromLocalStorage();
        history.push('/login');
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            {
                isDesktop ? (
                    <DesktopAppBar logoutUser={logoutUser} />
                ) : (
                    <MobileAppBar logoutUser={logoutUser} />
                )
            }
        </Box>
    );
};

export default withRouter(AppHeader);

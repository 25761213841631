export default theme => ({
    table: {
        width: 'calc(100% - 35px)',
        margin: '40px auto 50px auto',
        minWidth: 'inherit'
    },
    tableCell: {
        borderRight: 'none !important'
    },
    tableCellName: {
        borderLeft: 'none !important'
    },
    title: {
        width: 'calc(100vw - 200px)',
        maxWidth: 1400
    },
    icon: {
        cursor: 'pointer',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    },
    loaderWrapper: {
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

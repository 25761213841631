import React from 'react';
import TextInputOutlined from 'libraries/TextInput';
import { returnHelperTextForSingleValueInput } from 'helper/codeChallenge';

const SingleValueInput = ({ type, paramIndex, value, onChangeValue }) => (
    <TextInputOutlined
        label="Value"
        fullWidth
        variant="outlined"
        value={value}
        onChange={e => onChangeValue(e.target.value, paramIndex)}
        helperText={returnHelperTextForSingleValueInput(type)}
    />
);

export default SingleValueInput;

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { goBackToAdmin } from 'requests/APIrequests';
import UploadUserPhotoAlt from 'components/photos/upload_user_photo_alt';
import { appCtx } from 'components/appStore';
import Popover from 'libraries/Popover';
import { checkHasCompanyFeature, objectLength } from 'helper/commonFunctions';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { PERMISSIONS, ASSESSMENT_BRANDING } from 'helper/constants';
import Photo from './Photo';

import useStyles from './styles';

const AccountDropdown = observer(({ logoutUser }) => {
    const {
        loggedUser, isLoadingCompany, company,
        flashMessage, updateLoggedUser
    } = useContext(appCtx);

    const [popover, setPopover] = useState(null);
    const {
        popoverRoot, button, nameSpan,
        popoverHeader, userInfo,
        userInfoName, menuList, menuItem,
        userInfoEmail, isLoadingUser
    } = useStyles();

    if (!objectLength(loggedUser)) return null;

    const { first_name, last_name, name, roleType } = loggedUser;
    const userName = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : name;

    const popoverMounted = (callbacks) => {
        setPopover(callbacks);
    };

    const onClose = () => {
        popover.handleClose();
    };

    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);
    const hasCompanyBranding = checkHasCompanyFeature(company, ASSESSMENT_BRANDING);
    const noAdminPermissions = ((roleType === 'team' && hasPermissionsFeature) || roleType === 'integration') && !isLoadingUser && !isLoadingCompany;
    const { freeTrialExpired, isSubscriptionPaused } = company;

    return (
        <Popover
            onMounted={callbacks => popoverMounted(callbacks)}
            className={popoverRoot}
            label={(
                <Button className={button} disableRipple disableElevation>
                    <span className={nameSpan}>{userName}</span>
                    <Photo
                        user={loggedUser}
                        size={36}
                    />
                </Button>
            )}
        >
            <div className={popoverHeader}>
                <UploadUserPhotoAlt
                    loggedUser={loggedUser}
                    updateLoggedUser={updateLoggedUser}
                    flashMessage={flashMessage}
                    size={54}
                    hasUpload
                />
                <div className={userInfo}>
                    <div className={userInfoName}>{loggedUser.first_name} {loggedUser.last_name}</div>
                    <div className={userInfoEmail}>{loggedUser.email}</div>
                </div>
            </div>
            <Divider />
            <nav>
                <MenuList className={menuList}>
                    <Link to="/my-account" id="qa-my-account">
                        <MenuItem className={menuItem} onClick={onClose} id="qa-li-mya">
                            My Account
                        </MenuItem>
                    </Link>
                    {
                        !noAdminPermissions && (
                            <Link to="/company-account" id="qa-company-account">
                                <MenuItem className={menuItem} onClick={onClose} id="qa-li-coa">
                                    Company Account
                                </MenuItem>
                            </Link>
                        )
                    }
                    {
                        !noAdminPermissions && hasCompanyBranding && (
                            <Link to="/branding-details" id="qa-notification-settings">
                                <MenuItem className={menuItem} onClick={onClose} id="qa-li-bra">
                                    Branding Details
                                </MenuItem>
                            </Link>
                        )
                    }
                    {
                        !noAdminPermissions && !freeTrialExpired && !isSubscriptionPaused && (
                            <Link to="/team-settings">
                                <MenuItem className={menuItem} onClick={onClose} id="qa-li-coa">
                                    Team
                                </MenuItem>
                            </Link>
                        )
                    }
                    <Link to="/notifications-settings" id="qa-notification-settings">
                        <MenuItem className={menuItem} onClick={onClose} id="qa-li-nts">
                            Notifications
                        </MenuItem>
                    </Link>
                    {
                        !noAdminPermissions && (
                            <>
                                <Link to="/billing" id="qa-subscription-management">
                                    <MenuItem className={menuItem} onClick={onClose} id="qa-li-smg">
                                        Current Plan and Billing Details
                                    </MenuItem>
                                </Link>
                                <Link to="/subscriptions">
                                    <MenuItem className={menuItem} onClick={onClose}>
                                        Plan Types
                                    </MenuItem>
                                </Link>
                                {
                                    !freeTrialExpired && !isSubscriptionPaused && (
                                        <Link to="/integrations-management" id="qa-integrations-management">
                                            <MenuItem className={menuItem} onClick={onClose} id="qa-li-jbm">
                                                Integrations
                                            </MenuItem>
                                        </Link>
                                    )
                                }
                            </>
                        )
                    }
                    <a href="https://vervoe.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer" id="qa-help">
                        <MenuItem className={menuItem} onClick={onClose} id="qa-li-hlp">
                            Help
                        </MenuItem>
                    </a>

                </MenuList>
                <Divider />
                <MenuList className={menuList}>
                    <MenuItem className={menuItem} onClick={logoutUser} id="qa-sign-out">
                        Sign out
                    </MenuItem>
                    {
                        loggedUser.backToAdmin
                        && <MenuItem className={menuItem} onClick={goBackToAdmin}>Back to Admin</MenuItem>
                    }
                </MenuList>
            </nav>
        </Popover>
    );
});

export default AccountDropdown;

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    contentPanel: {
        position: 'relative',
        minWidth: 550,
        padding: '26px 60px 40px',
        width: 'calc(100% - 400px)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '26px 30px 40px'
        }
    }
}));

import React, { PureComponent } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from 'libraries/Dialog';
import TextInputOutlined from 'libraries/TextInputValidated';
import { authGuestForPublicCard } from 'requests/LoginRequests';

const MAX_NAME_LENGTH = 160;
const MAX_EMAIL_LENGTH = 180;

class AuthDialog extends PureComponent {
    state = {
        fullName: null,
        email: null,
        isLoading: false
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('trimMaxNameLength', value => value.trim().length <= MAX_NAME_LENGTH);
        ValidatorForm.addValidationRule('trimMaxEmailLength', value => value.trim().length <= MAX_EMAIL_LENGTH);
    }

    authAndSave = () => {
        const { fullName, email } = this.state;
        const { saveValue, notSavedValue, closeDialog, flashMessage, authorizeGuest, comment } = this.props;
        this.setState({ isLoading: true });
        authGuestForPublicCard(email, fullName)
            .then((data) => {
                const { success, token } = data;
                if (success) {
                    localStorage.setItem('token', token);
                    saveValue(notSavedValue)
                        .then(() => {
                            if (authorizeGuest) authorizeGuest();
                            closeDialog();
                            if (!comment) {
                                flashMessage('Info saved! You can continue scoring responses.', '');
                            }
                        })
                        .catch((err) => {
                            if (err.response && err.response.data.msg) {
                                flashMessage(err.response.data.msg, 'error');
                            } else {
                                flashMessage('Something went wrong', 'error');
                            }
                        })
                        .finally(() => {
                            this.setState({ isLoading: false });
                        });
                }
            })
            .catch(() => {
                this.setState({ isLoading: false });
            });
    };

    renderValue = (value) => {
        if (this.state[value]) {
            return this.state[value];
        }
        return '';
    };

    handleChange = (e) => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        this.setState({
            [targetName]: targetValue
        });
    };

    validate = (event) => {
        this.refs[event.target.name].validate(event.target.value);
    };

    render() {
        const { open, closeDialog, candidateName, comment } = this.props;
        const { isLoading } = this.state;
        return (
            <Dialog
                open={open}
                maxWidth="sm"
                handleClose={closeDialog}
                titleComponent={<>Leave your feedback on {candidateName}</>}
                actionComponent={(
                    <>
                        <Button
                            onClick={closeDialog}
                            className="u-txt--bold"
                        >
                            Don&#39;t save my { comment ? 'comment' : 'score' }
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            form="savePublicScore"
                            type="submit"
                            disabled={isLoading}
                        >
                            {
                                isLoading ? (
                                    <CircularProgress size={22} />
                                ) : <>Save { comment ? 'comment' : 'score' }</>
                            }
                        </Button>
                    </>
                )}
            >
                <div>
                    <div>Your feedback will not be sent to the candidate.</div>
                    <div>To score Skills Assessments or leave a comment, please enter the following:</div>
                </div>
                <ValidatorForm
                    id="savePublicScore"
                    onSubmit={this.authAndSave}
                    instantValidate={false}
                >
                    <TextInputOutlined
                        ref="fullName"
                        name="fullName"
                        label="Your full name"
                        placeholder="e.g Mel Ming"
                        autoFocus
                        variant="outlined"
                        validators={['required', `trimMaxNameLength:${MAX_NAME_LENGTH}`]}
                        errorMessages={[
                            'Please enter your full name',
                            `Full name cannot be longer than ${MAX_NAME_LENGTH} characters.`
                        ]}
                        onBlur={this.validate}
                        onChange={this.handleChange}
                        value={this.renderValue('fullName')}
                    />
                    <TextInputOutlined
                        ref="email"
                        name="email"
                        label="Your work email"
                        placeholder="e.g mel@company.com"
                        validators={['required', 'isEmail', `trimMaxEmailLength:${MAX_EMAIL_LENGTH}`]}
                        errorMessages={[
                            'Please enter your work email',
                            'Please enter valid email',
                            `Maximum ${MAX_EMAIL_LENGTH} characters`
                        ]}
                        variant="outlined"
                        onBlur={this.validate}
                        onChange={this.handleChange}
                        value={this.renderValue('email')}
                    />
                </ValidatorForm>
            </Dialog>
        );
    }
}
export default AuthDialog;

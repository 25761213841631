import React, { useContext, useRef, useState } from 'react';
import RetinaImage from 'react-retina-image';
import { observer } from 'mobx-react-lite';
import Dropzone from 'react-dropzone';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { updateBrandingLogo } from 'requests/SettingsRequests';
import { appCtx } from 'components/appStore';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import SettingsEvents from 'events/SettingsEvents';
import { CircularProgress } from '@mui/material';


const styles = {
    dropzone: {
        outline: 'none',
        cursor: 'pointer',
        margin: '0 auto',
        width: 204,
        textAlign: 'center'
    },
    wrapper: {
        minHeight: 104,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 0 25px',
        textAlign: 'center'
    }
};

const UploadLogo = observer(({ classes, brandingSetUuid, logoLink, setBrandings, brandings, setSaving }) => {
    const [loading, setLoading] = useState(false);
    const { flashMessage } = useContext(appCtx);
    const dropzoneRef = useRef(null);

    const onOpenClickDropzone = (e) => {
        e.preventDefault();
        dropzoneRef.current.open();
    };

    const onDrop = (files) => {
        setSaving(true);
        setLoading(true);
        const formData = new FormData();
        if (!files[0]) return;
        formData.append('logo', files[0]);
        updateBrandingLogo(brandingSetUuid, formData)
            .then(({ success, data: { logoUrl, color } }) => {
                if (success) {
                    const newBrandings = brandings.map(brand => (brand.uuid === brandingSetUuid ? { ...brand, color, logoUrl } : brand));
                    setBrandings(newBrandings);
                    SettingsEvents.BRANDING_LOGO_ADDED();
                }
            })
            .catch(() => {
                flashMessage('Error: Invalid file type or size');
            })
            .finally(() => {
                setSaving(false);
                setLoading(false);
            });
    };

    return <>
        <div className="u-dsp--distribute">
            <div className="u-mrg--bx2">
                Logo
                <InfoTooltip
                    isBlack
                    placement="right"
                    text={<>Logo size will be reduced proportionally to fit the 40px height&nbsp;
                        <b>in the candidate experience.</b>&nbsp;
                        Logo should not include padding or coloured background.
                    </>}
                />
            </div>
            <Button
                color="primary"
                className="u-txt--bold"
                onClick={onOpenClickDropzone}
                disabled={!logoLink}
            >
                Change
            </Button>
        </div>
        <div className={classes.wrapper}>
            { loading
                ? (
                    <div
                        role="presentation"
                        style={{ height: 100, width: 200 }}
                    >
                        <CircularProgress className="u-mrg--tx3" />
                    </div>
                ) : <>
                    {logoLink.length ? (
                        <div className="c-upload-photo c-upload-photo_rectangle">
                            <div
                                role="presentation"
                                style={{ maxHeight: 100, maxWidth: 200 }}
                            >
                                <RetinaImage
                                    alt="logo"
                                    src={logoLink}
                                    style={{ maxHeight: 100, maxWidth: 200 }}
                                />
                            </div>
                            <Dropzone
                                className="hide"
                                ref={dropzoneRef}
                                accept="image/*"
                                multiple={false}
                                onDrop={onDrop}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    ) : (
                        <Dropzone
                            className="c-img-dropzone"
                            activeClassName="c-img-dropzone--active"
                            ref={dropzoneRef}
                            multiple={false}
                            onDrop={onDrop}
                            accept="image/*"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    className={classes.dropzone}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <i className="c-img-dropzone__icon icon-upload-cloud u-mrg--bx2" />
                                    <span className="c-img-dropzone__title">DRAG &amp; DROP</span><br />
                                    <span className="c-img-dropzone__txt">your logo to upload, or <span className="u-txt--underline  c-link">browse</span></span>
                                </div>
                            )}
                        </Dropzone>
                    )}
                </>
            }
        </div>
    </>;
});

export default withStyles(styles)(UploadLogo);

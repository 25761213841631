import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/TooltipWhite';
import Button from '@mui/material/Button';

import styles from '../footerStyles';

const FooterComponent = ({
    classes, disabledNext, setStep, currentStep,
    submitRequest, loading, closeDialog
}) => {
    const getNextButtonText = () => {
        switch (currentStep) {
            case 1:
                return 'Next: Role Name';
            case 4:
                return 'Submit Request';
            default:
                return 'Next';
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.actionWrapper}>
                <Button
                    color="primary"
                    variant="text"
                    className={classes.nextButton}
                    onClick={currentStep === 1 ? closeDialog : () => setStep(currentStep - 1)}
                    disabled={loading}
                >
                    {currentStep === 1 ? 'Cancel' : 'Back'}
                    {
                        loading && (
                            <CircularProgress
                                className={classes.loader}
                                size={25}
                                thickness={3}
                            />
                        )
                    }
                </Button>
                <div
                    data-tip
                    data-for="complete_all_steps"
                    role="presentation"
                    className={classes.nextButtonWrapper}
                >

                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.nextButton}
                        onClick={currentStep === 4 ? submitRequest : () => setStep(currentStep + 1)}
                        disabled={loading || disabledNext}
                    >
                        {getNextButtonText()}
                        {
                            loading && (
                                <CircularProgress
                                    className={classes.loader}
                                    size={25}
                                    thickness={3}
                                />
                            )
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(FooterComponent);

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import { ssoLogin } from 'requests/LoginRequests';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import VervoeIcon from './vervoeIcon.svg';
import SsoFailureScreen from './SsoFailureScreen';

import styles from './styles';

const MAX_EMAIL_LENGTH = 180;

const SsoLoginDialog = observer(({ open, onClose, classes }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [failureScreenOpen, setFailureScreenOpen] = useState(false);

    useEffect(() => {
        ValidatorForm.addValidationRule('trimMaxEmailLength', value => value.trim().length <= MAX_EMAIL_LENGTH);
    }, []);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleClose = () => {
        onClose();
        setTimeout(() => { setFailureScreenOpen(false); setEmail(''); }, 300);
    };

    const login = () => {
        setLoading(true);
        ssoLogin(email)
            .then(({ data, success }) => {
                if (data && success) window.location = data.redirectUrl;
                handleClose();
            })
            .catch(() => setFailureScreenOpen(true))
            .finally(() => setLoading(false));
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            handleClose={handleClose}
            contentClassName={classes.dialog}
        >
            {failureScreenOpen
                ? <SsoFailureScreen {...{ handleClose }} />
                : <>
                    <img src={VervoeIcon} alt="V" className="u-pdn--x4" />
                    <h1 className={classes.heading}>Sign in to your account with SSO</h1>
                    <div className={classes.container}>
                        <ValidatorForm
                            id="ssoLogin"
                            onSubmit={login}
                            instantValidate={false}
                        >
                            <TextValidator
                                autoFocus
                                className={classes.input}
                                name="email"
                                label="Enter your email"
                                validators={['required', 'isEmail', `trimMaxEmailLength:${MAX_EMAIL_LENGTH}`]}
                                errorMessages={[
                                    'Please enter your email',
                                    'Please enter valid email',
                                    `Maximum ${MAX_EMAIL_LENGTH} characters`
                                ]}
                                variant="outlined"
                                onChange={handleChange}
                                value={email}
                            />
                            <Button
                                color="primary"
                                className={classes.button}
                                variant="contained"
                                disabled={loading}
                                type="submit"
                            >
                                Next
                            </Button>
                            <div className={classes.bottomText}>
                                Don't have an SSO account?&nbsp;
                                <span role="presentation" className={classes.goBack} onClick={handleClose}>
                                Go back
                                </span>
                            </div>
                        </ValidatorForm>
                    </div>
            </>
            }
        </Dialog>
    );
});

export default withStyles(styles)(SsoLoginDialog);

import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import styles from './styles';

const ConfirmWrapper = ({
    subscriptionPlan, classes, onClose, productPrice,
    subscribeSubscription, disableGetVervoe, isCurrentPlanSelected
}) => {
    const { name: planName, period } = subscriptionPlan;
    let interval = '';
    if (!productPrice) {
        interval = period === 'monthly' ? '(monthly)' : '(annually)';
    }

    const buyProductWithoutChangingPlan = productPrice && isCurrentPlanSelected;

    return (
        <div className={classes.questionWrapper}>
            {!buyProductWithoutChangingPlan && (
                <>
                    <div>Ready to change your plan to</div>
                    <div><b>{planName} {interval}</b>?</div>
                </>
            )}
            <div className={classes.buttonWrapper}>
                {!buyProductWithoutChangingPlan && (
                    <Button
                        onClick={onClose}
                        className="u-txt--bold u-mrg--rx3"
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    color="primary"
                    variant="contained"
                    label="Change Plan"
                    className="u-txt--bold"
                    disabled={disableGetVervoe}
                    onClick={subscribeSubscription}
                >
                    {!buyProductWithoutChangingPlan ? 'Change Plan' : 'Place your order'}
                </Button>
            </div>
        </div>
    );
};

export default withStyles(styles)(ConfirmWrapper);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    content: {
        width: 'calc(100% - 55px)',
        flexGrow: 1,
        margin: 0,
        border: 'none',
        padding: '35px 40px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: 0,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    wrapper: {
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        minHeight: 400,
        border: `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
        marginTop: 32,
        marginBottom: 36,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'block',
            paddingLeft: ({ hasDrawer }) => hasDrawer ? 55 : 0
        }
    },
    contentWrapper: {
        display: 'flex',
        minHeight: 550,
        overflowX: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    contentShift: {
        width: 'calc(100% - 190px)',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: '0 !important',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    loaderWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 100,
        height: '100%',
        margin: 'auto'
    },
    headerClassName: {
        marginBottom: -32
    }
}));

import React from 'react';
import clsx from 'clsx';

import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import PageHeader from './PageHeader';
import InfoPanel from '../../board/board_info_panel';
import ContentWrapper from './ContentWrapper';

const PageWrapper = ({
    children, headerLabel,
    headerActions, loading,
    headerClassName, className
}) => (
    <Container className={clsx('u-pdn--bx3 u-mrg--tx8', className)}>
        <PageHeader
            headerClassName={headerClassName}
            headerLabel={headerLabel}
            headerActions={headerActions}
        />
        <InfoPanel />
        {
            loading ? (
                <ContentWrapper loading>
                    <CircularProgress />
                </ContentWrapper>
            ) : (
                children
            )
        }
    </Container>
);

export default PageWrapper;

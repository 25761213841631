import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteAssessmentFromJobAdder } from 'requests/SubscriptionRequests';

const styles = () => ({
    actionButton: {
        fontWeight: 700,
        paddingRight: 20,
        paddingLeft: 20
    },
    deleteButton: {
        paddingRight: 40,
        paddingLeft: 40,
        fontWeight: 700,
        minWidth: 213
    },
    loader: {
        color: '#fff'
    }
});

const DeleteAssessmentDialog = ({ slug, setJobAdderAssessmentShow, onClose, classes, open, flashMessage }) => {
    const [loading, setLoading] = useState(false);

    const deleteFromJobAdder = (e) => {
        if (e) e.stopPropagation();
        setLoading(true);
        deleteAssessmentFromJobAdder(slug)
            .then(() => {
                flashMessage('Assessment deleted from JobAdder', 'done');
                setJobAdderAssessmentShow(true);
            })
            .catch((err) => {
                if (err.response && err.response.status === 418) {
                    flashMessage('Not authorized in JobAdder', 'error');
                } else {
                    flashMessage('Something went wrong', 'error');
                }
            })
            .finally(() => {
                setLoading(false);
                onClose();
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            titleComponent="Delete Assessment from JobAdder"
            actionComponent={(
                    <>
                        <Button
                            color="primary"
                            className={classes.actionButton}
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            className={classes.deleteButton}
                            onClick={deleteFromJobAdder}
                        >
                            {
                                loading ? (
                                    <CircularProgress
                                        size={24}
                                        thickness={3}
                                        classes={{ root: classes.loader }}
                                    />
                                ) : 'Delete Assessment'
                            }
                        </Button>
                    </>
            )}
            open={open}
            onClose={onClose}
            handleClose={onClose}
        >
                Are you sure you want to delete this assessment from JobAdder? <br /> This action is not recoverable and all data relevant to this assessment will be lost in JobAdder.
        </Dialog>
    );
};

export default withStyles(styles)(DeleteAssessmentDialog);

import React from 'react';

import { withStyles } from '@mui/styles';

import downloadIcon from 'img/icons/download.svg';
import styles from './styles';

const FileQuestion = ({ file, classes, brandColor }) => {
    if (!file) return null;
    const { fileName, fileUrl } = file;
    const mediaFullPath = process.env.REACT_APP_API_URL + fileUrl;
    return (
        <div className={classes.fileWrapper}>
            <div className={classes.content}>
                <img src={downloadIcon} alt="" />
                <span className={classes.fileName}>{fileName}</span>
                <a href={mediaFullPath} className={classes.customButton} style={{ color: brandColor }}>
                    Download file
                </a>
            </div>
        </div>
    );
};

export default withStyles(styles)(FileQuestion);

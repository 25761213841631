import React from 'react';
import { withStyles } from '@mui/styles';
import AnswerContent from 'components/grade_components/AnswerContent';

const styles = theme => ({
    codeWrapper: {
        pointerEvents: 'none'
    },
    header: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 13,
        color: theme.palette.grey[900]
    }
});

const AnswerType = ({ classes, ...other }) => <>
    <h3 className={classes.header}>Candidate Answer</h3>
    <AnswerContent {...other} />
</>;

export default withStyles(styles)(AnswerType);

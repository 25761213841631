import React from 'react';
import { makeStyles } from '@mui/styles';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles(theme => ({
    editor: {
        border: '1px solid',
        borderColor: theme.palette.grey[400],
        margin: '10px 35px',
        backgroundColor: 'white',
        padding: '0 20px',
        minHeight: '500px'
    },
    wrapper: {
        backgroundColor: theme.palette.grey[100],
        paddingBottom: '10px'
    }
}));

const RichTextEditorAnswer = ({ content }) => {
    const { editor, wrapper } = useStyles({});
    const richTextEditorToolbarSettings = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove']
    };
    const richTextEditorContent = content ? EditorState.createWithContent(convertFromRaw(JSON.parse(content))) : EditorState.createEmpty();

    return (
        <Editor
            toolbar={richTextEditorToolbarSettings}
            editorState={richTextEditorContent}
            wrapperClassName={wrapper}
            editorClassName={editor}
            readOnly
        />
    );
};

export default RichTextEditorAnswer;

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TableCellCustom from 'components/table_components/table_cell';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import Select from 'libraries/Select';
import Dialog from 'libraries/Dialog';

import {
    getLeverPostingStages, linkLeverStageToAssessment,
    unlinkLeverStageToAssessment, getLeverAssessments
} from 'requests/SubscriptionRequests';


const styles = () => ({
    select: {
        width: 350,
        minWidth: 350,
        marginLeft: 15,
        '@media (max-width: 795px)': {
            marginLeft: 0,
            marginTop: 10,
            width: '100%'
        }
    },
    assessmentRow: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 795px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    }
});

const Posting = ({ classes, posting, flashMessage, loadPostings }) => {
    const [opened, setOpened] = useState(false);
    const [allStages, setAllStages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [assessments, setAssessments] = useState([]);
    const [loadingStagesChanges, setLoadingStagesChanges] = useState({});
    const { postingId, postingName, stages, auditions } = posting;

    useEffect(() => {
        if (!opened) return;
        setLoading(true);
        getLeverAssessments()
            .then(({ data }) => {
                if (data) {
                    setAssessments(data);
                }
            });
        getLeverPostingStages(postingId)
            .then(({ data }) => {
                if (data) {
                    setAllStages(data);
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [opened]);


    const setAssessmentForStage = (index, slug) => {
        const { stageId } = allStages[index];
        setLoadingStagesChanges(({ loadingStagesChanges: prevLoadingStagesChanges }) => ({ ...prevLoadingStagesChanges, [stageId]: true }));
        const request = slug.trim() ? linkLeverStageToAssessment(slug, postingId, stageId) : unlinkLeverStageToAssessment(postingId, stageId);
        request
            .then(() => {
                const newStages = allStages.slice();
                newStages[index].auditionSlug = slug;
                setAllStages(newStages);
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
            })
            .finally(() => {
                setLoadingStagesChanges(({ loadingStagesChanges: prevLoadingStagesChanges }) => ({ ...prevLoadingStagesChanges, [stageId]: false }));
            });
    };

    const handleDialog = () => {
        const newValue = !opened;
        setOpened(newValue);
        if (!newValue) {
            setAllStages([]);
            loadPostings();
        }
    };

    const assessmentOptions = assessments.map(({ name, slug }) => ({
        value: slug, label: name
    }));
    assessmentOptions.unshift({ value: ' ', label: 'No assessment selected' });

    return (
        <TableRow>
            <TableCellCustom className={classes.tableCell} align="left">
                {postingName}
            </TableCellCustom>
            <TableCellCustom className={classes.tableCell} align="left">
                {
                    stages.length ? (
                        stages.map(name => <TruncateWithTooltip text={name} width={200} />)
                    ) : '-'
                }
            </TableCellCustom>
            <TableCellCustom className={classes.tableCell}>
                {
                    auditions.length ? (
                        auditions.map(name => <TruncateWithTooltip text={name} width={320} />)
                    ) : '-'
                }
            </TableCellCustom>
            <TableCellCustom className={classes.tableCell} align="right">
                <Button color="primary" onClick={handleDialog}>
                    Edit
                </Button>
                <Dialog
                    open={opened}
                    titleComponent={`Edit ${postingName} posting`}
                    onClose={handleDialog}
                    handleClose={handleDialog}
                >
                    {
                        loading && (
                            <div className="u-txt--center">
                                <CircularProgress size={30} />
                            </div>
                        )
                    }
                    {
                        allStages.map(({ stageId, stageName, auditionSlug }, index) => (
                            <div className={classes.assessmentRow}>
                                <div>{stageName}</div>
                                <div className="u-dsp--f u-ai--center">
                                    {
                                        loadingStagesChanges[stageId] && (
                                            <CircularProgress className="u-mrg--rx2" size={18} />
                                        )
                                    }
                                    <Select
                                        margin="none"
                                        variant="outlined"
                                        value={auditionSlug || ' '}
                                        className={classes.select}
                                        onChange={e => setAssessmentForStage(index, e.target.value)}
                                        options={assessmentOptions}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </Dialog>
            </TableCellCustom>
        </TableRow>
    );
};

export default withStyles(styles)(Posting);

import React from 'react';

import Dialog from 'libraries/Dialog';
import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';

import addCode from 'img/candidateCard/addcode.svg';
import styles from '../styles';

const ReviewTheFollowingDialog = ({
    open, handleClose, classes,
    saveCode, setActiveLanguage,
    closeCodeChallengeModal,
    codeChallengeQuestions
}) => {
    const showEmptyField = () => {
        const questionWithEmptyFields = codeChallengeQuestions
            .find(({ header, body, footer }) => !body.trim() && !header.trim() && !footer.trim());
        setActiveLanguage(questionWithEmptyFields.language);
        handleClose();
    };

    const handleSaveAndContinue = () => {
        saveCode();
        handleClose();
        closeCodeChallengeModal();
    };

    return (
        <Dialog
            open={open}
            scroll="paper"
            maxWidth="md"
            handleClose={handleClose}
            titleComponent="Please review the following:"
            actionComponent={(
                <>
                    <Button
                        className={classes.btnNoGraded}
                        onClick={handleClose}
                    >
                        Back to edit code
                    </Button>
                    <Button
                        className={classes.btnAdd}
                        variant="contained"
                        color="primary"
                        onClick={handleSaveAndContinue}
                    >
                        Save and continue
                    </Button>
                </>
            )}
        >
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginRight: 26 }}>
                    <img src={addCode} alt="" />
                </div>
                <div className={classes.rightSide}>
                    <h2>Make sure you&apos;ve added default code to all languages</h2>
                    <p>We recommend you supply the same starting point in every language allowed. </p>
                    <Button
                        color="primary"
                        onClick={showEmptyField}
                        classes={{ root: classes.btnAddDefaultCode }}
                    >
                        Add default code to other languages >
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default withStyles(styles)(ReviewTheFollowingDialog);

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

// material components
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { evaluateSpreadsheetTestCase } from 'requests/QuestionsRequests';

import { appCtx } from 'components/appStore';

import styles from './styles';

const CheckButton = observer(({ classes, questionId, testCase, setActualResult, isCMS }) => {
    const [checking, setChecking] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [failed, setFailed] = useState(false);

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setChecking(false);
        setSuccessful(false);
        setFailed(false);
        setActualResult(null);
    }, [testCase]);

    const onClick = () => {
        setChecking(true);
        evaluateSpreadsheetTestCase(questionId, testCase, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    const { testPassed, actualResult } = data;
                    if (testPassed) {
                        setSuccessful(true);
                        setFailed(false);
                    } else {
                        setFailed(true);
                        setSuccessful(false);
                        setActualResult(actualResult);
                    }
                }
            })
            .catch((err) => {
                flashMessage(err.response.data && err.response.data.errors ? err.response.data.errors : 'Something went wrong');
            })
            .finally(() => {
                setChecking(false);
            });
    };

    return (
        <Button
            onClick={onClick}
            className={classes.checkButton}
            color="primary"
        >
            {
                checking ? (
                    <>
                        Checking
                        <CircularProgress className="u-mrg--lx1" size={16} />
                    </>
                ) : (
                    <>
                        Check
                        { successful && <DoneIcon fontSize="small" className={classes.doneIcon} /> }
                        { failed && <CloseIcon fontSize="small" className={classes.failIcon} /> }
                    </>
                )
            }
        </Button>
    );
});

export default withStyles(styles)(CheckButton);

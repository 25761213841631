import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    tableRow: {
        cursor: 'pointer'
    },
    tableRowDisabled: {
        cursor: 'default'
    },
    statusDate: {
        color: theme.palette.grey[900],
        fontWeight: 600,
        fontSize: 14,
        marginLeft: 5,
        whiteSpace: 'nowrap'
    },
    tableCell: {
        borderColor: `${theme.palette.grey[300]}!important`
    },
    tableRowSelected: {
        backgroundColor: theme.palette.blue[50],
        border: `2px solid ${theme.palette.blue[600]}`
    }
}));

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import { publishSet } from 'requests/CMSRequests';
import { appCtx } from 'components/appStore';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import Icon from './icon.svg';
import DoneIcon from './done.svg';

const useStyles = makeStyles(theme => ({
    button: {
        fontWeight: 700,
        padding: '4px 20px',
        minWidth: 170,
        justifyContent: 'flex-start',
        '&:disabled': {
            backgroundColor: theme.palette.grey[400]
        }
    },
    buttonDone: {
        backgroundColor: theme.palette.green[600]
    }
}));

const SynchroniseButton = observer(({ uuid, disabled, setSelectedSetData }) => {
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const { flashMessage } = useContext(appCtx);
    const classes = useStyles();

    const synchronize = () => {
        setLoading(true);
        publishSet(uuid)
            .then(({ success, data }) => {
                if (success && data) {
                    setDone(true);
                    setTimeout(() => {
                        setDone(false);
                        setSelectedSetData(data);
                    }, 1300);
                    flashMessage('Question set synchronized', 'done');
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Button
            color="primary"
            variant="contained"
            className={clsx(classes.button, done && classes.buttonDone)}
            onClick={synchronize}
            disabled={disabled && !done}
        >
            {
                loading ? (
                    <CircularProgress className="u-mrg--rx2" size={18} />
                ) : <img src={done ? DoneIcon : Icon} alt="!" className="u-mrg--rx2" />
            } {done ? 'Saved' : 'Save Changes'}
        </Button>
    );
});

export default SynchroniseButton;

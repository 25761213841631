import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { appCtx } from 'components/appStore';
import ZapierContent from './components/ZapierContent';
import GreenhouseContent from './components/GreenhouseContent';
import SeekContent from './components/SeekContent';
import PageUpContent from './components/PageUpContent';
import ComeetContent from './components/ComeetContent';
import PublicApiContent from './components/PublicApiContent';
import TalkToSalesContent from './components/TalkToSalesContent';
import SmartRecruitersV2Content from './components/SmartRecruitersV2Content';
import JobAdderContent from './components/JobAdderContent';
import LeverContent from './components/LeverContent';
import OracleContent from './components/OracleContent';
import IcimsContent from './components/IcimsContent';
import JobViteContent from './components/JobViteContent';

const styles = theme => ({
    contentWrapper: {
        overflow: 'auto',
        height: 'calc(100vh - 109px)',
        width: '100%',
        padding: '0 59px'
    },
    contentWrapperHeader: {
        fontWeight: 700,
        fontSize: 18,
        color: theme.palette.grey[900],
        paddingBottom: 17,
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    }
});

const IntegrationContent = observer(({
    activeIntegration
}) => {
    const {
        updateLoggedUser, company, updateCompany,
        flashMessage, loggedUser, updateCompanyProperty
    } = useContext(appCtx);
    const { companySettings } = company;

    const propsObj = {
        company,
        loggedUser,
        flashMessage,
        updateCompany,
        updateLoggedUser,
        updateCompanyProperty
    };

    const {
        seekIntegration, zapierIntegration, oracleIntegration,
        greenhouseIntegration, apiIntegration,
        pageUpIntegration, comeetIntegration,
        smartRecruitersV2Integration,
        jobAdderIntegration, leverIntegration, icimsIntegration,
        jobViteIntegration

    } = companySettings;

    switch (activeIntegration) {
        case 'Zapier':
            if (zapierIntegration) {
                return <ZapierContent {...propsObj} />;
            }
            break;
        case 'Greenhouse':
            if (greenhouseIntegration) {
                return <GreenhouseContent {...propsObj} />;
            }
            break;
        case 'Seek':
            if (seekIntegration) {
                return (
                    <SeekContent {...propsObj} />
                );
            }
            break;
        case 'PageUp':
            if (pageUpIntegration) {
                return (
                    <PageUpContent {...propsObj} />
                );
            }
            break;
        case 'Comeet':
            if (comeetIntegration) {
                return (
                    <ComeetContent {...propsObj} />
                );
            }
            break;
        case 'Vervoe API':
            if (apiIntegration) {
                return (
                    <PublicApiContent {...propsObj} />
                );
            }
            break;
        case 'SmartRecruiters v2':
            if (smartRecruitersV2Integration) {
                return (
                    <SmartRecruitersV2Content {...propsObj} isIntegrationsList />
                );
            }
            break;
        case 'JobAdder':
            if (jobAdderIntegration) {
                return (
                    <JobAdderContent {...propsObj} />
                );
            }
            break;
        case 'Lever':
            if (leverIntegration) {
                return (
                    <LeverContent {...propsObj} />
                );
            }
            break;
        case 'Oracle':
            if (oracleIntegration) {
                return (
                    <OracleContent {...propsObj} />
                );
            }
            break;
        case 'iCIMS':
            if (icimsIntegration) {
                return (
                    <IcimsContent {...propsObj} />
                );
            }
            break;
        case 'Jobvite':
            if (jobViteIntegration) {
                return (
                    <JobViteContent {...propsObj} />
                );
            }
            break;
        default:
            return null;
    }

    return (
        <TalkToSalesContent
            integration={activeIntegration}
        />
    );
});

export default withStyles(styles)(IntegrationContent);

import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Moment from 'react-moment';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import DatePicker from './DatePicker';
import useStyles from './styles';

const Item = ({ header, value, itemValueClassName }) => {
    const classes = useStyles();
    return (
        <div className="u-mrg--bx2">
            <p className={classes.itemHeader}>{header}</p>
            {
                typeof value === 'string' ? (
                    <TruncateWithTooltip
                        width={220}
                        text={value}
                        className={clsx(classes.itemValue, itemValueClassName)}
                    />
                ) : <p className={clsx(classes.itemValue, itemValueClassName)}>{value}</p>
            }
        </div>
    );
};


const DateSelectionContent = observer(({ candidates, selected, setSelected, assessmentName }) => {
    const classes = useStyles();
    if (!candidates.length) return null;
    const { fullName, hiringManager } = candidates[0];
    const countLeft = candidates.length - 1;
    return (
        <div className="u-dsp--distribute u-ai--start">
            <div className="u-mrg--tx2">
                <Item
                    header="Assessment"
                    value={assessmentName}
                />
                <Item
                    header="Candidate"
                    itemValueClassName={countLeft > 0 ? 'u-dsp--f u-ai--center u-jc--start' : ''}
                    value={countLeft > 0 ? (
                        <>
                            <TruncateWithTooltip width={160} text={fullName} />
                            <span className={classes.countLabel}>+{countLeft} more</span>
                        </>
                    ) : fullName}
                />
                <Item
                    header="Hiring Manager"
                    value={hiringManager ? hiringManager.fullName : '-'}
                />
                <Item
                    header="Hire Date"
                    value={selected ? <Moment format="MMM D, YYYY">{selected}</Moment> : '-'}
                />
            </div>
            <div>
                <DatePicker
                    value={selected}
                    setValue={setSelected}
                />
            </div>
        </div>
    );
});

export default withRouter(DateSelectionContent);

import React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';

import Icon from 'img/alertRed.svg';
import { MARKETING_SIMULATOR, SALES_SIMULATOR, QA_SIMULATOR } from 'helper/constants';
import useStyles from './styles';

const CompareNotAvailable = ({ type }) => {
    const classes = useStyles();
    const returnLabel = () => {
        switch (type) {
            case SALES_SIMULATOR: return 'sales simulation';
            case MARKETING_SIMULATOR: return 'marketing simulation';
            case QA_SIMULATOR: return 'QA simulation';
            default: return 'this';
        }
    };

    return (
        <div className={clsx(classes.wrapper, 'u-dsp--centered u-fdir--column')}>
            <img src={Icon} className={classes.noAnswerIcon} alt="No Compare View" />
            <Typography variant="h6" className={classes.text}>
                Compare view for {returnLabel()} question type is not available. Please go back to the individual candidate cards to review responses.
            </Typography>
        </div>
    );
};

export default CompareNotAvailable;

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import AssessmentEvents from 'events/AssessmentEvents';
import TableFilter from 'components/table_components/table_filter';
import CustomMenuItem from 'components/table_components/table_filter/table_filter_item';

import { assessmentSettingsCtx } from 'pages/assessment/store';
import { invitationsCtx } from '../../invitationsStore';

import NoTagsBlock from '../../../components/NoTagsBlockFilter';

import useStyles from './styles';

const InviteTableFilter = observer(() => {
    const classes = useStyles();

    const {
        handleChangeFilterSelect, filterCategory,
        filterTagsCategory, filterSourceCategory,
        invitationsStats
    } = useContext(invitationsCtx);

    const { audition, companyTags } = useContext(assessmentSettingsCtx);

    const handleChange = (value, isTags, isSource) => {
        const { uuid: ttId, name: ttName } = audition;
        let transformedObj;
        if (isTags) transformedObj = filterTagsCategory;
        if (isSource) transformedObj = filterSourceCategory;
        if (!isTags && !isSource) transformedObj = filterCategory;
        AssessmentEvents.CANDIDATES_FILTERED({
            ttName,
            ttId,
            filterType: value,
            filterEnabled: !(value in transformedObj),
            context: 'invite page'
        });
        handleChangeFilterSelect(value, isTags, isSource);
    };

    const inviteOptions = invitationsStats ? [{
        value: 'all',
        label: 'All Candidates',
        count: invitationsStats.allCandidates,
        checked: !Object.keys(filterCategory).length
    }, {
        value: 'invited',
        label: 'Invited',
        count: invitationsStats.invited,
        checked: filterCategory.invited
    }, {
        value: 'applied',
        label: 'Applied',
        count: invitationsStats.applied,
        checked: filterCategory.applied
    }, {
        value: 'inProgress',
        label: 'In Progress',
        count: invitationsStats.inProgress,
        checked: filterCategory.inProgress
    }, {
        value: 'expired',
        label: 'Expired',
        count: invitationsStats.expired,
        checked: filterCategory.expired
    }, {
        value: 'moreTimeRequested',
        label: 'More time requested',
        count: invitationsStats.moreTimeRequested,
        checked: filterCategory.moreTimeRequested
    }, {
        value: 'screeningFailed',
        label: 'Failed Screening',
        count: invitationsStats.screeningFailed,
        checked: filterCategory.screeningFailed
    }, {
        value: 'screeningInProgress',
        label: 'Screening In Progress',
        count: invitationsStats.screeningInProgress,
        checked: filterCategory.screeningInProgress
    }, {
        value: 'rejected',
        count: invitationsStats.rejected,
        checked: filterCategory.rejected
    }, {
        value: 'completed',
        count: invitationsStats.completed,
        checked: filterCategory.completed
    }, {
        value: 'hired',
        count: invitationsStats.hired,
        checked: filterCategory.hired
    }] : [];

    let sourcesOptions;

    if (filterSourceCategory && invitationsStats && invitationsStats.sources) {
        sourcesOptions = invitationsStats.sources.map(el => ({
            value: el.key,
            label: el.name,
            count: el.count,
            checked: filterSourceCategory[el.key]
        }));
    }

    const tagOptions = companyTags
        .filter(({ title }) => Boolean(invitationsStats.tags && invitationsStats.tags[title]))
        .map(({ title }) => ({
            checked: filterTagsCategory[title],
            value: title,
            count: invitationsStats.tags[title]
        }));

    const renderMenuItems = (menuList, isTags, isSource) => (
        menuList.map(({ value, label = value, checked, count }) => (
            <CustomMenuItem
                key={value}
                value={value}
                label={label}
                count={count}
                checked={checked}
                isCheckbox={isTags || isSource || false}
                showCountNumber
                handleChange={val => handleChange(val, isTags, isSource)}
            />
        ))
    );

    if (!audition || !filterCategory) return null;

    const hasTaggedCandidates = tagOptions.some(({ count }) => Boolean(count));
    const filterCount = Object.keys(filterTagsCategory).length
        + (filterCategory.shortlist ? Object.keys(filterCategory).length - 1 : Object.keys(filterCategory).length)
        + (filterSourceCategory ? Object.keys(filterSourceCategory).length : 0);

    return (
        <TableFilter
            isFilterActive={Boolean(filterCount)}
        >
            <Typography variant="h6" className={classes.filterHeader}>Status</Typography>
            { renderMenuItems(inviteOptions) }
            {
                sourcesOptions && (
                    <>
                        <Typography variant="h6" className={classes.filterHeader}>Sources</Typography>
                        {renderMenuItems(sourcesOptions, false, true)}
                    </>
                )
            }
            <Typography variant="h6" className={classes.filterHeader}>Tags</Typography>
            {hasTaggedCandidates ? renderMenuItems(tagOptions, true) : <NoTagsBlock />}
        </TableFilter>
    );
});

export default InviteTableFilter;

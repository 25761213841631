import React from 'react';

const DeleteIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd" stroke="#F02E2E" strokeLinecap="round" strokeLinejoin="round">
            <path d="M1 3h14M9.5 1h-3a1 1 0 0 0-1 1v1h5V2a1 1 0 0 0-1-1zM6.5 11.5v-5M9.5 11.5v-5M12.577 14.083a1 1 0 0 1-.997.917H4.42a1 1 0 0 1-.996-.917L2.5 3h11l-.923 11.083z" />
        </g>
    </svg>
);

export default DeleteIcon;

export default theme => ({
    link: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        '&:hover, &:focus, &:active': {
            color: theme.palette.primary.dark
        }
    },
    loader: {
        color: '#fff',
        left: 'auto',
        right: '15px',
        display: 'block',
        top: '19%'
    },
    forgotPW: {
        fontSize: 13,
        textAlign: 'right',
        marginTop: 10,
        marginBottom: 26
    },
    signUp: {
        color: theme.palette.grey[900],
        fontWeight: 600
    },
    sso: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.grey[500],
        fontWeight: 600,
        cursor: 'pointer'
    },
    signInButton: {
        height: 50,
        fontWeight: 700,
        marginTop: 10
    },
    heading: {
        textAlign: 'center',
        fontWeight: 800,
        marginBottom: '.5em',
        color: theme.palette.grey[900]
    },
    heading2: {
        fontWeight: 800,
        marginBottom: 50,
        fontSize: 24,
        color: theme.palette.grey[900]
    },
    text: {
        color: theme.palette.grey[900],
        fontWeight: 600,
        marginTop: 40
    },
    verificationInput: {
        margin: '30px auto',
        '& input': {
            border: `2px solid ${theme.palette.grey[300]}`,
            borderRight: 'none',
            fontFamily: 'Open Sans',
            fontSize: 24,
            fontWeight: 600,
            color: theme.palette.grey[900],
            '&:focus': {
                border: `2px solid ${theme.palette.primary.main}`,
                caretColor: theme.palette.primary.main
            }
        },
        '& input:last-child': {
            borderRight: `2px solid ${theme.palette.grey[300]}`
        }
    },
    resendButton: {
        fontWeight: 700,
        padding: 2,
        maxHeight: 25,
        marginTop: 10
    },
    errorMessage: {
        width: 430,
        height: 50,
        fontWeight: 600,
        fontSize: 14,
        borderRadius: 4,
        color: theme.palette.grey[900],
        margin: '0 auto 20px',
        display: 'flex',
        alignItems: 'center',
        padding: 15,
        backgroundColor: theme.palette.red[50]
    }
});

import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withStyles, withTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import Popover from 'libraries/Popover';
import TextInput from 'libraries/TextInput';
import AddIcon from 'img/add.svg';
import { appCtx } from 'components/appStore';
import { createSkill, getListOfSkills, addSkillToAssessment } from 'requests/CMSRequests';

import { MAX_SKILL_NAME_LENGTH } from 'helper/constants';

import styles from './styles';

let timerId;

const AddSkillButton = observer(({ classes, auditionUuid, addSet }) => {
    const [skillName, setSkillName] = useState('');
    const [popover, setPopover] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const [skillsList, setSkillsList] = useState([]);
    const { flashMessage } = useContext(appCtx);

    const popoverMounted = (callbacks) => {
        setPopover(callbacks);
    };

    const onChange = (e) => {
        const { value } = e.target;
        setSkillName(value);
        if (value) {
            setIsLoading(true);
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                getListOfSkills(value)
                    .then(({ success, data }) => {
                        if (success && data) {
                            setSkillsList(data);
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }, 300);
        }
    };

    const closePopover = () => {
        if (popover) {
            popover.handleClose();
        }
    };

    const addSkill = (id) => {
        addSkillToAssessment(auditionUuid, id)
            .then(({ success, data }) => {
                if (success && data) {
                    addSet(data);
                    flashMessage('New skill created', 'done');
                    setSkillName('');
                    setSkillsList([]);
                    closePopover();
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.errors && err.response.data.errors.message) {
                    flashMessage(err.response.data.errors.message, 'error');
                }
            })
            .finally(() => {
                setIsCreateLoading(false);
            });
    };

    const onCreateSkill = () => {
        setIsCreateLoading(true);
        createSkill(skillName)
            .then(({ success, data }) => {
                if (success && data) {
                    addSkill(data.id);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
                setIsCreateLoading(false);
            });
    };

    const onClose = () => {
        setSkillName('');
    };


    return (
        <Popover
            onClose={onClose}
            onMounted={callbacks => popoverMounted(callbacks)}
            className={classes.popoverRoot}
            label={(
                <div className="u-txt--center u-pos--relative">
                    <Button className={classes.addButton}>
                        <img src={AddIcon} className="u-mrg--rx1" alt="add" />
                        Add new Skill
                    </Button>
                </div>
            )}
        >
            <div className={classes.addSkillWrapper}>
                <Typography variant="h6">Add new skill</Typography>
            </div>
            <div className={classes.popoverFooter}>
                <TextInput
                    variant="outlined"
                    placeholder="Add new skill"
                    className={classes.skillInput}
                    onChange={onChange}
                    margin="none"
                    maxLength={MAX_SKILL_NAME_LENGTH}
                    value={skillName}
                />
            </div>
            {
                Boolean(skillName) && (
                    <div className={clsx(classes.paper, isLoading && classes.paperLoading)}>
                        {
                            isLoading ? (
                                <CircularProgress color="primary" size={23} />
                            ) : (
                                <>
                                    {
                                        skillsList.length ? (
                                            <>
                                                {
                                                    skillsList.map(({ title, id }) => (
                                                        <p
                                                            onClick={() => addSkill(id)}
                                                            className={classes.listItem}
                                                            key={id}
                                                        >
                                                            {title}
                                                        </p>
                                                    ))
                                                }
                                            </>
                                        ) : (
                                            <div className="u-pdn--lx2 u-pdn--rx2">
                                                <Typography variant="h6" className="u-txt--center">
                                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                                    No matches for "{skillName}"
                                                </Typography>
                                            </div>
                                        )
                                    }
                                    <div className={classes.emptyWrapper}>
                                        <Button
                                            className="u-mrg--tx2"
                                            variant="contained"
                                            color="primary"
                                            disabled={isCreateLoading}
                                            onClick={onCreateSkill}
                                        >
                                            {
                                                isCreateLoading ? (
                                                    <CircularProgress
                                                        size={24}
                                                        thickness={3}
                                                        classes={{ root: classes.loader }}
                                                    />
                                                ) : 'Create new skill'
                                            }
                                        </Button>
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            }
        </Popover>
    );
});

export default withTheme(withStyles(styles)(AddSkillButton));

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import BoardNoContent from 'components/board/board_no_content';
import clsx from 'clsx';
import RequestInfoDialog from 'components/dialogs/RequestInfoDialog';
import TagsList from 'pages/assessment/components/TagsList';
import RequestsTable from './RequestsTable';
import { customRequestsStoreCtx } from '../../store';

import EmptyIcon from '../../../assessment/candidate_invite/InviteCandidates/InviteCandidatesList/icon.svg';
import NotFoundContent from '../../../../components/assessments_pages/NotFoundContent';

import useStyles from './styles';


const RequestsList = observer(() => {
    const classes = useStyles();
    const {
        totalCount: requestsLength, query,
        isLoading, isNoCandidates, changeStatus, filterCategory,
        isLoadingLocal, showRequestModal, onHideInfo, requestInfoId, filterLabel, handleFilter
    } = useContext(customRequestsStoreCtx);

    if (isLoading || isLoadingLocal) {
        return (
            <div className={classes.wrapper}>
                <div className={classes.loaderWrapper}>
                    <CircularProgress color="primary" />
                </div>
            </div>
        );
    }

    if (query && !requestsLength) {
        return <NotFoundContent searchValue={query} />;
    }

    if (isNoCandidates) {
        return (
            <BoardNoContent
                header="No Requests"
                hideIconWrapper
                className={classes.board}
                icon={<img src={EmptyIcon} alt="empty-content" />}
            />
        );
    }

    return (
        <>
            <div className={classes.infoWrapper}>
                <div className="u-txt--bold u-dsp--f u-txt--16 u-ai--center">
                    Requests
                    <div className={clsx(classes.requestsNumber, 'u-mrg--lx1 u-txt--14')}>({requestsLength})</div>
                    {
                        filterCategory && filterCategory !== 'all' && (
                            <TagsList {...{
                                filterCategory: filterLabel,
                                handleChangeFilterSelect: handleFilter
                            }}
                            />
                        )
                    }
                </div>
            </div>
            <RequestsTable />
            <RequestInfoDialog
                open={showRequestModal}
                handleClose={onHideInfo}
                id={requestInfoId}
                changeStatus={changeStatus}
            />
        </>
    );
});

export default RequestsList;

import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditQuestion from 'components/marketplace/EditQuestion';

import CloseIcon from 'img/close.svg';

import {
    addQuestionToSet, createCompanyQuestion,
    deleteCompanyQuestion
} from 'requests/CMSRequests';

import { appCtx } from 'components/appStore';

import { observer } from 'mobx-react-lite';
import EditQuestionHeader from './EditQuestionHeader';

import styles from './styles';

const QuestionDrawer = observer(({
    classes, toggleDrawer, open, selectedQuestionId,
    setSaving, setSelectedQuestionId, addToSelectedSkill,
    selectedSet, selectedSkill, getAssessment, onDelete
}) => {
    const [createdQuestionId, setCreatedQuestionId] = useState(null);
    const [loading, setLoading] = useState(null);
    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        if (!open) {
            setCreatedQuestionId(null);
            setSelectedQuestionId(null);
        }

        if (open && !selectedQuestionId) {
            createQuestion();
        }
    }, [open]);

    const questionId = selectedQuestionId || createdQuestionId;

    const createQuestion = () => {
        setLoading(true);
        createCompanyQuestion(selectedSkill ? { companySkill: selectedSkill.id } : {})
            .then(({ success, data }) => {
                if (success && data) {
                    setCreatedQuestionId(data.id);
                    if (selectedSet && selectedSet.uuid && addToSelectedSkill) {
                        addQuestionToSet(selectedSet.uuid, data.uuid);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteQuestion = () => {
        if (!questionId) return;
        setLoading(true);
        return deleteCompanyQuestion(questionId)
            .then(() => {
                if (onDelete) onDelete(questionId);
                toggleDrawer();
                flashMessage('Question deleted', 'done');
            })
            .catch(() => {
                flashMessage('Something went wrong');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer}
            classes={{ paper: classes.drawerPaper }}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
                style: { position: 'absolute' }
            }}
            variant="persistent"
        >
            <div className={classes.headerWrapper}>
                <IconButton className={classes.closeIcon} onClick={toggleDrawer} size="large">
                    <img src={CloseIcon} alt="close" />
                </IconButton>
                <Typography variant="h4">
                    { selectedQuestionId ? 'Edit' : 'Create New' } question
                </Typography>
            </div>
            <div className={classes.questionWrapper}>
                {
                    questionId && (
                        <EditQuestion
                            isCMS
                            questionId={questionId}
                            selectedQuestionId={selectedQuestionId}
                            getAssessment={getAssessment}
                            setSaving={setSaving}
                            QuestionHeaderComp={EditQuestionHeader}
                            savingQuestion={loading}
                            deleteQuestion={deleteQuestion}
                        />
                    )
                }
            </div>
        </Drawer>
    );
});

export default withStyles(styles)(QuestionDrawer);

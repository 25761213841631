import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextInput from 'libraries/TextInput';
import { applyWithSeekAdvertiserId } from 'requests/SettingsRequests';

import stylesJS from './styles';

class SeekContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            advertiserId: '',
            advertiserIdError: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        const { loggedUser } = this.props;
        if (loggedUser.applyWithSeekAdvertiserId !== nextProps.loggedUser.applyWithSeekAdvertiserId) {
            this.setState({ advertiserId: nextProps.loggedUser.applyWithSeekAdvertiserId });
        }
    }

    saveAdvertiserId = (advertiserId) => {
        const { loggedUser, flashMessage, updateLoggedUser } = this.props;
        applyWithSeekAdvertiserId(advertiserId)
            .then((data) => {
                if (data.success) {
                    const user = JSON.parse(JSON.stringify(loggedUser));
                    user.applyWithSeekAdvertiserId = data.advertiserId;
                    updateLoggedUser(user);
                    this.setState({ advertiserIdError: '' });
                    flashMessage('Advertiser ID saved');
                } else {
                    this.setState({ advertiserIdError: data.advertiserIdError });
                }
            });
    };

    handleChangeAdvertiserId = (e) => {
        const targetValue = e.target.value;
        this.setState({ advertiserId: targetValue });
    };

    render() {
        const { classes } = this.props;
        const { advertiserId, advertiserIdError } = this.state;
        return (
            <div>
                <p className="u-mrg--bx4">
                    Connect Vervoe to your seek job postings using your advertiser ID. If you don’t have one, contact and request one from Seek.
                </p>
                <TextInput
                    label="Seek Advertiser ID"
                    variant="outlined"
                    className={classes.input}
                    name="advertiserId"
                    margin="none"
                    isError={Boolean(advertiserIdError)}
                    helperText={advertiserIdError}
                    value={advertiserId}
                    onChange={this.handleChangeAdvertiserId}
                /><br />
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.saveButton}
                    onClick={() => this.saveAdvertiserId(advertiserId)}
                >
                    Save
                </Button>
            </div>
        );
    }
}

export default withStyles(stylesJS)(SeekContent);

import React from 'react';
import MaterialButton from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './styles';

const Button = ({ children, classes, hideIcon, loading, ...other }) => (
    <MaterialButton
        {...other}
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
        className={classes.root}
    >
        {
            loading ? (
                <CircularProgress size={24} className={classes.loader} />
            ) : (
                <>
                    { children }
                </>
            )
        }
    </MaterialButton>
);

export default withStyles(styles)(Button);

export default () => ({
    wrapper: {
        width: '100%',
        overflow: 'auto',
        minHeight: 250
    },
    loaderWrapper: {
        width: '100%',
        marginTop: 70,
        textAlign: 'center'
    },
    loaderBlock: {
        margin: 0
    }
});

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import { getAssessmentRequestInfo } from 'requests/CustomAssessmentRequests';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadCircle from 'img/download_circle.svg';
import CheckBoxIcon from 'img/checkbox.svg';
import clsx from 'clsx';
import StatusLabel from 'pages/assessment_requests/components/RequestsList/RequestsTable/RequestRow/StatusLabel';
import { formatTimestamp, nl2brCustom } from 'helper/commonFunctions';
import parse from 'html-react-parser';

const styles = theme => ({
    loaderWrapper: {
        textAlign: 'center',
        marginTop: 35
    },
    mainInfoRow: {
        display: 'flex',
        height: 37,
        marginBottom: 11,
        color: theme.palette.grey[900],
        '& .labelValue': {
            fontSize: 14,
            fontWeight: 400
        }
    },
    title: {
        fontWeight: 700,
        minWidth: 165
    },
    download: {
        color: theme.palette.blue[500],
        fontSize: 14,
        fontWeight: 400,
        '& img': {
            marginRight: 10
        }
    }
});

const RequestInfoDialog = observer(({
    handleClose, classes, open, id, changeStatus
}) => {
    const [requestInfo, setRequestInfo] = useState(null);

    useEffect(() => {
        if (id) {
            getAssessmentRequestInfo(id).then(({ success, data }) => {
                if (success) {
                    setRequestInfo(data);
                }
            });
        } else {
            setTimeout(() => {
                setRequestInfo(null);
            }, 200);
        }
    }, [id]);

    const downloadFileComp = url => (
        <a href={url} target="_blank" rel="noopener noreferrer" className={classes.download}>
            <img src={DownloadCircle} alt="" />
                Download File
        </a>
    );

    return (
        <Dialog
            open={open}
            maxWidth="md"
            onClose={handleClose}
            titleComponent="Custom Assessment Request"
            handleClose={handleClose}
        >
            {
                !requestInfo && (
                    <div className={classes.loaderWrapper}>
                        <CircularProgress color="primary" />
                    </div>
                )
            }

            {
                requestInfo && (
                    <div>
                        <div className={classes.mainInfoRow}>
                            <div className={classes.title}>Status</div>
                            <div>
                                <StatusLabel text={requestInfo.status} dark id={requestInfo.id} changeStatus={changeStatus} />
                            </div>
                        </div>
                        <div className={classes.mainInfoRow}>
                            <div className={classes.title}>Company Name</div>
                            <div className="labelValue">{requestInfo.companyName}</div>
                        </div>
                        <div className={classes.mainInfoRow}>
                            <div className={classes.title}>Company Slug</div>
                            <div className="labelValue">{requestInfo.companySlug}</div>
                        </div>
                        <div className={classes.mainInfoRow}>
                            <div className={classes.title}>Requested By</div>
                            <div className="labelValue">{requestInfo.employerName} {requestInfo.employerEmail}</div>
                        </div>
                        <div className={classes.mainInfoRow}>
                            <div className={classes.title}>Request Date</div>
                            <div className="labelValue">{formatTimestamp(requestInfo.createdAt)}</div>
                        </div>
                        <div className={classes.mainInfoRow}>
                            <div className={classes.title}>Category</div>
                            <div className="labelValue">{requestInfo.category}</div>
                        </div>
                        <div className={classes.mainInfoRow}>
                            <div className={classes.title}>Role</div>
                            <div className="labelValue">{requestInfo.role}</div>
                        </div>
                        <div className={classes.mainInfoRow}>
                            <div className={classes.title}>JD</div>
                            <div>
                                { downloadFileComp(requestInfo.file.fullFileUrl) }
                            </div>
                        </div>
                        {
                            requestInfo.answers.map((answer, idx) => (
                                <div key={idx}>
                                    <div className={clsx(classes.title, 'u-mrg--bx2 u-mrg--tx6')}>{answer.title}</div>
                                    {answer.options && answer.options.map((option, optionIdx) => (
                                        <div className="u-mrg--bx2" key={optionIdx}>
                                            <div className="u-mrg--bx1">
                                                <img src={CheckBoxIcon} alt="" className="u-mrg--rx2" />
                                                {option.title}
                                            </div>
                                            <div>{parse(nl2brCustom(option.text))}</div>
                                        </div>
                                    ))}
                                    <div className="u-mrg--bx2">{answer.text === 'null' ? '' : parse(nl2brCustom(answer.text))}</div>
                                    {answer.file && downloadFileComp(answer.file.fullFileUrl)}
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </Dialog>
    );
});

export default withStyles(styles)(RequestInfoDialog);

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import { TextField } from '@mui/material';
import PhoneInputWithCountries from 'components/inputs/PhoneInputWithCountries';

import Tooltip from 'libraries/Tooltip';
import Link from 'react-router-dom/Link';
import useStyles from '../styles';

const ChangePhoneDialog = ({
    open, onClose, candidate, onResend,
    onSaveChanges, phoneValue, phoneValid,
    handlePhoneChange, company, invite
}) => {
    const { button, saveButton, disabledInput, tooltipBox, tooltipText, link } = useStyles({});
    if (!company.smsLimitPeriod) return null;
    const smsLimitPeriodToShow = company.smsLimitPeriod.split(' ')[1];

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}
            handleClose={onClose}
            onClick={e => e.stopPropagation()}
            titleComponent="Candidate Details"
            actionComponent={(
                <>
                    {(company.countSmsLeft > 0 || !invite) ? (
                        <Button
                            className={button}
                            color="primary"
                            onClick={onResend}
                            disabled={!phoneValid || !phoneValue || !invite}
                        >
                            Resend SMS
                        </Button>
                    ) : (
                        <Tooltip
                            white
                            boxShadow
                            placement="left"
                            tooltipClass={tooltipBox}
                            label={(
                                <div className={tooltipText}>
                                    You have <b>{company.countSmsLeft}/{company.countSmsPerCompanyQuota}</b> SMS notifications remaining of
                                    <br />
                                    your plan quota this {smsLimitPeriodToShow}.&nbsp;
                                    <Link to="/billing" className={link}>
                                        My account
                                    </Link>
                                </div>
                            )}
                        >
                            <span>
                                <Button
                                    className={button}
                                    color="primary"
                                    onClick={onResend}
                                    disabled={!phoneValid || !phoneValue || company.countSmsLeft <= 0}
                                >
                                    Resend SMS
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                    <Button
                        className={saveButton}
                        color="primary"
                        variant="contained"
                        onClick={onSaveChanges}
                        disabled={!phoneValid || !phoneValue}
                    >
                        Save changes
                    </Button>
                </>
            )}
        >
            <TextField
                disabled
                id="outlined-disabled"
                className={disabledInput}
                label="Full Name"
                defaultValue={candidate.fullName}
            />
            <TextField
                disabled
                id="outlined-disabled"
                className={disabledInput}
                label="Email"
                defaultValue={candidate.email}
            />
            <PhoneInputWithCountries
                value={phoneValue}
                placeholder="Phone Number"
                isValid={phoneValid}
                onChange={handlePhoneChange}
            />
        </Dialog>
    );
};

export default ChangePhoneDialog;

import React from 'react';

// material components
import Select from 'libraries/Select';

const SheetNameSelect = ({
    question, onChange, row,
    value, sheetNames, classes, ...other
}) => {
    const handleChange = (e) => {
        onChange(e, row);
    };

    return (
        <Select
            margin="none"
            variant="outlined"
            label="Sheet name"
            value={value}
            name="sheetName"
            onChange={handleChange}
            placeholder="Sheet name"
            options={
                sheetNames.map(item => ({
                    label: item,
                    value: item
                }))
            }
            {...other}
        />
    );
};

export default SheetNameSelect;

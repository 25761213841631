import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TextInputOutlined from 'libraries/TextInput';
import Tooltip from 'libraries/Tooltip';

const styles = () => ({
    wrapper: {
        position: 'relative',
        marginBottom: 30,
        '&:first-child': {
            marginTop: 30
        }
    },
    wrapperBanner: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 1000,
        background: 'rgba(255, 255, 255, 0.6)'
    },
    tooltip: {
        textAlign: 'center',
        width: 210
    },
    warningText: {
        fontWeight: 500,
        fontSize: 12,
        margin: '7px 0'
    }
});

let timerId = null;
let timerId2 = null;

const SimulationDescription = ({
    classes, isCMS, changeQuestion, isRequired, question: {
        simulationDescription = '', simulationCandidateQuestionInstructions
    }
}) => {
    const [instruction, setInstruction] = useState(simulationCandidateQuestionInstructions);
    const [description, setDescription] = useState(!isCMS ? (simulationDescription || '').replace(/(<([^>]+)>)/gi, ' ') : simulationDescription);
    const disabled = !isCMS;

    const onChangeDescription = (e) => {
        if (disabled) return;
        const { value } = e.target;
        setDescription(value);
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            changeQuestion({ simulationDescription: value });
        }, 300);
    };

    const onChangeInstruction = (e) => {
        const { value } = e.target;
        setInstruction(value);
        clearTimeout(timerId2);
        timerId2 = setTimeout(() => {
            changeQuestion({ simulationCandidateQuestionInstructions: value });
        }, 300);
    };

    return (
        <>
            <div className={classes.wrapper}>
                <Typography variant="h6">Simulation description</Typography>
                {
                    disabled && (
                        <Tooltip
                            tooltipClass={classes.tooltip}
                            label="Description is unable to be changed for simulations"
                        >
                            <div className={classes.wrapperBanner} />
                        </Tooltip>
                    )
                }
                <TextInputOutlined
                    label="Simulation Description"
                    value={description}
                    multiline
                    variant="outlined"
                    onChange={onChangeDescription}
                    maxLength={25000}
                    hasCounter={!disabled}
                    placeholder="Use case 1 describes ...”"
                    isError={!description && isCMS && isRequired}
                />
            </div>
            <div className={classes.wrapper}>
                <Typography variant="h6">Candidate question instructions</Typography>
                <div className={classes.warningText}>
                    Candidate question instructions give the candidate guidance on the question
                </div>
                <TextInputOutlined
                    label="Candidate instructions"
                    value={instruction}
                    multiline
                    variant="outlined"
                    onChange={onChangeInstruction}
                    maxLength={25000}
                    hasCounter
                    isError={!instruction}
                    helperText={!instruction && 'No candidate instructions text'}
                />
            </div>
        </>
    );
};

export default withStyles(styles)(SimulationDescription);

import React from 'react';

const Line = ({ color }) => (
    <svg style={{ margin: '0 6px' }} width="121" height="2" viewBox="0 0 121 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1H120" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>

);

export default Line;

import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CreateAssessmentTagButton from './CreateAssessmentTagButton';

import styles from './styles';

const TagsHeader = ({ classes, tags, setTags, totalTagsCount, setTotalTagsCount }) => (
    <div className={classes.infoWrapper}>
        <div className="u-dsp--f">
            <Typography variant="h4" className="u-mrg--x0">
                    Assessment Tags {!!totalTagsCount && <span className={classes.countLabel}>({totalTagsCount || 0})</span>}
            </Typography>
        </div>
        <div className="u-dsp--f u-ai--center">
            <CreateAssessmentTagButton {...{ tags, setTags, totalTagsCount, setTotalTagsCount }} />
        </div>
    </div>
);

export default withStyles(styles)(TagsHeader);

import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Tooltip from 'libraries/Tooltip';

import QuestionTypes from '../QuestionTypes';
import AnswerTypes from '../AnswerTypes';

const useStyles = makeStyles(theme => ({
    numberQuestion: {
        fontWeight: 'bold',
        color: theme.palette.grey[900],
        fontSize: 16
    },
    interviewItemAction: {
        marginTop: 19,
        textAlign: 'right',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column-reverse'
        }
    },
    saveButton: {
        backgroundColor: ({ brandColor }) => brandColor,
        marginLeft: 16,
        opacity: 0.9,
        '&:hover': {
            backgroundColor: ({ brandColor }) => brandColor,
            opacity: 1
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginBottom: 16
        }
    },
    backButton: {
        fontWeight: 700
    }
}));

const QuestionWrapperAssessmentPreview = ({
    QuestionHeaderComp, brandColor,
    interview = {}, questions, selectedQuestionIndex,
    setSelectedQuestionIndex, question, isPreviewPage, isLibrary,
    isClosedAssessment, isPublicPreviewPage
}) => {
    const classes = useStyles({ brandColor });

    if (!question) return null;

    return <>
        <Grow
            in
            style={{ transformOrigin: '0 0 0', marginBottom: 80 }}
            {...{ timeout: 1000 }}
        >
            <div>
                <QuestionHeaderComp
                    index={selectedQuestionIndex}
                    interview={interview}
                    question={question}
                    isLibrary={isLibrary}
                    isClosedAssessment={isClosedAssessment}
                />
                <QuestionTypes data={question} brandColor={brandColor} />
                <AnswerTypes
                    interview={interview}
                    data={question}
                    index={selectedQuestionIndex}
                    brandColor={brandColor}
                    isPreviewPage={isPreviewPage}
                />
                <div className={classes.interviewItemAction}>
                    {
                        selectedQuestionIndex !== 0 && (
                            <Button
                                className={classes.backButton}
                                onClick={() => setSelectedQuestionIndex(selectedQuestionIndex - 1)}
                            >
                               Back
                            </Button>
                        )
                    }
                    {
                        selectedQuestionIndex < (questions.length - 1) ? (
                            <Button
                                variant="contained"
                                classes={{ root: classes.saveButton }}
                                color="primary"
                                onClick={() => setSelectedQuestionIndex(selectedQuestionIndex + 1)}
                            >
                                Save and continue
                            </Button>
                        ) : (
                            <>
                                {
                                    isPublicPreviewPage ? (
                                        <Button
                                            variant="contained"
                                            classes={{ root: classes.saveButton }}
                                            color="primary"
                                            onClick={() => { window.location.href = 'https://app.vervoe.com/registration-trial'; }}
                                        >
                                            Start now
                                        </Button>
                                    ) : (
                                        <Tooltip label="This is a preview of your assessment and replies are unable to be submitted.">
                                            <Button
                                                variant="contained"
                                                classes={{ root: classes.saveButton }}
                                                color="primary"
                                                onClick={() => {}}
                                            >
                                                Submit
                                            </Button>
                                        </Tooltip>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </Grow>
    </>;
};

export default QuestionWrapperAssessmentPreview;

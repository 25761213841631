import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Masonry from 'react-masonry-component';

import { withStyles } from '@mui/styles';
import { ButtonBase, Button, CircularProgress } from '@mui/material';

import Layout from 'components/layout';
import PageWrapper from 'components/layout/PageWrapper';
import BoardNoContent from 'components/board/board_no_content';
import AddBrandingSetDialog from 'components/dialogs/AddBrandingSetDialog';
import SavingLabel from 'components/layout/DrawerPageWrapper/SavingLabel';
import { appCtx } from 'components/appStore';
import EmptyIcon from 'img/emptyDetailsIcon.svg';
import { MAX_BRANDING_SETS } from 'helper/constants';
import { getBrandingsList } from 'requests/SettingsRequests';
import SettingsHeader from '../settings_header';
import PausedLabel from '../PausedLabel';
import BrandingCard from './BrandingCard';


const styles = theme => ({
    buttonBase: {
        color: theme.palette.primary.main
    },
    masonryWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '20px -12px 10px',
        '& > div': {
            marginLeft: 13,
            marginRight: 13,
            marginBottom: 30
        }
    },
    cardsWrapper: {
        maxWidth: 1406,
        margin: '0 auto',
        '@media only screen and (max-width: 1439px)': {
            maxWidth: 920
        },
        '@media only screen and (max-width: 990px)': {
            maxWidth: 450
        }
    }
});

let timerId = null;

const BrandingDetails = observer(({ company, classes }) => {
    const [brandings, setBrandings] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [showLabel, setShowLabel] = useState(false);

    const handleSaving = (value) => {
        clearTimeout(timerId);
        setShowLabel(true);
        setSaving(value);
        if (!value) {
            timerId = setTimeout(() => {
                setShowLabel(false);
            }, 3000);
        }
    };

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        getBrandings();
    }, []);

    const getBrandings = async () => {
        setLoading(true);
        const { data } = await getBrandingsList();
        setBrandings(data);
        setLoading(false);
    };

    const setBrandingDetail = (uuid, field, value) => {
        const newBrandings = brandings.map(brand => (brand.uuid === uuid ? { ...brand, [field]: value } : brand));
        setBrandings(newBrandings);
    };

    const openDialog = () => setDialogOpen(true);

    const wrapper = component => (
        <Layout>
            <PausedLabel />
            <SettingsHeader />
            <PageWrapper
                headerLabel="Branding Details"
                headerActions={<>
                    {showLabel && <SavingLabel isSaving={isSaving} />}
                    <Button
                        variant="contained"
                        onClick={openDialog}
                        color="primary"
                        disabled={loading || isSaving || brandings.length >= MAX_BRANDING_SETS}
                    >
                        Add New Branding
                    </Button>
                </>}
                className="u-z--1 u-pos--relative"
            >
                {component}
            </PageWrapper>
            <AddBrandingSetDialog
                isSaving={isSaving}
                setSaving={handleSaving}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                setBrandings={setBrandings}
                brandings={brandings}
            />
        </Layout>
    );

    if (!company) {
        return (
            <Layout>
                <SettingsHeader />
            </Layout>
        );
    }

    if (!brandings.length && !loading) {
        return wrapper(
            <BoardNoContent
                header="No Branding Details"
                text={(
                    <div className="u-dsp--f u-ai--center">
                        <div>You haven't added any assessment branding yet.</div>
                        &nbsp;
                        <ButtonBase
                            className={classes.buttonBase}
                            onClick={openDialog}
                        >
                            Add branding
                        </ButtonBase>
                    </div>
                )}
                hideIconWrapper
                icon={<img src={EmptyIcon} alt="empty-content" />}
            />
        );
    }

    return wrapper(
        <>
            {
                loading ? (
                    <div className="u-txt--center">
                        <CircularProgress className="u-mrg--tx3" />
                    </div>
                ) : (
                    <div className={classes.cardsWrapper}>
                        <Masonry component="div" className={classes.masonryWrapper}>
                            {
                                brandings.map((brandingSet, index) => (
                                    <BrandingCard
                                        isSaving={isSaving}
                                        setSaving={handleSaving}
                                        brandingSet={brandingSet}
                                        key={index}
                                        flashMessage={flashMessage}
                                        brandings={brandings}
                                        setBrandings={setBrandings}
                                        setBrandingDetail={setBrandingDetail}
                                    />
                                ))
                            }
                        </Masonry>
                    </div>
                )
            }
        </>
    );
});

export default withStyles(styles)(BrandingDetails);

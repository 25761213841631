export default theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '30px 0 40px',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    optionButton: {
        padding: '20px 28px',
        width: 164,
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.grey[200],
        borderRadius: 8,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: 20
        },
        '&:hover': {
            color: theme.palette.grey[900],
            backgroundColor: theme.palette.grey[300]
        }
    },
    optionButtonSelected: {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: '#fff',
            backgroundColor: theme.palette.primary.dark
        }
    },
    actionsBlock: {
        background: theme.palette.grey[900],
        color: '#fff',
        fontSize: 16,
        fontWeight: 700,
        padding: '20px 30px 30px',
        textAlign: 'center',
        borderRadius: 8,
        marginBottom: 20
    },
    buttonsBlock: {
        minHeight: 40,
        maxWidth: 550,
        margin: '20px auto 0',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    actionButton: {
        minWidth: 210
    },
    cancelButton: {
        color: theme.palette.grey[500],
        fontWeight: 600,
        '&:disabled': {
            color: theme.palette.grey[700]
        }
    },
    loader: {
        color: '#fff',
        '& svg': {
            margin: 0
        }
    },
    inputRoot: {
        backgroundColor: '#fff',
        borderRadius: 8,
        margin: '30px 0 15px'
    }
});

import React, { useContext, useState, useEffect } from 'react';
import Moment from 'react-moment';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import NotificationButton from 'components/job_or_assessment_settings/NotificationButton';
import BoardItemPhotos from 'components/board/board_photos';
import BoardItemWrapper from 'components/board/board_item';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import { sendAssessmentToSmartRecruiters } from 'requests/AssessmentRequests';
import { appCtx } from 'components/appStore';
import AssessmentEvents from 'events/AssessmentEvents';
import {
    checkHasCompanyFeature,
    checkHasUserPermission,
    checkPluralNew, getDateShortName
} from 'helper/commonFunctions';
import DeactivateAssessmentDialog from 'components/dialogs/DeactivateAssessmentDialog';
import ActivateAssessmentDialog from 'components/dialogs/ActivateAssessmentDialog';
import ActivateAssessmentLowPlanDialog from 'components/dialogs/ActivateAssessmentLowPlanDialog';
import ActivateLimitDialog from 'components/dialogs/ActivateLimitDialog';
import DeactivateLinkedAssessmentDialog from 'components/dialogs/DeactivateLinkedAssessmentDialog';
import DeactivateSurveyDialog from 'components/dialogs/DeactivateSurveyDialog';
import DeleteAssessmentFromJobAdderDialog from 'components/dialogs/DeleteAssessmentFromJobAdderDialog';
import DeleteAssessmentFromJobViteDialog from 'components/dialogs/DeleteAssessmentFromJobViteDialog';
import FoldersMenu from 'components/job_or_assessment_settings/FoldersMenu';
import InsightsButton from 'components/assessments_pages/InsightsButton';
import { addAssessmentToJobAdder, addAssessmentToJobVite } from 'requests/SubscriptionRequests';
import { AssessmentStore } from 'components/assessments_pages/AssessmentList/assessmentStore';
import RegenerateComponent from 'components/assessments_pages/RegenerateComponent';
import { copyPrivateToCMS } from 'requests/CMSRequests';
import CloseIcon from 'img/close_round.svg';
import DuplicateIcon from 'img/duplicate.svg';
import EditIcon from 'img/edit.svg';
import InviteIcon from 'img/invite.svg';
import PreviewIcon from 'img/preview.svg';
import SettingIcon from 'img/settings.svg';
import ActiveIcon from 'img/active.svg';
import DeleteIcon from 'img/trash.svg';
import LinkIcon from 'img/link_grey.svg';
import { copyAssessmentPreviewLink, copyAssessmentPreviewLinkCallback } from 'helper/assessmentFunctions';
import { foldersCtx } from '../../../../../AssessmentFolders/store';
import NotificationRow from '../../../../NotificationRow';
import InfoRow from '../../../../InfoRow';
import ClosedOverlay from '../../../../ClosedOverlay';
import LockedCandidatesBlock from './LockedCandidatesBlock';
import { AssessmentTagsListCtx } from '../../../TabsSearchHeader/store';

import stylesCommon from '../styles';
import styles from './styles';

const getPluralName = value => ({
    day: 'd'
}[value]);

const getObjFirstField = (obj) => {
    if (!obj) return null;
    const keys = Object.keys(obj);
    if (!keys.length) return null;
    return keys[0];
};

const getLastCompletedPeriod = (lastCompletionPeriod) => {
    if (!lastCompletionPeriod) return null;

    const fieldName = getObjFirstField(lastCompletionPeriod);
    const value = lastCompletionPeriod[fieldName];
    const shortForm = getDateShortName(fieldName);

    return `${value} ${checkPluralNew(value, shortForm, getPluralName(fieldName))} ago`;
};

const getLastCompletionDays = (lastCompletionPeriod) => {
    const fieldName = getObjFirstField(lastCompletionPeriod);
    if (fieldName !== 'day') return 0;
    return lastCompletionPeriod[fieldName];
};

const isNoCompletionsLastDays = (lastCompletionPeriod) => {
    if (!lastCompletionPeriod) return null;
    const value = getLastCompletionDays(lastCompletionPeriod);

    return value && value > 2;
};

const EXTRA_BIG_COUNT = 9999;

const AdvancedAssessmentsContent = observer(({
    assessment, history, classes, handleDuplicate, handlePreviewScript, handleDeleteScript,
    goToAssessmentSettings, goToAssessment,
    removeAssessment, launchSegmentTTCustomized, setAssessmentFolder
}) => {
    const { flashMessage, company, activeAuditionsSlotsIsAvailable } = useContext(appCtx);
    const { getFolders } = useContext(foldersCtx);
    const { getAssessmentFilterNumbers, assessmentsFilter } = useContext(AssessmentTagsListCtx);
    const [deactivateOpen, setDeactivateOpen] = useState(false);
    const [deactivateSurveyOpen, setDeactivateSurveyOpen] = useState(false);
    const [deactivateLinkedDialogOpened, setDeactivateLinkedDialogOpened] = useState(false);
    const [deleteFromJADialogOpen, setDeleteFromJADialogOpen] = useState(false);
    const [deleteFromJVDialogOpen, setDeleteFromJVDialogOpen] = useState(false);
    const [loadingActivate, setLoadingActivate] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [assessmentStore] = useState((() => new AssessmentStore(assessment)));

    const {
        activateDialogOpen, setActivateDialogOpen,
        limitActivateDialogOpen, closeLimitActivateDialog,
        onActivate, duplicateAndActivate, activateAssessment,
        duplicate: duplicateAssessment
    } = assessmentStore;

    const {
        notificationsEnabled, archived,
        slug, active: isAssessmentActive, numberOfCompletions,
        activatedAt, completedPeriod,
        owner, employers = [], permissions, uuid, name,
        lockedCandidates, countRealCandidates, linkedSlugs,
        smartRecruitersAssessment, countHiredCandidates,
        countCompletedNotRejectedCandidates, auditionFolder,
        jobAdderIntegration, description, inActivatedAt, showRequiredHiringInformation, requestedMoreTimeCount,
        countCompleteQuestions, jobViteIntegration
    } = assessment;

    const [active, setActive] = useState(isAssessmentActive);
    const [activateDate, setActivateDate] = useState(activatedAt);
    const [inActivateDate, setInActivateDate] = useState(inActivatedAt);

    const [smartRecruitersAssessmentShow, setSmartRecruitersAssessmentShow] = useState(smartRecruitersAssessment === false);
    const [jobAdderAssessmentShow, setJobAdderAssessmentShow] = useState(jobAdderIntegration === false);
    const [jobViteAssessmentShow, setJobViteAssessmentShow] = useState(!jobViteIntegration);

    const { isJobAdderAtsEnabled, isJobViteAtsEnabled } = company;

    const edit = checkHasUserPermission(company, permissions, 'edit');
    const canDeactivateAssessment = checkHasCompanyFeature(company, 'OPPORTUNITY_DEACTIVATION');
    const hasFolders = checkHasCompanyFeature(company, 'AUDITION_FOLDERS');
    const companyHasCMS = checkHasCompanyFeature(company, 'CMS');
    const canActivateAssessmentWithCandidates = checkHasCompanyFeature(company, 'ACTIVATE_ASSESSMENT_WITH_PREEXISTING_CANDIDATES');

    useEffect(() => {
        if (!shareLink) return;
        copyAssessmentPreviewLinkCallback({ ...assessment, text: shareLink });
    }, [shareLink]);

    const goToAssessmentInvites = () => {
        launchSegmentTTCustomized();
        history.push(`/script/invite/${slug}/invitation`);
    };

    const goToAssessmentEdit = () => {
        launchSegmentTTCustomized();
        history.push(`/script/edit/${slug}`);
    };

    const handleDeactivateDialog = () => {
        if (linkedSlugs.length) {
            setDeactivateLinkedDialogOpened(!deactivateLinkedDialogOpened);
        } else if (countRealCandidates
            && countCompletedNotRejectedCandidates
            && !countHiredCandidates) {
            setDeactivateSurveyOpen(!deactivateSurveyOpen);
        } else {
            setDeactivateOpen(!deactivateOpen);
        }
    };

    const handleDeactivateSurvey = () => {
        setDeactivateSurveyOpen(!deactivateSurveyOpen);
    };

    const handleActivateDialog = (e) => {
        if (e) e.stopPropagation();
        if (countRealCandidates) {
            setActivateDialogOpen(!activateDialogOpen);
        } else {
            onClickActivate(e);
        }
    };

    const onClickActivate = (e) => {
        if (e) e.stopPropagation();
        setLoadingActivate(true);
        onActivate()
            .then((data) => {
                setActivateDate(data.activatedAt);
                setActive(true);
                if (assessmentsFilter === 'closed') removeAssessment();
            })
            .catch(() => {})
            .finally(() => {
                setLoadingActivate(false);
                getAssessmentFilterNumbers();
            });
    };

    const onClickActivateOnDialog = (e) => {
        if (e) e.stopPropagation();
        setLoadingActivate(true);
        activateAssessment()
            .then((data) => {
                setActivateDate(data.activatedAt);
                setActive(true);
                if (assessmentsFilter === 'closed') removeAssessment();
            })
            .finally(() => {
                setLoadingActivate(false);
                getAssessmentFilterNumbers();
            });
    };

    const duplicateAndActivateHandler = () => {
        setLoadingActivate(true);
        duplicateAndActivate()
            .then(() => {
                handleDuplicate();
            })
            .finally(() => {
                setLoadingActivate(false);
                getAssessmentFilterNumbers();
            });
    };

    const handleSendToSmartRecruiters = (e) => {
        if (e) e.stopPropagation();
        setSmartRecruitersAssessmentShow(false);
        sendAssessmentToSmartRecruiters(slug).then((data) => {
            if (data) {
                flashMessage('Assessment sent to SmartRecruiters', 'done');
                const { smartRecruitersAssessmentUpdated } = data;
                setSmartRecruitersAssessmentShow(smartRecruitersAssessmentUpdated === false);
            } else {
                flashMessage('Something went wrong', 'error');
            }
        }).catch(() => { flashMessage('Something went wrong', 'error'); });
    };

    const addToJobAdder = (e) => {
        if (e) e.stopPropagation();
        addAssessmentToJobAdder(slug)
            .then(() => {
                flashMessage('Assessment added to JobAdder', 'done');
                setJobAdderAssessmentShow(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 418) {
                    flashMessage('Not authorized in JobAdder', 'error');
                } else {
                    flashMessage('Something went wrong', 'error');
                }
            });
    };

    const addToJobVite = (e) => {
        if (e) e.stopPropagation();
        addAssessmentToJobVite(slug)
            .then(() => {
                flashMessage('Assessment added to Jobvite', 'done');
                setJobViteAssessmentShow(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 418) {
                    flashMessage('Not authorized in Jobvite', 'error');
                } else {
                    flashMessage('Something went wrong', 'error');
                }
            });
    };

    const onNotificationToggle = (enabled) => {
        AssessmentEvents.NOTIFICATION_CHANGED({ enabled, ttId: uuid, ttName: name });
    };

    const onDuplicateClick = () => {
        duplicateAssessment()
            .then(() => {
                if (hasFolders) getFolders();
                handleDuplicate();
                getAssessmentFilterNumbers();
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    const handleCMSCopy = () => {
        flashMessage('Copying assessment', 'pending', 100000);
        copyPrivateToCMS(slug)
            .then(() => {
                flashMessage('Assessment copied to CMS', 'done');
            })
            .catch((err) => {
                if (err.response && err.response.data.errors && err.response.data.errors.message) {
                    flashMessage(err.response.data.errors.message, 'error');
                } else {
                    flashMessage('Something went wrong', 'error');
                }
            });
    };

    const copyPreview = () => {
        if (shareLink) {
            copyAssessmentPreviewLinkCallback({ ...assessment, text: shareLink });
            return;
        }

        copyAssessmentPreviewLink(assessment)
            .then((link) => {
                if (!link) return;
                setShareLink(link);
            });
    };

    const previewMenuItems = [
        { label: 'Preview', onClick: handlePreviewScript, icon: PreviewIcon, disabled: !countCompleteQuestions },
        { label: 'Copy Preview Link', onClick: copyPreview, icon: LinkIcon, disabled: !countCompleteQuestions }
    ];

    const getBoardMenuItems = () => {
        if (!edit) {
            return ([
                ...previewMenuItems,
                { label: 'Duplicate', onClick: onDuplicateClick, icon: DuplicateIcon }
            ]);
        }

        const boardMenuItems = active ? [
            ...previewMenuItems,
            { label: 'Edit', onClick: goToAssessmentEdit, icon: EditIcon, disabled: !active },
            { label: 'Settings', onClick: goToAssessmentSettings, icon: SettingIcon },
            { label: 'Invite Candidates', onClick: goToAssessmentInvites, icon: InviteIcon },
            { label: 'Duplicate', onClick: onDuplicateClick, icon: DuplicateIcon },
            { divider: true },
            { label: 'Close Assessment', onClick: handleDeactivateDialog, disabled: Boolean(!canDeactivateAssessment && numberOfCompletions > 0), icon: CloseIcon }
        ] : [
            ...previewMenuItems,
            { label: 'Edit', onClick: goToAssessmentEdit, icon: EditIcon, disabled: !active },
            { label: 'Invite Candidates', onClick: goToAssessmentInvites, icon: InviteIcon, disabled: true },
            { label: 'Duplicate', onClick: onDuplicateClick, icon: DuplicateIcon },
            { label: 'Delete', onClick: handleDeleteScript, icon: DeleteIcon },
            { divider: true },
            { label: 'Open Assessment', disabled: archived, onClick: activeAuditionsSlotsIsAvailable ? handleActivateDialog : onClickActivate, icon: ActiveIcon }
        ];

        if (companyHasCMS) {
            boardMenuItems.push({ label: 'Copy to CMS', onClick: handleCMSCopy, icon: DuplicateIcon });
        }

        if (smartRecruitersAssessmentShow && active && description) {
            boardMenuItems.push({ label: 'Send to SmartRecruiters', onClick: handleSendToSmartRecruiters, icon: PreviewIcon });
        }

        if (jobAdderAssessmentShow && active && isJobAdderAtsEnabled) {
            boardMenuItems.push({ label: 'Add Assessment to JobAdder', onClick: addToJobAdder, icon: PreviewIcon });
        }

        if (!jobAdderAssessmentShow && isJobAdderAtsEnabled) {
            boardMenuItems.push({ label: 'Delete Assessment from JobAdder', onClick: () => setDeleteFromJADialogOpen(true), icon: DeleteIcon });
        }

        if (jobViteAssessmentShow && active && isJobViteAtsEnabled) {
            boardMenuItems.push({ label: 'Add Assessment to Jobvite', onClick: addToJobVite, icon: PreviewIcon });
        }

        if (!jobViteAssessmentShow && isJobViteAtsEnabled) {
            boardMenuItems.push({ label: 'Delete Assessment from Jobvite', onClick: () => setDeleteFromJVDialogOpen(true), icon: DeleteIcon });
        }


        return (boardMenuItems);
    };

    let completionRate = 0;
    if (countRealCandidates) {
        completionRate = Math.round(numberOfCompletions / countRealCandidates * 100);
    }

    const onInsightsClick = () => {
        history.push(`/script/insights/${slug}`);
    };

    const handleDeactivateSuccess = (newInActivatedDate) => {
        setInActivateDate(newInActivatedDate);
        setActive(false);
        getAssessmentFilterNumbers();
        if (assessmentsFilter === 'open') removeAssessment();
    };

    const returnAssessmentStatusLabel = () => {
        if (archived) {
            return <div className={classes.label}>Archived</div>;
        }
        return (
            <div
                className={clsx(classes.label, active && classes.openLabel)}
            >
                {active ? 'Open' : 'Closed'}
            </div>
        );
    };

    return (
        <>
            <BoardItemWrapper
                className={classes.wrapper}
                onClick={goToAssessment}
            >
                <div className={classes.header}>
                    <div className={classes.boardPhotos}>
                        <BoardItemPhotos users={[owner].concat(employers)} extended />
                        <BoardItemMenu
                            options={getBoardMenuItems()}
                        />
                    </div>
                    <div className={classes.title}>
                        {name}
                    </div>
                </div>
                <div className={classes.contentBody}>
                    <InfoRow
                        className={classes.infoRow}
                        items={[
                            {
                                label: 'Candidates',
                                value: <span className="u-dsp--f">{countRealCandidates} <LockedCandidatesBlock lockedCandidates={lockedCandidates} /></span>,
                                width: countRealCandidates > 99 ? 105 : 80,
                                isSmallValueSize: countRealCandidates > EXTRA_BIG_COUNT
                            },
                            {
                                label: 'Completions',
                                value: numberOfCompletions,
                                rate: completionRate,
                                lastTime: completionRate,
                                width: numberOfCompletions > EXTRA_BIG_COUNT ? 155 : 150,
                                completed: getLastCompletedPeriod(completedPeriod),
                                isSmallValueSize: numberOfCompletions > EXTRA_BIG_COUNT
                            },
                            {
                                label: 'Hires',
                                value: countHiredCandidates,
                                width: countRealCandidates > 99 ? 55 : 80,
                                isSmallValueSize: countHiredCandidates > EXTRA_BIG_COUNT
                            }
                        ]}
                    />
                    <NotificationRow
                        className={classes.notificationRow}
                        notifications={[
                            {
                                title: `${requestedMoreTimeCount} extension requests.`,
                                actionText: 'Review requests',
                                action: (e) => {
                                    e.stopPropagation();
                                    history.push(`/script/invite/${slug}/invite-candidates`);
                                },
                                isActive: Boolean(requestedMoreTimeCount) && active
                            },
                            {
                                title: 'Hiring information required.',
                                actionText: 'Review hired',
                                action: (e) => {
                                    e.stopPropagation();
                                    history.push(`/script/select/${slug}`);
                                },
                                isActive: active && showRequiredHiringInformation
                            },
                            {
                                title: `No completions in ${getLastCompletionDays(completedPeriod)} days.`,
                                actionText: 'Review candidates',
                                action: (e) => {
                                    e.stopPropagation();
                                    history.push(`/script/invite/${slug}/invite-candidates`);
                                },
                                isActive: active && isNoCompletionsLastDays(completedPeriod)
                            }
                        ]}
                    />
                    {!active && (
                        <ClosedOverlay
                            archived={archived}
                            activateHandler={onClickActivate}
                            isLowPlan={!canActivateAssessmentWithCandidates}
                            countRealCandidates={countRealCandidates}
                        />
                    )}
                </div>
                <div className={clsx(classes.additionalInfo, classes.additionalInfoOverride)}>
                    <div className={classes.clickableArea}>
                        {
                            loadingActivate
                                ? <CircularProgress size={21} className={classes.progress} />
                                : returnAssessmentStatusLabel()
                        }

                        <span className={active ? classes.additionalInfoValue : classes.additionalInfoValueDisable}>
                            {active && activateDate && (
                                <Moment format="D MMM, YYYY">{activateDate}</Moment>
                            )}

                            {!active && inActivateDate && (
                                <Moment format="D MMM, YYYY">{inActivateDate}</Moment>
                            )}
                        </span>
                    </div>

                    <div className="u-dsp--f u-ai--center">
                        <RegenerateComponent
                            assessment={assessment}
                            onSuccess={handleDuplicate}
                        />
                        <InsightsButton
                            variant="text"
                            onClick={onInsightsClick}
                            assessment={assessment}
                            className={classes.insightsButton}
                        />
                        {hasFolders && <FoldersMenu {...{ auditionFolder, slug, uuid, name, removeAssessment, setAssessmentFolder }} />}
                        <NotificationButton
                            disabled={!active}
                            slug={assessment.slug}
                            enabled={notificationsEnabled}
                            onNotificationToggle={onNotificationToggle}
                        />
                    </div>
                </div>
            </BoardItemWrapper>
            <ActivateLimitDialog
                open={limitActivateDialogOpen}
                onClose={closeLimitActivateDialog}
            />
            <DeactivateAssessmentDialog
                open={deactivateOpen}
                onClose={handleDeactivateDialog}
                audition={assessment}
                onSuccess={handleDeactivateSuccess}
                flashMessage={flashMessage}
            />
            <DeactivateSurveyDialog
                open={deactivateSurveyOpen}
                onClose={handleDeactivateSurvey}
                assessment={assessment}
                onSuccess={handleDeactivateSuccess}
            />
            <DeactivateLinkedAssessmentDialog
                open={deactivateLinkedDialogOpened}
                onClose={() => { setDeactivateLinkedDialogOpened(false); }}
                audition={assessment}
            />
            <DeleteAssessmentFromJobAdderDialog
                open={deleteFromJADialogOpen}
                onClose={() => { setDeleteFromJADialogOpen(false); }}
                setJobAdderAssessmentShow={setJobAdderAssessmentShow}
                slug={slug}
                flashMessage={flashMessage}
            />
            <DeleteAssessmentFromJobViteDialog
                open={deleteFromJVDialogOpen}
                onClose={() => { setDeleteFromJVDialogOpen(false); }}
                setJobViteAssessmentShow={setJobViteAssessmentShow}
                slug={slug}
                flashMessage={flashMessage}
            />
            {
                canActivateAssessmentWithCandidates
                    ? (
                        <ActivateAssessmentDialog
                            open={activateDialogOpen}
                            onClickActivate={onClickActivateOnDialog}
                            onClickDuplicate={duplicateAndActivateHandler}
                            candidatesCount={countRealCandidates}
                            onClose={handleActivateDialog}
                        />
                    )
                    : (
                        <ActivateAssessmentLowPlanDialog
                            open={activateDialogOpen}
                            onClickActivate={onClickActivate}
                            onClose={handleActivateDialog}
                        />
                    )
            }
        </>
    );
});

export default withRouter(withStyles(stylesCommon)(withStyles(styles)(AdvancedAssessmentsContent)));

import React, { useEffect, useState, useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import Layout from 'components/layout';
import PageWrapper from 'components/layout/PageWrapper';
import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import { getTeamMembers } from 'requests/SettingsRequests';

import { appCtx } from 'components/appStore';
import SettingsHeader from '../settings_header';
import PausedLabel from '../PausedLabel';

import InviteTeamMemberButton from './InviteTeamMemberButton';
import TeamMembersTable from './TeamMembersTable';
import TeamMembersStatistics from './TeamMembersStatistics';
import TeamMembersLimitLabel from './TeamMembersLimitLabel';
import SecuritySettings from './SecuritySettings';

const TeamSettings = () => {
    const [loading, setLoading] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [pendingTeamMembers, setPendingTeamMembers] = useState([]);
    const countPendingTeamMembers = pendingTeamMembers.length;
    const countTeamMembers = teamMembers.filter(teamMember => teamMember.roleType !== 'integration').length;

    const { setTeamMembersUsed } = useContext(appCtx);

    useEffect(() => {
        loadTeamMembers();
    }, []);

    const loadTeamMembers = () => {
        setLoading(true);
        getTeamMembers()
            .then(({ data, success }) => {
                if (success) {
                    const { pendingMembers, teamMembers: newTeamMembers } = data;
                    setTeamMembers(newTeamMembers || []);
                    setPendingTeamMembers(pendingMembers || []);
                    const membersCount = [...pendingMembers || [], ...newTeamMembers || []].filter(member => member.roleType !== 'integration').length;
                    setTeamMembersUsed(membersCount);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <Layout>
            <PausedLabel />
            <SettingsHeader />
            <PageWrapper
                headerLabel="Team Members"
                headerActions={!loading && (
                    <InviteTeamMemberButton
                        loadTeamMembers={loadTeamMembers}
                        countTeamMembers={countTeamMembers}
                        countPendingTeamMembers={countPendingTeamMembers}
                    />
                )}
            >
                <ContentWrapper className="u-pdn--bx6">
                    <div className="u-txt--bold u-txt--16 u-mrg--bx4">Security Settings</div>
                    <SecuritySettings />
                    <div className="u-txt--bold u-txt--16 u-mrg--bx4">Team Members</div>
                    {
                        loading ? (
                            <div className="u-txt--center">
                                <CircularProgress className="u-mrg--tx3" />
                            </div>
                        ) : (
                            <>
                                <TeamMembersStatistics
                                    countTeamMembers={teamMembers.length}
                                    countActiveMembers={countTeamMembers}
                                    countPendingTeamMembers={countPendingTeamMembers}
                                />
                                <TeamMembersTable
                                    teamMembers={teamMembers}
                                    loadTeamMembers={loadTeamMembers}
                                    pendingTeamMembers={pendingTeamMembers}
                                />
                                <TeamMembersLimitLabel
                                    countTeamMembers={countTeamMembers}
                                    countPendingTeamMembers={countPendingTeamMembers}
                                />
                            </>
                        )
                    }
                </ContentWrapper>
            </PageWrapper>
        </Layout>
    );
};

export default TeamSettings;

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from 'libraries/SwitchBrandWithLabel';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import GeneralEvents from 'events/GeneralEvents';
import AssessmentEvents from 'events/AssessmentEvents';
import { clone, copyTextToClipboard } from 'helper/commonFunctions';
import { loadCandidateAssessmentForSharing, createSharedLink, createSharedLinkBatch } from 'requests/CandidateLinksRequests';
import Checkbox from 'libraries/Checkbox';
import CommonDialog from '../CommonDialog';

const getUrl = window.location;
const { protocol, host } = getUrl;

const styles = (theme => ({
    assessmentWrapper: {
        paddingLeft: 15,
        maxHeight: 140,
        marginBottom: 15,
        overflowY: 'auto',
        minHeight: 50,
        overflowX: 'hidden'
    },
    assessmentLabel: {
        marginLeft: 5,
        color: theme.palette.grey[500],
        fontStyle: 'italic'
    },
    assessmentLabelOpen: {
        color: theme.palette.green[400]
    }
}));


const ShareAFCandidateDialog = observer(({
    disabledScoring, open, onClose, company,
    flashMessage, shareCandidate,
    userUuid, classes, assessment, userIds, assessmentSlug
}) => {
    const baseUrl = `${protocol}//${host}`;
    const [gradeableCard, setGradeableCard] = useState(false);
    const [hideNameCard, setHideNameCard] = useState(false);
    const [loadingAssessments, setLoadingAssessments] = useState(false);
    const [shareAll, setShareAll] = useState(false);
    const [assessmentList, setAssessmentList] = useState([]);
    const [checkedAssessments, setCheckedAssessments] = useState({});
    const [sharedLink, setSharedLink] = useState('');

    useEffect(() => {
        if (open && userIds.length === 1) {
            loadAssessmentsList();
        }
        if (!open) {
            setDefault();
            setSharedLink('');
            setCheckedAssessments({});
        }
    }, [open, userIds.length]);

    useEffect(() => {
        if (!assessment || !open) return;
        const { name, uuid } = assessment;
        setCheckedAssessments({ ...checkedAssessments, [uuid]: name });
    }, [open, assessment]);

    useEffect(() => {
        setGradeableCard(false);
        setSharedLink('');
    }, [userIds.length]);

    useEffect(() => {
        setSharedLink('');
    }, [shareAll, gradeableCard, hideNameCard, checkedAssessments]);

    useEffect(() => {
        if (sharedLink) {
            copyTextToClipboard(sharedLink, () => {
                makeEvent();
                if (shareCandidate) shareCandidate({ isShared: true });
                flashMessage('Candidate report card link copied to clipboard', 'done');
            });
        }
    }, [sharedLink]);

    const loadAssessmentsList = () => {
        if (!userIds[0]) return;
        setLoadingAssessments(true);
        loadCandidateAssessmentForSharing(userIds[0])
            .then(({ data, success }) => {
                if (data && success) {
                    setAssessmentList(data);
                }
            })
            .finally(() => {
                setLoadingAssessments(false);
            });
    };

    const setDefault = () => {
        setGradeableCard(false);
        setHideNameCard(false);
        setShareAll(false);
    };

    const makeEvent = () => {
        if (userIds.length === 1) {
            const ttUUID = [];
            const ttName = [];
            Object
                .keys(checkedAssessments)
                .forEach((key) => {
                    ttName.push(checkedAssessments[key]);
                    ttUUID.push(key);
                });

            GeneralEvents.CANDIDATE_SHARED({
                scoreEnabled: gradeableCard,
                candidateId: userUuid || userIds,
                nameHidden: hideNameCard,
                shareAllAssessments: shareAll,
                ttName,
                ttUUID
            });
        } else {
            const { name: ttName, uuid: ttId } = assessment;
            AssessmentEvents.CANDIDATES_BATCH_ACTIONED({
                ttId,
                ttName,
                actionType: 'share',
                numberCandidatesActioned: userIds.length
            });
        }
    };

    const onCopy = () => {
        if (!userIds.length) return;

        if (sharedLink) {
            copyTextToClipboard(sharedLink, () => { flashMessage('Candidate report card link copied to clipboard', 'done'); });
            return;
        }

        flashMessage('Loading', 'pending');
        let promise;

        if (userIds.length === 1) {
            const userId = userIds[0];
            promise = createSharedLink(userId, {
                abilityGuestScoring: gradeableCard,
                forAllAssessments: shareAll,
                hiddenCandidateDetails: hideNameCard,
                auditionUuids: Object.keys(checkedAssessments)
            });
        } else {
            promise = createSharedLinkBatch(assessmentSlug, {
                abilityGuestScoring: gradeableCard,
                hiddenCandidateDetails: hideNameCard,
                candidatesIds: userIds
            });
        }

        promise
            .then(({ success, data }) => {
                if (success && data && data.uuid) {
                    const newSharedLink = `${baseUrl}/candidates/${data.uuid}/shared-card`;
                    setSharedLink(newSharedLink);
                }
            })
            .catch((error) => {
                flashMessage('Something went wrong. Try again.', '');
                console.log(error);
            });
    };

    const onChangeSwitch = () => {
        setGradeableCard(!gradeableCard);
    };

    const handleChangeHideSwitch = () => {
        setHideNameCard(!hideNameCard);
    };

    const handleChangeShareAllSwitch = () => {
        if (shareAll && assessment) {
            const { name, uuid } = assessment;
            setCheckedAssessments({ [uuid]: name });
        }
        setShareAll(!shareAll);
    };

    const onChangeAssessment = (e) => {
        const { value } = e.target;
        if (value in checkedAssessments) {
            const newCheckedAssessments = clone(checkedAssessments);
            delete newCheckedAssessments[value];
            setCheckedAssessments(newCheckedAssessments);
        } else {
            if (Object.keys(checkedAssessments).length + 1 === assessmentList.length) {
                setShareAll(true);
            }
            const { name } = assessmentList.find(({ uuid }) => uuid.toString() === value);
            setCheckedAssessments({ ...checkedAssessments, [value]: name });
        }
    };


    const returnAssessmentStatusLabel = ({ active, archived }) => {
        if (archived) {
            return <span className={classes.assessmentLabel}>(archived)</span>;
        }
        return (
            <span className={clsx(classes.assessmentLabel, active && classes.assessmentLabelOpen)}>
                ({active ? 'open' : 'closed'})
            </span>
        );
    };

    return (
        <CommonDialog
            open={open}
            onClose={onClose}
            onCopy={onCopy}
            company={company}
            onChangeSwitch={onChangeSwitch}
            flashMessage={flashMessage}
            gradeableCard={gradeableCard}
            disabledScoring={disabledScoring}
            sharedLink={sharedLink}
            disableCopyButton={!Object.keys(checkedAssessments).length && !shareAll}
        >
            <Switch
                classNameLabel="u-dsp--f u-ai--center"
                label={(
                    <>
                        Hide candidates details
                        <InfoTooltip
                            className="u-txt--12 u-txt--normal"
                            isBlack
                            classNameIcon="u-txt--lh1"
                            text="Hide candidates names and allow viewers to view or grade without bias"
                        />
                    </>
                )}
                checked={hideNameCard}
                className="u-mrg--tx0 u-mrg--bx1"
                onChange={handleChangeHideSwitch}
            /><br />
            {
                userIds.length === 1 && (
                    <>
                        <Switch
                            classNameLabel="u-dsp--f u-ai--center"
                            label={(
                                <>
                                    Share all assessments
                                    <InfoTooltip
                                        className="u-txt--12 u-txt--normal"
                                        isBlack
                                        classNameIcon="u-txt--lh1"
                                        text="If candidate has completed multiple assessments allow selection of those to share"
                                    />
                                </>
                            )}
                            checked={shareAll}
                            className="u-mrg--tx0 u-mrg--bx1"
                            onChange={handleChangeShareAllSwitch}
                        />
                        {
                            !shareAll && (
                                <div className={classes.assessmentWrapper}>
                                    {
                                        assessmentList.map(({ name, active, uuid, archived }) => (
                                            <Checkbox
                                                label={(
                                                    <>
                                                        {name}
                                                        {returnAssessmentStatusLabel({ active, archived })}
                                                    </>
                                                )}
                                                value={uuid}
                                                checked={uuid in checkedAssessments}
                                                className="u-mrg--bx0"
                                                classNameLabel="u-txt--semibold"
                                                onChange={onChangeAssessment}
                                            />
                                        ))
                                    }
                                    { loadingAssessments && <CircularProgress size={22} /> }
                                </div>)
                        }
                    </>
                )
            }
        </CommonDialog>
    );
});

export default withStyles(styles)(ShareAFCandidateDialog);

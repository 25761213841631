import React from 'react';
import TableFilter from 'components/table_components/table_filter';
import CustomMenuItem from 'components/table_components/table_filter/table_filter_item';
import FlagIcon from 'components/grade_components/FlagIcon';

const ActiveLearningFilter = ({
    filterStats: { all, poor, average, excellent, flagged },
    filterFlagged, setFilterFlagged, filterSection, setFilterSection, setPage
}) => {
    const options = [{
        value: 'all',
        label: 'Show all',
        count: all || 0
    }, {
        value: 'poor',
        label: 'Poor',
        count: poor
    }, {
        value: 'average',
        label: 'Average',
        count: average
    }, {
        value: 'excellent',
        label: 'Excellent',
        count: excellent
    }];

    options.forEach((filterOption) => { filterOption.checked = filterSection === filterOption.value; });

    const handleChange = (value) => {
        setPage(1);
        if (value === 'flagged') setFilterFlagged(Number(!filterFlagged));
        else setFilterSection(value);
    };

    return (
        <TableFilter
            isFilterActive={filterSection !== 'all' || filterFlagged}
            innerHeader="Experience"
            options={options}
            showCountNumber
            isCheckbox={false}
            handleChange={handleChange}
        >
            <CustomMenuItem
                value="flagged"
                label="Flagged"
                showCountNumber
                handleChange={handleChange}
                count={flagged}
                CustomIconComp={(
                    <FlagIcon
                        size={21}
                        className="u-pdn--x0 u-mrg--rx2"
                        filled={!!filterFlagged}
                    />
                )}
            />
        </TableFilter>
    );
};

export default ActiveLearningFilter;

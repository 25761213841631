import React, { useState, useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import SearchField from 'components/inputs/SearchField';
import clsx from 'clsx';
import styles from './styles';
import { skillsCtx } from '../../store';

const SkillsSearchField = observer(({ classes }) => {
    const { query, handleChangeSearch } = useContext(skillsCtx);
    const [searchFocused, setSearchFocused] = useState(false);

    return (
        <SearchField
            placeholder="Search Skills"
            className={clsx(classes.searchField, classes.search, searchFocused && classes.searchFieldFocused)}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => setSearchFocused(false)}
            onChange={handleChangeSearch}
            value={query}
        />
    );
});

export default withStyles(styles)(SkillsSearchField);

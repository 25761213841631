import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    dotsWrapper: {
        fontSize: props => props.fontSize,
        maxWidth: props => props.fontSize * 0.82
    }
});


const CalculatingLabel = ({ className = '', label = 'Calculating AI score', fontSize = 11, ...other }) => {
    const { dotsWrapper } = useStyles({ fontSize });
    return (
        <div className={`u-dsp--f u-jc--start ${className}`} {...other}>
            {label}
            <div className={dotsWrapper}>
                <div className="c-card__ai-score-dots">...</div>
            </div>
        </div>
    );
};

export default CalculatingLabel;

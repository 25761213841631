import React, { useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';

import TextInput from 'libraries/TextInput';
import EmptyIcon from 'img/emptyDetailsIcon.svg';
import RemoveIcon from 'img/remove.svg';
import Chevron from 'img/chevron_active.svg';

import styles from './styles';


const AnswersMenu = observer(({
    classes, onChange: handleChangeExpectedResults, isError, maxLength, testCase: { expectedResults }, helperText
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [newResult, setNewResult] = useState('');

    const open = Boolean(anchorEl);
    const popoverId = open ? 'popover' : undefined;

    const openPopover = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handlePopoverClose = () => {
        setNewResult('');
        setAnchorEl(null);
    };

    const onChangeNewResult = (e) => {
        setNewResult(e.target.value);
    };

    const addNewResult = async () => {
        setLoading(true);
        const newResults = expectedResults.slice();
        newResults.push(newResult);
        await handleChangeExpectedResults({ target: { name: 'expectedResults', value: newResults } });
        setNewResult('');
        setLoading(false);
    };

    const deleteResult = async (e, index) => {
        setLoading(true);
        const newResults = expectedResults.slice();
        newResults.splice(index, 1);
        await handleChangeExpectedResults({ target: { name: 'expectedResults', value: newResults } });
        setLoading(false);
    };

    const handleChangeResult = async (e, index) => {
        setLoading(true);
        const newResults = expectedResults.slice();
        newResults[index] = e.target.value;
        await handleChangeExpectedResults({ target: { name: 'expectedResults', value: newResults } });
        setLoading(false);
    };

    return <>
        <div className={classes.btnWrapper}>
            <Button
                variant="outlined"
                onClick={openPopover}
                className={clsx(classes.buttonFakeSelect, isError && classes.buttonErr)}
            >
                <div className={classes.innerWrapper}>
                    Correct Values
                    <img src={Chevron} className="u-mrg--lx2" alt="v" />
                </div>
            </Button>
            <span className={clsx(classes.helperText, isError && classes.buttonErr)}>{helperText}</span>
        </div>
        <Popover
            id={popoverId}
            open={open}
            className={classes.popoverRoot}
            classes={{ paper: classes.popoverPaper }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            anchorEl={anchorEl}
            onClick={e => e.stopPropagation()}
            onClose={handlePopoverClose}
        >
            <div className={classes.popoverHeader}>
                <Typography variant="h6">Correct Values</Typography>
            </div>
            <div className={classes.popoverContent}>
                {
                    loading && (
                        <div className={classes.loaderWrapper}>
                            <CircularProgress size={35} color="primary" />
                        </div>
                    )
                }
                {!expectedResults.length && (
                    <div className={classes.emptyBoard}>
                        <img src={EmptyIcon} alt="empty-content" /> <br />
                        <Typography variant="h6">
                            No correct values added
                        </Typography>
                    </div>
                )}
                <List className={classes.list}>
                    { expectedResults.map((result, index) => (
                        <ListItem className={classes.listItem}>
                            <TextInput
                                variant="outlined"
                                className={classes.skillInput}
                                onChange={e => handleChangeResult(e, index)}
                                value={result}
                                maxLength={maxLength}
                            />
                            <IconButton
                                onClick={e => deleteResult(e, index)}
                                className={classes.deleteIcon}
                                size="large"
                            >
                                <img src={RemoveIcon} alt="x" />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </div>
            <div className={classes.addSection}>
                <TextInput
                    variant="outlined"
                    placeholder="Correct value"
                    className={classes.skillInput}
                    onChange={onChangeNewResult}
                    value={newResult}
                    maxLength={maxLength}
                />
                <Button
                    disabled={!newResult.length}
                    className="u-txt--bold"
                    onClick={addNewResult}
                    color="primary"
                >
                    Add
                </Button>
            </div>
        </Popover>
    </>;
});

export default withStyles(styles)(AnswersMenu);

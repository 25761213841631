import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import WarningBanner from 'components/assessments_pages/WarningBanner';
import { appCtx } from 'components/appStore';

const PausedLabel = observer(() => {
    const { company: { isSubscriptionPaused }, loggedUser } = useContext(appCtx);
    if (!isSubscriptionPaused) return null;
    const { roleType } = loggedUser;

    return (
        <WarningBanner
            className="u-mrg--tx0 u-mrg--bx0"
            label={(
                <>
                    <b>Your account subscription has been paused.</b>
                    {roleType !== 'team' && (<>&nbsp;To reactivate visit your <Link to="/billing">Account Billing</Link></>)}
                </>
            )}
        />
    );
})

export default PausedLabel;

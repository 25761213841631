import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Switch from 'libraries/SwitchBrandWithLabel';
import {
    setCompanyDailyDigest,
    setProductNews,
    setBlogArticles,
    getEmployerNotifications
} from 'requests/SettingsRequests';
import SettingsEvents from 'events/SettingsEvents';
import Layout from 'components/layout';
import PageWrapper from 'components/layout/PageWrapper';
import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';

import SettingsHeader from '../settings_header';
import PausedLabel from '../PausedLabel';

import styles from './styles';

class SettingsNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employerCompanyDailyDigest: false,
            productNews: false,
            blogArticles: false
        };
    }

    componentDidMount() {
        this.getEmployerNotifications();
    }

    getEmployerNotifications = () => {
        getEmployerNotifications()
            .then(({ data, success }) => {
                if (success && data) {
                    const {
                        productNews,
                        blogArticles,
                        employerCompanyDailyDigest
                    } = data;
                    this.setState({
                        blogArticles,
                        employerCompanyDailyDigest,
                        productNews
                    });
                }
            });
    };

    handleToggleDaily = (e) => {
        e.preventDefault();
        const { employerCompanyDailyDigest } = this.state;
        const { loggedUser: { uuid: userId } } = this.props;
        const newEmployerCompanyDigest = !employerCompanyDailyDigest;

        setCompanyDailyDigest(newEmployerCompanyDigest)
            .then((data) => {
                if (data.success) {
                    const { flashMessage, company } = this.props;
                    const updatedCompany = JSON.parse(JSON.stringify(company));
                    updatedCompany.employer_company_daily_digest = newEmployerCompanyDigest;
                    this.setState({ employerCompanyDailyDigest: newEmployerCompanyDigest });
                    flashMessage('Updated successfully');
                    SettingsEvents.DAILY_DIGEST_NOTIFICATIONS_CHANGED({ userId, enabled: newEmployerCompanyDigest });
                }
            });
    };

    handleProductNews = (e) => {
        e.preventDefault();
        const { productNews } = this.state;
        const { loggedUser: { uuid: userId } } = this.props;
        const newProductNews = !productNews;

        setProductNews(newProductNews)
            .then((data) => {
                if (data.success) {
                    const { updateLoggedUser, flashMessage, loggedUser } = this.props;
                    const updatedUser = JSON.parse(JSON.stringify(loggedUser));
                    updatedUser.product_news = newProductNews;
                    updateLoggedUser(updatedUser);
                    this.setState({ productNews: newProductNews });
                    flashMessage('Updated successfully');
                    SettingsEvents.NEWS_NOTIFICATIONS_CHANGED({ userId, enabled: newProductNews });
                }
            });
    };

    handleBlogArticles = (e) => {
        e.preventDefault();
        const { blogArticles } = this.state;
        const { loggedUser: { uuid: userId } } = this.props;
        const newBlogArticles = !blogArticles;

        setBlogArticles(newBlogArticles)
            .then((data) => {
                if (data.success) {
                    const { updateLoggedUser, flashMessage, loggedUser } = this.props;
                    const updatedUser = JSON.parse(JSON.stringify(loggedUser));
                    updatedUser.blog_articles = newBlogArticles;
                    updateLoggedUser(updatedUser);
                    this.setState({ blogArticles: newBlogArticles });
                    flashMessage('Updated successfully');
                    SettingsEvents.BLOG_NOTIFICATIONS_CHANGED({ userId, enabled: newBlogArticles });
                }
            });
    };

    render() {
        const { classes } = this.props;
        const {
            employerCompanyDailyDigest,
            productNews, blogArticles
        } = this.state;

        return (
            <Layout>
                <PausedLabel />
                <SettingsHeader />
                <PageWrapper
                    headerLabel="Email Notifications"
                    className="u-z--1  u-pos--relative"
                >
                    <ContentWrapper>
                        <div className={classes.description}>
                            Notifications allow your administrators to have global updates to account activity and daily assessment summaries.<br />
                            Individual notifications can be turned off at the assessment level.
                        </div>
                        <div className={classes.innerWrapper}>
                            <Switch
                                label="Daily digest"
                                value="daily_digest"
                                classNameLabel={classes.switchFormLabel}
                                checked={employerCompanyDailyDigest}
                                onChange={this.handleToggleDaily}
                            />
                            <Switch
                                label="Stay up to date with product news"
                                value="product_news"
                                classNameLabel={classes.switchFormLabel}
                                checked={productNews}
                                onChange={this.handleProductNews}
                            />
                            <Switch
                                label="Subscribe to blog articles"
                                value="blog_articles"
                                classNameLabel={classes.switchFormLabel}
                                checked={blogArticles}
                                onChange={this.handleBlogArticles}
                            />
                        </div>
                    </ContentWrapper>
                </PageWrapper>
            </Layout>
        );
    }
}

export default withStyles(styles)(SettingsNotifications);

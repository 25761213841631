import React from 'react';
import { observer } from 'mobx-react-lite';
import TypeWrapper from './components/TypeWrapper';
import TypesFactory from './components/TypesFactory';

const ALLOW_SYMBOLS = 25000;

const AnswerAttachment = observer(({
    id, type, file, ziggeo, question,
    codeEditorValue, isUploadingFile, handleChangeToken,
    onDrop, handleChangeCodeEditor, allowedLength = ALLOW_SYMBOLS,
    value, onChange, handleChangeType, placeholder, label, className,
    isError, isQuestion
}) => (
    <TypeWrapper
        question={question}
        isQuestion={isQuestion}
        isUploadingFile={isUploadingFile}
        questionFile={file}
        valueType={type}
        allowedLength={allowedLength}
        handleChangeType={handleChangeType}
        value={value}
        label={label}
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        isError={isError}
    >
        <TypesFactory
            id={id}
            type={type}
            file={file}
            onDrop={onDrop}
            ziggeo={ziggeo}
            isUploadingFile={isUploadingFile}
            codeEditorValue={codeEditorValue}
            handleChangeToken={handleChangeToken}
            handleChangeCodeEditor={handleChangeCodeEditor}
        />
    </TypeWrapper>
));

export default AnswerAttachment;

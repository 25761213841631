import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Header from './Header';

const styles = {
    content: {
        minHeight: 'calc(100vh - 64px - 170px)'
    }
};

const Layout = ({ classes, header = <Header />, contentClassName = '', children, hideHeader = false }) => (
    <>
        {!hideHeader && header}
        <Toolbar />
        <Box component="main" className={clsx(classes.content, contentClassName)}>
            {children}
        </Box>
    </>
);

export default withStyles(styles)(Layout);

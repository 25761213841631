import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import CandidatesCount from 'components/table_components/candidates_count';
import BoardNoContent from 'components/board/board_no_content';
import { CANDIDATES_PER_RESULTS_PAGE } from 'helper/constants';

import CandidatesTable from './components/CandidatesTable';
import SearchFilterBlock from '../../../components/SearchFilterBlock';
import SortLabels from '../../common/SortLabels';
import BatchBlock from '../../../components/BatchBlock';
import NotFoundContent from '../../../../../components/assessments_pages/NotFoundContent';
import TagsList from '../../../components/TagsList';
import { candidateResultCtx } from '../../common/CandidateListWrapper/candidatesStore';
import EmptyIcon from '../../common/NoInvitationsContent/icon.svg';
import ResultsTableFilter from '../../common/ResultsTableFilter';
import { statsCtx } from '../../../statsStore';

const useStyles = makeStyles(() => ({
    board: {
        minHeight: 330,
        paddingTop: 0,
        paddingBottom: 70
    },
    title: {
        margin: '0 12px 0 0'
    }
}));

const CandidatesList = observer(() => {
    const {
        totalCount, allCandidates,
        selectedCandidates, query,
        page, isLoading, filterCategory,
        handleChangeFilterSelect, handleRemoveFilterSelect,
        filterTagsCategory, filterPlagiarismCategory,
        clearSelection, selectedUserAuditionsIds,
        selectedCandidatesIds, selectedColumnUserIds,
        loadCandidates, loadFiltersStats
    } = useContext(candidateResultCtx);
    const classes = useStyles();
    const { loadAssessmentStats } = useContext(statsCtx);

    const returnNoContentBoard = () => {
        if (totalCount) return null;
        if (query) return <NotFoundContent searchValue={query} />;
        if (Object.keys(filterCategory).length) {
            const category = Object.keys(filterCategory)[0];
            const displayedCategoryName = category[0].toUpperCase() + category.slice(1);
            const proposedCategory = category === 'active' ? 'all' : 'active';
            return (
                <BoardNoContent
                    header={`No ${displayedCategoryName} Candidates`}
                    buttonLabel={`Review ${proposedCategory} candidates`}
                    hideIconWrapper
                    className={classes.board}
                    onClick={() => handleChangeFilterSelect(proposedCategory, false)}
                    icon={<img src={EmptyIcon} alt="empty-content" />}
                />
            );
        }
    };

    const onSuccess = () => {
        loadFiltersStats();
        loadAssessmentStats();
    };

    return (
        <div>
            {
                Object.keys(selectedCandidates).length ? (
                    <BatchBlock
                        {...{
                            clearSelection,
                            selectedCandidates,
                            selectedUserAuditionsIds,
                            selectedCandidatesIds,
                            selectedColumnUserIds,
                            loadCandidates,
                            onSuccess
                        }}
                    />
                ) : (
                    <SearchFilterBlock
                        context={candidateResultCtx}
                        TableFilterComp={(
                            <ResultsTableFilter
                                filterCategory={filterCategory}
                                handleChangeFilterSelect={handleChangeFilterSelect}
                            />
                        )}
                    />
                )
            }
            <div className="u-dsp--f u-ai--start u-jc--sb">
                <div className="u-dsp--f u-mrg--bx2 u-ai--start">
                    <div className="u-dsp--f u-ai--baseline">
                        <Typography variant="h5" className={classes.title}>
                            Candidates
                        </Typography>
                        {
                            !isLoading && (
                                <CandidatesCount
                                    className="u-mrg--bx0"
                                    candidatesPerPage={CANDIDATES_PER_RESULTS_PAGE}
                                    page={page}
                                    candidatesLength={totalCount}
                                    candidatesFound={allCandidates.length}
                                    label="Completions"
                                />
                            )
                        }
                    </div>
                    <TagsList {...{ filterTagsCategory,
                        filterCategory,
                        filterPlagiarismCategory,
                        handleRemoveFilterSelect,
                        handleChangeFilterSelect
                    }}
                    />
                </div>
                <SortLabels />
            </div>
            <CandidatesTable />
            { returnNoContentBoard() }
        </div>
    );
});

export default CandidatesList;

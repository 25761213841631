import React from 'react';
import { withTheme } from '@mui/styles';

const CompareIcon = ({ isActive }) => {
    if (isActive) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="3" width="7.5" height="7.5" rx="1" fill="#00B898" />
                <rect x="2" y="14.25" width="7.5" height="7.5" rx="1" fill="#00B898" />
                <rect x="14.5" y="14.25" width="7.5" height="7.5" rx="1" fill="#00B898" />
                <circle cx="18" cy="6.75" r="3.75" fill="#00B898" />
            </svg>
        );
    }

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="3" width="7.5" height="7.5" rx="1" stroke="#A9AAAE" strokeWidth="1.2" />
            <rect x="2" y="14.25" width="7.5" height="7.5" rx="1" stroke="#A9AAAE" strokeWidth="1.2" />
            <rect x="14.5" y="14.25" width="7.5" height="7.5" rx="1" stroke="#A9AAAE" strokeWidth="1.2" />
            <circle cx="18" cy="6.75" r="3.75" stroke="#A9AAAE" strokeWidth="1.2" />
        </svg>

    );
};

export default withTheme(CompareIcon);

export default theme => ({
    saveChangesButton: {
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    talkToUsLabel: {
        display: 'block',
        textDecoration: 'underline',
        marginTop: 12,
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: 0.2,
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'underline'
        }
    },
    tooltip: {
        lineHeight: 1.4,
        padding: 20,
        maxWidth: 350
    }
});

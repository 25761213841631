import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '18px 10px 18px 16px',
        cursor: 'pointer',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        '&:hover': {
            background: '#F4F4F7'
        },
        '&:last-child': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        }
    },
    selectedWrapper: {
        background: '#F4F4F7'
    },
    header: {
        paddingLeft: 20,
        paddingTop: 32
    },
    compareWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        fontWeight: 700,
        padding: '14px 10px 14px 20px'
    },
    item: {
        lineHeight: 1.2,
        marginLeft: -4,
        marginTop: 5
    },
    itemNameInactive: {
        color: theme.palette.grey[600]
    },
    itemEmail: {
        fontWeight: 600
    },
    itemEmailInactive: {
        color: theme.palette.grey[400]
    },
    compareWrapperActive: {
        color: theme.palette.primary.main
    },
    hiddenBlock: {
        height: 13,
        borderRadius: 30,
        backgroundColor: theme.palette.grey[300],
        width: 160
    },
    icon: {
        fontSize: 12,
        color: theme.palette.grey[600]
    },
    list: {
        maxHeight: 'calc(100vh - 140px)',
        overflowY: 'auto'
    }
}));

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { AUDIO, VIDEO, TEXT, SIMULATION_GROUP } from 'helper/constants';
import { convertHtmlToStringWithSpaces } from 'helper/commonFunctions';
import { modifyScreeningQuestion } from 'helper/questionSettingsFunctions';

import TimerInfo from './TimerInfo';
import QuestionErrors from './QuestionErrors';
import BoxHeader from './BoxHeader';

import { assessmentEditCtx } from '../../store';

import styles from './styles';


const SidebarBox = observer(({ question: questionProps, audition, classes, questionIndex, isScreening = false }) => {
    const {
        questions, selectQuestion,
        activeQuestionIndex: activeQuestion,
        isSelectedQuestionScreening
    } = useContext(assessmentEditCtx);

    const question = modifyScreeningQuestion(questionProps);

    const {
        missingAnswer, answerType,
        isAuthorLogged,
        valid, simulationDescription,
        description, template,
        exampleAnswers,
        missingQuestion,
        timerValid, manualGradesRequired,
        simulationCandidateQuestionInstructions
    } = question;

    const { scoreType } = audition;

    const isActive = questionIndex === activeQuestion && isScreening === isSelectedQuestionScreening;
    const ifMissingForAnswer = (missingAnswer) || (!answerType && !isAuthorLogged);

    const isMediaAnswer = answerType === AUDIO || answerType === VIDEO;
    const hasNoExampleAnswers = (isMediaAnswer || answerType === TEXT) && (exampleAnswers && !exampleAnswers.length);
    const hasErrors = isScreening ? !valid : (!valid || ifMissingForAnswer || (hasNoExampleAnswers && !manualGradesRequired) || !timerValid || missingQuestion);
    const missingSimulationInstruction = SIMULATION_GROUP.types.includes(answerType)
        && !simulationCandidateQuestionInstructions;
    const shownDescription = description || simulationDescription;

    return (
        <div>
            <div
                role="presentation"
                onClick={() => selectQuestion(questionIndex + 1, 0, isScreening)}
                className={clsx(classes.box, isActive && classes.boxActive, hasErrors && !template && classes.boxError)}
                id={isActive ? 'active_question' : ''}
            >
                <BoxHeader
                    isActive={isActive}
                    hasNoExampleAnswers={hasNoExampleAnswers}
                    questionIndex={questionIndex}
                    question={question}
                    hasErrors={hasErrors}
                    questions={questions}
                    scoreType={scoreType}
                    className={classes.gradient}
                    isScreening={isScreening}
                />
                {
                    template ? (
                        <div className={classes.descriptionTemplate}>
                            Add a question for this skill
                        </div>
                    ) : (
                        <div className={classes.description}>
                            {convertHtmlToStringWithSpaces(shownDescription)}
                        </div>
                    )
                }
                <QuestionErrors
                    question={question}
                    hasErrors={hasErrors}
                    isScreening={isScreening}
                    ifMissingForAnswer={ifMissingForAnswer}
                    hasNoExampleAnswers={hasNoExampleAnswers}
                    missingSimulationInstruction={missingSimulationInstruction}
                />
                <TimerInfo
                    audition={audition}
                    question={question}
                />
            </div>
        </div>
    );
});

export default withStyles(styles)(SidebarBox);

import React from 'react';
import { makeStyles } from '@mui/styles';
import { AI_HEALTH_STATUS_HIGH } from 'helper/constants';
import InsightsModelItem from './InsightsItem';

const useStyles = makeStyles(theme => ({
    intro: {
        fontSize: 14,
        marginBottom: 25,
        color: theme.palette.grey[900]
    }
}));

const QuestionInsights = ({ suggestedAnswerAdded, status }) => {
    const classes = useStyles();

    const modelItems = [{
        heading: 'How Model',
        active: true,
        explanation: 'This model reviews and analyzes the way your candidates interact with the assessment including time to complete, keystrokes and movement through the questions.'
    }, {
        heading: 'Correct Answers',
        active: suggestedAnswerAdded,
        explanation: 'These are used to benchmark candidate\'s responses and helps the AI understand what a strong response is for this type of question.'
    }, {
        heading: 'What Model',
        active: status >= AI_HEALTH_STATUS_HIGH,
        explanation: 'Looks at the content and quality of a candidates response and benchmarks it against other poor, average and excellent responses to the same question.'
    }
    // {
    //     heading: 'Personalized Model',
    //     active: true,
    //     explanation: 'Your personalized AI model is activated when you grade candidate responses across the range 0 - 10. Your input trains the AI to grade exactly as you would for future candidates.'
    // }
    ];

    return <>
        <div className={classes.intro}>
            An overview of how your candidates are scored by our AI models.&nbsp;
            To increase the accuracy of your overall scores ensure all models are activated.
        </div>
        {modelItems.map(modelItem => <InsightsModelItem {...modelItem} />)}
    </>;
};

export default QuestionInsights;

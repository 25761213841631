import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/Tooltip';
import TrashIcon from 'components/icons/TrashIcon';
import SettingsIcon from 'components/icons/SettingsIcon';
import Typography from '@mui/material/Typography';
import { publish } from 'helper/event';
import DeleteQuestionDialog from './DeleteQuestionDialog';
import SkillWarning from './SkillWarning';
import styles from './styles';

const EditQuestionHeader = ({ activeQuestion, question, classes, deleteQuestion, savingQuestion }) => {
    const [deleteQuestionDialogOpen, setDeleteQuestionDialog] = useState(false);

    const handleDeleteQuestionDialog = () => {
        setDeleteQuestionDialog(!deleteQuestionDialogOpen);
    };

    const publishScrollEvent = () => {
        publish('goToView');
    };

    if (!question) return null;
    const { companySkills } = question;

    return <>
        { !savingQuestion && <SkillWarning companySkills={companySkills} /> }
        <div className={classes.questionHeader}>
            <Typography variant="h3">Question</Typography>
            <div className="u-dsp--centered">
                <Tooltip
                    label="Question Settings"
                >
                    <div>
                        <IconButton onClick={publishScrollEvent} size="large">
                            <SettingsIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <Tooltip
                    label="Delete question"
                >
                    <div>
                        <IconButton onClick={handleDeleteQuestionDialog} size="large">
                            <TrashIcon />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
            <DeleteQuestionDialog
                question={question}
                open={deleteQuestionDialogOpen}
                deleteQuestion={deleteQuestion}
                questionIndex={activeQuestion}
                onClose={handleDeleteQuestionDialog}
            />
        </div>
    </>;
};

export default withStyles(styles)(EditQuestionHeader);

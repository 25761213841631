import React from 'react';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({
    children, id,
    position = 'bottom',
    className = '',
    effect = 'solid',
    delayHide, delayShow, offset,
    style, ...other
}) => (
    <ReactTooltip
        id={id}
        style={style}
        place={position}
        offset={offset}
        delayShow={delayShow}
        delayHide={delayHide}
        effect={effect}
        type="light"
        className={`c-tooltip_white ${className}`}
        {...other}
    >
        {children}
    </ReactTooltip>
);

export default Tooltip;

import React from 'react';


const DocumentIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M23.7261 28.7332H7.33398C6.56079 28.7332 5.93398 28.1064 5.93398 27.3332V4.6665C5.93398 3.8933 6.56078 3.2665 7.33398 3.2665H20.1918C20.5631 3.2665 20.9192 3.414 21.1818 3.67655L24.7161 7.21086C24.9786 7.47342 25.1261 7.82951 25.1261 8.20081V27.3332C25.1261 28.1064 24.4993 28.7332 23.7261 28.7332Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.4512 3.33301V8.94091H24.6668" stroke={color} strokeWidth="1.2" strokeLinejoin="round" />
        <path d="M10.0391 13.647H21.0195" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0391 16.7842H21.0195" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0391 19.9214H21.0195" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0391 23.0586H16.3136" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default DocumentIcon;

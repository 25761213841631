import { get, post } from '../helper/API';


export function shareCandidateProfile(userId, isShared, hideScores) {
    return post('employer/share-candidate-profile', {
        userId,
        isShared,
        hideScores
    });
}

export function createSharedLink(userId, data) {
    return post(`employer/candidates/${userId}/candidate-card-link/create`, data);
}

export function createSharedLinkBatch(slug, data) {
    return post(`employer/auditions/${slug}/candidate-card-link/create`, data);
}

export function loadCandidateAssessmentForSharing(id) {
    return get(`employer/candidates/${id}/auditions`);
}

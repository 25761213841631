import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    menuIcon: {
        transform: 'rotate(90deg)',
        marginLeft: 2
    },
    tooltipLink: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    }
}));

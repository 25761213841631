
export const MAX_FUNCTION_NAME_LENGTH = 250;
export const MAX_FUNCTION_PARAMETERS = 10;

export const typeOptions = [
    'INTEGER',
    'FLOAT',
    'STRING',
    'BOOLEAN',
    'LONG_INTEGER',
    'DOUBLE',
    'CHARACTER',
    'INTEGER_ARRAY',
    'STRING_ARRAY',
    'LONG_INTEGER_ARRAY',
    'FLOAT_ARRAY',
    'DOUBLE_ARRAY',
    'CHARACTER_ARRAY',
    'BOOLEAN_ARRAY',
    'INTEGER_2D_ARRAY',
    'STRING_2D_ARRAY',
    'LONG_INTEGER_2D_ARRAY',
    'FLOAT_2D_ARRAY',
    'DOUBLE_2D_ARRAY',
    'CHARACTER_2D_ARRAY',
    'BOOLEAN_2D_ARRAY'
];

export const returnHelperTextForArray = (type) => {
    switch (type) {
        case 'INTEGER_ARRAY':
        case 'INTEGER_2D_ARRAY':
            return 'Please enter a list of integer numbers separated by a space. Example: -21 0 7';
        case 'LONG_INTEGER_ARRAY':
        case 'LONG_INTEGER_2D_ARRAY':
            return 'Please enter a list of long integer numbers separated by a space. Example: 15000000000 14000000000 13000000000';
        case 'FLOAT_ARRAY':
        case 'DOUBLE_ARRAY':
        case 'FLOAT_2D_ARRAY':
        case 'DOUBLE_2D_ARRAY':
            return 'Please enter a list of numbers with a decimal point separated by a space. Example: 1.5 2.5 3.5';
        case 'STRING_ARRAY':
        case 'STRING_2D_ARRAY':
            return 'Please enter a list of elements without quotes separated by a space. Example: Hello World';
        case 'CHARACTER_ARRAY':
        case 'CHARACTER_2D_ARRAY':
            return 'Please enter a list of characters without quotes separated by a space. Example: H e l l o';
        case 'BOOLEAN_ARRAY':
        case 'BOOLEAN_2D_ARRAY':
            return 'Please enter a list of elements separated by a space. Each element should be 1 (true) or 0 (false). Example: 1 0 1';
        default:
            return null;
    }
};

export const returnHelperTextForSingleValueInput = (type) => {
    switch (type) {
        case 'INTEGER':
            return 'Please enter an integer number. Example: -21';
        case 'LONG_INTEGER':
            return 'Please enter a long integer number. Example: 8589934592';
        case 'FLOAT':
        case 'DOUBLE':
            return 'Please enter a number with a decimal point. Example: 1.5';
        case 'STRING':
            return 'Please enter a text without quotes. Example: Hello, World!';
        case 'CHARACTER':
            return 'Please enter a single character without quotes. Example: h';
        case 'BOOLEAN':
            return 'Please enter 1 (true) or 0 (false). Example: 1';
        default:
            return null;
    }
};

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';
import EditWarning from 'components/marketplace/EditWarning';
import Select from 'libraries/Select';
import NumberInput from 'components/inputs/NumberInput';
import { checkPluralNew } from 'helper/commonFunctions';
import EditPanelSection from '../EditPanelSection';

const styles = {
    timerUnitsSelect: {
        width: 100,
        marginLeft: 8,
        marginRight: 6,
        padding: 0
    },
    timerSelectMenu: {
        padding: '6px 10px'
    },
    selectIconClassName: {
        marginTop: 1,
        marginRight: 0
    }
};

const LimitSettings = ({
    audition: { noAnswerRequired },
    classes, settings,
    changeAssessmentSettings
}) => {
    const [form, setForm] = useState({ timerEnabled: false });
    const [errorForm, setErrorForm] = useState({ name: '', description: '' });

    useEffect(() => {
        initFormData(settings);
    }, [settings]);

    const handleChangeTimerLimit = (e) => {
        const { checked } = e.target;
        const newForm = {};
        const newErrorForm = {};
        newForm.timerEnabled = checked;
        newForm.timerCount = form.timerCount;
        changeAssessmentSettings(newForm);
        setForm({ ...form, ...newForm });
        setErrorForm({ ...errorForm, ...newErrorForm });
    };

    const initFormData = (audition) => {
        const { timerEnabled, timerCount: timerCountData } = audition;
        let timerUnits = 'minutes';
        let timerCount = timerCountData;
        let newTimerEnabled = timerEnabled;
        let newTimerValue = timerCountData;
        // initial values, calculated depends on incoming data
        if (timerEnabled || timerCountData > 0) {
            if (!(timerCount % 1440)) {
                timerUnits = 'days';
                newTimerValue = (timerCountData / 1440).toFixed();
            } else if (!(timerCount % 60)) {
                timerUnits = 'hours';
                newTimerValue = (timerCountData / 60).toFixed();
            }
        } else {
            newTimerEnabled = false;
            timerCount = 15;
            timerUnits = 'minutes';
            newTimerValue = 15;
        }

        setForm({
            timerValue: newTimerValue,
            timerUnits,
            timerEnabled: newTimerEnabled,
            timerCount
        });
    };

    const handleChangeTimer = (e, val) => {
        const { timerUnits, timerValue, timerCount } = form;
        let newTimerValue = timerValue;
        let newTimerUnits = timerUnits;
        let newTimerCount = timerCount;

        if (val) {
            newTimerUnits = val;
        } else {
            const { value } = e.target;
            let newValue = parseInt(value, 10);
            if (Number.isNaN(newValue) || newValue <= 0) {
                newValue = 1;
            }
            newTimerValue = newValue;
        }

        // Change timer value to minutes
        switch (newTimerUnits) {
            case 'hours':
                newTimerCount = newTimerValue * 60;
                break;
            case 'days':
                newTimerCount = newTimerValue * 60 * 24;
                break;
            default:
                newTimerCount = newTimerValue;
                break;
        }

        changeAssessmentSettings({ timerCount: parseInt(newTimerCount, 10) });

        setForm({
            ...form,
            timerCount: parseInt(newTimerCount, 10),
            timerUnits: newTimerUnits,
            timerValue: newTimerValue
        });
    };

    return (
        <EditPanelSection
            className="u-pdn--tx0"
            header={(
                <>
                    Set Assessment time limit
                    <Switch
                        checked={form.timerEnabled}
                        onChange={handleChangeTimerLimit}
                    />
                </>
            )}
        >
            <div className="u-dsp--f u-ai--center u-jc--start">
                <NumberInput
                    value={form.timerValue}
                    onChange={handleChangeTimer}
                    type="number"
                    name="timerCount"
                    min="1"
                    disabled={!form.timerEnabled}
                />
                <Select
                    name="timerUnits"
                    margin="none"
                    variant="outlined"
                    value={form.timerUnits}
                    selectIconClassName={classes.selectIconClassName}
                    InputProps={{ classes: { input: classes.timerSelectMenu } }}
                    className={classes.timerUnitsSelect}
                    onChange={e => handleChangeTimer(e, e.target.value)}
                    disabled={!form.timerEnabled}
                    options={[
                        { value: 'minutes', label: checkPluralNew(form.timerValue, 'minute') },
                        { value: 'hours', label: checkPluralNew(form.timerValue, 'hour') },
                        { value: 'days', label: checkPluralNew(form.timerValue, 'day') }
                    ]}
                />
            </div>
            <p className="u-mrg--tx4">Once started, candidates are required to submit Skill Assessment within this time.</p>
            {
                form.timerEnabled && noAnswerRequired
                && (
                    <EditWarning
                        className="u-mrg--tx2"
                        message={(
                            <span>
                                Assessments with a time limit may prevent candidates from <br />submitting a required answer if they run out of time.
                            </span>
                        )}
                    />
                )
            }
        </EditPanelSection>
    );
};

export default withRouter(withStyles(styles)(LimitSettings));

import React from 'react';
import { withStyles } from '@mui/styles';
import Switch from 'libraries/SwitchBrandWithLabel';
import Tooltip from 'libraries/TooltipWhite';
import TextInput from 'libraries/TextInput';
import Loader from 'components/loaders/Loader';

import SettingsEvents from 'events/SettingsEvents';
import { getComeetCredentials, updateComeetIntegration } from 'requests/SettingsRequests';

import styles from './styles';

class ComeetContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientId: '',
            clientIdError: '',
            enabled: false,
            dataCame: false
        };
    }

    componentDidMount() {
        getComeetCredentials()
            .then(({ data, success }) => {
                if (success) {
                    this.setState({ dataCame: true });
                    if (data) {
                        const { apiKey, enabled } = data;
                        this.setState({
                            clientId: apiKey,
                            enabled
                        });
                    }
                }
            });
    }

    handleChangeClientId = (e) => {
        const targetValue = e.target.value;
        const { clientIdError } = this.state;
        if (clientIdError) {
            this.setState({ clientIdError: '' });
        }
        this.setState({ clientId: targetValue });
    };

    handleChangeSwitch = () => {
        const { enabled, clientId } = this.state;
        const { flashMessage, updateCompany, company } = this.props;
        const newEnabled = !enabled;
        SettingsEvents.COMEET_ENABLED({ enabled: newEnabled });
        if (newEnabled && !clientId) {
            this.setState({
                clientIdError: 'Please contact your Comeet Technical Account Manager to get your Client ID'
            });
            return;
        }
        this.setState({ enabled: newEnabled });
        updateComeetIntegration(clientId, newEnabled)
            .then(({ success, data }) => {
                if (success) {
                    if (data) {
                        const { enabled: dataEnabled } = data;
                        const newCompany = { ...company, isAtsEnabled: dataEnabled };
                        flashMessage(`Integration ${dataEnabled ? 'enabled' : 'disabled'}`, 'done');
                        updateCompany(newCompany);
                    }
                } else {
                    flashMessage('Something went wrong');
                }
            })
            .catch(({ response }) => {
                this.setState({ enabled });
                if (response.data && response.data.errors) {
                    const { apiKey: errApiKey } = response.data.errors;
                    this.setState({
                        clientIdError: errApiKey ? errApiKey[0].message : ''
                    });
                } else {
                    flashMessage('Something went wrong');
                }
            });
    };

    render() {
        const { enabled, clientId, clientIdError, dataCame } = this.state;

        return (
            <div>
                <div className="c-settings__additional-info">
                    <div className="c-settings__additional-info-header">About the integration</div>
                    <div>
                        <p>The integration enables you to completely streamline your skill testing from Comeet.</p>

                        With the integration you’ll be able to do the following from Comeet:

                        <ul className="c-settings__additional-info-list">
                            <li>Invite candidates to complete a Skills Assessment</li>
                            <li>View candidate results</li>
                            <li>Manually grade candidate results</li>
                            <li>Let recruiters order Skills Assessments from Comeet</li>
                        </ul>
                    </div>
                </div>
                {
                    !dataCame ? (
                        <Loader show style={{ backgroundColor: 'none', position: 'relative', marginTop: 70 }} />
                    ) : (
                        <div className="c-settings__pageup-settings">
                            <div className="c-settings__input-wrapper">
                                <div
                                    data-tip
                                    data-for="page-up-disabled-tooltip"
                                >
                                    <TextInput
                                        label="Comeet CLIENT ID"
                                        fullWidth
                                        data-tip
                                        data-for="page-up-disabled-tooltip"
                                        name="clientId"
                                        type="text"
                                        variant="outlined"
                                        value={clientId}
                                        helperText={clientIdError}
                                        isError={Boolean(clientIdError)}
                                        onChange={this.handleChangeClientId}
                                        disabled={enabled}
                                    />
                                </div>
                            </div>
                            {
                                enabled && (
                                    <Tooltip
                                        id="page-up-disabled-tooltip"
                                        position="right"
                                        delayHide={300}
                                        delayShow={300}
                                        className="c-settings__pageup-tooltip"
                                    >
                                        <p className="u-mrg--bx0 u-pdn--rx3">
                                            To edit this field, first disable the integration, then re-enable it.
                                        </p>
                                    </Tooltip>
                                )
                            }
                            <br />
                            <Switch
                                label="Enable Comeet Integration"
                                checked={enabled}
                                value={enabled}
                                onChange={this.handleChangeSwitch}
                            />
                        </div>
                    )
                }
            </div>
        );
    }
}

export default withStyles(styles)(ComeetContent);

import React, { useEffect, useState } from 'react';

import { withStyles } from '@mui/styles';

import EditQuestionWarning from 'components/marketplace/EditWarning';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import styles from '../../styles';

const RequiredAnswerSwitch = ({ question, audition, changeQuestionSettings }) => {
    const [requiredAnswer, setRequiredAnswer] = useState(required);
    const { required, timeLimit, id, answerType } = question;

    useEffect(() => {
        setRequiredAnswer(required);
    }, [id, answerType]);

    const handleChangeRequiredQuestion = () => {
        const newValue = !requiredAnswer;
        setRequiredAnswer(newValue);
        changeQuestionSettings({ required: newValue });
    };

    return (
        <>
            <SwitchBrandWithLabel
                label="Require candidates to answer this question"
                checked={Boolean(requiredAnswer)}
                className="u-mrg--bx0"
                onChange={handleChangeRequiredQuestion}
            />
            {
                requiredAnswer && (!!timeLimit || audition.timerEnabled) && (
                    <EditQuestionWarning
                        className="u-mrg--bx2"
                        message={(
                            <span>
                                Questions or entire Skills Assessments with time limits may prevent <br />
                                candidates from submitting a required answer if they run out of time.
                            </span>
                        )}
                    />
                )
            }
        </>
    );
};

export default withStyles(styles)(RequiredAnswerSwitch);

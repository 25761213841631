import React, { useContext } from 'react';
import clsx from 'clsx';
import ClosedIcon from 'img/closed-assessment.svg';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { appCtx } from 'components/appStore';

import stylesJS from './styles';

const ClosedOverlay = ({
    classes, className = '', activateHandler,
    isLowPlan, countRealCandidates, archived
}) => {
    const { loggedUser: { roleType } } = useContext(appCtx);
    return (
    <>
        <div className={clsx(classes.container, className)}>
            <img className={classes.iconClosed} src={ClosedIcon} alt="closed" />
            {
                archived ? (
                    <div className={classes.titleClosed}>Assessment is Archived</div>
                ) : (
                    <>
                        <div className={classes.titleClosed}>Assessment is Closed</div>
                        {
                            !isLowPlan && Number(countRealCandidates) > 0 && roleType !== 'team' && (
                                <div className={classes.textClosed}>
                                    Re-open assessment
                                    <button onClick={activateHandler}> with </button>
                                    or
                                    <button onClick={activateHandler}> without </button>
                                    candidates
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    </>
    );
};

export default withStyles(stylesJS)(withRouter(ClosedOverlay));

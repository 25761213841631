export default theme => ({
    leftPanel: {
        width: 400,
        minWidth: 400,
        paddingBottom: 50,
        backgroundColor: theme.palette.grey[50],
        position: 'relative',
        padding: '26px 33px',
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderRight: 'none',
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        }
    },
    contentPanel: {
        position: 'relative',
        padding: '26px 60px 40px',
        width: 'calc(100% - 400px)',
        minWidth: 500,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '26px 30px 40px'
        }
    }
});

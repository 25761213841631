import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import { downloadCSV } from 'helper/commonFunctions';
import CandidateTabEvents from 'events/CandidateTabEvents';
import ExportingPopup from 'libraries/SnackbarProgress';
import { exportSettingsFormCtx } from '../ExportSettingsForm/store';

import styles from './styles';

const ExportUsers = observer(({
    open, onClose, classes,
    slug, children, fileName,
    usersCount, requestExportCSVFunc,
    segmentEventContext
}) => {
    const [CSVPercents, setCSVPercents] = useState(0);
    const [cancelImporting, setCancelImporting] = useState(false);
    const [dialog_exporting_error, setDialogExportingError] = useState(false);
    const [dialogExporting, setDialogExporting] = useState(false);

    const {
        exportQuestions, excludeExported,
        includeShareLink, enabledScoring,
        includeRank, setDefault
    } = useContext(exportSettingsFormCtx);

    useEffect(() => () => {
        if (!open) {
            setDefault();
        }
    }, [open]);

    const exportCSV = () => {
        CandidateTabEvents.CANDIDATES_EXPORTED({
            numberCandidates: usersCount,
            excludeDuplicates: excludeExported,
            includeShareLink,
            exportQuestions,
            scoreEnabled: enabledScoring,
            context: segmentEventContext
        });

        onClose();
        setDialogExporting(true);
        setCancelImporting(false);
        setDialogExportingError(false);
        setCSVPercents(0);

        requestExportCSV();
    };

    const requestExportCSV = () => {
        if (!requestExportCSVFunc) return;
        const handleError = () => {
            setDialogExportingError(true);
            setCancelImporting(false);
            setTimeout(() => {
                setDialogExporting(false);
            }, 5000);
        };

        let candidateLinkType = includeShareLink;
        if (candidateLinkType) {
            candidateLinkType = enabledScoring ? 'agency' : 'public';
        }

        const options = {
            exclude: excludeExported,
            includeShareLink,
            exportQuestions,
            candidateLinkType,
            includeRank
        };

        requestExportCSVFunc(options)
            .then((response) => {
                const { success, totalItemsCount, offset, csv } = response;

                const handleSuccess = (fullString) => {
                    if (!cancelImporting) {
                        setCancelImporting(false);
                        setCSVPercents(100);
                        downloadCSV(fullString, fileName || slug);
                    }

                    setTimeout(() => {
                        setDialogExporting(false);
                    }, 1000);
                };


                if (success) {
                    if (parseInt(totalItemsCount, 10) > parseInt(offset, 10)) {
                        const maxPage = Math.ceil(totalItemsCount / offset);
                        let page = 2;
                        let fullString = csv;

                        const CSVPercentsCalc = () => parseInt((offset * (page - 1) * 100) / totalItemsCount, 10);
                        const makeRequest = () => {
                            requestExportCSVFunc(options, page)
                                .then((newResponse) => {
                                    if (newResponse.success) {
                                        fullString += `\n${newResponse.csv}`;
                                        page += 1;
                                        if (page <= maxPage) {
                                            setCSVPercents(CSVPercentsCalc());
                                            makeRequest();
                                        } else {
                                            handleSuccess(fullString);
                                        }
                                    } else {
                                        handleError();
                                    }
                                });
                        };
                        setCSVPercents(CSVPercentsCalc());
                        makeRequest();
                    } else {
                        handleSuccess(csv);
                    }
                } else {
                    handleError();
                }
            })
            .catch(() => {
                handleError();
            });
    };

    const cancelExporting = () => {
        setCancelImporting(true);
        setTimeout(() => {
            setDialogExporting(false);
        }, 1000);
    };

    const retryCSV = () => {
        setDialogExportingError(false);
        setCSVPercents(0);
        requestExportCSV();
    };


    return (
        <>
            <Dialog
                open={open}
                maxWidth="sm"
                handleClose={onClose}
                titleComponent="Export Candidates to CSV"
                onClose={onClose}
                actionComponent={(
                    <>
                        <Button
                            className={classes.actionButton}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.actionButton}
                            color="primary"
                            variant="contained"
                            onClick={exportCSV}
                        >
                            Export candidates to CSV
                        </Button>
                    </>
                )}
            >
                {children}
            </Dialog>
            <ExportingPopup
                open={dialogExporting}
                message="Exporting candidates to CSV…"
                onCancel={cancelExporting}
                error={dialog_exporting_error}
                onRetry={retryCSV}
                canceled={cancelImporting}
                percents={CSVPercents}
            />
        </>
    );
});

export default withStyles(styles)(ExportUsers);

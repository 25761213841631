import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';

const FeedbackQuestion = observer(() => {
    const { company } = useContext(appCtx);
    return (
        <p className="u-txt--14">
            Do you have any final thoughts or comments for {company.name}?
            Is there anything else you would like to add? This is the final question of the assessment and is optional.
        </p>
    );
});

export default FeedbackQuestion;

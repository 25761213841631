import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import styles from './styles';

const IcimsIcon = withStyles(styles)(withTheme(({ theme, isActive }) => {
    const fillColor = isActive ? theme.palette.primary.main : '#A9AAAE';
    return (
        <svg width="106" height="32" viewBox="0 0 106 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 9C6.98528 9 9 6.98528 9 4.5C9 2.01472 6.98528 0 4.5 0C2.01472 0 0 2.01472 0 4.5C0 6.98528 2.01472 9 4.5 9Z" fill={fillColor} />
            <path d="M21.1502 15.5742C23.036 15.5742 24.1884 16.4977 24.7646 18.048H32.1507C30.9983 12.9355 26.9822 10 21.3248 10C14.6022 10 9.92265 14.2549 9.92265 21C9.92265 27.7451 14.6022 32 21.2375 32C26.8949 32 30.9109 28.8995 32.0634 23.952H24.6773C24.1011 25.5022 22.9487 26.4258 21.0629 26.4258C18.6008 26.4258 16.8896 24.5622 16.8896 21C16.8896 17.4378 18.7056 15.5742 21.1502 15.5742ZM88.5502 16.5142C88.5502 15.5082 89.4582 14.8816 91.0995 14.8816C93.0726 14.8816 94.2076 15.8051 94.4521 17.2894H100.843C100.441 13.1004 97.1585 10.0165 91.3265 10.0165C85.4246 10.0165 82.142 13.0345 82.142 16.5967C82.142 24.4138 94.4346 21.94 94.4346 25.4198C94.4346 26.3433 93.5266 27.1184 91.7281 27.1184C89.8423 27.1184 88.3756 26.1124 88.2883 24.6447H81.4086C81.7403 28.7511 85.7564 31.9175 91.8154 31.9175C97.4728 31.9175 101 29.1304 101 25.4198C100.843 17.5202 88.5502 19.9115 88.5502 16.5142ZM4.43985 11.5502C3.13026 11.5502 1.97782 11.1709 1 10.5442V31.6867H7.967V10.5442C6.90187 11.1709 5.74943 11.5502 4.43985 11.5502ZM33.373 10.3133H40.34V31.6867H33.373V10.3133ZM70.9144 10.0825C67.6317 10.0825 64.7681 11.7811 63.2839 14.024C61.7299 11.4678 59.0234 10.0825 55.5836 10.0825C52.6326 10.0825 50.3452 11.2369 48.9483 13.018V10.3133H41.9813V31.6867H48.9483V20.0765C48.9483 17.2894 50.677 15.7391 53.2961 15.7391C55.9153 15.7391 57.644 17.2894 57.644 20.0765V31.6867H64.5237V20.0765C64.5237 17.2894 66.2523 15.7391 68.8715 15.7391C71.4907 15.7391 73.2193 17.2894 73.2193 20.0765V31.6867H80.099V19.1364C80.1863 13.4138 76.5718 10.0825 70.9144 10.0825Z" fill={fillColor} />
            <path d="M102 15C102 13.8687 102.788 13 104.052 13C105.212 13 106 13.8485 106 15C106 16.1313 105.212 17 104.052 17C102.767 17 102 16.1515 102 15ZM102.29 15C102.29 15.9495 102.974 16.7172 103.948 16.7172C105.026 16.7172 105.71 15.9495 105.71 15C105.71 14.0505 105.026 13.2828 103.948 13.2828C102.953 13.303 102.29 14.0505 102.29 15ZM104.218 15.202L104.902 16.1515H104.508L103.824 15.202H103.534V16.1515H103.244V13.9697H104.114C104.591 13.9697 104.798 14.1515 104.798 14.6364C104.819 14.9192 104.611 15.202 104.218 15.202ZM103.554 14.2525V14.9192H104.135C104.425 14.9192 104.529 14.8182 104.529 14.5354C104.529 14.2525 104.342 14.1515 104.135 14.1515H103.554V14.2525Z" fill={fillColor} />
        </svg>
    );
}));

export default IcimsIcon;

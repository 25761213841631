import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { arrayMove } from 'react-sortable-hoc';


import { clone } from 'helper/commonFunctions';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import { addQuestionGroup, deleteQuestionGroup, editQuestionGroup, changeQuestionGroup } from 'requests/QuestionsRequests';

import PanelHeader from 'components/assessments_pages/asessment_settings_components/SectionHeader';
import TTSettings from 'components/assessments_pages/asessment_settings_components/TTSettings';
import GroupScoreRange from 'components/assessments_pages/edit_question_groups/GroupScoreRange';
import NoGroupsContent from 'components/assessments_pages/edit_question_groups/NoGroupsContent';
import SkillGroupRandomization from 'components/assessments_pages/edit_question_groups/SkillGroupRandomization';
import AddNewSkillButton from 'components/assessments_pages/edit_question_groups/AddNewSkillButton';
import { appCtx } from 'components/appStore';


const SkillsGroupPage = observer(({
    settings, changeAssessmentSettings,
    loadEditScript, audition, match, setSaving
}) => {
    const [questionsGroups, setQuestionsGroups] = useState(audition.questionGroups);
    const [scoreType, setScoreType] = useState(audition.scoreType);
    const { flashMessage } = useContext(appCtx);
    const { uuid: ttId, name: ttName } = audition;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const addScoreGroup = async (newQuestionsGroups, title) => {
        const { scriptSlug } = match.params;
        const newTitle = (title || `Skill ${newQuestionsGroups.length + 1}`).toLowerCase();
        try {
            setSaving(true);
            const { data } = await addQuestionGroup(scriptSlug, { title: newTitle, completeQuestions: [] });
            AssessmentSettingsEvents.GROUP_CREATED({ groupName: newTitle, ttId, ttName });
            newQuestionsGroups.push(data);
            setSaving(false);
            return newQuestionsGroups;
        } catch (e) {
            setSaving(false);
            flashMessage('Something went wrong.');
        }
    };

    const changeScoreType = async (type, title) => {
        let newQuestionsGroups = questionsGroups ? clone(questionsGroups) : [];

        if (type === 3) {
            newQuestionsGroups = await addScoreGroup(newQuestionsGroups, title);
            if (newQuestionsGroups) {
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
            }
        }

        setQuestionsGroups(newQuestionsGroups);
        setScoreType(type);
    };

    const removeScoreGroup = async (index) => {
        const newQuestionGroups = clone(questionsGroups);
        const deletedGroup = newQuestionGroups[index];
        const { completeQuestions, title: groupName } = deletedGroup;
        try {
            setSaving(true);
            await deleteQuestionGroup(deletedGroup.id);
            setSaving(false);

            const question = completeQuestions || [];
            newQuestionGroups.splice(index, 1);
            if (newQuestionGroups.length) {
                const newGroup = newQuestionGroups[0];
                newGroup.completeQuestions = newGroup.completeQuestions ? newGroup.completeQuestions.concat(question) : [];
                newQuestionGroups.forEach((el) => {
                    el.sort = newQuestionGroups.indexOf(el);
                });
            } else {
                changeScoreType(1);
            }
            setQuestionsGroups(newQuestionGroups);
            AssessmentSettingsEvents.GROUP_DELETED({
                groupName,
                ttId,
                ttName,
                questionsNumber: completeQuestions ? completeQuestions.length : 0
            });
            flashMessage('Question Group deleted', 'done');
        } catch (e) {
            setSaving(false);
            flashMessage('Something went wrong.');
        }
    };

    const editSkillGroup = async (id, dataParams) => {
        setSaving(true);
        const { data } = await editQuestionGroup(id, dataParams);
        setSaving(false);
        return data;
    };

    const editGroupName = async (index, newName) => {
        const newQuestionsGroups = clone(questionsGroups);
        const { title: oldName, id } = newQuestionsGroups[index];
        const data = await editSkillGroup(id, { title: newName });
        AssessmentSettingsEvents.GROUP_NAME_EDITED({
            ttId,
            ttName,
            oldName,
            newName
        });
        newQuestionsGroups[index] = data;
        setQuestionsGroups(newQuestionsGroups);
    };

    const editGroup = async (index, newGroup) => {
        const newQuestionsGroups = clone(questionsGroups);
        newQuestionsGroups[index] = newGroup;
        setQuestionsGroups(newQuestionsGroups);
    };

    const moveQuestion = async (e, questionId, distGroupIndex, sourceGroupIndex) => {
        const newQuestionsGroups = clone(questionsGroups);
        const sourceGroup = newQuestionsGroups[sourceGroupIndex];
        let returnedQuestion = null;

        for (let i = 0; i < sourceGroup.completeQuestions.length; i += 1) {
            const question = sourceGroup.completeQuestions[i];
            if (question.id === questionId) {
                returnedQuestion = question;
                newQuestionsGroups[sourceGroupIndex].completeQuestions.splice(i, 1);
                break;
            }
        }

        if (returnedQuestion) {
            setSaving(true);
            await changeQuestionGroup(questionId, newQuestionsGroups[distGroupIndex].id);
            AssessmentSettingsEvents.GROUP_QUESTION_MOVED({
                ttId,
                ttName,
                questionId: returnedQuestion.uuid,
                oldGroupName: sourceGroup.title,
                newGroupName: newQuestionsGroups[distGroupIndex].title
            });

            if (!newQuestionsGroups[distGroupIndex].completeQuestions) {
                newQuestionsGroups[distGroupIndex].completeQuestions = [];
            }
            newQuestionsGroups[distGroupIndex].completeQuestions.push(returnedQuestion);
            setQuestionsGroups(newQuestionsGroups);
            if (loadEditScript) await loadEditScript();
            setSaving(false);
        }
    };

    const changeSkillGroup = (group, groupIndex) => {
        const newQuestionsGroups = clone(questionsGroups);
        newQuestionsGroups[groupIndex] = group;
        setQuestionsGroups(newQuestionsGroups);
    };

    const changeGroupScoreType = async (type, index) => {
        const newQuestionsGroups = clone(questionsGroups);
        const changedGroupId = newQuestionsGroups[index].id;
        const changedGroup = await editSkillGroup(changedGroupId, { type });
        newQuestionsGroups[index] = changedGroup;
        setQuestionsGroups(newQuestionsGroups);
    };

    const reorderGroups = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;
        setSaving(true);
        let newQuestionsGroups = clone(questionsGroups);
        newQuestionsGroups = arrayMove(newQuestionsGroups, oldIndex, newIndex);
        const firstGroup = newQuestionsGroups[oldIndex];
        const secondGroup = newQuestionsGroups[newIndex];
        if (!firstGroup || !secondGroup) return;
        newQuestionsGroups[oldIndex].sort = oldIndex + 1;
        newQuestionsGroups[newIndex].sort = newIndex + 1;
        setQuestionsGroups(newQuestionsGroups);
        flashMessage('Skill Group reordered.', 'done');
        setSaving(false);
    };

    if (!(questionsGroups && scoreType === 3)) {
        return (
            <>
                <TTSettings audition={audition} settings={settings} />
                <NoGroupsContent changeScoreType={changeScoreType} />
            </>
        );
    }

    return (
        <div>
            <TTSettings
                audition={audition}
                settings={settings}
                changeAssessmentSettings={changeAssessmentSettings}
            />
            <SkillGroupRandomization
                editSkillGroup={editSkillGroup}
                changeSkillGroup={changeSkillGroup}
                questionsGroups={questionsGroups}
            />
            <PanelHeader className="u-mrg--tx4">
                Skill Group Details
                <AddNewSkillButton
                    changeScoreType={changeScoreType}
                    disabled={audition.countQuestions === 0}
                />
            </PanelHeader>
            <GroupScoreRange
                scoreType={scoreType}
                editGroup={editGroup}
                handleMove={moveQuestion}
                reorderGroups={reorderGroups}
                editGroupName={editGroupName}
                scoreGroups={questionsGroups}
                removeGroup={removeScoreGroup}
                changeScoreType={changeGroupScoreType}
            />
        </div>
    );
});

export default SkillsGroupPage;

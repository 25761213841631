import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    buttongroup: {
        height: 32,
        padding: '0',
        '& button:disabled': {
            opacity: 0.7,
            color: theme.palette.grey[600]
        },
        '& button:first-child': {
            minWidth: 166,
            paddingTop: 0,
            justifyContent: 'flex-start',
            paddingLeft: 10
        },
        '& button:last-child': {
            minWidth: 34
        }
    },
    button: {
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.grey[500],
        letterSpacing: '0.155556px',
        padding: 5,
        '& svg, & img': {
            margin: 0
        },
        '&:hover': {
            borderRightColor: 'transparent!important'
        }
    },
    activeButton: {
        backgroundColor: '#fff',
        color: theme.palette.grey[900]
    }
}));

export default useStyles;

import React, { useEffect } from 'react';
import { withStyles, withTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

import { MULTIPLE_CHOICE_AUTOGRADED, TEXT, VIDEO, CODE_TEST, AUDIO, GOOGLE_DOC } from 'helper/constants';

import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';

import styles from './styles';

const TemplateBanner = ({
    classes, activeQuestionIndex, handleTemplateBanner,
    questions, showSuggestedBanner, theme, company, onUnmount = () => { }
}) => {
    let header = '';
    let questionType = '';
    let subheader = '';
    const showQuestionBanks = checkHasCompanyFeature(company, 'QUESTION_BANKS');

    if (!activeQuestionIndex && activeQuestionIndex !== 0) return null;

    useEffect(() => onUnmount, []);

    switch (activeQuestionIndex) {
        case 0:
            header = 'Start by adding the first question';
            questionType = MULTIPLE_CHOICE_AUTOGRADED;
            subheader = <>A simple multiple choice or text question first enhances<br />candidate engagement.</>;
            break;
        case 1:
            header = 'Now add your second question';
            questionType = TEXT;
            subheader = <>Another text or multiple choice question is great to help<br />ease your candidates into the assessment.</>;
            break;
        case 2:
            header = 'Question three';
            questionType = TEXT;
            subheader = <>Have your candidates solve a problem or respond to a<br />customer with a text answer.</>;
            break;
        case 3:
            header = 'Add your fourth question';
            questionType = CODE_TEST;
            subheader = <>If you are testing technical skills now would be a good time<br />to do so. Include a coding question or a document.</>;
            break;
        case 4:
            header = 'Add question five';
            questionType = AUDIO;
            subheader = <>This would be a good time to include a video or audio<br />question. These are great for testing communication skills.</>;
            break;
        case 5:
            header = 'Add a sixth question';
            questionType = VIDEO;
            subheader = <>To enhance candidate experience, try adding a video or audio<br />file or uploading a picture for candidates to respond to.</>;
            break;
        case 6:
            header = 'Question seven';
            questionType = TEXT;
            subheader = <>Another technical question would be great here. Why not include<br />a text question to explain a technical term or solve a problem.</>;
            break;
        case 7:
            header = 'Add question eight';
            questionType = TEXT;
            subheader = <>This is a good chance to ask candidates a question about cultural alignment<br />or values. Feel free to include a picture or video file in the question.</>;
            break;
        case 8:
            header = 'Add your ninth question';
            questionType = MULTIPLE_CHOICE_AUTOGRADED;
            subheader = <>This could be a good opportunity to include a text or<br />multiple choice question to test knowledge.</>;
            break;
        case 9:
            header = 'Question 10';
            questionType = VIDEO;
            subheader = <>Nearing the end of an assessment is a good time to ask more<br />about the candidate personally through video or audio.</>;
            break;
        case 10:
            header = 'Add question 11';
            questionType = GOOGLE_DOC;
            subheader = <>Include another document question here that mimics how<br />candidates will need to use to document in the role. </>;
            break;
        case 11:
            header = 'Add your twelfth question';
            questionType = TEXT;
            subheader = <>Include another text question here to provide some variety<br />for your candidates.</>;
            break;
        case 12:
            header = 'Add question 13';
            questionType = VIDEO;
            subheader = <>This would be a good time to include the last video<br />or audio question. </>;
            break;
        case 13:
            header = 'Add your fourteenth question';
            questionType = TEXT;
            subheader = <>Include a text question here. Now would be a great time to test more<br />about their values or cultural alignment.</>;
            break;
        case 14:
            header = 'Question 15';
            questionType = MULTIPLE_CHOICE_AUTOGRADED;
            subheader = <>Adding a 15th question will make this a long assessment. Are you sure you want to<br />keep going? If so, add in a multiple choice question to minimise assessment fatigue.</>;
            break;
        default:
            header = `Question ${activeQuestionIndex + 1}`;
            questionType = MULTIPLE_CHOICE_AUTOGRADED;
            subheader = <>Adding another question will make this a long assessment. We recommend<br />shorter assessments for better candidate engagement.</>;
            break;
    }

    const content = Icon => (Icon ? <div><Icon color={theme.palette.grey[300]} className={classes.iconStyles} /></div> : null);

    return (
        <>
            <Typography variant="h3" className={classes.questionHeader}>
                Question {activeQuestionIndex + 1} of {questions.length}
            </Typography>
            <div className={classes.wrapper}>
                {getQuestionTypeInfo(questionType, content)}
                <Typography variant="h4" className={classes.header}>
                    {header}
                </Typography>
                <div className={classes.subheader}>{subheader}</div>
                <div className={classes.actions}>
                    Add your question from&nbsp;
                    {
                        showSuggestedBanner && (
                            <ButtonBase color="primary" onClick={() => { handleTemplateBanner(1); }}>Suggested</ButtonBase>
                        )
                    }
                    {
                        showQuestionBanks && (
                            <>
                                ,&nbsp;<ButtonBase color="primary" onClick={() => { handleTemplateBanner(2); }}>Saved</ButtonBase>
                            </>
                        )
                    }
                    &nbsp;or&nbsp;
                    <ButtonBase color="primary" onClick={() => { handleTemplateBanner(0); }}>Create</ButtonBase>
                </div>
            </div>
        </>
    );
};

export default withTheme(withStyles(styles)(TemplateBanner));

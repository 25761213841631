import React from 'react';
import { Redirect } from 'react-router-dom';

const RegistrationPremium = () => {
    const wasAuthorized = localStorage.getItem('wasAuthorized');

    return (
        <Redirect to={{
            pathname: wasAuthorized ? '/login' : '/registration',
            state: {
                isPremium: true
            }
        }}
        />
    );
};

export default RegistrationPremium;

import React, { useEffect, useState } from 'react';
import { setAtsApiSettings, getAtsApiSettings, changeAtsApiEvent } from 'requests/SettingsRequests';

import Checkbox from 'libraries/Checkbox';
import InfoTooltip from 'components/tooltips/InfoTooltip';

const PublicApiSettings = ({ flashMessage }) => {
    const [sendReports, setSendReports] = useState(false);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = () => {
        setLoading(true);
        getAtsApiSettings()
            .then(({ data }) => {
                if (!data) return;
                const { allCandidatesReportingAllowed, reportEvents } = data;
                setSendReports(allCandidatesReportingAllowed);
                setEvents(reportEvents.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1)));
            })
            .finally(() => setLoading(false));
    };

    const changeReportingForAllCandidates = () => {
        setLoading(true);
        setAtsApiSettings({ allCandidatesReportingAllowed: !sendReports })
            .then(({ data: { allCandidatesReportingAllowed } }) => {
                flashMessage('Setting has been updated', 'done');
                setSendReports(allCandidatesReportingAllowed);
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => setLoading(false));
    };

    const onChangeSetting = (title, enabled) => {
        setLoading(true);
        const newEvents = JSON.parse(JSON.stringify(events));
        const index = newEvents.findIndex(event => event.title === title);
        newEvents[index].enabled = !enabled;
        changeAtsApiEvent(title, !enabled)
            .then(() => {
                flashMessage('The list has been updated', 'done');
                setEvents(newEvents);
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => setLoading(false));
    };

    return (
        <div>
            <Checkbox
                label={<>
                    Send all candidate assessment reports to webhook URL
                    <InfoTooltip
                        id="send-reports"
                        isBlack
                        text="Reports will be sent on all candidates invited via API and any other sources"
                    />
                </>}
                checked={sendReports}
                className="u-mrg--tx4 u-mrg--bx4"
                classNameLabel="u-txt--bold"
                onChange={changeReportingForAllCandidates}
                disabled={loading}
            />
            <div className="u-txt--bold u-mrg--bx2">
                List of events
            </div>
            <p className="u-txt--light">Select list of events for reporting webhook</p>
            {events.map(({ title, displayTitle, enabled }) => (
                <Checkbox
                    label={displayTitle}
                    checked={enabled}
                    className="u-mrg--tx1 u-mrg--bx1 u-txt--semibold"
                    onChange={() => onChangeSetting(title, enabled)}
                    disabled={loading}
                />
            ))}
        </div>
    );
};


export default PublicApiSettings;

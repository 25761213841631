import React from 'react';
import Dialog from 'libraries/Dialog';
import CompareAnswers from './CompareAnswers';
import QuestionInfo from './QuestionInfo';


const PlagiarismDialog = ({ open, audition, handleClose, candidate, plagiarismData, getUserInfo }) => (
    <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        titleComponent={
            <div> Similar answers detected </div>
        }
        handleClose={handleClose}
    >
        {plagiarismData.map(({ questionResult, plagiarismEntities }) => (
            <>
                <QuestionInfo
                    audition={audition}
                    candidate={candidate}
                    plagiarismQuestion={questionResult}
                    getUserInfo={getUserInfo}
                />
                {
                    plagiarismEntities.map(plagiarismEntity => (
                        <CompareAnswers
                            candidateName={candidate.fullName}
                            plagiarism={plagiarismEntity}
                            key={plagiarismEntity.questionResult.id}
                        />
                    ))
                }
            </>
        )) }
    </Dialog>
);

export default PlagiarismDialog;

import React from 'react';
import { getInitials } from 'helper/commonFunctions';
import { useStyles } from './styles';

const ActiveTicketHeader = ({ candidate, ticket }) => {
    const { wrapper, initialsCandidate, agentLabel, block, openLabel, idLabel, agentName, numberWrapper } = useStyles({});

    return (
        <div className={wrapper}>
            <div className={block}>
                <div className={initialsCandidate}>{getInitials(candidate)}</div>
                <div>
                    <div className={agentLabel}>Agent</div>
                    <div className={agentName}>
                        {candidate}
                    </div>
                </div>
            </div>
            {
                ticket && (
                    <div className={numberWrapper}>
                        <div className={openLabel}>{ticket.hasCheckedAnswer ? 'Replied' : 'Open'}</div>
                        {
                            ticket.ticketNumber && (
                                <div className={idLabel}>Ticket {ticket.ticketNumber} </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

export default ActiveTicketHeader;

export default () => ({
    root: {
        padding: '13px 0',
        marginTop: 20,
        minHeight: 50
    },
    loader: {
        position: 'absolute',
        color: '#fff'
    }
});

import { createContext } from 'react';
import { action, observable } from 'mobx';

class AssessmentQueryStore {
    @observable query = '';

    @observable showAll = false;

    @action setQuery = (newQuery) => {
        this.query = newQuery;
    }

    @action setShowAll = (newValue) => {
        this.showAll = newValue;
    }


    @action cleanState = () => {
        this.query = '';
        this.showAll = false;
    }
}

export const assessmentQueryStore = new AssessmentQueryStore();
export const AssessmentQueryCtx = createContext(assessmentQueryStore);

export default ({
    saveButton: {
        padding: '10px 50px',
        fontWeight: 'bold',
        marginTop: 40
    },
    input: {
        maxWidth: 500
    }
});

import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import styles from './styles';

const TooltipNew = ({ children, classes, label, placement = 'top', white, boxShadow, tooltipClass, ...other }) => {
    if (!children) return null;
    if (!label) return children;

    return (
        <Tooltip
            title={label}
            classes={{
                tooltip: clsx(white ? classes.tooltipWhite : classes.tooltipDark, boxShadow && classes.boxShadow, tooltipClass || ''),
                arrow: white ? classes.arrowPopperWhite : classes.arrowPopperDark
            }}
            placement={placement}
            arrow
            {...other}
        >
            {children}
        </Tooltip>
    );
};

export default withStyles(styles)(TooltipNew);

import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { publishAudition, unPublishAudition } from 'requests/CMSRequests';

import stylesJS from './styles';

const AssessmentStatus = ({
    classes, className = '', flashMessage,
    isInteractive, uuid, status = '', setAudition
}) => {
    const [statusLabel, setStatusLabel] = useState(status);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setStatusLabel(status);
    }, [status]);

    const onHover = () => {
        if (!isInteractive) return;
        if (status === 'unpublished' || status === 'draft') {
            setStatusLabel('publish');
        }
        if (status === 'published') {
            setStatusLabel('unpublish');
        }
    };

    const onClick = () => {
        if (!isInteractive) return;
        setIsLoading(true);
        let request = publishAudition;
        if (statusLabel !== 'publish') {
            request = unPublishAudition;
        }
        request(uuid)
            .then(({ success, data }) => {
                if (success && data) {
                    setAudition(data);
                    flashMessage(`Assessment ${statusLabel === 'publish' ? '' : 'un'}published`, 'done');
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onUnHover = () => {
        if (!isInteractive) return;
        setStatusLabel(status);
    };

    if (!statusLabel) return null;

    return (
        <>
            <div
                onMouseOver={onHover}
                onFocus={onHover}
                onClick={onClick}
                onMouseLeave={onUnHover}
                role="presentation"
                className={clsx(
                    classes.wrapper,
                    isInteractive && 'u-cursor--p',
                    (statusLabel === 'published') && classes.wrapperPublished,
                    (statusLabel === 'draft') && classes.wrapperDraft,
                    className
                )}
            >
                {
                    isLoading && (
                        <CircularProgress size={12} className={classes.loader} />
                    )
                } {statusLabel}
            </div>
        </>
    );
};

export default withStyles(stylesJS)(AssessmentStatus);

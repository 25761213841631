import React from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import Switch from 'libraries/SwitchBrandWithLabel';

import { checkCompanyCanBeUpgraded, checkHasCompanyFeature } from 'helper/commonFunctions';
import { GUEST_GRADING } from 'helper/constants';
import Zendesk from 'helper/zendeskFunctions';

import styles from './styles';


const CommonDialog = observer(({
    classes, open, onClose, onCopy, children,
    disabledScoring, sharedLink, gradeableCard,
    onChangeSwitch, disableCopyButton, company, flashMessage
}) => {
    const planFit = checkHasCompanyFeature(company, GUEST_GRADING);

    const onCopyText = () => {
        flashMessage('Candidate report card link copied to clipboard', 'done');
    };


    const handleChangeSwitch = () => {
        if (planFit) {
            onChangeSwitch();
        } else if (checkCompanyCanBeUpgraded(company)) {
            flashMessage('Advanced candidate sharing requires an upgrade in your plan type');
            window.open('/subscriptions', '_blank');
        } else {
            flashMessage('Talk to us to upgrade your plan type');
            openZendesk();
        }
    };

    const openZendesk = () => {
        Zendesk.open(true);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            handleClose={onClose}
            titleComponent="Share Candidate Report Card"
            actionComponent={(
                <>
                    <Button color="primary" className="u-txt--bold" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        variant="contained"
                        onClick={onCopy}
                        disabled={disableCopyButton}
                    >
                        Copy link
                    </Button>
                </>
            )}
        >
            <div className={classes.linkPermissionsLabel}>Share Permissions</div>
            <Switch
                classNameLabel="u-dsp--f u-ai--center"
                disabled={disabledScoring}
                label={(<>Allow commenting and scoring of candidate
                    <InfoTooltip
                        className="u-txt--12 u-txt--normal"
                        isBlack
                        classNameIcon="u-txt--lh1"
                        text="Allow anyone with this link to comment and score candidate answers."
                    />
                </>)}
                checked={gradeableCard}
                className={classes.switch}
                onChange={handleChangeSwitch}
            />
            {children}
            <p className={classes.linkDescription}>
                Anyone with this link will be able to review candidates
            </p>
            {
                sharedLink && process.env.REACT_APP_SHOW_LINK && (
                    <CopyToClipboard text={sharedLink} onCopy={onCopyText}>
                        <p className={classes.link}>{sharedLink}</p>
                    </CopyToClipboard>
                )
            }
        </Dialog>
    );
});

export default withStyles(styles)(CommonDialog);

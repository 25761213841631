export default theme => ({
    wrapper: {
        fontWeight: 700,
        fontSize: 12,
        marginTop: -8
    },
    name: {
        height: 28,
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '28px'
    },
    description: {
        display: 'flex',
        alignItems: 'center',
        height: 24,
        '& div': {
            marginRight: 15
        }
    },
    scoreLabel: {
        textTransform: 'uppercase',
        color: theme.palette.grey[500],
        marginRight: 5,
        fontSize: 13,
        fontWeight: 700
    },
    scoresTitle: {
        fontSize: 14,
        marginBottom: 12
    },
    calculatingLabel: {
        alignItems: 'baseline'
    }
});

import React from 'react';

const AssessmentLinkSvg = ({ isActive = false }) => {
    const color = isActive ? '#1247F6' : '#EBECEE';
    return (
        <svg width="119" height="32" viewBox="0 0 119 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 16H39" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M80 16H117" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M52.0003 16.6668L64.5693 16.6668M64.5693 16.6668L60.1693 13.3335M64.5693 16.6668L60.1693 20.0002" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <ellipse cx="71.3337" cy="16.6668" rx="3.33333" ry="3.33333" transform="rotate(90 71.3337 16.6668)" stroke={color} strokeWidth="1.6" />
            <ellipse cx="48.6667" cy="16.6668" rx="3.33333" ry="3.33333" transform="rotate(90 48.6667 16.6668)" stroke={color} strokeWidth="1.6" />
        </svg>
    );
};

export default AssessmentLinkSvg;

import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PageWrapper from 'components/layout/PageWrapper';

import LoaderBounce from 'components/loaders/LoaderBounce';
import { appCtx } from 'components/appStore';
import GeneralPageFilter from '../components/GeneralPageFilter';
import TopStatisticBar from '../components/TopStatisticBar';
import { accountInsightsStoreCtx } from '../store';
import useStyles from '../styles';
import DataMetrics from '../components/DataMetrics';
import AssessmentsTable from '../components/AssessmentsTable';
import CompletionsData from '../components/CompletionsData';
import ScoreDistribution from '../components/ScoreDistribution';
import EeocDistribution from '../components/EeocDistribution';
import InnerContentWrapper from '../../assessment/components/InnerContentWrapper';
import InnerHeader from '../../../components/layout/InnerHeader';
import Layout from '../../../components/layout';

const AccountInsights = observer(() => {
    const { company } = useContext(appCtx);
    const { loadData, timeRange, loading, selectedAssessment } = useContext(accountInsightsStoreCtx);
    const classes = useStyles();

    useEffect(() => {
        loadData();
    }, [timeRange, selectedAssessment]);

    return (
        <Layout>
            <InnerHeader
                header="Account Insights"
                rightPart={(
                    <GeneralPageFilter />
                )}
            />
            <PageWrapper className="u-pdn--bx5">
                <InnerContentWrapper
                    className={classes.contentWrapper}
                    contentClassName="u-pdn--x0"
                >
                    {
                        loading
                            ? (
                                <div className={classes.loaderWrapper}>
                                    <LoaderBounce />
                                </div>
                            )
                            : (
                                <>
                                    <TopStatisticBar
                                        company={company}
                                    />
                                    <DataMetrics />
                                    <AssessmentsTable />
                                    <CompletionsData />
                                    <ScoreDistribution />
                                    <EeocDistribution />
                                </>
                            )
                    }

                </InnerContentWrapper>
            </PageWrapper>
        </Layout>
    );
});

export default AccountInsights;

import { get, patch } from 'helper/API';

export function getAuditionAiHealthInfo(slug) {
    return get(`employer/auditions/${slug}/active-learning`);
}

export function getQuestionForGrading(slug, questionHashUuid) {
    return get(`employer/auditions/${slug}/active-learning/question-results/${questionHashUuid}`);
}

export function gradeQuestion(questionResultId, questionHashUuid, value) {
    return patch(`employer/active-learning/question-results/${questionResultId}/hash-uuid/${questionHashUuid}`, { value });
}

export function getQuestionScores(slug, questionHashUuid, page, section, flagged) {
    return get(`employer/auditions/${slug}/question-results/${questionHashUuid}/with-grading`, { page, section, flagged });
}

export function getQuestionScoresStats(slug, questionHashUuid) {
    return get(`employer/auditions/${slug}/question-results/${questionHashUuid}/grading-statistic`);
}

export function markAnswerScoreWithFlag(questionId, flagged) {
    return patch(`/employer/question-result-grades/${questionId}/flagged`, { flagged });
}

export function exportInconsistencyScoreCSV(slug) {
    return get(`/employer/auditions/${slug}/active-learning/inconsistency-score/export`);
}

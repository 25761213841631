export default theme => ({
    actionsWrapper: {
        position: 'absolute',
        right: 0,
        top: 18,
        display: 'flex',
        alignItems: 'center'
    },
    actionDialogButton: {
        fontWeight: 700,
        paddingLeft: 50,
        paddingRight: 50
    },
    menuItem: {
        fontWeight: 600,
        fontSize: 13,
        color: theme.palette.grey[800],
        '& > img': {
            width: 23,
            marginRight: 10
        }
    },
    iconButton: {
        padding: 3
    }
});

import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import TextInputOutlined from 'libraries/TextInput';

const styles = theme => ({
    descriptionField: {
        minHeight: 70
    },
    hintLabel: {
        color: theme.palette.grey[900],
        fontWeight: 600,
        marginBottom: 30,
        fontSize: 12,
        marginTop: -5
    }
});

let timerId = null;

const SettingsFieldChange = ({
    classes, field = '', multiline, minLength = 2,
    maxLength = 500, label = '', audition, loadEditScript,
    changeAssessmentSettings, hintLabel = null, notRequired, settings, ...other
}) => {
    const [changedValue, setValue] = useState(settings[field] || audition[field]);
    const [error, setError] = useState('');

    const handleChangeText = (e) => {
        const { value, name } = e.target;
        let newError = '';

        clearTimeout(timerId);

        if (!notRequired) {
            if (value.length < 1) {
                newError = `${label} field is required.`;
            } else if (value.length < minLength) {
                newError = `Please select at least ${minLength} characters.`;
            } else {
                newError = '';
            }
        }

        setValue(value);
        setError(newError);

        if (!newError) {
            timerId = setTimeout(() => {
                changeAssessmentSettings({ [name]: value })
                    .then(() => {
                        if (field === 'name' && loadEditScript) {
                            loadEditScript();
                        }
                    });
            }, 300);
        }
    };

    return (
        <>
            <TextInputOutlined
                maxLength={maxLength}
                hasCounter
                variant="outlined"
                InputProps={{ classes: multiline ? { input: classes.descriptionField } : {} }}
                isError={Boolean(error)}
                helperText={error}
                name={field}
                label={label}
                multiline
                fullWidth
                value={changedValue}
                placeholder={label}
                onChange={handleChangeText}
                {...other}
            />
            {
                hintLabel && (
                    <div className={classes.hintLabel}>{hintLabel}</div>
                )
            }
        </>
    );
};

export default withStyles(styles)(SettingsFieldChange);

export default theme => ({
    addButton: {
        fontSize: 14,
        color: theme.palette.primary.main,
        borderRadius: 100,
        fontWeight: 600,
        paddingTop: 3,
        paddingBottom: 3,
        boxShadow: 'none',
        border: `2px solid ${theme.palette.grey[300]}`,
        '&:hover': {
            boxShadow: 'none'
        },
        '& img': {
            width: 15
        }
    },
    actionsWrapper: {
        whiteSpace: 'nowrap'
    },
    label: {
        display: 'flex',
        alignItems: 'center'
    },
    labelActive: {
        color: theme.palette.grey[900]
    }
});

import React from 'react';
import { withTheme } from '@mui/styles';


const CommentsIcon = ({ theme, color: propsColor, ...other }) => {
    const color = propsColor || theme.palette.grey[400];
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M20.508 17.0705C20.883 16.6954 21.0938 16.1867 21.0938 15.6562V11V3.65625H5.09375C4.56332 3.65625 4.05461 3.86696 3.67954 4.24204C3.30446 4.61711 3.09375 5.12582 3.09375 5.65625V21.6562L7.09375 17.6562H19.0938C19.6242 17.6562 20.1329 17.4455 20.508 17.0705Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 14H11" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 11L17 11" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 8L17 8" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default withTheme(CommentsIcon);

import React from 'react';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import useStyles from './styles';

const TagsContainer = ({ tags, onRemove, containerClassName, tagClassName }) => {
    const classes = useStyles();
    if (!tags.length) return <div className={classes.noTagsLabel}> The candidate is not tagged.</div>;
    return (
        <div className={clsx(classes.container, containerClassName)}>
            {tags.map((tag, index) => (
                <div className={clsx(classes.tag, onRemove && 'u-pdn--rx4', tagClassName)} key={tag.title}>
                    <TruncateWithTooltip capitalize text={tag.title} width={115} />
                    {
                        onRemove && (
                            <IconButton
                                className={classes.closeButton}
                                onClick={() => onRemove(tag, index)}
                                size="large"
                            >
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        )
                    }
                </div>
            ))}
        </div>
    );
};

export default TagsContainer;

export default theme => ({
    tooltip: {
        padding: '0!important',
        borderRadius: '8px!important'
    },
    removeTooltipArrow: {
        '& .__react_component_tooltip': {
            '&:after, &:before': {
                border: 'none!important'
            }
        }
    },
    card: {
        minWidth: 360,
        height: 'auto',
        borderRadius: 8,
        border: 'none',
        color: theme.palette.grey[900]
    },
    name: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        height: 28,
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '28px'
    },
    shortlistedIcon: {
        minWidth: 20
    },
    description: {
        display: 'flex',
        alignItems: 'center',
        height: 24,
        whiteSpace: 'nowrap',
        '& div': {
            marginRight: 15
        }
    },
    scoreLabel: {
        textTransform: 'uppercase',
        color: theme.palette.grey[600],
        marginRight: 5
    },
    container: {
        padding: '13px 13px 13px 17px'
    },
    scrollerContainer: {
        maxHeight: 260,
        overflow: 'auto'
    },
    wrapperBorder: {
        padding: '13px 13px 13px 17px',
        borderTop: `1px solid ${theme.palette.grey[300]}`
    },
    scoresTitle: {
        fontSize: 14,
        marginBottom: 12
    },
    footer: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: '12px 17px 5px 17px'
    }
});

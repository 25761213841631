import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Dialog from 'libraries/Dialog';

import Button from '@mui/material/Button';
import CheckIcon from '../Check.svg';

import styles from '../styles';

const GoToNextQuestionDialog = ({ classes, open, handleNext }) => (
    <Dialog
        open={open}
        maxWidth="sm"
        className={classes.dialog}
        handleClose={handleNext}
        contentClassName={classes.dialogContent}
    >
        <div>
            <img src={CheckIcon} alt="check" className={classes.icon} />
            <Typography variant="h3" className={classes.successHeader}>
                No Answers to Grade
            </Typography>
            There are no more candidate responses to this question.
        </div>
        <Button color="primary" onClick={handleNext} className="u-mrg--tx2">
            Go to next Question
        </Button>
    </Dialog>
);

export default withStyles(styles)(GoToNextQuestionDialog);

import React from 'react';


const SimulationIcon = ({ color = '#A9AAAE', size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M9.63922 19.9137C8.0838 21.0465 6.69642 21.4634 5.82268 20.9037C4.00823 19.7419 5.09046 14.8134 8.23921 9.89567C11.388 4.97795 15.4111 1.93343 17.2256 3.09463C18.1246 3.67018 18.3122 5.17001 17.8908 7.13482" stroke={color} strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.3817 3.90818C8.95702 2.65221 7.63092 2.11521 6.71359 2.58335C4.79489 3.56323 5.39318 8.57382 8.05042 13.7739C10.7077 18.9741 14.4155 22.3951 16.3342 21.4152C18.2529 20.4354 17.6546 15.4248 14.9974 10.2246" stroke={color} strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.8763 15.0734C20.8177 14.4492 22.0421 13.5255 22.0876 12.449C22.1792 10.2965 17.5236 8.35066 11.6892 8.10238C5.85479 7.85409 1.05141 9.39814 0.959807 11.5506C0.915582 12.5974 1.99339 13.595 3.7832 14.3727" stroke={color} strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.6506 13.349C12.3516 13.349 12.9198 12.7808 12.9198 12.0798C12.9198 11.3788 12.3516 10.8105 11.6506 10.8105C10.9496 10.8105 10.3813 11.3788 10.3813 12.0798C10.3813 12.7808 10.9496 13.349 11.6506 13.349Z" fill={color} />
    </svg>

);

export default SimulationIcon;

import React, { useState } from 'react';
import { Autocomplete } from '@mui/lab';
import TextInput from 'libraries/TextInput';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    listItem: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.grey[400]
    },
    highlighted: {
        color: theme.palette.grey[900]
    }
}));

const AutocompleteInput = ({ options, onChange, value, label }) => {
    const [open, setOpen] = useState(false);

    const { listItem, highlighted } = useStyles();

    return (
        <div>
            <Autocomplete
                disableClearable
                options={options}
                open={open}
                onInputChange={(event, val, reason) => {
                    setOpen(reason === 'input' && val.trim().length > 0);
                }}
                value={value}
                onChange={(_, val) => onChange(val)}
                onFocus={() => setOpen(false)}
                onBlur={() => setOpen(false)}
                popupIcon={null}
                renderInput={params => (
                    <TextInput
                        {...params}
                        label={label}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            style: { height: 50 }
                        }}
                    />
                )}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option, inputValue, { insideWords: true });
                    const parts = parse(option, matches);
                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        className={clsx(listItem, part.highlight && highlighted)}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
        </div>
    );
};

export default AutocompleteInput;

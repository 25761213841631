import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withTheme, withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Select from 'libraries/Select';
import EmptyIcon from 'img/emptyDetailsIcon.svg';
import { PERMISSIONS, MAX_BRANDING_SETS } from 'helper/constants';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';

import styles from './styles';


const BrandingOptionsDropdown = observer(({
    classes, brandings, currentBrandingUuid, assignBrandingToAudition, company, setAddBrandingSetDialogOpen
}) => {
    const { loggedUser: { roleType } } = useContext(appCtx);

    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);
    const hasAdminPermissions = !((roleType === 'team' && hasPermissionsFeature) || roleType === 'integration');

    return (
        <Select
            value={currentBrandingUuid}
            onChange={assignBrandingToAudition}
            fullWidth
            name="branding"
            label="Select Branding"
            options={brandings.map(({ title, uuid }) => ({
                value: uuid,
                label: <TruncateWithTooltip capitalize width={250} text={title} />
            }))}
            className={classes.select}
            menuPaperClassName={classes.paper}
            childrenAfter={(
                <div>
                    {!brandings.length && (
                        <div className={classes.emptyBoard}>
                            <img src={EmptyIcon} alt="empty-content" /> <br />
                            <Typography variant="h6">
                                No brandings created
                            </Typography>
                        </div>
                    )}
                    {hasAdminPermissions && <>
                        <Button
                            className={classes.addBranding}
                            onClick={() => setAddBrandingSetDialogOpen(true)}
                            color="primary"
                            disabled={brandings.length >= MAX_BRANDING_SETS}
                        >
                            + Add new Branding
                        </Button>
                        <div className={classes.footer}>
                            Manage brandings in <Link to="/branding-details">company account</Link>
                        </div>
                    </>}
                </div>
            )}
        />
    );
});

export default withStyles(styles)(withTheme(BrandingOptionsDropdown));

import React, { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { withStyles } from '@mui/styles';
import styles from './styles';
import ExpandIcon from '../AnswerOptionImage/expandIcon.svg';

const AnswerOptionCodeEditor = ({ answer: { codeEditor }, classes }) => {
    const { wrapper, codeMirrorStyle, dialogPaper, expandButton } = classes;

    const [dialogOpen, setDialogOpen] = useState(false);

    const toggleDialogOpen = () => {
        setDialogOpen(!dialogOpen);
    };

    return (
        <>
            <div className={wrapper}>
                <CodeMirror
                    value={codeEditor}
                    options={{
                        lineNumbers: true,
                        theme: 'material',
                        mode: 'javascript'
                    }}
                />
            </div>
            <Button
                className={expandButton}
                onClick={toggleDialogOpen}
            >
                <img src={ExpandIcon} alt="<- ->" />
            </Button>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={dialogOpen}
                onClose={toggleDialogOpen}
                classes={{ paper: dialogPaper }}
            >
                <div
                    onClick={toggleDialogOpen}
                    className={codeMirrorStyle}
                >
                    <CodeMirror
                        value={codeEditor}
                        options={{
                            lineNumbers: true,
                            theme: 'material',
                            mode: 'javascript'
                        }}
                    />
                </div>
            </Dialog>
        </>
    );
};

export default withStyles(styles)(AnswerOptionCodeEditor);

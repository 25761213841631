import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { appCtx } from 'components/appStore';
import DefaultCross from 'libraries/Dialog/DefaultCross';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import { checkCompanyCanBeUpgraded } from 'helper/commonFunctions';
import Zendesk from 'helper/zendeskFunctions';
import LockIcon from './lock.svg';

import styles from './styles';

const UpgradeDialog = observer(({ open, classes, onClose }) => {
    const { company } = useContext(appCtx);
    const { plan } = company;
    const isPilot = plan && plan.level === 1;
    const canCompanyUpgrade = checkCompanyCanBeUpgraded(company);

    let planName = '';

    if (!isPilot && !canCompanyUpgrade) {
        planName = 'Enterprise';
    }

    const openZendesk = () => {
        Zendesk.open(true);
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}
        >
            <div className={classes.topBlock}>
                <img src={LockIcon} className={classes.lockIcon} alt="" />
                <div className={classes.header}>Candidate limit reached</div>
                <div className={classes.subheader}>
                    {planName === 'Enterprise'
                        ? 'To unlock candidate responses speak to your account executive today.'
                        : 'To unlock candidate responses you\'ll need to upgrade your plan.'
                    }
                </div>
                <DefaultCross className={classes.closeIcon} close={onClose} fill="#fff" />
            </div>
            <div className={classes.bottomBlock}>
                <div className={classes.buttonActions}>
                    {
                        canCompanyUpgrade ? (
                            <SubscriptionsLink
                                label={(
                                    <Button
                                        className={classes.upgradeButton}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Upgrade now
                                    </Button>
                                )}
                                segmentLocation="candidates table with locked responses"
                            />
                        ) : (
                            <Button
                                onClick={openZendesk}
                                className={classes.upgradeButton}
                                variant="contained"
                                color="primary"
                            >
                                Talk to us
                            </Button>
                        )
                    }
                    <Button
                        onClick={onClose}
                        color="primary"
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
});

export default withStyles(styles)(UpgradeDialog);

import React from 'react';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';

const format = 'D MMM yyyy';

const styles = {
    root: {
        '& .PrivatePickersToolbar-dateTitleContainer': {
            display: 'none'
        }
    }
};

function TicketDatePicker({ classes, value, onChangeTicket }) {
    const [stateValue, setStateValue] = React.useState(value ? moment(value).format(format) : '');

    const handleValue = (newValue) => {
        setStateValue(newValue);
        onChangeTicket(
            'ticketDateAsText',
            newValue
                ? moment(newValue).format(format)
                : ''
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label="Ticket date"
                value={stateValue}
                inputFormat="dd MMM yyyy"
                disableCloseOnSelect={false}
                okText={null}
                DialogProps={{
                    className: classes.root
                }}
                cancelText={null}
                onChange={(newValue) => {
                    handleValue(newValue);
                }}
                renderInput={params => (
                    <TextField
                        isError={!stateValue}
                        className="u-mrg--tx3 u-mrg--bx3"
                        fullWidth
                        {...params}
                    />
                )}
            />
        </LocalizationProvider>
    );
}

export default withStyles(styles)(TicketDatePicker);

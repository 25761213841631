export default theme => ({
    loader: {
        color: '#fff'
    },
    pauseDialogButton: {
        minWidth: 200
    },
    pauseButton: {
        '&:disabled': {
            color: theme.palette.grey[600]
        }
    }
});

import { get, patch, post } from '../helper/API';

export function createAssessmentRequest(body) {
    return post('employer/assessments/requests', body);
}

export function getCustomRequestsCount() {
    return get('employer/assessments/requests/count');
}

export function getCustomAssessmentRequests(data) {
    return get('employer/assessments/requests', data);
}

export function changeAssessmentRequestStatus(id, data) {
    return patch(`/employer/assessments/requests/${id}/status`, data);
}

export function getAssessmentRequestInfo(id) {
    return get(`/employer/assessments/requests/${id}`);
}

export function getFilterData() {
    return get('/employer/assessments/requests/filters');
}

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import TextInput from 'libraries/TextInput';
import Typography from '@mui/material/Typography';
import { changeNumberCorrectAnswer, createNumberCorrectAnswer } from 'requests/QuestionsRequests';

const styles = {
    wrapper: {
        marginTop: 15,
        marginBottom: 15
    }
};

let timerId = null;
let timerQuestionId = null;

const NumberCorrectAnswer = ({
    classes, question: { id, correctNumber },
    fetchQuestion, setSaving, isCMS
}) => {
    const [correctAnswer, setCorrectAnswer] = useState({});
    const [error, setError] = useState('');

    useEffect(() => {
        if (!correctNumber) {
            createCorrectNumber();
        } else {
            setCorrectAnswer(correctNumber);
        }
    }, [id]);

    const createCorrectNumber = async () => {
        const { data, success } = await createNumberCorrectAnswer(id, { text: '' }, isCMS);
        if (data && success) {
            fetchQuestion();
            setCorrectAnswer(data);
        }
    };

    const testRegex = str => /^[+-]?\d+(\.\d+)?$/.test(str);

    const onChange = (e) => {
        const { value: eValue } = e.target;
        const newValue = eValue.trim();
        if (newValue.length && !/^[\d.+-]+$/.test(newValue)) {
            return;
        }
        setError('');
        setSaving(true);
        setCorrectAnswer({ ...correctAnswer, text: newValue });
        clearTimeout(timerId);
        timerId = setTimeout(async () => {
            clearTimeout(timerQuestionId);
            try {
                await changeNumberCorrectAnswer(correctAnswer.id, { text: newValue }, isCMS);
                timerQuestionId = setTimeout(fetchQuestion, 500);
            } catch ({ response }) {
                if (response.data && response.data.errors) {
                    const { text } = response.data.errors;
                    setError(text && text[0] ? text[0].message : '');
                }
            }
            setSaving(false);
        }, 300);
    };

    return (
        <div className={classes.wrapper}>
            <Typography variant="h6">Correct answer</Typography>
            <p>Add correct answer for this question. Answer can be numbers only. No letters or characters.</p>
            <TextInput
                onChange={onChange}
                value={correctAnswer.text}
                isError={!correctAnswer.text || error || !testRegex(correctAnswer.text)}
                variant="outlined"
                label="Enter Answer"
                maxLength={25000}
                helperText={error}
            />
        </div>
    );
};

export default withStyles(styles)(NumberCorrectAnswer);

export default theme => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.2,
        marginBottom: 12
    },
    svgIconStyle: {
        maxWidth: 20,
        marginLeft: 4,
        width: 24,
        height: 24,
        color: '#fff',
        marginBottom: 0
    },
    questionLabel: {
        marginRight: 13,
        height: 32,
        padding: '4px 9px',
        borderRadius: 4,
        fontSize: 14,
        lineHeight: 1,
        display: 'flex',
        color: '#fff',
        fontWeight: 700,
        alignItems: 'center',
        backgroundColor: theme.palette.grey[400],
        '&$questionLabelCorrect': {
            backgroundColor: theme.palette.green[600]
        },
        '&$questionLabelActive': {
            backgroundColor: theme.palette.blue[500]
        },
        '&$questionLabelError': {
            backgroundColor: theme.palette.yellow[900]
        }
    },
    questionLabelActive: {},
    questionLabelError: {},
    questionLabelCorrect: {},
    skillName: {
        fontSize: 12,
        marginLeft: 5,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textTransform: 'uppercase',
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    tooltipClass: {
        padding: '5px 15px 7px'
    }
});

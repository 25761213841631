import React from 'react';
import {
    TEXT, MULTIPLE_CHOICE_AUTOGRADED, AUDIO,
    VIDEO, ANY_FILE, NO_ANSWER,
    GOOGLE_DOC, PRESENTATION,
    SPREADSHEET, CODE_TEST,
    CODE_TEST_AUTOGRADED, CYBER_SIMULATOR,
    FEEDBACK, SINGLE_CHOICE_AUTO_GRADED, SPREADSHEET_AUTO_GRADED,
    NUMBER, MULTIPLE_CHOICE_OLD, CS_SIMULATOR, TYPING_TEST, EXCEL_AUTO_GRADED,
    SALES_SIMULATOR, TABLE,

    TYPE_PROSPECT_LIST, TYPE_PROSPECT_SINGLE_CHOICE, TYPE_PROSPECT_SINGLE_EMAIL,
    TYPE_PROSPECT_SINGLE_EMAIL_CHOICE, TYPE_PROSPECT_SINGLE_VIDEO,
    TYPE_PROSPECT_SINGLE_AUDIO, TYPE_PROSPECT_SINGLE_NOTE,
    TYPE_PROSPECT_SINGLE_EMAIL_SUBJECT, TYPE_PROSPECT_SINGLE_EMPLOYEE_SUBJECT,
    TYPE_MARKET_EMAIL, TYPE_MARKET_SINGLE_CHOICE, TYPE_PROSPECT_LIST_TEXT, TYPE_PROSPECT_LIST_EMAIL,
    TYPE_PROSPECT_SINGLE_VOICE_MESSAGE
} from 'helper/constants';
import TextAnswer from './TextAnswer';
import MediaAnswer from './MediaAnswer';
import FileAnswer from './FileAnswer';
import GoogleQuestion from './GoogleQuestion';
import MultipleChoiceAnswer from './MultipleChoiceAnswer';
import CodeAnswer from './CodeAnswer';
import CodeChallengeAnswer from './CodeChallengeAnswer';
import NumberAnswer from './NumberAnswer';
import NoAnswerRequired from './NoAnswerRequired';
import NoAnswerProvided from './NoAnswerProvided';
import SimulationAnswer from './SimulationAnswer';
import CyberSimulationAnswer from './CyberSimulationAnswer';
import TypingTestAnswer from './TypingTestAnswer';
import ProspectListAnswer from './ProspectListAnswer';
import SalesSimulationAnswer from './SalesSimulationAnswer';
import TableAnswer from './TableAnswer';

const AnswerContent = ({
    question, questionIndex, userName = '',
    NoAnswerComponent = NoAnswerProvided,
    NoAnswerRequiredComponent = NoAnswerRequired,
    hasTruncate, setGradeQuestion,
    candidate, audition, propPublic,
    updateUserAuditionAndBox, canGrade,
    isIntegration, hideScoreLabel, isAiHelth,
    questionResults = []
}) => {
    if (!question) return null;
    const { question: questionContent, googleDriveFile, oneDriveFile, openedExternalFile, spreadsheetTestCasesResults } = question;
    let returnedAnswer;

    if (!questionContent) { // feedback question
        returnedAnswer = (
            <TextAnswer
                NoAnswerComponent={NoAnswerComponent}
                question={question}
                hasTruncate={hasTruncate}
            />
        );
    } else {
        const { answerType, required, description } = questionContent;

        switch (answerType) {
            case TEXT:
            case FEEDBACK:
            case TYPE_PROSPECT_SINGLE_EMAIL:
            case TYPE_PROSPECT_SINGLE_NOTE:
            case TYPE_PROSPECT_SINGLE_EMAIL_SUBJECT:
            case TYPE_PROSPECT_SINGLE_EMPLOYEE_SUBJECT:
            case TYPE_MARKET_EMAIL:
            case TYPE_PROSPECT_LIST_TEXT:
            case TYPE_PROSPECT_LIST_EMAIL:
                returnedAnswer = (
                    <TextAnswer
                        question={question}
                        NoAnswerComponent={NoAnswerComponent}
                        hasTruncate={hasTruncate}
                    />);
                break;
            case SALES_SIMULATOR:
                returnedAnswer = (
                    <SalesSimulationAnswer
                        question={question}
                        NoAnswerComponent={NoAnswerComponent}
                    />
                );
                break;
            case AUDIO:
            case VIDEO:
            case TYPE_PROSPECT_SINGLE_VIDEO:
            case TYPE_PROSPECT_SINGLE_AUDIO:
            case TYPE_PROSPECT_SINGLE_VOICE_MESSAGE:
                returnedAnswer = (
                    <MediaAnswer
                        audio={[AUDIO, TYPE_PROSPECT_SINGLE_AUDIO, TYPE_PROSPECT_SINGLE_VOICE_MESSAGE].includes(answerType)}
                        question={question}
                        NoAnswerComponent={NoAnswerComponent}
                        showTranscript={!isAiHelth}
                    />
                );
                break;
            case ANY_FILE:
                returnedAnswer = <FileAnswer question={question} NoAnswerComponent={NoAnswerComponent} />;
                break;
            case NO_ANSWER:
                returnedAnswer = <NoAnswerRequiredComponent />;
                break;
            case MULTIPLE_CHOICE_AUTOGRADED:
            case SINGLE_CHOICE_AUTO_GRADED:
            case MULTIPLE_CHOICE_OLD:
            case TYPE_PROSPECT_SINGLE_CHOICE:
            case TYPE_PROSPECT_SINGLE_EMAIL_CHOICE:
            case TYPE_MARKET_SINGLE_CHOICE:
                returnedAnswer = (
                    <MultipleChoiceAnswer
                        question={question}
                        singleChoice={answerType === SINGLE_CHOICE_AUTO_GRADED}
                        hideScoreLabel={hideScoreLabel}
                    />);
                break;
            case TYPE_PROSPECT_LIST:
                returnedAnswer = (
                    <ProspectListAnswer
                        questionResults={questionResults}
                    />
                );
                break;
            case GOOGLE_DOC:
            case PRESENTATION:
            case SPREADSHEET:
            case SPREADSHEET_AUTO_GRADED:
            case EXCEL_AUTO_GRADED:
                returnedAnswer = (
                    <GoogleQuestion
                        canGrade={canGrade}
                        audition={audition}
                        candidate={candidate}
                        setGradeQuestion={setGradeQuestion}
                        questionNumber={questionIndex}
                        currentQuestion={question}
                        question={{ answerType, googleDriveFile, oneDriveFile, openedExternalFile, required, description, spreadsheetTestCasesResults }}
                        userName={userName}
                        propPublic={propPublic}
                        updateUserAuditionAndBox={updateUserAuditionAndBox}
                        isIntegration={isIntegration}
                    />
                );
                break;
            case CODE_TEST:
                returnedAnswer = <CodeAnswer question={question} />;
                break;
            case CODE_TEST_AUTOGRADED:
                returnedAnswer = (
                    <CodeChallengeAnswer
                        NoAnswerComponent={NoAnswerComponent}
                        question={question}
                    />
                );
                break;
            case NUMBER:
                returnedAnswer = (
                    <NumberAnswer
                        question={question}
                        NoAnswerComponent={NoAnswerComponent}
                    />
                );
                break;
            case CYBER_SIMULATOR:
                returnedAnswer = (
                    <CyberSimulationAnswer
                        question={question}
                        NoAnswerComponent={NoAnswerComponent}
                    />
                );
                break;
            case CS_SIMULATOR:
                returnedAnswer = (
                    <SimulationAnswer
                        question={question}
                        NoAnswerComponent={NoAnswerComponent}
                    />
                );
                break;
            case TYPING_TEST:
                returnedAnswer = (
                    <TypingTestAnswer
                        question={question}
                        NoAnswerComponent={NoAnswerComponent}
                        hasTruncate={hasTruncate}
                    />
                );
                break;
            case TABLE:
                returnedAnswer = (
                    <TableAnswer
                        question={question}
                        NoAnswerComponent={NoAnswerComponent}
                    />
                );
                break;
            default:
                returnedAnswer = null;
        }
    }

    return returnedAnswer;
};

export default AnswerContent;

import Checkbox from 'libraries/Checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { switchAssessmentSendRejectionEmail } from 'requests/EmailRequests';
import PreviewRejectionEmailButton from 'components/assessments_pages/asessment_settings_components/InstructionToCandidatesSection/components/PreviewRejectionEmailButton';
import { assessmentSettingsCtx } from '../../store';

const styles = () => ({
    checkboxLabel1: {
        width: 'auto'
    }
});

const RejectionEmailFooter = observer(({ classes }) => {
    const [notifyRejectEmail, setNotifyRejectEmail] = useState(false);
    const { audition, setAudition } = useContext(assessmentSettingsCtx);
    const { slug, sendRejectEmail } = audition;

    useEffect(() => {
        setNotifyRejectEmail(sendRejectEmail);
    }, [slug]);

    const handleChangeNotifyAboutReject = async (e) => {
        const { checked } = e.target;
        setNotifyRejectEmail(checked);
        await switchAssessmentSendRejectionEmail(slug);
        setAudition({ ...audition, sendRejectEmail: checked });
    };

    return (
        <div className="u-dsp--distribute">
            <Checkbox
                label="Email candidates when rejecting?"
                className={classes.checkboxLabel1}
                checked={notifyRejectEmail}
                onChange={handleChangeNotifyAboutReject}
            />
            <PreviewRejectionEmailButton audition={audition} />
        </div>
    );
});

export default withStyles(styles)(RejectionEmailFooter);

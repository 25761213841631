import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/Tooltip';
import AssessmentEvents from 'events/AssessmentEvents';
import InsightsIcon from './components/insightsIcon';

const styles = {
    previewButton: {
        lineHeight: '21px',
        whiteSpace: 'nowrap',
        padding: 5,
        height: 39,
        width: 39,
        minWidth: 34,
        marginRight: 12
    },
    previewButtonDisabled: {
        opacity: 0.4
    }
};

const InsightsButton = ({ variant = 'outlined', assessment, classes, onClick, className = '', disabled }) => {
    const handlePreviewScript = (e) => {
        e.stopPropagation();
        const { uuid: ttId, name: ttName } = assessment;
        AssessmentEvents.INSIGHTS_VIEWED({ ttId, ttName });
        if (onClick && !disabled) onClick();
    };


    return (
        <Tooltip
            label="Insights"
            tooltipClass={classes.tooltip}
        >
            <Button
                variant={variant}
                disabled={disabled}
                className={clsx(classes.previewButton, disabled && classes.previewButtonDisabled, className)}
                onClick={handlePreviewScript}
            >
                <InsightsIcon />
            </Button>
        </Tooltip>
    );
};

export default withRouter(withStyles(styles)(InsightsButton));

import React from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import StatsItem from 'components/assessments_pages/AssessmentStatsItem';

import ActiveLearningStatus from '../../ActiveLearningStatus';
import TimeRequested from '../../TimeRequested';

import styles from './styles';

const AssessmentStats = observer(({
    classes, statsArr, activeLearningStatistic,
    requestedMoreTimeCount, loading, onExtendRequestSuccess,
    extensionRequestedCandidates, setExtensionRequestedCandidates,
    loadExtensionsRequests, audition,
    isOnboarding
}) => (
    <div className={classes.wrapper}>
        <div className={classes.content}>
            {
                loading ? (
                    <div className={classes.loaderWrapper}>
                        <CircularProgress color="primary" size={25} className="u-mrg--tx2" />
                    </div>
                ) : (
                    <>
                        <div className={classes.stats}>
                            {!!(requestedMoreTimeCount && Number.isInteger(requestedMoreTimeCount)) && audition?.active && (
                                <TimeRequested
                                    requestedMoreTimeCount={requestedMoreTimeCount}
                                    extensionRequestedCandidates={extensionRequestedCandidates}
                                    setExtensionRequestedCandidates={setExtensionRequestedCandidates}
                                    loadExtensionsRequests={loadExtensionsRequests}
                                    onExtendRequestSuccess={onExtendRequestSuccess}
                                />
                            )}
                            {
                                statsArr && statsArr.map((item, key) => (
                                    <StatsItem isPercentageGrey key={key} {...item} />
                                ))
                            }
                        </div>
                        {activeLearningStatistic && audition?.active && (
                            <ActiveLearningStatus
                                {...activeLearningStatistic}
                                isOnboarding={isOnboarding}
                            />
                        )}
                    </>
                )
            }
        </div>
    </div>
));

export default withStyles(styles)(AssessmentStats);

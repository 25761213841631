import React from 'react';
import Linkify from 'react-linkify';
import { withStyles } from '@mui/styles';

const styles = {
    textStyle: {
        '& a:active': {
            color: 'rgb(85, 26, 139)'
        }
    }
};

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
    </a>
);


const LinkifyComponent = ({ children, classes, className }) => {
    if (!children) return null;
    return (
        <Linkify componentDecorator={componentDecorator}>
            <p className={`${classes.textStyle} ${className}`}>{children}</p>
        </Linkify>
    );
};

export default withStyles(styles)(LinkifyComponent);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import CandidateTabEvents from 'events/CandidateTabEvents';
import TableFilter from 'components/table_components/table_filter';

import { candidateCtx } from '../../AllCandidates/candidateStore';

const AllCandidatesTableFilter = observer(({ handleChangeFilterSelect, filterCategory }) => {
    const { allCandidatesStats } = useContext(candidateCtx);
    const { all, rejected, active, hired } = allCandidatesStats;

    const options = [{
        value: 'all',
        label: 'Show all',
        count: all
    }, {
        value: 'rejected',
        label: 'Rejected Candidates',
        count: rejected
    }, {
        value: 'active',
        label: 'Active Candidates',
        count: active
    }, {
        value: 'hired',
        label: 'Hired Candidates',
        count: hired
    }];

    options.forEach((item) => { item.checked = filterCategory === item.value; });

    const handleChange = (value) => {
        handleChangeFilterSelect(value);
        CandidateTabEvents.CANDIDATES_FILTERED(value, 'All candidates page');
    };

    return (
        <TableFilter
            isFilterActive={filterCategory !== 'all'}
            isCheckbox={false}
            showCountNumber
            options={options}
            innerHeader="Candidates"
            handleChange={handleChange}
        />
    );
});

export default AllCandidatesTableFilter;

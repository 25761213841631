import React from 'react';
import { CODE_TEST } from 'helper/constants';

const Svg = (onClick, className) => (

    <svg
        className={className}
        onClick={e => onClick(e, CODE_TEST)}
        width="24"
        height="15"
        viewBox="0 0 24 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M14.8472 1L9.52129 13.7821" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.9229 12.3349L22.9998 7.58313L17.9229 2.83139" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.07715 12.3349L1.00022 7.58313L6.07715 2.83139" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default Svg;

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';
import { Button, ButtonBase } from '@mui/material';

import TextInput from 'libraries/TextInput';
import { createAssessmentTag } from 'requests/CMSRequests';
import { appCtx } from 'components/appStore';
import CreateTagDialog from 'components/dialogs/ConfirmDialog';

const useStyles = makeStyles(theme => ({
    button: {
        fontWeight: 700,
        marginLeft: 5
    },
    buttonBase: {
        color: theme.palette.primary.main
    }
}));

const CreateAssessmentTagButton = observer(({ tags, setTags, isBoardNoContent = false, setTotalTagsCount, totalTagsCount }) => {
    const [isLoading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [createDialogOpened, setCreateDialogOpened] = useState(false);
    const [nameError, setNameError] = useState('');

    const { button, buttonBase } = useStyles();
    const { flashMessage } = useContext(appCtx);

    const handleDialog = () => {
        const newValue = !createDialogOpened;
        if (!newValue) {
            setValue('');
            setNameError('');
        }
        setCreateDialogOpened(newValue);
    };

    const handleChange = (e) => {
        const { value: eValue } = e.target;
        setValue(eValue);
        setNameError('');
    };

    const onAddTag = () => {
        setLoading(true);
        createAssessmentTag(value)
            .then(({ success, data }) => {
                if (success && data) {
                    setTags([data, ...tags]);
                    handleDialog();
                    flashMessage(`${value} Assessment Tag created`, 'done');
                    setTotalTagsCount(totalTagsCount + 1);
                    setNameError('');
                }
            })
            .catch(({ response: { data: { msg } } }) => {
                setNameError(msg);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {
                isBoardNoContent ? (
                    <ButtonBase
                        className={buttonBase}
                        onClick={handleDialog}
                    >
                        Add your tags now
                    </ButtonBase>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        className={button}
                        onClick={handleDialog}
                    >
                        Create New Assessment Tag
                    </Button>
                )
            }
            <CreateTagDialog
                open={createDialogOpened}
                onClose={handleDialog}
                actionButtonText="Add"
                onAction={onAddTag}
                title="Create new assessment tag"
                disabledAction={!value.trim().length}
                loading={isLoading}
            >
                <TextInput
                    label="Assessment Tag Name"
                    hasCounter
                    value={value}
                    maxLength={150}
                    variant="outlined"
                    onChange={handleChange}
                    helperText={nameError}
                    isError={Boolean(nameError)}
                />
            </CreateTagDialog>
        </>
    );
});

export default CreateAssessmentTagButton;

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import FolderIcon from 'components/icons/FoldersIcon';

import BoardItemMenu from 'components/board/board_item/board_item_menu';

import DeleteIcon from 'img/trash.svg';
import EditIcon from 'img/edit.svg';


import { FilterCtx } from 'pages/expert_assessments/filter-store';
import CardMenuItem from '../CardMenuItem';
import DeleteCategoryDialog from '../DeleteDialog';
import RenameCategoryDialog from '../RenameDialog';

import styles from '../styles';


const CategoryCard = observer(({
    classes, activeFolderId, setActiveFolder,
    title, uuid, companyAuditionsCount,
    renameFolder, deleteFolder
}) => {
    const { removeFilterPropertyFromOutside } = useContext(FilterCtx);

    const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
    const [renameFolderDialogOpen, setRenameFolderDialogOpen] = useState(false);

    let currentUuid = uuid;
    if (title === 'All') {
        currentUuid = null;
    }

    const active = (currentUuid === activeFolderId);

    const handleClick = () => {
        if (setActiveFolder) setActiveFolder(currentUuid);
    };

    return <>
        <div
            role="presentation"
            className={clsx(classes.card, active && classes.active)}
            onClick={handleClick}
        >
            <div className={classes.header}>
                <TruncateWithTooltip text={title} width={100} />
                <div>
                    {Boolean(currentUuid) && (
                        <div className={classes.menu}>
                            <BoardItemMenu
                                iconMenuClassName={classes.menuIcon}
                                options={[
                                    {
                                        label: <CardMenuItem label="Edit Category Name" icon={EditIcon} />,
                                        onClick: () => { setRenameFolderDialogOpen(!renameFolderDialogOpen); }
                                    },
                                    {
                                        label: <CardMenuItem label="Delete Category" icon={DeleteIcon} />,
                                        onClick: () => { setDeleteFolderDialogOpen(!deleteFolderDialogOpen); } }
                                ]}
                            />
                            <IconButton className={classes.organiseButton} disabled size="large">
                                <FolderIcon color={active && '#1247F6'} />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
            <div className={classes.footer}>
                {companyAuditionsCount} <span>Assessment{companyAuditionsCount !== 1 && 's'}</span>
            </div>
        </div>
        <DeleteCategoryDialog
            open={deleteFolderDialogOpen}
            onClose={() => setDeleteFolderDialogOpen(false)}
            id={currentUuid}
            title={title}
            deleteFolder={(id, name) => {
                removeFilterPropertyFromOutside('categories', name);
                return deleteFolder(id, name);
            }}
        />
        <RenameCategoryDialog
            name={title}
            open={renameFolderDialogOpen}
            onClose={() => setRenameFolderDialogOpen(false)}
            id={currentUuid}
            renameFolder={renameFolder}
        />
    </>;
});

export default withStyles(styles)(CategoryCard);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    timerIcon: {
        marginRight: 5
    },
    timerLabel: {
        color: theme.palette.grey[700],
        fontWeight: 600,
        fontSize: 12,
        display: 'flex',
    }
}));

export default theme => ({
    wrapper: {
        display: 'flex',
        marginBottom: 25,
        marginTop: -8,
        overflowX: 'auto'
    },
    category: {
        whiteSpace: 'nowrap',
        fontWeight: 600,
        letterSpacing: 0.2,
        marginRight: 20,
        color: theme.palette.grey[500]
    },
    categoryValue: {
        color: theme.palette.grey[900]
    }
});

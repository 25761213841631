import { makeStyles } from '@mui/styles';

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
    appBar: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        height: 56
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: -drawerWidth
    },
    mobileLink: {
        cursor: 'pointer',
        marginTop: 5,
        color: theme.palette.grey[600],
        '&:hover': {
            color: theme.palette.grey[900],
            background: 'none'
        }
    },
    menuIcon: {
        color: 'black'
    },
    beamer: {
        marginTop: 5,
        marginRight: 5
    }
}));

export default useStyles;

require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');

require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');

export default {
    lineNumbers: true,
    theme: 'material',
    mode: 'javascript'
};

import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import {
    getQuestionCategoriesList, getQuestionsListByCategory,
    replaceQuestionWithBankQuestion, searchByCompanyQuestionCategory
} from 'requests/QuestionsRequests';
import { deleteCompanyQuestion, deleteCompanyCategory } from 'requests/CMSRequests';
import { appCtx } from 'components/appStore';
import { capitalizeEachFirstLetter } from 'helper/commonFunctions';
import AssessmentEvents from 'events/AssessmentEvents';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';


import { assessmentSettingsCtx } from 'pages/assessment/store';
import { assessmentEditCtx } from '../../../../store';

import QuestionGroupsDropdown from '../../common/QuestionGroupsDropdown';
import CommonContent from './CommonContent';


const GroupContent = observer(({
    question, questionsList,
    setQuestionsList, query, setQuery,
    loadingQuestions, setLoadingQuestions,
    loading, setLoading,
    loadingAddButton, setLoadingAddButton
}) => {
    const [questionGroups, setQuestionGroups] = useState([]);
    const [questionGroup, setQuestionGroup] = useState({});
    const { id, title: questionGroupName } = questionGroup;
    const { flashMessage } = useContext(appCtx);
    const { changeAuditionParameter, audition } = useContext(assessmentSettingsCtx);
    const { questions: assessmentQuestions } = useContext(assessmentEditCtx);

    useEffect(() => {
        if (id) loadQuestionsListByGroup(id);
    }, [id]);

    useEffect(() => {
        setLoading(true);
        loadQuestionCategoriesList();
    }, []);

    const loadQuestionCategoriesList = () => {
        getQuestionCategoriesList()
            .then(({ success, data }) => {
                if (success && data && data.length) {
                    setQuestionGroups(data);
                    const foundCategory = question.questionGroup
                        ? data.find(({ title: dataTitle }) => dataTitle === question.questionGroup.title)
                        : null;
                    if (foundCategory) {
                        setQuestionGroup(foundCategory);
                    } else {
                        setQuestionGroup(data[0]);
                    }
                } else {
                    setQuestionGroups([]);
                    setQuestionGroup({});
                }
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const loadQuestionsListByGroup = (categoryId) => {
        setLoadingQuestions(true);
        getQuestionsListByCategory(categoryId)
            .then(({ success, data }) => {
                if (success && data) {
                    setQuestionsList(data);
                }
            })
            .finally(() => {
                setLoadingQuestions(false);
            });
    };

    const searchByGroup = (requestQuery) => {
        if (!questionGroup || !questionGroup.id) return;
        searchByCompanyQuestionCategory(questionGroup.id, requestQuery)
            .then(({ success, data }) => {
                if (success && data) {
                    setQuestionsList(data);
                    setLoadingQuestions(false);
                    AssessmentEvents.SAVED_QUESTION_SEARCH({
                        searchTerm: requestQuery,
                        numberResults: data.length,
                        skill: questionGroupName
                    });
                }
            })
            .catch(() => {
                setLoadingQuestions(false);
                flashMessage('Something went wrong', 'error');
            });
    };

    const removeCategory = () => {
        deleteCompanyCategory(questionGroup.id)
            .then(() => {
                AssessmentSettingsEvents.SAVED_GROUP_QUESTION_DELETED({ groupName: questionGroup.title });
                loadQuestionCategoriesList();
                flashMessage('Skill group deleted', 'done');
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    const onReplace = (data) => {
        const { questionGroup: newQuestionGroup, id: newId } = data;
        if (assessmentQuestions.some(q => newId === q.id)) {
            const { questionGroups: auditionQuestionGroups } = audition;
            let auditionHasGroup = true;
            if (auditionQuestionGroups) { // if adding question of non-existent group
                auditionHasGroup = newQuestionGroup && auditionQuestionGroups.some(item => item.id === newQuestionGroup.id);
            }
            if (!auditionHasGroup) {
                changeAuditionParameter('questionGroups', auditionQuestionGroups.concat([newQuestionGroup]));
            }
        }
    };

    let bannerText = '';
    let bannerHeader = '';

    if (!questionGroups.length) {
        bannerHeader = 'You haven’t added any questions';
        bannerText = 'Save any question from an assessment';
    } else if (query) {
        bannerHeader = `We couldn't find a match for "${query}"`;
        bannerText = 'Check your spelling or try a new search';
    } else {
        bannerHeader = `You haven’t added any questions ${questionGroupName ? `to ‘${capitalizeEachFirstLetter(questionGroupName)}’` : ''}`;
        bannerText = (
            <Button
                color="primary"
                onClick={removeCategory}
            >
                Delete empty skill group
            </Button>
        );
    }

    return (
        <CommonContent
            question={question}
            questionsList={questionsList}
            setQuestionsList={setQuestionsList}
            query={query}
            setQuery={setQuery}
            loadingQuestions={loadingQuestions}
            setLoadingQuestions={setLoadingQuestions}
            loading={loading}
            loadingAddButton={loadingAddButton}
            setLoadingAddButton={setLoadingAddButton}
            onSearch={searchByGroup}
            replaceQuestion={replaceQuestionWithBankQuestion}
            onReplace={onReplace}
            removeQuestion={deleteCompanyQuestion}
            emptyBannerHeader={bannerHeader}
            emptyBannerText={bannerText}
            disabledSearch={!questionGroups.length}
            questionGroupName={questionGroupName}
            SearchComp={(
                <QuestionGroupsDropdown
                    setQuery={setQuery}
                    questionGroups={questionGroups}
                    setQuestionGroup={setQuestionGroup}
                    questionGroupId={questionGroup.id}
                    loadQuestionsListByGroup={loadQuestionsListByGroup}
                />
            )}
        />
    );
});

export default GroupContent;

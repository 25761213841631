import React from 'react';
import Checkbox from 'libraries/Checkbox';
import Tooltip from 'libraries/TooltipWhite';
import { checkCompanyCanBeUpgraded, checkHasCompanyFeature } from 'helper/commonFunctions';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';

const ExportQuestionsAndAnswersCheckbox = ({
    classes, company,
    onChange, checked,
    disableExportQuestions
}) => {
    const hasFeatureExportQuestionsAndAnswers = checkHasCompanyFeature(company, 'CSV_EXPORT_CANDIDATE_QUESTIONS_AND_ANSWERS');
    const canUpgrade = checkCompanyCanBeUpgraded(company);
    return (
        <>
            <Checkbox
                data-tip
                data-for="questions_tooltip"
                label="Export candidate questions and answers"
                name="exportQuestions"
                checked={checked}
                styles={{ label: classes.checkboxLabel }}
                value="exportQuestions"
                onChange={onChange}
                disabled={!hasFeatureExportQuestionsAndAnswers || disableExportQuestions}
                helperText="Export all candidate questions and answers when exporting from a singular assessment."
            />
            {
                !hasFeatureExportQuestionsAndAnswers && (
                    <Tooltip
                        id="questions_tooltip"
                        delayHide={100}
                    >
                        The ability to export candidate questions and answers is not available on the current plan.
                        {
                            canUpgrade
                                ? <SubscriptionsLink label="Upgrade" className={classes.talkToUsLabel} segmentLocation="export candidate questions and answers tooltip" />
                                : (
                                    <div
                                        role="presentation"
                                        className={classes.talkToUsLabel}
                                        onClick={() => window.open('/subscriptions', '_blank')}
                                    >Upgrade.
                                    </div>
                                )
                        }
                    </Tooltip>
                )
            }
        </>
    );
};

export default ExportQuestionsAndAnswersCheckbox;

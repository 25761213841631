export default theme => ({
    loaderWrapper: {
        textAlign: 'center',
        marginTop: 35
    },
    allCandidatesTab: {
        width: 240,
        paddingLeft: 0,
        paddingRight: 0
    },
    shortlistTab: {
        width: 180,
        paddingLeft: 0,
        paddingRight: 0
    },
    pageHeader: {
        color: theme.palette.grey[600],
        fontSize: 14,
        fontWeight: 600
    },
    contentWrapper: {
        padding: 0,
        marginBottom: 0
    },
    innerTabs: {
        width: '100%',
        margin: 0
    },
    content: {
        overflow: 'auto',
        minHeight: 250
    },
    allLabel: {
        color: theme.palette.grey[500]
    },
    closeIcon: {
        padding: 4,
        marginLeft: 10,
        '& > img': {
            width: 16
        }
    },
    wrapper: {
        padding: '40px 0 0 30px'
    },
    status: {
        backgroundColor: 'red',
        position: 'relative',
        display: 'inline-block',
        fontSize: 11,
        textTransform: 'capitalize',
        fontWeight: 700,
        borderRadius: 4,
        padding: '6px 15px',
        marginRight: 10
    },
    statusDONE: {
        backgroundColor: `${theme.palette.green[600]}26`,
        color: theme.palette.green[800]
    },
    statusREJECT: {
        backgroundColor: theme.palette.red[50],
        color: theme.palette.red[600]
    },
    statusTODO: {
        backgroundColor: `${theme.palette.grey[600]}26`,
        color: theme.palette.grey[800]
    },
    statusDONE_dark: {
        backgroundColor: theme.palette.green[600],
        color: 'white',
        cursor: 'pointer'
    },
    statusREJECT_dark: {
        backgroundColor: theme.palette.red[600],
        color: 'white',
        cursor: 'pointer'
    },
    statusTODO_dark: {
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        cursor: 'pointer'
    },
    popoverRoot: {
        marginTop: 8
    },
    popoverItem: {
        fontSize: 12,
        fontWeight: 600,
        color: '#3A3C43',
        margin: '10px 0',
        lineHeight: '24px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    popoverPaper: {
        width: 160,
        padding: '4px 14px'
    }
});

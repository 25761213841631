import React from 'react';
import { withTheme } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import MenuItem from '@mui/material/MenuItem';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import useStyles from './styles';

const CountNumber = ({ value }) => {
    const classes = useStyles();
    if (value === null) return null;
    return (
        <span className={classes.countNumber}>
            {value}
        </span>
    );
};

const CustomMenuItem = ({
    value, label, showCountNumber,
    isCheckbox = true, handleChange,
    checked, count, CustomIconComp
}) => {
    const classes = useStyles();

    const checkboxProps = {
        disableRipple: true,
        checked: Boolean(checked),
        className: classes.checkbox
    };

    return (
        <MenuItem
            key={value}
            value={value}
            disabled={!handleChange}
            onClick={() => handleChange(value)}
        >
            <div className={classes.filterOption}>
                <div className="u-dsp--f">
                    {
                        CustomIconComp || (
                            <>
                                {
                                    isCheckbox ? (
                                        <Checkbox {...checkboxProps} />
                                    ) : (
                                        <Radio {...checkboxProps} />
                                    )
                                }
                            </>
                        )
                    }
                    <TruncateWithTooltip capitalize text={label} width={170} />
                </div>
                { showCountNumber && <CountNumber value={count} /> }
            </div>
        </MenuItem>
    );
};

export default withTheme(CustomMenuItem);

export default theme => ({
    container: {
        width: 350
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 25
    },
    button: {
        width: '100%',
        height: 48,
        marginTop: 25
    },
    heading: {
        fontWeight: 600,
        fontSize: 16
    },
    failHeading: {
        fontWeight: 700,
        fontSize: 20
    },
    bottomText: {
        textAlign: 'right',
        marginTop: 10,
        color: theme.palette.grey[900],
        fontWeight: 700,
        fontSize: 12
    },
    goBack: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    goBackButton: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginTop: 30
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    input: {
        width: '100%',
        marginTop: 25
    }
});

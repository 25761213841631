import { tracking } from 'helper/eventSegment';

export default {
    PLAN_CANCEL_ABANDONED: (reasonLink, feedback) => {
        tracking('A-plan-cancel-abandoned', { reasonLink, feedback });
    },
    PLAN_CONTACT: () => {
        tracking('A-plan-contact');
    },
    PLAN_UPGRADE_FAILED: (data) => {
        tracking('A-plan-upgrade-failed', data);
    },
    UPGRADE_BUTTON_CLICKED: (location, isAF) => {
        tracking('U-button-clicked', { location, isAF });
    },
    BUY_PLAN_CLICKED: (selectedPlan) => {
        tracking('A-buy-plan-clicked', selectedPlan);
    }

};

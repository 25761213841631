import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './styles';
import { subscriptionDialogStoreCtx } from '../../store';
import PlanPrice from './PlanPrice';
import PlanPriceSwitch from './PlanPriceSwitch';

const PlanSelectList = observer(({ classes, isOutdated }) => {
    const {
        availablePlans, selectedPlan, selectPlan,
        handleSelectInterval, companyPlan, selectPrice
    } = useContext(subscriptionDialogStoreCtx);

    if (!selectedPlan) return null;

    const handleClick = (plan) => {
        const planHasOnlyYearlySubscription = plan.publicAuditionPlans.length === 1;
        if (planHasOnlyYearlySubscription) handleSelectInterval('yearly');
        selectPrice(null);
        selectPlan(plan);
    };

    return (
        <div className={clsx(classes.wrapper, isOutdated && classes.outdated)}>
            <p className={classes.changePlanText}>CHANGE PLAN</p>
            {
                availablePlans.map((plan) => {
                    const { name, id } = plan;
                    const isSelected = selectedPlan.id === id;
                    const isCurrentPlan = companyPlan.name === name;
                    return (
                        <div
                            key={id}
                            onClick={() => handleClick(plan)}
                            role="presentation"
                            className={clsx(classes.item, isSelected && classes.itemSelected)}
                        >
                            {isCurrentPlan && <div className={clsx(classes.currentPlan, 'u-txt--14')}>CURRENT PLAN</div> }
                            <FormControlLabel
                                className="u-mrg--lx2 u-mrg--tx5"
                                classes={{ label: 'u-txt--bold u-txt--20 u-mrg--lx2' }}
                                control={<Radio disableRipple checked={isSelected} />}
                                label={name.toUpperCase()}
                            />
                            <PlanPrice plan={plan} />
                            <PlanPriceSwitch plan={plan} />
                        </div>
                    );
                })
            }
        </div>
    );
});

export default withStyles(styles)(PlanSelectList);

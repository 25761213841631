import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Pagination from 'components/board/board_pagination';
import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';


import { CANDIDATES_PER_RESULTS_PAGE } from 'helper/constants';

import CandidateRow from './CandidateRow';

import useStyles from './styles';


const CandidatesTable = observer(({
    handleChangePage, allCandidates,
    totalCount, page, isLoading, isLoadingLocal,
    audition, companyTags = []
}) => {
    const classes = useStyles();
    const totalPagesCount = Math.ceil(totalCount / CANDIDATES_PER_RESULTS_PAGE);

    const hasGroups = audition && audition.scoreType === 3;

    return (
        <TableContainer className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCellHead label="Rank" className={clsx(classes.tableCellHead, classes.tableCellRank)} hideSort />
                        <TableCellHead
                            label="Name"
                            className={clsx(classes.tableCellHead, classes.tableCellName)}
                            labelComponent="Candidate"
                            hideSort
                        />
                        <TableCellHead className={classes.tableCellHead} label="Completed" hideSort />
                        <TableCellHead className={classes.tableCellHead} label="Score" hideSort />
                        {
                            hasGroups && (
                                <TableCellHead className={classes.tableCellHead} label="Skill Scores" hideSort />
                            )
                        }
                        <TableCellHead className={classes.tableCellHead} label=" " hideSort align="right" hideSort />
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {
                        (isLoading || isLoadingLocal) && (
                            <div className={classes.loaderWrapper}>
                                <CircularProgress color="primary" />
                            </div>
                        )
                    }
                    {
                        allCandidates && allCandidates
                            .map(candidate => (
                                <CandidateRow
                                    key={candidate.candidateId}
                                    companyTags={companyTags}
                                    candidate={candidate}
                                    audition={audition}
                                />
                            ))
                    }
                </TableBody>
            </Table>
            <Pagination
                currentPage={page}
                totalPages={totalPagesCount}
                handleChangePage={handleChangePage}
            />
        </TableContainer>
    );
});

export default CandidatesTable;

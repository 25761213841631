import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    root: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        color: theme.palette.grey[900],
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        fontWeight: 700,
        transition: 'all 0.3s',
        padding: '6px 25px 6px 13px',
        cursor: 'pointer',
        marginLeft: 15,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 7
        },
        '&:hover': {
            borderColor: theme.palette.primary.main
        },
        '& > svg': {
            marginRight: 8
        }
    },
    rootActive: {
        borderColor: theme.palette.primary.main
    },
    menu: {
        maxHeight: 310,
        overflow: 'auto',
        minWidth: 240,
        padding: '10px 4px'
    },
    filterHeader: {
        color: theme.palette.grey[800],
        padding: '9px 15px',
        '&:not(:first-child)': {
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            marginTop: 10
        }
    },
    popover: {
        marginTop: 8
    },
    filterCount: {
        color: theme.palette.primary.main,
        paddingLeft: 15,
        marginLeft: 15,
        marginRight: -7,
        borderLeft: `1px solid ${theme.palette.grey[300]}`
    },
    rootDisabled: {
        color: 'rgba(0, 0, 0, 0.26)',
        cursor: 'default',
        '&:hover': {
            border: `2px solid ${theme.palette.grey[300]}`
        }
    }
}));

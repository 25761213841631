import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { Typography, IconButton } from '@mui/material';
import TrashIcon from 'components/icons/TrashIcon';
import Tooltip from 'libraries/Tooltip';
import AssessmentEvents from 'events/AssessmentEvents';

import SidebarBox from '../../SidebarBox';
import { assessmentEditCtx } from '../../../store';
import { assessmentSettingsCtx } from '../../../../../store';
import DeleteScreeningDialog from './DeleteScreeningDialog';

import ScreeningButton from './ScreeningButton';
import CreateBox from '../CreateBox';
import styles from './styles';


const ScreeningList = observer(({ classes, match, slug }) => {
    const [open, setOpen] = useState(false);
    const { screeningQuestions, selectedSkill, addNewQuestion, setActiveTab } = useContext(assessmentEditCtx);
    const { audition } = useContext(assessmentSettingsCtx);
    const { scoreType, uuid, name } = audition;
    const { scriptSlug } = match.params;

    const handleDialog = () => {
        setOpen(!open);
    };

    const handleClickScrollPosition = () => {
        const element = document.getElementById('screening_list');
        if (!element) return;
        element.scrollTop = element.scrollHeight;
    };


    const createNewQuestion = () => {
        const questionCount = screeningQuestions.length;
        return addNewQuestion(scriptSlug, selectedSkill !== 'all' ? selectedSkill : undefined, true)
            .then(() => {
                if (!questionCount) {
                    AssessmentEvents.SCREENER_ADDED({
                        ttUUID: uuid,
                        ttName: name
                    });
                } else {
                    AssessmentEvents.SCREENER_QUESTION_ADDED({
                        ttUUID: uuid,
                        ttName: name,
                        questionNumber: questionCount
                    });
                }
                handleClickScrollPosition();
                setActiveTab(1);
            });
    };


    return (
        <div className={classes.wrapper}>
            <ScreeningButton
                createNewQuestion={createNewQuestion}
                clickable={!screeningQuestions.length}
                questionsLength={screeningQuestions.length}
                hasErrors={screeningQuestions.some(item => !item.valid)}
            />
            <DeleteScreeningDialog
                open={open}
                slug={slug}
                audition={audition}
                onClose={handleDialog}
                screeningQuestionsNumber={screeningQuestions.length}
            />
            {
                Boolean(screeningQuestions.length) && (
                    <>
                        <div className="u-dsp--distribute">
                            <Typography variant="h5" className="u-mrg--bx0">
                                Screening
                            </Typography>
                            <Tooltip label="Delete Section">
                                <IconButton onClick={handleDialog}>
                                    <TrashIcon size={22} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div id="screening_list" className={classes.scrollbars}>
                            {
                                screeningQuestions.map((item, index) => {
                                    const { questionGroup } = item;
                                    const skillName = questionGroup ? questionGroup.title : '';
                                    if (scoreType === 3 && selectedSkill !== 'all' && skillName !== selectedSkill) {
                                        return null;
                                    }
                                    return (
                                        <SidebarBox
                                            key={item.id}
                                            question={item}
                                            questionIndex={index}
                                            audition={audition}
                                            isScreening
                                        />
                                    );
                                })
                            }
                        </div>
                        <CreateBox onClick={createNewQuestion} />
                    </>
                )
            }
        </div>
    );
});

export default withRouter(withStyles(styles)(ScreeningList));

import { createContext } from 'react';
import { action, observable, computed } from 'mobx';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import QuestionList from './QuestionListClass';

class AssessmentEditStore {
    @observable isSelectedQuestionScreening = true;

    @observable activeTab = 0;

    @observable isSavingItem = false;

    @observable noQuestionInSkillBanner = false;

    @observable generalQuestionsList = null;

    @observable screeningQuestionsList = null;

    @observable selectedSkill = 'all';


    constructor() {
        this.generalQuestionsList = new QuestionList();
        this.screeningQuestionsList = new QuestionList('screening');
    }

    @computed get activeQuestionIndex() {
        return this.questionsObj.activeItemIndex;
    }

    @computed get generalQuestions() {
        return this.generalQuestionsList.list;
    }

    @computed get screeningQuestions() {
        return this.screeningQuestionsList.list;
    }

    @computed get questionsObj() {
        return this.isSelectedQuestionScreening ? this.screeningQuestionsList : this.generalQuestionsList;
    }

    @computed get questions() {
        return this.questionsObj.list;
    }

    @computed get savingQuestion() {
        return this.questionsObj.isSavingItem;
    }

    @computed get assessmentHasTypingTest() {
        return this.generalQuestionsList.hasTypingTest;
    }

    @action getQuestionsObjByMode = isScreening => (isScreening ? this.screeningQuestionsList : this.generalQuestionsList);

    @action setSelectedSkill = (value) => {
        this.selectedSkill = value;
    };

    @action loadQuestions = slug => Promise.all([
        this.screeningQuestionsList.loadList(slug),
        this.generalQuestionsList.loadList(slug)
    ]).then((values) => {
        if (!values[0]?.length) {
            this.isSelectedQuestionScreening = false;
        }
    })


    @action addNewQuestion = (scriptSlug, groupTitle, isScreening, onSuccess) => {
        AssessmentSettingsEvents.ADD_QUESTION_CLICKED();
        if (groupTitle) {
            this.setNoQuestionInSkillBanner(false);
        }
        return this.getQuestionsObjByMode(isScreening).addItem(scriptSlug, groupTitle, () => {
            this.isSelectedQuestionScreening = isScreening;
            if (onSuccess) onSuccess();
        }, isScreening);
    };

    @action deleteQuestion = (key, isScreening = this.isSelectedQuestionScreening) => this.getQuestionsObjByMode(isScreening).deleteItem(key, isScreening);

    @action reorderQuestions = (questionId, targetQuestionIndex, questionIndex, newIndex, position, isScreening) => (
        this.getQuestionsObjByMode(isScreening)
            .reorderItems(questionId, targetQuestionIndex, questionIndex, newIndex, position, isScreening)
    );

    @action updateQuestion = (question, isScreening = this.isSelectedQuestionScreening) => {
        this.getQuestionsObjByMode(isScreening).updateItem(question);
    };

    @action setActiveQuestionBySkill = (value) => {
        if (this.isSelectedQuestionScreening) return;
        if (value !== 'all') {
            const firstQuestionIndex = this.questions.findIndex(({ questionGroup }) => {
                const skillName = questionGroup ? questionGroup.title : '';
                return skillName === value;
            });
            if (firstQuestionIndex !== -1) {
                this.setActiveQuestionIndex(firstQuestionIndex + 1);
            } else {
                this.setNoQuestionInSkillBanner(true);
            }
        } else {
            this.setActiveQuestionIndex(1);
        }
    };

    @action setNoQuestionInSkillBanner = (value) => {
        this.noQuestionInSkillBanner = value;
    };

    @action selectQuestion = (key, newActiveTab = this.activeTab, isScreening = this.isSelectedQuestionScreening) => {
        this.isSelectedQuestionScreening = isScreening;
        this.setActiveQuestionIndex(key - 1);
        this.setActiveTab(newActiveTab);
        this.setNoQuestionInSkillBanner(false);
    };

    @action setActiveQuestionIndex = (index) => {
        if (!this.isSelectedQuestionScreening) {
            this.generalQuestionsList.setActiveItemIndex(index);
        } else {
            this.screeningQuestionsList.setActiveItemIndex(index);
        }
    };

    @action setActiveTab = (tab) => {
        this.activeTab = tab;
    };

    @action reset = () => {
        this.activeTab = 0;
        this.screeningQuestionsList.reset();
        this.generalQuestionsList.reset();
        this.noQuestionInSkillBanner = false;
        this.isSelectedQuestionScreening = false;
    };
}

export const assessmentEditStore = new AssessmentEditStore();
export const assessmentEditCtx = createContext(assessmentEditStore);

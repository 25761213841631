import React from 'react';
import { makeStyles } from '@mui/styles';

import AlertIcon from 'components/icons/AlertIcon';


const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: theme.palette.yellow[50],
        borderRadius: 4,
        padding: '6px 20px',
        color: theme.palette.yellow[900],
        fontWeight: 700,
        fontSize: 14,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 22,
        minHeight: 50,
        '& svg': {
            marginRight: 6
        }
    }
}));

const ManualGradingRequiredBanner = () => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <AlertIcon size={25} />
            <div>
                Manual grading required to view full report card
            </div>
        </div>
    );
};

export default ManualGradingRequiredBanner;

import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { changeCandidatePhoneNumber, resendCandidatePhoneNumber } from 'requests/CandidatesRequests';
import { getCandidateProfile } from 'requests/APIrequests';
import { isPhoneValid, checkHasUserPermission } from 'helper/commonFunctions';
import MiscellaneousEvents from 'events/MiscellaneousEvents';


import ChangePhoneDialog from './ChangePhoneDialog';
import ResendSMSDialog from './ResendSMSDialog';
import { appCtx } from '../../appStore';

const CandidateDetailsDialog = observer(({
    open, onClose, candidate, callback,
    flashMessage, candidateUuid, isResend = false,
    audition, resendCallback
}) => {
    const [phoneValue, setPhoneValue] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);
    const [oldPhone, setOldPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { company, decreaseSmsLeftCount } = useContext(appCtx);
    const invite = checkHasUserPermission(company, audition.permissions, 'invite');

    useEffect(() => {
        if (open) {
            setPhoneValue('');
            setPhoneValid(true);
            getCandidateProfile(candidateUuid, false)
                .then((response) => {
                    const { success, data: { phone } } = response;
                    if (success && phone && phone.length > 1) {
                        setPhoneValue(phone);
                        setOldPhone(phone);
                        setPhoneValid(isPhoneValid(phone));
                    }
                });
        }
    }, [open]);

    const dataForEvents = {
        candidateEmail: candidate.email,
        candidateFullName: candidate.fullName,
        candidateUUID: candidateUuid,
        eventDate: moment(new Date()).format()
    };

    const onResendSMS = () => {
        setIsLoading(true);
        resendCandidatePhoneNumber(candidate.columnUserUuid, phoneValue).then((data) => {
            if (data.success) {
                if (callback) {
                    callback();
                }
                if (resendCallback) {
                    resendCallback(data.data.smsSent ? data.data.smsSentAt : null);
                }

                decreaseSmsLeftCount();
                flashMessage('SMS invitation sent');
                MiscellaneousEvents.CANDIDATE_SMS_RESENT({
                    ...dataForEvents,
                    ttName: audition?.name,
                    ttUUID: audition?.uuid
                });
                if (oldPhone !== phoneValue) {
                    MiscellaneousEvents.CANDIDATE_PHONE_CHANGED(dataForEvents);
                }
            }
        }).finally(() => setIsLoading(false));
    };

    const onSaveChanges = () => {
        setIsLoading(true);
        changeCandidatePhoneNumber(candidateUuid, phoneValue).then((data) => {
            if (data.success) {
                if (callback) {
                    callback();
                }
                flashMessage('Candidate phone number updated');
                MiscellaneousEvents.CANDIDATE_PHONE_CHANGED(dataForEvents);
            }
        }).finally(() => setIsLoading(false));
    };

    const handlePhoneChange = (phone) => {
        setPhoneValue(phone);
        setPhoneValid(isPhoneValid(phone));
    };

    if (isResend) {
        return (
            <ResendSMSDialog
                open={open}
                onClose={onClose}
                candidate={candidate}
                onResend={onResendSMS}
                phoneValue={phoneValue}
                phoneValid={phoneValid}
                handlePhoneChange={handlePhoneChange}
                oldPhone={oldPhone}
                company={company}
                isLoading={isLoading}
            />
        );
    }

    return (
        <ChangePhoneDialog
            open={open}
            onClose={onClose}
            candidate={candidate}
            onResend={onResendSMS}
            onSaveChanges={onSaveChanges}
            phoneValue={phoneValue}
            phoneValid={phoneValid}
            handlePhoneChange={handlePhoneChange}
            isLoading={isLoading}
            company={company}
            invite={invite}
        />
    );
});

export default CandidateDetailsDialog;

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Truncate from 'react-truncate';
import { IconButton, TableRow } from '@mui/material';
import { withStyles, withTheme } from '@mui/styles';

import TableCell from 'components/table_components/table_cell';
import EditIcon from 'components/icons/EditIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import DeleteTagDialog from 'components/dialogs/ConfirmDialog';
import RenameTagDialog from 'components/dialogs/RenameTagDialog';
import { appCtx } from 'components/appStore';
import { checkPluralNew } from 'helper/commonFunctions';
import TooltipBlack from 'libraries/Tooltip';
import { deleteAssessmentTag } from 'requests/CMSRequests';
import PlusIcon from 'img/add_grey.svg';

import styles from '../styles';
import { FilterCtx } from '../../../../expert_assessments/filter-store';


const TagRow = observer(({
    classes, tag: { title, id, companyAuditions },
    tag, tags, setTags, theme, totalTagsCount,
    setTotalTagsCount, changePage
}) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { flashMessage } = useContext(appCtx);
    const { removeFilterPropertyFromOutside } = useContext(FilterCtx);

    const deleteTag = () => {
        setLoading(true);
        deleteAssessmentTag(id)
            .then(() => {
                if (tags.length === 1 && totalTagsCount !== 1) {
                    changePage(1);
                } else {
                    setTags(tags.filter(({ id: tagId }) => tagId !== id));
                    setTotalTagsCount(totalTagsCount - 1);
                }
                removeFilterPropertyFromOutside('tag', id);
                flashMessage(`${title} Assessment Tag Deleted`, 'done');
                setDeleteDialogOpen(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return <>
        <TableRow>
            <TableCell className={classes.tableCellName}>
                <div className={classes.title}>
                    <img src={PlusIcon} alt="+" className="u-mrg--rx2" />
                    <Truncate lines={1}>
                        {title}
                    </Truncate>
                </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <div className="u-dsp--f u-ai--center">
                    <TooltipBlack label="Edit Assessment Tag">
                        <IconButton onClick={() => setEditDialogOpen(true)} size="large">
                            <EditIcon color={theme.palette.grey[600]} />
                        </IconButton>
                    </TooltipBlack>
                    <TooltipBlack label="Remove Assessment Tag">
                        <IconButton onClick={() => setDeleteDialogOpen(true)} size="large">
                            <DeleteIcon color={theme.palette.grey[600]} />
                        </IconButton>
                    </TooltipBlack>
                </div>
            </TableCell>
        </TableRow>
        <DeleteTagDialog
            isDelete
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onAction={deleteTag}
            loading={loading}
            title="Are you sure you want to delete this assessment tag?"
            titleClassName={classes.dialogTitle}
        >
            This assessment tag is active in <b>{companyAuditions.length} {checkPluralNew(companyAuditions.length, 'assessment')}</b> and will be removed from every instance.
            {companyAuditions.map(companyAudition => <div className={classes.assessmentName}>{companyAudition?.settings?.name}</div>)}
        </DeleteTagDialog>
        <RenameTagDialog
            tag={tag}
            tags={tags}
            setTags={setTags}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            flashMessage={flashMessage}
        />
    </>;
});

export default withTheme(withStyles(styles)(TagRow));

import React from 'react';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import CardDetails from 'components/subscription/CardDetailsBlock';

import styles from './styles';

const CardEditDialog = ({
    onClose,
    classes,
    updateCompany,
    open
}) => (
    <Dialog
        maxWidth="sm"
        contentClassName="u-pdn--x0 u-mrg--tx0"
        handleClose={onClose}
        open={open}
    >
        <div className={classes.wrapper}>
            <CardDetails
                isCardEdit
                updateCompany={updateCompany}
                onUpdateCreditCard={onClose}
            />
        </div>
    </Dialog>
);

export default withStyles(styles)(CardEditDialog);

import React from 'react';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    numberWrapper: {
        width: 45,
        borderRadius: 20,
        marginRight: 10,
        fontWeight: 700,
        height: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.grey[900]
    }
});

const ScoreNumberWrapper = ({ classes, score }) => (
    <div className={classes.numberWrapper}>
        {score}%
    </div>
);

export default withStyles(styles)(ScoreNumberWrapper);

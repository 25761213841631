import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { appCtx } from 'components/appStore';
import { checkHasUserPermission } from 'helper/commonFunctions';

import ExtensionsRequestsDialog from 'components/dialogs/ExtensionsRequestsDialog';
import GradingOptimisedIcon from './icon.svg';
import { assessmentSettingsCtx } from '../store';

import styles from './styles';


const TimeRequestedItem = observer(({
    classes, requestedMoreTimeCount,
    extensionRequestedCandidates, setExtensionRequestedCandidates,
    loadExtensionsRequests, onExtendRequestSuccess
}) => {
    const [dialogRequestsOpen, setDialogRequestsOpen] = useState(false);
    const { audition } = useContext(assessmentSettingsCtx);
    const { company } = useContext(appCtx);

    if (!audition) return null;

    const { permissions } = audition;
    const edit = checkHasUserPermission(company, permissions, 'edit');

    const handleDialog = () => {
        setDialogRequestsOpen(!dialogRequestsOpen);
    };

    return (
        <div className={clsx('u-dsp--f u-ai--end', classes.wrapper)}>
            <img src={GradingOptimisedIcon} className={classes.img} alt="grading_optimised" />
            <div>
                <div>
                    <span
                        role="presentation"
                        onClick={handleDialog}
                        className={clsx(classes.button, edit && classes.buttonActive)}
                    >
                        Time extension <br />request ({requestedMoreTimeCount})
                    </span>
                    {
                        edit && (
                            <ExtensionsRequestsDialog
                                audition={audition}
                                open={dialogRequestsOpen}
                                handleClose={handleDialog}
                                requestedMoreTimeCount={requestedMoreTimeCount}

                                extensionRequestedCandidates={extensionRequestedCandidates}
                                setExtensionRequestedCandidates={setExtensionRequestedCandidates}
                                loadExtensionsRequests={loadExtensionsRequests}
                                onExtendRequestSuccess={onExtendRequestSuccess}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
});

export default withStyles(styles)(TimeRequestedItem);

import React from 'react';
import { withRouter } from 'react-router-dom';
import Preview from './components/Preview';

const AssessmentPreview = ({ match }) => {
    const { linkUuid } = match.params;

    return (
        <Preview
            {...{
                uuid: linkUuid
            }}
        />
    );
};

export default withRouter(AssessmentPreview);

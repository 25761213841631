import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';

import Dialog from 'libraries/Dialog';
import { appCtx } from 'components/appStore';

import Select from 'libraries/Select';
import ButtonBase from '@mui/material/ButtonBase';
import TextInput from 'libraries/TextInput';
import clsx from 'clsx';
import { createAssessmentRequest } from 'requests/CustomAssessmentRequests';
import FooterComponent from './components/FooterComponent';
import { isValid, skipNotRequiredFields } from './components/infoFormConfig';
import SuccessfulScreen from './components/SuccessfulScreen';

import stylesJs from './styles';
import FormRequestInfo from './components/FormRequestInfo';

const CATEGORIES = [
    {
        label: 'Sales',
        value: 'sales'
    },
    {
        label: 'Customer Service',
        value: 'customer_service'
    },
    {
        label: 'Marketing',
        value: 'marketing'
    },
    {
        label: 'Graduate',
        value: 'graduate'
    },
    {
        label: 'Other',
        value: 'other'
    }
];

const TTRequestDialog = ({ open, flashMessage, history, classes }) => {
    const [loading, setLoading] = useState(false);

    const [customTitle, setCustomTitle] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [requestFormData, setRequestFormData] = useState({});

    const { closeTTRequestDialog, loadRequestsCount } = useContext(appCtx);

    useEffect(() => {
        if (!open) {
            setDefaultValues();
        }
    }, [currentStep, open]);

    const setDefaultValues = () => {
        setLoading(false);
        setCustomTitle('');
        setSelectedCategory('');
        setCurrentStep(1);
        setUploadedFile(null);
    };

    const submitRequest = async () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('category', selectedCategory);
        formData.append('role', customTitle);
        formData.append('file', uploadedFile);
        Object.values(skipNotRequiredFields(requestFormData, selectedCategory)).forEach((item, index) => {
            if (item.options) {
                formData.append(`answers[${index}][title]`, item.title);
                item.options.forEach((option, optionIndex) => {
                    formData.append(`answers[${index}][options][${optionIndex}][title]`, option.title);
                    if (option.text) {
                        formData.append(`answers[${index}][options][${optionIndex}][text]`, option.text.length ? option.text : null);
                    }
                });
            } else {
                formData.append(`answers[${index}][title]`, item.title);
                formData.append(`answers[${index}][text]`, item.text.length ? item.text : null);
                if (item.file) {
                    formData.append(`answers[${index}][file]`, item.file);
                }
            }
        });


        try {
            const { success } = await createAssessmentRequest(formData);
            if (success) {
                setCurrentStep(prev => prev + 1);
                loadRequestsCount();
            }
        } catch (e) {
            let errorLabel = '';
            if (e.response.data && e.response.data.errors) {
                Object.keys(e.response.data.errors).forEach((key) => {
                    const error = e.response.data.errors[key][0];
                    errorLabel += error && error.message ? error.message : '';
                });
            }
            flashMessage(errorLabel || 'Something went wrong', '', 10000);
            setLoading(false);
        }
    };

    const handleClose = () => {
        closeTTRequestDialog();
    };

    const goToMyAssessments = () => {
        closeTTRequestDialog();
        history.push('/my-assessments');
    };

    const handleSelectCategory = (e) => {
        setCustomTitle('');
        setSelectedCategory(e.target.value);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const maxFileSize = 10 * 1024 * 1024;
            if (file.size > maxFileSize) {
                flashMessage('The file should not be empty and its size should be 10MB or less', 'error');
                return;
            }
            setUploadedFile(file);
        }
    };

    const resetUploadedFile = () => {
        setUploadedFile(null);
    };

    const setStep = (newStep) => {
        setCurrentStep(newStep);
    };

    const isDisabledNextButton = (currentStep === 1 && !selectedCategory)
        || (currentStep === 2 && customTitle.trim().length < 1)
        || (currentStep === 3 && !uploadedFile)
        || (currentStep === 4 && !isValid(requestFormData, selectedCategory));

    if (currentStep === 5) {
        return (
            <SuccessfulScreen handleClose={handleClose} open={open} onClick={goToMyAssessments} />
        );
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            titleComponent="Request custom assessment"
            actionComponent={(
                <FooterComponent
                    setStep={setStep}
                    loading={loading}
                    customTitle={customTitle}
                    submitRequest={submitRequest}
                    closeDialog={closeTTRequestDialog}
                    currentStep={currentStep}
                    disabledNext={isDisabledNextButton}
                />
            )}
            contentClassName={classes.root}
            actionClassName={classes.actions}
        >
            <div className={clsx(currentStep !== 4 && classes.content)}>
                {currentStep === 1 && (
                    <div className={classes.contentWrapper}>
                        <div className={classes.title}>Please select the role category you are hiring for:</div>
                        <Select
                            label="Category"
                            options={CATEGORIES}
                            value={selectedCategory}
                            onChange={handleSelectCategory}
                        />
                    </div>
                )}

                {currentStep === 2 && (
                    <div className={classes.contentWrapper}>
                        <div className={classes.title}> What is the title of the role?</div>
                        <TextInput
                            label="Role Title"
                            value={customTitle}
                            onChange={e => setCustomTitle(e.target.value)}
                            maxLength={100}
                            hasCounter
                        />
                    </div>
                )}

                {currentStep === 3 && (
                    <div className={classes.contentWrapper}>
                        <div className={classes.title}>Please upload a copy of your JD</div>
                        <div className="u-dsp--f u-jc--center u-mrg--tx4">
                            <i className="c-img-dropzone__icon icon-upload-cloud" />
                        </div>
                        { uploadedFile ? (
                            <>
                                <div className={classes.helperText}>{uploadedFile.name} uploaded</div>
                                <ButtonBase className={classes.baseButton} onClick={resetUploadedFile}>Delete</ButtonBase>
                            </>
                        ) : (
                            <>
                                <label htmlFor="file-upload" className={classes.baseButton}>
                                    Upload
                                    <input
                                        className={classes.invisible}
                                        type="file"
                                        accept=".pdf,.doc,.docx,application/msword,application/pdf"
                                        onChange={handleFileUpload}
                                        hidden
                                        id="file-upload"
                                    />
                                </label>
                                <div className={classes.helperText}>Please make sure the file is under 10MB in .pdf or .doc format</div>
                            </>
                        ) }

                    </div>
                )}

                {currentStep === 4 && (
                    <div>
                        <FormRequestInfo
                            selectedCategory={selectedCategory}
                            setRequestFormData={setRequestFormData}
                            requestFormData={requestFormData}
                            flashMessage={flashMessage}
                        />
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default withStyles(stylesJs)(withRouter(TTRequestDialog));

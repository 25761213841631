export default theme => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #F3F3F3',
        marginBottom: 20,
        paddingBottom: 7,
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    actionWrapper: {
        display: 'flex',
        alignItems: 'center'
    }
});

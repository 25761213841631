import React from 'react';
import { withStyles } from '@mui/styles';

import ReactDraftWysiwyg from 'components/inputs/ReactDraftWysiwyg';
import IconsBar from './IconsBar';

const styles = {
    inputText: {
        borderRadius: 3,
        fontSize: 16,
        lineHeight: 1.1,
        '& > div': {
            paddingBottom: 40
        }
    }
};

const DescriptionField = ({ question, handleChangeType, value, handleValue, allowedLength, placeholder }) => {
    if (!question) return null;
    const { type, screeningQuestion, richTextEditorDescription, description, answerType } = question;
    return (
        <div className="o-grid u-mrg--bx6 u-mrg--tx3">
            <div className="o-box o-box--whitefull u-mrg--bx1 u-pdn--x0">
                <ReactDraftWysiwyg
                    value={richTextEditorDescription}
                    html={description}
                    onEditorStateChange={handleValue}
                    delay={300}
                    placeholder={placeholder}
                    answerType={answerType}
                />
                <IconsBar
                    questionValue={value}
                    valueType={type}
                    allowedLength={allowedLength}
                    selectQuestionType={handleChangeType}
                    screeningQuestion={screeningQuestion}
                />
            </div>
        </div>
    );
};

export default withStyles(styles)(DescriptionField);

import React, { useContext, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { authorizeInLever } from 'requests/SubscriptionRequests';
import * as qs from 'query-string';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';

const styles = ({
    progressWrapper: {
        width: '100hh',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const LeverAuth = observer(({ classes }) => {
    const { flashMessage } = useContext(appCtx);
    useEffect(() => {
        const parsed = qs.parse(window.location.search);
        const { code, state, error } = parsed;
        authorizeInLever({ code, state, error })
            .then(() => {
                if (window.opener) window.opener.postMessage({ code, state, error }, '*');
                window.close();
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
                if (window.opener) {
                    window.opener.postMessage({
                        code,
                        state,
                        error: 'Authorization failed'
                    }, '*');
                }
                window.close();
            });
    }, []);

    return (
        <div className={classes.progressWrapper}>
            <CircularProgress size={50} />
        </div>
    );
});

export default withStyles(styles)(LeverAuth);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Route } from 'react-router-dom';
import { appCtx } from '../../appStore';

const ProtectedWithAssessmentFlow = observer(({ path, render, ...other }) => {
    const { isLoadingCompany } = useContext(appCtx);
    if (isLoadingCompany) return null;
    return (
        <Route
            path={path}
            {...other}
            render={render}
        />
    );
});

export default ProtectedWithAssessmentFlow;

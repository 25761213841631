import React, { useEffect, useRef } from 'react';
import { useStyles } from './styles';
import Ticket from './ticket';

const Tickets = (props) => {
    const { tickets, activeTicket, setActiveTicketIndex } = props;
    const { wrapper, header, viewport } = useStyles({});

    const activeRef = useRef(null);

    useEffect(() => {
        if (activeRef && activeRef.current) {
            activeRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
        }
    }, [activeTicket]);

    return (
        <div className={wrapper}>
            <div className={header}>
                <span>Ticket Inbox</span>
                <span>Open ({tickets.length})</span>
            </div>
            <div className={viewport}>
                {
                    tickets.map((item, index) => (
                        <Ticket
                            key={item.id}
                            isActive={item.id === activeTicket}
                            onClick={() => setActiveTicketIndex(index)}
                            refs={item.id === activeTicket ? activeRef : null}
                            {...item}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default Tickets;

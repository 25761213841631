export default theme => ({
    root: {
        height: 47,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        color: theme.palette.grey[900],
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        fontWeight: 600,
        transition: 'all 0.3s',
        padding: '6px 25px 6px 13px',
        marginBottom: 28,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 7
        },
        '&:hover': {
            borderColor: theme.palette.primary.main
        },
        '& > img': {
            marginRight: 11
        }
    },
    checkbox: {
        padding: 2,
        marginRight: 10
    },
    option: {
        fontWeight: 600,
        color: theme.palette.grey[900]
    },
    popover: {
    },
    menu: {
        width: 250,
        padding: '10px 0',
        '@media only screen and (min-width: 1600px)': {
            width: 350
        }
    },
    label: {
        position: 'absolute',
        fontSize: 12,
        top: -10,
        padding: '0 5px',
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.background.paper
    },
    chevron: {
        position: 'absolute',
        right: 5
    },
    chevronOpen: {
        transform: 'rotate(180deg)'
    },
    countNumber: {
        position: 'absolute',
        right: 20,
        color: theme.palette.blue[500],
        background: theme.palette.grey[200],
        borderRadius: '70px',
        minWidth: 25,
        textAlign: 'center',
        fontSize: 10,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

import React from 'react';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/TooltipWhite';
import InfoSvg from './info.svg';

const styles = {
    iconWrapper: {
        padding: '0 8px'
    }
};

const QuestionTooltipWhite = ({ id, text, classes, className = '', ...other }) => (
    <>
        <img src={InfoSvg} className={classes.iconWrapper} alt="info" data-tip data-for={id} />
        {
            text && (
                <Tooltip
                    id={id}
                    className={className}
                    {...other}
                >
                    {text}
                </Tooltip>
            )
        }
    </>
);

export default withStyles(styles)(QuestionTooltipWhite);

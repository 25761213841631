import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '0 24px',
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
        minHeight: 88,
        '&:after': {
            content: '""',
            height: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            top: 0
        }
    },
    wrapperWithStepper: {
        position: 'relative',
        paddingBottom: 40,
        [theme.breakpoints.down('md')]: {
            paddingBottom: 0
        }
    },
    headerClass: {
        whiteSpace: 'pre-wrap',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 15
        }
    },
    innerContainer: {
        position: 'relative',
        paddingTop: 12,
        minHeight: 72,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            paddingBottom: 12
        }
    },
    tab: {
        minWidth: 'auto',
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '36px',
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        marginRight: 30,

        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.2px',
        textTransform: 'none'
    },
    tabs: {
        minHeight: 32
    }
}));

export default useStyles;

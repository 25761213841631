export default (theme => ({
    addBtn: {
        fontWeight: 700
    },
    header: {
        fontWeight: 700,
        color: theme.palette.grey[900],
        marginTop: 32
    },
    warning: {
        marginTop: 5,
        marginBottom: 25
    }
}));

export default theme => ({
    button: {
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '21px',
        textAlign: 'center',
        letterSpacing: '0.2px',
        padding: '0 24px',
        boxShadow: 'none',
        minHeight: 40,
        minWidth: '185px'
    },
    buttonDisabled: {
        borderWidth: 2,
        borderRadius: 4,
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        color: `${theme.palette.grey[500]}!important`,
        opacity: '1!important',
        minWidth: '225px'
    },
    link: {
        color: theme.palette.blue[500],
        marginTop: -2
    },
    popoverRoot: {
        marginTop: 8
    },
    popoverItem: {
        fontSize: 12,
        fontWeight: 600,
        color: '#3A3C43',
        margin: '10px 0',
        lineHeight: '24px',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.blue[500],
            fontWeight: 700
        }
    },
    disabledPopoverItem: {
        color: theme.palette.grey[500],
        cursor: 'default',
        '&:hover': {
            color: theme.palette.grey[500],
            fontWeight: 600
        }
    },
    popoverPaper: {
        width: 288,
        padding: '12px 14px'
    }
});

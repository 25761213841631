import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import TestRun from './TestRun';

import styles from './styles';

export default withStyles(styles)(({ answerResults, classes }) => (
    <>
        <div className={clsx(classes.testRunRow, 'u-dsp--f u-jc--sb u-txt--bold u-pdn--bx2 u-txt--12')}>
            <div>Key</div>
            <div>Status</div>
        </div>
        {answerResults.map((answer, idx) => (
            <TestRun
                answer={answer}
                key={idx}
                order={idx + 1}
            />
        ))}
    </>
));

export default theme => ({
    tableCellHead: {
        backgroundColor: 'transparent',
        '&:first-child': {
            paddingLeft: 16
        }
    },
    newExtensionsNumber: {
        display: 'inline-block',
        color: theme.palette.green[600],
        position: 'relative',
        cursor: 'pointer'
    },
    newExtensionsIcon: {
        position: 'absolute',
        right: -18,
        top: 8
    },
    popoverPaper: {
        minWidth: 300,
        padding: '7px 15px',
        borderRadius: 4
    },
    blueLabel: {
        color: theme.palette.primary.main
    },
    name: {
        fontSize: 12,
        fontWeight: 600,
        maxWidth: 230,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    dialogTitle: {
        paddingTop: 10,
        paddingBottom: 12
    }
});

import React from 'react';

// material components
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

// local files and components
import clsx from 'clsx';
import Loader from 'components/loaders/Loader';
import DropZone from '../../../DropZone';
import styles from '../styles';

const ImageUploadCase = ({
    onDrop, onImgLoad, isUploadingFile,
    questionFile, classes
}) => {
    if (isUploadingFile) {
        return (
            <div className={clsx(classes.dropZoneBox, 'o-box o-box--whitefull c-img-upload-box')}>
                <Loader
                    variant="indeterminate"
                    show
                    thickness={3}
                />
            </div>
        );
    }

    if (!questionFile && !onDrop) return null;

    return (
        questionFile
            ? (
                <>
                    <div className="c-createbox is-created u-txt--left u-pdn--x0">
                        <div>
                            <img
                                role="presentation"
                                onLoad={onImgLoad}
                                src={process.env.REACT_APP_API_URL + questionFile.fileUrl}
                                alt={questionFile.name}
                            />
                            {
                                onDrop && (
                                    <DropZone
                                        onDrop={onDrop}
                                        minSize={1}
                                        disabled={!onDrop}
                                        maxSize={10485760}
                                        accept="image/jpeg, image/jpg, image/png, image/gif, image/svg, image/svg+xml"
                                        label={(
                                            <Button
                                                classes={{ root: classes.button }}
                                            >
                                                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.6666 1.27127V4.90763H11.0303" stroke="#1247F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M1.3335 10.9682V7.33188H4.96986" stroke="#1247F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M2.85471 4.30159C3.16208 3.43297 3.68449 2.65638 4.37317 2.04426C5.06186 1.43215 5.89438 1.00447 6.79306 0.801137C7.69174 0.597799 8.62729 0.625429 9.5124 0.881449C10.3975 1.13747 11.2033 1.61353 11.8547 2.26522L14.6668 4.90765M1.3335 7.33189L4.14562 9.97431C4.79698 10.626 5.60281 11.1021 6.48793 11.3581C7.37304 11.6141 8.30858 11.6417 9.20726 11.4384C10.1059 11.2351 10.9385 10.8074 11.6272 10.1953C12.3158 9.58316 12.8382 8.80656 13.1456 7.93795" stroke="#1247F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <span>Replace</span>
                                            </Button>
                                        )}
                                    />
                                )
                            }
                        </div>
                    </div>
                </>
            ) : (
                <DropZone
                    className={clsx(classes.dropZoneBox, 'o-box o-box--whitefull u-mrg--bx1 c-img-upload-box')}
                    onDrop={onDrop}
                    minSize={1}
                    disabled={!onDrop}
                    maxSize={10485760}
                    accept="image/jpeg, image/jpg, image/png, image/gif, image/svg, image/svg+xml"
                    label={(
                        <div className="u-txt--center">
                            <svg
                                height="32"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M5.4 22H18.6C19.3732 22 20 21.3732 20 20.6V6.12789C20 5.74564 19.8437 5.38 19.5674 5.11587L16.7136 2.38798C16.4531 2.13897 16.1066 2 15.7462 2H5.4C4.6268 2 4 2.6268 4 3.4V20.6C4 21.3732 4.6268 22 5.4 22Z" stroke="#676A78" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.5 9C10.3284 9 11 8.32843 11 7.5C11 6.67157 10.3284 6 9.5 6C8.67157 6 8 6.67157 8 7.5C8 8.32843 8.67157 9 9.5 9Z" stroke="#676A78" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                <path className="noStroke" d="M15.045 10.0712L14.604 9.66428L15.045 10.0712ZM20.4576 12.5757L17.4882 9.6063L16.6396 10.4548L19.6091 13.4243L20.4576 12.5757ZM14.604 9.66428L4.05903 21.0931L4.94097 21.9069L15.486 10.478L14.604 9.66428ZM17.4882 9.6063C16.6848 8.80296 15.3745 8.8293 14.604 9.66428L15.486 10.478C15.7942 10.144 16.3183 10.1335 16.6396 10.4548L17.4882 9.6063Z" fill="#676A78" />
                            </svg>
                            <p className={classes.mainText}>Choose a .jpg, .jpeg, .png, .gif, .svg to upload</p>
                            <p className={classes.secondaryText}>(max file size 10 MB)</p>
                        </div>
                    )}
                />
            )
    );
};

export default withStyles(styles, { withTheme: true })(ImageUploadCase);

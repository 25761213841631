import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';

import {
    getAuditionsForProgress, setAutoProgressSettings,
    deleteAutoProgress, getAutoProgressSettings
} from 'requests/ScriptRequests';
import TextInput from 'libraries/TextInput';
import { appCtx } from 'components/appStore';
import Select from 'libraries/Select';

import AddIcon from 'img/add.svg';
import ArrowIcon from 'img/icons/up-right-arrow.svg';
import Tooltip from 'libraries/Tooltip';
import EditPanelSection from 'components/assessments_pages/asessment_settings_components/EditPanelSection';
import PreviewInvitationEmailButton from 'pages/assessment/candidate_invite/EmailInvitation/PreviewInvitationEmailButton';

import { checkHasCompanyFeature } from 'helper/commonFunctions';
import AssessmentEvents from 'events/AssessmentEvents';
import RemoveAutoProgressDialog from './RemoveAutoProgressDialog';
import AssessmentLinkSvg from './AssessmentLinkSvg';
import styles from '../styles';

let timedIdForPercentage = null;

const AutoProgressSettings = observer(({ classes, audition, setSaving, history }) => {
    const { flashMessage, company } = useContext(appCtx);
    const { name, slug, pureAutoGrade, active, uuid } = audition;

    const [toggleChecked, setToggleChecked] = useState(false);
    const [percentage, setPercentage] = useState(75);
    const [linkedAssessment, setLinkedAssessment] = useState(null);
    const [assessmentsForProgressList, setAssessmentsForProgressList] = useState([]);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    const hasAutoProgress = checkHasCompanyFeature(company, 'AUTO_PROGRESS');

    const hide = !hasAutoProgress || !active || !pureAutoGrade;

    useEffect(() => {
        if (hide) return;
        loadAutoProgressSettings();
        setAssessmentsForProgress();
    }, [slug]);

    const loadAutoProgressSettings = () => {
        getAutoProgressSettings(slug)
            .then(({ data, success }) => {
                if (data && success) {
                    const { enabled, percentage: newPercentage, link } = data;
                    setLinkedAssessment(link || null);
                    setToggleChecked(enabled);
                    setPercentage(newPercentage === null ? 75 : newPercentage);
                }
            })
            .catch(() => {});
    };

    const setAssessmentsForProgress = () => {
        getAuditionsForProgress(slug)
            .then(({ data, success }) => {
                if (data && success) {
                    setAssessmentsForProgressList(data);
                }
            })
            .catch(() => {
                flashMessage('Something went wrong.');
            });
    };

    const setNewAutoProgress = (newAutoProgress, newPercentage, linkedSlug) => {
        setSaving(true);
        return setAutoProgressSettings(slug, linkedSlug, {
            enabled: newAutoProgress,
            percentage: newPercentage
        })
            .then(({ data, success }) => {
                if (data && success) {
                    setPercentage(newPercentage);
                    if (data.length) {
                        const selected = data.find(item => item.slug === linkedSlug);
                        if (selected) {
                            setLinkedAssessment(selected);

                            AssessmentEvents.AUTOPROGRESS_LINK_ADDED({
                                ttUUID: uuid,
                                ttName: name,
                                linkedTTUUID: selected.uuid,
                                linkedTTName: selected.name
                            });
                        }
                    }
                }
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
                flashMessage('Something went wrong.');
            });
    };

    const removeAutoProgress = () => {
        setSaving(true);
        return deleteAutoProgress(slug)
            .then(() => {
                setSaving(false);
                setToggleChecked(false);
                setPercentage(75);
                setLinkedAssessment(null);
                flashMessage('Auto progress removed.', 'done');

                AssessmentEvents.AUTOPROGRESS_LINK_REMOVED({
                    ttUUID: uuid,
                    ttName: name
                });
            }).catch(() => {
                setSaving(false);
                flashMessage('Something went wrong.');
            });
    };

    const saveNewAutoProgressPercentage = (newPercentage) => {
        setNewAutoProgress(true, newPercentage, linkedAssessment.slug)
            .then(() => {
                flashMessage(`Candidates with score above ${newPercentage}% will be Automatically Progressed.`, 'done');
            });
    };

    const handleChangeSwitch = (e) => {
        const { checked } = e.target;
        setSaving(true);

        const eventData = {
            enabled: false,
            ttUUID: uuid,
            ttName: name
        };

        if (checked && !linkedAssessment) {
            setToggleChecked(true);
            eventData.enabled = true;
            setSaving(false);
        }
        if (!checked && !linkedAssessment) {
            setToggleChecked(false);
            setSaving(false);
        }
        if (checked && linkedAssessment) {
            setNewAutoProgress(true, percentage, linkedAssessment.slug)
                .then(() => {
                    setToggleChecked(true);
                    eventData.enabled = true;
                    flashMessage('Auto progress has been enabled.', 'done');
                });
        }
        if (!checked && linkedAssessment) {
            setNewAutoProgress(false, percentage, linkedAssessment.slug).then(() => {
                setToggleChecked(false);
                flashMessage('Auto progress has been disabled.', 'done');
            });
        }

        AssessmentEvents.AUTOPROGRESS_ENABLED(eventData);
    };

    const handleChangeProgressScore = (e) => {
        const { value } = e.target;
        let newValue = parseInt(value, 10);
        if (Number.isNaN(newValue) || newValue <= 0) {
            newValue = 0;
        } else if (newValue > 100) {
            newValue = 100;
        }
        setPercentage(newValue);
        clearTimeout(timedIdForPercentage);
        timedIdForPercentage = setTimeout(() => {
            saveNewAutoProgressPercentage(newValue);
        }, 1000);
    };

    const handleClickRemoveAP = () => {
        setRemoveDialogOpen(true);
    };

    const handleChangeLinkedAssessment = (e) => {
        const { value } = e.target;
        if (toggleChecked) {
            const selected = assessmentsForProgressList.find(item => item.slug === value);
            if (selected) {
                setLinkedAssessment(selected);
                AssessmentEvents.AUTOPROGRESS_LINK_ADDED({
                    ttUUID: uuid,
                    ttName: name,
                    linkedTTUUID: selected.uuid,
                    linkedTTName: selected.name
                });
            }
            if (!linkedAssessment) {
                setNewAutoProgress(true, percentage, value);
                flashMessage('Auto progress has been enabled.', 'done');
            }
        }
    };

    if (hide) return null;

    return (
        <EditPanelSection
            header={(
                <>
                    Auto Progress
                    <Switch
                        className={classes.switch}
                        checked={toggleChecked}
                        onChange={handleChangeSwitch}
                    />
                </>
            )}
        >
            {linkedAssessment
                ? (
                    <p>
                        Assessment Workflows allow your candidates to be automatically progressed to a second
                        assessment if they meet the set conditions. All linked assessments must be active.
                    </p>
                )
                : (
                    <p>
                        Automatically progress candidates to the next assessment if this conditions are met.
                        All linked assessments must be active and enabled for autograde.
                        <a
                            className="c-link--brand"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vervoe.zendesk.com/hc/en-us/articles/360029638831-Creating-an-Auto-Graded-Skills-Assessment"
                        > Read more about autograding.
                        </a>
                    </p>
                )
            }
            <div className={classes.condition}>
                <span className={clsx(classes.conditionLabel, toggleChecked && classes.activatedLabel)}>{name}</span>
                <AssessmentLinkSvg isActive={toggleChecked && linkedAssessment} />
                {linkedAssessment
                    ? <span className={clsx(classes.conditionLabel, toggleChecked && classes.activatedLabel)}>{linkedAssessment.name}</span>
                    : (
                        <Select
                            value={!linkedAssessment ? null : linkedAssessment.slug}
                            className={classes.select}
                            variant="outlined"
                            label="Select Opened Assessment"
                            disabled={!toggleChecked || !assessmentsForProgressList.length}
                            onChange={(label, value) => handleChangeLinkedAssessment(label, value)}
                            options={assessmentsForProgressList.map(({ name: label, slug: value }) => ({
                                value,
                                label
                            }))}
                        />
                    )}
                {
                    linkedAssessment
                    && <>
                        <Tooltip
                            label="Go to linked assessment"
                        >
                            <IconButton
                                type="button"
                                onClick={() => { history.push(`/script/edit/${linkedAssessment.slug}`); }}
                                className={clsx(classes.arrowIcon, classes.icon)}
                                size="large"
                            >
                                <img src={ArrowIcon} alt="->" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            label="Remove auto progress"
                        >
                            <IconButton
                                type="button"
                                onClick={handleClickRemoveAP}
                                className={clsx(classes.closeIcon, classes.icon)}
                                size="large"
                            >
                                <img src={AddIcon} alt="x" />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            </div>
            { linkedAssessment
                && <>
                    <Typography variant="h6" className={classes.text}>
                        Condition
                    </Typography>
                    <div className={classes.condition}>
                        <span className={classes.conditionLabel}>IF</span>
                        <span>candidate score is</span>
                        <span className={clsx(classes.conditionLabel, classes.greenLabel)}>Greater Than</span>
                        <TextInput
                            disabled={!toggleChecked || !linkedAssessment}
                            type="number"
                            min="0"
                            max="100"
                            label="Enter score"
                            variant="outlined"
                            value={percentage}
                            className={classes.percentageInput}
                            onChange={(e) => {
                                handleChangeProgressScore(e);
                            }}
                        />
                        <span className={classes.conditionLabel}>Then</span>
                        <span>Automatically Progress</span>
                    </div>
                    <div className={classes.footerBlock}>
                        <PreviewInvitationEmailButton
                            text="Preview and edit invitation email for linked assessment"
                            slug={linkedAssessment.slug}
                            className="u-mrg--rx2"
                            audition={linkedAssessment}
                        />
                    </div>
                </>
            }
            <RemoveAutoProgressDialog
                open={removeDialogOpen}
                handleClose={() => { setRemoveDialogOpen(false); }}
                removeAutoProgress={removeAutoProgress}
            />
        </EditPanelSection>
    );
});

export default withStyles(styles)(withRouter(AutoProgressSettings));

export default theme => ({
    loaderWrapper: {
        textAlign: 'center',
        marginTop: 35
    },
    allCandidatesTab: {
        width: 240,
        paddingLeft: 0,
        paddingRight: 0
    },
    shortlistTab: {
        width: 180,
        paddingLeft: 0,
        paddingRight: 0
    },
    pageHeader: {
        color: theme.palette.grey[600],
        fontSize: 14,
        fontWeight: 600
    },
    contentWrapper: {
        padding: 0,
        marginBottom: 0
    },
    innerTabs: {
        width: '100%',
        margin: 0
    },
    content: {
        overflow: 'auto',
        minHeight: 250
    },
    allLabel: {
        color: theme.palette.grey[500]
    },
    closeIcon: {
        padding: 4,
        marginLeft: 10,
        '& > img': {
            width: 16
        }
    }
});

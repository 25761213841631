import React from 'react';
import { NavLink } from 'react-router-dom';
import PlanEvents from 'events/PlanEvents';

const SubscriptionsLink = ({ label, className, activeClassName, segmentLocation }) => {
    if (!label) return null;

    const handleClick = () => {
        PlanEvents.UPGRADE_BUTTON_CLICKED(segmentLocation, true);
    };

    return (
        <NavLink
            component={NavLink}
            target="_blank"
            to="/subscriptions"
            className={className}
            activeClassName={activeClassName}
            onClick={handleClick}
        >
            {label}
        </NavLink>
    );
};

export default SubscriptionsLink;

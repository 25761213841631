import React from 'react';
import { withRouter } from 'react-router-dom';
import PageWrapper from 'components/layout/PageWrapper';
import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import ScoreDistribution from './ScoreDistribution';
import QuestionByQuestion from './QuestionByQuestion';
import CompletionsData from './CompletionsData';
import InsightsStats from './InsightsStats';


const AssessmentInsights = ({ audition }) => (
    <PageWrapper>
        <ContentWrapper className="u-pdn--x0">
            <InsightsStats audition={audition} />
            <CompletionsData audition={audition} />
            <ScoreDistribution audition={audition} />
            <QuestionByQuestion audition={audition} />
        </ContentWrapper>
    </PageWrapper>
);

export default withRouter(AssessmentInsights);

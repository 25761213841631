export default theme => ({
    btnEdit: {
        background: 'white',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#3a3c43',
        textTransform: 'none',
        width: 250,
        height: 40,
        '&:hover': {
            background: 'rgba(255,255,255, 0.4)'
        }
    },
    stylesWrapper: {
        cursor: 'pointer',
        borderRadius: 4,
        marginTop: 20,
        overflow: 'hidden',
        position: 'relative',
        height: 400,
        background: '#282b34'
    },
    headerStyles: {
        background: 'rgb(40, 43, 52)',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 12,
        paddingRight: 16,
        height: 44
    },
    labelStyles: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 116,
        height: 23,
        borderRadius: 12,
        background: '#1a1b1e',
        color: 'white',
        padding: '0 8px 0px 16px',
        fontSize: 10,
        fontWeight: 'bold'
    },
    stylesWarning: {
        ontSize: 16,
        color: theme.palette.red.primary,
        fontWeight: 'bold',
        margin: 0
    },
    footerStyles: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        left: 0,
        height: 80,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        zIndex: 10,
        paddingLeft: 38,
        paddingRight: 16,
        background: 'linear-gradient(transparent, rgb(40, 43, 52), rgb(40, 43, 52))'
    },
    footerError: {
        justifyContent: 'space-between'
    },
    stylesCasesLength: {
        display: 'flex',
        color: 'white',
        fontSize: '16px',
        marginTop: '0px',
        fontWeight: 'bold'
    }
});

import React from 'react';
import { withTheme } from '@mui/styles';

const LIIcon = ({ theme, active, size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect width="32" height="32" rx="4" fill={active ? theme.palette.primary.main : theme.palette.grey[400]} />
        <g clipPath="url(#clip0_4011_30908)">
            <path d="M8.73186 12.2148H12.0147V22.7764H8.73186V12.2148ZM10.3748 6.96466C11.4245 6.96466 12.2775 7.81765 12.2775 8.86736C12.2775 9.91861 11.4245 10.7716 10.3748 10.7716C9.32203 10.7716 8.47058 9.91861 8.47058 8.86736C8.47058 7.81765 9.32203 6.96466 10.3748 6.96466Z" fill="white" />
            <path d="M14.0741 12.2148H17.2232V13.6579H17.2663C17.7043 12.828 18.7755 11.952 20.3724 11.952C23.6967 11.952 24.3099 14.139 24.3099 16.9838V22.778H21.0286V17.6401C21.0286 16.4152 21.0056 14.8398 19.3227 14.8398C17.6151 14.8398 17.3523 16.1739 17.3523 17.5525V22.778H14.0741V12.2148Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_4011_30908">
                <rect width="15.8394" height="15.8118" fill="white" transform="translate(8.47058 6.96466)" />
            </clipPath>
        </defs>
    </svg>
);

export default withTheme(LIIcon);

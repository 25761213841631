import React from 'react';
import AutocompleteInput from 'libraries/AutocompleteInput';
import { withStyles } from '@mui/styles';
import TextInput from 'libraries/TextInput';
import styles from '../../styles';

const PreselectedFlow = ({ currentStep, classes, customTitle, setCustomTitle, setSelectedRoleName, selectedRoleName, roles }) => {
    const onChangeRole = (value) => {
        setSelectedRoleName(value);
        setCustomTitle(value);
    };

    return (
        <>
            {currentStep === 2 && (
                <div className={classes.contentWrapper}>
                    <div className={classes.title}>Select the title of your role here</div>
                    <div className={classes.subtitle}>You can update the name of the role later</div>
                    <AutocompleteInput options={Object.keys(roles)} label="Role Name" onChange={onChangeRole} value={selectedRoleName} />
                </div>
            )}
            {currentStep === 3 && (
                <div className={classes.contentWrapper}>
                    <div className={classes.title}>These are the most tested skills for <br /> a <span className={classes.roleHighlight}>{selectedRoleName}</span> have.</div>
                    <div className={classes.subtitle}>we recommend testing for the following <b>top skills</b> for this role</div>
                    <div className={classes.skillsContainer}>
                        {roles[selectedRoleName]?.map((role, idx) => (
                            <span className={classes.skill} key={idx}>{role}</span>
                        ))}
                    </div>
                </div>
            )}
            {currentStep === 4 && (
                <div className={classes.contentWrapper}>
                    <div className={classes.title}>What do you want to call this assessment?</div>
                    <div className={classes.subtitle}>We’ll use this name to save your assessment. You can change it at anytime.</div>
                    <TextInput
                        label="Assessment Name"
                        value={customTitle}
                        onChange={e => setCustomTitle(e.target.value)}
                        min={2}
                        max={80}
                    />
                </div>
            )}
        </>
    );
};

export default withStyles(styles)(PreselectedFlow);

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/TooltipWhite';
import Button from '@mui/material/Button';

import styles from '../footerStyles';

let fooRef;

const FooterTTBuilder = ({
    classes, customTitle,
    submitDescription, addedSkills, loading, resetStep
}) => {
    const completed = customTitle && addedSkills.length >= 3;

    if (completed) {
        ReactTooltip.show(fooRef);
    } else {
        ReactTooltip.hide(fooRef);
    }


    return (
        <div className={classes.wrapper}>
            <div className={classes.actionWrapper}>
                <Button
                    color="primary"
                    variant="text"
                    className={classes.nextButton}
                    onClick={resetStep}
                    disabled={loading}
                >
                    {'Back'}
                    {
                        loading && (
                            <CircularProgress
                                className={classes.loader}
                                size={25}
                                thickness={3}
                            />
                        )
                    }
                </Button>
                <Tooltip
                    id="complete_all_steps"
                    position="top"
                    delayHide={100}
                    className={classes.tooltip}
                >
                    {
                        completed ? (
                        <>
                            <div className={classes.tooltipHeader}>All done?</div>
                            Once you’ve added your skills create your assessment and start adding questions.
                        </>
                        ) : (
                        <>
                            <div className={classes.tooltipHeader}>Add more details to continue</div>
                            Please include the role name and three skills you would like to test to continue.

                            <a
                                href="https://vervoe.zendesk.com/hc/en-us/articles/360047393052-Step-1-Creating-questions-for-your-Skills-Assessment#h_01EFQDZXA6J12WXTVJRVYTWA3H"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.tooltipLink}
                            >
                                Find out how it works →
                            </a>
                        </>
                        )
                    }
                </Tooltip>
                <div
                    data-tip
                    data-for="complete_all_steps"
                    role="presentation"
                    ref={(ref) => { fooRef = ref; }}
                    className={classes.nextButtonWrapper}
                >

                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.nextButton}
                        onClick={submitDescription}
                        disabled={!completed || loading}
                    >
                        Create Assessment
                        {
                            loading && (
                                <CircularProgress
                                    className={classes.loader}
                                    size={25}
                                    thickness={3}
                                />
                            )
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(FooterTTBuilder);

import React from 'react';
import { withTheme } from '@mui/styles';

const EditIcon = ({ theme, color = theme.palette.grey[500] }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0512 4.89746H4.78915C4.31464 4.89746 3.85956 5.08596 3.52403 5.42149C3.1885 5.75702 3 6.2121 3 6.68661V19.2106C3 19.6851 3.1885 20.1402 3.52403 20.4758C3.85956 20.8113 4.31464 20.9998 4.78915 20.9998H17.3132C17.7877 20.9998 18.2428 20.8113 18.5783 20.4758C18.9138 20.1402 19.1023 19.6851 19.1023 19.2106V12.9486" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.7605 3.55582C18.1163 3.19993 18.599 3 19.1023 3C19.6056 3 20.0883 3.19993 20.4442 3.55582C20.8001 3.9117 21 4.39438 21 4.89768C21 5.40097 20.8001 5.88365 20.4442 6.23954L11.9457 14.738L8.36743 15.6326L9.26201 12.0543L17.7605 3.55582Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(EditIcon);

import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from './icon.svg';

const EmptyQuestionsList = ({
    onClick, hasSet
}) => (
    <div className="u-txt--center u-mrg--tx4">
        <img src={Icon} className="u-mrg--bx2" alt="+" />
        {
            hasSet ? (
                <>
                    <Typography className="u-mrg--bx1" variant="h4">Add a question to set</Typography>
                    <p>You haven’t added any questions to this set yet.
                        {onClick && <Button color="primary" onClick={onClick}>Add your questions now</Button> }
                    </p>
                </>
            ) : (
                <>
                    <Typography className="u-mrg--bx1" variant="h4">
                        You haven’t added any questions yet
                    </Typography>
                </>
            )
        }
    </div>
);

export default EmptyQuestionsList;

import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';

import { appCtx } from 'components/appStore';
import Typography from '@mui/material/Typography';
import PurchaseDialog from './PurchaseDialog';
import ConfirmCancelPlanDialog from './ConfirmCancelPlanDialog';
import PlanFooterMessage from './PlanFooterMessage';

import styles from './styles';
import { subscriptionDialogStoreCtx } from '../store';
import PlanSelectList from './PlanSelectList';


const PlanInfoBlock = observer(({ classes }) => {
    const {
        selectedPlan, dialogPaymentDetailsOpen, handleConfirmCancelPlan,
        subscriptionPlan, cards, isCurrentPlanOutdated, dialogConfirmCancelPlanOpen,
        getCompanyPlan, handleClosePaymentDetails, handleCloseConfirmCancelPlan,
        isLoadingConfirmCancelPlan
    } = useContext(subscriptionDialogStoreCtx);
    const { company } = useContext(appCtx);


    if (!selectedPlan) return;

    const showOutdatedBlock = isCurrentPlanOutdated && company.plan.level !== 1;

    return (
        <div className="u-ovf--a">
            {showOutdatedBlock && (
                <div className={classes.oldPlan}>
                    <Typography variant="h4" color="#fff" className="u-txt--normal u-txt--20">Current Plan</Typography>
                    <div className={classes.companyOldPlanName}>{company.plan.name}*</div>
                    <div className={classes.description}>
                        *This plan has been discontinued, to upgrade/change select any one of the plans below.
                    </div>
                </div>
            )}
            <div className={classes.wrapper}>
                <div className={classes.addInfoWrapper}>
                    <PlanSelectList company={company} isOutdated={showOutdatedBlock} />
                </div>
                <PlanFooterMessage />
            </div>
            <PurchaseDialog
                cards={cards}
                open={dialogPaymentDetailsOpen}
                getCompanyPlan={getCompanyPlan}
                subscriptionPlan={subscriptionPlan}
                onClose={handleClosePaymentDetails}
            />
            <ConfirmCancelPlanDialog
                open={dialogConfirmCancelPlanOpen}
                handleConfirmCancelPlan={handleConfirmCancelPlan}
                onClose={handleCloseConfirmCancelPlan}
                isLoadingConfirmCancelPlan={isLoadingConfirmCancelPlan}
            />
        </div>
    );
});

export default withStyles(styles)(PlanInfoBlock);

export default theme => ({
    wrapper: {
        position: 'absolute',
        top: -3,
        left: -3,
        width: 'calc(100% + 6px)',
        height: 'calc(100% + 6px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[200],
        border: `2px solid ${theme.palette.grey[200]}`
    },
    wrapperChecked: {
        borderColor: theme.palette.primary.main
    },
    gradeLoading: {
        animation: 'circle 2s linear infinite',
    }
});

import React from 'react';

import LanguageList from './LangugaeList';
import CodeEditorQuestion from '../../../CodeEditorQuestion';

const CodeEditorAutograded = ({ data }) => {
    if (!data) return null;
    const languageList = data.reduce((list, { language }) => [...list, language], []);
    const firstLang = { ...data[0] };

    const value = Object.keys(firstLang).reduce((val, key) => {
        if (key === 'body' || key === 'header' || key === 'footer') {
            val += `${firstLang[key]}
        `;
        }
        return val;
    }, '');

    return (
        <div>
            <LanguageList list={languageList} />
            <CodeEditorQuestion data={value} />
        </div>
    );
};

export default CodeEditorAutograded;

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';

import LinkIcon from 'img/link.svg';
import { copyAssessmentPreviewLink, copyAssessmentPreviewLinkCallback } from 'helper/assessmentFunctions';

const styles = {
    shareLink: {
        padding: '7px 24px 8px 24px',
        lineHeight: '21px',
        whiteSpace: 'nowrap',
        minWidth: 38,
        marginRight: 15,
        '& img': {
            width: 20,
            height: 20,
            marginRight: 10
        },
        '&:disabled img': {
            opacity: 0.4
        }
    }
};


const ShareButton = observer(({ classes, disabled, audition }) => {
    const [shareLink, setShareLink] = useState('');

    useEffect(() => {
        if (!shareLink) return;
        copyAssessmentPreviewLinkCallback({ ...audition, text: shareLink });
    }, [shareLink]);

    const copyPreview = () => {
        if (shareLink) {
            copyAssessmentPreviewLinkCallback({ ...audition, text: shareLink });
            return;
        }

        copyAssessmentPreviewLink(audition)
            .then((link) => {
                if (!link) return;
                setShareLink(link);
            });
    };

    return (
        <Button
            variant="outlined"
            className={classes.shareLink}
            disabled={disabled}
            onClick={copyPreview}
        >
            <img src={LinkIcon} alt="" />
            Copy Preview Link
        </Button>
    );
});

export default withRouter(withStyles(styles)(ShareButton));

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Switch from 'libraries/SwitchBrandWithLabel';
import TextInput from 'libraries/TextInput';
import { getOracleSettings, updateOracleSettings } from 'requests/SettingsRequests';
import SettingsEvents from 'events/SettingsEvents';

const styles = () => ({
    heading: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '28px',
        marginBottom: 20
    },
    input: {
        maxWidth: 500,
        marginBottom: 10
    }
});


const OracleContent = ({ classes, flashMessage }) => {
    const [activated, setActivated] = useState(false);
    const [partnerId, setPartnerId] = useState('');
    const [baseUrl, setBaseUrl] = useState('');
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [referenceKey, setReferenceKey] = useState('');

    const updateIntegrationData = (dataFromServer) => {
        const { enabled, partnerId, baseUrl, clientId, clientSecret, referenceKey } = dataFromServer;
        setActivated(enabled);
        setPartnerId(partnerId);
        setBaseUrl(baseUrl);
        setClientId(clientId);
        setClientSecret(clientSecret);
        setReferenceKey(referenceKey);
    };

    useEffect(() => {
        getOracleSettings()
            .then(({ success, data }) => {
                if (data && success) {
                    updateIntegrationData(data);
                }
            });
    }, []);

    const handleChangeSwitch = () => {
        if (partnerId === '') {
            flashMessage('Please paste partner Id', 'error');
        } else if (baseUrl === '') {
            flashMessage('Please paste base URL', 'error');
        } else {
            updateOracleSettings({ enabled: !activated, partnerId, baseUrl })
                .then(({ success, data }) => {
                    if (success && data) {
                        SettingsEvents.ORACLE_ENABLED({ enabled });
                        const { enabled } = data;
                        updateIntegrationData(data);
                        flashMessage(`Integration has been turned ${enabled ? 'on' : 'off'}`, 'done');
                    }
                })
                .catch(() => { flashMessage('Something went wrong', 'error'); });
        }
    };

    return (
        <div>
            <h3 className={classes.heading}>Connect Vervoe to Oracle</h3>
            <TextInput
                label="Partner Id"
                fullWidth
                className={classes.input}
                type="text"
                variant="outlined"
                value={partnerId}
                onChange={(e) => { setPartnerId(e.target.value); }}
                disabled={activated}
            />
            <br />
            <p className="u-mrg--bx5">Please paste partner Id in order to allow the integration</p>
            <TextInput
                label="Base URL"
                fullWidth
                className={classes.input}
                type="text"
                variant="outlined"
                value={baseUrl}
                onChange={(e) => { setBaseUrl(e.target.value); }}
                disabled={activated}
            />
            <br />
            <p className="u-mrg--bx5">Please paste partner Id in order to allow the integration</p>
            <Switch
                label="Enable Oracle Integration"
                checked={activated}
                value={activated}
                onChange={handleChangeSwitch}
            />
            <br />
            { activated && (
                <>
                    <TextInput
                        label="Client Id"
                        fullWidth
                        className={classes.input}
                        type="text"
                        variant="outlined"
                        value={clientId}
                        disabled
                    />
                    <br />
                    <TextInput
                        label="Client Secret"
                        fullWidth
                        className={classes.input}
                        type="text"
                        variant="outlined"
                        value={clientSecret}
                        disabled
                    />
                    <br />
                    <TextInput
                        label="reference Key"
                        fullWidth
                        className={classes.input}
                        type="text"
                        variant="outlined"
                        value={referenceKey}
                        disabled
                    />
                </>
            )}
        </div>
    );
};

export default withStyles(styles)(OracleContent);

export default theme => ({
    wrapper: {
        marginBottom: 30,
        padding: '20px 25px',
        border: `2px solid ${theme.palette.grey[200]}`,
        borderRadius: 4,
        overflow: 'auto'
    },
    innerWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        minWidth: 450
    },
    container: {
        marginRight: 30,
        paddingRight: 20,
        minHeight: 230,
        minWidth: 180,
        borderRight: `2px solid ${theme.palette.grey[200]}`
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    label: {
        marginBottom: 4,
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.grey[900]
    },
    scoreLabel: {
        fontSize: 28,
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    rankLabel: {
        fontSize: 14,
        fontWeight: 700,
        letterSpacing: 0.1,
        whiteSpace: 'nowrap',
        color: theme.palette.grey[900],
        '& > span': {
            marginLeft: 15
        }
    },
    scoreWrapper: {
        width: 'calc(100% - 210px)'
    },
    demoScoreLabel: {
        color: '#fff',
        backgroundColor: theme.palette.yellow[900],
        padding: '4px 10px',
        borderRadius: 4,
        textAlign: 'center',
        fontSize: 12,
        marginRight: 5,
        fontWeight: 700,
        marginLeft: 7,
        whiteSpace: 'nowrap'
    }
});

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    itemHeader: {
        marginBottom: 0,
        textTransform: 'uppercase',
        color: theme.palette.grey[500],
        fontWeight: 700,
        fontSize: 12
    },
    itemValue: {
        fontWeight: 700
    },
    itemValueShrinked: {
        maxWidth: 160,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    countLabel: {
        marginLeft: 8,
        fontWeight: 600,
        fontSize: 13,
        color: theme.palette.grey[500]
    }
}));

import React from 'react';
import EditPanelSection from '../EditPanelSection';
import SettingsFieldChange from '../SettingsFieldChange';
import SlugSettings from '../SlugSettings';
import BrandingDetails from '../BrandingDetails';

const DetailSettings = ({ ...props }) => (
    <EditPanelSection>
        <SettingsFieldChange
            maxLength={100}
            label="Assessment Name"
            field="name"
            className="u-mrg--bx3 u-mrg--tx0"
            {...props}
        />
        <SettingsFieldChange
            maxLength={1000}
            hasCounter
            field="description"
            label="Assessment Description"
            multiline
            hintLabel="This Assessment description is not visible to your candidates"
            {...props}
        />
        <SlugSettings {...props} />
        <BrandingDetails {...props} />
    </EditPanelSection>
);

export default DetailSettings;

import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import { getScreeningSavedQuestions, replaceScreeningQuestionWithBankQuestion } from 'requests/QuestionsRequests';
import { deleteCompanyQuestion } from 'requests/CMSRequests';
import CommonContent from './CommonContent';
import styles from './CommonContent/styles';


const PAGE_STEP = 10;
let timedId = null;

const GroupContent = observer(({
    question, questionsList,
    setQuestionsList, query, setQuery,
    loadingQuestions, setLoadingQuestions,
    loading, setLoading,
    loadingAddButton, setLoadingAddButton
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        setLoadingQuestions(true);
        clearTimeout(timedId);
        timedId = setTimeout(() => { loadQuestions(); }, 400);
    }, [currentPage]);


    const loadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const loadQuestions = (term = query) => {
        setLoadingQuestions(true);
        getScreeningSavedQuestions({ query: term, page: currentPage, offset: PAGE_STEP })
            .then(({ success, data }) => {
                if (success && data) {
                    const { hasMorePages, items } = data;
                    setHasMore(hasMorePages);
                    setQuestionsList(currentPage === 1 ? items : questionsList.concat(items));
                }
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false);
                setLoadingQuestions(false);
            });
    };

    let bannerText = '';
    let bannerHeader = '';

    if (query) {
        bannerHeader = `We couldn't find a match for "${query}"`;
        bannerText = 'Check your spelling or try a new search';
    } else {
        bannerHeader = 'You haven’t added any questions to Screening';
        bannerText = 'Save any question from an assessment';
    }

    return (
        <CommonContent
            question={question}
            questionsList={questionsList}
            setQuestionsList={setQuestionsList}
            query={query}
            isScreening
            setQuery={setQuery}
            loadingQuestions={loadingQuestions}
            setLoadingQuestions={setLoadingQuestions}
            loading={loading}
            loadingAddButton={loadingAddButton}
            setLoadingAddButton={setLoadingAddButton}
            onSearch={loadQuestions}
            replaceQuestion={replaceScreeningQuestionWithBankQuestion}
            removeQuestion={deleteCompanyQuestion}
            emptyBannerHeader={bannerHeader}
            emptyBannerText={bannerText}
            LoadMoreComp={
                <>
                    {
                        Boolean(hasMore && questionsList.length) && (
                            <div className="u-txt--center u-mrg--tx1 u-mrg--bx2">
                                <Button
                                    color="primary"
                                    onClick={loadMore}
                                    disabled={loadingQuestions}
                                    variant="contained"
                                >
                                    Load more questions
                                </Button>
                            </div>
                        )
                    }
                </>
            }
        />
    );
});

export default withStyles(styles)(GroupContent);

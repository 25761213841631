import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import styles from './styles';

const PanelHeader = ({ classes, children, className = '' }) => (
    <Typography variant="h5" className={clsx(classes.header, className)}>
        {children}
    </Typography>
);

export default withStyles(styles)(PanelHeader);

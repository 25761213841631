import { tracking } from 'helper/eventSegment';

export default {
    SEARCH_CONDUCTED: (data) => {
        tracking('L-search-conducted', data);
    },
    TT_DUPLICATED: (data) => {
        tracking('L-tt-duplicated', data);
    },
    TT_SHARED: (data) => {
        tracking('L-tt-shared', data);
    },
    TT_USED: (data) => {
        tracking('L-tt-used', data);
    },
    TT_CUSTOMIZED: (data) => {
        tracking('L-tt-customized', data);
    },
    PRIVATE_TT_DELETED: (data) => {
        tracking('L-private-tt-deleted', data);
    },
    PRIVATE_TT_ACTIVATED: (data) => {
        tracking('L-private-tt-activated', data);
    },
    PRIVATE_TT_DEACTIVATED: (data) => {
        tracking('L-private-tt-deactivated', data);
    },
    PRIVATE_TT_CUSTOMIZED: (data) => {
        tracking('L-private-tt-customized', data);
    },
    PREEMPTIVE_SEARCH_CONDUCTED: (data) => {
        tracking('L-preemptive-search-conducted', data);
    }
};

import React from 'react';
import { checkPluralNew } from 'helper/commonFunctions';

class ScoreRangeBarChart {
    constructor(value) {
        this.value = value;
        this.YAxisWidth = 40;
        this.minPointSize = 0;
    }

    set chartValues(newValue) {
        this.value = newValue;
    }

    get chartValues() {
        return this.value;
    }

    get getYAxisWidth() {
        return this.YAxisWidth;
    }

    showTooltipData(tooltipData, tooltipLabel, classes) {
        if (typeof tooltipData.payload[0] !== 'undefined') {
            const { payload: { count, range, percentage } } = tooltipData.payload[0];
            return (
                <div className={classes.chartTooltipWrapper}>
                    <div className={classes.tootlipRange}>{range}</div>
                    <div className="u-txt--bold">
                        <span className={classes.chartTooltipValue}>
                            {count} {checkPluralNew(+count, tooltipLabel)}
                        </span>
                        {
                            !!percentage && (
                                <span className={classes.chartTooltipPercentage}>{percentage}%</span>
                            )
                        }
                    </div>
                </div>
            );
        }
        return null;
    }
}

export default ScoreRangeBarChart;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import InnerHeader from 'components/layout/InnerHeader';
import styles from './styles';
import HeaderStepper from './HeaderStepper';
import SettingsStepper from './SettingsStepper';


const AssessmentHeaderWithStepper = observer(({ audition = {}, location, header, rightPart }) => {
    const returnStepper = () => {
        if (location.pathname.includes('script/settings/')) return SettingsStepper;
        return HeaderStepper;
    };

    return (
        <InnerHeader
            header={header}
            rightPart={<div className="u-dsp--f u-ai--center u-jc--end">{rightPart}</div>}
            StepperComp={returnStepper()}
            stepperProps={{ audition }}
        />
    );
});

export default withRouter(withStyles(styles)(AssessmentHeaderWithStepper));

import React, { useEffect, useState } from 'react';

import { withStyles } from '@mui/styles';

import EditQuestionWarning from 'components/marketplace/EditWarning';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import QuestionTooltip from 'components/tooltips/QuestionTooltipWhite';

const styles = {
    tooltip: {
        width: 350,
        fontSize: 12
    }
};

const IncludeInGradingSwitch = ({ classes, changeQuestionSettings, question }) => {
    const [willBeGraded, setWillBeGraded] = useState(false);
    const { willBeGraded: willBeGradedQuestion, id, answerType } = question;

    useEffect(() => {
        setWillBeGraded(willBeGradedQuestion);
    }, [id, answerType]);

    const handleChangeWillBeGradedQuestion = () => {
        const newValue = !willBeGraded;
        setWillBeGraded(newValue);
        changeQuestionSettings({ willBeGraded: newValue });
    };

    return (
        <>
            <SwitchBrandWithLabel
                label={(
                    <>
                        Do not include question in grading
                        <QuestionTooltip
                            id="include_in_grading_witch"
                            className={classes.tooltip}
                            text='Marking a question as "Do not grade" means that it will be excluded from the AI results. These questions cannot be manually graded. '
                        />
                    </>
                )}
                checked={Boolean(!willBeGraded)}
                className="u-mrg--bx0"
                onChange={handleChangeWillBeGradedQuestion}
            />
            <EditQuestionWarning
                className="u-mrg--bx3"
                message="Instructional multiple choice or text and introductory videos are examples of questions that don’t need grading."
            />
        </>
    );
}

export default withStyles(styles)(IncludeInGradingSwitch);

import React from 'react';


const UsersIcon = ({ color = '#6B6B70', ...other }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <ellipse cx="8.92308" cy="7.07692" rx="3.07692" ry="3.07692" stroke={color} strokeWidth="1.4" />
        <path d="M15.0769 10.1538C16.7763 10.1538 18.1539 8.77626 18.1539 7.07692C18.1539 5.37759 16.7763 4 15.0769 4" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.8462 19.3846V19.3846C15.8462 16.4108 13.4354 14 10.4615 14H7.38462C4.41077 14 2 16.4108 2 19.3846V19.3846" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22 19.3846V18.6154C22 16.0664 19.9336 14 17.3846 14V14" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default UsersIcon;

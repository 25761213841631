import React from 'react';
import { withStyles } from '@mui/styles';

import clsx from 'clsx';
import CheckIcon from './check.svg';

const styles = theme => ({
    savingLabel: {
        position: 'absolute',
        right: 0,
        top: -30,
        color: theme.palette.grey[500],
        fontSize: 14,
        fontWeight: 600
    },
    savingLabelActive: {
        color: theme.palette.primary.main
    }
});

const SavingLabel = ({ isSaving, classes }) => (
    <span className={clsx(classes.savingLabel, isSaving && classes.savingLabelActive)}>
        {isSaving ? 'Saving...' : <><img src={CheckIcon} alt="!" /> &nbsp;Saved</>}
    </span>
);


export default withStyles(styles)(SavingLabel);

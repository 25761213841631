import React from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { secondsToTimeObject, timeObjectToFormattedString } from 'helper/commonFunctions';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { AI_HEALTH_STATUS_HIGH, AI_HEALTH_STATUS_LOW, AI_HEALTH_STATUS_MEDIUM, AI_HEALTH_STATUS_OPTIMIZED, AI_LIGHT_GRADING_REQUIRED, MANUAL_GRADED, MANUAL_GRADING_REQUIRED } from 'helper/constants';
import NoResponsesIcon from '../../../QuestionsList/Questions/Item/assets/no-responses.svg';
import CompletedIcon from '../../../QuestionsList/Questions/Item/assets/completed.svg';

const useStyles = makeStyles(theme => ({
    list: {
        display: 'flex',
        padding: '10px 30px 10px 23px',
        overflow: 'auto',
        justifyContent: 'flex-start'
    },
    listItem: {
        width: 'fit-content',
        padding: '8px 30px 8px 0',
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        whiteSpace: 'nowrap'
    },
    name: {
        fontSize: 12,
        textTransform: 'uppercase',
        color: theme.palette.grey[500],
        marginBottom: 6
    },
    suggested: {
        color: ({ suggestedAnswerAdded }) => (suggestedAnswerAdded ? theme.palette.green[600] : theme.palette.yellow[900]),
        fontSize: 14,
        display: 'flex',
        alignItems: 'center'
    },
    tooltipIcon: {
        margin: '0 0 -3px 0'
    },
    seeScoresBtn: {
        color: theme.palette.blue[700],
        textDecoration: 'underline',
        fontSize: 12,
        fontWeight: 400,
        padding: 2,
        marginLeft: 4
    },
    anchor: {
        color: '#ffffff',
        '&:hover': {
            color: theme.palette.blue[400]
        }
    }
}));

const QuestionStatistic = ({ question, scoresSectionOpen, setScoresSectionOpen }) => {
    const { gradedCount, needsToBeGraded: readyToGradeCount, responsesCount, avgTeamScore,
        suggestedAnswerAdded, question: { questionGroup }, questionResult, status } = question;

    const classes = useStyles({ suggestedAnswerAdded });
    const AiHealthStatuses = [AI_HEALTH_STATUS_LOW, AI_HEALTH_STATUS_MEDIUM, AI_HEALTH_STATUS_HIGH, AI_HEALTH_STATUS_OPTIMIZED];

    const statsItems = [
        {
            name: 'Responses',
            value: new Intl.NumberFormat('en-US').format(responsesCount),
            statisticType: AiHealthStatuses
        }, {
            name: <>
                Grading required
                <InfoTooltip
                    isBlack
                    classNameIcon={classes.tooltipIcon}
                    classNameWrapper="u-mrg--lx1"
                    iconColor="#A9AAAE"
                    text="We've hidden candidate details to help give you a bias-free score. To see individual candidate responses, view the individual candidate scores."
                />
            </>,
            value: `${gradedCount}/${readyToGradeCount}`,
            statisticType: [...AiHealthStatuses, MANUAL_GRADED, MANUAL_GRADING_REQUIRED, AI_LIGHT_GRADING_REQUIRED]
        }, {
            name: <>
                Avg Team Score
                <InfoTooltip
                    isBlack
                    classNameIcon={classes.tooltipIcon}
                    classNameWrapper="u-mrg--lx1"
                    iconColor="#A9AAAE"
                    text={(
                        <a
                            className={classes.anchor}
                            href="https://help.vervoe.com/hc/en-us/articles/360046252091-Step-3-AI-Health-Optimize-and-train-the-AI"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            About flagging scores
                        </a>
                    )}
                />
            </>,
            value: <>
                {avgTeamScore ?? '-'}
                {avgTeamScore !== null && !scoresSectionOpen && (
                    <ButtonBase
                        disabled={scoresSectionOpen}
                        onClick={() => setScoresSectionOpen(true)}
                        className={classes.seeScoresBtn}
                    >
                        See Scores
                    </ButtonBase>
                )}
            </>,
            statisticType: [...AiHealthStatuses, MANUAL_GRADED, MANUAL_GRADING_REQUIRED, AI_LIGHT_GRADING_REQUIRED]
        }, {
            name: 'Answered In',
            value: questionResult?.timeSpent ? timeObjectToFormattedString(secondsToTimeObject(questionResult.timeSpent)) : '-',
            statisticType: [...AiHealthStatuses, MANUAL_GRADED, MANUAL_GRADING_REQUIRED]
        }, {
            name: 'Skill Group',
            value: <TruncateWithTooltip capitalize width={180} text={questionGroup?.title || '-'} />,
            statisticType: []
        }, {
            name: 'Suggested Answers',
            value: <>
                <span className={classes.suggested}>
                    <img src={suggestedAnswerAdded ? CompletedIcon : NoResponsesIcon} className="u-mrg--rx1" alt="!" />
                    {suggestedAnswerAdded ? 'Added' : 'Add suggested answers'}
                </span>
            </>,
            statisticType: []
        }];

    const filteredStatsItems = statsItems.filter((item) => {
        const { statisticType } = item;
        if (statisticType.length) {
            return item.statisticType.includes(status);
        }
        return false;
    });

    return (
        <List className={classes.list}>
            {filteredStatsItems.map(({ name, value }, index) => value && (
                <ListItem
                    key={index}
                    className={classes.listItem}
                >
                    <div className={classes.name}>{name}</div>
                    <div>{value}</div>
                </ListItem>
            ))}
        </List>
    );
};

export default QuestionStatistic;

export default theme => ({
    timerWrapper: {
        marginTop: 20
    },
    timeLimitWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 12,
        marginBotton: 20,
        marginRight: 18,
        '& > svg': {
            marginRight: 7
        }
    },
    timeLimitError: {
        color: theme.palette.red.primary
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.2,
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: 10
    },
    box: {
        position: 'relative',
        padding: '16px 20px 16px 16px',
        borderRadius: 8,
        backgroundColor: '#ffffff',
        fontSize: '11px',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.45',
        letterSpacing: 'normal',
        color: theme.palette.grey[900],
        fontWeight: 600,
        cursor: 'pointer',
        border: `2px solid ${theme.palette.grey[300]}`,
        marginBottom: 16,
        '&:hover': {
            backgroundColor: theme.palette.grey[50]
        },
        '&:hover $gradient': {
            background: 'linear-gradient(90deg, rgba(251, 251, 253, 0) 0%, rgba(251, 251, 253, 1) 50%)'
        },
        [theme.breakpoints.down('md')]: {
            width: 300
        }
    },
    gradient: {},
    boxActive: {
        borderColor: theme.palette.primary.main,
        '&$boxError': {
            borderColor: theme.palette.yellow[900]
        }
    },
    boxError: {},
    description: {
        minHeight: 10,
        maxWidth: '100%',
        maxHeight: 45,
        fontSize: 12,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    descriptionTemplate: {
        color: theme.palette.grey[500]
    },
    error: {
        color: theme.palette.red.primary
    },
    svgIconStyle: {
        marginRight: '8px',
        width: 24,
        height: 24,
        marginBottom: 0
    },
    animation: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
    }
});

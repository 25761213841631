import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import CandidatesCount from 'components/table_components/candidates_count';
import BoardNoContent from 'components/board/board_no_content';
import { CANDIDATES_PER_RESULTS_PAGE } from 'helper/constants';

import CandidatesTable from './CandidatesTable';
import SearchFilterBlock from '../../components/SearchFilterBlock';
import BatchBlock from '../../components/BatchBlock';
import NotFoundContent from '../../../../components/assessments_pages/NotFoundContent';
import EmptyIcon from '../common/NoInvitationsContent/icon.svg';
import { archivedCtx } from './archivedStore';
import { statsCtx } from '../../statsStore';

const useStyles = makeStyles(() => ({
    board: {
        minHeight: 330,
        paddingTop: 0,
        paddingBottom: 70
    },
    title: {
        margin: '0 12px 0 0'
    }
}));

const ArchivedList = observer(({ match }) => {
    const {
        totalCount, allCandidates,
        selectedCandidates, query,
        page, isLoading,
        setSlug, cleanState,
        clearSelection, selectedUserAuditionsIds,
        selectedCandidatesIds, selectedColumnUserIds,
        loadCandidates
    } = useContext(archivedCtx);
    const { loadAssessmentStats } = useContext(statsCtx);
    const classes = useStyles();

    const { scriptSlug } = match.params;


    useEffect(() => {
        setSlug(scriptSlug);
    }, [scriptSlug]);

    useEffect(() => {
        loadCandidates();
        return () => cleanState();
    }, []);

    const returnNoContentBoard = () => {
        if (totalCount || isLoading) return null;
        if (query) return <NotFoundContent searchValue={query} />;
        return (
            <BoardNoContent
                header="No Archived Candidates"
                hideIconWrapper
                className={classes.board}
                icon={<img src={EmptyIcon} alt="empty-content" />}
            />
        );
    };

    return (
        <div className="u-mrg--tx4">
            {
                Object.keys(selectedCandidates).length ? (
                    <BatchBlock
                        {...{
                            clearSelection,
                            selectedCandidates,
                            selectedUserAuditionsIds,
                            selectedCandidatesIds,
                            selectedColumnUserIds,
                            loadCandidates,
                            onSuccess: loadAssessmentStats,
                            isArchiveSection: true
                        }}
                    />
                ) : (
                    <SearchFilterBlock
                        context={archivedCtx}
                        hideExportButton
                        hideTableSort
                    />
                )
            }
            <div className="u-dsp--f u-ai--start u-jc--sb">
                <div className="u-dsp--f u-mrg--bx2 u-ai--start">
                    <div className="u-dsp--f u-ai--baseline">
                        <Typography variant="h5" className={classes.title}>
                            Candidates
                        </Typography>
                        {
                            !isLoading && (
                                <CandidatesCount
                                    className="u-mrg--bx0"
                                    candidatesPerPage={CANDIDATES_PER_RESULTS_PAGE}
                                    page={page}
                                    candidatesLength={totalCount}
                                    candidatesFound={allCandidates.length}
                                    label="Archived Candidates"
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <CandidatesTable />
            { returnNoContentBoard() }
        </div>
    );
});

export default ArchivedList;

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import ExpandIcon from './expandIcon.svg';
import styles from './styles';

const AnswerOptionImage = ({ answer: { file }, classes }) => {
    const [imgDialogOpen, setImgDialogOpen] = useState(false);
    if (!file) return null;
    const { fileUrl, fileName } = file;
    const { imgDialog, img, dialogPaper, expandButton } = classes;
    const imgPath = `${process.env.REACT_APP_API_URL}${fileUrl}`;

    const toggleDialogOpen = () => {
        setImgDialogOpen(!imgDialogOpen);
    };

    return (
        <>
            <img
                role="presentation"
                src={imgPath}
                alt={fileName}
                onLoad={() => {}}
                className={img}
            />
            <Button
                className={expandButton}
                onClick={toggleDialogOpen}
            >
                <img src={ExpandIcon} alt="<- ->"/>
            </Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={imgDialogOpen}
                onClose={toggleDialogOpen}
                classes={{ paper: dialogPaper }}
            >
                <div onClick={toggleDialogOpen}>
                    <img className={imgDialog} src={imgPath} alt={fileName} />
                </div>
            </Dialog>
        </>
    );
};

export default withStyles(styles)(AnswerOptionImage);

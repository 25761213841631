const formConfig = {
    sales: [
        {
            id: 'salesMulti',
            title: 'What is the key focus of sales in this role?',
            subtitle: 'Please choose all that apply.',
            options: [
                {
                    label: 'New client acquisition (key skills: Prospecting, Communication, Presentation Skills)',
                    withTextField: false
                },
                {
                    label: 'Account management (key skills: Relationship Management, Communication - Verbal and Written, New Business Development)',
                    withTextField: false
                },
                {
                    label: 'Account expansion (key skills: New Business Development, Sales Strategy, Analytical)',
                    withTextField: false
                },
                {
                    label: 'Telesales (key skills: Communication etc…)',
                    withTextField: false
                },
                {
                    label: 'Hunter',
                    withTextField: true,
                    placeholder: 'Key skills'
                },
                {
                    label: 'Gatherer',
                    withTextField: true,
                    placeholder: 'Key skills'
                }
            ],
            required: true
        },
        {
            id: 'salesChallengeField',
            title: 'What are the main challenges employees face and are there any common reasons people leave this role?',
            placeholder: 'What is the hardest part of the role or takes the longest to learn and is there any information on attrition?',
            required: true
        },
        {
            id: 'salesOtherField',
            title: 'Anything else that we need to know to create this assessment?',
            placeholder: 'Eg any specific branding, employer videos, or product-specific information you’d like to test candidates on etc you would like to include',
            required: false
        }
    ],
    customer_service: [
        {
            id: 'csMulti',
            title: 'What is the key focus of customer service in this role?',
            subtitle: 'Please choose all that apply.',
            options: [
                {
                    label: 'Account Management',
                    withTextField: false
                },
                {
                    label: 'Inbound Call Centre',
                    withTextField: false
                },
                {
                    label: 'Outbound Call Centre',
                    withTextField: false
                },
                {
                    label: 'Chat Support',
                    withTextField: false
                },
                {
                    label: 'Email Support',
                    withTextField: false
                },
                {
                    label: 'Customer Success',
                    withTextField: false
                },
                {
                    label: 'Technical Customer Support',
                    withTextField: false
                }
            ],
            required: true
        },
        {
            id: 'csMulti2',
            title: 'Choose the top 4-5 skills required to successfully perform in this role that need to be tested in this assessment:',
            subtitle: 'Please choose all that apply (maximum of 5 can be selected).',
            max: 5,
            options: [
                {
                    label: 'Customer Service',
                    withTextField: false
                },
                {
                    label: 'Resilience',
                    withTextField: false
                },
                {
                    label: 'Empathy',
                    withTextField: false
                },
                {
                    label: 'Relationship Management',
                    withTextField: false
                },
                {
                    label: 'Communication - Verbal and Written',
                    withTextField: false
                },
                {
                    label: 'Problem Solving',
                    withTextField: false
                },
                {
                    label: 'Critical Thinking',
                    withTextField: false
                },
                {
                    label: 'Learning Ability',
                    withTextField: false
                },
                {
                    label: 'Computer Literacy/Digital Skills',
                    withTextField: false
                },
                {
                    label: 'Multitasking',
                    withTextField: false
                },
                {
                    label: 'Team Leadership',
                    withTextField: false
                },
                {
                    label: 'Strategy',
                    withTextField: false
                },
                {
                    label: 'Conflict Resolution',
                    withTextField: false
                },
                {
                    label: 'Attention to Detail',
                    withTextField: false
                },
                {
                    label: 'Adaptability',
                    withTextField: false
                },
                {
                    label: 'Listening Skills',
                    withTextField: false
                },
                {
                    label: 'Sales',
                    withTextField: false
                },
                {
                    label: 'Other',
                    withTextField: true,
                    placeholder: 'Please specify',
                    required: true
                }
            ],
            required: true
        },
        {
            id: 'csChallengeField',
            title: 'What are the main challenges employees face and are there any common reasons people leave this role?',
            placeholder: 'What is the hardest part of the role or takes the longest to learn and is there any information on attrition?',
            required: true
        },
        {
            id: 'csOtherField',
            title: 'Anything else that we need to know to create this assessment?',
            placeholder: 'Eg any specific branding, employer videos, or product-specific information you’d like to test candidates on etc you would like to include',
            required: false
        }
    ],
    marketing: [
        {
            id: 'marketingMulti',
            title: 'What is the key focus of Marketing in this role?',
            subtitle: 'Please choose all that apply.',
            options: [
                {
                    label: 'SEO',
                    withTextField: false
                },
                {
                    label: 'Digital Marketing',
                    withTextField: false
                },
                {
                    label: 'Content',
                    withTextField: false
                },
                {
                    label: 'Advertising',
                    withTextField: false
                },
                {
                    label: 'B2B',
                    withTextField: false
                },
                {
                    label: 'B2C',
                    withTextField: false
                },
                {
                    label: 'PPC',
                    withTextField: false
                },
                {
                    label: 'Social Media',
                    withTextField: false
                },
                {
                    label: 'PR',
                    withTextField: false
                }
            ],
            required: true
        },
        {
            id: 'marketingMulti2',
            title: 'Choose the top 4-5 skills required to successfully perform in this role that need to be tested in this assessment:',
            subtitle: 'Please choose all that apply (maximum of 5 can be selected).',
            max: 5,
            options: [
                {
                    label: 'Relationship Management',
                    withTextField: false
                },
                {
                    label: 'Communication - Verbal and Written',
                    withTextField: false
                },
                {
                    label: 'Problem Solving',
                    withTextField: false
                },
                {
                    label: 'Critical Thinking',
                    withTextField: false
                },
                {
                    label: 'Computer Literacy/Digital Skills',
                    withTextField: false
                },
                {
                    label: 'Multitasking',
                    withTextField: false
                },
                {
                    label: 'Team Leadership',
                    withTextField: false
                },
                {
                    label: 'Marketing Strategy',
                    withTextField: false
                },
                {
                    label: 'Attention to Detail',
                    withTextField: false
                },
                {
                    label: 'Adaptability',
                    withTextField: false
                },
                {
                    label: 'SEO',
                    withTextField: false
                },
                {
                    label: 'PPC',
                    withTextField: false
                },
                {
                    label: 'Data Analysis',
                    withTextField: false
                },
                {
                    label: 'Other',
                    withTextField: true,
                    placeholder: 'Please specify',
                    required: true
                }
            ],
            required: true
        },
        {
            id: 'marketingChallengeField',
            title: 'What are the main challenges employees face in this role? Be as descriptive as possible.',
            placeholder: 'What is the core focus of the role? Please provide examples of the types of projects they would be tasked with or the challenges the businesses is facing that the role will be required to help solve',
            required: true
        },
        {
            id: 'marketingReasonField',
            title: 'Are there any common reasons people leave this role?',
            placeholder: 'What is the hardest part of the role or takes the longest to learn and is there any information on attrition?',
            required: true
        },
        {
            id: 'marketingOtherField',
            title: 'Anything else that we need to know to create this assessment?',
            placeholder: 'Eg any specific branding, employer videos, or product-specific information you’d like to test candidates on etc you would like to include',
            required: false
        }
    ],
    graduate: [
        {
            id: 'graduateIndustryField',
            title: 'What industry will the Graduate be working in?',
            placeholder: 'Please outline the key focus of the graduate program. What projects will graduates be working on, what will their day consist of, who will they liaise with, what are the key deliverables of the program?',
            required: true
        },
        {
            id: 'graduateMulti',
            title: 'Choose the top 4-5 skills required to successfully perform in this role that need to be tested in this assessment:',
            subtitle: 'Please choose all that apply (maximum of 5 can be selected).',
            max: 5,
            options: [
                {
                    label: 'Attention to Detail',
                    withTextField: false
                },
                {
                    label: 'Resilience',
                    withTextField: false
                },
                {
                    label: 'Learning Ability',
                    withTextField: false
                },
                {
                    label: 'Relationship Management',
                    withTextField: false
                },
                {
                    label: 'Negotiation',
                    withTextField: false
                },
                {
                    label: 'Communication - Verbal and Written',
                    withTextField: false
                },
                {
                    label: 'Analytical',
                    withTextField: false
                },
                {
                    label: 'Presentation Skills',
                    withTextField: false
                },
                {
                    label: 'Problem Solving',
                    withTextField: false
                },
                {
                    label: 'Time Management',
                    withTextField: false
                },
                {
                    label: 'Adaptable',
                    withTextField: false
                },
                {
                    label: 'Teamwork / Collaboration',
                    withTextField: false
                },
                {
                    label: 'Digital Literacy - Basic Computer Skills',
                    withTextField: false
                },
                {
                    label: 'Business Acumen',
                    withTextField: false
                },
                {
                    label: 'Critical Thinking',
                    withTextField: false
                },
                {
                    label: 'Analytical Skills',
                    withTextField: false
                },
                {
                    label: 'Data Analysis',
                    withTextField: false
                },
                {
                    label: 'Technical',
                    withTextField: false
                },
                {
                    label: 'Initiative',
                    withTextField: false
                },
                {
                    label: 'Organization',
                    withTextField: false
                },
                {
                    label: 'Conflict Resolution',
                    withTextField: false
                },
                {
                    label: 'Other',
                    withTextField: true,
                    placeholder: 'Please specify',
                    required: true
                }
            ],
            required: true
        },
        {
            id: 'graduateChallengeField',
            title: 'What are the main challenges Graduates face in this program? Be as descriptive as possible.',
            placeholder: 'What is the hardest part of the role or takes the longest to learn and is there any information on attrition?',
            required: true
        },
        {
            id: 'graduateReasonField',
            title: 'Are there any common reasons Graduates aren\'t successful?',
            placeholder: 'Describe the key projects that graduates will be working on during the program. What level of involvement will they have?',
            required: true
        },
        {
            id: 'graduateSpecificField',
            title: 'Are there any specific tasks or situations you would like to see candidates complete in this assessment that would help to understand whether they are a good fit?',
            placeholder: 'Provide specific tasks or responsibilities graduates undertake in the program.',
            required: true
        },
        {
            id: 'graduateAdditionalField',
            title: 'Provide any additional company information; such as industry, projects, or anything else that will help with contextualising the assessment to your organisation.',
            placeholder: 'Provide any additional information on the graduate program that will help with contextualising the assessment to your organisation. e.g. do graduates perform rotations in different teams.',
            required: true
        },
        {
            id: 'graduateOtherField',
            title: 'Anything else that we need to know to create this assessment?',
            placeholder: 'Eg any specific branding, employer videos, or product-specific information you’d like to test candidates on etc you would like to include',
            required: false
        }
    ],
    other: [
        {
            id: 'otherField',
            title: 'Is there anything else important you would like to share about this role or the challenges employees may face in it?',
            placeholder: 'Do you wish to include any additional information about the role that isn’t in your job description? You can also provide employer branding or value proposition materials in any of the supported format types below.',
            required: false
        }
    ]
};

export const getConfig = category => formConfig[category];

export const getInitialData = (category) => {
    const result = {};
    const config = formConfig[category];

    config.forEach((field) => {
        if (field.options) {
            result[field.id] = {
                title: field.title,
                options: []
            };
        } else {
            result[field.id] = {
                title: field.title,
                text: '',
                file: null
            };
        }
    });

    return result;
};

const isValidOptionText = (configOptions, dataOptions) => {
    const requiredOptions = new Set(configOptions.filter(option => option.required).map(option => option.label));
    return dataOptions.every(dataOption => !requiredOptions.has(dataOption.title) || (dataOption.text && dataOption.text.trim()));
};

export const isValid = (formData, category) => {
    const config = formConfig[category];

    return config.every((field) => {
        if (!(field.id in formData)) {
            return !field.required;
        }

        const formDataField = formData[field.id];

        if (field.required) {
            if (!field.options) {
                return formDataField.text.trim() || formDataField.file !== null;
            }
            return formDataField.options.length > 0 && isValidOptionText(field.options, formDataField.options);
        }

        return true;
    });
};

export const skipNotRequiredFields = (formData, category) => {
    const config = formConfig[category];
    const requiredFields = new Set();
    const filteredFormData = { ...formData };

    config.forEach((field) => {
        if (field.required) {
            requiredFields.add(field.id);
        }
    });

    Object.keys(filteredFormData).forEach((key) => {
        // If the field is not required and its text is empty or undefined, or if there is no file attached -> delete it from the formData
        if (!requiredFields.has(key) && !filteredFormData[key].file && (!filteredFormData[key].text || filteredFormData[key].text.trim() === '')) {
            delete filteredFormData[key];
        }
    });

    return filteredFormData;
};

export default formConfig;

import { tracking } from 'helper/eventSegment';

export default {
    NOTIFICATION_CHANGED: (data) => {
        tracking('TT-notifications-changed', data);
    },
    INVITE_CANDIDATE_VIEWED: (data) => {
        tracking('TT-invite-candidate-viewed', data);
    },
    LINK_CREATED: (data) => {
        tracking('TT-link-created', data);
    },
    LINK_COPIED: (data) => {
        tracking('TT-link-copied', data);
    },
    LINK_REMOVED: (data) => {
        tracking('TT-link-removed', data);
    },
    POST_SOCIAL: (data) => {
        tracking('TT-post-social', data);
    },
    TEST_TO_MYSELF: (data) => {
        tracking('TT-test-myself-send', data);
    },
    CANDIDATES_BATCH_ACTIONED: (data) => {
        tracking('TT-candidates-batch-actioned', data);
    },
    CANDIDATES_FILTERED: (data) => {
        tracking('TT-candidates-filtered', data);
    },
    HIRE_SURVEY: (data) => {
        tracking('TT-hire-survey', data);
    },
    HIRE_SURVEY_SKIPPED: (data) => {
        tracking('TT-hire-survey-skipped', data);
    },
    CANDIDATE_SHORTLISTED: (data) => {
        tracking('TT-candidate-shortlisted', data);
    },
    CANDIDATE_SHORTLISTED_REMOVED: (data) => {
        tracking('TT-candidate-shortlisted-removed', data);
    },
    CUSTOM_SCORE_APPLIED: (data) => {
        tracking('TT-custom-score-applied', data);
    },
    CUSTOM_SCORE_REMOVED: (data) => {
        tracking('TT-custom-score-removed', data);
    },
    CUSTOM_SCORE_FILTER_CHANGED: (data) => {
        tracking('TT-custom-score-filter-changed', data);
    },
    CUSTOM_SHORTLIST_COMPARED: (data) => {
        tracking('TT-shortlist-compared', data);
    },
    ADD_QUESTION_CREATE_CLICKED: () => {
        tracking('TT-add-question-create-clicked');
    },
    ADD_QUESTION_SUGGESTED_CLICKED: () => {
        tracking('TT-add-question-suggested-clicked');
    },
    ADD_QUESTION_SAVED_CLICKED: () => {
        tracking('TT-add-question-saved-clicked');
    },
    QUESTIONS_GENERATED: (data) => {
        tracking('TT-questions-generated', data);
    },
    QUESTIONS_GENERATED_FAILED: (data) => {
        tracking('TT-questions-generated-failed', data);
    },
    QUESTION_ADDED: (data) => {
        tracking('TT-question-added', data);
    },
    QUESTION_SAVED: (data) => {
        tracking('TT-question-saved', data);
    },
    SAVED_QUESTION_DELETED: (data) => {
        tracking('TT-saved-question-deleted', data);
    },
    SAVED_QUESTION_SEARCH: (data) => {
        tracking('TT-saved-question-search', data);
    },
    INSIGHTS_VIEWED: (data) => {
        tracking('TT-insights-viewed', data);
    },
    INSIGHTS_COMPLETIONS_FILTERED: (data) => {
        tracking('TT-insights-candidate-completions-filtered', data);
    },
    INSIGHTS_COMPLETIONS_DOWNLOADED: (data) => {
        tracking('TT-insights-candidate-completions-downloaded', data);
    },
    INSIGHTS_SCORE_DISTRIBUTION_FILTERED: (data) => {
        tracking('TT-insights-score-distribution-filtered', data);
    },
    INSIGHTS_SCORE_DISTRIBUTION_DOWNLOADED: (data) => {
        tracking('TT-insights-score-distribution-downloaded', data);
    },
    QUESTION_CODE_GENERATED: (data) => {
        tracking('TT-question-code-generated', data);
    },
    QUESTION_CODE_GENERATED_FAILED: (data) => {
        tracking('TT-question-code-generated-failed', data);
    },
    AUTOREJECT_ENABLED: (data) => {
        tracking('TT-autoreject-enabled', data);
    },
    AUTOPROGRESS_ENABLED: (data) => {
        tracking('TT-autoprogress-enabled', data);
    },
    AUTOPROGRESS_LINK_ADDED: (data) => {
        tracking('TT-autoprogress-link-added', data);
    },
    AUTOPROGRESS_LINK_REMOVED: (data) => {
        tracking('TT-autoprogress-link-removed', data);
    },
    QUESTION_PLAGIARISM_ENABLED: (data) => {
        tracking('TT-question-plagiarism-enabled', data);
    },
    DEADLINE_DATE_SET: (data) => {
        tracking('TT-deadline-date-set', data);
    },
    PREVIEW_SHARED: (data) => {
        tracking('L-tt-preview-shared', data);
    },
    REGENERATED: (data) => {
        tracking('L-tt-regenerated', data);
    },
    SCREENER_ADDED: (data) => {
        tracking('TT-screener-added', data);
    },
    SCREENER_DELETED: (data) => {
        tracking('TT-screener-deleted', data);
    },
    SCREENER_QUESTION_ADDED: (data) => {
        tracking('TT-screening-question-added', data);
    },
    SCREENER_QUESTION_DELETED: (data) => {
        tracking('TT-screening-question-deleted', data);
    },
    SCREENER_QUESTION_SAVED: (data) => {
        tracking('TT-screening-question-saved', data);
    },
    SCREENER_SAVED_QUESTION_DELETED: (data) => {
        tracking('TT-saved-screening-question-deleted', data);
    },
    QUESTION_SUGGESTED_ADDED: (data) => {
        tracking('TT-question-suggested-added', data);
    },
    GEOLP_FLAG_REMOVED: (data) => {
        tracking('G-candidate-geoIp-flag-removed', data);
    },
    PLAGIARISM_FLAG_REMOVED: (data) => {
        tracking('G-candidate-plagiarism-flag-removed', data);
    }
};

const styles = theme => ({
    roleInputRoot: {
        padding: '16px 20px'
    },
    roleInputInput: {
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.blue[600]
    },
    roleInputLabel: {
        fontSize: 18,
        fontWeight: 700,
        left: 13,
        overflow: 'visible',
        color: theme.palette.grey[400]
    },
    roleInputNotchedOutline: {
        paddingLeft: '23px !important',
        '& legend': {
            width: 64
        }
    },
    roleInputLabelShrink: {
        fontWeight: 600,
        fontSize: 17,
        left: 15,
        top: 'auto'
    },
    roleInputHelperText: {
        fontWeight: 600,
        fontSize: 14,
        marginTop: 5,
        color: theme.palette.grey[500]
    }
});

export default styles;

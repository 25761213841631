import { createContext } from 'react';
import { action, observable } from 'mobx';

class ResultsStore {
    @observable candidateTabs = [];

    @action setCandidateTabs = (assessmentSlug) => {
        const storedAssessmentTabs = JSON.parse(localStorage.getItem('assessmentTabs')) || {};
        if (storedAssessmentTabs[assessmentSlug]) {
            this.candidateTabs = storedAssessmentTabs[assessmentSlug];
        } else {
            this.candidateTabs = [];
        }
    }

    @action addCandidateTab = (id, name, assessmentSlug) => {
        if (this.candidateTabs.find(({ id: candidateId }) => candidateId === id)) return;
        const newTab = { name, id };
        this.candidateTabs.push(newTab);
        const storedAssessmentTabs = JSON.parse(localStorage.getItem('assessmentTabs')) || {};
        if (!storedAssessmentTabs[assessmentSlug]) storedAssessmentTabs[assessmentSlug] = [];
        if (!storedAssessmentTabs[assessmentSlug].find(({ id: candidateId }) => candidateId === id)) {
            storedAssessmentTabs[assessmentSlug].push(newTab);
        }
        localStorage.setItem('assessmentTabs', JSON.stringify(storedAssessmentTabs));
    };

    @action removeCandidateTab = (id, assessmentSlug) => {
        const index = this.candidateTabs.findIndex(tab => tab.id === +id);
        if (index !== -1) this.candidateTabs.splice(index, 1);
        const storedAssessmentTabs = JSON.parse(localStorage.getItem('assessmentTabs')) || {};
        if (!storedAssessmentTabs[assessmentSlug]) storedAssessmentTabs[assessmentSlug] = [];
        const storedIndex = storedAssessmentTabs[assessmentSlug].findIndex(({ id: candidateId }) => candidateId === id);
        storedAssessmentTabs[assessmentSlug].splice(storedIndex, 1);
        localStorage.setItem('assessmentTabs', JSON.stringify(storedAssessmentTabs));
    };


    @action removeAllCandidateTabsForAssessment = (assessmentSlug) => {
        const storedAssessmentTabs = JSON.parse(localStorage.getItem('assessmentTabs')) || {};
        delete storedAssessmentTabs[assessmentSlug];
        localStorage.setItem('assessmentTabs', JSON.stringify(storedAssessmentTabs));
        this.candidateTabs = [];
    };

    @action cleanScript = () => {
        this.candidateTabs = [];
    }
}

export const resultsStore = new ResultsStore();
export const resultsCtx = createContext(resultsStore);

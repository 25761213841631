import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import { observer } from 'mobx-react-lite';
import TableRow from '@mui/material/TableRow';
import clsx from 'clsx';

import CandidatePhoto from 'components/photos/candidate_photo';
import TableCellCustom from 'components/table_components/table_cell';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import CandidateActions from 'pages/assessment/results_assessment/common/CandidateActions';
import { appCtx } from 'components/appStore';

import Tooltip from 'libraries/Tooltip';
import { checkHasCompanyFeature, checkHasUserPermission } from 'helper/commonFunctions';
import { BATCH_ACTIONS, SCORE_TYPE_AI } from 'helper/constants';
import GeneralEvents from 'events/GeneralEvents';

import SmsIndication from 'components/candidate_cards/SMSIndication';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import SeenStarted from 'img/seen_started.svg';
import WasResetIcon from 'img/wasReset.svg';
import { invitationsCtx } from '../../../../../invitationsStore';
import { statsCtx } from '../../../../../../statsStore';

import useStyles from './styles';


const CandidateRow = observer(({ candidate, companyTags, history }) => {
    const [shownBatch, setShownBatch] = useState(false);
    const classes = useStyles();
    const {
        score, candidateId, candidateUuid,
        fullName, completionsPerOpportunityLimitExceeded,
        scoreType, tags, email, candidateStatus,
        candidateStatusActualDate, source, invited, completed,
        auditionOpened, wasReset, screeningQuestions
    } = candidate;
    const [candidateTags, setCandidateTags] = useState(tags);

    useEffect(() => {
        setCandidateTags(candidateTags);
    }, [tags]);

    const {
        selectedCandidates, toggleSelectedUserAuditionIds,
        loadCandidates, fetchInvitationStats, filterTagsCategory
    } = useContext(invitationsCtx);
    const { audition } = useContext(assessmentSettingsCtx);
    const { loadAssessmentStats, loadExtensionsRequests } = useContext(statsCtx);

    const { company } = useContext(appCtx);

    if (!audition) return null;

    const { permissions, uuid: ttId, name: ttName, slug } = audition;

    const edit = checkHasUserPermission(company, permissions, 'edit');

    const dataForEvents = {
        candidateId: candidateUuid,
        context: 'invite page',
        ttId,
        ttName,
        score: score ? score.score : undefined,
        scoreIsAI: score ? score.type === SCORE_TYPE_AI : undefined,
        scoreType
    };

    const hoverable = candidateStatus === 'Hired' || completed || screeningQuestions;

    const goToCard = () => {
        if (!hoverable) return;
        GeneralEvents.CANDIDATE_VIEWED(dataForEvents);
        history.push(`/script/${(screeningQuestions && !completed) ? 'invite' : 'select'}/${slug}/candidate/${candidateId}`);
    };

    const showBatch = () => {
        if (!edit) return;
        setShownBatch(true);
    };

    const hideBatch = () => setShownBatch(false);

    const onBatchClick = (e) => {
        e.stopPropagation();
        toggleSelectedUserAuditionIds(candidate);
    };

    const hasCompanyFeature = checkHasCompanyFeature(company, BATCH_ACTIONS);
    const tableCellClass = clsx(classes.tableCell, invited && classes.tableCellMuted);

    return (
        <TableRow
            className={clsx(hoverable && classes.row)}
            onMouseEnter={showBatch}
            onMouseLeave={hideBatch}
            hover={hoverable}
            onClick={goToCard}
        >
            <TableCellCustom className={tableCellClass} align="left">
                <div className="u-dsp--centered u-jc--start">
                    <CandidatePhoto
                        user={candidate}
                        onClick={onBatchClick}
                        checked={selectedCandidates[candidateId]}
                        shownBatch={shownBatch && hasCompanyFeature && !completionsPerOpportunityLimitExceeded}
                        setShownBatch={setShownBatch}
                    />
                    <TruncateWithTooltip text={fullName} />
                </div>
            </TableCellCustom>
            <TableCellCustom className={tableCellClass} align="left">
                <TruncateWithTooltip text={email} />
            </TableCellCustom>
            <TableCellCustom className={tableCellClass} align="left">
                {candidateStatus}
                <Tooltip
                    label={<Moment format="MMM D, YYYY">{candidateStatusActualDate}</Moment>}
                >
                    <span className={classes.statusDate}>
                        <Moment fromNow>
                            {candidateStatusActualDate}
                        </Moment>
                    </span>
                </Tooltip>
                {
                    auditionOpened && (
                        <Tooltip label="Assessment opened">
                            <span className={classes.statusDate}>
                                <img className="u-mrg--lx1" src={SeenStarted} alt="+" />
                            </span>
                        </Tooltip>
                    )
                }
                {
                    wasReset && (
                        <Tooltip label="Extension Received">
                            <span className={classes.statusDate}>
                                <img className="u-mrg--lx1" src={WasResetIcon} alt="+" />
                            </span>
                        </Tooltip>
                    )
                }
            </TableCellCustom>
            <TableCellCustom className={tableCellClass} align="left">
                <div className="u-dsp--f u-ai--center">
                    <TruncateWithTooltip text={source} width={170} />
                    <span className="u-mrg--lx1">
                        <SmsIndication candidate={candidate} audition={audition} />
                    </span>
                </div>
            </TableCellCustom>
            <TableCellCustom className={classes.tableCell} align="right">
                <CandidateActions
                    isInviteSection
                    companyTags={companyTags}
                    candidate={candidate}
                    loadCandidates={loadCandidates}
                    loadFiltersStats={fetchInvitationStats}
                    loadAssessmentStats={loadAssessmentStats}
                    loadExtensionsRequests={loadExtensionsRequests}
                    dataForEvents={dataForEvents}
                    filterTagsCategory={filterTagsCategory}
                />
            </TableCellCustom>
        </TableRow>
    );
});

export default withRouter(CandidateRow);

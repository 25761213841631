import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextInputValidated from 'libraries/TextInputValidated';
import { changePassword } from 'requests/LoginRequests';
import { myAccount } from 'requests/SettingsRequests';
import SettingsEvents from 'events/SettingsEvents';

import PageWrapper from 'components/layout/PageWrapper';
import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import UploadUserPhotoAlt from 'components/photos/upload_user_photo_alt';
import Layout from 'components/layout';
import { passwordValidateFunction } from 'helper/validationFunctions';
import { isPhoneValid } from 'helper/commonFunctions';
import PhoneInputWithCountries from 'components/inputs/PhoneInputWithCountries';
import SettingsHeader from '../settings_header';
import PausedLabel from '../PausedLabel';

import styles from './styles';


const MIN_PASSWORD_LENGTH = 8;
const MAX_NAME_LENGTH = 160;
const MAX_EMAIL_LENGTH = 180;

class SettingsMyAccount extends Component {
    state = {
        form: {},
        errorForm: {},
        formPassword: {},
        errorFormPassword: {}
    };

    componentDidMount() {
        const { loggedUser } = this.props;
        const { first_name, last_name, email, phone } = loggedUser;
        const form = {
            first_name,
            last_name,
            email,
            phone
        };
        this.setState({ form });

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            const { formPassword } = this.state;
            const { new_password } = formPassword;
            return value === new_password;
        });
        ValidatorForm.addValidationRule('trimMaxNameLength', value => value.trim().length <= MAX_NAME_LENGTH);
        ValidatorForm.addValidationRule('trimMaxEmailLength', value => value.trim().length <= MAX_EMAIL_LENGTH);
        ValidatorForm.addValidationRule('noPlusInside', this.checkIfNoPlusInside);
    }

    componentWillReceiveProps(nextProps) {
        const { form } = this.state;
        const { loggedUser } = this.props;
        const newForm = {};
        if (form.first_name === undefined || loggedUser !== nextProps.loggedUser) {
            if (nextProps.loggedUser) {
                newForm.first_name = nextProps.loggedUser.first_name;
                newForm.last_name = nextProps.loggedUser.last_name;
                newForm.email = nextProps.loggedUser.email;
                newForm.phone = nextProps.loggedUser.phone;
            }
            this.setState({ form: newForm });
        }
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    checkIfNoPlusInside = (value) => {
        const hasPlus = new RegExp('\\+').test(value);
        return !hasPlus || (hasPlus && new RegExp('vervoe.com').test(value));
    };

    updateSettings = (e) => {
        e.preventDefault();
        const { form } = this.state;
        const { loggedUser } = this.props;
        if (form.phone) form.phone = form.phone.trim();
        myAccount(JSON.stringify(form))
            .then(({ success, data: { user } }) => {
                if (success && user) {
                    const { updateLoggedUser, flashMessage } = this.props;
                    updateLoggedUser(user);
                    this.setState({ errorForm: {} });
                    const { first_name: oldFirstName, last_name: oldLastName, email: oldEmail, phone: oldPhoneNumber } = loggedUser;
                    const { first_name: newFirstName, last_name: newLastName, email: newEmail, phone: newPhoneNumber } = form;
                    const data = {
                        oldFirstName,
                        newFirstName,
                        oldLastName,
                        newLastName,
                        oldEmail,
                        newEmail,
                        newPhoneNumber
                    };
                    if (oldPhoneNumber) data.oldPhoneNumber = oldPhoneNumber;
                    SettingsEvents.ACCOUNT_DETAILS_UPDATED(data);
                    flashMessage('Settings have been updated successfully.');
                }
            })
            .catch(({ response }) => {
                if (response.data.errors) {
                    const { email, firstName, mobile } = response.data.errors;
                    const firstNameErr = firstName && firstName[0] ? firstName[0].message : '';
                    const emailErr = email && email[0] ? email[0].message : '';
                    const phoneErr = mobile && mobile[0] ? mobile[0].message : '';
                    this.setState({
                        errorForm: {
                            email: emailErr,
                            firstName: firstNameErr,
                            phone: phoneErr
                        }
                    });
                }
            });
    };

    handleChange = (e) => {
        const { form } = this.state;
        const updateForm = { ...form, [e?.target?.name || 'phone']: e?.target?.name ? e?.target?.value : e };
        this.setState({ form: updateForm });
    };

    changePassword = (e) => {
        e.preventDefault();
        const { formPassword } = this.state;
        changePassword(JSON.stringify(formPassword))
            .then((data) => {
                if (data.success) {
                    const { flashMessage } = this.props;
                    this.setState({
                        formPassword: {},
                        errorFormPassword: {}
                    });
                    flashMessage('Password has been updated successfully.');
                } else {
                    this.setState({ errorFormPassword: data.errorForm });
                }
            });
    };

    handleChangePassword = (e) => {
        const { name: targetName, value } = e.target;
        const { formPassword: form } = this.state;
        if (targetName === 'new_password') {
            this.customPasswordValidate(e); // validate password simultaneously
        }
        const updateForm = { ...form, [targetName]: value };
        this.setState({ formPassword: updateForm });
    };

    renderValue = (value) => {
        const { form } = this.state;
        if (form && form[value]) {
            return form[value];
        }
        return '';
    };

    renderError = (value) => {
        const { errorForm } = this.state;
        if (errorForm && errorForm[value]) {
            return errorForm[value];
        }
        return '';
    };

    renderFormPassword = (value) => {
        const { formPassword } = this.state;
        if (formPassword && formPassword[value]) {
            return formPassword[value];
        }
        return '';
    };

    renderErrorPassword = (value) => {
        const { errorFormPassword } = this.state;
        if (errorFormPassword && errorFormPassword[value]) {
            return errorFormPassword[value];
        }
        return '';
    };

    validate = (e) => {
        this.refs[e.target.name].validate(e.target.value);
    };

    customPasswordValidate = (e) => {
        const { value } = e.target;
        const { errorForm } = this.state;
        const newErrorForm = JSON.parse(JSON.stringify(errorForm));
        newErrorForm.new_password = passwordValidateFunction(value, MIN_PASSWORD_LENGTH);
        this.setState({ errorFormPassword: newErrorForm });
    };

    render() {
        const {
            loggedUser, updateLoggedUser,
            flashMessage, classes
        } = this.props;
        return (
            <Layout>
                <PausedLabel />
                <SettingsHeader />
                <PageWrapper
                    headerLabel="My Account"
                    className="u-z--1  u-pos--relative"
                >
                    <ContentWrapper>
                        <div className={classes.innerWrapper}>
                            <div className={classes.photoWrapper}>
                                <UploadUserPhotoAlt
                                    loggedUser={loggedUser}
                                    updateLoggedUser={updateLoggedUser}
                                    flashMessage={flashMessage}
                                    hasUpload
                                />
                            </div>
                            <ValidatorForm
                                onSubmit={this.updateSettings}
                            >
                                <div className="o-grid">
                                    <div className="o-grid__1/2">
                                        <TextInputValidated
                                            label="First Name"
                                            name="first_name"
                                            value={this.renderValue('first_name')}
                                            fullWidth
                                            className="u-mrg--bx4"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            isError={Boolean(this.renderError('firstName'))}
                                            formerror={this.renderError('firstName')}
                                            validators={['required', `trimMaxNameLength:${MAX_NAME_LENGTH}`]}
                                            errorMessages={['This field is required.', `Maximum ${MAX_NAME_LENGTH} characters`]}
                                        />
                                    </div>
                                    <div className="o-grid__1/2">
                                        <TextInputValidated
                                            label="Last Name"
                                            name="last_name"
                                            variant="outlined"
                                            className="u-mrg--bx4"
                                            value={this.renderValue('last_name')}
                                            fullWidth
                                            onChange={this.handleChange}
                                            isError={Boolean(this.renderError('lastName'))}
                                            formerror={this.renderError('lastName')}
                                            validators={['required', `trimMaxNameLength:${MAX_NAME_LENGTH}`]}
                                            errorMessages={['This field is required.', `Maximum ${MAX_NAME_LENGTH} characters`]}
                                        />
                                    </div>
                                </div>
                                <TextInputValidated
                                    label="Email Address"
                                    name="email"
                                    value={this.renderValue('email')}
                                    fullWidth
                                    variant="outlined"
                                    className="u-mrg--bx4"
                                    type="text"
                                    onChange={this.handleChange}
                                    isError={Boolean(this.renderError('email'))}
                                    formerror={this.renderError('email')}
                                    validators={['required', 'isEmail', 'noPlusInside', `trimMaxEmailLength:${MAX_EMAIL_LENGTH}`]}
                                    errorMessages={[
                                        'This field is required.',
                                        'Please enter a work email address.',
                                        'Please do not use special characters inside your email.',
                                        `Maximum ${MAX_EMAIL_LENGTH} characters`
                                    ]}
                                />
                                <div className="u-mrg--tx2">
                                    <PhoneInputWithCountries
                                        value={this.renderValue('phone')}
                                        messageClassname={classes.phoneHelperText}
                                        isValid={isPhoneValid(this.renderValue('phone'))}
                                        name="phone"
                                        onChange={this.handleChange}
                                        placeholder="Phone (optional)"
                                    />
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes.submitButton}
                                    disabled={!isPhoneValid(this.renderValue('phone'))}
                                >
                                    Update settings
                                </Button>
                            </ValidatorForm>
                        </div>
                        <div className={classes.passwordHeader}>
                            Password
                        </div>
                        <div className={classes.innerWrapper}>
                            <ValidatorForm
                                instantValidate={false}
                                onSubmit={this.changePassword}
                            >
                                <TextInputValidated
                                    label="Current password"
                                    name="current_password"
                                    ref="current_password"
                                    fullWidth
                                    className="u-mrg--bx4"
                                    variant="outlined"
                                    value={this.renderFormPassword('current_password')}
                                    type="password"
                                    onChange={this.handleChangePassword}
                                    formerror={this.renderErrorPassword('current_password')}
                                    onBlur={this.validate}
                                    validators={['required']}
                                    errorMessages={['Please enter your password.']}
                                />
                                <TextInputValidated
                                    label="New password"
                                    name="new_password"
                                    ref="new_password"
                                    variant="outlined"
                                    className="u-mrg--bx4"
                                    value={this.renderFormPassword('new_password')}
                                    fullWidth
                                    type="password"
                                    onBlur={this.validate}
                                    onFocus={this.customPasswordValidate}
                                    onChange={this.handleChangePassword}
                                    formerror={this.renderErrorPassword('new_password')}
                                    validators={[
                                        'required'
                                    ]}
                                    errorMessages={[
                                        'Please enter the new password.'
                                    ]}
                                />
                                <TextInputValidated
                                    label="Confirm new password"
                                    fullWidth
                                    className="u-mrg--bx4"
                                    value={this.renderFormPassword('confirm_new_password')}
                                    name="confirm_new_password"
                                    ref="confirm_new_password"
                                    type="password"
                                    variant="outlined"
                                    onChange={this.handleChangePassword}
                                    formerror={this.renderErrorPassword('confirm_new_password')}
                                    validators={['required', 'isPasswordMatch']}
                                    errorMessages={['Please confirm the new password.', 'Passwords do not match.']}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitButton}
                                    type="submit"
                                >
                                    Change Password
                                </Button>
                            </ValidatorForm>
                        </div>
                    </ContentWrapper>
                </PageWrapper>
            </Layout>
        );
    }
}

export default withStyles(styles)(SettingsMyAccount);

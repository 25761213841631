import { createContext } from 'react';
import { action, observable } from 'mobx';
import { loadAudition } from 'requests/ScriptRequests';
import { getCompanyTags } from 'requests/SettingsRequests';

class AssessmentSettingsStore {
    @observable slug = '';

    @observable audition = null;

    @observable isLoading = true;

    @observable opportunitySlug = '';

    @observable companyTags = [];

    @action setAudition = (newAudition) => {
        this.audition = newAudition;
    };

    @action changeAuditionParameter = (param, value) => {
        this.audition[param] = value;
    };

    @action setInterviewSlug = (slug) => {
        this.slug = slug;
    };

    @action setAuditionIsActive = () => {
        this.audition.active = true;
    }

    @action loadEditScript = (history) => {
        if (!this.slug) return;
        loadAudition(this.slug)
            .then(({ success, data }) => {
                if (success && data) {
                    const { opportunitySlug } = data;
                    this.opportunitySlug = opportunitySlug;
                    this.audition = data;
                }
            })
            .catch((err) => {
                if (history && err.response && err.response.status === 403) history.push('/forbidden');
                if (history && err.response && err.response.status === 404) history.push('/not-found');
            })
            .finally(() => {
                this.isLoading = false;
            });
    };

    @action getTags = () => {
        getCompanyTags()
            .then(({ success, data }) => {
                if (success && data) {
                    this.companyTags = data;
                }
            });
    };

    @action setTags = (tags) => {
        this.companyTags = tags;
    };

    @action cleanScript = () => {
        this.audition = null;
        this.slug = '';
        this.opportunitySlug = '';
        this.companyTags = [];
    }
}

export const assessmentSettingsStore = new AssessmentSettingsStore();
export const assessmentSettingsCtx = createContext(assessmentSettingsStore);

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { appCtx } from 'components/appStore';
import InviteDialog from './InviteDialog';

const styles = {
    inviteButton: {
        paddingLeft: 25,
        paddingRight: 25,
        '&:hover, &:active, &:focus': {
            color: '#fff',
            outline: 'none'
        }
    }
};

const InviteTeamMemberButton = observer(({ classes, countTeamMembers, countPendingTeamMembers, loadTeamMembers }) => {
    const [openedDialog, setDialog] = useState(false);
    const { company } = useContext(appCtx);
    const { companySettings } = company;

    if (!companySettings) return null;

    const totalCount = countTeamMembers + countPendingTeamMembers;
    const available = companySettings.teamMembers - totalCount;

    const handleDialog = () => {
        setDialog(!openedDialog);
    };

    const hasAvailableTeamSlots = available > 0 || companySettings.teamMembers === -1;

    return (
        <>
            <Button
                className={classes.inviteButton}
                variant="contained"
                onClick={handleDialog}
                color="primary"
                disabled={!hasAvailableTeamSlots}
            >
                Invite Team Members
            </Button>
            <InviteDialog
                loadTeamMembers={loadTeamMembers}
                onClose={handleDialog}
                open={openedDialog}
            />
        </>
    );
});

export default withStyles(styles)(InviteTeamMemberButton);

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import { errorMessage } from 'helper/constants';
import { rejectCandidates } from 'requests/AssessmentRequests';
import GeneralEvents from 'events/GeneralEvents';
import { Link } from 'react-router-dom';
import { appCtx } from '../../appStore';

const styles = theme => ({
    progress: {
        color: '#fff'
    },
    submitButton: {
        minWidth: 124,
        paddingLeft: 45,
        paddingRight: 45
    },
    cancelButton: {
        fontWeight: 700,
        paddingLeft: 30,
        paddingRight: 30
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover, &:focus, &:active': {
            color: theme.palette.primary.main
        }
    }
});

const RejectDialog = observer(({
    callback, dataForEvent = {},
    user, slug, handleClose, classes, open, notifyRejectEmail,
    assessmentSlug
}) => {
    const [pending, setPending] = useState(false);
    const { flashMessage } = useContext(appCtx);

    const rejectUser = async () => {
        setPending(true);
        const requestPromise = rejectCandidates(slug, [user.candidateId]);
        requestPromise
            .then((response) => {
                setPending(false);
                if (response.success) {
                    GeneralEvents.CANDIDATE_REJECTED({
                        candidateId: user && user.uuid,
                        multipleGeoIpDetected: user && user.fraudDetected,
                        plagiarismDetected: user && user.plagiarismDetected,
                        ...dataForEvent
                    });

                    flashMessage('Rejected successfully', 'done');
                }
                if (callback) {
                    callback(response);
                }
                if (response.success) handleClose();
            })
            .catch(() => {
                flashMessage(errorMessage);
                setPending(false);
            });
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={handleClose}
            titleComponent="Are you sure you want to reject this candidate?"
            handleClose={handleClose}
            actionComponent={(
                <>
                    <Button
                        className={classes.cancelButton}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={rejectUser}
                        disabled={pending}
                        className={classes.submitButton}
                    >
                        {
                            pending ? (
                                <CircularProgress
                                    size={22}
                                    className={classes.progress}
                                />
                            ) : 'Reject'
                        }
                    </Button>
                </>
            )}
        >
            They will be removed from the assessment process and{!notifyRejectEmail && ' will not'} receive a <br />
            <Link
                to={`/script/settings/${assessmentSlug}/candidate-communication`}
                className={classes.link}
            >
                rejection email
            </Link>
        </Dialog>
    );
});

export default withStyles(styles)(RejectDialog);

import React from 'react';
import { withTheme } from '@mui/styles';

const LinkIcon = ({ theme, active, size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect width="32" height="32" rx="4" fill={active ? theme.palette.primary.main : theme.palette.grey[400]} />
        <path d="M18.6667 11.5555H21.3334C21.9171 11.5555 22.495 11.6705 23.0342 11.8939C23.5734 12.1172 24.0634 12.4446 24.4761 12.8573C24.8888 13.27 25.2162 13.7599 25.4395 14.2992C25.6629 14.8384 25.7778 15.4163 25.7778 16C25.7778 16.5836 25.6629 17.1616 25.4395 17.7008C25.2162 18.24 24.8888 18.73 24.4761 19.1427C24.0634 19.5554 23.5734 19.8828 23.0342 20.1061C22.495 20.3295 21.9171 20.4444 21.3334 20.4444H18.6667M13.3334 20.4444H10.6667C10.0831 20.4444 9.50514 20.3295 8.96592 20.1061C8.42669 19.8828 7.93674 19.5554 7.52404 19.1427C6.69054 18.3092 6.22229 17.1787 6.22229 16C6.22229 14.8212 6.69054 13.6908 7.52404 12.8573C8.35753 12.0238 9.48799 11.5555 10.6667 11.5555H13.3334" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.4446 16H19.5557" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(LinkIcon);

import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import SettingsIcon from 'components/icons/SettingsIcon';
import BackIconButton from 'components/assessments_pages/BackIconButton';
import { assessmentSettingsCtx } from 'pages/assessment/store';

import useStyles from './styles';


const HeaderStepper = ({ history, location, className = '' }) => {
    const classes = useStyles();
    const [previousState, setPreviousState] = useState('');
    const { audition, loadEditScript } = useContext(assessmentSettingsCtx);
    const { slug, active } = audition;

    useEffect(() => {
        if (location.state && location.state.from) {
            setPreviousState(location.state.from);
        }
    }, []);

    const goToAssessment = () => {
        loadEditScript();
        if (previousState) {
            history.push(previousState);
            return;
        }
        if (active) {
            history.push(`/script/select/${slug}`);
        } else {
            history.push(`/script/edit/${slug}`);
        }
    };

    return (
        <Container className={clsx('u-wdt--100p', classes.wrapper, className)}>
            <div className="u-dsp--distribute u-fw--wrap">
                <div className="u-dsp--f u-ai--center u-jc--start">
                    <BackIconButton onClick={goToAssessment} />
                    <p className={classes.settingsLabel}>Settings</p>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    className="u-pdn--rx4 u-pdn--lx3"
                >
                    <SettingsIcon color="#fff" className="u-mrg--rx1" />
                    Settings
                </Button>
            </div>
        </Container>
    );
};

export default withRouter(HeaderStepper);

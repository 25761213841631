import React from 'react';
import { withStyles } from '@mui/styles';
import VervoeLogo from 'components/layout/Header/components/VervoeLogo';
import LoginImg from './login.png';
import CheckIcon from './check.svg';

import styles from './styles';

const HomepageWrapper = ({ classes, children, noSidebar = false }) => (
    <div className={classes.wrapper}>
        <div className={classes.banner} />
        <div className={classes.main}>
            <a href="https://vervoe.com"><VervoeLogo className={classes.vervoeLogo} /></a>
            <div className={classes.content}>
                { children }
            </div>
        </div>
        { !noSidebar && (
            <div className={classes.sidebar}>
                <div>
                    <img width={364} className={classes.img} src={LoginImg} alt="Vervoe" />
                    <div className={classes.header}>See people do the job before they get the job with Vervoe</div>
                    <div className={classes.features}>
                        {[
                            'Assessment creation',
                            'Candidate Engagement',
                            'Collaboration tools',
                            'Reporting and analytics',
                            'Artificial Intelligence',
                            'Integrations'
                        ]
                            .map(feature => (
                                <div key={feature} className={classes.feature}>
                                    <img className="u-mrg--rx2" src={CheckIcon} alt="v" />
                                    {feature}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )}
    </div>
);

export default withStyles(styles)(HomepageWrapper);

export default ({
    inviteButton: {
        paddingLeft: 25,
        paddingRight: 25
    },
    cancelButton: {
        paddingLeft: 30,
        paddingRight: 30,
        fontWeight: 700
    },
    sendButton: {
        minWidth: 180,
        paddingLeft: 35,
        paddingRight: 35,
        fontWeight: 700
    },
    inputWrapper: {
        display: 'flex',
        '& > div:first-child': {
            width: '100%'
        }
    },
    select: {
        width: 160,
        marginLeft: 17
    },
    loader: {
        color: '#fff',
        '& svg': {
            margin: 0
        }
    }
});

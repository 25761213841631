import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import GroupContent from './components/GroupContent';
import ScreeningContent from './components/ScreeningContent';


const QuestionBanks = observer(({ question, isScreening }) => {
    const [questionsList, setQuestionsList] = useState([]);
    const [query, setQuery] = useState('');
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingAddButton, setLoadingAddButton] = useState(false);

    const props = {
        isScreening,
        questionsList,
        setQuestionsList,
        question,
        query,
        setQuery,
        loadingQuestions,
        setLoadingQuestions,
        loading,
        setLoading,
        loadingAddButton,
        setLoadingAddButton
    };

    return isScreening ? <ScreeningContent {...props} /> : <GroupContent {...props} />;
});

export default QuestionBanks;

import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import codeMirrorOptions from '../CodeMirrorOptions';

import styles from './styles.module.scss';
import TestCasesWrapper from './TestCasesWrapper';

const countPassed = (total, curr) => total + (+curr.testPassed);

export default ({ question: { grade, codeChallengeFinalAnswer, codeChallengeTestCaseResults }, NoAnswerComponent }) => (
    codeChallengeFinalAnswer
        ? (
                <>
                    <div className={styles.wrapper}>
                        <div className={styles.languageWrapper}>{codeChallengeFinalAnswer.language}</div>
                        <CodeMirror
                            value={codeChallengeFinalAnswer.code || ''}
                            options={codeMirrorOptions}
                        />
                        {
                            codeChallengeTestCaseResults && (
                                <div className={styles.countWrapper}>
                                    {
                                        grade === null
                                            ? 'Calculating score...'
                                            : `${codeChallengeTestCaseResults.reduce(countPassed, 0)}/${codeChallengeTestCaseResults.length} test cases passed`
                                    }
                                </div>
                            )
                        }
                    </div>
                    {
                        grade !== null && codeChallengeTestCaseResults && (
                            <div className={styles.listWrapper}>
                                {
                                    codeChallengeTestCaseResults.map(item => (
                                        <TestCasesWrapper
                                            item={item}
                                            key={item.id}
                                        />
                                    ))
                                }
                            </div>
                        )
                    }
                </>
        ) : <NoAnswerComponent />
);

export default {
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },
    bootstrapPopper: {
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: '#1f2032 transparent transparent transparent'
            }
        }
    },
    bootstrapTooltip: { backgroundColor: '#1f2032' },
    bootstrapPlacementLeft: { margin: '0 8px' },
    bootstrapPlacementRight: { margin: '0 8px' },
    bootstrapPlacementTop: { margin: '8px 0' },
    bootstrapPlacementBottom: { margin: '8px 0' },
    toolText: {
        fontFamily: 'Open Sans',
        fontSize: 11,
        fontWeight: 600,
        color: 'white',
        padding: 6
    },
    charactersHelper: {
        paddingLeft: '15px',
        marginBottom: '0',
        fontFamily: '"Open Sans"',
        fontSize: '12px',
        color: '#9fa0a5'
    },
    charactersHelperActive: {
        color: '#f02e2e'
    },
    iconsBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 0',
        marginTop: -52,
        position: 'relative',
        zIndex: 100,
        '& > div > svg': {
            marginRight: 15,
            cursor: 'pointer'
        }
    },
    svg: {
        marginRight: 14,
        '& > path:not(.noStroke), & > g:not(.noStroke)': {
            stroke: '#676A78'
        },
        '&:hover > g, &:hover > path:not(.noStroke), &:hover > use': {
            stroke: '#436CFF'
        },
        '& > path.noStroke': {
            fill: '#676A78'
        },
        '&:hover > path.noStroke': {
            fill: '#436CFF'
        }
    }
};

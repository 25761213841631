export default ({
    dialog: {},
    icon: {
        marginBottom: 20
    },
    dialogContent: {
        padding: '70px 35px 60px',
        textAlign: 'center',
        lineHeight: '28px'
    },
    successHeader: {
        marginBottom: 10
    }
});

import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SketchPicker } from 'react-color';

import Select from 'libraries/Select';
import TextInput from 'libraries/TextInput';
import { isPhoneValid, checkHasCompanyFeature, getBaseUrlForRedirect } from 'helper/commonFunctions';
import PageWrapper from 'components/layout/PageWrapper';
import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import { isEmptyObject } from 'helper/validationFunctions';
import { getCompanyAccount, deleteCompanyLogoAndColor } from 'requests/APIrequests';
import { getAssessmentsLanguages, getPossibleTimezones } from 'requests/SettingsRequests';
import Layout from 'components/layout';
import AddressAutocomplete from 'components/inputs/AddressAutocomplete';
import PhoneInputWithCountries from 'components/inputs/PhoneInputWithCountries';
import UpgradeToUnlockTooltip from 'components/tooltips/UpgradeToUnlockTooltip';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import ChangeSlugDialog from 'components/dialogs/ChangeSlugDialog';
import SettingsEvents from 'events/SettingsEvents';

import PausedLabel from '../PausedLabel';
import SettingsHeader from '../settings_header';

import UploadCompanyPhoto from './UploadCompanyPhoto';
import BrandColorDialog from './BrandColorDialog';
import CandidatesTags from './CandidatesTags';
import FeedbackAgreement from './FeedbackAgreement';

import styles from './styles';

const sizeOptions = [
    { value: 0, label: '1 - 10' },
    { value: 1, label: '11 - 50' },
    { value: 2, label: '51 - 200' },
    { value: 3, label: '201 - 1000' },
    { value: 4, label: '1001 - 5000' },
    { value: 5, label: 'More than 5000' }
];

class SettingsCompanyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {},
            errorForm: {},
            dialog_open: false,
            openChangeCompanySlugModal: false,
            timezones: [],
            languages: {}
        };
    }

    componentDidMount() {
        const { company, location } = this.props;

        this.getTimezones();
        this.getLanguages();
        this.setFormParams(company);

        if (location.state && location.state.scrollToEnd) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { loggedUser } = this.props;
        const { form: oldForm } = this.state;
        if (oldForm.name === undefined || loggedUser !== nextProps.loggedUser) {
            this.setFormParams(nextProps.company);
        }
    }

    getTimezones = () => {
        getPossibleTimezones()
            .then(({ success, data }) => {
                if (success) {
                    this.setState({ timezones: data });
                }
            });
    };

    getLanguages = async () => {
        const response = await getAssessmentsLanguages();
        const { success, data } = response;
        if (success && data) {
            this.setState({ languages: data });
        }
    };

    setFormParams = (company) => {
        const {
            name, location,
            website, phone,
            size, description,
            companyColor,
            timezone,
            candidateAppLanguage
        } = company;
        const form = {
            name,
            location,
            website,
            companyColor,
            phone,
            description,
            size,
            timezone,
            candidateAppLanguage
        };
        this.setState({ form });
    };

    changeSize = (event) => {
        const { value } = event.target;
        const { form } = this.state;
        form.size = +value;
        if (form.size < 2) form.phone = '';
        this.setState({ form });
    };

    changeTimezone = (event) => {
        const { value } = event.target;
        const { form } = this.state;
        form.timezone = value;
        this.setState({ form });
    };

    setCompanyColor = (color) => {
        const { form } = this.state;
        const updateForm = { ...form, companyColor: color };
        this.setState({ form: updateForm });
    };

    handleOpen = (value) => {
        this.setState({ dialog_open: value });
    };

    handleClose = () => {
        this.setState({ dialog_open: false });
    };

    handleChange = (e) => {
        const { form } = this.state;
        let targetName = '';
        let value = '';
        if (!e.target) {
            targetName = 'phone';
            value = e;
        } else {
            targetName = e.target.name;
            value = e.target.value;
        }
        if (e.hex) {
            targetName = 'companyColor';
            value = e.hex;
        }
        const updateForm = { ...form, [targetName]: value };

        this.setState({ form: updateForm });
    };

    handleChangeLanguage = (e) => {
        const { form } = this.state;
        const { value } = e.target;
        form.candidateAppLanguage = value;
        this.setState({ form });
    }

    handleChangeColor = (e) => {
        const { company } = this.props;
        const hasCompanyBranding = checkHasCompanyFeature(company, 'COMPANY_BRANDING');
        if (!hasCompanyBranding) return;
        const { form } = this.state;
        const updateForm = { ...form, companyColor: e.hex || '' };
        this.setState({ form: updateForm });
    };

    handleChangeAutoComplete = (value) => {
        const { form } = this.state;
        const updateForm = { ...form, location: value };

        this.setState({ form: updateForm });
    };

    renderValue = (value) => {
        const { form } = this.state;
        if (form && form[value]) {
            return form[value];
        }
        return '';
    };

    renderError = (value) => {
        const { errorForm } = this.state;
        if (errorForm && errorForm[value]) {
            return errorForm[value];
        }
        return '';
    };


    submitForm = (e) => {
        e.preventDefault();
        const { form } = this.state;
        const { updateCompany, flashMessage, company } = this.props;
        getCompanyAccount(form)
            .then(({ data, success }) => {
                if (success) {
                    updateCompany(data);
                    this.setState({ errorForm: {} });
                    flashMessage('Company settings have been updated successfully.', 'done');
                    const {
                        name: oldCompanyName,
                        location,
                        website,
                        companyColor,
                        phone: phoneNumber,
                        description: companyDescription,
                        size,
                        timezone
                    } = company;
                    const {
                        name: newCompanyName,
                        location: newLocation,
                        website: newWebsite,
                        companyColor: newCompanyColor,
                        phone: newPhoneNumber,
                        description: newCompanyDescription,
                        size: newSize,
                        timezone: newTimezone,
                        candidateAppLanguage
                    } = form;
                    SettingsEvents.COMPANY_DETAILS_UPDATED({
                        oldCompanyName,
                        newCompanyName,
                        location,
                        newLocation,
                        website,
                        newWebsite,
                        companySize: size ? sizeOptions.find(option => option.value === size).label : '',
                        newCompanySize: newSize ? sizeOptions.find(option => option.value === newSize).label : '',
                        timezone,
                        newTimezone,
                        phoneNumber,
                        newPhoneNumber,
                        companyDescription,
                        newCompanyDescription,
                        candidateAppLanguage,
                        companyColor,
                        newCompanyColor
                    });
                } else {
                    this.setState({ errorForm: data.errorForm });
                }
            })
            .catch(({ response }) => {
                if (response) {
                    const { data: { errors } } = response;
                    if (errors) {
                        const newErrorForm = {};
                        Object.keys(errors).forEach((key) => {
                            newErrorForm[key] = errors[key][0].message;
                        });
                        this.setState({ errorForm: newErrorForm });
                    }
                } else {
                    flashMessage('Something went wrong');
                }
            });
    };

    changeCompanySlugModal = () => {
        const { openChangeCompanySlugModal } = this.state;
        this.setState({ openChangeCompanySlugModal: !openChangeCompanySlugModal });
    };

    resetCompanyBranding = () => {
        const {
            flashMessage,
            updateCompany
        } = this.props;
        deleteCompanyLogoAndColor()
            .then(({ data }) => {
                updateCompany(data);
                this.setCompanyColor(data.companyColor);
                flashMessage('Branding removed', 'done');
                SettingsEvents.COMPANY_LOGO_RESET();
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    returnColorPicker = () => {
        const { company, classes } = this.props;
        const hasCompanyBranding = checkHasCompanyFeature(company, 'COMPANY_BRANDING');
        const picker = (
            <SketchPicker
                color={this.renderValue('companyColor')}
                onChangeComplete={this.handleChangeColor}
                disableAlpha
                width="95%"
                className="u-mrg--tx2"
                name="companyColor"
                presetColors={[]}
            />
        );

        if (!hasCompanyBranding) {
            return (
                <UpgradeToUnlockTooltip>
                    <div className={!hasCompanyBranding && classes.colorPickerWrapper}>
                        {picker}
                    </div>
                </UpgradeToUnlockTooltip>
            );
        }

        return picker;
    };

    render() {
        const {
            company, classes,
            flashMessage,
            updateCompany
        } = this.props;
        const {
            form, dialog_open,
            openChangeCompanySlugModal, timezones, languages
        } = this.state;
        if (isEmptyObject(company)) {
            return null;
        }
        const { location, slug, companyImageUrl, companyColor, candidateFeedbackEnabled } = company;

        const companyUrl = getBaseUrlForRedirect(slug);
        const hasLocalization = checkHasCompanyFeature(company, 'CANDIDATE_APP_LANGUAGE');
        const hasCompanyBranding = checkHasCompanyFeature(company, 'COMPANY_BRANDING');

        return (
            <Layout>
                <PausedLabel />
                <SettingsHeader />
                <PageWrapper
                    headerLabel="Company Settings"
                    className="u-z--1  u-pos--relative"
                >
                    <ContentWrapper>
                        <div className={classes.row}>
                            <div className={classes.column}>
                                <TextInput
                                    label="Company Name"
                                    fullWidth
                                    name="name"
                                    className="u-mrg--bx4"
                                    variant="outlined"
                                    value={this.renderValue('name')}
                                    onChange={this.handleChange}
                                    isError={Boolean(this.renderError('name'))}
                                    helperText={this.renderError('name')}
                                />
                                <AddressAutocomplete
                                    onChange={this.handleChangeAutoComplete}
                                    label="Headquarters Location"
                                    name="location"
                                    placeholder=""
                                    className="u-mrg--bx4"
                                    value={location}
                                    variant="outlined"
                                    googleAutocomplete={window.google}
                                />
                                <TextInput
                                    label="Website"
                                    fullWidth
                                    className="u-mrg--bx4"
                                    value={this.renderValue('website')}
                                    name="website"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    isError={Boolean(this.renderError('website'))}
                                    helperText={this.renderError('website')}
                                />
                                <Select
                                    value={form.size === null ? '' : form.size}
                                    name="size"
                                    className="u-mrg--bx4"
                                    onChange={this.changeSize}
                                    label="Company Size"
                                    fullWidth
                                    variant="outlined"
                                    options={sizeOptions}
                                />
                                <Select
                                    value={form.timezone}
                                    onChange={this.changeTimezone}
                                    label="Company Timezone"
                                    fullWidth
                                    className="u-mrg--bx4"
                                    variant="outlined"
                                    maxHeight={200}
                                    name="timezone"
                                    options={(
                                        timezones.map(item => (
                                            { value: item, label: item }
                                        ))
                                    )}
                                />
                                <div className="u-mrg--bx4 u-mrg--tx3">
                                    <PhoneInputWithCountries
                                        value={this.renderValue('phone')}
                                        messageClassname={classes.phoneHelperText}
                                        isValid={isPhoneValid(this.renderValue('phone'))}
                                        name="phone"
                                        onChange={this.handleChange}
                                        placeholder="Phone number (optional)"
                                    />
                                </div>
                                <TextInput
                                    maxLength={500}
                                    label="About us"
                                    multiline
                                    variant="outlined"
                                    className="u-mrg--bx4"
                                    fullWidth
                                    hasCounter
                                    value={this.renderValue('description')}
                                    onChange={this.handleChange}
                                    name="description"
                                    isError={Boolean(this.renderError('description'))}
                                    helperText={this.renderError('description')}
                                />
                                { hasLocalization && (
                                    <>
                                        <Typography variant="h6" className="u-mrg--bx2">
                                            Candidate display language
                                        </Typography>
                                        <Select
                                            value={form.candidateAppLanguage}
                                            onChange={this.handleChangeLanguage}
                                            label="Display Language"
                                            fullWidth
                                            className="u-mrg--bx4"
                                            variant="outlined"
                                            maxHeight={200}
                                            name="candidateAppLanguage"
                                            options={(
                                                Object.keys(languages).map(key => ({ label: languages[key], value: key }))
                                            )}
                                        />
                                    </>
                                )}
                                <CandidatesTags />
                                {
                                    !candidateFeedbackEnabled && (
                                        <FeedbackAgreement
                                            company={company}
                                            updateCompany={updateCompany}
                                            textStyle={classes.feedbackAgreementText}
                                        />
                                    )
                                }
                            </div>
                            <div className={classes.column}>
                                <div className={classes.subHeader}>
                                    Company Logo
                                    <InfoTooltip
                                        isBlack
                                        placement="right"
                                        text={<>Logo size will be reduced proportionally to fit the 40px height&nbsp;
                                            <b>in the candidate experience.</b>&nbsp;
                                            Logo should not include padding or coloured background.
                                        </>}
                                    />
                                </div>
                                <div className="c-user-popover__header o-mediablock u-dsp--f u-ai--end">
                                    <UploadCompanyPhoto
                                        hasCompanyBranding={hasCompanyBranding}
                                        company={company}
                                        updateCompany={updateCompany}
                                        flashMessage={flashMessage}
                                        isForm
                                        setCompanyColor={this.setCompanyColor}
                                        openDialog={this.handleOpen}
                                    />
                                    {(companyColor !== '#1247F6' || !!companyImageUrl.length) && (
                                        <Button
                                            color="primary"
                                            className="u-txt--bold u-mrg--lx3"
                                            onClick={this.resetCompanyBranding}
                                        >
                                            Reset company branding
                                        </Button>
                                    )}
                                </div>
                                <div className={classes.subHeader}>Company URL</div>
                                <div className={`u-dsp--f u-jc--start u-ai--center ${classes.companyLink}`}>
                                    {companyUrl}&nbsp;
                                    {
                                        checkHasCompanyFeature(company, 'CHANGE_COMPANY_SLUG') && (
                                            <Button
                                                color="primary"
                                                className="u-txt--bold"
                                                onClick={this.changeCompanySlugModal}
                                            >
                                                (change)
                                            </Button>
                                        )
                                    }
                                </div>
                                <div className={classes.subHeader}>Pick your brand colour:</div>
                                { this.renderValue('companyColor') && this.returnColorPicker() }

                                <Button
                                    className={classes.submitButton}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={this.submitForm}
                                    disabled={!isPhoneValid(this.renderValue('phone'))}
                                >
                                    Update settings
                                </Button>
                            </div>
                        </div>
                    </ContentWrapper>
                </PageWrapper>
                <BrandColorDialog
                    onClose={this.handleClose}
                    open={dialog_open}
                />
                <ChangeSlugDialog
                    open={openChangeCompanySlugModal}
                    oldSlug={slug}
                    flashMessage={flashMessage}
                    onClose={this.changeCompanySlugModal}
                    company={company}
                />
            </Layout>
        );
    }
}

export default withStyles(styles)(SettingsCompanyAccount);

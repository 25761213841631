import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextInput from 'libraries/TextInput';
import AddIcon from 'img/add.svg';
import { addCompanyTag } from 'requests/SettingsRequests';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import SettingsEvents from 'events/SettingsEvents';
import Tooltip from 'libraries/Tooltip';
import AddGrayIcon from './add_gray.svg';

import ActionDialog from 'components/dialogs/ActionDialog';

const useStyles = makeStyles(theme => ({
    button: {
        fontWeight: 700,
        marginLeft: 5,
        '& img': {
            marginRight: 5
        },
        '&:disabled': {
            opacity: 1,
            color: theme.palette.grey[400]
        }
    },
    buttonWrapper: {
        display: 'inline-block'
    },
    tooltipWrapper: {
        maxWidth: 195
    }
}));

const CreateButton = observer(({ addNewTag, tagsLength }) => {
    const [isLoading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [createDialogOpened, setCreateDialogOpened] = useState(false);
    const { button, buttonWrapper, tooltipWrapper } = useStyles();
    const { flashMessage, company } = useContext(appCtx);
    const { companySettings } = company;
    let isDisabledButton = false;

    if (companySettings) {
        const { tags } = companySettings;
        isDisabledButton = tags !== -1 && tagsLength >= tags;
    }

    const handleDialog = () => {
        const newValue = !createDialogOpened;
        if (!newValue) {
            setValue('');
        }
        setCreateDialogOpened(newValue);
    };

    const handleChange = (e) => {
        const { value: eValue } = e.target;
        setValue(eValue);
    };

    const onAddTag = () => {
        setLoading(true);
        addCompanyTag({ title: value.toLowerCase() })
            .then(({ success, data }) => {
                if (success && data) {
                    const { id, title } = data;
                    addNewTag(data);
                    handleDialog();
                    SettingsEvents.CANDIDATE_TAG_ADDED({ tagId: id, tagName: title, context: 'Company account page' });
                    flashMessage(`${value} Tag created`, 'done');
                }
            })
            .catch(({ response }) => {
                if (response.data && response.data.errors) {
                    const { errors } = response.data;
                    if (typeof errors === 'object') {
                        const { title } = response.data.errors;
                        flashMessage(title[0].message);
                    } else {
                        flashMessage(errors);
                    }
                }
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {
                isDisabledButton ? (
                    <Tooltip
                        label="Maximum tag limit reached. Delete tags to create new."
                        tooltipClass={tooltipWrapper}
                    >
                        <div className={buttonWrapper}>
                            <Button
                                color="primary"
                                className={button}
                                onClick={handleDialog}
                                disabled
                            >
                                <img src={AddGrayIcon} alt="+" /> Create new tag
                            </Button>
                        </div>
                    </Tooltip>
                ) : (
                    <Button
                        color="primary"
                        className={button}
                        onClick={handleDialog}
                    >
                        <img src={AddIcon} alt="+" /> Create new tag
                    </Button>
                )
            }
            <ActionDialog
                open={createDialogOpened}
                onClose={handleDialog}
                buttonLabel="Add"
                onClick={onAddTag}
                titleComponent="Create new candidate tag"
                disabledButton={!value.trim().length}
                isLoading={isLoading}
            >
                <TextInput
                    placeholder="Tag name"
                    value={value}
                    maxLength={150}
                    variant="outlined"
                    onChange={handleChange}
                />
            </ActionDialog>
        </>
    );
});

export default CreateButton;

import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import SearchField from 'components/inputs/SearchField';
import QuestionWrapper from 'components/marketplace/Preview/common/QuestionWrapper';
import LoaderCalibration from 'components/loaders/LoaderCalibration';
import { appCtx } from 'components/appStore';


import { assessmentSettingsCtx } from 'pages/assessment/store';
import { assessmentEditCtx } from '../../../../../store';

import NoResultsBanner from '../../../common/NoResultsBanner';
import QuestionDisplayHeader from '../QuestionDisplayHeader';
import styles from './styles';


let timedId = null;

const CommonContent = observer(({
    classes, question, isScreening, questionsList,
    setQuestionsList, query, setQuery,
    loadingQuestions, setLoadingQuestions,
    loading, loadingAddButton, setLoadingAddButton,
    onSearch, replaceQuestion,
    removeQuestion, SearchComp = null, LoadMoreComp = null, onReplace,
    emptyBannerHeader, emptyBannerText,
    disabledSearch = false, questionGroupName
}) => {
    const [searchFocused, setSearchFocused] = useState(false);
    const { flashMessage } = useContext(appCtx);
    const { audition, loadEditScript } = useContext(assessmentSettingsCtx);
    const {
        assessmentHasTypingTest, updateQuestion,
        activeQuestionIndex, questions: assessmentQuestions,
        setActiveTab, selectQuestion
    } = useContext(assessmentEditCtx);

    const deleteQuestionFromBank = (questionId) => {
        removeQuestion(questionId, isScreening)
            .then((response) => {
                if (response.status === 204) {
                    flashMessage('Question deleted', 'done');
                    setQuestionsList(questionsList.filter(questionItem => questionItem.id !== questionId));
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    const onChangeSearch = (e) => {
        setLoadingQuestions(true);
        const { value } = e.target;
        setQuery(value);
        clearTimeout(timedId);
        timedId = setTimeout(() => { onSearch(value); }, 1000);
    };

    const replaceFunc = newQuestion => (replaceQuestion(newQuestion.id, question.id)
        .then((response) => {
            const { data, success } = response;
            if (data && success) {
                if (onReplace) onReplace(data);
                return response;
            }
        }));

    const header = <h1 className={classes.title}>Saved {isScreening ? 'Screening ' : ''}Questions</h1>;

    if (loading) {
        return (
            <div className={classes.wrapper}>
                {header}
                <LoaderCalibration
                    className="u-mrg--tx20"
                    widthOuter={100}
                />
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            {header}
            <div className={clsx(classes.searchBlock, isScreening && 'u-jc--end')}>
                {SearchComp}
                <SearchField
                    className={clsx(classes.searchField, searchFocused && classes.searchFieldFocused)}
                    placeholder="Search"
                    onChange={onChangeSearch}
                    disabled={disabledSearch}
                    value={query}
                    onFocus={() => { setSearchFocused(true); }}
                    onBlur={() => { setSearchFocused(false); }}
                />
            </div>
            <div className={classes.previewContentWrapper}>
                {Boolean(!loadingQuestions && questionsList.length && query) && (
                    <div className={classes.resultsLine}>
                        <span>{questionsList.length}</span> Results for <span>{`'${query}'`}</span>
                    </div>
                )}
                {!loadingQuestions && questionsList.length === 0 && (
                    <NoResultsBanner
                        header={emptyBannerHeader}
                        text={emptyBannerText}
                    />
                )}
                {!loadingQuestions && questionsList.length !== 0 && (
                    <QuestionWrapper
                        QuestionHeaderComp={QuestionDisplayHeader}
                        questions={questionsList}
                        questionGroupName={questionGroupName}
                        deleteQuestionFromBank={deleteQuestionFromBank}
                        activeQuestion={question}
                        interview={audition}
                        setActiveTab={setActiveTab}
                        replaceFunc={replaceFunc}
                        assessmentQuestions={assessmentQuestions}
                        activeQuestionIndex={activeQuestionIndex}
                        selectQuestion={selectQuestion}
                        updateQuestion={updateQuestion}
                        loading={loadingAddButton}
                        setLoading={setLoadingAddButton}
                        assessmentHasTypingTest={assessmentHasTypingTest}
                        onReplace={loadEditScript}
                        isScreening={isScreening}
                    />
                )}
                {LoadMoreComp}
                {loadingQuestions && (
                    <div className="u-txt--center u-mrg--bx2">
                        <CircularProgress
                            size={22}
                            thickness={3}
                        />
                    </div>
                )}
            </div>
        </div>
    );
});

export default withStyles(styles)(CommonContent);

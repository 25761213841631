import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Dialog from 'libraries/Dialog';
import { appCtx } from '../../appStore';

const SmsPreviewDialog = observer(({ open, handleClose }) => {
    const { company: { name, slug, candidateAppLanguage } } = useContext(appCtx);
    const candidateAppUrl = process.env.REACT_APP_CANDIDATE_BASE_URL;

    return (
        <Dialog
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            titleComponent="SMS Invitation"
        >
            {candidateAppLanguage === 'es'
                ? <>Gracias por su interés en <b>{name}</b>. Inicia sesión a través del siguiente enlace para continuar el proceso </>
                : <>Thank you for your interest in <b>{name}</b>. Login via link below to continue the process </>
            }
            <b>https://{slug}.{candidateAppUrl}</b>
        </Dialog>
    );
});

export default SmsPreviewDialog;

export default theme => ({
    currentPlanLabel: {
        fontSize: 16,
        marginBottom: 8,
        color: theme.palette.grey[900],
        fontWeight: 700
    },
    planName: {
        fontWeight: 700,
        color: theme.palette.blue[500],
        marginTop: -2
    },
    infoLabel: {
        display: 'inline-block',
        marginRight: 24,
        fontWeight: 600,
        color: theme.palette.grey[900],
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    },
    infoValue: {
        fontWeight: 700
    },
    actionWrapper: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        paddingTop: 26,
        marginTop: 30
    },
    creditCard: {
        marginBottom: 0,
        fontWeight: 600,
        color: theme.palette.grey[900]
    },
    actionButtonsWrapper: {
        marginTop: 7,
        marginLeft: -8
    },
    currentPlanHeader: {
        backgroundColor: theme.palette.blue[500],
        padding: '30px 32px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 30
    },
    currentPlanText: {
        color: 'white',
        fontSize: 20
    },
    currentPlan: {
        color: 'white',
        fontSize: 40,
        fontWeight: 700
    },
    changePlanButton: {
        color: theme.palette.blue[800],
        fontSize: 14,
        background: 'white',
        borderRadius: 4,
        width: 360,
        padding: 9,
        fontWeight: 700,
        marginRight: 90,
        marginBottom: 12
    },
    outdatedText: {
        color: 'white',
        maxWidth: 340,
        fontSize: 16,
        marginLeft: 10
    }
});

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { subscriptionDialogStoreCtx } from '../../store';
import styles from './styles';

const ProductPricingList = observer(({ classes }) => {
    const { selectedPlan, selectedPrice, selectPrice } = useContext(subscriptionDialogStoreCtx);
    if (!selectedPlan) return null;

    const { stripeProducts } = selectedPlan;

    const { name: planName } = selectedPlan;
    switch (planName.toLowerCase()) {
        default:
            break;
    }

    return (
        <div className={clsx('u-txt--nowrap', classes.productPricingWrapper)}>
            {stripeProducts.map((product) => {
                const { stripePrices } = product;
                return stripePrices.sort((price1, price2) => price1.order - price2.order).map((priceObj) => {
                    const { id, name, currency, price, discount } = priceObj;
                    const isSelected = selectedPrice ? selectedPrice.id === id : false;
                    return (
                        <div
                            key={id}
                            onClick={() => selectPrice(priceObj)}
                            role="presentation"
                            className={clsx(classes.item, isSelected && classes.itemSelected)}
                        >
                            <FormControlLabel
                                classes={{ label: 'u-txt--bold u-txt--22' }}
                                control={<Radio disableRipple checked={isSelected} />}
                                label={name.toUpperCase()}
                            />
                            <div className={classes.priceWrapper}>
                                <span className={classes.price}>{currency === 'usd' ? '$' : `${currency} `}
                                    {new Intl.NumberFormat('en-US').format(price / 100)}
                                </span>
                                <span className={classes.discountLabel}>{discount}</span>
                            </div>
                        </div>
                    );
                });
            })}
        </div>
    );
});

export default withStyles(styles)(ProductPricingList);

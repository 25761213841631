export default theme => ({
    wrapper: {
        position: 'relative',
        width: '100%',
        marginBottom: 30
    },
    scrollbars: {
        marginTop: 10,
        maxHeight: 420,
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            maxHeight: 400,
            display: 'flex',
            overflowX: 'auto',
            padding: '10px 21px'
        },
        '& > div': {
            maxWidth: '100%',
            marginBottom: 20,
            [theme.breakpoints.down('md')]: {
                marginRight: 20,
                marginBottom: 0
            }
        }
    }
});

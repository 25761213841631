import React from 'react';
import { observer } from 'mobx-react-lite';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCellHead from 'components/table_components/table_cell_head';
import TableBody from '@mui/material/TableBody';
import Table from 'components/table_components/table';
import TableCell from 'components/table_components/table_cell';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import moment from 'moment';
import Tooltip from 'libraries/Tooltip';
import { formatTimeFromNow, formatTimestamp } from 'helper/commonFunctions';

const SuccessfulTable = observer(({ data, hasData, getAuditionStatus }) => (
    <Table className="u-mrg--bx0">
        <TableHead>
            <TableRow>
                <TableCellHead className="u-pdn--lx3" label="Assessment" hideSort />
                <TableCellHead label="Invited" hideSort align="center" />
                <TableCellHead label="Started" hideSort align="center" />
                <TableCellHead label="Screened" hideSort align="center" />
                <TableCellHead label="Completed" hideSort align="center" />
                <TableCellHead label="Hired" hideSort align="center" />
                <TableCellHead label="CSAT Score" hideSort align="center" />
                <TableCellHead label="Last Completion" hideSort align="center" extraPadding={false} />
            </TableRow>
        </TableHead>
        <TableBody>
            {
                hasData && data?.items.map((audition) => {
                    const { name, invited, started, screened, completed, hired, csatScore, lastCompletion, id } = audition;
                    return (
                        <TableRow key={id}>
                            <TableCell align="left" className="u-dsp--f">
                                <TruncateWithTooltip text={name} width={250} />
                                &nbsp;{getAuditionStatus(audition)}
                            </TableCell>
                            <TableCell align="center">{invited}</TableCell>
                            <TableCell align="center">{started}</TableCell>
                            <TableCell align="center">{screened}</TableCell>
                            <TableCell align="center">{completed}</TableCell>
                            <TableCell align="center">{hired}</TableCell>
                            <TableCell align="center">{csatScore || '-'}</TableCell>
                            <TableCell align="center">
                                {
                                    lastCompletion ? (
                                        <Tooltip placement="top" label={formatTimestamp(lastCompletion)}>
                                            <div>
                                                {formatTimeFromNow(moment(lastCompletion))}
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <span>-</span>
                                    )
                                }
                            </TableCell>
                        </TableRow>
                    );
                })
            }
        </TableBody>
    </Table>
));

export default SuccessfulTable;

export default theme => ({
    formControlLabel: {
        fontSize: 14,
        color: 'rgb(58, 60, 67)'
    },
    expansionPanel: {
        boxShadow: 'none',
        marginBottom: 0,
        '&:before': {
            display: 'none'
        }
    },
    expansionPanelSummary: {
        width: 205,
        padding: 0,
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: 0.8,
        color: '#3a3c43',
        textTransform: 'uppercase'
    },
    expansionPanelDetails: {
        paddingLeft: 10,
        paddingRight: 10
    },
    expandIcon: {
        color: theme.palette.primary.main
    },
    checkboxLabel: {
        fontSize: 14,
        color: '#3a3c43'
    },
    alertIcon: {
        marginRight: 5
    },
    actionButton: {
        fontWeight: 'bold',
        textTransform: 'none'
    },
    talkToUsLabel: {
        textDecoration: 'underline',
        marginTop: 12,
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: 0.2,
        color: theme.palette.primary.main
    },
    hereLabel: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        textDecoration: 'underline',
        '&:hover, &:active, &:focus': {
            color: theme.palette.primary.main
        }
    },
    exportSelect: {
        width: 280,
        marginTop: 10,
        maxWidth: 'calc(100% - 70px)'
    }
});

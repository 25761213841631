import React from 'react';
import { observer } from 'mobx-react-lite';
import SimulatorTypeContent from '../components/SimulatorTypeContent';
import SimulatorTicket from './SimulatorTicket';


const SimulatorTicketContent = observer(({
    question, fetchQuestion, setSaving, isCMS
}) => (
    <SimulatorTypeContent
        ItemComp={SimulatorTicket}
        entity="ticket"
        {...{
            question, fetchQuestion, setSaving, isCMS
        }}
    />
));

export default SimulatorTicketContent;

export default theme => ({
    fileWrapper: {
        height: 70,
        backgroundColor: '#f0f0f0',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        '& img': {
            marginRight: 14
        }
    },
    customButton: {
        width: 200,
        minWidth: 200,
        height: 40,
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 4,
        alignItems: 'center',
        boxShadow: '0 3px 15px 0 rgba(144, 144, 144, 0.17)',
        background: 'white',
        transition: 'all 0.3s',
        '&:hover': {
            backgroundColor: '#EBEBEB',
            color: theme.palette.primary.main
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    fileName: {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
});

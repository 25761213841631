import React from 'react';
import { secondsToTimeObject, timeObjectToFormattedString } from 'helper/commonFunctions';
import RequiredQuestionLabel from 'components/job_or_assessment_settings/Chip';
import TimerIcon from 'img/timer.svg';

import useStyles from './styles';

const QuestionHeader = ({ question }) => {
    if (!question) return null;
    const { timerEnabled, timeLimit, required } = question;
    const classes = useStyles();
    return (
        <>
            { required && <RequiredQuestionLabel uppercase /> }
            {
                timerEnabled && timeLimit && (
                    <div className={classes.timerLabel}>
                        <img src={TimerIcon} className={classes.timerIcon} alt="timer" />
                        { timeObjectToFormattedString(secondsToTimeObject(timeLimit * 60)) }
                    </div>
                )
            }
        </>
    );
};

export default QuestionHeader;

import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { checkHasCompanyFeature, checkHasUserPermission } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';

import styles from './styles';

const HoverButton = observer(({
    audition = {}, handleDeactivateDialog, classes,
    onActivate, disabled
}) => {
    const { company } = useContext(appCtx);
    const [hovered, handleHovered] = useState(false);

    const { numberOfCompletions, permissions } = audition;

    const canDeactivateAssessment = checkHasCompanyFeature(company, 'OPPORTUNITY_DEACTIVATION');
    const edit = checkHasUserPermission(company, permissions, 'edit');

    const setHovered = (value) => {
        if (disabled) return;
        handleHovered(value);
    };

    if (audition.archived) {
        return (
            <span
                className={clsx(classes.label, classes.inactiveLabel)}
            >
                Archived
            </span>
        );
    }

    if (audition.active) {
        const hasHover = hovered && !disabled && !(!canDeactivateAssessment && numberOfCompletions);
        return (
            <>
                {
                    edit ? (
                        <span
                            role="presentation"
                            className={clsx(classes.label, hasHover ? classes.hoveredLabel : classes.activeLabel)}
                            onClick={hasHover ? handleDeactivateDialog : undefined}
                            onMouseOver={() => { setHovered(true); }}
                            onFocus={() => { setHovered(true); }}
                            onMouseLeave={() => { setHovered(false); }}
                        >
                            {hasHover ? 'Close' : 'Open'}
                        </span>
                    ) : (
                        <span
                            className={clsx(classes.label, classes.activeLabel)}
                        >
                            Open
                        </span>
                    )
                }
            </>
        );
    }

    return (
        <>
            {
                edit ? (
                    <span
                        role="presentation"
                        className={clsx(classes.label, hovered ? classes.hoveredLabel : classes.inactiveLabel)}
                        onClick={!disabled ? onActivate : undefined}
                        onMouseOver={() => { setHovered(true); }}
                        onFocus={() => { setHovered(true); }}
                        onMouseLeave={() => { setHovered(false); }}
                    >
                        {hovered ? 'Open' : 'Closed'}
                    </span>
                ) : (
                    <span
                        className={clsx(classes.label, classes.inactiveLabel)}
                    >
                        Closed
                    </span>
                )}
        </>
    );
});

export default withRouter(withStyles(styles)(HoverButton));

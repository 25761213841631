import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteCandidates } from 'requests/CandidatesRequests';
import { BATCH_STEP } from 'helper/constants';
import { checkPluralNew } from 'helper/commonFunctions';
import AssessmentEvents from 'events/AssessmentEvents';
import SnackbarProgress from 'libraries/SnackbarProgress';
import { appCtx } from '../../appStore';
import DeleteResultsDialog from './DeleteResultsDialog';

const { CancelToken } = axios;
let source = CancelToken.source();

const styles = () => ({
    loader: {
        color: '#fff'
    }
});

const DeleteCandidateDialog = observer(({
    classes, open, onClose, callback,
    columnUserIds, audition
}) => {
    const [loading, setLoading] = useState(false);
    const [showFailed, setShowFailed] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [error, setError] = useState(false);
    const [currentStepState, setCurrentStepState] = useState(0);
    const [stepsNeededState, setStepsNeededState] = useState(1);
    const [failedList, setFailedList] = useState([]);
    const { flashMessage } = useContext(appCtx);
    const { uuid: ttId, name: ttName } = audition;

    useEffect(() => () => {
        onCancel();
    }, []);

    const setInitialState = () => {
        setCurrentStepState(0);
        setStepsNeededState(1);
        setCanceled(false);
    };

    const deleteCandidatesFunc = (sentData, currentStep = 1, stepsNeeded, failedListProp) => {
        const nextStartIndex = (currentStep - 1) * BATCH_STEP;
        const nextLastIndex = nextStartIndex + BATCH_STEP;
        source = CancelToken.source();
        return deleteCandidates(sentData.slice(nextStartIndex, nextLastIndex), { cancelToken: source.token })
            .then(({ success, data }) => {
                if (success) {
                    const { failedEmails: newFailedEmails } = data;
                    const updatedFailedList = [...failedListProp, ...newFailedEmails];

                    setCurrentStepState(currentStep);

                    if (currentStep === stepsNeeded) {
                        setLoading(false);
                        setInitialState();
                        if (open) onClose();

                        if (updatedFailedList?.length !== columnUserIds.length) {
                            if (columnUserIds.length === 1) {
                                flashMessage('Candidate deleted from the assessment', 'done');
                            } else {
                                flashMessage(`${columnUserIds.length - updatedFailedList.length} ${checkPluralNew(columnUserIds.length - updatedFailedList.length, 'candidate')} deleted from the assessment`, 'done');
                            }
                        }
                        if (updatedFailedList.length) {
                            setShowFailed(true);
                            setFailedList(updatedFailedList);
                        } else if (callback) callback();
                    } else if (currentStep < stepsNeeded && !canceled) {
                        deleteCandidatesFunc(sentData, currentStep + 1, stepsNeeded, updatedFailedList);
                    }
                }
            })
            .catch((err) => {
                if ((window.navigator && !window.navigator.onLine) || (err.response && err.response.status.toString()[0] === '5')) {
                    setLoading(false);
                    setInitialState();
                    flashMessage('Something went wrong', 'error');
                    return;
                }

                if (axios.isCancel(err)) {
                    setLoading(false);
                    setInitialState();
                    flashMessage(`${currentStep * BATCH_STEP - failedListProp.length} ${checkPluralNew(columnUserIds.length - failedListProp.length, 'candidate')} deleted from the assessment`, 'done');
                    if (failedListProp.length) {
                        setShowFailed(true);
                        setFailedList(failedListProp);
                    }
                    return;
                }

                if (!err.response) {
                    flashMessage('Something went wrong', 'error');
                    return;
                }

                const errValue = err.response.data.errors;
                if (errValue) {
                    if (typeof errValue === 'string') {
                        setError(errValue || 'Something went wrong');
                    }
                }

                setLoading(false);
                setInitialState();
            });
    };

    const onSubmit = () => {
        setLoading(true);
        const stepsNeeded = Math.ceil(columnUserIds.length / BATCH_STEP);
        setStepsNeededState(stepsNeeded);
        setFailedList([]);

        if (columnUserIds.length > 1) {
            AssessmentEvents.CANDIDATES_BATCH_ACTIONED({
                actionType: 'delete',
                numberCandidatesActioned: columnUserIds.length,
                ttId,
                ttName
            });
            onClose();
        }

        deleteCandidatesFunc(columnUserIds, 1, stepsNeeded, []);
    };

    const onCancel = () => {
        setCanceled(true);
        source.cancel();
        setLoading(false);
    };

    const header = columnUserIds.length > 1 ? `Delete ${columnUserIds.length} Candidates` : 'Delete Candidate';

    return (
        <>
            <SnackbarProgress
                open={loading && stepsNeededState > 1}
                showSuccessIcon
                onCancel={onCancel}
                message={`Deleting ${columnUserIds.length} ${checkPluralNew(columnUserIds.length, 'candidate')}`}
                percents={Math.ceil(currentStepState / stepsNeededState * 100)}
                error={error}
                canceled={canceled}
                errorLabel={error}
            />
            <Dialog
                maxWidth="sm"
                titleComponent={header}
                open={open}
                onClose={onClose}
                handleClose={onClose}
                actionComponent={
                <>
                    <Button
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        onClick={onClose}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={loading}
                        className="u-pdn--lx8 u-pdn--rx8"
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : header
                        }
                    </Button>
                </>
                }
            >
                Do you want to delete the selected {checkPluralNew(columnUserIds.length, 'candidate')}? Once deleted, they can’t be restored.
            </Dialog>
            <DeleteResultsDialog
                open={showFailed}
                failed={failedList}
                onClose={() => {
                    setShowFailed(false);
                    if (callback) callback();
                }}
            />
        </>
    );
});

export default withStyles(styles)(DeleteCandidateDialog);

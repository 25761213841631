import React from 'react';


const QASimulation = ({ color = '#A9AAAE', size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <g clipPath="url(#clip0_317_2336)">
            <path
                d="M8.98047 0.5H10.0195C10.3456 0.620234 10.496 0.86763 10.5361 1.20013C10.5495 1.30997 10.5945 1.41784 10.597 1.52719C10.6049 1.85227 10.7558 2.04227
            11.0754 2.12044C11.1729 2.14419 11.2407 2.15904 11.3273 2.09867C11.5559 1.94034 11.7884 1.78844 12.0239 1.64049C12.2619 1.49107 12.5296 1.47128 12.732
            1.65979C13.0585 1.96458 13.4766 2.21 13.5543 2.71964C13.6305 2.71964 13.6978 2.71964 13.7656 2.71964C14.7359 2.71964 15.7062 2.71865 16.677 2.72063C16.8061
            2.72063 16.9382 2.72063 17.0644 2.74437C17.8541 2.8938 18.3682 3.53703 18.3682 4.3757C18.3691 8.0718 18.3682 11.7679 18.3682 15.464C18.3682 15.5417 18.3682
            15.6194 18.3682 15.6985C18.714 15.7242 18.8936 15.9167 19.0005 16.1973V17.5703C18.9876 17.6104 18.9718 17.6495 18.9629 17.6901C18.7813 18.5124 18.2994 19.077
            17.5107 19.3743C17.3687 19.4278 17.2178 19.4589 17.0708 19.5H1.92969C1.88961 19.4871 1.85052 19.4713 1.80995 19.4624C1.0544 19.2981 0.505677 18.8706 0.188516
            18.1641C0.103906 17.976 0.061849 17.7687 0 17.5703C0 17.1126 0 16.6549 0 16.1973C0.105885 15.9068 0.299349 15.7213 0.632344 15.6941C0.632344 15.6099 0.632344
            15.5372 0.632344 15.464C0.632344 11.7738 0.632344 8.08367 0.632344 4.39352C0.632344 3.39008 1.3013 2.72161 2.30573 2.72013C3.28839 2.71865 4.27154 2.72013
            5.25419 2.72013C5.31951 2.72013 5.38531 2.72013 5.44419 2.72013C5.50983 2.47966 5.63188 2.2702 5.81034 2.09174C5.91969 1.9824 6.02805 1.87255 6.13838
            1.76419C6.41547 1.49206 6.70096 1.46039 7.02456 1.66969C7.24227 1.8107 7.45997 1.95221 7.67075 2.10362C7.75932 2.16745 7.82711 2.14766 7.9231 2.12341C8.24026
            2.04326 8.39018 1.85128 8.39958 1.52719C8.40305 1.4119 8.45154 1.29859 8.46638 1.18232C8.50794 0.854766 8.66628 0.61974 8.98096 0.500495L8.98047 0.5ZM17.7353
            15.6901C17.7353 15.6045 17.7353 15.5318 17.7353 15.4585C17.7353 11.7911 17.7353 8.12326 17.7353 4.45586C17.7353 4.38807 17.7373 4.31979 17.7328 4.25201C17.7002
            3.76018 17.3162 3.36188 16.8353 3.35792C15.6666 3.34802 14.4974 3.35247 13.3287 3.35693C13.2738 3.35693 13.206 3.39997 13.1659 3.44352C12.9452 3.68151 12.8478
            3.93534 13.0511 4.24458C13.1055 4.32721 13.1397 4.38708 13.2466 4.40589C13.5261 4.45536 13.8042 4.51523 14.0803 4.58253C14.3574 4.65031 14.538 4.84971 14.5548
            5.13075C14.5716 5.42021 14.5716 5.71214 14.5548 6.00159C14.5385 6.28411 14.3593 6.48104 14.0828 6.5513C13.9036 6.59682 13.7216 6.64779 13.5385 6.66115C13.2292
            6.68341 13.0293 6.80661 12.9527 7.12477C12.9259 7.2351 12.9096 7.31328 12.9799 7.41224C13.1367 7.63391 13.2857 7.8625 13.4272 8.09406C13.5924 8.36422 13.5637
            8.6507 13.3495 8.8788C13.1763 9.06385 12.9962 9.24297 12.8121 9.41714C12.585 9.63089 12.2941 9.65909 12.0274 9.4963C11.8745 9.40279 11.7157 9.31372 11.5816
            9.19646C11.348 8.9926 11.1219 8.94362 10.8399 9.10789C10.731 9.17122 10.6736 9.22961 10.6519 9.34787C10.6073 9.59081 10.5549 9.83227 10.5029 10.0737C10.4238
            10.4414 10.1982 10.6284 9.82805 10.6318C9.60539 10.6338 9.38273 10.6343 9.16008 10.6318C8.80086 10.6274 8.57227 10.4369 8.49656 10.0821C8.46292 9.92529 8.41195
            9.76844 8.40404 9.6101C8.38721 9.28948 8.25214 9.0881 7.92706 9.01438C7.83206 8.9926 7.76328 8.97083 7.67471 9.03318C7.45255 9.18953 7.22396 9.33797 6.99338
            9.48146C6.69898 9.66503 6.41299 9.63385 6.1651 9.39536C5.99588 9.23258 5.82914 9.06682 5.66635 8.89711C5.42935 8.64971 5.40214 8.36125 5.58669 8.06784C5.67526
            7.92682 5.75591 7.77591 5.86674 7.6537C6.08297 7.41471 6.12503 7.17919 5.95135 6.89469C5.89445 6.80117 5.85091 6.74378 5.74057 6.72497C5.49714 6.68391 5.25568
            6.62948 5.01422 6.57852C4.62581 6.49638 4.44125 6.2757 4.43333 5.87987C4.42937 5.68195 4.43086 5.48404 4.43333 5.28612C4.43779 4.84872 4.62432 4.63052 5.05677
            4.54839C5.29922 4.50237 5.54167 4.45487 5.78312 4.40094C5.83062 4.39055 5.89099 4.35987 5.91128 4.32078C6.04883 4.05458 6.17401 3.78393 5.89049 3.52664C5.88604
            3.52268 5.88208 3.51724 5.87961 3.5118C5.82518 3.3782 5.72622 3.34901 5.5862 3.35C4.49766 3.35594 3.40911 3.35297 2.32057 3.35346C1.64518 3.35346 1.26568 3.73495
            1.26568 4.41232C1.26568 8.10445 1.26568 11.7971 1.26568 15.4892C1.26568 15.5545 1.26568 15.6198 1.26568 15.6906H17.7368L17.7353 15.6901ZM12.9838 16.3452C12.9838
            16.4724 12.9873 16.5768 12.9833 16.6817C12.9576 17.3521 12.441 17.9003 11.7825 17.9063C10.2615 17.9196 8.74049 17.9177 7.2195 17.9068C6.63367 17.9023 6.14729
             17.4644 6.04487 16.885C6.01419 16.7114 6.01419 16.5317 5.99984 16.3497H0.64125C0.64125 16.7262 0.609089 17.0933 0.647682 17.4526C0.739219 18.3016 1.41164
             18.8677 2.29237 18.8677C7.09086 18.8686 11.8889 18.8677 16.6873 18.8677C16.7428 18.8677 16.7987 18.8677 16.8541 18.8652C17.5067 18.8345 18.0866 18.3991 18.2845
             17.7801C18.4345 17.3105 18.3444 16.8276 18.3691 16.3452H12.9838ZM12.8923 8.42557C12.7191 8.16581 12.5519 7.91346 12.3836 7.66211C12.2416 7.44984 12.2199 7.22917
             12.3238 6.99414C12.3886 6.84768 12.449 6.69875 12.5078 6.54982C12.5964 6.32667 12.7508 6.1812 12.9888 6.13221C13.2243 6.08372 13.4593 6.02831 13.6973
              5.99318C13.87 5.96794 13.963 5.90906 13.9358 5.71411C13.918 5.58695 13.9284 5.45484 13.9333 5.32521C13.9373 5.22922 13.9076 5.1837 13.8042 5.16539C13.5246
              5.11641 13.2471 5.05604 12.9695 4.99568C12.7468 4.94719 12.5989 4.80815 12.5128 4.60034C12.4559 4.46378 12.4109 4.32128 12.3446 4.18966C12.208 3.91802 12.2367
               3.66914 12.4094 3.42372C12.5756 3.18771 12.731 2.94378 12.8725 2.73151C12.6889 2.53755 12.5212 2.36091 12.35 2.17982C12.1412 2.31935 11.922 2.44849 11.7221
                2.60237C11.4242 2.83146 11.1229 2.86956 10.7924 2.68302C10.7073 2.63503 10.6108 2.60633 10.5178 2.57318C10.256 2.48016 10.1017 2.30104 10.0512 2.02495C9.99677
                 1.72906 9.93146 1.43466 9.87109 1.13977H9.12594C9.06458 1.44109 9.00125 1.73648 8.94534 2.03336C8.89487 2.30055 8.74445 2.47719 8.49062 2.5707C8.37484
                  2.61326 8.25708 2.65432 8.14773 2.70974C7.8662 2.85323 7.60544 2.83344 7.34716 2.64937C7.11263 2.48214 6.8682 2.32875 6.65841 2.18971C6.46445 2.3718
                   6.2893 2.53557 6.10672 2.70677C6.24773 2.91854 6.37687 3.13724 6.53026 3.33664C6.76331 3.63995 6.74599 3.92594 6.62328 4.28565C6.44911 4.79578 6.14284
                    5.04516 5.62083 5.08573C5.44023 5.09958 5.18839 5.11047 5.10477 5.22378C5.01174 5.34995 5.0726 5.58992 5.06667 5.78091C5.06667 5.79328 5.06172 5.80961
                     5.06766 5.81753C5.10575 5.86651 5.13841 5.94221 5.1874 5.95656C5.35909 6.00555 5.53523 6.04711 5.71237 6.06591C6.1562 6.11292 6.47237 6.30341 6.57331
                      6.76753C6.58221 6.80859 6.60547 6.84768 6.62625 6.88529C6.8019 7.19552 6.7643 7.4825 6.55351 7.76552C6.39271 7.98125 6.25219 8.21232 6.11513
                       8.41766C6.30117 8.60964 6.46742 8.78133 6.6401 8.95945C6.82466 8.83625 7.0206 8.7274 7.1918 8.58737C7.53766 8.30435 7.89193 8.24646 8.28281
                       8.48445C8.34516 8.52206 8.41987 8.53938 8.48964 8.56461C8.74445 8.65615 8.89437 8.83378 8.94534 9.10047C9.00174 9.39734 9.06508 9.69273 9.12594
                       9.99307H9.87159C9.93195 9.69768 9.99726 9.40328 10.0517 9.1069C10.1022 8.8313 10.256 8.65219 10.5178 8.55818C10.6395 8.51464 10.7607 8.46714 10.877
                       8.41073C11.1402 8.28258 11.3866 8.2999 11.6286 8.46763C11.8715 8.63635 12.1199 8.79667 12.3549 8.95302C12.536 8.7749 12.7072 8.60667 12.8918
                       8.42508L12.8923 8.42557ZM6.66633 16.3432C6.56836 16.8761 6.8019 17.3011 7.46245 17.2888C8.85182 17.2626 10.2417 17.2695 11.6311 17.2873C12.0719
                       17.2932 12.4732 16.9652 12.3213 16.3432H6.66633Z"
                fill={color}
            />
            <path d="M4.74649 15.0667C3.17108 15.0637 1.89154 13.7783 1.89748 12.2043C1.90342 10.6314 3.19037 9.36127 4.77074 9.36819C6.33428 9.37512 7.60886 10.6621 7.60194 12.2281C7.59501 13.7921 6.30954 15.0702 4.74649 15.0667ZM4.74996 14.4314C5.9716 14.4304 6.97256 13.4285 6.9691 12.2103C6.96563 10.9906 5.96269 9.99658 4.73957 10C3.5298 10.0035 2.53329 11.001 2.53032 12.2123C2.52735 13.4309 3.52881 14.4324 4.74946 14.4314H4.74996Z" fill={color} />
            <path d="M14.2465 15.0667C12.6721 15.0623 11.3911 13.7743 11.398 12.2029C11.4044 10.6304 12.6919 9.36077 14.2727 9.3682C15.8358 9.37562 17.1089 10.6631 17.1019 12.2296C17.095 13.7946 15.81 15.0712 14.2465 15.0667ZM14.2222 14.4309C15.4315 14.4522 16.4399 13.4769 16.4686 12.2583C16.4973 11.054 15.5072 10.0208 14.3059 10.001C13.0684 9.98075 12.0521 10.9565 12.0308 12.1846C12.01 13.4012 12.9937 14.4096 14.2222 14.4314V14.4309Z" fill={color} />
            <path d="M4.28372 16.9712C4.4381 16.9712 4.59297 16.9662 4.74734 16.9722C4.93289 16.9791 5.0556 17.0998 5.06203 17.2695C5.06895 17.4397 4.95515 17.5887 4.77505 17.5956C4.44205 17.6085 4.10757 17.608 3.77458 17.5956C3.59596 17.5887 3.48067 17.4387 3.4876 17.27C3.49453 17.0988 3.61526 16.9801 3.80229 16.9731C3.9626 16.9672 4.12341 16.9717 4.28422 16.9717L4.28372 16.9712Z" fill={color} />
            <path d="M2.21677 17.2795C2.21925 17.4493 2.06982 17.6007 1.90011 17.6007C1.72693 17.6007 1.5874 17.4562 1.58938 17.279C1.59136 17.1059 1.71852 16.9767 1.8912 16.9723C2.06735 16.9678 2.2143 17.1069 2.21677 17.2795Z" fill={color} />
            <path d="M3.16072 17.2834C3.16072 17.4596 3.01723 17.6036 2.84504 17.6006C2.67533 17.5976 2.52838 17.4437 2.53332 17.275C2.53827 17.1093 2.67681 16.9747 2.84455 16.9722C3.02465 16.9697 3.16072 17.1038 3.16072 17.2834Z" fill={color} />
            <path d="M12.0295 5.56559C12.03 6.96684 10.8934 8.10536 9.49662 8.10239C8.09933 8.09942 6.96774 6.95942 6.97071 5.55718C6.97417 4.16434 8.10279 3.03424 9.49464 3.03028C10.8919 3.02682 12.029 4.16335 12.0295 5.5651V5.56559ZM11.3966 5.55916C11.3941 4.51218 10.5332 3.6557 9.49316 3.6651C8.4437 3.67401 7.60107 4.52554 7.60404 5.5745C7.60701 6.62049 8.46646 7.47599 9.508 7.46906C10.556 7.46213 11.3996 6.60911 11.3971 5.55966L11.3966 5.55916Z" fill={color} />
            <path d="M4.10429 12.7373C4.64856 12.1915 5.16315 11.676 5.67724 11.1604C5.72523 11.1124 5.77125 11.0614 5.82468 11.0204C5.94888 10.9249 6.12205 10.9353 6.2314 11.0392C6.34471 11.1465 6.36401 11.3098 6.27346 11.4434C6.23932 11.4939 6.19429 11.5379 6.15075 11.5815C5.57432 12.1584 4.99789 12.7348 4.42096 13.3108C4.18742 13.5438 4.04591 13.5428 3.81187 13.3103C3.64117 13.1401 3.46948 12.9713 3.30125 12.7986C3.13994 12.6334 3.13005 12.4429 3.27156 12.3098C3.40664 12.1821 3.58625 12.1975 3.74705 12.3514C3.8094 12.4112 3.87125 12.4716 3.93013 12.5349C3.98455 12.5938 4.03453 12.6566 4.1038 12.7378L4.10429 12.7373Z" fill={color} />
            <path d="M14.6846 12.2133C14.9013 12.4261 15.0963 12.6151 15.2887 12.8061C15.5386 13.054 15.5762 13.2306 15.4149 13.3875C15.2551 13.5428 15.0923 13.5087 14.8484 13.2603C14.6544 13.0629 14.4644 12.862 14.2601 12.6492C14.0072 12.9036 13.7806 13.1376 13.5466 13.3647C13.368 13.5384 13.1394 13.5176 13.029 13.3222C12.9395 13.1628 12.9894 13.0263 13.1121 12.9056C13.3407 12.6809 13.5683 12.4553 13.8123 12.2148C13.5614 11.9669 13.3234 11.7354 13.0894 11.4998C12.9766 11.3865 12.9518 11.2515 13.0335 11.1109C13.1116 10.9773 13.2393 10.9298 13.3833 10.9709C13.4555 10.9917 13.5248 11.0461 13.5797 11.101C13.7984 11.3178 14.0107 11.5409 14.2502 11.7863C14.4817 11.5478 14.693 11.3252 14.9112 11.1095C14.9706 11.0506 15.0443 10.9917 15.122 10.9699C15.266 10.9294 15.3926 10.9818 15.4683 11.1169C15.548 11.2599 15.5178 11.393 15.405 11.5053C15.173 11.7359 14.9379 11.964 14.6836 12.2133H14.6846Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_317_2336">
                <rect width="19" height="19" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>

);

export default QASimulation;

const DRAWER_HEIGHT = 315;

const styles = theme => ({
    root: {
        display: 'flex'
    },
    headerVisiblePanel: {
        position: 'absolute',
        width: 'calc(100% - 320px)',
        height: '80px',
        top: '-80px',
        left: '0',
        backgroundColor: '#2f3545',
        overflow: 'visible',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '5px -11px 18px -4px rgba(0, 0, 0, 0.1) !important'
    },
    headerVisiblePanelOpen: {
        overflow: 'hidden'
    },
    headerContent: {
        padding: '0 20px',
        backgroundColor: '#2f3545',
        height: 315,
        width: 'calc(100% - 300px)'
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20
    },
    hide: {
        display: 'none'
    },
    drawer: {
        height: DRAWER_HEIGHT,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        height: DRAWER_HEIGHT,
        transition: theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        height: 0,
        [theme.breakpoints.up('sm')]: {
            height: 0
        }
    },
    drawerPaper: {
        overflow: 'visible',
        backgroundColor: '#2f3545',
        width: 'calc(100% - 200px)',
        left: 520
    },
    drawerHeader: {
        maxHeight: '60vh',
        overflow: 'auto',
        padding: '0',
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        overflowY: 'auto',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    }
});

export default styles;

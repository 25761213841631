import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    wrapper: {
        position: 'relative',
        backgroundColor: 'white',
        width: '100%',
        paddingTop: 30,
        fontSize: 14,
        marginBottom: 15
    },
    contentWrapper: {
        maxWidth: 700
    },
    loaderWrapper: {
        marginTop: 70,
        textAlign: 'center'
    },
    loaderBlock: {
        margin: 0
    }
}));

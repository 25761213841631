import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import Tooltip from 'libraries/Tooltip';
import {
    createCompanyQuestionFromSuggested,
    createCompanyQuestionFromScreeningSuggested
} from 'requests/QuestionsRequests';
import SaveIcon from 'components/icons/SaveIcon';
import { checkHasCompanyFeature, capitalizeEachFirstLetter } from 'helper/commonFunctions';
import AssessmentEvents from 'events/AssessmentEvents';
import { appCtx } from 'components/appStore';

import { QA_SIMULATOR, SALES_SIMULATOR, MARKETING_SIMULATOR } from 'helper/constants';

import styles from './styles';


const AddToBankFromSuggestedIcon = observer(({
    question, classes, loading,
    setLoading, segmentData, questionGroupName,
    isScreening
}) => {
    const { flashMessage, company } = useContext(appCtx);
    if (!question) return null;

    const hasQuestionBanks = checkHasCompanyFeature(company, 'QUESTION_BANKS');
    const { uuid, answerType, id } = question;

    if (!hasQuestionBanks || (!isScreening && !questionGroupName)) return null;

    const addQuestionToBankFromSuggested = () => {
        setLoading(true);
        AssessmentEvents.QUESTION_SAVED(segmentData);
        createCompanyQuestionFromSuggested(uuid, questionGroupName)
            .then(({ data, success }) => {
                if (data && success) {
                    const { companyQuestionCategory } = data;
                    if (companyQuestionCategory) {
                        const { title } = companyQuestionCategory;
                        flashMessage(`Question added to ${capitalizeEachFirstLetter(title)}`, 'done');
                    }
                }
            })
            .catch(() => {
                flashMessage('Something went wrong.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const addQuestionToBankFromScreeningSuggested = () => {
        setLoading(true);
        AssessmentEvents.SCREENER_QUESTION_SAVED(segmentData);
        createCompanyQuestionFromScreeningSuggested(id)
            .then(({ data, success }) => {
                if (data && success) {
                    flashMessage('Question added to Screening Saved', 'done');
                }
            })
            .catch(() => {
                flashMessage('Something went wrong.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if ([SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR].includes(answerType)) return null;

    return (
        <Tooltip
            label="Add to Saved Questions"
        >
            <div className={classes.iconWrapper}>
                {loading
                    ? <CircularProgress size={25} />
                    : (
                        <IconButton
                            onClick={
                                isScreening
                                    ? addQuestionToBankFromScreeningSuggested
                                    : addQuestionToBankFromSuggested
                            }
                            disabled={loading}
                            size="large"
                        >
                            <SaveIcon />
                        </IconButton>
                    )}
            </div>
        </Tooltip>
    );
});

export default withStyles(styles)(AddToBankFromSuggestedIcon);

import React, { useContext, useState, useEffect } from 'react';

import { withStyles } from '@mui/styles';

import Loader from 'components/loaders/Loader';
import { TEXT } from 'helper/constants';

import { changeScreeningQuestionParams } from 'requests/QuestionsRequests';
import {
    deleteScreeningFile, deleteScreeningZiggeoFile,
    uploadScreeningAuditionFile, saveScreeningZiggeo
} from 'requests/ScriptRequests';
import { appCtx } from 'components/appStore';
import { modifyScreeningQuestion } from 'helper/questionSettingsFunctions';

import DescriptionFieldTextField from '../QuestionType/DescriptionFieldTextField';
import AnswerAttachment from '../AnswerAttachment';
import MultipleChoiceAnswerType from './MultipleChoiceAnswerType';

import styles from '../../styles';

const ALLOW_SYMBOLS = 25000;
let timerId = null;

const loaderStyles = {
    zIndex: '1000',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255, .3)'
};

const LABEL = 'Write your question here...';


const ScreeningContent = ({ // can be also used in CMS
    questionId, deleteQuestion,
    savingQuestion, activeQuestionIndex,
    questions = [], loading,
    setSaving, QuestionHeaderComp,
    handlePreviewMode, totalQuestionsNumber,
    fetchQuestion, updateQuestion, question, setQuestion,
    onUpdateQuestion
}) => {
    const [value, setValue] = useState('');
    const [file, setFile] = useState(null);
    const [type, setType] = useState(null);
    const [ziggeo, setZiggeo] = useState(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const { flashMessage } = useContext(appCtx);

    const {
        id, ziggeo: questionZiggeo,
        file: questionFile, text
    } = question;


    useEffect(() => {
        setValue(text || '');
        setType(modifyScreeningQuestion(question).type);
        setFile(questionFile);
        setZiggeo(questionZiggeo);
    }, [id]);

    const handleValue = (e) => {
        const { value: targetValue } = e.target;
        setValue(targetValue);
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            changeQuestion({ text: targetValue });
        }, 300);
    };


    const handleChangeType = (e, newType) => {
        if (newType === TEXT && ziggeo) {
            deleteFile(true);
        } else if (newType === TEXT && file) {
            deleteFile();
        } else {
            setType(+newType);
        }
    };

    const deleteFile = (av = false) => {
        const func = av ? deleteScreeningZiggeoFile : deleteScreeningFile;
        func(id)
            .then((response) => {
                if (response.status === 204) {
                    if (av) {
                        setZiggeo(null);
                    } else {
                        setFile(null);
                    }
                    setType(null);
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    const onDrop = (files) => {
        const formData = new FormData();
        if (!files.length) {
            flashMessage('The file should not be empty and its size should be 10MB or less', 'error');
            return;
        }
        files.forEach((f, index) => formData.append(index, f));
        formData.append('type', type);
        setIsUploadingFile(true);

        uploadScreeningAuditionFile(id, formData)
            .then(({ success, data }) => {
                if (success && data) {
                    const { fileName, fileUrl } = data;
                    setFile({ fileName, fileUrl });
                    fetchQuestion();
                }
            })
            .catch(({ response }) => {
                if (response) {
                    const { msg } = response.data;
                    if (msg) {
                        flashMessage(msg, 'error');
                    } else {
                        flashMessage('Something went wrong', 'error');
                    }
                }
            })
            .finally(() => {
                setIsUploadingFile(false);
            });
    };

    const handleChangeToken = (token) => {
        saveScreeningZiggeo(id, { token, type });
        setZiggeo({ token });
    };

    const changeQuestion = (data) => {
        setSaving(true);
        return changeScreeningQuestionParams(questionId, data)
            .then(({ success, data: questionData }) => {
                if (success && questionData) {
                    setQuestion(questionData);
                    if (updateQuestion) updateQuestion(questionData);
                    onUpdateQuestion(questionData);
                }
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
                flashMessage('Something went wrong', 'error');
            });
    };

    return (
        <>
            {
                QuestionHeaderComp && (
                    <QuestionHeaderComp
                        question={question}
                        questions={questions}
                        savingQuestion={savingQuestion}
                        activeQuestion={activeQuestionIndex}
                        deleteQuestion={deleteQuestion}
                        handlePreviewMode={handlePreviewMode}
                        totalQuestionsNumber={totalQuestionsNumber}
                        isScreening
                    />
                )
            }
            {
                question && !loading && (
                    <div>
                        {
                            type === 1 || !type ? (
                                <DescriptionFieldTextField
                                    {...{
                                        question,
                                        value,
                                        label: LABEL,
                                        handleValue,
                                        handleChangeType,
                                        allowedLength: ALLOW_SYMBOLS
                                    }}
                                />
                            ) : (
                                <AnswerAttachment
                                    file={file}
                                    id={question.id}
                                    ziggeo={ziggeo}
                                    type={type}
                                    onDrop={onDrop}
                                    label={LABEL}
                                    isUploadingFile={isUploadingFile}
                                    handleChangeToken={handleChangeToken}
                                    onChange={handleValue}
                                    handleChangeType={handleChangeType}
                                    value={value}
                                />
                            )
                        }
                        <MultipleChoiceAnswerType
                            fetchQuestion={fetchQuestion}
                            question={question}
                            setSaving={setSaving}
                            isScreening
                            changeQuestion={changeQuestion}
                        />
                    </div>
                )
            }
            <Loader
                variant="indeterminate"
                show={savingQuestion}
                thickness={3}
                style={loaderStyles}
            />
        </>
    );
};

export default withStyles(styles)(ScreeningContent);

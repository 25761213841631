import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import RequiredQuestionLabel from 'components/job_or_assessment_settings/Chip';
import QuestionCompletedInLabel from 'components/candidate_cards/QuestionCompletedInLabel';

import Photo from 'components/candidate_cards/CardEmployerPhoto';
import QuestionNumberLabel from 'components/candidate_cards/QuestionNumberLabel';
import QuestionSkillLabel from 'components/candidate_cards/QuestionSkillLabel';
import Tooltip from 'libraries/Tooltip';
import { AUTOGRADED_ANSWER_TYPES } from 'helper/constants';

import GradingLabel from './GradingLabel';
import AIIcon from './aiIcon.svg';

import stylesJS from './styles';

class QuestionResultHeader extends React.Component {
    renderEmployerScored = () => {
        const { question: { questionResultGrades }, classes } = this.props;
        let restOfEmployers = 0;
        let employerInvolved = false;
        if (questionResultGrades && questionResultGrades.length) {
            const keys = Object.keys(questionResultGrades);
            const userKeys = keys.splice(0, 4);
            restOfEmployers = keys.length;
            employerInvolved = userKeys.map(key1 => (
                <Photo
                    key={key1}
                    size={32}
                    user={questionResultGrades[key1].user}
                />
            ));
        }
        if (!employerInvolved) return null;
        return (
            <div className="c-card__judges">
                {employerInvolved}
                {
                    restOfEmployers > 0 && (
                        <div className={`c-card-big__judge ${classes.employersWrapper}`}>
                            +{restOfEmployers}
                        </div>
                    )
                }
            </div>
        );
    };

    render() {
        const {
            grade, classes,
            question: { question: questionObj, manualGradeMissing },
            isTask, question,
            currentQuestion,
            className = '', hideScore, currentTask
        } = this.props;
        const { willBeGraded, answerType } = questionObj;

        const isAutogradedTask = currentTask && AUTOGRADED_ANSWER_TYPES.includes(currentTask.type) && willBeGraded;
        const isAutograded = AUTOGRADED_ANSWER_TYPES.includes(answerType) && willBeGraded;

        return (
            <div className="c-card-big">
                <div className={`c-grade-card__header ${className}`}>
                    <div className="u-fxexpand1  u-mrg--rx2">
                        <div className="u-dsp--f u-ai--center u-mrg--bx1">
                            <QuestionNumberLabel
                                isTask={isTask}
                                question={questionObj}
                                questionNumber={currentQuestion}
                            />
                            {
                                questionObj?.candidateQuestionGroup && (
                                    <QuestionSkillLabel
                                        className="u-mrg--rx3"
                                        skill={questionObj.candidateQuestionGroup.title}
                                    />
                                )
                            }
                            { questionObj?.required && <RequiredQuestionLabel uppercase /> }
                        </div>
                        <div className="u-dsp--f u-ai--center">
                            <QuestionCompletedInLabel question={question} />
                            <GradingLabel question={{ ...questionObj, manualGradeMissing }} currentTask={currentTask} />
                        </div>
                    </div>
                    {
                        (!questionObj || questionObj.willBeGraded) && (
                            <div className="c-card__score-box  c-card__score-box--v2">
                                {
                                    (question?.aiScoreSection && !question?.questionResultGrades.length && !question?.manualGradeMissing && !(isAutograded || isAutogradedTask)) && (
                                        <Tooltip
                                            label={(
                                                <a
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{ color: '#1247F6' }}
                                                    href="https://help.vervoe.com/hc/en-us/articles/4407259075732-How-does-Vervoe-s-AI-feature-work"
                                                >
                                                    How does Vervoe’s AI feature work?
                                                </a>
                                            )}
                                        >
                                            <img src={AIIcon} alt="AI" />
                                        </Tooltip>
                                    )
                                }
                                {
                                    !hideScore && (
                                        <>
                                            { this.renderEmployerScored() }
                                            {
                                                grade !== null && grade !== undefined && (
                                                    <div className={classes.scoreWrapper}>
                                                        <span className="u-mrg--lx1">&nbsp;score&nbsp;</span>
                                                        <span className={classes.userGrade}>{grade}</span>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default withTheme(withStyles(stylesJS)(QuestionResultHeader));

import React from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import useStyles from './styles';

const OnboardingDialog = observer(({ children, open, onClose }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            BackdropComponent={null}
            BackdropProps={{
                className: classes.backdrop
            }}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialogPaper
            }}
        >
            <div className={classes.contentWrapper}>{children}</div>
            <Button
                variant="contained"
                className={classes.letsGoButton}
                onClick={onClose}
            >
                Let's go
            </Button>
        </Dialog>
    );
});

export default withRouter(OnboardingDialog);

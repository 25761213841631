import React from 'react';
import { withStyles } from '@mui/styles';
import AnswerContent from 'components/grade_components/AnswerContent';

import Transcript from 'components/candidate_cards/Transcript';
import styles from './styles';

const CandidateAnswer = ({ classes, question, questionNumber }) => {
    const { question: questionContent, ziggeo } = question;

    return (
        <>
            <div className={classes.header}>Candidate answer</div>
            <div className={classes.answerWrapper}>
                <AnswerContent
                    question={question}
                    questionIndex={questionNumber}
                    isAiHelth
                />
            </div>
            {
                questionContent && ziggeo?.transcription && (
                    <Transcript value={ziggeo.transcription} />
                )
            }
        </>
    );
};

export default withStyles(styles)(CandidateAnswer);

import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import useStyles from './styles';
import QuestionItem from './QuestionItem';

const AnswersContent = observer(({ candidatesIdsToCompare, getAnswersContent, propPublic, auditionSlug, audition }) => {
    const classes = useStyles({ compareNumber: Object.keys(candidatesIdsToCompare).length });
    const [isLoading, setIsLoading] = useState(false);
    const [questionsList, setQuestionsList] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        getAnswersContent()
            .then(({ success, data }) => {
                if (success && data) {
                    setQuestionsList(data);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <div className={classes.wrapper}>
                <CircularProgress
                    size={15}
                    className={classes.loader}
                    color="primary"
                />
            </div>
        );
    }

    if (!questionsList) return null;

    return (
        <div className={classes.wrapper}>
            {
                questionsList.map((question, index) => (
                    <QuestionItem
                        key={question.id}
                        questionIndex={index}
                        question={question}
                        auditionSlug={auditionSlug}
                        candidatesIdsToCompare={candidatesIdsToCompare}
                        audition={audition}
                        propPublic={propPublic}
                    />
                ))
            }
        </div>
    );
});

export default withRouter(AnswersContent);

import React from 'react';
import { withTheme } from '@mui/styles';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import FilterIcon from 'components/icons/FilterIcon';

import CustomMenuItem from './table_filter_item';

import useStyles from './styles';


const TableFilter = ({
    theme, isFilterActive = false, children,
    options = [], innerHeader, handleChange,
    showCountNumber = false, isCheckbox, filterCount,
    disabled
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderMenuItems = menuList => (
        menuList.map(({ value, label = value, checked, count }) => (
            <CustomMenuItem
                key={value}
                value={value}
                label={label}
                count={count}
                checked={checked}
                isCheckbox={isCheckbox}
                showCountNumber={showCountNumber}
                handleChange={handleChange}
            />
        ))
    );

    return (
        <>
            <div
                role="presentation"
                className={clsx(classes.root, (open || isFilterActive) && classes.rootActive, disabled && classes.rootDisabled)}
                onClick={!disabled ? handleClick : undefined}
            >
                <FilterIcon color={(open || isFilterActive) && theme.palette.primary.main} /> Filter
                {
                    Boolean(filterCount) && (
                        <div className={classes.filterCount}>{filterCount}</div>
                    )
                }
            </div>
            <Popover
                anchorEl={anchorEl}
                keepMounted
                open={open}
                classes={{ root: classes.popover, paper: classes.menu }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                { innerHeader && <Typography variant="h6" className={classes.filterHeader}>{innerHeader}</Typography> }
                { renderMenuItems(options) }
                { children }
            </Popover>
        </>
    );
};

export default withTheme(TableFilter);

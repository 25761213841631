import React from 'react';
import { withStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import CheckboxMaterial from '@mui/material/Checkbox';

import clsx from 'clsx';
import stylesJS from './styles';

const Checkbox = ({ classes, className, checked, onChange, classNameLabel = '', styles = {}, value = '', label, name, helperText, disableRipple, ...other }) => (
    <>
        <FormControlLabel
            control={(
                <CheckboxMaterial
                    checked={checked}
                    onChange={onChange}
                    value={value.toString()}
                    name={name}
                    disableRipple={disableRipple || !onChange}
                    classes={{
                        root: clsx(classes.root, !onChange && classes.notActive),
                        checked: classes.checked,
                        disabled: classes.disabled,
                    }}
                />
            )}
            className={className}
            classes={{
                root: clsx(classes.formLabelRoot, styles.root || '', !onChange && classes.notActive),
                label: clsx(classes.label, classNameLabel, styles.label || '')
            }}
            label={label || ' '}
            {...other}
        />
        { helperText && <FormHelperText className={classes.formHelperText}>{helperText}</FormHelperText> }
    </>
);

export default withStyles(stylesJS)(Checkbox);

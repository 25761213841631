import React from 'react';


const SimulationIcon = ({ color = '#A9AAAE', size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M11.5003 1.9165L21.0837 8.14567V14.854L11.5003 21.0832L1.91699 14.854V8.14567L11.5003 1.9165Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.5 21.0832V14.854" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.0837 8.146L11.5003 14.8543L1.91699 8.146" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.91699 14.8543L11.5003 8.146L21.0837 14.8543" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.5 1.9165V8.14567" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default SimulationIcon;

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    tableContainer: {
        paddingBottom: 24,
        position: 'relative',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    table: {
        background: theme.palette.grey[300],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    tableHead: {
        border: `1px solid ${theme.palette.grey[300]}`
    },
    tableBody: {
        position: 'relative',
        background: '#ffffff'
    },
    tableCell: {
        backgroundColor: theme.palette.grey[300],
        color: '#3A3C43',
        fontWeight: 700,
        borderRadius: '4px 4px 0px 0px',
        fontSize: 14
    },
    tableCellHead: {
        height: 54,
        padding: '7px 16px',
        borderColor: theme.palette.grey[300],
        backgroundColor: `${theme.palette.grey[300]}!important`
    },
    tableCellName: {
        cursor: 'pointer',
        position: 'relative',
        paddingLeft: '15.5px!important'
    },
    checkbox: {
        marginRight: 13
    },
    loadingWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        paddingTop: 5
    },
    linearProgressRoot: {
        height: 6,
        backgroundColor: theme.palette.grey[300]
    },
    loaderWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.5)'
    }
}));

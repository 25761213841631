import React, { useContext } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Layout from 'components/layout';
import InnerHeader from 'components/layout/InnerHeader';
import SearchField from 'components/inputs/SearchField';
import PageWrapper from 'components/layout/PageWrapper';

import InnerContentWrapper from '../../assessment/components/InnerContentWrapper';

import ContentAssessments from './content_assessments';
import ContentLibraryTabs from '../common/ContentLibraryTabs';


import styles from './styles';
import { assessmentListCtx } from './content_assessments/AssessmentList/store';

const ContentLibrary = observer(({ classes }) => {
    const { query, setQuery, setActiveFolderId } = useContext(assessmentListCtx);

    let header = 'Content Library';

    if (query) {
        header = `Results for '${query}'`;
    }

    const onChange = (e) => {
        const newValue = e.target.value;
        setQuery(newValue);
        setActiveFolderId(null);
    };

    return (
        <Layout>
            <InnerHeader
                header={header}
                className={classes.wrapper}
                rightPart={(
                    <SearchField
                        placeholder="Search"
                        onChange={onChange}
                        className={clsx(classes.searchField)}
                        value={query}
                    />
                )}
            />

            <PageWrapper className="u-pdn--bx5">
                <InnerContentWrapper
                    className={classes.contentWrapper}
                    contentClassName="u-pdn--x0"
                >
                    <ContentLibraryTabs />
                    <div className={classes.content}>
                        <ContentAssessments />
                    </div>
                </InnerContentWrapper>
            </PageWrapper>
        </Layout>
    );
});

export default withRouter(withStyles(styles)(ContentLibrary));

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import SearchField from 'components/inputs/SearchField';
import SelectOutlined from 'libraries/Select';
import { getListOfSkills } from 'requests/CMSRequests';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
    loader: {
        background: 'rgba(255, 255, 255, 0.7)',
        position: 'absolute',
        left: 0,
        zIndex: 1,
        top: 75,
        width: '100%',
        height: 'calc(100% - 75px)'
    },
    skillItem: {
        maxWidth: 500
    }
};

const SkillsInput = observer(({
    classes, className = '', selectedSkill,
    setSelectedSkill, hideAllSkills, ...other
}) => {
    const [skillSearchValue, setSkillSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [skillsList, setSkillsList] = useState([]);

    useEffect(() => {
        setLoading(true);
        getListOfSkills(skillSearchValue)
            .then(({ success, data }) => {
                if (success && data) {
                    setSkillsList(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [skillSearchValue, selectedSkill && selectedSkill.title]);

    const onOpen = () => {
        getListOfSkills(skillSearchValue)
            .then(({ success, data }) => {
                if (success && data) {
                    setSkillsList(data);
                }
            });
    };

    const onClose = () => {
        setSkillSearchValue('');
    };

    const stopEvents = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleChangeSearch = (e) => {
        e.stopPropagation();
        const { value } = e.target;
        setSkillSearchValue(value);
    };

    const handleChangeSelectedSkill = (e) => {
        stopEvents(e);
        const { value } = e.target;
        if (value === '-') {
            setSelectedSkill(null);
        } else if (value === -1) {
            setSelectedSkill(-1);
        } else {
            setSelectedSkill(skillsList.find(({ id }) => value === id));
        }
    };

    const filteredSkills = skillsList.map(({ id, title }) => ({
        label: title,
        value: id,
        className: classes.skillItem
    }));

    const noSkillsOption = [{ label: 'No Skill', value: -1 }];

    if (!hideAllSkills) {
        noSkillsOption.unshift({ label: 'All Skills', value: '-' });
    }

    if (selectedSkill && selectedSkill !== -1 && !filteredSkills.some(({ value }) => value === selectedSkill.id)) {
        noSkillsOption.push({ label: selectedSkill.title, value: selectedSkill.id, className: classes.skillItem });
    }
    const options = noSkillsOption.concat(filteredSkills);

    return (
        <SelectOutlined
            variant="outlined"
            value={(selectedSkill && selectedSkill.id) || selectedSkill || '-'}
            onChange={handleChangeSelectedSkill}
            label="Skills"
            onOpen={onOpen}
            className={className}
            onClose={onClose}
            options={options}
            {...other}
        >
            <div
                role="presentation"
                onClick={stopEvents}
                className="u-pdn--x2 u-txt--center"
            >
                <SearchField
                    placeholder="Search Skills"
                    className="u-mrg--x0"
                    value={skillSearchValue}
                    onChange={handleChangeSearch}
                    onClick={stopEvents}
                    onKeyDown={e => e.stopPropagation()}
                />
            </div>
            {
                loading && (
                    <div className={clsx(classes.loader, 'u-dsp--centered')}>
                        <CircularProgress size={24} />
                    </div>
                )
            }
        </SelectOutlined>
    );
});

export default withStyles(styles)(SkillsInput);

import React from 'react';
import { withTheme } from '@mui/styles';

const ShieldIcon = ({ theme, color = theme.palette.grey[600], size= 24, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M15 4L12 3L4 5.85V12.5C4 18.2 12 22 12 22C12 22 20 18.2 20 12.5V11" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 11.6375L11.5385 14L20 5" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(ShieldIcon);

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import { purchaseProduct, updateCustomerSubscription } from 'requests/SubscriptionRequests';
import { appCtx } from 'components/appStore';
import { subscriptionDialogStoreCtx } from 'pages/settings/subscriptions/store';
import Loader from 'components/loaders/Loader';
import CardDetails from 'components/subscription/CardDetailsBlock';
import PlanEvents from 'events/PlanEvents';
import PaymentReceipt from '../../PaymentReceiptBlock';
import ConfirmWrapper from './ConfirmWrapper';

import styles from './styles';
import ThankModal from './ThankModal';


const PurchaseDialog = observer(({
    onClose,
    subscriptionPlan,
    classes,
    getCompanyPlan,
    open, cards,
    defaultCouponString
}) => {
    const [disableGetVervoe, setDisableGetVervoe] = useState(false);
    const [appliedCoupon, setAppliedCoupon] = useState(false);
    const [thankModalOpen, setThankModalOpen] = useState(false);

    const { flashMessage, updateCompany } = useContext(appCtx);
    const { isSelectedCurrent, selectedPrice } = useContext(subscriptionDialogStoreCtx);

    const closeDialog = () => {
        onClose();
        setDisableGetVervoe(false);
        setAppliedCoupon(false);
    };

    const handleThankModal = () => {
        setThankModalOpen(!thankModalOpen);
    };

    const purchaseProductHandler = (internalPlanId, stripePriceId) => {
        purchaseProduct(internalPlanId, stripePriceId, appliedCoupon ? appliedCoupon.id : '')
            .then(({ success, data }) => {
                if (success) {
                    updateCompany(data);
                    getCompanyPlan();
                    handleThankModal();
                    closeDialog();
                    // Reset coupon applied
                    setDisableGetVervoe(false);
                    setAppliedCoupon(false);
                } else {
                    PlanEvents.PLAN_UPGRADE_FAILED({ place: 'plan upgrade' });
                    closeDialog();
                }
            })
            .catch(({ response: { data } }) => {
                PlanEvents.PLAN_UPGRADE_FAILED({ place: 'plan upgrade failed' });
                closeDialog();
                if (data.errors) {
                    flashMessage(data.errors.message);
                } else {
                    flashMessage('Something went wrong.');
                }
            });
    };

    const subscribeSubscription = () => {
        setDisableGetVervoe(true);
        if (!subscriptionPlan) return;
        const { period, id: internalPlanId } = subscriptionPlan;

        if (!selectedPrice) {
            updateCustomerSubscription(internalPlanId, period, appliedCoupon ? appliedCoupon.id : '')
                .then(({
                    success,
                    data
                }) => {
                    if (success) {
                        updateCompany(data);
                        getCompanyPlan();
                        handleThankModal();
                        closeDialog();
                        // Reset coupon applied
                        setDisableGetVervoe(false);
                        setAppliedCoupon(false);
                    } else {
                        PlanEvents.PLAN_UPGRADE_FAILED({ place: 'plan upgrade' });
                        closeDialog();
                    }
                })
                .catch(({ response: { data } }) => {
                    PlanEvents.PLAN_UPGRADE_FAILED({ place: 'plan upgrade failed' });
                    closeDialog();
                    if (data.errors) {
                        flashMessage(data.errors.message);
                    } else {
                        flashMessage('Something went wrong.');
                    }
                });
        } else {
            purchaseProductHandler(internalPlanId, selectedPrice.id);
        }
    };

    return (
        <>
            <Dialog
                contentClassName="u-pdn--x0 u-mrg--tx0"
                handleClose={closeDialog}
                onClose={closeDialog}
                open={open}
            >
                <Loader show={disableGetVervoe} />
                <div className={classes.wrapper}>
                    <PaymentReceipt
                        subscriptionPlan={subscriptionPlan}
                        appliedCoupon={appliedCoupon}
                        applyCoupon={setAppliedCoupon}
                        defaultCouponString={defaultCouponString}
                        productPrice={selectedPrice}
                    />
                    {
                        subscriptionPlan && (
                            <div className={classes.cardDetails}>
                                {
                                    !cards || !cards.length ? (
                                        <CardDetails
                                            updateCompany={updateCompany}
                                            onUpdateCreditCard={subscribeSubscription}
                                            disabledProceed={disableGetVervoe}
                                        />
                                    ) : (
                                        <ConfirmWrapper
                                            onClose={onClose}
                                            disableGetVervoe={disableGetVervoe}
                                            subscriptionPlan={subscriptionPlan}
                                            subscribeSubscription={subscribeSubscription}
                                            productPrice={selectedPrice}
                                            isCurrentPlanSelected={isSelectedCurrent}
                                        />
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </Dialog>
            <ThankModal
                open={thankModalOpen}
                handleThankModal={handleThankModal}
            />
        </>
    );
});

export default withStyles(styles)(PurchaseDialog);

export default theme => ({
    message: {
        fontSize: 12,
        display: 'flex',
        lineHeight: 'normal',
        fontWeight: 600,
        color: theme.palette.red.primary,
        '& a': {
            textDecoration: 'underline',
            color: theme.palette.red.primary
        },
        '& img': {
            marginRight: 12,
            height: 16,
            marginTop: 3
        }
    }
});

import React, { PureComponent } from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';

import Dialog from 'libraries/Dialog';
import SnackbarProgress from 'libraries/SnackbarProgress';
import { checkPluralNew } from 'helper/commonFunctions';
import { timeToCloseFlashMessageCancel, BATCH_STEP } from 'helper/constants';
import { hireCandidates } from 'requests/CandidatesRequests';
import AssessmentEvents from 'events/AssessmentEvents';
import HiringFlowDialog from '../HiringFlowDialog';

import HireIcon from '../HireCandidateDialog/icon.svg';

import styles from '../HireCandidateDialog/styles';

class HireUsers extends PureComponent {
    state = {
        showProgress: false,
        hireFlowDialogOpened: false,
        canceled: false,
        progress: 0,
        error: false
    };

    hireUsers = () => {
        const { canceled } = this.state;
        const { columnUserIds, handleClose, audition } = this.props;
        const { uuid: ttId, name: ttName, slug } = audition;

        this.setState({ showProgress: true, error: false });

        handleClose();
        const max = Math.ceil(columnUserIds.length / BATCH_STEP);
        let i = 0;

        AssessmentEvents.CANDIDATES_BATCH_ACTIONED({
            actionType: 'hire',
            numberCandidatesActioned: columnUserIds.length,
            ttId,
            ttName
        });

        const handleError = () => {
            this.setState({ error: true }, () => {
                setTimeout(() => {
                    this.setState({ showProgress: false });
                }, 2000);
            });
        };

        const requestCycle = async () => {
            if (i < max && !canceled) {
                this.setState({ progress: parseInt((i * 100) / max, 10) });
                const startPos = i * BATCH_STEP;
                const endPos = startPos + BATCH_STEP;
                try {
                    const slicedArr = columnUserIds.slice(startPos, endPos);
                    const result = await hireCandidates(slug, slicedArr);
                    if (result.success) {
                        i += 1;
                        setTimeout(() => {
                            this.setState({ showProgress: false });
                        }, 1000);
                        handleClose();
                        requestCycle();
                    } else {
                        handleError();
                    }
                } catch (e) {
                    handleError();
                }
            } else if (!canceled) {
                this.setState({
                    progress: 100,
                    hireFlowDialogOpened: true
                });
            }
        };
        requestCycle();
    };

    handleCancel = () => {
        this.setState({ canceled: true }, () => {
            const { handleClose } = this.props;
            setTimeout(handleClose, timeToCloseFlashMessageCancel);
        });
    };

    handleNextStepsDialogOpened = () => {
        this.setState({
            hireFlowDialogOpened: false
        });
    };

    onFinish = () => {
        const { onSuccess } = this.props;

        if (onSuccess) onSuccess();
    };

    render() {
        const { handleClose, columnUserIds, open, classes, audition, selectedCandidates } = this.props;
        const { showProgress, progress, error, canceled, hireFlowDialogOpened } = this.state;
        const candidateFormatted = `${columnUserIds.length} ${checkPluralNew(columnUserIds.length, 'candidate')}`;

        if (showProgress) {
            return (
                <SnackbarProgress
                    open
                    showSuccessIcon
                    message={`Hiring ${candidateFormatted}`}
                    onCancel={this.handleCancel}
                    percents={progress}
                    error={error}
                    errorLabel="ERROR - INTERNET CONNECTION TIMED OUT"
                    canceled={canceled}
                />
            );
        }

        return (
            <>
                <Dialog
                    open={open}
                    maxWidth="sm"
                    onClose={handleClose}
                    handleClose={handleClose}
                    contentClassName={classes.contentClassName}
                    actionComponent={(
                        <>
                            <Button
                                className={classes.secondaryButton}
                                onClick={handleClose}
                            >
                                Back
                            </Button>
                            <Button
                                className={classes.mainButton}
                                color="primary"
                                variant="contained"
                                onClick={this.hireUsers}
                                disabled={showProgress}
                            >
                                Yes
                            </Button>
                        </>
                    )}
                >
                    <img src={HireIcon} alt="!" className="u-mrg--bx2" />
                    <div className={classes.contentTitle}>
                        Mark {candidateFormatted} as hired?
                    </div>
                    <div className={classes.contentText}>
                        Marking candidates as hired will allow you to find and filter them in future from all candidates.
                    </div>
                </Dialog>

                <HiringFlowDialog
                    open={hireFlowDialogOpened}
                    onClose={this.handleNextStepsDialogOpened}
                    candidates={selectedCandidates}
                    assessment={audition}
                    onSuccessClose={this.onFinish}
                />
            </>
        );
    }
}

export default withStyles(styles)(HireUsers);

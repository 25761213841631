export default theme => ({
    contentWrapper: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    dialog: {
        padding: 0,
        margin: 0,
        height: '100vh'
    },
    dialogTitle: {
        '&:after': {
            width: '100%'
        }
    }
});

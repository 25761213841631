import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import TextInputOutlined from 'libraries/TextInput';
import { MAX_SKILL_NAME_LENGTH } from 'helper/constants';

import styles from './styles';


const AddNewSkillButton = ({ classes, buttonLabel, className, disabled, changeScoreType }) => {
    const [openedDialog, setOpenedDialog] = useState(false);
    const [skillName, setSkillName] = useState('');

    const handleDialog = () => {
        if (openedDialog) {
            setSkillName('');
        }
        setOpenedDialog(!openedDialog);
    };

    const handleSkillName = (e) => {
        const { value } = e.target;
        setSkillName(value);
    };

    return (
        <>
            <Button
                className={clsx(classes.newGroupButton, className)}
                onClick={handleDialog}
                disabled={disabled}
                color="primary"
            >
                { buttonLabel || 'Add new Skill' }
            </Button>
            <Dialog
                maxWidth="sm"
                actionComponent={(
                    <>
                        <Button
                            className={classes.actionDialogButton}
                            onClick={handleDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.actionDialogButton}
                            variant="contained"
                            color="primary"
                            disabled={!skillName || !skillName.trim()}
                            onClick={() => { changeScoreType(3, skillName); handleDialog(); }}
                        >
                            Save
                        </Button>
                    </>
                )}
                open={openedDialog}
                handleClose={handleDialog}
                onClose={handleDialog}
                titleComponent="Add new skill"
            >
                <TextInputOutlined
                    onChange={handleSkillName}
                    value={skillName}
                    variant="outlined"
                    maxLength={MAX_SKILL_NAME_LENGTH}
                    minLength={1}
                    placeholder="Fire Control"
                    label="Assessment question skill name"
                />
            </Dialog>
        </>
    );
};

export default withStyles(styles)(AddNewSkillButton);

export default theme => ({
    wrapper: {
        borderWidth: 1,
        '& h2': {
            minWidth: '224px'
        }
    },
    searchField: {
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 8,
        margin: 0,
        minWidth: '100px',
        color: theme.palette.grey[700],
        height: '43px',
        background: '#fff',
        '& div': {
            width: '30px !important'
        },
        '& input::placeholder': {
            color: theme.palette.grey[700]
        }
    },
    searchFieldFocused: {
        background: '#fff',
        border: `2px solid ${theme.palette.primary.main}`
    },
    searchLabel: {
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 0
    },
    wrapClass: {
        width: '100%',
        marginRight: '20px'
    },
    boxWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginLeft: '35px'
    },
    wrapBox: {
        display: 'flex'
    },
    assessmentListWrap: {
        padding: '20px',
        width: '100%'
    }
});

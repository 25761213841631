export default () => ({
    imgDialog: {
        maxWidth: '100%',
        maxHeight: 'calc(100vh - 105px)'
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    dialogPaper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textAlign: 'center',
        margin: 20
    },
    expandButton: {
        position: 'absolute',
        bottom: -5,
        right: 19,
        height: 30,
        paddingLeft: 5,
        paddingRight: 5,
        boxSizing: 'border-box',
        minWidth: 30,
        minHeight: 30,
        marginBottom: 15
    }
});

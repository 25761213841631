export default theme => ({
    addBtn: {
        fontWeight: 600,
        '& img': {
            marginRight: 10
        }
    },
    closeIcon: {
        padding: 0,
        height: 35,
        width: 35,
        margin: '0 5px'
    },
    labelError: {
        color: theme.palette.red.primary,
        fontWeight: 600
    },
    input: {
        margin: '0 5px'
    },
    pointsInput: {
        maxWidth: 120
    },
    warningText: {
        fontWeight: 500,
        fontSize: 12,
        margin: '7px 0'
    }
});

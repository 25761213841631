import React from 'react';
import { withStyles } from '@mui/styles';
import MaterialPopper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';

const stylesJS = {
    popperRoot: {
        zIndex: 1300,
        '&[x-placement*="bottom"]': {
            marginBottom: 20,
            '& $popperArrow': {
                top: '0',
                left: '25px !important',
                marginTop: '6px',
                width: '3em',
                height: '1em',
                '&:before': {
                    borderWidth: '0 15px 15px 15px',
                    borderColor: 'transparent transparent #fff transparent'
                }
            }
        },
        '&[x-placement*="top"]': {
            '& $popperArrow': {
                bottom: '-20px',
                width: '100%',
                height: '20px',
                '&:before': {
                    position: 'absolute',
                    left: '25px',
                    borderColor: '#fff transparent transparent transparent',
                    borderWidth: '15px 15px 0 15px'
                }
            }
        },
        '&[x-placement*="right"]': {
            '& $popperArrow': {
                marginLeft: '6px',
                left: '25px!important',
                height: '3em',
                width: '1em',
                '&:before': {
                    borderWidth: '15px 15px 15px 0',
                    borderColor: 'transparent #fff transparent transparent'
                }
            }
        },
        '&[x-placement*="left"]': {
            '& $popperArrow': {
                right: '25px',
                marginRight: '6px',
                height: '3em',
                width: '1em',
                '&:before': {
                    borderWidth: '15px 0 15px 15px',
                    borderColor: 'transparent transparent transparent #fff'
                }
            }
        }
    },
    popperPaper: {
        padding: '10px 20px',
        maxWidth: '350px',
        boxSizing: 'border-box',
        marginTop: '20px',
        boxShadow: '0 17px 50px 0 rgba(0, 0, 0, 0.19)'
    },
    popperArrow: {
        position: 'absolute',
        fontSize: '7px',
        width: '3em',
        height: '3em',
        '&:before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: '0',
            height: '0',
            borderStyle: 'solid'
        }
    },
    popperContent: {
        position: 'relative'
    }
};


function PopperContent({ placement = 'bottom-start', children, open, anchorEl, classes, arrow, styles, className }) {
    return (
        <MaterialPopper
            open={open}
            anchorEl={anchorEl}
            className={`${classes.popperRoot} ${className}`}
            placement={placement}
            modifiers={{
                flip: {
                    enabled: false
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent'
                },
                arrow: {
                    enabled: arrow
                }
            }}
        >
            {arrow ? <span className={classes.popperArrow} /> : null}
            <Paper className={classes.popperPaper} style={styles.paperStyle}>
                <div className={classes.popperContent}>{children}</div>
            </Paper>
        </MaterialPopper>
    );
}
export default withStyles(stylesJS)(PopperContent);

import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import Masonry from 'react-masonry-component';
import CircularProgress from '@mui/material/CircularProgress';
import { loadPublicInterviews } from 'requests/ScriptRequests';
import BoardPagination from 'components/board/board_pagination';
import OnboardingAssessmentItem from './onboarding_assessment_item';


const styles = theme => ({
    mansoryWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 -12px 0',
        '& > div': {
            marginLeft: 13,
            marginRight: 13,
            marginBottom: 30
        },
        [theme.breakpoints.down('lg')]: {
            margin: '0 auto 0',
            maxWidth: 770
        },
        '@media only screen and (max-width: 795px)': {
            '& > div': {
                margin: '0 calc(50% - 160px) 30px'
            }
        }
    },
    loaderWrapper: {
        minHeight: 330,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const OnboardingAssessmentList = observer(({ classes, isOnBoarding, onSelect, selectLoadingId }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [assessments, setAssessments] = useState([]);
    const [loading, setLoading] = useState(true);
    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        getAssessments();
    }, [currentPage]);

    const getAssessments = () => {
        loadPublicInterviews('', currentPage, null, {
            sort: 'popular',
            onboarding: +Boolean(isOnBoarding)
        })
            .then((data) => {
                if (data) {
                    const { total, currentPage: dataCurrentPage, items } = data;
                    setTotalCount(total);
                    setAssessments(items);
                    setCurrentPage(dataCurrentPage);
                }
                setLoading(false);
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
                setAssessments([]);
                setLoading(false);
            });
    };

    const changePage = (page) => {
        setCurrentPage(page);
        setLoading(true);
    };

    const totalPages = Math.ceil(totalCount / 12);

    return (
        <>
            {
                loading ? (
                    <div className={classes.loaderWrapper}>
                        <CircularProgress size={25} className={classes.loader} />
                    </div>
                ) : (
                    <Masonry component="div" className={classes.mansoryWrapper}>
                        {assessments
                            .map(assess => (
                                <OnboardingAssessmentItem
                                    assessment={assess}
                                    key={assess.id}
                                    onClick={onSelect}
                                    selectLoadingId={selectLoadingId}
                                />
                            ))
                        }
                    </Masonry>
                )
            }
            {
                isOnBoarding && (
                    <BoardPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handleChangePage={changePage}
                    />
                )
            }
        </>
    );
});

export default withRouter(withStyles(styles)(OnboardingAssessmentList));

import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ApplicationLink from 'components/job_or_assessment_settings/ApplicationLink';
import { loadAssessmentLinks } from 'requests/AssessmentRequests';
import AssessmentEvents from 'events/AssessmentEvents';
import Popover from '@mui/material/Popover';
import { appCtx } from 'components/appStore';
import { loadAssessmentSettings } from 'requests/ScriptRequests';
import InviteResultsDialog from 'components/job_or_assessment_settings/InviteResultsDialog';
import QuickInviteForm from './QuickInviteForm';
import styles from './styles';
import { statsCtx } from '../../../../../statsStore';

const InviteNewCandidate = observer(({ onClose, context, open, audition, history, classes, buttonRef }) => {
    const { flashMessage } = useContext(appCtx);
    const { loadAssessmentStats } = useContext(statsCtx);
    const { slug } = audition;
    const [shareLink, setShareLink] = useState('');
    const [otherLinks, setOtherLinks] = useState([]);
    const [canCopyLink, setCanCopyLink] = useState(false);
    const [enableExitTransition, setEnableExitTransition] = useState(true);

    const {
        toggleResponseInviteDialog, errorLimit,
        showResponseInviteDialog, successfullySentData
    } = useContext(context);

    useEffect(() => {
        getAssessmentLinks();
        setEnableExitTransition(true);

        loadAssessmentSettings(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setCanCopyLink(Boolean(data.message));
                }
            });
    }, [open]);

    const getAssessmentLinks = () => {
        loadAssessmentLinks(slug)
            .then(({ data, success }) => {
                if (success && data) {
                    const { shareLink: mainLink, links } = data;
                    setShareLink(mainLink);
                    setOtherLinks(Object.values(links).reverse().slice(0, 2));
                }
            });
    };

    const handleViewAllShareLinks = () => {
        onClose();
        history.push(`/script/invite/${slug}/invitation`);
    };

    const onCopyLink = ({ name: linkName }) => {
        AssessmentEvents.LINK_COPIED({ linkName, ttId: audition.uuid, ttName: audition.name });
        flashMessage('Link copied to clipboard', 'done');
    };

    const goToSettings = () => {
        setEnableExitTransition(false);
        onClose();
        history.push(`/script/settings/${slug}/invitation-preview`);
    };


    const handleGoToCandidates = () => {
        toggleResponseInviteDialog();
        onClose();
        history.push(`/script/invite/${audition.slug}/invite-candidates`);
    };

    return (
        <>
            <Popover
                open={open}
                onClose={onClose}
                handleClose={onClose}
                anchorEl={buttonRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                classes={{
                    paper: classes.popoverContainer
                }}
                TransitionProps={{ exit: enableExitTransition }}
            >
                <Typography variant="h5" className={classes.header}>
                    Invite new candidates
                </Typography>
                <QuickInviteForm
                    isQuick
                    slug={slug}
                    context={context}
                    assessment={audition}
                    closeDialog={onClose}
                    onAllInvitationsSent={loadAssessmentStats}
                />
                <div className={classes.divider} />
                <Typography variant="h5" className={classes.header}>
                    Share Links
                </Typography>
                <Typography variant="h6" className={classes.subHeader}>
                    Anyone with this link can view and take the assessment
                </Typography>
                {
                    [shareLink, ...otherLinks].map((link, idx) => (
                        <ApplicationLink
                            key={idx}
                            onCopyLink={onCopyLink}
                            slug={slug}
                            audition={audition}
                            disabled={!canCopyLink}
                            mainLink
                            link={{ ...link, name: link.name }}
                            disableTooltip={(
                                <div>
                                    Please check <span onClick={goToSettings} className={classes.tooltipLink} role="button" tabIndex={0}>Invite Settings</span>
                                </div>
                            )}
                            withIcon
                        />
                    ))
                }
                <Button
                    color="primary"
                    className={classes.link}
                    onClick={handleViewAllShareLinks}
                    variant="text"
                >
                    View all share links
                </Button>
            </Popover>
            <InviteResultsDialog
                errorLimit={errorLimit}
                open={showResponseInviteDialog}
                sentData={successfullySentData}
                onClose={toggleResponseInviteDialog}
            >
                <Button
                    color="primary"
                    onClick={handleGoToCandidates}
                    className="u-mrg--tx2"
                >
                    Go to Candidates
                </Button>
            </InviteResultsDialog>
        </>
    );
});

export default withStyles(styles)(InviteNewCandidate);

import React from 'react';

const FlagIcon = ({ filled = false, size = 18, className }) => (
    <svg className={className} width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.1875 11.5876C3.1875 11.5876 3.89062 10.9126 6 10.9126C8.10938 10.9126 9.51562 12.2626 11.625 12.2626C13.7344 12.2626 14.4375 11.5876 14.4375 11.5876V3.48762C14.4375 3.48762 13.7344 4.16262 11.625 4.16262C9.51562 4.16262 8.10938 2.81262 6 2.81262C3.89062 2.81262 3.1875 3.48762 3.1875 3.48762V11.5876Z" fill={filled && '#0025D3'} stroke={filled ? '#0025D3' : '#A9AAAE'} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.1875 16.3126V11.5876" stroke={filled ? '#0025D3' : '#A9AAAE'} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default FlagIcon;

class Zendesk {
    constructor() {
        this.inilialize();
    }

    inilialize() {
        this.zendesk = window.zE;
    }

    /**
     * Opens widget with optional contactForm
     * @param contactForm boolean
     * @param message - sets pre-filled message in contactForm
     */
    open(contactForm, message) {
        this.inilialize();
        if (!this.zendesk) return;
        this.zendesk('webWidget', 'open');
        if (contactForm) {
            this.zendesk('webWidget', 'updateSettings', {
                webWidget: {
                    helpCenter: {
                        suppress: true
                    }
                }
            });
        }
        if (message) {
            this.zendesk('webWidget', 'updateSettings', {
                webWidget: {
                    contactForm: {
                        fields: [
                            { id: 'description', prefill: { '*': message } }
                        ]
                    }
                }
            });
        }
    }

    /**
     * Set offset values to widget position
     * @param params obj in format { horizontal: 'Xpx', vertical: 'Ypx' }
     */
    setPosition(params) {
        this.inilialize();
        if (!this.zendesk) return;
        this.zendesk('webWidget', 'updateSettings', {
            webWidget: {
                offset: params
            }
        });
    }

    resetPosition() {
        this.setPosition({
            horizontal: '0px',
            vertical: '0px'
        });
    }

    reset() {
        this.inilialize();
        if (!this.zendesk) return;
        this.zendesk('webWidget', 'reset');
    }

    hide() {
        this.inilialize();
        if (!this.zendesk) return;
        this.zendesk('webWidget', 'hide');
    }

    show() {
        this.inilialize();
        if (!this.zendesk) return;
        this.zendesk('webWidget', 'show');
    }
}

export default new Zendesk();

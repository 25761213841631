import React, {useState} from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import SetsContent from './SetsContent';
import SkillsSelection from './SkillsSelection';
import QuestionsWrapper from './QuestionsWrapper';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        position: 'relative',
        overflow: 'auto',
        minHeight: 400,
        width: '100%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'block'
        }
    },
    leftPanel: {
        width: '50%',
        minHeight: 550,
        minWidth: 650,
        paddingBottom: 50,
        backgroundColor: theme.palette.grey[50],
        position: 'relative',
        padding: '16px 0',
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderRight: 'none',
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        }
    },
    contentPanel: {
        width: '50%',
        position: 'relative',
        padding: '16px 0 40px',
        minWidth: 550,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '26px 30px 40px'
        }
    }
});

const ContentQuestions = observer(({ classes, ...other }) => {
    const [addToSetQuestionUuid, setAddToSetQuestionUuid] = useState(null);
    const [removeFromQuestionUuid, setRemoveFromSetQuestionUuid] = useState(null);

    const additionalProps = {
        addToSetQuestionUuid,
        setAddToSetQuestionUuid,
        removeFromQuestionUuid,
        setRemoveFromSetQuestionUuid
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.leftPanel}>
                <SkillsSelection {...other} {...additionalProps} />
                <QuestionsWrapper {...other} {...additionalProps} />
            </div>
            <div className={classes.contentPanel}>
                <SetsContent {...other} {...additionalProps} />
            </div>
        </div>
    );
});

export default withStyles(styles)(ContentQuestions);

export default theme => ({
    wrapper: {
        width: 375,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    priceBlock: {
        backgroundColor: theme.palette.blue[800],
        padding: '66px 33px 40px'
    },
    price: {
        fontSize: 40,
        fontWeight: 700,
        marginLeft: 60
    },
    priceWrapper: {
        marginTop: 12,
        fontSize: 35
    },
    pricingSwitchWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: 14
    },
    pricingSwitch: {
        height: 37
    },
    inactiveLabel: {
        opacity: 0.7
    }
});

import React from 'react';
import { ZiggeoRecorder } from 'react-ziggeo';

class Recorder extends React.Component {
    constructor(props) {
        super(props);
        this.child = false;
    }

    recorderCountdown = () => {
        const { recordingStarted } = this.props;
        if (recordingStarted) {
            recordingStarted();
        }
    };

    recorderUploadSelected = () => {
        const { recordingStarted } = this.props;
        if (recordingStarted) {
            recordingStarted();
        }
    };

    recorderUploaded = () => null;

    recorderVerified = () => {
        const { recordingPublished, recordingStoped, recordingProcessed } = this.props;
        const token = this.child.get('video');
        if (recordingPublished) {
            recordingPublished(token);
        }
        if (recordingStoped) {
            recordingStoped();
        }
        if (recordingProcessed) {
            recordingProcessed();
        }

        if (this.child) {
            this.child.reset();
        }
    };

    render() {
        const { title, video } = this.props;
        return (
            <ZiggeoRecorder
                apiKey={process.env.REACT_APP_APPLICATION_TOKEN}
                onRef={(ref) => { this.child = ref; }}
                title={title || ''}
                description=""
                width="100%"
                height="auto"
                theme="space"
                themecolor="blue"
                onlyaudio={!video}
                localplayback={false}
                picksnapshots={video}
                allowedextensions={['mp4', 'avi', 'mov', 'mkv', 'wmv', 'ogv', 'webm', 'flv']}

                snapshotfromuploader={true}
                snapshotfrommobilecapture={true}
                custom-covershots={true}

                // webrtc
                webrtc_on_mobile
                webrtc_streaming_if_necessary

                // Events
                onCountdown={this.recorderCountdown}
                onUploadSelected={this.recorderUploadSelected}
                onUploaded={this.recorderUploaded}
                onVerified={this.recorderVerified}
            />
        );
    }
}

export default Recorder;

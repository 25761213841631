import { tracking } from 'helper/eventSegment';

export default {
    TEAM_MEMBER_INVITED: (data) => {
        tracking('S-team-member-invited', data);
    },
    TEAM_MEMBER_REMOVED: (data) => {
        tracking('S-team-member-removed', data);
    },
    TEAM_MEMBER_UPGRADED: (data) => {
        tracking('S-team-member-upgraded', data);
    },
    TEAM_MEMBER_DOWNGRADED: (data) => {
        tracking('S-team-member-downgraded', data);
    },
    TEAM_MEMBER_INVITATION_CANCELLED: (data) => {
        tracking('S-team-member-invitation-cancelled', data);
    },
    OWNER_CHANGED: (data) => {
        tracking('S-owner-changed', data);
    },
    ASSESSMENT_TEAM_ADDED: (data) => {
        tracking('S-assessment-team-added', data);
    },
    ASSESSMENT_TEAM_REMOVED: (data) => {
        tracking('S-assessment-team-removed', data);
    },
    ASSESSMENT_TEAM_ROLE_CHANGED: (data) => {
        tracking('S-assessment-team-role-changed', data);
    },
    JOB_TEAM_ADDED: (data) => {
        tracking('S-job-team-added', data);
    },
    JOB_TEAM_REMOVED: (data) => {
        tracking('S-job-team-removed', data);
    },
    JOB_TEAM_ROLE_CHANGED: (data) => {
        tracking('S-job-team-role-changed', data);
    },
    LOGO_ADDED: (data) => {
        tracking('S-logo-added', data);
    },
    CANDIDATE_TAG_ADDED: (data) => {
        tracking('S-tag-added', data);
    },
    CANDIDATE_TAG_REMOVED: (data) => {
        tracking('S-tag-removed', data);
    },
    CANDIDATE_TAG_RENAMED: (data) => {
        tracking('S-tag-renamed', data);
    },
    ACCOUNT_DETAILS_UPDATED: (data) => {
        tracking('S-account-details-updated', data);
    },
    COMPANY_DETAILS_UPDATED: (data) => {
        tracking('S-company-details-updated', data);
    },
    COMPANY_SLUG_UPDATED: (data) => {
        tracking('S-company-slug-updated', data);
    },
    DAILY_DIGEST_NOTIFICATIONS_CHANGED: (data) => {
        tracking('S-daily-digest-notifications-changed', data);
    },
    NEWS_NOTIFICATIONS_CHANGED: (data) => {
        tracking('S-news-notifications-changed', data);
    },
    BLOG_NOTIFICATIONS_CHANGED: (data) => {
        tracking('S-blog-notifications-changed', data);
    },
    CREDIT_CARD_UPDATED: () => {
        tracking('S-credit-card-updated');
    },
    CREDIT_CARD_UPDATE_FAILED: () => {
        tracking('S-credit-card-update-failed');
    },
    ZAPIER_ENABLED: (data) => {
        tracking('S-zapier-enabled ', data);
    },
    GREENHOUSE_ENABLED: (data) => {
        tracking('S-greenhouse-enabled', data);
    },
    API_ENABLED: (data) => {
        tracking('S-api-enabled', data);
    },
    COMEET_ENABLED: (data) => {
        tracking('S-comeet-enabled', data);
    },
    SMARTRECRUITERS_ENABLED: (data) => {
        tracking('S-smartrecruiters-enabled', data);
    },
    SMARTRECRUITERS_V2_ENABLED: (data) => {
        tracking('S-smartrecruiters-v2-enabled', data);
    },
    PUP_ENABLED: (data) => {
        tracking('S-pup-enabled', data);
    },
    JOBADDER_ENABLED: (data) => {
        tracking('S-jobadder-enabled', data);
    },
    JOBADDER_REMOVED: () => {
        tracking('S-jobadder-removed');
    },
    BRANDING_CREATED: (data) => {
        tracking('S-tt-branding-created', data);
    },
    BRANDING_DELETED: (data) => {
        tracking('S-tt-branding-deleted', data);
    },
    BRANDING_UPDATED: (data) => {
        tracking('S-tt-branding-updated', data);
    },
    BRANDING_LOGO_ADDED: (data) => {
        tracking('S-tt-branding-logo-added', data);
    },
    COMPANY_LOGO_RESET: () => {
        tracking('S-company-logo-reset');
    },
    LEVER_ENABLED: (data) => {
        tracking('S-lever-enabled', data);
    },
    LEVER_RESETED: () => {
        tracking('S-lever-reseted');
    },
    ORACLE_ENABLED: (data) => {
        tracking('S-oracle-enabled', data);
    },
    ICIMS_ENABLED: (data) => {
        tracking('S-icims-enabled', data);
    },
    TEAM_MEMBER_REINVITED: (data) => {
        tracking('S-team-member-reinvited', data);
    },
    MFA_ENABLED: (data) => {
        tracking('S-mfa-enabled', data);
    }
};

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';
import DeactivateAssessmentDialog from 'components/dialogs/DeactivateAssessmentDialog';
import ActivateAssessmentDialog from 'components/dialogs/ActivateAssessmentDialog';

import ActivateLimitDialog from 'components/dialogs/ActivateLimitDialog';
import DeactivateSurveyDialog from 'components/dialogs/DeactivateSurveyDialog';
import DeactivateLinkedAssessmentDialog from 'components/dialogs/DeactivateLinkedAssessmentDialog';
import { AssessmentStore } from 'components/assessments_pages/AssessmentList/assessmentStore';
import ActivateAssessmentLowPlanDialog from 'components/dialogs/ActivateAssessmentLowPlanDialog';
import { statsCtx } from 'pages/assessment/statsStore';
import HoverButton from './HoverButton';


const ActiveLabel = observer(({ audition = {}, history, disabled, loadEditScript, setAuditionIsActive }) => {
    const { company, flashMessage, activeAuditionsSlotsIsAvailable } = useContext(appCtx);
    const { setSlug } = useContext(statsCtx);
    const [deactivateDialogOpened, setDeactivateDialogOpened] = useState(false);
    const [deactivateSurveyOpened, setDeactivateSurveyOpened] = useState(false);
    const [deactivateLinkedDialogOpened, setDeactivateLinkedDialogOpened] = useState(false);

    const [assessmentStore] = useState((() => new AssessmentStore(audition)));

    const canActivateAssessmentWithCandidates = checkHasCompanyFeature(company, 'ACTIVATE_ASSESSMENT_WITH_PREEXISTING_CANDIDATES');


    const {
        activateDialogOpen, setActivateDialogOpen,
        limitActivateDialogOpen, closeLimitActivateDialog,
        onActivate, duplicateAndActivate, activateAssessment
    } = assessmentStore;

    const { linkedSlugs, slug, countRealCandidates,
        countCompletedNotRejectedCandidates, countHiredCandidates
    } = audition;

    const { companySettings } = company;

    if (!companySettings) return null;

    const onDeactivate = () => {
        loadEditScript(history);
        history.push(`/script/edit/${slug}`);
    };

    const handleDeactivate = () => {
        if (linkedSlugs.length) {
            setDeactivateLinkedDialogOpened(!deactivateLinkedDialogOpened);
        } else if (countRealCandidates
            && countCompletedNotRejectedCandidates
            && !countHiredCandidates) {
            setDeactivateSurveyOpened(!deactivateSurveyOpened);
        } else {
            setDeactivateDialogOpened(!deactivateDialogOpened);
        }
    };

    const handleActivateDialog = () => {
        if (countRealCandidates > 0) {
            setActivateDialogOpen(!activateDialogOpen);
        } else {
            onClickActivate();
        }
    };

    const onClickActivate = () => {
        onActivate().then(() => {
            setAuditionIsActive();
        }).catch(() => {});
    };

    const onClickActivateOnDialog = () => {
        activateAssessment().then(() => {
            setAuditionIsActive();
        }).catch(() => {});
    };

    const duplicateAndActivateHandler = () => {
        duplicateAndActivate().then((data) => {
            const { slug: newSlug } = data;
            setSlug(newSlug);
            history.push(`/script/edit/${newSlug}`);
        }).catch(() => {});
    };

    return (
        <>
            <HoverButton
                audition={audition}
                disabled={disabled}
                handleDeactivateDialog={handleDeactivate}
                onActivate={activeAuditionsSlotsIsAvailable ? handleActivateDialog : onClickActivate}
            />
            <ActivateLimitDialog
                open={limitActivateDialogOpen}
                onClose={closeLimitActivateDialog}
            />
            <DeactivateAssessmentDialog
                open={deactivateDialogOpened}
                onClose={() => setDeactivateDialogOpened(false)}
                audition={audition}
                onSuccess={onDeactivate}
                flashMessage={flashMessage}
            />
            <DeactivateSurveyDialog
                open={deactivateSurveyOpened}
                onClose={() => setDeactivateSurveyOpened(false)}
                assessment={audition}
                onSuccess={onDeactivate}
            />
            <DeactivateLinkedAssessmentDialog
                open={deactivateLinkedDialogOpened}
                onClose={() => setDeactivateLinkedDialogOpened(false)}
                audition={audition}
            />
            {
                canActivateAssessmentWithCandidates
                    ? (
                        <ActivateAssessmentDialog
                            open={activateDialogOpen}
                            onClickActivate={onClickActivateOnDialog}
                            onClickDuplicate={duplicateAndActivateHandler}
                            candidatesCount={countRealCandidates}
                            onClose={handleActivateDialog}
                        />
                    )
                    : (
                        <ActivateAssessmentLowPlanDialog
                            open={activateDialogOpen}
                            onClickActivate={onClickActivate}
                            onClose={handleActivateDialog}
                        />
                    )
            }
        </>
    );
});

export default withRouter(ActiveLabel);

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import SelectOutlined from 'libraries/Select';
import TextInputOutlined from 'libraries/TextInput';
import { generateCodeForCodeChallenge } from 'requests/ScriptRequests';
import AssessmentEvents from 'events/AssessmentEvents';
import { typeOptions, MAX_FUNCTION_NAME_LENGTH, MAX_FUNCTION_PARAMETERS } from 'helper/codeChallenge';

import { appCtx } from 'components/appStore';
import PlusIcon from 'img/plus.svg';
import ParameterRow from './ParameterRow';
import ConfirmGenerateDialog from './ConfirmGenerateDialog';
import { codeGeneratorCtx } from '../store';

import styles from '../styles';

const GeneratorTab = observer(({ classes, question: { id }, codeGeneratorCallback, codeChallengeIsNotEmpty, dataForEvents, isCMS }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [functionName, setFunctionName] = useState('');
    const [returnType, setReturnType] = useState('');
    const [functionParameters, setFunctionParameters] = useState([]);

    const { getCodeGenerationDraft, saveCodeGenerationDraft } = useContext(codeGeneratorCtx);
    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        getCodeGenerationDraft(id, isCMS)
            .then(({ name, returnType: type, functionParameters: params }) => {
                setFunctionName(name);
                setReturnType(type);
                setFunctionParameters(params.length ? params : [{ name: '', type: '' }]);
            });
    }, [id]);

    const canGenerate = functionName
                        && returnType
                        && !functionParameters.filter(({ name, type }) => (!name || !type)).length;

    const addParameter = () => {
        setFunctionParameters([...functionParameters, {
            name: '',
            type: ''
        }]);
    };

    const setParameter = (index, name, type) => {
        const newParams = [...functionParameters];
        newParams[index] = { name, type };
        setFunctionParameters([...newParams]);
    };

    const deleteParameter = (index) => {
        const newParams = [...functionParameters];
        newParams.splice(index, 1);
        setFunctionParameters([...newParams]);
        flashMessage('Function parameter removed', 'done');
    };

    const handleGenerateClick = () => {
        if (codeChallengeIsNotEmpty) setDialogOpen(true);
        else generateCode();
    };

    const generateCode = () => {
        setLoading(true);
        generateCodeForCodeChallenge(id, {
            functionName,
            returnType,
            functionParameters: functionParameters.reduce((result, { name, type }) => {
                result[name] = type;
                return result;
            }, {})
        }, isCMS)
            .then(({ data, success }) => {
                if (!data || !success) return;
                saveCodeGenerationDraft({ functionName, returnType, functionParameters });
                codeGeneratorCallback(data);
                AssessmentEvents.QUESTION_CODE_GENERATED(dataForEvents);
            })
            .catch(() => {
                flashMessage('Error during code generation. Please, check that the input is correct', 'error');
                AssessmentEvents.QUESTION_CODE_GENERATED_FAILED(dataForEvents);
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.content}>
                <p>The code can be automatically generated by specifying the following parameters.</p>
                <TextInputOutlined
                    maxLength={MAX_FUNCTION_NAME_LENGTH}
                    label="Function Name"
                    value={functionName}
                    variant="outlined"
                    onChange={e => setFunctionName(e.target.value)}
                    disabled={loading}
                />
                <SelectOutlined
                    label="Return Type"
                    variant="outlined"
                    value={returnType}
                    options={typeOptions.map(option => ({ value: option, label: option }))}
                    onChange={e => setReturnType(e.target.value)}
                    disabled={loading}
                />
                <h2 className={classes.subtitle}>Function parameters</h2>
                {
                    functionParameters.map((parameter, index) => (
                        <ParameterRow
                            key={index}
                            index={index}
                            disabled={loading}
                            {...{
                                parameter,
                                setParameter,
                                deleteParameter,
                                typeOptions
                            }}
                        />
                    ))
                }
                <div className="u-dsp--f u-jc--start">
                    <Button
                        className={classes.secondaryNoBorderButton}
                        onClick={addParameter}
                        disabled={loading || functionParameters.length >= MAX_FUNCTION_PARAMETERS}
                    >
                        <img src={PlusIcon} alt="+" />
                        Add a parameter
                    </Button>
                </div>
            </div>
            <div className={classes.footer}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleGenerateClick}
                    disabled={loading || !canGenerate}
                >
                    { loading
                        ? <CircularProgress size={22} />
                        : 'Generate Code'
                    }
                </Button>
            </div>
            <ConfirmGenerateDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                generateCode={generateCode}
            />
        </div>
    );
});

export default withStyles(styles)(GeneratorTab);

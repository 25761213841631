export default theme => ({
    root: {
        fontSize: 10,
        display: 'flex',
        lineHeight: 'normal',
        fontWeight: 600,
        color: theme.palette.grey[700],
        '& img': {
            marginRight: 12,
            height: 16
        },
        '& a': {
            fontWeight: 'bold',
            color: theme.palette.grey[700],
            textDecoration: 'underline'
        }
    }
});

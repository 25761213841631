import * as React from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    disabledDate: {
        color: 'red',
        pointerEvents: 'none',
        opacity: 0.3
    },
    root: {
        width: 270
    }
}));

export default function BasicDateRangePicker({ dateRange, setDateRange, disabled, limitOneYear }) {
    const { root } = useStyles();

    const handleDateChange = (newValue) => {
        const formattedDates = newValue.map(item => moment(item).format());
        setDateRange(formattedDates);
    };

    const shouldDisableEndDate = (date) => {
        if (!dateRange[0] || !limitOneYear) return false;
        const startDate = moment(dateRange[0]);
        const oneYearAfterStart = startDate.clone().add(1, 'year');
        return moment(date).isAfter(oneYearAfterStart);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
                startText="Select Date"
                calendars={1}
                value={dateRange}
                disabled={disabled}
                onChange={handleDateChange}
                shouldDisableDate={shouldDisableEndDate}
                renderInput={(startProps, endProps) => {
                    const {
                        inputProps: { value: startValue }
                    } = startProps;
                    const {
                        inputProps: { value: endValue }
                    } = endProps;
                    const { inputProps } = startProps;
                    const { onChange, ...otherInputProps } = inputProps;
                    const formatStr = 'MMM Do, YYYY';
                    const formattedStartValue = startValue ? moment(startValue).format(formatStr) : '';
                    const formattedEndValue = endValue ? moment(endValue).format(formatStr) : '';
                    return (
                        <TextField
                            {...startProps}
                            helperText=""
                            className={root}
                            inputProps={{
                                ...otherInputProps,
                                value: `${formattedStartValue} - ${formattedEndValue}`
                            }}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
}

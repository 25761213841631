import React from 'react';

import { withStyles } from '@mui/styles';

const styles = theme => ({
    textField: {
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        padding: 10,
        fontSize: 14,
        fontWeight: 600,
        width: 290,
        marginTop: 25,
        backgroundColor: '#fff',
        color: theme.palette.grey[500],
        lineHeight: '28px',
        marginBottom: 10
    },
    helperText: {
        color: theme.palette.grey[900],
        fontSize: 12,
        fontWeight: 600
    }
});

const NoAnswer = ({ classes }) => (
    <div>
        <div className={classes.textField}>Your Answer</div>
        <div className={classes.helperText}>Accepts numbers only</div>
    </div>
);

export default withStyles(styles)(NoAnswer);

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { getAuditionStatistics } from 'requests/CMSRequests';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
    block: {
        padding: '0 25px',
        '&:not(:last-child)': {
            borderRight: `1px solid ${theme.palette.grey[300]}`
        },
        '&:first-child': {
            paddingLeft: 0
        }
    },
    label: {
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        color: theme.palette.grey[600],
        fontSize: 10,
        fontWeight: 700,
        lineHeight: '18px'
    },
    value: {
        lineHeight: 1.2,
        whiteSpace: 'nowrap',
        color: theme.palette.grey[900],
        fontSize: 24,
        fontWeight: 700
    },
    wrapper: {
        padding: 25,
        borderBottom: '2px solid #F3F3F3'
    }
});

const StatisticsItem = withStyles(styles)(({ classes, value, label }) => (
    <div className={classes.block}>
        <div className={classes.value}>{value}</div>
        <div className={classes.label}>{label}</div>
    </div>
));


const StatisticsBlock = ({
    classes, auditionUuid, selectedSet
}) => {
    const [statistics, setStatistics] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadStatistics();
    }, [selectedSet]);

    const loadStatistics = () => {
        getAuditionStatistics(auditionUuid)
            .then(({ success, data }) => {
                if (success && data) {
                    setStatistics(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={classes.wrapper}>
            <Typography variant="h5">Assessments</Typography>
            <div className="u-dsp--f u-ai--center">
                {
                    loading && (
                        <CircularProgress
                            size={24}
                        />
                    )
                }
                {
                    statistics && (
                        <>
                            <StatisticsItem label="Skills" value={statistics.countSkills} />
                            <StatisticsItem
                                label="Questions"
                                value={statistics.countQuestions}
                            />
                            <StatisticsItem
                                label="Avg time to complete"
                                value={`${statistics.averageTimeToComplete}min`}
                            />
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default withStyles(styles)(StatisticsBlock);

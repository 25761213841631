import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import InfoTooltip from 'components/tooltips/InfoTooltip';
import PauseSubscriptionDialog from 'components/dialogs/PauseSubscriptionDialog';


import styles from './styles';

const SubscriptionInfoBlock = observer(({ classes, getCompanyPlan, pauseAllowed }) => {
    const [pauseSubscriptionDialogOpen, setPauseSubscriptionDialogOpen] = useState(false);

    const handlePauseSubscriptionDialog = () => {
        setPauseSubscriptionDialogOpen(!pauseSubscriptionDialogOpen);
    };

    return (
        <>
            <Button
                color="primary"
                className={classes.pauseButton}
                disabled={!pauseAllowed}
                onClick={handlePauseSubscriptionDialog}
            >
                <div className="u-txt--bold u-dsp--centered">
                    Pause subscription
                    <InfoTooltip
                        isBlack
                        classNameWrapper="u-dsp--centered"
                        classNameIcon="u-mrg--lx1"
                        text={(
                            <>
                                You can select to pause your Vervoe account for up to 3 months. During this time your account and all associated data will be placed into inactive mode. You won't be able to invite candidates, create assessments or export data. You can unpause your account at any time from this page. After 3 months your account and billing cycle will automatically restart.
                            </>
                        )}
                    />
                </div>
            </Button>
            <PauseSubscriptionDialog
                pauseSubscriptionDialogOpen={pauseSubscriptionDialogOpen}
                handlePauseSubscriptionDialog={handlePauseSubscriptionDialog}
                getCompanyPlan={getCompanyPlan}
            />
        </>
    );
});

export default withStyles(styles)(SubscriptionInfoBlock);

import React from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { checkHasCompanyFeature } from 'helper/commonFunctions';

import styles from './styles';


const QuestionContentHeader = ({
    classes, handleChange, company,
    showSuggestedBanner, activeTab
}) => {
    const showQuestionBanks = checkHasCompanyFeature(company, 'QUESTION_BANKS');

    const tabList = [{
        label: 'Create',
        value: 0
    }];

    if (showSuggestedBanner) {
        tabList.splice(1, 0, {
            label: 'Suggested',
            value: 1
        });
    }

    if (showQuestionBanks) {
        tabList.splice(2, 0, {
            label: 'Saved',
            value: 2
        });
    }

    return (
        <div className={classes.tabWrapper}>
            <Tabs
                value={activeTab}
                classes={{ root: classes.tabs, indicator: classes.indicator }}
                onChange={handleChange}
            >
                {
                    tabList.map(({ label, value: tabValue }, index) => (
                        <Tab
                            disableRipple
                            key={index}
                            value={tabValue}
                            label={label}
                            classes={{
                                root: classes.tab,
                                selected: classes.tabSelected
                            }}
                        />
                    ))
                }
            </Tabs>
        </div>
    );
};

export default withStyles(styles)(withRouter(QuestionContentHeader));

import React from 'react';

// material components
import GoogleDocCase from '../GoogleDocCase';
import AutoGraded from '../../../AutoGraded';

const SpreadsheetAutoGraded = ({
    question, fetchQuestion, setSaving, classes,
    googleLink, flashMessage, googleDialogOpen,
    isCMS, ...other
}) => {
    const { googleDriveFile } = question;
    const googleViewLink = googleDriveFile && googleDriveFile.previewLink;

    return (
        <div>
            <GoogleDocCase
                googleLink={googleLink}
                {...other}
            />
            {
                googleViewLink && (
                    <AutoGraded
                        viewLink={googleViewLink}
                        question={question}
                        isOpen={googleDialogOpen}
                        fetchQuestion={fetchQuestion}
                        isCMS={isCMS}
                        setSaving={setSaving}
                        flashMessage={flashMessage}
                    />
                )
            }
        </div>
    );
};

export default SpreadsheetAutoGraded;

import React, { useContext } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { observer } from 'mobx-react-lite';

import VervoeLogo from '../VervoeLogo';
import MobileMenu from './MobileMenu';

import useStyles from './styles';
import AppcuesLaunchpadIcon from '../AppcuesLaunchpadIcon';
import { appCtx } from '../../../../appStore';

const MobileAppBar = observer(({ location: { pathname }, logoutUser }) => {
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [open, setOpen] = React.useState(false);

    const { company: { freeTrialExpired, isSubscriptionPaused } } = useContext(appCtx);

    if (isDesktop) return null;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return <>
        <AppBar
            id="app_header"
            position="fixed"
            enableColorOnDark
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                    size="large"
                >
                    <MenuIcon className={classes.menuIcon} />
                </IconButton>
                <div className="u-dsp--distribute u-wdt--100p">
                    <NavLink className={clsx(classes.mobileLink, pathname === '/marketplace' ? 'hidden-xs' : '')} to="/">
                        <VervoeLogo />
                    </NavLink>
                    <div className="hidden-sm" />
                    <AppcuesLaunchpadIcon className={classes.beamer} />
                </div>
            </Toolbar>
        </AppBar>
        <MobileMenu
            open={open}
            logoutUser={logoutUser}
            onClose={handleDrawerClose}
        />
    </>;
});

export default withRouter(MobileAppBar);

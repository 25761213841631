export default theme => ({
    wrapper: {
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        minHeight: 400,
        border: `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
        marginTop: 32,
        marginBottom: 36,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'block',
            paddingLeft: 55
        }
    },
    switch: {
        marginBottom: 0,
        marginRight: 0,
        marginTop: 3
    },
    leftPanel: {
        width: 400,
        minWidth: 400,
        paddingBottom: 50,
        backgroundColor: theme.palette.grey[50],
        position: 'relative',
        padding: '26px 33px',
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderRight: 'none',
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        }
    },
    contentPanel: {
        position: 'relative',
        padding: '26px 60px 40px',
        minWidth: 600,
        width: 'calc(100% - 400px)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '26px 30px 40px'
        }
    }
});

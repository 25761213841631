import React from 'react';
import { makeStyles } from '@mui/styles';
import NoAnswerIcon from 'img/no_answer.svg';

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: 600,
        minHeight: 200,
        flexDirection: 'column',
        textAlign: 'center',
        '& img': {
            width: 25,
            marginBottom: 10
        }
    }
}))

export default () => {
    const { wrapper } = useStyles();
    return (
        <div className={wrapper}>
            <img src={NoAnswerIcon} alt="no_answer" />
            No candidate answer
        </div>
    );
};

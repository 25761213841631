export default theme => ({
    wrapper: {
        position: 'relative',
        display: 'inline-block',
        height: 235,
        cursor: 'pointer',
        width: 372,
        backgroundColor: 'white',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: theme.palette.grey[600],
        fontWeight: 'bold',
        fontSize: 12,
        border: '1px solid #EBECEE',
        boxShadow: '0px 0px 24px rgba(37, 56, 78, 0.08)',
        borderRadius: 8,
        '&:hover': {
            boxShadow: '0 8px 17px 0 rgba(0,0,0,0.20), 0 6px 20px 0 rgba(0,0,0,0.19)',
            transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
        },
        [theme.breakpoints.down('xl')]: {
            width: 368
        },
        [theme.breakpoints.down('md')]: {
            width: 356
        },
        '@media only screen and (max-width: 805px)': {
            width: '100%',
            maxWidth: 320
        }
    },
    wrapperDisabled: {
        cursor: 'default',
        '&:hover': {
            boxShadow: '0px 0px 24px rgba(37, 56, 78, 0.08)'
        }
    }
});

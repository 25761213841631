export default theme => ({
    blockWrapper: {
        padding: '20px 33px',
        borderTop: `1px solid ${theme.palette.grey[300]}`
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 22
    },
    buttonWrapper: {
        minWidth: 190,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
});

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Checkbox from 'libraries/Checkbox';
import Tooltip from 'libraries/TooltipWhite';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import { checkCompanyCanBeUpgraded, checkHasCompanyFeature } from 'helper/commonFunctions';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import UnlockIcon from 'img/icons/unlock.svg';
import LockIcon from 'img/icons/lock.svg';
import ViewOffIcon from 'img/icons/view-off.svg';
import ViewIcon from 'img/icons/view.svg';
import { appCtx } from 'components/appStore';

import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import AlertIcon from './alert.svg';

import styles from './styles';

const IncludeShareLinkCheckbox = observer(({
    classes, checked,
    enabledScoring,
    onChange, handleChangeSwitch,
    disabledExportScoring
}) => {
    const { company } = useContext(appCtx);
    const hasFeatureIncludeCandidateShareLink = checkHasCompanyFeature(company, 'INCLUDE_CANDIDATE_SHARE_LINK_IN_CSV');
    const canUpgrade = checkCompanyCanBeUpgraded(company);

    return (
        <>
            <Checkbox
                data-tip
                data-for="share_link_tooltip"
                label="Include candidate share link"
                name="includeShareLink"
                checked={checked}
                styles={{ label: classes.checkboxLabel }}
                value="includeShareLink"
                onChange={onChange}
                helperText={(
                    <span>
                        <img src={AlertIcon} alt="!" className={classes.alertIcon} /> This will include a public link of the candidate.
                    </span>
                )}
                disabled={!hasFeatureIncludeCandidateShareLink}
            />
            {
                hasFeatureIncludeCandidateShareLink && (
                    <SwitchBrandWithLabel
                        label={(
                            <>
                                Enable scoring & hide existing scores
                                <InfoTooltip
                                    id="enable_scoring_export_tooltip"
                                    className={classes.tooltip}
                                    position="right"
                                    text={(
                                        <>
                                            <div className={classes.linkPermissionsLabel}>Link permissions on current setting:</div>
                                            <div className={classes.iconWithLabelWrapper}>
                                                <div className={classes.iconWrapper}>
                                                    <img src={!enabledScoring ? LockIcon : UnlockIcon} className={classes.lockImg} alt="Lock" />
                                                </div>
                                                <div>Guest scoring & comments { !enabledScoring ? 'disabled' : 'enabled' }</div>
                                            </div>
                                            <div className={classes.iconWithLabelWrapper}>
                                                <div className={classes.iconWrapper}>
                                                    <img src={!enabledScoring ? ViewIcon : ViewOffIcon} alt="View" />
                                                </div>
                                                <div>Scores, times & suggested answers are { !enabledScoring ? '' : 'not' } visible</div>
                                            </div>
                                        </>
                                    )}
                                />
                            </>
                        )}
                        disabled={disabledExportScoring}
                        checked={enabledScoring}
                        classNameLabel={classes.switchLabel}
                        className={classes.switchRoot}
                        onChange={handleChangeSwitch}
                    />
                )
            }
            {
                !hasFeatureIncludeCandidateShareLink && (
                    <Tooltip
                        id="share_link_tooltip"
                        delayHide={100}
                    >
                        The ability to export candidate share links is not available on the current plan.
                        {
                            canUpgrade
                                ? <SubscriptionsLink label="Upgrade" className={classes.talkToUsLabel} segmentLocation="export candidate share links tooltip" />
                                : (
                                    <div
                                        role="presentation"
                                        className={classes.talkToUsLabel}
                                        onClick={() => window.open('/subscriptions', '_blank')}
                                    >
                                        Upgrade.
                                    </div>
                                )
                        }
                    </Tooltip>
                )
            }
        </>
    );
});

export default withStyles(styles)(IncludeShareLinkCheckbox);

const styles = {
    wrapper: {
        overflowX: 'hidden',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    navArrows: {
        padding: 6,
        margin: '0 3px'
    },
    item: {
        borderRadius: 4,
        fontWeight: 'bold',
        fontSize: 16,
        minWidth: 28,
        height: 28,
        padding: '0 6px'
    },
    itemActive: {
        backgroundColor: '#F3F3F3',
        color: '#1A1B1E'
    }
};

export default styles;

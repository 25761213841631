import React from 'react';
import { withStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from 'libraries/Checkbox';
import TypesFactory from 'components/marketplace/EditQuestion/components/AnswerAttachment/components/TypesFactory';
import { MULTIPLE_CHOICE_OLD } from 'helper/constants';

const styles = theme => ({
    typeWrapper: {
        width: 340,
        maxWidth: '100%'
    },
    summaryContent: {
        margin: 0
    },
    expandIcon: {
        color: theme.palette.grey[400]
    },
    accordion: {
        boxShadow: 'none',
        wordBreak: 'break-word',
        '&$accordionExpanded': {
            '&:after': {
                content: '""',
                position: 'absolute',
                opacity: 1,
                bottom: -10,
                left: 0,
                right: 0,
                height: 1,
                transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundColor: 'rgba(0, 0, 0, 0.12)'
            },
            '&:before': {
                opacity: 1
            }
        }
    },
    accordionExpanded: {},
    summaryRoot: {
        '&$summaryDisabled': {
            backgroundColor: '#fff',
            opacity: 1
        }
    },
    summaryDisabled: {}

});

export default withStyles(styles)(({
    question: { question: { willBeGraded, answerType }, answerResults, grade },
    singleChoice, classes, hideScoreLabel
}) => {
    const hideGrading = MULTIPLE_CHOICE_OLD === answerType;
    const firstCheckedVariant = answerResults.findIndex(el => el.checked);

    return (
        <div>
            {
                Object.keys(answerResults).map((key) => {
                    const { checked, title, type, codeEditor: codeEditorValue } = answerResults[key];
                    const hasMedia = Boolean(type) && type !== 1;
                    return (
                        <Accordion
                            key={key}
                            classes={{
                                root: classes.accordion,
                                expanded: classes.accordionExpanded
                            }}
                            disabled={!hasMedia}
                        >
                            <AccordionSummary
                                classes={{ root: classes.summaryRoot, content: classes.summaryContent, disabled: classes.summaryDisabled }}
                                expandIcon={hasMedia ? <ExpandMoreIcon className={classes.expandIcon} /> : null}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {
                                    singleChoice ? (
                                        <FormControlLabel
                                            key={key}
                                            value="runTime"
                                            control={(
                                                <Radio
                                                    disabled
                                                    color="primary"
                                                    checked={firstCheckedVariant === +key}
                                                />
                                            )}
                                            label={title || ' '}
                                        />
                                    ) : (
                                        <Checkbox
                                            label={title}
                                            checked={checked}
                                            disabled
                                        />
                                    )
                                }
                            </AccordionSummary>
                            <AccordionDetails>
                                <TypesFactory {...answerResults[key]} codeEditorValue={codeEditorValue} />
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            }
            {
                !hideScoreLabel && willBeGraded && !hideGrading && (
                    <div className="u-txt--right  u-txt--nowrap  u-muted-6">
                        <small>
                            Question score: <b>{grade || 0}</b> of <b>10</b> points
                        </small>
                    </div>
                )
            }
        </div>
    );
});

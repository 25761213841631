const styles = theme => ({
    wrapper: {
        backgroundColor: theme.palette.red[50],
        marginTop: 12,
        marginBottom: 24,
        borderRadius: '4px',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        padding: '15px 16px 15px 21px',
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '18px',
        letterSpacing: 0.1,
        color: '#000',
        justifyContent: 'space-between'
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    text: {
        marginLeft: 16,
        marginRight: 30
    },
    img: {
        width: 16
    },
    link: {
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration: 'underline',
        color: theme.palette.red[400]
    }
});

export default styles;

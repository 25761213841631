import React, { useContext } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import CandidatesSearch from 'components/job_or_assessment_settings/CandidatesSearch';
import ExportButton from '../ExportButton';
import TableSort from '../TableSort';

import { assessmentSettingsCtx } from '../../store';
import { statsCtx } from '../../statsStore';

import useStyles from './styles';


const SearchFilterBlock = observer(({ match, context, TableFilterComp, hideTableSort, hideExportButton, className = '' }) => {
    const classes = useStyles();
    const {
        query, handleChangeFilter,
        totalCount, segmentEventContext
    } = useContext(context);
    const { audition } = useContext(assessmentSettingsCtx);
    const { isNoCandidates } = useContext(statsCtx);
    const { scriptSlug } = match.params;

    return (
        <div className={clsx(classes.infoWrapper, className)}>
            <CandidatesSearch
                className={classes.search}
                onChange={handleChangeFilter}
                value={query}
                audition={audition}
            />
            <div className="u-dsp--f u-ai--center">
                {
                    !hideExportButton && (
                        <ExportButton
                            isAssessment
                            disabled={isNoCandidates}
                            slug={scriptSlug}
                            opportunity={audition}
                            candidatesCount={totalCount}
                            segmentEventContext={segmentEventContext}
                        />
                    )
                }
                {TableFilterComp}
                {!hideTableSort && (
                    <TableSort
                        disabled={!audition.active}
                        disabledLabel={!audition.active ? 'This feature is not available for closed assessment' : ''}
                    />
                )}
            </div>
        </div>
    );
});

export default withRouter(SearchFilterBlock);

export default theme => ({
    questionHeader: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.grey[900],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 15,
        marginBottom: 24,
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    iconButton: {
        width: 40,
        height: 40,
        padding: 0
    },
    manuallyGradedLabel: {
        display: 'inline-block',
        padding: '0 10px',
        borderRadius: '12px',
        backgroundColor: '#e6e6e6',
        fontSize: 10,
        color: '#1a1b1e',
        lineHeight: '25px',
        height: '25px',
        margin: '4px 0 0 16px',
        textTransform: 'uppercase'
    },
    questionNumber: {
        display: 'flex'
    }
});

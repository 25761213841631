import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { withStyles } from '@mui/styles';
import TooltipBlack from 'libraries/Tooltip';
import Dialog from 'libraries/Dialog';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

import DownloadCSVRecursively from 'components/insight_components/download_csv_recursively';

import styles from './styles';

const FeedbackModal = ({ classes, open, loadDataRequest, loadParams, loadFilterRequest,
    onExportCSV, requestExportCsv, canDownloadCSV, onClose, renderItem, title, maxWidth, csvSlug = 'Candidate-feedback', disabledDownloadCsv }) => {
    const [dataList, setDataList] = useState({});
    const [filterData, setFilterData] = useState(null);
    const [dataListLoading, setDataListLoading] = useState(false);
    const [selectedScore, setSelectedScore] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (!open) return;
        loadData();
    }, [open, currentPage, selectedScore]);


    useEffect(() => {
        if (open) {
            loadDataFilter();
        } else {
            setCurrentPage(1);
            setSelectedScore('All');
            setDataList({});
            setFilterData(null);
        }
    }, [open]);

    const handleSelectedScore = (score) => {
        if (score === selectedScore) return;
        setDataList({});
        setCurrentPage(1);
        setSelectedScore(score);
    };

    const loadData = () => {
        setDataListLoading(true);

        let sendScore;
        if (selectedScore === 'All') {
            sendScore = null;
        } else if (selectedScore === 'No rating') {
            sendScore = 'null';
        } else {
            sendScore = selectedScore;
        }
        loadDataRequest({ ...loadParams, page: currentPage, score: sendScore })
            .then(({ success, data }) => {
                if (success && data) {
                    setDataList({
                        ...data,
                        items: dataList.items ? [...dataList.items, ...data.items] : data.items
                    });
                }
            })
            .finally(() => {
                setDataListLoading(false);
            });
    };

    const loadDataFilter = () => {
        loadFilterRequest(loadParams)
            .then(({ success, data }) => {
                if (success && data) {
                    setFilterData(data);
                }
            });
    };

    const loadMore = () => {
        if (!dataList.hasMorePages) return;
        setDataList({ ...dataList, hasMorePages: false });
        setCurrentPage(currentPage + 1);
    };

    return (
        <>
            <Dialog
                maxWidth={maxWidth || 'sm'}
                open={open}
                onClose={onClose}
                handleClose={onClose}
                titleComponent={title}
            >
                {
                    filterData && (
                        <div className="u-dsp--f u-ai--center u-jc--end">
                            <span className={classes.filterLabel}>Show Rating</span>
                            { filterData.map(({ count, score }) => (
                                <span
                                    role="presentation"
                                    className={clsx(classes.score, selectedScore === score && classes.selectedScore)}
                                    onClick={() => { handleSelectedScore(score); }}
                                >
                                    {score}
                                    <span className={classes.countLabel}>({count})</span>
                                </span>
                            )) }
                        </div>
                    )
                }
                { canDownloadCSV
                    && (disabledDownloadCsv ? (
                        <div className={classes.disabledTooltip}>
                            <TooltipBlack label={disabledDownloadCsv}>
                                <div>
                                    <DownloadCSVRecursively
                                        onExportCSV={onExportCSV}
                                        request={requestExportCsv}
                                        params={loadParams}
                                        filename={csvSlug}
                                        disabled
                                    />
                                </div>
                            </TooltipBlack>
                        </div>
                    ) : (
                        <DownloadCSVRecursively
                            onExportCSV={onExportCSV}
                            request={requestExportCsv}
                            params={loadParams}
                            filename={csvSlug}
                        />
                    )) }
                <InfiniteScroll
                    pageStart={0}
                    useWindow={false}
                    loadMore={loadMore}
                    threshold={0}
                    hasMore={dataList.hasMorePages && !dataListLoading}
                >
                    {
                        dataList.items && dataList.items.map(renderItem)
                    }
                </InfiniteScroll>
                {
                    dataListLoading && (
                        <div className="u-txt--center u-mrg--tx3">
                            <CircularProgress color="primary" size={20} />
                        </div>
                    )
                }
            </Dialog>
        </>
    );
};

export default withStyles(styles)(withRouter(FeedbackModal));

export default theme => ({
    tabWrapper: {
        backgroundColor: '#fff',
        width: '100%',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    tab: {
        position: 'relative',
        textTransform: 'none',
        color: theme.palette.grey[500],
        fontSize: 14,
        padding: '14px 25px',
        letterSpacing: '0.2px',
        fontWeight: 600,
        minWidth: 'auto',
        '&:not(:last-child):after': {
            content: '""',
            position: 'absolute',
            width: 1,
            backgroundColor: theme.palette.grey[300],
            right: 0,
            top: 15,
            height: 24
        },
        '&:first-child': {
            padding: '14px 25px 14px 0'
        }
    },
    indicator: {
        display: 'none'
    },
    tabSelected: {
        color: theme.palette.grey[900],
        fontWeight: 700
    }
});

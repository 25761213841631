import React from 'react';
import { withTheme } from '@mui/styles';

const PreviewQuestionIcon = ({ theme, size, color = theme.palette.grey[500] }) => (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 20.6361C14 20.6361 15.8182 16.9997 19 16.9997C22.1818 16.9997 24 20.6361 24 20.6361C24 20.6361 22.1818 24.2724 19 24.2724C15.8182 24.2724 14 20.6361 14 20.6361Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.9999 21.9997C19.753 21.9997 20.3635 21.3892 20.3635 20.636C20.3635 19.8829 19.753 19.2724 18.9999 19.2724C18.2468 19.2724 17.6362 19.8829 17.6362 20.636C17.6362 21.3892 18.2468 21.9997 18.9999 21.9997Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21 14.5V5.99997C21 4.8954 20.1046 3.99997 19 3.99997H7C5.89543 3.99997 5 4.8954 5 5.99997V21.8571C5 22.9617 5.89543 23.8571 7 23.8571H14" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 20.6361C14 20.6361 15.8182 16.9997 19 16.9997C22.1818 16.9997 24 20.6361 24 20.6361C24 20.6361 22.1818 24.2724 19 24.2724C15.8182 24.2724 14 20.6361 14 20.6361Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.9999 21.9997C19.753 21.9997 20.3635 21.3892 20.3635 20.636C20.3635 19.8829 19.753 19.2724 18.9999 19.2724C18.2468 19.2724 17.6362 19.8829 17.6362 20.636C17.6362 21.3892 18.2468 21.9997 18.9999 21.9997Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21 14.5V5.99997C21 4.8954 20.1046 3.99997 19 3.99997H7C5.89543 3.99997 5 4.8954 5 5.99997V21.8571C5 22.9617 5.89543 23.8571 7 23.8571H14" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 11H17.1667" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 16.791H13.0833" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 13.8955H17.1667" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(PreviewQuestionIcon);

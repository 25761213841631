export default theme => ({
    wrapper: {
        fontSize: 10,
        padding: '5px 12px',
        fontWeight: 700,
        borderRadius: 55,
        textTransform: 'capitalize',
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[900],
        maxHeight: 24,
        lineHeight: 1.1,
        display: 'inline-block'
    },
    hoveredWrapper: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[600]
    },
    loader: {
        color: '#fff'
    }
});

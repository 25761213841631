import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import Dialog from 'libraries/Dialog';
import { getCandidateCompareModeInfo, getQuestionsToCompare } from 'requests/AssessmentRequests';

import ShortlistCardHeader from '../ShortlistCardHeader';
import CandidateActions from './CandidateActions';
import AnswersContent from './AnswersContent';
import SkillsContent from './ScoreContent';
import CommentsContainer from './CommentsContainer';
import TagContent from './TagContent';

import { assessmentSettingsCtx } from '../../../store';
import { candidateResultCtx } from '../../common/CandidateListWrapper/candidatesStore';
import { shortlistCtx } from '../shortlistStore';

import useStyles from './styles';


const CompareDialog = observer(({ open, handleClose }) => {
    const classes = useStyles();
    const [candidatesData, setCandidatesData] = useState({});
    const [loadingCandidates, setLoadingCandidates] = useState({});
    const { candidatesIdsToCompare, removeCandidateCompare } = useContext(shortlistCtx);
    const { audition } = useContext(assessmentSettingsCtx);
    const { handleChangePage } = useContext(candidateResultCtx);
    const { slug, opportunitySlug } = audition;

    const compareLength = Object.keys(candidatesIdsToCompare).length;

    useEffect(() => {
        if (open) {
            setCandidatesData({});
            setLoadingCandidates({});
            Object.keys(candidatesIdsToCompare).forEach((id) => {
                getUserInfo(id);
            });
        }
    }, [open]);

    const getAnswersContent = () => getQuestionsToCompare(slug);

    const getUserInfo = (userId) => {
        setLoadingCandidates(oldLoadingCandidates => ({ ...oldLoadingCandidates, [userId]: true }));
        getCandidateCompareModeInfo(slug, userId)
            .then(({ success, data }) => {
                if (success) {
                    setCandidatesData(oldCandidatesData => ({ ...oldCandidatesData, [userId]: data }));
                }
            })
            .catch(() => {
                handleClose();
            })
            .finally(() => {
                setLoadingCandidates((oldLoadingCandidates) => {
                    const newLoadingCandidates = { ...oldLoadingCandidates };
                    delete newLoadingCandidates[userId];
                    return newLoadingCandidates;
                });
            });
    };

    // struct { userUuid: name }
    const candidatesUuidsToCompare = Object.keys(candidatesIdsToCompare).reduce((prev, id) => {
        const newObj = {};
        if (candidatesData[id]) newObj[candidatesData[id].candidateUuid] = candidatesData[id].fullName;
        return ({ ...prev, ...newObj });
    }, {});

    return (
        <Dialog
            maxWidth="lg"
            handleClose={handleClose}
            open={open}
            fullScreen
            onClose={handleClose}
            contentClassName="u-pdn--x0 u-mrg--tx0 u-txt--14 u-dsp--f"
            titleComponent={`Compare Candidates (${compareLength})`}
            titleClassName={classes.dialogTitle}
        >
            <div>
                <div className="u-dsp--f">
                    <div className={clsx(classes.category, classes.candidateCategory)}>Candidate</div>
                    {
                        Object.keys(candidatesIdsToCompare).map(id => (
                            id in loadingCandidates ? (
                                <div key={id} className={classes.candidateHeader}>
                                    <CircularProgress
                                        size={15}
                                        className={classes.loader}
                                        color="primary"
                                    />
                                </div>
                            ) : (
                                <div key={id} className={classes.candidateHeader}>
                                    <CandidateActions
                                        candidate={candidatesData[id]}
                                        removeFromCompare={removeCandidateCompare}
                                        audition={audition}
                                        onRemoveFromShortlist={() => {
                                            handleChangePage(1);
                                        }}
                                    />
                                    <ShortlistCardHeader
                                        candidate={candidatesData[id]}
                                    />
                                </div>
                            )
                        ))
                    }
                </div>
                <div className={classes.wrapper}>
                    <div className="u-dsp--f">
                        <div className={classes.category}>Skill Scores</div>
                        {
                            Object.keys(candidatesIdsToCompare).map(id => (
                                <div className={classes.candidateWrapper} key={id}>
                                    <SkillsContent
                                        candidate={candidatesData[id]}
                                        isLoading={id in loadingCandidates}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <div className="u-dsp--f">
                        <div className={classes.category}>Tags</div>
                        {
                            Object.keys(candidatesIdsToCompare).map(id => (
                                <div className={classes.candidateWrapper} key={id}>
                                    <TagContent
                                        candidate={candidatesData[id]}
                                        isLoading={id in loadingCandidates}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <div className="u-dsp--f">
                        <div className={classes.category}>Comments</div>
                        {
                            Object.keys(candidatesIdsToCompare).map(id => (
                                <div className={classes.candidateWrapper} key={id}>
                                    <CommentsContainer
                                        userId={id}
                                        opportunitySlug={opportunitySlug}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <div className="u-dsp--f">
                        <div className={classes.category}>Answers</div>
                        <AnswersContent
                            candidatesIdsToCompare={candidatesUuidsToCompare}
                            auditionSlug={slug}
                            audition={audition}
                            getAnswersContent={getAnswersContent}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
});

export default withRouter(CompareDialog);

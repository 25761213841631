import React from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { timeObjectToFormattedString } from 'helper/commonFunctions';
import Tooltip from 'libraries/Tooltip';
import TimerLabel from '../timer_label';

const useStyles = makeStyles({
    timeStatusWrapper: {
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: 12
    }
});

const InterviewStatus = ({ interview, className }) => {
    const { timeStatusWrapper } = useStyles({});
    if (!interview) return null;
    const {
        expirationTimeStatus, timerEnabled,
        timerCount, expirationDate
    } = interview;

    const tooltipLabel = moment(expirationDate).format('MMMM Do YYYY, h:mm a');

    return (
        <div className={`${timeStatusWrapper} ${className}`}>
            {
                timerEnabled
                    ? (
                        <TimerLabel
                            value={timerCount * 60}
                            totalValue={timerCount * 60}
                        />
                    ) : (
                        <>
                            {
                                expirationTimeStatus && (
                                    <Tooltip
                                        label={`Assessment expires on ${tooltipLabel}`}
                                        placement="bottom-end"
                                    >
                                        <div>
                                            {timeObjectToFormattedString(expirationTimeStatus)} remaining
                                        </div>
                                    </Tooltip>
                                )
                            }
                        </>
                    )
            }
        </div>
    );
};

export default InterviewStatus;

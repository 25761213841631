import React from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Zendesk from 'helper/zendeskFunctions';

const styles = theme => ({
    span: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    wrapper: {
        maxWidth: 550
    }
});

const TalkToSalesEmptyContent = observer(({ classes, integration }) => {
    const openZendesk = () => {
        Zendesk.open(true);
    };

    return (
        <div className={classes.wrapper}>
            The {integration} integration is not available on the current plan. To enable you will need to <span role="presentation" className={classes.span} onClick={openZendesk}>talk to sales</span>
        </div>
    );
});

export default withStyles(styles)(TalkToSalesEmptyContent);

import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import SeekIcon from './assets/seek';
import ZapierIcon from './assets/zapier';
import GreenhouseIcon from './assets/greenhouse';
import PageUpIcon from './assets/pageup';
import VervoeAPIIcon from './assets/vervoeAPI';
import ComeetIcon from './assets/comeet';
import LeverIcon from './assets/lever';
import WorkdayIcon from './assets/workday';
import SmartRecruitersIcon from './assets/smartRecruiters';
import JobAdderIcon from './assets/jobAdder';
import IcimsIcon from './assets/icims';
import JobViteIcon from './assets/jobVite';
import OracleIcon from './assets/oracle';

import styles from './styles';

const getImgUrl = (item, isActive) => ({
    Seek: <SeekIcon isActive={isActive} />,
    Greenhouse: <GreenhouseIcon isActive={isActive} />,
    'Vervoe API': <VervoeAPIIcon isActive={isActive} />,
    PageUp: <PageUpIcon isActive={isActive} />,
    Zapier: <ZapierIcon isActive={isActive} />,
    Comeet: <ComeetIcon isActive={isActive} />,
    Workday: <WorkdayIcon isActive={isActive} />,
    'SmartRecruiters v2': <SmartRecruitersIcon isActive={isActive} />,
    iCIMS: <IcimsIcon isActive={isActive} />,
    JobAdder: <JobAdderIcon isActive={isActive} />,
    Lever: <LeverIcon isActive={isActive} />,
    Jobvite: <JobViteIcon isActive={isActive} />,
    Oracle: <OracleIcon isActive={isActive} />
}[item]);

class IntegrationsManagement extends React.Component {
    renderListItems = () => {
        const { activeIntegration, integrations, handleListItemChange, classes } = this.props;

        return integrations.map((item, index) => {
            const isActive = activeIntegration === item;
            const imgUrl = getImgUrl(item, isActive);
            return (
                <div
                    key={index}
                    role="presentation"
                    onClick={() => handleListItemChange(item)}
                    className={clsx(classes.listItemStyle, isActive && classes.listItemActiveStyle)}
                >
                    {imgUrl || item}
                </div>
            );
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.wrapper}>
                {this.renderListItems()}
            </div>
        );
    }
}

export default withStyles(styles)(IntegrationsManagement);

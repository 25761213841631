import React from 'react';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    header: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 13,
        color: theme.palette.grey[900]
    },
    hr: {
        borderTop: '2px solid #EBECEE'
    }
});

export default withStyles(styles)(({ classes,
    question: { question: { willBeGraded },
        correctNumberResult: { actualNumber, correctNumber } },
    NoAnswerComponent
}) => (
    <div>
        <p>{ actualNumber || <NoAnswerComponent /> }</p>
        <hr className={classes.hr} />
        <div>
            <p className={classes.header}>Correct answer</p>
            <p className="u-mrg--bx0">{ correctNumber }</p>
            {
                willBeGraded && (
                    <div className="u-txt--right u-pdn--bx2 u-txt--nowrap  u-muted-6">
                        <small>
                            Question score: <b>{actualNumber === correctNumber && actualNumber ? 10 : 0}</b> of <b>10</b> points
                        </small>
                    </div>
                )
            }
        </div>
    </div>
));

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';

import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import { Typography } from '@mui/material';
import { codeGeneratorCtx } from '../../../../store';
import SingleValueInput from '../SingleValueInput';
import ArrayInput from '../ArrayInput';
import TwoDimensionArrayInput from '../2DArrayInput';
import TwoDimensionArrayOutput from '../2DArrayOutput';

import styles from './styles';


const TestCaseGenerator = observer(({ classes, enabled, setEnabled, handleInputOutputChange, testCaseInput, testCaseOutput }) => {
    const { funcReturnType, funcParameters, funcName } = useContext(codeGeneratorCtx);
    const [parametersValues, setParametersValues] = useState([]);
    const [notFirstOpen, setNotFirstOpen] = useState(false);

    const disabled = !funcName.length;

    useEffect(() => {
        if (notFirstOpen) {
            handleInputOutputChange(parametersValues.join('\n'), testCaseOutput);
        } else {
            setNotFirstOpen(true);
        }
    }, [parametersValues]);

    useEffect(() => {
        if (enabled) transformTestCaseInputIntoGeneratorInputs();
    }, [enabled]);

    const transformTestCaseInputIntoGeneratorInputs = () => {
        const inputLines = testCaseInput.split('\n');
        let counter = 0;
        const newParamsValues = [];
        funcParameters.forEach(({ type }, index) => {
            if (type.includes('ARRAY')) {
                const is2dArray = type.includes('2D_ARRAY');

                // to prevent falldown, maximum rows in 2d array is 20
                if (Number.isInteger(is2dArray && +inputLines[counter]) && +inputLines[counter] > 20) {
                    inputLines[counter] = 1;
                }

                // eslint-disable-next-line
                const arr = Array
                    .apply(null, { length: (Number.isInteger(+inputLines[counter]) && +inputLines[counter] < 21) ? +inputLines[counter] : 1 })
                    .map((item, i) => {
                        if (inputLines[counter + i + (is2dArray ? 2 : 1)] === 'undefined') return '';
                        return inputLines[counter + i + (is2dArray ? 2 : 1)];
                    });

                if (is2dArray) arr.unshift(`${inputLines[counter + 1] || 1}`);
                arr.unshift(`${inputLines[counter] || 1}`);

                newParamsValues[index] = arr.join('\n');

                if (is2dArray) {
                    counter += Number.isInteger(+inputLines[counter]) ? (+inputLines[counter] + 2) : 3;
                } else {
                    counter += Number.isInteger(+inputLines[counter]) ? (+inputLines[counter] + 1) : 2;
                }
            } else {
                newParamsValues[index] = inputLines[counter] || '';
                counter += 1;
            }
        });
        setParametersValues(newParamsValues);
    };

    const handleChangeSwitch = () => {
        if (!enabled) transformTestCaseInputIntoGeneratorInputs();
        setEnabled(!enabled);
    };

    const returnTestCaseInputComponent = (type, paramIndex, value = '') => {
        const props = { type, paramIndex, value, onChangeValue: onChangeParamValue };

        if (type.includes('2D')) return <TwoDimensionArrayInput {...props} />;
        if (type.includes('ARRAY')) return <ArrayInput {...props} />;
        return <SingleValueInput {...props} />;
    };

    const returnTestCaseOutputComponent = (type, value = '') => {
        const props = { type, value, onChangeValue: onChangeOutput };

        if (type.includes('2D')) return <TwoDimensionArrayOutput {...props} />;
        if (type.includes('ARRAY')) return <ArrayInput isOutput {...props} />;
        return <SingleValueInput {...props} />;
    };

    const onChangeParamValue = (newValue, index) => {
        const newParametersValues = [...parametersValues];
        newParametersValues[index] = newValue;
        setParametersValues(newParametersValues);
    };

    const onChangeOutput = (newOutput) => {
        handleInputOutputChange(parametersValues.join('\n'), newOutput);
    };

    return <>
        <SwitchBrandWithLabel
            className={classes.switch}
            label="Enable test case generator"
            checked={enabled}
            onChange={handleChangeSwitch}
            disabled={disabled}
        />
        {disabled && <p>Please enter function parameters first and generate the code to create the test cases.</p>}
        {
            enabled && funcParameters.map(({ name, type }, index) => (
                <div className="u-mrg--bx2">
                    <div className="u-dsp--f u-jc--sb u-ai--center">
                        <Typography variant="h6">Function Parameter {index + 1}: {name}</Typography>
                        <span className={classes.parameter}>{type}</span>
                    </div>
                    {returnTestCaseInputComponent(type, index, parametersValues[index])}
                </div>
            ))
        }
        {enabled && <>
            <div className="u-dsp--f u-jc--sb u-ai--center u-mrg--tx5">
                <Typography variant="h6">Expected Output</Typography>
                <span className={classes.parameter}>{funcReturnType}</span>
            </div>
            {returnTestCaseOutputComponent(funcReturnType, testCaseOutput)}
        </>}
    </>;
});

export default withStyles(styles)(TestCaseGenerator);

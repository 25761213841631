import React from 'react';

export default function IEBanner({ color }) {
    const backColor = color || '#1247F6';
    return (
        <div className="c-IE-banner" style={{ backgroundColor: backColor }}>
            <p>Unfortunately we do not support Internet Explorer. Please use Chrome or Firefox instead.</p>
        </div>
    );
}

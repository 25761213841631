import React, { useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import QuestionNumberLabel from 'components/candidate_cards/QuestionNumberLabel';
import QuestionSkillLabel from 'components/candidate_cards/QuestionSkillLabel';
import QuestionContent from 'components/candidate_cards/QuestionContent';
import { QA_SIMULATOR, SALES_SIMULATOR, MARKETING_SIMULATOR } from 'helper/constants';

import Answers from './Answers';
import CompareNotAvailable from './CompareNotAvailable';

import useStyles from './styles';

const QuestionItem = ({ questionIndex, question, candidatesIdsToCompare, propPublic, auditionSlug, audition }) => {
    const classes = useStyles();
    const [openedContent, setOpenedContent] = useState(false);

    const handleOpen = () => {
        setOpenedContent(!openedContent);
    };

    const { questionGroup, type } = question;

    const returnedAudition = { ...audition };

    if (propPublic) {
        returnedAudition.permissions = { edit: false };
    }

    return <>
        <div role="presentation" className={classes.wrapper} onClick={handleOpen}>
            <IconButton className={classes.moreButton} size="large">
                <ExpandMoreIcon className={clsx(classes.moreButtonIcon, openedContent && classes.moreButtonIconOpened)} />
            </IconButton>
            <QuestionNumberLabel
                question={question}
                questionNumber={questionIndex + 1}
            />
            {
                questionGroup && <QuestionSkillLabel skill={questionGroup.title} className={classes.skillName} />
            }
            <QuestionContent
                question={question}
                questionNumber={questionIndex + 1}
                audition={returnedAudition}
            />
        </div>
        {
            openedContent && <>
                    {[SALES_SIMULATOR, MARKETING_SIMULATOR, QA_SIMULATOR].includes(type)
                        ? <CompareNotAvailable type={type} />
                        : (
                            <div className="u-dsp--f">
                                {Object.keys(candidatesIdsToCompare).map(candidateId => (
                                    <Answers
                                        key={candidateId}
                                        userName={candidatesIdsToCompare[candidateId]}
                                        candidateId={candidateId}
                                        auditionSlug={auditionSlug}
                                        hashUuid={question.hashUuid}
                                        questionIndex={questionIndex}
                                        propPublic={propPublic}
                                        timerEnabled={audition.timerEnabled}
                                    />
                                ))}
                            </div>
                        )
                    }
            </>
        }
    </>;
};

export default withRouter(QuestionItem);

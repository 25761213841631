export default theme => ({
    divider: {
        height: '24px',
        width: 1,
        marginLeft: 8,
        border: `0.5px solid ${theme.palette.grey[300]}`
    },
    actionDialogButton: {
        fontWeight: 700,
        paddingLeft: 50,
        paddingRight: 50
    },
    groupWrapper: {},
    groupHeader: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontStyle: 'normal',
        padding: 0,
        fontStretch: 'normal',
        lineHeight: 'normal',
        textTransform: 'capitalize',
        letterSpacing: 'normal'
    },
    groupTitle: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: 1.2
    },
    questionsCountLabel: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: 1.2,
        marginLeft: 9,
        color: theme.palette.grey[400]
    },
    accordion: {
        boxShadow: 'none'
    },
    groupWrapperClosed: {
        '&:not(:last-of-type)': {
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        }
    },
    accordionSummaryContent: {
        margin: '22px 0',
        width: '100%'
    },
    expandIconButton: {
        position: 'absolute',
        right: 67,
        top: 23
    },
    expandIcon: {
        color: theme.palette.grey[400]
    }
});

import React from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
    iconButton: {
        position: 'absolute',
        top: -40,
        right: -10,
        padding: 5
    },
    paper: {
        overflowY: 'visible'
    }
};

const VideoSimulatorDialog = observer(({ question, open, onClose, link, classes }) => (
    <Dialog
        maxWidth={false}
        open={open}
        classes={{ paper: classes.paper }}
        onClose={onClose}
    >
        <IconButton onClick={onClose} className={classes.iconButton} size="large">
            <CloseIcon fontSize="large" />
        </IconButton>
        <iframe
            src={link}
            width="640"
            height="360"
            frameBorder="0"
            title={`simulation_${question.id}`}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
        />
    </Dialog>
));

export default withStyles(styles)(VideoSimulatorDialog);

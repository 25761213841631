import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { clone } from 'helper/commonFunctions';
import SelectOutlined from 'libraries/Select';
import PlusIcon from 'img/add.svg';
import { addSkillToCompanyQuestion, getListOfSkills, removeSkillFromCompanyQuestion } from 'requests/CMSRequests';
import Delete from '@mui/icons-material/Close';
import RemoveSkillDialog from './RemoveSkillDialog';

const styles = {
    wrapper: {
        marginTop: 15,
        marginBottom: 15
    },
    removeIcon: {
        height: 35,
        width: 35,
        marginLeft: 10,
        '& svg': {
            height: 25,
            width: 25
        }
    },
    optionSkill: {
        maxWidth: 560
    }
};

const SkillInputCMS = ({
    classes, question: { id: questionId, companySkills },
    setSaving, setQuestion
}) => {
    const [companySkillsList, setCompanySkillsList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showEmptySelect, setShowEmptySelect] = useState(false);
    const [skillsList, setSkillsList] = useState([]);
    const [openDeleteSkill, setOpenDeleteSkill] = useState(null);


    useEffect(() => {
        setLoading(true);
        loadSkills();
        setCompanySkillsList(companySkills);
    }, [questionId]);

    const onOpen = () => {
        getListOfSkills()
            .then(({ success, data }) => {
                if (success && data) {
                    setSkillsList(data);
                }
            });
    };

    const loadSkills = () => {
        getListOfSkills()
            .then(({ success, data }) => {
                if (success && data) {
                    setSkillsList(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeDeleteSkillDialog = () => {
        setOpenDeleteSkill(null);
    };

    const handleAddNew = () => {
        setShowEmptySelect(true);
    };

    const addNewSkill = (e) => {
        const { value } = e.target;
        if (value === '-') return;
        addSkillToCompanyQuestion(questionId, value)
            .then(({ success, data }) => {
                if (success && data) {
                    const skill = skillsList.find(({ id }) => id === value);
                    setCompanySkillsList([...companySkillsList, skill]);
                    setShowEmptySelect(false);
                    setQuestion(data);
                }
            });
    };

    const deleteSkill = () => {
        setSaving(true);
        setDeleteLoading(true);
        const { id: skillId } = openDeleteSkill;
        removeSkillFromCompanyQuestion(questionId, skillId)
            .then(({ success, data }) => {
                if (success && data) {
                    const skillIndex = companySkillsList.findIndex(({ id }) => id === skillId);
                    const newAnswers = clone(companySkillsList);
                    newAnswers.splice(skillIndex, 1);
                    setCompanySkillsList(newAnswers);
                    setShowEmptySelect(false);
                    setOpenDeleteSkill(null);
                    setQuestion(data);
                }
            })
            .finally(() => {
                setDeleteLoading(false);
                setSaving(false);
            });
    };

    if (isLoading) {
        return (
            <CircularProgress size={20} />
        );
    }


    const filteredSkills = skillsList
        .filter(({ id }) => !companySkillsList.some(({ id: companyListId }) => companyListId === id))
        .map(({ id, title }) => ({ label: title, key: id, value: id, className: classes.optionSkill }));

    return (
        <div className={classes.wrapper}>
            {
                companySkillsList.map(({ id, title }) => (
                    <div className="u-dsp--distribute" key={id}>
                        <SelectOutlined
                            value={id}
                            variant="outlined"
                            onOpen={onOpen}
                            label="Skill"
                            disabled
                            options={[{ value: id, label: title, key: id }].concat(filteredSkills)}
                        />
                        <IconButton
                            className={classes.removeIcon}
                            onClick={() => setOpenDeleteSkill({ id, title })}
                            size="large"
                        >
                            <Delete color="primary" />
                        </IconButton>
                    </div>
                ))
            }
            {
                Boolean(filteredSkills.length) && (
                    <>
                        {
                            showEmptySelect ? (
                                <SelectOutlined
                                    value="-"
                                    variant="outlined"
                                    onOpen={onOpen}
                                    label="Skill"
                                    options={[{ label: 'No Skill', value: '-' }].concat(filteredSkills)}
                                    onChange={addNewSkill}
                                />
                            ) : (
                                <Button className="u-txt--bold u-mrg--tx1 u-mrg--bx2" onClick={handleAddNew}>
                                    <img src={PlusIcon} className="u-mrg--rx1" alt="+" /> Add to {Boolean(companySkillsList.length) && 'another '}skill
                                </Button>
                            )
                        }
                    </>
                )
            }
            <RemoveSkillDialog
                open={Boolean(openDeleteSkill)}
                loading={deleteLoading}
                skillName={openDeleteSkill ? openDeleteSkill.title : ''}
                onClose={closeDeleteSkillDialog}
                onRemove={deleteSkill}
            />
        </div>
    );
};

export default withStyles(styles)(SkillInputCMS);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { capitalizeEachFirstLetter } from 'helper/commonFunctions';
import PanelHeader from '../../../../../../components/assessments_pages/asessment_settings_components/SectionHeader';
import SidebarFooter from './SidebarFooter';
import SkillsFilter from './SkillsFilter';
import QuestionsList from './QuestionsList';
import ScreeningList from './ScreeningList';
import { assessmentEditCtx } from '../../store';
import { assessmentSettingsCtx } from '../../../../store';

import styles from './styles';


const SidebarContainer = observer(({ classes }) => {
    const { selectedSkill, setSelectedSkill, setActiveQuestionBySkill } = useContext(assessmentEditCtx);
    const { audition } = useContext(assessmentSettingsCtx);

    const handleSelectedSkill = (value) => {
        setActiveQuestionBySkill(value);
        setSelectedSkill(value);
    };

    const { questionGroups, scoreType, slug } = audition;
    const skillOptions = scoreType === 3 ? (
        questionGroups.map(({ title: value }) => ({ value, label: capitalizeEachFirstLetter(value), className: classes.skillOption }))
    ) : [];

    return (
        <div className={classes.wrapper}>
            <div className={classes.headerWrapper}>
                <PanelHeader>Assessment Questions</PanelHeader>
                <ScreeningList slug={slug} />
            </div>
            {
                scoreType === 3 && (
                    <SkillsFilter
                        onChange={handleSelectedSkill}
                        filterCategory={selectedSkill}
                        options={[{ value: 'all', label: 'Show all' }].concat(skillOptions)}
                    />
                )
            }
            <QuestionsList />
            <SidebarFooter />
        </div>
    );
});

export default withStyles(styles)(SidebarContainer);

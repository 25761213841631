import BackImg from './back.png';

export default theme => ({
    recordWrapper: {
        height: 410,
        marginTop: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        fontWeight: 'bold',
        borderRadius: 4,
        border: `2px solid ${theme.palette.grey[400]}`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${BackImg})`,
        '@media only screen and (max-width: 767px)': {
            height: 196
        }
    },
    svgIconStyle: {
        width: 43
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
        width: 77,
        marginBottom: 10,
        padding: '7px 15px',
        backgroundColor: theme.palette.grey[400]
    },
    startButton: {
        backgroundColor: '#fff',
        color: theme.palette.blue[600],
        fontWeight: 700,
        padding: '6px 24px 6px 18px',
        boxShadow: '0px 0px 40px rgba(41, 42, 45, 0.15)'
    }
});

import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Select from 'libraries/Select';
import { editContentLibraryAssessment } from 'requests/CMSRequests';
import { appCtx } from 'components/appStore';

import { observer } from 'mobx-react-lite';
import styles from './styles';


const LevelSelect = observer(({ classes, audition: { level }, changeAssessmentSettings }) => {
    const [assessmentLevel, setAssessmentLevel] = useState(level);
    const { flashMessage } = useContext(appCtx);

    const handleLevel = (e) => {
        const { value } = e.target;
        setAssessmentLevel(value);
        changeAssessmentSettings({ level: value })
            .then(() => {
                flashMessage('Level changed', 'done');
            });
    };

    return (
        <Select
            value={assessmentLevel}
            name="size"
            onChange={handleLevel}
            label="Level"
            placeholder="Level"
            fullWidth
            className="u-mrg--bx5"
            variant="outlined"
            menuPaperClassName={classes.selectPaper}
            options={[
                { label: 'No level', value: 'no_level' },
                { label: 'Junior', value: 'junior' },
                { label: 'Mid', value: 'mid' },
                { label: 'Senior', value: 'senior' }
            ]}
        />
    );
});

export default withStyles(styles)(withRouter(LevelSelect));

import React, { useContext, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import SidebarContainer from './components/SidebarContainer';
import styles from '../styles';
import QuestionContent from './components/QuestionContent';
import QuestionPreviewLocked from './components/QuestionPreviewLocked';
import { assessmentEditCtx } from './store';

const CreateScriptQuestions = observer(({
    match: { params: { scriptSlug } }, classes,
    setSaving, company, setLoading, history
}) => {
    const { activeQuestionIndex, questions, reset, loadQuestions } = useContext(assessmentEditCtx);

    const { audition } = useContext(assessmentSettingsCtx);

    useEffect(() => {
        loadData(scriptSlug);
        return () => {
            reset();
        };
    }, [scriptSlug]);

    const loadData = (slug) => {
        setLoading(true);

        loadQuestions(slug || scriptSlug)
            .catch(() => {
                history.push('/marketplace');
            }).finally(() => {
                setLoading(false);
            });
    };

    const canEditQuestionsFromPublicLibrary = checkHasCompanyFeature(company, 'EDIT_QUESTIONS_FROM_PUBLIC_LIBRARY');
    const question = questions.length && questions[activeQuestionIndex];

    return (
        <>
            <div className={classes.leftPanel}>
                <SidebarContainer />
            </div>
            {
                Boolean(question) && (
                    <div className={classes.contentPanel}>
                        {question.fromPublicLibrary && !canEditQuestionsFromPublicLibrary
                            ? (
                                <QuestionPreviewLocked
                                    interview={audition}
                                    question={question}
                                    activeQuestionIndex={activeQuestionIndex}
                                />
                            )
                            : (
                                <QuestionContent
                                    question={question}
                                    setSaving={setSaving}
                                />
                            )}
                    </div>
                )
            }
        </>
    );
});

export default withStyles(styles)(CreateScriptQuestions);

import React from 'react';
import { ANY_FILE } from 'helper/constants';


const Svg = (onClick, className) => (
    <svg
        className={className}
        onClick={e => onClick(e, ANY_FILE)}
        width="18"
        height="23"
        viewBox="0 0 18 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M5.5 21H3C1.89543 21 1 20.1046 1 19V3C1 1.89543 1.89543 1 3 1H12.5056C13.0204 1 13.5154 1.19852 13.8875 1.55425L16.382 3.93864C16.7767 4.31597 17 4.83831 17 5.38439V19C17 20.1046 16.1046 21 15 21H12.5" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.00565 22.5L9.00565 10M9.00565 10L6 13.9675M9.00565 10L12.0113 13.9675" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Svg;

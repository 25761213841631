import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { nl2brCustom } from 'helper/commonFunctions';
import Truncate from 'react-truncate';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RequiredQuestionLabel from 'components/job_or_assessment_settings/Chip';

import styles from './styles';

class ImmersiveDialog extends Component {
    state = {
        titleOpened: false,
        truncated: false
    };

    toggleTitleOpen = (e) => {
        const { titleOpened } = this.state;
        e.preventDefault();
        this.setState({ titleOpened: !titleOpened });
    };

    handleTruncate = (trunc) => {
        const { truncated } = this.state;
        if (trunc && !truncated) {
            this.setState({ truncated: trunc, titleOpened: !trunc });
        } else if (!trunc) {
            this.setState({ titleOpened: !trunc });
        }
    };


    render() {
        const { titleOpened, truncated } = this.state;
        const {
            question, userName,
            questionNumber, classes,
            onClose, open, previewLink
        } = this.props;
        if (!question) return null;
        const { googleDriveFile, required, description } = question;
        return (
            <Dialog
                fullScreen
                open={open}
            >
                <div className={`c-reg-step-dialog ${classes.header}`}>
                    <Collapse
                        in={titleOpened || !truncated}
                        collapsedSize={truncated ? '76px' : '0px'}
                        style={{ maxWidth: '700px' }}
                    >
                        <div className="u-mrg--tx2 u-mrg--bx2 u-txt--18 u-dsp--f u-ai--center">
                            <div>{questionNumber !== undefined && <>Question {questionNumber + 1}</>}{userName ? ` - ${userName}` : ''}</div>
                            { required && <RequiredQuestionLabel uppercase className="u-mrg--lx2" /> }
                        </div>
                        {
                            !titleOpened && (
                                <div>
                                    <Truncate
                                        lines={2}
                                        ellipsis={(
                                            <>... <button onClick={this.toggleTitleOpen} className="c-button-link t-txt--brand">(show more)</button></>
                                        )}
                                        onTruncate={this.handleTruncate}
                                    >
                                        {Boolean(description) && ReactHtmlParser(nl2brCustom(description))}
                                    </Truncate>
                                </div>
                            )
                        }
                        {
                            titleOpened && Boolean(description) && ReactHtmlParser(nl2brCustom(description))
                        }
                        {
                            titleOpened && truncated && <button onClick={this.toggleTitleOpen} className="c-button-link t-txt--brand">&nbsp;(show less)</button>
                        }
                        {
                            !titleOpened && !truncated && Boolean(description) && ReactHtmlParser(nl2brCustom(description))
                        }
                    </Collapse>
                    <IconButton onClick={onClose} className={classes.iconClose} size="large">
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </div>
                <iframe
                    title="Grade card 2"
                    frameBorder="0"
                    src={previewLink || (googleDriveFile && googleDriveFile.previewLink ? `${googleDriveFile.previewLink}&rm=embedded` : null)}
                    width="100%"
                    height="100%"
                    style={{ position: 'relative', left: '0', height: 'calc(100vh - 90px)' }}
                />
            </Dialog>
        );
    }
}

export default withStyles(styles)(ImmersiveDialog);

import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import CandidatePhoto from 'components/photos/candidate_photo';
import TableCell from 'components/table_components/table_cell';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { resendTeamInvitation } from 'requests/SettingsRequests';
import Loader from 'components/loaders/Loader';
import { appCtx } from 'components/appStore';
import SettingsEvents from 'events/SettingsEvents';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { PERMISSIONS } from 'helper/constants';
import RoleActions from './RoleActions';
import PermissionsDialog from '../../PermissionsDialog';

const styles = theme => ({
    mutedCell: {
        opacity: 0.55
    },
    roleCell: {
        width: 200
    },
    resendInvite: {
        fontWeight: 600,
        color: theme.palette.grey[500],
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    loader: {
        color: theme.palette.grey[500]
    },
    permissionsText: {
        maxWidth: 200
    },
    buttonCount: {
        color: theme.palette.blue[500],
        cursor: 'pointer'
    }
});

const loaderStyles = { position: 'relative', width: 'auto' };

const TeamMemberRow = ({ classes, user, loadTeamMembers, isPending = false }) => {
    const [reInviteLoading, setReInviteLoading] = useState(false);
    const [openPermissionsDialog, setOpenPermissionsDialog] = useState(false);
    const { email, invited, fullName, id: teamId, uuid: teamUuid, roleType, canEditAllAuditions, canEditAuditionsCount, canViewAuditionsCount } = user;
    const { flashMessage, company } = useContext(appCtx);
    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);

    const cellClassName = clsx(invited && classes.mutedCell);

    const handleResendInvitation = () => {
        setReInviteLoading(true);
        resendTeamInvitation(teamId).then(() => {
            flashMessage('Invitation resent');
            setReInviteLoading(false);
        }).catch((error) => {
            flashMessage(error.response.data.msg || 'Something went wrong');
            setReInviteLoading(false);
        });
        SettingsEvents.TEAM_MEMBER_REINVITED({ roleType });
    };

    const handlePermissionDialog = () => {
        setOpenPermissionsDialog(!openPermissionsDialog);
    };

    const handleClose = () => {
        handlePermissionDialog();
        loadTeamMembers();
    };


    const showPermissionDetails = () => {
        if (!hasPermissionsFeature) return '';

        const renderCountText = (prefix, count) => (
            <div>{prefix} <span role="button" tabIndex={0} className={classes.buttonCount} onClick={handlePermissionDialog}> {count} assessment{count !== 1 && 's'}</span></div>
        );
        if (canEditAllAuditions) {
            return 'Can edit all assessments';
        }

        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(canEditAuditionsCount) && !isNaN(canViewAuditionsCount)) {
            return (
                <div className="u-dsp--f u-fdir--column">
                    {renderCountText('Can edit', canEditAuditionsCount)}
                    {renderCountText('and view', canViewAuditionsCount)}
                </div>
            );
        }

        if (canEditAuditionsCount) {
            return renderCountText('Can edit', canEditAuditionsCount);
        }

        if (canViewAuditionsCount) {
            return renderCountText('Can view', canViewAuditionsCount);
        }

        return renderCountText('Can edit', 0);
    };

    return (
        <>
            <TableRow>
                <TableCell align="left">
                    <div className="u-dsp--centered u-jc--start">
                        <CandidatePhoto user={user} />
                        <span className={cellClassName}><TruncateWithTooltip width={400} text={fullName} /></span>
                    </div>
                </TableCell>
                <TableCell className={cellClassName} align="left">{email}</TableCell>
                <TableCell align="left">
                    {
                        isPending && (
                            <div className="u-dsp--f u-ai--center">
                                <Button
                                    className={classes.resendInvite}
                                    onClick={handleResendInvitation}
                                    disabled={reInviteLoading}
                                >
                                    Resend Invitation
                                </Button>
                                <Loader show={reInviteLoading} className={classes.loader} size={26} style={loaderStyles} />
                            </div>
                        )
                    }
                    {
                        !isPending && (
                            <div className={clsx('u-dsp--f', 'u-ai--center', classes.permissionsText)}>
                                {showPermissionDetails()}
                            </div>
                        )
                    }
                </TableCell>
                <TableCell className={classes.roleCell} align="right">
                    <RoleActions user={user} loadTeamMembers={loadTeamMembers} />
                </TableCell>
            </TableRow>
            <PermissionsDialog
                teamUuid={teamUuid}
                open={openPermissionsDialog}
                onClose={handleClose}
            />
        </>
    );
};

export default withRouter(withStyles(styles)(TeamMemberRow));

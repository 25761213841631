import React from 'react';
import RetinaImage from 'react-retina-image';
import { observer } from 'mobx-react-lite';

const CompanyLogo = observer(({
    initials, company,
    className = '',
    size,
    style,
    onClick,
    sizeHeight,
    assessmentImageUrl
}) => {
    if (!company || !Object.keys(company).length) {
        return null;
    }

    const widthSize = { maxHeight: '100px', maxWidth: size };
    const heightSize = { height: sizeHeight, maxWidth: 200 };

    const { companyImageUrl, company_image_url, name } = company;

    if (assessmentImageUrl || (companyImageUrl && companyImageUrl.length) || (company_image_url && company_image_url.length)) {
        return (
            <div
                className={className}
                onClick={onClick}
                role="presentation"
                style={sizeHeight && !size ? heightSize : widthSize}
            >
                <RetinaImage
                    alt={name}
                    src={assessmentImageUrl || companyImageUrl || company_image_url}
                    style={sizeHeight && { height: sizeHeight }}
                />
            </div>
        );
    }

    return (
        <div className={className} style={style}>
            {
                initials
                    ? (
                        <div className="c-img-placeholder">
                            {name.charAt(0)}
                        </div>
                    )
                    : name
            }
        </div>
    );
});

export default CompanyLogo;

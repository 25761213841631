import React from 'react';
import { withStyles } from '@mui/styles';
import IdealDistributionImage from 'img/score_ideal_graph.png';

import VerticalBarChart from './VerticalBarChart';

const styles = {
    insightsChart: {
        position: 'relative',
        flexBasis: '100%',
        width: '100%',
        borderRadius: 6,
        backgroundColor: '#ffffff',
        padding: '30px 0 0',
        marginBottom: 8
    },
    idealImage: {
        position: 'absolute',
        bottom: 29,
        left: 0,
        width: '100%',
        height: 296,
        zIndex: 0,
        backgroundImage: `url(${IdealDistributionImage})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }
};

const ScoreDistributionChart = ({ classes, tooltipLabel, value }) => (
    <div
        className={classes.insightsChart}
    >
        <VerticalBarChart
            value={value}
            tooltipLabel={tooltipLabel}
        />
        <div className={classes.idealImage} />
    </div>
);

export default withStyles(styles)(ScoreDistributionChart);

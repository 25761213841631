import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import LockIcon from './lock.svg';

import styles from './styles';

const ShareErrorBanner = ({ show, classes, location, history, slug }) => {
    if (!show) return null;

    const goToJobEdit = () => {
        history.push({
            pathname: `/script/settings/${slug}/invitation-preview`,
            state: { from: location.pathname }
        });
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.errorWrapper}>
                <img src={LockIcon} className={classes.img} alt="error" />
                <div className={classes.text}>
                    In order to share your assessment, you’ll first need to fill out your&nbsp;
                    <span
                        role="presentation"
                        className={classes.link}
                        onClick={goToJobEdit}
                    >
                        Invite Settings
                    </span>.
                </div>
            </div>
        </div>
    );
};

export default withRouter(withStyles(styles)(ShareErrorBanner));

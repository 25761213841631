import React from 'react';
import { withTheme } from '@mui/styles';

const TagIconActive = ({ isActive, theme, color = theme.palette.green[600], ...other }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M15 5.33331L7.14698 5.33331C6.52858 5.33331 5.94497 5.61938 5.56615 6.10817L1.94948 10.7748C1.39059 11.496 1.39059 12.504 1.94948 13.2251L5.56615 17.8918C5.94497 18.3806 6.52858 18.6666 7.14698 18.6666L18.5 18.6666C19.6046 18.6666 20.5 17.7712 20.5 16.6666L20.5 11" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11 10.1375L13.5385 12.5L21 4" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default withTheme(TagIconActive);

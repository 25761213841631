export default () => ({
    wrapper: {
        position: 'relative',
        width: '100%',
        paddingBottom: 150
    },
    headerWrapper: {},
    header: {
        position: 'relative',
        paddingBottom: 21,
        borderBottom: '2px solid #F3F3F3'
    },
    skillOption: {
        maxWidth: 300,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
});

import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';
import Select from 'libraries/Select';
import CandidatePhoto from 'components/photos/candidate_photo';
import { changeTeamMemberRoleByAssessment, removeTeamMemberRoleFromAssessment } from 'requests/ScriptRequests';
import { appCtx } from 'components/appStore';
import TrashIcon from 'img/trash.svg';

import styles from './styles';

const ExistingUsersListItem = observer(({
    user, classes, opportunitySlug,
    loadNotInvitedList, userIndex,
    onRemoveTeamMember, onChangeTeamMember
}) => {
    const [selectedRole, setSelectedRole] = useState(user.roleType);
    const [loadingChange, setLoadingChange] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const { fullName, owner, uuid, id, roleType } = user;
    const { flashMessage, loggedUser: { id: loggedUserId } } = useContext(appCtx);


    const onChange = (e) => {
        const { value } = e.target;
        const oldValue = selectedRole;
        setSelectedRole(value);
        setLoadingChange(true);
        changeTeamMemberRoleByAssessment(opportunitySlug, uuid, value)
            .then(({ success }) => {
                setLoadingChange(false);
                if (success) {
                    onChangeTeamMember(userIndex, { ...user, roleType: value });
                    flashMessage('Team member role changed.', 'done');
                }
            })
            .catch(() => {
                setSelectedRole(oldValue);
                setLoadingChange(false);
                flashMessage('Something went wrong.');
            });
    };

    const onRemove = () => {
        setLoadingRemove(true);
        removeTeamMemberRoleFromAssessment(opportunitySlug, uuid)
            .then(() => {
                if (onRemoveTeamMember) onRemoveTeamMember(userIndex);
                loadNotInvitedList();
                flashMessage('Team member was removed.', 'done');
            })
            .catch(() => {
                setLoadingRemove(false);
                flashMessage('Something went wrong.');
            });
    };

    return (
        <div className={classes.row}>
            <div className="u-dsp--f u-ai--center">
                <CandidatePhoto user={user} />
                <div className={classes.fullName}>{fullName}</div>
            </div>
            <div>
                {
                    owner ? (
                        <div className={classes.roleWrapper}>Owner</div>
                    ) : (
                        <>
                            {
                                loggedUserId === id ? (
                                    <div className={classes.roleWrapper}>{roleType === 'admin' ? 'editor' : 'viewer'}</div>
                                ) : (
                                    <div className="u-dsp--f u-ai--center">
                                        <IconButton
                                            onClick={onRemove}
                                            disabled={loadingRemove}
                                            className={classes.removeButton}
                                            size="large"
                                        >
                                            <img src={TrashIcon} alt="trash" />
                                        </IconButton>
                                        <Select
                                            margin="none"
                                            selectMenuClassName={classes.selectedMenu}
                                            className={classes.roleSelect}
                                            value={selectedRole}
                                            disabled={loadingChange || loadingRemove}
                                            variant="outlined"
                                            onChange={onChange}
                                            options={[{
                                                label: 'Viewer',
                                                value: 'viewer'
                                            }, {
                                                label: 'Editor',
                                                value: 'admin'
                                            }]}
                                        />
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>
        </div>
    );
});

export default withStyles(styles)(ExistingUsersListItem);

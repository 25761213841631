import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import codeMirrorOptions from '../CodeMirrorOptions';

export default ({ question: { answeredText } }) => (
    <CodeMirror
        value={answeredText}
        options={codeMirrorOptions}
    />
);

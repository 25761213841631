import React from 'react';
import clsx from 'clsx';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import useStyles from './styles';

const CustomMenuItem = ({ question, className = '', onClickQuestion, disabled }) => {
    const { description, checked, id, sort } = question;
    const classes = useStyles();

    const checkboxProps = {
        disableRipple: true,
        checked: Boolean(checked),
        className: classes.checkbox,
        disabled
    };

    return (
        <MenuItem
            key={id}
            value={id}
            className={clsx(classes.menuItem, className)}
            onClick={!disabled ? onClickQuestion : undefined}
        >
            <div className={classes.filterOption}>
                <div className={classes.filterOptionLabel}>
                    <Checkbox {...checkboxProps} />
                    <TruncateWithTooltip
                        width={220}
                        className="u-txt--13"
                        text={`Q${sort}. ${(description || '').replace(/(<([^>]+)>)/gi, ' ')}`}
                    />
                </div>
            </div>
        </MenuItem>
    );
};

export default CustomMenuItem;

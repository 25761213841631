export default {
    customFileView: {
        height: 168,
        border: '2px dotted rgba(52, 52, 52, 0.46)',
        backgroundColor: 'rgba(144, 144, 144, 0.3)',
        padding: 23,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 4,
        '& img': {
            marginBottom: 12,
            width: 32
        },
        '& p': {
            marginBottom: 0,
            fontWeight: 'bold',
            fontSize: 16
        }
    },
    customLink: {
        textDecoration: 'underline',
        color: '#3a3c43',
        fontSize: 16,
        marginBottom: 15
    },
    helperText: {
        fontSize: 12,
        fontWeight: 600,
        color: '#9fa0a5'
    }
};

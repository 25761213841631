import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';

import { appCtx } from 'components/appStore';

import GeneralEvents from 'events/GeneralEvents';
import { extendCandidates, rejectExtensionRequest } from 'requests/AssessmentRequests';

import ApproveIcon from 'img/approveButtons/approve.svg';
import ApproveDisabledIcon from 'img/approveButtons/approveDisabled.svg';
import DeclineIcon from 'img/approveButtons/decline.svg';

const styles = () => ({
    iconButton: {
        padding: 2,
        '& img': {
            height: 27
        },
        '&:first-child': {
            marginRight: 4
        }
    }
});


const ExtensionButtons = observer(({
    classes, userAuditionId, candidateUuid,
    onExtendRequestSuccess, disabled, setDisabled, audition
}) => {
    const { flashMessage } = useContext(appCtx);

    if (!audition) return null;

    const { opportunitySlug, uuid: ttId, name: ttName, expired, valid } = audition;


    const handleExtension = (e, decline = false) => {
        e.stopPropagation();
        setDisabled(true);
        const requestPromise = decline
            ? rejectExtensionRequest(userAuditionId)
            : extendCandidates(opportunitySlug, [userAuditionId]);

        requestPromise
            .then((data) => {
                if (data.success) {
                    if (onExtendRequestSuccess) onExtendRequestSuccess(userAuditionId);
                    if (!decline) GeneralEvents.DEADLINE_EXTENDED({ candidateId: candidateUuid, ttId, ttName });
                    flashMessage(`Candidate request ${decline ? 'denied' : 'approved'}`, 'done');
                }
            })
            .catch((err) => {
                flashMessage(err?.response?.data?.msg || 'Something went wrong', 'error');
            })
            .finally(() => {
                setDisabled(false);
            });
        setDisabled(false);
    };

    return (
        <div>
            <IconButton
                onClick={handleExtension}
                className={classes.iconButton}
                disabled={disabled || expired || !valid}
                size="large"
            >
                <img src={(disabled || expired || !valid) ? ApproveDisabledIcon : ApproveIcon} alt="approve" />
            </IconButton>
            <IconButton
                onClick={(e) => { handleExtension(e, true); }}
                className={classes.iconButton}
                disabled={disabled}
                size="large"
            >
                <img src={DeclineIcon} alt="decline" />
            </IconButton>
        </div>
    );
});

export default withStyles(styles)(ExtensionButtons);

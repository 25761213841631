import React from 'react';
import { withTheme } from '@mui/styles';

const WhatsAppIcon = ({ theme, active, size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect width="32" height="32" rx="4" fill={active ? theme.palette.primary.main : theme.palette.grey[400]} />
        <path d="M22.3701 9.6352C20.6726 7.9369 18.4153 7.0009 16.0103 7C11.0547 7 7.0216 11.0311 7.0198 15.9865C7.0189 17.5705 7.43292 19.1167 8.21956 20.4793L7 25L11.7099 23.8867C13.0231 24.6031 14.501 24.9802 16.0058 24.9811H16.0094C20.9642 24.9811 24.9973 20.9491 25 15.9946C25.0018 13.5925 24.0675 11.3344 22.3701 9.6352ZM20.4089 19.1986C20.2217 19.7233 19.3045 20.2291 18.8923 20.266C18.4801 20.3038 18.094 20.4523 16.1967 19.7044C13.9132 18.8044 12.4714 16.4635 12.3598 16.3141C12.2473 16.1638 11.4426 15.0964 11.4426 13.9912C11.4426 12.886 12.0231 12.3424 12.2293 12.1183C12.4354 11.8933 12.6784 11.8375 12.8287 11.8375C12.9781 11.8375 13.1284 11.8375 13.2589 11.8429C13.4191 11.8492 13.5964 11.8573 13.7647 12.2308C13.9645 12.6754 14.4011 13.786 14.4569 13.8985C14.5127 14.011 14.5505 14.1424 14.4758 14.2918C14.4011 14.4412 14.3633 14.5348 14.2517 14.6662C14.1392 14.7976 14.0158 14.9587 13.915 15.0595C13.8025 15.1711 13.6855 15.2935 13.816 15.5176C13.9474 15.7426 14.3975 16.4779 15.0653 17.0728C15.9239 17.8378 16.6467 18.0745 16.8717 18.1879C17.0967 18.3004 17.2272 18.2815 17.3586 18.1312C17.49 17.9818 17.9202 17.476 18.0697 17.251C18.2191 17.026 18.3694 17.0638 18.5755 17.1385C18.7816 17.2132 19.8859 17.7568 20.1101 17.8693C20.3351 17.9818 20.4845 18.0376 20.5403 18.1312C20.5961 18.2239 20.5961 18.6739 20.4089 19.1986Z" fill="white" />
    </svg>
);

export default withTheme(WhatsAppIcon);

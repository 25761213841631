import React from 'react';
import { observer } from 'mobx-react-lite';
import Dialog from '@mui/material/Dialog';
import DialogContent from './dialog_content';

const SimulatorDialog = observer(({ question, open, onClose, interview }) => (
    <Dialog
        fullScreen
        open={open}
        onClose={onClose}
    >
        <DialogContent
            question={question}
            simulatorOpen={open}
            toggleDialogOpen={onClose}
            candidateName="Candidate Name"
            interview={interview}
        />
    </Dialog>
));

export default SimulatorDialog;

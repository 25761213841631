import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import BoardNoContent from 'components/board/board_no_content';
import CreateNewAssessment from 'components/assessments_pages/CreateNewAssessment';
import { appCtx } from 'components/appStore';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import Button from '@mui/material/Button';
import EmptyIcon from './icon.svg';
import { foldersCtx } from '../../../AssessmentFolders/store';


const NoAssessmentsContent = observer(({ history }) => {
    const { loggedUser, company } = useContext(appCtx);
    const { activeFolderId } = useContext(foldersCtx);
    const { roleType } = loggedUser;
    const { companySettings } = company;
    if (!companySettings) return null;
    const { activeAuditionsPerCompany: limitCount, activeAuditionsPerCompanyUsed } = companySettings;

    const companyCanCreateAssessment = checkHasCompanyFeature(company, 'CREATE_ASSESSMENT');

    let text = (
        <>
            You haven’t created any assessments yet.<br /><br />
            {
                companyCanCreateAssessment ? (
                    <CreateNewAssessment
                        light
                        buttonLabel="Create your first assessment"
                    />
                ) : (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => history.push('/marketplace')}
                        className="u-txt--bold"
                    >
                        Browse the library to get started
                    </Button>
                )
            }

        </>
    );

    if (limitCount === activeAuditionsPerCompanyUsed) {
        text = <>All active assessment spaces have been used.<br />{roleType === 'team' ? 'Please contact company admin.' : ''}</>;
    }

    if (activeFolderId) text = null;

    return (
        <BoardNoContent
            header="No Assessments"
            icon={<img src={EmptyIcon} alt="empty-content" />}
            text={text}
        />
    );
});

export default withRouter(NoAssessmentsContent);

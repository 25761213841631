import React from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';

const styles = {
    icon: {
        maxWidth: 18,
        marginRight: 7
    }
};


const CardMenuItem = observer(({
    icon, label, classes
}) => (
    <span className="u-dsp--f u-ai--center u-mrg--rx2">
        <img className={classes.icon} src={icon} alt="+" />
        {label}
    </span>
));

export default withStyles(styles)(CardMenuItem);

import { appStore } from 'components/appStore';
import DevelopmentIcon from '../img/assessmentCategory/development.svg';
import MarketingIcon from '../img/assessmentCategory/marketing.svg';
import AccountingIcon from '../img/assessmentCategory/accounting.svg';
import CustomerSupportIcon from '../img/assessmentCategory/customerservice.svg';
import SalesIcon from '../img/assessmentCategory/sales.svg';
import AdminIcon from '../img/assessmentCategory/admin.svg';
import CustomIcon from '../img/assessmentCategory/custom.svg';
import DesignIcon from '../img/assessmentCategory/design.svg';
import GeneralIcon from '../img/assessmentCategory/general.svg';
import RetailIcon from '../img/assessmentCategory/retail.svg';
import ManagementIcon from '../img/assessmentCategory/management.svg';
import CybernNetworkIcon from '../img/assessmentCategory/cybernnetwork.svg';
import HumanResourcesIcon from '../img/assessmentCategory/humanresources.svg';


import SalesPopularIcon from '../img/popularAssessmentCategory/sales.svg';
import DevelopmentPopularIcon from '../img/popularAssessmentCategory/development.svg';
import MarketingPopularIcon from '../img/popularAssessmentCategory/marketing.svg';
import AccountingPopularIcon from '../img/popularAssessmentCategory/accounting.svg';
import CustomerSupportPopularIcon from '../img/popularAssessmentCategory/customerservice.svg';
import AdminPopularIcon from '../img/popularAssessmentCategory/admin.svg';
import DesignPopularIcon from '../img/popularAssessmentCategory/design.svg';
import RetailPopularIcon from '../img/popularAssessmentCategory/retail.svg';
import GeneralPopularIcon from '../img/popularAssessmentCategory/general.svg';
import ManagementPopularIcon from '../img/popularAssessmentCategory/management.svg';
import CybernNetworkPopularIcon from '../img/popularAssessmentCategory/cybernnetwork.svg';
import HumanResourcesPopularIcon from '../img/popularAssessmentCategory/humanresources.svg';

import colors from '../components/colors';
import LibraryEvents from '../events/LibraryEvents';
import { SCORE_TYPE_MANUAL, SCORE_TYPE_AI, SCORE_TYPE_COMBINED } from './constants';
import { addAssessmentFromPublicLibrary } from '../requests/ScriptRequests';
import { copyTextToClipboard, getBaseUrl } from './commonFunctions';
import { getAssessmentPreviewLink } from '../requests/AssessmentRequests';
import AssessmentEvents from '../events/AssessmentEvents';


export function getImageUrl(type) {
    return ({
        'Software Development': DevelopmentIcon,
        Marketing: MarketingIcon,
        'Client Success Manager': CustomerSupportIcon,
        'Customer Service': CustomerSupportIcon,
        Sales: SalesIcon,
        'Admin and Office': AdminIcon,
        Custom: CustomIcon,
        'Accounting and Finance': AccountingIcon,
        Design: DesignIcon,
        Retail: RetailIcon,
        'General Skills': GeneralIcon,
        Management: ManagementIcon,
        'Cyber and Network': CybernNetworkIcon,
        Product: ManagementIcon,
        'Human Resources': HumanResourcesIcon
    }[type]);
}


export function getPopularImageUrl(type) {
    return ({
        Sales: SalesPopularIcon,
        Marketing: MarketingPopularIcon,
        'Accounting and Finance': AccountingPopularIcon,
        'Software Development': DevelopmentPopularIcon,
        'Customer Service': CustomerSupportPopularIcon,
        'Admin and Office': AdminPopularIcon,
        Design: DesignPopularIcon,
        Retail: RetailPopularIcon,
        'General Skills': GeneralPopularIcon,
        Management: ManagementPopularIcon,
        'Cyber and Network': CybernNetworkPopularIcon,
        Product: ManagementPopularIcon,
        'Human Resources': HumanResourcesPopularIcon
    }[type]);
}

export function getCategoryColor(type) {
    return ({
        'Software Development': colors.blue[400],
        Marketing: colors.yellow[800],
        'Client Success Manager': colors.red[400],
        'Customer Service': colors.red[400],
        Sales: colors.green[300],
        'Admin and Office': colors.purple[500],
        Custom: colors.purple[900],
        'Accounting and Finance': colors.green[400],
        Retail: colors.green[400],
        'General Skills': colors.yellow[900],
        Design: colors.purple[400],
        'Cyber and Network': colors.grey[700],
        Management: colors.grey[900],
        Product: colors.grey[900],
        'Human Resources': colors.blue[700]
    }[type]);
}

export function getCategoryTitle(name) {
    return ({
        'Software Development': 'development',
        Marketing: 'marketing',
        'Client Success Manager': 'customer service',
        'Customer Service': 'customer service',
        Sales: 'sales',
        'Admin and Office': 'admin & office',
        Custom: 'custom',
        'General Skills': 'General Skills',
        Design: 'Design',
        Retail: 'Retail',
        'Accounting and Finance': 'Accounting and Finance',
        'Cyber and Network': 'Cyber and Network',
        Management: 'Management',
        Product: 'Product',
        'Human Resources': 'Human Resources',
        Uncategorized: 'Uncategorized'
    }[name]);
}

export function onDeactivateCallback(promise) {
    return promise
        .then((result) => {
            const { success, data } = result;
            const { uuid: ttId,
                name: ttName,
                countQuestions: questionsNumber,
                numberOfCompletions: totalCompletionsNumber,
                totalCandidates: totalCandidatesNumber,
                questionGroups } = data;
            if (success) {
                const { companySettings } = appStore.company;
                const { activeAuditionsPerCompanyUsed } = companySettings;
                appStore.updateCompany({
                    ...appStore.company,
                    companySettings: {
                        ...companySettings,
                        activeAuditionsPerCompanyUsed: activeAuditionsPerCompanyUsed - 1
                    }
                });
                const segmentData = {
                    ttId,
                    ttName,
                    questionsNumber,
                    totalCompletionsNumber,
                    totalCandidatesNumber,
                    questionGroupsNumber: questionGroups.length
                };
                if (questionGroups.length) {
                    segmentData.questionGroups = questionGroups.map(item => item.title);
                }
                LibraryEvents.PRIVATE_TT_DEACTIVATED(segmentData);
                appStore.flashMessage('Assessment was closed', 'done');
            }
            return result;
        })
        .catch((err) => {
            if (err.response.status === 418) {
                appStore.flashMessage('Cannot deactivate the assessment on your plan. It contains real candidates.', 'error');
            } else {
                appStore.flashMessage('Something went wrong');
            }
            return err;
        });
}


export function onActivateCallback(promise) {
    return promise
        .then((result) => {
            const { success, data } = result;
            const { uuid, name, countQuestions, questionGroups, numberOfCompletions, totalCandidates } = data;
            if (success) {
                increaseActiveAuditionsPerCompany();
                appStore.flashMessage('Assessment was opened', 'done');
                const segmentData = {
                    ttId: uuid,
                    ttName: name,
                    questionsNumber: countQuestions,
                    totalCompletionsNumber: numberOfCompletions,
                    totalCandidatesNumber: totalCandidates,
                    questionGroupsNumber: questionGroups.length
                };
                if (questionGroups.length) {
                    segmentData.questionGroups = questionGroups.map(item => item.title);
                }
                LibraryEvents.PRIVATE_TT_ACTIVATED(segmentData);
            }
            return result;
        });
}

export function increaseActiveAuditionsPerCompany() {
    const { companySettings } = appStore.company;
    if (companySettings) {
        const { activeAuditionsPerCompanyUsed } = companySettings;
        appStore.updateCompany({
            ...appStore.company,
            companySettings: {
                ...companySettings,
                activeAuditionsPerCompanyUsed: activeAuditionsPerCompanyUsed + 1
            }
        });
    }
}


export function returnScoreLabel(score, userAudition, isEmployerSortEnabled) {
    if (!score || !userAudition) return null;
    if (isEmployerSortEnabled) return 'Score';
    const { type } = score;
    const { usersScoredAudition, currentEmployerGradedAll } = userAudition;
    if (type === SCORE_TYPE_AI || type === SCORE_TYPE_COMBINED) return 'AI Score';
    if (type === SCORE_TYPE_MANUAL) {
        return (usersScoredAudition && usersScoredAudition.length === 1 && currentEmployerGradedAll)
            ? 'My Score'
            : 'Team Score';
    }
    return 'Score';
}

export function isCalculating(candidate, isEmployerScoreSettings) {
    const { score, completed, isTest, pureNoAnswerRequiredAssessment, rejected } = candidate;
    return score === null && completed && !isTest && !pureNoAnswerRequiredAssessment && isEmployerScoreSettings && !rejected;
}

export function copyScriptAction(assessment, history) {
    const { company, updateCompany } = appStore;
    const { companySettings } = company;
    if (!companySettings) return null;
    const { activeAuditionsPerCompanyUsed } = companySettings;
    const {
        name, uuid, questionGroups, category,
        countQuestions
    } = assessment;
    return addAssessmentFromPublicLibrary(uuid)
        .then(({ success, data }) => {
            if (success) {
                appStore.flashMessage('Template copied to your library', 'done');
                if (data.active) {
                    updateCompany({
                        ...company,
                        companySettings: {
                            ...companySettings,
                            activeAuditionsPerCompanyUsed: activeAuditionsPerCompanyUsed + 1
                        }
                    });
                }
                const segmentData = {
                    ttId: uuid,
                    ttName: name,
                    category: category ? category.title : '',
                    questionsNumber: countQuestions,
                    questionGroupsNumber: questionGroups.length,
                    assessmentActive: data.active
                };
                if (questionGroups.length) {
                    segmentData.questionGroups = questionGroups.map(item => item.title);
                }

                LibraryEvents.TT_USED(segmentData);
                history.push(`/script/edit/${data.slug}/preview`);
            } else {
                appStore.flashMessage(data.message);
            }
        })
        .catch((err) => {
            const { response } = err;
            if (response && response.data && response.data.errors) {
                appStore.flashMessage(response.data.errors);
            } else {
                appStore.flashMessage('Something went wrong');
            }
        });
}

export function copyAssessmentPreviewLink({ slug }) {
    appStore.flashMessage('Copying...', 'pending', 100000);
    return getAssessmentPreviewLink(slug).then(({ data, success }) => {
        if (success && data) {
            return `${getBaseUrl()}/assessment/${data.uuid}/preview`;
        }
    }).catch(() => {
        appStore.flashMessage('Something went wrong', 'error');
    });
}

export function copyAssessmentPreviewLinkCallback({ text, name, uuid, countQuestions }) { // needed to split otherwise doesn't work in safari
    copyTextToClipboard(text, () => {
        appStore.flashMessage('Link copied to clipboard', 'done');

        AssessmentEvents.PREVIEW_SHARED({
            ttUUID: uuid,
            ttName: name,
            questionsNumber: countQuestions
        });
    });
}

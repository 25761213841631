import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import BoardItemWrapper from 'components/board/board_item';
import SimulationIcon from './SimulationIcon.svg';


const styles = theme => ({
    header: {
        maxWidth: 960,
        lineHeight: 1.4,
        textAlign: 'center',
        fontWeight: 700,
        margin: '0 auto 20px'
    },
    wrapper: {
        position: 'relative',
        height: 330,
        width: 330,
        fontWeight: 'normal',
        overflow: 'hidden',
        boxShadow: 'none',
        border: 'none',
        margin: '8px 11px',
        '@media only screen and (max-width: 763px)': {
            height: 350
        },
        [theme.breakpoints.down('lg')]: {
            width: 358
        }
    },
    headerWrapper: {
        position: 'relative',
        padding: '53px 24px 0px'
    },
    title: {
        boxSizing: 'content-box',
        maxHeight: 48,
        textAlign: 'left',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.4',
        letterSpacing: 'normal',
        color: theme.palette.blue[550],
        whiteSpace: 'pre-wrap'
    },
    contentWrapper: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        padding: '16px 24px'
    },
    descriptionWrapper: {
        boxSizing: 'content-box',
        height: 185,
        fontSize: 13,
        marginBottom: 16,
        textAlign: 'left',
        display: '-webkit-box',
        WebkitLineClamp: '8',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '20px',
        letterSpacing: 'normal',
        color: theme.palette.grey[900]
    },
    simulationHeader: {
        color: theme.palette.grey[900],
        textTransform: 'uppercase',
        position: 'absolute',
        top: 21,
        left: 23,
        display: 'flex',
        alignItems: 'center',
        '& img': {
            marginRight: 7
        }
    },
    loaderWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
    },
    wrapperFaked: {
        position: 'relative',
        cursor: 'default',
        '&:hover': {
            boxShadow: 'none'
        },
        '&:after': {
            display: 'block',
            content: '"coming soon"',
            position: 'absolute',
            top: 25,
            right: -43,
            padding: 3,
            width: 160,
            transform: 'rotate(45deg)',
            color: '#007066',
            fontSize: 11,
            fontWeight: 600,
            background: theme.palette.green[50],
            textAlign: 'center',
            textTransform: 'uppercase',
            clipPath: 'polygon(24% 0, 76% 0, 90% 100%, 10% 100%)'
        }
    },
    wrapperDisabled: {
        '&:hover': {
            boxShadow: 'none'
        }
    }
});

const OnboardingAssessmentItem = ({ classes, onClick, assessment, selectLoadingId }) => {
    const { name, summary, containsSimulationQuestions, uuid, faked } = assessment;

    const handleClick = () => {
        if (faked) return;
        onClick(uuid);
    };

    return (
        <BoardItemWrapper
            className={clsx(
                classes.wrapper,
                faked && classes.wrapperFaked,
                Boolean(selectLoadingId) && classes.wrapperDisabled
            )}
            onClick={handleClick}
            disabled={Boolean(selectLoadingId)}
        >
            {
                selectLoadingId === uuid && (
                    <div className={classes.loaderWrapper}>
                        <CircularProgress size={35} color="primary" />
                    </div>
                )
            }
            <div className={classes.headerWrapper}>
                <div className={classes.header}>
                    {
                        containsSimulationQuestions && (
                            <div className={classes.simulationHeader}>
                                <img src={SimulationIcon} alt="Sim" />
                                Simulation
                            </div>
                        )
                    }
                    <div className={classes.title}>
                        {name}
                    </div>
                </div>
            </div>
            <div className={classes.contentWrapper}>
                <div className={classes.descriptionWrapper}>{summary}</div>
            </div>
        </BoardItemWrapper>
    );
};

export default withRouter(withStyles(styles)(OnboardingAssessmentItem));

import React from 'react';
import { withStyles } from '@mui/styles';
import TextInput from 'libraries/TextInput';
import Tooltip from 'libraries/Tooltip';
import Typography from '@mui/material/Typography';

const styles = {
    wrapper: {
        marginTop: 15,
        marginBottom: 15
    },
    tooltip: {
        textAlign: 'center'
    }
};

const TypingTestContent = ({ classes, question: { typingTestContent } }) => (
    <div className={classes.wrapper}>
        <Typography variant="h6">Typing Test Content</Typography>
        <Tooltip
            tooltipClass={classes.tooltip}
            label={<>Question settings are unable to<br />be changed for typing tests</>}
        >
            <div className="u-mrg--tx2">
                <TextInput
                    multiline
                    disabled
                    value={typingTestContent}
                    variant="outlined"
                    label="Typing Test Content"
                />
            </div>
        </Tooltip>
    </div>
);

export default withStyles(styles)(TypingTestContent);

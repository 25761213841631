import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';

import clsx from 'clsx';
import styles from './styles';
import { subscriptionDialogStoreCtx } from '../../../store';

const PlanInfoBlock = observer(({ classes, plan }) => {
    const { company } = useContext(appCtx);
    const { interval, showSecret } = useContext(subscriptionDialogStoreCtx);

    if (!plan) return;

    const { name, publicAuditionPlans, level, slug } = plan;
    const auditionPlan = publicAuditionPlans.find(({ currency, period: periodAP }) => currency === company.currencyStripe && periodAP === interval);

    if (level === 1 && slug !== 'premium') {
        return (
            <div className={clsx(classes.price, 'u-mrg--tx2')}>Free</div>
        );
    }

    if (name.toLowerCase() === 'enterprise' && !showSecret) {
        return (
            <div className={clsx(classes.price, 'u-mrg--tx2')}>Custom</div>
        );
    }

    if (!auditionPlan) return null;

    const { amount, currency, period } = auditionPlan;
    const planHasOnlyYearlySubscription = publicAuditionPlans.length === 1;

    return (
        <div className={classes.priceWrapper}>
            <span className={classes.price}>{currency === 'usd' ? '$' : `${currency} `}
                {(period === 'yearly' && !planHasOnlyYearlySubscription) ? (+amount / 12) : new Intl.NumberFormat('en-US').format(amount)}
            </span>
            {!planHasOnlyYearlySubscription && <span>/mo</span>}
        </div>
    );
});

export default withStyles(styles)(PlanInfoBlock);

export default () => ({
    iconStar: {
        borderRadius: 4,
        padding: 6,
        marginRight: 5,

        '&:disabled': {
            pointerEvents: 'auto'
        }
    }
});

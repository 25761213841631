import React from 'react';
import { withRouter } from 'react-router-dom';
import LimitSettings from 'components/assessments_pages/asessment_settings_components/LimitSettings';
import DeadlineSettings from 'components/assessments_pages/asessment_settings_components/DeadlineSettings';
import AutoExtendSettings from 'components/assessments_pages/asessment_settings_components/AutoExtendSettings';


const DeadlinePage = ({ ...other }) => (
    <div>
        <LimitSettings {...other} />
        <DeadlineSettings {...other} />
        <AutoExtendSettings {...other} />
    </div>
);

export default withRouter(DeadlinePage);

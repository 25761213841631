export default (theme => ({
    checkButton: {
        fontWeight: 600,
        position: 'absolute',
        right: 40,
        bottom: -5,
        padding: 0,
        minWidth: 'auto'
    },
    doneIcon: {
        color: theme.palette.green.primary,
        marginLeft: 5
    },
    failIcon: {
        color: theme.palette.red.primary,
        marginLeft: 5
    }
}));

export default theme => ({
    wrapper: {
        position: 'relative',
        paddingTop: 25
    },
    header: {
        fontWeight: 700,
        marginBottom: 7,
        color: theme.palette.grey[900]
    },
    subheader: {
        fontSize: 12,
        color: theme.palette.grey[700],
        marginBottom: 24,
        fontWeight: 600
    },
    chip: {
        marginBottom: 10,
        marginRight: 10
    },
    error: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: '#f02e2e',
        fontSize: 10,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.8px'
    },
    skillInput: {
        fontSize: 12,
        margin: '20px 0 20px',
        maxWidth: 550
    }
});

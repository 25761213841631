import React from 'react';
import Grow from '@mui/material/Grow';
import { modifyScreeningQuestion } from 'helper/questionSettingsFunctions';
import QuestionTypes from '../QuestionTypes';
import AnswerTypes from '../AnswerTypes';


const QuestionWrapper = ({
    QuestionHeaderComp, interview = {}, questions, activeQuestion,
    assessmentQuestions, ...other
}) => (
    <>
        { questions && questions.map((item, i) => {
            const question = modifyScreeningQuestion(item);

            return (
                <Grow
                    in
                    key={question.id}
                    style={{ transformOrigin: '0 0 0', marginBottom: 80 }}
                    {...{ timeout: 1000 }}
                >
                    <div>
                        <QuestionHeaderComp
                            index={i}
                            interview={interview}
                            question={question}
                            questions={questions}
                            activeQuestion={activeQuestion}
                            assessmentQuestions={assessmentQuestions}
                            {...other}
                        />
                        <QuestionTypes data={question} />
                        <AnswerTypes
                            interview={interview}
                            data={question}
                            index={i}
                        />
                    </div>
                </Grow>
            );
        })}
    </>
);

export default QuestionWrapper;

import React, { useContext } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

// material components
import TextInputOutlined from 'libraries/TextInput';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import { AUDIO, IMAGE, VIDEO, ANY_FILE, TEXT } from 'helper/constants';
import AnswerAttachment from 'components/marketplace/EditQuestion/components/AnswerAttachment';
import { appCtx } from 'components/appStore';
import { isEmptyObject } from 'helper/validationFunctions';

import {
    removeFileFromMultipleChoiceAnswer,
    removeZiggeoFromMultipleChoiceAnswer,
    uploadFileMultipleChoiceAnswer
} from 'requests/QuestionsRequests';

import MediaChoiceTypes from '../../MediaChoiceTypes';

import styles from './styles';


const MultipleChoiceInput = observer(({
    classes, onChange, index, isCMS,
    answers, actionLoading, removeAnswer,
    item: { id, title, valid, type, file, ziggeo, codeEditor, point, correctChoice },
    setUploadingFiles, uploadingFiles, autoGraded, hasCustomScore
}) => {
    const { flashMessage } = useContext(appCtx);

    const onChangeType = async (newType) => {
        if (type === ANY_FILE || type === IMAGE) {
            await removeFileFromMultipleChoiceAnswer(id);
            onChange(index, 'file', null, true);
        } else if (type === AUDIO || type === VIDEO) {
            await removeZiggeoFromMultipleChoiceAnswer(id, isCMS);
            onChange(index, 'ziggeo', null, true);
        }
        onChange(index, 'type', newType);
    };

    const handleChangeText = (event) => {
        const { value } = event.target;
        onChange(index, 'title', value);
    };

    const handleChangeToken = (token) => {
        onChange(index, 'ziggeo', { token });
    };

    const onDropFile = (files) => {
        const data = new FormData();
        if (!files.length) {
            flashMessage('The file should not be empty and its size should be 10MB or less', 'error');
            return;
        }
        files.forEach((f, ind) => data.append(ind, f));
        setUploadingFiles(oldUploadingFiles => ({ ...oldUploadingFiles, [id]: true }));

        uploadFileMultipleChoiceAnswer(id, data, isCMS)
            .then(({ success, file: newFile, message }) => {
                if (success) {
                    const { fileName, fileUrl } = data;
                    onChange(index, 'file', { fileName, fileUrl });
                } else if (message) {
                    flashMessage(message);
                }
            })
            .catch((jqXHR, textStatus) => {
                flashMessage(textStatus);
            })
            .finally(() => {
                setUploadingFiles((oldUploadingFiles) => {
                    delete oldUploadingFiles[id];
                    return ({ ...oldUploadingFiles });
                });
            });
    };

    const handleChangeCodeEditor = (event, value) => {
        onChange(index, 'codeEditor', value);
    };


    const handleChangePoints = (e) => {
        const { value } = e.target;
        let newValue = parseInt(value, 10);
        if (Number.isNaN(newValue) || newValue <= 0) {
            newValue = 0;
        } else if (newValue > 10) {
            newValue = 10;
        }
        onChange(index, 'point', newValue);
    };


    const onChangeCheckbox = () => {
        onChange(index, 'correctChoice', !correctChoice);
    };

    const hasPoints = Boolean(autoGraded || (!autoGraded && hasCustomScore && correctChoice));

    return <>
        <div className="-pos--relative">
            <div className="c-multicol__col">
                <div className="u-dsp--f u-ai--start u-wdt--100p  u-pos--relative">
                    {
                        !autoGraded && (
                            <Checkbox
                                onChange={onChangeCheckbox}
                                checked={correctChoice}
                            />
                        )
                    }
                    {
                        type === TEXT ? (
                            <TextInputOutlined
                                className={classes.input}
                                value={title}
                                name="title"
                                isError={!valid && (point !== null || !autoGraded)}
                                variant="outlined"
                                label="Enter answer"
                                onChange={handleChangeText}
                                fullWidth
                                maxLength={25000}
                            />
                        ) : (
                            <div className={clsx(classes.attachmentWrapper, hasPoints && classes.attachmentWrapperWithPoints)}>
                                <AnswerAttachment
                                    type={type}
                                    value={title}
                                    file={file}
                                    ziggeo={ziggeo}
                                    handleChangeToken={handleChangeToken}
                                    codeEditorValue={codeEditor}
                                    onChange={handleChangeText}
                                    onDrop={onDropFile}
                                    isError={!valid && (point !== null || !autoGraded)}
                                    isUploadingFile={id in uploadingFiles}
                                    className="u-mrg--bx3 u-mrg--tx0"
                                    placeholder="Enter answer"
                                    handleChangeCodeEditor={handleChangeCodeEditor}
                                    handleChangeType={(e, newType) => onChangeType(newType)}
                                />
                            </div>
                        )
                    }
                    {
                        hasPoints && (
                            <TextInputOutlined
                                className={clsx(classes.input, classes.pointsInput)}
                                value={Number.isInteger(point) && !Number.isNaN(point) ? parseInt(point, 10) : ''}
                                label="Points (max 10)"
                                variant="outlined"
                                isError={!valid && point === ''}
                                onChange={handleChangePoints}
                                type="number"
                                min="0"
                                max="10"
                                name="point"
                            />
                        )
                    }
                    {
                        answers && answers.length > 1 && (
                            <IconButton
                                type="button"
                                onClick={event => removeAnswer(event, index)}
                                className={classes.closeIcon}
                                disabled={actionLoading || !isEmptyObject(uploadingFiles)}
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        )
                    }
                </div>
            </div>
        </div>
        <MediaChoiceTypes onChangeType={onChangeType} type={type} />
    </>;
});

export default withStyles(styles)(MultipleChoiceInput);

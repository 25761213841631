import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Pagination from 'components/board/board_pagination';
import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';
import { CANDIDATES_PER_ALL_CANDIDATES_PAGE } from 'helper/constants';
import { candidateCtx } from '../../../../AllCandidates/candidateStore';

import CandidateRow from './CandidateRow';


const CandidatesTable = observer(() => {
    const {
        handleChangePage, allCandidates,
        totalCount: candidatesLength,
        page, handleChangeSort, sortValue
    } = useContext(candidateCtx);
    const totalPagesCount = Math.ceil(candidatesLength / CANDIDATES_PER_ALL_CANDIDATES_PAGE);

    const sortByName = (sortCategory, sortOrder) => {
        handleChangeSort(sortOrder);
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellHead label="Name" sortCategory={{ name: true }} sortOrder={sortValue} onSort={sortByName} labelComponent="Candidate" />
                        <TableCellHead label="Email" hideSort />
                        <TableCellHead label="Assessments" hideSort />
                        <TableCellHead align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object
                            .keys(allCandidates)
                            .map(key => (
                                <CandidateRow key={key} candidate={allCandidates[key]} />
                            ))
                    }
                </TableBody>
            </Table>
            <Pagination
                currentPage={page}
                totalPages={totalPagesCount}
                handleChangePage={handleChangePage}
            />
        </TableContainer>
    );
});

export default CandidatesTable;

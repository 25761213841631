import React, { useState } from 'react';
import { getInitials } from 'helper/commonFunctions';
import Button from '@mui/material/Button';

import useStyles from './styles';
import SourceFacebook from '../../../../assets/source_facebook.svg';
import SourceTwitter from '../../../../assets/source_twitter.svg';
import SourceEmail from '../../../../assets/source_email.svg';
import AnswersDialog from './answers_dialog';
import Attachment from './attachment';

const ActiveTicketBody = ({ ticket }) => {
    const {
        wrapper, initialsCandidate, nameLabel, dateLabel,
        header, ticketTextBlock, ticketText,
        ticketBody, actionBlock, responseButton,
        submittedFromBlock, ticketTextBlockWrapper
    } = useStyles({});
    const [answersDialogOpened, setAnswersDialogOpened] = useState(false);

    const sourceHtml = (source) => {
        switch (source) {
            case 'facebook':
                return (
                    <>
                        <img src={SourceFacebook} alt="" />
                        <span className="source">Facebook</span>
                    </>
                );
            case 'twitter':
                return (
                    <>
                        <img src={SourceTwitter} alt="" />
                        <span className="source">Twitter</span>
                    </>
                );
            case 'email':
                return (
                    <>
                        <img src={SourceEmail} alt="" />
                        <span className="source">Email</span>
                    </>
                );
            default:
                return <span className="source gap">{source}</span>;
        }
    };

    if (!ticket) return <div className={wrapper} />;

    return (
        <div className={wrapper}>
            <div className={ticketTextBlockWrapper}>
                <div className={ticketTextBlock}>
                    <div className={initialsCandidate}>{getInitials(ticket.customer.name)}</div>
                    <div className={ticketBody}>
                        <div className={header}>
                            <div className={nameLabel}>{ticket.customer.name}</div>
                            <div className={dateLabel}>{ticket.ticketDateAsText}</div>
                        </div>
                        <div className={ticketText}>
                            {ticket.text}
                        </div>
                        {ticket.file && <Attachment file={ticket.file} />}
                        {ticket.customer.source && (
                            <div className={submittedFromBlock}>
                                <span>Submitted from channel:</span>
                                {sourceHtml(ticket.customer.source)}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className={actionBlock}>
                <Button
                    variant="outlined"
                    color="primary"
                    className={responseButton}
                    onClick={() => setAnswersDialogOpened(true)}
                >
                    Select Response
                </Button>
            </div>

            <AnswersDialog
                open={answersDialogOpened}
                handleClose={() => setAnswersDialogOpened(false)}
                answers={ticket.validCsSimulatorAnswers}
                ticketId={ticket.id}
            />
        </div>
    );
};

export default ActiveTicketBody;

import React from 'react';
import { withStyles } from '@mui/styles';
import SearchIcon from './icon.svg';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: theme.palette.grey[600],
        fontSize: 14,
        marginTop: 15,
        paddingBottom: 100,
        minHeight: 400
    },
    header: {
        fontSize: 16,
        color: '#1A1B1E',
        fontWeight: 'bold',
        lineHeight: '36px',
        marginTop: 10
    }
});

const NotFoundContent = ({ classes, searchValue }) => (
    <div className={classes.wrapper}>
        <img src={SearchIcon} alt="search" />
        <div className={classes.header}>We couldn't find a match for “{searchValue}”</div>
        <div>Check your spelling or try a new search</div>
    </div>
);

export default withStyles(styles)(NotFoundContent);

export default theme => ({
    table: {
        width: 'calc(100% - 70px)',
        margin: '30px 30px 50px',
        minWidth: 'inherit'
    },
    tableCell: {
        borderRight: 'none !important',
        padding: '5px 0 !important'
    },
    tableCellName: {
        borderLeft: 'none !important'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100vw - 300px)'
    },
    loaderWrapper: {
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dialogTitle: {
        fontSize: 18
    },
    assessmentName: {
        color: theme.palette.blue[600],
        fontSize: 14,
        fontWeight: 600,
        margin: '12px 0'
    }
});

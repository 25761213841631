export default theme => ({
    iconButton: {
        padding: 5,
        marginRight: 10
    },
    select: {
        width: 125
    },
    selectedMenu: {
        padding: '12px 14px'
    },
    nonChangeableWrapper: {
        color: theme.palette.grey[500],
        width: 125,
        textTransform: 'capitalize',
        border: '2px solid #EBECEE',
        height: 40,
        display: 'flex',
        padding: 11,
        justifyContent: 'flex-start',
        alignItems: 'center',
        float: 'right',
        borderRadius: 4
    }
});

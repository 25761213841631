import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import AssessmentEvents from 'events/AssessmentEvents';
import TableFilter from 'components/table_components/table_filter';
import CustomMenuItem from 'components/table_components/table_filter/table_filter_item';

import { candidateResultCtx } from 'pages/assessment/results_assessment/common/CandidateListWrapper/candidatesStore';
import { assessmentSettingsCtx } from 'pages/assessment/store';

import NoTagsBlock from 'pages/assessment/components/NoTagsBlockFilter';

import useStyles from './styles';


const ResultsTableFilter = observer(({ isShortList }) => {
    const classes = useStyles();

    const {
        handleChangeFilterSelect, filterCategory,
        filterTagsCategory, filterSourceCategory,
        filtersCount, filterPlagiarismCategory
    } = useContext(candidateResultCtx);

    const { audition, companyTags } = useContext(assessmentSettingsCtx);

    const returnEventContext = () => {
        if (isShortList) return 'shortlist page';
        return 'select candidates';
    };

    const handleChange = (value, isTags, isSource) => {
        const { uuid: ttId, name: ttName } = audition;
        let transformedObj;
        if (isTags) transformedObj = filterTagsCategory;
        if (isSource) transformedObj = filterSourceCategory;
        if (!isTags && !isSource) transformedObj = filterCategory;
        AssessmentEvents.CANDIDATES_FILTERED({
            ttName,
            ttId,
            filterType: value,
            filterEnabled: !(value in transformedObj),
            context: returnEventContext()
        });
        handleChangeFilterSelect(value, isTags, isSource);
    };

    const options = filtersCount ? [{
        value: 'all',
        label: 'show all',
        count: filtersCount.allCandidates,
        checked: !Object.keys(filterCategory).length || (Object.keys(filterCategory).length === 1 && filterCategory.shortlist)
    }, {
        value: 'active',
        count: filtersCount.active,
        checked: filterCategory.active
    }, {
        value: 'rejected',
        count: filtersCount.rejected,
        checked: filterCategory.rejected
    }, {
        value: 'hired',
        count: filtersCount.hired,
        checked: filterCategory.hired
    }] : [];

    const plagiarismOptions = filtersCount ? [{
        value: 'multipleGeolocations',
        label: 'multiple geolocations',
        count: filtersCount.multipleGeolocations,
        checked: filterPlagiarismCategory.multipleGeolocations
    }, {
        value: 'plagiarism',
        count: filtersCount.plagiarism,
        checked: filterPlagiarismCategory.plagiarism
    }] : [];

    const tagOptions = companyTags
        .filter(({ title }) => Boolean(filtersCount.tags && filtersCount.tags[title]))
        .map(({ title }) => ({
            checked: filterTagsCategory[title],
            value: title,
            count: filtersCount.tags[title]
        }));

    const renderMenuItems = (menuList, isTags, isSource) => (
        menuList.map(({ value, label = value, checked, count }) => (
            <CustomMenuItem
                key={value}
                value={value}
                label={label}
                count={count}
                checked={checked}
                isCheckbox={isTags || isSource || false}
                showCountNumber={!isShortList}
                handleChange={val => handleChange(val, isTags, isSource)}
            />
        ))
    );

    if (!audition || !filterCategory) return null;

    const hasTaggedCandidates = tagOptions.some(({ count }) => Boolean(count));
    const filterCount = Object.keys(filterTagsCategory).length
        + (filterCategory.shortlist ? Object.keys(filterCategory).length - 1 : Object.keys(filterCategory).length)
        + Object.keys(filterSourceCategory || {}).length
        + Object.keys(filterPlagiarismCategory).filter(key => filterPlagiarismCategory[key]).length;

    return (
        <TableFilter
            isFilterActive={Boolean(filterCount)}
        >
            <Typography variant="h6" className={classes.filterHeader}>Tags</Typography>
            {hasTaggedCandidates ? renderMenuItems(tagOptions, true) : <NoTagsBlock />}
            {!isShortList && <Typography variant="h6" className={classes.filterHeader}>Status</Typography>}
            {!isShortList && renderMenuItems(options)}
            {!isShortList && <Typography variant="h6" className={classes.filterHeader}>Anti Cheating</Typography>}
            {!isShortList && renderMenuItems(plagiarismOptions, true)}
        </TableFilter>
    );
});

export default ResultsTableFilter;

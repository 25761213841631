export default () => ({
    flip: {
        transform: 'scaleX(-1)'
    },
    settingsIcon: {
        maxWidth: 24,
        maxHeight: 24
    },
    tooltipClass: {
        height: 24
    }
});

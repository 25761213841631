import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import { getFilterList } from 'requests/ScriptRequests';
import styles from './styles';

const QuickSearchPanel = ({ classes, filterData, handleFilter }) => {
    const [tags, setTags] = useState([]);

    useEffect(() => {
        getFilterList().then((data) => {
            if (data.tags) {
                setTags(data.tags);
            }
        });
    }, []);

    if (!tags.length || !filterData) return null;

    return (
        <div className={classes.root}>
            <span className={classes.title}>QUICK SEARCHES:</span>
            <div className="u-dsp--f u-fw--wrap">
                {
                    tags.map((tag) => {
                        const isActive = filterData.tag && filterData.tag === tag.title;
                        return (
                            <button className={clsx(classes.quickSearchItem, isActive && classes.quickSearchItemActive)} onClick={() => handleFilter('tag', tag.title)}>{tag.title}</button>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default withStyles(styles)(QuickSearchPanel);

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';

import { AUDIO, VIDEO, ANY_FILE, IMAGE, TEXT, TYPING_TEST, CYBER_SIMULATOR } from 'helper/constants';
import { deleteAuditionFile, deleteZiggeoFile, uploadAuditionFile } from 'requests/ScriptRequests';
import Tooltip from 'libraries/Tooltip';

import DescriptionField from './DescriptionField';
import AnswerAttachment from '../AnswerAttachment';
import { appCtx } from '../../../../appStore';
import { convertToRaw } from 'draft-js';

const ALLOW_SYMBOLS = 25000;
const PLACEHOLDER = 'Try asking a question that represents a task or scenario this person will encounter in their new role...';

const styles = {
    wrapper: {
        position: 'relative',
        padding: 0
    },
    wrapperBanner: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100% + 5px)',
        left: 0,
        top: -5,
        zIndex: 1000,
        background: 'rgba(255, 255, 255, 0.6)'
    },
    tooltip: {
        textAlign: 'center',
        width: 210
    }
};

let timerId = null;

const QuestionType = observer(({ classes, changeQuestion, fetchQuestion, isCMS, question }) => {
    const {
        id, answerType, description,
        ziggeo: questionZiggeo,
        codeEditor, file: questionFile,
        type: questionType,
        descriptionPlainText
    } = question;
    const [value, setValue] = useState('');
    const [descriptionValueForCount, setDescriptionValueForCount] = useState('');
    const [codeEditorValue, setCodeEditorValue] = useState('');
    const [ziggeo, setZiggeo] = useState(null);
    const [file, setFile] = useState(null);
    const [type, setType] = useState(null);
    const { flashMessage } = useContext(appCtx);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const LABEL = answerType === TYPING_TEST ? 'Question introduction' : 'Write your question here...';
    const disabled = answerType === CYBER_SIMULATOR && !isCMS;

    useEffect(() => {
        setValue(description || '');
        setDescriptionValueForCount(descriptionPlainText || '');
        setZiggeo(questionZiggeo);
        setFile(questionFile);
        setCodeEditorValue(codeEditor);
        setType(questionType);
    }, [id]);

    useEffect(() => {
        if (answerType === TYPING_TEST) setValue(description || '');
    }, [answerType]);

    const handleDescription = (richTextEditorValue, plainText, html) => {
        if (disabled) return;
        setDescriptionValueForCount(plainText);
        changeQuestion({
            description: html,
            descriptionPlainText: plainText,
            richTextEditorDescription: richTextEditorValue
        });
    };

    const handleChangeType = (e, newType) => {
        if (newType === TEXT && (type === AUDIO || type === VIDEO) && ziggeo) {
            deleteFile(true);
        } else if (newType === TEXT && (type === ANY_FILE || type === IMAGE) && file) {
            deleteFile();
        } else {
            setType(+newType);
            changeQuestion({ type: +newType });
        }
    };

    const deleteFile = (av = false) => {
        const requestFunc = av ? deleteZiggeoFile : deleteAuditionFile;
        requestFunc(id, isCMS)
            .then((response) => {
                if (response.status === 204) {
                    if (av) {
                        setZiggeo(null);
                    } else {
                        setFile(null);
                    }
                    setType(TEXT);
                    changeQuestion({ type: TEXT });
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    const onDrop = (files) => {
        const formData = new FormData();
        if (!files.length) {
            flashMessage('The file should not be empty and its size should be 10MB or less', 'error');
            return;
        }
        files.forEach((f, index) => formData.append(index, f));
        setIsUploadingFile(true);

        uploadAuditionFile(id, formData, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    const { fileName, fileUrl } = data;
                    setFile({ fileName, fileUrl });
                    fetchQuestion();
                }
            })
            .catch(({ response }) => {
                if (response) {
                    const { msg } = response.data;
                    if (msg) {
                        flashMessage(msg, 'error');
                    } else {
                        flashMessage('Something went wrong', 'error');
                    }
                }
            })
            .finally(() => {
                setIsUploadingFile(false);
            });
    };

    const handleChangeCodeEditor = (event, newValue) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            changeQuestion({ codeEditor: newValue });
            setCodeEditorValue(newValue);
        }, 300);
    };

    const handleChangeToken = (token) => {
        changeQuestion({ ziggeo: token });
        setZiggeo({ token });
    };

    return (
        <div className={classes.wrapper}>
            {
                disabled && (
                    <Tooltip
                        tooltipClass={classes.tooltip}
                        label="Question is unable to be changed for simulations"
                    >
                        <div className={classes.wrapperBanner} />
                    </Tooltip>
                )
            }
            {
                type === TEXT || !type ? (
                    <DescriptionField
                        {...{
                            question,
                            value: descriptionValueForCount,
                            placeholder: LABEL,
                            handleValue: handleDescription,
                            handleChangeType,
                            allowedLength: ALLOW_SYMBOLS
                        }}
                    />
                ) : (
                    <AnswerAttachment
                        question={question}
                        file={file}
                        id={question.id}
                        ziggeo={ziggeo}
                        type={type}
                        onDrop={onDrop}
                        label={LABEL}
                        placeholder={PLACEHOLDER}
                        isUploadingFile={isUploadingFile}
                        handleChangeToken={handleChangeToken}
                        codeEditorValue={codeEditorValue}
                        handleChangeCodeEditor={handleChangeCodeEditor}
                        onChange={handleDescription}
                        handleChangeType={handleChangeType}
                        value={value}
                    />
                )
            }
        </div>
    );
});

export default withStyles(styles)(QuestionType);

import React, { useContext } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';

import Switch from '@mui/material/Switch';

import styles from './styles';
import { subscriptionDialogStoreCtx } from '../../../store';

const PlanPriceSwitch = observer(({ classes, plan }) => {
    const { interval, handleSelectInterval, showSecret } = useContext(subscriptionDialogStoreCtx);

    const toggleSelectInterval = () => {
        if (interval === 'yearly') {
            handleSelectInterval('monthly');
        } else {
            handleSelectInterval('yearly');
        }
    };

    if (!plan) return;

    const { name, level, publicAuditionPlans, slug } = plan;
    const planHasOnlyYearlySubscription = publicAuditionPlans.length === 1;

    if (name.toLowerCase() === 'candidate pack') {
        return (
            <div className={clsx(classes.pricingLabel, 'u-mrg--tx10')}>
                One time payment
            </div>
        );
    }

    if (publicAuditionPlans.length === 0) return;

    if (level === 1 && slug !== 'premium') {
        return (
            <div className={classes.pricingLabel}>
                This is a trial plan
            </div>
        );
    }

    if ((name.toLowerCase() === 'enterprise' && !showSecret) || planHasOnlyYearlySubscription) {
        return (
            <div className={classes.pricingLabel}>
                Billed Annually
            </div>
        );
    }

    return (
        <div className={classes.pricingSwitchWrapper}>
            <span className={clsx(interval === 'yearly' && classes.inactiveLabel)}>Monthly</span>
            <Switch
                className={classes.pricingSwitch}
                onChange={toggleSelectInterval}
                checked={interval === 'yearly'}
                classes={{
                    switchBase: classes.switchBase,
                    track: classes.switchTrack,
                    checked: classes.checked,
                    thumb: classes.thumb
                }}
            />
            <span className={clsx(interval === 'monthly' && classes.inactiveLabel)}>Annually (save up to 25%)</span>
        </div>
    );
});

export default withStyles(styles)(PlanPriceSwitch);

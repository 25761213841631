import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { getCandidatePosts, getGuestCandidatePosts } from 'requests/JobOpportunityRequests';
import PostItem from 'components/candidate_cards/PostItem';
import CommentsIcon from 'components/icons/CommentsIcon';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

const useStyles = makeStyles(theme => ({
    showAllButton: {
        color: theme.palette.grey[900],
        marginTop: -30,
        fontWeight: 700,
        padding: 0
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto'
    }
}));


const CommentsContainer = ({ userId, opportunitySlug, propPublic }) => {
    const [posts, setCandidatePosts] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        setCandidatePosts([]);
        getUserPosts();
    }, [userId, opportunitySlug]);


    const getUserPosts = () => {
        if (opportunitySlug && userId) {
            setIsLoading(true);
            const func = propPublic ? getGuestCandidatePosts : getCandidatePosts;
            return func(userId, opportunitySlug)
                .then((response) => {
                    const { success, data: { candidatePosts: newCandidatePosts } } = response;
                    if (success) {
                        setCandidatePosts(newCandidatePosts);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const onShowAll = () => {
        setShowAll(true);
    };

    if (isLoading) {
        return (
            <CircularProgress
                size={15}
                className={classes.loader}
                color="primary"
            />
        );
    }

    if (!posts || !posts.length) {
        return (
            <div className="u-dsp--centered u-fdir--column u-hgt--100p u-hgt-min--120">
                <CommentsIcon width={30} height={30} className="u-mrg--bx1" />
                <Typography variant="h6">No Comments</Typography>
            </div>
        );
    }

    const renderedPosts = showAll ? posts : [posts[0]];

    return (
        <div>
            {
                renderedPosts.map((post, index) => (
                    <PostItem key={index} post={post} />
                ))
            }
            {
                posts.length > 1 && !showAll && (
                    <Button
                        onClick={onShowAll}
                        className={classes.showAllButton}
                    >
                        Show all ({posts.length})
                    </Button>
                )
            }
        </div>
    );
};

export default CommentsContainer;

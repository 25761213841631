import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import Link from 'react-router-dom/Link';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';


import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { SEND_SMS_INVITATION, APP_SUMO } from 'helper/constants';
import Tooltip from 'libraries/Tooltip';
import SmsPreviewDialog from 'components/dialogs/SmsPreviewDialog';

import InfoIcon from './infoIcon.svg';
import PlusIcon from './plusGreen.svg';
import { appCtx } from '../../../../appStore';

import useStyles from '../styles';

const PhoneButton = observer(({ buttonClassName, openPhoneInput, expired }) => {
    const { company } = useContext(appCtx);
    const isAppSumo = checkHasCompanyFeature(company, APP_SUMO);

    const {
        phoneButton, disabledPhoneButton, highlighted,
        link, tooltipBox, tooltipText, previewButton
    } = useStyles({});

    if (isAppSumo) return null;

    const [previewDialogOpen, setPreviewDialogOpen] = useState(false);

    const hasSMSFeature = checkHasCompanyFeature(company, SEND_SMS_INVITATION);

    const { countSmsPerCompanyQuota, smsLimitPeriod } = company;

    const smsLimitPeriodToShow = smsLimitPeriod.split(' ')[1];
    const smsUsed = company.countSmsUsed;
    const smsLeft = company.countSmsLeft;

    const disabled = smsUsed >= countSmsPerCompanyQuota || expired || !hasSMSFeature || smsLeft <= 0;

    const button = (
        <Button
            className={clsx(phoneButton, disabled && disabledPhoneButton, buttonClassName)}
            color="primary"
            variant="contained"
            onClick={disabled ? () => {} : openPhoneInput}
        >
            <img src={disabled ? InfoIcon : PlusIcon} alt="i" className="u-mrg--rx2" />
            Phone
        </Button>
    );

    const tooltipForDisabledButton = hasSMSFeature
        ? <>
            You have&nbsp;
            <span className={highlighted}>
                {smsLeft}/{countSmsPerCompanyQuota}
            </span>
            &nbsp;SMS notifications remaining of
            <br />
            your plan quota this {smsLimitPeriodToShow}.&nbsp;
            <Link to="/billing" className={link}>
                My account
            </Link>
        </> : <>
            Your plan doesn't allow SMS notifications.
            <br />
            <ButtonBase
                className={link}
                onClick={() => window.open('/subscriptions', '_blank')}
            >
                Upgrade to activate
            </ButtonBase>
        </>;

    if (expired) return button;

    return <>
        <Tooltip
            white
            boxShadow
            placement="left"
            tooltipClass={tooltipBox}
            label={(
                <div className={tooltipText}>
                    {disabled
                        ? tooltipForDisabledButton
                        : <>
                            Notify candidate via SMS of your invitation.
                            <br />
                            <ButtonBase
                                className={previewButton}
                                onClick={() => setPreviewDialogOpen(!previewDialogOpen)}
                            >
                                Preview message
                            </ButtonBase>
                            <br />
                            You have <b>{smsLeft}/{countSmsPerCompanyQuota}</b> SMS notifications remaining of
                            <br />
                            your plan quota this {smsLimitPeriodToShow}.&nbsp;
                            <Link to="/billing" className={link}>
                                My account
                            </Link>
                        </>
                    }
                </div>
            )}
        >
            {button}
        </Tooltip>
        <SmsPreviewDialog
            open={previewDialogOpen}
            handleClose={() => setPreviewDialogOpen(!previewDialogOpen)}
        />
    </>;
});

export default PhoneButton;

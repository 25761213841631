import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles, withTheme } from '@mui/styles';
import InnerTabs from 'components/layout/InnerTabs';
import UsersIcon from 'components/icons/UsersIcon';
import BookmarkIcon from 'components/icons/BookmarkIcon';
import { appCtx } from 'components/appStore';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from 'img/close.svg';
import { candidateCtx } from '../../AllCandidates/candidateStore';
import { candidatesCtx } from '../../store';
import styles from '../../styles';

const CandidatesTabs = observer(({ classes, history, match, theme }) => {
    const { allCount, allCandidatesStats } = useContext(candidateCtx);
    const { candidateTabs, removeCandidateTab, setCandidateTabs } = useContext(candidatesCtx);
    const { company } = useContext(appCtx);
    const companyHasCandidatesPage = checkHasCompanyFeature(company, 'ALL_CANDIDATES_PAGE');

    useEffect(() => {
        setCandidateTabs();
    }, []);

    const hasAllCandidates = checkHasCompanyFeature(company, 'ALL_CANDIDATES_PAGE');
    const { user_id } = match.params;

    const onRemove = (e, id) => {
        e.stopPropagation();
        removeCandidateTab(id);
        if (id.toString() === user_id) {
            if (companyHasCandidatesPage) {
                history.push('/all-candidates');
            } else {
                history.push('/hired');
            }
        }
    };

    const candidateTabsElements = candidateTabs.map(({ id, name }) => ({
        label: (<>
            <div><TruncateWithTooltip text={name} width={250} /></div>
            <IconButton className={classes.closeIcon} onClick={e => onRemove(e, id)} size="large">
                <img src={CloseIcon} alt="x" />
            </IconButton>
        </>),
        url: `/all-candidates/candidate/${id}`
    }));

    let tabList = [];

    if (hasAllCandidates) {
        tabList.push({
            label: (<>
                <UsersIcon color={theme.palette.primary.main} />Candidates{allCount ? ` (${allCount})` : ''}
            </>),
            className: classes.allCandidatesTab,
            url: '/all-candidates'
        });
    }

    tabList.push({
        label: (<>
            <BookmarkIcon color={theme.palette.green[600]} />Hired {allCandidatesStats.hired ? `(${allCandidatesStats.hired})` : null}
        </>),
        className: classes.shortlistTab,
        url: '/hired'
    });

    tabList = tabList.concat(candidateTabsElements);

    return (
        <InnerTabs
            className={classes.innerTabs}
            tabList={tabList}
        />
    );
});

export default withTheme(withStyles(styles)(withRouter(CandidatesTabs)));

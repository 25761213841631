import React from 'react';
import SelectOutlined from 'libraries/Select';
import { capitalizeEachFirstLetter } from 'helper/commonFunctions';

const QuestionGroupsDropdown = ({ questionGroups, questionGroupId, setQuestionGroup, setQuery }) => {
    const menuOptions = questionGroups.map(group => ({ value: group.id, label: capitalizeEachFirstLetter(group.title) }));

    const handleChangeQuestionGroup = (e) => {
        const newId = +e.target.value;
        const questionGroup = questionGroups.find(item => item.id === newId);
        setQuery('');
        setQuestionGroup({ title: questionGroup.title, id: newId });
    };

    return (
        <SelectOutlined
            disabled={!questionGroups.length}
            className="u-mrg--bx3"
            variant="outlined"
            value={questionGroups.length && questionGroupId ? questionGroupId : undefined}
            onChange={handleChangeQuestionGroup}
            label="Skill groups"
            options={menuOptions}
        />
    );
};

export default QuestionGroupsDropdown;

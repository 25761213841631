import React from 'react';

const WarningIcon = () => (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99999 17.3333C7.99999 17.3333 14.6667 14.1667 14.6667 9.41667V3.875L7.99999 1.5L1.33333 3.875V9.41667C1.33333 14.1667 7.99999 17.3333 7.99999 17.3333Z" stroke="#F87E30" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 9.83325L8 4.83325" stroke="#F87E30" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <ellipse cx="8" cy="12.3333" rx="0.833333" ry="0.833333" fill="#F87E30" />
    </svg>
);

export default WarningIcon;

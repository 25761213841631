import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Button } from '@mui/material';
import { withStyles } from '@mui/styles';

import { checkHasCompanyFeature } from 'helper/commonFunctions';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import UpgradeToUnlockTooltip from 'components/tooltips/UpgradeToUnlockTooltip';
import ChangeSlugDialog from 'components/dialogs/ChangeSlugDialog';
import { appCtx } from 'components/appStore';


const styles = theme => ({
    slug: {
        fontWeight: 600,
        fontSize: 18,
        marginTop: 30,
        color: theme.palette.grey[900]
    }
});

const SlugSettings = observer(({ classes, audition, audition: { slug } }) => {
    const [open, setOpen] = useState(false);
    const { flashMessage, company } = useContext(appCtx);

    return <>
        <Typography variant="h4">
        Custom Assessment URL
            <InfoTooltip
                id="change_slug_id"
                position="top"
                isBlack
                text="View and set a custom URL"
            />
        </Typography>
        <div className={`u-dsp--f u-jc--start u-ai--center ${classes.slug}`}>
            {slug}&nbsp;
            {
                checkHasCompanyFeature(company, 'CHANGE_AUDITION_SLUG') ? (
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={() => setOpen(true)}
                    >
                        (Change)
                    </Button>
                ) : (
                    <UpgradeToUnlockTooltip>
                        <Button
                            color="primary"
                            className="u-txt--bold"
                        >
                            (Change)
                        </Button>
                    </UpgradeToUnlockTooltip>
                )
            }
        </div>
        <ChangeSlugDialog
            open={open}
            oldSlug={slug}
            audition={audition}
            flashMessage={flashMessage}
            onClose={() => setOpen(false)}
            company={company}
        />
    </>;
});

export default withStyles(styles)(SlugSettings);

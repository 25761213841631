import React from 'react';
import { withTheme } from '@mui/styles';


const SortIcon = ({ theme, color: propsColor, ...other }) => {
    const color = propsColor || theme.palette.grey[400];
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M3.33341 14.6667H6.66675" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.33342 10.5H11.6667" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.33342 6.33325L16.6667 6.33325" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default withTheme(SortIcon);

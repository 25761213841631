import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextInputOutlined from 'libraries/TextInput';
import Dialog from 'libraries/Dialog';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { reorderQuestionGroupBefore, reorderQuestionGroupAfter } from 'requests/AssessmentRequests';

import ReorderDialog from 'components/dialogs/ReorderDialog';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import ReorderIcon from 'components/icons/ReorderIcon';


import TrashIcon from 'img/trash.svg';
import EditIcon from 'img/edit.svg';
import { MAX_SKILL_NAME_LENGTH } from 'helper/constants';

import styles from './styles';

const GroupActions = ({
    removeGroup, editGroupName,
    group, classes, index, groups,
    reorderGroups
}) => {
    const [openedEditDialog, handleOpenedEdit] = useState(false);
    const [inputValue, setInputValue] = useState(group.title || '');
    const [reorderDialogOpened, setReorderDialog] = useState(false);

    const handleEditDialog = (e) => {
        e.stopPropagation();
        if (!openedEditDialog) {
            setInputValue(group.title);
        }
        handleOpenedEdit(!openedEditDialog);
    };

    const onReorder = (e, targetQuestionIndex, newIndex, position) => {
        const { id } = group;
        const { id: targetQroupId } = groups[targetQuestionIndex];
        const reorderFunc = position === 'after' ? reorderQuestionGroupAfter : reorderQuestionGroupBefore;
        return reorderFunc(id, targetQroupId)
            .then(({ success }) => {
                if (success) {
                    reorderGroups({ oldIndex: index, newIndex });
                    handleReorderDialog(e);
                }
            });
    };

    const handleReorderDialog = (e) => {
        e.stopPropagation();
        setReorderDialog(!reorderDialogOpened);
    };

    return (
        <>
            <div className={classes.actionsWrapper}>
                {
                    groups.length > 1 && (
                        <IconButton onClick={handleReorderDialog} className={classes.iconButton} size="large">
                            <ReorderIcon />
                        </IconButton>
                    )
                }
                <BoardItemMenu
                    IconComp={MoreVert}
                    options={[
                        {
                            label: (
                                <>
                                    <img src={EditIcon} alt="|" />
                                    Edit Skill Name
                                </>
                            ),
                            className: classes.menuItem,
                            onClick: handleEditDialog
                        },
                        {
                            label: (
                                <>
                                    <img src={TrashIcon} alt="x" />
                                    Delete Skill Group
                                </>
                            ),
                            disabled: groups.length < 2,
                            className: classes.menuItem,
                            onClick: () => removeGroup(index)
                        }
                    ]}
                />
            </div>
            <ReorderDialog
                hideIndex
                entityList={groups}
                entityIndex={index}
                entity="skill group"
                onReorder={onReorder}
                labelParameter="title"
                open={reorderDialogOpened}
                onClose={handleReorderDialog}
            />
            <Dialog
                maxWidth="sm"
                actionComponent={(
                    <>
                        <Button
                            className={classes.actionDialogButton}
                            onClick={handleEditDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.actionDialogButton}
                            variant="contained"
                            color="primary"
                            disabled={!inputValue || !inputValue.trim()}
                            onClick={(e) => {
                                editGroupName(index, inputValue);
                                handleEditDialog(e);
                            }}
                        >
                            Save
                        </Button>
                    </>
                )}
                open={openedEditDialog}
                handleClose={handleEditDialog}
                onClose={handleEditDialog}
                onClick={e => e.stopPropagation()}
                titleComponent="Edit skill name"
            >
                <TextInputOutlined
                    onChange={(e) => { setInputValue(e.target.value); }}
                    value={inputValue}
                    variant="outlined"
                    maxLength={MAX_SKILL_NAME_LENGTH}
                    minLength={1}
                    placeholder="Fire Control"
                    label="Assessment question skill name"
                />
            </Dialog>
        </>
    );
};

export default withStyles(styles)(GroupActions);

export default theme => ({
    wrapper: {
        width: '100%',
        padding: '25px 0',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    status: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: -51,
        right: 50
    }
});

export default {
    container: {
        padding: '20px 35px'
    },
    nameInput: {
        width: 333,
        marginRight: 25
    },
    removeIcon: {
        width: 48,
        height: 48,
        marginLeft: 5,
        marginTop: 17
    }
};

export default theme => ({
    selectMenu: {
        fontSize: 14,
        '&:focus': {
            backgroundColor: 'transparent'
        }
    },
    menuPaper: {
        borderRadius: 4
    },
    menuItem: {
        fontSize: 12,
        color: theme.palette.grey[900],
        padding: '10px 15px',
        fontWeight: 600,
        letterSpacing: 0.156,
        '&$selectedMenuItem, &$selectedMenuItem:hover': {
            backgroundColor: theme.palette.grey[100]
        },
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        },
        '& *': {
            whiteSpace: 'pre-wrap'
        }
    },
    selectedMenuItem: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    icon: {
        marginRight: 5,
        color: theme.palette.primary.main
    }
});

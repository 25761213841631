import React from 'react';
import { withTheme } from '@mui/styles';

const TagIcon = ({ isActive, theme, color = theme.palette.grey[400], ...other }) => (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M12.9277 4.88892L5.81079 4.88892C5.19239 4.88892 4.60877 5.17498 4.22996 5.66377L1.04384 9.77488C0.484951 10.496 0.484951 11.504 1.04384 12.2252L4.22996 16.3363C4.60877 16.8251 5.19239 17.1111 5.81079 17.1111L15.9694 17.1111C17.0739 17.1111 17.9694 16.2157 17.9694 15.1111L17.9694 10.0834" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.8553 4.38041C21.1358 4.38041 21.3632 4.60778 21.3632 4.88826C21.3632 5.16874 21.1358 5.39612 20.8553 5.39612L15.2689 5.39612C14.9884 5.39612 14.761 5.16874 14.761 4.88826C14.761 4.60778 14.9884 4.3804 15.2689 4.3804L20.8553 4.38041Z" fill={color} />
        <path d="M18.5699 7.60677C18.5699 7.88725 18.3426 8.11462 18.0621 8.11462C17.7816 8.11462 17.5542 7.88725 17.5542 7.60677L17.5542 2.02035C17.5542 1.73986 17.7816 1.51249 18.0621 1.51249C18.3426 1.51249 18.5699 1.73986 18.5699 2.02035L18.5699 7.60677Z" fill={color} />
    </svg>
);

export default withTheme(TagIcon);

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import SelectOutlined from 'libraries/Select';
import { addCategoryToQuestion, getQuestionCategories } from 'requests/CMSRequests';
import Delete from '@mui/icons-material/Close';
import RemoveCategoryDialog from './RemoveCategoryDialog';

const styles = {
    wrapper: {
        marginTop: 15,
        marginBottom: 15
    },
    removeIcon: {
        height: 35,
        width: 35,
        marginLeft: 10,
        '& svg': {
            height: 25,
            width: 25
        }
    },
    optionSkill: {
        maxWidth: 560
    }
};

const QuestionCategory = ({
    classes, question: { id: questionId, category: questionCategory },
    setSaving, setQuestion
}) => {
    const [isLoading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [openDeleteCategory, setOpenDeleteCategory] = useState(null);

    useEffect(() => {
        setLoading(true);
        loadCategories();
    }, [questionId]);

    const loadCategories = () => {
        getQuestionCategories()
            .then((data) => {
                if (data) {
                    setCategoriesList(data.map(category => ({ value: category, label: category })));
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeDeleteSkillDialog = () => {
        setOpenDeleteCategory(null);
    };

    const addNewCategory = (e) => {
        const { value } = e.target;
        addCategoryToQuestion(questionId, value)
            .then(({ success, data }) => {
                if (success && data) {
                    setQuestion(data);
                }
            });
    };

    const deleteCategory = () => {
        setSaving(true);
        setDeleteLoading(true);
        addCategoryToQuestion(questionId, null)
            .then(({ success, data }) => {
                if (success && data) {
                    setQuestion(data);
                }
            })
            .finally(() => {
                setDeleteLoading(false);
                setSaving(false);
                setOpenDeleteCategory(null);
            });
    };

    if (isLoading) {
        return (
            <CircularProgress size={20} />
        );
    }

    return (
        <div className={classes.wrapper}>
            <div className="u-dsp--distribute">
                <SelectOutlined
                    value={questionCategory || null}
                    variant="outlined"
                    label="Question Category"
                    options={categoriesList}
                    onChange={addNewCategory}
                />
                {
                    questionCategory != null && (
                        <IconButton
                            className={classes.removeIcon}
                            onClick={() => setOpenDeleteCategory(true)}
                            size="large"
                        >
                            <Delete color="primary" />
                        </IconButton>
                    )
                }
            </div>
            <RemoveCategoryDialog
                open={Boolean(openDeleteCategory)}
                loading={deleteLoading}
                category={questionCategory}
                onClose={closeDeleteSkillDialog}
                onRemove={deleteCategory}
            />
        </div>
    );
};

export default withStyles(styles)(QuestionCategory);

export default theme => ({
    timerIcon: {
        marginLeft: '-2px',
        marginRight: '2px'
    },
    timerLabel: {
        marginRight: 10,
        color: theme.palette.grey[900],
        fontWeight: 600,
        fontSize: 12,
        display: 'flex',
        alignItems: 'center'
    }
});

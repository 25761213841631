export default (() => ({
    button: {
        minHeight: 36,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold'
    },
    duplicateButton: {
        minHeight: 36,
        minWidth: 225,
        fontWeight: 'bold',
        outline: 'none',
        color: '#fff',
        '&:hover, &:active, &:focus': {
            color: '#fff',
            outline: 'none'
        }
    },
    contentClassName: {
        padding: '65px 25px 40px',
        textAlign: 'center'
    },
    selectPaper: {
        maxWidth: 500
    },
    loader: {
        color: '#fff'
    }
}));

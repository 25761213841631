import React, { useState } from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import ReactHtmlParser from 'react-html-parser';
import { useStyles } from './styles';
import CloseIcon from '../../../assets/close.svg';

const TicketInfoPanel = (props) => {
    const { articles, customer, orders } = props;
    const { wrapper, knowledgeBaseContainer, blockTitle, knowledgeArticle,
        customerDetailsContainer, attributeTitle, attributeText, attributeTextOrder,
        attributeOrder, articleWrapper, drawerHeader, drawerWrapper, orderText, articleText,
        description, drawer
    } = useStyles({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeArticle, setActiveArticle] = useState();

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleArticleClick = (article) => {
        setActiveArticle(article);
        toggleDrawer();
    };

    return (
        <div className={wrapper}>
            <div className={knowledgeBaseContainer}>
                <div className={blockTitle}>Knowledge Base</div>
                {articles && articles.map(el => (
                    <div
                        key={el.id}
                        className={knowledgeArticle}
                        role="presentation"
                        onClick={() => handleArticleClick(el)}
                    >
                        {el.title}
                    </div>
                ))}
            </div>
            <div className={customerDetailsContainer}>
                <div className={blockTitle}>Customer Details</div>
                <div className={attributeTitle}>Customer Name</div>
                <div className={attributeText}>{customer.name}</div>
                {
                    customer.source === 'email' && (
                        <>
                            <div className={attributeTitle}>Customer Email</div>
                            <div className={attributeText}>{customer.customerIdentifier}</div>
                        </>
                    )
                }
                {
                    orders.length > 0 && (
                        <>
                            <div className={attributeTitle}>Account / Orders</div>
                            {orders.map(el => (
                                <div className={attributeOrder} key={el.id}>
                                    <div className={attributeTextOrder}>{el.number}</div>
                                    <p className={clsx(description, orderText)}>
                                        {ReactHtmlParser(el.details)}
                                    </p>
                                </div>
                            ))}
                        </>
                    )
                }
            </div>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer}
                classes={{ root: drawer, paper: drawerWrapper }}
            >
                <div className={drawerHeader}>
                    <img src={CloseIcon} alt="" onClick={toggleDrawer} />
                    {activeArticle && activeArticle.title}
                </div>
                {
                    activeArticle && (
                        <div className={articleWrapper}>
                            <p className={clsx(description, articleText)}>
                                {ReactHtmlParser(activeArticle.content)}
                            </p>
                        </div>
                    )
                }
            </Drawer>
        </div>
    );
};

export default TicketInfoPanel;

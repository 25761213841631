export default theme => ({
    mansoryWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 -12px 0',
        '& > div': {
            marginLeft: 13,
            marginRight: 13,
            marginBottom: 30
        },
        [theme.breakpoints.down('lg')]: {
            margin: '0 -25px 0',
            '& > div': {
                marginLeft: 25,
                marginRight: 25
            }
        },
        '@media only screen and (max-width: 795px)': {
            '& > div': {
                margin: '0 calc(50% - 160px) 30px'
            }
        }
    },
    jobCount: {
        fontWeight: 600,
        marginBottom: 32
    },
    wrapper: {
        padding: 0,
        '@media only screen and (max-width: 790px)': {
            padding: 15
        },
        '@media only screen and (max-width: 763px)': {
            padding: 0,
            backgroundColor: 'inherit',
            border: 'none'
        }
    }
});

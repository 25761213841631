import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from '@mui/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from 'libraries/Tooltip';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    noRankIcon: {
        color: theme.palette.grey[600]
    },
    arrowWrapper: {
        display: 'inline-block',
        position: 'absolute',
        width: 20,
        paddingLeft: 17,
        color: theme.palette.red[600],
        height: 28
    },
    arrowWrapperActive: {
        color: theme.palette.green[400]
    },
    sortArrow: {
        position: 'absolute',
        left: 0,
        color: '#E1E1E1'
    },
    sortArrowActive: {
        color: theme.palette.grey[600]
    },
    sortArrowUp: {
        top: -2,
        color: theme.palette.green[400]
    },
    sortArrowDown: {
        top: 2,
        color: theme.palette.red[600]
    },
    tooltip: {
        maxWidth: 250
    }
}));


const RankCell = observer(({ rank, rankDifference }) => {
    const classes = useStyles();

    const isUp = Boolean(rankDifference && rankDifference > 0);

    return (
        <>
            {rank || <RemoveIcon className={classes.noRankIcon} /> }
            {
                Boolean(rankDifference) && (
                    <Tooltip
                        tooltipClass={classes.tooltip}
                        label="Rank changes are impacted by active sort selections. Disable to return to default ranking."
                    >
                        <span className={clsx(classes.arrowWrapper, isUp && classes.arrowWrapperActive)}>
                            {Math.abs(rankDifference)}
                            {
                                isUp ? (
                                    <ArrowDropUpIcon className={clsx(classes.sortArrow, classes.sortArrowUp)} />
                                ) : (
                                    <ArrowDropDownIcon className={clsx(classes.sortArrow, classes.sortArrowDown)} />
                                )
                            }
                        </span>
                    </Tooltip>
                )
            }
        </>
    );
});

export default withRouter(RankCell);

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LockIcon from 'img/lock.svg';
import Popover from 'libraries/Popover';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import Moment from 'react-moment';
import styles from './styles';


const AssessmentPopoverSelect = observer(({ classes, assessmentsList, children = null, onSelect }) => {
    const [popover, setPopover] = useState(null);

    if (!assessmentsList || !assessmentsList.length) return null;

    const closePopover = () => {
        if (popover) {
            popover.handleClose();
        }
    };

    if (assessmentsList.length === 1) {
        return children;
    }

    return (
        <Popover
            className={classes.popoverRoot}
            onMounted={callbacks => setPopover(callbacks)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            label={children}
        >
            <div className={classes.headerWrapper}>
                <Typography variant="h5">Report Cards</Typography>
                <p className={classes.subtopic}>select an assessment to view candidate results</p>
            </div>
            <div className={classes.opportunitiesWrapper}>
                {
                    assessmentsList.map(({ name, slug, completedAt, isCompletionLimitExceeded }) => (
                        <MenuItem
                            key={slug}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (onSelect) onSelect(slug);
                                closePopover();
                            }}
                            disabled={isCompletionLimitExceeded}
                            className={classes.assessmentWrapper}
                        >
                            <div className={classes.name}>
                                <TruncateWithTooltip text={name} width={260} />
                            </div>
                            {
                                completedAt ? (
                                    <div className={classes.date}>
                                        <Moment format="D MMMM, YYYY">{completedAt}</Moment>
                                    </div>
                                ) : <div className={classes.date}>Incomplete</div>
                            }
                            <IconButton className={classes.iconButton}>
                                {
                                    isCompletionLimitExceeded ? <img src={LockIcon} alt="lock" /> : <KeyboardArrowRightIcon className={classes.icon} />
                                }

                            </IconButton>
                        </MenuItem>
                    ))
                }
            </div>
        </Popover>
    );
});

export default withStyles(styles)(AssessmentPopoverSelect);

export default {
    menu: {
        minWidth: 150
    },
    btnLang: {
        color: '#fff',
        minWidth: 150,
        borderRadius: 25,
        backgroundColor: '#1a1b1e',
        fontSize: 12,
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 29,
        padding: '0 10px 0px 20px',
        textTransform: 'uppercase',
        minHeight: 40
    },
    btnLangLabel: {
        fontSize: 12,
        color: 'white'
    },
    btnLangDis: {
        opacity: 1,
        color: 'white',
        '&:disabled': {
            color: 'white'
        }
    }
};

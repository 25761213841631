import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { getQuestionsToComparePublic } from 'requests/AssessmentRequests';
import { getPublicCandidateAudition } from 'requests/JobOpportunityRequests';

import ShortlistCardHeader from 'pages/assessment/results_assessment/shortlist/ShortlistCardHeader';
import AnswersContent from 'pages/assessment/results_assessment/shortlist/CompareDialog/AnswersContent';
import SkillsContent from 'pages/assessment/results_assessment/shortlist/CompareDialog/ScoreContent';
import CommentsContainer from 'pages/assessment/results_assessment/shortlist/CompareDialog/CommentsContainer';
import TagContent from 'pages/assessment/results_assessment/shortlist/CompareDialog/TagContent';

import useStyles from './styles';


const CompareContent = observer(({
    handleClose, audition, candidatesInfo,
    gradeableCard, candidatesIdsToCompare = {},
    hideCandidateDetails, opportunitySlug
}) => {
    const classes = useStyles({ compareNumber: Object.keys(candidatesIdsToCompare).length });
    const [candidatesData, setCandidatesData] = useState({});
    const [loadingCandidates, setLoadingCandidates] = useState({});
    const { slug, uuid } = audition;

    useEffect(() => {
        setCandidatesData({});
        setLoadingCandidates({});
        Object.keys(candidatesIdsToCompare).forEach((id, index) => {
            getUserInfo(id, candidatesIdsToCompare[id], index);
        });
    }, []);

    const getUserInfo = (userId, userAuditionUuid, index) => {
        setLoadingCandidates(oldLoadingCandidates => ({ ...oldLoadingCandidates, [userId]: true }));
        getPublicCandidateAudition(userAuditionUuid, gradeableCard)
            .then(({ success, data }) => {
                if (success) {
                    setCandidatesData((oldCandidatesData) => {
                        const newCandidatesData = ({
                            ...oldCandidatesData,
                            [userId]: {
                                ...candidatesInfo[userId].candidate,
                                ...data
                            }
                        });
                        if (hideCandidateDetails) newCandidatesData[userId].fullName = `Candidate ${index + 1}`;
                        return newCandidatesData;
                    });
                }
            })
            .catch(() => {
                handleClose();
            })
            .finally(() => {
                setLoadingCandidates((oldLoadingCandidates) => {
                    const newLoadingCandidates = { ...oldLoadingCandidates };
                    delete newLoadingCandidates[userId];
                    return newLoadingCandidates;
                });
            });
    };

    const getAnswersContent = () => getQuestionsToComparePublic(uuid);
    const hideScore = gradeableCard && Object.keys(candidatesIdsToCompare).every(id => candidatesData[id] && !candidatesData[id].score);

    return (
        <div className={classes.mainWrapper}>
            <div className="u-dsp--f">
                <div className={clsx(classes.category, classes.candidateCategory)}>Candidate</div>
                {
                    Object.keys(candidatesIdsToCompare).map(id => (
                        id in loadingCandidates ? (
                            <div key={id} className={classes.candidateHeader}>
                                <CircularProgress
                                    size={15}
                                    className={classes.loader}
                                    color="primary"
                                />
                            </div>
                        ) : (
                            <div key={id} className={classes.candidateHeader}>
                                <ShortlistCardHeader
                                    propPublic
                                    gradeableCard={gradeableCard}
                                    candidate={candidatesData[id]}
                                />
                            </div>
                        )
                    ))
                }
            </div>
            <div className={classes.wrapper}>
                {
                    !hideScore && (
                        <div className="u-dsp--f">
                            <div className={classes.category}>Skill Scores</div>
                            {
                                Object.keys(candidatesIdsToCompare).map(id => (
                                    <div className={classes.candidateWrapper} key={id}>
                                        <SkillsContent
                                            candidate={candidatesData[id]}
                                            isLoading={id in loadingCandidates}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                <div className="u-dsp--f">
                    <div className={classes.category}>Tags</div>
                    {
                        Object.keys(candidatesIdsToCompare).map(id => (
                            <div className={classes.candidateWrapper} key={id}>
                                <TagContent
                                    candidate={candidatesData[id]}
                                    isLoading={id in loadingCandidates}
                                />
                            </div>
                        ))
                    }
                </div>
                {
                    gradeableCard && (
                        <div className="u-dsp--f">
                            <div className={classes.category}>Comments</div>
                            {
                                Object.keys(candidatesIdsToCompare).map(id => (
                                    <div className={classes.candidateWrapper} key={id}>
                                        <CommentsContainer
                                            userId={candidatesData[id] ? candidatesData[id].candidateId : ''}
                                            propPublic
                                            opportunitySlug={opportunitySlug}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                <div className="u-dsp--f">
                    <div className={classes.category}>Answers</div>
                    <AnswersContent
                        candidatesIdsToCompare={candidatesIdsToCompare}
                        auditionSlug={slug}
                        propPublic
                        getAnswersContent={getAnswersContent}
                        audition={audition}
                    />
                </div>
            </div>
        </div>
    );
});

export default withRouter(CompareContent);

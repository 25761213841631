import React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import PositiveIcon from './good.svg';
import NegativeIcon from './bad.svg';

import useStyles from './styles';

const getSkillLabel = type => ({
    TOP_TWO: <>top <b>2%</b></>,
    TOP_FIVE: <>top <b>5%</b></>,
    TOP_TEN: <>top <b>10%</b></>,
    BOTTOM_TWENTY: <>bottom <b>20%</b></>,
    BOTTOM_TEN: <>bottom <b>10%</b></>
}[type] || null);

const Achievements = ({ achievements }) => {
    const classes = useStyles();
    if (!achievements || !achievements.length) return null;

    return (
        <div className={classes.wrapper}>
            <Typography variant="h6" className="u-mrg--bx2 u-mrg--tx1 u-txt--15">Achievements</Typography>
            <div className={classes.achievementWrapper}>
                {
                    achievements.map(({ skillName, type, positive }, index) => (
                        <div key={index} className={clsx(classes.achievement, skillName.length > 30 && classes.achievementLong, skillName.length > 60 && classes.achievementSuperLong)}>
                            <div className={classes.iconWrapper}>
                                <img src={positive ? PositiveIcon : NegativeIcon} alt="!" />
                            </div>
                            <div>Finished {getSkillLabel(type)} in <span className="u-txt--cap">{skillName}</span></div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Achievements;

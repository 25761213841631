export default theme => ({
    root: {
        color: theme.palette.primary.main,
        '&$checked': {
            color: theme.palette.primary.main
        },
        '&$disabled': {
            color: theme.palette.primary.main
        }
    },
    notActive: {
        cursor: 'default'
    },
    disabled: {
        opacity: 0.5
    },
    label: {
        color: theme.palette.grey[900]
    },
    checked: {},
    formLabelRoot: {
        width: '100%'
    },
    formHelperText: {
        fontSize: 10,
        letterSpacing: 0.1,
        color: theme.palette.grey[600],
        marginLeft: 33,
        marginTop: -7,
        marginBottom: 20
    },
    checkboxRoot: {
        color: theme.palette.primary.main,
        padding: '6px 11px',
        '&$checkboxRootChecked': {
            color: theme.palette.primary.main
        }
    },
    checkboxRootChecked: {},
    formControlLabelRoot: {
        width: '100%'
    },
    formControlLabel: {
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.5)'
    }
});

import React from 'react';
import { withStyles } from '@mui/styles';
import styles from '../styles';

const AnswersHeader = ({ candidateName, plagiarismSource, plagiarismTitle, classes }) => (
    <div className={classes.answersWrapper}>
        <div className={classes.text}>
            <p className={classes.candidateName}><strong>{candidateName}</strong></p>
            <p><strong>Candidate Answer</strong></p>
        </div>
        <div className={classes.text}>
            <p className={classes.anonymous}><strong>{plagiarismTitle}</strong></p>
            <p><strong>{plagiarismSource}</strong></p>
        </div>
    </div>
);

export default withStyles(styles)(AnswersHeader);

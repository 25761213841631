import React from 'react';
import { withStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
    root: {
        padding: 0,
        '& > div': {
            minHeight: '54px',
            borderRadius: 4,
            border: 'solid 2px #fff',
            backgroundColor: theme.palette.grey[900],
            fontSize: 16,
            fontWeight: 600,
            color: '#ffffff',
            padding: '6px 21px'
        }
    },
    anchorOriginBottomLeft: {
        zIndex: '99999 !important'
    },
    pendingIcon: {
        color: theme.palette.info.main
    }
});

export default withStyles(styles)(({ action, classes, onClose, ...other }) => {
    let actionElement = null;

    switch (action) {
        case 'done':
            actionElement = (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path fill="none" fillRule="evenodd" stroke="#00c8af" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 14.046L9.65 17 18 8" />
                </svg>
            );
            break;
        case 'pending':
            actionElement = (
                <CircularProgress
                    size={22}
                    className={classes.pendingIcon}
                    thickness={3}
                />
            );
            break;
        case 'error':
            actionElement = (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 8L8 24" stroke="#FF113D" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 8L24 24" stroke="#FF113D" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
            break;
        default:
            break;
    }

    return (
        <Snackbar
            classes={{
                root: classes.root,
                anchorOriginBottomLeft: classes.anchorOriginBottomLeft
            }}
            action={actionElement}
            {...other}
            onClose={action === 'pending' ? () => {} : onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
        />
    );
});

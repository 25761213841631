export default {
    iconClose: {
        color: 'grey'
    },
    header: {
        color: 'rgba(0,0,0,0.63)',
        fontSize: '14px',
        lineHeight: '16px',
        padding: '16px 20px 11px'
    },
    immersiveWrapper: {
        cursor: 'pointer'
    },
    error: {
        fontSize: 10,
        fontWeight: 600,
        letterSpacing: '0.8px',
        color: '#f02e2e',
        marginBottom: 19,
        textTransform: 'uppercase',
        '& img': {
            width: 16,
            marginRight: 10
        }
    },
    tableHead: {
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        padding: 5,
        marginBottom: 20
    },
    cell: {
        width: '25%',
        marginRight: 7,
        '&:nth-child(2)': {
            paddingLeft: 15
        },
        '&:nth-child(3)': {
            paddingLeft: 15
        }
    }
};

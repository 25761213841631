export default (theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    mainButton: {
        width: 150,
        minHeight: 36,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold',
        outline: 'none',
        color: '#fff',
        '&:hover, &:active, &:focus': {
            color: '#fff',
            outline: 'none'
        }
    },
    secondaryNoBorderButton: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        minHeight: 50,
        padding: 3,
        fontSize: 14,
        fontWeight: 700,
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        marginBottom: 15,
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.grey[200],
            borderColor: theme.palette.grey[400]
        }
    },
    activeOption: {
        color: theme.palette.primary.main,
        background: theme.palette.blue[50],
        borderColor: theme.palette.primary.main
    },
    text: {
        fontSize: 14,
        fontWeight: 600,
        marginTop: 15
    },
    inputsContainer: {
        margin: '15px 0',
        '&>*': {
            margin: '7px 0'
        }
    },
    editManagerForm: {
        width: '100%',
        padding: '0 10px'
    },
    notSureButton: {
        fontWeight: 700
    },
    loader: {
        color: 'white'
    }
}));

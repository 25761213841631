export default theme => ({
    wrapper: {
        minWidth: 300
    },
    addRow: {
        paddingLeft: 10,
        paddingRight: 10,
        height: 38,
        textTransform: 'none',
        fontWeight: 'bold'
    },
    importCsvBtn: {
        paddingLeft: 23,
        paddingRight: 23,
        color: '#1f2032',
        fontWeight: 'bold',
        backgroundColor: '#fff',
        textTransform: 'none'
    },
    importCsv: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    importCsvButton: {
        marginRight: 15
    },

    importCsvPopover: {
        fontWeight: 600,
        color: '#9FA0A5',
        fontSize: 14,

        '& i': {
            verticalAlign: 'middle'
        },

        '& span': {
            lineHeight: '24px',
            verticalAlign: 'middle'
        }
    },
    importCsvPopoverContent: {
        fontWeight: 400,
        fontSize: 14,
        '& b': {
            fontWeight: 700
        },
        '& img': {
            marginTop: 10
        }
    },
    csvError: {
        marginTop: 10,
        fontWeight: 600,
        color: theme.palette.red.primary
    },
    form: {
        maxHeight: 280,
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: 10,
        paddingTop: 10
    }
});

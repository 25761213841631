import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { appCtx } from 'components/appStore';
import { observer } from 'mobx-react-lite';
import { checkCompanyCanBeUpgraded } from 'helper/commonFunctions';
import SubscriptionsLink from '../../../../../subscription/SubscriptionsLink';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '9px 24px',
        marginRight: 10,
        lineHeight: '21px',
        whiteSpace: 'nowrap',
        borderWidth: 2,
        borderRadius: 4,
        borderColor: theme.palette.blue[500],
        borderStyle: 'solid',
        color: theme.palette.blue[500],
        '&:hover': {
            color: theme.palette.blue[500],
            backgroundColor: '#f6f8ff!important'
        }
    }
}));

const UpgradeButton = observer(() => {
    const { button } = useStyles();
    const { company, isLoadingCompany } = useContext(appCtx);

    const canBeUpgraded = checkCompanyCanBeUpgraded(company);

    if (!canBeUpgraded || isLoadingCompany) return null;

    return (
        <SubscriptionsLink
            label={(
                <Button
                    className={button}
                    variant="outlined"
                >
                    Upgrade Plan
                </Button>
            )}
            segmentLocation="header right button"
        />
    );
});

export default UpgradeButton;

import React from 'react';
import { withTheme } from '@mui/styles';

const SettingsIcon = ({ theme, color = theme.palette.grey[600], ...other }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8Z" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4356 2.29296C16.248 2.10538 15.9937 2 15.7284 2H8.27266C8.00745 2 7.75309 2.10536 7.56556 2.29289L2.29289 7.56556C2.10536 7.75309 2 8.00745 2 8.27266V15.7284C2 15.9937 2.10538 16.248 2.29296 16.4356L7.56557 21.7072C7.7531 21.8947 8.00742 22 8.27261 22H15.7285C15.9937 22 16.248 21.8946 16.4356 21.7071L21.7071 16.4356C21.8946 16.248 22 15.9937 22 15.7285V8.27261C22 8.00742 21.8947 7.7531 21.7072 7.56557L16.4356 2.29296Z" stroke={color} strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(SettingsIcon);

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: theme.palette.grey[100],
        paddingBottom: '10px',
        '& .rdw-link-modal': {
            right: 40,
            left: 'unset'
        },
        '& .rdw-link-modal-target-option': {
            display: 'none'
        },
        '& .rdw-right-aligned-block div': {
            textAlign: 'right!important'
        },
        '& .rdw-center-aligned-block div': {
            textAlign: 'center!important'
        },
        '& .rdw-justify-aligned-block div': {
            textAlign: 'justify!important'
        }
    },
    editor: {
        border: '1px solid',
        borderColor: theme.palette.grey[400],
        backgroundColor: 'white',
        padding: '20px 20px 40px',
        minHeight: '450px',
        '& *': {
            marginTop: 0,
            marginBottom: 0
        },
        '& a span': {
            textDecoration: 'underline'
        }
    }
}));

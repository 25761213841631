import React from 'react';
import { withTheme } from '@mui/styles';

const TiktokIcon = ({ theme, active, size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect width="32" height="32" rx="4" fill={active ? theme.palette.primary.main : theme.palette.grey[400]} />
        <path d="M18.8455 20.0758C18.8455 22.2425 17.0894 23.9986 14.9228 23.9986C12.7561 23.9986 11 22.2425 11 20.0758C11 17.9092 12.7561 16.1531 14.9228 16.1531" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M18.8455 20.0758V7" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M24 12.2303C21.1468 12.1898 18.8455 9.86361 18.8455 7" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    </svg>
);

export default withTheme(TiktokIcon);

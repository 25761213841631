import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import CandidateIcon from './icon.svg';

import styles from './styles';


const QuestionAddedToAssessmentDialog = ({
    open, classes, onClose, setActiveTab,
    selectQuestion, activeQuestionIndex, isSimulationList,
    questions, updateQuestion, newQuestionData
}) => {
    const handleNextQuestion = () => {
        if (isSimulationList && updateQuestion && newQuestionData) updateQuestion(newQuestionData);
        onClose();
        selectQuestion(activeQuestionIndex + 2); // selects question next to active
    };

    const handleEditQuestion = () => {
        onClose();
        setActiveTab(0); // switches to 'Create' tab
        if (isSimulationList && updateQuestion && newQuestionData) updateQuestion(newQuestionData);
    };

    const lastQuestionInList = questions.length - 1 !== activeQuestionIndex;

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}
            handleClose={onClose}
            contentClassName={classes.contentClassName}
            actionComponent={(
                    <>
                        {
                            lastQuestionInList && (
                                <Button
                                    className={classes.button}
                                    onClick={handleNextQuestion}
                                >
                                    Add next question
                                </Button>
                            )
                        }
                        <Button
                            className={classes.duplicateButton}
                            color="primary"
                            variant="contained"
                            onClick={handleEditQuestion}
                        >
                            Edit Question
                        </Button>
                    </>
            )}
        >
            <img src={CandidateIcon} alt="!" className="u-mrg--bx2" />
            <div className={classes.contentTitle}>
                Question has been added to <br /> assessment
            </div>
            <div className={classes.contentText}>
                {`Do you want to edit this question${lastQuestionInList ? ' or add another' : ''}?`}
            </div>
        </Dialog>
    );
};

export default withStyles(styles)(QuestionAddedToAssessmentDialog);

export default theme => ({
    textFieldWrapper: {
        position: 'relative',
        maxWidth: 500
    },
    buttonRoot: {
        backgroundColor: 'rgb(245, 245, 245)',
        position: 'absolute',
        right: 10,
        top: 30,
        zIndex: 1,
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 1.17,
        letterSpacing: 0.2,
        color: '#676a78',
        '&:hover': {
            backgroundColor: 'rgb(245, 245, 245)'
        }
    },
    dialogButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        padding: '8px 25px'
    },
    pageUpLink: {
        color: theme.palette.primary.main,
        textDecoration: 'underline'
    },
    input: {
        maxWidth: 500
    }
});

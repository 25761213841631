import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Select from 'libraries/Select';
import { getAssessmentCategories } from 'requests/ScriptRequests';
import { appCtx } from 'components/appStore';

import { observer } from 'mobx-react-lite';
import styles from './styles';


const CategoriesSelect = observer(({ classes, audition: { category }, changeAssessmentSettings }) => {
    const [selectedCategoryId, setSelectedCategoryId] = useState(category && category.uuid ? category.uuid : '-');
    const [categoriesList, setCategoriesList] = useState([]);
    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        setSelectedCategoryId(category && category.uuid ? category.uuid : '-');
    }, [category]);

    const getCategories = () => {
        getAssessmentCategories()
            .then(({ success, data }) => {
                if (success && data) {
                    setCategoriesList(data);
                }
            });
    };

    const handleCategory = (e) => {
        const { value } = e.target;
        setSelectedCategoryId(value);
        changeAssessmentSettings({ category: value === '-' ? null : value })
            .then(() => {
                flashMessage('Category changed', 'done');
            });
    };

    return (
        <Select
            value={selectedCategoryId}
            name="size"
            onChange={handleCategory}
            label="Category"
            placeholder="Category"
            fullWidth
            className="u-mrg--bx5"
            variant="outlined"
            menuPaperClassName={classes.selectPaper}
            options={
                [{ label: 'No category', value: '-' }]
                    .concat(categoriesList
                        .filter(({ id }) => id !== -1)
                        .map(({ title, uuid }) => ({ label: title, value: uuid })))
            }
        />
    );
});

export default withStyles(styles)(withRouter(CategoriesSelect));

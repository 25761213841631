import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, withTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'libraries/TooltipWhite';
import TooltipNew from 'libraries/Tooltip';

import { SCORE_TYPE_MANUAL } from 'helper/constants';
import UsersIcon from 'components/icons/UsersIcon';
import GradedTeamList from 'components/candidate_cards/GradedTeamList';

import { candidateResultCtx } from '../CandidateListWrapper/candidatesStore';

const useStyles = makeStyles(() => ({
    tooltip: {
        padding: '0!important'
    },
    icon: {
        borderRadius: 4,
        padding: 6,
        marginRight: 5
    }
}));

const TeamScore = observer(({ theme, gradedBy, candidateId, score: scoreObj, disabled }) => {
    const classes = useStyles();
    const { tooltip, icon } = classes;

    const { isEmployerSortEnabled } = useContext(candidateResultCtx);

    if (!gradedBy || !gradedBy.length || !scoreObj || isEmployerSortEnabled || scoreObj.type !== SCORE_TYPE_MANUAL) {
        return (
            <TooltipNew
                label="Team Score"
            >
                <IconButton className={icon} size="large" disabled={disabled}>
                    <UsersIcon color={theme.palette.grey[400]} />
                </IconButton>
            </TooltipNew>
        );
    }

    return <>
        <IconButton
            className={icon}
            data-tip
            data-for={`graded_by_${candidateId}`}
            size="large"
            disabled={disabled}
        >
            <UsersIcon color={theme.palette.green[600]} />
        </IconButton>
        <Tooltip
            position="top"
            id={`graded_by_${candidateId}`}
            className={tooltip}
        >
            <GradedTeamList gradedBy={gradedBy} />
        </Tooltip>
    </>;
});

export default withTheme(TeamScore);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';

const MessageFooterElement = observer(({ columnType, type, opportunity }) => {
    const { company } = useContext(appCtx);
    const { description } = opportunity;

    if ((type === 'invitation' && columnType === 'registered') || (type === 'invitation' && columnType === 'custom')) {
        return (
            <div className="u-mrg--tx10 o-box  o-box--sm  o-box--border u-rounded-alt  u-txt--left">
                <h2 className="u-mrg--bx2  u-txt--break" style={{ color: company.companyColor }}>{opportunity.jobTitle}</h2>
                <div>
                    <div className="u-txt--10  t-txt--black-54  u-mrg--bx2">
                        <i className="icon-location  u-mrg--r/2" />
                        {
                            opportunity.location || 'Remote'
                        }
                        {
                            opportunity.jobType
                            && <span> - {opportunity.jobType}</span>
                        }

                    </div>
                    {
                        opportunity.description && (
                            <div
                                className="c-text-edited  [ u-txt--16  u-txt--light ] t-txt--black-54"
                                dangerouslySetInnerHTML={{ __html: description.toString('html') }}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
    return null;
});

export default MessageFooterElement;

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';

import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import { checkHasCompanyFeature, checkPluralNew } from 'helper/commonFunctions';
import { SEND_SMS_INVITATION } from 'helper/constants';
import { appCtx } from 'components/appStore';
import ButtonBase from '@mui/material/ButtonBase';
import HelpIcon from 'img/helpIcon.svg';
import Tooltip from 'libraries/Tooltip';
import { getAvailablePlans } from 'requests/SubscriptionRequests';
import ActionsBlock from './ActionsBlock';

import styles from './styles';

const UNLIMITED = 'Unlimited';

const SubscriptionInfoBlock = observer(({
    companyPlan, cards, cancelAtPeriodEnd, period,
    classes, remainingDays,
    updateCompany: customUpdateCompany, pauseAllowed,
    subscriptionId, getCompanyPlan, pausesCount, daysOfPauseLeft,
    countSmsLeft, countSmsPerCompanyQuota,
    activeAuditionsPerCompany, activeAuditionsPerCompanyLeft
}) => {
    const [showOutdatedInfo, setShowOutdatedInfo] = useState(false);
    const { company } = useContext(appCtx);
    const { name, level } = companyPlan;
    const { companySettings, isSubscriptionPaused, subscription: isStripeSubscription } = company;
    if (!companySettings) return null;
    const { teamMembers, teamMembersUsed, candidatesCompletionsPerOpportunity,
        candidatesCompletionsPerOpportunityIncrementedMonthly } = companySettings;

    const isUnlimited = value => value === -1;

    const renderPlanInterval = () => {
        if (!period) return null;
        return period === 'month' ? '(monthly)' : '(annually)';
    };

    const hasSMSFeature = checkHasCompanyFeature(company, SEND_SMS_INVITATION);
    const remainingDaysLabel = (remainingDays === 0) ? '<1' : remainingDays;

    useEffect(() => {
        getAvailablePlans().then(({ data, success }) => {
            if (success && data && data.length) {
                setShowOutdatedInfo(data.every(el => el.name !== name) && level > 1);
            }
        });
    }, []);

    const goToSubscription = () => window.open('/subscriptions');

    const teamMembersLeft = teamMembers - teamMembersUsed;

    return (
       <>
           <div className={classes.currentPlanHeader}>
               <div className="u-dsp--f u-fdir--column">
                   <span className={classes.currentPlanText}>Current Plan</span>
                   <span className={classes.currentPlan}>{name}</span>
               </div>
               {showOutdatedInfo && (
                   <div className={classes.outdatedText}>
                       *This plan has been discontinued. Lock in your current price by keeping it active or change to one of our new plans
                   </div>
               )}
               <ButtonBase className={classes.changePlanButton} onClick={goToSubscription}>Change Plan</ButtonBase>
           </div>
           <ContentWrapper className="u-pdn--bx6" style={{ marginTop: 0, paddingTop: 25 }}>
               <div className={classes.currentPlanLabel}>
                   Your plan: <ButtonBase className={classes.planName} onClick={goToSubscription}>{name} {renderPlanInterval()}</ButtonBase>
               </div>
               <div className={classes.infoWrapper}>
                   {
                       level !== 1 && remainingDaysLabel && (
                           <div className={classes.infoLabel}>
                               Subscription Plan&nbsp;
                               <span className={classes.infoValue}>
                                    ({remainingDaysLabel} {checkPluralNew(remainingDays, 'Day')} remaining)
                               </span>
                           </div>
                       )
                   }
                   <div className={classes.infoLabel}>
                       <span className={classes.infoValue}>
                           {isUnlimited(activeAuditionsPerCompany) ? UNLIMITED : `${activeAuditionsPerCompanyLeft}/${activeAuditionsPerCompany}`}
                            &nbsp;
                       </span>
                        Active Assessment{activeAuditionsPerCompanyLeft !== 1 && 's'}
                   </div>
                   <div className={classes.infoLabel}>
                       <span className={classes.infoValue}>
                           {isUnlimited(teamMembers) ? UNLIMITED : `${teamMembersLeft}/${teamMembers}`}
                           &nbsp;
                       </span>
                       User Seat{teamMembersLeft !== 1 && 's'}
                       {
                           level === 4 && (
                               <Tooltip label="Talk to sales to add more team seats">
                                   <img className="u-mrg--lx2" src={HelpIcon} alt="" />
                               </Tooltip>
                           )
                       }
                   </div>
                   {
                       name.toLowerCase() !== 'candidate pack' && (
                           <div className={classes.infoLabel}>
                               <span className={classes.infoValue}>
                                   {isUnlimited(candidatesCompletionsPerOpportunity) ? UNLIMITED : `${candidatesCompletionsPerOpportunity}`}
                                &nbsp;
                               </span>
                            Candidate Limit {!isUnlimited(candidatesCompletionsPerOpportunity) && level !== 1
                             && `(per ${candidatesCompletionsPerOpportunityIncrementedMonthly ? 'month' : 'year'})`}
                           </div>
                       )
                   }
                   {
                       hasSMSFeature && (
                           <div className={classes.infoLabel}>
                               SMS Remaining&nbsp;
                               <span className={classes.infoValue}>
                                   ({isUnlimited(countSmsPerCompanyQuota) ? UNLIMITED : `${countSmsLeft}/${countSmsPerCompanyQuota}`})
                               </span>
                           </div>
                       )
                   }
               </div>
               <ActionsBlock
                   companyPlan={companyPlan}
                   cards={cards}
                   remainingDays={remainingDays}
                   updateCompany={customUpdateCompany}
                   cancelAtPeriodEnd={cancelAtPeriodEnd}
                   subscriptionId={subscriptionId}
                   getCompanyPlan={getCompanyPlan}
                   period={period}
                   pausesCount={pausesCount}
                   daysOfPauseLeft={daysOfPauseLeft}
                   isSubscriptionPaused={isSubscriptionPaused}
                   pauseAllowed={pauseAllowed}
                   isStripeSubscription={isStripeSubscription}
               />
           </ContentWrapper>
       </>
    );
});

export default withStyles(styles)(SubscriptionInfoBlock);

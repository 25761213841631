import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    accordion: {
        boxShadow: 'none',
        '&:before': {
            backgroundColor: theme.palette.grey[300]
        },
        '&$accordionExpanded': {
            margin: 0,
            '&:before': {
                opacity: 1
            }
        }
    },
    accordionExpanded: {},
    accordionSummary: {
        minHeight: 'auto',
        padding: '0 16px 0 10px',
        '&$accordionSummaryExpanded': {
            minHeight: 'auto'
        }
    },
    accordionSummaryExpanded: {},
    accordionSummaryContent: {
        margin: 0,
        '&$accordionSummaryExpanded': {
            margin: 0
        }
    },
    accordionDetails: {
        display: 'block',
        background: theme.palette.grey[100],
        padding: 0
    },
    menu: {
        maxWidth: 285,
        maxHeight: 390,
        overflow: 'auto',
        minWidth: 240,
        padding: 0
    },
    menuItem: {
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[200]}`
        },
        padding: '6px 10px'
    },
    filterOption: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textTransform: 'capitalize',
        marginBottom: 0,
        width: '100%',
        color: '#3A3C43',
        fontWeight: 600
    },
    filterOptionLabel: {
        display: 'flex',
        alignItems: 'center'
    },
    filterOptionDescription: {
        maxWidth: 170,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    checkbox: {
        padding: 0,
        marginRight: 10
    },
    expandIcon: {
        color: theme.palette.grey[400]
    }
}));

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    container: {
        padding: '0 24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    wrapper: {
        border: '1px solid #EBECEE',
        backgroundColor: '#fff',
        borderRadius: 4,
        margin: '0 auto',
        maxWidth: 720,
        '& p': {
            '&:last-child': {
                marginBottom: 0
            },
            marginBottom: 30
        }
    },
    date: {
        color: theme.palette.grey[600],
        textTransform: 'uppercase',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 30,
        fontWeight: 700
    },
    divider: {

    },
    section: {
        padding: '45px 60px 45px 60px',
        [theme.breakpoints.down('md')]: {
            padding: 20
        },
        '&:not(:last-child)': {
            borderBottom: '2px solid #F3F3F3'
        },
        '&:not(:first-child)': {
            fontWeight: 700
        }
    },
    gradeScaleWrapper: {
        maxWidth: 'fit-content'
    },
    button: {
        backgroundColor: theme.palette.grey[900],
        width: 150,
        marginTop: 25
    },
    imgContainer: {
        margin: '15px auto 50px',
        maxWidth: 720
    },
    radio: props => ({
        '& .Mui-checked': {
            color: props.companyColor
        },
        marginTop: 5,
        '& span': {
            fontWeight: 600
        }
    }),
    input: props => ({
        '& .MuiOutlinedInput-root': {
            '&:hover': {
                '& fieldset': {
                    borderColor: `${props.companyColor}!important`
                }
            }
        },
        '& .Mui-focused': {
            color: `${props.companyColor}!important`,
            '& fieldset': {
                borderColor: `${props.companyColor}!important`
            }
        }
    }),
    logoContainer: {
        maxWidth: 720,
        margin: '40px auto 15px'
    },
    loader: {
        color: '#292A2D'
    },
    header: {
        fontWeight: 700,
        fontSize: 20
    }
}));

export default useStyles;

import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';

import TextInputOutlined from 'libraries/TextInput';
import Dialog from 'libraries/Dialog';
import { onDeactivateCallback } from 'helper/assessmentFunctions';
import { deactivateAssessment } from 'requests/AssessmentRequests';
import AssessmentEvents from 'events/AssessmentEvents';
import { appCtx } from '../../../appStore';

import styles from '../styles';

const options = [
    {
        index: 1,
        text: 'Just testing an assessment'
    },
    {
        index: 2,
        text: 'Need more candidates'
    },
    {
        index: 3,
        text: 'Role no longer available'
    },
    {
        index: 4,
        text: 'Vervoe is not what I expected'
    },
    {
        index: 5,
        text: 'Not using Vervoe for hiring'
    }
];


const NoHireReasonDialog = observer(({
    classes, open, onClose, assessment, onSuccess
}) => {
    const [reason, setReason] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const [loading, setLoading] = useState(false);

    const { flashMessage } = useContext(appCtx);

    const { uuid, name, slug } = assessment;

    const segmentData = {
        ttId: uuid,
        ttName: name,
        candidatesHired: false
    };

    const changeFeedback = (e) => {
        const { value } = e.target;
        setFeedback(value);
    };

    const handleSubmit = (skip = false) => {
        setLoading(true);
        onDeactivateCallback(deactivateAssessment(slug))
            .then(({ success, data }) => {
                if (success && onSuccess) {
                    onSuccess(data.inActivatedAt);
                }
                if (skip) {
                    AssessmentEvents.HIRE_SURVEY_SKIPPED(segmentData);
                } else {
                    segmentData.reason = reason && options.find(option => option.index === reason).text;
                    if (feedback) segmentData.feedback = feedback;
                    AssessmentEvents.HIRE_SURVEY(segmentData);
                }
                flashMessage('Assessment was closed', 'done');
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setLoading(false);
                onClose();
            });
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}
            handleClose={onClose}
            titleComponent="Why didn't you make a hire?"
            actionComponent={(
                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.secondaryNoBorderButton}
                        onClick={() => handleSubmit(true)}
                    >
                        Skip and close assessment
                    </Button>
                    <Button
                        className={clsx(classes.mainButton, !reason && classes.greyButton)}
                        color="primary"
                        variant="contained"
                        disabled={!reason || loading}
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </Button>
                </div>
            )}
        >
            <div className={classes.optionsContainer}>
                {options.map(({ text, index }) => (
                    <div
                        role="presentation"
                        className={clsx(classes.option, Boolean(reason === index) && classes.activeOption)}
                        key={index}
                        onClick={() => setReason(index)}
                    >
                        <Radio
                            checked={index === reason}
                        />
                        {text}
                    </div>
                ))}
                {reason === 5 && (
                    <TextInputOutlined
                        label="What were you using it for?"
                        placeholder="What were you using it for?"
                        value={feedback}
                        fullWidth
                        maxLength={255}
                        className="u-mrg--bx0 u-mrg--tx0"
                        variant="outlined"
                        onChange={changeFeedback}
                    />
                )}
            </div>
        </Dialog>
    );
});

export default withStyles(styles)(withRouter(NoHireReasonDialog));

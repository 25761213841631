import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Pagination from 'components/board/board_pagination';
import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';
import { CUSTOM_ASSESSMENT_REQUEST_LIMIT } from 'helper/constants';
import { customRequestsStoreCtx } from '../../../store';
import RequestRow from './RequestRow';


const RequestsTable = observer(() => {
    const {
        handleChangePage, allRequests,
        totalCount,
        page, changeStatus, onShowInfo
    } = useContext(customRequestsStoreCtx);
    const totalPagesCount = Math.ceil(totalCount / CUSTOM_ASSESSMENT_REQUEST_LIMIT);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellHead className="u-pdn--lx3" label="Company Name" hideSort />
                        <TableCellHead label="Company Slug" hideSort />
                        <TableCellHead label="Request Date" hideSort />
                        <TableCellHead label="Category" hideSort />
                        <TableCellHead label="Role" hideSort />
                        <TableCellHead className="u-pdn--rx12 u-pdn--lx0" label="Status" align="right" hideSort />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object
                            .keys(allRequests)
                            .map(key => (
                                <RequestRow
                                    key={key}
                                    requestItem={allRequests[key]}
                                    changeStatus={changeStatus}
                                    showRequestInfo={() => onShowInfo(allRequests[key].id)}
                                />
                            ))
                    }
                </TableBody>
            </Table>
            <Pagination
                currentPage={page}
                totalPages={totalPagesCount}
                handleChangePage={handleChangePage}
            />
        </TableContainer>
    );
});

export default RequestsTable;

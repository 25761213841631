import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';

import GeneralEvents from 'events/GeneralEvents';
import CandidatePhoto from 'components/photos/candidate_photo';
import TableCell from 'components/table_components/table_cell';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import HiringManagerButton from 'components/assessments_pages/HiredComponents/HiringManagerButton';
import HiringDateButton from 'components/assessments_pages/HiredComponents/HiredDateButton';
import ManagerFeedbackButton from './ManagerFeedbackButton';
import CandidateRowMenu from './CandidateRowMenu';
import { candidatesCtx } from '../../../../../store';

const useStyles = makeStyles(theme => ({
    clickableCell: {
        cursor: 'pointer'
    },
    hiredLabel: {
        color: theme.palette.yellow[800],
        background: theme.palette.yellow[200],
        borderRadius: 4,
        padding: '5px 10px',
        marginRight: 10,
        cursor: 'pointer'
    },
    preWrap: {
        whiteSpace: 'pre'
    },
    tooltip: {
        whiteSpace: 'pre-wrap'
    }
}));

const CandidateRow = ({ candidate, history, callback }) => {
    const { addCandidateTab } = useContext(candidatesCtx);
    const { clickableCell, hiredLabel, lastCell, tooltip, preWrap } = useStyles();
    const [user, setUser] = useState(candidate);

    useEffect(() => {
        setUser(candidate);
    }, [candidate]);

    const {
        candidateFullName, candidateEmail, candidateId,
        assessmentTitle, canEdit, candidateUuid, assessmentUuid,
        canAssignHiringManager, assessmentSlug
    } = user;

    const dataForEvents = {
        candidateId: candidateUuid,
        ttId: assessmentUuid,
        ttName: assessmentTitle,
        context: 'hired page'
    };

    const updateUserFields = (updatedFields) => {
        setUser({ ...user, ...updatedFields });
    };

    const goToCard = () => {
        GeneralEvents.CANDIDATE_VIEWED(dataForEvents);
        addCandidateTab(candidateId, candidateFullName);
        history.push(`/all-candidates/candidate/${candidateId}`);
    };

    return (
        <>
            <TableRow hover>
                <TableCell onClick={goToCard} className={clickableCell} align="left">
                    <div className="u-dsp--centered u-jc--start">
                        <CandidatePhoto user={user} />
                        <TruncateWithTooltip width={195} text={candidateFullName} />
                    </div>
                </TableCell>
                <TableCell onClick={goToCard} className={clickableCell} align="left"><TruncateWithTooltip width={195} text={candidateEmail} /></TableCell>
                <TableCell onClick={goToCard} className={clickableCell} align="left">
                    <TruncateWithTooltip
                        width={195}
                        className={preWrap}
                        tooltipClassName={tooltip}
                        text={assessmentTitle}
                    />
                </TableCell>
                <TableCell align="left">
                    <HiringDateButton
                        dataForEvents={dataForEvents}
                        candidate={user}
                        assessmentSlug={assessmentSlug}
                        disabled={!canEdit}
                        onSuccess={updateUserFields}
                    />
                </TableCell>
                <TableCell align="left">
                    <HiringManagerButton
                        dataForEvents={dataForEvents}
                        candidate={user}
                        assessmentSlug={assessmentSlug}
                        canEdit={canEdit}
                        canAssignHiringManager={canAssignHiringManager}
                        onSuccess={updateUserFields}
                    />
                </TableCell>
                <TableCell align="right" className={lastCell}>
                    <span role="presentation" onClick={goToCard} className={hiredLabel}>Hired</span>
                    <ManagerFeedbackButton candidate={user} />
                    <CandidateRowMenu
                        candidate={user}
                        canEdit={canEdit}
                        callback={callback}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default withRouter(CandidateRow);

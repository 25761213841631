import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import PlanFeatureList from './PlanFeatureList';
import ProductPricingList from './ProductPricingList';

import styles from './styles';
import { subscriptionDialogStoreCtx } from '../store';
import PlanInfoHeader from '../PlanInfoBlock/PlanInfoHeader';


const PlanFeaturesBlock = observer(({ classes }) => {
    const { availablePlans, companyPlan } = useContext(subscriptionDialogStoreCtx);
    if (!availablePlans || !companyPlan) {
        return (
            <div className="u-txt--center">
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            <PlanInfoHeader />
            <ProductPricingList />
            <PlanFeatureList />
        </div>
    );
});

export default withStyles(styles)(PlanFeaturesBlock);

import React, { Component } from 'react';

import TextInput from 'libraries/TextInputValidated';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import PhoneInput from '../../PhoneInput';

import 'react-phone-input-2/lib/style.css';
import styles from './styles.module.scss';


class InviteForm extends Component {
    renderError = (formParam) => {
        const { form_invite_error } = this.props;
        if (form_invite_error && form_invite_error[formParam]) {
            return form_invite_error[formParam];
        }
        return '';
    };

    onHandleChange = (e, fieldName) => {
        const { onHandleChange, indexKey } = this.props;
        onHandleChange(fieldName, e.target.value, indexKey);
    };

    render() {
        const {
            formInvite, indexKey, company,
            onHandleChange, hidePhoneField,
            expired, listCountriesTopOffset
        } = this.props;

        return <>
            <div className={styles.inviteFormRowWrapper}>
                <div className={styles.fieldWrapper}>
                    <TextInput
                        label="Full Name"
                        variant="outlined"
                        value={formInvite.name.value}
                        onChange={(e) => { this.onHandleChange(e, 'name'); }}
                        fullWidth
                        name={`name[${indexKey}]`}
                        validators={formInvite.name.validator}
                        errorMessages={['Please enter your name']}
                        margin="none"
                        disabled={expired}
                    />
                </div>
                <div className={styles.fieldWrapper}>
                    <TextInput
                        label="Email"
                        variant="outlined"
                        value={formInvite.email.value}
                        onChange={(e) => { this.onHandleChange(e, 'email'); }}
                        fullWidth
                        name={`email[${indexKey}]`}
                        validators={formInvite.email.validator}
                        errorMessages={['Please enter a valid email address', 'Please enter your email address']}
                        margin="none"
                        disabled={expired}
                    />
                </div>
                {
                    !hidePhoneField && (
                        <PhoneInput
                            value={(formInvite.phone && formInvite.phone.value) || ''}
                            isPhoneValid={formInvite.isPhoneValid?.value}
                            onHandleChange={onHandleChange}
                            company={company}
                            indexKey={indexKey}
                            expired={expired}
                            listCountriesTopOffset={listCountriesTopOffset}
                        />
                    )
                }
            </div>
            <div className={styles.tagsWrapper}>
                {formInvite.tags?.value.map((tag, i) => (
                    <div key={i} className={styles.tag}>
                        <TruncateWithTooltip text={tag} width={150} />
                    </div>
                ))}
            </div>
        </>;
    }
}

export default InviteForm;

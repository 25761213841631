import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import TableFilter from 'components/table_components/table_filter';
import Typography from '@mui/material/Typography';
import TagsList from 'pages/assessment/components/TagsList';
import SkillsSearchField from './searchField';
import styles from './styles';
import { skillsCtx } from '../store';

const options = [{
    value: 'all',
    label: 'Show all'
}, {
    value: 'popular',
    label: 'Popular'
},
{
    value: 'regular',
    label: 'Regular'
}];

const SkillsSearchFilterBlock = observer(({ classes }) => {
    const { handleChangeFilterSelect, filterCategory, totalCount } = useContext(skillsCtx);
    options.forEach((item) => {
        item.checked = filterCategory === item.value;
    });

    return (
        <div className={classes.infoWrapper}>
            <div className={classes.wrap}>
                <Typography variant="h4" className={classes.header}>
                    Skills <span className={classes.countLabel}>({totalCount || 0})</span>
                </Typography>
                {filterCategory !== 'all' && <TagsList {...{ filterCategory, handleChangeFilterSelect }} />}
            </div>
            <div className="u-dsp--f u-ai--center">
                <SkillsSearchField />
                <TableFilter
                    isFilterActive={filterCategory !== 'all'}
                    isCheckbox={false}
                    options={options}
                    innerHeader="Status"
                    handleChange={handleChangeFilterSelect}
                />
            </div>
        </div>);
});

export default withStyles(styles)(SkillsSearchFilterBlock);

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { GOOGLE_DOC, PRESENTATION, SPREADSHEET, SPREADSHEET_AUTO_GRADED } from 'helper/constants';
import Loader from 'components/loaders/Loader';
import { appCtx } from 'components/appStore';

import { createGoogleDriveDocument, uploadGoogleDriveDocument } from 'requests/ScriptRequests';
import GoogleDocCase from './components/GoogleDocCase';
import SpreadsheetAutoGraded from './components/SpreadsheetAutoGraded';
import DocDialog from '../DocDialog';

const docTypes = {
    [GOOGLE_DOC]: 'document',
    [SPREADSHEET]: 'spreadsheet',
    [SPREADSHEET_AUTO_GRADED]: 'spreadsheet',
    [PRESENTATION]: 'presentation'
};

const GoogleQuestionWrapper = observer(({
    question, fetchQuestion, setSaving, isCMS
}) => {
    const [googleLink, setGoogleLink] = useState(null);
    const [googleDialogOpen, setGoogleDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { answerType, googleDriveFile, id } = question;

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setGoogleLink(googleDriveFile);
    }, [googleDriveFile, id]);

    const title = docTypes[answerType];

    const handleGoogleDialog = () => {
        setGoogleDialogOpen(!googleDialogOpen);
    };

    const createGoogleDoc = () => {
        const formData = new FormData();
        formData.append('questionId', question.id);
        formData.append('mimeType', title);
        setLoading(true);
        setSaving(true);
        createGoogleDriveDocument(id, formData, isCMS)
            .then(({ data, success }) => {
                if (success && data) {
                    setGoogleLink(data);
                    fetchQuestion();
                    setGoogleDialogOpen(true);
                    setLoading(false);
                    setSaving(false);
                } else {
                    setLoading(false);
                    setSaving(false);
                    flashMessage('Something went wrong');
                }
            })
            .catch(() => {
                setLoading(false);
                setSaving(false);
                flashMessage('Something went wrong');
            });
    };

    const uploadGoogleDoc = (file) => {
        const formData = new FormData();
        formData.append('questionId', question.id);
        formData.append('mimeType', docTypes[question.answerType]);
        formData.append('file', file);
        setLoading(true);
        setSaving(true);
        uploadGoogleDriveDocument(id, formData, isCMS)
            .then(({ data, success, errorMessage }) => {
                if (success && data) {
                    setGoogleLink(data);
                    fetchQuestion();
                    setGoogleDialogOpen(true);
                }
                if (!success && errorMessage) {
                    flashMessage(errorMessage, 'error');
                }
                setLoading(false);
                setSaving(false);
            })
            .catch(() => {
                setLoading(false);
                setSaving(false);
                flashMessage('Something went wrong');
            });
    };

    const currentType = () => {
        switch (answerType) {
            case GOOGLE_DOC: // google doc
            case SPREADSHEET: // google sheet
            case PRESENTATION: // google slides
                return (
                    <GoogleDocCase
                        {...{
                            googleLink,
                            title,
                            loading,
                            createGoogleDoc,
                            handleGoogleDialog,
                            onDrop: (files, rejected) => uploadGoogleDoc(files[0], rejected)
                        }}
                    />
                );
            case SPREADSHEET_AUTO_GRADED:
                return (
                    <SpreadsheetAutoGraded
                        {...{
                            isCMS,
                            question,
                            googleLink,
                            loading,
                            title,
                            googleDialogOpen,
                            handleGoogleDialog,
                            fetchQuestion,
                            setSaving,
                            createGoogleDoc,
                            onDrop: (files, rejected) => uploadGoogleDoc(files[0], rejected),
                            flashMessage
                        }}
                    />
                );
            default: return null;
        }
    };
    return (
        <>
            {currentType()}
            <div className="o-grid__7/10  js-main-content u-pos--relative">
                <DocDialog
                    {...{
                        isOpen: googleDialogOpen,
                        onClose: handleGoogleDialog,
                        question,
                        sourceLink: googleLink?.viewLink ? `${googleLink.viewLink}&rm=embedded` : null
                    }}
                />
                <div className="u-pos--overlay">
                    <Loader />
                </div>
            </div>
        </>
    );
});

export default GoogleQuestionWrapper;

import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { withStyles, withTheme } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import PageWrapper from 'components/layout/PageWrapper';
import ProtectedWithSpecificValue from 'components/routes/protected_with_specific_value';

import InnerContentWrapper from '../components/InnerContentWrapper';

import SelectTabs from './common/SelectTabs';
import Shortlist from './shortlist';
import CandidatesTab from './candidates_list';
import ArchivedTab from './archived_list';
import CandidatePage from './candidate';
import { statsCtx } from '../statsStore';

import { assessmentSettingsCtx } from '../store';
import { resultsCtx } from './store';
import styles from './styles';

const CandidateResults = observer(({ classes, match }) => {
    const { audition, getTags } = useContext(assessmentSettingsCtx);
    const { candidateTabs, removeCandidateTab, setCandidateTabs } = useContext(resultsCtx);

    const {
        loadAssessmentStats, loading: statsLoading,
        setLoading: setStatsLoading,
        shortlistCandidatesCount,
        completedNotArchivedCount, archivedCount
    } = useContext(statsCtx);

    const { scriptSlug } = match.params;

    useEffect(() => {
        getTags();

        setStatsLoading(true);
        loadAssessmentStats();
    }, [scriptSlug]);

    return (
        <PageWrapper className="u-pdn--bx5">
            {
                statsLoading ? (
                    <InnerContentWrapper>
                        <div className={classes.loaderWrapper}>
                            <CircularProgress color="primary" />
                        </div>
                    </InnerContentWrapper>
                ) : (
                    <InnerContentWrapper
                        className={classes.contentWrapper}
                        contentClassName="u-pdn--x0"
                    >
                        <SelectTabs
                            params={match.params}
                            {...{
                                activeAssessment: audition.active,
                                shortlistCandidatesCount,
                                loadAssessmentStats,
                                candidateTabs,
                                archivedCount,
                                completedNotArchivedCount,
                                removeCandidateTab,
                                setCandidateTabs
                            }}
                        />
                        <div className={classes.content}>
                            {
                                audition && (
                                    <Switch>
                                        <Route
                                            path="/script/select/:scriptSlug/candidate/:user_id"
                                            render={props => (<CandidatePage {...props} />)}
                                        />
                                        <ProtectedWithSpecificValue
                                            component={Shortlist}
                                            value={audition.active}
                                            path="/script/select/:scriptSlug/shortlist"
                                        />
                                        <Route
                                            path="/script/select/:scriptSlug/archived"
                                            render={props => (<ArchivedTab {...props} />)}
                                        />
                                        <Route
                                            path="/script/select/:scriptSlug"
                                            render={props => (<CandidatesTab {...props} audition={audition} />)}
                                        />
                                    </Switch>
                                )
                            }
                        </div>
                    </InnerContentWrapper>
                )
            }
        </PageWrapper>
    );
});

export default withTheme(withStyles(styles)(CandidateResults));

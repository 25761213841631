import React from 'react';
import Tooltip from 'libraries/TooltipWhite';
import BoardItemPhotos from 'components/board/board_photos';
import GradedTeamList from '../GradedTeamList';

const GradedByList = ({ gradedBy, candidateId }) => {
    if (!gradedBy) return null;

    return (
        <>
            <BoardItemPhotos
                data-tip
                data-for={`graded_by_${candidateId}`}
                users={gradedBy}
            />
            <Tooltip
                id={`graded_by_${candidateId}`}
                className="u-pdn--x0"
                delayHide={500}
            >
                <GradedTeamList gradedBy={gradedBy} />
            </Tooltip>
        </>
    );
};

export default GradedByList;

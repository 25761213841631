import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import DoneIcon from 'components/icons/CheckIcon';
import CloseIcon from 'img/remove.svg';
import TooltipBlack from 'libraries/Tooltip';
import Dialog from 'libraries/Dialog';
import CalculatingLabel from 'components/scores/CalculatingLabel';
import NoAnswerIcon from 'img/no_answer.svg';

import clsx from 'clsx';
import styles from './styles';

export default withStyles(styles)(({
    question: { linuxSimulationResult, questionHasAnswer }, NoAnswerComponent, classes
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const noAnswer = linuxSimulationResult && !questionHasAnswer;
    const calculatingScore = !linuxSimulationResult || !linuxSimulationResult.linuxSimulationUseCaseResults || !linuxSimulationResult.linuxSimulationUseCaseResults.length;
    const incompleteSimulation = linuxSimulationResult && linuxSimulationResult.sshFailed;


    const returnTooltip = (label) => {
        if (label && label.length > 30) {
            return (
                <TooltipBlack label={label}>
                    <span className={classes.truncated}>{label}</span>
                </TooltipBlack>
            );
        }
        return <span className={classes.truncated}>{label}</span>;
    };

    const handleDialog = () => {
        setOpenDialog(!openDialog);
    };

    if (noAnswer) {
        return <NoAnswerComponent />;
    }

    if (incompleteSimulation) {
        return (
            <div className={classes.wrapper}>
                <img src={NoAnswerIcon} alt="no_answer" />
                Incomplete simulation
            </div>
        );
    }

    if (calculatingScore) {
        return (
            <div className={classes.wrapper}>
                <CalculatingLabel label="Calculating score" />
            </div>
        );
    }

    const { linuxSimulationUseCaseResults, commandHistory } = linuxSimulationResult;

    return (
        <div>
            <div className={clsx(classes.headerWrapper, 'u-dsp--distribute')}>
                <div className={classes.ticketNumberColumn}>Script</div>
                <div className={clsx(classes.headerColumnsWrapper, 'u-dsp--distribute')}>
                    <div>Expected Value</div>
                    <div className={classes.pointsColumnHeader}>Result</div>
                </div>
            </div>
            {
                linuxSimulationUseCaseResults.map((useCase, useCaseIndex) => {
                    const { id, actual, expected, command } = useCase;
                    const passed = expected === actual;
                    return (
                        <>
                            <Accordion
                                key={id}
                                classes={{
                                    root: classes.accordion,
                                    expanded: classes.accordionExpanded
                                }}
                            >
                                <AccordionSummary
                                    classes={{ root: classes.summaryRoot, content: classes.summaryContent, disabled: classes.summaryDisabled }}
                                    expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    Script {useCaseIndex + 1}
                                </AccordionSummary>
                                <AccordionDetails className="u-dsp--distribute u-pdn--x0">
                                    <div className={classes.ticketNumberColumn}>{returnTooltip(command)}</div>
                                    <div className={clsx(classes.headerColumnsWrapper, 'u-dsp--distribute')}>
                                        <div className={classes.skillsColumn}>
                                            {returnTooltip(expected)}
                                        </div>
                                        <div className={classes.pointsColumn}>
                                            {
                                                passed ? (
                                                    <>Pass <DoneIcon className="u-mrg--lx1" /></>
                                                ) : (
                                                    <>Fail <img src={CloseIcon} alt="" className={classes.nonPass} /></>
                                                )
                                            }
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    );
                })
            }
            {
                commandHistory && (
                    <>
                        <div className="u-txt--right">
                            <Button
                                color="primary"
                                className="u-mrg--tx2"
                                onClick={handleDialog}
                            >
                                View Log Files
                            </Button>
                        </div>
                        <Dialog
                            open={openDialog}
                            maxWidth="sm"
                            handleClose={handleDialog}
                            onClose={handleDialog}
                            titleComponent="Log Files"
                        >
                            <div className={classes.logsWrapper}>
                                {commandHistory}
                            </div>
                        </Dialog>
                    </>
                )
            }
        </div>
    );
});

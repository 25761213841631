import React, { useState } from 'react';
import clsx from 'clsx';

import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { Route, Switch, withRouter } from 'react-router-dom';
import PageWrapper from 'components/layout/PageWrapper';

import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import { cloneChildrenWithNewProps } from 'helper/commonFunctions';
import SavingLabel from './SavingLabel';

import useStyles from './styles';

let timerId = null;

const DrawerPageWrapper = observer(({
    loading, setLoading, EditDrawer, contentWrapperCls = '',
    setAudition, routes, children, ...other
}) => {
    const [openedSection, setOpenedSection] = useState(Boolean(localStorage.getItem('navOpened')));
    const [isSaving, setSaving] = useState(false);
    const [showLabel, setShowLabel] = useState(false);
    const classes = useStyles({ hasDrawer: Boolean(EditDrawer) });

    const handleSaving = (value) => {
        clearTimeout(timerId);
        setShowLabel(true);
        setSaving(value);
        if (!value) {
            timerId = setTimeout(() => {
                setShowLabel(false);
            }, 3000);
        }
    };


    const handleMenu = () => {
        if (!openedSection) {
            localStorage.setItem('navOpened', 'true');
        } else {
            localStorage.removeItem('navOpened');
        }
        setOpenedSection(!openedSection);
    };

    const additionalProps = {
        isSaving,
        setLoading,
        setAudition,
        setSaving: handleSaving,
        setOpenedSection,
        ...other
    };

    return (
        <PageWrapper
            className="u-pdn--bx5"
            headerClassName={classes.headerClassName}
            headerActions={showLabel && <SavingLabel isSaving={isSaving} />}
        >
            <div className={classes.wrapper}>
                {
                    EditDrawer && (
                        <EditDrawer
                            open={openedSection}
                            handleMenu={handleMenu}
                        />
                    )
                }
                <ContentWrapper
                    className={clsx(classes.content, 'u-pdn--x0', {
                        [classes.contentShift]: Boolean(openedSection)
                    })}
                >
                    <div className={clsx(classes.contentWrapper, contentWrapperCls)}>
                        {
                            loading && (
                                <ContentWrapper className={classes.loaderWrapper} loading>
                                    <CircularProgress />
                                </ContentWrapper>
                            )
                        }
                        {cloneChildrenWithNewProps(children, additionalProps)}
                        {
                            routes && (
                                <Switch>
                                    {
                                        routes.map(({ path, Component, Type = Route }) => (
                                            <Type exact path={path} render={props => (<Component {...additionalProps} {...props} />)} />
                                        ))
                                    }
                                </Switch>
                            )
                        }
                    </div>
                </ContentWrapper>
            </div>
        </PageWrapper>
    );
});

export default withRouter(DrawerPageWrapper);

import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { appCtx } from 'components/appStore';
import Button from '@mui/material/Button';
import { checkCompanyCanBeUpgraded } from 'helper/commonFunctions';

import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import FlagIcon from './flag.svg';

import styles from './styles';

const CandidatesLimitBlock = observer(({ classes }) => {
    const { company } = useContext(appCtx);
    const { plan, companySettings } = company;
    if (!plan) return null;
    const { candidatesCompletionsPerOpportunity } = companySettings;

    if (candidatesCompletionsPerOpportunity === -1) return null;
    const canCompanyUpgrade = checkCompanyCanBeUpgraded(company);

    return (
        <div className={classes.wrapper}>
            <img src={FlagIcon} alt="!" />
            See results for up to&nbsp;<b>{candidatesCompletionsPerOpportunity}</b>&nbsp;candidates with your {plan.name} Plan.
            {canCompanyUpgrade ? (
                <SubscriptionsLink
                    label={(<Button className={classes.button}>Upgrade</Button>)}
                    segmentLocation="invite via email"
                />
            ) : (
                <span
                    role="presentation"
                    onClick={() => window.open('/subscriptions', '_blank')}
                    className={classes.button}
                >
                    Upgrade
                </span>)
            }&nbsp;to view more
        </div>
    );
});

export default withRouter(withStyles(styles)(CandidatesLimitBlock));

import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@mui/styles';
import BugIcon from '../icons/create-bug.svg';
import UserStoryIcon from '../icons/user-story.svg';
import TestCaseIcon from '../icons/test-case.svg';
import TestRunIcon from '../icons/test-run.svg';
import { QA_CREATE_BUG, QA_EDIT_BUG, QA_TEST_CASE, QA_TEST_RUN, QA_USER_STORY } from '../../../../helper/constants';
import ScoringTips
from '../../../candidate_cards/CandidateCardWrapper/GradeBlock/components/QuestionsContent/ScoringTips';

const useStyles = makeStyles(theme => ({
    ticketNumberRow: {
        color: theme.palette.grey[600]
    }
}));

const getIcon = (type) => {
    const icons = {
        [QA_USER_STORY]: UserStoryIcon,
        [QA_TEST_CASE]: TestCaseIcon,
        [QA_TEST_RUN]: TestRunIcon,
        [QA_EDIT_BUG]: BugIcon,
        [QA_CREATE_BUG]: BugIcon
    };

    return icons[type];
};

export default ({ ticketNumber, shownTitle = true, taskType, questionResults }) => {
    const { ticketNumberRow } = useStyles();

    const haveScoringInstruction = questionResults && questionResults.some(el => el.question.scoringInstruction);

    return (
        <>
            {haveScoringInstruction && (
                <>
                    <hr />
                    {
                        questionResults.map(taskQuestionResult => (
                            <ScoringTips isTask question={taskQuestionResult} key={taskQuestionResult.id} />
                        ))
                    }
                </>
            )}
            <hr />
            {shownTitle && <div className="u-txt--14 u-txt--bold u-mrg--bx2">Candidate Answer</div>}
            <div className={clsx(ticketNumberRow, 'u-dsp--f u-ai--center u-txt--bold u-txt--12 u-mrg--bx3')}>
                <img src={getIcon(taskType)} alt="" className="u-mrg--rx1" />
                {ticketNumber}
            </div>
        </>
    );
};

export default theme => ({
    popoverPaper: {
        width: 350,
        padding: '13px 15px'
    },
    wrapper: {
        padding: '3px 8px',
        fontWeight: 700,
        borderRadius: '60px',
        fontSize: 10,
        textTransform: 'capitalize',
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[600]
    },
    listItem: {
        fontWeight: 600,
        color: theme.palette.grey[800]
    },
    wrapperUnpublished: {
        backgroundColor: theme.palette.yellow[50],
        color: theme.palette.yellow[900]
    },
    wrapperPublished: {
        color: theme.palette.green[600],
        backgroundColor: theme.palette.green[50]
    },
    wrapperDraft: {
        backgroundColor: theme.palette.yellow[50],
        color: theme.palette.yellow[900]
    },
    draftLabel: {
        color: theme.palette.yellow[900],
        fontWeight: 500,
        fontStyle: 'italic'
    }
});

import { get, patch, del, post } from 'helper/API';

export function getCompanyFolders() {
    return get('employer/audition-folders');
}

export function createFolder(name) {
    return post('employer/audition-folders', { name });
}

export function removeFolder(id) {
    return del(`employer/audition-folders/${id}`);
}

export function editFolderName(id, name) {
    return patch(`employer/audition-folders/${id}`, { name });
}

export function moveAuditionToFolder(auditionSlug, folderId) {
    return patch(`employer/auditions/${auditionSlug}/audition-folders/${folderId}/move`);
}

export function moveAuditionToDefaultFolder(auditionSlug) {
    return patch(`employer/auditions/${auditionSlug}/move-to-default-folder`);
}

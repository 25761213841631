import React from 'react';
import withStyles from '@mui/styles/withStyles';
import EditCase from './components/EditCase';

import styles from '../styles';
import TestCasesList from './components/TestCasesList';


const TestCasesTab = ({ classes, cases, setTestData, editTestCase,
    setDeleteTestCaseDialogOpen, runTest, runningTest, handleDrawerOpen,
    editedCase, setEditedCase, chooseCase, currentCase
}) => <>
    <div className={classes.wrapper}>
        {currentCase === 'edit' || currentCase === 'autograded' || currentCase === 'example'
            ? (
                <EditCase
                    {...{
                        editedCase,
                        editTestCase,
                        setEditedCase,
                        setDeleteTestCaseDialogOpen,
                        chooseCase
                    }}
                    sendTestData={setTestData}
                    currentCase={currentCase}
                />
            ) : (
                <TestCasesList {...{
                    cases,
                    runTest,
                    runningTest,
                    handleDrawerOpen,
                    chooseCase
                }}
                />
            )}
    </div>
</>;

export default withStyles(styles)(TestCasesTab);

import React from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import TooltipArrow from 'libraries/Tooltip';
import QuestionHeaderLeftPart from '../../common/QuestionHeaderLeftPart';

import AddToAssessmentButton from '../../common/AddToAssessmentButton';
import AddToBankFromSuggestedIcon from '../AddToBankFromSuggestedIcon';

import styles from './styles';

const QuestionHeader = observer(({
    question, interview, activeQuestion,
    updateQuestion, setActiveTab, selectQuestion,
    replaceFunc, activeQuestionIndex, assessmentQuestions,
    loadingAddButton, setLoadingAddButton,
    loadingSaveButton, setLoadingSaveButton,
    assessmentHasTypingTest, isSimulationList,
    onReplace, questionGroupName, isScreening
}) => {
    const { questionGroup, answerType, type, timerEnabled, required, exampleAnswers = [] } = question;
    const skillName = questionGroup ? questionGroup.title : '';
    const { name, uuid } = interview;

    const segmentData = {
        ttId: uuid,
        ttName: name,
        skill: questionGroupName,
        questionType: type,
        answerType,
        timerEnabled,
        required,
        questionNumber: activeQuestionIndex + 1,
        correctAnswer: Boolean(exampleAnswers?.length),
        questionSuggested: true
    };

    return (
        <QuestionHeaderLeftPart
            question={question}
            interview={interview}
            rightPart={(
                <>
                    <TooltipArrow
                        label={`Add ${skillName} Question`}
                    >
                        <AddToAssessmentButton
                            segmentData={segmentData}
                            question={question}
                            updateQuestion={updateQuestion}
                            setActiveTab={setActiveTab}
                            questions={assessmentQuestions}
                            activeQuestionIndex={activeQuestionIndex}
                            replaceFunc={replaceFunc}
                            selectQuestion={selectQuestion}
                            activeQuestion={activeQuestion}
                            loading={loadingAddButton}
                            setLoading={setLoadingAddButton}
                            assessmentHasTypingTest={assessmentHasTypingTest}
                            isSimulationList={isSimulationList}
                            onReplace={onReplace}
                        />
                    </TooltipArrow>
                    <AddToBankFromSuggestedIcon
                        segmentData={segmentData}
                        question={question}
                        loading={loadingSaveButton}
                        setLoading={setLoadingSaveButton}
                        questionGroupName={questionGroupName}
                        isScreening={isScreening}
                    />
                </>
            )}
        />
    );
});

export default withStyles(styles)(withRouter(QuestionHeader));

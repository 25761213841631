import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

const styles = () => ({
    wrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#000',
        borderRadius: 4,
        fontSize: 14,
        fontWeight: 600
    },
    img: {
        marginRight: 20
    }
});

const PageHeader = ({
    classes, headerLabel = null,
    headerActions = null,
    headerClassName
}) => (
    headerLabel || headerActions ? (
        <div className={clsx(classes.wrapper, headerClassName)}>
            <Typography variant="h3">{headerLabel}</Typography>
            {headerActions}
        </div>
    ) : null
);

export default withStyles(styles)(PageHeader);

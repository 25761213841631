import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import BoardItemPhotos from 'components/board/board_photos';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import TooltipBlack from 'libraries/Tooltip';
import { PERMISSIONS } from 'helper/constants';
import TeamEvents from 'events/SettingsEvents';
import PermissionUsersDialog from './PermissionUsersDialog';

import styles from './styles';

const PermissionUsersList = observer(({
    audition, setAudition, classes,
    hasEditPermission
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const { company } = useContext(appCtx);
    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);
    const { slug, name, uuid, owner = {}, employers = [], opportunitySlug } = audition;

    useEffect(() => {
        setUsersList(employers);
    }, [slug]);

    const handleDialog = () => {
        setOpenDialog(!openDialog);
    };

    const onAddTeamMember = (newTM) => {
        const newUserList = usersList.slice();
        newUserList.push(newTM);
        setUsersList(newUserList);
        setAudition({ ...audition, employers: newUserList });

        const { roleType, selectedMember } = newTM;
        TeamEvents.ASSESSMENT_TEAM_ADDED({
            ttId: uuid,
            ttName: name,
            userId: selectedMember,
            assessmentRole: roleType
        });
    };

    const onRemoveTeamMember = (index) => {
        TeamEvents.ASSESSMENT_TEAM_REMOVED({
            ttId: uuid,
            ttName: name,
            userId: usersList[index].uuid,
            assessmentRole: usersList[index].roleType
        });
        const newUserList = usersList.slice();
        newUserList.splice(index, 1);
        setUsersList(newUserList);
        setAudition({ ...audition, employers: newUserList });
    };

    const onChangeTeamMember = (index, user) => {
        const newUserList = usersList.slice();
        newUserList[index] = user;
        setUsersList(newUserList);
        setAudition({ ...audition, employers: newUserList });

        TeamEvents.ASSESSMENT_TEAM_ROLE_CHANGED({
            ttId: uuid,
            ttName: name,
            userId: usersList[index].uuid,
            newAssessmentRole: usersList[index].roleType
        });
    };

    const fullUserList = [{ ...owner, owner: true }].concat(employers);

    return (
        <div className={classes.wrapper}>
            {
                hasPermissionsFeature && (
                    <>
                        <TooltipBlack
                            label="Manage Team"
                        >
                            <IconButton
                                onClick={handleDialog}
                                className={classes.button}
                                aria-label="delete"
                                disabled={!hasEditPermission}
                                color="primary"
                                size="large"
                            >
                                <AddIcon size="small" />
                            </IconButton>
                        </TooltipBlack>
                        <PermissionUsersDialog
                            open={openDialog}
                            onRemoveTeamMember={onRemoveTeamMember}
                            users={fullUserList}
                            onChangeTeamMember={onChangeTeamMember}
                            onAddTeamMember={onAddTeamMember}
                            opportunitySlug={opportunitySlug}
                            onClose={handleDialog}
                        />
                    </>
                )
            }
            <BoardItemPhotos
                users={fullUserList}
            />
        </div>
    );
});

export default withRouter(withStyles(styles)(PermissionUsersList));

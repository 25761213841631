import React from 'react';
import { withStyles } from '@mui/styles';
import MaterialDialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DefaultCross from './DefaultCross';
import closeIcon from '../../img/candidateCard/close_balck.svg';
import styles from './styles';

const Dialog = ({
    close, hideCross,
    children, classes,
    titleComponent,
    actionClassName = '',
    titleClassName = '',
    contentClassName = '',
    maxWidth = 'md',
    className, actionComponent,
    handleClose, ...other
}) => (
    <MaterialDialog
        className={className}
        fullWidth
        maxWidth={maxWidth}
        classes={{
            root: classes.root,
            paper: classes.paper,
            paperWidthMd: classes.paperWidthMd,
            paperFullWidth: classes.paperFullWidth,
            paperScrollBody: classes.paperScrollBody
        }}
        {...other}
    >
        {
            !hideCross && (
                <IconButton className={classes.closeIconButton} onClick={handleClose} size="large">
                    <DefaultCross className={classes.closeIcon} />
                </IconButton>
            )
        }
        {
            titleComponent
            && (
                <DialogTitle className={titleClassName} classes={{ root: classes.title }}>
                    {titleComponent}
                    { close ? <img onClick={close} src={closeIcon} alt="closeIcon" /> : null }
                </DialogTitle>
            )
        }
        <DialogContent
            className={contentClassName}
            classes={{ root: classes.content }}
        >
            {children}
        </DialogContent>
        {
            actionComponent && (
                <DialogActions
                    className={actionClassName}
                    classes={{ root: classes.actions }}
                >
                    {actionComponent}
                </DialogActions>
            )
        }
    </MaterialDialog>
);

export default withStyles(styles)(Dialog);

import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';

import Pagination from 'components/board/board_pagination';
import Table from 'components/table_components/table';
import { POPULAR_SKILLS_PER_ALL_SKILLS_PAGE } from 'helper/constants';
import { withStyles } from '@mui/styles';
import NotFoundContent from 'components/assessments_pages/NotFoundContent';
import SkillRow from './row';
import { skillsCtx } from '../store';
import styles from './styles';

const PopularSkillsTable = observer(({ classes }) => {
    const {
        allSkills,
        handleClickPopular,
        page, totalCount,
        handleChangePage,
        isLoading,
        query
    } = useContext(skillsCtx);

    const totalPagesCount = useMemo(
        () => Math.ceil(totalCount / POPULAR_SKILLS_PER_ALL_SKILLS_PAGE),
        [totalCount]
    );

    if (query && !totalCount) {
        return <NotFoundContent searchValue={query} />;
    }

    if (isLoading) {
        return (
            <div className={classes.loaderWrapper}>
                <CircularProgress color="primary" />
            </div>
        );
    }

    return (
        <TableContainer>
            <Table className={classes.table}>
                <TableBody>
                    {
                        allSkills
                            .map((skill, index) => (
                                <SkillRow
                                    handleClickPopular={() => handleClickPopular(skill.id, !skill.popular)}
                                    popularSkill={skill}
                                    key={index}
                                />
                            ))
                    }
                </TableBody>
            </Table>
            <Pagination
                currentPage={page}
                totalPages={totalPagesCount}
                handleChangePage={handleChangePage}
            />
        </TableContainer>
    );
});

export default withStyles(styles)(PopularSkillsTable);

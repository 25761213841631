import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ProtectedWithAssessmentFlow from 'components/routes/protected_with_assessment_flow';
import ProtectedWithSpecificValue from 'components/routes/protected_with_specific_value';

import { Route, Switch, withRouter } from 'react-router-dom';
import { checkHasUserPermission } from 'helper/commonFunctions';
import PageWrapper from 'components/layout/PageWrapper';
import { makeStyles } from '@mui/styles';
import InviteCandidates from './InviteCandidates';
import EmailInvitation from './EmailInvitation';
import InviteTabs from './InviteTabs';
import { statsCtx } from '../statsStore';
import { appCtx } from '../../../components/appStore';
import { assessmentSettingsCtx } from '../store';
import InnerContentWrapper from '../components/InnerContentWrapper';
import CandidatePage from '../results_assessment/candidate';

const useStyles = makeStyles(() => ({
    loaderWrapper: {
        textAlign: 'center',
        marginTop: 35
    },
    content: {
        overflow: 'auto',
        padding: '0 30px',
        minHeight: 250
    }
}));

const CandidateInvite = observer(({ match }) => {
    const classes = useStyles();
    const { loadAssessmentStats, statsLoading, setLoading: setStatsLoading } = useContext(statsCtx);
    const { getTags, audition } = useContext(assessmentSettingsCtx);
    const { company } = useContext(appCtx);
    const { permissions } = audition;
    const edit = checkHasUserPermission(company, permissions, 'edit');

    const { scriptSlug } = match.params;

    useEffect(() => {
        setStatsLoading(true);
        loadAssessmentStats();
        getTags();
    }, [scriptSlug]);


    return (
        <PageWrapper className="u-pdn--bx5">
            {
                statsLoading ? (
                    <InnerContentWrapper>
                        <div className={classes.loaderWrapper}>
                            <CircularProgress color="primary" />
                        </div>
                    </InnerContentWrapper>
                ) : (
                    <InnerContentWrapper
                        className="u-pdn--x0 u-mrg--x0"
                        contentClassName="u-pdn--x0"
                    >
                        <InviteTabs
                            params={match.params}
                            audition={audition}
                        />
                        <div className={classes.content}>
                            {
                                audition && (
                                    <Switch>
                                        <Route
                                            path="/script/invite/:scriptSlug/candidate/:user_id"
                                            render={props => (<CandidatePage {...props} isInvite />)}
                                        />
                                        <ProtectedWithAssessmentFlow path="/script/invite/:scriptSlug/invite-candidates" component={InviteCandidates} />
                                        <ProtectedWithSpecificValue value={edit} path="/script/invite/:scriptSlug/invitation" component={EmailInvitation} />
                                    </Switch>
                                )
                            }
                        </div>
                    </InnerContentWrapper>
                )
            }
        </PageWrapper>
    );
});

export default withRouter(CandidateInvite);

import React from 'react';
import ALLinearProgress from 'components/grade_components/LinearProgress';
import ALProgressBar from 'components/grade_components/ALProgressBar';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import {
    AI_HEALTH_STATUS_LOW, AI_HEALTH_STATUS_MEDIUM,
    AI_HEALTH_STATUS_HIGH, AI_HEALTH_STATUS_OPTIMIZED, MANUAL_GRADING_REQUIRED, AI_LIGHT_GRADING_REQUIRED
} from 'helper/constants';
import ShieldIcon from './assets/ShieldIcon';
import OptimizedResultStatus from './assets/OptimizedResultStatus';

import useStyles from './styles';


const GradingResultsStatus = ({ status }) => {
    if (status === 'optimized') return <OptimizedResultStatus />;

    const returnStatusObj = () => {
        switch (status) {
            case AI_HEALTH_STATUS_LOW: return {
                color: '#FF113D',
                backgroundColor: '#FFEBF0',
                label: 'Low',
                tooltipText: 'Add correct answer samples to increase the accuracy of your score!',
                progressBar: true
            };
            case AI_HEALTH_STATUS_MEDIUM: return {
                color: '#009879',
                backgroundColor: '#E0F7F4',
                label: 'Medium',
                tooltipText: 'Grade candidate responses and take it to the next level!',
                progressBar: true
            };
            case AI_HEALTH_STATUS_HIGH: return {
                color: '#009879',
                backgroundColor: '#E0F7F4',
                label: 'High',
                tooltipText: 'Grade responses and train Vervoe to be more like a member of your team!',
                progressBar: true
            };
            case AI_HEALTH_STATUS_OPTIMIZED: return {
                color: '#009879',
                backgroundColor: '#E0F7F4',
                label: 'Optimized',
                progressBar: true
            };
            case MANUAL_GRADING_REQUIRED: return {
                color: '#F87E30',
                backgroundColor: '#FFF9E4',
                label: 'Manual Grades Required',
                progressBar: false
            };
            case AI_LIGHT_GRADING_REQUIRED: return {
                color: '#F87E30',
                backgroundColor: '#FFF9E4',
                label: 'Grading Required',
                progressBar: false
            };
            case 'optimizing': return {
                color: '#0032DD',
                backgroundColor: '#E9F0FF',
                label: 'Optimizing'
            };
            default: return null;
        }
    };

    if (!returnStatusObj()) return null;

    const { color, label, backgroundColor, tooltipText } = returnStatusObj();

    const { container, auxiliaryText, statusLabel, mainLabel, progressBar } = useStyles({ color, backgroundColor });

    return <>
        <div className={container}>
            <div className="u-dsp--f u-mrg--bx2">
                <ShieldIcon color={color} />
                <div className="u-mrg--lx2">
                    <div className={auxiliaryText}>
                        Assessment Score Accuracy
                    </div>
                    <div className={statusLabel}>
                        <div className={mainLabel}>{label}</div>
                        {tooltipText && <InfoTooltip text={tooltipText} iconColor="#A9AAAE" isBlack />}
                    </div>
                </div>
            </div>
            {(label !== 'Optimizing' && progressBar) && <ALProgressBar color={color} status={status} />}
            {label === 'Optimizing' && <ALLinearProgress />}
        </div>
    </>;
};

export default GradingResultsStatus;

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import styles from './styles';

const ContentAccordion = ({ header = null, content = null, classes, className = '' }) => (
    <Accordion
        className={clsx(classes.expansionPanel, className)}
        classes={{ expanded: classes.expanded }}
    >
        <AccordionSummary
            classes={{
                root: classes.expansionPanelSummary,
                expandIcon: classes.expandIcon,
                content: 'u-dsp--f u-ai--center'
            }}
            expandIcon={<ExpandMoreIcon />}
        >
            {header}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.expansionPanelDetails }}>
            {content}
        </AccordionDetails>
    </Accordion>
);

export default withStyles(styles)(ContentAccordion);

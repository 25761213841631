import { action, computed, observable } from 'mobx';
import { createContext } from 'react';
import { getInvitationsInAssessment, loadInvitationsStats } from 'requests/AssessmentRequests';
import MiscellaneousEvents from 'events/MiscellaneousEvents';


let FILTER_STATUS_SLUG = 'filter-invite-status';
let FILTER_TAGS_SLUG = 'filter-invite-tags';
let FILTER_SOURCES_SLUG = 'filter-invite-sources';
const SORT_PAGE_IDENTIFIER = 'sort-invite-page';

class InvitationsStore {
    @observable allCandidates = [];

    @observable totalCount = 0;

    @observable currentCount = 0;

    @observable page = 1;

    @observable hasMore = false;

    @observable query = '';

    @observable filterCategory = {};

    @observable filterSourceCategory = {};

    @observable filterTagsCategory = {};

    @observable isLoading = true;

    @observable isLoadingLocal = false;

    @observable sortCategory = {};

    @observable sortOrder = null;

    @observable selectedCandidates = {};

    @observable slug = '';

    @observable invitationsStats = {};

    @observable segmentEventContext = 'invite page';

    timerId = null;

    @computed get selectedCandidatesIds() {
        return Object.keys(this.selectedCandidates);
    }

    @computed get selectedUserAuditionsIds() {
        return Object.keys(this.selectedCandidates).map(key => this.selectedCandidates[key].userAuditionId);
    }

    @computed get selectedColumnUserIds() {
        return Object.keys(this.selectedCandidates).map(key => this.selectedCandidates[key].columnUserId);
    }

    @action setSlug = (slug) => {
        this.slug = slug;
        FILTER_STATUS_SLUG += `_${this.slug}`;
        FILTER_TAGS_SLUG += `_${this.slug}`;
        FILTER_SOURCES_SLUG += `_${this.slug}`;
    };

    @action fetchInvitationStats = () => {
        if (!this.slug) return;
        loadInvitationsStats(this.slug)
            .then(({ success, data }) => {
                if (success && data) {
                    this.invitationsStats = data;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    @action cleanFilterSettings = (slug, except = '') => {
        const localStorageItems = Object.keys({ ...localStorage });
        const localStorageItemsStatuses = localStorageItems.filter(item => item.includes(slug));
        localStorageItemsStatuses.forEach((item) => {
            if (item !== `${slug}-${except}`) {
                localStorage.removeItem(item);
            }
        });
    };

    @action setFilterCategory = () => {
        const localStorageItems = Object.keys({ ...localStorage });
        const localStorageItemsStatuses = localStorageItems.filter(item => item.includes(FILTER_STATUS_SLUG));
        const localStorageItemsTags = localStorageItems.filter(item => item.includes(FILTER_TAGS_SLUG));
        const localStorageItemsSources = localStorageItems.filter(item => item.includes(FILTER_SOURCES_SLUG));

        if (localStorageItemsStatuses.length) {
            this.filterCategoryStr = localStorageItemsStatuses[0].replace(`${FILTER_STATUS_SLUG}-`, '');
            this.cleanFilterSettings(FILTER_STATUS_SLUG, this.filterCategoryStr);
            this.filterCategory[this.filterCategoryStr] = true;
        }

        if (localStorageItemsTags.length) {
            localStorageItemsTags.forEach((item) => {
                const tagName = item.replace(`${FILTER_TAGS_SLUG}-`, '');
                this.filterTagsCategory[tagName] = true;
            });
        }

        if (localStorageItemsSources.length) {
            localStorageItemsSources.forEach((item) => {
                const sourceName = item.replace(`${FILTER_SOURCES_SLUG}-`, '');
                this.filterSourceCategory[sourceName] = true;
            });
        }
    };

    @action loadCandidates = (query = this.query) => {
        if (!this.slug) return;
        if (localStorage.getItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`)) {
            const [sortBy, sortOrder] = localStorage.getItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`).split(',');
            this.sortCategory[sortBy] = true;
            this.sortOrder = sortOrder;
        }

        const filterCategoryStr = Object.keys(this.filterCategory).join(',');
        const filterSourceCategory = Object.keys(this.filterSourceCategory);
        const filterTagsCategoryStr = Object.keys(this.filterTagsCategory).join(',');
        const sortCategoryStr = Object.keys(this.sortCategory).join(',');
        this.isLoadingLocal = true;
        getInvitationsInAssessment(this.slug, this.page, query, filterCategoryStr, filterTagsCategoryStr, sortCategoryStr, this.sortOrder, filterSourceCategory)
            .then(({ success, data }) => {
                if (success && data) {
                    const { items, hasMorePages, total, currentPage } = data;
                    this.allCandidates = items;
                    this.totalCount = total;
                    this.page = currentPage;
                    this.hasMore = hasMorePages;
                }
                this.isLoading = false;
                this.isLoadingLocal = false;
            })
            .catch((error) => {
                this.isLoading = false;
                this.isLoadingLocal = false;
                if (error.response) {
                    const { status } = error.response;
                    if (status === 400 && this.page !== 1) {
                        this.page = 1;
                        this.loadCandidates();
                    }
                }
            });
    };

    @action handleChangeFilter = (e, audition) => {
        const { value } = e.target;
        this.query = value;
        this.page = 1;
        this.isLoadingLocal = true;
        clearTimeout(this.timerId);
        this.timerId = setTimeout(async () => {
            await this.loadCandidates();
            MiscellaneousEvents.CANDIDATE_SEARCH_CONDUCTED({
                searchTerm: value,
                context: 'Invite',
                ttId: audition.uuid,
                ttName: audition.name
            });
        }, 300);
    };

    @action handleChangePage = (page) => {
        this.page = page;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };

    @action handleChangeFilterSelect = (filterCategory, isTags, isSource) => {
        if (isTags) {
            if (this.filterTagsCategory[filterCategory]) {
                localStorage.removeItem(`${FILTER_TAGS_SLUG}-${filterCategory}`);
                delete this.filterTagsCategory[filterCategory];
            } else {
                this.filterTagsCategory[filterCategory] = true;
                localStorage.setItem(`${FILTER_TAGS_SLUG}-${filterCategory}`, 'true');
            }
        } else if (isSource) {
            if (this.filterSourceCategory[filterCategory]) {
                localStorage.removeItem(`${FILTER_SOURCES_SLUG}-${filterCategory}`);
                delete this.filterSourceCategory[filterCategory];
            } else {
                localStorage.setItem(`${FILTER_SOURCES_SLUG}-${filterCategory}`, 'true');
                this.filterSourceCategory[filterCategory] = true;
            }
        } else if (filterCategory === 'all') {
            this.cleanFilterSettings(FILTER_STATUS_SLUG);
            this.filterCategory = {};
        } else {
            this.cleanFilterSettings(FILTER_STATUS_SLUG, filterCategory);
            this.filterCategory = { [filterCategory]: true };
            localStorage.setItem(`${FILTER_STATUS_SLUG}-${filterCategory}`, 'true');
        }

        this.page = 1;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };

    @action handleRemoveFilterSelect = (filterCategory, isTags, isSource) => {
        if (isTags) {
            localStorage.removeItem(`${FILTER_TAGS_SLUG}-${filterCategory}`);
            delete this.filterTagsCategory[filterCategory];
        } else if (isSource) {
            localStorage.removeItem(`${FILTER_SOURCES_SLUG}-${filterCategory}`);
            delete this.filterSourceCategory[filterCategory];
        } else {
            this.cleanFilterSettings(FILTER_STATUS_SLUG);
            this.filterCategory = {};
        }

        this.page = 1;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };

    @action handleChangeSortCategory = (sortCategory, sortOrder) => {
        this.sortCategory = { [sortCategory]: true };
        this.sortOrder = sortOrder;
        if (sortCategory && sortOrder) {
            localStorage.setItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`, `${sortCategory},${sortOrder}`);
        } else {
            localStorage.removeItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`);
        }
        this.page = 1;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };


    @action setLoading = (value) => {
        this.isLoading = value;
    };

    @action cleanState = () => {
        this.allCandidates = [];
        this.totalCount = -1;
        this.page = 1;
        this.hasMore = false;
        this.query = '';
        this.filterCategory = {};
        this.filterSourceCategory = {};
        this.filterTagsCategory = {};
        this.isLoading = false;
        this.isLoadingLocal = false;
        this.sortCategory = {};
        this.sortOrder = null;
        this.clearSelection();
        this.slug = '';
        this.invitationsStats = {};

        FILTER_STATUS_SLUG = 'filter-invite-status';
        FILTER_TAGS_SLUG = 'filter-invite-tags';
        FILTER_SOURCES_SLUG = 'filter-invite-sources';
    };

    @action toggleSelectedUserAuditionIds = (candidate) => {
        const { candidateId } = candidate;
        if (this.selectedCandidates[candidateId]) {
            delete this.selectedCandidates[candidateId];
        } else {
            this.selectedCandidates[candidateId] = candidate;
        }
    };

    @action clearSelection = () => {
        this.selectedCandidates = {};
    };

    @action toggleSelectAll = (selected) => {
        if (selected) {
            this.selectedCandidates = {};
        } else {
            this.allCandidates.forEach((item) => {
                this.selectedCandidates[item.candidateId] = item;
            });
        }
    };
}

export const invitationsStore = new InvitationsStore();
export const invitationsCtx = createContext(invitationsStore);

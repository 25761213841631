import React from 'react';
import Typography from '@mui/material/Typography';
import Icon from './icon.svg';

const EmptySetsList = () => (
    <div className="u-txt--center u-mrg--tx4">
        <img src={Icon} className="u-mrg--bx2" alt="+" />
        <Typography className="u-mrg--bx1" variant="h4">
            You don't have any created sets for this skill
        </Typography>
    </div>
);

export default EmptySetsList;

export default theme => ({
    title: {
        boxSizing: 'content-box',
        height: 40,
        textAlign: 'left',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.4',
        letterSpacing: 'normal',
        color: theme.palette.grey[900],
        marginTop: 9,
        paddingBottom: 8,
        whiteSpace: 'pre-wrap'
    },
    menu: {
        fontSize: 16,
        position: 'absolute',
        top: 12,
        right: 5,
        zIndex: '2'
    },
    notificationWrapper: {
        position: 'absolute',
        right: 13,
        top: 15,
        display: 'flex',
        justifyContent: 'space-between'
    },
    additionalInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '18px',
        color: theme.palette.grey[500]
    },
    additionalInfoValue: {
        color: theme.palette.grey[800]
    },
    additionalInfoValueDisable: {
        color: theme.palette.grey[600]
    },
    clickableArea: {
        display: 'flex',
        alignItems: 'center'
    },
    boardPhotos: {
        marginTop: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .candidate-photo': {
            width: 32,
            height: 32
        }
    }
});

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import 'react-circular-progressbar/dist/styles.css';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from 'libraries/Tooltip';
import GradeLoader from './GradeLoader';

import styles from './styles';
import Arrow from './shevron.svg';

const GradeScale = ({
    classes, grade, willBeGraded, hideCheck,
    propPublic, saveGrade, gradeableCard, canGrade,
    gradeLoading, sections = ['Poor', 'Average', 'Excellent'],
    questionNumber, totalCount, disabled: disabledProp,
    goToNextQuestion, goToPreviousQuestion, postHiring, hintSection,
    nextQuestionDisabled = questionNumber >= totalCount,
    previousQuestionDisabled = questionNumber <= 1
}) => {
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [loadedGrade, setLoadedGrade] = useState(null);

    useEffect(() => {
        setSelectedGrade(grade);
    }, [grade]);

    const availableGrades = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const disabled = (propPublic && !gradeableCard)
        || !willBeGraded
        || disabledProp
        || (!canGrade && !(propPublic && gradeableCard));

    const parsedGrade = parseInt(selectedGrade, 10);

    const gradeAnswer = (e, item) => {
        e.stopPropagation();
        if (disabled || gradeLoading || item === selectedGrade) return;

        if (postHiring) {
            saveGrade(item);
            setSelectedGrade(item);
            return;
        }

        if (saveGrade) {
            saveGrade(item);
            setLoadedGrade(item);
            setSelectedGrade(null);
        }
    };

    const getSectionIndexes = (section) => {
        const startIndex = (section - 1) * 4;
        const lastIndex = startIndex + 4;
        return { startIndex, lastIndex };
    };

    const getGradeValues = (startIndex, lastIndex) => (
        availableGrades
            .slice(startIndex, lastIndex)
            .map((item) => {
                const isActive = selectedGrade !== null && parsedGrade === item;
                return (
                    disabled ? (
                        <Tooltip key={item} label="Grading disabled">
                            <div
                                role="presentation"
                                className={clsx(classes.grade, isActive && classes.gradeSelected)}
                            >
                                {item}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            role="presentation"
                            onClick={e => gradeAnswer(e, item)}
                            className={clsx(classes.grade, isActive && classes.gradeActive, !disabled && classes.active)}
                            key={item}
                        >
                            {item}
                            <GradeLoader
                                isActive={loadedGrade === item}
                                hideCheck={hideCheck}
                                isLoading={gradeLoading && loadedGrade === item}
                            />
                        </div>
                    )
                );
            })
    );

    const getSection = (section, text) => {
        const { startIndex, lastIndex } = getSectionIndexes(section);
        const isActiveSection = (selectedGrade !== null && (parsedGrade < lastIndex && parsedGrade >= startIndex))
            || (hintSection && hintSection.toLowerCase() === text.toLowerCase() && selectedGrade === null);
        return (
            <div
                key={section}
                className={
                    clsx(classes.section, isActiveSection && classes.sectionActive, disabled && classes.sectionDisabled)
                }
            >
                <div className={classes.gradeWrapper}>
                    {getGradeValues(startIndex, lastIndex)}
                </div>
                <div
                    className={
                        clsx(classes.sectionHeader,
                            isActiveSection && classes.sectionActiveHeader,
                            disabled ? classes.sectionHeaderDisabled : classes.active)
                    }
                >
                    {text}&nbsp;
                </div>
            </div>
        );
    };

    return (
        <div className={!postHiring && classes.wrapper}>
            {!postHiring && <>
                <p className={clsx(classes.header, disabled && classes.headerDisabled)}>Estimated Score Range</p>
                <p className={classes.headerLabel}>
                    Confirm by selecting a score in the range or choose a new score to personalize your AI
                </p>
            </>}
            <div className={classes.actionsWrapper}>
                {
                    goToPreviousQuestion && (
                        <div className={classes.arrowButtonWrapper}>
                            <IconButton
                                className={classes.arrowButton}
                                disabled={previousQuestionDisabled}
                                onClick={goToPreviousQuestion}
                            >
                                <img src={Arrow} className={classes.backArrow} alt="<" />
                            </IconButton>
                        </div>
                    )
                }
                <div className={classes.scaleWrapper}>
                    {sections.map((text, index) => getSection(index + 1, text))}
                </div>
                {
                    goToNextQuestion && (
                        <div className={classes.arrowButtonWrapper}>
                            <IconButton
                                className={classes.arrowButton}
                                disabled={nextQuestionDisabled}
                                onClick={goToNextQuestion}
                            >
                                <img src={Arrow} className={classes.arrow} alt=">" />
                            </IconButton>
                            <Button
                                className={classes.skipButton}
                                disabled={nextQuestionDisabled}
                                onClick={goToNextQuestion}
                            >
                                Skip
                            </Button>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default withStyles(styles)(GradeScale);

import { createContext } from 'react';
import { action, observable } from 'mobx';
import { loadHiredCandidates, loadFiltersForHired } from 'requests/CandidatesRequests';
import MiscellaneousEvents from '../../../events/MiscellaneousEvents';

const FILTER_ASSESSMENT_SLUG = 'filter-hired-assessment';
const FILTER_MANAGER_SLUG = 'filter-hired-manager';
const SORT_PAGE_IDENTIFIER = 'sort-hired-page';

class HiredCandidateStore {
    @observable hiredCandidates = [];

    @observable totalCount = 0;

    @observable page = 1;

    @observable hasMore = false;

    @observable query = '';

    @observable filterCategory = 'all';

    @observable isLoading = true;

    @observable sortOrder = null;

    @observable sortBy = {};

    @observable isLoadingLocal = false;

    @observable statistics = {};

    @observable auditions = [];

    @observable hiringManagers = [];

    @observable filterAudition = null;

    @observable filterHiringManager = null;

    @observable isNoCandidates = false;

    timerId = null;

    @action cleanFilterSettings = (slug, except = '') => {
        const localStorageItems = Object.keys({ ...localStorage });
        const localStorageItemsStatuses = localStorageItems.filter(item => item.includes(slug));
        localStorageItemsStatuses.forEach((item) => {
            if (item !== `${slug}-${except}`) {
                localStorage.removeItem(item);
            }
        });
    };

    @action setFilterCategory = () => {
        const localStorageItems = Object.keys({ ...localStorage });
        const localStorageItemsAssessments = localStorageItems.filter(item => item.includes(FILTER_ASSESSMENT_SLUG));
        const localStorageItemsManagers = localStorageItems.filter(item => item.includes(FILTER_MANAGER_SLUG));

        if (localStorageItemsAssessments.length) {
            this.filterAudition = localStorageItemsAssessments[0].replace(`${FILTER_ASSESSMENT_SLUG}-`, '');
            this.cleanFilterSettings(FILTER_ASSESSMENT_SLUG, this.filterAudition);
        }

        if (localStorageItemsManagers.length) {
            this.filterHiringManager = localStorageItemsManagers[0].replace(`${FILTER_MANAGER_SLUG}-`, '');
            this.cleanFilterSettings(FILTER_MANAGER_SLUG, this.filterHiringManager);
        }
    };

    @action loadHiredCandidates = () => {
        this.isLoadingLocal = true;

        if (localStorage.getItem(SORT_PAGE_IDENTIFIER)) {
            const [sortBy, sortOrder] = localStorage.getItem(SORT_PAGE_IDENTIFIER).split(',');
            this.sortBy = { [sortBy]: true };
            this.sortOrder = sortOrder;
        }

        const sortByStr = Object.keys(this.sortBy).join(',');
        loadHiredCandidates(this.page, this.query, this.sortOrder, this.filterAudition, this.filterHiringManager, sortByStr)
            .then(({ success, data }) => {
                if (success && data) {
                    const { items, hasMorePages, total, currentPage } = data.paginated;
                    this.hiredCandidates = items;
                    this.totalCount = total;
                    this.page = currentPage;
                    this.hasMore = hasMorePages;
                    if (this.totalCount === 0 && !this.query && !this.filterAudition && !this.filterHiringManager) this.isNoCandidates = true;
                    this.statistics = data.statistics;
                }
            })
            .finally(() => {
                this.isLoading = false;
                this.isLoadingLocal = false;
            });
    };

    @action fetchFiltersForHired = () => {
        loadFiltersForHired()
            .then(({ success, data }) => {
                if (success && data) {
                    const { auditions, hiringManagers } = data;
                    this.auditions = auditions;
                    this.hiringManagers = hiringManagers;
                }
            });
    }

    @action setLoading = (value) => {
        this.isLoading = value;
    };

    @action handleChangeFilter = (e) => {
        const { value } = e.target;
        this.query = value;
        this.page = 1;
        this.isLoadingLocal = true;
        clearTimeout(this.timerId);
        this.timerId = setTimeout(async () => {
            await this.loadHiredCandidates();
            MiscellaneousEvents.CANDIDATE_SEARCH_CONDUCTED({
                searchTerm: value,
                context: 'Hired'
            });
        }, 300);
    };

    @action handleChangePage = (page) => {
        this.page = page;
        this.isLoadingLocal = true;
        this.loadHiredCandidates();
    };

    @action clearQuery = () => {
        this.query = '';
    }

    @action handleChangeFilterSelect = (filterUuid, filterType) => {
        if (filterType === 'audition') {
            this.cleanFilterSettings(FILTER_ASSESSMENT_SLUG);
            this.filterAudition = filterUuid;
            if (this.filterAudition) localStorage.setItem(`${FILTER_ASSESSMENT_SLUG}-${this.filterAudition}`, 'true');
        }
        if (filterType === 'hiringManager') {
            this.cleanFilterSettings(FILTER_MANAGER_SLUG);
            this.filterHiringManager = filterUuid;
            if (this.filterHiringManager) localStorage.setItem(`${FILTER_MANAGER_SLUG}-${this.filterHiringManager}`, 'true');
        }
        this.page = 1;
        this.isLoadingLocal = true;
        this.loadHiredCandidates();
    };

    @action clearFiltersSelect = () => {
        this.filterAudition = null;
        this.filterHiringManager = null;
        this.page = 1;
        this.isLoadingLocal = true;
        this.loadHiredCandidates();
    }

    @action handleChangeSort = (sortCategory, orderSort) => {
        this.sortOrder = orderSort;
        if (sortCategory) {
            this.sortBy[sortCategory] = true;
            if (orderSort) {
                localStorage.setItem(SORT_PAGE_IDENTIFIER, `${sortCategory},${orderSort}`);
            }
        } else {
            this.sortBy = {};
            localStorage.removeItem(SORT_PAGE_IDENTIFIER);
        }
        this.page = 1;
        this.isLoadingLocal = true;
        this.loadHiredCandidates();
    };

    @action cleanState = () => {
        this.hiredCandidates = [];
        this.filterHiringManager = null;
        this.filterAudition = null;
        this.page = 1;
        this.hasMore = false;
        this.query = '';
        this.isLoading = true;
        this.isNoCandidates = false;
    }
}

export const hiredCandidateStore = new HiredCandidateStore();
export const hiredCandidateCtx = createContext(hiredCandidateStore);

import React from 'react';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    label: {
        fontWeight: 600,
        color: theme.palette.grey[700]
    },
    value: {
        fontWeight: 700,
        color: theme.palette.grey[900]
    }
});

const SectionRow = ({
    label,
    value,
    classes
}) => (
    <div className="u-mrg--bx2 u-txt--16 u-dsp--distribute">
        <span className={classes.label}>{label}</span>
        <span className={classes.value}>{value}</span>
    </div>
);

export default withStyles(styles)(SectionRow);

import React from 'react';

const PoweredBy = ({ color }) => (
    <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M47.8721 16.2745V20.7818C47.3044 20.6286 46.721 20.5452 46.1343 20.5335C43.8137 20.5335 41.9422 21.9789 41.9422 25.1786V34.0055H37.1299V16.0539H41.9422V18.3875C42.4138 17.653 43.0588 17.0543 43.8165 16.648C44.5742 16.2417 45.4197 16.041 46.2733 16.0649C46.8112 16.0855 47.3463 16.1556 47.8721 16.2745Z" fill={color} />
        <path d="M85.1302 25.0242C85.1302 27.4047 84.2137 29.6878 82.5822 31.371C80.9507 33.0543 78.7379 34 76.4306 34C74.1233 34 71.9105 33.0543 70.279 31.371C68.6475 29.6878 67.731 27.4047 67.731 25.0242C67.731 23.8455 67.956 22.6783 68.3932 21.5893C68.8304 20.5003 69.4712 19.5108 70.279 18.6773C71.0869 17.8438 72.0459 17.1827 73.1014 16.7316C74.1569 16.2805 75.2881 16.0484 76.4306 16.0484C77.5731 16.0484 78.7043 16.2805 79.7598 16.7316C80.8153 17.1827 81.7743 17.8438 82.5822 18.6773C83.39 19.5108 84.0308 20.5003 84.468 21.5893C84.9052 22.6783 85.1302 23.8455 85.1302 25.0242ZM72.4417 25.0242C72.4417 26.1157 72.862 27.1625 73.61 27.9343C74.3581 28.7061 75.3727 29.1397 76.4306 29.1397C77.4885 29.1397 78.5031 28.7061 79.2512 27.9343C79.9992 27.1625 80.4195 26.1157 80.4195 25.0242C80.4195 23.9327 79.9992 22.8859 79.2512 22.1141C78.5031 21.3422 77.4885 20.9087 76.4306 20.9087C75.3727 20.9087 74.3581 21.3422 73.61 22.1141C72.862 22.8859 72.4417 23.9327 72.4417 25.0242Z" fill={color} />
        <path d="M13.8275 15.9987L9.37871 24.6049L4.91928 16.0153L0 15.9987L9.39475 33.9559L18.7521 15.9987H13.8275Z" fill={color} />
        <path d="M63.3626 15.9987L58.9139 24.6049L54.4544 16.0153L49.5352 15.9987L58.9299 33.9559L68.2872 15.9987H63.3626Z" fill={color} />
        <path d="M98.236 29.1121C97.502 29.6529 96.613 29.923 95.7122 29.879C94.6798 29.9321 93.666 29.5817 92.8731 28.8975C92.0802 28.2134 91.5665 27.2459 91.4345 26.1882H103.888C103.952 25.6365 103.989 24.8145 103.989 24.4284C103.992 23.3142 103.779 22.2107 103.365 21.1819C102.95 20.1531 102.342 19.2195 101.575 18.4353C100.808 17.651 99.8973 17.0318 98.8964 16.6135C97.8955 16.1953 96.8241 15.9863 95.7443 15.9987C94.6023 15.9788 93.4681 16.1949 92.4085 16.6345C91.3488 17.074 90.3852 17.7279 89.5744 18.5578C88.7636 19.3876 88.122 20.3766 87.6875 21.4663C87.2531 22.5561 87.0345 23.7245 87.0446 24.9028C87.0303 26.0994 87.2481 27.2869 87.6853 28.3953C88.1225 29.5037 88.7703 30.5106 89.5904 31.3568C90.4106 32.203 91.3865 32.8713 92.4608 33.3224C93.5351 33.7735 94.6861 33.9983 95.8458 33.9835C97.3773 33.9852 98.8866 33.6068 100.246 32.8801L98.236 29.1121ZM95.5625 20.026C96.3961 19.9846 97.2141 20.2697 97.8526 20.8242C98.4911 21.3787 98.9031 22.1615 99.0059 23.0161H91.6858C91.8837 22.1287 92.379 21.3421 93.0844 20.7949C93.7898 20.2478 94.6599 19.9755 95.5411 20.026H95.5625Z" fill={color} />
        <path d="M29.3766 29.1121C28.641 29.6537 27.75 29.9238 26.8474 29.879C25.8151 29.9321 24.8013 29.5817 24.0084 28.8975C23.2154 28.2134 22.7017 27.2459 22.5698 26.1882H35.0338C35.0987 25.6039 35.1344 25.0165 35.1407 24.4284C35.1429 23.3133 34.9302 22.2089 34.5151 21.1793C34.0999 20.1498 33.4905 19.2157 32.7223 18.4313C31.9541 17.6469 31.0424 17.0279 30.0403 16.6103C29.0382 16.1927 27.9656 15.9848 26.8849 15.9987C25.7411 15.978 24.605 16.1941 23.5436 16.6343C22.4823 17.0745 21.5173 17.7299 20.7057 18.5616C19.8941 19.3933 19.2524 20.3844 18.8186 21.4765C18.3847 22.5685 18.1675 23.7392 18.1799 24.9194C18.1655 26.1164 18.3835 27.3043 18.8211 28.4131C19.2586 29.5219 19.9068 30.5291 20.7276 31.3753C21.5483 32.2216 22.5249 32.8898 23.5998 33.3406C24.6747 33.7913 25.8262 34.0156 26.9865 34C28.5179 34.0018 30.0272 33.6234 31.3871 32.8966L29.3766 29.1121ZM26.6817 20.026C27.5153 19.9846 28.3333 20.2697 28.9718 20.8242C29.6104 21.3787 30.0223 22.1615 30.1252 23.0161H22.8264C23.0243 22.1287 23.5196 21.3421 24.225 20.7949C24.9304 20.2478 25.8005 19.9755 26.6817 20.026Z" fill={color} />

        <path d="M3.55059 0H0.68457V7.72351H1.93578V4.74444H3.55059C4.93012 4.74444 5.94606 3.74039 5.94606 2.36119C5.94606 1.00406 4.93012 0 3.55059 0ZM3.55059 3.50868H1.93578V1.23576H3.55059C4.20292 1.23576 4.68416 1.72124 4.68416 2.36119C4.68416 3.01217 4.20292 3.50868 3.55059 3.50868Z" fill={color} />
        <path d="M8.92812 2.21775C7.39887 2.21775 6.22252 3.43145 6.22252 5.03132C6.22252 6.63119 7.39887 7.83385 8.92812 7.83385C10.4574 7.83385 11.6444 6.63119 11.6444 5.03132C11.6444 3.43145 10.4574 2.21775 8.92812 2.21775ZM8.92812 3.39835C9.79434 3.39835 10.4681 4.10449 10.4681 5.03132C10.4681 5.9471 9.79434 6.65325 8.92812 6.65325C8.0619 6.65325 7.39887 5.9471 7.39887 5.03132C7.39887 4.10449 8.0619 3.39835 8.92812 3.39835Z" fill={color} />
        <path d="M13.7911 7.72351H14.7322L15.9727 4.22586L17.2132 7.72351H18.165L20.0578 2.32809H18.7745L17.6837 5.83677L16.4432 2.32809H15.5021L14.2723 5.83677L13.1815 2.32809H11.8982L13.7911 7.72351Z" fill={color} />
        <path d="M25.572 4.90995C25.572 3.33214 24.4277 2.21775 23.0161 2.21775C21.5082 2.21775 20.3212 3.43145 20.3212 4.99822C20.3212 6.60912 21.5082 7.83385 23.0375 7.83385C24.3208 7.83385 25.3153 7.08356 25.5078 5.9471H24.3101C24.2031 6.41051 23.7005 6.71946 23.0589 6.71946C22.214 6.71946 21.6793 6.22294 21.5296 5.42853H25.5399C25.5613 5.32922 25.572 5.05338 25.572 4.90995ZM21.5617 4.38033C21.7328 3.71832 22.2461 3.33214 22.9519 3.33214C23.6684 3.33214 24.171 3.76245 24.278 4.38033H21.5617Z" fill={color} />
        <path d="M29.1608 2.21775C28.5406 2.21775 28.0593 2.52669 27.7492 3.04527V2.32809H26.5622V7.72351H27.7492V4.84375C27.7492 3.87279 28.3695 3.33214 29.1288 3.33214C29.2999 3.33214 29.4817 3.36524 29.6528 3.40938V2.28395C29.4924 2.25085 29.3212 2.21775 29.1608 2.21775Z" fill={color} />
        <path d="M35.0859 4.90995C35.0859 3.33214 33.9417 2.21775 32.5301 2.21775C31.0222 2.21775 29.8351 3.43145 29.8351 4.99822C29.8351 6.60912 31.0222 7.83385 32.5514 7.83385C33.8347 7.83385 34.8293 7.08356 35.0218 5.9471H33.824C33.7171 6.41051 33.2145 6.71946 32.5728 6.71946C31.728 6.71946 31.1933 6.22294 31.0436 5.42853H35.0539C35.0753 5.32922 35.0859 5.05338 35.0859 4.90995ZM31.0757 4.38033C31.2468 3.71832 31.7601 3.33214 32.4659 3.33214C33.1824 3.33214 33.685 3.76245 33.792 4.38033H31.0757Z" fill={color} />
        <path d="M40.0569 0V3.27698C39.6826 2.60393 39.0409 2.21775 38.2068 2.21775C36.8165 2.21775 35.7044 3.46455 35.7044 5.02028C35.7044 6.57602 36.8165 7.83385 38.2068 7.83385C39.0409 7.83385 39.6826 7.44767 40.0569 6.77462V7.72351H41.2546V0H40.0569ZM38.4634 6.66429C37.5758 6.66429 36.8807 5.93607 36.8807 5.02028C36.8807 4.11553 37.5758 3.38731 38.4634 3.38731C39.3724 3.38731 40.0569 4.09346 40.0569 5.02028C40.0569 5.95814 39.3724 6.66429 38.4634 6.66429Z" fill={color} />
        <path d="M47.9173 2.21775C47.0725 2.21775 46.4309 2.61496 46.0566 3.29904V0H44.8695V7.72351H46.0566V6.75256C46.4309 7.43664 47.0725 7.83385 47.9173 7.83385C49.3076 7.83385 50.4091 6.57602 50.4091 5.02028C50.4091 3.46455 49.3076 2.21775 47.9173 2.21775ZM47.6607 6.66429C46.741 6.66429 46.0566 5.95814 46.0566 5.02028C46.0566 4.09346 46.741 3.38731 47.6607 3.38731C48.5483 3.38731 49.2434 4.11553 49.2434 5.02028C49.2434 5.93607 48.5483 6.66429 47.6607 6.66429Z" fill={color} />
        <path d="M54.739 2.32809L53.3381 6.00227L51.9371 2.32809H50.6539L52.7285 7.43664L51.7874 9.62129H53.0707L56.0223 2.32809H54.739Z" fill={color} />
    </svg>
);

export default PoweredBy;

export default theme => ({
    wrapper: {
        display: 'block',
        paddingLeft: 30,
        paddingRight: 30,
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        '&:first-child': {
            border: 'none',
            paddingLeft: 0
        }
    },
    value: {
        lineHeight: 1.2,
        whiteSpace: 'nowrap',
        color: theme.palette.grey[900],
        fontSize: 32,
        fontWeight: 700
    },
    valueLabel: {
        marginLeft: 2,
        fontSize: 16,
        lineHeight: 1.4
    },
    preValueLabel: {
        marginRight: 2,
        fontSize: 21,
        lineHeight: 1.4
    },
    label: {
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        color: theme.palette.grey[600],
        fontSize: 10,
        fontWeight: 700,
        lineHeight: '18px'
    },
    percentage: {
        marginLeft: 4,
        fontSize: 18
    }
});

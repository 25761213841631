import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
import AvatarIcon from '../../../assets/avatar.svg';
import CommentIcon from '../../../assets/comment.svg';
import EditIcon from '../../../assets/edit.svg';
import EllipseIcon from '../../../assets/ellipse.svg';
import HamburgerIcon from '../../../assets/hamburger.svg';
import MailIcon from '../../../assets/mail.svg';

const FakeHeader = () => {
    const { wrapper, iconWrapper, active, activeEllipse } = useStyles({});

    return (
        <div className={wrapper}>
            <div className={iconWrapper}>
                <img src={HamburgerIcon} alt="" />
            </div>
            <div className={clsx(iconWrapper, active)}>
                <img src={MailIcon} alt="" />
                <img src={EllipseIcon} alt="" className={activeEllipse} />
            </div>
            <div className={iconWrapper}>
                <img src={AvatarIcon} alt="" />
            </div>
            <div className={iconWrapper}>
                <img src={CommentIcon} alt="" />
            </div>
            <div className={iconWrapper}>
                <img src={EditIcon} alt="" />
            </div>
        </div>
    );
};

export default FakeHeader;

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import styles from './styles';

const TextInput = (props) => {
    const {
        classes,
        className,
        isError,
        value = '',
        helperText,
        maxLength,
        hasCounter,
        variant,
        counterClassName,
        helperTextClassName = '',
        inputComponent,
        max, min,
        InputProps = {},
        inputRef = null,
        startAdornment = null,
        endAdornment = null,
        dataRow, inputProps,
        FormHelperTextProps: formHelperTextProps,
        InputLabelProps = {},
        margin = 'normal',
        ...other
    } = props;

    const charactersRemaining = value ? maxLength - value.length : maxLength;
    const hasErrorField = isError || (hasCounter && charactersRemaining < 0);

    const FormHelperTextProps = formHelperTextProps || { error: hasErrorField };

    const counterLabel = (maxLength && hasCounter) ? (
        <span className={clsx(classes.counterLabel, counterClassName,
            variant === 'outlined' && classes.counterLabelOutlined,
            charactersRemaining < 0 && classes.counterLabelError)}
        >
            {charactersRemaining} characters remaining
        </span>
    ) : null;

    let formattedHelperText = (
        Array.isArray(helperText) ? (
            <span className="u-wdt--100p u-dsp--distribute">
                <span className="u-wdt--100p">
                    {
                        helperText.map((item, index) => (
                            <span key={index} className={classes.errorArrayItem}>{item}</span>
                        ))
                    }
                </span>
                {counterLabel}
            </span>
        ) : (
            <span className={`u-wdt--100p u-dsp--distribute ${helperTextClassName}`}>
                <span className="u-wdt--100p">{helperText}</span>
                {counterLabel}
            </span>
        )
    );
    formattedHelperText = (helperText || counterLabel) ? formattedHelperText : null;

    return (
        <TextField
            fullWidth
            value={value}
            variant={variant}
            helperText={formattedHelperText}
            className={className}
            margin={margin}
            InputLabelProps={{
                error: hasErrorField,
                ...InputLabelProps
            }}
            InputProps={{
                'data-row': dataRow,
                error: hasErrorField,
                startAdornment,
                endAdornment,
                inputRef,
                inputComponent,
                ...InputProps
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ maxLength, min, max, ...inputProps }}
            {...other}
            FormHelperTextProps={FormHelperTextProps}
        />
    );
};

export default withStyles(styles)(TextInput);

import axios from 'axios';
import { action, observable } from 'mobx';
import { loadPrivateInterviews, loadPublicInterviews } from 'requests/ScriptRequests';
import LibraryEvents from 'events/LibraryEvents';
import { createContext } from 'react';
import { appStore } from '../../appStore';
import { assessmentQueryStore } from './queryStore';
import { assessmentTagsListStore } from './PrivateAssessmentsView/TabsSearchHeader/store';

const { CancelToken } = axios;
let source;

export class AssessmentListStore {
    @observable assessments = null;

    @observable currentPage = 0;

    @observable totalCount = 0;

    @observable loadingAssessments = true;

    @observable loadingSearchAssessments = true;

    @observable mode = 'assessment';

    @observable refineByTitle = '';

    @observable refineByList = null;

    @observable timerId = null;

    @action setTotalCount = (newValue) => {
        this.totalCount = newValue;
    }

    @action setCurrentPage = (newPage) => {
        this.currentPage = newPage;
    }

    @action setAssessments = (newValue) => {
        this.assessments = newValue;
    }

    @action setLoadingSearchAssessments = (newValue) => {
        this.loadingSearchAssessments = newValue;
    }

    @action setLoadingAssessments = (newValue) => {
        this.loadingAssessments = newValue;
    }

    @action setMode = (newValue) => {
        this.mode = newValue;
    }

    @action setRefineByTitle = (newValue) => {
        this.refineByTitle = newValue;
    }

    @action getPublicAssessments = async (filterData) => {
        this.loadingAssessments = true;
        if (source && source.token) source.cancel();
        source = CancelToken.source();

        await this.getAssessmentsWrapper(true, null, filterData);
    }

    @action getPrivateAssessments = async (folderId) => {
        this.loadingAssessments = true;
        if (source && source.token) source.cancel();
        source = CancelToken.source();
        await this.getAssessmentsWrapper(false, folderId);
    }

    @action getAssessmentsWrapper = async (isPublic, folderId, filterData) => {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
            const func = (isPublic
                ? loadPublicInterviews(assessmentQueryStore.query, this.currentPage + 1, { cancelToken: source.token }, filterData)
                : loadPrivateInterviews(assessmentQueryStore.query, this.currentPage + 1, assessmentTagsListStore.assessmentsFilter, null, null, folderId, { cancelToken: source.token }));

            return (
                func
                    .then((data) => {
                        if (data) {
                            const { total, currentPage: dataCurrentPage, items, refineBy } = data;

                            if (assessmentQueryStore.query) {
                                LibraryEvents.SEARCH_CONDUCTED({
                                    public: isPublic,
                                    numberResults: total,
                                    searchTerm: assessmentQueryStore.query,
                                    assessmentActive: assessmentTagsListStore.assessmentsFilter === 'open'
                                });
                            }

                            const newAssessmentsPublicList = !this.assessments || this.currentPage === 0 ? [] : this.assessments;

                            this.totalCount = total;
                            this.assessments = !isPublic ? items : newAssessmentsPublicList.concat(items);
                            this.loadingAssessments = false;
                            this.currentPage = dataCurrentPage;
                            this.loadingSearchAssessments = false;
                            this.refineByList = refineBy;
                        }
                        this.loadingAssessments = false;
                    })
                    .catch((err) => {
                        if (!axios.isCancel(err)) {
                            appStore.flashMessage('Something went wrong', 'error');
                            this.assessments = [];
                            this.refineByList = [];
                            this.loadingAssessments = false;
                        }
                    })
            );
        }, 500);
    }

    @action cleanState = () => {
        this.totalCount = 0;
        this.assessments = null;
        this.loadingAssessments = true;
        this.currentPage = 0;
        this.loadingSearchAssessments = false;
        this.refineByList = null;
    }
}

export const assessmentListStore = new AssessmentListStore();
export const AssessmentListCtx = createContext(assessmentListStore);

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import { getJobAdderWebhookStatus,
    reSubscribeToJobAdderWebhook } from 'requests/SubscriptionRequests';
import CheckIcon from './check.svg';
import CrossIcon from './cross.svg';

const styles = theme => ({
    enabled: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.green[600]
    },
    suspended: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.red.primary
    },
    resubscribeButton: {
        marginBottom: 48,
        marginLeft: -9
    }
});


const WebhookStatusBlock = ({ classes, flashMessage }) => {
    const [webhookStatus, setWebhookStatus] = useState(undefined);

    useEffect(() => {
        getWebhookStatus();
    }, []);

    const getWebhookStatus = (resubscribe = false) => {
        setWebhookStatus(undefined);
        const request = resubscribe ? reSubscribeToJobAdderWebhook : getJobAdderWebhookStatus;
        request()
            .then(({ data, success }) => {
                if (data && success) {
                    setWebhookStatus(data.status);
                }
            })
            .catch(() => {
                flashMessage('Please, try to resubscribe to webhook', 'error');
                setWebhookStatus('Disabled');
            });
    };

    const returnWebhookStatus = () => {
        switch (webhookStatus) {
            case 'Enabled':
                return <div className={classes.enabled}>Enabled<img className="u-mrg--lx1" src={CheckIcon} alt="done" /></div>;
            case undefined:
                return <>Checking<CircularProgress size={14} className="u-mrg--lx2" /></>;
            default:
                return <div className={classes.suspended}>{webhookStatus}<img className="u-mrg--lx1" src={CrossIcon} alt="error" /></div>;
        }
    };

    return (
        <>
            <div className="u-dsp--f u-ai--center u-mrg--bx2">
                <Typography variant="h6" className="u-mrg--rx4">
                    Webhook status:
                </Typography>
                {returnWebhookStatus()}
            </div>
            <Button
                color="primary"
                onClick={() => getWebhookStatus(true)}
                className={classes.resubscribeButton}
                disabled={!webhookStatus}
            >
                Resubscribe to webhook
            </Button>
        </>
    );
};

export default withStyles(styles)(WebhookStatusBlock);

export default theme => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '34px 0 45px',
        textAlign: 'center',
        minHeight: 400
    },
    text: {
        margin: 'auto',
        maxWidth: 555,
        marginTop: 22,
        marginBottom: 24,
        color: theme.palette.grey[700],
        fontSize: 14
    },
    button: {
        fontWeight: 'bold',
        fontSize: 14
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        width: 90,
        height: 90,
        borderRadius: '100%',
        '& img': {
            marginWidth: '100%',
            marginTop: 5
        }
    },
    iconWrapperBackground: {
        backgroundColor: theme.palette.grey[200],
        margin: '0 auto 24px'
    }
});

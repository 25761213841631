import React from 'react';
import { observer } from 'mobx-react-lite';
import SimulatorTypeContent from '../components/SimulatorTypeContent';
import SimulatorArticle from './SimulatorArticle';


const SimulatorTicketContent = observer(({
    question, fetchQuestion, setSaving, isCMS
}) => (
    <SimulatorTypeContent
        ItemComp={SimulatorArticle}
        entity="article"
        {...{
            question, fetchQuestion, setSaving, isCMS
        }}
    />
));

export default SimulatorTicketContent;

export default theme => ({
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media only screen and (max-width: 767px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    numberQuestion: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        color: theme.palette.grey[900],
        fontSize: 16,
        '@media only screen and (max-width: 767px)': {
            marginBottom: 20
        }
    },
    questionTimeLimit: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: 12,
        color: '#3a3c43',
        fontWeight: 600,
        marginLeft: 8
    },
    svgIconStyle: {
        maxWidth: 20,
        width: 24,
        height: 24,
        color: '#fff',
        marginBottom: 0
    },
    skillWrapper: {
        display: 'flex',
        textTransform: 'capitalize',
        alignItems: 'center',
        background: theme.palette.blue[500],
        borderRadius: 4,
        fontSize: 12,
        fontWeight: 600,
        marginRight: 10,
        color: '#fff',
        padding: '4px 8px'
    },
    requiredLabel: {
        display: 'inline-block',
        padding: '5px 10px',
        borderRadius: '12px',
        backgroundColor: '#e6e6e6',
        fontSize: 10,
        color: '#1a1b1e',
        letterSpacing: '.8',
        marginLeft: 12,
        textTransform: 'uppercase'
    },
    addButton: {
        fontSize: 14,
        color: theme.palette.primary.main,
        borderRadius: 100,
        fontWeight: 600,
        paddingTop: 3,
        paddingBottom: 3,
        boxShadow: 'none',
        border: `2px solid ${theme.palette.grey[300]}`,
        '&:hover': {
            boxShadow: 'none'
        },
        '& img': {
            width: 15
        }
    },
    reorderButton: {
        boxShadow: 'none',
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: '50%',
        width: 40,
        height: 40,
        minWidth: 40,
        marginLeft: 15,
        padding: 0,
        '& img': {
            marginRight: 0
        },
        '&:hover': {
            boxShadow: 'none'
        }
    },
    cachedIcon: {
        color: theme.palette.primary.main
    },
    skillName: {
        fontWeight: 'bold',
        textTransform: 'capitalize'
    },
    actionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap'
    }
});

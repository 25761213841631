import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { clone } from 'helper/commonFunctions';
import { editCMSQuestionSet } from 'requests/CMSRequests';

import EditPanelSection from 'components/assessments_pages/asessment_settings_components/EditPanelSection';
import GroupRandOption from 'components/assessments_pages/edit_question_groups/SkillGroupRandomization/GroupRandOption';

import styles from './styles';

const SkillsRandomise = ({ audition, setAuditionProps, setSaving }) => {
    const [questionsGroups, setQuestionsGroups] = useState(audition.companySets || []);

    useEffect(() => {
        setQuestionsGroups(audition.companySets || []);
    }, [audition.companySets]);

    const editSkillGroup = async (id, dataParams) => {
        setSaving(true);
        const { data } = await editCMSQuestionSet(id, dataParams);
        if (setAuditionProps) setAuditionProps({ ...audition, synchronized: false });
        setSaving(false);
        return data;
    };

    const changeSkillGroup = (group, groupIndex) => {
        const newQuestionsGroups = clone(questionsGroups);
        newQuestionsGroups[groupIndex] = group;
        setQuestionsGroups(newQuestionsGroups);
    };

    if (!questionsGroups.length) return null;

    return (
        <EditPanelSection
            header="Question Randomise (Skill)"
        >
            <p>Randomise a number of your questions in each skill so each candidate seems a different assessment</p>
            {
                questionsGroups.map((group, index) => (
                    <GroupRandOption
                        groupIndex={index}
                        changeSkillGroup={changeSkillGroup}
                        key={`${group.id}_${index}`}
                        group={{ ...group, ...group.companySkill }}
                        isCMS
                        editSkillGroup={editSkillGroup}
                    />
                ))
            }
        </EditPanelSection>
    );
};

export default withStyles(styles)(SkillsRandomise);

export default (theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    mainButton: {
        width: '48%',
        minHeight: 36,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold',
        outline: 'none',
        color: '#fff',
        '&:hover, &:active, &:focus': {
            color: '#fff',
            outline: 'none'
        }
    },
    greyButton: {
        background: `${theme.palette.grey[400]}!important`
    },
    secondaryButton: {
        width: '48%',
        minHeight: 36,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold',
        color: theme.palette.grey[900],
        background: '#fff',
        border: `2px solid ${theme.palette.grey[300]}`
    },
    secondaryNoBorderButton: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    contentClassName: {
        padding: '65px 25px 40px',
        textAlign: 'center'
    },
    contentTitle: {
        color: theme.palette.grey[900],
        fontSize: 20,
        fontWeight: 700,
        marginTop: 15,
        marginBottom: 20
    },
    contentText: {
        maxWidth: 415,
        margin: 'auto',
        color: theme.palette.grey[700]
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '3px 15px 3px 3px',
        fontSize: 14,
        fontWeight: 700,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        marginBottom: 15,
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.grey[200],
            borderColor: theme.palette.grey[400]
        }
    },
    activeOption: {
        color: theme.palette.primary.main,
        background: theme.palette.blue[50],
        borderColor: theme.palette.primary.main
    },
    tab: {
        minWidth: 'auto',
        fontWeight: 'bold',
        fontSize: 14,
        padding: 0,
        marginRight: 30,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.2px',
        textTransform: 'none'
    },
    score: {
        color: theme.palette.grey[600],
        fontSize: 12
    },
    count: {
        color: theme.palette.grey[500],
        fontSize: 12,
        fontWeight: 600
    }
}));

import React from 'react';


const CodeIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M19.7969 8L12.6957 25.0428" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.8965 23.1132L30.6657 16.7776L23.8965 10.4419" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.10352 23.1132L1.33428 16.7776L8.10352 10.4419" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default CodeIcon;

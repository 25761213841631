import { del, get, patch, post } from '../helper/API';

// --------- AF --------- //

export function getAssessmentAdmins(slug) {
    return get(`employer/auditions/${slug}/admin-employers`);
}

// reject AF

export function getAssessmentRejectionEmailData(slug) {
    return get(`employer/auditions/${slug}/custom-email/reject`);
}

export function changeAssessmentRejectionEmailData(slug, data) {
    return patch(`employer/auditions/${slug}/custom-email/reject`, data);
}

export function deleteAssessmentRejectionEmailData(slug) {
    return del(`employer/auditions/${slug}/custom-email/reject`);
}

export function switchAssessmentSendRejectionEmail(slug) {
    return patch(`employer/auditions/${slug}/send-reject-email/switch`);
}

// remind AF

export function deleteReminderEmailData(slug, data) {
    return del(`employer/auditions/${slug}/custom-email/reminder`, data);
}

export function switchAssessmentSendReminderEmail(slug) {
    return patch(`employer/auditions/${slug}/send-reminder-email/switch`);
}

export function changeReminderEmailData(slug, data) {
    return patch(`employer/auditions/${slug}/custom-email/reminder`, data);
}

export function getAssessmentReminderEmailData(slug) {
    return get(`employer/auditions/${slug}/custom-email/reminder`);
}

// invite AF

export function getAssessmentInvitationEmailData(slug) {
    return get(`employer/auditions/${slug}/custom-email/invite`);
}

export function changeAssessmentEmailData(slug, data) {
    return patch(`employer/auditions/${slug}/custom-email/invite`, data);
}

export function deleteAssessmentEmailData(slug) {
    return del(`employer/auditions/${slug}/custom-email/invite`);
}

// candidate feedback

export function getCandidateFeedbackPDF(slug) {
    return get(`employer/auditions/${slug}/candidate-feedback/pdf`, {}, { responseType: 'blob' });
}

export function getCandidateFeedbackEmailData(slug) {
    return get(`employer/auditions/${slug}/custom-email/feedback`);
}

export function changeCandidateFeedbackEmailData(slug, data) {
    return patch(`employer/auditions/${slug}/custom-email/feedback`, data);
}

export function deleteCandidateFeedbackEmailData(slug) {
    return del(`employer/auditions/${slug}/custom-email/feedback`);
}

export function sendCandidateFeedback(candidateId) {
    return post(`employer/user-auditions/${candidateId}/candidate-feedback/send`);
}

export default theme => ({
    label: {
        fontSize: 12,
        marginBottom: 2,
        marginLeft: 6,
        color: theme.palette.green[600],
        verticalAlign: 'middle',
        padding: '0px 6px',
        fontWeight: 700,
        '& svg': {
            marginRight: 5
        },
        '& img': {
            marginRight: 5,
            marginBottom: 3
        },
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 50,
        borderRadius: 4,
        lineHeight: '22px'
    },
    activeLabel: {
        background: theme.palette.green[600],
        color: 'white'
    },
    inactiveLabel: {
        background: theme.palette.grey[400],
        color: 'white'
    },
    hoveredLabel: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[400],
        cursor: 'pointer'
    }
});

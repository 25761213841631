import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import BoardItemWrapper from 'components/board/board_item';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';
import AssessmentPreviewDialog from 'components/dialogs/AssessmentPreviewDialog';

import GeneralEvents from 'events/GeneralEvents';

import { copyScriptAction } from 'helper/assessmentFunctions';
import PreviewIcon from 'img/preview.svg';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import AddIcon from 'img/add_to_assessments.svg';

import SkillsGroups from '../../../SkillsGroups';
import Rating from './Rating';

import ViewIcon from './view.svg';

import stylesJS from './styles';

const PublicBoardItem = observer(({
    assessment, query, history, classes, handleCategory
}) => {
    const [openPreviewScript, setOpenPreviewScript] = useState(false);
    const [openAssessmentPreviewDialog, setOpenAssessmentPreviewDialog] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const {
        name, slug: interviewSlug, uuid,
        questionGroups, category, active = false,
        scoreType, summary, csatScore, csatNumberOfFeedbacks
    } = assessment;

    const handleAssessmentPreviewDialog = () => {
        setOpenAssessmentPreviewDialog(!openAssessmentPreviewDialog);
    };

    const handleOpenPreview = (e) => {
        e.stopPropagation();
        GeneralEvents.TT_PREVIEWED({
            ttId: uuid,
            ttName: name,
            publicLibrary: true,
            activeAssessment: active
        });
        setOpenPreviewScript(true);
    };

    const onClickAddToMyAssessments = (e) => {
        e.stopPropagation();
        setLoading(true);
        copyScriptAction(assessment, history)
            .then(() => {
                setOpenAssessmentPreviewDialog(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getBoardMenuItems = () => ([
        {
            label: <><img src={AddIcon} alt="" /> Add to my assessments</>,
            onClick: onClickAddToMyAssessments,
            disabled: isLoading
        },
        {
            label: <><img src={ViewIcon} alt="" /> View Details</>,
            onClick: handleAssessmentPreviewDialog
        },
        {
            label: <><img src={PreviewIcon} alt="" /> Preview</>,
            onClick: handleOpenPreview
        }
    ]);

    return (
        <>
            <BoardItemWrapper
                className={classes.wrapper}
                onClick={handleAssessmentPreviewDialog}
            >
                <div className={classes.headerWrapper}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            {name}
                        </div>
                        {
                            category && category.title && (
                                <>
                                    <div
                                        role="presentation"
                                        className={classes.categoryType}
                                        onClick={() => handleCategory(category.title)}
                                    >
                                        {category.title}
                                    </div>
                                </>
                            )
                        }
                        <Rating csatScore={csatScore} csatNumberOfFeedbacks={csatNumberOfFeedbacks} />
                    </div>
                    <BoardItemMenu
                        iconMenuClassName={classes.boardMenu}
                        options={getBoardMenuItems()}
                    />
                </div>
                <div className={classes.contentWrapper}>
                    <div className={classes.descriptionWrapper}>{summary}</div>
                    <SkillsGroups
                        className={classes.skillsWrapper}
                        items={questionGroups}
                        query={query}
                        scoreType={scoreType}
                    />
                </div>
            </BoardItemWrapper>
            <PreviewScript
                {...{
                    onClose: setOpenPreviewScript,
                    interviewSlug,
                    isPublic: true,
                    isLibrary: true,
                    open: openPreviewScript,
                    uuid
                }}
            />
            <AssessmentPreviewDialog
                open={openAssessmentPreviewDialog}
                onClose={handleAssessmentPreviewDialog}
                assessment={assessment}
                isLoading={isLoading}
                copyScriptAction={onClickAddToMyAssessments}
                handleOpenPreview={handleOpenPreview}
            />
        </>
    );
});

export default withStyles(stylesJS)(withRouter(PublicBoardItem));

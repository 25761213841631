import React from 'react';
import Tooltip from 'libraries/TooltipWhite';

const NoInteractionTooltip = ({ id }) => (
    <Tooltip
        id={id}
        delayHide={100}
        effect="float"
    >
        This preview is not interactive, however the assessment your candidate will see is.
    </Tooltip>
);

export default NoInteractionTooltip;

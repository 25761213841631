import React, { useState } from 'react';

// material components
import { withStyles } from '@mui/styles';
import TextInputOutlined from 'libraries/TextInput';
import Typography from '@mui/material/Typography';
import Select from 'libraries/Select';
import styles from './styles';

const CustomerDetails = ({ customer, onChangeTicket }) => {
    const [customerState, setCustomerState] = useState(customer || {});
    const { name, customerIdentifier, source } = customerState;

    const handleChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { value, name: eventName } = e.target;
        const newState = {
            ...customerState,
            [eventName]: value
        };
        setCustomerState(newState);
        onChangeTicket('customer', newState);
    };

    const options = ['email', 'other'];

    return (
        <>
            <Typography variant="h6" className="u-mrg--bx2">Customer Details</Typography>
            <TextInputOutlined
                value={name || ''}
                name="name"
                variant="outlined"
                label="Customer Name"
                isError={!name}
                onChange={handleChange}
                fullWidth
                maxLength={160}
                className="u-mrg--bx2"
            />
            <Select
                value={source}
                name="source"
                isError={!source}
                onChange={handleChange}
                fullWidth
                className="u-mrg--bx2"
                variant="outlined"
                label="Customer Source"
                options={options.map(item => ({ value: item, label: item }))}
            />
            {
                source && (
                    <TextInputOutlined
                        value={customerIdentifier || ''}
                        isError={!customerIdentifier}
                        name="customerIdentifier"
                        variant="outlined"
                        label={source === 'email' ? 'Customer Email' : 'User identificator'}
                        onChange={handleChange}
                        fullWidth
                        maxLength={180}
                        className="u-mrg--bx2"
                    />
                )
            }
        </>
    );
};

export default withStyles(styles)(CustomerDetails);

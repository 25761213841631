import React from 'react';
import { withStyles } from '@mui/styles';

import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import { round } from 'helper/commonFunctions';
import { observer } from 'mobx-react-lite';
import Table from 'components/table_components/table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCellHead from 'components/table_components/table_cell_head';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCellCustom from 'components/table_components/table_cell';
import Button from '@mui/material/Button';

const styles = theme => ({
    cellHead: {
        '&:first-child, &:last-child': {
            paddingLeft: 16
        }
    },
    header: {
        fontSize: 18,
        marginBottom: 25,
        fontWeight: 700,
        color: theme.palette.grey[900]
    }
});

const InvoicesBlock = observer(({
    classes, invoices
}) => {
    const handleClickExportInvoice = (url) => {
        window.location.assign(url);
    };

    if (!invoices || !invoices.length) return null;

    return (
        <ContentWrapper className="u-pdn--bx6">
            <header className={classes.header}>Invoices</header>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellHead label="Date" className={classes.cellHead} hideSort />
                            <TableCellHead label="Status" hideSort />
                            <TableCellHead label="Product" hideSort />
                            <TableCellHead label="Invoice ID" hideSort />
                            <TableCellHead label="Amount*" className={classes.cellHead} hideSort />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            invoices.map((invoice) => {
                                const { paid, date, prices, id, currency, total, invoice_pdf } = invoice;
                                return (
                                    <TableRow key={id}>
                                        <TableCellCustom align="left">
                                            {date}
                                        </TableCellCustom>
                                        <TableCellCustom align="left">
                                            {paid ? 'Paid' : 'Pending'}
                                        </TableCellCustom>
                                        <TableCellCustom align="left">
                                            {prices}
                                        </TableCellCustom>
                                        <TableCellCustom align="left">
                                            <Button
                                                color="primary"
                                                role="presentation"
                                                className="u-txt--bold"
                                                rel="noopener noreferrer"
                                                onClick={() => { handleClickExportInvoice(invoice_pdf); }}
                                            >
                                                {id}
                                            </Button>
                                        </TableCellCustom>
                                        <TableCellCustom align="left">
                                            {currency} {round(total, 2)}
                                        </TableCellCustom>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </ContentWrapper>
    );
});

export default withStyles(styles)(InvoicesBlock);

export default {
    chip: {
        width: 32,
        height: 32,
        marginLeft: 8
    },
    chipTest: {
        width: 24,
        height: 24,
        display: 'block',
        marginRight: 16
    },
    testName: {
        display: 'inline-block',
        width: 140,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    chipTestExample: {
        width: 24,
        height: 24,
        display: 'block'
    },
    points: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        '& $stylePoints': {
            width: 65
        },
        '& > div': {
            display: 'none',
            position: 'absolute',
            transform: 'translate(0, -50%)',
            top: '50%',
            right: 22
        },
        '&:hover > div': {
            display: 'block'
        },
        '&:hover $stylePoints': {
            display: 'none'
        },
        '&:hover $chipTest': {
            marginRight: 111
        }
    },
    pointsEditNotAllow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    stylePoints: {
        display: 'inline-block',
        color: 'white',
        fontWeight: 'bold'
    },
    stylePointsError: {
        color: '#ff5e5e',
        fontWeight: 'normal'
    },
    stylePointsSuccess: {
        color: '#2fd1b8',
        fontWeight: 'normal'
    },
    loader: {
        width: 'auto',
        height: 'auto',
        left: 'auto',
        right: '20px',
        display: 'block',
        position: 'absolute',
        top: 11,
        color: 'white'
    }
};

import React from 'react';
import { observer } from 'mobx-react-lite';
import { withTheme } from '@mui/styles';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'libraries/Tooltip';
import CommentsIcon from 'components/icons/CommentsIcon';
import styles from './styles';


const CommentsButton = observer(({
    classes, theme, count, ...other
}) => (
    <Tooltip
        label={`Comments${count ? ` ${count}` : ''}`}
    >
        <IconButton className={classes.iconStar} {...other} size="large">
            <CommentsIcon color={count ? theme.palette.green[600] : theme.palette.grey[400]} />
        </IconButton>
    </Tooltip>
));

export default withStyles(styles)(withTheme(CommentsButton));

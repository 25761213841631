import React, { useContext } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import GeneralEvents from 'events/GeneralEvents';
import Popover from '@mui/material/Popover';
import { appCtx } from '../../../appStore';
import styles from '../styles';


const CreateNewAssessmentDropdown = observer(({
    light, buttonLabel = 'Create New Assessment', className = '', disabled, classes, fullLimit, allRequestsIsUsed
}) => {
    const { openTTBuilderDialog, openTTRequestDialog } = useContext(appCtx);
    const { button, popoverRoot, popoverPaper, popoverItem, disabledPopoverItem } = classes;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const openAssessmentBuilderModal = () => {
        localStorage.removeItem('assessmentName');
        setAnchorEl(null);
        openTTBuilderDialog();
        GeneralEvents.TB_INTENT({ context: 'create new assessment' });
    };

    const openAssessmentRequestModal = () => {
        setAnchorEl(null);
        openTTRequestDialog();
    };

    const renderMenuItems = () => {
        const OPTIONS = [
            {
                value: 'standard',
                label: 'New Standard Assessment',
                onClick: openAssessmentBuilderModal,
                disabledItem: fullLimit
            },
            {
                value: 'custom',
                label: 'Request Custom Assessment',
                onClick: openAssessmentRequestModal,
                disabledItem: allRequestsIsUsed
            }
        ];

        return OPTIONS.map(({ value, label = value, onClick, disabledItem }, index) => (
            <div className={clsx(popoverItem, disabledItem && disabledPopoverItem)} onClick={disabledItem ? null : onClick} role="button" tabIndex={index}>
                {label}
            </div>
        ));
    };

    return (
        <>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant={!light ? 'contained' : 'text'}
                color="primary"
                onClick={e => setAnchorEl(e.currentTarget)}
                className={clsx(button, className)}
                disabled={fullLimit && allRequestsIsUsed}
            >
                {buttonLabel}
            </Button>
            <Popover
                anchorEl={anchorEl}
                keepMounted
                open={open}
                classes={{ root: popoverRoot, paper: popoverPaper }}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {renderMenuItems()}
            </Popover>
        </>
    );
});

export default withRouter(withStyles(styles)(CreateNewAssessmentDropdown));

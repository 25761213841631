import React from 'react';
import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import SelectOutlined from 'libraries/Select';
import TextInputOutlined from 'libraries/TextInput';
import RemoveIcon from 'img/remove.svg';

import styles from './styles';

const MAX_PARAMETER_NAME_LENGTH = 250;

const ParameterRow = ({
    classes, index, typeOptions, disabled,
    parameter: { name, type }, setParameter, deleteParameter
}) => (
    <div className="u-dsp--f">
        <TextInputOutlined
            maxLength={MAX_PARAMETER_NAME_LENGTH}
            className={classes.nameInput}
            label="Parameter Name"
            value={name}
            variant="outlined"
            onChange={e => setParameter(index, e.target.value, type)}
            disabled={disabled}
        />
        <SelectOutlined
            label="Type"
            variant="outlined"
            value={type}
            options={typeOptions.map(option => ({ value: option, label: option }))}
            onChange={e => setParameter(index, name, e.target.value)}
            placeholder="Select an option"
            disabled={disabled}
        />
        <IconButton
            type="button"
            onClick={() => deleteParameter(index)}
            className={classes.removeIcon}
            disabled={disabled}
        >
            <img src={RemoveIcon} alt="x" />
        </IconButton>
    </div>
);

export default withStyles(styles)(ParameterRow);

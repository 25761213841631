import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: theme.palette.grey[100],
        minWidth: 350,
        width: 350,
        maxHeight: '100%',
        padding: '16px 16px 100px 16px',
        overflow: 'hidden auto'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14,
        fontWeight: 700
    },
    viewport: {
        height: 'auto'
    }
}));

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import styles from './styles';

const InnerTabs = ({ classes, history, tabList = [], className = '', location, loadAssessmentStats }) => {
    const handleChangeInnerTabs = (e, newValue) => {
        history.push(newValue);
        if (loadAssessmentStats) loadAssessmentStats();
    };

    return (
        <div className={clsx(classes.tabsWrapper, className)}>
            <Container className="u-pdn--x0">
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={location.pathname}
                    classes={{
                        root: classes.tabs,
                        indicator: classes.indicator,
                        scrollButtons: classes.scrollButtons,
                        flexContainer: classes.flexContainer
                    }}
                    onChange={handleChangeInnerTabs}
                >
                    {
                        tabList.map(({ label, url, disabled, className: tabClassName }, index) => (
                            <Tab
                                key={index}
                                label={label}
                                value={url}
                                disabled={disabled}
                                className={clsx(classes.tab, tabClassName)}
                            />
                        ))
                    }
                </Tabs>
            </Container>
        </div>
    );
};

export default withStyles(styles)(withRouter(InnerTabs));

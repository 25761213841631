import React from 'react';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import UploadLogo from 'pages/settings/branding_details/BrandingCard/UploadLogo';
import { SketchPicker } from 'react-color';
import withStyles from '@mui/styles/withStyles';
import { editBrandingColor } from 'requests/SettingsRequests';
import { CircularProgress } from '@mui/material';

const styles = {
    main: {
        fontWeight: 600,
        padding: '20px 0'
    }
};

let timerId = null;


const AddBrandingDetailsDialog = ({
    open, onClose,
    updatingBrandUuid,
    classes, setBrandings, brandings,
    isSaving, setSaving,
    flashMessage,
    assignBrandingToAudition
}) => {
    if (!updatingBrandUuid) return null;

    const currentBrand = brandings.find(({ uuid }) => uuid === updatingBrandUuid);

    const handleClose = () => {
        if (isSaving) return;
        assignBrandingToAudition(updatingBrandUuid);
        onClose();
    };

    const changeBrandingColor = (e) => {
        const newBrandings = brandings.map(brand => (brand.uuid === updatingBrandUuid ? { ...brand, color: e.hex } : brand));
        setBrandings(newBrandings);
        setSaving(true);
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            editBrandingColor(updatingBrandUuid, e.hex)
                .catch(() => {
                    flashMessage('Something went wrong', 'error');
                })
                .finally(() => {
                    setSaving(false);
                });
        }, 500);
    };

    return (
        <Dialog
            maxWidth="sm"
            onClose={handleClose}
            handleClose={handleClose}
            actionComponent={(
                <>
                    <Button
                        className="u-pdn--lx7 u-pdn--rx7 u-wdt--200"
                        color="primary"
                        variant="contained"
                        onClick={handleClose}
                        disabled={isSaving}
                    >
                        {isSaving ? <CircularProgress size={24} /> : <>Close</>}
                    </Button>
                </>
            )}
            titleComponent={`Edit branding ${currentBrand.title}`}
            open={open}
        >
            <div className={classes.main}>
                <UploadLogo
                    logoLink={currentBrand.logoUrl}
                    brandingSetUuid={updatingBrandUuid}
                    setBrandings={setBrandings}
                    brandings={brandings}
                    isSaving={isSaving}
                    setSaving={setSaving}
                />
                <div className="u-mrg--bx2">Pick your brand colour</div>
                <SketchPicker
                    color={currentBrand.color}
                    onChangeComplete={changeBrandingColor}
                    disableAlpha
                    width="97%"
                    className="u-mrg--tx2"
                    name="brandColor"
                    presetColors={[]}
                />
            </div>
        </Dialog>
    );
};

export default withStyles(styles)(AddBrandingDetailsDialog);

import React from 'react';
import { withTheme } from '@mui/styles';


const CloseIcon = ({ theme, size = 24, color = theme.palette.red[600], ...other }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...other}>
        <path fill={color} fillRule="evenodd" d="M10.94 12L3.97 5.03a.75.75 0 0 1 1.06-1.06L12 10.94l6.97-6.97a.75.75 0 0 1 1.06 1.06L13.06 12l6.97 6.97a.75.75 0 0 1-1.06 1.06L12 13.06l-6.97 6.97a.75.75 0 0 1-1.06-1.06L10.94 12z" />
    </svg>
);

export default withTheme(CloseIcon);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    phoneButton: {
        marginLeft: 24,
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        height: 50,
        padding: '12px 25px',
        '&:hover': {
            backgroundColor: theme.palette.grey[300],
            boxShadow: 'none'
        }
    },
    disabledPhoneButton: {
        color: theme.palette.grey[400]
    },
    deleteButton: {
        width: 35,
        height: 35,
        margin: 7
    },
    phoneHelperText: {
        fontSize: 10,
        fontWeight: 600,
        color: theme.palette.red[700],
        position: 'absolute',
        top: 54,
        left: 16
    },
    highlighted: {
        color: theme.palette.secondary.main,
        fontWeight: 700
    },
    link: {
        color: theme.palette.blue[500],
        fontWeight: 700
    },
    tooltipBox: {
        maxWidth: 380
    },
    tooltipText: {
        fontSize: 13
    },
    previewButton: {
        marginBottom: 7,
        textDecoration: 'underline',
        fontWeight: 700,
        cursor: 'pointer'
    },
    fieldWrapper: {
        flexGrow: 1,
        marginBottom: 15,
        maxWidth: '50%',
        '&:first-child': {
            marginRight: 24
        },
        '&:nth-child(3)': {
            marginLeft: 24
        },
        '& input': {
            height: '1.25em'
        }
    }
}));

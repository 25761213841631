import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { resendMfaEmailConfirmation, loginMfaUser } from 'requests/LoginRequests';
import { observer } from 'mobx-react-lite';
import { getBaseUrlForRedirect } from 'helper/commonFunctions';
import VerifyCodeForm from '../common/VerifyCodeForm';
import useTimeout from '../../../hooks/useTimeout';

const MfaCodeVerification = observer(({ location, flashMessage, history }) => {
    const [resendLoading, setResendLoading] = useState(false);
    const [incorrect, setIncorrect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');

    if (!location.state) return <Redirect to="/forbidden" />;

    const {
        enteredEmail, mfaToken, isPremium,
        emailConfirmationExpiresAfterMinutes,
        redirectAfterConfirmation
    } = location.state;

    useEffect(() => {
        setToken(mfaToken);
    }, [mfaToken]);

    const EXPIRE_TIME = emailConfirmationExpiresAfterMinutes * 60 * 1_000;

    const { restartWithTime, expired } = useTimeout(null, EXPIRE_TIME);

    const handleResendClick = () => {
        setResendLoading(true);
        resendMfaEmailConfirmation(token)
            .then((data) => {
                if (data) {
                    const { mfaToken: newToken } = data;
                    if (enteredEmail) {
                        flashMessage(`New code has been sent to ${enteredEmail}`, 'done');
                    }
                    setToken(newToken);
                    setIncorrect(false);
                    restartWithTime(EXPIRE_TIME);
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 403) history.push('/login');
            })
            .finally(() => {
                setResendLoading(false);
            });
    };

    const verify = (code, verifyCallback) => {
        setLoading(true);
        loginMfaUser({ token, mfaCode: code })
            .then(({ success, ...data }) => {
                if (success) {
                    if (data.redirection_url) {
                        window.location = data.redirection_url;
                    } else if (redirectAfterConfirmation) {
                        window.location = `${getBaseUrlForRedirect(data.companySlug)}${redirectAfterConfirmation}&token=${data.refreshToken}`;
                    } else {
                        verifyCallback(data);
                    }
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 403) history.push('/login');
                if (err.response && err.response.status === 418) {
                    flashMessage('Email confirmation code is incorrect');
                    setIncorrect(true);
                }
                if (err.response && err.response.data.errors === 'Email already confirmed') flashMessage(err.response.data.errors);
            })
            .finally(() => setLoading(false));
    };

    return (
        <VerifyCodeForm
            isPremium={isPremium}
            flashMessage={flashMessage}
            locationState={location.state}
            resendLoading={resendLoading}
            incorrect={incorrect}
            loading={loading}
            expired={expired}
            onResend={handleResendClick}
            onVerify={verify}
        />
    );
});

export default withRouter(MfaCodeVerification);

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';

import QuestionWrapperAssessmentPreview
from 'components/marketplace/Preview/common/QuestionWrapperAssessmentPreview';
import InterviewStepper from 'components/marketplace/Preview/SimplePreview/components/Stepper';
import QuestionHeader from 'components/marketplace/Preview/SimplePreview/components/QuestionHeader';
import { getPublicAssessmentPreviewData } from 'requests/AssessmentRequests';

import NotFoundComponent from 'components/not_found';
import DeactivatedIcon from 'img/deactivated.svg';
import PoweredBy from 'img/poweredBy';
import PreviewHeader from './PreviewHeader';
import styles from './styles';

const Preview = ({ classes, history, uuid }) => {
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDeactivated, setIsDeactivated] = useState(false);

    useEffect(() => {
        getPublicAssessmentPreviewData(uuid)
            .then(({ data, success }) => {
                if (!data || !data.length) {
                    setIsDeactivated(true);
                    return;
                }
                if (data && success) {
                    setQuestions(data);
                }
            })
            .catch(() => {
                history.push('/not-found');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [uuid]);

    if (isDeactivated) {
        return (
            <NotFoundComponent
                icon={DeactivatedIcon}
                mainText="The preview link not found."
                additionalText={(
                    <p className={classes.notFoundContent}>
                        The preview link for this assessment has been not found. Try our&nbsp;
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vervoe.zendesk.com/hc/en-us"
                            className={classes.link}
                        >
                             Help Centre
                        </a>
                    </p>
                )}
            />
        );
    }

    return (
        <Dialog
            scroll="body"
            open
            fullScreen
        >
            <PreviewHeader />
            {
                !loading && (
                    <>
                        <div className={classes.previewContentWrapper}>
                            <QuestionWrapperAssessmentPreview
                                brandColor="#0C58B6"
                                QuestionHeaderComp={QuestionHeader}
                                interview={{}}
                                questions={questions}
                                question={questions[selectedQuestionIndex]}
                                selectedQuestionIndex={selectedQuestionIndex}
                                setSelectedQuestionIndex={setSelectedQuestionIndex}
                                isPreviewPage
                                isPublicPreviewPage
                            />
                        </div>
                        <InterviewStepper
                            companyColor="#0C58B6"
                            questions={questions}
                            selectedQuestionIndex={selectedQuestionIndex}
                            setSelectedQuestionIndex={setSelectedQuestionIndex}
                        />
                    </>
                )
            }
            <div className={classes.poweredImgContainer}>
                <PoweredBy color="#0C58B6" />
            </div>
            {
                loading && (
                    <>
                        <div className={classes.loaderWrapper}>
                            <CircularProgress />
                        </div>
                    </>
                )
            }
        </Dialog>
    );
};

export default withRouter(withStyles(styles)(Preview));

import React from 'react';
import { withTheme } from '@mui/styles';


const FolderAddedIcon = ({ theme, color: propsColor, ...other }) => {
    const color = propsColor || theme.palette.grey[600];
    return (
        <svg width="22" height="22" viewBox="0 2 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M14.6666 6.41667L10.0833 6.41667M1.83325 12.4056L1.83325 13.985L1.83325 17.25C1.83325 18.3546 2.72869 19.25 3.83325 19.25L18.1666 19.25C19.2712 19.25 20.1666 18.3546 20.1666 17.25L20.1666 11.9167" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.83325 12.8333L1.83325 6.58331C1.83325 5.47874 2.72868 4.58331 3.83325 4.58331H8.24992L10.0833 6.41665" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.4583 11L13.3268 12.8333L21.0833 5.5" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default withTheme(FolderAddedIcon);

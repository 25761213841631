import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import PageWrapper from 'components/layout/PageWrapper';

import LoaderBounce from 'components/loaders/LoaderBounce';
import { getPublicCompany } from 'requests/APIrequests';
import CardFooter from 'components/candidate_cards/PublicCardContent/CardFooter';
import InnerContentWrapper from '../../assessment/components/InnerContentWrapper';
import GeneralPageFilter from '../components/GeneralPageFilter';
import TopStatisticBar from '../components/TopStatisticBar';
import { accountInsightsStoreCtx } from '../store';
import useStyles from '../styles';
import DataMetrics from '../components/DataMetrics';
import AssessmentsTable from '../components/AssessmentsTable';
import CompletionsData from '../components/CompletionsData';
import ScoreDistribution from '../components/ScoreDistribution';
import PublicHeader from '../components/PublicHeader';
import EeocDistribution from '../components/EeocDistribution';

const DISABLED_TOOLTIP_TEXT = 'You don’t have permission for this action. Please get in touch with the company admin.';

const AccountInsights = observer(({ match }) => {
    const [company, setCompany] = useState(null);
    const { linkUuid } = match.params;
    const { loadPublicData, loading } = useContext(accountInsightsStoreCtx);
    const classes = useStyles();

    useEffect(() => {
        getPublicCompany()
            .then(({ success, company: companyRsp }) => {
                if (success && companyRsp) {
                    setCompany(companyRsp);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        loadPublicData(linkUuid);
    }, []);

    return (
        <>
            <PageWrapper className="u-pdn--bx5">
                <InnerContentWrapper
                    className={classes.contentWrapper}
                    contentClassName="u-pdn--x0"
                >
                    {
                        loading
                            ? (
                                <div className={classes.loaderWrapper}>
                                    <LoaderBounce />
                                </div>
                            )
                            : (
                                <>
                                    <PublicHeader company={company} />
                                    <div className="u-dsp--f u-jc--sb u-ai--center">
                                        <div className={classes.headerTitle}>Account Insights</div>
                                        <GeneralPageFilter
                                            disabled={false}
                                            disabledTooltip={DISABLED_TOOLTIP_TEXT}
                                            isPublic
                                        />
                                    </div>
                                    <TopStatisticBar company={null} isPublic />
                                    <DataMetrics isPublic />
                                    <AssessmentsTable isPublic />
                                    <CompletionsData isPublic />
                                    <ScoreDistribution isPublic />
                                    <EeocDistribution isPublic />
                                    <CardFooter company={company} />
                                </>
                            )
                    }

                </InnerContentWrapper>
            </PageWrapper>
        </>
    );
});

export default withRouter(AccountInsights);

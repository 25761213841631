import { createContext } from 'react';
import { action, observable, autorun } from 'mobx';
import { assessmentQueryStore } from 'components/assessments_pages/AssessmentList/queryStore';
import { removePropertyFromObject } from '../../helper/commonFunctions';

const filterFormatName = {
    categories: 'Category: ',
    skills: 'Skill: ',
    levels: 'Level: ',
    sort: 'Sort By: ',
    query: ''
};
const defaultFilter = { sort: 'popular' };
const FILTER_DATA_KEY = 'library-filter';
const FILTER_HISTORY_KEY = 'library-filter-params';
const FILTER_KEY = {
    query: 'query',
    sort: 'sort',
    tag: 'tag',
    skill: 'skill',
    category: 'category'
};

class FilterStore {
    @observable filterData = defaultFilter;

    @observable filterHistory = {};

    constructor() {
        this.loadFilter();

        autorun(() => {
            this.saveFilterData(this.filterData);
            this.saveFilterHistory(this.filterHistory);
        });
    }

    @action setFilterData = (filterKey, value) => {
        this.filterData = { ...this.filterData };

        if (filterKey === FILTER_KEY.query) {
            this.filterData = { ...defaultFilter, query: value };
            return;
        }

        if (filterKey === FILTER_KEY.sort) {
            this.filterData[filterKey] = value;
            return;
        }

        if (filterKey === FILTER_KEY.tag) {
            this.filterData[filterKey] = value === this.filterData.tag ? '' : value;
            return;
        }

        if (!this.filterData.sort) {
            this.filterData.sort = 'popular';
        }

        if (!this.filterData[filterKey]) {
            this.filterData[filterKey] = [value];
            return;
        }

        const filterArray = this.filterData[filterKey];
        if (filterArray.includes(value)) {
            filterArray.splice(filterArray.indexOf(value), 1);
        } else {
            filterArray.push(value);
        }
        this.filterData[filterKey] = filterArray;
    }

    @action setFilterHistory = (filterKey, value) => {
        if (filterKey === FILTER_KEY.query) {
            this.filterHistory = { query: value };
            return;
        }

        const fullParam = filterFormatName[filterKey] + value;
        if (this.filterHistory[value]) {
            this.filterHistory = removePropertyFromObject(this.filterHistory, value);
            return;
        }

        this.filterHistory[value] = fullParam;
    }

    @action handleFilter = (filterKey, value, clearAllPrevious = false) => {
        if (clearAllPrevious) {
            this.clearFilter();
        }
        this.setFilterData(filterKey, value);
        if (filterKey === FILTER_KEY.sort || filterKey === FILTER_KEY.tag) return;
        this.setFilterHistory(filterKey, value);
    }

    @action deleteFromHistory = (value) => {
        if (this.filterHistory.query === value) {
            this.filterHistory = removePropertyFromObject(this.filterHistory, FILTER_KEY.query);
            this.filterData = removePropertyFromObject(this.filterData, FILTER_KEY.query);
            assessmentQueryStore.setQuery('');
            return;
        }

        const currentValue = value.split(':')[1].trim();

        const currentFilterData = { ...this.filterData };
        Object.keys(filterFormatName).forEach((key) => {
            if (currentFilterData[key] && currentFilterData[key].includes(currentValue)) {
                currentFilterData[key].splice(currentFilterData[key].indexOf(currentValue), 1);
            }
        });
        this.filterData = currentFilterData;
        this.filterHistory = this.filterHistory[currentValue.trim()]
            ? removePropertyFromObject(this.filterHistory, currentValue)
            : { ...this.filterHistory };
    }

    @action clearFilter = () => {
        this.filterHistory = {};
        this.filterData = defaultFilter;
        assessmentQueryStore.setQuery('');

        localStorage.removeItem(FILTER_DATA_KEY);
        localStorage.removeItem(FILTER_HISTORY_KEY);
    }

    @action removeFilterPropertyFromOutside = (filterKey, value) => {
        let savedFilterData = JSON.parse(localStorage.getItem(FILTER_DATA_KEY));
        if (savedFilterData[filterKey]?.includes(value)) {
            savedFilterData[filterKey].splice(savedFilterData[filterKey].indexOf(value), 1);
        } else if (filterKey === 'tag') {
            const { tag, ...rest } = savedFilterData;
            savedFilterData = rest;
        }
        localStorage.setItem(FILTER_DATA_KEY, JSON.stringify(savedFilterData));
        this.filterData = savedFilterData;

        const savedFilterHistory = JSON.parse(localStorage.getItem(FILTER_HISTORY_KEY));
        const newHistory = removePropertyFromObject(savedFilterHistory, value);
        localStorage.setItem(FILTER_HISTORY_KEY, JSON.stringify(newHistory));
        this.filterHistory = newHistory;
    }

    saveFilterData = (data) => {
        localStorage.setItem(FILTER_DATA_KEY, JSON.stringify(data));
    }

    saveFilterHistory = (data) => {
        localStorage.setItem(FILTER_HISTORY_KEY, JSON.stringify(data));
    }

    loadFilter = () => {
        const savedFilterData = localStorage.getItem(FILTER_DATA_KEY);
        const savedFilterHistory = localStorage.getItem(FILTER_HISTORY_KEY);

        this.filterData = savedFilterData ? JSON.parse(savedFilterData) : defaultFilter;
        this.filterHistory = savedFilterHistory ? JSON.parse(savedFilterHistory) : {};
    }
}

export const filterStore = new FilterStore();
export const FilterCtx = createContext(filterStore);

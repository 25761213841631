import React from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

import styles from './styles';

const InnerContentHeader = ({ classes, header, headerActions }) => (
    <div className={classes.wrapper}>
        <Typography variant="h4">
            {header}
        </Typography>
        <div className={classes.actionWrapper}>
            {headerActions}
        </div>
    </div>
);

export default withStyles(styles)(InnerContentHeader);

import React from 'react';
import { withStyles } from '@mui/styles';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import AssessmentEvents from 'events/AssessmentEvents';
import { modifyScreeningQuestion } from 'helper/questionSettingsFunctions';

import QuestionHeaderLeftPart from '../../../common/QuestionHeaderLeftPart';
import AddToAssessmentButton from '../../../common/AddToAssessmentButton';
import Delete from './delete.svg';
import styles from './styles';


const QuestionDisplayHeader = ({
    classes, question, activeQuestion, interview,
    deleteQuestionFromBank, replaceFunc,
    questionGroupName, updateQuestion,
    setActiveTab, selectQuestion, activeQuestionIndex,
    assessmentQuestions, loading, setLoading,
    assessmentHasTypingTest, onReplace, isScreening
}) => {
    const {
        id, type, answerType, required,
        exampleAnswers, timerEnabled = [],
        questionGroup: { title } = {}
    } = modifyScreeningQuestion(question);

    const { uuid, name } = interview;
    const skillName = questionGroupName || title;

    const segmentDataForDeleteFromBank = {
        skill: skillName,
        questionType: type,
        answerType,
        timerEnabled,
        required,
        correctAnswer: Boolean(exampleAnswers?.length)
    };

    const segmentData = {
        ttId: uuid,
        ttName: name,
        ...segmentDataForDeleteFromBank,
        questionNumber: activeQuestionIndex + 1,
        questionSuggested: false,
        questionSaved: true
    };

    return (
        <QuestionHeaderLeftPart
            question={{ ...question, questionGroup: { title: skillName } }}
            interview={interview}
            rightPart={(
                <>
                    <AddToAssessmentButton
                        segmentData={segmentData}
                        question={question}
                        updateQuestion={updateQuestion}
                        setActiveTab={setActiveTab}
                        questions={assessmentQuestions}
                        replaceFunc={replaceFunc}
                        selectQuestion={selectQuestion}
                        activeQuestionIndex={activeQuestionIndex}
                        activeQuestion={activeQuestion}
                        loading={loading}
                        setLoading={setLoading}
                        assessmentHasTypingTest={assessmentHasTypingTest}
                        onReplace={onReplace}
                    />
                    <BoardItemMenu
                        iconMenuClassName={classes.iconMenu}
                        options={[
                            {
                                label: (<><img className="u-mrg--rx1" src={Delete} alt="x" />Delete Question</>),
                                onClick: () => {
                                    deleteQuestionFromBank(id);
                                    if (isScreening) {
                                        AssessmentEvents.SCREENER_SAVED_QUESTION_DELETED(segmentDataForDeleteFromBank);
                                    } else {
                                        AssessmentEvents.SAVED_QUESTION_DELETED(segmentDataForDeleteFromBank);
                                    }
                                }
                            }
                        ]}
                    />
                </>
            )}
        />
    );
};

export default withStyles(styles)(QuestionDisplayHeader);

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '26px 46px 26px 26px',
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.grey[400],
        borderBottomStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    initialsCandidate: {
        borderRadius: '50%',
        width: 40,
        height: 40,
        minWidth: 40,
        textAlign: 'center',
        lineHeight: '40px',
        backgroundColor: theme.palette.blue[600],
        color: 'white',
        fontWeight: 700,
        marginRight: 16
    },
    agentLabel: {
        fontSize: 12,
        color: theme.palette.grey[600],
        fontWeight: 700
    },
    block: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 200,
        width: '50%'
    },
    openLabel: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.green[600]
    },
    idLabel: {
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.grey[900],
        display: 'box',
        lineClamp: 2,
        boxOrient: 'vertical',
        overflow: 'hidden'
    },
    agentName: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.grey[900],
        '& > img': {
            marginLeft: 10
        }
    },
    numberWrapper: {
        maxHeight: 93,
        maxWidth: '50%'
    }
}));

import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import AddIcon from './add.svg';
import FolderIcon from './folder.svg';
import FolderAddedIcon from './folderAdded.svg';
import CheckIcon from './check.svg';

import styles from './styles';


const SkillsOrTagsMenuItem = ({ classes, onClick, group: { title }, disabled, isActive, loadingName, className = '', folder }) => (
    <ListItem
        button
        disabled={Boolean(loadingName) || disabled}
        onClick={e => (disabled ? e.stopPropagation() : onClick(title))}
        className={clsx(classes.groupSkill, className, isActive && classes.groupSkillActive)}
    >
        {
            isActive
                ? <img src={folder ? FolderAddedIcon : CheckIcon} alt="done" />
                : <img src={folder ? FolderIcon : AddIcon} alt="add" />
        }
        <TruncateWithTooltip text={title} width={150} />
        {
            loadingName === title && (
                <CircularProgress
                    className={classes.loaderSkill}
                    size={15}
                    color="primary"
                />
            )
        }
    </ListItem>
);

export default withRouter(withStyles(styles)(SkillsOrTagsMenuItem));

export default {
    previewContentWrapper: {
        width: 750,
        margin: '0 auto',
        paddingBottom: 130,
        '@media only screen and (max-width: 767px)': {
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '20px'
        }
    },
    loaderWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: 40,
        height: 40
    },
    poweredImgContainer: {
        position: 'fixed',
        left: 40,
        bottom: 40,
        height: 28,
        '& svg': {
            height: '100%'
        }
    },
    contentWrapper: {
        margin: '120px auto 0'
    }
};

import React from 'react';
import { withTheme } from '@mui/styles';


const ExclamationIcon = ({ theme, size = 16, color: propsColor, ...other }) => {
    const color = propsColor || theme.palette.yellow[900];
    return (
        <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M27 52.5C41.0833 52.5 52.5 41.0833 52.5 27C52.5 12.9167 41.0833 1.5 27 1.5C12.9167 1.5 1.5 12.9167 1.5 27C1.5 41.0833 12.9167 52.5 27 52.5Z" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27 29.8334L27 12.8334" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <ellipse cx="27" cy="38.3333" rx="2.83333" ry="2.83333" fill={color} />
        </svg>
    );
};

export default withTheme(ExclamationIcon);

import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { createOnBoardingAudition } from 'requests/APIrequests';
import OnboardingEvents from 'events/OnboardingEvents';

import OnboardingAssessmentList from './onboarding_assessment_list';
import LockImg from './lock.svg';

const styles = theme => ({
    header: {
        maxWidth: 960,
        fontSize: 32,
        lineHeight: 1.4,
        textAlign: 'center',
        color: theme.palette.grey[900],
        fontWeight: 700,
        margin: '40px auto 20px'
    },
    assessmentsWrapper: {
        maxWidth: 1060,
        margin: 'auto',
        marginTop: 40
    },
    hiddenAssessmentsWrapper: {
        position: 'relative',
        marginTop: 50
    },
    hiddenAssessmentsBlock: {
        zIndex: 1000,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'center',
        background: 'linear-gradient(0deg, rgba(248, 248, 250, 0.9) 50.62%, rgba(248, 248, 250, 0.7) 179.97%)'
    },
    hiddenAssessmentsIcon: {
        marginTop: 60,
        marginBottom: 20
    }
});

const OnboardingAssessments = observer(({ classes, history }) => {
    const [selectLoadingId, setSelectLoadingId] = useState(null);
    const { loggedUser, updateCompany, company } = useContext(appCtx);

    const { first_name } = loggedUser;

    useEffect(() => {
        OnboardingEvents.LIBRARY_LOADED();
    }, []);

    const onSelect = (uuid) => {
        setSelectLoadingId(uuid);
        createOnBoardingAudition(uuid)
            .then(({ data, success }) => {
                if (data && success) {
                    updateCompany({ ...company, onboarding: data });
                    const { auditionName: ttName, auditionSlug: ttSlug, auditionUuid: ttUUID } = data;
                    OnboardingEvents.LIBRARY_TT_SELECTED({ ttSlug, ttName, ttUUID });
                    setSelectLoadingId(null);
                    history.push('/onboarding/start-assessment');
                }
            })
            .finally(() => {
                setSelectLoadingId(null);
            });
    };

    return (
        <div>
            <h1 className={`${classes.header} ${classes.registrationPageHeader}`}>
                <span role="img" aria-label="hi">👋</span> Hi {first_name}, <br />
                Welcome to Vervoe,<br />
                we’re ready to help you uncover skills at scale!<br />
                To get started, pick your favourite role from<br />
                the selection below and we’ll show you the Vervoe magic
            </h1>
            <div className={classes.assessmentsWrapper}>
                <OnboardingAssessmentList
                    selectLoadingId={selectLoadingId}
                    isOnBoarding
                    onSelect={onSelect}
                />
                <div className={classes.hiddenAssessmentsWrapper}>
                    <div className={classes.hiddenAssessmentsBlock}>
                        <img src={LockImg} className={classes.hiddenAssessmentsIcon} alt="lock" />
                        <p>See our full content library after your quick tour</p>
                    </div>
                    <OnboardingAssessmentList />
                </div>
            </div>
        </div>
    );
});

export default withRouter(withStyles(styles)(OnboardingAssessments));

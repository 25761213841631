import React, { useState } from 'react';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import IconButton from '@mui/material/IconButton';
import TableCell from 'components/table_components/table_cell';
import TableRow from '@mui/material/TableRow';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import CopyIcon from 'img/duplicate.svg';
import TrashIcon from 'img/trash.svg';
import EditIcon from 'img/edit.svg';
import Tooltip from 'libraries/Tooltip';
import DeleteLinkDialog from 'components/job_or_assessment_settings/ApplicationLink/DeleteLinkDialog';
import ApplicationLinkDialog from 'components/job_or_assessment_settings/ApplicationLinkDialog';
import SocialMediaIcons from 'components/icons/social_media_icons';
import useStyles from './styles';


const LinkRow = ({
    history, disabled, updateLink, audition,
    removeLink, onCopyLink, link, slug,
    flashMessage, location
}) => {
    const [deleteLinkOpened, setDeleteLinkOpened] = useState(false);
    const [listingLinksDialogOpen, setListingLinksDialogOpen] = useState(false);
    const classes = useStyles();
    const { url, name, source, statistics = {}, id = null, main } = link;
    const { applied, started, completed, lastApplied } = statistics;
    const isDisabled = disabled || (audition?.expired || !audition?.valid);


    const returnValue = (value, hidePercentage) => {
        if (value) return `${value}${hidePercentage ? '' : '%'}`;
        return '-';
    };

    const handleOpenDeleteLink = () => {
        setDeleteLinkOpened(!deleteLinkOpened);
    };

    const handleOpenListingLink = () => {
        setListingLinksDialogOpen(!listingLinksDialogOpen);
    };

    const goToSettings = () => {
        history.push({ pathname: `/script/settings/${slug}/invitation-preview`, state: { from: location.pathname } });
    };

    return (
        <>
            <TableRow className="">
                <TableCell align="left">
                    <div className="u-dsp--f u-ai--center">
                        <SocialMediaIcons active socialMedia={source} className="u-mrg--rx2" />
                        <TruncateWithTooltip text={name} width={500} />
                    </div>
                </TableCell>
                <TableCell align="right">{returnValue(applied, true)}</TableCell>
                <TableCell align="right">{returnValue(started)}</TableCell>
                <TableCell align="right">{returnValue(completed)}</TableCell>
                <TableCell align="right">
                    {
                        lastApplied ? (
                            <Tooltip
                                label={<Moment format="MMM D, YYYY">{lastApplied}</Moment>}
                            >
                                <span className={classes.statusDate}>
                                    <Moment fromNow>
                                        {lastApplied}
                                    </Moment>
                                </span>
                            </Tooltip>
                        ) : '-'
                    }
                </TableCell>

                <TableCell align="right">
                    {
                        isDisabled ? (
                            <Tooltip
                                label={disabled ? (
                                    <div>
                                        Please check <span onClick={goToSettings} className={classes.tooltipLink} role="button" tabIndex={0}>Invite Settings</span>
                                    </div>
                                ) : `Assessment ${audition?.expired ? 'expired' : 'invalid'}`}
                                TransitionProps={{ exit: false }}
                            >
                                <span>
                                    <IconButton
                                        variant="outlined"
                                        className={classes.copyLinkButton}
                                        disabled
                                    >
                                        <img src={CopyIcon} alt="Copy link" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        ) : (
                            <CopyToClipboard text={url} onCopy={() => onCopyLink({ name })}>
                                <Tooltip
                                    isBlack
                                    label="Copy link"
                                >
                                    <IconButton
                                        variant="outlined"
                                        className={classes.copyLinkButton}
                                    >
                                        <img src={CopyIcon} alt="Copy link" />
                                    </IconButton>
                                </Tooltip>
                            </CopyToClipboard>
                        )
                    }
                    <BoardItemMenu
                        iconMenuClassName={classes.menuIcon}
                        disabled={main || isDisabled}
                        options={[
                            {
                                label: <><img src={EditIcon} alt="|" /> Edit</>,
                                onClick: handleOpenListingLink
                            },
                            {
                                label: <><img src={TrashIcon} alt="x" /> Delete </>,
                                onClick: handleOpenDeleteLink
                            }
                        ]}
                    />
                </TableCell>
            </TableRow>
            <DeleteLinkDialog
                linkId={id}
                slug={slug}
                removeLink={(linkId, data) => removeLink(linkId, data, name)}
                open={deleteLinkOpened}
                onClose={handleOpenDeleteLink}
            />
            <ApplicationLinkDialog
                listingLinksDialogOpen={listingLinksDialogOpen}
                handleClose={handleOpenListingLink}
                formLink={link}
                updateLink={updateLink}
                slug={slug}
                flashMessage={flashMessage}
            />
        </>
    );
};

export default withRouter(LinkRow);

import React from 'react';
import { withTheme } from '@mui/styles';
import { getScoreColorByParam } from 'helper/commonFunctions';

import useStyles from './styles';

const SLICE_NUMBER = 5;


const Score = ({ candidate, theme }) => {
    const { circle, tagNumber } = useStyles();
    const { candidateId, score: scoreObj, rejected } = candidate;

    const renderColor = value => (rejected ? theme.palette.grey[400] : getScoreColorByParam(value));

    let scoreByFormat = [];

    if (scoreObj && scoreObj.scoreByFormat) {
        scoreByFormat = scoreObj.scoreByFormat;
    }

    return (
        <div
            data-tip
            data-for={scoreByFormat.length && `groups_${candidateId}`}
            className="u-dsp--f u-ai--center u-jc--start"
        >
            {
                Boolean(scoreByFormat.length) && (
                    <>
                        {
                            scoreByFormat.slice(0, SLICE_NUMBER).map(({ scoreColorRange, groupName }) => (
                                <div
                                    key={groupName}
                                    className={circle}
                                    style={{ backgroundColor: renderColor(scoreColorRange) }}
                                />
                            ))
                        }
                        {
                            scoreByFormat.length > SLICE_NUMBER && (
                                <div className={tagNumber}>+{scoreObj.scoreByFormat.length - SLICE_NUMBER}</div>
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

export default withTheme(Score);

import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import { secondsToTimeObject, timeObjectToFormattedString } from 'helper/commonFunctions';
import TimerIcon from 'img/timer.svg';

import stylesJS from './styles';

const QuestionTImerLabel = ({ classes, timeLimit }) => (
    <div className={classes.timerLabel}>
        <img src={TimerIcon} className={classes.timerIcon} alt="timer" />
        { timeObjectToFormattedString(secondsToTimeObject(timeLimit * 60)) }
    </div>
);

export default withTheme(withStyles(stylesJS)(QuestionTImerLabel));

export default theme => ({
    wrapper: {
        fontSize: 12,
        padding: '5px 12px',
        fontWeight: 700,
        borderRadius: 4,
        textTransform: 'capitalize',
        backgroundColor: theme.palette.grey[400],
        color: '#fff',
        maxHeight: 24,
        lineHeight: 1.1,
        display: 'inline-block'
    },
    wrapperDraft: {
        backgroundColor: theme.palette.yellow[800]
    },
    wrapperPublished: {
        backgroundColor: theme.palette.green[600]
    },
    loader: {
        color: '#fff'
    }
});

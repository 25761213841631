import React from 'react';
import { withTheme } from '@mui/styles';


const FolderEmptyIcon = ({ theme, color: propsColor, ...other }) => {
    const color = propsColor || theme.palette.grey[600];
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 13.5333L2 15.2564L2 19C2 20.1046 2.89543 21 4 21L20 21C21.1046 21 22 20.1046 22 19L22 9C22 7.89543 21.1046 7 20 7L13.7647 7L11 7" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 14L2 7C2 5.89543 2.89543 5 4 5H9L11 7" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 14H9" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 11V17" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default withTheme(FolderEmptyIcon);

export default theme => ({
    card: {
        minHeight: 411,
        borderRadius: 4,
        border: `2px solid ${theme.palette.grey[300]}`,
        color: theme.palette.grey[900],
        '& ::-webkit-scrollbar': {
            width: 4
        },
        '& ::-webkit-scrollbar-track': {
            background: theme.palette.grey[300],
            borderRadius: 2
        },
        '& ::-webkit-scrollbar-thumb': {
            background: theme.palette.grey[500],
            borderRadius: 2,
            '&:hover': {
                background: theme.palette.grey[600]
            }
        }
    },
    iconHeader: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {
        padding: '13px 13px 13px 17px'
    },
    wrapperBorder: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        height: 231
    },
    wrapperBorderPreviewCard: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    scoresTitle: {
        fontSize: 14,
        marginBottom: 12
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 17px',
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.grey[700]
    },
    footerText: {
        display: 'flex',
        alignItems: 'center'
    },
    footerDescription: {
        width: '67%'
    },
    additionalText: {
        margin: '0 3px',
        fontWeight: 400
    },
    dateLabel: {
        fontWeight: 600,
        color: theme.palette.grey[500],
        marginTop: 5
    },
    shortLister: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    scrolledContainer: {
        overflowY: 'auto',
        maxHeight: 173
    }
});

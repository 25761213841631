import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import AssessmentStats from 'pages/assessment/components/AssessmentStats';
import { AI_HEALTH_STATUS_HIGH } from 'helper/constants';

import CandidatesList from './CandidatesList';

const CandidatesListPage = observer(({
    statsArr, loading,
    allCandidates, totalCount,
    audition, isLoading, loadCandidates
}) => {
    useEffect(() => {
        loadCandidates();
    }, []);

    return (
        <div>
            <AssessmentStats
                statsArr={statsArr}
                audition={audition}
                loading={loading || isLoading}
                activeLearningStatistic={{
                    status: AI_HEALTH_STATUS_HIGH
                }}
                isOnboarding
            />
            <CandidatesList
                {
                ...{
                    totalCount,
                    allCandidates,
                    isLoading,
                    audition
                }}
            />
        </div>
    );
});

export default CandidatesListPage;

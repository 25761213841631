// version 1
import { appStore } from 'components/appStore';

export const verify = () => (window.analytics);

export const tracking = (name, obj) => {
    if (verify() && appStore.loggedUser) {
        identify(appStore.loggedUser.uuid, appStore.loggedUser.userSegmentTraits);
        window.analytics.track(name, obj);
    }
};

export const pagevisit = (name, obj = {}) => {
    if (verify()) window.analytics.page(name, obj);
};

export const identify = (uuid, traits = {}, options = {}) => {
    if (verify()) {
        window.analytics.identify(uuid, traits, options);
    }
};

export const group = (uuid, traits = {}, options = {}) => {
    if (verify()) {
        window.analytics.group(uuid, traits, options);
    }
};

export const reset = () => {
    if (verify()) {
        window.analytics.reset();
    }
};

import React from 'react';
import { withTheme } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import TableFilter from 'components/table_components/table_filter';

import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import { SINGLE_CHOICE_AUTO_GRADED } from 'helper/constants';

const ContentQuestionsTableFilter = observer(({ filters, options, handleChangeFilters }) => {
    const tagOptions = options
        .map(({ type, count }) => ({
            checked: filters[type],
            label: (
                Number(type) !== -1
                    ? getQuestionTypeInfo(type, (Icon, label, labelShort = label) => (
                        type === SINGLE_CHOICE_AUTO_GRADED
                            ? 'Single Choice'
                            : labelShort
                    ))
                    : 'No type'
            ),
            value: type,
            count
        }));

    const filterCount = Object.keys(filters).length;

    return (
        <TableFilter
            filterCount={filterCount}
            isFilterActive={Boolean(filterCount)}
            innerHeader="Question Type"
            options={tagOptions}
            showCountNumber
            handleChange={handleChangeFilters}
        />
    );
});

export default withTheme(ContentQuestionsTableFilter);

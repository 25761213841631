import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import CandidatesLimitBlock from './CandidatesLimitBlock';
import { assessmentSettingsCtx } from '../../store';
import PreviewInvitationEmailButton from './PreviewInvitationEmailButton';
import EmailAssessmentInviteForm from './EmailAssessmentInviteForm';
import ShareLinks from './ShareLinks';

const styles = {
    wrapper: {
        width: '100%',
        overflow: 'auto',
        padding: '0 0 40px',
        minHeight: 250
    }
};


const EmailInvitation = observer(({ classes }) => {
    const { audition } = useContext(assessmentSettingsCtx);
    const { slug: scriptSlug } = audition;

    if (audition && !audition.active) return <Redirect to={`/script/invite/${scriptSlug}/invite-candidates`} />;

    return (
        <div className={classes.wrapper}>
            <div className="u-dsp--distribute u-mrg--tx4">
                <Typography variant="h6">Email Invite</Typography>
                <PreviewInvitationEmailButton
                    audition={audition}
                    text="Preview/Edit invitation email"
                />
            </div>
            <CandidatesLimitBlock />
            <EmailAssessmentInviteForm />
            <ShareLinks />
        </div>
    );
});

export default withStyles(styles)(EmailInvitation);

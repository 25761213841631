import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import { getRatingScoreColor } from 'helper/commonFunctions';

import styles from './styles';

const RatingItem = ({ value, totalCount, classes }) => (
    <div className={classes.wrapper}>
        <div className={classes.label}>CSAT rating</div>
        <div className={classes.value}>
            <span style={{ color: getRatingScoreColor(value, totalCount) }}>{ (!totalCount || (!value && value !== 0)) ? '-' : value }</span>
            <span className={classes.valueLabel}>/ 5</span>
            <span
                role="presentation"
                className={classes.countLabel}
            >
                ({totalCount || 0})
            </span>
        </div>
    </div>
);

export default withTheme(withStyles(styles)(RatingItem));

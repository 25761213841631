import React, { useContext, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import QuestionWrapper from 'components/marketplace/Preview/common/QuestionWrapper';
import { replaceQuestionWithSuggestedQuestion, getSimulationQuestionList } from 'requests/QuestionsRequests';
import QuestionHeader from 'pages/assessment/edit_assessment/edit_questions/components/QuestionContent/SuggestedList/QuestionHeader';
import NoResultsBanner from 'pages/assessment/edit_assessment/edit_questions/components/QuestionContent/common/NoResultsBanner';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import styles from './styles';

const PAGE_STEP = 10;
let timedId = null;

const content = (Icon, text) => text;


const SimulationList = observer(({
    classes, audition, activeQuestionIndex, question,
    updateQuestion, setActiveTab, selectQuestion,
    questions: assessmentQuestions, assessmentHasTypingTest
}) => {
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [questionsList, setQuestionsList] = useState([]);
    const [loadingAddButton, setLoadingAddButton] = useState(false);
    const [questionGroup, setQuestionGroup] = useState({});
    const [loadingSaveButton, setLoadingSaveButton] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const { loadEditScript } = useContext(assessmentSettingsCtx);
    const { title: questionGroupName } = questionGroup;

    const { company } = useContext(appCtx);
    const { questionGroups = [] } = audition;
    const { answerType } = question;

    useEffect(() => {
        if (!questionGroups.length) return;
        if (question.questionGroup && questionGroups.some(({ id }) => id === question.questionGroup.id)) {
            setQuestionGroup(question.questionGroup);
        } else {
            setQuestionGroup(questionGroups[0]);
        }
    }, [questionGroups.length]);

    useEffect(() => {
        setLoadingQuestions(true);
        clearTimeout(timedId);
        timedId = setTimeout(() => { loadQuestionsListByGroup(); }, 400);
    }, [currentPage, answerType]);

    const loadQuestionsListByGroup = () => {
        setLoadingQuestions(true);
        getSimulationQuestionList({ type: answerType, page: currentPage, offset: PAGE_STEP })
            .then(({ success, data }) => {
                if (success && data) {
                    const { hasMorePages, items } = data;
                    setHasMore(hasMorePages);
                    setQuestionsList(currentPage === 1 ? items : questionsList.concat(items));
                }
            })
            .catch((error) => {
                if (error.response) {
                    const { status } = error.response;
                    if (status === 400 || status === 500) {
                        // TTBuilderEvents.PREVIEW_VIEW_FAILED();
                    }
                }
            })
            .finally(() => {
                setLoadingQuestions(false);
            });
    };

    const loadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const replaceFunc = newQuestion => replaceQuestionWithSuggestedQuestion(newQuestion.uuid, question.id, { questionGroupName });

    const hasFeature = checkHasCompanyFeature(company, 'UNLIMITED_QUESTIONS_PER_SWAP');

    return (
        <div className={classes.wrapper}>
            <div className={classes.previewContentWrapper}>
                {!loadingQuestions && !questionsList.length && (
                    <NoResultsBanner
                        header={`We couldn't find any questions for ${getQuestionTypeInfo(answerType, content)} type`}
                    />
                )}
                {
                    Boolean(questionsList.length) && (
                        <QuestionWrapper
                            QuestionHeaderComp={QuestionHeader}
                            isSimulationList
                            replaceFunc={replaceFunc}
                            questions={questionsList}
                            interview={audition}
                            setActiveTab={setActiveTab}
                            selectQuestion={selectQuestion}
                            updateQuestion={updateQuestion}
                            activeQuestion={question}
                            assessmentQuestions={assessmentQuestions}
                            activeQuestionIndex={activeQuestionIndex}
                            loadingAddButton={loadingAddButton}
                            setLoadingAddButton={setLoadingAddButton}
                            loadingSaveButton={loadingSaveButton}
                            setLoadingSaveButton={setLoadingSaveButton}
                            assessmentHasTypingTest={assessmentHasTypingTest}
                            onReplace={loadEditScript}
                        />
                    )}
                {
                    Boolean(hasFeature && hasMore && questionsList.length) && (
                        <div className="u-txt--center u-mrg--tx1 u-mrg--bx2">
                            <Button
                                color="primary"
                                onClick={loadMore}
                                disabled={loadingQuestions}
                                variant="contained"
                            >
                                Load more questions
                            </Button>
                        </div>
                    )
                }
                <div className="u-txt--center u-mrg--bx2 u-mrg--tx2">
                    {
                        loadingQuestions && (
                            <CircularProgress
                                size={22}
                                thickness={3}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
});

export default withStyles(styles)(SimulationList);

import React from 'react';
import { withStyles } from '@mui/styles';
import { Controlled as CodeMirror } from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';

const codeMirrorOptions = {
    lineNumbers: true,
    theme: 'material',
    mode: 'javascript'
};

const styles = {
    wrapper: {
        height: 355,
        borderRadius: 6,
        overflow: 'hidden',
        display: 'inline-grid',
        width: '100%'
    },
    divWrapper: {
        width: '100%'
    }
};

class CodeEditorQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    render() {
        const { classes: { wrapper, divWrapper }, onChange } = this.props;
        const { value: stateValue } = this.state;
        return (
            <div className={divWrapper}>
                <CodeMirror
                    className={wrapper}
                    value={stateValue}
                    options={codeMirrorOptions}
                    onBeforeChange={onChange ? (editor, data, value) => {
                        this.setState({ value });
                    } : undefined}
                    onChange={onChange ? (editor, data, value) => {
                        onChange(editor, value);
                    } : undefined}
                />
            </div>
        );
    }
}

export default withStyles(styles)(CodeEditorQuestion);

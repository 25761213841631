import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    wrapper: {
        fontSize: 14,
        color: '#3A3C43',
        letterSpacing: '0.1px',
        whiteSpace: 'nowrap'
    }
}));

const CandidatesCount = ({ page, candidatesLength, candidatesFound, candidatesPerPage, className, label = 'Candidates' }) => {
    const { wrapper } = useStyles();

    const getCurrentCandidatesRange = () => {
        if (candidatesFound === -1) {
            return 0;
        }
        if (candidatesLength <= candidatesPerPage) return candidatesFound;
        const startIndex = (page - 1) * candidatesPerPage + 1;
        const lastIndex = startIndex + candidatesFound - 1;
        if (lastIndex === 0) return 0;
        return `${startIndex}-${lastIndex}`;
    };

    const getAllCandidatesNumber = () => {
        if (candidatesLength === -1) {
            return 0;
        }
        return candidatesLength;
    };

    return (
        <div className={clsx(wrapper, className)}>
            Showing <b>{ getCurrentCandidatesRange() }</b> of <b>{ getAllCandidatesNumber() }</b> {label}
        </div>
    );
};

export default CandidatesCount;

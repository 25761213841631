import React from 'react';

// material components
import { withStyles } from '@mui/styles';
import { changeSimulatorTicketResponse } from 'requests/QuestionsRequests';
import Button from '@mui/material/Button';
import AddIcon from 'img/add.svg';
import Typography from '@mui/material/Typography';

import AnswerItem from './AnswerItem';
import styles from './styles';

let timerId = null;

const AnswersList = ({
    classes, uploadingFiles,
    flashMessage, setSaving, setUploadingFiles,
    isCMS, companyQuestionCsSimulatorAnswers = [],
    addResponse, removeResponse, ticket, setTicket
}) => {
    const handleChangeResponse = (index, name, value) => {
        const { id: ticketId } = companyQuestionCsSimulatorAnswers[index];
        clearTimeout(timerId);
        setSaving(true);
        timerId = setTimeout(() => {
            changeSimulatorTicketResponse(ticketId, { [name]: value }, isCMS)
                .then(({ data, success }) => {
                    if (data && success) {
                        const newCompanyQuestionCsSimulatorAnswers = companyQuestionCsSimulatorAnswers.slice();
                        newCompanyQuestionCsSimulatorAnswers[index].valid = data.valid;
                        setTicket({
                            ...ticket,
                            companyQuestionCsSimulatorAnswers: newCompanyQuestionCsSimulatorAnswers
                        });
                    }
                    setSaving(false);
                })
                .catch((err) => {
                    if (err.response.data && err.response.data.msg && err.response.status === 422) {
                        flashMessage(err.response.data.msg, 'error');
                    }
                    setSaving(false);
                });
        }, 500);
    };

    const validAnswersCount = companyQuestionCsSimulatorAnswers.reduce((accumulator, { valid }) => accumulator + (valid ? 1 : 0), 0);

    return (
        <>
            <Typography variant="h6" className="u-mrg--bx2">Responses</Typography>
            { !companyQuestionCsSimulatorAnswers.length && <p className={classes.noTicketsLabel}>No ticket responses created yet</p> }
            {
                companyQuestionCsSimulatorAnswers.map((answerItem, index) => (
                    <AnswerItem
                        key={answerItem.id}
                        {...{
                            answerItem,
                            uploadingFiles,
                            flashMessage,
                            setUploadingFiles,
                            isCMS,
                            companyQuestionCsSimulatorAnswers,
                            handleChangeResponse,
                            removeResponse,
                            index
                        }}
                    />
                ))
            }
            <div className="u-mrg--bx6">
                <Button
                    onClick={addResponse}
                    id="qa-add-mlc-ans"
                    color="primary"
                    className="u-txt--bold u-mrg--bx2"
                >
                    <img src={AddIcon} alt="x" />&nbsp; Add Choice
                </Button>
                {
                    validAnswersCount < 2 && (
                        <Typography
                            variant="h6"
                            color="secondary"
                        >
                            Please add {validAnswersCount ? 'more ' : ''}valid ticket responses
                        </Typography>
                    )
                }
            </div>
        </>
    );
};

export default withStyles(styles)(AnswersList);

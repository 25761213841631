export default () => ({
    replyLabel: {
        fontSize: 16,
        marginBottom: 20
    },
    dialogActions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    saveLoader: {
        color: '#fff'
    }
});

export default theme => ({
    wrapper: {
        position: 'relative',
        backgroundColor: 'white',
        marginTop: 32,
        marginBottom: 36,
        width: '100%',
        padding: 30,
        fontSize: 14,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 4
    },
    contentWrapper: {
        paddingBottom: 48
    },
    footerWrapper: {
        paddingTop: 20,
        borderTop: '1px solid #F3F3F3'
    }
});

export default theme => ({
    tag: {
        position: 'relative',
        backgroundColor: theme.palette.grey[300],
        display: 'inline-block',
        margin: '4px 10px 4px 0',
        fontSize: 11,
        textTransform: 'capitalize',
        color: theme.palette.grey[900],
        fontWeight: 700,
        borderRadius: 4,
        padding: '6px 30px 5px 15px'
    },
    closeButton: {
        padding: 0,
        position: 'absolute',
        right: 9,
        top: 8
    },
    closeIcon: {
        width: 13,
        height: 13,
        color: theme.palette.grey[500]
    }
});

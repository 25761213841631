import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import { objectLength } from 'helper/commonFunctions';
import styles from './styles';

const FilterPanel = ({ classes, filterPanelData, deleteFromHistory }) => {
    if (!objectLength(filterPanelData)) return null;

    return (
        <div className={classes.root}>
            <span className={classes.title}>SHOWING RESULTS:</span>
            {Object.values(filterPanelData).map(filterParam => (
                <div className={clsx('u-dsp--f', 'u-pos--relative', classes.filterParam)}>
                    <span>{filterParam}</span>
                    <IconButton
                        className={classes.closeButton}
                        onClick={() => deleteFromHistory(filterParam)}
                        size="large"
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </div>
            ))}
        </div>
    );
};

export default withStyles(styles)(FilterPanel);

import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    feedbackWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 700,
        border: '1px solid #EBECEE',
        backgroundColor: '#fff',
        borderRadius: 4,
        margin: '0 auto',
        maxWidth: 720,
        padding: 120,
        '& div': {
            textAlign: 'center',
            paddingTop: 25
        },
        [theme.breakpoints.down('md')]: {
            padding: 20
        }
    }
}));

const FeedbackConfirmed = () => {
    const classes = useStyles();

    return (
        <div className={classes.feedbackWrapper}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M26.1756 37.8498L41.1505 21.9536C41.8902 21.1684 43.1362 21.1224 43.9335 21.8509C44.7308 22.5794 44.7775 23.8065 44.0378 24.5917L27.5949 42.0462C26.8068 42.8828 25.4593 42.8717 24.6855 42.0223L19.4669 36.2938C18.7406 35.4965 18.808 34.2704 19.6176 33.5551C20.4271 32.8398 21.6721 32.9063 22.3984 33.7035L26.1756 37.8498Z" fill="#1247F6" />
                <circle cx="32" cy="32" r="30.5" stroke="#1247F6" strokeWidth="3" />
            </svg>
            <div>Thank you, your candidate survey<br /> has been submitted</div>
        </div>
    );
};

export default FeedbackConfirmed;

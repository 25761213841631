import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';

import styles from './styles';

const PlanFooterMessage = observer(({ classes }) => {
    const { company } = useContext(appCtx);

    return (
        <div className={classes.wrapper}>
            <div className={classes.label}>
                Prices are in { company.currencyStripe === 'usd' ? 'US' : 'AU' } dollars and exclude any applicable taxes.
            </div>
        </div>
    );
});

export default withStyles(styles)(PlanFooterMessage);

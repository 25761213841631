import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import TooltipWhite from 'libraries/TooltipWhite';
import Tooltip from 'libraries/Tooltip';
import InfoIcon from 'components/icons/InfoIcon';

const styles = {
    iconWrapper: {
        margin: '0 7px -3px'
    },
    tooltipClass: {
        maxWidth: 255
    }
};

const InfoTooltip = ({
    id, text, iconColor, Icon = InfoIcon,
    isBlack, classes, classNameWrapper = '',
    classNameIcon = '', className = '', ...other
}) => {
    if (isBlack) {
        return (
            <Tooltip
                label={text}
                tooltipClass={clsx(classes.tooltipClass, className)}
                {...other}
            >
                <span className={classNameWrapper}>
                    <Icon
                        id={id}
                        color={iconColor}
                        className={clsx(classes.iconWrapper, classNameIcon)}
                    />
                </span>
            </Tooltip>
        );
    }

    return (
        <>
            <Icon
                color={iconColor}
                data-tip
                data-for={id}
                className={clsx(classes.iconWrapper, classNameIcon)}
            />
            <TooltipWhite
                id={id}
                className={className}
                {...other}
            >
                {text}
            </TooltipWhite>
        </>
    );
};

export default withStyles(styles)(InfoTooltip);

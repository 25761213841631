import React from 'react';
import Typography from '@mui/material/Typography';
import Icon from 'img/emptyContentWithPlus.svg';

const EmptyDuplicatesList = () => (
    <div className="u-txt--center u-mrg--tx4">
        <img src={Icon} className="u-mrg--bx2" alt="+" />
        <Typography className="u-mrg--bx1" variant="h4">Select a question</Typography>
    </div>
);

export default EmptyDuplicatesList;

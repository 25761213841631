import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles, withTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {
    getAuditionQuestions, removeSetFromAssessment,
    reorderCompanyQuestion
} from 'requests/CMSRequests';

import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import ShieldIcon from 'components/icons/ShieldIcon';

import QuestionsList from '../../../common/QuestionsList';
import EmptyQuestionsList from '../../../common/EmptyQuestionsList';

import SetsActions from './SetsActions';
import QuestionIcon from './question-icon.svg';
import styles from './styles';

const PAGE_STEP = 12;
let timerId = null;


const QuestionsListWrapper = observer(({
    openDrawer, selectedSet, classes, theme, setSaving,
    match, setSelectedQuestionId, getAssessment, handleOpenDrawer,
    setAddToSelectedSkill, setSelectedSet, toggleSetDrawer,
    audition, numberOfSets, setAudition, selectedQuestionId, ...other
}) => {
    const { auditionUuid } = match.params;
    const [questionsData, setQuestionsData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const [onRemoveLoading, setOnRemoveLoading] = useState(false);

    const { flashMessage } = useContext(appCtx);
    const { status, synchronized } = audition;

    useEffect(() => {
        if (!openDrawer && questionsData.items) {
            const index = questionsData.items.findIndex(({ id }) => id === selectedQuestionId);
            if (index === -1) {
                setCurrentPage(1);
                loadQuestions(1);
            } else loadQuestions(Math.floor(index / PAGE_STEP) + 1);
        }
    }, [openDrawer]);

    useEffect(() => {
        loadQuestions();
    }, [currentPage]);

    useEffect(() => {
        setQuestionsData({});
        setCurrentPage(1);
        loadQuestions(1);
    }, [selectedSet, status, synchronized]);

    const loadQuestions = (page = currentPage) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            setLoadingQuestions(true);
            getAuditionQuestions(auditionUuid, { page, companySet: selectedSet ? selectedSet.uuid : undefined })
                .then(({ success, data }) => {
                    if (success && data) {
                        let newItems = data.items;
                        if (page !== 1) {
                            const slicedQuestions = questionsData.items.slice(0, PAGE_STEP * (page - 1));
                            newItems = [...slicedQuestions, ...data.items];
                        }
                        setQuestionsData({ ...data, items: newItems });
                    }
                })
                .catch(({ response }) => {
                    if (response && response.data && response.data.msg) {
                        const { data: { msg } } = response;
                        flashMessage(msg, 'error');
                    }
                })
                .finally(() => {
                    setLoadingQuestions(false);
                });
        }, 300);
    };

    const handleNextButtonClick = () => {
        const maxNumberOfPage = Math.ceil(questionsData.total / PAGE_STEP);
        if (!questionsData.hasMorePages || currentPage + 1 > maxNumberOfPage) return;
        setQuestionsData({ ...questionsData, hasMorePages: false });
        setCurrentPage(currentPage + 1);
    };


    const onAdd = () => {
        setAddToSelectedSkill(true);
        handleOpenDrawer();
    };

    const onRemove = () => {
        setOnRemoveLoading(true);
        removeSetFromAssessment(auditionUuid, selectedSet.uuid)
            .then(() => {
                getAssessment();
                setSelectedSet(null);
            })
            .finally(() => {
                setOnRemoveLoading(false);
            });
    };

    const updateQuestions = (questions) => {
        setQuestionsData({ ...questionsData, items: questions });
    };

    const saveQuestionsSort = (question, order) => {
        setSaving(true);
        reorderCompanyQuestion(selectedSet.uuid, question.uuid, { order })
            .then(() => {
                setAudition({ ...audition, synchronized: false });
                flashMessage('Question reordered', 'done');
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const questions = questionsData.items;

    return (
        <>
            <div className={clsx(classes.header, 'u-dsp--distribute')}>
                <Typography className="u-dsp--f u-ai--center u-txt--lh1" variant="h4">
                    {
                        selectedSet ? (
                            <>
                                <ShieldIcon
                                    className={clsx('u-mrg--rx1', classes.skillIcon)}
                                    color={theme.palette.primary.main}
                                />
                                {selectedSet.companySkill.title}
                            </>
                        ) : (
                            <>
                                <img src={QuestionIcon} alt="question" className="u-mrg--rx2" />
                                All Questions
                            </>
                        )
                    }
                </Typography>
                <SetsActions
                    onAdd={onAdd}
                    onRemove={onRemove}
                    hasSet={Boolean(selectedSet)}
                    onRemoveLoading={onRemoveLoading}
                    toggleSetDrawer={toggleSetDrawer}
                    numberOfSets={numberOfSets}
                />
            </div>
            <div className={classes.questionsWrapper}>
                <QuestionsList
                    hideDuplicate
                    assessmentPage
                    threshold={0}
                    hideAdd={!selectedSet}
                    hideReorder={!selectedSet}
                    loading={loadingQuestions}
                    selectedSkill={selectedSet ? selectedSet.companySkill : null}
                    questions={questions}
                    hasMorePages={questionsData.hasMorePages}
                    loadMore={handleNextButtonClick}
                    loadQuestions={loadQuestions}
                    selectedSet={selectedSet}
                    setSelectedQuestionId={setSelectedQuestionId}
                    updateQuestions={updateQuestions}
                    saveQuestionsSort={saveQuestionsSort}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    audition={audition}
                    {...other}
                />
                {
                    !loadingQuestions && (!questions || !questions.length) && (
                        <EmptyQuestionsList
                            hasSet={Boolean(selectedSet)}
                            onClick={toggleSetDrawer}
                        />
                    )
                }
            </div>
        </>
    );
});

export default withRouter(withStyles(styles)(withTheme(QuestionsListWrapper)));

import React from 'react';
import { withTheme } from '@mui/styles';

const TimerIcon = ({ theme, style = {}, className = '', color = theme.palette.primary.main, width = 16, height = 16 }) => (
    <svg style={style} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
        <path fill={color} fillRule="evenodd" d="M5.387 5.502A4.475 4.475 0 0 1 7.5 4.694V3.667H7a.5.5 0 0 1 0-1h2a.5.5 0 1 1 0 1h-.5v1.027c.732.081 1.411.338 1.994.727l.608-.608a.5.5 0 0 1 .707.707l-.547.547a4.5 4.5 0 1 1-6.62.104l-.65-.65a.5.5 0 1 1 .706-.708l.69.69zM8 12.667a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zm.367-3.16a.5.5 0 0 1-.734-.68l1.387-1.5a.5.5 0 1 1 .734.68l-1.387 1.5z" />
    </svg>
);

export default withTheme(TimerIcon);

export default theme => ({
    form: {
        paddingBottom: '100px'
    },
    error: {
        fontSize: '12px',
        marginLeft: 0,
        marginRight: 0
    },
    btnSave: {
        width: '160px'
    },
    checked: {
        '& + $bar': {
            opacity: '1 !important',
            backgroundColor: `${theme.palette.primary.main} !important`
        }
    },
    bar: {
        width: '34px',
        height: '18px',
        borderRadius: '9px',
        backgroundColor: '#b1b1b1'
    },
    icon: {
        width: '16px',
        height: '16px',
        backgroundColor: 'white',
        marginTop: '4px',
        marginRight: '2px'
    },
    btnDelete: {
        width: 160,
        height: 40,
        borderRadius: 3,
        boxShadow: '0 3px 15px 0 rgba(240, 46, 46, 0.4)',
        background: '#ff1111',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#fff',
        marginLeft: 20,
        textTransform: 'none',
        '&:hover': {
            background: 'rgba(255,27,27,0.7) !important'
        }
    },
    btnCancelDelete: {
        width: 160,
        height: 40,
        borderRadius: 3,
        background: 'transparent',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#1a1b1e',
        textTransform: 'none'
    },
    titleDelete: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 35,
        '&>h2': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 'bold',
            fontSize: '24px',
            color: '#1a1b1e'
        }
    },
    btnAdd: {
        textTransform: 'none'
    },
    btnNoGraded: {
        textTransform: 'none'
    },
    btnNoGradeLabel: {
        fontSize: 14,
        color: '#1a1b1e',
        fontWeight: 'bold',
        textTransform: 'none'
    },
    rightSide: {
        width: 470,
        '& h2': {
            fontSize: 16,
            color: '#4a4a4a',
            fontWeight: 'bold',
            margin: 0
        },
        '& p': {
            fontSize: 16,
            color: '#4a4a4a'
        }
    },
    btnAddDefaultCode: {
        fontSize: 16,
        fontWeight: 'bold',
        textDecoration: 'underline',
        textTransform: 'none',
        padding: 0,
        minHeight: 'auto',
        lineHeight: '16px'
    },
    selectedTab: {
        background: '#2f3545',
        color: 'white !important'
    },
    labelContainer: {
        padding: 0
    },
    tab: {
        boxSizing: 'border-box',
        fontSize: '12px',
        fontWeight: 600,
        color: '#676a78',
        height: '100%',
        minHeight: 'auto',
        minWidth: 'auto',
        width: '50%'
    },
    tabs: {
        width: '250px',
        height: '36px',
        border: '2px solid #2f3545',
        borderRadius: '3px',
        minHeight: 'auto',
        marginTop: 10
    },
    flexContainer: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'
    },
    indicator: {
        display: 'none'
    },
    visibleScrollDialog: {
        overflowY: 'visible'
    },
    tooltipClass: {
        boxShadow: '0 10px 50px 0 rgba(0, 0, 0, 0.25)',
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1.5,
        padding: 20
    },
    content: {
        padding: '20px 35px',
        overflow: 'auto'
    },
    footer: {
        background: theme.palette.grey[200],
        height: 80,
        width: '100%',
        maxWidth: 520,
        padding: 20,
        display: 'flex',
        justifyContent: 'space-between',
        '& *': {
            fontWeight: 700
        }
    },
    disabledButton: {
        width: 160,
        backgroundColor: `${theme.palette.grey[400]}!important`,
        boxShadow: 'none!important',
        cursor: 'default'
    }
});

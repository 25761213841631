export default theme => ({
    wrapper: {
        borderWidth: 1
    },
    searchField: {
        background: theme.palette.grey[200],
        border: `2px solid ${theme.palette.grey[200]}`,
        borderRadius: 4,
        margin: 0,
        minWidth: 370,
        color: theme.palette.grey[900]
    },
    searchFieldFocused: {
        background: '#fff',
        border: `2px solid ${theme.palette.primary.main}`
    },
    paper: {
        position: 'absolute',
        top: 'calc(100% + 11px)',
        left: 0,
        width: '100%',
        minHeight: 260,
        zIndex: 100,
        boxShadow: '0px 0px 15px rgba(41, 42, 45, 0.1)'
    },
    paperWithOptions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    paperLoading: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    block: {
        width: '100%',
        textAlign: 'left',
        padding: '20px 0',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    header: {
        padding: '0 22px',
        fontWeight: 600,
        marginBottom: 10,
        color: theme.palette.grey[500]
    },
    listItem: {
        padding: '5px 22px',
        fontSize: 13,
        cursor: 'pointer',
        marginBottom: 0,
        color: theme.palette.grey[900],
        textTransform: 'capitalize',
        whiteSpace: 'pre-wrap',
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    }
});

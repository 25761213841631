import React from 'react';


const InfoIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.99844 28.5432C5.22524 28.5432 4.59844 27.9164 4.59844 27.1432V4.66697C4.59844 3.89378 5.22524 3.26697 5.99844 3.26697H23.3318C24.105 3.26697 24.7318 3.89377 24.7318 4.66697V16.667C24.7318 16.9983 25.0004 17.267 25.3318 17.267C25.6631 17.267 25.9318 16.9983 25.9318 16.667V4.66697C25.9318 3.23103 24.7677 2.06697 23.3318 2.06697H5.99844C4.5625 2.06697 3.39844 3.23103 3.39844 4.66697V27.1432C3.39844 28.5791 4.5625 29.7432 5.99844 29.7432H15.9984C16.3298 29.7432 16.5984 29.4745 16.5984 29.1432C16.5984 28.8118 16.3298 28.5432 15.9984 28.5432H5.99844Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.73047 9.96816C8.73047 9.63679 8.9991 9.36816 9.33047 9.36816H19.9971C20.3285 9.36816 20.5971 9.63679 20.5971 9.96816C20.5971 10.2995 20.3285 10.5682 19.9971 10.5682H9.33047C8.9991 10.5682 8.73047 10.2995 8.73047 9.96816Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.73047 20.1996C8.73047 19.8682 8.9991 19.5996 9.33047 19.5996H14.6638C14.9952 19.5996 15.2638 19.8682 15.2638 20.1996C15.2638 20.531 14.9952 20.7996 14.6638 20.7996H9.33047C8.9991 20.7996 8.73047 20.531 8.73047 20.1996Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.73047 15.0844C8.73047 14.753 8.9991 14.4844 9.33047 14.4844H19.9971C20.3285 14.4844 20.5971 14.753 20.5971 15.0844C20.5971 15.4157 20.3285 15.6844 19.9971 15.6844H9.33047C8.9991 15.6844 8.73047 15.4157 8.73047 15.0844Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.0003 30.0666C26.6144 30.0666 28.7336 27.9474 28.7336 25.3333C28.7336 22.7191 26.6144 20.5999 24.0003 20.5999C21.3861 20.5999 19.2669 22.7191 19.2669 25.3333C19.2669 27.9474 21.3861 30.0666 24.0003 30.0666ZM29.9336 25.3333C29.9336 28.6102 27.2771 31.2666 24.0003 31.2666C20.7234 31.2666 18.0669 28.6102 18.0669 25.3333C18.0669 22.0564 20.7234 19.3999 24.0003 19.3999C27.2772 19.3999 29.9336 22.0564 29.9336 25.3333Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M24 28.5C23.7239 28.5 23.5 28.2761 23.5 28L23.5 25.3333C23.5 25.0572 23.7239 24.8333 24 24.8333C24.2761 24.8333 24.5 25.0572 24.5 25.3333L24.5 28C24.5 28.2761 24.2761 28.5 24 28.5Z" fill={color} />
        <path d="M23.2003 23.1333C23.2003 22.6547 23.5883 22.2667 24.0669 22.2667C24.5456 22.2667 24.9336 22.6547 24.9336 23.1333C24.9336 23.612 24.5456 24 24.0669 24C23.5883 24 23.2003 23.612 23.2003 23.1333Z" fill={color} />
    </svg>
);

export default InfoIcon;

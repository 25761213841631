import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import {
    FEEDBACK, NO_ANSWER,
    SALES_AUTOGRADED_TYPES,
    SALES_SIMULATION_TYPES,
    AUTOGRADED_ANSWER_TYPES,
    NOT_REQUIRED_ANSWER_TYPES
} from 'helper/constants';
import NotRequiredImg from './warning.svg';
import CheckedImg from './check.svg';

const styles = theme => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginLeft: '15px',
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.yellow[800],
        '& img': {
            marginRight: 5
        }
    },
    notRequiredLabel: {
        color: theme.palette.yellow[800]
    },
    autogradedLabel: {
        color: theme.palette.green[600]
    }
});

const GradingLabel = ({ classes, question, currentTask }) => {
    if (!question) return null;
    const { willBeGraded, answerType, manualGradeMissing } = question;

    const isAutogradedTask = currentTask && AUTOGRADED_ANSWER_TYPES.includes(currentTask.type) && willBeGraded;
    const isNotAutogradedTask = currentTask
        && !AUTOGRADED_ANSWER_TYPES.includes(currentTask.type)
        && !NOT_REQUIRED_ANSWER_TYPES.includes(currentTask.type)
        && willBeGraded;

    if (!isAutogradedTask && !isNotAutogradedTask && [FEEDBACK, NO_ANSWER].includes(answerType)) return null;

    const isAutograded = AUTOGRADED_ANSWER_TYPES.includes(answerType) && willBeGraded;

    const isSalesSimulationNotAutograded = SALES_SIMULATION_TYPES.includes(answerType)
        && !SALES_AUTOGRADED_TYPES.includes(answerType)
        && willBeGraded;

    if (!willBeGraded) {
        return (
            <div className={clsx(classes.label, classes.notRequiredLabel)}>
                <img src={NotRequiredImg} alt=" " />
                MARKED AS DO NOT GRADE
            </div>
        );
    }
    if (isAutograded || isAutogradedTask) {
        return (
            <div className={clsx(classes.label, classes.autogradedLabel)}>
                <img src={CheckedImg} alt=" " />
                AUTOGRADED
            </div>
        );
    }
    if (isSalesSimulationNotAutograded || isNotAutogradedTask || (manualGradeMissing && !currentTask)) {
        return (
            <div className={clsx(classes.label, classes.notRequiredLabel)}>
                <img src={NotRequiredImg} alt=" " />
                Grading required
            </div>
        );
    }

    return null;
};

export default withStyles(styles)(GradingLabel);

import React from 'react';


const TrashIcon = ({ size = 18, color = '#7F8084', ...other }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.6 4.8C15.6 3.80589 14.7941 3 13.8 3L10.2 3C9.20591 3 8.40002 3.80589 8.40002 4.8V5.4L5.40003 5.4H3.6C3.26863 5.4 3 5.66863 3 6C3 6.33137 3.26863 6.6 3.6 6.6H4.84797L5.91091 19.3491C5.9883 20.2821 6.7684 21.0001 7.70488 21L16.296 21C17.2324 21.0001 18.0125 20.2821 18.09 19.3489L19.1521 6.6H20.4C20.7314 6.6 21 6.33137 21 6C21 5.66863 20.7314 5.4 20.4 5.4H18.6L15.6 5.4V4.8ZM6.05211 6.6L17.9479 6.6L16.8941 19.2496C16.8683 19.5607 16.6082 19.8 16.2961 19.8L7.7048 19.8C7.39259 19.8 7.13256 19.5607 7.10675 19.2496L6.05211 6.6ZM14.4 4.79997V5.39997L9.60005 5.39997V4.79997C9.60005 4.4686 9.86868 4.19998 10.2 4.19998L13.8 4.19998C14.1314 4.19998 14.4 4.4686 14.4 4.79997ZM10.2 16.8C10.5314 16.8 10.8 16.5314 10.8 16.2L10.8 10.2C10.8 9.86863 10.5314 9.6 10.2 9.6C9.86868 9.6 9.60005 9.86863 9.60005 10.2L9.60005 16.2C9.60005 16.5314 9.86868 16.8 10.2 16.8ZM14.4 16.2C14.4 16.5314 14.1314 16.8 13.8 16.8C13.4687 16.8 13.2 16.5314 13.2 16.2V10.2C13.2 9.86863 13.4687 9.6 13.8 9.6C14.1314 9.6 14.4 9.86863 14.4 10.2V16.2Z" fill={color} />
    </svg>
);

export default TrashIcon;

import React from 'react';
import { withStyles } from '@mui/styles';
import { FEEDBACK, NO_ANSWER, AUTOGRADED_ANSWER_TYPES } from 'helper/constants';

const styles = theme => ({
    label: {
        fontSize: 10,
        marginRight: 10,
        fontWeight: 600,
        color: theme.palette.grey[600],
        fontStyle: 'italic'
    }
});

const GradingLabel = ({ classes, question }) => {
    if (!question) return null;
    const { willBeGraded, answerType } = question;
    if ([FEEDBACK, NO_ANSWER].some(el => el === answerType)) return null;
    const isAutograded = AUTOGRADED_ANSWER_TYPES.some(el => el === answerType) && willBeGraded;

    if (isAutograded || !willBeGraded) {
        return (
            <div className={classes.label}>
                {!willBeGraded && 'Grading not required'}
                {isAutograded && 'Autograded'}
            </div>
        );
    }
    return null;
};

export default withStyles(styles)(GradingLabel);

import React from 'react';

// material component
import { withStyles } from '@mui/styles';
import errorIcon from 'img/candidateCard/errorIcon.svg';

// local files and components
import styles from './styles';


const ErrorTable = ({
    error,
    classes: {
        cellH, row, cell, errIcon, wrapper,
        table, head, wrapperStyle,
        label
    }
}) => {
    if (!error) return null;
    return (
        <div className={wrapper}>
            <div className={label}>
                <img className={errIcon} src={errorIcon} alt="error" />
                Compilation failed
            </div>
            <div className={wrapperStyle}>
                <div className={table}>
                    <div className={head}>
                        <div className={cellH}>Your output</div>
                        <div className={cellH}>Console output</div>
                    </div>
                    <div className={row}>
                        <div className={cell}>
                            <img className={errIcon} src={errorIcon} alt="error" />
                        </div>
                        <div className={cell}>
                            {error}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(ErrorTable);

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    attachment: {
        display: 'flex',
        '& > img': {
            marginRight: 10
        },
        '& > a': {
            fontSize: 13,
            color: theme.palette.blue[600]
        }
    }
}));

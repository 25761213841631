import React from 'react';
import { withStyles } from '@mui/styles';
import QuestionContent from 'components/grade_components/QuestionContent';
import {
    MARKETING_SIM_FLOW_BUILDER, MARKETING_SIM_SEO_SITEMAP, MARKETING_SIMULATOR,
    QA_CREATE_BUG, QA_EDIT_BUG, QA_SIMULATOR, QA_TEST_CASE,
    QA_TEST_RUN, MARKETING_SIM_CM_SPREADSHEET
} from 'helper/constants';
import MarketingSimTasksAnswerContent from 'components/grade_components/MarketingSimTasksAnswerContent';
import TaskAnswerContent from 'components/grade_components/TaskAnswerContent';
import QuestionResultHeader from '../../QuestionsContent/QuestionResultHeader';

const styles = theme => ({
    wrapper: {
        letterSpacing: '0.16px',
        padding: '20px 40px',
        color: theme.palette.grey[900],
        minWidth: 690,

        '& hr': {
            borderTop: `2px solid ${theme.palette.grey[300]}`
        }
    }
});

const hideScoreFor = [
    QA_TEST_CASE, QA_EDIT_BUG, QA_CREATE_BUG, QA_TEST_RUN,
    MARKETING_SIM_FLOW_BUILDER, MARKETING_SIM_SEO_SITEMAP,
    MARKETING_SIM_CM_SPREADSHEET
];

const TasksQuestionContent = ({
    classes, scriptName, currentGrade,
    question, gradeableCard, taskNumber, audition, ...props
}) => {
    const { question: questionObj, candidateTasks } = question;
    const currentTask = candidateTasks[taskNumber];
    if (!currentTask) return null;
    const { description: currentTaskDescription, questionResults, employerDescription } = currentTask;

    const hasOnlyOnlyQuestionResult = questionResults.length === 1;
    const shownQuestionResult = hasOnlyOnlyQuestionResult || [QA_SIMULATOR, MARKETING_SIMULATOR].includes(question.type) ? questionResults[0] : null;

    let shownGrade = shownQuestionResult ? shownQuestionResult.grade : null;
    let shownSkill = shownQuestionResult ? shownQuestionResult.skillName : null;
    const shownAnswerType = shownQuestionResult ? shownQuestionResult.type : null;
    const shownQuestionResultGrades = shownQuestionResult ? shownQuestionResult.questionResultGrades : null;

    if ([QA_TEST_CASE, QA_TEST_RUN].includes(currentTask.type)) {
        shownSkill = currentTask.questionResults.find(result => result.skillName)?.skillName || null;
    }

    if (gradeableCard) shownGrade = currentGrade;

    const propsToPass = {
        currentTask,
        question,
        currentGrade,
        gradeableCard,
        taskNumber,
        candidateTasks,
        audition,
        ...props
    };

    return (
        <div className={classes.wrapper}>
            <QuestionResultHeader
                isTask
                grade={shownGrade}
                scriptName={scriptName}
                currentQuestion={taskNumber + 1}
                audition={audition}
                question={{
                    ...question,
                    questionResultGrades: shownQuestionResultGrades,
                    question: {
                        ...question.question,
                        answerType: shownAnswerType,
                        candidateQuestionGroup: { title: shownSkill }
                    }
                }}
                hideScore={hideScoreFor.includes(currentTask.type)}
                currentTask={currentTask}
                className="u-pdn--x0 u-pdn--tx3 u-pdn--bx3 u-ai--start"
            />
            <hr className={`${classes.hr} u-mrg--x0 u-mrg--bx3`} />
            <QuestionContent
                question={{
                    ...questionObj,
                    description: currentTaskDescription,
                    employerDescription
                }}
            />
            {question.type === MARKETING_SIMULATOR
                ? <MarketingSimTasksAnswerContent {...propsToPass} />
                : <TaskAnswerContent {...propsToPass} />
            }
        </div>
    );
};

export default withStyles(styles)(TasksQuestionContent);

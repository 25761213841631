import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';

import styles from './styles';
import InnerContentHeader from './InnerContentHeader';

const InnerContentWrapper = ({
    children, classes,
    header, headerActions,
    footer, contentClassName = '',
    className = ''
}) => (
    <div className={clsx(classes.wrapper, className)}>
        {
            header && (
                <InnerContentHeader
                    header={header}
                    headerActions={headerActions}
                />
            )
        }
        <div className={clsx(classes.contentWrapper, contentClassName)}>
            {children}
        </div>
        {
            footer && (
                <div className={classes.footerWrapper}>
                    {footer}
                </div>
            )
        }
    </div>
);

export default withStyles(styles)(InnerContentWrapper);

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    button: {
        minWidth: 34,
        height: 32,
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.grey[500],
        letterSpacing: '0.155556px',
        padding: 5,
        backgroundColor: '#fff',
        '& svg, & img': {
            margin: 0
        },
        '&:disabled': {
            opacity: 0.7,
            borderColor: 'rgba(0, 0, 0, 0.12)',
            color: theme.palette.grey[600]
        }
    },
    activeButton: {
        backgroundColor: '#fff',
        color: theme.palette.grey[900]
    }
}));

export default useStyles;

import React from 'react';

import { SIMULATION_GROUP, TYPES_DISABLED_FOR_CMS, CYBER_SIMULATOR } from 'helper/constants';
import SelectOutlined from 'libraries/Select';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';

const iconStyles = {
    width: '24px',
    height: '24px',
    marginRight: '15px'
};

const content = (Icon, text) => (
    <div className="u-dsp--f u-ai--center">
        { Icon && <Icon style={iconStyles} /> }
        {text}
    </div>
);

const SimulationTypesDropDown = ({ value, onChange, isCMS }) => {
    const handleMenuItem = (e) => {
        onChange(+e.target.value);
    };

    const menuOptions = (
        SIMULATION_GROUP.types
            .filter(type => type !== CYBER_SIMULATOR)
            .map(type => ({
                value: type,
                disabled: (TYPES_DISABLED_FOR_CMS.includes(type) && isCMS),
                label: getQuestionTypeInfo(type, content)
            }))
    );

    return (
        <SelectOutlined
            className="u-mrg--bx3"
            variant="outlined"
            value={value}
            onChange={handleMenuItem}
            label="Simulation type"
            options={menuOptions}
        />
    );
};

export default SimulationTypesDropDown;

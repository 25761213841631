import React from 'react';
import { checkPluralNew } from 'helper/commonFunctions';

class CompletionsAreaChart {
    constructor(value) {
        this.value = value;
        this.YAxisWidth = 40;
        this.minPointSize = 0;
    }

    set chartValues(newValue) {
        this.value = newValue;
    }

    get chartValues() {
        return this.value;
    }

    get getYAxisWidth() {
        return this.YAxisWidth;
    }

    showTooltipData(tooltipData, tooltipLabel, classes) {
        if (typeof tooltipData.payload[0] !== 'undefined') {
            const { payload: { completed_count, completed_date } } = tooltipData.payload[0];
            return (
                <div className={classes.chartTooltipWrapper}>
                    <div className={classes.tootlipRange}>{completed_date}</div>
                    <div className="u-txt--bold">
                        <span className={classes.chartTooltipValue}>
                            {completed_count} {checkPluralNew(+completed_count, tooltipLabel)}
                        </span>
                    </div>
                </div>
            );
        }
        return null;
    }
}

export default CompletionsAreaChart;

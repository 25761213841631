import React, { createContext, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { withStyles, withTheme } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { InviteCandidatesTabStore } from 'components/job_or_assessment_settings/InviteForm/store';
import PlusIcon from 'components/icons/PlusIcon';
import InviteNewCandidate from './InviteNewCandidates';

const styles = {
    previewButton: {
        padding: '8px 24px',
        lineHeight: '21px',
        whiteSpace: 'nowrap',
        marginLeft: '15px',
        '&:disabled svg': {
            opacity: 0.4
        }
    }
};

export const inviteCandidatesTabStore = new InviteCandidatesTabStore();
export const inviteCandidatesTabCtx = createContext(inviteCandidatesTabStore);

const CandidateButton = observer(({ classes, theme, disabled, audition, history }) => {
    const [inviteCandidateDialogOpened, setInviteCandidateDialogOpened] = useState(false);
    const buttonRef = useRef();
    const handleInviteCandidateDialog = (value) => {
        setInviteCandidateDialogOpened(value);
    };

    return (
        <>
            <div ref={buttonRef}>
                <Button
                    variant="outlined"
                    className={classes.previewButton}
                    disabled={disabled || inviteCandidateDialogOpened || audition.expired || !audition.valid}
                    onClick={() => handleInviteCandidateDialog(!inviteCandidateDialogOpened)}
                >
                    <PlusIcon className="u-mrg--rx1 u-pdn--rx1" color={theme.palette.grey[900]} />
                    Candidate
                </Button>
            </div>
            <InviteNewCandidate
                open={inviteCandidateDialogOpened}
                onClose={() => handleInviteCandidateDialog(false)}
                audition={audition}
                history={history}
                buttonRef={buttonRef}
                context={inviteCandidatesTabCtx}
            />
        </>
    );
});

export default withRouter(withTheme(withStyles(styles)(CandidateButton)));

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    circle: {
        marginRight: 8,
        width: 16,
        height: 16,
        borderRadius: '50%'
    },
    tagNumber: {
        display: 'inline-block',
        position: 'relative',
        fontSize: 11,
        color: theme.palette.grey[800],
        padding: 5,
        marginRight: 5,
        textDecoration: 'underline',
        fontWeight: 700
    }
}));

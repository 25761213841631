import React from 'react';
import clsx from 'clsx';

// material components
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';

// local files and components
import Loader from 'components/loaders/Loader';
import DropZone from '../../../DropZone';
import styles from '../styles';

const FileUploadCase = ({ questionFile, onDrop, classes, isUploadingFile }) => {
    if (isUploadingFile) {
        return (
            <div className={clsx(classes.dropZoneBox, 'o-box o-box--whitefull u-mrg--bx1 c-img-upload-box')}>
                <Loader
                    variant="indeterminate"
                    show
                    thickness={3}
                />
            </div>
        );
    }

    if (!questionFile && !onDrop) return null;

    return (
        questionFile
            ? (
                <>
                <div className={classes.fileBox}>
                    <a
                        href={`${process.env.REACT_APP_API_URL}${questionFile.fileUrl}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        className={classes.fileName}
                    >
                        <i className="icon-file c-icontxt u-txt--20" />{questionFile.fileName}
                    </a>
                    {
                        onDrop && (
                            <DropZone
                                onDrop={onDrop}
                                minSize={1}
                                disabled={!onDrop}
                                maxSize={10485760}
                                label={(
                                    <Button
                                        classes={{ root: classes.button }}
                                    >
                                        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.6666 1.27127V4.90763H11.0303" stroke="#1247F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M1.3335 10.9682V7.33188H4.96986" stroke="#1247F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.85471 4.30159C3.16208 3.43297 3.68449 2.65638 4.37317 2.04426C5.06186 1.43215 5.89438 1.00447 6.79306 0.801137C7.69174 0.597799 8.62729 0.625429 9.5124 0.881449C10.3975 1.13747 11.2033 1.61353 11.8547 2.26522L14.6668 4.90765M1.3335 7.33189L4.14562 9.97431C4.79698 10.626 5.60281 11.1021 6.48793 11.3581C7.37304 11.6141 8.30858 11.6417 9.20726 11.4384C10.1059 11.2351 10.9385 10.8074 11.6272 10.1953C12.3158 9.58316 12.8382 8.80656 13.1456 7.93795" stroke="#1247F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span>Replace</span>
                                    </Button>
                                )}
                            />
                        )
                    }
                </div>
            </>
            ) : (
                <DropZone
                    className={clsx(classes.dropZoneBox, 'o-box o-box--whitefull u-mrg--bx1 c-img-upload-box')}
                    onDrop={onDrop}
                    minSize={1}
                    disabled={!onDrop}
                    maxSize={10485760}
                    label={(
                        <div className="u-txt--center">
                            <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.33325 28.3333H3.33325C2.22868 28.3333 1.33325 27.4379 1.33325 26.3333V3.66665C1.33325 2.56209 2.22868 1.66666 3.33325 1.66666H16.9414C17.4562 1.66666 17.9512 1.86518 18.3233 2.22091L22.0485 5.78177C22.4433 6.1591 22.6666 6.68143 22.6666 7.22751V26.3333C22.6666 27.4379 21.7712 28.3333 20.6666 28.3333H16.6666" stroke="#7F8084" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12.0075 30.3334L12.0075 13.6667M12.0075 13.6667L8 18.9566M12.0075 13.6667L16.0151 18.9566" stroke="#7F8084" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p className={classes.mainText}>Choose a file to upload</p>
                            <p className={classes.secondaryText}>(max file size 10MB)</p>
                        </div>
                    )}
                />
            )
    );
};

export default withStyles(styles)(FileUploadCase);

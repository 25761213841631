import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import React from 'react';
import BoardNoContent from 'components/board/board_no_content';
import Button from '@mui/material/Button';
import AddInvitationsIcon from './addInvitations.svg';

const useStyles = makeStyles(() => ({
    link: {
        minWidth: 'auto',
        height: 21,
        padding: 0,
        marginBottom: 4
    }
}));

const NoInvitationsBoard = observer(({ history, location, slug, audition }) => {
    if (!audition) return null;

    const classes = useStyles();

    const goToSettings = () => {
        history.push({ pathname: `/script/settings/${slug}/invitation-preview`, state: { from: location.pathname } });
    };

    return (
        <BoardNoContent
            header="Invite your first candidates"
            text={
                <>
                    Candidates that you’ve invited will show up here.&nbsp;
                    {
                        audition.active && (
                            <>
                                You can invite more candidates <br />
                                anytime&nbsp;
                                {(
                                    <Button
                                        color="primary"
                                        className={classes.link}
                                        onClick={() => { history.push(`/script/invite/${slug}/invitation`); }}
                                    >
                                        via link
                                    </Button>
                                )} or {(
                                    <Button
                                        color="primary"
                                        className={classes.link}
                                        onClick={() => { history.push(`/script/invite/${slug}/invitation`); }}
                                    >
                                    via email
                                    </Button>
                                )}
                                . Before inviting candidates check your&nbsp;
                                <Button
                                    color="primary"
                                    className={classes.link}
                                    onClick={goToSettings}
                                >
                                    invite settings
                                </Button>.
                            </>
                        )
                    }

                </>
            }
            hideIconWrapper
            icon={<img src={AddInvitationsIcon} alt="empty-content" />}
        />
    );
});

export default withRouter(NoInvitationsBoard);

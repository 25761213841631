import { createContext } from 'react';
import { action, observable } from 'mobx';
import { loadAssessmentStats, getCandidateRating } from 'requests/AssessmentRequests';

class StatsStore {
    @observable loading = true;

    @observable statsArr = [];

    @observable slug = '';

    @observable statsCount = {};

    @observable ratingData = null;

    @action loadAssessmentStats = () => {
        if (!this.slug) return;
        return loadAssessmentStats(this.slug)
            .then(({ data, success }) => {
                if (data && success) {
                    this.setStatsData(data);
                }
                this.loading = false;
            });
    };

    @action loadRatingData = () => {
        if (!this.slug) return;
        return getCandidateRating(this.slug)
            .then(({ data, success }) => {
                if (data && success) {
                    this.ratingData = data;
                }
            });
    };

    @action setLoading = (value) => {
        this.loading = value;
    };

    @action setSlug = (value) => {
        this.slug = value;
    };

    @action setStatsData = (data) => {
        const {
            completionsRate, statsCount = {}, expiredRate,
            shareLinkInvitationsCount, shareLinkInvitationsRate,
            emailInvitationsRate, emailInvitationsCount,
            inProgressRate, countRealCandidates, avgTimeSpent,
            startedScreeningRate, passedScreeningRate, failedScreeningRate, startedRate,
            startedScreeningCount, passedScreeningCount, failedScreeningCount
        } = data;

        this.statsArr = [{
            value: countRealCandidates,
            label: 'Candidates'
        }, {
            value: shareLinkInvitationsCount,
            percentage: shareLinkInvitationsRate,
            isPercentageGrey: true,
            label: 'Share link'
        }, {
            value: emailInvitationsCount,
            percentage: emailInvitationsRate,
            isPercentageGrey: true,
            label: 'Email invitation'
        }, {
            value: startedScreeningCount,
            percentage: startedScreeningRate,
            isPercentageGrey: true,
            isBlueLabel: true,
            label: 'Started screening'
        }, {
            value: passedScreeningCount,
            percentage: passedScreeningRate,
            isPercentageGrey: true,
            isBlueLabel: true,
            label: 'Passed screening'
        }, {
            value: failedScreeningCount,
            percentage: failedScreeningRate,
            isPercentageGrey: true,
            isBlueLabel: true,
            label: 'Failed screening'
        }, {
            value: statsCount.started,
            percentage: startedRate,
            isPercentageGrey: true,
            label: 'Started assessment'
        }, {
            value: statsCount.inProgress,
            percentage: inProgressRate,
            label: 'In progress'
        }, {
            value: statsCount.expired,
            percentage: expiredRate,
            label: 'Expired'
        }, {
            value: statsCount.completed,
            percentage: completionsRate,
            label: 'Completed'
        }, {
            value: `${Math.round(avgTimeSpent / 60)}min`,
            label: 'Avg Completion Time'
        }, {
            value: statsCount.hired,
            label: 'Hired'
        }];

        this.statsCount = statsCount;
    };

    @action cleanStatsData = () => {
        this.loading = false;
        this.statsArr = [];
        this.slug = '';
        this.statsCount = {};
        this.ratingData = null;
    }
}

export const statsStore = new StatsStore();
export const statsCtx = createContext(statsStore);

import React from 'react';

const MailIcon = ({ color = '#6B6B70', ...other }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M1.6 18L1.6 6C1.6 5.2268 2.2268 4.6 3 4.6L21 4.6C21.7732 4.6 22.4 5.2268 22.4 6L22.4 18C22.4 18.7732 21.7732 19.4 21 19.4L19.1176 19.4L3 19.4C2.2268 19.4 1.6 18.7732 1.6 18Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 4.87061L12 14.0001L21.6987 4.82819" stroke={color} strokeWidth="1.2" strokeLinejoin="round" />
        <path d="M9 12L2 19" stroke={color} />
        <path d="M14.5 11.5L22 19" stroke={color} />
    </svg>
);

export default MailIcon;

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        minHeight: 200,
        padding: '35px 24px',
        position: 'relative',
        borderRight: `2px solid ${theme.palette.grey[200]}`
    },
    noAnswerIcon: {
        width: 70,
        marginBottom: 7
    },
    text: {
        width: 330,
        textAlign: 'center'
    }
}));

import React from 'react';

import { isCalculating } from 'helper/assessmentFunctions';
import CalculatingLabel from 'components/scores/CalculatingLabel';
import TopPerformerLabel from 'components/candidate_cards/TopPerformerLabel';
import ScoreNumberWrapper from './ScoreNumberWrapper';
import UpgradeButton from './UpgradeButton';


const Score = ({ candidate, isEmployerSortEnabled, hired }) => {
    const {
        score: scoreObj, completionsPerOpportunityLimitExceeded,
        pureNoAnswerRequiredAssessment, topPerformer,
        scoreExists, rejected, manualGradeMissing
    } = candidate;

    if (completionsPerOpportunityLimitExceeded) {
        return <UpgradeButton />;
    }

    if (manualGradeMissing) {
        return;
    }

    if (scoreObj === null && !pureNoAnswerRequiredAssessment) {
        if (rejected) return null;
        if (isCalculating(candidate, (isEmployerSortEnabled && scoreExists) || !isEmployerSortEnabled)) {
            return <CalculatingLabel fontSize={14} />;
        }
        if (!isEmployerSortEnabled) {
            return 'No score yet';
        }
    }

    if (!scoreObj) return null;
    const { score } = scoreObj;

    return (
        <div className="u-dsp--f u-ai--center u-jc--start">
            <ScoreNumberWrapper
                score={score}
            />
            {topPerformer && !hired && <TopPerformerLabel />}
        </div>
    );
};

export default Score;

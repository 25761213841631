export default ({
    saveButton: {
        padding: '10px 100px',
        fontWeight: 'bold',
        marginTop: 10
    },
    input: {
        marginTop: 0,
        marginBottom: 10,
        maxWidth: 500
    }
});

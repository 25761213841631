import React from 'react';


const MultipleChoiceIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M28 16L10.6667 16" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28 8L10.6667 8" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.668 24L28.0013 24" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.4013 8.00016C7.4013 9.14155 6.47602 10.0668 5.33464 10.0668C4.19325 10.0668 3.26797 9.14155 3.26797 8.00016C3.26797 6.85877 4.19325 5.9335 5.33464 5.9335C6.47602 5.9335 7.4013 6.85877 7.4013 8.00016Z" stroke={color} strokeWidth="1.2" />
        <circle cx="5.33464" cy="16.0002" r="2.06667" stroke={color} strokeWidth="1.2" />
        <path d="M7.4013 24.0002C7.4013 25.1416 6.47602 26.0668 5.33464 26.0668C4.19325 26.0668 3.26797 25.1416 3.26797 24.0002C3.26797 22.8588 4.19325 21.9335 5.33464 21.9335C6.47602 21.9335 7.4013 22.8588 7.4013 24.0002Z" stroke={color} strokeWidth="1.2" />
    </svg>
);

export default MultipleChoiceIcon;

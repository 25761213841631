import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './styles';
import ActiveTicketHeader from './active_ticket_header';
import ActiveTicketBody from './active_ticket_body';

const ActiveTicket = ({ ticket, candidate, loading }) => {
    const { wrapper, loader, spinnerRoot, spinnerColor } = useStyles({});

    return (
        <div className={wrapper}>
            {loading && (
                <div className={loader}>
                    <CircularProgress classes={{ root: spinnerRoot, colorPrimary: spinnerColor }} size={65} />
                </div>
            )}
            <ActiveTicketHeader candidate={candidate} ticket={ticket} />
            <ActiveTicketBody ticket={ticket} />
        </div>
    );
};

export default ActiveTicket;

import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import SearchField from 'components/inputs/SearchField';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';
import { copyScriptAction } from 'helper/assessmentFunctions';
import GeneralEvents from 'events/GeneralEvents';
import LibraryEvents from 'events/LibraryEvents';

import { AssessmentQueryCtx } from 'components/assessments_pages/AssessmentList/queryStore';
import AssessmentPreviewDialog from 'components/dialogs/AssessmentPreviewDialog';
import { loadPublicLibrarySearchResults, loadOnePublicInterview } from 'requests/ScriptRequests';
import NoResultsBanner from '../../assessment/edit_assessment/edit_questions/components/QuestionContent/common/NoResultsBanner';

import styles from './styles';

let timerId;

const MarketplaceSearchField = observer(({
    classes, className = '', history, wrapClass = '', handleFilter
}) => {
    const [searchFocused, setSearchFocused] = useState(false);
    const [openPreviewScript, setOpenPreviewScript] = useState(false);
    const [value, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCopyLoading, setIsCopyLoading] = useState(false);
    const [paperIsOpened, setPaperIsOpened] = useState(false);
    const [options, setOptions] = useState({});
    const [assessment, setAssessment] = useState(null);
    const [dialogOpened, setDialogOpened] = useState(false);

    const {
        query, setQuery, setShowAll
    } = useContext(AssessmentQueryCtx);

    useEffect(() => {
        setSearchValue(query);
    }, [query]);

    const onChange = (e) => {
        const newValue = e.target.value;
        setSearchValue(newValue);
        if (newValue) {
            setIsLoading(true);
            setPaperIsOpened(true);
            clearTimeout(timerId);
            timerId = setTimeout(async () => {
                const data = await loadPublicLibrarySearchResults(newValue);
                const { categories, skills, auditions } = data;
                LibraryEvents.PREEMPTIVE_SEARCH_CONDUCTED({
                    preemptiveSearchTerm: newValue,
                    numberResults: {
                        category: categories ? categories.length : 0,
                        skill: skills ? skills.length : 0,
                        assessment: auditions ? auditions.length : 0
                    },
                    isAF: true
                });
                setOptions(data);
                setIsLoading(false);
            }, 300);
        } else {
            setQuery('');
        }
    };

    const handleDialog = () => {
        const nextValue = !dialogOpened;
        if (!nextValue) {
            setAssessment(null);
        }
        setDialogOpened(nextValue);
    };

    const renderList = (header, listOptions, onClick) => (
        listOptions && listOptions.length ? (
            <div className={classes.block}>
                <p className={classes.header}>{header}</p>
                {
                    listOptions.map((item) => {
                        const { title, id } = item;
                        return (
                            <p
                                onClick={() => {
                                    onClick(item);
                                    setSearchValue('');
                                    setPaperIsOpened(false);
                                }}
                                className={classes.listItem}
                                key={id}
                            >
                                {title}
                            </p>
                        );
                    })
                }
            </div>
        ) : null
    );

    const showAll = () => {
        if (!value) return;
        setQuery(value);
        setShowAll(true);
        handleFilter('query', value);
        setTimeout(() => {
            setPaperIsOpened(false);
            setSearchValue('');
        }, 500);
    };

    const onSkillClick = ({ title }) => {
        setQuery('');
        setShowAll(false);
        handleFilter('skills', title, true);
    };

    const onCategoryClick = ({ title }) => {
        setShowAll(false);
        setQuery('');
        handleFilter('categories', title, true);
    };

    const onAssessmentClick = async ({ uuid }) => {
        const { data } = await loadOnePublicInterview(uuid);
        setAssessment(data);
        handleDialog();
    };

    const onClickAddToMyAssessments = async (e) => {
        e.stopPropagation();
        if (!assessment) return;
        setIsCopyLoading(true);
        await copyScriptAction(assessment, history)
            .then(() => {
                setDialogOpened(false);
            });
        setIsCopyLoading(false);
    };

    const onEnter = (e) => {
        if (e.keyCode === 13) {
            showAll();
        }
    };

    const handleOpenPreview = (e) => {
        e.stopPropagation();
        if (!assessment) return;
        const { uuid, name, active = false } = assessment;
        GeneralEvents.TT_PREVIEWED({
            ttId: uuid,
            ttName: name,
            publicLibrary: true,
            activeAssessment: active
        });
        setOpenPreviewScript(true);
    };

    const hasOptions = !isLoading && Object.keys(options).length && Object.keys(options).some(key => options[key].length);

    return (
        <div
            className={clsx(wrapClass, 'u-pos--relative')}
        >
            <SearchField
                placeholder="Search by role or skill"
                onChange={onChange}
                className={clsx(classes.searchField, className, searchFocused && classes.searchFieldFocused)}
                value={value}
                onKeyDown={onEnter}
                onFocus={() => { setSearchFocused(true); }}
                onBlur={() => { setSearchFocused(false); }}
            />
            {
                Boolean(value && paperIsOpened) && (
                    <Paper
                        className={clsx(classes.paper, classes.paperWithOptions, isLoading && classes.paperLoading)}
                        elevation={3}
                    >
                        {
                            isLoading ? (
                                <CircularProgress color="primary" size={40} />
                            ) : (
                                <>
                                    {
                                        hasOptions ? (
                                            <>
                                                {renderList('Category', options.categories, onCategoryClick)}
                                                {renderList('Assessment', options.auditions, onAssessmentClick)}
                                                {renderList('Skills', options.skills, onSkillClick)}
                                                <div className="u-pdn--x2">
                                                    <Button
                                                        color="primary"
                                                        className="u-txt--semibold"
                                                        onClick={showAll}
                                                    >
                                                        Show all results
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <NoResultsBanner
                                                className="u-wdt--100p"
                                                header={`We couldn't find a match for "${value}"`}
                                                text="Check your spelling or try a new search"
                                            />
                                        )
                                    }
                                </>
                            )
                        }
                    </Paper>
                )
            }
            <AssessmentPreviewDialog
                open={dialogOpened}
                onClose={handleDialog}
                assessment={assessment}
                isLoading={isCopyLoading}
                copyScriptAction={onClickAddToMyAssessments}
                handleOpenPreview={handleOpenPreview}
            />
            <PreviewScript
                {...{
                    onClose: setOpenPreviewScript,
                    interviewSlug: assessment && assessment.slug,
                    isPublic: true,
                    open: openPreviewScript,
                    uuid: assessment && assessment.uuid,
                    isLibrary: true
                }}
            />
        </div>
    );
});

export default withStyles(styles)(withRouter(MarketplaceSearchField));

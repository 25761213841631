import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    tableWrapper: {
        padding: '20px 0',
        overflow: 'auto'
    },
    table: {
        border: '2px solid black',
        width: 'calc(100% - 30px)',
        '& tr': {
            position: 'relative',
            '& th, & td': {
                textAlign: 'center',
                padding: '30px 5px',
                wordBreak: 'break-word',
                '&:not(:last-of-type)': {
                    borderRight: '2px solid black'
                }
            },
            '& textarea': {
                border: 'none',
                textAlign: 'center',
                fontWeight: 700
            },
            '&:not(:last-of-type)': {
                '& th, & td': {
                    borderBottom: '2px solid black'
                }
            },
            '& th': {
                backgroundColor: theme.palette.grey[300],
                fontWeight: 700,
                minWidth: 120
            }
        }
    }
}));

import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';

const RemoveSkillDialog = ({
    open, onClose, onRemove, loading, assessmentPage
}) => (
    <Dialog
        open={open}
        maxWidth="sm"
        onClose={onClose}
        titleComponent="Are you sure you want to remove this question?"
        handleClose={onClose}
        actionComponent={(
            <>
                <Button
                    className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onRemove}
                    disabled={loading}
                    style={{ width: 120 }}
                    className="u-pdn--lx6 u-pdn--rx6"
                >
                    {
                        loading ? (
                            <CircularProgress
                                size={24}
                                thickness={3}
                            />
                        ) : 'Remove'
                    }
                </Button>
            </>
        )}
    >
        Question will be removed from {assessmentPage ? 'the assessment and' : ''} set
    </Dialog>
);

export default RemoveSkillDialog;

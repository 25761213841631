import { makeStyles } from '@mui/styles';

export default props => makeStyles(theme => ({
    phoneInputContainer: {
        height: 50,
        borderRadius: 4,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        '&:hover, &:focus, &:active': {
            borderColor: theme.palette.blue[500]
        },
        '& .country-list': {
            maxHeight: 250,
            position: 'fixed',
            left: props.left,
            top: props.top + 30
        }
    },
    phoneInput: {
        height: '29px !important'
    },
    phoneInputFocused: {
        borderColor: theme.palette.blue[500]
    },
    phoneInvalid: {
        borderColor: theme.palette.red[600],
        '&:hover, &:focus, &:active': {
            borderColor: theme.palette.red[600]
        }
    },
    phoneHelperText: {
        fontSize: 10,
        fontWeight: 600,
        color: theme.palette.red[700]
    }
}));

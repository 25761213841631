import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { appCtx } from 'components/appStore';
import { observer } from 'mobx-react-lite';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';

const styles = theme => ({
    wrapper: {
        overflowX: 'auto',
        fontWeight: 600,
        textAlign: 'center',
        lineHeight: '23px',
        marginTop: 25,
        color: theme.palette.grey[900]
    },
    actionLabel: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover, &:active, &:focus': {
            color: theme.palette.primary.main
        }
    }
});

const TeamMembersLimitLabel = observer(({ classes, countTeamMembers, countPendingTeamMembers }) => {
    const { company } = useContext(appCtx);
    const { companySettings, plan } = company;
    const totalCount = countTeamMembers + countPendingTeamMembers;

    if (!companySettings || companySettings.teamMembers === -1 || totalCount < companySettings.teamMembers) return null;

    const canBeUpgraded = plan && plan.canBeUpgraded;

    return (
        <div className={classes.wrapper}>
            You have filled the maximum number of team seats <br />on the {plan.name} plan. Need more?&nbsp;
            {
                canBeUpgraded ? (
                    <SubscriptionsLink
                        label="Upgrade now"
                        className={classes.actionLabel}
                        segmentLocation="team seats number warning"
                    />
                ) : (
                    <span
                        className={classes.actionLabel}
                        role="presentation"
                        onClick={() => window.open('/subscriptions', '_blank')}
                    >
                        Talk to Sales
                    </span>
                )
            }
        </div>
    );
});

export default withStyles(styles)(TeamMembersLimitLabel);

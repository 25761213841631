import React, { useEffect, useState } from 'react';
import {
    Button, Dialog, DialogActions,
    DialogContent, RadioGroup,
    DialogTitle, FormControlLabel, Radio
} from '@mui/material';
import clsx from 'clsx';
import Tooltip from 'libraries/TooltipWhite';
import useStyles from './styles';
import Attachment from '../attachment';

const AnswersDialog = ({ open, handleClose, answers, ticketId }) => {
    const { paper, dialogTitle, dialogContent, dialogActions, sendButton, answerBlock, radioButton, attachment } = useStyles({});
    const [selectedAnswer, setSelectedAnswer] = useState();

    useEffect(() => {
        if (open) setSelectedAnswer(null);
    }, [open]);

    return (
        <Dialog
            classes={{ paper }}
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: dialogTitle }}>Select Response</DialogTitle>
            <DialogContent classes={{ root: dialogContent }}>
                <Tooltip
                    id={`simulation_ticket_${ticketId}`}
                    delayHide={100}
                    effect="float"
                >
                    This is a preview of your simulation and replies are unable to be submitted.
                </Tooltip>
                <RadioGroup
                    aria-label="answer"
                    data-tip
                    data-for={`simulation_ticket_${ticketId}`}
                    value={selectedAnswer}
                    onChange={e => setSelectedAnswer(e.target.value)}
                    name="radio-buttons-group"
                >
                    {answers && answers.map(el => (
                        <FormControlLabel
                            className={clsx(answerBlock, el.id.toString() === selectedAnswer && 'active')}
                            value={el.id.toString()}
                            control={<Radio disabled classes={{ root: radioButton }} />}
                            disabled
                            label={(
                                <>
                                    {el.text}
                                    {el.file && <Attachment className={attachment} file={el.file}/>}
                                </>
                            )}
                            key={el.id}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions classes={{ root: dialogActions }}>
                <Button
                    onClick={handleClose}
                    className={sendButton}
                    variant="contained"
                    color="primary"
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AnswersDialog;

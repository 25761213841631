import React, { cloneElement } from 'react';
import clsx from 'clsx';

// material components
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';

// local files and components
import ActiveFab from '../ActiveFab';
import ResultPanelsHeader from '../ResultPanelHeader';
import ErrorTable from '../ErrorTable';
import EmptyResultPanel from '../EmptyResultPanel';
import CasesTable from '../CasesTable';
import styles from './styles';

const DRAWER_HEIGHT = 315;

class PersistentDrawerLeft extends React.Component {
    state = {
        customInput: false
    };

    componentDidMount() {
        if (this.main) {
            this.main.style.height = `${this.countEditorHeight()}px`;
            window.addEventListener('resize', this.resizeListener);
        }
    }

    componentDidUpdate({ open }, prevState, snapshot) {
        if (open) {
            this.main.style.height = `${this.countEditorHeight()}px`;
        }
        if (!open && snapshot) {
            setTimeout(() => {
                this.main.style.height = `${this.countEditorHeight(DRAWER_HEIGHT)}px`;
            }, 300);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListener);
    }

    countEditorHeight = (drawerHeight = 0) => {
        const SCREEN_HEIGHT = window.innerHeight;
        const HEADER_HEIGHT = 100;
        const SETTING_PANEL_HEIGHT = 60;
        const DRAWER_HEADER_HEIGHT = 78;
        const filledScreen = HEADER_HEIGHT + SETTING_PANEL_HEIGHT + DRAWER_HEADER_HEIGHT + drawerHeight;

        return SCREEN_HEIGHT - filledScreen;
    };

    changeCustomInput = ({ target: { checked } }) => {
        this.handleDrawerOpen();
        this.setState({ customInput: checked });
    };

    resizeListener = () => {
        const { open } = this.props;
        this.main.style.height = `${this.countEditorHeight(open ? DRAWER_HEIGHT : '')}px`;
    }

    render() {
        const {
            state: { customInput },
            props: {
                open, classes, children, cases, runTest, error,
                activeCase, changeActiveCase, runningTest,
                handleDrawerClose, handleDrawerOpen, setDeleteTestCaseDialogOpen,
                setTestCaseToDeleteFromTable
            },
            changeCustomInput, countEditorHeight
        } = this;
        return (
            <div className={classes.root}>
                <Drawer
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })}
                    classes={{
                        paper: clsx(classes.drawerPaper, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open
                        })
                    }}
                    variant="permanent"
                    anchor="bottom"
                >
                    <div className={classes.drawerHeader}>
                        <div
                            className={clsx(classes.headerVisiblePanel, open && classes.headerVisiblePanelOpen)}
                        >
                            <ActiveFab {...{ open, handleDrawerClose, handleDrawerOpen }} />
                            <ResultPanelsHeader
                                {...{
                                    error,
                                    handleDrawerOpen,
                                    open,
                                    changeCustomInput,
                                    runTest,
                                    customInput,
                                    cases,
                                    runningTest
                                }}
                            />
                        </div>
                        <ErrorTable error={error} />
                        <div className={classes.headerContent}>
                            {
                                !cases.length
                                    ? (<EmptyResultPanel {...{ open }} />)
                                    : (
                                        <CasesTable
                                            {...{ error,
                                                cases,
                                                activeCase,
                                                changeActiveCase,
                                                setTestCaseToDeleteFromTable,
                                                runningTest,
                                                setDeleteTestCaseDialogOpen
                                            }}
                                        />
                                    )
                            }
                        </div>
                    </div>
                </Drawer>
                <main
                    ref={(main) => { this.main = main; }}
                    className={clsx(classes.content, open && classes.contentShift)}
                >
                    {cloneElement(children, { countEditorHeight })}
                </main>
            </div>
        );
    }
}

export default withStyles(styles)(PersistentDrawerLeft);

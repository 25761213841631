import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    // Page Itself Styles
    contentWrapper: {
        padding: 0,
        marginBottom: 0,
        backgroundColor: 'transparent',
        border: 'none'
    },
    baseButton: {
        color: theme.palette.blue[500],
        fontWeight: 600,
        fontSize: 14
    },
    loaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: 700,
        color: '#000'
    },

    // General Page Filter Styles
    assessmentSelect: {
        width: 235
    },

    // Common insights styles
    tab: {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        width: '100%',
        height: 72,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.grey[900],
        backgroundColor: 'white',
        cursor: 'pointer'
    },
    activeTab: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.blue[600]
    }
}));

export default useStyles;

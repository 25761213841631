import React from 'react';
import { useStyles } from './styles';
import FileAttachment from '../../../../../assets/file_attachment.svg';

const ActiveTicketBody = (props) => {
    const { file } = props;
    const { attachment } = useStyles({});

    return (
        <div key={file} className={attachment}>
            <img src={FileAttachment} alt="" />
            <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + file.fileUrl}>{file.fileName}</a>
        </div>
    );
};

export default ActiveTicketBody;

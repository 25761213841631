export function createHyperlink(urlString) {
    let hyperlink = urlString;
    const prefixHttp = 'http://';
    const prefixHttps = 'https://';
    const startWithHttp = urlString.startsWith(prefixHttp);
    const startWithHttps = urlString.startsWith(prefixHttps);

    if (startWithHttp && !startWithHttps) {
        hyperlink = urlString.replace(prefixHttp, prefixHttps);
    } else if (!startWithHttps) {
        hyperlink = prefixHttps + urlString;
    }

    return hyperlink;
}

export function isEmptyObject(obj) {
    // null and undefined are "empty"
    if (obj === null || obj === undefined) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

// returns array of errors
export function passwordValidateFunction(value, minLength = 2) {
    const errors = [];
    if (value.length < minLength) {
        errors.push(`Minimum ${minLength} characters`);
    }
    if (!new RegExp('(?=.*[0-9])').test(value)) {
        errors.push('At least 1 number');
    }
    if (!new RegExp('.*[@<({[\\\\^/=\\]}\'"`~)>!?$%&#*-+.,;:_|-]').test(value)) {
        errors.push('Include at least one special character');
    }
    return errors;
}

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { loadAssessmentLinks } from 'requests/AssessmentRequests';

import styles from './styles';

const PreviewButton = observer(({ classes, slug }) => {
    const [shareLink, setShareLink] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        loadAssessmentLinks(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    const { shareLink: { url } } = data;
                    setShareLink(`${url}/preview`);
                }
                setLoading(false);
            });
    }, []);

    if (loading) return null;

    return (
        <a href={shareLink} rel="noopener noreferrer" target="_blank">
            <Button
                color="primary"
                className={classes.actionButton}
            >
                Preview application page
            </Button>
        </a>
    );
});

export default withStyles(styles)(PreviewButton);

const styles = theme => ({
    textStyle: {
        color: theme.palette.grey[900],
        fontSize: 14,
        marginTop: 25,
        marginBottom: 14,
        '& *': {
            marginBottom: 0
        },
        '& ul li': {
            listStyleType: 'disc',
            marginLeft: 40
        },
        '& ul > br, ol > br': {
            display: 'none'
        },
        '& code': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[900],
            fontSize: 16
        },
        '& blockquote': {
            fontStyle: 'italic',
            color: theme.palette.grey[700],
            borderLeft: '5px solid #eee'
        }
    }
});

export default styles;

export default theme => ({
    input: {
        margin: '0 5px'
    },
    icon: {
        color: theme.palette.grey[700],
        width: 25,
        height: 25
    },
    select: {
        maxWidth: 150
    }
});

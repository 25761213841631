import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import { withStyles } from '@mui/styles';

import EditIcon from './editIcon';
import styles from './styles';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';

const codeMirrorOptions = {
    lineNumbers: true,
    theme: 'material',
    mode: 'javascript'
};

const CodeEditorQuestion = ({ data, classes }) => (
    <div className={classes.codeEditorWrapper}>
        <CodeMirror
            className="CodeMirror__preView"
            value={data}
            options={codeMirrorOptions}
        />
        <div className={classes.editButton}>
            <EditIcon />
            View and Edit
        </div>
    </div>
);

export default withStyles(styles)(CodeEditorQuestion);

import axios from 'axios';
import { action, observable } from 'mobx';
import { clone } from 'helper/commonFunctions';
import { appStore } from 'components/appStore';
import { createContext } from 'react';
import { loadContentLibraryAssessments } from 'requests/CMSRequests';

const { CancelToken } = axios;
let source;

class AssessmentListStore {
    @observable assessments = null;

    @observable currentPage = 0;

    @observable totalCount = 0;

    @observable loadingAssessments = true;

    @observable loadingSearchAssessments = true;

    @observable timerId = null;

    @observable activeFolderId = null;

    @observable query = '';

    @action setQuery = (newValue) => {
        this.query = newValue;
    }

    @action setTotalCount = (newValue) => {
        this.totalCount = newValue;
    }

    @action setCurrentPage = (newPage) => {
        this.currentPage = newPage;
    }

    @action setAssessments = (newValue) => {
        this.assessments = newValue;
    }

    @action setActiveFolderId = (newValue) => {
        this.activeFolderId = newValue;
    }

    @action setLoadingSearchAssessments = (newValue) => {
        this.loadingSearchAssessments = newValue;
    }

    @action setLoadingAssessments = (newValue) => {
        this.loadingAssessments = newValue;
    }

    @action getPrivateAssessments = async (query) => {
        this.loadingSearchAssessments = true;
        if (source && source.token) source.cancel();
        source = CancelToken.source();
        await loadContentLibraryAssessments(query, this.currentPage + 1, this.activeFolderId, { cancelToken: source.token })
            .then(({ data, success }) => {
                if (data && success) {
                    const { total, currentPage: dataCurrentPage, items } = data;

                    const newAssessmentsPublicList = !this.assessments || this.currentPage === 0 ? [] : this.assessments;

                    this.totalCount = total;
                    this.assessments = newAssessmentsPublicList.concat(items);
                    this.loadingAssessments = false;
                    this.currentPage = dataCurrentPage;
                    this.loadingSearchAssessments = false;
                }
                this.loadingAssessments = false;
            })
            .catch((err) => {
                if (!axios.isCancel(err)) {
                    appStore.flashMessage('Something went wrong', 'error');
                    this.assessments = [];
                    this.loadingSearchAssessments = false;
                    this.loadingAssessments = false;
                }
            });
    }

    @action cleanState = () => {
        this.totalCount = 0;
        this.assessments = null;
        this.loadingAssessments = true;
        this.currentPage = 0;
        this.loadingSearchAssessments = false;
        this.activeFolderId = null;
    }
}

export const assessmentListStore = new AssessmentListStore();
export const assessmentListCtx = createContext(assessmentListStore);

import React, { Component } from 'react';

// material components
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Chip from '@mui/material/Chip';
import { withStyles } from '@mui/styles';

// local files and components
import errorIcon from 'img/candidateCard/errorIcon.svg';
import CircularProgress from '@mui/material/CircularProgress';
import lockIcon from 'img/candidateCard/lock.svg';
import TooltipArrow from 'libraries/Tooltip';
import CloseIcon from './SvgIcons/closeIcon';
import DoneIcon from './SvgIcons/doneIcon';
import DeleteIcon from './SvgIcons/deleteIcon';
import styles from './styles';


class ListItemWrapper extends Component {
    handleDeleteClick = (e) => {
        const { setDeleteTestCaseDialogOpen, testCase, setTestCaseToDeleteFromTable } = this.props;
        if (!setDeleteTestCaseDialogOpen) return;
        setTestCaseToDeleteFromTable(testCase);
        setDeleteTestCaseDialogOpen(true);
        e.stopPropagation();
    }

    render() {
        const {
            testCase: { name, score, pass, completed, type },
            classes,
            classesItem,
            runningTest,
            error,
            ...other
        } = this.props;

        return (
            <ListItem {...other} classes={classesItem} button>
                {
                    score && (
                        <TooltipArrow
                            white
                            label="This is a graded test case, therefore input and expected output will be hidden from candidates."
                        >
                            <ListItemIcon>
                                <img src={lockIcon} alt="lock" />
                            </ListItemIcon>
                        </TooltipArrow>
                    )
                }
                <div
                    className={classes.points}
                >
                    <span className={classes.testName}>{name}</span>
                    {
                        !error && completed && !runningTest && (
                            !pass
                                ? <CloseIcon className={type === 'example' ? classes.chipTestExample : classes.chipTest} />
                                : <DoneIcon className={type === 'example' ? classes.chipTestExample : classes.chipTest} />
                        )
                    }
                    {
                        !error && score && !runningTest && (
                            <span className={classes.stylePoints}>
                                {
                                    !completed ? (
                                        `${score} PTS`
                                    ) : (
                                        <span className={pass ? classes.stylePointsSuccess : classes.stylePointsError}>
                                            {pass ? `+${score} PTS` : '+0 PTS'}
                                        </span>
                                    )
                                }
                            </span>
                        )
                    }
                    {
                        error && !runningTest && <img src={errorIcon} alt="" />
                    }
                    <div className="u-ai--center u-mrg--rx2">
                        <TooltipArrow
                            white
                            label="Delete test case"
                        >
                            <Chip
                                onClick={this.handleDeleteClick}
                                classes={{ root: classes.chip, label: 'u-pdn--x0 u-txt--lh1' }}
                                label={(<DeleteIcon />)}
                            />
                        </TooltipArrow>
                    </div>
                </div>
                {
                    runningTest && (
                        <CircularProgress
                            size={24}
                            thickness={2}
                            classes={{ root: classes.loader }}
                        />
                    )
                }
            </ListItem>
        );
    }
}

export default withStyles(styles)(ListItemWrapper);

import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

const styles = theme => ({
    root: {
        padding: 0,
        '& > div': {
            minHeight: '54px',
            borderRadius: 4,
            border: 'solid 2px #fff',
            backgroundColor: theme.palette.grey[900],
            fontSize: 16,
            fontWeight: 600,
            color: '#ffffff',
            padding: '6px 15px 6px 21px'
        }
    },
    anchorOriginBottomLeft: {
        zIndex: '99999 !important'
    },
    actionButton: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        margin: 'auto',
        right: 0,
        fontWeight: 600,
        color: theme.palette.blue[500],
        textTransform: 'uppercase'
    },
    subHeader: {
        fontSize: 12,
        textAlign: 'left',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        textTransform: 'uppercase',
        marginBottom: 7
    },
    successIcon: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        margin: 'auto'
    },
    infoIcon: {
        marginRight: 5
    },
    labelWrapper: {
        position: 'relative',
        paddingRight: 75,
        minHeight: 36
    },
    message: {
        width: '100%'
    }
});

const SnackbarProgress = withStyles(styles)(({
    message, canceled, classes, error,
    errorLabel = 'download error', showSuccessIcon,
    onCancel, percents, onRetry, ...other
}) => (
    <Snackbar
        classes={{
            root: classes.root,
            anchorOriginBottomLeft: classes.anchorOriginBottomLeft
        }}
        ContentProps={{
            classes: {
                message: classes.message
            }
        }}
        message={(
                <>
                    <div className={clsx('u-dsp--f u-ai--center', classes.labelWrapper)}>
                        {message}
                        {
                            error && onRetry && (
                                <Button
                                    onClick={onRetry}
                                    className={classes.actionButton}
                                >
                                    retry
                                </Button>
                            )
                        }
                        {
                            !error && percents < 100 && onCancel && (
                                <Button
                                    onClick={onCancel}
                                    className={classes.actionButton}
                                >
                                    cancel
                                </Button>
                            )
                        }
                        {
                            showSuccessIcon && percents >= 100 && (
                                <svg className={classes.successIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g fill="none" fillRule="evenodd">
                                        <mask id="b" fill="#fff">
                                            <path d="M9.662 15.516l7.605-8.196a1 1 0 1 1 1.466 1.36l-8.35 9a1 1 0 0 1-1.477-.012l-2.65-2.954a1 1 0 1 1 1.488-1.336l1.918 2.138z" />
                                        </mask>
                                        <g fill="#2fd1b8" mask="url(#b)">
                                            <path d="M0 0h24v24H0z" />
                                        </g>
                                    </g>
                                </svg>
                            )
                        }
                    </div>
                    {
                        error && (
                            <div className={classes.subHeader}>
                                <svg className={classes.infoIcon} width="14" height="14" viewBox="0 0 14 14">
                                    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinejoin="round">
                                        <path strokeLinecap="round" d="M12.738 6.9c.055 3.17-2.568 5.785-5.738 5.84-3.169.055-5.683-2.47-5.738-5.64C1.207 3.932 3.83 1.316 7 1.262c3.169-.056 5.683 2.47 5.738 5.639zM7 7.783V4.13" />
                                        <path fill="#FFF" d="M7.26 9.609a.261.261 0 1 1-.521 0 .261.261 0 0 1 .522 0z" />
                                    </g>
                                </svg>
                                {errorLabel}
                            </div>
                        )
                    }
                    {
                        !error && !canceled && (
                            <div className={classes.subHeader}>
                                {percents}% COMPLETE
                            </div>
                        )
                    }
                    {
                        canceled
                        && (
                            <div className={classes.subHeader}>
                                CANCELLED BY USER
                            </div>
                        )
                    }
                </>
        )}
        {...other}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
        }}
    />
));

export default SnackbarProgress;

export default theme => ({
    button: {
        minHeight: 36,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold',
        color: theme.palette.grey[900]
    },
    countNumber: {
        color: theme.palette.primary.main
    },
    duplicateButton: {
        minHeight: 36,
        paddingLeft: 45,
        paddingRight: 45,
        width: 220,
        fontWeight: 'bold',
        outline: 'none',
        color: '#fff',
        '&:hover, &:active, &:focus': {
            color: '#fff',
            outline: 'none'
        }
    },
    contentClassName: {
        padding: '65px 25px 40px',
        textAlign: 'center'
    },
    contentTitle: {
        color: theme.palette.grey[900],
        fontSize: 20,
        fontWeight: 700,
        marginTop: 15,
        marginBottom: 20
    },
    contentText: {
        maxWidth: 415,
        margin: 'auto'
    },
    loader: {
        color: '#fff',
        '& svg': {
            margin: 0
        }
    }
});

import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import styles from './styles';

const TagsList = ({ classes, filterSection, setFilterSection, filterFlagged, setFilterFlagged }) => {
    const tag = (item) => {
        const handleClick = () => {
            if (item === 'flagged') setFilterFlagged(0);
            else setFilterSection('all');
        };

        return (
            <div key={item} className={classes.tag}>
                <TruncateWithTooltip
                    capitalize
                    text={item}
                    width={130}
                />
                <IconButton
                    className={classes.closeButton}
                    onClick={handleClick}
                    size="large"
                >
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </div>
        );
    };

    return <>
        { filterSection !== 'all' && tag(filterSection) }
        { !!filterFlagged && tag('flagged') }
    </>;
};

export default withStyles(styles)(TagsList);

import { createContext } from 'react';
import { action, observable } from 'mobx';

class ExportSettingsFormStore {
    @observable excludeExported = false;

    @observable exportQuestions = false;

    @observable includeShareLink = false;

    @observable includeRank = false;

    @observable enabledScoring = false;

    @action setExcludeExported = (value) => {
        this.excludeExported = value;
    };

    @action setExportQuestions = (value) => {
        this.exportQuestions = value;
    };

    @action setIncludeShareLink = (value) => {
        this.includeShareLink = value;
    };

    @action setIncludeRank = (value) => {
        this.includeRank = value;
    };

    @action setEnabledScoring = (value) => {
        this.enabledScoring = value;
    };

    @action setDefault = () => {
        this.excludeExported = false;
        this.exportQuestions = false;
        this.includeShareLink = false;
        this.includeRank = false;
        this.enabledScoring = false;
    }
}

export const exportSettingsFormStore = new ExportSettingsFormStore();
export const exportSettingsFormCtx = createContext(exportSettingsFormStore);

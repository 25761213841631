export default theme => ({
    headerWrapper: {
        padding: '25px 50px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    closeIcon: {
        position: 'absolute',
        left: 8,
        top: 21
    },
    drawerPaper: {
        width: 660,
        zIndex: 100,
        maxWidth: '100%'
    },
    questionWrapper: {
        position: 'relative',
        padding: '28px 38px'
    },
    tabsWrapper: {
        backgroundColor: '#fff',
        width: '100%',
        position: 'relative'
    },
    tab: {
        top: -1,
        whiteSpace: 'nowrap',
        textTransform: 'none',
        color: theme.palette.grey[500],
        fontSize: 14,
        padding: '14px 35px',
        letterSpacing: '0.2px',
        fontWeight: 700,
        flexDirection: 'row',
        opacity: 1,
        minWidth: 'auto',
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        '& svg': {
            marginRight: 5
        },
        '&:last-child': {
            boxShadow: `5px 0px 10px 3px ${theme.palette.grey[200]}`
        },
        '&:not(.Mui-selected):hover': {
            color: theme.palette.grey[600]
        },
        '&.Mui-selected': {
            padding: '13px 30px 15px',
            top: 1,
            color: theme.palette.grey[900],
            '&:after': {
                content: '""',
                position: 'absolute',
                width: '100%',
                backgroundColor: '#fff',
                left: 0,
                bottom: 1,
                height: 2,
                zIndex: 100,
                pointerEvents: 'none'
            }
        }
    },
    flexContainer: {
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            backgroundColor: theme.palette.grey[300],
            left: 0,
            bottom: 0,
            height: 1,
            pointerEvents: 'none'
        }
    },
    indicator: {
        display: 'none'
    },
    tabWrapper: {
        flexDirection: 'row',
        '& > svg': {
            marginRight: 10
        },
        '& > div': {
            maxWidth: 250,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    scrollButtons: {
        position: 'absolute',
        top: 10,
        width: 30,
        height: 30,
        borderRadius: '50%',
        zIndex: 100,
        color: '#fff',
        backgroundColor: theme.palette.grey[300],
        '&:first-child': {
            left: 5
        },
        '&:last-child': {
            right: 5
        },
    },
    skillName: {
        paddingRight: 3,
        fontStyle: 'italic'
    }
});

import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { withStyles } from '@mui/styles';

import CandidatesList from '../components/CandidatesList';
import { hiredCandidateCtx } from './hiredCandidateStore';
import HiredSearchFilterBlock from '../components/HiredSearchFilterBlock';
import HiredStats from '../components/HiredStats';


const styles = {
    wrapper: {
        padding: '0 30px',
        marginTop: 20
    }
};

const HiredCandidates = observer(({ classes }) => {
    const { loadHiredCandidates,
        setLoading, cleanState,
        setFilterCategory, isNoCandidates
    } = useContext(hiredCandidateCtx);


    useEffect(() => {
        setLoading(true);
        setFilterCategory();
        loadHiredCandidates();
        return () => {
            cleanState();
        };
    }, []);

    return (
        <div className={classes.wrapper}>
            {!isNoCandidates && <><HiredStats /><HiredSearchFilterBlock /></>}
            <CandidatesList hired />
        </div>
    );
});

export default withStyles(styles)(HiredCandidates);

import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { bulkUploadAssessmentsJson } from 'requests/ScriptRequests';
import { appCtx } from 'components/appStore';
import SearchField from 'components/inputs/SearchField';
import UploadSvg from './upload.svg';
import { AssessmentQueryCtx } from '../AssessmentList/queryStore';
import TableFilter from './MyAssessmentsTableFilter';
import CreateNewAssessment from '../CreateNewAssessment';


const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: 23,
        width: '100%',
        backgroundColor: '#ffffff',
        borderBottom: `2px solid ${theme.palette.grey[300]}`
    },
    uploadButton: {
        minWidth: 'auto',
        lineHeight: '21px',
        letterSpacing: '0.2px',
        marginRight: 16,
        padding: 6
    },
    searchField: {
        flexGrow: 1,
        margin: '0 21px 0 35px',
        background: theme.palette.grey[300],
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        minWidth: 250,
        height: 40
    },
    filterButton: {
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    headerClass: {
        whiteSpace: 'pre-wrap',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 15
        }
    }
}));

const bulkUploadCompanySlugs = ['delphi', 'jobadder-test-18-06-2021', 'certsy', 'certsy-api-sandbox', 'qa-bulk-upload-test'];

const MyAssessmentsHeader = observer(({ history }) => {
    const classes = useStyles();
    const { flashMessage, company } = useContext(appCtx);
    const { query, setQuery } = useContext(AssessmentQueryCtx);
    const { slug, freeTrialExpired, isSubscriptionPaused } = company;

    const fileInput = React.createRef();

    useEffect(() => () => {
        setQuery('');
    }, []);

    const clickBulkAssessmentsImport = (e) => {
        e.preventDefault();
        fileInput.current.click();
    };

    const onFileJSONLoad = () => {
        const file = fileInput.current.files[0];
        const jsonType = /^.+\.json$/;
        if (file && file.name.match(jsonType)) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                bulkUploadAssessmentsJson(reader.result).then(() => {
                    flashMessage('Assessments successfully uploaded', 'done');
                    history.push('/my-assessments');
                }).catch(() => {
                    flashMessage('Something went wrong.');
                });
            };
        } else {
            flashMessage('Something went wrong.');
        }
    };

    const onChangeSearch = (e) => {
        const { value } = e.target;
        setQuery(value);
    };

    return (
        <div className={classes.wrapper}>
            <Typography variant="h2" className={classes.headerClass}>
                My Assessments
            </Typography>
            {
                bulkUploadCompanySlugs.includes(slug) && (
                    <Button
                        variant="outlined"
                        className={classes.uploadButton}
                        onClick={clickBulkAssessmentsImport}
                    >
                        <img src={UploadSvg} alt="upload" />
                    </Button>
                )
            }

            <SearchField
                value={query}
                className={classes.searchField}
                placeholder="Search"
                onChange={onChangeSearch}
            />
            {!freeTrialExpired && !isSubscriptionPaused && (
                <CreateNewAssessment
                    buttonLabel="New Assessment"
                    className="u-mrg--rx1"
                />
            )}
            <TableFilter className={classes.filterButton} />
            <input type="file" ref={fileInput} onChange={onFileJSONLoad} style={{ display: 'none' }} />
        </div>
    );
});

export default withRouter(MyAssessmentsHeader);

import React from 'react';
import { withStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import styles from './styles';

const TableCellHead = ({
    classes, label, align = 'left',
    hideSort, sortOrder, sortCategory,
    onSort, labelComponent = label,
    className, extraPadding = true
}) => {
    if (!label) return <TableCell className={classes.tableCell} align={align} />;
    const isSameCategory = sortCategory && label.toLowerCase() in sortCategory;
    const hasActiveSort = !hideSort && isSameCategory && sortOrder;

    const onClick = () => {
        let category = label.toLowerCase();
        let order = 'asc';
        if (isSameCategory) {
            if (sortOrder === 'asc') {
                order = 'desc';
            } else if (sortOrder === 'desc') {
                category = null;
                order = null;
            }
        }
        onSort(category, order);
    };

    return (
        <TableCell
            className={clsx(classes.tableCell, className, extraPadding && classes.tableCellExtraPadding)}
            align={align}
        >
            <span
                className={
                    clsx(classes.labelWrapper,
                        hasActiveSort && classes.labelWrapperActive,
                        !hideSort && classes.labelWrapperWithSort)
                }
                onClick={!hideSort ? onClick : undefined}
            >
                {labelComponent}
                {
                    !hideSort && (
                        <span className={classes.arrowWrapper}>
                            <ArrowDropUpIcon className={clsx(classes.sortArrow, classes.sortArrowUp, sortOrder === 'asc' && isSameCategory && classes.sortArrowActive)} />
                            <ArrowDropDownIcon className={clsx(classes.sortArrow, classes.sortArrowDown, sortOrder === 'desc' && isSameCategory && classes.sortArrowActive)} />
                        </span>
                    )
                }
            </span>
        </TableCell>
    );
};

export default withStyles(styles)(TableCellHead);

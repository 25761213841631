import React from 'react';
import Drawer from '@mui/material/Drawer';
import HtmlDescription from 'components/assessments_pages/HtmlDescription';
import { nl2brCustom } from 'helper/commonFunctions';
import QuestionInsights from './components/QuestionInsights';
import EmptyState from './components/EmptyState';
import CorrectAnswerContent from './components/CorrectAnswerContent';

import useStyles from './styles';

const PersistentDrawer = ({
    permanentDrawerWidth, question, status, suggestedAnswerAdded,
    drawerWidth = 310, handleOpen, openedSection, anchor = 'right'
}) => {
    const classes = useStyles({ permanentDrawerWidth, drawerWidth });
    if (!question) return null;
    const { scoringInstruction, exampleAnswers } = question;
    let header = null;
    let content = null;

    switch (openedSection) {
        case 'question-insights':
            header = 'Question Insights';
            content = <QuestionInsights suggestedAnswerAdded={suggestedAnswerAdded} status={status} />;
            break;
        case 'correct-answer':
            header = 'Correct answer examples';
            content = <CorrectAnswerContent exampleAnswers={exampleAnswers} />;
            break;
        case 'instructions':
            header = 'Scoring Instructions';
            content = scoringInstruction ? <HtmlDescription htmlDescription={nl2brCustom(scoringInstruction)} /> : <EmptyState header="Scoring Instructions" />;
            break;
        default:
            break;
    }


    return (
        <Drawer
            anchor={anchor}
            open={Boolean(openedSection)}
            classes={{
                root: classes.drawer,
                paper: classes.drawerPaper
            }}
            onClose={() => { handleOpen(null); }}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
                style: { position: 'absolute' }
            }}
            variant="persistent"
        >
            <div className={classes.header}>
                {header}
            </div>
            {content}
        </Drawer>
    );
};

export default PersistentDrawer;

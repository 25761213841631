import React from 'react';

const Tick = ({ color = '#00A98A' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 8.5C14 12.0899 11.0899 15 7.5 15C3.91015 15 1 12.0899 1 8.5C1 4.91015 3.91015 2 7.5 2" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M12.75 2.75L7 10L4.75 8.25" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Tick;

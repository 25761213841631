import React from 'react';
import TagIcon from 'components/icons/TagIcon';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    text: {
        fontSize: 13,
        color: theme.palette.grey[700]
    }
}));

const TagsBlock = () => {
    const { text } = useStyles();
    return (
        <div className="u-txt--center">
            <TagIcon />
            <Typography variant="h6">
                No candidates tagged
            </Typography>
            <p className={text}>Use tags to segment candidates</p>
        </div>
    );
};

export default TagsBlock;

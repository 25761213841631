import React from 'react';
import { withStyles } from '@mui/styles';
import nl2br from 'react-nl2br';

import Photo from 'components/candidate_cards/CardEmployerPhoto';
import styles from './styles';

const PostItem = ({
    post, classes
}) => {
    const { postedBy, content, timeAgo } = post;
    const { firstName, lastName } = postedBy;
    const fullName = `${firstName} ${lastName || ''}`;

    return (
        <div className={classes.commentWrapper}>
            <div className={classes.commentHeaderWrapper}>
                <Photo
                    className={classes.employerPhoto}
                    user={postedBy}
                    size={40}
                />
                <div>
                    <h3 className="u-txt--12  u-mrg--bx0  u-txt--bold">{fullName}</h3>
                    <span className="u-txt--10  t-txt--grey9">{timeAgo || ''}</span>
                </div>
            </div>
            <div><p>{nl2br(content)}</p></div>
        </div>
    );
};

export default withStyles(styles)(PostItem);

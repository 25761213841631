import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import CopyToClipboard from 'react-copy-to-clipboard';
import { appCtx } from '../../appStore';

const styles = theme => ({
    copyLinkLabel: {
        color: theme.palette.primary.main
    }
});

const ShareJobLinkDialog = ({
    onClose, open, shareLink,
    classes
}) => {
    const { flashMessage } = useContext(appCtx);
    const onCopyLink = () => flashMessage('Job Application link copied to clipboard');

    return (
        <Dialog
            open={Boolean(open)}
            handleClose={onClose}
            titleComponent="Share your new “Job Application” link"
            actionComponent={(
                <Button
                    id="qa-close-job-link-dlg"
                    color="primary"
                    variant="contained"
                    onClick={onClose}
                >
                    Close
                </Button>
            )}
        >
            <p className="t-txt--black-54  u-mrg--bx5 qa-job-link-dlg-txt1">
                Please copy the link shown below. You can paste this link to a social media post, your career site or any job boards for free!
            </p>
            <p className="t-txt--black-54  u-mrg--bx5 qa-job-link-dlg-link">
                <strong>{shareLink}</strong>
                <CopyToClipboard text={shareLink} onCopy={onCopyLink}>
                    <span className={classes.copyLinkLabel}> (copy link)</span>
                </CopyToClipboard>
            </p>
        </Dialog>
    );
};

export default withStyles(styles)(ShareJobLinkDialog);

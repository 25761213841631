import React from 'react';
import { withStyles } from '@mui/styles';
import InfoIcon from 'img/candidateCard/errorIcon.svg';
import styles from './styles';

const EditError = ({ message, classes, className }) => (
    <div className={`${classes.message} ${className}`}>
        <img src={InfoIcon} alt="error" />
        <div>
            { message }
        </div>
    </div>
);

export default withStyles(styles)(EditError);

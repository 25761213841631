import { createContext } from 'react';
import { action, observable } from 'mobx';

class ShortlistStore {
    @observable candidatesIdsToCompare = {};

    @action setCandidatesIdsToCompare = (newCandidatesIdsToCompare) => {
        this.candidatesIdsToCompare = newCandidatesIdsToCompare;
    }

    @action addCandidateCompare = (id, userAuditionId) => {
        this.candidatesIdsToCompare[id] = userAuditionId;
    };

    @action removeCandidateCompare = async (id) => {
        delete this.candidatesIdsToCompare[id];
    };

    @action cleanScript = () => {
        this.candidatesIdsToCompare = {};
    }
}

export const shortlistStore = new ShortlistStore();
export const shortlistCtx = createContext(shortlistStore);

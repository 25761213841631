import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    drawer: {
        width: ({ permanentDrawerWidth }) => permanentDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerPaper: {
        zIndex: 1000,
        borderLeft: ({ isRight }) => (isRight ? `1px solid ${theme.palette.grey[300]}` : 'none'),
        borderRight: ({ isRight }) => (!isRight ? `1px solid ${theme.palette.grey[300]}` : 'none')
    },
    drawerOpen: {
        width: ({ permanentDrawerWidth }) => permanentDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: ({ permanentDrawerWidthMini }) => permanentDrawerWidthMini
    },
    list: {
        padding: 8
    },
    listText: {
        fontWeight: 600,
        color: theme.palette.grey[800]
    },
    listTextActive: {
        color: theme.palette.primary.main
    },
    listIcon: {
        minWidth: 25
    },
    listIconMini: {
        minWidth: 40,
        maxHeight: 25
    },
    listRoot: {
        whiteSpace: 'nowrap',
        marginBottom: 6,
        padding: 8,
        borderRadius: 4
    },
    activeListRoot: {
        '& path, & rect, & ellipse, & circle': {
            stroke: theme.palette.primary.main
        }
    }
}));

import React, { useEffect, useState } from 'react';
// material components
import { withStyles } from '@mui/styles';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import TextInputOutlined from 'libraries/TextInput';

import styles from './styles';

const OrderItem = ({ classes, answerItem, index, removeOrder, handleChangeOrder }) => {
    const [order, setOrder] = useState(answerItem);

    useEffect(() => {
        setOrder({ ...order, valid: answerItem.valid });
    }, [answerItem.valid]);

    const { details, number } = order;

    const handleChangeText = (event) => {
        const { value, name } = event.target;
        setOrder({ ...order, [name]: value });
        handleChangeOrder(index, name, value);
    };

    return (
        <div>
            <div className="u-dsp--distribute u-mrg--bx1">
                <Typography variant="h6">Order {index + 1}</Typography>
                <IconButton
                    type="button"
                    onClick={() => removeOrder(index)}
                    className={classes.closeIcon}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <TextInputOutlined
                className={classes.input}
                value={number}
                name="number"
                variant="outlined"
                label="Order Number"
                onChange={handleChangeText}
                fullWidth
                hasCounter
                maxLength={255}
            />
            <TextInputOutlined
                className={classes.input}
                value={details}
                name="details"
                variant="outlined"
                label="Order Details"
                onChange={handleChangeText}
                fullWidth
                multiline
                hasCounter
                rows={3}
                maxLength={500}
            />
        </div>
    );
};

export default withStyles(styles)(OrderItem);

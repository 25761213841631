export default theme => ({
    popoverContainer: {
        marginTop: 10,
        padding: 25,
        width: 724,

        '& .share-link-button': {
            display: 'none'
        },

        '& .share-link:hover .share-link-button': {
            display: 'inline-block'
        }
    },
    link: {
        fontWeight: 600,
        paddingLeft: 0
    },
    header: {
        fontWeight: 700,
        marginBottom: 0,
        color: theme.palette.grey[900]
    },
    subHeader: {
        color: theme.palette.grey[700],
        fontWeight: 600
    },
    tooltipLink: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    divider: {
        height: 1,
        width: 'calc(100% + 50px)',
        backgroundColor: theme.palette.grey[300],
        margin: '30px -25px 24px -25px'
    }
});

export default theme => ({
    typeWrapper: {
        width: 340,
        maxWidth: '100%'
    },
    summaryContent: {
        margin: 0,
        justifyContent: 'space-between'
    },
    expandIcon: {
        color: theme.palette.grey[400]
    },
    accordion: {
        boxShadow: 'none',
        '&$accordionExpanded': {
            '&:not(:last-child):after': {
                content: '""',
                position: 'absolute',
                opacity: 1,
                bottom: -16,
                left: 0,
                right: 0,
                height: 1,
                transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundColor: 'rgba(0, 0, 0, 0.12)'
            },
            '&:before': {
                opacity: 1
            }
        }
    },
    accordionExpanded: {},
    summaryRoot: {
        color: theme.palette.grey[900],
        fontWeight: 700,
        paddingLeft: 0,
        '&$summaryDisabled': {
            backgroundColor: '#fff',
            opacity: 1
        }
    },
    summaryDisabled: {},
    checkboxLabel: {
        color: theme.palette.grey[900]
    },
    pointsLabel: {
        marginRight: 5,
        color: theme.palette.grey[500]
    },
    headerWrapper: {
        paddingBottom: 10,
        fontWeight: 700,
        marginTop: 25
    },
    ticketNumberColumn: {
        maxWidth: 300,
        width: '50%',
        paddingRight: 20,
        fontSize: 14
    },
    headerColumnsWrapper: {
        width: '50%'
    },
    pointsColumn: {
        width: 100,
        display: 'flex'
    },
    pointsColumnHeader: {
        width: 100
    },
    truncated: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
    },
    skillsColumn: {
        fontWeight: 400,
        fontSize: 14,
        maxWidth: 300,
        paddingRight: 20
    },
    nonPass: {
        width: 20,
        marginLeft: 6
    },
    logsWrapper: {
        whiteSpace: 'pre-line'
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: 600,
        minHeight: 200,
        flexDirection: 'column',
        textAlign: 'center',
        '& img': {
            width: 25,
            marginBottom: 10
        }
    }
});

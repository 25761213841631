import React from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { timeObjectToFormattedString, secondsToTimeObject } from 'helper/commonFunctions';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import TimerIcon from 'img/timer.svg';


import styles from './styles';

const QuestionHeaderLeftPart = observer(({
    classes, question, interview: { timerEnabled },
    rightPart
}) => {
    const { answerType, questionGroup, timeLimit } = question;
    const skillName = questionGroup ? questionGroup.title : '';

    const returnTypeImg = () => {
        if (!answerType) return null;
        return getQuestionTypeInfo(answerType, renderIconAndLabel);
    };

    const renderIconAndLabel = Icon => (
        Icon ? <Icon color="#fff" className={classes.svgIconStyle} /> : null
    );

    return (
        <div className={classes.headerWrapper}>
            <div className={classes.numberQuestion}>
                <div className={classes.skillWrapper}>
                    {returnTypeImg()}{ Boolean(skillName) && <span className="u-mrg--lx1">{ skillName }</span> }
                </div>
                {
                    question.timerEnabled && !timerEnabled && (
                        <div className={classes.questionTimeLimit}>
                            <img src={TimerIcon} alt="t" />&nbsp;
                            { timeObjectToFormattedString(secondsToTimeObject(timeLimit * 60)) }
                        </div>
                    )
                }
                {
                    question.required && (
                        <span className={classes.requiredLabel}>
                            Required
                        </span>
                    )
                }
            </div>
            <div className={classes.actionsWrapper}>
                {rightPart}
            </div>
        </div>
    );
});

export default withStyles(styles)(withRouter(QuestionHeaderLeftPart));

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import * as qs from 'query-string';
import Layout from 'components/layout';
import PageWrapper from 'components/layout/PageWrapper';
import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import IntegrationList from './components/IntegrationList';
import IntegrationContent from './components/IntegrationContent';
import SettingsHeader from '../settings_header';
import PausedLabel from '../PausedLabel';

import styles from './styles';


const integrationList = [
    'Greenhouse', 'Vervoe API', 'PageUp', 'Comeet',
    'Zapier', 'SmartRecruiters v2', 'JobAdder', 'Lever',
    'Oracle', 'iCIMS', 'Jobvite'
];

const IntegrationsManagement = ({ company, history, location, classes }) => {
    const [activeIntegration, setActiveIntegration] = useState(integrationList[0]);
    const parsed = qs.parse(location.search);

    useEffect(() => {
        const selectedIntegration = parsed.integration;
        if (selectedIntegration && integrationList.includes(selectedIntegration)) {
            setActiveIntegration(selectedIntegration);
        } else {
            history.push('/integrations-management');
        }
    }, []);

    const handleListItemChange = (name) => {
        setActiveIntegration(name);
        history.push(`/integrations-management?integration=${name}`);
    };

    if (!company.companySettings) return null;

    return (
        <Layout footer={null}>
            <PausedLabel />
            <SettingsHeader />
            <PageWrapper
                headerLabel="Integrations"
                className="u-z--1  u-pos--relative"
            >
                <ContentWrapper>
                    <div className={classes.integrationWrapper}>
                        <IntegrationList
                            integrations={integrationList}
                            handleListItemChange={handleListItemChange}
                            activeIntegration={activeIntegration}
                        />
                        <div className={classes.contentWrapper}>
                            <div className={classes.contentWrapperHeader}>{activeIntegration}</div>
                            <IntegrationContent
                                activeIntegration={activeIntegration}
                            />
                        </div>
                    </div>
                </ContentWrapper>
            </PageWrapper>
        </Layout>
    );
}

export default withStyles(styles)(IntegrationsManagement);

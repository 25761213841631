export default theme => ({
    title: {
        margin: '15px 0'
    },
    infiniteScroll: {
        textAlign: 'center',
        position: 'relative',
        paddingTop: 20
    },
    masonryWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '0 -12px 0',
        '& > div': {
            width: 410,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 30
        },
        '@media only screen and (max-width: 1440px)': {
            margin: '0 -9px 0',
            '& > div': {
                width: '31.5%',
                marginLeft: 10,
                marginRight: 10
            }
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: 890,
            margin: '0 auto',
            '& > div': {
                width: 420,
                maxWidth: 420,
                marginLeft: 10,
                marginRight: 10
            }
        },
        '@media only screen and (max-width: 1026px)': {
            '& > div': {
                width: '47.5%'
            }
        },
        '@media only screen and (max-width: 930px)': {
            '& > div': {
                width: 420,
                margin: '0 calc(50% - 210px) 30px'
            }
        }
    },
    loader: {
        display: 'block',
        marginBottom: 40,
        marginTop: 40,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    loadingWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        paddingTop: 5
    },
    linearProgressRoot: {
        height: 6,
        backgroundColor: theme.palette.grey[300]
    }
});

import React, { useEffect, useState } from 'react';

import QuestionWrapper from 'components/marketplace/Preview/common/QuestionWrapper';

import { getQuestionInfo } from 'requests/QuestionsRequests';
import EditQuestionHeader from '../EditQuestionHeader';


const PreviewMode = ({
    isCMS = false, question,
    activeQuestionIndex, audition = {}, questions = [],
    handlePreviewMode, isScreening, ...other
}) => {
    const [questionState, setQuestionState] = useState(question);

    useEffect(() => {
        setQuestionState(question);
        fetchQuestion();
    }, [question.id]);

    const fetchQuestion = () => (
        getQuestionInfo(question.id, isCMS, isScreening)
            .then(({ success, data }) => {
                if (data && success) {
                    setQuestionState(data);
                }
                return data;
            })
            .catch(() => {})
    );

    return (
        <QuestionWrapper
            isPreviewMode
            interview={audition}
            questions={[questionState]}
            activeQuestion={activeQuestionIndex}
            handlePreviewMode={handlePreviewMode}
            QuestionHeaderComp={EditQuestionHeader}
            totalQuestionsNumber={questions.length}
            {...other}
        />
    );
};

export default PreviewMode;

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import stylesJs from './styles';

function SwitchBrandWithLabel({
    checked, value, classes,
    className, onChange,
    label = '', classNameLabel,
    ...other
}) {
    return (
        <FormControlLabel
            control={(
                <Switch
                    checked={checked}
                    onChange={onChange}
                    value={value}
                />
            )}
            classes={{
                root: clsx(classes.switchRoot, className),
                label: clsx(classes.switchLabel, classNameLabel)
            }}
            label={label}
            {...other}
        />
    );
}

export default withStyles(stylesJs)(SwitchBrandWithLabel);

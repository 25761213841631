import React from 'react';

const SvgEdit = ({ other }) => (
    <svg
        {...other}
        style={{ marginRight: '10px' }}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <path fill="#3a3c43" fillRule="evenodd" d="M19.68 5.67l-6.29 6.29a.75.75 0 0 1-1.062-1.06l6.498-6.498h-3.7a.75.75 0 1 1 0-1.5h5.304a.75.75 0 0 1 .75.75v5.303a.75.75 0 1 1-1.5 0V5.669zm-1.308 8.58a.75.75 0 1 1 1.5 0v7.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25v-15a2.25 2.25 0 0 1 2.25-2.25h7.5a.75.75 0 1 1 0 1.5h-7.5a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75v-7.5z" />
    </svg>
);

export default SvgEdit;

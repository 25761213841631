import React, { useEffect, useState } from 'react';

import { withStyles } from '@mui/styles';

import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import QuestionTooltip from 'components/tooltips/QuestionTooltipWhite';
import AssessmentEvents from 'events/AssessmentEvents';

const styles = {
    tooltip: {
        width: 350,
        fontSize: 12
    }
};

const PlagiarismSwitch = ({ question, changeQuestionSettings, classes, audition }) => {
    const [checkPlagiarism, setCheckPlagiarism] = useState();
    const { uuid, name } = audition;
    const { questionSettings: { plagiarismDetection }, id, answerType, sort } = question;

    useEffect(() => {
        setCheckPlagiarism(plagiarismDetection);
    }, [id, answerType]);

    const handleChangeCheckPlagiarism = () => {
        const newValue = !checkPlagiarism;
        setCheckPlagiarism(newValue);
        changeQuestionSettings({ plagiarismDetection: newValue });

        AssessmentEvents.QUESTION_PLAGIARISM_ENABLED({
            enabled: newValue,
            ttUUID: uuid,
            ttName: name,
            questionNumber: sort
        });
    };

    return (
        <>
            <SwitchBrandWithLabel
                label={(
                    <>
                        Include question in plagiarism checker
                        <QuestionTooltip
                            id="include_in_plagiarism_checker"
                            className={classes.tooltip}
                            text='Marking a question as "Included in plagiarism checker" means that candidate similar answer detection will be activated.'
                        />
                    </>
                )}
                checked={checkPlagiarism}
                className="u-mrg--bx0"
                onChange={handleChangeCheckPlagiarism}
            />
        </>
    );
};

export default withStyles(styles)(PlagiarismSwitch);

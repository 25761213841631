import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { checkPluralNew } from 'helper/commonFunctions';

import QuestionList from './QuestionsList';
import GroupActions from './GroupActions';

import styles from './styles';

const GroupItem = ({
    handleMove, removeGroup, editGroupName,
    classes, group, index, groups, reorderGroups
}) => {
    const [expanded, setExpanded] = useState(true);
    const { completeQuestions, title } = group;
    const questionsCountNotFiltered = completeQuestions ? completeQuestions.length : 0;

    const handleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={clsx(classes.groupWrapper, !expanded && classes.groupWrapperClosed)}>
            <Accordion
                className={classes.accordion}
                expanded={expanded}
                onChange={handleExpanded}
            >
                <AccordionSummary
                    className={classes.groupHeader}
                    classes={{
                        expandIconWrapper: classes.expandIconButton,
                        content: classes.accordionSummaryContent
                    }}
                    expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                >
                    <div className="u-dsp--f u-ai--center u-jc--start u-wdt--100p">
                        <TruncateWithTooltip capitalize text={title} width={300} className={classes.groupTitle} />&nbsp;
                        <span className={classes.questionsCountLabel}>
                            {questionsCountNotFiltered} {checkPluralNew(questionsCountNotFiltered, 'Question')}
                        </span>
                    </div>
                    <GroupActions
                        group={group}
                        index={index}
                        groups={groups}
                        removeGroup={removeGroup}
                        editGroupName={editGroupName}
                        reorderGroups={reorderGroups}
                    />
                </AccordionSummary>
                <QuestionList
                    groups={groups}
                    currentGroup={title}
                    list={completeQuestions}
                    handleMove={(e, questionId, groupIndex2) => { handleMove(e, questionId, groupIndex2, index); }}
                />
                <br />
                <br />
            </Accordion>
        </div>
    );
};

export default withStyles(styles)(GroupItem);

import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

// material components
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import {
    changeSimulatorTicket, changeSimulatorArticle,
    reorderSimulatorTicket, reorderSimulatorArticle,
    deleteSimulatorTicket, deleteSimulatorArticle,
    addSimulatorTicket, addSimulatorArticle,
    getSimulatorTickets, getSimulatorArticles,
    duplicateSimulatorTicket, duplicateSimulatorArticle
} from 'requests/QuestionsRequests';

import AddIcon from 'img/add.svg';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import LoaderBounce from 'components/loaders/LoaderBounce';

import { capitalizeFirstLetter } from 'helper/commonFunctions';
import SimulatorAccordion from '../SimulatorAccordion';
import styles from './styles';


let timerId = null;
let timerQuestionId = null;

const SimulatorTypeContent = observer(({
    question, fetchQuestion, entity = 'article',
    setSaving, classes, isCMS, ItemComp
}) => {
    const [loading, setLoading] = useState(true);
    const [entityList, setEntityList] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState({});
    const { id, answerType } = question;
    const { flashMessage } = useContext(appCtx);
    const entityCap = capitalizeFirstLetter(entity);

    useEffect(() => {
        setLoading(true);
        getEntityList();
    }, [answerType, id]);

    const getEntityList = () => {
        const func = entity === 'article' ? getSimulatorArticles : getSimulatorTickets;
        func(id, isCMS)
            .then(({ data, success }) => {
                if (data && success) {
                    setEntityList(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const addEntity = () => {
        setSaving(true);
        const func = entity === 'article' ? addSimulatorArticle : addSimulatorTicket;
        func(question.id, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    const newEntityList = entityList.slice();
                    newEntityList.push({ ...data, expanded: true });
                    setEntityList(newEntityList);
                    flashMessage(`${entityCap} added`, 'done');
                }
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const removeEntity = (e, index) => {
        e.stopPropagation();
        const newEntityList = entityList.slice();
        const { id: ticketId } = newEntityList[index];
        clearTimeout(timerQuestionId);
        setSaving(true);
        const func = entity === 'article' ? deleteSimulatorArticle : deleteSimulatorTicket;
        func(ticketId, isCMS)
            .then(() => {
                newEntityList.splice(index, 1);
                setEntityList(newEntityList);
                timerQuestionId = setTimeout(fetchQuestion, 1500);
                setSaving(false);
                flashMessage(`${entityCap} removed`, 'done');
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const onChange = (index, name, value) => {
        const newEntityList = entityList.slice();
        const { id: ticketId } = newEntityList[index];
        clearTimeout(timerId);
        clearTimeout(timerQuestionId);
        setSaving(true);
        const func = entity === 'article' ? changeSimulatorArticle : changeSimulatorTicket;
        timerId = setTimeout(() => {
            func(ticketId, { [name]: value }, isCMS)
                .then(({ data, success }) => {
                    if (data && success) {
                        const newEntityList2 = entityList.slice();
                        newEntityList2[index].valid = data.valid;
                        setEntityList(newEntityList2);
                        clearTimeout(timerQuestionId);
                        timerQuestionId = setTimeout(fetchQuestion, 1500);
                    }
                    setSaving(false);
                })
                .catch((err) => {
                    if (err.response.data && err.response.data.msg && err.response.status === 422) {
                        flashMessage(err.response.data.msg, 'error');
                    }
                    setSaving(false);
                });
        }, 300);
    };

    const onReorder = (e, targetQuestionIndex, oldIndex, newIndex) => {
        e.stopPropagation();
        const { order } = entityList[targetQuestionIndex];
        const { id: ticketId } = entityList[oldIndex];
        const func = entity === 'article' ? reorderSimulatorArticle : reorderSimulatorTicket;
        return func(ticketId, { newOrder: order + 1 }, isCMS)
            .then(({ success }) => {
                if (success) {
                    const newEntityList = entityList.slice();
                    const element = newEntityList[oldIndex];
                    newEntityList.splice(oldIndex, 1);
                    newEntityList.splice(newIndex, 0, element);
                    setEntityList(newEntityList);
                    flashMessage(`${entityCap} reordered`, 'done');
                }
            });
    };

    const duplicateEntity = (e, index) => {
        e.stopPropagation();
        const { id: ticketId } = entityList[index];
        const func = entity === 'article' ? duplicateSimulatorArticle : duplicateSimulatorTicket;
        return func(ticketId, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    setEntityList([...entityList, { ...data, expanded: true }]);
                    const lastElement = document.getElementById(`${entity}_${entityList.length}`);
                    if (lastElement) {
                        lastElement.scrollIntoView({ behavior: 'smooth' });
                    }
                    flashMessage(`${entityCap} duplicated`, 'done');
                }
            });
    };

    const noValidEntities = entityList.every(({ valid }) => !valid);

    return (
        <>
            {
                !entityList.length && !loading && (
                    <p
                        className={clsx(!entityList.length && classes.emptyWrapper,
                            classes.noTicketsLabel)}
                    >
                        No customer {entity}s created yet
                    </p>
                )
            }
            {
                loading && (
                    <div className="u-txt--center">
                        <LoaderBounce />
                    </div>
                )
            }
            {
                entityList.map((item, index) => (
                    <SimulatorAccordion
                        key={item.id}
                        entity={entity}
                        expanded={item.expanded}
                        entityList={entityList}
                        entityIndex={index}
                        onRemove={e => removeEntity(e, index)}
                        onDuplicate={e => duplicateEntity(e, index)}
                        onReorder={onReorder}
                    >
                        {
                            ItemComp && (
                                <ItemComp
                                    {...{
                                        item,
                                        onChange,
                                        uploadingFiles,
                                        entityIndex: index,
                                        flashMessage,
                                        entityList,
                                        setUploadingFiles,
                                        isCMS,
                                        setSaving,
                                        setEntityList
                                    }}
                                />
                            )
                        }
                    </SimulatorAccordion>
                ))
            }
            <div className={clsx('u-mrg--bx6 u-mrg--tx2', classes.emptyWrapper)}>
                <Button
                    onClick={addEntity}
                    id="qa-add-mlc-ans"
                    color="primary"
                    className="u-txt--bold u-mrg--bx2 u-txt--cap"
                >
                    <img src={AddIcon} alt="x" />&nbsp; New {entity}
                </Button>
                {
                    Boolean(noValidEntities) && !loading && entity !== 'article' && (
                        <Typography
                            variant="h6"
                            color="secondary"
                        >
                            Please add valid {entity}
                        </Typography>
                    )
                }
            </div>
        </>
    );
});

export default withStyles(styles)(SimulatorTypeContent);

export default theme => ({
    mansoryWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 -12px 0',
        '& > div': {
            marginLeft: 12,
            marginRight: 12,
            marginBottom: 30
        },
        '@media only screen and (max-width: 1400px)': {
            width: 990,
            margin: 'auto'
        },
        '@media only screen and (max-width: 1080px)': {
            width: 660,
            margin: 'auto'
        },
        '@media only screen and (max-width: 763px)': {
            width: '100%',
            '& > div': {
                margin: '0 calc(50% - 150px) 30px'
            }
        }
        // [theme.breakpoints.down('md')]: {
        //     margin: '0 -20px 0',
        //     '& > div': {
        //         marginLeft: 20,
        //         marginRight: 20
        //     }
        // },
        // '@media only screen and (max-width: 790px)': {
        //     '& > div': {
        //         margin: '0 calc(50% - 160px) 30px'
        //     }
        // }
    },
    jobCount: {
        marginBottom: 32
    },
    wrapper: {
        borderRadius: 4,
        margin: 'auto',
        '@media only screen and (max-width: 790px)': {
            padding: 15
        },
        '@media only screen and (max-width: 763px)': {
            padding: 0,
            backgroundColor: 'inherit',
            border: 'none'
        }
    },
    notFoundWrapper: {
        border: '1px solid #EAECED',
        backgroundColor: 'white'
    },
    listHeaderWrapper: {
        fontWeight: 800,
        marginBottom: 0,
        marginTop: 0
    },
    listSubHeader: {
        marginTop: 5,
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.grey[600]
    },
    progressWrapper: {
        textAlign: 'center'
    },
    countLabel: {
        fontSize: 14,
        color: theme.palette.grey[500]
    }
});

import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import LoaderBounce from 'components/loaders/LoaderBounce';
import { statsCtx } from 'pages/assessment/statsStore';
import CandidatePhotoInfo from './CandidatePhotoInfo';
import CandidateStatusInfo from './CandidateStatusInfo';
import CommentsInfo from './CommentsInfo';
import ReportCardHeader from './ReportCardHeader';
import TagsInfo from './TagsInfo';
import CardScoreBlock from './CardScoreBlock';
import GradeBlock from './GradeBlock';
import FraudDetectedBanner from './WarningBanner/FraudDetectedBanner';
import CheatDetectedBanner from './WarningBanner/CheatDetectedBanner';
import ManualGradingRequiredBanner from './WarningBanner/ManualGradingRequiredBanner';
import NotCompletedBlock from './NotCompletedBlock';
import SatisfactionBlock from './SatisfactionBlock';
import ExtendRequestedBlock from './ExtendRequestedBlock';
import StatusHistory from './StatusHistory';

import useStyles from './styles';
import { getCandidateProfile } from '../../../requests/APIrequests';


const CandidateCardWrapper = observer(({
    className = '', getUserInfo, company, isLoading,
    user, setUser, handleCloseCard, context = 'report card',
    opportunitySlug, audition, audition: { uuid, name }, isLoadingAudition,
    getTags, companyTags, setTags, isEmployerSortEnabled,
    loadCandidatePosts, propPublic, authorizeGuest, candidatesProp, gradeableCard,
    addCandidateComment, loadGradeQuestion, gradeFunction, isIntegration,
    getAuditionQuestionResults, ReportHeaderComp, hideCandidateDetails,
    onHireChanges, loadFullStats,
    getAuditionScreeningQuestionResults, onDeleteCallback
}) => {
    const [showHireBanner, setShowHireBanner] = useState(false);
    const [profile, setProfile] = useState({});
    const { increaseShortlistCandidatesCount } = useContext(statsCtx);

    const classes = useStyles();

    useEffect(() => {
        setShowHireBanner(false);
    }, [user && user.id]);

    const dataForEvents = {
        candidateId: user ? user.candidateUuid : null,
        ttId: uuid,
        ttName: name,
        context
    };

    const updateUserFields = (updatedFields) => {
        setUser({ ...user, ...updatedFields });
    };

    const getUserProfile = (userUuid, callback = () => {}) => {
        getCandidateProfile(userUuid, Boolean(propPublic))
            .then((response) => {
                const { success, data } = response;
                if (success) {
                    setProfile(data);
                }
            })
            .finally(callback);
    };


    if (isLoading) {
        return (
            <div className="u-txt--center u-pdn--tx10 u-pdn--bx10">
                <LoaderBounce />
            </div>
        );
    }

    if (!user) return null;
    const { permissions, active } = audition;
    const { manualGradeMissing } = user;

    return (
        <div className={clsx(classes.wrapper, className)}>
            <div className={classes.leftPanel}>
                <CandidatePhotoInfo
                    {...{
                        user,
                        context,
                        setUser,
                        audition,
                        company,
                        propPublic,
                        handleCloseCard,
                        getUserInfo,
                        opportunitySlug,
                        updateUserFields,
                        showHireBanner,
                        setShowHireBanner,
                        hideCandidateDetails,
                        getUserProfile,
                        increaseShortlistCandidatesCount,
                        isIntegration,
                        onHireChanges,
                        loadFullStats,
                        onDeleteCallback
                    }}
                />
                {
                    (!propPublic && !isLoadingAudition && !isIntegration && active) && (
                        <ExtendRequestedBlock
                            candidate={user}
                            audition={audition}
                            getUserInfo={getUserInfo}
                        />
                    )
                }
                <CandidateStatusInfo
                    dataForEvents={dataForEvents}
                    user={user}
                    company={company}
                    permissions={permissions}
                    updateUserFields={updateUserFields}
                    showHireBanner={showHireBanner}
                    setShowHireBanner={setShowHireBanner}
                    hideCandidateDetails={hideCandidateDetails}
                    profile={profile}
                    getUserProfile={getUserProfile}
                    isIntegration={isIntegration}
                    propPublic={propPublic}
                    audition={audition}
                />
                <TagsInfo
                    candidate={user}
                    audition={audition}
                    getTags={getTags}
                    setTags={setTags}
                    context={context}
                    companyTags={companyTags}
                    propPublic={propPublic}
                    isIntegration={isIntegration}
                />
                {
                    !candidatesProp && (gradeableCard || !propPublic) && (
                        <CommentsInfo
                            user={user}
                            audition={audition}
                            opportunitySlug={opportunitySlug}
                            loadCandidatePosts={loadCandidatePosts}
                            propPublic={propPublic}
                            authorizeGuest={authorizeGuest}
                            addCandidateComment={addCandidateComment}
                        />
                    )
                }
            </div>
            <div className={classes.contentPanel}>
                {
                    !propPublic && (
                        <>
                            <FraudDetectedBanner
                                candidate={user}
                                audition={audition}
                                getUserInfo={getUserInfo}
                            />
                            <CheatDetectedBanner
                                candidate={user}
                                company={company}
                                audition={audition}
                                getUserInfo={getUserInfo}
                            />
                        </>
                    )
                }
                <ReportCardHeader
                    audition={audition}
                    opportunitySlug={opportunitySlug}
                    candidate={user}
                    propPublic={propPublic}
                    handleCloseCard={handleCloseCard}
                    ReportHeaderComp={ReportHeaderComp}
                />
                {
                    manualGradeMissing && (
                        <ManualGradingRequiredBanner />
                    )
                }
                {
                    user.completed ? (
                        <>
                            {
                                !(gradeableCard && !user.score) && !manualGradeMissing && (
                                    <CardScoreBlock
                                        propPublic={propPublic}
                                        candidate={user}
                                        gradeableCard={gradeableCard}
                                        isEmployerSortEnabled={isEmployerSortEnabled}
                                    />
                                )
                            }
                        </>
                    ) : <>{ !isLoadingAudition ? <NotCompletedBlock audition={audition} /> : null }</>
                }
                {
                    user.statusHistory && <StatusHistory statusHistory={user.statusHistory} />
                }
                {
                    user.completed && (
                        <SatisfactionBlock
                            audition={audition}
                            candidate={user}
                            propPublic={propPublic}
                        />
                    )
                }
                {
                    (user.completed || user.screeningQuestions) && (
                        <GradeBlock
                            audition={audition}
                            candidate={user}
                            propPublic={propPublic}
                            isIntegration={isIntegration}
                            gradeFunction={gradeFunction}
                            updateUserAuditionAndBox={getUserInfo}
                            loadGradeQuestion={loadGradeQuestion}
                            gradeableCard={gradeableCard}
                            authorizeGuest={authorizeGuest}
                            getAuditionResults={getAuditionQuestionResults}
                            getAuditionScreeningResults={getAuditionScreeningQuestionResults}
                        />
                    )
                }
            </div>
        </div>
    );
});

export default withRouter(CandidateCardWrapper);

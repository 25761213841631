import React from 'react';
import { FEEDBACK, TYPING_TEST } from 'helper/constants';
import Button from '@mui/material/Button';
import Tooltip from 'libraries/Tooltip';
import { makePluralString, convertHtmlToStringWithSpaces } from 'helper/commonFunctions';
import Chip from 'components/job_or_assessment_settings/Chip';
import TimerIcon from './timer.svg';

import useStyles from './styles';

const StepperItem = ({ index, opened, item, setSelectedQuestionIndex, selectedQuestionIndex, color, assessmentTimed }) => {
    const {
        wrapper, wrapperLabel,
        wrapperActive, number,
        description, currentQuestionStyle,
        descriptionOpened,
        additionalInfo, timerIcon, requiredLabel,
        wrapperActiveBackground, touchRippleRoot,
        wrapperLabelOpened
    } = useStyles({ color });

    const {
        description: questionDescription, id, type,
        answerType, timeLimit, required, timerEnabled: timedQuestion,
        simulationCandidateQuestionInstructions
    } = item;
    const hasTimer = timedQuestion && !assessmentTimed;
    const timeUnits = hasTimer && makePluralString(timeLimit, 'minute');
    const current = selectedQuestionIndex === index - 1;

    const returnDescription = () => {
        if (type === FEEDBACK) return 'Your feedback';
        if (answerType === TYPING_TEST) return convertHtmlToStringWithSpaces(questionDescription);
        if (hasTimer) return `This question is timed, you'll have ${timeLimit} ${timeUnits} to complete it.`;
        if (!questionDescription && !simulationCandidateQuestionInstructions) return 'This question contains media';
        return convertHtmlToStringWithSpaces(questionDescription || simulationCandidateQuestionInstructions);
    };

    return (
        <Button
            id={`step_${id}`}
            onClick={() => { setSelectedQuestionIndex(index - 1); }}
            classes={{
                root: `${wrapper} ${current ? wrapperActive : ''} ${current && opened ? wrapperActiveBackground : ''}`,
                label: `${wrapperLabel} ${opened ? wrapperLabelOpened : ''}`
            }}
            TouchRippleProps={{
                classes: {
                    root: touchRippleRoot
                }
            }}
        >
            <div className={number}>
                Q{index}
            </div>
            {
                current && <div className={currentQuestionStyle} />
            }
            <div className={`${description} ${opened ? descriptionOpened : ''}`}>
                {returnDescription()}
            </div>
            {
                opened && (
                    <div className={additionalInfo}>
                        {
                            required
                                && <Chip uppercase className={requiredLabel} />
                        }
                        {
                            hasTimer && (
                                <Tooltip
                                    placement="left"
                                    label={`Timed question (${timeLimit} ${timeUnits})`}
                                >
                                    <img src={TimerIcon} className={timerIcon} alt="⌛" />
                                </Tooltip>
                            )
                        }
                    </div>
                )
            }
        </Button>
    );
};

export default StepperItem;

export default theme => ({
    pricingSwitchWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: 14
    },
    pricingSwitch: {
        height: 37
    },
    switchBase: {
        '&$checked + $switchTrack': {
            backgroundColor: '#fff',
            borderColor: '#fff'
        }
    },
    thumb: {
        backgroundColor: theme.palette.primary.main
    },
    checked: {},
    switchTrack: {
        backgroundColor: '#fff',
        borderColor: '#fff'
    },
    inactiveLabel: {
        opacity: 0.7
    },
    pricingLabel: {
        height: 37,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 60
    }
});

import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { loadCompany } from 'requests/APIrequests';
import NotFoundComponent from 'components/not_found';
import { withStyles } from '@mui/styles';
import Icon from './icon.svg';
import { appCtx } from '../../../components/appStore';

const styles = theme => ({
    link: {
        fontWeight: 'bold',
        color: theme.palette.blue[600]
    }
});

const Forbidden = observer(({ classes }) => {
    const { company } = useContext(appCtx);
    const localStorageToken = localStorage.getItem('token');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorageToken) {
            setLoading(true);
            loadCompany()
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) return null;

    return (
        <NotFoundComponent
            icon={Icon}
            mainText="Access is denied."
            additionalText={(
                <>
                    <p>You don’t have permission to view this page.</p>
                    <p>{localStorageToken ? 'Go' : 'You can go'} to&nbsp;
                        {company && localStorageToken
                            && <>
                                <NavLink
                                    to="/my-assessments"
                                    className={classes.link}
                                >
                                    my assessments&nbsp;
                                </NavLink>
                                or try&nbsp;
                            </>}our&nbsp;
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vervoe.zendesk.com/hc/en-us"
                            className={classes.link}
                        >
                            Help Centre
                        </a>
                    </p>
                </>
            )}
        />
    );
});

export default withStyles(styles)(Forbidden);

export default {
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    typeH3: {
        color: '#1a1b1e',
        fontSize: '20px',
        fontWeight: 'bold',
        fontFamily: 'Open Sans'
    },
    tabs: {
        width: '100%',
        height: '36px',
        border: '2px solid #2f3545',
        borderRadius: '3px',
        minHeight: 'auto'
    },
    indicator: { display: 'none' },
    formLabel: {
        fontSize: '14px',
        letterSpacing: '0.2px',
        color: '#3a3c43'
    },
    icon: {
        marginRight: '2px',
        marginTop: '4px',
        background: 'white',
        width: '16px',
        height: '16px'
    },
    labelContainer: {
        padding: 0
    },
    checked: {
        '&+$checkedToggle': {
            background: '#1247F6 !important',
            opacity: 1
        }
    },
    tab__3: {
        boxSizing: 'border-box',
        fontSize: '12px',
        fontWeight: 600,
        color: '#676a78',
        height: '100%',
        minHeight: 'auto',
        minWidth: 'auto',
        width: '33.3333%',
        '&:nth-child(2)': {
            borderLeft: '2px solid #2f3545',
            borderRight: '2px solid #2f3545'
        }
    },
    tab__4: {
        boxSizing: 'border-box',
        fontSize: '12px',
        fontWeight: 600,
        color: '#676a78',
        height: '100%',
        minHeight: 'auto',
        minWidth: 'auto',
        width: '25%',
        '&:nth-child(2)': {
            borderLeft: '2px solid #2f3545',
            borderRight: '1px solid #2f3545'
        },
        '&:nth-child(3)': {
            borderLeft: '1px solid #2f3545',
            borderRight: '2px solid #2f3545'
        }
    },
    flexContainer: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'
    },
    selectedTab: {
        background: '#2f3545',
        color: 'white !important'
    },
    subHeading: {
        fontFamily: 'Open Sans',
        marginTop: 22,
        marginBottom: 8,
        fontSize: 12,
        letterSpacing: '0.2px',
        color: '#9fa0a5'
    },
    btnEdit: {
        background: 'white',
        color: '#1a1b1e',
        borderRadius: 3,
        boxShadow: '0 3px 15px 0 rgba(144, 144, 144, 0.17)',
        width: '100%',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.2px',
        fontFamily: 'Open Sans'
    },
    popper: {
        width: '380px',
        height: '280px',
        zIndex: '10000',
        padding: '22px',
        background: 'white',
        borderRadius: '7px',
        boxShadow: '0 17px 50px 0 rgba(0, 0, 0, 0.19)',
        position: 'absolute',
        top: '60px',
        left: '-300px'
    }
};

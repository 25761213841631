import React from 'react';

const LogoGraphic = function logo({ width = 50, height = 50, fill = '#000', className = '' }) {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 50 50"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Vervoe</title>
            <path
                fill={fill}
                d="M25 0C11.193 0 0 11.193 0 25s11.193 25 25 25 25-11.193 25-25c0-6.63-2.634-12.99-7.322-17.678C37.988 2.634 31.63 0 25 0zm1.56 41.22h-2.84L11 14.25h7.195l7 15.42 6.817-15.42h6.884L26.56 41.22z"
            />
        </svg>
    );
};

export default LogoGraphic;

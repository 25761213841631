import React from 'react';
import { useStyles } from './styles';
import FileAttachment from './file_attachment.svg';

const SimulatorFileAttachment = ({ file }) => {
    const { attachment } = useStyles({});
    if (!file) return null;
    const { fileName, fileUrl } = file;

    return (
        <div key={file} className={attachment}>
            <img src={FileAttachment} alt="" />
            <a
                href={`${process.env.REACT_APP_API_URL}${fileUrl}`}
                target="_blank"
                rel="noreferrer noopener"
            >
                {fileName}
            </a>
        </div>
    );
};

export default SimulatorFileAttachment;

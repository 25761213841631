import React from 'react';
import { withTheme } from '@mui/styles';

const TwitterIcon = ({ theme, active, size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect width={size} height={size} rx="4" fill={active ? theme.palette.primary.main : theme.palette.grey[400]} />
        <path d="M6.04935 7L13.8792 17.397L6 25.85H7.77344L14.6718 18.4491L20.2453 25.85H26.28L18.0094 14.8683L25.3434 7H23.57L17.2171 13.8159L12.084 7H6.04935ZM8.65726 8.29747H11.4296L23.6717 24.5529H20.8995L8.65726 8.29747Z" fill="white" />
    </svg>

);

export default withTheme(TwitterIcon);

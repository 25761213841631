import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Masonry from 'react-masonry-component';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import BoardPagination from 'components/board/board_pagination';
import { clone } from 'helper/commonFunctions';

import NoAssessmentsContent from './NoAssessmentsContent';
import PrivateBoardItem from './PrivateBoardItem';
import NotFoundAssessmentsContent from './NotFoundAssessmentsContent';


import { appCtx } from '../../../appStore';
import TabsSearchHeader from './TabsSearchHeader';

import styles from './styles';
import { AssessmentQueryCtx } from '../queryStore';

const PrivateAssessmentsView = observer(({
    classes, removeAssessments,
    handleDuplicate, changePage,
    assessments, currentPage,
    totalCount, loadingAssessments,
    loadingSearchAssessments,
    setAssessments
}) => {
    const { company, sentTTRequestCount } = useContext(appCtx);
    const { query } = useContext(AssessmentQueryCtx);

    const { companySettings } = company;
    if (!companySettings) return null;
    const hasFoundAssessments = Boolean(assessments && assessments.length);
    const showNoAssessments = Boolean(assessments && !assessments.length && !query);
    const showNoFoundAssessments = Boolean(assessments && !assessments.length && query);
    const totalPages = Math.ceil(totalCount / 12);
    const currentCount = assessments ? assessments.length : 0;

    const setAssessmentFolder = (slug, auditionFolder) => {
        const newAssessments = clone(assessments);
        const index = newAssessments.findIndex(item => item.slug === slug);
        newAssessments[index].auditionFolder = auditionFolder;
        setAssessments(newAssessments);
    };

    return (
        <>
            <TabsSearchHeader
                {...{
                    query,
                    currentCount,
                    loadingAssessments,
                    totalCount,
                    loadingSearchAssessments,
                    requestCounts: sentTTRequestCount
                }}
            />
            {
                !loadingSearchAssessments && !loadingAssessments && (
                    <div className={classes.wrapper}>
                        {
                            showNoAssessments && (
                                <NoAssessmentsContent />
                            )
                        }
                        {
                            showNoFoundAssessments && (
                                <NotFoundAssessmentsContent
                                    query={query}
                                />
                            )
                        }
                        {
                            hasFoundAssessments && (
                                <>
                                    <Masonry component="div" className={classes.mansoryWrapper}>
                                        {
                                            assessments.map((item, index) => (
                                                <PrivateBoardItem
                                                    setAssessmentFolder={setAssessmentFolder}
                                                    assessment={item}
                                                    key={item.slug}
                                                    opportunityIndex={index}
                                                    handleDuplicate={handleDuplicate}
                                                    removeAssessment={() => removeAssessments(index)}
                                                />
                                            ))
                                        }
                                    </Masonry>
                                    <BoardPagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        handleChangePage={changePage}
                                    />
                                </>
                            )
                        }
                    </div>
                )
            }
        </>
    );
});

export default withRouter(withStyles(styles)(PrivateAssessmentsView));

import React, { Fragment } from 'react';

// material components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withStyles } from '@mui/styles';

import styles from '../CodeSettings/styles';

const TabsContainer = ({ items, heading, styleTab, classes, ...other }) => (
    <Fragment>
        <h4 className={classes.subHeading}>{heading}</h4>
        <Tabs
            classes={{
                root: classes.tabs,
                flexContainer: classes.flexContainer,
                indicator: classes.indicator
            }}
            {...other}
        >
            {
                items.map(({ value }) => (
                    <Tab
                        classes={{
                            root: styleTab,
                            selected: classes.selectedTab,
                            labelContainer: classes.labelContainer
                        }}
                        key={value}
                        label={value}
                    />
                ))
            }
        </Tabs>
    </Fragment>
);
export default withStyles(styles)(TabsContainer);

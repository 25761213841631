import React from 'react';


export default ({
    question: { questionHasAnswer }, NoAnswerComponent
}) => {
    if (!questionHasAnswer) return <NoAnswerComponent />;

    return null;
};

import React from 'react';
import clsx from 'clsx';
import Truncate from 'react-truncate';
import DoneTicketIcon from '../../../../assets/done-ticket.svg';
import SourceFacebook from '../../../../assets/source_facebook.svg';
import SourceTwitter from '../../../../assets/source_twitter.svg';
import SourceEmail from '../../../../assets/source_email.svg';
import { useStyles } from './styles';

const Ticket = ({ isActive, hasCheckedAnswer, ticketDateAsText, text, customer, onClick, refs }) => {
    const { wrapper, active, header, ticketStatus, ticketName, ticketDateText, doneIcon, newTicket, ticketSource, ticketText } = useStyles({});

    const sourceIcon = (source) => {
        switch (source) {
            case 'facebook':
                return <img src={SourceFacebook} alt="" />;
            case 'twitter':
                return <img src={SourceTwitter} alt="" />;
            case 'email':
                return <img src={SourceEmail} alt="" />;
            default:
                return null;
        }
    };

    return (
        <div
            role="presentation"
            className={clsx(wrapper, isActive ? active : '')}
            onClick={onClick}
            ref={refs}
        >
            <div className={header}>
                <div className={ticketName}>{customer.name}</div>
                <div className={ticketStatus}>
                    <span className={ticketDateText}>{ticketDateAsText}</span>
                    {hasCheckedAnswer ? <img src={DoneTicketIcon} alt="" className={doneIcon} /> : <span className={newTicket}>New</span>}
                </div>
            </div>
            <div className={ticketSource}>
                { sourceIcon(customer.source) }
                <span>
                    { customer.customerIdentifier }
                </span>
            </div>
            <div className={ticketText}>
                <Truncate lines={3}>
                    {text}
                </Truncate>
            </div>
        </div>
    );
};

export default Ticket;

export default {
    root: {
        width: 50,
        zIndex: 10,
        backgroundColor: '#fff'
    },
    input: {
        fontSize: 14,
        padding: '5px 5px',
        backgroundColor: '#fff'
    },
    notchedOutline: {
        borderColor: '#EBECEE',
        borderWidth: 2,
        borderRadius: 4,
        '& legend': {
            borderBottom: 'none'
        }
    }
};

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    infoWrapper: {
        marginBottom: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    search: {
        height: 40
    },
    sortRoot: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        color: 'rgba(0, 0, 0, 0.26)',
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        fontWeight: 700,
        transition: 'all 0.3s',
        padding: '6px 25px 6px 13px',
        marginLeft: 15,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 7
        },
        '& > svg': {
            marginRight: 8
        }
    }
}));

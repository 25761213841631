import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import CustomMenuItem from '../MenuItem';
import useStyles from './styles';


const CustomGroupItem = ({
    group, allQuestions, onClickGroup,
    onClickQuestion, disabled
}) => {
    const classes = useStyles();
    const { checked, description, id } = group;

    const checkboxProps = {
        disableRipple: true,
        checked: Boolean(checked),
        className: classes.checkbox,
        disabled
    };

    const questionList = allQuestions.filter(({ questionGroupId }) => questionGroupId === id);
    const hasChecked = questionList.some(({ checked: checkedQuestion }) => checkedQuestion);

    return (
        <Accordion
            defaultExpanded={hasChecked}
            classes={{
                root: classes.accordion,
                expanded: classes.accordionExpanded
            }}
        >
            <AccordionSummary
                classes={{
                    root: classes.accordionSummary,
                    expanded: classes.accordionSummaryExpanded,
                    content: classes.accordionSummaryContent,
                    expandIcon: 'u-pdn--x2'
                }}
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
            >
                <MenuItem
                    key={id}
                    value={id}
                    className="u-pdn--lx0"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (disabled) return;
                        onClickGroup();
                    }}
                >
                    <div className={classes.filterOption}>
                        <div className={classes.filterOptionLabel}>
                            <Checkbox
                                {...checkboxProps}
                            />
                            <div className={classes.filterOptionDescription}>
                                {description}
                            </div>
                        </div>
                    </div>
                </MenuItem>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {
                    questionList.map(question => (
                        <CustomMenuItem
                            key={question.id}
                            classes={classes}
                            className="u-pdn--lx3"
                            question={question}
                            disabled={disabled}
                            onClickQuestion={() => onClickQuestion(allQuestions.indexOf(question))}
                        />
                    ))
                }
            </AccordionDetails>
        </Accordion>
    );
};

export default CustomGroupItem;

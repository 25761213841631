export default theme => ({
    card: {
        boxShadow: 'none',
        display: 'inline-block',
        margin: '0 20px 20px',
        height: 95
    },
    cardDisabled: {
        opacity: 0.4
    },
    cardContent: {
        padding: '15px 10px 10px',
        minWidth: 120,
        height: 95,
        textAlign: 'center',
        transition: 'all 0.3s',
        '& $label': {
            whiteSpace: 'nowrap'
        },
        '& svg': {
            marginBottom: 5
        },
        '& svg:not($fillIcon) path, & svg:not($fillIcon) circle, & svg:not($fillIcon) rect': {
            stroke: theme.palette.grey[700]
        },
        '& $fillIcon path, & $fillIcon circle, & $fillIcon rect': {
            fill: theme.palette.grey[700]
        },
        '&:hover svg:not($fillIcon) path, &:hover svg:not($fillIcon) circle, &:hover svg:not($fillIcon) rect': {
            stroke: theme.palette.primary.main
        },
        '&:hover $fillIcon path, &:hover $fillIcon circle, &:hover $fillIcon rect': {
            fill: theme.palette.primary.main
        },
        '&:hover $label': {
            color: theme.palette.primary.main
        }
    },
    fillIcon: {},
    label: {
        margin: 0,
        fontSize: 12,
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme.palette.grey[900]
    },
    subLabel: {
        fontWeight: 600,
        fontSize: 11,
        textTransform: 'uppercase',
        color: theme.palette.grey[400]
    },
    wrapperIcon: {
        columnCount: 5,
        columnWidth: 130,
        backgroundColor: '#ffffff',
        borderRadius: 10,
        margin: '0 -20px 30px'
    },
    noMobileIcon: {
        margin: '0 0 2px 4px',
        maxHeight: 15
    }
});

import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import Tooltip from 'libraries/Tooltip';
import { SALES_SIMULATOR, SALES_SIMULATION_TYPES, QA_SIMULATOR, MARKETING_SIMULATOR } from 'helper/constants';

import stylesJS from './styles';

class QuestionNumberLabel extends React.Component {
    returnTypeImg = () => {
        const { question: questionObj, isTask } = this.props;
        if (!questionObj || !questionObj.answerType) return null;
        let returnedAnswerType = questionObj.answerType;
        if (SALES_SIMULATION_TYPES.includes(returnedAnswerType) && isTask) {
            returnedAnswerType = SALES_SIMULATOR;
        }
        if (questionObj.type === QA_SIMULATOR && isTask) {
            returnedAnswerType = QA_SIMULATOR;
        }
        if (questionObj.type === MARKETING_SIMULATOR && isTask) {
            returnedAnswerType = MARKETING_SIMULATOR;
        }
        return getQuestionTypeInfo(returnedAnswerType, this.renderIconAndLabel);
    };

    renderIconAndLabel = (Icon, label, labelShort = label) => {
        const { classes, theme } = this.props;
        return (
            <Tooltip
                label={labelShort}
                tooltipClass={classes.tooltipClass}
            >
                {
                    Icon && (
                        <span className={classes.iconWrapper}>
                            <Icon color={theme.palette.grey[900]} className={classes.svgIconStyle} />
                        </span>
                    )
                }
            </Tooltip>
        );
    }

    render() {
        const { classes, questionNumber, isTask } = this.props;


        return (
            <div className={classes.header}>
                { isTask ? 'Task' : 'Question'} {questionNumber}
                {this.returnTypeImg()}
            </div>
        );
    }
}

export default withTheme(withStyles(stylesJS)(QuestionNumberLabel));

import { action, computed, observable } from 'mobx';
import { createContext } from 'react';
import { getArchivedCandidates } from 'requests/AssessmentRequests';
import MiscellaneousEvents from 'events/MiscellaneousEvents';

const SORT_PAGE_IDENTIFIER = 'sort-archive-page';


class ArchivedStore {
    @observable allCandidates = [];

    @observable totalCount = 0;

    @observable currentCount = 0;

    @observable page = 1;

    @observable hasMore = false;

    @observable query = '';

    @observable filterCategory = {};

    @observable isLoading = true;

    @observable isLoadingLocal = false;

    @observable sortCategory = {};

    @observable sortOrder = null;

    @observable selectedCandidates = {};

    @observable slug = '';

    timerId = null;

    @computed get selectedCandidatesIds() {
        return Object.keys(this.selectedCandidates);
    }

    @computed get selectedUserAuditionsIds() {
        return Object.keys(this.selectedCandidates).map(key => this.selectedCandidates[key].userAuditionId);
    }

    @computed get selectedColumnUserIds() {
        return Object.keys(this.selectedCandidates).map(key => this.selectedCandidates[key].columnUserId);
    }

    @action setSlug = (slug) => {
        this.slug = slug;
    };

    @action loadCandidates = (query = this.query) => {
        if (!this.slug) return;
        if (localStorage.getItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`)) {
            const [sortBy, sortOrder] = localStorage.getItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`).split(',');
            this.sortCategory[sortBy] = true;
            this.sortOrder = sortOrder;
        }

        const sortCategoryStr = Object.keys(this.sortCategory).join(',');
        this.isLoadingLocal = true;
        getArchivedCandidates(this.slug, this.page, query, sortCategoryStr, this.sortOrder)
            .then(({ success, data }) => {
                if (success && data) {
                    const { items, hasMorePages, total, currentPage } = data;
                    this.allCandidates = items;
                    this.totalCount = total;
                    this.page = currentPage;
                    this.hasMore = hasMorePages;
                }
                this.isLoading = false;
                this.isLoadingLocal = false;
            })
            .catch((error) => {
                this.isLoading = false;
                this.isLoadingLocal = false;
                if (error.response) {
                    const { status } = error.response;
                    if (status === 400 && this.page !== 1) {
                        this.page = 1;
                        this.loadCandidates();
                    }
                }
            });
    };

    @action handleChangeFilter = (e, audition) => {
        const { value } = e.target;
        this.query = value;
        this.page = 1;
        this.isLoadingLocal = true;
        clearTimeout(this.timerId);
        this.timerId = setTimeout(async () => {
            await this.loadCandidates();
            MiscellaneousEvents.CANDIDATE_SEARCH_CONDUCTED({
                searchTerm: value,
                context: 'Invite',
                ttId: audition.uuid,
                ttName: audition.name
            });
        }, 300);
    };

    @action handleChangePage = (page) => {
        this.page = page;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };

    @action handleChangeSortCategory = (sortCategory, sortOrder) => {
        this.sortCategory = { [sortCategory]: true };
        this.sortOrder = sortOrder;
        if (sortCategory && sortOrder) {
            localStorage.setItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`, `${sortCategory},${sortOrder}`);
        } else {
            localStorage.removeItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`);
        }
        this.page = 1;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };


    @action setLoading = (value) => {
        this.isLoading = value;
    };

    @action cleanState = () => {
        this.allCandidates = [];
        this.totalCount = -1;
        this.page = 1;
        this.hasMore = false;
        this.query = '';
        this.isLoading = false;
        this.isLoadingLocal = false;
        this.sortCategory = {};
        this.sortOrder = null;
        this.clearSelection();
        this.slug = '';
    };

    @action toggleSelectedUserAuditionIds = (candidate) => {
        const { candidateId } = candidate;
        if (this.selectedCandidates[candidateId]) {
            delete this.selectedCandidates[candidateId];
        } else {
            this.selectedCandidates[candidateId] = candidate;
        }
    };

    @action clearSelection = () => {
        this.selectedCandidates = {};
    };

    @action toggleSelectAll = (selected) => {
        if (selected) {
            this.selectedCandidates = {};
        } else {
            this.allCandidates.forEach((item) => {
                this.selectedCandidates[item.candidateId] = item;
            });
        }
    };
}

export const archivedStore = new ArchivedStore();
export const archivedCtx = createContext(archivedStore);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        margin: '7px auto 0',
        padding: '25px 0 22px',
        borderTop: `2px solid ${theme.palette.grey[300]}`
    },
    settingsLabel: {
        textTransform: 'uppercase',
        marginBottom: 0,
        fontWeight: 700,
        marginLeft: -5,
        fontSize: 16,
        color: theme.palette.grey[600]
    }
}));

import React from 'react';
import Transcript from 'components/candidate_cards/Transcript';
import MediaPlayer from 'components/media_player';

const MediaAnswer = ({ question: { ziggeo }, audio, NoAnswerComponent, showTranscript }) => (
    ziggeo ? (
        <div>
            <MediaPlayer audio={audio} ziggeo={ziggeo} />
            {
                showTranscript && ziggeo && ziggeo.transcription && (
                    <div className="u-txt--left u-mrg--tx6">
                        <Transcript value={ziggeo.transcription} />
                    </div>
                )
            }
        </div>
    ) : <NoAnswerComponent />
);

export default MediaAnswer;

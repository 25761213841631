import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    dialogHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'rgba(0,0,0,0.63)',
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 'normal',
        padding: '10px 20px',
        width: '100%',
        backgroundColor: theme.palette.grey[100],
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: theme.palette.grey[400],
        borderStyle: 'solid',
        minHeight: 76
    },
    dialogHeaderLabel: {
        marginBottom: 10,
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 700,
        '& > .logo': {
            maxHeight: 45
        }
    },
    button: {
        minWidth: 190,
        marginLeft: 26,
        borderWidth: 2,
        backgroundColor: '#fff'
    },
    dialogRightPart: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    timeLabel: {
        fontWeight: 800,
        fontSize: 14,
        '& > span': {
            textTransform: 'none',
            fontSize: 13,
            fontWeight: 400
        }
    },
    warningTime: {
        color: theme.palette.red[700]
    },
    interviewStatus: {
        fontSize: 13
    }
}));

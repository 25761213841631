import React, { useEffect } from 'react';
import { getCandidateInfoOLD } from 'requests/APIrequests';


const PublicCard = ({ match, history, gradeableCard }) => {
    const { candidateId } = match.params;

    useEffect(() => {
        getUserInfo();
    }, []);

    const getUserInfo = () => {
        getCandidateInfoOLD(candidateId, true)
            .then((response) => {
                const { success, data } = response;
                if (success && data) {
                    const { uuid } = data;
                    history.push(`/candidates/${uuid}/card/${gradeableCard ? 'agency' : 'public'}`);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return null;
};

export default PublicCard;

import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch, Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import PageWrapper from 'components/layout/PageWrapper';
import CandidatesTabs from './components/CandidatesTabs';

import InnerContentWrapper from '../assessment/components/InnerContentWrapper';
import { appCtx } from '../../components/appStore';
import Layout from '../../components/layout';
import InnerHeader from '../../components/layout/InnerHeader';
import AllCandidates from './AllCandidates';
import styles from './styles';
import ProtectedWithAssessmentFlow from '../../components/routes/protected_with_assessment_flow';
import HiredCandidates from './HiredCandidates';
import { checkHasCompanyFeature } from '../../helper/commonFunctions';
import EmployerCard from './employer_card';
import { candidateCtx } from './AllCandidates/candidateStore';

const CandidatesPage = observer(({ classes }) => {
    const { isLoadingCompany, company, flashMessage } = useContext(appCtx);
    const isFreeTrial = company && company.plan && company.plan.name === 'Free Trial';

    if (isLoadingCompany) return <Layout />;
    const { fetchAllCandidatesStats } = useContext(candidateCtx);

    useEffect(() => {
        fetchAllCandidatesStats(); // for hired number label
    }, []);

    return (
        <Layout>
            <InnerHeader
                header={window.location.pathname.includes('hired')
                    ? <>
                        {(isFreeTrial || !checkHasCompanyFeature(company, 'ALL_CANDIDATES_PAGE'))
                            ? <span className={classes.allLabel}>All Candidates</span>
                            : <Link to="/all-candidates"><span className={classes.allLabel}>All Candidates</span></Link>
                        }
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" style={{ margin: '0 15px' }} xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.67158e-08 10.5982L1.34186 12L7 6L1.34186 -6.74726e-08L1.26383e-07 1.40176L4.31628 6L1.67158e-08 10.5982Z" fill="#7F8084" />
                        </svg>
                        Hired
                    </>
                    : 'All Candidates'}
            />
            <PageWrapper className="u-pdn--bx5">
                <InnerContentWrapper
                    className={classes.contentWrapper}
                    contentClassName="u-pdn--x0"
                >
                    <CandidatesTabs />
                    <div className={classes.content}>
                        <Switch>
                            <Route
                                path="/all-candidates/candidate/:user_id"
                                render={props => (
                                    <EmployerCard
                                        {...props}
                                        flashMessage={flashMessage}
                                        getSingleBox={fetchAllCandidatesStats}
                                        candidatesProp
                                    />
                                )}
                            />
                            <Route path="/all-candidates" render={props => (<AllCandidates {...props} />)} />
                            <ProtectedWithAssessmentFlow path="/hired" render={props => (<HiredCandidates {...props} />)} />
                        </Switch>
                    </div>
                </InnerContentWrapper>
            </PageWrapper>
        </Layout>
    );
});

export default withStyles(styles)(CandidatesPage);

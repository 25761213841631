import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    root: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        color: theme.palette.grey[900],
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        fontWeight: 700,
        transition: 'all 0.3s',
        padding: '6px 25px 6px 13px',
        cursor: 'pointer',
        marginLeft: 15,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 7
        },
        '&:not($rootDisabled):hover': {
            borderColor: theme.palette.primary.main
        },
        '& > svg': {
            marginRight: 8
        }
    },
    rootOpened: {
        borderColor: theme.palette.primary.main
    },
    rootActive: {
        paddingRight: 15
    },
    rootDisabled: {
        opacity: 0.8,
        cursor: 'default',
        color: theme.palette.grey[600]
    },
    menu: {
        maxWidth: 310,
        maxHeight: 440,
        overflow: 'auto',
        minWidth: 300,
        padding: 0
    },
    menuItem: {
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[200]}`
        },
        padding: '6px 10px'
    },
    allLabel: {
        fontSize: 14,
        fontWeight: 700,
        color: '#3A3C43'
    },
    filterHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.grey[800],
        padding: '9px 10px 9px 15px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    expandIcon: {
        color: theme.palette.grey[400]
    },
    activeWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 15,
        marginLeft: 15,
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        '& svg': {
            width: 10
        }
    },
    bottomWrapper: {
        textAlign: 'right',
        padding: '16px 25px',
        borderTop: `1px solid ${theme.palette.grey[300]}`
    },
    applyButton: {
        fontWeight: 700,
        padding: '5px 24px',
        minWidth: 90
    },
    questionsWrapper: {
        maxHeight: 250,
        overflowY: 'auto'
    },
    loader: {
        color: '#fff'
    },
    tooltip: {
        maxWidth: 220
    },
    infoTooltip: {
        fontWeight: 400,
        fontSize: 12,
        maxWidth: 300,
        padding: 16
    },
    popover: {
        marginTop: 8
    },
    iconWrapper: {
        display: 'inline-flex',
        marginLeft: 5
    }
}));

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';


const BrandColorDialog = ({ open, onClose }) => (
    <Dialog
        titleComponent="Brand color"
        maxWidth="sm"
        actionComponent={(
            <Button
                color="primary"
                primary
                className="u-pdn--rx5 u-pdn--lx5"
                variant="contained"
                onClick={onClose}
            >
                Got it
            </Button>
        )}
        handleClose={onClose}
        onClose={onClose}
        open={open}
    >
        We have automatically set your company color based on your logo. You can change it below anytime.
    </Dialog>
);

export default BrandColorDialog;

import React from 'react';

// material components
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { makeStyles } from '@mui/styles';

// local files and components
import DoneIcon from 'components/icons/DoneIcon';
import CloseIcon from 'components/icons/CloseIcon';
import lockIcon from 'img/candidateCard/lock.svg';
import styles from './styles.module.scss';


const useStyles = makeStyles(() => ({
    scoreWrapper: {
        marginLeft: 10,
        textAlign: 'right',
        minWidth: 70,
        whiteSpace: 'nowrap'
    }
}));

const ListItemWrapper = ({
    item,
    ...other
}) => {
    const classes = useStyles();
    if (!item) return null;
    const { testPassed, type, name, score } = item;
    return (
        <ListItem className={styles.item} {...other}>
            <div className={styles.iconWrapper}>
                {
                    type === 'autograded'
                    && (
                        <ListItemIcon>
                            <img src={lockIcon} alt="lock" />
                        </ListItemIcon>
                    )
                }
            </div>
            <div className={styles.name}>{name}</div>
            { testPassed ? <DoneIcon /> : <CloseIcon /> }
            {
                type === 'autograded' ? (
                    <div className={`${classes.scoreWrapper} ${testPassed ? styles.passed : styles.failed}`}>
                        {`+${testPassed ? score : 0}/${score} PTS`}
                    </div>
                ) : <div className={classes.scoreWrapper} />
            }
        </ListItem>
    );
};

export default ListItemWrapper;

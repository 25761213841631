import React from 'react';
import { withStyles } from '@mui/styles';
import QuestionContent from 'components/grade_components/QuestionContent';
import LoaderBounce from 'components/loaders/LoaderBounce';
import { SALES_SIMULATOR } from 'helper/constants';

import QuestionResultHeader from './QuestionResultHeader';
import BottomContainer from './BottomContainer';
import AnswerContent from './AnswerContent';
import ScoringTips from './ScoringTips';

const styles = theme => ({
    wrapper: {
        letterSpacing: '0.16px',
        padding: '20px 40px',
        color: theme.palette.grey[900],
        minWidth: 750
    },
    hr: {
        borderTop: `2px solid ${theme.palette.grey[300]}`
    }
});

const QuestionResult = (props) => {
    const {
        classes, userName, saveGrade,
        scriptName, currentGrade,
        question, questionCount,
        questionNumber, canGrade,
        gradeLoading, goToNextQuestion,
        goToPreviousQuestion, isTask,
        gradeableCard, propPublic, setGradeQuestion,
        candidate, audition, isIntegration,
        updateUserAuditionAndBox
    } = props;
    if (!questionCount || !question) {
        return (
            <div className={classes.wrapper}>
                <LoaderBounce />
            </div>
        );
    }

    const { question: questionObj } = question;
    if (!questionObj) return null;
    const { answerType } = questionObj;
    const currentQuestionIndex = questionNumber + 1;

    return (
        <div className={classes.wrapper}>
            <QuestionResultHeader
                isTask={isTask}
                grade={gradeableCard ? currentGrade : question.grade}
                scriptName={scriptName}
                currentQuestion={currentQuestionIndex}
                question={question}
                className="u-pdn--x0 u-pdn--tx3 u-pdn--bx3 u-ai--start"
                audition={audition}
            />
            <hr className="u-mrg--x0 u-mrg--bx3" />

            <QuestionContent question={questionObj} />
            { answerType !== SALES_SIMULATOR && <hr /> }
            <AnswerContent
                canGrade={canGrade}
                audition={audition}
                candidate={candidate}
                question={question}
                questionIndex={questionNumber}
                userName={userName}
                setGradeQuestion={setGradeQuestion}
                propPublic={propPublic}
                updateUserAuditionAndBox={updateUserAuditionAndBox}
                isIntegration={isIntegration}
            />
            <hr />
            <ScoringTips question={question} />
            <BottomContainer
                canGrade={canGrade}
                saveGrade={saveGrade}
                question={question}
                candidate={candidate}
                currentGrade={currentGrade}
                gradeLoading={gradeLoading}
                totalCount={questionCount}
                questionNumber={currentQuestionIndex}
                goToPreviousQuestion={goToPreviousQuestion}
                goToNextQuestion={goToNextQuestion}
                activeAssessment={audition?.active}
                {...{ gradeableCard, propPublic }}
            />
        </div>
    );
};

export default withStyles(styles)(QuestionResult);

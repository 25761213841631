import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        padding: '12px 15px 12px 12px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    switchRoot: {
        margin: 0,
        paddingTop: 3,
        width: '100%',
        backgroundColor: theme.palette.blue[50]
    },
    switchLabel: {
        width: '100%',
        fontWeight: 700,
        position: 'relative',
        fontSize: 12,
        color: theme.palette.grey[900]
    }
}));

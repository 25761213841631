import React from 'react';
import ExistingUsersListItem from './ExistingUsersListItem';

const ExistingUsersList = ({
    users, loadNotInvitedList,
    onRemoveTeamMember, opportunitySlug,
    onChangeTeamMember
}) => (
    <div className="u-mrg--tx3">
        {
            users.map((item, index) => (
                <ExistingUsersListItem
                    user={item}
                    key={item.uuid}
                    userIndex={index - 1}
                    onChangeTeamMember={onChangeTeamMember}
                    onRemoveTeamMember={onRemoveTeamMember}
                    opportunitySlug={opportunitySlug}
                    loadNotInvitedList={loadNotInvitedList}
                />
            ))
        }
    </div>
);

export default ExistingUsersList;

export default theme => ({
    title: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.grey[900],
        margin: '20px 0'
    },
    wrapper: {
        minHeight: 50
    }
});

import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { appCtx } from 'components/appStore';
import { loadAssessmentLinks } from 'requests/AssessmentRequests';
import AssessmentEvents from 'events/AssessmentEvents';
import ShareErrorBanner from 'components/job_or_assessment_settings/ShareErrorBanner';
import AddApplicationLinkButton from 'components/job_or_assessment_settings/AddApplicationLinkButton';
import SocialLinkButtons from 'components/job_or_assessment_settings/SocialLinkButtons';
import { loadAssessmentSettings } from 'requests/ScriptRequests';
import LinkRow from './LinkRow';
import { assessmentSettingsCtx } from '../../../store';
import useStyles from './styles';

const ShareLinks = observer(({ match }) => {
    const { audition } = useContext(assessmentSettingsCtx);
    const { flashMessage } = useContext(appCtx);
    const [loading, setLoading] = useState(false);
    const [twitterShareLink, setTwitterShareLink] = useState('');
    const [facebookShareLink, setFacebookShareLink] = useState('');
    const [linkedinShareLink, setLinkedinShareLink] = useState('');
    const [shareLink, setShareLink] = useState(null);
    const [links, setLinks] = useState({});
    const classes = useStyles();
    const [isEmptyDescription, setIsEmptyDescription] = useState(false);
    const { scriptSlug } = match.params;

    useEffect(() => {
        setLoading(true);
        getAssessmentLinks();
    }, []);

    useEffect(() => {
        if (!audition.slug) return;
        loadAssessmentSettings(audition.slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setIsEmptyDescription(!data.message);
                }
            });
    }, [audition]);

    const getAssessmentLinks = () => {
        loadAssessmentLinks(scriptSlug)
            .then(({ data, success }) => {
                if (success && data) {
                    const {
                        twitterShareLink: newTwitterShareLink,
                        facebookShareLink: newFacebookShareLink,
                        linkedinShareLink: newLinkedinShareLink,
                        shareLink: newShareLink,
                        links: newLinks
                    } = data;
                    setFacebookShareLink(newFacebookShareLink);
                    setLinkedinShareLink(newLinkedinShareLink);
                    setTwitterShareLink(newTwitterShareLink);
                    setShareLink(newShareLink);
                    setLinks(newLinks);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const onCopyLink = ({ name: linkName }) => {
        AssessmentEvents.LINK_COPIED({ linkName, ttId: audition.uuid, ttName: audition.name });
        flashMessage('Link copied to clipboard', 'done');
    };

    const updateLink = (data) => {
        const newLinks = { ...links };
        if (!newLinks[data.id]) newLinks[data.id] = {};
        newLinks[data.id] = { ...newLinks[data.id], ...data };
        setLinks(newLinks);
    };

    const removeLink = (linkId, data, linkName) => {
        AssessmentEvents.LINK_REMOVED({ linkName, ttId: audition.uuid, ttName: audition.name });
        const newLinks = { ...links };
        delete newLinks[linkId];
        setLinks(newLinks);
    };

    const onPostSocialNetwork = (socialNetwork) => {
        AssessmentEvents.POST_SOCIAL({ socialNetwork, ttId: audition.uuid, ttName: audition.name });
    };

    if (audition && !audition.active) return <Redirect to={`/script/edit/${scriptSlug}`} />;


    const applicationLinks = Object.values(links);
    if (shareLink) applicationLinks.unshift({ ...shareLink, main: true, name: shareLink.url });

    return (
        <div className={classes.wrapper}>
            <Typography variant="h6">Invite Links</Typography>
            <p className="u-mrg--tx2 u-mrg--bx4">
                Invite candidates via link through social network or your own careers page using trackable and customised links. Anyone with the link is able to take the assessment.
            </p>
            { loading ? (
                <div className={classes.loaderWrapper}>
                    <CircularProgress
                        color="primary"
                        className={classes.loaderBlock}
                    />
                </div>
            )
                : <>
                    <ShareErrorBanner
                        show={isEmptyDescription}
                        slug={scriptSlug}
                    />
                    <TableContainer className={classes.tableContainer}>
                        <Table>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCellHead className={classes.tableCellHead} label="Source" hideSort />
                                    <TableCellHead className={classes.tableCellHead} label="Applied" align="right" hideSort />
                                    <TableCellHead className={classes.tableCellHead} label="Started" align="right" hideSort />
                                    <TableCellHead className={classes.tableCellHead} label="Completed" align="right" hideSort />
                                    <TableCellHead className={classes.tableCellHead} label="Date applied" align="right" hideSort />
                                    <TableCellHead className={classes.tableCellHead} label="" align="right" hideSort />
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {
                                    applicationLinks
                                        .map(link => (
                                            <LinkRow
                                                key={link.url}
                                                onCopyLink={onCopyLink}
                                                link={link}
                                                slug={scriptSlug}
                                                disabled={isEmptyDescription}
                                                updateLink={updateLink}
                                                removeLink={removeLink}
                                                flashMessage={flashMessage}
                                                audition={audition}
                                            />
                                        ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <AddApplicationLinkButton
                        addLink={updateLink}
                        slug={scriptSlug}
                        audition={audition}
                    />
                    <SocialLinkButtons
                        onClick={onPostSocialNetwork}
                        opportunityOrAssessment={{
                            twitterShareLink,
                            facebookShareLink,
                            linkedinShareLink,
                            slug: scriptSlug,
                            description: audition && audition.description
                        }}
                    />
            </>}
        </div>

    );
});

export default withRouter(ShareLinks);

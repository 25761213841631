import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';

export default function DatePicker({ value, setValue }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={value}
                minDate={new Date('2015-01-01')}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                renderInput={params => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
}

import React from 'react';
import clsx from 'clsx';
import { Link, Redirect } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withStyles } from '@mui/styles';

import TextInputValidated from 'libraries/TextInputValidated';
import { resetPassword } from 'requests/LoginRequests';
import Button from '@mui/material/Button';
import HomepageWrapper from '../common/HomepageWrapper';

import styles from '../common/styles';


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: [],
            errorForm: [],
            openForgotpasswordSent: false
        };
    }

    handleOpenForgotPasswordSent = () => {
        this.setState({ openForgotpasswordSent: true });
    };

    renderError = (formParam) => {
        const { errorForm } = this.state;
        if (errorForm && errorForm[formParam]) {
            return errorForm[formParam];
        }
        return '';
    };

    renderValue = (value) => {
        const { form } = this.state;
        if (form && form[value]) {
            return form[value];
        }
        return '';
    };

    handleChange = (e) => {
        const { form } = this.state;
        const targetName = e.target.name;
        const targetValue = e.target.value;
        const updateForm = { ...form, [targetName]: targetValue };
        this.setState({ form: updateForm });
    };

    reset = (e) => {
        const { form } = this.state;
        e.preventDefault();
        resetPassword(JSON.stringify(form))
            .then((data) => {
                if (data.success) {
                    this.handleOpenForgotPasswordSent();
                } else {
                    this.setState({ errorForm: data.errorForm });
                }
            });
    };

    render() {
        const { openForgotpasswordSent } = this.state;
        const { classes } = this.props;
        const localStorageToken = localStorage.getItem('token');
        if (localStorageToken) {
            return <Redirect to="/welcome" />;
        }
        return (
            <HomepageWrapper>
                { openForgotpasswordSent ? <>
                    <h1 className={classes.heading}>Email sent</h1>
                    <p className={clsx(classes.text, 'u-txt--center')}>
                        Follow instructions from email to set up a new password.
                    </p>
                </> : <>
                    <h1 className={classes.heading}>Forgot password?</h1>
                    <p className={clsx(classes.text, 'u-txt--left')}>
                        No problem! Enter your email and we’ll send you a link to reset your password
                    </p>
                    <ValidatorForm
                        onSubmit={this.reset}
                    >
                        <TextInputValidated
                            variant="outlined"
                            label="Enter your email"
                            fullWidth
                            name="email"
                            type="email"
                            value={this.renderValue('email')}
                            formerror={this.renderError('email')}
                            onChange={this.handleChange}
                            validators={['required', 'isEmail']}
                            errorMessages={['Please enter your email.', 'Please enter a valid work email e.g kate@hooli.com.']}
                        />
                        <Button
                            className={classes.signInButton}
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            Submit
                        </Button>
                    </ValidatorForm>
                    <p className={classes.signUp}>
                        <Link to="/login" className={classes.link} color="primary">Return to sign in</Link>
                    </p>
                </> }
            </HomepageWrapper>
        );
    }
}

export default withStyles(styles)(ForgotPassword);

import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CS_SIMULATOR } from 'helper/constants';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import ExportIcon from './export.svg';

import styles from './styles';

const Simulator = ({ classes, onClick, type = CS_SIMULATOR }) => {
    const renderIconAndLabel = Icon => (
        <div className={classes.iconWrapper}>
            <Icon color="#fff" size={43} className={classes.svgIconStyle} />
        </div>
    );

    return (
        <div className={classes.recordWrapper}>
            {getQuestionTypeInfo(type, renderIconAndLabel)}
            <Typography variant="h6" className="u-mrg--bx2">{getQuestionTypeInfo(type, (Icon, label) => label)}</Typography>
            <Button className={classes.startButton} onClick={onClick}>
                <img src={ExportIcon} alt="" className="u-mrg--rx1" />
                Start Simulation
            </Button>
        </div>
    );
};


export default withStyles(styles)(Simulator);

import React from 'react';

const ShieldIcon = ({ color }) => (
    <svg width="40" height="46" viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 44.543C20 44.543 38.5 35.8344 38.5 22.7715V7.53145L20 1L1.5 7.53145V22.7715C1.5 35.8344 20 44.543 20 44.543Z" fill={color} stroke={color === '#009879' ? '#00876A' : color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.5614 26.5925H18.4638L17.8163 14.1892H22.2089L21.5614 26.5925ZM17.7655 30.9343C17.7655 30.1388 17.9813 29.5802 18.413 29.2585C18.8531 28.9369 19.3821 28.7761 19.9999 28.7761C20.6008 28.7761 21.1171 28.9369 21.5487 29.2585C21.9888 29.5802 22.2089 30.1388 22.2089 30.9343C22.2089 31.696 21.9888 32.2462 21.5487 32.5847C21.1171 32.9233 20.6008 33.0925 19.9999 33.0925C19.3821 33.0925 18.8531 32.9233 18.413 32.5847C17.9813 32.2462 17.7655 31.696 17.7655 30.9343Z" fill="white" />
    </svg>
);

export default ShieldIcon;

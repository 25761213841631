import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    label: {
        color: props => `${props.color}!important`,
        backgroundColor: props => `${props.backgroundColor}!important`,
        marginRight: 10,
        textAlign: 'center',
        textTransform: props => (props.uppercase ? 'uppercase' : 'capitalize'),
        borderRadius: '12px',
        fontSize: '10px',
        fontWeight: 700,
        padding: '5.5px 13px',
        height: 25,
        width: 'fit-content'
    }
}));

const Chip = ({ text = 'Required', color = '#1a1b1e', backgroundColor = '#e6e6e6', uppercase, className }) => {
    const { label } = useStyles({ color, backgroundColor, uppercase });
    return <div className={clsx(label, className)}>{text}</div>;
};

export default Chip;

import React from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import { checkPluralNew } from 'helper/commonFunctions';

import UnpauseButton from './UnpauseButton';
import PauseButton from './PauseButton';

import Icon from './icon.svg';

import styles from './styles';

const PauseBlock = observer(({
    classes, companyPlan, daysOfPauseLeft,
    getCompanyPlan, handleCancelSubscriptionDialog,
    showCancel, pausesCount
}) => (
    <div className={classes.wrapper}>
        <img src={Icon} className="u-mrg--bx2" alt="Paused" />
        <Typography
            className="u-dsp--centered"
            variant="h5"
        >
                Subscription Paused ({daysOfPauseLeft} {checkPluralNew(daysOfPauseLeft, 'day')} remaining)
            <InfoTooltip
                isBlack
                classNameWrapper="u-dsp--centered"
                classNameIcon="u-mrg--lx1"
                text={(
                        <>
                            You can unpause your account at any time from this page. After 3 months your account and billing cycle will automatically restart.
                        </>
                )}
            />
        </Typography>
        <div className="u-dsp--centered u-mrg--tx2">
            {
                showCancel && (
                    <Button
                        color="secondary"
                        className="u-txt--bold u-mrg--rx3"
                        onClick={handleCancelSubscriptionDialog}
                    >
                        Cancel account
                    </Button>
                )
            }

            <PauseButton
                pausesCount={pausesCount}
                daysOfPauseLeft={daysOfPauseLeft}
                companyPlan={companyPlan}
                getCompanyPlan={getCompanyPlan}
            />

            <UnpauseButton getCompanyPlan={getCompanyPlan} />
        </div>
    </div>
));

export default withStyles(styles)(PauseBlock);

import React from 'react';
import RetinaImage from 'react-retina-image';
import { getInitials } from 'helper/commonFunctions';

const CandidatePhoto = ({ className, user, rounded }) => {
    const { firstName, lastName, imageUrl, initials, candidateProfileImage } = user;
    const userName = `${firstName || ''} ${lastName || ''}`;
    const candidateImage = imageUrl || candidateProfileImage;

    return (
        <div
            role="presentation"
            className={className}
        >
            {
                candidateImage
                    ? (
                        <RetinaImage
                            alt={userName}
                            src={candidateImage}
                            style={rounded && { borderRadius: '50%', width: '100%', height: '100%' }}
                        />
                    ) : (
                        <>
                            {initials || getInitials(userName)}
                        </>
                    )
            }
        </div>
    );
};

export default CandidatePhoto;

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { DATE_RANGES } from 'helper/constants';
import DateRangePicker from './DateRangePicker';
import styles from './styles';

const OPTIONS_MAP = {
    [DATE_RANGES.ALL_TIME]: () => [null, null],
    [DATE_RANGES.LAST_7_DAYS]: () => [moment().subtract(7, 'd').format(), moment().format()],
    [DATE_RANGES.LAST_30_DAYS]: () => [moment().subtract(30, 'd').format(), moment().format()],
    [DATE_RANGES.LAST_YEAR]: () => [moment().subtract(1, 'y').format(), moment().format()]
};

const ALL_OPTIONS = [DATE_RANGES.ALL_TIME, DATE_RANGES.LAST_7_DAYS, DATE_RANGES.LAST_30_DAYS, DATE_RANGES.LAST_YEAR];

const setInitialRange = (defaultRange) => {
    if (Array.isArray(defaultRange) && defaultRange.length === 2) {
        return defaultRange;
    }
    return OPTIONS_MAP[defaultRange]();
};

const TimeRange = observer(({
    classes, setIsCustomRange = () => {}, handleDateRange = () => {}, availableOptions = ALL_OPTIONS,
    onOptionClick = () => {}, onCustomRange = () => {}, defaultRange = DATE_RANGES.LAST_7_DAYS, disabled, limitOneYear, hideRanges, disabledSelect
}) => {
    const [dateRange, setDateRange] = useState(setInitialRange(defaultRange));
    const [selectedDateSection, setSelectedDateSection] = useState(1);

    useEffect(() => {
        handleDateRange(dateRange, availableOptions[selectedDateSection]);
    }, [dateRange]);

    const onButtonClick = (option) => {
        const range = OPTIONS_MAP[option]();
        setIsCustomRange(option !== DATE_RANGES.ALL_TIME);
        onOptionClick(option.toLowerCase());
        setSelectedDateSection(availableOptions.indexOf(option));
        setDateRange(range);
    };

    const handleRange = (value) => {
        const newValue = [...value];
        if (!moment(value[1]).isValid()) {
            newValue[1] = new Date();
        }
        const fromDate = moment(newValue[0]);
        const toDate = moment(newValue[1]);

        if (limitOneYear && toDate.diff(fromDate, 'years', true) > 1) {
            newValue[1] = fromDate.add(1, 'years').endOf('day').format();
        } else {
            const toDateFormatted = new Date(toDate.year(), toDate.month(), toDate.date(), 23, 59, 59);
            newValue[1] = moment(toDateFormatted).format();
        }

        onCustomRange(newValue);
        setSelectedDateSection(null);
        setIsCustomRange(true);
        setDateRange(newValue);
    };

    return (
        <>
            {
                !hideRanges && availableOptions.map((option, index) => (
                    <Button
                        key={option}
                        className={clsx(classes.button, selectedDateSection === index && classes.buttonActive)}
                        onClick={() => onButtonClick(option)}
                        disabled={disabled}
                    >
                        {option}
                    </Button>
                ))
            }
            <DateRangePicker dateRange={dateRange} setDateRange={handleRange} disabled={disabled || disabledSelect} limitOneYear={limitOneYear} />
        </>
    );
});

export default withStyles(styles)(TimeRange);

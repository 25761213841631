import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';

import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';
import EditQuestion from 'components/marketplace/EditQuestion';
import AssessmentEvents from 'events/AssessmentEvents';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import { assessmentEditCtx } from '../../store';

import QuestionBanks from './QuestionBanks';
import SuggestedList from './SuggestedList';
import QuestionContentHeader from './QuestionContentHeader';
import EditQuestionHeader from './EditQuestionHeader';
import PreviewMode from './PreviewMode';
import TemplateBanner from './TemplateBanner';
import NoQuestionInSkillBanner from './NoQuestionInSkillBanner';

import styles from './styles';

const QuestionContent = observer(({ classes, question, setSaving }) => {
    const [showTemplateBanner, setTemplateBanner] = useState(false);
    const [previewMode, setPreviewMode] = useState(false);
    const [templateBannerWasShownFor, setTemplateBannerWasShownFor] = useState(null);

    const { company } = useContext(appCtx);
    const {
        questions, assessmentHasTypingTest, activeQuestionIndex,
        noQuestionInSkillBanner, updateQuestion,
        deleteQuestion, selectQuestion, activeTab, setActiveTab,
        savingQuestion, isSelectedQuestionScreening
    } = useContext(assessmentEditCtx);
    const { loadEditScript, audition } = useContext(assessmentSettingsCtx);
    const { scoreType } = audition;
    const showSuggestedBanner = scoreType === 3;
    const { template, id } = question;

    const showQuestionBanks = checkHasCompanyFeature(company, 'QUESTION_BANKS');

    useEffect(() => {
        if (template) {
            setActiveTab(1);
        } else {
            setActiveTab(0);
        }
    }, [id, template]);

    useEffect(() => {
        setTemplateBanner(false);
        setTemplateBannerWasShownFor(null);
        setPreviewMode(question.valid);
    }, [id]);


    const noSuggestedCallback = () => {
        if ((!templateBannerWasShownFor || templateBannerWasShownFor !== id) && template) {
            setTemplateBanner(true);
            setActiveTab(null);
        }
    };

    const handlePreviewMode = () => {
        setPreviewMode(!previewMode);
    };

    const handleChange = (_event, newValue) => {
        setTemplateBanner(false);
        setActiveTab(newValue);
        switch (newValue) {
            case 0: AssessmentEvents.ADD_QUESTION_CREATE_CLICKED(); break;
            case 1: AssessmentEvents.ADD_QUESTION_SUGGESTED_CLICKED(); break;
            case 2: AssessmentEvents.ADD_QUESTION_SAVED_CLICKED(); break;
            default: break;
        }
    };

    if (noQuestionInSkillBanner) {
        return (
            <NoQuestionInSkillBanner />
        );
    }

    const tabList = [{
        label: 'Create',
        value: 0
    }];

    if (showSuggestedBanner) {
        tabList.splice(1, 0, {
            label: 'Suggested',
            value: 1
        });
    }

    if (showQuestionBanks) {
        tabList.splice(2, 0, {
            label: 'Saved',
            value: 2
        });
    }

    if (previewMode) {
        return (
            <PreviewMode
                question={question}
                interview={audition}
                questions={questions}
                activeQuestion={activeQuestionIndex}
                handlePreviewMode={handlePreviewMode}
                totalQuestionsNumber={questions.length}
                deleteQuestion={deleteQuestion}
                isScreening={isSelectedQuestionScreening}
            />
        );
    }


    return (
        <>
            <QuestionContentHeader
                company={company}
                activeTab={activeTab}
                handleChange={handleChange}
                showSuggestedBanner={showSuggestedBanner}
            />
            <div className={classes.tabsWrapper}>
                {
                    showTemplateBanner ? (
                        <TemplateBanner
                            questions={questions}
                            company={company}
                            showSuggestedBanner={showSuggestedBanner}
                            handleTemplateBanner={newValue => handleChange(null, newValue)}
                            activeQuestionIndex={activeQuestionIndex}
                            onUnmount={() => setTemplateBannerWasShownFor(id)}
                        />
                    ) : (
                        <>
                            {
                                activeTab === 0 && (
                                    <EditQuestion
                                        key={id}
                                        audition={audition}
                                        questions={questions}
                                        getAssessment={loadEditScript}
                                        handlePreviewMode={handlePreviewMode}
                                        QuestionHeaderComp={EditQuestionHeader}
                                        totalQuestionsNumber={questions.length}
                                        questionId={question ? question.id : null}
                                        updateQuestion={updateQuestion}
                                        savingQuestion={savingQuestion}
                                        deleteQuestion={deleteQuestion}
                                        activeQuestionIndex={activeQuestionIndex}
                                        selectQuestion={selectQuestion}
                                        setSaving={setSaving}
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                        assessmentHasTypingTest={assessmentHasTypingTest}
                                        isScreening={isSelectedQuestionScreening}
                                    />
                                )
                            }
                            {
                                activeTab === 1 && (
                                    <SuggestedList
                                        question={question}
                                        setActiveTab={setActiveTab}
                                        isScreening={isSelectedQuestionScreening}
                                        noQuestionsCallback={noSuggestedCallback}
                                    />
                                )
                            }
                            {
                                activeTab === 2 && (
                                    <QuestionBanks
                                        question={question}
                                        isScreening={isSelectedQuestionScreening}
                                    />
                                )
                            }
                        </>
                    )
                }
            </div>
        </>
    );
});

export default withStyles(styles)(withRouter(QuestionContent));

export default theme => ({
    switchRoot: {
        marginLeft: 20,
        marginBottom: 5,
        marginTop: -10
    },
    switchLabel: {
        color: '#3a3c43'
    },
    talkToUsLabel: {
        display: 'block',
        textDecoration: 'underline',
        marginTop: 12,
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: 0.2,
        color: theme.palette.primary.main
    },
    alertIcon: {
        marginRight: 5
    },
    checkboxLabel: {
        fontSize: 14,
        color: '#3a3c43'
    },
    linkPermissionsLabel: {
        fontSize: '14px',
        marginBottom: '10px',
        lineHeight: '1.5',
        letterSpacing: '0.3px',
        color: 'rgba(103, 106, 120, 0.5)'
    },
    iconWithLabelWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px'
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 25,
        marginRight: '12px',
        minHeight: '24px'
    },
    lockImg: {
        marginRight: '7px'
    },
    tooltip: {
        width: 'auto'
    }
});

import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import ButtonBase from '@mui/material/ButtonBase';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import CandidatePhoto from 'components/photos/candidate_photo';
import FlagIcon from 'components/grade_components/FlagIcon';
import Tooltip from 'libraries/Tooltip';
import { markAnswerScoreWithFlag } from 'requests/ActiveLearningRequests';
import MiscellaneousEvents from 'events/MiscellaneousEvents';

import styles from './styles';

const TeamMemberScore = ({
    classes, updatedAt, id, user, user: { fullName }, audition: { name: ttName, uuid: ttUUID },
    grade, flagged, flashMessage, filterStats, setFilterStats, questionNumber
}) => {
    const [loading, setLoading] = useState(false);
    const [isFlagged, setIsFlagged] = useState(flagged);

    useEffect(() => {
        setIsFlagged(flagged);
    }, [flagged]);

    const handleClick = async () => {
        setLoading(true);
        try {
            const { data: { flagged: responseFlagged } } = await markAnswerScoreWithFlag(id, !isFlagged);
            setIsFlagged(responseFlagged);
            setFilterStats({ ...filterStats, flagged: filterStats.flagged + (responseFlagged ? 1 : -1) });
            MiscellaneousEvents.SCORE_INCONSISTENCY_MARKED({
                flagged: responseFlagged,
                ttName,
                ttUUID,
                questionNumber
            });
        } catch {
            flashMessage('Something went wrong', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className="u-dsp--f u-ai--center u-jc--sb u-mrg--bx1">
                <div className="u-dsp--f u-ai--center">
                    <CandidatePhoto inverted size={30} user={user} />
                    <div className="u-mrg--lx-1">{fullName}</div>
                </div>
                <div className="u-dsp--f u-ai--center">
                    <span className="u-mrg--rx1">{grade}</span>
                    { loading
                        ? <div className="u-mrg--l/2 u-mrg--t/2"><CircularProgress color="primary" size={15} /></div>
                        : (
                            <Tooltip label={`${isFlagged ? 'Unm' : 'M'}ark score as inconsistent`}>
                                <ButtonBase onClick={handleClick}>
                                    <FlagIcon filled={isFlagged} />
                                </ButtonBase>
                            </Tooltip>
                        )
                    }
                </div>
            </div>
            {updatedAt && <div className={classes.date}><Moment format="D MMM YYYY">{updatedAt}</Moment></div>}
        </div>
    );
};

export default withStyles(styles)(TeamMemberScore);

import { createTheme } from '@mui/material/styles';

import colors from './colors';

const fontFamilies = [
    'Open Sans',
    'Work Sans',
    'Helvetica',
    'Arial',
    'sans-serif'
];

const defaultTheme = createTheme();

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },
    palette: {
        ...colors,
        background: {
            default: '#f5f5f5',
            paper: '#fff'
        }
    },
    typography: {
        fontSize: 14,
        color: colors.grey[900],
        fontFamily: fontFamilies.join(','),
        h1: {
            fontSize: 27,
            fontWeight: 800,
            lineHeight: '35px',
            color: colors.grey[900]
        },
        h2: {
            fontSize: 22,
            fontWeight: 800,
            lineHeight: '32px',
            color: colors.grey[900]
        },
        h3: {
            fontSize: 20,
            fontWeight: 'bold',
            lineHeight: '31px',
            color: colors.grey[900]
        },
        h4: {
            fontSize: 18,
            fontWeight: 'bold',
            lineHeight: '31px',
            color: colors.grey[900]
        },
        h5: {
            fontSize: 16,
            fontWeight: 'bold',
            lineHeight: 2,
            color: colors.grey[900]
        },
        h6: {
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 2,
            color: colors.grey[900]
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                color: 'inherit'
            },
            styleOverrides: {
                root: {
                    fontSize: 14,
                    textTransform: 'none',
                    fontWeight: 600,
                    boxShadow: 'none',
                    '& svg': {
                        margin: 0
                    }
                },
                textPrimary: {
                    '&:disabled': {
                        opacity: 0.5,
                        color: colors.primary.main
                    }
                },
                outlined: {
                    borderRadius: 4,
                    color: colors.grey[900],
                    fontWeight: 'bold',
                    borderWidth: 2,
                    borderColor: colors.grey[300],
                    letterSpacing: '0.2px',
                    '&:hover': {
                        borderWidth: 2
                    },
                    '&:disabled': {
                        borderWidth: 2
                    },
                    '&:hover, &:active, &:focus': {
                        color: colors.grey[900],
                        outline: 'none'
                    }
                },
                outlinedSecondary: {
                    fontWeight: 700,
                    borderWidth: 2,
                    color: colors.secondary.main,
                    borderColor: colors.secondary.main,
                    '&:hover': {
                        borderWidth: 2,
                        color: '#fff',
                        backgroundColor: colors.secondary.main,
                        borderColor: colors.secondary.main
                    },
                    '&:disabled': {
                        opacity: 0.5,
                        borderWidth: 2,
                        color: colors.secondary.main,
                        borderColor: colors.secondary.main
                    }
                },
                outlinedPrimary: {
                    borderWidth: 2,
                    borderColor: colors.primary.main,
                    '&:hover': {
                        borderWidth: 2,
                        color: '#fff',
                        backgroundColor: colors.primary.main,
                        borderColor: colors.primary.main
                    },
                    '&:disabled': {
                        opacity: 0.5,
                        borderWidth: 2,
                        color: colors.primary.main,
                        borderColor: colors.primary.main
                    }
                },
                contained: {
                    boxShadow: 'none',
                    fontWeight: 700,
                    '&$disabled': {
                        color: '#fff',
                        opacity: 0.5
                    },
                    '& .MuiCircularProgress-root': {
                        color: '#fff'
                    }
                },
                containedPrimary: {
                    '&:disabled': {
                        color: '#fff',
                        backgroundColor: colors.primary.main,
                        opacity: 0.5
                    }
                },
                containedSecondary: {
                    '&$disabled': {
                        backgroundColor: colors.secondary.main
                    }
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    '& svg': {
                        margin: 0
                    }
                }
            }
        },
        MuiButtonGroup: {
            defaultProps: {
                color: 'inherit'
            }
        },
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    [defaultTheme.breakpoints.up('lg')]: {
                        maxWidth: 1624
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff'
                },
                select: {
                    fontWeight: 600,
                    color: colors.grey[900]
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: 8
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: colors.grey[500],
                    fontWeight: 600,
                    maxWidth: '95%',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    '&$focused': {
                        color: colors.primary.main
                    },
                    '&$shrink': {
                        maxWidth: '100%'
                    },
                    '&$error': {
                        color: colors.red.primary
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                    '&$disabled': {
                        opacity: 0.5,
                        cursor: 'not-allowed',
                        color: colors.grey[900]
                    }
                },
                input: {
                    color: colors.grey[900],
                    fontWeight: 600,
                    fontSize: 14,
                    '&::placeholder': {
                        fontSize: 14,
                        color: 'rgba(46, 54, 85, 0.67)'
                    }
                },
                inputMultiline: {
                    lineHeight: 1.3,
                    fontSize: 14
                },
                underline: {
                    '&:hover:not($disabled):before': {
                        borderBottom: `2px solid ${colors.primary.main}`
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                    backgroundColor: '#fff',
                    '&.Mui-disabled': {
                        opacity: 0.7,
                        cursor: 'not-allowed',
                        color: colors.grey[900]
                    },
                    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.grey[300]
                    },
                    '&:hover:not(.Mui-error):not(.MuiOutlinedInput-focused):not(.Mui-disabled)': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors.primary.main
                        }
                    },
                    '&.MuiOutlinedInput-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#436cff'
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.red.primary
                    }
                },
                input: {
                    color: colors.grey[900],
                    fontWeight: 600,
                    fontSize: 14,
                    '&::placeholder': {
                        fontSize: 14,
                        color: 'rgba(46, 54, 85, 0.67)'
                    }
                },
                inputMultiline: {
                    lineHeight: 1.3,
                    fontSize: 14
                },
                notchedOutline: {
                    borderColor: colors.grey[300],
                    borderWidth: 2,
                    '& legend': {
                        width: 'auto',
                        borderBottom: 'none'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: colors.grey[500],
                    '&$disabled': {
                        color: colors.primary.main
                    }
                }
            }
        },
        MuiRadio: {
            defaultProps: {
                color: 'primary'
            },
            styleOverrides: {
                root: {
                    color: colors.grey[500],
                    '&$disabled': {
                        color: colors.primary.main
                    }
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    'strong, b': {
                        fontWeight: 700
                    },
                    body: {
                        fontSize: 14
                    }
                }
            }
        },
        MuiSwitch: {
            defaultProps: {
                color: 'primary'
            },
            styleOverrides: {
                root: {
                    width: 68,
                    height: 'auto',
                    '&$checked': {
                        transform: 'translateX(15px)',
                        '& + .MuiSwitch-track': {
                            opacity: 1,
                            border: 'none'
                        }
                    }
                },
                switchBase: {
                    left: 5,
                    '&.Mui-checked': {
                        color: 'white',
                        '& + .MuiSwitch-track': {
                            opacity: 1,
                            backgroundColor: colors.primary.main
                        }
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5
                    },
                    '&.Mui-checked.Mui-disabled': {
                        color: '#f8f8fa'
                    },
                    transition: defaultTheme.transitions.create('transform', {
                        duration: defaultTheme.transitions.duration.shortest,
                        easing: defaultTheme.transitions.easing.sharp
                    })
                },
                track: {
                    borderRadius: 12,
                    width: 45,
                    height: 24,
                    marginTop: -5,
                    marginLeft: 0,
                    border: 'solid 1px',
                    borderColor: colors.grey[400],
                    backgroundColor: colors.grey[500],
                    opacity: 1,
                    transition: defaultTheme.transitions.create(['background-color', 'border'])
                },
                checked: {}
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: 10,
                    fontWeight: 600,
                    color: colors.grey[700],
                    '&$error': {
                        color: colors.red.primary
                    }
                }
            }
        }
    }
});

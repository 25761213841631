import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    paper: {
        width: 500,
        height: '70%',
        marginLeft: '50%',
        [theme.breakpoints.down('md')]: {
            marginLeft: '20%'
        }
    },
    dialogContent: {
        overflowX: 'hidden',
        padding: 0
    },
    dialogTitle: {
        padding: '17px 21px',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[300],
        '& > *': {
            fontSize: 14,
            fontWeight: 700
        }
    },
    dialogActions: {
        height: 80,
        padding: '20px 30px',
        boxShadow: '0px 0px 24px rgba(37, 56, 78, 0.08)',
        margin: 0
    },
    sendButton: {
        minWidth: 180,
        height: 40,
        color: 'white',
        '&:hover': {
            background: theme.palette.blue[700]
        }
    },
    answerBlock: {
        padding: '24px 30px 24px 18px',
        display: 'flex',
        alignItems: 'flex-start',
        margin: 0,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[300],
        '&.active': {
            backgroundColor: theme.palette.grey[100]
        },
        '&:last-child': {
            borderBottomWidth: 0
        }
    },
    radioButton: {
        color: `${theme.palette.blue[600]}!important`,
        paddingTop: 0
    },
    attachment: {
        marginTop: 15
    }
}));

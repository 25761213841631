import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';

import { createScript, getAuditionCreateCategories, getRolesWithSkills } from 'requests/ScriptRequests';
import TTBuilderEvents from 'events/TTBuilderEvents';
import Dialog from 'libraries/Dialog';
import { appCtx } from 'components/appStore';

import Select from 'libraries/Select';
import FooterPreselectedTTBuilder from './components/FooterPreselectedTTBuilder';
import FooterCustomTTBuilder from './components/FooterCustomTTBuilder';

import stylesJs from './styles';
import PreselectedFlow from './components/PreselectedFlow';
import CustomFlow from './components/CustomFlow';

const FLOW_TYPE = {
    preselected: 'PRESELECTED',
    custom: 'CUSTOM'
};

const TTBuilderDialog = ({ open, flashMessage, history, classes }) => {
    const [loading, setLoading] = useState(false);
    const [flowType, setFlowType] = useState(FLOW_TYPE.preselected);
    const [writingSkills, setWritingSkills] = useState([]);
    const [manualAddedSkills, setManualAddedSkills] = useState([]);

    const [customTitle, setCustomTitle] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    const [currentStep, setCurrentStep] = useState(1);
    const [roles, setRoles] = useState({});
    const [selectedRoleName, setSelectedRoleName] = useState('');
    const [eventFired, setEventFired] = useState(false);

    const { company, updateCompany, closeTTBuilderDialog } = useContext(appCtx);

    const fetchCategories = useCallback(async () => {
        const data = await getAuditionCreateCategories();
        const preparedData = data.map(category => ({ label: category, value: category }));
        setCategories(preparedData);
    }, []);

    const fetchRolesWithSkills = useCallback(async () => {
        const data = await getRolesWithSkills(selectedCategory);
        setRoles(data);
    }, [selectedCategory]);

    useEffect(() => {
        if (open && !eventFired) {
            TTBuilderEvents.INPUT_SCREEN_VIEW();
            setEventFired(true);
        }

        if (!open) {
            setDefaultValues();
            return;
        }

        if (currentStep === 1 && !categories.length) {
            fetchCategories();
        }

        if (currentStep === 2) {
            setFlowType(selectedCategory === 'Other' ? FLOW_TYPE.custom : FLOW_TYPE.preselected);
            fetchRolesWithSkills();
        }
    }, [currentStep, open, fetchCategories, fetchRolesWithSkills]);

    const setDefaultValues = () => {
        setLoading(false);
        setCustomTitle('');
        setSelectedRoleName('');
        setSelectedCategory('');
        setWritingSkills([]);
        setManualAddedSkills([]);
        setFlowType(FLOW_TYPE.preselected);
        setCurrentStep(1);
    };

    const submitDescription = async () => {
        const skills = flowType === FLOW_TYPE.preselected ? roles[selectedRoleName] : [...writingSkills, ...manualAddedSkills];
        const roleName = flowType === FLOW_TYPE.preselected ? selectedRoleName : customTitle;

        TTBuilderEvents.DESCRIPTION_SAVED({
            skills,
            numberSkills: skills.length,
            title: roleName
        });

        setLoading(true);

        localStorage.setItem('assessmentName', roleName);
        localStorage.setItem('assessmentSkills', JSON.stringify(skills));

        try {
            const { success, data } = await createScript({ name: customTitle, skills, roleName, categoryName: selectedCategory });
            closeTTBuilderDialog();
            if (success && data) {
                if (data.active) {
                    const { companySettings } = company;
                    const { activeAuditionsPerCompanyUsed } = companySettings;
                    updateCompany({
                        ...company,
                        companySettings: {
                            ...companySettings,
                            activeAuditionsPerCompanyUsed: activeAuditionsPerCompanyUsed + 1
                        }
                    });
                }
                history.push(`/script/edit/${data.slug}`);
            }
        } catch (e) {
            let errorLabel = '';
            if (e.response.data && e.response.data.errors) {
                Object.keys(e.response.data.errors).forEach((key) => {
                    const error = e.response.data.errors[key][0];
                    errorLabel += error && error.message ? error.message : '';
                });
            }

            TTBuilderEvents.DESCRIPTION_SAVED_FAILED({
                skills,
                numberSkills: skills.length,
                title: customTitle
            });
            flashMessage(errorLabel || 'Something went wrong', '', 10000);
            setLoading(false);
        }
    };

    const handleClose = () => {
        closeTTBuilderDialog();
    };

    const handleSelectCategory = (e) => {
        setCustomTitle('');
        setSelectedRoleName('');
        setWritingSkills([]);
        setManualAddedSkills([]);
        setSelectedCategory(e.target.value);
    };

    const isDisabledNextButton = () => (currentStep === 1 && !selectedCategory) || (currentStep === 2 && !selectedRoleName) || (currentStep === 4 && customTitle.length < 2);

    const addedSkills = [...writingSkills, ...manualAddedSkills];

    const resetStep = () => {
        setCurrentStep(1);
        setFlowType(FLOW_TYPE.preselected);
    };

    const setStep = (newStep) => {
        setCurrentStep(newStep);
        if (newStep === 2) {
            TTBuilderEvents.CATEGORY_ADDED({ roleCategory: selectedCategory });
        }
        if (newStep === 3) {
            TTBuilderEvents.ROLE_ADDED({ role: selectedRoleName });
        }
    };

    const renderFooter = () => {
        if (flowType === FLOW_TYPE.preselected) {
            return (
                <FooterPreselectedTTBuilder
                    lastStep={currentStep === 4}
                    step={currentStep}
                    setStep={setStep}
                    disabled={isDisabledNextButton()}
                    loading={loading}
                    submitDescription={submitDescription}
                    onClose={closeTTBuilderDialog}
                />
            );
        }

        return (
            <FooterCustomTTBuilder
                loading={loading}
                customTitle={customTitle}
                addedSkills={addedSkills}
                submitDescription={submitDescription}
                resetStep={resetStep}
            />
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            titleComponent="Create new assessment"
            actionComponent={renderFooter()}
            contentClassName={classes.root}
            actionClassName={classes.actions}
        >
            <div className={classes.content}>
                {currentStep === 1 && (
                    <div className={classes.contentWrapper}>
                        <div className={classes.title}>What kind of role are you hiring for?</div>
                        <Select
                            label="Category"
                            options={categories}
                            value={selectedCategory}
                            onChange={handleSelectCategory}
                        />
                    </div>
                )}
                {flowType === FLOW_TYPE.preselected && (
                    <PreselectedFlow
                        currentStep={currentStep}
                        submitDescription={submitDescription}
                        customTitle={customTitle}
                        setCustomTitle={setCustomTitle}
                        setSelectedRoleName={setSelectedRoleName}
                        selectedRoleName={selectedRoleName}
                        roles={roles}
                    />
                )}
                {flowType === FLOW_TYPE.custom && (
                    <CustomFlow
                        customTitle={customTitle}
                        setCustomTitle={setCustomTitle}
                        writingSkills={writingSkills}
                        setWritingSkills={setWritingSkills}
                        manualAddedSkills={manualAddedSkills}
                        setManualAddedSkills={setManualAddedSkills}
                        addedSkills={addedSkills}
                    />
                )}
            </div>
        </Dialog>
    );
};

export default withStyles(stylesJs)(withRouter(TTBuilderDialog));

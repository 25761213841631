import { tracking } from 'helper/eventSegment';

export default {
    PREVIEW_VIEW_FAILED: () => {
        tracking('AB-preview-view-failed');
    },
    INVITE_CANDIDATES_CLICKED: (data) => {
        tracking('AL-invite-candidates-clicked', data);
    },
    FOLDER_CREATED: (data) => {
        tracking('TT-folder-created', data);
    },
    FOLDER_DELETED: (data) => {
        tracking('TT-folder-deleted', data);
    },
    FOLDER_NAME_EDITED: (data) => {
        tracking('TT-folder-name-edited', data);
    },
    ASSESSMENT_MOVED_TO_FOLDER: (data) => {
        tracking('L-private-tt-folder-moved', data);
    },
    ASSESSMENT_REMOVED_FROM_FOLDER: (data) => {
        tracking('L-private-tt-folder-removed', data);
    },
    CANDIDATE_SEARCH_CONDUCTED: (data) => {
        tracking('G-candidate-search-conducted', data);
    },
    EMAIL_SETTINGS_UPDATED: (data) => {
        tracking('TT-email-settings-updated', data);
    },
    EMAIL_SETTINGS_RESETED: (data) => {
        tracking('TT-email-settings-reseted', data);
    },
    CANDIDATE_SMS_RESENT: (data) => {
        tracking('G-candidate-sms-resent', data);
    },
    CANDIDATE_PHONE_CHANGED: (data) => {
        tracking('G-candidate-phone-changed', data);
    },
    INTEGRATION_CARD_VIEWED: (data) => {
        tracking('G-integration-card-viewed', data);
    },
    SCORE_INCONSISTENCY_MARKED: (data) => {
        tracking('AL-score-inconsistency-marked', data);
    },
    AI_HEALTH_SCORES_EXPORTED: (data) => {
        tracking('AL-scores-exported', data);
    },
    ARCHIVED_CANDIDATE: (data) => {
        tracking('G-candidate-archived', data);
    },
    UNARCHIVED_CANDIDATE: (data) => {
        tracking('G-candidate-unarchived', data);
    }
};

import React from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import LoaderBounce from 'components/loaders/LoaderBounce';

import EmptyDataBlock from './EmptyDataBlock';

const styles = theme => ({
    wrapper: {
        padding: '35px 30px 30px',
        borderTop: `1px solid ${theme.palette.grey[300]}`
    },
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& > h5': {
                marginBottom: 10
            }
        }
    },
    loaderWrapper: {
        minHeight: 400,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const InsightsSectionWrapper = observer(({
    classes, audition, rightPart,
    header, children, emptyLabel, imgSrc,
    loading, isEmpty, customEmptyLabel,
    selectedQuestionGroup = 'all'
}) => {
    const headerComponent = (
        <div className={classes.headerWrapper}>
            {header}
            <div className="u-dsp--centered u-ai--center">
                {rightPart}
            </div>
        </div>
    );

    if (loading) {
        return (
            <div className={classes.wrapper}>
                {headerComponent}
                <div className={classes.loaderWrapper}>
                    <LoaderBounce />
                </div>
            </div>
        );
    }

    if (isEmpty) {
        return (
            <div className={classes.wrapper}>
                {selectedQuestionGroup === 'all' ? header : headerComponent}
                <EmptyDataBlock
                    label={emptyLabel}
                    imgSrc={imgSrc}
                    audition={audition}
                    selectedQuestionGroup={selectedQuestionGroup}
                    customEmptyLabel={customEmptyLabel}
                />
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            {headerComponent}
            {children}
        </div>
    );
});

export default withStyles(styles)(withRouter(InsightsSectionWrapper));

import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Tooltip from 'libraries/Tooltip';
import CandidateDetailsDialog from 'components/dialogs/CandidateDetailsDialog';
import { checkHasUserPermission } from 'helper/commonFunctions';
import PaperPlaneIcon from './icon';
import { appCtx } from '../../appStore';

const SmsIndication = ({ candidate, actionCallback, hideLinks, audition }) => {
    const [hovered, setHovered] = useState(false);
    const [candidateDetailsDialogOpened, setCandidateDetailsDialogOpened] = useState(false);
    const [smsSentUnsync, setSmsSentUnsync] = useState(null);
    const { company, flashMessage } = useContext(appCtx);
    const invite = checkHasUserPermission(company, audition.permissions, 'invite');

    const { smsSent, smsSentAt } = candidate;

    useEffect(() => {
        setSmsSentUnsync(smsSent ? smsSentAt : null);
    }, [candidate]);

    if (smsSent === null) return null;

    const handleCandidateDetailsDialog = (event) => {
        if (event) event.stopPropagation();
        setCandidateDetailsDialogOpened(!candidateDetailsDialogOpened);
    };

    const onSmsCallback = () => {
        setCandidateDetailsDialogOpened(false);
        if (actionCallback) actionCallback();
    };

    const label = smsSentUnsync
        ? <>
            <b>SMS Sent</b><br />{moment(smsSentUnsync).format('MMM Do, YYYY')} &nbsp;
            { !hideLinks && <span className="u-txt--underline" role="button" tabIndex={0} onClick={handleCandidateDetailsDialog}>Update details</span> }
        </>
        : (
            <>
                <b>SMS not Sent</b><br />
                { !hideLinks && (
                    <span
                        className="u-txt--underline"
                        role="button"
                        tabIndex={0}
                        onClickCapture={handleCandidateDetailsDialog}
                    >
                        { invite ? 'Resend Invitation' : 'Update details' }
                    </span>
                )}
            </>
        );

    return (
        <>
            <Tooltip label={label}>
                <span
                    onMouseOver={() => setHovered(true)}
                    onFocus={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    style={{ height: 28 }}
                >
                    <PaperPlaneIcon color={smsSentUnsync ? '#00A98A' : '#FF113D'} hovered={hovered} />
                </span>
            </Tooltip>
            <CandidateDetailsDialog
                open={candidateDetailsDialogOpened}
                onClose={() => setCandidateDetailsDialogOpened(false)}
                candidate={candidate}
                callback={onSmsCallback}
                resendCallback={setSmsSentUnsync}
                company={company}
                flashMessage={flashMessage}
                candidateUuid={candidate.candidateUuid}
                isResend={!smsSentUnsync && invite}
                audition={audition}
            />
        </>
    );
};

export default SmsIndication;

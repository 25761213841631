export default theme => ({
    wrapper: {
        height: 290,
        width: 300,
        fontWeight: 'normal',
        overflow: 'hidden',
        boxShadow: '0px 0px 40px rgba(41, 42, 45, 0.15)',
        '@media only screen and (max-width: 763px)': {
            height: 290
        },
        [theme.breakpoints.down('lg')]: {
            width: 320
        }
    },
    headerWrapper: {
        position: 'relative',
        padding: '21px 48px 0px 24px'
    },
    boardMenu: {
        position: 'absolute',
        right: 19,
        top: 17,
        borderRadius: 4
    },
    title: {
        boxSizing: 'content-box',
        maxHeight: 48,
        textAlign: 'left',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.4',
        letterSpacing: 'normal',
        color: theme.palette.grey[900],
        whiteSpace: 'pre-wrap'
    },
    header: {
        height: 107,
        paddingBottom: 16
    },
    infoRow: {
        paddingLeft: 24,
        paddingRight: 24,
        borderTop: `1px solid ${theme.palette.grey[300]}`
    },
    contentWrapper: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        padding: '16px 24px'
    },
    descriptionWrapper: {
        boxSizing: 'content-box',
        height: 80,
        fontSize: 13,
        marginBottom: 16,
        textAlign: 'left',
        display: '-webkit-box',
        WebkitLineClamp: '4',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '20px',
        letterSpacing: 'normal',
        color: theme.palette.grey[900]
    },
    skillsWrapper: {
        height: 22,
        whiteSpace: 'nowrap'
    },
    categoryType: {
        marginTop: 5,
        fontWeight: 700,
        whiteSpace: 'nowrap',
        color: theme.palette.grey[500],
        '&:hover': {
            color: theme.palette.grey[900]
        }
    }
});

import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import PermanentDrawer from 'components/assessments_pages/PermanentDrawer';


import SettingsIcon from 'components/icons/SettingsIcon';
import ShieldIcon from 'components/icons/ShieldIcon';
import NavIcon from 'components/icons/ExpandMenuIcon';

import styles from './styles';

const EditDrawer = ({ classes, history, location, match, open, handleMenu }) => {
    const { auditionUuid } = match.params;
    const { pathname } = location;

    const returnIcon = (Element, className = '') => (<Element className={clsx(classes.settingsIcon, className)} />);

    const returnMenuItems = () => [{
        name: 'open',
        onClick: handleMenu,
        tooltipText: 'Open/Close',
        icon: returnIcon(NavIcon, open && classes.flip)
    }, {
        name: 'questions',
        text: 'Questions',
        active: pathname === `/content-library/${auditionUuid}/questions`,
        onClick: () => {
            history.push(`/content-library/${auditionUuid}/questions`);
        },
        tooltipText: 'Question',
        icon: returnIcon(ShieldIcon)
    }, {
        name: 'settings',
        text: 'Settings',
        active: pathname === `/content-library/${auditionUuid}/settings`,
        onClick: () => {
            history.push(`/content-library/${auditionUuid}/settings`);
        },
        tooltipText: 'Settings',
        icon: returnIcon(SettingsIcon)
    }];

    return (
        <PermanentDrawer
            isMiniVariant
            permanentDrawerWidth={190}
            permanentDrawerWidthMini={55}
            open={open}
            hideTooltip={open}
            anchor="left"
            tooltipProps={{
                placement: 'right'
            }}
            menuItems={returnMenuItems()}
        />
    );
};

export default withRouter(withStyles(styles)(EditDrawer));

import React from 'react';

import { withStyles } from '@mui/styles';

import downloadIcon from 'img/icons/download.svg';
import styles from './styles';

const FileAnswer = ({ classes }) => (
    <div className={classes.customFileView}>
        <img src={downloadIcon} alt="" />
        <p>
            Drag & drop your file here
        </p>
        <span className={classes.customLink}>
            or browse files
        </span>
        <span className={classes.helperText}>
            FILE SIZE MUST BE 60MB OR LESS
        </span>
    </div>
);

export default withStyles(styles)(FileAnswer);

import React from 'react';

import {
    MULTIPLE_CHOICE_AUTOGRADED, SINGLE_CHOICE_AUTO_GRADED, NO_ANSWER,
    GOOGLE_DOC, PRESENTATION, SPREADSHEET,
    CODE_TEST, CODE_TEST_AUTOGRADED, SPREADSHEET_AUTO_GRADED,
    NUMBER, CS_SIMULATOR, TYPING_TEST, AUDIO, VIDEO, CYBER_SIMULATOR, EXCEL_AUTO_GRADED,
    SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR
} from 'helper/constants';

// local files and components
import MultipleChoiceAnswerType from './components/MultipleChoiceAnswerType';
import CodeEditorPerViewAnswer from './components/CodeEditorPerViewAnswer';
import GoogleQuestionWrapper from './components/GoogleQuestionWrapper';
import SwitchAutoGrade from './components/SwitchAutoGrade';
import NumberCorrectAnswer from './components/NumberCorrectAnswer';
import SimulatorAnswerType from './components/SimulatorAnswerType';
import TypingTestContent from './components/TypingTestContent';
import RecordingTimeLimitInput from './components/RecordingTimeLimitInput';
import SimulationDescription from './components/SimulationDescription';
import SimulationTask from './components/SimulationTask';
import ExcelQuestionWrapper from './components/ExcelQuestionWrapper';

const AnswerType = ({
    question, changeQuestion, isCMS,
    questionsNumber, fetchQuestion,
    setQuestion, setSaving,
    changeQuestionSettings, audition
}) => {
    const { answerType } = question;

    const onChangeType = (params) => {
        setQuestion({ ...question, ...params });
        return changeQuestion(params);
    };

    const currentType = () => {
        switch (answerType) {
            case SINGLE_CHOICE_AUTO_GRADED: // TYPE_SINGLE_CHOICE_ANSWER
            case MULTIPLE_CHOICE_AUTOGRADED: // TYPE_MULTIPLE_CHOICE_ANSWER
                return (
                    <MultipleChoiceAnswerType
                        isCMS={isCMS}
                        fetchQuestion={fetchQuestion}
                        question={question}
                        setSaving={setSaving}
                        onChangeType={onChangeType}
                        changeQuestionSettings={changeQuestionSettings}
                    />
                );
            case null:
            case NO_ANSWER: return null; // TYPE_NO_ANSWER
            case GOOGLE_DOC: // google doc
            case SPREADSHEET: // google sheet
            case PRESENTATION: // google slides
            case SPREADSHEET_AUTO_GRADED:
                return (
                    <GoogleQuestionWrapper
                        isCMS={isCMS}
                        question={question}
                        setQuestion={setQuestion}
                        fetchQuestion={fetchQuestion}
                        setSaving={setSaving}
                    />
                );
            case EXCEL_AUTO_GRADED:
                return (
                    <ExcelQuestionWrapper
                        isCMS={isCMS}
                        question={question}
                        setQuestion={setQuestion}
                        fetchQuestion={fetchQuestion}
                        setSaving={setSaving}
                    />
                );
            case CODE_TEST:
                return (
                    <SwitchAutoGrade
                        isCMS={isCMS}
                        question={question}
                        onChangeType={onChangeType}
                    />
                );
            case CODE_TEST_AUTOGRADED:
                return (
                    <CodeEditorPerViewAnswer
                        isCMS={isCMS}
                        onChangeType={onChangeType}
                        question={question}
                        fetchQuestion={fetchQuestion}
                        questionsNumber={questionsNumber}
                        setSaving={setSaving}
                        audition={audition}
                    />
                );
            case NUMBER:
                return (
                    <NumberCorrectAnswer
                        isCMS={isCMS}
                        question={question}
                        fetchQuestion={fetchQuestion}
                        setSaving={setSaving}
                        changeQuestion={changeQuestion}
                    />
                );
            case CS_SIMULATOR:
                return (
                    <>
                        <SimulatorAnswerType
                            isCMS={isCMS}
                            question={question}
                            fetchQuestion={fetchQuestion}
                            setSaving={setSaving}
                            changeQuestion={changeQuestion}
                        />
                        <SimulationDescription
                            question={question}
                            isCMS={isCMS}
                            isRequired={false}
                            changeQuestion={changeQuestion}
                        />
                    </>
                );
            case TYPING_TEST:
                return (
                    <TypingTestContent
                        question={question}
                    />
                );
            case AUDIO:
            case VIDEO:
                return (
                    <RecordingTimeLimitInput
                        question={question}
                        changeQuestionSettings={changeQuestionSettings}
                    />
                );
            case CYBER_SIMULATOR:
                return (
                    <>
                        <SimulationTask
                            question={question}
                            isCMS={isCMS}
                            changeQuestion={changeQuestion}
                        />
                        <SimulationDescription
                            question={question}
                            isCMS={isCMS}
                            isRequired
                            changeQuestion={changeQuestion}
                        />
                    </>
                );
            case SALES_SIMULATOR:
            case QA_SIMULATOR:
            case MARKETING_SIMULATOR:
                return (
                    <SimulationDescription
                        question={question}
                        isCMS={isCMS}
                        isRequired
                        changeQuestion={changeQuestion}
                    />
                );
            default: return null;
        }
    };
    return (
        <div className="u-mrg--bx2">
            {currentType()}
        </div>
    );
};

export default AnswerType;

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Delete from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import { TEXT, VIDEO, AUDIO, MULTIPLE_CHOICE_AUTOGRADED } from 'helper/constants';
import { clone } from 'helper/commonFunctions';
import {
    changeExistingExampleAnswer,
    createExampleAnswer, getExampleAnswers,
    removeExampleAnswer
} from 'requests/QuestionsRequests';
import TextInput from 'libraries/TextInput';
import EditWarning from 'components/marketplace/EditWarning';

const styles = {
    wrapper: {
        marginTop: 15,
        marginBottom: 15
    }
};

let timerId = null;

const CorrectAnswerInputs = ({
    classes, question: { answerType, id },
    fetchQuestion, setSaving, isCMS, flashMessage
}) => {
    if (answerType !== TEXT && answerType !== VIDEO && answerType !== AUDIO) return null;
    const isTextPlaceholder = answerType === TEXT || answerType === MULTIPLE_CHOICE_AUTOGRADED;
    const [lastInput, setLastInput] = useState('');
    const [answers, setAnswers] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getExampleAnswers(id, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    setAnswers(data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [id]);


    const handleChangeExampleAnswers = (e, index) => {
        const { value, name } = e.target;
        const { id: answerId } = answers[index];
        const newExampleAnswers = clone(answers);
        newExampleAnswers[index].description = value;
        setAnswers(newExampleAnswers);
        clearTimeout(timerId);
        timerId = setTimeout(async () => {
            if (!value) {
                deleteExampleAnswer(e, index);
            } else {
                setSaving(true);
                await changeExistingExampleAnswer(answerId, { [name]: value }, isCMS);
                setSaving(false);
            }
        }, 300);
    };

    const handleChangeLastInput = (e) => {
        const { value } = e.target;
        setLastInput(value);
    };

    const updateExampleAnswers = () => {
        if (!lastInput || !lastInput.trim()) return;
        const newExampleAnswers = answers.slice();
        const lastElement = { description: lastInput };
        newExampleAnswers.push(lastElement);
        const lastIndex = newExampleAnswers.length - 1;
        setAnswers(newExampleAnswers);
        setSaving(true);
        createExampleAnswer(id, lastElement, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    const newExampleAnswers2 = answers.slice();
                    newExampleAnswers2[lastIndex] = data;
                    setAnswers(newExampleAnswers2);
                    fetchQuestion();
                    setSaving(false);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
                setSaving(false);
            });
        setLastInput('');
    };

    const deleteExampleAnswer = (e, key) => {
        e.preventDefault();
        const { id: answerId } = answers[key];
        setSaving(true);
        removeExampleAnswer(answerId, isCMS)
            .then(() => {
                const newAnswers = clone(answers);
                newAnswers.splice(key, 1);
                setAnswers(newAnswers);
                fetchQuestion();
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const commonTextInputProps = {
        placeholder: isTextPlaceholder
            ? 'e.g “7”, “Use the company’s brand to format and write an article.”'
            : 'Write a transcript of what a good recording would contain',
        label: 'Example of a correct answer',
        maxRows: 6,
        multiline: true,
        variant: 'outlined'
    };

    if (isLoading) {
        return (
            <CircularProgress size={20} />
        );
    }

    return (
        <div className={classes.wrapper} onMouseLeave={updateExampleAnswers}>
            {
                answers.map(({ description, id: answerId }, index) => (
                    <TextInput
                        key={answerId}
                        value={description}
                        variant="outlined"
                        name="description"
                        onChange={e => handleChangeExampleAnswers(e, index)}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={e => deleteExampleAnswer(e, index)}
                                    size="large"
                                >
                                    <Delete fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        )}
                        {...commonTextInputProps}
                    />
                ))
            }
            <TextInput
                onChange={handleChangeLastInput}
                onBlur={updateExampleAnswers}
                onEnter={updateExampleAnswers}
                value={lastInput}
                name="description"
                helperText={(
                    <EditWarning
                        message={(
                            <span>
                                Examples help our AI predict scores accurately for this question, list as many as possible.
                                <br />
                                <a
                                    href="https://vervoe.zendesk.com/hc/en-us/articles/360035658232-Adding-Example-Answers"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                See examples here.
                                </a>
                            </span>
                        )}
                    />
                )}
                {...commonTextInputProps}
            />
        </div>
    );
};

export default withStyles(styles)(CorrectAnswerInputs);

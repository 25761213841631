export default theme => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 15,
        marginBottom: 24
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.grey[900],
        padding: '40px 0 15px'
    },
    searchBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 30
    },
    searchField: {
        background: theme.palette.grey[300],
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        margin: '16px 0 18px 27px',
        minWidth: 250,
        height: 54
    },
    searchFieldFocused: {
        background: '#fff'
    },
    previewContentWrapper: {
        position: 'relative',
        minHeight: 300,
        maxHeight: 900,
        overflowY: 'auto'
    },
    resultsLine: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.grey[600],
        marginBottom: 40,
        '& span': {
            fontWeight: 700,
            color: theme.palette.grey[900]
        }
    }
});

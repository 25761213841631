export default theme => ({
    contentWrapper: {
        position: 'relative',
        backgroundColor: 'white',
        marginTop: 32,
        marginBottom: 36,
        width: '100%',
        padding: '39px 25px 65px 32px',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 4
    },
    contentWrapperEmpty: {
        paddingTop: 65,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

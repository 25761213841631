import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'libraries/Tooltip';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import CardMenuItem from 'components/assessments_pages/AssessmentFolders/CardMenuItem';

import AddGrey from 'img/add_grey.svg';
import AddToAssessment from 'img/add_to_assessments.svg';
import RemoveGrey from 'img/remove_grey.svg';

import RemoveSkillDialog from './RemoveSkillDialog';
import clsx from "clsx";

const styles = theme => ({
    icon: {
        padding: 0,
        backgroundColor: '#fff',
        width: 32,
        height: 32,
        marginLeft: 8,
        borderRadius: 4,
        border: `1px solid ${theme.palette.grey[300]}`
    },
    iconDisabled: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            opacity: 0.4
        }
    },
    menuIcon: {

    }
});

const SetsActions = ({
    classes, onAdd, onRemove, numberOfSets,
    toggleSetDrawer, hasSet, onRemoveLoading
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialog = () => {
        setDialogOpen(!dialogOpen);
    };

    const handleRemove = () => {
        onRemove();
        handleDialog();
    };

    return (
        <div className="u-txt--nowrap u-dsp--centered">
            {
                !hasSet ? (
                    <>
                        <Tooltip label={`${numberOfSets ? 'Select' : 'Create'} a set to Create Question`}>
                            <div className={clsx(classes.icon, classes.iconDisabled)}>
                                <img src={AddGrey} alt="create" />
                            </div>
                        </Tooltip>
                        <Tooltip label={`${numberOfSets ? 'Select' : 'Create'} a set to Add Question`}>
                            <div className={clsx(classes.icon, classes.iconDisabled)}>
                                <img src={AddToAssessment} alt="add" />
                            </div>
                        </Tooltip>
                    </>
                ) : (
                    <>
                        <Tooltip label="Create Question">
                            <IconButton className={classes.icon} onClick={onAdd} size="large">
                                <img src={AddGrey} alt="create" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip label="Add Question">
                            <IconButton className={classes.icon} onClick={toggleSetDrawer} size="large">
                                <img src={AddToAssessment} alt="add" />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
            {
                hasSet && (
                    <BoardItemMenu
                        iconMenuClassName={classes.icon}
                        options={[
                            {
                                label: <CardMenuItem label="Remove skill" icon={RemoveGrey} />,
                                onClick: handleDialog
                            }
                        ]}
                    />
                )
            }
            <RemoveSkillDialog
                open={dialogOpen}
                onRemove={handleRemove}
                onClose={handleDialog}
                loading={onRemoveLoading}
            />
        </div>
    );
};

export default withStyles(styles)(SetsActions);

import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from './closeIconWithRoundCorners';
import PopperContent from './PoperContent';

const stylesJs = {
    popperLabel: {
        cursor: 'pointer'
    },
    closeButton: {
        display: 'block',
        textAlign: 'right'
    }
};

class Popper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            isHover: Boolean(props.hover),
            arrow: Boolean(props.arrow)
        };
    }

    open = (e) => {
        this.setState({
            anchorEl: e.currentTarget
        });
    };

    close = () => {
        this.setState({
            anchorEl: null
        });
    };

    onHandleClick = (e) => {
        const { isHover, anchorEl } = this.state;

        if (!isHover) {
            this.setState({
                anchorEl: anchorEl ? null : e.currentTarget
            });
        }
    };

    onHandleMouseEnter = (e) => {
        const { isHover } = this.state;

        if (isHover) {
            this.open(e);
        }
    };

    onHandleMouseLeave = () => {
        const { isHover } = this.state;

        if (isHover) {
            this.close();
        }
    };

    render() {
        const {
            children, classes,
            label, closeButton,
            closeIcon, closeButtonLabel,
            className, placement, styles = {}
        } = this.props;
        const { anchorEl, arrow } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div onMouseLeave={this.onHandleMouseLeave}>
                <Typography
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onClick={this.onHandleClick}
                    onMouseEnter={this.onHandleMouseEnter}
                    className={classes.popperLabel}
                >
                    {label}
                </Typography>

                <PopperContent
                    open={open}
                    anchorEl={anchorEl}
                    arrow={arrow}
                    styles={styles}
                    className={className}
                    placement={placement}
                >
                    { children }
                    {
                        closeButton && (
                            <div className={classes.closeButton}>
                                <Button
                                    color="primary"
                                    onClick={this.close}
                                >
                                    { closeButtonLabel }
                                </Button>
                            </div>
                        )
                    }
                    { closeIcon && <CloseIcon onClick={this.close} className={styles.closeIcon} /> }
                </PopperContent>
            </div>
        );
    }
}
export default withStyles(stylesJs)(Popper);

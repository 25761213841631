import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import PageWrapper from 'components/layout/PageWrapper';

import InnerTabs from 'components/layout/InnerTabs';
import RequestAssessmentTabIcon from 'img/requests_tab_icon.svg';
import InnerContentWrapper from '../assessment/components/InnerContentWrapper';
import { appCtx } from '../../components/appStore';
import Layout from '../../components/layout';
import InnerHeader from '../../components/layout/InnerHeader';
import styles from './styles';
import RequestsList from './components/RequestsList';
import { customRequestsStoreCtx } from './store';
import RequestTableFilter from './components/RequestsTableFilter';

const AssessmentRequests = observer(({ classes }) => {
    const { isLoadingCompany } = useContext(appCtx);
    const { loadRequests } = useContext(customRequestsStoreCtx);

    if (isLoadingCompany) return <Layout />;

    useEffect(() => {
        loadRequests();
    }, []);

    const tabList = [
        {
            label: (<>
                <img src={RequestAssessmentTabIcon} alt="icon" className="u-mrg--rx1" /> Requests
            </>),
            className: classes.shortlistTab,
            url: '/assessment-requests'
        }
    ];

    return (
        <Layout>
            <InnerHeader
                header="Assessment Requests"
                rightPart={
                    <RequestTableFilter />
                }
            />
            <PageWrapper className="u-pdn--bx5">
                <InnerContentWrapper
                    className={classes.contentWrapper}
                    contentClassName="u-pdn--x0"
                >
                    <InnerTabs
                        className={classes.innerTabs}
                        tabList={tabList}
                    />
                    <div className={classes.content}>
                        <div className={classes.wrapper}>
                            <RequestsList />
                        </div>
                    </div>
                </InnerContentWrapper>
            </PageWrapper>
        </Layout>
    );
});

export default withStyles(styles)(AssessmentRequests);

import React from 'react';

import {
    Controlled as CodeMirrorBody,
    Controlled as CodeMirrorHeader,
    Controlled as CodeMirrorFooter
} from 'react-codemirror2';

import * as codeMirror from 'codemirror/lib/codemirror';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/css-hint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/anyword-hint';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/search';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/wrap/hardwrap';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/theme/monokai.css';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/php/php';
import 'codemirror/mode/python/python';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/go/go';
import 'codemirror/mode/ruby/ruby';
import 'codemirror/keymap/sublime';
import 'codemirror/keymap/emacs';
import 'codemirror/keymap/vim';

// local files and components
import grayArrow from '../../../img/candidateCard/chevron-down-gray.svg';
import {
    wrapperBodyStyles,
    wrapperStyles,
    wrapperHeader,
    wrapperFooter,
    titleHeader,
    titleFooterBody,
    commentStyle,
    wrapperBodyStylesPreView
} from './styles';


class CodeEditorQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valueHeader: props.header,
            valueBody: props.body,
            valueFooter: props.footer,
            settingsHeader: { firstLineNumber: 2 },
            settingsBody: { firstLineNumber: 3 },
            settingsFooter: { firstLineNumber: 5 }
        };
    }

    onUpdateHeader = ({ doc: { size } }) => {
        const { className } = this.props;
        const { doc: { size: sizeBody } } = this.body;
        if (className === 'preView') {
            this.setState({
                settingsBody: { firstLineNumber: size + 3 },
                settingsFooter: { firstLineNumber: size + sizeBody + 4 }
            });
        }
        this.setState({
            settingsBody: { firstLineNumber: size + 2 },
            settingsFooter: { firstLineNumber: size + sizeBody + 3 }
        });
    };

    onUpdateBody = ({ doc: { size } }) => {
        const { className } = this.props;
        const { doc: { size: sizeHeader } } = this.header;
        if (className === 'preView') {
            this.setState({
                settingsFooter: { firstLineNumber: size + sizeHeader + 4 }
            });
        } else {
            this.setState({
                settingsFooter: { firstLineNumber: size + sizeHeader + 3 }
            });
        }
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            valueHeader: nextProps.header,
            valueBody: nextProps.body,
            valueFooter: nextProps.footer
        });
    }

    componentDidMount() {
        const { className } = this.props;
        if (className === 'preView') {
            const { doc: { size: sizeHeader } } = this.header;
            const { doc: { size: sizeBody } } = this.body;
            this.setState({
                settingsHeader: { firstLineNumber: 2 },
                settingsBody: { firstLineNumber: sizeHeader + 3 },
                settingsFooter: { firstLineNumber: sizeBody + sizeHeader + 4 }
            });
        }
    }

    returnHelperText = (text, preview) => {
        const { preView } = this.props;

        if (preView || preview) {
            return `Custom ${text} would be here`;
        }

        return `*/ Add custom ${text} here. Candidates will not be able to edit this /*`;
    };


    render() {
        const {
            valueBody,
            valueHeader,
            valueFooter,
            settingsBody,
            settingsFooter,
            settingsHeader
        } = this.state;
        const { onChange, options, className, stylesComment } = this.props;
        return (
            <div style={wrapperStyles}>
                <div style={wrapperHeader}>
                    <span style={titleHeader}>HEADER</span>
                    <p
                        style={{
                            ...((settingsHeader.firstLineNumber - 1).toString().length > 1 ? { ...commentStyle, ...{ paddingLeft: 7 } } : commentStyle),
                            ...((settingsHeader.firstLineNumber - 1).toString().length > 1 && stylesComment ? { ...stylesComment, ...{ paddingLeft: 12 } } : stylesComment),
                            ...(className === 'preView' ? {} : { fontSize: 16 })
                        }}
                    >
                        {`${settingsHeader.firstLineNumber - 1} `}
                        <img src={grayArrow} alt="" />
                        {` ${this.returnHelperText('header')}`}
                    </p>
                    <CodeMirrorHeader
                        editorDidMount={(header) => { this.header = header; }}
                        className={`CodeMirror__header ${className || ''}`}
                        value={valueHeader}
                        options={{ ...options, ...settingsHeader }}
                        onBeforeChange={(editor, data, header) => {
                            this.setState({ valueHeader: header });
                        }}
                        onChange={(editor, data, header) => onChange(header, 'header')}
                        onUpdate={this.onUpdateHeader}
                        onKeyUp={(cm, event) => {
                            if (!cm.state.completionActive && event.keyCode !== 13) {
                                codeMirror.commands.autocomplete(cm, null, { completeSingle: false });
                            }
                        }}
                    />
                </div>
                <div style={className === 'preView' ? wrapperBodyStylesPreView : wrapperBodyStyles}>
                    <span style={titleFooterBody}>BODY</span>
                    {
                        className === 'preView' && (
                            <p
                                style={{
                                    ...((settingsHeader.firstLineNumber - 1).toString().length > 1 ? { ...commentStyle, ...{ paddingLeft: 7 } } : commentStyle),
                                    ...((settingsHeader.firstLineNumber - 1).toString().length > 1 && stylesComment ? { ...stylesComment, ...{ paddingLeft: 12 } } : stylesComment)
                                }}
                            >
                                {`${settingsBody.firstLineNumber - 1} `}
                                <img src={grayArrow} alt="" />
                                {` ${this.returnHelperText('body', true)}`}
                            </p>
                        )
                    }
                    <CodeMirrorBody
                        editorDidMount={(body) => { this.body = body; }}
                        className={`CodeMirror__body ${className || ''}`}
                        value={valueBody}
                        options={{ ...options, ...settingsBody }}
                        onBeforeChange={(editor, data, body) => {
                            this.setState({ valueBody: body });
                        }}
                        onChange={(editor, data, body) => onChange(body, 'body')}
                        onUpdate={this.onUpdateBody}
                    />
                </div>
                <div style={wrapperFooter}>
                    <span style={titleFooterBody}>FOOTER</span>
                    <p
                        style={{
                            ...((settingsFooter.firstLineNumber - 1).toString().length > 1 ? { ...commentStyle, ...{ paddingLeft: 7 } } : commentStyle),
                            ...((settingsFooter.firstLineNumber - 1).toString().length > 1 && stylesComment ? { ...stylesComment, ...{ paddingLeft: 12 } } : stylesComment),
                            ...(className === 'preView' ? {} : { fontSize: 16 })
                        }}
                    >
                        {`${settingsFooter.firstLineNumber - 1} `}
                        <img src={grayArrow} alt="" />
                        {` ${this.returnHelperText('footer')}`}
                    </p>
                    <CodeMirrorFooter
                        editorDidMount={(footer) => { this.footer = footer; }}
                        className={`CodeMirror__footer ${className || ''}`}
                        value={valueFooter}
                        options={{ ...options, ...settingsFooter }}
                        onBeforeChange={(editor, data, footer) => {
                            this.setState({ valueFooter: footer });
                        }}
                        onChange={(editor, data, footer) => onChange(footer, 'footer')}
                    />
                </div>
            </div>
        );
    }
}

export default CodeEditorQuestion;

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import TextInput from 'libraries/TextInput';
import { addNewBranding, editBrandingName } from 'requests/SettingsRequests';
import { CircularProgress } from '@mui/material';
import SettingsEvents from 'events/SettingsEvents';

const MAX_BRANDING_SET_NAME_SYMBOLS = 150;

const AddBrandingSetDialog = ({
    open, onClose,
    defaultBrandingSet: { title = '', uuid } = {},
    setUpdatingBrandUuid, setBrandings, brandings,
    isSaving, setSaving
}) => {
    const [brandingName, setBrandingName] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (open) {
            setError('');
            setBrandingName(title);
        }
    }, [open]);

    const handleClose = () => {
        if (isSaving) return;
        onClose();
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setError('');
        setBrandingName(value);
    };

    const newError = text => (
        <div style={{ whiteSpace: 'pre' }}>
            {text}
        </div>
    );

    const request = uuid ? editBrandingName : addNewBranding;

    const setBrandingSetName = () => {
        if (brandingName.toLowerCase() === 'vervoe') {
            setError(newError('The use of the word Vervoe as your branding slug is not authorised'));
            return;
        }
        setSaving(true);
        request(brandingName.toLowerCase(), uuid)
            .then(({ data }) => {
                let newBrandings = [data, ...brandings];
                if (uuid) {
                    newBrandings = brandings.map(brand => (brand.title === title ? { ...brand, title: brandingName.toLowerCase() } : brand));
                    SettingsEvents.BRANDING_UPDATED({ oldBrandingTitle: title, newBrandingTitle: brandingName });
                } else {
                    SettingsEvents.BRANDING_CREATED({ brandingTitle: brandingName, brandingUUID: data.uuid });
                }
                setBrandings(newBrandings);
                setError('');
                if (setUpdatingBrandUuid) setUpdatingBrandUuid(data.uuid);
                onClose();
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    setError(newError(err.response.data.msg));
                }
            })
            .finally(() => {
                setSaving(false);
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            onClose={handleClose}
            handleClose={handleClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={handleClose}
                        disabled={isSaving}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="u-pdn--lx7 u-pdn--rx7 u-wdt--200"
                        color="primary"
                        variant="contained"
                        onClick={setBrandingSetName}
                        disabled={!brandingName || isSaving || brandingName === title}
                    >
                        {isSaving ? <CircularProgress size={24} /> : <>
                            {uuid ? 'Save' : 'Add branding'}
                        </>}
                    </Button>
                </>
            )}
            titleComponent={uuid ? 'Edit branding title' : 'Add new branding set'}
            open={open}
        >
            <div className="u-dsp--distribute u-mrg--tx4">
                <TextInput
                    className="u-mrg--tx0"
                    value={brandingName}
                    variant="outlined"
                    label="Branding Title"
                    onChange={handleChange}
                    helperText={error}
                    isError={Boolean(error)}
                    disabled={isSaving}
                    maxLength={MAX_BRANDING_SET_NAME_SYMBOLS}
                />
            </div>
        </Dialog>
    );
};

export default AddBrandingSetDialog;

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import TextInput from 'libraries/TextInput';
import { getPublicApiSettings, updatePublicApiSettings } from 'requests/SettingsRequests';
import Switch from 'libraries/SwitchBrandWithLabel';
import Zendesk from 'helper/zendeskFunctions';
import SettingsEvents from 'events/SettingsEvents';
import PublicApiSettings from './PublicApiSettings';

import stylesJS from './styles';

class PublicApiContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiKey: '',
            secretKey: '',
            url: '',
            oldUrl: '',
            enabled: false,
            webHookSecret: '',
            errorForm: {},
            loading: false
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        getPublicApiSettings()
            .then(({ success, data }) => {
                if (success && data) {
                    const {
                        enabled, secretKey,
                        notifyEmployerUrl, webHookSecret,
                        apiKey
                    } = data;
                    this.setState({
                        secretKey,
                        apiKey,
                        oldUrl: notifyEmployerUrl,
                        url: notifyEmployerUrl,
                        enabled,
                        webHookSecret,
                        errorForm: {}
                    });
                }
                this.setState({ loading: false });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChangeWebhookURL = (e) => {
        const { value } = e.target;
        this.setState({ url: value });
    };

    handleChangeSwitch = () => {
        const { enabled } = this.state;
        const { flashMessage } = this.props;
        SettingsEvents.API_ENABLED({ enabled: !enabled });
        this.changePublicApiSettings(!enabled)
            .then((response) => {
                const { success, data } = response;
                const { enabled: newEnabled } = data;
                if (success) {
                    flashMessage(`Integration has been turned ${newEnabled ? 'on' : 'off'}`, 'done');
                } else {
                    flashMessage('Something went wrong', '');
                }
            });
    };

    // eslint-disable-next-line react/destructuring-assignment
    changePublicApiSettings = (enabled = this.state.enabled) => {
        const { url, webHookSecret } = this.state;
        return updatePublicApiSettings({ enabled, notifyEmployerUrl: url, webHookSecret })
            .then((response) => {
                const { success, data } = response;
                if (success) {
                    const {
                        secretKey, enabled: newEnabled,
                        webHookSecret: newWebHookSecret, apiKey,
                        notifyEmployerUrl
                    } = data;
                    this.setState({
                        secretKey,
                        enabled: newEnabled,
                        apiKey,
                        url: notifyEmployerUrl,
                        oldUrl: notifyEmployerUrl,
                        webHookSecret: newWebHookSecret,
                        errorForm: {}
                    });
                }
                return response;
            })
            .catch(({ response }) => {
                const { data: { errors } } = response;
                const newErrorForm = {};
                Object.keys(errors).forEach((key) => {
                    newErrorForm[key] = errors[key][0].message;
                });
                this.setState({
                    errorForm: newErrorForm,
                    url,
                    oldUrl: url
                });
            });
    };

    renderError = (value) => {
        const { errorForm } = this.state;
        if (errorForm && errorForm[value]) {
            return errorForm[value];
        }
        return '';
    };

    openZendesk = () => {
        Zendesk.open(true);
    };

    saveWebhook = () => {
        this.changePublicApiSettings();
    };

    render() {
        const {
            secretKey, url, enabled,
            apiKey, loading, webHookSecret,
            oldUrl
        } = this.state;
        const { classes, flashMessage } = this.props;

        if (loading) {
            return (
                <div>
                    <div className={classes.text}>
                        To learn more or set up the Vervoe API, please <span onClick={this.openZendesk} className={classes.contactLink}>contact us</span>.
                    </div>
                    <div className={classes.progressWrapper}>
                        <CircularProgress
                            size={30}
                            className={classes.progress}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className={classes.text}>
                    To learn more or set up the Vervoe API, please <span onClick={this.openZendesk} className={classes.contactLink}>contact us</span>.
                </div>
                <div className={classes.webhookWrapper}>
                    <Button
                        className={classes.webhookSaveButton}
                        color="primary"
                        onClick={this.saveWebhook}
                        disabled={oldUrl === url}
                    >
                        Save
                    </Button>
                    <TextInput
                        label="Webhook url"
                        fullWidth
                        variant="outlined"
                        name="webhook_url"
                        type="text"
                        value={url}
                        className={classes.input}
                        isError={Boolean(this.renderError('notifyEmployerUrl'))}
                        helperText={this.renderError('notifyEmployerUrl')}
                        onChange={this.handleChangeWebhookURL}
                    />
                    <p className="u-txt--12">Please paste webhook URL in order to allow the integration.</p>
                </div>
                <br />
                <Switch
                    label="Enable Vervoe API integration"
                    checked={enabled}
                    value={enabled}
                    onChange={this.handleChangeSwitch}
                />
                <br />
                <TextInput
                    label="API key"
                    className={clsx('u-mrg--bx2', classes.input)}
                    isError={Boolean(this.renderError('apiKey'))}
                    helperText={this.renderError('apiKey')}
                    fullWidth
                    name="key"
                    type="text"
                    variant="outlined"
                    value={apiKey}
                    disabled
                /><br />
                <TextInput
                    label="Secret key"
                    className={clsx('u-mrg--bx2', classes.input)}
                    isError={Boolean(this.renderError('secretKey'))}
                    helperText={this.renderError('secretKey')}
                    fullWidth
                    name="key"
                    type="text"
                    variant="outlined"
                    value={secretKey}
                    disabled
                /><br />
                <TextInput
                    label="Webhook Secret"
                    isError={Boolean(this.renderError('webhookKey'))}
                    helperText={this.renderError('webhookKey')}
                    fullWidth
                    name="key"
                    type="text"
                    variant="outlined"
                    value={webHookSecret}
                    disabled
                    className={clsx('u-mrg--bx2', classes.input)}
                />
                { enabled && !loading && (
                    <PublicApiSettings
                        flashMessage={flashMessage}
                    />
                )}
            </div>
        );
    }
}

export default withStyles(stylesJS)(PublicApiContent);

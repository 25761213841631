import React, { useLayoutEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import Popover from '@mui/material/Popover';

import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import ChevronIcon from 'img/chevron.svg';
import ChevronActiveIcon from 'img/chevron_active.svg';
import FilterIcon from '../filterIcon.svg';
import FilterIconActive from '../filterIconActive.svg';
import styles from './styles';


const FilterSelect = ({
    options = [], handleChange, classes, title, filterData, withIcon = true, multiSelect = true
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [truncateWidth, setTruncateWidth] = useState(230);
    const open = Boolean(anchorEl);
    const isArray = Array.isArray(filterData);
    const isActive = isArray && filterData.length > 0;
    const renderedValue = !isArray && options.find(option => option.value === filterData)?.label;

    useLayoutEffect(() => {
        if (window.innerWidth < 1600) {
            setTruncateWidth(130);
        } else {
            setTruncateWidth(230);
        }
    }, [window.innerWidth]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderMenuItems = menuList => (
        menuList.map(({ value, label = value, checked, count }) => {
            const checkboxProps = {
                disableRipple: true,
                checked: Boolean(checked),
                className: classes.checkbox
            };

            return (
                <MenuItem
                    key={value}
                    value={value}
                    disabled={!handleChange}
                    onClick={() => handleChange(value)}
                >
                    <div className={classes.filterOption}>
                        <div className="u-dsp--f u-ai--center">
                            {multiSelect && (
                                <Checkbox {...checkboxProps} classes={classes.checkbox} checked={filterData.includes(value)} />
                            )}
                            <TruncateWithTooltip text={label} width={truncateWidth} className={classes.option} />
                            {count >= 0 && (
                                <span className={classes.countNumber}>
                                    {count}
                                </span>
                            )}
                        </div>
                    </div>
                </MenuItem>
            );
        })
    );

    return (
        <>
            <div
                role="presentation"
                className={classes.root}
                onClick={handleClick}
            >
                <div className={classes.label}>{title}</div>
                {withIcon && <img src={isActive ? FilterIconActive : FilterIcon} alt="" />}
                {renderedValue || 'Select an option'}
                <img className={clsx(classes.chevron, open && classes.chevronOpen)} src={isActive ? ChevronActiveIcon : ChevronIcon} alt="" />
            </div>
            <Popover
                anchorEl={anchorEl}
                keepMounted
                open={open}
                classes={{ root: classes.popover, paper: classes.menu }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                { renderMenuItems(options) }
            </Popover>
        </>
    );
};

export default withStyles(styles)(FilterSelect);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    drawer: {
        width: ({ drawerWidth }) => drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        minHeight: 'calc(100% + 220px)',
        zIndex: 1000,
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        width: ({ drawerWidth }) => drawerWidth,
        padding: '40px 24px 40px 24px'
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 20,
        color: theme.palette.grey[900]
    }
}));

import React from 'react';

// material component
import { withStyles } from '@mui/styles';


// local files and components
import ListItemWrapper from './ListItemWrapper';
import styles from './styles';


const CasesTable = ({
    cases,
    classes: {
        listItem, listItemDisactive,
        list, cellH, row, cell, listItemDisactiveBottomEmpty,
        table, wrapperStyle, cellB
    },
    activeCase,
    changeActiveCase,
    runningTest,
    setDeleteTestCaseDialogOpen,
    setTestCaseToDeleteFromTable,
    error
}) => (
    <div className={wrapperStyle}>
        <div className={list}>
            {
                cases.map((testCase, i) => {
                    const itemDisactive = (activeCase === (i + 1)) ? listItemDisactiveBottomEmpty : listItemDisactive;
                    return (
                        <ListItemWrapper
                            setDeleteTestCaseDialogOpen={setDeleteTestCaseDialogOpen}
                            error={error}
                            runningTest={runningTest}
                            testCase={testCase}
                            key={testCase.id}
                            classesItem={{ root: activeCase === i ? listItem : itemDisactive }}
                            setTestCaseToDeleteFromTable={setTestCaseToDeleteFromTable}
                            onClick={() => {
                                changeActiveCase(i);
                            }}
                        />
                    );
                })
            }
        </div>
        <div className={table}>
            <div className={row}>
                <div className={cell}>
                    <div className={cellH}>INPUT</div>
                    <div className={cellB}>{cases[activeCase].input}</div>
                </div>
                <div className={cell}>
                    <div className={cellH}>EXPECTED OUTPUT</div>
                    <div className={cellB}>{cases[activeCase].output}</div>
                </div>
                <div className={cell}>
                    <div className={cellH}>YOUR OUTPUT</div>
                    <div className={cellB}>{cases[activeCase].stdout || 'Empty'}</div>
                </div>
                <div className={cell}>
                    <div className={cellH}>CONSOLE OUTPUT</div>
                    <div className={cellB}> {cases[activeCase].stderr || 'Empty'}</div>
                </div>
            </div>
        </div>
    </div>
);
export default withStyles(styles)(CasesTable);

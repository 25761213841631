import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Switch from 'libraries/SwitchBrandWithLabel';
import TextInput from 'libraries/TextInput';
import { getZapierSettings, updateZapierSettings } from 'requests/SubscriptionRequests';
import SettingsEvents from 'events/SettingsEvents';

const styles = theme => ({
    blue: {
        color: theme.palette.primary.main
    },
    input: {
        maxWidth: 500,
        marginBottom: 10
    },
    heading: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '28px',
        marginBottom: 20
    }
});


const ZapierContent = ({ classes, flashMessage }) => {
    const [activated, setActivated] = useState(false);
    const [zapierKey, setZapierKey] = useState('');

    useEffect(() => {
        getZapierSettings()
            .then(({ success, data }) => {
                if (data && success) {
                    const { enabled, apiKey } = data;
                    setZapierKey(apiKey);
                    setActivated(enabled);
                }
            });
    }, []);

    const handleChangeSwitch = () => {
        SettingsEvents.ZAPIER_ENABLED({ activated: !activated });
        updateZapierSettings({ enabled: !activated })
            .then(({ success, data }) => {
                if (success && data) {
                    const { enabled, apiKey } = data;
                    setZapierKey(apiKey);
                    setActivated(enabled);
                    flashMessage(`Integration has been turned ${enabled ? 'on' : 'off'}`, 'done');
                }
            })
            .catch(() => { flashMessage('Something went wrong'); });
    };

    return (
        <div>
            <h3 className={classes.heading}>Connect Vervoe to hundreds of other apps with Zapier</h3>
            <p className="u-mrg--bx5">
                <a href="https://zapier.com/zapbook/Vervoe/" className={classes.blue}>Zapier</a> lets you connect Vervoe to 1,000+ other web services. Automated connections called Zaps, set up
                in minutes with no coding, can automate your day-to-day tasks and build workflows between apps that
                otherwise wouldn't be possible.
                <br /><br />
                Each Zap has one app as the Trigger, where your information comes from and which causes one or more
                Actions in other apps, where your data gets sent automatically.
            </p>
            <TextInput
                label="Zapier API KEY"
                fullWidth
                className={classes.input}
                type="text"
                variant="outlined"
                value={zapierKey}
                disabled
            />
            <br />
            <Switch
                label="Enable Zapier Integration"
                checked={activated}
                value={activated}
                onChange={handleChangeSwitch}
            />
        </div>
    );
};

export default withStyles(styles)(ZapierContent);

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { withRouter } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import Tooltip from 'libraries/Tooltip';
import trashIcon from 'img/icons/trashIcon.svg';
import pencilIcon from 'img/icons/pencil.svg';
import shareIcon from 'img/icons/share.svg';
import shareIconDisable from 'img/icons/share_disable.svg';
import SocialMediaIcons from 'components/icons/social_media_icons';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import DeleteLinkDialog from './DeleteLinkDialog';
import ApplicationLinkDialog from '../ApplicationLinkDialog';
import { appCtx } from '../../appStore';

import styles from './styles';

const ApplicationLink = observer(({
    onCopyLink, disabled,
    slug, removeLink,
    audition, link, classes, updateLink,
    mainLink, withIcon, disableTooltip
}) => {
    const [deleteLinkOpened, setDeleteLinkOpened] = useState(false);
    const [listingLinksDialogOpen, setListingLinksDialogOpen] = useState(false);

    const { flashMessage } = useContext(appCtx);
    const isDisabled = disabled || (audition && audition.expired);

    const { name, url, source, id = null } = link;

    const handleOpenDeleteLink = () => {
        setDeleteLinkOpened(!deleteLinkOpened);
    };

    const handleOpenListingLink = () => {
        setListingLinksDialogOpen(!listingLinksDialogOpen);
    };

    return (
        <>
            <div className={clsx(classes.shareLink, 'share-link')}>
                <SocialMediaIcons active socialMedia={source} className="u-mrg--rx2" />
                <div className={classes.shareLinkLabelWrapper}>
                    {
                        isDisabled ? (
                            <div>
                                <div className={`${classes.shareLinkLabel} ${classes.shareLinkLabelDisabled}`}>
                                    <TruncateWithTooltip text={name} width={540} />
                                </div>
                                {
                                    disableTooltip ? (
                                        <Tooltip
                                            label={disableTooltip}
                                            TransitionProps={{ exit: false }}
                                        >
                                            <Button
                                                variant="outlined"
                                                className={clsx(classes.copyLinkButton, classes.copyLinkButtonDisabled, withIcon && classes.iconButtonDisable, 'share-link-button')}
                                            >
                                                {withIcon && <img src={shareIconDisable} alt="Copy link" />}
                                                Copy link
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            className={`${classes.copyLinkButton} ${classes.copyLinkButtonDisabled} share-link-button`}
                                        >
                                            Copy link
                                        </Button>
                                    )
                                }
                            </div>
                        ) : (
                            <CopyToClipboard text={url} onCopy={() => onCopyLink({ name })}>
                                <div>
                                    <div className={classes.shareLinkLabel}>
                                        <TruncateWithTooltip text={name} width={540} />
                                    </div>
                                    <Button
                                        variant="outlined"
                                        className={clsx(classes.copyLinkButton, withIcon && classes.iconButton, 'share-link-button')}
                                    >
                                        {withIcon && <img src={shareIcon} alt="Copy link" />}
                                        Copy link
                                    </Button>
                                </div>
                            </CopyToClipboard>
                        )
                    }
                </div>
                {
                    !mainLink && (
                        <div className={classes.shareLinkActions}>
                            <div className={classes.shareLinkActionItem}>
                                <Tooltip label="Remove link">
                                    <div
                                        role="presentation"
                                        className={classes.shareLinkActionIcon}
                                        onClick={handleOpenDeleteLink}
                                    >
                                        <img src={trashIcon} alt="Trash share link" />
                                    </div>
                                </Tooltip>
                            </div>
                            <div className={classes.shareLinkActionItem}>
                                <Tooltip label="Edit link">
                                    <div
                                        role="presentation"
                                        className={classes.shareLinkActionIcon}
                                        onClick={handleOpenListingLink}
                                    >
                                        <img src={pencilIcon} alt="Edit share link" />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
                <DeleteLinkDialog
                    linkId={id}
                    slug={slug}
                    removeLink={(linkId, data) => removeLink(linkId, data, name)}
                    open={deleteLinkOpened}
                    onClose={handleOpenDeleteLink}
                />
                <ApplicationLinkDialog
                    listingLinksDialogOpen={listingLinksDialogOpen}
                    handleClose={handleOpenListingLink}
                    formLink={link}
                    updateLink={updateLink}
                    slug={slug}
                    flashMessage={flashMessage}
                />
            </div>
        </>
    );
});

export default withStyles(styles)(withRouter(ApplicationLink));

import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import ItemStatus from 'components/assessments_pages/ItemStatus';
import { appCtx } from 'components/appStore';
import Paginator from './Paginator';
import SetMenu from './SetMenu';


const styles = theme => ({
    header: {
        padding: '0 30px 20px',
        marginBottom: 20,
        height: 99,
        borderBottom: `2px solid ${theme.palette.grey[300]}`
    }
});

const SetsSelection = observer(({
    classes, selectedSetData, setSelectedSet, selectedSkill,
    setSelectedSetData, sets, setSets, selectedIndex, setSelectedIndex
}) => {
    const { flashMessage } = useContext(appCtx);

    const onNext = () => {
        const newIndex = selectedIndex === sets.length - 1 ? 0 : selectedIndex + 1;
        setSelectedSet(sets[newIndex]);
        setSelectedIndex(newIndex);
    };

    const onPrev = () => {
        const newIndex = !selectedIndex ? sets.length - 1 : selectedIndex - 1;
        setSelectedSet(sets[newIndex]);
        setSelectedIndex(newIndex);
    };

    return (
        <>
            <div className={clsx('u-dsp--distribute', classes.header)}>
                <div className="u-dsp--f u-ai--center u-jc--start">
                    <Typography
                        variant="h5"
                        className="u-mrg--rx2"
                    >
                        Set
                    </Typography>
                    {
                        Boolean(sets.length) && (
                            <Paginator
                                selectedIndex={selectedIndex}
                                totalNumber={sets.length}
                                onNext={onNext}
                                onPrev={onPrev}
                            />
                        )
                    }
                    {
                        selectedSetData && (
                            <ItemStatus
                                className="u-mrg--lx2"
                                isInteractive
                                isSet
                                uuid={selectedSetData.uuid}
                                status={selectedSetData.status}
                                setQuestion={setSelectedSetData}
                                flashMessage={flashMessage}
                            />
                        )
                    }
                </div>
                <div>
                    <SetMenu
                        sets={sets}
                        setSets={setSets}
                        selectedIndex={selectedIndex}
                        selectedSkill={selectedSkill}
                        selectedSetData={selectedSetData}
                        setSelectedIndex={setSelectedIndex}
                        setSelectedSet={setSelectedSet}
                        setSelectedSetData={setSelectedSetData}
                    />
                </div>
            </div>
        </>
    );
});

export default withStyles(styles)(SetsSelection);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    leftPanel: {
        minHeight: 550,
        width: 465,
        minWidth: 465,
        paddingBottom: 50,
        backgroundColor: theme.palette.grey[50],
        position: 'relative',
        padding: '16px 0',
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderRight: 'none',
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        }
    },
    panelHeader: {
        margin: '12px 62px 20px'
    },
    menuItem: {
        cursor: 'pointer',
        padding: '15px 20px 20px 62px',
        '&:hover': {
            backgroundColor: theme.palette.grey[300]
        }
    },
    menuItemActive: {
        backgroundColor: theme.palette.grey[300]
    },
    menuItemDisabled: {
        '& > p': {
            color: theme.palette.grey[500]
        },
        backgroundColor: 'transparent',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    menuItemHeader: {
        fontSize: 16,
        marginBottom: 10,
        fontWeight: 700
    },
    menuItemEquipment: {
        padding: '0 60px 20px 62px'
    },
    menuItemHeaderEquipment: {
        fontSize: 16,
        paddingTop: 30,
        marginBottom: 30,
        borderTop: `2px solid ${theme.palette.grey[200]}`,
        fontWeight: 700
    },
    menuItemHeaderActive: {
        color: theme.palette.primary.main
    },
    menuItemSubheader: {
        marginBottom: 0
    },
    link: {
        color: theme.palette.primary.main
    },
    regenerateWrapper: {
        padding: '10px 55px 0'
    },
    regenerateItem: {
        borderTop: `2px solid ${theme.palette.grey[200]}`,
        paddingTop: 25
    },
    settingsButton: {
        fontWeight: 700
    }
}));

import React from 'react';
import { withRouter } from 'react-router-dom';
import BoardNoContent from 'components/board/board_no_content';
import Icon from './icon.svg';


const NoQuestionInSkillBanner = () => (
    <BoardNoContent
        hideIconWrapper
        icon={<img src={Icon} alt="Icon" />}
        header="No Questions in Skill Group"
    />
);

export default withRouter(NoQuestionInSkillBanner);

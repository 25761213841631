import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import {checkPluralNew} from "../../../../../../helper/commonFunctions";

const styles = () => ({
    actionButton: {
        fontWeight: 700,
        paddingRight: 20,
        paddingLeft: 20
    },
    deleteButton: {
        fontWeight: 700,
        minWidth: 175
    }
});

const DeleteQuestionDialog = observer(({
    classes, open, onClose, question,
    deleteQuestion, questionIndex
}) => {
    const [loading, setLoading] = useState(false);
    const { assessmentsUsing = [], setsUsing = [] } = question;

    const onDelete = () => {
        setLoading(true);
        deleteQuestion(questionIndex)
            .then(() => {
                onClose();
                setLoading(false);
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            actionComponent={(
                <>
                    <Button
                        className={classes.actionButton}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        className={classes.deleteButton}
                        onClick={onDelete}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                />
                            ) : 'Delete Question'
                        }
                    </Button>
                </>
            )}
            open={open}
            onClose={onClose}
            handleClose={onClose}
            titleComponent="Are you sure you want to delete this question?"
        >
            This question is active in <b>{setsUsing.length} {checkPluralNew(setsUsing.length, 'set')} </b>
            and <b>{assessmentsUsing.length} {checkPluralNew(assessmentsUsing.length, 'assessment')}</b> and will be removed from every instance.
            If this question is used in public assessments, it will be removed from them in Public Library as well.
            {
                assessmentsUsing.map(({ name, uuid: assessmentUuid, setsContaining }) => (
                    <div key={assessmentUuid} className="t-txt--brand-new u-txt--14 u-txt--semibold u-mrg--tx2">
                        {name} ({checkPluralNew(setsContaining.length, 'set')}: {setsContaining.map(({ title }) => title).join(', ')})
                    </div>
                ))
            }
            {
                Boolean(setsUsing.length) && (
                    <p className="u-txt--12 u-txt--semibold u-mrg--bx1 u-mrg--tx2">Sets:</p>
                )
            }
            {
                setsUsing.map(({ title, uuid: assessmentUuid }) => (
                    <div key={assessmentUuid} className="t-txt--brand-new u-txt--14 u-txt--semibold u-mrg--bx2">
                        {title}
                    </div>
                ))
            }
        </Dialog>
    );
});

export default withStyles(styles)(DeleteQuestionDialog);

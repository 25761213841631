export default theme => ({
    moveButton: {
        position: 'absolute',
        top: 15,
        right: 5,
        padding: '2px 3px',
        fontSize: 12,
        fontWeight: 700,
        backgroundColor: theme.palette.grey[200],
        '&:hover': {
            backgroundColor: theme.palette.grey[300]
        }
    },
    questionNumber: {
        backgroundColor: theme.palette.grey[400],
        borderRadius: 4,
        color: '#fff',
        fontSize: 14,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 15,
        padding: '4px 9px'
    },
    questionItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'relative',
        padding: '12px 16px',
        lineHeight: '24px',
        fontSize: '14px',
        '&:not(:last-child)': {
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        }
    },
    questionItemTextWrapper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: theme.palette.grey[900],
        textOverflow: 'ellipsis',
        fontSize: 12,
        fontWeight: 600,
        maxWidth: 'calc(100% - 120px)',
        lineHeight: '31px'
    },
    questionItemTextWrapperEmpty: {
        whiteSpace: 'normal',
        maxWidth: '100%'
    },
    skillsMenuItem: {
        fontSize: 12,
        minWidth: 140,
        fontWeight: 600,
        color: theme.palette.grey[700]
    }
});

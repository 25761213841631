export default theme => ({
    wrapper: {
        marginBottom: 25,
        marginTop: 20,
        color: theme.palette.grey[900],
        '& > p': {
            marginBottom: 20,
            fontSize: 14,
            fontWeight: 600
        }
    },
    divider: {
        paddingBottom: 20,
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    }
});

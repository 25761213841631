import React from 'react';
import { observer } from 'mobx-react-lite';

// material components
import TextInputOutlined from 'libraries/TextInput';
import Select from 'libraries/Select';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styles from './styles';


const MultipleChoiceInput = observer(({
    classes, onChange, index,
    answers, actionLoading, removeAnswer,
    item: { title, correct },
    autoGraded
}) => {
    const handleChangeText = (event) => {
        const { value } = event.target;
        onChange(index, 'title', value);
    };

    const handleChangePass = (e) => {
        const { value } = e.target;
        onChange(index, 'correct', value);
    };

    return (
        <div className="u-dsp--f u-ai--start u-wdt--100p u-pos--relative u-mrg--bx2">
            <TextInputOutlined
                className={classes.input}
                value={title}
                name="title"
                isError={!title}
                variant="outlined"
                label="Enter answer"
                onChange={handleChangeText}
                fullWidth
                maxLength={25000}
            />
            {
                autoGraded && (
                    <Select
                        label="Grade"
                        margin="none"
                        value={typeof correct !== 'boolean' ? '' : correct}
                        variant="outlined"
                        className={classes.select}
                        onChange={handleChangePass}
                        isError={typeof correct !== 'boolean'}
                        options={[
                            { value: true, label: 'Pass' },
                            { value: false, label: 'Fail' }
                        ]}
                    />
                )
            }
            {
                answers && answers.length > 2 && (
                    <IconButton
                        type="button"
                        onClick={event => removeAnswer(event, index)}
                        className={classes.closeIcon}
                        disabled={actionLoading}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                )
            }
        </div>
    );
});

export default withStyles(styles)(MultipleChoiceInput);

export default {
    noAnswer: {
        height: 70,
        borderRadius: 4,
        backgroundColor: '#f0f0f0',
        color: '#3a3c43',
        fontWeight: 600,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

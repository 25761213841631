import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import CopyToClipboard from 'react-copy-to-clipboard';

import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import TextInput from 'libraries/TextInput';
import { getJobViteSettings, updateJobVite } from 'requests/SettingsRequests';
import Switch from 'libraries/SwitchBrandWithLabel';
import SettingsEvents from 'events/SettingsEvents';

import stylesJS from './styles';

const JobViteContent = ({ flashMessage, classes, company, updateCompany }) => {
    const [form, setForm] = useState({
        apiKey: '',
        secretKey: '',
        url: '',
        enabled: false,
        errorForm: {}
    });
    const [loading, setLoading] = useState(false);
    const { apiKey, secretKey, url, enabled, errorForm } = form;

    useEffect(() => {
        setLoading(true);
        getJobViteSettings()
            .then(({ success, data }) => {
                if (success && data) {
                    const {
                        enabled: newEnabled,
                        secretKey: newSecretKey,
                        webhookUrl,
                        apiKey: newApiKey
                    } = data;
                    setForm({
                        ...form,
                        secretKey: newSecretKey,
                        apiKey: newApiKey,
                        enabled: newEnabled,
                        url: webhookUrl
                    });
                    updateCompany({ ...company, isJobViteAtsEnabled: newEnabled });
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    const handleChange = (e) => {
        const { value, name } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleChangeSwitch = () => {
        SettingsEvents.API_ENABLED({ enabled: !enabled });
        changeJobViteSettings(!enabled)
            .then((response) => {
                const { success, data } = response;
                const { enabled: newEnabled } = data;
                if (success) {
                    flashMessage(`Integration has been turned ${newEnabled ? 'on' : 'off'}`, 'done');
                } else {
                    flashMessage('Something went wrong', '');
                }
            });
    };

    // eslint-disable-next-line react/destructuring-assignment
    const changeJobViteSettings = (changedEnabled = enabled) => updateJobVite({ enabled: changedEnabled, apiKey, secretKey })
        .then((response) => {
            const { success, data } = response;
            if (success) {
                const {
                    secretKey: newSecretKey, enabled: newEnabled,
                    apiKey: newApiKey, webhookUrl
                } = data;
                setForm({
                    ...form,
                    secretKey: newSecretKey,
                    apiKey: newApiKey,
                    enabled: newEnabled,
                    url: webhookUrl,
                    errorForm: {}
                });
                updateCompany({ ...company, isJobViteAtsEnabled: newEnabled });
                flashMessage('Integration settings updated', 'done');
            }
            return response;
        })
        .catch(({ response }) => {
            const { data: { errors } } = response;
            const newErrorForm = {};
            Object.keys(errors).forEach((key) => {
                newErrorForm[key] = errors[key][0].message;
            });
            setForm({
                ...form,
                errorForm: newErrorForm,
                url
            });
        });

    const renderError = (value) => {
        if (errorForm && errorForm[value]) {
            return errorForm[value];
        }
        return '';
    };

    const onCopyLink = () => {
        flashMessage('Launch URL copied to clipboard');
    };


    if (loading) {
        return (
            <div className={classes.progressWrapper}>
                <CircularProgress
                    size={30}
                    className={classes.progress}
                />
            </div>
        );
    }

    return (
        <div>
            <Switch
                label="Enable Jobvite integration"
                checked={enabled}
                value={enabled}
                onChange={handleChangeSwitch}
            />
            <br />
            <TextInput
                label="API key"
                className={clsx('u-mrg--bx2', classes.input)}
                isError={Boolean(renderError('apiKey'))}
                helperText={renderError('apiKey')}
                fullWidth
                name="apiKey"
                type="text"
                variant="outlined"
                value={apiKey}
                onChange={handleChange}
            /><br />
            <TextInput
                label="Secret key"
                className={clsx('u-mrg--bx2', classes.input)}
                isError={Boolean(renderError('secretKey'))}
                helperText={renderError('secretKey')}
                fullWidth
                name="secretKey"
                type="text"
                variant="outlined"
                value={secretKey}
                onChange={handleChange}
            /><br />
            <Button
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={() => changeJobViteSettings()}
            >
                Save
            </Button>
            {
                enabled && (
                    <div className={classes.textFieldWrapper}>
                        <CopyToClipboard
                            text={url}
                            className={classes.buttonRoot}
                            onCopy={onCopyLink}
                        >
                            <Button color="primary">
                                COPY LINK
                            </Button>
                        </CopyToClipboard>
                        <TextInput
                            label="Webhook url"
                            fullWidth
                            variant="outlined"
                            name="webhook_url"
                            type="text"
                            value={url}
                            className={classes.input}
                            isError={Boolean(renderError('webhookUrl'))}
                            helperText={renderError('webhookUrl')}
                            disabled
                        />
                    </div>
                )
            }
        </div>
    );
};

export default withStyles(stylesJS)(JobViteContent);

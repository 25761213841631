import React from 'react';

const SaveIcon = ({ color = '#7F8084', ...other }) => (
    <svg width="24" height="24" viewBox="0 1 20 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M18.0003 11.1076V23.1041C18.0003 24.2087 17.1049 25.1041 16.0003 25.1041H3.66699C2.56242 25.1041 1.66699 24.2087 1.66699 23.1041V6.83332C1.66699 5.72875 2.56242 4.83331 3.66699 4.83331H11.5837" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.75 12.5555H13.9167" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.75 18.3472H9.83333" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.75 15.4514H13.9167" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 1.33331L18 8.33331" stroke={color} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.5 4.83331H14.5" stroke={color} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default SaveIcon;

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route } from 'react-router-dom';
import { appCtx } from '../../appStore';

const ProtectedWithSpecificValue = observer(({ value, render, ...other }) => {
    const { isLoadingUser } = useContext(appCtx);
    if (isLoadingUser) return null;
    if (!value) return <Redirect to="/forbidden" />;
    return (
        <Route
            {...other}
            render={render}
        />
    );
});

export default ProtectedWithSpecificValue;

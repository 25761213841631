import React from 'react';
import { withTheme } from '@mui/styles';

const IndeedIcon = ({ theme, active, size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect width="32" height="32" rx="4" fill={active ? theme.palette.primary.main : theme.palette.grey[400]} />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.1473 6.00234C17.3058 6.0238 16.4589 6.19271 15.6538 6.47137C12.5357 7.72149 10.3674 10.4492 9.38643 13.722C9.22081 14.3363 9.08647 14.9512 9.01435 15.5858C9.01435 15.6063 8.96265 16.2311 9.05551 16.0625C9.14837 15.9141 9.18954 15.6955 9.24124 15.5266C9.71607 13.9896 10.2949 12.5908 11.1827 11.2619C13.3099 8.25612 16.7272 6.32239 20.3405 7.39379C21.0119 7.61144 21.6106 7.92933 22.2093 8.30609C22.3225 8.38549 23.1589 9.02036 22.9732 8.44496C22.8184 7.98942 22.4262 7.60163 22.0851 7.29416C20.937 6.33955 19.5501 5.96648 18.1473 6.00234ZM17.3336 9.83579C16.8971 9.82482 16.4648 9.91959 16.0766 10.1114C14.7861 10.7358 14.2695 12.2533 14.9303 13.493C15.5807 14.733 17.1596 15.2293 18.4504 14.5947C19.7409 13.9696 20.2573 12.4522 19.5967 11.2131C19.3845 10.8083 19.0619 10.4664 18.6634 10.2238C18.2648 9.98128 17.8052 9.84716 17.3336 9.83579ZM19.1014 15.9534C18.2749 16.4285 17.3048 16.6967 16.2725 16.6967C16.0562 16.6967 15.8494 16.6872 15.6324 16.667V23.9666C15.6324 24.6411 15.7977 25.1469 16.1181 25.4844C16.4483 25.8314 16.8514 26 17.3572 26C17.8729 26 18.297 25.8314 18.6161 25.4942C18.9368 25.157 19.1014 24.6512 19.1014 23.9666V15.9537V15.9534Z" fill="white" />
    </svg>
);

export default withTheme(IndeedIcon);

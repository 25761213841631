import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withStyles, withTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import Tooltip from 'libraries/Tooltip';
import { clone } from 'helper/commonFunctions';
import { Scrollbars } from 'react-custom-scrollbars';
import ReorderIcon from 'img/reorder.svg';

import ShieldIcon from 'components/icons/ShieldIcon';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import AddSkill from './AddSkill';
import styles from './styles';


const SetsItem = withTheme(({ classes, theme, setSelectedSet, selectedSet, set }) => {
    const { uuid, companySkill, companyQuestions } = set;
    return (
        <div
            role="presentation"
            onClick={() => setSelectedSet(set)}
            className={clsx(classes.set, (selectedSet && selectedSet.uuid === uuid) && classes.setSelected)}
        >
            <div className="u-dsp--distribute u-mrg--bx2">
                <div className={classes.badge}>
                    <ShieldIcon color={theme.palette.primary.main} />Skill
                </div>
                <Tooltip label="Reorder">
                    <IconButton
                        data-drag="drop"
                        className={clsx(classes.icon, 'u-cursor--g')}
                        size="large"
                    >
                        <img src={ReorderIcon} alt="reorder" />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="u-dsp--f">
                <TruncateWithTooltip
                    width={290}
                    text={companySkill ? companySkill.title : ''}
                /> <div className="u-dsp--ib u-mrg--lx1">({companyQuestions.length})</div>
            </div>
        </div>
    );
});

const SetsList = observer(({
    setsList, selectedSet, classes, theme,
    auditionUuid, setSelectedSet, addSet,
    updateSets, saveSetsSort
}) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;
        let newSetsList = clone(setsList);
        newSetsList = arrayMove(newSetsList, oldIndex, newIndex);
        const set = setsList[oldIndex];
        if (updateSets) updateSets(newSetsList, true);
        if (saveSetsSort) saveSetsSort(set, newIndex);
    };

    const shouldCancelStart = (e) => {
        const { dataset, parentElement } = e.target;
        const { dataset: datasetParent } = parentElement;
        return dataset.drag !== 'drop' && datasetParent.drag !== 'drop';
    };


    const SortableItem = SortableElement(({ value }) => (
        <SetsItem
            set={value}
            classes={classes}
            selectedSet={selectedSet}
            setSelectedSet={setSelectedSet}
        />
    ));

    const SortableList = SortableContainer(({ items }) => (
        <div>
            {items.map((value, index) => (
                <SortableItem key={value.companySkill.id} {...{ value, index }} />
            ))}
        </div>
    ));

    return (
        <div className={classes.wrapper}>
            <div className="u-dsp--distribute">
                <Typography variant="h6">Content</Typography>
                {
                    selectedSet && (
                        <Button
                            color="primary"
                            className="u-txt--bold"
                            onClick={() => setSelectedSet(null)}
                        >
                            View all Questions <KeyboardArrowRight color={theme.palette.primary.main} />
                        </Button>
                    )
                }
            </div>
            <div>
                <Scrollbars
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={700}
                >
                    <SortableList
                        items={setsList || []}
                        onSortEnd={onSortEnd}
                        shouldCancelStart={shouldCancelStart}
                    />
                </Scrollbars>
            </div>
            <AddSkill
                addSet={addSet}
                auditionUuid={auditionUuid}
            />
        </div>
    );
});

export default withTheme(withStyles(styles)(SetsList));

import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import styles from './styles';

const transformItemNaming = (item, invitationsStats, isSource) => {
    if (isSource && invitationsStats && invitationsStats.sources) {
        const foundItem = invitationsStats.sources.find(({ key }) => key === item);
        if (foundItem) return foundItem.name;
    }

    switch (item) {
        case 'moreTimeRequested': return 'More time requested';
        case 'inProgress': return 'In progress';
        case 'multipleGeolocations': return 'Multiple Geolocations';
        case 'screeningInProgress': return 'Screening in progress';
        case 'screeningFailed': return 'Failed Screening';
        default: return item.replace(/_/gi, ' ');
    }
};

const TagsList = ({
    classes, filterTagsCategory, filterSourceCategory,
    filterCategory, handleRemoveFilterSelect,
    handleChangeFilterSelect, invitationsStats,
    filterPlagiarismCategory
}) => {
    const tag = (item, isTags, isSource) => (
        <div key={item} className={classes.tag}>
            <TruncateWithTooltip capitalize text={transformItemNaming(item, invitationsStats, isSource)} width={130} />
            <IconButton
                className={classes.closeButton}
                onClick={() => (handleRemoveFilterSelect
                    ? handleRemoveFilterSelect(item, isTags, isSource)
                    : handleChangeFilterSelect()
                )}
                size="large"
            >
                <CloseIcon className={classes.closeIcon} />
            </IconButton>
        </div>
    );

    const renderItems = (array, isTags, isSource) => {
        if (!array) return null;
        return (
            Object.keys(array)
                .filter(item => item !== 'shortlist')
                .map(item => tag(item, isTags, isSource))
        );
    };

    if (!handleRemoveFilterSelect) { // only for AllCandidates page
        if (filterCategory === 'all') return null;
        return <div className="u-mrg--lx3">{tag(filterCategory)}</div>;
    }

    return (
        <div className="u-mrg--lx3">
            { renderItems(filterCategory, false) }
            { renderItems(filterSourceCategory, false, true) }
            { renderItems(filterTagsCategory, true) }
            { renderItems(filterPlagiarismCategory, false, false) }
        </div>
    );
};

export default withStyles(styles)(TagsList);

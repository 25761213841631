import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import styles from './styles';

const ComeetIcon = withStyles(styles)(withTheme(({ theme, isActive }) => {
    const fillColor = isActive ? theme.palette.primary.main : '#A9AAAE';
    return (
        <svg width="115" height="28" viewBox="0 0 115 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.4456 8.57955C11.0724 8.57955 9.14148 6.65512 9.14148 4.28966C9.14148 1.92431 11.0724 0 13.4456 0C15.8189 0 17.7497 1.92431 17.7497 4.28966C17.7497 6.65512 15.8189 8.57955 13.4456 8.57955ZM13.4456 1.90597C12.1268 1.90597 11.0538 2.97528 11.0538 4.28966C11.0538 5.60404 12.1268 6.67358 13.4456 6.67358C14.7644 6.67358 15.8374 5.60404 15.8374 4.28966C15.8374 2.97528 14.7644 1.90597 13.4456 1.90597Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.4456 12.424C8.94627 12.424 5.28583 8.77577 5.28583 4.29126H5.69545C6.48416 4.29126 7.17165 4.83621 7.33786 5.60475C7.94443 8.40956 10.4525 10.518 13.4456 10.518C16.4385 10.518 18.9466 8.40956 19.5532 5.60475C19.7194 4.83621 20.4069 4.29126 21.1956 4.29126H21.6052C21.6052 8.77577 17.9449 12.424 13.4456 12.424Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M22.5807 24.3613C21.8509 24.3613 21.1122 24.1766 20.436 23.7875C19.4403 23.2145 18.7282 22.2896 18.4307 21.1827C18.133 20.0759 18.2858 18.92 18.8606 17.9274C19.4354 16.9352 20.3636 16.2254 21.474 15.9287C22.5844 15.6322 23.7444 15.7844 24.74 16.3574C25.7356 16.9303 26.4478 17.8554 26.7454 18.9621C27.0429 20.069 26.8902 21.2251 26.3153 22.2173C25.5191 23.5919 24.0693 24.3613 22.5807 24.3613ZM21.3922 22.1369C22.5343 22.7941 23.9999 22.4027 24.6592 21.2643C24.9788 20.713 25.0637 20.0705 24.8983 19.4555C24.7329 18.8405 24.337 18.3264 23.7839 18.0079C23.2306 17.6896 22.586 17.6051 21.969 17.7697C21.3519 17.9346 20.8361 18.329 20.5167 18.8805C20.1972 19.4321 20.1123 20.0743 20.2778 20.6894C20.4431 21.3045 20.8389 21.8186 21.3922 22.1369Z" fill={fillColor} />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="14" y="11" width="13" height="17">
                <path d="M14.4235 11.9353H26.6665V27.1147H14.4235V11.9353Z" fill={fillColor} />
            </mask>
            <g mask="url(#mask0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M18.5068 27.1147C16.6193 26.0285 15.2691 24.2748 14.705 22.1765C14.1051 19.9452 14.4706 17.6082 15.7371 15.6514C16.7518 14.0833 18.2919 12.9052 20.074 12.3337C22.299 11.6201 24.6589 11.8732 26.6665 13.0283L26.4617 13.382C26.0672 14.0628 25.25 14.3837 24.4991 14.143C21.7587 13.2642 18.6726 14.3748 17.1762 16.9581C16.3418 18.3985 16.1202 20.0766 16.5522 21.683C16.8669 22.8542 17.5016 23.8852 18.3823 24.6801C18.9701 25.2107 19.1075 26.0777 18.7109 26.7622L18.5068 27.1147Z" fill={fillColor} />
            </g>
            <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="15" width="9" height="10">
                <path d="M0 15.7808H8.60902V24.3613H0V15.7808Z" fill={fillColor} />
            </mask>
            <g mask="url(#mask1)">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.31024 24.3613C2.82157 24.3613 1.37185 23.592 0.575617 22.2173C-0.611048 20.1688 0.0957441 17.5402 2.15092 16.3574C3.14658 15.7844 4.30653 15.6322 5.417 15.9287C6.52746 16.2254 7.45556 16.9352 8.03038 17.9274C8.60519 18.92 8.75793 20.0759 8.46031 21.1827C8.1628 22.2896 7.45062 23.2145 6.45497 23.7875C5.77892 24.1765 5.03981 24.3613 4.31024 24.3613ZM4.29913 17.6876C3.88468 17.6876 3.47562 17.7959 3.10707 18.0079C1.96496 18.6651 1.57229 20.1261 2.23172 21.2643C2.89104 22.4027 4.3567 22.794 5.49881 22.1369C6.05219 21.8186 6.4479 21.3045 6.6132 20.6894C6.77862 20.0743 6.69378 19.4321 6.37439 18.8805C6.05488 18.329 5.5391 17.9346 4.92197 17.7697C4.71604 17.7148 4.50674 17.6876 4.29913 17.6876Z" fill={fillColor} />
            </g>
            <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="11" width="13" height="17">
                <path d="M0.22467 11.9404H12.4678V27.1147H0.22467V11.9404Z" fill={fillColor} />
            </mask>
            <g mask="url(#mask2)">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.38443 27.1147L8.18029 26.7623C7.78369 26.0777 7.92105 25.2108 8.50889 24.6801C9.38963 23.8852 10.0243 22.8542 10.339 21.683C10.771 20.0766 10.5494 18.3985 9.71497 16.9582C8.21856 14.3748 5.13249 13.2642 2.39207 14.1431C1.64117 14.3838 0.823951 14.0629 0.429481 13.382L0.22467 13.0283C4.12123 10.7862 9.12141 12.1217 11.3711 16.0053C12.4609 17.8865 12.7503 20.0782 12.1862 22.1765C11.6221 24.2748 10.2719 26.0285 8.38443 27.1147Z" fill={fillColor} />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M73.3049 8.52148C71.747 8.52148 70.4124 9.17537 69.5117 10.3703C68.7386 9.17504 67.4649 8.52148 65.8886 8.52148C64.6175 8.52148 63.2615 8.96957 62.6699 9.99425C62.6699 9.24361 62.0521 8.58602 60.6612 8.77326H60.5657V21.3097H62.6699V14.3074C62.6699 12.502 63.4793 10.5731 65.7507 10.5731C67.3377 10.5731 68.2117 11.5903 68.2117 13.4376V21.3097H70.3159V14.3074C70.3159 11.9341 71.3969 10.5731 73.2819 10.5731C74.9188 10.5731 75.8575 11.6172 75.8575 13.4376V19.6319C75.8575 20.5585 76.6112 21.3097 77.5409 21.3097H77.9618V13.4376C77.9618 10.4512 76.1338 8.52148 73.3049 8.52148Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M115 10.8476V8.77332H111.579V4.84595L109.452 6.06883V8.77332H106.926V10.8476H109.452V17.8086C109.452 19.0581 109.785 19.9508 110.471 20.5381C111.068 21.049 111.897 21.2976 113.006 21.2976C113.48 21.2976 114 21.2535 114.594 21.1632L115 21.1015V19.1419L114.48 19.1877C113.299 19.2916 112.289 19.3484 111.898 18.9914C111.683 18.7958 111.579 18.4089 111.579 17.8086V10.8476H115Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M52.5432 8.50195C48.9694 8.50195 46.0618 11.3997 46.0618 14.9619C46.0618 18.5238 48.9694 21.4216 52.5432 21.4216C56.117 21.4216 59.0245 18.5238 59.0245 14.9619C59.0245 11.3997 56.117 8.50195 52.5432 8.50195ZM56.8997 14.9619C56.8997 17.356 54.9453 19.3038 52.5432 19.3038C50.1409 19.3038 48.1866 17.356 48.1866 14.9619C48.1866 12.5676 50.1409 10.6198 52.5432 10.6198C54.9453 10.6198 56.8997 12.5676 56.8997 14.9619Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M42.15 18.1644C41.3121 18.9291 40.1742 19.3723 38.9346 19.2951C36.7768 19.1609 35.0224 17.4299 34.8674 15.2806C34.6845 12.7429 36.7043 10.6195 39.2124 10.6195C40.3231 10.6195 41.3379 11.036 42.1081 11.7206C42.6841 12.2325 43.5456 12.2641 44.1804 11.8264L44.6715 11.488L44.6728 11.4881C43.4412 9.57188 41.2246 8.34172 38.7396 8.51856C35.5681 8.74428 33.0027 11.2794 32.752 14.4386C32.4506 18.2357 35.4653 21.4216 39.2124 21.4216C41.5284 21.4216 43.5635 20.2036 44.71 18.3775L44.2355 18.0453C43.5967 17.5981 42.7258 17.639 42.15 18.1644Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M81.7332 13.9541C82.1755 12.0085 83.7979 10.671 85.7585 10.671C87.7191 10.671 89.3416 12.0085 89.7838 13.9541H81.7332ZM85.7585 8.55322C82.2432 8.55322 79.4897 11.3908 79.4897 15.0132C79.4897 18.6353 82.2432 21.4729 85.7585 21.4729C85.8037 21.4729 85.8486 21.4702 85.8936 21.4687V21.47C88.1475 21.4189 90.1201 20.2151 91.2418 18.4284L90.7673 18.0963C90.1285 17.6491 89.2575 17.6899 88.6818 18.2154C87.9454 18.8873 86.9771 19.31 85.912 19.3505V19.3504C85.8608 19.3522 85.8099 19.3551 85.7585 19.3551C83.7978 19.3551 82.1755 18.0176 81.7332 16.0718H91.9411L91.9663 15.8887C92.0068 15.5938 92.0273 15.2994 92.0273 15.0132C92.0273 11.3908 89.2738 8.55322 85.7585 8.55322Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M95.8733 13.9541C96.3155 12.0085 97.938 10.671 99.8985 10.671C101.859 10.671 103.482 12.0085 103.924 13.9541H95.8733ZM99.8985 8.55322C96.3833 8.55322 93.6299 11.3908 93.6299 15.0132C93.6299 18.6353 96.3833 21.4729 99.8985 21.4729C99.9438 21.4729 99.9887 21.4702 100.034 21.4687V21.47C102.288 21.4189 104.26 20.2151 105.382 18.4284L104.907 18.0963C104.269 17.6491 103.398 17.6899 102.822 18.2154C102.085 18.8873 101.117 19.31 100.052 19.3505V19.3504C100.001 19.3522 99.9499 19.3551 99.8985 19.3551C97.9379 19.3551 96.3155 18.0176 95.8733 16.0718H106.081L106.106 15.8887C106.147 15.5938 106.168 15.2994 106.168 15.0132C106.168 11.3908 103.414 8.55322 99.8985 8.55322Z" fill={fillColor} />
        </svg>
    );
}));

export default ComeetIcon;

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { EXCEL_AUTO_GRADED } from 'helper/constants';
import Loader from 'components/loaders/Loader';
import { appCtx } from 'components/appStore';

import { createGoogleDriveDocument, uploadMicrosoftExcel } from 'requests/ScriptRequests';
import SpreadsheetAutoGraded from './components/SpreadsheetAutoGraded';
import DocDialog from '../DocDialog';

const docTypes = {
    [EXCEL_AUTO_GRADED]: 'excel'
};

const ExcelQuestionWrapper = observer(({
    question, fetchQuestion, setSaving, isCMS
}) => {
    const [excelLink, setExcelLink] = useState(null);
    const [excelDialogOpen, setExcelDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { answerType, oneDriveFile, id } = question;

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setExcelLink(oneDriveFile);
    }, [oneDriveFile, id]);

    const title = docTypes[answerType];

    const handleExcelDialog = () => {
        setExcelDialogOpen(!excelDialogOpen);
    };

    const createExcel = () => {
        const formData = new FormData();
        formData.append('questionId', question.id);
        formData.append('mimeType', title);
        setLoading(true);
        setSaving(true);
        createGoogleDriveDocument(id, formData, isCMS)
            .then(({ data, success }) => {
                if (success && data) {
                    setExcelLink(data);
                    fetchQuestion();
                    setExcelDialogOpen(true);
                    setLoading(false);
                    setSaving(false);
                } else {
                    setLoading(false);
                    setSaving(false);
                    flashMessage('Something went wrong');
                }
            })
            .catch(() => {
                setLoading(false);
                setSaving(false);
                flashMessage('Something went wrong');
            });
    };

    const uploadExcel = (file) => {
        const formData = new FormData();
        formData.append('questionId', question.id);
        formData.append('mimeType', docTypes[question.answerType]);
        formData.append('file', file);
        setLoading(true);
        setSaving(true);
        uploadMicrosoftExcel(id, formData, isCMS)
            .then(({ data, success, errorMessage }) => {
                if (success && data) {
                    setExcelLink(data);
                    fetchQuestion();
                    setExcelDialogOpen(true);
                }
                if (!success && errorMessage) {
                    flashMessage(errorMessage, 'error');
                }
                setLoading(false);
                setSaving(false);
            })
            .catch(({ response }) => {
                setLoading(false);
                setSaving(false);
                flashMessage(response?.data?.msg || 'Something went wrong');
            });
    };

    return (
        <>
            <SpreadsheetAutoGraded
                {...{
                    isCMS,
                    question,
                    excelLink,
                    loading,
                    title,
                    excelDialogOpen,
                    handleExcelDialog,
                    fetchQuestion,
                    setSaving,
                    createExcel,
                    onDrop: (files, rejected) => uploadExcel(files[0], rejected),
                    flashMessage
                }}
            />
            <div className="o-grid__7/10  js-main-content u-pos--relative">
                <DocDialog
                    {...{
                        isOpen: excelDialogOpen,
                        onClose: handleExcelDialog,
                        question,
                        sourceLink: excelLink?.editUrl
                    }}
                />
                <div className="u-pos--overlay">
                    <Loader />
                </div>
            </div>
        </>
    );
});

export default ExcelQuestionWrapper;

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import HiringManagerFeedbackDialog from 'components/dialogs/HiringManagerFeedbackDialog';
import DoneSurvey from './doneSurvey.svg';
import Survey from './survey.svg';

const useStyles = makeStyles(() => ({
    icon: {
        padding: 4
    }
}));

const ManagerFeedbackButton = observer(({ candidate }) => {
    const { icon } = useStyles();
    const { hiringManagerFeedback } = candidate;
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

    const handleFeedbackSurveyClick = (e) => {
        e.stopPropagation();
        setFeedbackDialogOpen(true);
    };

    return <>
        <IconButton
            disabled={!hiringManagerFeedback}
            className={icon}
            onClick={handleFeedbackSurveyClick}
            size="large"
        >
            <img src={hiringManagerFeedback ? DoneSurvey : Survey} alt="i" />
        </IconButton>
        {hiringManagerFeedback && Object.keys(hiringManagerFeedback).length && (
            <HiringManagerFeedbackDialog
                candidate={candidate}
                open={feedbackDialogOpen}
                onClose={() => setFeedbackDialogOpen(false)}
            />
        )}
    </>;
});

export default ManagerFeedbackButton;

import React from 'react';
import { makeStyles } from '@mui/styles';
import RetinaImage from 'react-retina-image';

const useStyles = makeStyles(theme => ({
    text: {
        fontWeight: 600,
        fontSize: 16,
        color: theme.palette.grey[900],
        whiteSpace: 'pre-wrap',
        marginTop: 20
    },
    companyLogo: {
        color: theme.palette.grey[900],
        fontSize: 25,
        fontFamily: 'Proximanova-Extrabold'
    }
}));

const PublicHeader = ({ company }) => {
    if (!company) return null;

    const { company_image_url, name } = company;
    const classes = useStyles();
    return (
        <header role="banner" className="u-mrg--bx6">
            {
                company_image_url?.length ? (
                    <div
                        className={classes.companyLogo}
                        role="presentation"
                        style={{ height: 45, maxWidth: 200 }}
                    >
                        <RetinaImage
                            alt="Logo"
                            src={company_image_url?.[0]}
                            style={{ height: 45 }}
                        />
                    </div>
                ) : <div className={classes.companyLogo}>{company.name}</div>
            }
            <p className={classes.text}>
                Account insights for <b>{name}</b> has been shared with you. This link will be active for 14 days; please request a new link once it expires.
            </p>
        </header>
    );
};

export default PublicHeader;

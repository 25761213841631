import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import NumberInput from 'components/inputs/NumberInput';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import useDebounce from 'hooks/useDebounce';
import { getCandidateFeedbackPDF } from 'requests/EmailRequests';
import { downloadFile } from 'helper/commonFunctions';
import CandidateFeedbackButton from 'components/assessments_pages/asessment_settings_components/InstructionToCandidatesSection/components/CandidateFeedbackButton';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import { Link } from 'react-router-dom';
import useStyles from './styles';

const RADIO = {
    DAYS: 'days',
    AFTER: 'after'
};

const EVENT_STRINGS = {
    closed: 'after assessment closed',
    days: 'days after last completion'
};

const CandidateFeedback = ({ switchValue, daysToSendCandidateFeedback, handleChangeCandidateFeedback, slug, audition, updateSettings, isAgreement, userRoleType }) => {
    const [feedbackVariant, setFeedbackVariant] = useState(daysToSendCandidateFeedback === -1 ? RADIO.AFTER : 'days');
    const [daysCount, setDaysCount] = useState(daysToSendCandidateFeedback === -1 ? 7 : daysToSendCandidateFeedback);

    const hasEditPermissions = (userRoleType === 'owner' || userRoleType === 'admin');

    useDebounce(daysCount, 300, () => {
        handleChangeCandidateFeedback(true, {
            daysToSendCandidateFeedback: feedbackVariant === RADIO.AFTER ? -1 : Number(daysCount)
        });
        handleSegmentEvent(feedbackVariant);
    });

    const classes = useStyles();

    const handleRadioChange = (e) => {
        const { value } = e.target;
        setFeedbackVariant(value);
        handleChangeCandidateFeedback(true, {
            daysToSendCandidateFeedback: value === RADIO.AFTER ? -1 : daysCount
        });
        handleSegmentEvent(value);
    };

    const handleSegmentEvent = (value) => {
        const additionalEventProps = {};
        if (value === RADIO.AFTER) {
            additionalEventProps.sendingEvent = EVENT_STRINGS.closed;
        } else {
            additionalEventProps.sendingEvent = EVENT_STRINGS.days;
            additionalEventProps.daysNumber = daysCount;
        }
        AssessmentSettingsEvents.FEEDBACK_SETTING_CHANGED({
            ttUUID: audition.uuid,
            ttName: audition.name,
            ...additionalEventProps
        });
    };

    const handleSwitchChange = (e) => {
        const { checked } = e.target;
        handleChangeCandidateFeedback(checked, checked ? {
            daysToSendCandidateFeedback: daysCount
        } : {});
        AssessmentSettingsEvents.FEEDBACK_ENABLED({
            ttUUID: audition.uuid,
            ttName: audition.name,
            enabled: checked
        });
    };

    const handleChangeDays = (e) => {
        const { value } = e.target;

        let daysValue = parseInt(value, 10);
        if (Number.isNaN(daysValue) || daysValue <= 3) {
            daysValue = 3;
        }
        if (daysValue >= 30) {
            daysValue = 30;
        }

        setDaysCount(daysValue);
        updateSettings({ daysToSendCandidateFeedback: Number(daysValue) });
    };

    const handlePreviewClick = async () => {
        const response = await getCandidateFeedbackPDF(slug);
        downloadFile(response, 'candidate-feedback.pdf', 'application/pdf');
    };

    return (
        <>
            <div className="u-dsp--distribute">
                <div className="u-mrg--bx2 u-dsp--f u-fw--wrap">
                    <Typography variant="h5" className="u-pdn--lx1 u-mrg--rx2">
                        Send Candidate Feedback
                        <InfoTooltip
                            id="send_candidate_feedback_id"
                            position="top"
                            isBlack
                            text="Feedback will only be sent for all new completions. Candidates who have previously received feedback would not receive feedback again."
                        />
                    </Typography>
                    <Button
                        color="primary"
                        onClick={handlePreviewClick}
                        className={clsx(classes.actionButton, 'u-mrg--rx2')}
                        disabled={!isAgreement}
                    >
                        Preview PDF
                    </Button>
                    {!isAgreement && (
                        <span className={classes.agreementText}>
                            {
                                hasEditPermissions
                                    ? (
                                        <>
                                            This setting must be authorized from the&nbsp;
                                            <Link
                                                to={{
                                                    pathname: '/company-account',
                                                    state: { scrollToEnd: true }
                                                }}
                                            >
                                                company account
                                            </Link>
                                        </>
                                    )
                                    : 'This setting must be authorized by your account admin'
                            }

                        </span>
                    )}
                </div>
                <Switch
                    onChange={handleSwitchChange}
                    checked={switchValue}
                    disabled={!isAgreement}
                />
            </div>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={feedbackVariant}
                onChange={handleRadioChange}
            >
                <FormControlLabel
                    value={RADIO.DAYS}
                    disabled={!switchValue || !isAgreement}
                    control={<Radio />}
                    label={(
                        <div className="u-dsp--f u-ai--center">
                            <NumberInput
                                InputProps={{ classes: { input: classes.input } }}
                                variant="outlined"
                                min={3}
                                maxLength={30}
                                value={daysCount}
                                onChange={handleChangeDays}
                                fullWidth={false}
                                margin="none"
                                disabled={!switchValue || !isAgreement}
                            />
                            <span className="u-mrg--lx2">days after last completion</span>
                        </div>
                    )}
                />
                <div className={classes.orLabel}>OR</div>
                <FormControlLabel disabled={!switchValue || !isAgreement} value={RADIO.AFTER} control={<Radio />} label="Send Feedback after close of assessment" />
            </RadioGroup>
            <CandidateFeedbackButton audition={audition} isAgreement={isAgreement} />
        </>
    );
};

export default CandidateFeedback;

import React from 'react';
import Button from '@mui/material/Button';
import { withRouter } from 'react-router-dom';
import BoardNoContent from 'components/board/board_no_content';
import EmptyIcon from './icon.svg';

const NoAssessmentsContent = ({ handleCreateAssessment }) => (
    <BoardNoContent
        header="No Library Assessments"
        icon={<img src={EmptyIcon} alt="empty-content" />}
        text={(
                <>
                    You haven’t created any assessments yet. <br />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateAssessment}
                        className="u-mrg--tx3"
                    >
                        Create Library Assessment
                    </Button>
                </>
        )}
    />
);

export default withRouter(NoAssessmentsContent);

import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import EditPanelSection from 'components/assessments_pages/asessment_settings_components/EditPanelSection';

const OnboardingAssessment = ({
    audition, audition: { onboarding }, setAudition, changeAssessmentSettings
}) => {
    const [checked, setChecked] = useState(onboarding);

    const handleSwitch = async (e) => {
        const { checked: newChecked } = e.target;
        changeAssessmentSettings({ onboarding: newChecked })
            .then(() => {
                setAudition({ ...audition, onboarding: newChecked });
                setChecked(newChecked);
            });
    };

    return (
        <EditPanelSection
            header={(
                <>
                    Onboarding Assessment
                    <Switch
                        checked={checked}
                        onChange={handleSwitch}
                    />
                </>
            )}
        >
            Add assessment to onboarding flow
        </EditPanelSection>
    );
};

export default OnboardingAssessment;

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';

const styles = {
    previewButton: {
        padding: '7px 24px',
        lineHeight: '21px',
        whiteSpace: 'nowrap'
    }
};

const PreviewButton = observer(({ classes, disabled, uuid }) => {
    const [openPreviewScript, setOpenPreviewScript] = useState(false);

    const handlePreviewScript = () => {
        setOpenPreviewScript(!openPreviewScript);
    };

    return (
        <>
            <Button
                variant="outlined"
                className={classes.previewButton}
                disabled={disabled}
                onClick={handlePreviewScript}
            >
                Preview
            </Button>
            <PreviewScript
                {...{
                    onClose: setOpenPreviewScript,
                    isCMS: true,
                    open: openPreviewScript,
                    uuid
                }}
            />
        </>
    );
});

export default withRouter(withStyles(styles)(PreviewButton));

import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

const DetailEmailInfo = ({ value, onCopy, className = '' }) => {
    if (!value) return null;

    const content = (
        <span className={`c-card-big__header-email--text c-card-big__header-detail-info--value ${className}`}>
            {value}
        </span>
    );

    if (!onCopy) {
        return content;
    }

    return (
        <CopyToClipboard text={value} onCopy={onCopy}>
            <button className="c-card-big__header-email">
                <span
                    className="c-card-big__header-email--text c-card-big__header-detail-info--value"
                >
                    {value}
                </span>
                <span className="c-card-big__header-email--btn">copy email</span>
            </button>
        </CopyToClipboard>
    );
};

export default DetailEmailInfo;

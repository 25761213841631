import React from 'react';
import { withTheme } from '@mui/styles';

const FBIcon = ({ theme, active, size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect width="32" height="31.9887" rx="4" fill={active ? theme.palette.primary.main : theme.palette.grey[400]} />
        <path d="M13.2354 20.4755V31.9999H17.9413V20.4755H21.4501L22.1177 16.0975H17.9413V13.2565C17.9413 12.0588 18.5248 10.8912 20.3954 10.8912H22.2942V7.16398C22.2942 7.16398 20.571 6.86816 18.9233 6.86816C15.4836 6.86816 13.2354 8.96487 13.2354 12.7607V16.0975H9.41187V20.4755H13.2354Z" fill="white" />
    </svg>
);

export default withTheme(FBIcon);

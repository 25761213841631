import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Masonry from 'react-masonry-component';
import AddImg from 'img/add.svg';
import NotFound from 'img/NotFound.svg';

import { FilterCtx } from 'pages/expert_assessments/filter-store';
import PublicBoardItem from './PublicBoardItem';
import { AssessmentQueryCtx } from '../queryStore';

import styles from './styles';
import FilterPanel from './FilterPanel';
import QuickSearchPanel from './QuickSearchPanel';

const PublicAssessmentsView = observer(({
    classes, removeAssessments,
    changePage, assessments, currentPage,
    totalCount, loadingAssessments,
    loadingSearchAssessments
}) => {
    const { query } = useContext(AssessmentQueryCtx);
    const { filterData, filterHistory, handleFilter, deleteFromHistory } = useContext(FilterCtx);
    const hasFoundAssessments = Boolean(assessments && assessments.length);
    const totalPages = Math.ceil(totalCount / 12);

    const handleNextButtonClick = () => {
        if (currentPage === totalPages) return;
        changePage(currentPage + 1);
    };

    const onBoardCategoryClick = (category) => {
        if (filterData.categories?.includes(category)) return;
        handleFilter('categories', category);
    };

    return (
        <>
            <div className={classes.wrapper}>
                <FilterPanel filterPanelData={filterHistory} deleteFromHistory={deleteFromHistory} />
                <QuickSearchPanel handleFilter={handleFilter} filterData={filterData} />
                <Masonry component="div" className={classes.mansoryWrapper}>
                    {
                        !((loadingAssessments || loadingSearchAssessments) && currentPage === 0)
                        && hasFoundAssessments
                        && assessments.map((item, index) => (
                            <PublicBoardItem
                                assessment={item}
                                key={`${item.slug}_${index}`}
                                opportunityIndex={index}
                                removeAssessments={() => removeAssessments(index)}
                                query={query}
                                handleCategory={onBoardCategoryClick}
                            />
                        ))
                    }
                </Masonry>
                {
                    !hasFoundAssessments && !loadingAssessments && !loadingSearchAssessments && (
                        <div className={classes.notFoundWrapper}>
                            <img src={NotFound} alt="" />
                            <div className={classes.notFoundText}>Sorry, we don’t have what you’re looking for, please try modifying your search/filters.</div>
                        </div>
                    )
                }
                {
                    !(loadingAssessments || loadingSearchAssessments) && (
                        <div className="u-txt--center u-mrg--bx5">
                            {
                                currentPage < totalPages && (
                                    <Button
                                        color="primary"
                                        onClick={handleNextButtonClick}
                                        className="u-txt--bold"
                                    >
                                        <img src={AddImg} className="u-mrg--rx1" alt="+" /> Load more
                                    </Button>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </>
    );
});

export default withRouter(withStyles(styles)(PublicAssessmentsView));

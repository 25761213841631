import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Plus from 'img/add.svg';
import ApplicationLinkDialog from '../ApplicationLinkDialog';
import { appCtx } from '../../appStore';

const styles = {
    addButton: {
        marginTop: 10,
        fontWeight: 700
    }
};

const AddApplicationLinkButton = withStyles(styles)(({ classes, audition, slug, addLink }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { flashMessage } = useContext(appCtx);

    const handleDialogOpen = () => {
        setDialogOpen(!dialogOpen);
    };

    return (
        <>
            <Button
                color="primary"
                className={classes.addButton}
                onClick={handleDialogOpen}
            >
                <img src={Plus} className="u-mrg--rx2" alt="+" /> Add trackable link
            </Button>
            <ApplicationLinkDialog
                listingLinksDialogOpen={dialogOpen}
                handleClose={handleDialogOpen}
                updateLink={addLink}
                slug={slug}
                audition={audition}
                flashMessage={flashMessage}
            />
        </>
    );
});

export default AddApplicationLinkButton;

export default theme => ({
    text: {
        fontSize: 14,
        lineHeight: '24px',
        color: '#000',

        '& a': {
            fontWeight: 700,
            color: '#000'
        },

        '& mark': {
            backgroundColor: '#fff1be'
        }
    },
    divider: {
        height: 1,
        backgroundColor: theme.palette.grey[300],
        width: '100%',
        marginBottom: 35
    },
    questionTitle: {
        fontSize: 20,
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& > img': {
            width: 30,
            marginRight: 7
        },
        '&:not(:first-child)': {
            paddingTop: '16px',
            marginTop: '15px',
            borderTop: `1px solid ${theme.palette.grey[300]}`
        }
    },
    plagiarismBanner: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[200],
        fontSize: 10,
        padding: '6px 10px',
        fontWeight: 400,
        marginLeft: 7,
        borderRadius: 4
    },
    iconButton: {
        padding: 0,
        '& > img': {
            width: 25
        },
        marginRight: 2,
        '&:last-child': {
            marginRight: 8
        },
        '&:disabled': {
            opacity: 0.7
        }
    },
    removeButton: {
        fontSize: 12,
        marginLeft: 7
    }
});

import React from 'react';
import clsx from 'clsx';

import { withStyles, withTheme } from '@mui/styles';
import Chip from '@mui/material/Chip';

import CircularProgress from '@mui/material/CircularProgress';
import { CloseIcon, PlusIcon } from '../Icons';

import {
    serverChipStyle,
    skillChipStyles,
    addChipStyle
} from './styles';

export const SkillChip = withStyles(skillChipStyles)(
    ({ classes, title, onClick, className, disabled }) => (
        <Chip
            onClick={!disabled ? onClick : () => {}}
            disabled={disabled}
            classes={{
                root: clsx(classes.chip, className),
                disabled: classes.chipDisabled
            }}
            label={(
                    <>
                        <div className={classes.title}>{title}</div>
                        { !disabled && <CloseIcon color="#fff" /> }
                    </>
            )}
        />
    )
);

export const ServerChip = withTheme(withStyles(serverChipStyle)(
    ({ classes, theme, title, onClick, light }) => (
        <Chip
            onClick={onClick}
            className={`${classes.serverChip} ${light ? classes.serverChipLight : ''}`}
            label={(
                <>
                    {title}
                    <PlusIcon
                        color={light ? theme.palette.primary.main : '#fff'}
                        marginLeft={10}
                        width={18}
                        height={18}
                    />
                </>
            )}
        />
    )
));

export const AddChip = withStyles(addChipStyle)(
    ({ classes, onClick, className, loading, disabled }) => (
        <Chip
            onClick={!disabled ? onClick : () => {}}
            disabled={disabled}
            className={`${classes.addChip} ${className}`}
            label={(
                !loading ? (
                    <PlusIcon
                        color="#000"
                        width={20}
                        height={20}
                    />
                ) : (
                    <CircularProgress size={18} />
                )
            )}
        />
    )
);

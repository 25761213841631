import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';

import { observer } from 'mobx-react-lite';
import SearchField from 'components/inputs/SearchField';
import { getCompanyQuestionsFilter } from 'requests/CMSRequests';
import TableFilter from './TableFilter';

const styles = {
    searchField: {
        maxWidth: '100%',
        width: 'calc(100% - 105px)',
        borderRadius: 4,
        height: 40,
        margin: 0
    }
};

const SearchFilterWrapper = observer(({
    classes, filters, handleChangeFilters, totalCount,
    handleChangeSearch, questionsSearchValue, openDrawer
}) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getFilters();
    }, [totalCount]);

    useEffect(() => {
        if (!openDrawer) getFilters();
    }, [openDrawer]);

    const getFilters = () => {
        getCompanyQuestionsFilter()
            .then(({ success, data }) => {
                if (data && success) {
                    setOptions(data);
                }
            });
    };

    return (
        <div className="u-dsp--distribute">
            <SearchField
                placeholder="Search Questions"
                className={classes.searchField}
                value={questionsSearchValue}
                onChange={handleChangeSearch}
            />
            <TableFilter
                filters={filters}
                options={options}
                handleChangeFilters={handleChangeFilters}
            />
        </div>
    );
});

export default withStyles(styles)(SearchFilterWrapper);

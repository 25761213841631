import React, { useEffect } from 'react';

const AppcuesLaunchpadIcon = ({ ...other }) => {
    useEffect(() => {
        if (window.Appcues) {
            window.Appcues.loadLaunchpad('#AppcuesIconSelector', {
                icon: "data:image/svg+xml,%3Csvg className='u-mrg--rx1' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3431 2.99824C7.91289 3.32981 5.2248 6.42209 5.2248 10.1878C5.2248 12.5523 5.00298 14.3633 4.63799 15.69C4.42994 16.4462 4.23552 16.8594 4.13121 17.0023C3.81681 17.433 4.10293 18.0631 4.61297 18.0631H19.297C19.8748 18.0631 20.1306 17.283 19.6799 16.895C19.6893 16.9031 19.6856 16.8971 19.6705 16.8732L19.6705 16.8732C19.66 16.8565 19.6441 16.8312 19.6233 16.7958C19.5089 16.6013 19.387 16.3119 19.2683 15.9109C18.9068 14.689 18.6851 12.8226 18.6851 10.1878C18.6851 6.4221 15.997 3.32983 12.5668 2.99825V1.65627C12.5668 1.29382 12.2929 1 11.955 1C11.6171 1 11.3431 1.29382 11.3431 1.65627V2.99824ZM9.73636 20.2168C9.64118 19.869 9.82686 19.5043 10.1511 19.4022C10.4753 19.3001 10.8153 19.4993 10.9105 19.8471C11.0468 20.3453 11.473 20.6875 11.957 20.6875C12.4411 20.6875 12.8672 20.3453 13.0035 19.8471C13.0987 19.4993 13.4387 19.3001 13.7629 19.4022C14.0872 19.5043 14.2728 19.869 14.1777 20.2168C13.8884 21.2739 12.9841 22 11.957 22C10.9299 22 10.0257 21.2739 9.73636 20.2168ZM18.1019 16.3079C17.6986 14.9446 17.4615 12.9479 17.4615 10.1878C17.4615 6.92579 14.9961 4.28138 11.955 4.28138C8.91381 4.28138 6.44847 6.92579 6.44847 10.1878C6.44847 12.673 6.21141 14.6083 5.81162 16.0615C5.74091 16.3185 5.66912 16.5477 5.59796 16.7506H18.2464C18.1976 16.6152 18.1493 16.4678 18.1019 16.3079Z' fill='%237F8084'/%3E%3C/svg%3E"
            }, []);
        }
    });

    return (<div id="AppcuesIconSelector" {...other} />);
};

export default AppcuesLaunchpadIcon;

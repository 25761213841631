import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import BoardItemWrapper from 'components/board/board_item';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';

import SkillsGroups from 'components/assessments_pages/SkillsGroups';
import CompanyLogo from 'components/assessments_pages/AssessmentList/CompanyLogo';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import DeleteContentLibraryAssessmentDialog from 'components/dialogs/DeleteContentLibraryAssessmentDialog';
import { appCtx } from 'components/appStore';

import { duplicateContentLibraryAssessment } from 'requests/CMSRequests';


import EditIcon from 'img/edit.svg';
import DuplicateIcon from 'img/duplicate.svg';
import PreviewIcon from 'img/preview.svg';
import RemoveGreyIcon from 'img/remove_grey.svg';

import AssessmentStatus from '../../../../../common/AssessmentStatus';
import stylesJS from './styles';

const ContentBoardItem = observer(({
    assessment, handleDuplicate,
    history, classes, getCategories,
    removeAssessment
}) => {
    const [openPreviewScript, setOpenPreviewScript] = useState(false);
    const [openDeleteScript, setOpenDeleteScript] = useState(false);
    const { company: { name: companyName, companyImageUrl }, flashMessage } = useContext(appCtx);

    const {
        settings, uuid,
        skills, category, level,
        summary, popular, status
    } = assessment;

    const { slug: interviewSlug, name } = settings;

    const handleOpenPreview = (e) => {
        e.stopPropagation();
        setOpenPreviewScript(true);
    };

    const handleOpenAssessment = (e) => {
        e.stopPropagation();
        history.push(`/content-library/${uuid}/questions`);
    };

    const handleDeleteAssessment = (e) => {
        e.stopPropagation();
        setOpenDeleteScript(!openDeleteScript);
    };

    const onDuplicateClick = () => {
        flashMessage('Duplicating assessment', 'pending', 100000);
        duplicateContentLibraryAssessment(uuid).then(() => {
            getCategories();
            flashMessage('Assessment duplicated', 'done');
            handleDuplicate();
        }).catch(() => { flashMessage('Something went wrong', 'error'); });
    };

    const getBoardMenuItems = () => ([
        {
            label: <><img src={PreviewIcon} alt="" /> Preview</>,
            onClick: handleOpenPreview
        },
        {
            label: <><img src={EditIcon} alt="" /> Edit</>,
            onClick: handleOpenAssessment
        },
        {
            label: <><img src={DuplicateIcon} alt="" /> Duplicate</>,
            onClick: onDuplicateClick
        },
        {
            label: <><img src={RemoveGreyIcon} alt="" /> Delete assessment</>,
            onClick: handleDeleteAssessment
        }
    ]);

    return (
        <>
            <BoardItemWrapper
                className={classes.wrapper}
                onClick={handleOpenAssessment}
            >
                <div className={classes.headerWrapper}>
                    <div className={classes.header}>
                        <div className={classes.labelsWrapper}>
                            <AssessmentStatus status={status} />
                            {
                                popular && (<div className={classes.popularLabel}>Popular</div>)
                            }
                            { level !== 'no_level' && (<div className={classes.levelLabel}>{level}</div>) }
                        </div>
                        <div className={classes.title}>
                            {name}
                        </div>
                        <div className={classes.authorWrapper}>
                            <CompanyLogo
                                companyName={companyName}
                                companyImage={companyImageUrl}
                            />
                            <div className={clsx(classes.companyName, !category && classes.companyNameExpanded)}>
                                {companyName}
                            </div>
                            {
                                category && (
                                    <>
                                        &nbsp;in&nbsp;
                                        <span
                                            role="presentation"
                                            className={classes.categoryType}
                                        >
                                            {category.title}
                                        </span>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <BoardItemMenu
                        iconMenuClassName={classes.boardMenu}
                        options={getBoardMenuItems()}
                    />
                </div>
                <div className={classes.contentWrapper}>
                    <div className={classes.descriptionWrapper}>{summary}</div>
                    <SkillsGroups
                        className={classes.skillsWrapper}
                        items={skills.map(title => ({ title }))}
                        scoreType={3}
                    />
                </div>
            </BoardItemWrapper>
            <DeleteContentLibraryAssessmentDialog
                open={openDeleteScript}
                uuid={uuid}
                onDelete={removeAssessment}
                onClose={handleDeleteAssessment}
            />
            <PreviewScript
                {...{
                    onClose: setOpenPreviewScript,
                    interviewSlug,
                    isCMS: true,
                    open: openPreviewScript,
                    uuid
                }}
            />
        </>
    );
});

export default withStyles(stylesJS)(withRouter(ContentBoardItem));

import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { Button, IconButton, CircularProgress } from '@mui/material';

import AssessmentEvents from 'events/AssessmentEvents';
import CheatIcon from 'img/icons/cheatFlag.svg';
import { removePlagiarismDetectionFlag } from 'requests/AssessmentRequests';
import ApproveIcon from 'img/approveButtons/approve.svg';
import DeclineIcon from 'img/approveButtons/decline.svg';
import { appCtx } from 'components/appStore';
import { checkHasUserPermission } from 'helper/commonFunctions';

import styles from './styles';

const QuestionInfo = ({
    plagiarismQuestion, candidate, audition,
    classes, getUserInfo
}) => {
    const [shown, setShown] = useState(false);
    const [loading, setLoading] = useState(false);
    const { flashMessage, company } = useContext(appCtx);
    const { candidateUuid: candidateId } = candidate;
    const { permissions, name: ttName, uuid: ttId } = audition;
    const edit = checkHasUserPermission(company, permissions, 'edit');

    const handleShown = () => {
        setShown(!shown);
    };

    const removeFlag = async () => {
        try {
            setLoading(true);
            await removePlagiarismDetectionFlag(plagiarismQuestion.id);
            AssessmentEvents.PLAGIARISM_FLAG_REMOVED({
                candidateId,
                ttId,
                ttName,
                questionNumber: plagiarismQuestion.sort
            });
            flashMessage('Plagiarism flag removed', 'done');
            setShown(false);
            setLoading(false);
            getUserInfo();
        } catch (e) {
            setLoading(false);
            setShown(false);
        }
    };

    return (
    <>
        <div className={clsx(classes.questionTitle, 'u-mrg--bx3')}>
            <img src={CheatIcon} alt="" /> Question {plagiarismQuestion.sort}
            {
                edit && (
                    <>
                        {
                            shown ? (
                                <div className={classes.plagiarismBanner}>
                                    {
                                        loading ? (
                                            <IconButton
                                                className={classes.iconButton}
                                                disabled={loading}
                                            >
                                                <CircularProgress size={22} />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={removeFlag}
                                                className={classes.iconButton}
                                                size="large"
                                            >
                                                <img src={ApproveIcon} style={{ width: 28 }} alt="approve" />
                                            </IconButton>
                                        )
                                    }
                                    <IconButton
                                        onClick={handleShown}
                                        className={classes.iconButton}
                                        size="large"
                                        disabled={loading}
                                    >
                                        <img src={DeclineIcon} alt="decline" />
                                    </IconButton>
                                    Click on check to remove flag or close to cancel.
                                </div>
                            ) : (
                                <Button
                                    className={classes.removeButton}
                                    color="primary"
                                    onClick={handleShown}
                                >
                                    Remove Flag
                                </Button>
                            )
                        }
                    </>
                )
            }
        </div>
        <div className={classes.text}>
            <p>{plagiarismQuestion.question.description}</p>
        </div>

        <div className={clsx(classes.divider, 'u-mrg--tx2')} />
    </>
    );
};

export default withStyles(styles)(QuestionInfo);

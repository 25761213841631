import React from 'react';
import Dialog from 'libraries/Dialog';
import successIcon from 'img/icons/success.svg';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

const styles = theme => ({
    dialog: {
        maxWidth: 898,
        margin: '0 auto'
    },
    wrapper: {
        padding: '96px 0'
    },
    title: {
        fontWeight: 700,
        fontSize: 32,
        lineHeight: '39px',
        margin: '30px 0',
        color: theme.palette.grey[900]
    },
    text: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '28px',
        color: theme.palette.grey[600]
    }
});

const ThankModal = ({ open, handleThankModal, classes }) => (
    <Dialog
        open={open}
        maxWidth="md"
        className={clsx(classes.dialog, 'u-txt--center')}
        onClose={handleThankModal}
        handleClose={handleThankModal}
    >
        <div className={classes.wrapper}>
            <img
                width="85"
                height="85"
                src={successIcon}
                alt="Success"
            />
            <h1 className={classes.title}>Payment Successful</h1>
            <p className={classes.text}>Thank you. Your subscription to Vervoe was successful and is now active.<br />
                You can view your invoice and subscription details in your <Link to="/billing" className="c-link--brand">account</Link>
            </p>
        </div>
    </Dialog>
);

export default withStyles(styles)(ThankModal);

import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: ({ hasTruncate }) => (hasTruncate ? 'column' : 'row'),
        maxWidth: ({ hasTruncate }) => (hasTruncate ? 252 : 'initial'),
        paddingRight: 80,
        marginTop: 10,
        borderRadius: 8,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column!important',
            maxWidth: '252px!important'
        }
    },
    optionContainer: {
        margin: 24,
        display: 'flex',
        flexDirection: 'column',
        '&:first-child': {
            minWidth: 84
        }
    },
    secondaryText: {
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 12,
        color: theme.palette.grey[600]
    },
    primaryText: {
        fontWeight: 700,
        fontSize: 32,
        lineHeight: '28px',
        color: theme.palette.grey[900],
        margin: '10px 0 8px'
    },
    lowercase: {
        textTransform: 'capitalize'
    },
    numberOfAttempts: {
        fontWeight: 600,
        fontSize: 12,
        color: theme.palette.grey[600],
        textAlign: 'right',
        '& span': {
            fontWeight: 700,
            color: theme.palette.grey[900]
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'left!important'
        }
    }
}));

export default ({ question: { typingTestResult: res, typingTestResultAttemptsCount }, NoAnswerComponent, hasTruncate }) => {
    const classes = useStyles({ hasTruncate });
    if (!res) return <NoAnswerComponent />;

    const getAWPMColor = () => {
        if (!res?.awpm) return '#7F8084';
        if (res.awpm <= 20) return '#7F8084';
        if (res.awpm <= 35) return '#FF113D';
        if (res.awpm <= 50) return '#FC9935';
        if (res.awpm <= 70) return '#00B898';
        return '#4438CB';
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.optionContainer}>
                    <span className={classes.secondaryText}>Typing Speed</span>
                    <span className={classes.primaryText}>{res?.wpm ?? '-'}</span>
                    <span className={classes.secondaryText}>Words per minute</span>
                </div>
                <div className={classes.optionContainer}>
                    <span className={classes.secondaryText}>Accuracy</span>
                    <span className={classes.primaryText}>{res?.accuracy ? `${res.accuracy}%` : '-'}</span>
                    <span className={classes.secondaryText}>{res?.countIncorrectWords !== null ? `${res.countIncorrectWords} typos` : 'No result'}</span>
                </div>
                <div className={classes.optionContainer}>
                    <span className={classes.secondaryText}>FINAL RESULT</span>
                    <span className={classes.primaryText} style={{ color: getAWPMColor() }}>{res?.awpm ?? '-'}</span>
                    <span className={classes.secondaryText}>WPM <span className={classes.lowercase}>(Adjusted)</span></span>
                </div>
            </div>
            <div className={classes.numberOfAttempts}>
                Number of Attempts: <span>{typingTestResultAttemptsCount}</span>
            </div>
        </>
    );
};

export default {
    wrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 0
    },
    wrapperBanner: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 1000,
        background: 'rgba(255, 255, 255, 0.6)'
    },
    tooltip: {
        textAlign: 'center',
        width: 210
    }
};

import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import WarningBanner from 'components/assessments_pages/WarningBanner';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import { checkCompanyCanBeUpgraded } from 'helper/commonFunctions';
import Zendesk from 'helper/zendeskFunctions';
import { getAvailablePlans } from 'requests/SubscriptionRequests';

const CandidatesLimitBlock = observer(({ completionsLeft, limitOfCompletions, interviewSlug }) => {
    const case1Slug = `${interviewSlug}-case1`;
    const case2Slug = `${interviewSlug}-case2`;
    const case3Slug = `${interviewSlug}-case3`;
    const case4Slug = `${interviewSlug}-case4`;

    const [case1Opened, setCase1Opened] = useState(false);
    const [case2Opened, setCase2Opened] = useState(false);
    const [case3Opened, setCase3Opened] = useState(false);
    const [case4Opened, setCase4Opened] = useState(false);

    const [availablePlans, setAvailablePlans] = useState([]);

    const { company } = useContext(appCtx);
    const { plan, companySettings } = company;

    useEffect(() => {
        if (!availablePlans.length) {
            getAvailablePlans().then(({ data, success }) => {
                if (success && data && data.length) {
                    setAvailablePlans(data);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (completionsLeft === undefined) return;
        const case1Item = localStorage.getItem(case1Slug);
        const case2Item = localStorage.getItem(case2Slug);
        const case4Item = localStorage.getItem(case4Slug);
        if (!case1Item || parseInt(case1Item, 10) !== completionsLeft) {
            setCase1Opened(true);
            localStorage.removeItem(case1Slug);
        }
        if (!case2Item || parseInt(case2Item, 10) !== completionsLeft) {
            setCase2Opened(true);
            localStorage.removeItem(case2Slug);
        }
        if (!case4Item || parseInt(case4Item, 10) !== completionsLeft) {
            setCase4Opened(true);
            localStorage.removeItem(case4Slug);
        }
    }, [completionsLeft]);

    useEffect(() => {
        if (!companySettings || completionsLeft === undefined) return;
        const { candidatesCompletionsPerOpportunity } = companySettings;
        if (candidatesCompletionsPerOpportunity === -1) return;
        const shownLimitOfCompletions = isFreeTrial ? limitOfCompletions : candidatesCompletionsPerOpportunity;

        const case3Item = localStorage.getItem(case3Slug);
        if (!case3Item || parseInt(case3Item, 10) !== shownLimitOfCompletions) {
            setCase3Opened(true);
            localStorage.removeItem(case3Slug);
        }
    }, [companySettings, completionsLeft]);


    if (!plan || !companySettings) return null;

    const isPilot = plan.level === 1;
    const isFreeTrial = plan.name === 'Free Trial';
    const isOutdatedPlan = availablePlans.every(el => el.name !== plan.name);

    const {
        candidatesCompletionsPerOpportunity,
        candidatesCompletionsPerOpportunityIncrementedYearly,
        candidatesCompletionsPerOpportunityIncrementedMonthly,
        companyCompletePackages
    } = companySettings;

    const returnPeriod = () => {
        if (!isPilot && candidatesCompletionsPerOpportunityIncrementedMonthly) return 'month';
        if (candidatesCompletionsPerOpportunityIncrementedYearly) return 'year';
        return '';
    };

    const openZendesk = () => {
        Zendesk.open(true);
    };

    const returnLabel = (label, segmentLocation) => {
        if (!canCompanyUpgrade && !isOutdatedPlan && !companyCompletePackages) {
            return (
                <a href="javascript:void(0)" onClick={openZendesk}>Talk to us {label}</a>
            );
        }
        return <SubscriptionsLink label={`Upgrade ${label}`} segmentLocation={segmentLocation} />;
    };

    if (companyCompletePackages && case4Opened) {
        return (completionsLeft === 0) ? (
            <WarningBanner
                label={<>
                    <b>Oh no! You’ve reached the end of the candidate limit. </b>&nbsp;
                    {returnLabel('now to unlock more', 'reached out candidate limit warning for packs')}
                    </>}
                onClose={() => {
                    setCase4Opened(false);
                    localStorage.setItem(case4Slug, completionsLeft);
                }}
            />
        ) : null;
    }


    if (completionsLeft === -1 || candidatesCompletionsPerOpportunity === -1) return null;
    const canCompanyUpgrade = checkCompanyCanBeUpgraded(company);

    let returnedLabel = null;
    let onCloseFunc;

    const shownLimitOfCompletions = isFreeTrial ? limitOfCompletions : candidatesCompletionsPerOpportunity;


    if (completionsLeft && shownLimitOfCompletions && case3Opened) {
        returnedLabel = (
            <>
                <b>You have {completionsLeft} candidate completions remaining.</b>
                {returnLabel('to unlock more', 'candidates limit block warning')}
            </>
        );
        onCloseFunc = () => {
            localStorage.setItem(case3Slug, shownLimitOfCompletions);
            setCase3Opened(false);
        };
    }

    if (completionsLeft && shownLimitOfCompletions && completionsLeft <= (shownLimitOfCompletions / 5) && case2Opened) {
        returnedLabel = (
            <>
                <b>You’ve almost reached your limit. </b>
                {returnLabel('now to unlock more candidates and great features', 'candidates limit block warning')}
            </>
        );
        onCloseFunc = () => {
            localStorage.setItem(case2Slug, completionsLeft);
            setCase2Opened(false);
        };
    }

    if (completionsLeft === 0 && case1Opened) {
        returnedLabel = isPilot ? (
            <>
                <b>Oh no! You’ve reached the end of candidate limit on the Trial.</b>
                {returnLabel('now to unlock more candidates and great features', 'reached out candidate limit warning')}
            </>
        ) : (
            <>
                <b>Oh no! You’ve reached the end of candidate limit for this {returnPeriod()}.</b>&nbsp;
                Candidate limits will reset next {returnPeriod()}.
                {returnLabel('now to unlock more', 'reached out candidate limit warning')}
            </>
        );
        onCloseFunc = () => {
            localStorage.setItem(case1Slug, completionsLeft);
            setCase1Opened(false);
        };
    }

    return (
        <WarningBanner
            label={returnedLabel}
            onClose={onCloseFunc}
        />
    );
});

export default CandidatesLimitBlock;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';

const styles = {
    submitButton: {
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 180
    },
    keepButton: {
        fontWeight: 700
    },
    loader: {
        color: '#fff'
    }
};

const ActivateAssessmentDialog = ({
    open, classes, onClose,
    onClickActivate, onClickDuplicate,
    candidatesCount
}) => (
    <Dialog
        maxWidth="sm"
        titleComponent="Re-open assessment"
        actionComponent={(
                <>
                    <Button
                        color="primary"
                        className={classes.keepButton}
                        onClick={(e) => { onClickActivate(e); onClose(e); }}
                    >
                        Keep candidates
                    </Button>
                    <Button
                        className={classes.submitButton}
                        variant="contained"
                        color="primary"
                        onClick={(e) => { onClickDuplicate(e); onClose(e); }}
                    >
                        Start Fresh
                    </Button>
                </>
        )}
        open={open}
        handleClose={onClose}
        onClose={onClose}
    >
        <p>
            Re-open assessment and keep existing  <b>{candidatesCount}</b> candidates or start with new assessment.
            Candidates are always available to view in <b>all candidates</b>.
        </p>
    </Dialog>
);

export default withStyles(styles)(withRouter(ActivateAssessmentDialog));

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import styles from './styles';

const BoardNoContent = ({
    classes, onClick, buttonLabel,
    header, text, icon, disabled,
    hideIconWrapper, className = '',
    classNameText = '', classNameButton = '',
    ButtonComp = null, ...other
}) => (
    <div className={clsx(classes.wrapper, className)}>
        <div className={clsx(classes.iconWrapper, !hideIconWrapper && classes.iconWrapperBackground)}>
            {icon}
        </div>
        <Typography variant="h4">
            {header}
        </Typography>
        {
            text && (
                <div className={clsx(classes.text, classNameText)}>
                    {text}
                </div>
            )
        }
        {
            ButtonComp ? (
                <ButtonComp
                    disabled={disabled}
                    buttonLabel={buttonLabel}
                    className={clsx(classes.button, classNameButton)}
                    {...other}
                />
            ) : (
                <>
                    {
                        buttonLabel && (
                            <Button
                                color="primary"
                                disabled={disabled}
                                className={clsx(classes.button, classNameButton)}
                                onClick={onClick}
                            >
                                {buttonLabel}
                            </Button>
                        )
                    }
                </>
            )
        }
    </div>
);

export default withStyles(styles)(BoardNoContent);

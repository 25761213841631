import React from 'react';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';

import Icon from './icon.svg';

const useStyles = makeStyles(theme => ({
    icon: {
        marginBottom: 15
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 10,
        color: theme.palette.grey[900]
    },
    wrapper: {
        color: theme.palette.grey[600],
        fontSize: 14,
        marginTop: 70,
        textAlign: 'center'
    }
}));

const NoGradingBlock = observer(({ header, subHeader }) => {
    const classes = useStyles();

    if (!header) return null;

    return (
        <div className={classes.wrapper}>
            <img src={Icon} className={classes.icon} alt="empty" />
            <div className={classes.header}>{header}</div>
            <div>{subHeader}</div>
        </div>
    );
});

export default NoGradingBlock;

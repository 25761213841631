import React from 'react';
import { withStyles } from '@mui/styles';

import styles from './styles';

const AssessmentStatsItem = ({ label, value, classes }) => (
    <div className={classes.wrapper}>
        <div className={classes.value}>{ value }</div>
        <div className={classes.label}>{label}</div>
    </div>
);

export default withStyles(styles)(AssessmentStatsItem);

export default {
    actionButton: {
        fontWeight: 600,
        minWidth: 240,
        whiteSpace: 'nowrap',
        fontSize: 14,
        letterSpacing: '0.2px',
        marginLeft: -8,
        display: 'flex',
        justifyContent: 'flex-start'
    }
};

import React from 'react';


const SpreadsheetIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M4 12H28" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 20H28" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 12L12 28" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="3.26602" y="3.2665" width="25.4667" height="25.4667" rx="1.4" stroke={color} strokeWidth="1.2" />
    </svg>
);

export default SpreadsheetIcon;

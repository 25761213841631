export default theme => ({
    wrapper: {
        width: '235px',
        [theme.breakpoints.down('md')]: {
            width: 'auto',
            maxWidth: '100%',
            display: 'flex',
            overflowX: 'auto'
        }
    },
    listItemStyle: {
        width: 200,
        cursor: 'pointer',
        height: 83,
        borderRadius: 4,
        marginBottom: 24,
        display: 'flex',
        fontSize: 20,
        color: theme.palette.grey[500],
        fontWeight: 700,
        justifyContent: 'center',
        alignItems: 'center',
        border: `3px solid ${theme.palette.grey[300]}`,
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 15,
            minWidth: 170
        }
    },
    listItemActiveStyle: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.grey[100],
        border: `3px solid ${theme.palette.primary.main}`
    }
});

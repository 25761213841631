export default (theme => ({
    header: {
        fontWeight: 700,
        color: theme.palette.grey[900],
        marginTop: 32
    },
    warning: {
        marginTop: 5,
        marginBottom: 25
    },
    noTicketsLabel: {
        marginTop: 30,
        fontWeight: 700,
        color: theme.palette.grey[500]
    },
    emptyWrapper: {
        padding: '10px 50px'
    }
}));

import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { getCompanySet, getListOfCompanySets, reorderCompanyQuestion } from 'requests/CMSRequests';
import TooltipBlack from 'libraries/Tooltip';
import { IconButton } from '@mui/material';
import EditIcon from 'img/edit.svg';
import { appCtx } from 'components/appStore';
import SetsSelection from './SetsSelection';
import QuestionsList from '../../../common/QuestionsList';
import EditSetDialog from './EditSetDialog';
import EmptyQuestionsList from '../../../common/EmptyQuestionsList';
import EmptySetsList from './EmptySetsList';

const styles = () => ({
    questionsWrapper: {
        padding: '0 30px'
    },
    selectedSkillWrapper: {
        padding: '0 30px'
    }
});

const SetsContent = observer(({
    classes, openDrawer, setSelectedQuestionId, setSaving,
    selectedSet, setSelectedSet, setRemoveFromSetQuestionUuid,
    addToSetQuestionUuid, removeFromQuestionUuid, handleOpenDrawer,
    setAddToSetQuestionUuid, setAddToSelectedSkill, selectedSkill, ...other
}) => {
    const [selectedSetData, setSelectedSetData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [sets, setSets] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        if (selectedSkill === -1) {
            setSets([]);
            setSelectedIndex(0);
            setSelectedSet(null);
        } else {
            loadSets();
        }
    }, [selectedSkill]);

    useEffect(() => {
        if (!openDrawer) {
            loadCurrentSetData();
        }
    }, [openDrawer]);

    useEffect(() => {
        setSelectedSetData(null);
        if (!selectedSet) return;
        setLoading(true);
        loadCurrentSetData();
    }, [selectedSet]);

    useEffect(() => {
        if (!removeFromQuestionUuid || !selectedSetData) return;
        let newCompanyQuestions = [...selectedSetData.companyQuestions];
        newCompanyQuestions = newCompanyQuestions.filter(({ uuid }) => uuid !== removeFromQuestionUuid);
        setSelectedSetData({ ...selectedSetData, companyQuestions: newCompanyQuestions });
        loadCurrentSetData();
        if (setRemoveFromSetQuestionUuid) setRemoveFromSetQuestionUuid(null);
    }, [removeFromQuestionUuid]);


    useEffect(() => {
        if (!addToSetQuestionUuid || !selectedSet) return;
        loadCurrentSetData();
        if (setAddToSetQuestionUuid) setAddToSetQuestionUuid(null);
    }, [addToSetQuestionUuid]);

    const loadSets = () => {
        if (selectedSkill === -1) return;
        setLoading(true);
        getListOfCompanySets(selectedSkill ? selectedSkill.id : null)
            .then(({ success, data }) => {
                if (success && data) {
                    setSets(data);
                    setSelectedIndex(0);
                    setSelectedSet(data.length ? data[0] : null);
                    if (!data.length) setLoading(false);
                }
            });
    };


    const loadCurrentSetData = async () => {
        if (!selectedSet) return;
        return getCompanySet(selectedSet.uuid)
            .then(({ success, data }) => {
                if (success && data) {
                    setSelectedSetData(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onEdit = (set) => {
        setSelectedSetData(set);
    };

    const handleDialog = () => {
        setOpenEditDialog(!openEditDialog);
    };


    const updateQuestions = (questions) => {
        setSelectedSetData({ ...selectedSetData, companyQuestions: questions });
    };

    const saveQuestionsSort = (question, order) => {
        setSaving(true);
        reorderCompanyQuestion(selectedSet.uuid, question.uuid, { order })
            .then(({ data, success }) => {
                if (success && data) {
                    setSelectedSetData(data);
                    flashMessage('Question reordered', 'done');
                }
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const onEmptyClick = () => {
        setAddToSelectedSkill(true);
        handleOpenDrawer();
    };

    const questions = selectedSetData ? selectedSetData.companyQuestions : null;

    return <>
        <SetsSelection
            selectedSkill={selectedSkill}
            selectedSet={selectedSet}
            setSelectedSet={setSelectedSet}
            selectedSetData={selectedSetData}
            setSelectedSetData={setSelectedSetData}
            setSaving={setSaving}
            sets={sets}
            setSets={setSets}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            {...other}
        />
        {
            selectedSetData && (
                <div className={classes.selectedSkillWrapper}>
                    <Typography className="u-mrg--bx2 u-txt--prewrap" variant="h6">
                        {selectedSetData.title}
                        <TooltipBlack label="Edit Skill">
                            <IconButton className={classes.icon} onClick={handleDialog} size="large">
                                <img src={EditIcon} alt="edit" />
                            </IconButton>
                        </TooltipBlack>
                    </Typography>
                    <EditSetDialog
                        set={selectedSetData}
                        handleDialog={handleDialog}
                        openedDialog={openEditDialog}
                        onSuccess={onEdit}
                    />
                </div>
            )
        }
        <div className={classes.questionsWrapper}>
            <QuestionsList
                hideDuplicate
                showOnFocus
                loading={loading}
                selectedSkill={selectedSkill}
                selectedSet={selectedSet}
                loadQuestions={loadCurrentSetData}
                setSelectedQuestionId={setSelectedQuestionId}
                setRemoveFromSetQuestionUuid={setRemoveFromSetQuestionUuid}
                setAddToSetQuestionUuid={setAddToSetQuestionUuid}
                questions={questions}
                saveQuestionsSort={saveQuestionsSort}
                updateQuestions={updateQuestions}
                {...other}
            />
            {
                !loading && (!questions || !questions.length) && (
                    <>
                        {
                            selectedSet ? (
                                <EmptyQuestionsList
                                    hasSet
                                    onClick={onEmptyClick}
                                    handleOpenDrawer={handleOpenDrawer}
                                    setAddToSelectedSkill={setAddToSelectedSkill}
                                />
                            ) : (
                                <EmptySetsList
                                    sets={sets}
                                    setSets={setSets}
                                    setSelectedSet={setSelectedSet}
                                    selectedSkill={selectedSkill}
                                    setSelectedIndex={setSelectedIndex}
                                />
                            )
                        }
                    </>
                )
            }

        </div>
    </>;
});

export default withStyles(styles)(SetsContent);

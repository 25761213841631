import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import styles from './styles';

const LeverIcon = withStyles(styles)(withTheme(({ theme, isActive }) => {
    const fillColor = isActive ? theme.palette.primary.main : '#A9AAAE';
    return (
        <svg width="147" height="42" viewBox="0 0 147 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.3573 39.7898L33.4976 31.8819C33.2494 31.6371 32.9156 31.5 32.568 31.5C32.2204 31.5 31.8866 31.6371 31.6384 31.8819L23.7787 39.7898C23.5913 39.9695 23.4627 40.2024 23.41 40.4575C23.3574 40.7126 23.3832 40.9778 23.4841 41.2177C23.5849 41.4577 23.756 41.6611 23.9746 41.8008C24.1932 41.9406 24.4489 42.01 24.7077 42H40.4283C40.6856 42.0065 40.9389 41.9349 41.1552 41.7945C41.3715 41.6542 41.5407 41.4516 41.641 41.2131C41.7412 40.9746 41.7677 40.7113 41.7171 40.4574C41.6665 40.2036 41.5411 39.9709 41.3573 39.7898Z" fill={isActive ? theme.palette.primary.main : '#C3C6CC'} />
            <path d="M41.6188 11.3873L39.1568 4.03465C39.0457 3.70214 38.8686 3.39581 38.6362 3.13428L0.298157 41.6995C0.523146 41.902 0.817039 42.0097 1.11883 42H12.0081C12.3124 42.0015 12.6141 41.9426 12.8957 41.8266C13.1774 41.7106 13.4335 41.5398 13.6494 41.3241L41.0969 13.713C41.3953 13.4157 41.6029 13.0388 41.6953 12.6268C41.7878 12.2147 41.7613 11.7847 41.6188 11.3873Z" fill="#E1E3E6" />
            <path d="M37.7405 2.60771L30.4315 0.132333C30.0364 -0.0109459 29.6089 -0.0376534 29.1993 0.0553621C28.7897 0.148378 28.415 0.357239 28.1195 0.657332L0.670672 28.2684C0.238884 28.7079 -0.0022489 29.3015 4.32606e-05 29.9195V40.8736C-0.0095416 41.1772 0.0974542 41.4728 0.298824 41.6992L38.6369 3.13406C38.3765 2.89949 38.0716 2.72043 37.7405 2.60771Z" fill={isActive ? theme.palette.primary.main : '#C3C6CC'} />
            <path d="M142.575 20.4078C142.6 20.8348 142.499 21.2597 142.286 21.6297C142.098 21.9176 141.832 22.1453 141.52 22.286C141.177 22.4375 140.809 22.5259 140.435 22.5471C140.03 22.5773 139.625 22.5922 139.221 22.5918H137.804V18.254H139.366C139.742 18.2541 140.118 18.2733 140.493 18.3117C140.853 18.3436 141.205 18.4372 141.534 18.5886C141.84 18.7317 142.101 18.9581 142.286 19.2423C142.494 19.5935 142.595 19.999 142.575 20.4078ZM143.096 31.4997H147L144.86 28.3261C144.493 27.7827 144.122 27.259 143.747 26.755C143.37 26.2497 143.056 25.8323 142.806 25.5029C142.626 25.2074 142.39 24.9502 142.112 24.7456C143.169 24.6588 144.155 24.1759 144.874 23.3924C145.615 22.5584 146.004 21.4673 145.958 20.35C145.973 19.6818 145.834 19.0192 145.553 18.4134C145.273 17.8076 144.857 17.2748 144.339 16.8561C143.801 16.431 143.172 16.1368 142.502 15.9965C141.632 15.8178 140.745 15.735 139.857 15.7497H134.594V31.4997H137.804V24.949C138.119 24.9349 138.431 25.0163 138.7 25.1826C138.934 25.3398 139.148 25.5254 139.337 25.7352C139.62 26.0852 139.885 26.4497 140.131 26.8272C140.45 27.3023 140.917 28.0155 141.534 28.9666L143.096 31.4997ZM115.903 31.4997H125.966V28.7631H119.171V24.571H124.259V21.9801H119.141V18.3406H125.272L125.734 15.7497H115.903V31.4997ZM103.278 31.4984L109.379 15.7497H105.996L102.902 23.9305C102.753 24.3019 102.623 24.6808 102.512 25.0658C102.387 25.4924 102.276 25.8949 102.18 26.2733C102.083 26.6526 102.006 26.9681 101.947 27.2196L101.861 27.599L101.745 27.2196C101.688 26.948 101.611 26.6233 101.514 26.2458C101.418 25.8665 101.307 25.4635 101.181 25.037C101.056 24.6091 100.936 24.2306 100.82 23.9016L97.9575 15.7497H94.3721L100.358 31.4984H103.278ZM77.8491 31.4997H87.9111V28.7631H81.1161V24.571H86.2059V21.9801H81.0874V18.3406H87.217L87.6802 15.7497H77.8491V31.4997ZM69.0383 31.4997L69.6463 28.8209H63.2844V15.7497H60.0174V31.4997H69.0383Z" fill={fillColor} />
        </svg>
    );
}));

export default LeverIcon;

import { createContext } from 'react';
import { action, computed, observable } from 'mobx';
import { getAvailablePlans, getCompanyCards, getCompanyPlan, subscriptionCancelImmediately } from 'requests/SubscriptionRequests';
import { appStore } from 'components/appStore';

class SubscriptionDialogStore {
    @observable availablePlans = [];

    @observable interval = 'yearly';

    @observable showSecret = false;

    @observable cards = null;

    @observable companyPlan = null;

    @observable period = null;

    @observable dialogPaymentDetailsOpen = false;

    @observable dialogConfirmCancelPlanOpen = false;

    @observable subscriptionPlan = false;

    @observable selectedPlan = null;

    @observable selectedPrice = null;

    @observable countSmsLeft = null;

    @observable countSmsPerCompanyQuota = null;

    @observable currentSubscriptionId = null;

    @observable isLoadingConfirmCancelPlan = false;

    @computed get isCurrentPlanOutdated() {
        return this.availablePlans.every(plan => plan.name !== this.companyPlan.name);
    }

    @computed get isSelectedCurrent() {
        return this.selectedPlan && this.companyPlan.name === this.selectedPlan.name;
    }

    @action setShowSecret = (showSecret) => {
        this.showSecret = showSecret;
    };

    @action getAvailablePlans = (planName) => {
        getAvailablePlans()
            .then(({ data, success }) => {
                if (success && data && data.length) {
                    this.availablePlans = data;
                    if (planName) {
                        const neededPlanIndex = data.findIndex(item => item.slug.toLowerCase() === planName);
                        if (neededPlanIndex !== -1) {
                            this.selectedPlan = data[neededPlanIndex];
                            return;
                        }
                    }
                    const currentPlanLevel = data.findIndex(item => item.level === appStore.company.plan.level && item.name === appStore.company.plan.name);
                    this.selectedPlan = data[currentPlanLevel !== -1 ? currentPlanLevel : 0];
                }
            });
    };

    @action findPlansBySlug = (planSlug) => {
        let foundPlan;
        this.selectedPlan = this.availablePlans.find(({ publicAuditionPlans }) => {
            foundPlan = publicAuditionPlans.find(({ plan_id }) => plan_id === planSlug);
            return Boolean(foundPlan);
        });
        if (!foundPlan) return null;
        this.selectedPlan = {
            ...this.selectedPlan,
            ...foundPlan
        };
        return this.selectedPlan;
    };

    @action getCompanyPlan = () => {
        getCompanyPlan()
            .then(({ success, data }) => {
                if (success) {
                    const { companyPlan, period, countSmsLeft, countSmsPerCompanyQuota, subscriptionId } = data;
                    this.companyPlan = companyPlan;
                    this.period = period;
                    this.countSmsLeft = countSmsLeft;
                    this.countSmsPerCompanyQuota = countSmsPerCompanyQuota;
                    this.currentSubscriptionId = subscriptionId;
                }
            });
    };

    @action getCards = () => {
        getCompanyCards()
            .then(({ success, data }) => {
                if (success) {
                    this.cards = data;
                }
            });
    };


    @action handleSelectInterval = (interval) => {
        this.interval = interval;
    };

    @action handleOpenPaymentDetails = (subscriptionPlan = false) => {
        if (subscriptionPlan) {
            if (this.selectedPrice && this.currentSubscriptionId && !this.isSelectedCurrent) {
                this.dialogConfirmCancelPlanOpen = true;
            } else {
                this.dialogPaymentDetailsOpen = true;
            }
            this.subscriptionPlan = subscriptionPlan;
        } else {
            this.dialogPaymentDetailsOpen = true;
        }
    };

    @action handleConfirmCancelPlan = () => {
        this.isLoadingConfirmCancelPlan = true;
        subscriptionCancelImmediately()
            .then(({ success }) => {
                if (success) {
                    this.dialogConfirmCancelPlanOpen = false;
                    this.dialogPaymentDetailsOpen = true;
                }
                this.isLoadingConfirmCancelPlan = false;
            })
            .catch(() => {
                this.isLoadingConfirmCancelPlan = false;
            });
    }

    @action handleCloseConfirmCancelPlan = () => {
        this.dialogConfirmCancelPlanOpen = false;
        this.subscriptionPlan = false;
    };

    @action handleClosePaymentDetails = () => {
        this.dialogPaymentDetailsOpen = false;
        this.subscriptionPlan = false;
    };

    @action selectPlan = (plan) => {
        this.selectedPlan = plan;
    };

    @action selectPrice = (price) => {
        this.selectedPrice = price;
    };

    @computed get isPlanHasPrices() {
        if (this.selectedPlan) {
            const { stripeProducts } = this.selectedPlan;
            const stripePricesCount = stripeProducts.reduce((total, stripeProduct) => total + stripeProduct.stripePrices.length, 0);
            return stripePricesCount > 0;
        }

        return false;
    }
}

export const subscriptionDialogStore = new SubscriptionDialogStore();
export const subscriptionDialogStoreCtx = createContext(subscriptionDialogStore);

export default theme => ({
    integrationWrapper: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    contentWrapper: {
        overflow: 'auto',
        width: '100%',
        color: theme.palette.grey[900],
        fontWeight: 600,
        padding: '0 59px',
        [theme.breakpoints.down('md')]: {
            padding: 0,
            marginTop: 20
        }
    },
    contentWrapperHeader: {
        fontWeight: 700,
        fontSize: 18,
        marginBottom: 40,
        color: theme.palette.grey[900],
        paddingBottom: 17,
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    }
});

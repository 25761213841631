import React from 'react';
import CatstompIcon from './CatstompIcon';
import FBIcon from './FBIcon';
import IndeedIcon from './IndeedIcon';
import LIIcon from './LIIcon';
import LinkIcon from './LinkIcon';
import SeekIcon from './SeekIcon';
import TikTokIcon from './TikTokIcon';
import TwitterIcon from './TwitterIcon';
import WhatsAppIcon from './WhatsAppIcon';


const SocialMediaIcons = ({ socialMedia, ...other }) => {
    switch (socialMedia) {
        case 'facebook': return <FBIcon {...other} />;
        case 'catstomp': return <CatstompIcon {...other} />;
        case 'indeed': return <IndeedIcon {...other} />;
        case 'linkedin': return <LIIcon {...other} />;
        case 'seek': return <SeekIcon {...other} />;
        case 'tiktok': return <TikTokIcon {...other} />;
        case 'twitter': return <TwitterIcon {...other} />;
        case 'whatsapp': return <WhatsAppIcon {...other} />;
        case 'vervoe': return <LinkIcon {...other} />;
        default: return <LinkIcon {...other} />;
    }
};

export default SocialMediaIcons;

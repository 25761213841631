import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import EditPanelSection from 'components/assessments_pages/asessment_settings_components/EditPanelSection';
import { manageAssessmentTags, getAssessmentTagsList } from 'requests/CMSRequests';
import TagsContainer from './TagsContainer';
import TagButton from './TagButton';

const AssessmentTags = ({ audition, changeAssessmentSettings }) => {
    const [allTags, setAllTags] = useState([]);

    useEffect(() => {
        getTags();
    }, []);

    const getTags = () => {
        getAssessmentTagsList().then(({ data, success }) => {
            if (success) {
                setAllTags(data.items.map((tag) => {
                    tag.isActive = tag.companyAuditions.some(tagAudition => tagAudition.uuid === audition.uuid);
                    return tag;
                }));
            }
        });
    };

    const addTag = async (tag) => {
        const { success } = await manageAssessmentTags(audition.uuid, { addTags: [tag.title] });
        if (success) {
            getTags();
            changeAssessmentSettings();
        }
    };

    const removeTag = async (tag) => {
        const { success } = await manageAssessmentTags(audition.uuid, { removeTags: [tag.title] });
        if (success) {
            getTags();
            changeAssessmentSettings();
        }
    };

    return (
        <EditPanelSection
            header={(
                <>
                    Assessment Tag
                    <TagButton allTags={allTags} audition={audition} getTags={getTags} addTag={addTag} removeTag={removeTag} />
                </>
            )}
        >
            <TagsContainer tags={allTags.filter(tag => tag.isActive)} onRemove={removeTag} />
            Add tags or quick searches to assessments
        </EditPanelSection>
    );
};

export default withRouter(AssessmentTags);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tooltip from 'libraries/Tooltip';
import LockIcon from 'img/lock.svg';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import PlanEvents from 'events/PlanEvents';
import { checkCompanyCanBeUpgraded } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';
import Zendesk from 'helper/zendeskFunctions';

const styles = theme => ({
    button: {
        padding: 0,
        fontSize: 10,
        paddingRight: 2,
        textDecoration: 'underline',
        color: theme.palette.grey[900],
        '& img': {
            marginRight: 2
        },
        '&:hover, &:focus, &:active': {
            textDecoration: 'underline',
            outline: 'none',
            color: theme.palette.grey[900]
        }
    },
    tooltip: {
        maxWidth: 255,
        fontSize: 12,
        textAlign: 'center'
    },
    tooltipLink: {
        color: '#fff',
        '&:hover': {
            color: theme.palette.grey[50]
        }
    },
    count: {
        color: theme.palette.yellow[800]
    }
});

const LockedCandidatesBlock = observer(({ lockedCandidates, classes }) => {
    const { company } = useContext(appCtx);
    if (!lockedCandidates) return null;
    const canCompanyUpgrade = checkCompanyCanBeUpgraded(company);

    const handleClick = (e) => {
        e.stopPropagation();
        PlanEvents.UPGRADE_BUTTON_CLICKED('assessment card button', true);
    };

    const openZendesk = (e) => {
        e.stopPropagation();
        Zendesk.open(true);
    };

    return (
        <div>
            <Tooltip
                label={(
                    <>
                        You've reached your candidate limit.&nbsp;
                        {
                            canCompanyUpgrade ? (
                                <SubscriptionsLink
                                    label={<u>Add more now</u>}
                                    className={classes.tooltipLink}
                                />
                            ) : (
                                <u
                                    className="u-cursor--p"
                                    role="presentation"
                                    onClick={openZendesk}
                                >
                                    Add more now
                                </u>
                            )
                        }
                    </>
                )}
                tooltipClass={classes.tooltip}
            >
                <div>
                    {
                        canCompanyUpgrade ? (
                            <SubscriptionsLink
                                label={(
                                    <Button
                                        onClick={handleClick}
                                        className={classes.button}
                                    >
                                        <img src={LockIcon} alt="!" /> Upgrade
                                    </Button>
                                )}
                            />
                        ) : (
                            <Button
                                onClick={openZendesk}
                                className={classes.button}
                            >
                                <img src={LockIcon} alt="!" /> Talk to us
                            </Button>
                        )
                    }
                </div>
            </Tooltip>
        </div>
    );
});

export default withStyles(styles)(LockedCandidatesBlock);

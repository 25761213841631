import React from 'react';
import { observer } from 'mobx-react-lite';

import { AUDIO, VIDEO, ANY_FILE, IMAGE, CODE_TEST } from 'helper/constants';

// local files and components
import FileUploadCase from '../FileUploadCase';
import ImageUploadCase from '../ImageUploadCase';
import CodeEditorPerView from '../CodeEditorPreView';
import SimulatorFileAttachment from '../SimulatorFileAttachment';
import MediaCase from '../MediaCase';

const TypesFactory = observer(({
    id, type, file, ziggeo, isSimulator,
    codeEditorValue, isUploadingFile, handleChangeToken,
    onDrop, handleChangeCodeEditor
}) => (
    <>
        {
            {
                [AUDIO]: <MediaCase
                    ziggeo={ziggeo}
                    recordingPublished={handleChangeToken}
                />,
                [VIDEO]: <MediaCase
                    video
                    ziggeo={ziggeo}
                    recordingPublished={handleChangeToken}
                />,
                [ANY_FILE]: !isSimulator ? (
                    <FileUploadCase
                        isUploadingFile={isUploadingFile}
                        onDrop={onDrop}
                        questionFile={file}
                    />
                ) : <SimulatorFileAttachment file={file} />,
                [IMAGE]: !isSimulator ? (
                    <ImageUploadCase
                        isUploadingFile={isUploadingFile}
                        questionFile={file}
                        onDrop={onDrop}
                    />
                ) : <SimulatorFileAttachment file={file} />,
                [CODE_TEST]: <CodeEditorPerView
                    key={id}
                    value={codeEditorValue}
                    onChange={handleChangeCodeEditor}
                />
            }[type]
        }
    </>
));

export default TypesFactory;

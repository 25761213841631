import React from 'react';
import LockIcon from 'img/lockGrey.svg';
import AlertIcon from 'components/icons/AlertIcon';
import DoneOutlinedIcon from 'components/icons/DoneOutlinedIcon';

const HeaderIcon = ({
    hasErrors, isTemplate, blocked
}) => {
    if (blocked) {
        return (<img src={LockIcon} alt="lock" />);
    }
    if (hasErrors && !isTemplate) {
        return <AlertIcon />;
    }

    return (
        <DoneOutlinedIcon
            size="20"
            color={isTemplate ? '#EBECEE' : '#00B898'}
        />
    );
};

export default HeaderIcon;

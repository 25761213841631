import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import Dialog from 'libraries/Dialog';
import TextInputOutlined from 'libraries/TextInput';
import { createSet } from 'requests/CMSRequests';
import CircularProgress from '@mui/material/CircularProgress';
import { appCtx } from 'components/appStore';
import SkillsInput from '../../../../SkillsSelection/SkillsInput';

const CreateSetDialog = observer(({
    onSuccess, openedDialog, handleDialog,
    selectedSkill: selectedSkillProps
}) => {
    const [loading, setLoading] = useState(false);
    const [skillName, setSkillName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedSkill, setSelectedSkill] = useState(-1);

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        if (!openedDialog) {
            setSkillName('');
            setErrorMessage('');
            setSelectedSkill(-1);
        }
    }, [openedDialog]);

    useEffect(() => {
        if (selectedSkillProps) {
            setSelectedSkill(selectedSkillProps);
        }
    }, [selectedSkillProps]);

    const handleSkillName = (e) => {
        setSkillName(e.target.value);
        setErrorMessage('');
    };

    const submit = () => {
        setLoading(true);
        createSet(skillName, selectedSkill.id)
            .then(({ success, data }) => {
                if (success && data) {
                    handleDialog();
                    flashMessage('New set created', 'done');
                    if (onSuccess) onSuccess(data);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    setErrorMessage(err.response.data.msg);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onClose = () => {
        setSkillName('');
        setErrorMessage('');
        handleDialog();
    };

    return (
        <Dialog
            onClose={onClose}
            handleClose={onClose}
            titleComponent="Create new set"
            maxWidth="sm"
            actionComponent={(
                <>
                    <Button
                        className="u-txt--bold"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="u-pdn--rx5 u-pdn--lx5"
                        disabled={!skillName || !skillName.trim() || !selectedSkill || selectedSkill === -1 || loading}
                        onClick={submit}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                />
                            ) : 'Save'
                        }
                    </Button>
                </>
            )}
            open={openedDialog}
        >

            <TextInputOutlined
                onChange={handleSkillName}
                value={skillName}
                variant="outlined"
                maxLength={255}
                minLength={1}
                hasCounter
                placeholder="Enter your new set name"
                label="Set Name"
                isError={Boolean(errorMessage)}
                helperText={errorMessage}
            />
            <SkillsInput
                fullWidth
                hideAllSkills
                selectedSkill={selectedSkill}
                setSelectedSkill={setSelectedSkill}
            />
        </Dialog>
    );
});

export default CreateSetDialog;

import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import { checkPluralNew } from 'helper/commonFunctions';
import Zendesk from 'helper/zendeskFunctions';
import InvitationAnswer from './InvitationAnswer';

import CheckIcon from './Check.svg';

import styles from './styles';

export const SUCCESS_INVITE_STATUS = 7;

const InviteResultsDialog = ({ classes, errorLimit, open, children, sentData, onClose }) => {
    if (!sentData) return null;
    const getInvitationAnswer = (key, values) => {
        let name = '';
        let message = '';
        const count = values.length;
        const targetLabel = 'assessment';
        const messagePart1 = `${checkPluralNew(count, 'Invitation')} which ${checkPluralNew(count, 'has', 'have', count)} incorrect`;
        const messagePart2 = `${checkPluralNew(count, 'Invitation')} to the following ${checkPluralNew(count, 'recipient')} ${checkPluralNew(count, 'was', 'were')} not sent because`;

        switch (Number(key)) {
            case 1:
                name = 'incorrectEmail';
                message = `${messagePart1} email:`;
                break;
            case 2:
                name = 'incorrectName';
                message = `${messagePart1} name:`;
                break;
            case 3:
                name = 'alreadyInOpportunity';
                message = `${messagePart2} they already applied for this ${targetLabel}:`;
                break;
            case 4:
                name = 'tags';
                message = `${messagePart2} some tags do not exist in the account:`;
                break;
            case 5:
                name = 'authors';
                message = `${messagePart2} they belong to authors:`;
                break;
            case 6:
                name = 'alreadyInvited';
                message = `${messagePart2} they have already been invited to this ${targetLabel}:`;
                break;
            case 8:
                name = 'limitExceeded';
                message = `${messagePart2} you've hit the candidate invitation limit. Upgrade for more:`;
                break;
            default:
                return '';
        }

        return (
            <InvitationAnswer
                key={name}
                value={values}
                message={message}
            />
        );
    };

    const openZendesk = () => {
        Zendesk.open(true);
    };

    const renderInvitationAnswer = () => {
        const invitationAnswer = [];

        Object.keys(sentData).forEach((field) => {
            invitationAnswer.push(getInvitationAnswer(field, sentData[field]));
        });
        return invitationAnswer;
    };

    const actionsEmployer = [
        <Button
            id="qa-msg-to-employer-close-btn"
            color="primary"
            onClick={onClose}
        >
            Close
        </Button>
    ];

    const inviteCount = (sentData[SUCCESS_INVITE_STATUS] || []).length;

    return (
        <Dialog
            actions={actionsEmployer}
            open={open}
            maxWidth="sm"
            className={classes.dialog}
            handleClose={onClose}
            contentClassName={classes.dialogContent}
        >
            {
                Boolean(inviteCount) && (
                    <div>
                        <img src={CheckIcon} alt="check" className={classes.icon} />
                        <Typography variant="h3" className={classes.successHeader}>
                            {checkPluralNew(inviteCount, 'Invitation')} Sent
                        </Typography>
                        Invitations to your assessment have successfully<br />
                        been sent to <b>{inviteCount}</b> {checkPluralNew(inviteCount, 'candidate')}.
                    </div>
                )
            }
            {
                !inviteCount && (
                    <div className={classes.successWrapper}>
                        <Typography variant="h3" className={classes.successHeader}>
                            Invitations Not Sent
                        </Typography>
                    </div>
                )
            }
            {
                errorLimit && (
                    <>
                        {
                            errorLimit.title && (
                                <Typography variant="h2">
                                    {errorLimit.title}
                                </Typography>
                            )
                        }
                        {errorLimit.message}
                    </>
                )
            }
            { renderInvitationAnswer()}
            {
                sentData['8'] ? (
                    <Button
                        fullWidth
                        color="primary"
                        className="u-txt--bold u-mrg--tx2"
                        onClick={openZendesk}
                    >
                        Talk To Sales
                    </Button>
                ) : children
            }
        </Dialog>
    );
};

export default withStyles(styles)(InviteResultsDialog);

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { publishAudition } from 'requests/CMSRequests';
import CircularProgress from '@mui/material/CircularProgress';

import stylesJS from './styles';

const AssessmentSynchronizeStatus = ({
    classes, uuid, className = '',
    synchronized = true,
    flashMessage, setAudition
}) => {
    const [hovered, setHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setHovered(false);
    }, [synchronized]);

    const onHover = () => {
        setHovered(true);
    };

    const onClick = () => {
        setIsLoading(true);
        publishAudition(uuid)
            .then(({ success, data }) => {
                if (success && data) {
                    setAudition(data);
                    flashMessage('Assessment synchronized', 'done');
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error', 3000);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onUnHover = () => {
        setHovered(false);
    };

    return (!synchronized ? (
        <div
            role="presentation"
            onMouseOver={onHover}
            onFocus={onHover}
            onClick={onClick}
            onMouseLeave={onUnHover}
            className={clsx(
                'u-cursor--p',
                classes.wrapper,
                hovered && classes.hoveredWrapper,
                className
            )}
        >
            {
                isLoading ? (
                    <CircularProgress size={11} className={classes.loader} />
                ) : (
                    <>
                        {hovered ? 'Synchronize' : 'Unsynchronized'}
                    </>
                )
            }
        </div>
    ) : null);
};

export default withStyles(stylesJS)(AssessmentSynchronizeStatus);

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    addQuestionToSet, duplicateCompanyQuestion,
    removeQuestionFromSet
} from 'requests/CMSRequests';

import { appCtx } from 'components/appStore';
import ItemActions from './ItemActions';
import RemoveQuestionDialog from './RemoveQuestionDialog';

const QuestionActions = observer(({
    loadQuestions, question, hideMenu, currentPage,
    setSelectedQuestionId, selectedSet,
    setAddToSetQuestionUuid, setRemoveFromSetQuestionUuid, setCurrentPage,
    assessmentPage, hideEdit, hideReorder, hideAdd, hideDuplicate,
    showDone, setQuestionState
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const { flashMessage } = useContext(appCtx);

    const { id, uuid, setsUsing } = question;

    const onEdit = () => {
        setSelectedQuestionId(id);
    };

    const handleDialog = () => {
        setOpenDialog(!openDialog);
    };

    const onDuplicate = () => {
        flashMessage('Duplicating question', 'pending', 100000);
        duplicateCompanyQuestion(id)
            .then(() => {
                if (currentPage === 1 && loadQuestions) loadQuestions();
                else if (setCurrentPage) setCurrentPage(1);
                flashMessage('Question duplicated', 'done');
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    const onAdd = () => {
        addQuestionToSet(selectedSet.uuid, uuid)
            .then(({ data, success }) => {
                if (success && data) {
                    if (setQuestionState) setQuestionState(data);
                    if (setAddToSetQuestionUuid) setAddToSetQuestionUuid(uuid);
                    flashMessage('Question added to set', 'done');
                }
            });
    };

    const onDelete = () => {
        setLoadingDelete(true);
        removeQuestionFromSet(selectedSet.uuid, uuid)
            .then(({ success, data }) => {
                if (success && data) {
                    if (setQuestionState) setQuestionState(data);
                }
                if (setRemoveFromSetQuestionUuid) setRemoveFromSetQuestionUuid(uuid);
                setOpenDialog(false);
                flashMessage(`Question removed ${assessmentPage ? '' : 'from set'}`, 'done');
            })
            .finally(() => {
                setLoadingDelete(false);
            });
    };

    const isInSelectedSet = selectedSet && setsUsing.some(({ uuid: setUuid }) => setUuid === selectedSet.uuid);

    return (
        <>
            {
                !hideMenu && (
                    <ItemActions
                        onEdit={onEdit}
                        onAdd={onAdd}
                        disabledAddToSet={!selectedSet}
                        isInSelectedSet={isInSelectedSet}
                        onDelete={handleDialog}
                        onDuplicate={onDuplicate}
                        hideEdit={hideEdit}
                        hideReorder={hideReorder}
                        hideAdd={hideAdd}
                        hideDuplicate={hideDuplicate}
                        showDone={showDone}
                    />
                )
            }
            <RemoveQuestionDialog
                open={openDialog}
                onRemove={onDelete}
                loading={loadingDelete}
                onClose={handleDialog}
                assessmentPage={assessmentPage}
            />
        </>
    );
});

export default QuestionActions;

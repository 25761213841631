import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import { IconButton } from '@mui/material';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import TooltipBlack from 'libraries/Tooltip';
import EditManagerForm from '../EditManagerForm';
import DeleteManagerDialog from './DeleteManagerDialog';

import styles from '../../styles';


const ManagerOption = ({ manager, selected, dataForEvents,
    setSelected, index, hiringManagers, setHiringManagers,
    assessmentSlug, classes, getHiringManagers, setHiringManager,
    hiringManagerFullName, setFullName, setManagerDeleted }) => {
    const [editOpen, setEditOpen] = useState(false);
    const [deleteManagerDialogOpen, setDeleteManagerDialogOpen] = useState(false);
    const [showEditButtons, setShowEditButtons] = useState(false);

    if (!manager) return null;
    const { fullName, email } = manager;

    const active = Boolean(selected.email === email);

    const handleClick = (e) => {
        e.stopPropagation();
        setSelected(manager);
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        setDeleteManagerDialogOpen(true);
    };

    const handleEdit = (e) => {
        e.stopPropagation();
        setEditOpen(!editOpen);
    };

    const handleMouseLeave = () => {
        if (!editOpen) setShowEditButtons(false);
    };

    return (
        <div
            role="presentation"
            className={clsx(classes.option, active && classes.activeOption)}
            onClick={handleClick}
            onMouseEnter={() => setShowEditButtons(true)}
            onMouseLeave={handleMouseLeave}
        >
            <div role="presentation" className="u-dsp--f u-ai--center u-jc--sb u-wdt--100p">
                <div className="u-dsp--f u-ai--center">
                    <Radio
                        checked={active}
                    />
                    <TruncateWithTooltip width={300} text={fullName} />
                    {index === 0 && <>&nbsp;(I'm the hiring manager)</>}
                </div>
                {index !== 0 && showEditButtons && (
                    <div className="u-dsp--f u-ai--center">
                        <TooltipBlack label="Edit Hiring Manager">
                            <IconButton onClick={handleEdit} size="large">
                                <EditIcon color={editOpen ? '#292A2D' : '#C7C8CC'} />
                            </IconButton>
                        </TooltipBlack>
                        <TooltipBlack label="Delete Hiring Manager">
                            <IconButton onClick={handleDelete} size="large">
                                <DeleteIcon color={deleteManagerDialogOpen ? '#292A2D' : '#C7C8CC'} />
                            </IconButton>
                        </TooltipBlack>
                    </div>
                )}
            </div>
            {editOpen && (
                <EditManagerForm
                    existingManager={manager}
                    {...{ setEditOpen,
                        hiringManagers,
                        setHiringManagers,
                        assessmentSlug,
                        getHiringManagers,
                        dataForEvents,
                        setManagerDeleted
                    }}
                />
            )}
            <DeleteManagerDialog
                open={deleteManagerDialogOpen}
                onClose={() => { setDeleteManagerDialogOpen(false); handleMouseLeave(); }}
                {...{ manager,
                    setHiringManagers,
                    hiringManagers,
                    hiringManagerFullName,
                    setFullName,
                    setManagerDeleted,
                    setHiringManager,
                    dataForEvents
                }}
            />
        </div>
    );
};

export default withStyles(styles)(ManagerOption);

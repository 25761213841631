import React from 'react';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { withStyles } from '@mui/styles';
import styles from '../styles';

const TagsContainer = ({ tags, onRemove, containerClassName, tagClassName, classes }) => (
    <div className={clsx(classes.container, containerClassName)}>
        {tags.map(tag => (
            <div className={clsx(classes.tag, onRemove && 'u-pdn--rx4', tagClassName)} key={tag.title}>
                <TruncateWithTooltip capitalize text={tag.title} width={115} />
                {
                    onRemove && (
                        <IconButton
                            className={classes.closeButton}
                            onClick={() => onRemove(tag)}
                            size="large"
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    )
                }
            </div>
        ))}
    </div>
);

export default withStyles(styles)(TagsContainer);

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import TextInput from 'libraries/TextInput';
import { editAssessmentTag } from 'requests/CMSRequests';


const RenameTagDialog = ({ open, tag: { title, id }, tags, setTags, onClose, flashMessage }) => {
    const [newName, setNewName] = useState('');
    const [nameError, setNameError] = useState('');

    useEffect(() => {
        if (open) {
            setNewName(title);
            setNameError('');
        }
    }, [open]);

    const handleTagName = (e) => {
        const { value } = e.target;
        setNewName(value);
        setNameError('');
    };

    const updateTagName = () => {
        editAssessmentTag(id, newName)
            .then(({ success }) => {
                if (success) {
                    setNameError('');
                    flashMessage('Assessment Tag renamed', 'done');
                    setTags(tags.map(tag => (tag.id === id ? { ...tag, title: newName } : tag)));
                    onClose();
                }
            })
            .catch(({ response: { data: { msg } } }) => {
                setNameError(msg);
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            onClose={onClose}
            handleClose={onClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="u-pdn--lx7 u-pdn--rx7"
                        color="primary"
                        variant="contained"
                        onClick={updateTagName}
                        disabled={newName === title || !newName}
                    >
                        Update
                    </Button>
                </>
            )}
            titleComponent="Rename assessment tag"
            open={open}
        >
            <div>
                This action will update the assessment tag name in all locations
            </div>
            <div className="u-dsp--distribute u-mrg--tx4">
                <TextInput
                    hasCounter
                    maxLength={150}
                    className="u-mrg--tx0"
                    value={newName}
                    variant="outlined"
                    label="Assessment Tag Name"
                    onChange={handleTagName}
                    helperText={nameError}
                    isError={Boolean(nameError)}
                />
            </div>
        </Dialog>
    );
};

export default RenameTagDialog;

import React from 'react';
import { withStyles } from '@mui/styles';

import Dialog from 'libraries/Dialog';
import BlueCheck from 'img/check_blue.svg';
import ButtonBase from '@mui/material/ButtonBase';
import clsx from 'clsx';
import styles from '../../styles';

const SuccessfulScreen = ({
    classes, handleClose, open, onClick
}) => (
    <Dialog
        open={open}
        onClose={handleClose}
        handleClose={handleClose}
        contentClassName={classes.root}
        fullWidth
        maxWidth="sm"
    >
        <div className={clsx(classes.successWrapper, 'u-dsp--f u-jc--center u-fdir--column u-ai--center u-pdn--tx4 u-pdn--bx4')}>
            <img src={BlueCheck} alt="" />
            <div className={clsx(classes.title, 'u-mrg--tx4 u-mrg--bx4')}>Request Submitted</div>
            <div className="u-txt--center">Your request for a custom assessment has been submitted. You will hear back from our team shortly</div>
            <ButtonBase onClick={onClick} className="u-mrg--tx4">Go to my Assessments</ButtonBase>
        </div>
    </Dialog>
);

export default withStyles(styles)(SuccessfulScreen);

import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import TextInput from 'libraries/TextInput';

class TextInputOutlinedValidated extends ValidatorComponent {
    renderValidatorComponent() {
        const { isValid } = this.state;
        const {
            formerror,
            errorMessages,
            validatorListener,
            requiredError,
            ...other
        } = this.props;
        const isError = !isValid || Boolean(formerror && formerror.length);
        return (
            <TextInput
                {...other}
                isError={isError}
                helperText={isError ? (formerror || this.getErrorMessage()) : null}
            />
        );
    }
}

export default TextInputOutlinedValidated;

export default theme => ({
    recordWrapper: {
        borderRadius: 5,
        backgroundColor: theme.palette.grey[300],
        height: 439,
        marginTop: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        fontWeight: 'bold',
        '@media only screen and (max-width: 767px)': {
            height: 196
        }
    },
    record: {
        width: 140,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        color: 'white',
        borderRadius: 20,
        marginBottom: 3,
        fontSize: 14,
        fontWeight: 'normal'
    },
    upload: {
        width: 140,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        color: '#9fa0a5',
        borderRadius: 20,
        fontSize: 14,
        fontWeight: 'normal'
    },
    helpText: {
        fontSize: 12,
        color: '#9fa0a5',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    limitLabel: {
        color: '#3a3c43',
        fontWeight: 700
    }
});

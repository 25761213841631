import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    table: {
        minWidth: 700
    },
    loaderWrapper: {
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    infoWrapper: {
        marginBottom: 15,
        display: 'flex',
        minHeight: 37,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    requestsNumber: {
        color: theme.palette.grey[500]
    },
    tableRow: {
        cursor: 'pointer'
    },
    tableCell: {
        backgroundColor: theme.palette.grey[200],
        color: '#3A3C43',
        fontWeight: 700,
        borderRadius: '4px 4px 0px 0px',
        fontSize: 14
    }
}));

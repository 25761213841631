import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import RetinaImage from 'react-retina-image';
import { Typography, Button } from '@mui/material';
import { withStyles } from '@mui/styles';

import {
    assignBrandingToAssessment,
    getBrandingsList,
    resetBrandingInAssessment
} from 'requests/SettingsRequests';
import { appCtx } from 'components/appStore';
import AddBrandingDetailsDialog from 'components/dialogs/AddBrandingDetailsDialog';
import AddBrandingSetDialog from 'components/dialogs/AddBrandingSetDialog';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { ASSESSMENT_BRANDING } from 'helper/constants';
import BrandingOptionsDropdown from './BradingOptionsDropdown';


const styles = {
    wrapper: {
        marginTop: 35,
        borderTop: '2px solid #F3F3F3',
        padding: '35px 0'
    },
    details: {
        maxWidth: 300,
        marginRight: 100
    },
    companyColorBlock: {
        width: '100%',
        minWidth: 100,
        height: 90,
        borderRadius: 8
    }
};

const BrandingDetails = observer(({
    setAudition, audition, audition: { slug, brand, name, uuid: auditionUuid }, classes, isSaving, setSaving
}) => {
    const [brandings, setBrandings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [brandingDetailsDialogOpen, setBrandingDetailsDialogOpen] = useState(false);
    const [addBrandingSetDialogOpen, setAddBrandingSetDialogOpen] = useState(false);
    const [updatingBrandUuid, setUpdatingBrandUuid] = useState('');
    const { flashMessage, company, company: { companyColor, companyImageUrl } } = useContext(appCtx);

    if (!checkHasCompanyFeature(company, ASSESSMENT_BRANDING)) return null;

    useEffect(() => {
        getBrandings();
    }, [brand]);

    const getBrandings = async () => {
        setLoading(true);
        const { data } = await getBrandingsList();
        setBrandings(data);
        setLoading(false);
    };

    const assignBrandingToAudition = (uuid) => {
        if (!uuid) return;
        assignBrandingToAssessment(slug, uuid)
            .then(({ data }) => {
                setAudition(data);
                AssessmentSettingsEvents.ASSESSMENT_BRANDING_ADDED({
                    ttUUID: auditionUuid,
                    ttName: name,
                    brandingTitle: data.brand.title,
                    brandingUUID: uuid
                });
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const resetAuditionBranding = () => {
        setSaving(true);
        resetBrandingInAssessment(slug)
            .then(() => {
                setAudition({ ...audition, brand: null });
                AssessmentSettingsEvents.ASSESSMENT_BRANDING_RESET({
                    ttUUID: auditionUuid,
                    ttName: name,
                    brandingTitle: brand.title,
                    brandingUUID: brand.uuid
                });
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const imageSrc = brandings.find(branding => brand?.uuid === branding.uuid)?.logoUrl[0] || companyImageUrl;

    return (
        <div className={classes.wrapper}>
            <Typography variant="h4">
                Branding Details
            </Typography>
            <div className="u-dsp--f">
                <div className={classes.details}>
                    <BrandingOptionsDropdown
                        company={company}
                        isSaving={isSaving}
                        setSaving={setSaving}
                        brandings={brandings}
                        currentBrandingUuid={brand?.uuid || ''}
                        assignBrandingToAudition={e => assignBrandingToAudition(e.target.value)}
                        setAddBrandingSetDialogOpen={setAddBrandingSetDialogOpen}
                    />
                    <Button
                        disabled={loading || !brand?.uuid}
                        className="u-txt--bold"
                        onClick={resetAuditionBranding}
                        color="primary"
                    >
                        Reset Assessment Branding
                    </Button>
                </div>
                {!loading && (
                    <div>
                        <div
                            role="presentation"
                            style={{ maxHeight: 100, maxWidth: 200, marginBottom: 20 }}
                        >
                            {imageSrc.length ? (
                                <RetinaImage
                                    alt="logo"
                                    src={imageSrc}
                                    style={{ maxHeight: 100, maxWidth: 200 }}
                                />
                            ) : (
                                <div className="u-dsp--f u-jc--center u-mrg--x4 u-txt--bold">
                                    No logo
                                </div>
                            )}
                        </div>
                        <div
                            className={classes.companyColorBlock}
                            style={{ backgroundColor: brandings.find(branding => brand?.uuid === branding.uuid)?.color || companyColor }}
                        />
                    </div>
                )}
            </div>
            <AddBrandingSetDialog
                isSaving={isSaving}
                setSaving={setSaving}
                open={addBrandingSetDialogOpen}
                onClose={() => {
                    setAddBrandingSetDialogOpen(false);
                    setBrandingDetailsDialogOpen(true);
                }}
                brandings={brandings}
                setBrandings={setBrandings}
                setUpdatingBrandUuid={setUpdatingBrandUuid}
            />
            <AddBrandingDetailsDialog
                isSaving={isSaving}
                setSaving={setSaving}
                open={brandingDetailsDialogOpen}
                onClose={() => {
                    setBrandingDetailsDialogOpen(false);
                    setUpdatingBrandUuid('');
                }}
                brandings={brandings}
                setBrandings={setBrandings}
                updatingBrandUuid={updatingBrandUuid}
                flashMessage={flashMessage}
                assignBrandingToAudition={assignBrandingToAudition}
            />
        </div>
    );
});

export default withStyles(styles)(BrandingDetails);

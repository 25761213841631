export default theme => ({
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media only screen and (max-width: 767px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    numberQuestion: {
        fontWeight: 'bold',
        color: theme.palette.grey[900],
        fontSize: 16,
        '& span:first-child': {
            fontSize: 12,
            color: '#3a3c43',
            fontWeight: 600,
            marginLeft: 10,
            display: 'inline-block'
        }
    },
    requiredLabel: {
        display: 'inline-block',
        padding: '5px 10px',
        borderRadius: '12px',
        backgroundColor: '#e6e6e6',
        fontSize: 10,
        color: '#1a1b1e',
        letterSpacing: '.8',
        marginLeft: 10,
        textTransform: 'uppercase'
    },
    swapButton: {
        fontSize: 11,
        borderRadius: 100,
        color: '#7b7a7a',
        textTransform: 'capitalize',
        backgroundColor: '#ededed',
        '&:hover': {
            backgroundColor: '#d6d6d6'
        },
        '@media only screen and (max-width: 767px)': {
            marginTop: 10
        }
    },
    cachedIcon: {
        color: theme.palette.primary.main
    },
    skillName: {
        fontWeight: 'bold',
        textTransform: 'capitalize'
    }
});

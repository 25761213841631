import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F3F3F3',
        marginRight: 15,
        width: ({ size = 36 }) => size,
        minWidth: ({ size = 36 }) => size,
        borderRadius: '50%',
        height: ({ size = 36 }) => size
    },
    imgWrapper: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        '& img': {
            borderRadius: '50%'
        }
    },
    initialsWrapper: {
        color: ({ inverted = false }) => (inverted ? '#ffffff' : theme.palette.primary.main),
        display: 'flex',
        background: ({ inverted = false }) => (inverted ? theme.palette.primary.main : '#ffffff'),
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        width: '100%',
        height: '100%',
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        fontWeight: 800
    },
    initialsWrapperMuted: {
        color: `${theme.palette.grey[500]}!important`,
        border: `2px solid ${theme.palette.grey[500]}`
    }
}));

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: theme.palette.blue[900],
        height: '100%',
        width: 60,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'flex-start',
        flexWrap: 'wrap'
    },
    iconWrapper: {
        position: 'relative',
        width: 46,
        height: 46,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10
    },
    active: {
        borderRadius: 4,
        backgroundColor: 'rgba(255,255,255,0.1)'
    },
    activeEllipse: {
        position: 'absolute',
        top: 10,
        right: 5
    }
}));

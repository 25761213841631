export default theme => ({
    container: {
        minHeight: '100vh',
        width: 520,
        minWidth: 520
    },
    tabs: {
        backgroundColor: '#fff',
        width: '100%',
        position: 'relative'
    },
    tab: {
        whiteSpace: 'nowrap',
        textTransform: 'none',
        color: theme.palette.grey[500],
        fontSize: 14,
        padding: '14px 35px',
        letterSpacing: '0.2px',
        fontWeight: 700,
        opacity: 1,
        minWidth: 'auto',
        width: '50%',
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        '&:not(.Mui-selected):hover': {
            color: theme.palette.grey[600]
        },
        '&:not(.Mui-selected)': {
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        },
        '&.Mui-selected': {
            top: -1,
            color: theme.palette.grey[900]
        }
    },
    indicator: {
        display: 'none'
    },
    tabSelected: {
        color: theme.palette.grey[900],
        fontWeight: 700
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'calc(100vh - 50px)'
    },
    content: {
        padding: '20px 35px',
        overflow: 'auto'
    },
    secondaryNoBorderButton: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginTop: 15,
        '& img': {
            marginRight: 10
        }
    },
    subtitle: {
        fontSize: 16,
        fontWeight: 700,
        marginTop: 25
    },
    footer: {
        background: theme.palette.grey[200],
        height: 80,
        width: '100%',
        maxWidth: 520,
        padding: 20,
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            minWidth: 160
        },
        '& *': {
            color: '#fff'
        }
    },
    testCase: {
        fontWeight: 700,
        fontSize: 16,
        color: theme.palette.primary.main,
        padding: '10px 0',
        borderBottom: '2px solid #EBECEE',
        cursor: 'pointer'
    },
    loader: {
        marginLeft: 10
    }
});

import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';

// material components
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from 'libraries/Tooltip';
import TextInputOutlinedValidated from 'libraries/TextInputValidated';

// local files and components
import infoIconGray from 'img/candidateCard/infoGray.svg';
import infoIcon from 'img/candidateCard/info.svg';
import TestCaseGenerator from './TestCaseGenerator';

import styles from './styles';


class EditCase extends Component {
    state = {
        formData: {
            name: '',
            input: '',
            output: '',
            score: ''
        },
        example: 1,
        scoreError: false,
        testCaseGeneratorEnabled: false
    };

    componentWillMount() {
        ValidatorForm.addValidationRule('isPointsMoreThenNull', (value) => {
            const { pointError } = this.state;
            if (value && +value <= 0) {
                this.setState({ pointError: true });
                return false;
            }
            if (pointError) {
                this.setState({ pointError: false });
            }
            return true;
        });
    }

    onError = (err) => {
        const score = err.find(({ props: { name } }) => name === 'score');
        if (score && !score.value) {
            this.setState({ scoreError: true });
        } else {
            this.setState({ scoreError: false });
        }
    };

    handleSubmit = () => {
        const { scoreError, formData, example, testCaseGeneratorEnabled } = this.state;
        if (scoreError || !formData.name) return;
        const generatorEnabledToSend = testCaseGeneratorEnabled;
        setTimeout(() => this.setState({ testCaseGeneratorEnabled: false }), 0);
        const {
            sendTestData,
            editedCase,
            currentCase,
            editTestCase,
            chooseCase
        } = this.props;

        const functionToSubmit = currentCase === 'edit' ? editTestCase : sendTestData;

        if (formData.score) {
            formData.score = +formData.score;
        }

        if (currentCase !== 'edit') {
            if (example) delete formData.score;
            setTimeout(() => functionToSubmit({
                ...formData,
                type: example ? 'example' : 'autograded',
                generatorEnabled: generatorEnabledToSend
            }), 10);
        } else {
            setTimeout(() => functionToSubmit(editedCase.id, {
                ...formData,
                type: example ? 'example' : 'autograded',
                generatorEnabled: generatorEnabledToSend
            }), 10);
        }
        chooseCase('default', null);
    };

    handleChange = ({ target: { value, name } }) => {
        const { formData } = this.state;
        if (name === 'score') this.setState({ scoreError: false });
        formData[name] = value;
        this.setState({ formData });
    }

    handleInputOutputChange = (inputValue, outputValue) => {
        const { formData, formData: { input, output } } = this.state;
        this.setState({ formData: { ...formData, input: (inputValue !== null) ? inputValue : input, output: (outputValue !== null) ? outputValue : output } });
    }

    handleToggle = (e, value) => this.setState({ example: value });

    setDefaultParams = (testCase) => {
        const {
            name,
            input,
            output,
            score,
            type
        } = testCase;
        const { currentCase } = this.props;

        if (Object.keys(testCase).length) {
            if (currentCase === 'example' || type === 'example') {
                this.setState({
                    formData: {
                        name,
                        input,
                        output
                    }
                });
            } else {
                this.setState({
                    formData: {
                        name,
                        input,
                        output,
                        score
                    },
                    example: 0
                });
            }
        } else {
            this.setState({
                example: currentCase === 'example' ? 1 : 0
            });
        }
    }

    validateNumber = (e) => {
        if (!/[0-9]/.test(String.fromCharCode(e.keyCode || e.which))) {
            e.preventDefault();
        } else {
            this.setState({ scoreError: false });
        }
    }

    componentDidMount() {
        const { editedCase, currentCase } = this.props;
        if (currentCase === 'edit') {
            this.setDefaultParams(editedCase);
        } else {
            this.setDefaultParams({});
        }
        if (editedCase && editedCase.generatorEnabled) this.setState({ testCaseGeneratorEnabled: editedCase.generatorEnabled });
    }

    render() {
        const { classes, setDeleteTestCaseDialogOpen, chooseCase, editedCase } = this.props;
        const {
            formData,
            scoreError,
            example,
            testCaseGeneratorEnabled
        } = this.state;
        return <>
            <div className={classes.content}>
                <h2 className="u-txt--14 u-txt--bold u-mrg--bx3">Test Case Type</h2>
                <Tabs
                    value={example}
                    onChange={this.handleToggle}
                    classes={{
                        root: classes.tabs,
                        flexContainer: classes.flexContainer,
                        indicator: classes.indicator
                    }}
                >
                    <Tab
                        disableRipple
                        classes={{
                            root: classes.tab,
                            selected: classes.selectedTab,
                            labelContainer: classes.labelContainer
                        }}
                        label="Autograded"
                    />
                    <Tab
                        disableRipple
                        classes={{
                            root: classes.tab,
                            selected: classes.selectedTab,
                            labelContainer: classes.labelContainer
                        }}
                        label="Example"
                    />
                </Tabs>
                <p className="u-txt--16 u-mrg--tx3">
                    {
                        !example
                            ? 'Assign a point value to this test case. Add input values and the expected output.'
                            : 'This test case will not affect the question’s score. Input & expected output will be visible and will help candidates understand the question better.'
                    }
                </p>
                <ValidatorForm
                    ref={(form) => { this.form = form; }}
                    onSubmit={this.handleSubmit}
                    onError={this.onError}
                    id="codeChallengeEditTestCaseForm"
                >
                    <TextInputOutlinedValidated
                        label="Test Case Name"
                        onChange={this.handleChange}
                        name="name"
                        value={formData.name}
                        validators={['required']}
                        errorMessages={['Please enter a test case name. This will not be visible to candidates.']}
                        variant="outlined"
                    />
                    {example === 0 && (
                        <TextInputOutlinedValidated
                            label="Points"
                            onChange={this.handleChange}
                            name="score"
                            value={formData.score}
                            validators={['isPointsMoreThenNull', 'required']}
                            errorMessages={['Please enter a number > 0', 'Please enter a number > 0']}
                            variant="outlined"
                            type="number"
                            maxLength={3}
                            style={{ width: 184 }}
                            onKeyPress={this.validateNumber}
                            endAdornment={(
                                <Tooltip
                                    white
                                    tooltipClass={classes.tooltipClass}
                                    placement="right"
                                    label="Points are relative to the total points for this question. They are awarded on an all or nothing basis.  The value won’t be visible to candidates. The value must be greater than 0."
                                >
                                    {
                                        scoreError
                                            ? <img src={infoIcon} alt="error" />
                                            : <img src={infoIconGray} alt="error" />
                                    }
                                </Tooltip>
                            )}
                        />
                    )}
                    <TestCaseGenerator
                        enabled={testCaseGeneratorEnabled}
                        setEnabled={value => this.setState({ testCaseGeneratorEnabled: value })}
                        handleInputOutputChange={this.handleInputOutputChange}
                        testCaseInput={formData.input}
                        testCaseOutput={formData.output}
                    />
                    {!testCaseGeneratorEnabled && <>
                        <TextInputOutlinedValidated
                            label="Input"
                            onChange={this.handleChange}
                            name="input"
                            value={formData.input}
                            rows={7}
                            fullWidth
                            variant="outlined"
                            multiline
                        />
                        <TextInputOutlinedValidated
                            label="Expected Output"
                            onChange={this.handleChange}
                            name="output"
                            value={formData.output}
                            validators={['required']}
                            rows={7}
                            errorMessages={['Please enter expected output.']}
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                </>}
                </ValidatorForm>
            </div>
            <div className={classes.footer}>
                <div>
                    <Button
                        onClick={() => chooseCase('default')}
                    >
                        Back
                    </Button>
                </div>
                <div>
                    { editedCase && editedCase.name && (
                        <Button
                            color="primary"
                            className="u-mrg--rx2"
                            onClick={() => setDeleteTestCaseDialogOpen(true)}
                        >
                            Delete
                        </Button>
                    )}
                    {(!formData.output || !formData.output.trim()) ? (
                        <Tooltip label="Please enter expected output.">
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.disabledButton}
                            >
                                Save test case
                            </Button>
                        </Tooltip>
                    ) : (
                        <Button
                            form="codeChallengeEditTestCaseForm"
                            variant="contained"
                            color="primary"
                            className={classes.btnSave}
                            type="submit"
                        >
                            Save test case
                        </Button>
                    )}
                </div>
            </div>
        </>;
    }
}

export default withStyles(styles)(EditCase);

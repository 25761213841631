import React, { Component } from 'react';

import Layout from 'components/layout';
import PageWrapper from 'components/layout/PageWrapper';
import { getInvoices, getCompanyPlan, getCompanyCards, getCompanyCompletePackages } from 'requests/SubscriptionRequests';
import SubscriptionInfoBlock from './SubscriptionInfoBlock';
import InvoicesBlock from './InvoicesBlock';
import CandidatePackListBlock from './CandidatePackListBlock';
import SettingsHeader from '../settings_header';
import PausedLabel from '../PausedLabel';


class BillingSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyPlan: null,
            remainingDays: null,
            invoices: null,
            cards: null,
            cancelAtPeriodEnd: false,
            period: null,
            subscriptionId: null,
            pausesCount: null,
            daysOfPauseLeft: null,
            pauseAllowed: null,
            countSmsLeft: null,
            countSmsPerCompanyQuota: null,
            activeAuditionsPerCompany: null,
            activeAuditionsPerCompanyLeft: null,
            companyCompletePackages: null
        };
    }

    componentDidMount() {
        this.getCompanyPlan();
        this.getInvoices();
        this.getCards();
        this.getCompanyCompletePackages();
    }

    getCompanyPlan = () => {
        getCompanyPlan()
            .then(({ success, data }) => {
                if (success) {
                    const {
                        companyPlan,
                        remainingDays,
                        cancelAtPeriodEnd,
                        period,
                        subscriptionId,
                        pausesCount,
                        daysOfPauseLeft,
                        pauseAllowed,
                        countSmsLeft,
                        countSmsPerCompanyQuota,
                        activeAuditionsPerCompany,
                        activeAuditionsPerCompanyLeft
                    } = data;
                    this.setState({
                        companyPlan,
                        remainingDays,
                        cancelAtPeriodEnd,
                        period,
                        subscriptionId,
                        pausesCount,
                        daysOfPauseLeft,
                        pauseAllowed,
                        countSmsLeft,
                        countSmsPerCompanyQuota,
                        activeAuditionsPerCompany,
                        activeAuditionsPerCompanyLeft
                    });
                }
            });
    };

    getInvoices = () => {
        getInvoices()
            .then(({ success, data }) => {
                if (success) {
                    this.setState({ invoices: data });
                }
            });
    };

    getCompanyCompletePackages = () => {
        getCompanyCompletePackages()
            .then(({ success, data }) => {
                if (success) {
                    this.setState({ companyCompletePackages: data });
                }
            });
    };

    getCards = () => {
        getCompanyCards()
            .then(({ success, data }) => {
                if (success) {
                    this.setState({ cards: data });
                }
            });
    };

    openSubscriptions = () => {
        const { history } = this.props;
        history.push('/subscriptions');
    };

    updateCompany = (company) => {
        const { updateCompany } = this.props;
        this.getCompanyPlan();
        this.getCards();
        this.getInvoices();
        this.getCompanyCompletePackages();
        updateCompany(company);
    };

    /*------------------------------------
        #RENDER
    ------------------------------------*/
    render() {
        const {
            invoices, cards, period,
            companyPlan, remainingDays,
            cancelAtPeriodEnd, subscriptionId,
            pausesCount, daysOfPauseLeft, pauseAllowed,
            countSmsLeft, countSmsPerCompanyQuota,
            activeAuditionsPerCompany,
            activeAuditionsPerCompanyLeft,
            companyCompletePackages
        } = this.state;

        return (
            <Layout>
                <PausedLabel />
                <SettingsHeader />
                <PageWrapper
                    headerLabel="Subscription Management"
                >
                    {
                        companyPlan && (
                            <SubscriptionInfoBlock
                                companyPlan={companyPlan}
                                period={period}
                                cards={cards}
                                cancelAtPeriodEnd={cancelAtPeriodEnd}
                                remainingDays={remainingDays}
                                updateCompany={this.updateCompany}
                                subscriptionId={subscriptionId}
                                getCompanyPlan={this.getCompanyPlan}
                                pausesCount={pausesCount}
                                daysOfPauseLeft={daysOfPauseLeft}
                                pauseAllowed={pauseAllowed}
                                countSmsLeft={countSmsLeft}
                                countSmsPerCompanyQuota={countSmsPerCompanyQuota}
                                activeAuditionsPerCompany={activeAuditionsPerCompany}
                                activeAuditionsPerCompanyLeft={activeAuditionsPerCompanyLeft}
                            />
                        )
                    }
                    <CandidatePackListBlock companyCompletePackages={companyCompletePackages} />
                    <InvoicesBlock
                        invoices={invoices}
                    />
                </PageWrapper>
            </Layout>
        );
    }
}

export default BillingSettings;

import React from 'react';
import { IMAGE } from 'helper/constants';

const Svg = (onClick, className) => (
    <svg
        className={className}
        onClick={e => onClick(e, IMAGE)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M5.4 22H18.6C19.3732 22 20 21.3732 20 20.6V6.12789C20 5.74564 19.8437 5.38 19.5674 5.11587L16.7136 2.38798C16.4531 2.13897 16.1066 2 15.7462 2H5.4C4.6268 2 4 2.6268 4 3.4V20.6C4 21.3732 4.6268 22 5.4 22Z" stroke="#676A78" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.5 9C10.3284 9 11 8.32843 11 7.5C11 6.67157 10.3284 6 9.5 6C8.67157 6 8 6.67157 8 7.5C8 8.32843 8.67157 9 9.5 9Z" stroke="#676A78" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path className="noStroke" d="M15.045 10.0712L14.604 9.66428L15.045 10.0712ZM20.4576 12.5757L17.4882 9.6063L16.6396 10.4548L19.6091 13.4243L20.4576 12.5757ZM14.604 9.66428L4.05903 21.0931L4.94097 21.9069L15.486 10.478L14.604 9.66428ZM17.4882 9.6063C16.6848 8.80296 15.3745 8.8293 14.604 9.66428L15.486 10.478C15.7942 10.144 16.3183 10.1335 16.6396 10.4548L17.4882 9.6063Z" fill="#676A78" />
    </svg>
);

export default Svg;

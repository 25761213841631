/* eslint-disable import/prefer-default-export */
import { action, observable } from 'mobx';
import { duplicateInterview } from 'requests/ScriptRequests';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import LibraryEvents from 'events/LibraryEvents';
import { onActivateCallback, increaseActiveAuditionsPerCompany } from 'helper/assessmentFunctions';
import { activateAssessment } from 'requests/AssessmentRequests';
import { appStore } from '../../appStore';


export class AssessmentStore {
    @observable assessment = null;

    @observable activateDialogOpen = false;

    @observable limitActivateDialogOpen = false;

    constructor(assessment) {
        this.assessment = assessment;
    }

    @action setlLimitActivateDialogOpen = (newValue) => {
        this.limitActivateDialogOpen = newValue;
    }

    @action closeLimitActivateDialog = () => {
        this.limitActivateDialogOpen = false;
    }

    @action setActivateDialogOpen = (newValue) => {
        this.activateDialogOpen = newValue;
    }

    @action onActivate = () => {
        const { slug, countRealCandidates } = this.assessment;
        const { company } = appStore;
        const { companySettings } = company;
        const { activeAuditionsPerCompany, activeAuditionsPerCompanyUsed } = companySettings;
        if (activeAuditionsPerCompany === -1 || activeAuditionsPerCompany > activeAuditionsPerCompanyUsed) {
            if (!checkHasCompanyFeature(company, 'ACTIVATE_ASSESSMENT_WITH_PREEXISTING_CANDIDATES') || countRealCandidates === 0) {
                return this.activateAssessment(slug);
            }
            this.setActivateDialogOpen(true);
            return Promise.reject();
        }
        this.setlLimitActivateDialogOpen(true);
        return Promise.reject();
    };

    @action activateAssessment = (assessmentSlug) => {
        const { slug } = this.assessment;
        if (!assessmentSlug) {
            assessmentSlug = slug;
        }
        return onActivateCallback(activateAssessment(assessmentSlug))
            .then((responseData) => {
                const { success, data } = responseData;
                if (success) {
                    return data;
                }
                return Promise.reject();
            })
            .catch((err) => {
                if (err.response && err.response.status === 418) {
                    this.setlLimitActivateDialogOpen(true);
                } else {
                    appStore.flashMessage('Something went wrong.');
                    this.activateDialogOpen = false;
                }
                return Promise.reject();
            });
    };

    @action duplicateAndActivate = () => this.duplicate().then((data) => {
        const { active: isActive, slug: newSlug } = data;
        if (!isActive) {
            return this.activateAssessment(newSlug);
        }
        return Promise.resolve(data);
    });

    @action duplicate = () => {
        const { name, slug, countQuestions, questionGroups, uuid: ttId } = this.assessment;
        const { flashMessage } = appStore;
        flashMessage(`Duplicating "${name}" Skills Assessment `, 'pending', 100000);
        return (
            duplicateInterview(slug)
                .then(({ success, data }) => {
                    if (success) {
                        const segmentData = {
                            ttName: name,
                            ttId,
                            questionsNumber: countQuestions,
                            questionGroupsNumber: questionGroups.length
                        };
                        if (questionGroups.length) segmentData.questionGroups = questionGroups.map(item => item.title);
                        LibraryEvents.TT_DUPLICATED(segmentData);
                        const { active: isActive } = data;
                        if (isActive) {
                            increaseActiveAuditionsPerCompany();
                        }
                        flashMessage('Assessment duplicated', 'done');

                        return data;
                    }
                    return Promise.reject();
                })
        );
    };
}

import React from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import Dialog from 'libraries/Dialog';
import { observer } from 'mobx-react-lite';

const styles = theme => ({
    dialog: {
        '& div': {
            fontWeight: 600,
            '& ::last-child': {
                marginTop: 5
            }
        }
    },
    grey: {
        color: theme.palette.grey[500]
    },
    red: {
        textTransform: 'capitalize',
        fontWeight: 700,
        color: theme.palette.red.primary
    },
    green: {
        textTransform: 'capitalize',
        fontWeight: 700,
        color: theme.palette.green[600]
    },
    comments: {
        fontWeight: 400
    }
});

const HiringManagerFeedbackDialog = observer(({
    candidate, classes, open, onClose
}) => {
    const { grey, green, red, dialog, comments } = classes;

    const { candidateFullName,
        hiringManagerFeedback: { comment, createdAt, hiringManagerFullName, wouldYouHireAgain, rating }
    } = candidate;

    const getWouldYouHireClass = () => {
        switch (wouldYouHireAgain) {
            case 'yes': return green;
            case 'no': return red;
            default: return grey;
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            titleComponent={`${candidateFullName} Feedback`}
            open={open}
            onClose={onClose}
            handleClose={onClose}
            className={dialog}
        >
            <div className="u-dsp--f u-jc--sb u-mrg--bx3">
                <div><b>{hiringManagerFullName}</b></div>
                <div className={grey}>{moment(createdAt).format('D MMM YYYY')}</div>
            </div>
            <div className="u-mrg--bx1">
                Would you hire again?&emsp;
                <span className={getWouldYouHireClass()}>{wouldYouHireAgain || 'No answer'}</span>
            </div>
            <div className="u-mrg--bx1">
                Rating&emsp;
                {rating || rating === 0
                    ? <span className={rating > 5 ? green : red}>{rating}/10</span>
                    : <span className={grey}>No rating</span>
                }
            </div>
            <div className="u-mrg--tx3">
                { comment
                    ? <span className={comments}>{comment}</span>
                    : <span className={grey}>No comments</span>
                }
            </div>
        </Dialog>
    );
});

export default withStyles(styles)(HiringManagerFeedbackDialog);

import { get, patch, post } from '../helper/API';


export function unrejectCandidate(columnUserUuid) {
    return patch(`employer/column-users/${columnUserUuid}/unreject`);
}

export function requestMessageCandidate(user, subject, text, replyTo) {
    return post('employer/candidate-message', {
        user,
        subject,
        text,
        replyTo
    });
}

export function getCandidatePosts(userId, opportunitySlug) {
    return get(`employer/opportunities/${opportunitySlug}/candidates/${userId}/posts`);
}

export function getAuditionQuestionResults(auditionId) {
    return get(`employer/user-auditions/${auditionId}/question-results`);
}

export function getAuditionScreeningQuestionResults(auditionId) {
    return get(`employer/user-auditions/${auditionId}/screening-question-results`);
}

export function addCandidateComment(comment_content, candidateId, opportunitySlug) {
    return post(`employer/opportunities/${opportunitySlug}/candidates/${candidateId}/comments`, {
        comment_content
    });
}

export function gradeEmployerAnswer(question_result_id, value) {
    return post('employer/grade/answer', {
        question_result_id,
        value
    });
}

export function getEmployerGradeQuestion(id) {
    return get(`employer/question-results/${id}`);
}


export function reverseAutoGradedSpreadsheetTestCaseResultOutcome(spreadsheetTestCaseResultId) {
    return patch(`employer/spreadsheet-test-case-results/${spreadsheetTestCaseResultId}/reverse-outcome`);
}


export function getCandidateOpportunitiesList(uuid) {
    return get(`employer/candidates/${uuid}/opportunities`);
}

/* Public card */

export function getPublicCandidateOpportunitiesList(uuid, isAgency) {
    const prefix = isAgency ? 'guest' : 'public';
    return get(`${prefix}/candidates/${uuid}/opportunities`);
}

export function getPublicLinkInfo(linkUuid) {
    return get(`public/candidata-card/${linkUuid}`);
}

export function getCandidateAudition(uuid) {
    return get(`employer/user-auditions/${uuid}`);
}

export function getPublicCandidateAudition(uuid, isAgency) {
    const prefix = isAgency ? 'guest' : 'public';
    return get(`${prefix}/user-auditions/${uuid}`);
}

export function getPublicAuditionQuestionResults(auditionId, isAgency) {
    const prefix = isAgency ? 'guest/' : 'public/';
    return get(`${prefix}user-auditions/${auditionId}/question-results`);
}

export function getPublicAuditionScreeningQuestionResults(auditionId, isAgency) {
    const prefix = isAgency ? 'guest/' : 'public/';
    return get(`${prefix}user-auditions/${auditionId}/screening-question-results`);
}

export function getPublicGradeQuestion(id, isAgency) {
    const prefix = isAgency ? 'guest/' : 'public/';
    return get(`${prefix}question-results/${id}`);
}

/* Guest card */
export function getGuestCandidatePosts(userId, opportunitySlug) {
    return get(`guest/opportunities/${opportunitySlug}/candidates/${userId}/posts`);
}

export function addGuestCandidateComment(comment_content, candidateId, opportunitySlug) {
    return post(`guest/opportunities/${opportunitySlug}/candidates/${candidateId}/comments`, {
        comment_content
    });
}

export function gradeGuestAnswer(question_result_id, value) {
    return post('guest/grade/answer', {
        question_result_id,
        value
    });
}

import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    topPerformerLabel: {
        color: theme.palette.green[600],
        backgroundColor: theme.palette.green[50],
        padding: '4px 10px',
        borderRadius: 4,
        textAlign: 'center',
        fontSize: 12,
        marginRight: 5
    }
}));

const TopPerformerLabel = ({ className }) => {
    const { topPerformerLabel } = useStyles();

    return (
        <span className={clsx(topPerformerLabel, className)}>Top Performer</span>
    );
};

export default TopPerformerLabel;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import ContentAccordion from 'libraries/ContentAccordion';

import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import styles from './styles';

const StatusHistory = observer(({ statusHistory, classes }) => (
    <ContentAccordion
        className={classes.accordion}
        header={(
            <Typography className="u-mrg--bx2">
                Status History
            </Typography>
        )}
        content={(
            <>
                <div className={clsx(classes.historyRow, classes.headerRow)}>
                    <span className={classes.timeColumn}>Time Stamp</span>
                    <span className={classes.statusColumn}>Status</span>
                </div>
                {statusHistory.map((historyItem, idx) => (
                    <div className={classes.historyRow} key={idx}>
                        <span className={classes.timeColumn}>{historyItem.timeStamp}</span>
                        <span className={classes.statusColumn}>{historyItem.status}</span>
                    </div>
                ))}
            </>
        )}
    />
));

export default withStyles(styles)(withRouter(StatusHistory));

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    textField: {
        width: 110,
        '& fieldset': {
            display: 'none'
        },
        '& input': {
            fontSize: 12,
            fontWeight: 600,
            padding: '0 10px',
            height: 32,
            cursor: 'pointer',
            transition: '0.3s',
            border: `2px solid ${theme.palette.grey[300]}`,
            borderRadius: 4,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 0,
            boxSizing: 'border-box',
            '&::placeholder': {
                fontSize: 12,
                fontWeight: 600,
                color: `${theme.palette.grey[500]}!important`,
                letterSpacing: '0.155556px',
                opacity: 1
            },
            '&.Mui-disabled': {
                borderColor: 'rgba(0, 0, 0, 0.12)',
                color: theme.palette.grey[600],
                '-webkit-text-fill-color': theme.palette.grey[600]
            }
        },
        '&:hover': {
            '& input:not(:disabled)': {
                backgroundColor: theme.palette.grey[300],
                '&::placeholder': {
                    color: `${theme.palette.grey[900]}!important`
                }
            }
        }
    }
}));

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: theme.palette.grey[100],
        width: 350,
        minWidth: 350
    },
    knowledgeBaseContainer: {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.grey[400],
        borderBottomStyle: 'solid',
        padding: 24,
        height: '40%',
        overflowY: 'auto'
    },
    blockTitle: {
        fontSize: 14,
        fontWeight: 700,
        color: '#000',
        marginBottom: 8
    },
    knowledgeArticle: {
        fontSize: 13,
        fontWeight: 600,
        color: theme.palette.blue[600],
        cursor: 'pointer',
        marginBottom: 12
    },
    customerDetailsContainer: {
        padding: 24,
        overflowY: 'auto',
        maxHeight: '60%'
    },
    attributeTitle: {
        marginTop: 17,
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.grey[500]
    },
    attributeText: {
        fontSize: 14,
        fontWeight: 700
    },
    attributeTextOrder: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.blue[600]
    },
    attributeOrder: {
        marginBottom: 10
    },
    drawer: {
        zIndex: 1400
    },
    drawerWrapper: {
        maxWidth: 600,
        width: '100%'
    },
    drawerHeader: {
        height: 75,
        minHeight: 75,
        paddingLeft: 27,
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.grey[900],
        display: 'flex',
        alignItems: 'center',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[100],
        '& > img': {
            marginRight: 20,
            cursor: 'pointer'
        }
    },
    articleWrapper: {
        fontSize: 14,
        fontWeight: 700,
        padding: '26px 26px 13px'
    },
    orderText: {
        fontSize: 14
    },
    articleText: {
        fontWeight: 400
    },
    description: {
        marginBottom: 0,
        color: '#3a3c43',
        '& pre': {
            display: 'block',
            padding: 20,
            margin: '0 0 10px',
            lineHeight: 1.43,
            color: '#333',
            wordBreak: 'break-all',
            wordWrap: 'break-word',
            backgroundColor: '#f5f5f5',
            border: '1px solid #ccc',
            borderRadius: 4
        },
        '& code': {
            backgroundColor: 'rgb(243, 243, 243)',
            padding: 2,
            whiteSpace: 'pre-wrap'
        },
        '& blockquote': {
            borderLeft: '5px solid #eee',
            color: '#666',
            fontFamily: 'Hoefler Text,Georgia,serif',
            fontStyle: 'italic',
            margin: '16px 0',
            padding: '10px 20px',
            fontSize: '1.1rem'
        },

        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            fontWeight: 400
        }
    }
}));

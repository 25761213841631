import React from 'react';
import { withStyles } from '@mui/styles';
import GradeScale from 'components/grade_components/GradeScale';

import styles from './styles';

const GradingBlock = ({ classes, saveGrade, gradeLoading, grade, hintSection, canGrade }) => (
    <div className={classes.wrapper}>
        <GradeScale
            canGrade={canGrade}
            willBeGraded
            grade={grade}
            hideCheck
            saveGrade={saveGrade}
            gradeLoading={gradeLoading}
            hintSection={hintSection}
        />
    </div>
);

export default withStyles(styles)(GradingBlock);

import React from 'react';
import uuidv4 from 'uuid/v4';
import { withStyles } from '@mui/styles';
import { saveGreenhouseSettings, getGreenhouseSettings } from 'requests/SettingsRequests';
import SettingsEvents from 'events/SettingsEvents';
import Button from '@mui/material/Button';
import TextInput from 'libraries/TextInput';
import Switch from 'libraries/SwitchBrandWithLabel';

import stylesJS from './styles';

class GreenhouseContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            harvestKeyError: '',
            harvestKey: '',
            key: '',
            enabled: false
        };
    }

    componentDidMount() {
        getGreenhouseSettings()
            .then(({ success, data }) => {
                if (success && data) {
                    const { apiKey, harvestApiKey, enabled } = data;
                    this.setState({
                        key: apiKey,
                        harvestKey: harvestApiKey || '',
                        enabled
                    });
                }
            });
    }

    handleChangeHarvestKey = (e) => {
        const targetValue = e.target.value;
        this.setState({ harvestKey: targetValue });
    };

    saveGreenhouseSettings = () => {
        const { enabled, key, harvestKey } = this.state;
        const { flashMessage } = this.props;
        saveGreenhouseSettings(enabled, key, harvestKey)
            .then(({ success, data }) => {
                if (success) {
                    const { apiKey, harvestApiKey, enabled: newEnabled } = data;
                    this.setState({
                        key: apiKey,
                        harvestKey: harvestApiKey || '',
                        enabled: newEnabled
                    });
                    flashMessage('Integration settings updated', 'done');
                } else {
                    flashMessage('Something went wrong', '');
                }
            })
            .catch(() => {
                flashMessage('Something went wrong');
            });
    };

    handleChangeSwitch = () => {
        const { enabled, key, harvestKey } = this.state;
        const { flashMessage } = this.props;
        const newKey = !key && !enabled ? uuidv4() : key;
        saveGreenhouseSettings(!enabled, newKey, harvestKey)
            .then(({ success, data }) => {
                if (success) {
                    const { apiKey, harvestApiKey, enabled: newEnabled } = data;
                    this.setState({
                        key: apiKey,
                        harvestKey: harvestApiKey || '',
                        enabled: newEnabled
                    });
                    flashMessage(`Integration ${newEnabled ? 'enabled' : 'disabled'}`, 'done');
                    SettingsEvents.GREENHOUSE_ENABLED({ enabled: newEnabled });
                    this.setState({ enabled: newEnabled });
                } else {
                    flashMessage('Something went wrong', '');
                }
            });
    };


    render() {
        const { classes } = this.props;
        const { harvestKey, key, harvestKeyError, enabled } = this.state;
        return (
            <div>
                <div className="u-mrg--bx2">
                    <TextInput
                        label="Greenhouse API key"
                        fullWidth
                        name="key"
                        type="text"
                        variant="outlined"
                        value={key}
                        className={classes.input}
                        disabled
                    />
                    <p className="u-txt--12">
                        Send the Greenhouse API key to Greenhouse support in order to enable this integration.
                    </p>
                    <Switch
                        label="Enable Greenhouse Integration"
                        checked={enabled}
                        value={enabled}
                        onChange={this.handleChangeSwitch}
                    />
                </div>
                <div>
                    <TextInput
                        label="Greenhouse Harvest API key"
                        fullWidth
                        name="harvestKey"
                        type="text"
                        variant="outlined"
                        className={classes.input}
                        isError={Boolean(harvestKeyError)}
                        helperText={harvestKeyError}
                        value={harvestKey}
                        onChange={this.handleChangeHarvestKey}
                    />
                    <p className="u-txt--12">
                        Please paste the Harvest API key you generated on Greenhouse in order to allow the grading of candidates.
                    </p>
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.saveButton}
                    onClick={this.saveGreenhouseSettings}
                >
                    Save
                </Button>
            </div>
        );
    }
}

export default withStyles(stylesJS)(GreenhouseContent);

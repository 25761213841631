import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { appCtx } from 'components/appStore';
import Coupon from './Coupon';
import SectionRow from './SectionRow';

import styles from './styles';

const CardDetails = observer(({
    subscriptionPlan,
    productPrice,
    defaultCouponString,
    appliedCoupon,
    applyCoupon,
    classes
}) => {
    if (!subscriptionPlan) return null;
    const { flashMessage, loggedUser } = useContext(appCtx);

    const planName = !productPrice ? subscriptionPlan.name : productPrice.name;
    const period = !productPrice ? subscriptionPlan.period : null;
    const subTotal = !productPrice ? subscriptionPlan.amount : productPrice.price / 100;
    const currency = !productPrice ? subscriptionPlan.currency : productPrice.currency;


    let totalAmount = +subTotal;

    if (appliedCoupon) {
        if (appliedCoupon.amount_off) {
            totalAmount -= parseInt(appliedCoupon.amount_off, 10) / 100;
        } else if (appliedCoupon.percent_off) {
            totalAmount *= 1 - (parseInt(appliedCoupon.percent_off, 10) / 100);
        }
    }

    totalAmount = Math.round(totalAmount * 100) / 100;
    const gst = loggedUser.country === 'Australia' ? +totalAmount / 10 : 0;
    totalAmount += gst;


    const getCurrencyLabel = curr => (curr === 'usd' ? '$' : `${curr.toUpperCase()} `);

    const currencyLabel = getCurrencyLabel(currency || '');

    const planSection = !productPrice ? (
        <div className={classes.planSection}>
            <SectionRow
                label="Plan"
                value={planName}
            />
            <SectionRow
                label="Subscription"
                value={period !== 'monthly' ? '12 Months' : '1 Month'}
            />
        </div>
    ) : (
        <div className={classes.planSection}>
            <SectionRow
                label={planName}
                value=""
            />
        </div>
    );

    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>Order Summary</div>
            <div className={classes.content}>
                {planSection}
                <div className={classes.planSection}>
                    <SectionRow
                        label="Sub total"
                        value={`${currencyLabel}${+subTotal}`}
                    />
                    <Coupon
                        className="u-mrg--bx2 u-mrg--tx2"
                        flashMessage={flashMessage}
                        applyCoupon={applyCoupon}
                        defaultCouponString={defaultCouponString}
                    />
                    {
                        appliedCoupon && (
                            <>
                                {
                                    appliedCoupon.amount_off && (
                                        <SectionRow
                                            label="Discount Coupon"
                                            value={`${getCurrencyLabel(appliedCoupon.currency)}${appliedCoupon.amount_off / 100}`}
                                        />
                                    )
                                }
                                {
                                    appliedCoupon.percent_off && (
                                        <SectionRow
                                            label="Discount Coupon"
                                            value={`${appliedCoupon.percent_off}%`}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                    {
                        Boolean(gst) && (
                            <SectionRow
                                label="GST (10%)"
                                value={`${currencyLabel}${gst}`}
                            />
                        )
                    }
                </div>
            </div>
            <div className={classes.totalWrapper}>
                <div className={classes.totalLabel}>Total</div>
                <div className="u-txt--up">
                    <span>{currency}</span>
                    <span className={classes.totalValue}>{currency === 'usd' ? currencyLabel : ''}{totalAmount}</span>
                </div>

            </div>
        </div>
    );
});

export default withStyles(styles)(CardDetails);

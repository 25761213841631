import React, { useState } from 'react';
import { withStyles, withTheme } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import SearchField from '../../inputs/SearchField';

const styles = theme => ({
    searchField: {
        background: theme.palette.grey[200],
        border: `2px solid ${theme.palette.grey[200]}`,
        borderRadius: 4,
        margin: 0,
        minWidth: 370,
        color: theme.palette.grey[900]
    },
    searchFieldFocused: {
        background: '#fff',
        border: `2px solid ${theme.palette.primary.main}`
    }
});

const CandidatesSearch = observer(({ classes, className = '', onChange, value, audition, disabled }) => {
    const [searchFocused, setSearchFocused] = useState(false);

    return (
        <SearchField
            placeholder="Search Candidates"
            onChange={e => onChange && onChange(e, audition)}
            className={clsx(classes.searchField, className, searchFocused && classes.searchFieldFocused)}
            value={value}
            disabled={disabled}
            onFocus={() => { setSearchFocused(true); }}
            onBlur={() => { setSearchFocused(false); }}
        />
    );
});

export default withTheme(withStyles(styles)(CandidatesSearch));

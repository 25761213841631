import React, { Component } from 'react';

// material components
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';

// local files and components
import TabsContainer from '../TabsConteiner';
import Icon from './icon.svg';

import styles from './styles';

class CodeSettings extends Component {
    state = {
        open: false,
        activeEditorMode: 0,
        activeTabSpacing: 0,
        autoBracket: false,
        tabSpaces: [
            { value: 'AUTO', spaces: 4 },
            { value: '2 SPACES', spaces: 2 },
            { value: '4 SPACES', spaces: 4 },
            { value: '8 SPACES', spaces: 8 }
        ],
        modes: [
            { value: 'SUBLIME' },
            { value: 'VIM' },
            { value: 'EMACS' }
        ]
    };

    handleToggle = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    changeEditorModes = (e, activeEditorMode) => {
        const { modes } = this.state;
        const { changeKeyMaps } = this.props;
        changeKeyMaps(modes[activeEditorMode].value.toLowerCase());
        this.setState({ activeEditorMode });
    };

    changeTabSpacing = (e, activeTabSpacing) => {
        const { changeTabSpacing } = this.props;
        const { tabSpaces } = this.state;
        changeTabSpacing(tabSpaces[activeTabSpacing].spaces);
        this.setState({ activeTabSpacing });
    };

    switchToggles = (event) => {
        const { changeAutoCloseBrackets } = this.props;
        changeAutoCloseBrackets(event.target.checked);
        this.setState({ autoBracket: event.target.checked });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const {
            open,
            modes,
            activeEditorMode,
            tabSpaces,
            activeTabSpacing,
            autoBracket
        } = this.state;
        const { classes } = this.props;
        return (
            <ClickAwayListener onClickAway={this.handleClose}>
                <div style={{ position: 'relative' }}>
                    <Button
                        buttonRef={(node) => {
                            this.anchorEl = node;
                        }}
                        aria-owns={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleToggle}
                    >
                        <img src={Icon} alt="*" />
                    </Button>
                    {
                        open && (
                            <Popper
                                style={{
                                    width: '380px',
                                    height: '280px',
                                    zIndex: '10000',
                                    padding: '22px',
                                    background: 'white',
                                    borderRadius: '7px',
                                    boxShadow: '0 17px 50px 0 rgba(0, 0, 0, 0.19)',
                                    position: 'absolute',
                                    top: '60px',
                                    left: '-300px'
                                }}
                                open
                                anchorEl={this.anchorEl}
                                transition
                                disablePortal
                            >
                                <div>
                                    <div className={classes.titleWrapper}>
                                        <Typography
                                            classes={{ root: classes.typeH3 }}
                                            variant="h4"
                                            component="h3"
                                        >
                                            Code Editor Settings
                                        </Typography>
                                        <svg
                                            style={{ cursor: 'pointer' }}
                                            onClick={this.handleClose}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path fill="#3a3c43" fillRule="evenodd" d="M10.94 12L3.97 5.03a.75.75 0 0 1 1.06-1.06L12 10.94l6.97-6.97a.75.75 0 0 1 1.06 1.06L13.06 12l6.97 6.97a.75.75 0 0 1-1.06 1.06L12 13.06l-6.97 6.97a.75.75 0 0 1-1.06-1.06L10.94 12z" />
                                        </svg>
                                    </div>
                                    <TabsContainer
                                        heading="EDITOR MODE"
                                        items={modes}
                                        styleTab={classes.tab__3}
                                        value={activeEditorMode}
                                        onChange={this.changeEditorModes}
                                    />
                                    <TabsContainer
                                        heading="TAB SPACING"
                                        items={tabSpaces}
                                        styleTab={classes.tab__4}
                                        value={activeTabSpacing}
                                        onChange={this.changeTabSpacing}
                                    />
                                    <SwitchBrandWithLabel
                                        style={{ marginTop: '10px' }}
                                        label="Auto-bracket completion"
                                        checked={autoBracket}
                                        onChange={this.switchToggles}
                                    />
                                </div>
                            </Popper>
                        )
                    }
                </div>
            </ClickAwayListener>
        );
    }
}

export default withStyles(styles)(CodeSettings);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    header: {
        padding: '2px 10px 2px 23px',
        borderBottom: '2px solid #F3F3F3',
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    contentNoShift: {
        marginRight: '0px !important'
    },
    loaderWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)'
    },
    wrapper: {
        width: '100%',
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginRight: ({ drawerWidth }) => -drawerWidth,
        position: 'relative'
    },
    answerWrapper: {
        padding: 23,
        minHeight: 300
    },
    backBtn: {
        minWidth: 'fit-content',
        marginLeft: 0,
        marginRight: 10
    }
}));

export default {
    listItem: {
        background: 'rgba(0, 0, 0, 0.1);',
        color: 'white',
        fontWeight: 'bold',
        borderBottomLeftRadius: '6px',
        borderTopLeftRadius: '6px',
        height: '47px',
        width: '100%'
    },
    listItemDisactive: {
        height: '47px',
        color: 'white',
        fontWeight: 'bold',
        background: 'transparent',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        width: '100%'
    },
    listItemDisactiveBottomEmpty: {
        height: '47px',
        color: 'white',
        fontWeight: 'bold',
        background: 'transparent',
        borderBottom: 'none',
        width: '100%'
    },
    list: {
        overflowY: 'auto',
        marginTop: 45,
        minWidth: 300,
        marginRight: 1,
        overflowX: 'hidden',
        minHeight: 260,
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 9,
        overflowY: 'auto',
        width: 'calc(100% - 300px)'
    },
    row: {
        minWidth: 660,
        overflowY: 'hidden',
        height: '100%',
        minHeight: 260,
        width: '100%'
    },
    cell: {
        background: 'rgba(0, 0, 0, 0.1)',
        fontSize: '14px',
        fontWeight: 600,
        color: '#ffffff',
        border: 'none',
        minHeight: 250,
        height: '100%',
        width: '25%',
        borderRight: '1px solid #2f3545',
        display: 'inline-block',
        lineHeight: 1.5,
        minWidth: 165,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    cellB: {
        paddingLeft: '10px',
        paddingTop: 13,
        whiteSpace: 'break-spaces'
    },
    cellH: {
        background: '#2f3545',
        border: 'none',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.9px',
        color: 'rgba(255, 255, 255, 0.37)',
        paddingLeft: '5px',
        lineHeight: 3,
        paddingTop: 0
    },
    wrapperStyle: {
        height: '100%',
        overflowY: 'hidden',
        overflowX: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        '& *': {
            fontFamily: 'Open Sans'
        }
    }
};

export default theme => ({
    typeWrapper: {
        width: 340,
        maxWidth: '100%'
    },
    summaryContent: {
        margin: 0,
        justifyContent: 'space-between'
    },
    expandIcon: {
        color: theme.palette.grey[400]
    },
    accordion: {
        boxShadow: 'none',
        '&$accordionExpanded': {
            '&:after': {
                content: '""',
                position: 'absolute',
                opacity: 1,
                bottom: -10,
                left: 0,
                right: 0,
                height: 1,
                transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundColor: 'rgba(0, 0, 0, 0.12)'
            },
            '&:before': {
                opacity: 1
            }
        }
    },
    accordionExpanded: {},
    summaryRoot: {
        color: theme.palette.grey[900],
        fontWeight: 700,
        paddingLeft: 0,
        '&$summaryDisabled': {
            backgroundColor: '#fff',
            opacity: 1
        }
    },
    summaryDisabled: {},
    checkboxLabel: {
        color: theme.palette.grey[900]
    },
    pointsLabel: {
        marginRight: 5,
        color: theme.palette.grey[500]
    },
    headerWrapper: {
        paddingBottom: 10,
        fontWeight: 700
    },
    ticketNumberColumn: {
        width: 100
    },
    headerColumnsWrapper: {
        width: 'calc(100% - 100px)'
    },
    pointsColumn: {
        width: 50,
        display: 'flex'
    },
    pointsColumnHeader: {
        paddingRight: 43
    },
    skillsColumn: {
        fontWeight: 400,
        fontSize: 12,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        maxWidth: 450,
        width: 'calc(100% - 65px)'
    }
});

export default theme => ({
    wrapper: {
        width: '100%',
        height: '100vh'
    },
    container: {
        padding: '0 24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        paddingBottom: '10vh'
    },
    vervoeLogo: {
        cursor: 'pointer',
        display: 'inline-block',
        padding: '22px 16px',
        position: 'fixed',
        top: 0
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontSize: 80,
        fontWeight: 'bold',
        lineHeight: '36px',
        marginBottom: 40,
        color: theme.palette.blue[600]
    },
    mainText: {
        maxWidth: 580,
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: '39px',
        textAlign: 'center',
        color: theme.palette.grey[900],
        marginBottom: 37,
        [theme.breakpoints.down('sm')]: {
            fontSize: 24
        }
    },
    additionalText: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.grey[700],
        '& p': {
            lineHeight: '28px',
            margin: 0
        }
    },
    link: {
        fontWeight: 'bold',
        color: theme.palette.blue[600]
    },
    icon: {
        width: 110,
        height: 110,
        marginBottom: 46,
        [theme.breakpoints.down('sm')]: {
            width: 60,
            height: 60
        }
    }
});

import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';

import { deactivateAssessment, getCandidatesInAssessment } from 'requests/AssessmentRequests';
import { hireCandidates } from 'requests/CandidatesRequests';
import { onDeactivateCallback } from 'helper/assessmentFunctions';
import { checkHasUserPermission, clone } from 'helper/commonFunctions';
import AssessmentEvents from 'events/AssessmentEvents';
import Dialog from 'libraries/Dialog';

import CandidateOption from './CandidateOption';
import HiringFlowDialog from '../../HiringFlowDialog';
import { appCtx } from '../../../appStore';

import styles from '../styles';

const TabPanel = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
    >
        {value === index && (
            <div>
                {children}
            </div>
        )}
    </div>
);

const a11yProps = index => (
    {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`
    }
);


const SelectHiredDialog = observer(({
    classes, open, onClose, assessment, onSuccess
}) => {
    const { flashMessage, company } = useContext(appCtx);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [shortlistCandidates, setShortlistCandidates] = useState([]);
    const [shortlistQuantity, setShortlistQuantity] = useState(false);
    const [allCandidates, setAllCandidates] = useState([]);
    const [allQuantity, setAllQuantity] = useState(null);
    const [hiredCandidates, setHiredCandidates] = useState([]);
    const [nextStepsDialogOpened, setNextStepsDialogOpened] = useState(false);

    const { uuid, name, slug, permissions } = assessment;
    const hire = checkHasUserPermission(company, permissions, 'hire');

    useEffect(() => {
        if (open) {
            loadCandidates(true);
            loadCandidates();
        }
    }, [open]);

    const loadCandidates = (shortlist = false) => {
        setLoading(true);
        getCandidatesInAssessment(slug,
            1, '', shortlist ? 'shortlist' : '', '',
            shortlist ? 'shortlist' : '', 'asc')
            .then(({ success, data }) => {
                if (success && data) {
                    const { items, total } = data;
                    if (shortlist) {
                        if (!items.length) setValue(1);
                        setShortlistCandidates(items);
                        setShortlistQuantity(total);
                    } else {
                        setAllCandidates(items);
                        setAllQuantity(total);
                    }
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const segmentData = {
        ttId: uuid,
        ttName: name,
        candidatesHired: true
    };

    const handleDeactivate = (skip = false) => {
        onDeactivateCallback(deactivateAssessment(slug))
            .then(({ success }) => {
                if (success && onSuccess) {
                    if (skip) {
                        onSuccess();
                        AssessmentEvents.HIRE_SURVEY_SKIPPED(segmentData);
                    }
                    flashMessage(`${hiredCandidates.length ? 'Candidates hired and a' : 'A'}ssessment closed`, 'done');
                }
            })
            .finally(() => {
                onClose();
            });
    };

    const handleSubmit = () => {
        setLoading(true);
        const columnUserIds = hiredCandidates.map(candidate => candidate.columnUserId);

        hireCandidates(slug, columnUserIds)
            .then(({ data, success }) => {
                if (data && success) {
                    if (hire) {
                        handleNextStepsDialogOpened();
                        setTimeout(onClose, 400);
                        segmentData.numberCandidatesHired = hiredCandidates.length;
                        segmentData.candidateId = hiredCandidates.map(candidate => candidate.candidateId);
                        AssessmentEvents.HIRE_SURVEY(segmentData);
                    } else {
                        handleDeactivate();
                        onClose();
                    }
                }
            }).catch(() => {
                onClose();
                flashMessage('Something went wrong', 'error');
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleHired = (candidate) => {
        const index = hiredCandidates.findIndex(item => candidate.candidateId === item.candidateId);
        if (index !== -1) {
            const newCandidates = clone(hiredCandidates);
            newCandidates.splice(index, 1);
            setHiredCandidates(newCandidates);
        } else {
            setHiredCandidates([...hiredCandidates, candidate]);
        }
    };

    const handleNextStepsDialogOpened = (skip = false) => {
        if (nextStepsDialogOpened) {
            handleDeactivate(skip);
        }
        setNextStepsDialogOpened(!nextStepsDialogOpened);
    };

    const updateUserFields = (updatedFields) => {
        setHiredCandidates(hiredCandidates.map(candidate => ({ ...candidate, ...updatedFields })));
    };

    return (
        <>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={onClose}
                handleClose={onClose}
                titleComponent="Who did you hire?"
                actionComponent={(
                    <div className={classes.buttonContainer}>
                        <Button
                            className={classes.secondaryNoBorderButton}
                            onClick={() => handleDeactivate(true)}
                        >
                            Skip and close assessment
                        </Button>
                        <Button
                            className={clsx(classes.mainButton, !hiredCandidates.length && classes.greyButton)}
                            color="primary"
                            variant="contained"
                            disabled={!hiredCandidates.length || loading}
                            onClick={handleSubmit}
                        >
                            Next
                        </Button>
                    </div>
                )}
            >
                {
                    loading ? (
                        <div className="u-txt--center">
                            <CircularProgress size={20} />
                        </div>
                    ) : (
                        <>
                            <Tabs
                                value={value}
                                textColor="primary"
                                onChange={handleChangeTab}
                                aria-label="tabs"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                            >
                                <Tab
                                    className={classes.tab}
                                    label={(
                                        <span>
                                            Shortlisted&nbsp;
                                            <span className={classes.count}>({shortlistQuantity})</span>
                                        </span>
                                    )}
                                    {...a11yProps(0)}
                                    disabled={!shortlistCandidates.length}
                                />
                                <Tab
                                    className={classes.tab}
                                    label={(
                                        <span>
                                            All candidates&nbsp;
                                            <span className={classes.count}>({allQuantity})</span>
                                        </span>
                                    )}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                            <div className={classes.optionsContainer}>
                                <TabPanel value={value} index={0}>
                                    {shortlistCandidates.map(candidate => (
                                        <CandidateOption key={candidate.candidateId} {...{ candidate, handleHired, hiredCandidates }} />
                                    ))}
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    {allCandidates.map(candidate => (
                                        <CandidateOption key={candidate.candidateId} {...{ candidate, handleHired, hiredCandidates }} />
                                    ))}
                                </TabPanel>
                            </div>
                        </>
                    )
                }
            </Dialog>
            <HiringFlowDialog
                open={nextStepsDialogOpened}
                onClose={handleNextStepsDialogOpened}
                candidates={hiredCandidates}
                assessment={assessment}
                updateUserFields={updateUserFields}
                onSuccessClose={onSuccess}
            />
        </>
    );
});

export default withStyles(styles)(withRouter(SelectHiredDialog));

export default theme => ({
    wrapper: {
        height: 320,
        [theme.breakpoints.down('md')]: {
            height: 'auto'
        }
    },
    notificationWrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    header: {
        padding: '20px 24px 11px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    additionalInfoOverride: {
        padding: '14px 6px 20px 25px'
    },
    activateButton: {
        color: theme.palette.yellow.primary,
        fontWeight: 700,
        '&:hover': {
            backgroundColor: theme.palette.yellow[50]
        }
    },
    infoRow: {
        paddingLeft: 25,
        paddingRight: 25
    },
    notificationRow: {
        paddingLeft: 25,
        paddingRight: 25
    },
    progress: {
        color: theme.palette.green[600],
        margin: 0,
        marginRight: 20
    },
    insightsButton: {
        width: 34,
        height: 34,
        marginRight: 0
    },
    label: {
        color: '#ffffff',
        background: theme.palette.grey[400],
        borderRadius: 4,
        padding: '5px 10px',
        marginRight: 10
    },
    openLabel: {
        background: theme.palette.green[600]
    },
    countTitle: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '25px',
        marginRight: 5
    },
    skillLabel: {
        textTransform: 'capitalize',
        borderWidth: 1
    },
    contentBody: {
        position: 'relative'
    }
});

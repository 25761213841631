import React from 'react';
import { observer } from 'mobx-react-lite';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';

import useStyles from './styles';


const ScoreFilterToggle = observer(({ onToggle, checked }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <SwitchBrandWithLabel
                classNameLabel={classes.switchLabel}
                className={classes.switchRoot}
                label="Custom Score Filter"
                onChange={onToggle}
                checked={checked}
            />
        </div>
    );
});

export default ScoreFilterToggle;

import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import TableRow from '@mui/material/TableRow';
import GeneralEvents from 'events/GeneralEvents';

import CandidatePhoto from 'components/photos/candidate_photo';
import TableCellCustom from 'components/table_components/table_cell';
import { appCtx } from 'components/appStore';
import CandidateMenu from 'components/candidate_cards/CandidateActions/CandidateMenu';

import Tooltip from 'libraries/Tooltip';

import { checkHasCompanyFeature, checkHasUserPermission } from 'helper/commonFunctions';
import { BATCH_ACTIONS, SCORE_TYPE_AI } from 'helper/constants';

import { assessmentSettingsCtx } from 'pages/assessment/store';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { archivedCtx } from '../../archivedStore';

import { statsCtx } from '../../../../statsStore';

import useStyles from './styles';


const CandidateRow = observer(({ candidate, history }) => {
    const [shownBatch, setShownBatch] = useState(false);
    const classes = useStyles();
    const {
        score, candidateId, candidateUuid,
        fullName, completionsPerOpportunityLimitExceeded,
        scoreType, email, candidateStatus,
        candidateStatusActualDate, completed
    } = candidate;

    const {
        selectedCandidates, toggleSelectedUserAuditionIds,
        loadCandidates
    } = useContext(archivedCtx);

    const { audition } = useContext(assessmentSettingsCtx);
    const { loadAssessmentStats } = useContext(statsCtx);
    const { company } = useContext(appCtx);

    const callback = () => {
        loadCandidates();
        loadAssessmentStats();
    };

    if (!audition) return null;

    const { permissions, uuid: ttId, name: ttName, slug } = audition;

    const edit = checkHasUserPermission(company, permissions, 'edit');

    const dataForEvents = {
        candidateId: candidateUuid,
        context: 'archive page',
        ttId,
        ttName,
        score: score ? score.score : undefined,
        scoreIsAI: score ? score.type === SCORE_TYPE_AI : undefined,
        scoreType
    };

    const hoverable = candidateStatus === 'Hired' || completed;

    const goToCard = () => {
        if (!hoverable) return;
        GeneralEvents.CANDIDATE_VIEWED(dataForEvents);
        history.push(`/script/select/${slug}/candidate/${candidateId}`);
    };

    const showBatch = () => {
        if (!edit) return;
        setShownBatch(true);
    };

    const hideBatch = () => setShownBatch(false);

    const onBatchClick = (e) => {
        e.stopPropagation();
        toggleSelectedUserAuditionIds(candidate);
    };

    const hasCompanyFeature = checkHasCompanyFeature(company, BATCH_ACTIONS);

    return (
        <TableRow
            className={clsx(hoverable && classes.tableRow)}
            onMouseEnter={showBatch}
            onMouseLeave={hideBatch}
            hover={hoverable}
            onClick={goToCard}
        >
            <TableCellCustom className={classes.tableCell} align="left">
                <div className="u-dsp--centered u-jc--start">
                    <CandidatePhoto
                        user={candidate}
                        onClick={onBatchClick}
                        checked={selectedCandidates[candidateId]}
                        shownBatch={shownBatch && hasCompanyFeature && !completionsPerOpportunityLimitExceeded}
                        setShownBatch={setShownBatch}
                    />
                    <TruncateWithTooltip text={fullName} />
                </div>
            </TableCellCustom>
            <TableCellCustom className={classes.tableCell} align="left">
                <TruncateWithTooltip text={email} />
            </TableCellCustom>
            <TableCellCustom className={classes.tableCell} align="left">
                {candidateStatus}
                <Tooltip
                    label={<Moment format="MMM D, YYYY">{candidateStatusActualDate}</Moment>}
                >
                    <span className={classes.statusDate}>
                        <Moment fromNow>
                            {candidateStatusActualDate}
                        </Moment>
                    </span>
                </Tooltip>
            </TableCellCustom>
            <TableCellCustom className={classes.tableCell} align="right">
                <CandidateMenu
                    candidate={candidate}
                    callback={callback}
                    dataForEvents={dataForEvents}
                    shownOptions={['message', 'unarchive']}
                    audition={audition}
                    company={company}
                />
            </TableCellCustom>
        </TableRow>
    );
});
export default withRouter(CandidateRow);

export default theme => ({
    switch: {
        marginBottom: 0,
        marginRight: 0,
        marginTop: 3
    },
    text: {
        marginTop: 40
    },
    condition: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 30,
        marginBottom: 30,
        fontWeight: 600,
        '&> *': {
            margin: 6
        }
    },
    conditionLabel: {
        fontWeight: 600,
        padding: '14px 16px',
        backgroundColor: theme.palette.grey[300],
        borderRadius: 4,
        border: '2px solid rgba(0,0,0,0)',
        maxWidth: 450,
        textAlign: 'center',
        whiteSpace: 'pre-wrap'
    },
    greenLabel: {
        backgroundColor: theme.palette.green[50],
        padding: '14px 42px'
    },
    redLabel: {
        backgroundColor: theme.palette.red[200],
        padding: '14px 42px'
    },
    activatedLabel: {
        backgroundColor: 'rgba(0,0,0,0)',
        padding: '14px 16px',
        borderRadius: 4,
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        maxWidth: 450,
        textAlign: 'center'
    },
    percentageInput: {
        minHeight: 75,
        width: 150,
        margin: '6px 6px -14px'
    },
    select: {
        maxWidth: 350,
        backgroundColor: '#fff',
        whiteSpace: 'pre-wrap',
        '& *': {
            whiteSpace: 'pre-wrap'
        }
    },
    icon: {
        padding: 0,
        height: 34,
        width: 34,
        margin: '3px'
    },
    closeIcon: {
        transform: 'rotate(45deg)'
    },
    arrowIcon: {
        '& img': {
            width: 15
        }
    },
    footerBlock: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    flip: {
        transform: 'scaleX(-1)'
    }
});

import React from 'react';
import clsx from 'clsx';
import { withStyles, withTheme } from '@mui/styles';
import { secondsToTimeObject, timeObjectToFormattedString } from 'helper/commonFunctions';
import TimerIcon from 'components/icons/TimerIcon';

import stylesJS from './styles';

const QuestionCompletedInLabel = ({ classes, className = '', question: { timeSpent, isRunOutOfTime, skipped, auditionTimerEnabled } }) => {
    const renderQuestionCompletedInLabel = () => {
        if (isRunOutOfTime) {
            return 'Candidate ran out of time';
        }
        if (timeSpent === 0 && skipped) {
            return 'Question skipped';
        }
        return auditionTimerEnabled ? '' : `Question completed in ${timeObjectToFormattedString(secondsToTimeObject(timeSpent))}`;
    };

    if (timeSpent !== undefined && timeSpent !== null) {
        return (
            <p className={clsx(classes.questionTimerWrapper, className)}>
                {
                    !auditionTimerEnabled && (
                        <TimerIcon
                            className={classes.timerIcon}
                            color="#757575"
                        />
                    )
                }
                { renderQuestionCompletedInLabel() }
            </p>
        );
    }

    return null;
};

export default withTheme(withStyles(stylesJS)(QuestionCompletedInLabel));

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import CandidatesCount from 'components/table_components/candidates_count';
import BoardNoContent from 'components/board/board_no_content';

import { CANDIDATES_PER_RESULTS_PAGE } from 'helper/constants';
import CandidatesTable from './components/CandidatesTable';
import SearchFilterBlock from '../../../components/SearchFilterBlock';
import NotFoundContent from '../../../../../components/assessments_pages/NotFoundContent';
import BatchBlock from '../../../components/BatchBlock';
import TagsList from '../../../components/TagsList';
import { invitationsCtx } from '../../invitationsStore';
import { statsCtx } from '../../../statsStore';
import EmptyIcon from './icon.svg';
import InviteTableFilter from '../InviteTableFilter';

const useStyles = makeStyles(theme => ({
    board: {
        minHeight: 330,
        paddingTop: 0,
        paddingBottom: 70
    },
    sortLabel: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginTop: 6,
        marginLeft: 15,
        whiteSpace: 'nowrap'
    },
    title: {
        whiteSpace: 'nowrap',
        marginRight: 12
    }
}));

const InviteCandidatesList = observer(() => {
    const {
        totalCount, allCandidates,
        selectedCandidates, query,
        page, isLoading, isLoadingLocal,
        filterCategory, filterSourceCategory,
        handleChangeFilterSelect,
        handleRemoveFilterSelect,
        clearSelection, fetchInvitationStats,
        filterTagsCategory,
        selectedUserAuditionsIds,
        selectedColumnUserIds, invitationsStats,
        selectedCandidatesIds, loadCandidates
    } = useContext(invitationsCtx);

    const { loadFullStats, loadExtensionsRequests } = useContext(statsCtx);

    const classes = useStyles();

    const onBatchSuccess = () => {
        loadFullStats();
        loadExtensionsRequests();
        fetchInvitationStats();
    };

    const noBoardFilterName = (filterCat) => {
        if (!Object.keys(filterCat)[0]) return;
        const name = Object.keys(filterCat)[0];
        switch (name) {
            case 'moreTimeRequested': return 'More time requested';
            case 'link': return 'Invited via link';
            case 'email': return 'Invited via email';
            case 'inProgress': return 'In progress';
            default: return name[0].toUpperCase() + name.slice(1);
        }
    };

    const returnNoContentBoard = () => {
        if (totalCount || isLoadingLocal) return null;
        if (query) return <NotFoundContent searchValue={query} />;
        if (Object.keys(filterCategory).length) {
            return (
                <BoardNoContent
                    header={`No ${noBoardFilterName(filterCategory)} Candidates`}
                    buttonLabel="Review all candidates"
                    hideIconWrapper
                    className={classes.board}
                    onClick={() => handleChangeFilterSelect('all', false, false)}
                    icon={<img src={EmptyIcon} alt="empty-content" />}
                />
            );
        }
        if (Object.keys(filterSourceCategory).length) {
            return (
                <BoardNoContent
                    header={`No ${noBoardFilterName(filterSourceCategory)} Candidates`}
                    buttonLabel="Review all candidates"
                    hideIconWrapper
                    className={classes.board}
                    onClick={() => handleRemoveFilterSelect(Object.keys(filterSourceCategory)[0], false, true)}
                    icon={<img src={EmptyIcon} alt="empty-content" />}
                />
            );
        }
        return (
            <BoardNoContent
                header="No Candidates"
                hideIconWrapper
                className={classes.board}
                icon={<img src={EmptyIcon} alt="empty-content" />}
            />
        );
    };

    return (
        <div>
            {
                Object.keys(selectedCandidates).length ? (
                    <BatchBlock
                        isInviteSection
                        {...{
                            clearSelection,
                            selectedCandidates,
                            selectedUserAuditionsIds,
                            selectedCandidatesIds,
                            selectedColumnUserIds,
                            loadCandidates,
                            onSuccess: onBatchSuccess
                        }}
                    />
                ) : (
                    <SearchFilterBlock
                        context={invitationsCtx}
                        hideTableSort
                        TableFilterComp={<InviteTableFilter />}
                    />
                )
            }
            <div className="u-dsp--f u-ai--start u-jc--sb">
                <div className="u-dsp--f u-mrg--bx2 u-ai--start">
                    <div className="u-dsp--f u-ai--baseline">
                        <Typography variant="h5" className={classes.title}>
                            Candidates
                        </Typography>
                        {
                            !isLoading && (
                                <CandidatesCount
                                    className="u-mrg--bx0"
                                    candidatesPerPage={CANDIDATES_PER_RESULTS_PAGE}
                                    page={page}
                                    candidatesLength={totalCount}
                                    candidatesFound={allCandidates.length}
                                    label="Invited Candidates"
                                />
                            )
                        }
                    </div>
                    <TagsList {...{
                        filterSourceCategory,
                        filterCategory,
                        filterTagsCategory,
                        handleRemoveFilterSelect,
                        handleChangeFilterSelect,
                        invitationsStats
                    }}
                    />
                </div>
            </div>
            <CandidatesTable />
            { returnNoContentBoard() }
        </div>
    );
});

export default InviteCandidatesList;

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BookmarkIcon from 'components/icons/BookmarkIcon';
import DocsIcon from 'components/icons/DocsIcon';

import CloseIcon from 'img/close.svg';

import { observer } from 'mobx-react-lite';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import QuestionSetContent from './QuestionSetContent';
import SavedQuestionContent from './SavedQuestionContent';

import styles from './styles';

const SetDrawer = observer(({
    classes, toggleDrawer, open, selectedSet,
    selectedSkill, setSelectedSet, getAssessment,
    ...other
}) => {
    const [selectedTabValue, setSelectedTabValue] = useState('sets');

    useEffect(() => {
        if (!open) {
            setSelectedTabValue('sets');
        }
    }, [open]);

    const handleChangeTabs = (event, newValue) => {
        setSelectedTabValue(newValue);
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer}
            classes={{ paper: classes.drawerPaper }}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
                style: { position: 'absolute' }
            }}
            variant="persistent"
        >
            <div className={classes.headerWrapper}>
                <IconButton className={classes.closeIcon} onClick={toggleDrawer} size="large">
                    <img src={CloseIcon} alt="close" />
                </IconButton>
                <Typography className="u-dsp--f" variant="h4">
                    Add Question&nbsp;
                    {
                        selectedSkill ? (
                            <TruncateWithTooltip
                                width={430}
                                className={classes.skillName}
                                capitalize
                                text={`- ${selectedSkill.title}`}
                            />
                        ) : null
                    }
                </Typography>
            </div>
            <div className={classes.tabsWrapper}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={selectedTabValue}
                    classes={{
                        root: classes.tabs,
                        indicator: classes.indicator,
                        scrollButtons: classes.scrollButtons,
                        flexContainer: classes.flexContainer
                    }}
                    onChange={handleChangeTabs}
                >
                    <Tab
                        label={<><DocsIcon /> Question sets</>}
                        value="sets"
                        classes={{
                            root: classes.tab,
                            wrapper: classes.tabWrapper
                        }}
                    />
                    {
                        selectedSkill && (
                            <Tab
                                label={<><BookmarkIcon /> Saved questions</>}
                                value="saved"
                                classes={{
                                    root: classes.tab,
                                    wrapper: classes.tabWrapper
                                }}
                            />
                        )
                    }
                </Tabs>
            </div>
            <div className={classes.questionWrapper}>
                {
                    selectedTabValue === 'sets' && (
                        <QuestionSetContent
                            openDrawer={open}
                            selectedSkill={selectedSkill}
                            setSelectedSet={setSelectedSet}
                            getAssessment={getAssessment}
                        />
                    )
                }
                {
                    selectedTabValue === 'saved' && (
                        <SavedQuestionContent
                            openDrawer={open}
                            selectedSet={selectedSet}
                            selectedSkill={selectedSkill}
                            setSelectedSet={setSelectedSet}
                            getAssessment={getAssessment}
                            {...other}
                        />
                    )
                }
            </div>
        </Drawer>
    );
});

export default withStyles(styles)(SetDrawer);

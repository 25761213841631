import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import TextInput from 'libraries/TextInput';
import { deleteCompanyTag, changeCompanyTag } from 'requests/SettingsRequests';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { capitalizeEachFirstLetter } from 'helper/commonFunctions';
import SettingsEvents from 'events/SettingsEvents';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import ActionDialog from 'components/dialogs/ActionDialog';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        display: 'inline-block',
        borderRadius: 100,
        padding: '7px 50px 7px 15px',
        fontWeight: 700,
        fontSize: 13,
        textTransform: 'capitalize',
        color: theme.palette.grey[800],
        border: `2px solid ${theme.palette.grey[300]}`,
        marginBottom: 19,
        marginRight: 12,
        maxWidth: 200
    },
    menu: {
        position: 'absolute',
        right: 4,
        top: 0.5
    }
}));

const TagItem = observer(({ tag: { id, title }, removeTag, renameTag }) => {
    const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
    const [renameDialogOpened, setRenameDialogOpened] = useState(false);
    const [isDeleteLoading, setDeleteLoading] = useState(false);
    const [isRenameLoading, setRenameLoading] = useState(false);
    const [value, setValue] = useState(capitalizeEachFirstLetter(title));
    const { wrapper, menu } = useStyles();
    const { flashMessage } = useContext(appCtx);

    const handleDeleteDialog = () => {
        setDeleteDialogOpened(!deleteDialogOpened);
    };

    const handleRenameDialog = () => {
        const newValue = !renameDialogOpened;
        if (newValue) {
            setValue(capitalizeEachFirstLetter(title));
        }
        setRenameDialogOpened(newValue);
    };

    const handleChange = (e) => {
        const { value: eValue } = e.target;
        setValue(eValue);
    };

    const onDelete = () => {
        setDeleteLoading(true);
        deleteCompanyTag(id)
            .then(() => {
                handleDeleteDialog();
                SettingsEvents.CANDIDATE_TAG_REMOVED({ tagId: id, tagName: title });
                flashMessage(`${title} Tag deleted`, 'done');
                removeTag();
            })
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    const onRename = () => {
        setRenameLoading(true);
        changeCompanyTag(id, { title: value })
            .then(() => {
                handleRenameDialog();
                SettingsEvents.CANDIDATE_TAG_RENAMED({ tagId: id, newTagName: value, oldTagName: title });
                flashMessage('Tag renamed', 'done');
                renameTag(value);
            })
            .catch(({ response }) => {
                if (response.data && response.data.errors) {
                    const { errors } = response.data;
                    if (typeof errors === 'string') {
                        flashMessage(errors);
                    }
                }
            })
            .finally(() => {
                setRenameLoading(false);
            });
    };

    return (
        <>
            <div key={id} className={wrapper}>
                <TruncateWithTooltip capitalize text={title} width={147} />
                <BoardItemMenu
                    options={[{
                        label: 'Rename',
                        onClick: handleRenameDialog
                    }, {
                        label: 'Delete Tag',
                        onClick: handleDeleteDialog
                    }]}
                    iconMenuClassName={menu}
                />
            </div>
            <ActionDialog
                open={deleteDialogOpened}
                onClose={handleDeleteDialog}
                buttonLabel="Delete Tag"
                titleComponent="Delete Tag"
                isLoading={isDeleteLoading}
                onClick={onDelete}
            >
                Are you sure you want to delete this tag and all associated data?
            </ActionDialog>
            <ActionDialog
                open={renameDialogOpened}
                onClose={handleRenameDialog}
                buttonLabel="Update"
                titleComponent="Rename Tag"
                isLoading={isRenameLoading}
                onClick={onRename}
                disabledButton={!value || !value.trim().length}
            >
                This action will update the tag name in all locations
                <TextInput
                    placeholder="Tag name"
                    value={value}
                    maxLength={150}
                    variant="outlined"
                    onChange={handleChange}
                />
            </ActionDialog>
        </>
    );
});

export default TagItem;

import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';

import Checkbox from 'libraries/Checkbox';
import UpgradeToUnlockTooltip from 'components/tooltips/UpgradeToUnlockTooltip';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import EditPanelSection from '../EditPanelSection';
import { appCtx } from '../../../appStore';

const styles = () => ({
    disabledCheckbox: {
        opacity: 0.3,
        cursor: 'default',
        userSelect: 'none'
    },
    tooltip: {
        position: 'absolute',
        top: -30,
        left: -70,
        width: 140
    },
    checkboxWrapper: {
        width: 'fit-content'
    }
});

let timerId = null;

const TTSettings = ({ settings, changeAssessmentSettings, isCMS, classes }) => {
    const [form, setForm] = useState({});
    const [errorForm, setErrorForm] = useState({ name: '', description: '' });

    const { company } = useContext(appCtx);
    const hasQuestionRandomizationFeature = checkHasCompanyFeature(company, 'QUESTION_RANDOMIZATION');

    useEffect(() => {
        initFormData(settings);
    }, [settings]);

    const handleChangeAutoGradePart = (e, inputName = false) => {
        const { name, value, checked } = e.target;
        const newForm = {};
        const newErrorForm = {};

        if (inputName) {
            newForm[inputName] = checked;
        } else {
            let newValue = parseInt(value, 10);
            if (Number.isNaN(newValue) || newValue <= 0) {
                newValue = 1;
            }
            newForm[name] = newValue;
        }

        clearTimeout(timerId);
        timerId = setTimeout(() => {
            changeAssessmentSettings(newForm);
        }, 300);
        setForm({ ...form, ...newForm });
        setErrorForm({ ...errorForm, ...newErrorForm });
    };

    const initFormData = (audition) => {
        const {
            randomiseQuestions, randomiseAnswers,
            limitSize, scoreType, eeocEnabled
        } = audition;

        setForm({
            scoreType,
            limitSize: limitSize === null ? 10 : limitSize,
            randomiseAnswers,
            randomiseQuestions,
            eeocEnabled
        });
    };

    return (
        <EditPanelSection className="u-pdn--tx0 u-brdr--none u-pos--rel">
            {(!hasQuestionRandomizationFeature && !form.randomiseQuestions) ? (
                <UpgradeToUnlockTooltip tooltipClassName={classes.tooltip}>
                    <div className={classes.checkboxWrapper}>
                        <Checkbox
                            label="Randomize question order for each candidate"
                            checked={false}
                            className={clsx('u-mrg--bx2', isCMS && 'u-mrg--tx4', classes.disabledCheckbox)}
                        />
                    </div>
                </UpgradeToUnlockTooltip>
            ) : (
                <Checkbox
                    label="Randomize question order for each candidate"
                    checked={Boolean(form.randomiseQuestions)}
                    onChange={(e) => { handleChangeAutoGradePart(e, 'randomiseQuestions'); }}
                    className={clsx('u-mrg--bx2', isCMS && 'u-mrg--tx4')}
                />
            )}
            <Checkbox
                className="u-mrg--bx1"
                label="Randomize answers' order for multiple choice answers"
                checked={Boolean(form.randomiseAnswers)}
                onChange={(e) => { handleChangeAutoGradePart(e, 'randomiseAnswers'); }}
            />
        </EditPanelSection>
    );
};

export default withStyles(styles)(withRouter(TTSettings));

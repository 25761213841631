export default theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 15
    },
    button: {
        backgroundColor: '#fff',
        padding: 3,
        marginRight: 5,
        border: `2px solid ${theme.palette.grey[300]}`
    }
});

import React, { useState } from 'react';
import TextInputOutlined from 'libraries/TextInput';
import { returnHelperTextForArray } from 'helper/codeChallenge';

const TwoDimensionArrayOutput = ({ type, value, onChangeValue }) => {
    const [rowsNumber, setRowsNumber] = useState(value ? value.split('\n').length : 1);

    let values = value ? value.split('\n') : [''];

    if (values.length < rowsNumber) {
        const difference = rowsNumber - values.length;

        // next line does not work without Array.apply
        // eslint-disable-next-line
        const additionalRows = Array.apply(null, { length: difference }).map(() => '');
        values.push(...additionalRows);
    }

    if (values.length > rowsNumber) {
        values = values.slice(0, rowsNumber);
    }

    const handleChangeRows = (e) => {
        if (+e.target.value > 20 || +e.target.value < 0) return;
        setRowsNumber(+e.target.value);
        onChangeValue(values.filter((val, i) => i < +e.target.value).join('\n'));
    };

    const handleChange = (e, valueIndex) => {
        onChangeValue(values.map((val, i) => (i === valueIndex ? e.target.value : val)).join('\n'));
    };

    return <>
        <div className="u-dsp--f u-jc--start u-ai--start">
            <TextInputOutlined
                label="Row Count"
                className="u-mrg--rx4"
                style={{ maxWidth: 130 }}
                variant="outlined"
                value={rowsNumber}
                type="number"
                min={1}
                max={20}
                maxLength={2}
                onChange={handleChangeRows}
            />
        </div>
        {values.map((inputValue, index) => (
            <TextInputOutlined
                key={index}
                label={`Row ${index + 1}`}
                variant="outlined"
                value={values[index]}
                onChange={e => handleChange(e, index)}
                helperText={returnHelperTextForArray(type)}
            />
        ))}
    </>;
};

export default TwoDimensionArrayOutput;

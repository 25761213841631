import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Moment from 'react-moment';

import BoardItem from 'components/board/board_item';
import { SCORE_TYPE_AI } from 'helper/constants';
import Actions from 'pages/assessment/results_assessment/common/CandidateActions';
import { resultsCtx } from 'pages/assessment/results_assessment/store';
import GeneralEvents from 'events/GeneralEvents';


import { addCandidateToCompare, removeCandidateFromCompare } from 'requests/AssessmentRequests';

import ScoreContainer from 'components/candidate_cards/ScoreContainer';
import { candidateResultCtx } from '../../common/CandidateListWrapper/candidatesStore';

import { assessmentSettingsCtx } from '../../../store';
import { statsCtx } from '../../../statsStore';
import CompareButton from '../CompareButton';
import ShortlistCardHeader from '../ShortlistCardHeader';

import styles from './styles';
import { shortlistCtx } from '../shortlistStore';

const ShortlistCard = observer(({
    match, history, candidate,
    classes, onRemoveFromShortlist
}) => {
    const { card, container, wrapperBorder,
        iconHeader, scrolledContainer,
        footer, footerText, dateLabel, shortLister,
        footerDescription, additionalText
    } = classes;

    const { audition, companyTags } = useContext(assessmentSettingsCtx);
    const { loadAssessmentStats } = useContext(statsCtx);
    const { isEmployerSortEnabled, loadCandidates, filterTagsCategory, loadFiltersStats } = useContext(candidateResultCtx);
    const { addCandidateTab } = useContext(resultsCtx);
    const { candidatesIdsToCompare, addCandidateCompare, removeCandidateCompare } = useContext(shortlistCtx);

    const { scoreType, uuid: ttId, name: ttName, canCompareCandidates, slug } = audition;

    const {
        fullName, score, shortlistedBy, shortlistedAt, rank,
        candidateUuid, candidateId, employerScore, candidateStatus
    } = candidate;

    let shortListerName = '';

    if (shortlistedBy) {
        shortListerName = shortlistedBy.fullName;
    }

    const scoreObj = isEmployerSortEnabled ? employerScore : score;

    const dataForEvents = {
        candidateId: candidateUuid,
        context: 'shortlist',
        ttId,
        ttName,
        score: scoreObj ? scoreObj.score : undefined,
        scoreIsAI: scoreObj ? scoreObj.type === SCORE_TYPE_AI : false,
        scoreType,
        rank,
        candidateHired: candidateStatus === 'Hired'
    };

    const openTab = () => {
        const { scriptSlug } = match.params;
        GeneralEvents.CANDIDATE_VIEWED(dataForEvents);
        addCandidateTab(candidateId, fullName, scriptSlug);
        history.push(`/script/select/${scriptSlug}/candidate/${candidateId}`);
    };

    const isCompareActive = `+${candidateId}` in candidatesIdsToCompare;

    const onCompareClick = async (e) => {
        e.stopPropagation();
        if (isCompareActive) {
            await removeCandidateFromCompare(slug, +candidateId);
            removeCandidateCompare(`+${candidateId}`);
        } else {
            await addCandidateToCompare(slug, +candidateId);
            addCandidateCompare(`+${candidateId}`, fullName);
        }
    };

    const onRemove = () => {
        removeCandidateCompare(`+${candidateId}`);
        onRemoveFromShortlist();
    };

    return (
        <BoardItem className={card} onClick={openTab}>
            <div className={container}>
                <div className={iconHeader}>
                    <Actions
                        candidate={{ ...candidate, score: scoreObj }}
                        companyTags={companyTags}
                        dataForEvents={dataForEvents}
                        onRemoveFromShortlist={onRemove}
                        loadCandidates={loadCandidates}
                        loadFiltersStats={loadFiltersStats}
                        loadAssessmentStats={loadAssessmentStats}
                        filterTagsCategory={filterTagsCategory}
                    />
                </div>
                <ShortlistCardHeader
                    candidate={candidate}
                />
            </div>
            <div className={wrapperBorder}>
                <div className={container}>
                    <ScoreContainer
                        smallCard
                        className={scrolledContainer}
                        candidate={{ ...candidate, score: scoreObj }}
                    />
                </div>
            </div>
            <div className={footer}>
                <div className={footerDescription}>
                    <div className={footerText}>
                        <span>Shortlisted</span> <span className={additionalText}>by</span>
                        <span className={shortLister}>{shortListerName}</span>
                    </div>
                    <div className={dateLabel}>
                        <Moment format="D MMM YYYY">{shortlistedAt}</Moment>
                    </div>
                </div>
                <CompareButton
                    onClick={onCompareClick}
                    count={Object.keys(candidatesIdsToCompare).length}
                    isActive={isCompareActive}
                    canCompareCandidates={canCompareCandidates}
                />
            </div>
        </BoardItem>
    );
});

export default withStyles(styles)(withRouter(ShortlistCard));

export default ({
    inputWrapper: {
        display: 'flex'
    },
    positionSelect: {
        maxWidth: 128,
        marginRight: 12.5
    },
    menu: {
        maxWidth: 150
    },
    reorderButton: {
        minWidth: 200,
        paddingLeft: 35,
        paddingRight: 35
    },
    nowrap: {
        '& *': {
            whiteSpace: 'nowrap!important'
        }
    }
});

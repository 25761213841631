import React from 'react';
import { withTheme } from '@mui/styles';

const InsightsIcon = ({ theme }) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 23.3334V11.6667" stroke={theme.palette.blue[500]} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 23.3334V4.66669" stroke={theme.palette.blue[500]} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 23.3333V16.3333" stroke={theme.palette.blue[500]} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(InsightsIcon);

import { tracking } from 'helper/eventSegment';

export default {
    CANDIDATE_HIRING_MANAGER_ADDED: (data) => {
        tracking('G-candidate-hiring-manager-added', data);
    },
    CANDIDATE_HIRING_MANAGER_SKIPPED: (data) => {
        tracking('G-candidate-hiring-manager-skipped', data);
    },
    CANDIDATE_HIRING_MANAGER_CHANGED: (data) => {
        tracking('G-candidate-hiring-manager-changed', data);
    },
    CANDIDATE_HIRING_MANAGER_REMOVED: (data) => {
        tracking('G-candidate-hiring-manager-removed', data);
    },
    HIRING_MANAGER_ADDED: (data) => {
        tracking('TT-hiring-manager-added', data);
    },
    HIRING_MANAGER_REMOVED: (data) => {
        tracking('TT-hiring-manager-removed', data);
    },
    HIRING_MANAGER_EDITED: (data) => {
        tracking('TT-hiring-manager-edited', data);
    },
    CANDIDATE_HIRE_DATE_ADDED: (data) => {
        tracking('G-candidate-hire-date-added', data);
    },
    CANDIDATE_HIRE_DATE_SKIPPED: (data) => {
        tracking('G-candidate-hire-date-skipped', data);
    },
    CANDIDATE_HIRE_DATE_CHANGED: (data) => {
        tracking('G-candidate-hire-date-changed', data);
    },
    CANDIDATE_HIRE_DATE_REMOVED: (data) => {
        tracking('G-candidate-hire-date-removed', data);
    },
    POST_HIRE_SURVEY_OPENED: (data) => {
        tracking('HM-post-hire-survey-opened', data);
    },
    POST_HIRE_SURVEY_STARTED: (data) => {
        tracking('HM-post-hire-survey-started', data);
    },
    CANDIDATE_UNHIRED: (data) => {
        tracking('G-candidate-unhired', data);
    }
};

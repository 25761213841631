import React from 'react';
import { secondsToTimeObject } from 'helper/commonFunctions';
import useStyles from './styles';

const TimerLabel = ({ value, totalValue, xs = false, className, rightTimePart }) => {
    const { timerWrapper, timerWrapperLeft } = useStyles({});
    const fifthValue = 0.2 * totalValue;

    const secondsToTime = (timeInSeconds) => {
        const addZeroIfNeeded = (value1) => {
            if (value1 < 10) return `0${value1}`;
            return value1;
        };
        const { days, hours, minutes, seconds } = secondsToTimeObject(timeInSeconds);

        if (days) {
            return `${days} ${xs ? 'D' : 'days'} ${hours ? `${hours} ${xs ? 'H' : 'hours'}` : ''}`;
        }

        if (hours) {
            return `${hours} ${xs ? 'H' : 'hours'} ${minutes ? `${minutes} ${xs ? 'M' : 'minutes'}` : ''}`;
        }

        return `${addZeroIfNeeded(minutes)}:${addZeroIfNeeded(seconds)}`;
    };

    return (
        <div
            className={`${timerWrapper} ${value <= fifthValue ? `${timerWrapperLeft}` : ''} ${className ? `${className}` : ''}`}
        >
            {rightTimePart && <span>Time remaining &nbsp;</span>}
            {secondsToTime(value)}&nbsp;
            {!rightTimePart && 'remaining'}
        </div>
    );
};

export default TimerLabel;

import React from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import CandidatesCount from 'components/table_components/candidates_count';

import { CANDIDATES_PER_RESULTS_PAGE } from 'helper/constants';

import SortLabels from 'pages/assessment/results_assessment/common/SortLabels';
import CandidatesTable from './components/CandidatesTable';
import SearchFilterBlock from './components/SearchFilterBlock';

const useStyles = makeStyles(() => ({
    board: {
        minHeight: 330,
        paddingTop: 0,
        paddingBottom: 70
    },
    title: {
        margin: '0 12px 0 0'
    }
}));

const CandidatesList = observer(({
    totalCount, allCandidates,
    isLoading, audition
}) => {
    const classes = useStyles();

    return (
        <div>
            <SearchFilterBlock />
            <div className="u-dsp--f u-ai--start u-jc--sb">
                <div className="u-dsp--f u-mrg--bx2 u-ai--start">
                    <div className="u-dsp--f u-ai--baseline">
                        <Typography variant="h5" className={classes.title}>
                            Candidates
                        </Typography>
                        {
                            !isLoading && (
                                <CandidatesCount
                                    className="u-mrg--bx0"
                                    candidatesPerPage={CANDIDATES_PER_RESULTS_PAGE}
                                    page={1}
                                    candidatesLength={totalCount}
                                    candidatesFound={allCandidates ? allCandidates.length : 0}
                                    label="Completions"
                                />
                            )
                        }
                    </div>
                </div>
                <SortLabels />
            </div>
            <CandidatesTable
                {
                ...{
                    allCandidates,
                    totalCount,
                    page: 1,
                    isLoading,
                    audition
                }}
            />
        </div>
    );
});

export default CandidatesList;

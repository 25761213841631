import React, { useEffect, useState } from 'react';

// material components
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import AddIcon from 'img/add.svg';

import { clone } from 'helper/commonFunctions';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import { MULTIPLE_CHOICE_AUTOGRADED, SINGLE_CHOICE_AUTO_GRADED } from 'helper/constants';
import Typography from '@mui/material/Typography';
import {
    changeExistingMultipleChoiceAnswer,
    createMultipleChoiceAnswer,
    getMultipleChoiceAnswers, removeMultipleChoiceAnswer
} from 'requests/QuestionsRequests';
import MultipleChoiceInput from './MultipleChoiceInput';

import styles from './styles';

let timerId = null;
let timerIdQuestion = null;

let prevKey = '';

const MultipleChoiceAnswerType = ({
    question: { id, answerType, valid: questionValid, missingAnswer, questionSettings },
    fetchQuestion, classes, setSaving, onChangeType, changeQuestionSettings,
    isCMS
}) => {
    const [answers, setAnswers] = useState([]);
    const [autoGraded, setAutoGrade] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState({});
    const [hasCustomScore, setHasCustomScore] = useState(false);

    useEffect(() => {
        setLoading(true);
        loadAnswers();
    }, [id]);

    useEffect(() => {
        setAutoGrade(answerType === SINGLE_CHOICE_AUTO_GRADED);
        setHasCustomScore(Boolean(questionSettings.customScoreForChoices));
    }, [answerType, id]);

    const loadAnswers = () => {
        getMultipleChoiceAnswers(id, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    setAnswers(data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const setChoiceByIndex = (index, newValue) => {
        setAnswers((oldAnswers) => {
            const newAnswers = clone(oldAnswers);
            newAnswers[index] = { ...newAnswers[index], ...newValue };
            return newAnswers;
        });
    };

    const onChange = (index, key, newValue, withoutRequest = false) => {
        const { id: answerId } = answers[index];
        setChoiceByIndex(index, { [key]: newValue });
        if (withoutRequest) return;
        if (prevKey === key) clearTimeout(timerId);
        prevKey = key;
        setSaving(true);
        timerId = setTimeout(async () => {
            clearTimeout(timerIdQuestion);
            timerIdQuestion = setTimeout(fetchQuestion, 1000);
            const { data } = await changeExistingMultipleChoiceAnswer(answerId, key !== 'ziggeo' ? { [key]: newValue } : { ziggeoToken: newValue.token }, isCMS);
            const changedKeys = { valid: data.valid };
            if (key !== 'title') changedKeys[key] = data[key];
            setChoiceByIndex(index, changedKeys);
            setSaving(false);
        }, 300);
    };

    const addAnswer = (e) => {
        e.preventDefault();
        setActionLoading(true);
        setSaving(true);
        createMultipleChoiceAnswer(id, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    const newAnswers = clone(answers);
                    newAnswers.push(data);
                    setAnswers(newAnswers);
                    fetchQuestion();
                }
                setActionLoading(false);
                setSaving(false);
            })
            .catch(() => {
                setActionLoading(false);
                setSaving(false);
            });
    };

    const removeAnswer = (e, key) => {
        e.preventDefault();
        const { id: answerId } = answers[key];
        setActionLoading(true);
        setSaving(true);
        removeMultipleChoiceAnswer(answerId, isCMS)
            .then(() => {
                const newAnswers = clone(answers);
                newAnswers.splice(key, 1);
                setAnswers(newAnswers);
                fetchQuestion();
                setActionLoading(false);
                setSaving(false);
            })
            .catch(() => {
                setActionLoading(false);
                setSaving(false);
            });
    };

    const onChangeAutograded = async ({ target: { checked } }) => {
        const newValue = checked ? MULTIPLE_CHOICE_AUTOGRADED : SINGLE_CHOICE_AUTO_GRADED;
        setAutoGrade(newValue === SINGLE_CHOICE_AUTO_GRADED);
        await onChangeType({ answerType: newValue });
        loadAnswers();
    };

    const onChangeCustomScoreForChoices = () => {
        const newValue = !hasCustomScore;
        setHasCustomScore(newValue);
        changeQuestionSettings({ customScoreForChoices: newValue });
    };

    if (isLoading) {
        return (
            <CircularProgress size={20} />
        );
    }

    const countValid = answers.filter(({ valid }) => valid).length;

    return (
        <div className="u-mrg--tx5 u-mrg--bx5">
            <Typography variant="h6">Answer Choices</Typography>
            <div className={classes.warningText}>
                Create and select a single correct answer. Enable multiple correct answers if required.
            </div>
            <SwitchBrandWithLabel
                label="Enable multiple correct answers"
                checked={!autoGraded}
                className="u-mrg--tx1 u-mrg--bx1"
                onChange={onChangeAutograded}
            /><br />
            {
                !autoGraded && (
                    <SwitchBrandWithLabel
                        label="Enable custom scoring"
                        checked={hasCustomScore}
                        className="u-mrg--tx0"
                        onChange={onChangeCustomScoreForChoices}
                    />
                )
            }
            <br />
            {
                Boolean(answers && answers.length) && answers.map((item, index) => {
                    const { id: answerId } = item;
                    return (
                        <MultipleChoiceInput
                            key={answerId}
                            isCMS={isCMS}
                            item={item}
                            index={index}
                            answers={answers}
                            onChange={onChange}
                            autoGraded={autoGraded}
                            uploadingFiles={uploadingFiles}
                            removeAnswer={removeAnswer}
                            actionLoading={actionLoading}
                            setUploadingFiles={setUploadingFiles}
                            hasCustomScore={hasCustomScore}
                        />
                    );
                })
            }
            <div className="u-mrg--bx2 u-mrg--tx2">
                <Button
                    onClick={addAnswer}
                    id="qa-add-mlc-ans"
                    color="primary"
                    disabled={actionLoading}
                    className={classes.addBtn}
                >
                    <img src={AddIcon} alt="x" />Add Choice
                </Button>
            </div>
            {
                !questionValid && countValid < 2 && (
                    <p className={classes.labelError}>Please add {countValid ? 'more ' : ''}valid answer choices.</p>
                )
            }
            {
                !questionValid && !autoGraded && missingAnswer && countValid > 1 && (
                    <p className={classes.labelError}>Please add correct choices.</p>
                )
            }
        </div>
    );
};

export default withStyles(styles)(MultipleChoiceAnswerType);

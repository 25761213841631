import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import TTBuilderEvents from 'events/TTBuilderEvents';
import { findKeyWords } from 'requests/QuestionsRequests';
import LoaderCalibration from 'components/loaders/LoaderCalibration';
import styles from '../../styles';
import TTBuilderInput from '../TTBuilderInput';
import KeyWordsModalContent from '../KeyWordsModalContent';

let timer = null;
const CustomFlow = ({ customTitle, setCustomTitle, classes, writingSkills, manualAddedSkills, setManualAddedSkills, setWritingSkills, addedSkills }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [keyWordsWithTitle, setKeyWordsWithTitle] = useState([]);
    const [started, setStarted] = useState(false);

    const addSkill = (skill) => {
        const skills = [...manualAddedSkills, ...writingSkills];

        if (!skills.includes(skill)) {
            setManualAddedSkills([...manualAddedSkills, skill]);
        }
    };

    const addServerSkill = (token) => {
        TTBuilderEvents.SKILL_ADDED({
            skill: token,
            title: customTitle,
            customSkill: false
        });
        addSkill(token);
    };

    const removeSkill = (skill) => {
        if (manualAddedSkills.includes(skill)) {
            setManualAddedSkills(manualAddedSkills.filter(item => item !== skill));
        } else if (writingSkills.includes(skill)) {
            setWritingSkills(writingSkills.filter(item => item !== skill));
        }
    };

    const changeCustomTitle = (value) => {
        if (!started) {
            setStarted(true);
        }
        clearTimeout(timer);
        setCustomTitle(value);
        setIsLoading(Boolean(value));
        timer = setTimeout(() => {
            getKeyWords(value);
        }, 500);
    };

    const getKeyWords = (title) => {
        findKeyWords(title)
            .then(({ success, data }) => {
                setIsLoading(false);
                if (success) {
                    setKeyWordsWithTitle(data);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={classes.contentWrapper}>
            <div className={classes.subHeading}>Start with the name of the role you want to hire</div>
            <TTBuilderInput
                onChange={changeCustomTitle}
                value={customTitle}
            />
            {
                isLoading && !(addedSkills && addedSkills.length) ? (
                    <LoaderCalibration className={classes.loaderWrapper} />
                ) : (
                    <KeyWordsModalContent
                        addSkill={addSkill}
                        addServerSkill={addServerSkill}
                        removeSkill={removeSkill}
                        addedSkills={addedSkills}
                        title={customTitle}
                        keyWordsWithTitle={keyWordsWithTitle.filter(item => !addedSkills.includes(item))}
                    />
                )
            }
        </div>
    );
};

export default withStyles(styles)(CustomFlow);

import React, { useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Dialog from 'libraries/Dialog';
import Tooltip from 'libraries/Tooltip';
import QuestionTypes from 'components/marketplace/Preview/common/QuestionTypes';
import AnswerTypes from 'components/marketplace/Preview/common/AnswerTypes';
import RequiredQuestionLabel from 'components/job_or_assessment_settings/Chip';
import RemainingTimeLabel from 'components/job_or_assessment_settings/RemainingTimeLabel';

import ContentIcon from './content.svg';
import useStyles from './styles';

const QuestionContent = ({ questionNumber, buttonClassName = '', question, audition }) => {
    const [open, setOpened] = useState(false);
    const { timerEnabled } = audition;
    const classes = useStyles();

    const handleOpen = () => {
        setOpened(!open);
    };

    const { required } = question;

    return (
        <div role="presentation" onClick={e => e.stopPropagation()}>
            <Tooltip
                label="Show Question"
            >
                <IconButton
                    className={clsx(classes.moreButton, buttonClassName)}
                    onClick={handleOpen}
                    size="large"
                >
                    <img src={ContentIcon} alt="question content" />
                </IconButton>
            </Tooltip>
            <Dialog
                maxWidth="sm"
                open={open}
                handleClose={handleOpen}
                onClose={handleOpen}
                classes={{
                    paper: classes.dialogPaper
                }}
                titleClassName={classes.titleClassName}
                titleComponent={(
                    <>
                        Question {questionNumber}
                        <RemainingTimeLabel
                            question={question}
                            auditionTimerEnabled={timerEnabled}
                        />
                        { required && <RequiredQuestionLabel uppercase className="u-mrg--lx1" /> }
                    </>
                )}
            >
                <QuestionTypes data={question} className="u-mrg--tx2" />
                <AnswerTypes
                    interview={audition}
                    data={question}
                    index={questionNumber - 1}
                    isPreviewPage
                />
            </Dialog>
        </div>
    );
};

export default withRouter(QuestionContent);

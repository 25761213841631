import React from 'react';

const DoneIcon = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <path id="a" d="M9.662 15.516l7.605-8.196a1 1 0 1 1 1.466 1.36l-8.35 9a1 1 0 0 1-1.477-.012l-2.65-2.954a1 1 0 1 1 1.488-1.336l1.918 2.138z" />
        </defs>
        <use fill="#2fd1b8" fillRule="evenodd" xlinkHref="#a" />
    </svg>
);

export default DoneIcon;

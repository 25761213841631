import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';

import QuestionsList from './QuestionsList';
import QuestionContent from './QuestionContent';
import { assessmentSettingsCtx } from '../store';
import { statsCtx } from '../statsStore';


import styles from './styles';

const GradingResultsDialog = observer(({
    classes, open, onClose, interviewSlug, openedDrawerSection, setOpenedDrawerSection
}) => {
    const { audition: { active } } = useContext(assessmentSettingsCtx);
    const { activeLearningStatistic, loadActiveLearning, selectedQuestionIndex, setSelectedQuestionIndex } = useContext(statsCtx);

    if (!active) return <Redirect to="/forbidden" />;

    useEffect(() => {
        loadActiveLearning(true);
    }, [interviewSlug]);

    const { questionList, status } = activeLearningStatistic;
    const selectedQuestion = questionList[selectedQuestionIndex];

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
            handleClose={onClose}
            titleComponent="Your AI Health"
            contentClassName={classes.dialog}
            titleClassName={classes.dialogTitle}
        >
            <div className={classes.contentWrapper}>
                <QuestionsList
                    questionList={questionList}
                    selectedQuestionIndex={selectedQuestionIndex}
                    setSelectedQuestionIndex={setSelectedQuestionIndex}
                    status={status}
                />
                <QuestionContent
                    slug={interviewSlug}
                    questionHashUuid={selectedQuestion ? selectedQuestion.questionHashUuid : null}
                    loadAudition={loadActiveLearning}
                    selectedQuestionIndex={selectedQuestionIndex}
                    openedDrawerSection={openedDrawerSection}
                    setOpenedDrawerSection={setOpenedDrawerSection}
                    onAiDialogClose={onClose}
                />
            </div>
        </Dialog>
    );
});

export default withStyles(styles)(GradingResultsDialog);

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    tableRow: {
        cursor: 'pointer'
    },
    tableCellMuted: {
        opacity: 0.55
    },
    menuIcon: {
        transform: 'scale(1.3)'
    },
    menuButton: {
        borderRadius: 4
    },
    noRankIcon: {
        color: theme.palette.grey[600]
    }
}));

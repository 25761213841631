import React, { useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import ScoreContainer from 'components/candidate_cards/ScoreContainer';
import { candidateResultCtx } from '../../../common/CandidateListWrapper/candidatesStore';

import useStyles from './styles';

const SkillsContent = observer(({ candidate, isLoading }) => {
    const classes = useStyles();
    const { isEmployerSortEnabled } = useContext(candidateResultCtx);

    if (isLoading) {
        return (
            <CircularProgress
                size={15}
                className={classes.loader}
                color="primary"
            />
        );
    }

    if (!candidate) return null;
    const { score, employerScore } = candidate;
    const scoreObj = isEmployerSortEnabled ? employerScore : score;

    return (
        <ScoreContainer
            hideLabel
            smallCard
            className={classes.scrolledContainer}
            candidate={{ ...candidate, score: scoreObj }}
        />
    );
});

export default withRouter(SkillsContent);

export default theme => ({
    wrapper: {
        width: 464,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    addInfoWrapper: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        }
    },
    oldPlan: {
        height: 240,
        width: '100%',
        backgroundColor: theme.palette.blue[500],
        padding: 35
    },
    companyOldPlanName: {
        color: '#fff',
        fontSize: 40,
        fontWeight: 700
    },
    description: {
        marginTop: 35,
        color: '#fff',
        fontSize: 16,
        maxWidth: 276
    }
});

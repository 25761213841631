import React from 'react';
import { VIDEO } from 'helper/constants';

const Svg = (onClick, className) => (

    <svg
        className={className}
        onClick={e => onClick(e, VIDEO)}
        width="22"
        height="18"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" d="M20.3491 16.4047C20.7659 15.9879 21 15.4227 21 14.8333V9.65972L20.8958 1.5H3.22222C2.63285 1.5 2.06762 1.73413 1.65087 2.15087C1.23413 2.56762 1 3.13285 1 3.72222V17.0556H18.7778C19.3671 17.0556 19.9324 16.8214 20.3491 16.4047Z" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" d="M14.4853 7.74264L8.7098 5.03287L8.7098 10.4524L14.4853 7.74264Z" stroke="#7F8084" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" d="M1 14H21" stroke="#7F8084" strokeWidth="1.2" />
    </svg>

);

export default Svg;

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SketchPicker } from 'react-color';
import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';

import AddBrandingSetDialog from 'components/dialogs/AddBrandingSetDialog';
import DeleteBrandingSetDialog from 'components/dialogs/DeleteBrandingSetDialog';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import Tooltip from 'libraries/Tooltip';
import SettingsEvents from 'events/SettingsEvents';
import { deleteCompanyBranding, editBrandingColor } from 'requests/SettingsRequests';

import EditIcon from './edit.svg';
import DeleteIcon from './delete.svg';
import UploadLogo from './UploadLogo';

let timerId = null;


const styles = theme => ({
    buttonBase: {
        color: theme.palette.primary.main
    },
    wrapper: {
        height: 700,
        maxWidth: 445,
        [theme.breakpoints.down('md')]: {
            height: 'auto'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[300],
        padding: '20px 40px',
        fontWeight: 700,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8
    },
    editIcon: {
        width: 20
    },
    main: {
        fontWeight: 600,
        padding: '20px 40px 40px',
        backgroundColor: '#ffffff',
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8
    }
});

const BrandingCard = observer(({
    classes, brandingSet: { color, logoUrl, title, uuid }, brandingSet,
    brandings, setBrandings, flashMessage, isSaving, setSaving, setBrandingDetail
}) => {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const deleteBranding = () => {
        setLoading(true);
        setSaving(true);
        deleteCompanyBranding(uuid)
            .then(() => {
                setBrandings(brandings.filter(brand => brand.uuid !== uuid));
                SettingsEvents.BRANDING_DELETED({ brandingTitle: title, brandingUUID: uuid });
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setLoading(false);
                setSaving(false);
                setDeleteDialogOpen(false);
            });
    };

    const changeBrandingColor = (e) => {
        const oldColor = color;
        setBrandingDetail(uuid, 'color', e.hex);
        setSaving(true);
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            editBrandingColor(uuid, e.hex)
                .catch(() => {
                    flashMessage('Something went wrong', 'error');
                    setBrandingDetail(uuid, 'color', oldColor);
                })
                .finally(() => {
                    setSaving(false);
                });
        }, 500);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>
                <TruncateWithTooltip capitalize text={title} width={290} />
                <div>
                    <Tooltip label="Edit title">
                        <IconButton onClick={() => setDialogOpen(true)} disabled={loading}>
                            <img src={EditIcon} className={classes.editIcon} alt="edit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip label="Delete">
                        <IconButton onClick={() => setDeleteDialogOpen(true)} disabled={loading}>
                            <img src={DeleteIcon} className={classes.editIcon} alt="del" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className={classes.main}>
                <UploadLogo
                    logoLink={logoUrl}
                    brandingSetUuid={uuid}
                    setBrandings={setBrandings}
                    brandings={brandings}
                    isSaving={isSaving}
                    setSaving={setSaving}
                />
                <div className="u-mrg--bx2">Pick your brand colour</div>
                <SketchPicker
                    disabled={loading}
                    color={color}
                    onChangeComplete={changeBrandingColor}
                    disableAlpha
                    width="95%"
                    className="u-mrg--tx2"
                    name="brandColor"
                    presetColors={[]}
                />
            </div>
            <AddBrandingSetDialog
                isSaving={isSaving}
                setSaving={setSaving}
                defaultBrandingSet={brandingSet}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                brandings={brandings}
                setBrandings={setBrandings}
            />
            <DeleteBrandingSetDialog
                isSaving={isSaving}
                setSaving={setSaving}
                brandingSet={brandingSet}
                open={deleteDialogOpen}
                deleteBranding={deleteBranding}
                onClose={() => setDeleteDialogOpen(false)}
            />
        </div>
    );
});

export default withStyles(styles)(BrandingCard);

const styles = theme => ({
    nextButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        paddingLeft: 40,
        paddingRight: 40,
        height: 50
    },
    root: {
        overflowX: 'hidden'
    },
    subHeading: {
        fontSize: 16,
        marginBottom: 12,
        color: theme.palette.grey[600]
    },
    actions: {
        padding: '20px 70px',
        [theme.breakpoints.down('md')]: {
            padding: '30px'
        }
    },
    loaderWrapper: {
        minHeight: 250
    },
    content: {
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    contentWrapper: {
        width: '100%',
        maxWidth: 800
    },
    title: {
        fontSize: 24,
        fontWeight: 800,
        color: theme.palette.grey[900],
        textAlign: 'center'
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.grey[700],
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 15
    },
    roleHighlight: {
        color: theme.palette.blue[600]
    },
    skillsContainer: {
        display: 'flex',
        gap: 8,
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    skill: {
        backgroundColor: theme.palette.blue[600],
        borderRadius: 63,
        padding: '5px 16px',
        color: 'white',
        fontSize: 24,
        fontWeight: 700
    }
});

export default styles;

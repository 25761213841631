export default theme => ({
    wrapper: {
        padding: '3px 8px',
        fontWeight: 700,
        borderRadius: '60px',
        fontSize: 10,
        marginLeft: 8,
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[900]
    }
});

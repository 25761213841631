import React from 'react';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    wrapper: {
        backgroundColor: theme.palette.green[50],
        color: '#000',
        border: `2px solid ${theme.palette.green[200]}`,
        borderRadius: 4,
        lineHeight: '28px',
        padding: '15px 25px',
        fontSize: 14,
        fontWeight: 600,
        marginTop: 24,
        '& a': {
            color: theme.palette.primary.main
        }
    }
});

const BoardInfoPanel = ({ classes, infoText }) => {
    if (!infoText) return null;
    return (
        <div className={classes.wrapper}>
            {infoText}
        </div>
    );
};

export default withStyles(styles)(BoardInfoPanel);

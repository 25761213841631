export default theme => ({
    popoverRoot: {
        padding: 0,
        width: 350
    },
    opportunitiesWrapper: {
        maxHeight: 250,
        overflow: 'auto',
        paddingBottom: 7
    },
    subtopic: {
        color: theme.palette.grey[600],
        fontSize: 12,
        fontWeight: 600,
        marginBottom: 0
    },
    name: {
        color: theme.palette.grey[900],
        fontSize: 14
    },
    date: {
        color: theme.palette.grey[500],
        fontSize: 12
    },
    headerWrapper: {
        padding: '21px 24px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    assessmentWrapper: {
        position: 'relative',
        fontWeight: 600,
        padding: '12px 40px 12px 24px',
        display: 'block',
        '&:not(:first-child)': {
            borderTop: `2px solid ${theme.palette.grey[200]}`
        }
    },
    icon: {
        color: theme.palette.grey[600]
    },
    iconButton: {
        width: 40,
        height: 40,
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto 0'
    }
});

export default {
    noVideoWrapper: {
        padding: 28,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
        fontSize: 14,
        marginTop: 25
    },
    introHeader: {
        fontSize: 20,
        marginBottom: 0,
        fontWeight: 'bold',
        lineHeight: 1.8,
        letterSpacing: 'normal',
        color: '#1a1b1e'
    },
    introSubheader: {
        fontSize: 10,
        fontWeight: 600,
        lineHeight: 1.8,
        letterSpacing: 0.8,
        color: '#9fa0a5'
    },
    recordButton: {
        marginTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
        fontWeight: 'bold',
        letterSpacing: 0.2
    },
    mediaWrapper: {
        display: 'none'
    },
    mediaWrapperShow: {
        display: 'block'
    },
    videoWrapper: {
        maxWidth: 700,
        margin: 'auto',
        textAlign: 'center'
    },
    buttonWrapper: {
        marginTop: 20,
        display: 'flex'
    },
    reRecordButton: {
        paddingLeft: 40,
        paddingRight: 40,
        marginRight: 30
    },
    removeButton: {
        paddingLeft: 30,
        paddingRight: 30,
        fontWeight: 600
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media only screen and (max-width: 1250px)': {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    message: {
        maxWidth: 495,
        paddingRight: 15,
        '@media only screen and (max-width: 1250px)': {
            maxWidth: 'none',
            marginBottom: 20
        }
    },
    videoPreviewWrapper: {
        borderRadius: 4,
        maxWidth: '100%'
    }
};

import React from 'react';
import { withRouter } from 'react-router-dom';
import BoardNoContent from 'components/board/board_no_content';
import Button from '@mui/material/Button';

import EmptyIcon from './icon.svg';

const NoAssessmentsContent = ({ query, handleCreateAssessment }) => (
    <BoardNoContent
        header="Can’t find what you’re looking for?"
        icon={<img src={EmptyIcon} alt="empty-content" />}
        text={(
            <>
                Sorry we didn’t find any results for {query ? <>“{query}”</> : 'selected category'}.<br />
                Check the spelling, or try a different search.<br /><br />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateAssessment}
                    className="u-mrg--tx3"
                >
                    Create Library Assessment
                </Button>
            </>
        )}
    />
);


export default withRouter(NoAssessmentsContent);

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';

import Switch from 'libraries/SwitchBrandWithLabel';
import Button from '@mui/material/Button';
import { updateJobAdderSettings,
    getJobAdderSettings,
    deleteJobAdderIntegration
} from 'requests/SubscriptionRequests';
import SettingsEvents from 'events/SettingsEvents';
import RemoveJobAdderDialog from 'components/dialogs/RemoveJobAdderDialog';
import ResetJobAdderDialog from 'components/dialogs/ResetJobAdderDialog';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import WebhookStatusBlock from './WebhookStatusBlock';
import SelectCustomScoreField from './SelectScoreCustomField';


const styles = () => ({
    heading: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '28px',
        marginBottom: 20
    },
    button: {
        height: 40,
        marginRight: 20,
        fontWeight: 'bold'
    }
});


const JobAdderContent = ({ classes, flashMessage, company, updateCompany }) => {
    const [activated, setActivated] = useState(false);
    const [isAuthorized, setAuthorized] = useState(false);
    const [scoreFieldId, setScoreFieldId] = useState(0);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [resetDialogOpen, setResetDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getJobAdderSettings()
            .then(({ success, data }) => {
                if (data && success) {
                    const { enabled, authorized, customFieldId } = data;
                    setActivated(enabled);
                    setAuthorized(authorized);
                    setScoreFieldId(customFieldId);
                }
            });
    }, []);

    const handleChangeSwitch = () => {
        updateJobAdderSettings(!activated)
            .then(({ success, data }) => {
                if (data && success) {
                    const { enabled, authorized } = data;
                    setActivated(enabled);
                    setAuthorized(authorized);
                    SettingsEvents.JOBADDER_ENABLED({ enabled });
                    updateCompany({ ...company, isJobAdderAtsEnabled: enabled });
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    const removeJobAdder = () => {
        setLoading(true);
        deleteJobAdderIntegration()
            .then(() => {
                SettingsEvents.JOBADDER_REMOVED();
                setActivated(false);
                setAuthorized(false);
                setScoreFieldId(0);
                flashMessage('Integration removed', 'done');
                updateCompany({ ...company, isJobAdderAtsEnabled: false });
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setRemoveDialogOpen(false);
                setLoading(false);
            });
    };

    const activateJobAdder = () => {
        updateJobAdderSettings(true)
            .then(({ success, data }) => {
                if (success && data) {
                    const { enabled, authLink } = data;
                    setActivated(enabled);
                    // eslint-disable-next-line no-restricted-globals
                    const left = (screen.width / 2) - (window.innerWidth / 2);
                    // eslint-disable-next-line no-restricted-globals
                    const top = (screen.height / 2) - (window.innerHeight / 2);
                    const w2 = window.open(
                        '',
                        '_blank',
                        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=550, height=750, top=${top}, left=${left}`
                    );
                    setTimeout(() => { w2.location.href = authLink; }, 2000);
                    window.addEventListener('message', (event) => {
                        const { code, state, error } = event.data;
                        if (error) {
                            flashMessage(error, 'error');
                        } else if (code && state && !error) {
                            flashMessage('Authorized successfully', 'done');
                            setAuthorized(true);
                            updateCompany({ ...company, isJobAdderAtsEnabled: true });
                        }
                    });
                }
            })
            .catch(() => { flashMessage('Something went wrong', 'error'); });
    };

    return (
        <div>
            <h3 className={classes.heading}>Connect Vervoe to JobAdder</h3>
            <Switch
                label="Enable JobAdder Integration"
                checked={activated}
                value={activated}
                onChange={handleChangeSwitch}
            />
            <div className="u-dsp--f u-ai--center u-mrg--bx8">
                <Button
                    color="primary"
                    variant="contained"
                    style={{ width: 200 }}
                    className={classes.button}
                    disabled={isAuthorized || !activated}
                    onClick={activateJobAdder}
                >
                    {`Authorize${isAuthorized ? 'd' : ''}`}
                </Button>
                {isAuthorized && activated && <>
                    <Button
                        color="primary"
                        onClick={() => setResetDialogOpen(true)}
                    >
                        Reset integration
                    </Button>
                    <InfoTooltip
                        isBlack
                        id="reset-jobadder"
                        text="With reset JobAdder will stop receiving candidate updates. All assessments will be removed, but the integration will remain enabled."
                    />
                </>
                }
            </div>
            {isAuthorized && activated && <>
                <SelectCustomScoreField {...{ scoreFieldId, setScoreFieldId, flashMessage }} />
                <WebhookStatusBlock flashMessage={flashMessage} />
            </>}
            {isAuthorized && <>
                <h3 className={classes.heading}>Disconnect Vervoe and JobAdder</h3>
                <div className="u-dsp--f">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: 200 }}
                        className={classes.button}
                        onClick={() => setRemoveDialogOpen(true)}
                    >
                        Remove integration
                    </Button>
                </div>
                </>
            }
            <RemoveJobAdderDialog
                open={removeDialogOpen}
                onClose={() => setRemoveDialogOpen(false)}
                callback={removeJobAdder}
                loading={loading}
            />
            <ResetJobAdderDialog
                open={resetDialogOpen}
                onClose={() => setResetDialogOpen(false)}
                flashMessage={flashMessage}
            />
        </div>
    );
};

export default withStyles(styles)(JobAdderContent);

import React from 'react';
import TextInputOutlined from 'libraries/TextInput';
import { returnHelperTextForArray } from 'helper/codeChallenge';

const TwoDimensionArrayInput = ({ type, paramIndex, value, onChangeValue }) => {
    let values = value.split('\n');

    if (values.length < (+values[0] + 2)) {
        const difference = (+values[0] || 0) + 2 - values.length;

        // next line does not work without Array.apply
        // eslint-disable-next-line
        const additionalRows = Array.apply(null, { length: difference }).map(() => '');
        values.push(...additionalRows);
    }

    if (values.length > (+values[0] + 2)) {
        values = values.slice(0, (+values[0] || 0) + 2);
    }

    const handleChange = (e, valueIndex) => {
        if (e.target.name === 'rows' && (+e.target.value > 20 || +e.target.value < 0)) return;
        let newValues = values.map((val, i) => (i === valueIndex ? e.target.value : val));
        if (e.target.name === 'rows') newValues = newValues.filter((val, i) => i < (+e.target.value + 2));
        onChangeValue(newValues.join('\n'), paramIndex);
    };

    return <>
        <div className="u-dsp--f u-jc--start u-ai--start">
            <TextInputOutlined
                name="rows"
                label="Row Count"
                className="u-mrg--rx4"
                style={{ maxWidth: 130 }}
                variant="outlined"
                value={values[0]}
                type="number"
                min={1}
                max={20}
                maxLength={2}
                onChange={e => handleChange(e, 0)}
            />
            <TextInputOutlined
                label="Column Count"
                className="u-mrg--rx4"
                value={values[1]}
                style={{ maxWidth: 130 }}
                variant="outlined"
                type="number"
                min={1}
                onChange={e => handleChange(e, 1)}
            />
        </div>
        {values.map((inputValue, index) => {
            if (index === 0 || index === 1) return null;
            return (
                <TextInputOutlined
                    key={index}
                    label={`Row ${index - 1}`}
                    variant="outlined"
                    value={values[index]}
                    onChange={e => handleChange(e, index)}
                    helperText={returnHelperTextForArray(type)}
                />
            );
        })}
    </>;
};

export default TwoDimensionArrayInput;

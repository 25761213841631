import React from 'react';
import DetailSettings from 'components/assessments_pages/asessment_settings_components/DetailSettings';
import AutoRejectSettings from 'components/assessments_pages/asessment_settings_components/AutoRejectSettings';
import AutoProgressSettings from 'components/assessments_pages/asessment_settings_components/AutoProgressSettings';


const DetailsPage = ({ ...other }) => (
    <div>
        <DetailSettings {...other} />
        <AutoRejectSettings {...other} />
        <AutoProgressSettings {...other} />
    </div>
);

export default DetailsPage;

import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, withTheme } from '@mui/styles';
import clsx from 'clsx';
import * as qs from 'query-string';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { getListOfDuplicatedQuestions } from 'requests/CMSRequests';
import { appCtx } from 'components/appStore';
import QuestionsList from '../../../common/QuestionsList';

const styles = theme => ({
    wrapper: {
        padding: '0 30px'
    },
    skillsWrapper: {
        padding: '0 30px 16px',
        marginBottom: 20,
        borderBottom: `2px solid ${theme.palette.grey[300]}`
    },
    questionsCountLabel: {
        fontSize: 12,
        marginBottom: 21,
        marginTop: 20,
        width: 95,
        height: 17,
        marginRight: 10
    }
});

const PAGE_STEP = 12;
let timerId = null;

const QuestionsWrapperDuplicated = observer(({
    classes, theme, selectedSkill, openDrawer, history,
    selectedDuplicatedQuestionId, setSelectedDuplicatedQuestionId, location,
    setSelectedQuestionId, selectedQuestionId, ...other
}) => {
    const [questionsData, setQuestionsData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [loadingLocal, setLoadingLocal] = useState(false);

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setQuestionsData({});
        setCurrentPage(1);
        loadQuestions(1);
    }, []);

    useEffect(() => {
        if (!openDrawer && questionsData.items) {
            const index = questionsData.items.findIndex(({ id }) => id === selectedQuestionId);
            loadPageByIndex(index);
        }
    }, [openDrawer]);

    useEffect(() => {
        setLoadingLocal(true);
        loadQuestions();
    }, [currentPage]);

    const parsed = qs.parse(location.search);
    const { questionId } = parsed;

    const loadPageByIndex = (index) => {
        setLoadingLocal(true);
        if (index === -1) {
            setCurrentPage(1);
            loadQuestions(1);
        } else loadQuestions(Math.floor(index / PAGE_STEP) + 1);
    };

    const onFocus = (index) => {
        if (questionsData.items && questionsData.items.length) {
            setSelectedDuplicatedQuestionId(questionsData.items[index].id);
        }
    };

    const loadQuestions = (page = currentPage) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            setLoadingLocal(true);
            getListOfDuplicatedQuestions(page)
                .then(({ success, data }) => {
                    if (success && data) {
                        let newItems = data.items;
                        if (page !== 1) {
                            const slicedQuestions = questionsData.items.slice(0, PAGE_STEP * (page - 1));
                            newItems = [...slicedQuestions, ...data.items];
                        } else if (questionId) {
                            setSelectedDuplicatedQuestionId(+questionId);
                            history.push('/question-duplicates');
                        }
                        setQuestionsData({ ...data, items: newItems });
                    }
                })
                .catch(({ response }) => {
                    if (response && response.data && response.data.msg) {
                        const { data: { msg } } = response;
                        flashMessage(msg, 'error');
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setLoadingLocal(false);
                });
        }, 300);
    };

    const handleNextButtonClick = () => {
        if (!questionsData.hasMorePages) return;
        setQuestionsData({ ...questionsData, hasMorePages: false });
        setCurrentPage(currentPage + 1);
    };

    return (
        <>
            <div className={clsx('u-dsp--distribute', classes.skillsWrapper)}>
                <Typography
                    variant="h4"
                    className="u-mrg--rx2"
                >
                    Questions &nbsp;
                    {!loading && (
                        <span className="u-txt--14">
                            ({questionsData.total})
                        </span>
                    )}
                </Typography>
                <Button
                    color="primary"
                    className="u-txt--bold"
                    disabled={!selectedDuplicatedQuestionId}
                    onClick={() => setSelectedDuplicatedQuestionId(null)}
                >
                    View all Questions <KeyboardArrowRight color={theme.palette.primary.main} />
                </Button>
            </div>
            <div className={classes.wrapper}>
                {
                    !loading && (!questionsData.items || !questionsData.items.length) && (
                        <Typography variant="h5">No Duplicated Questions</Typography>
                    )
                }
                <QuestionsList
                    hideReorder
                    hideAdd
                    showOnFocus
                    loading={loadingLocal}
                    questions={questionsData.items}
                    currentPage={currentPage}
                    hasMorePages={questionsData.hasMorePages}
                    loadMore={handleNextButtonClick}
                    loadQuestions={loadQuestions}
                    selectedDuplicatedQuestionId={selectedDuplicatedQuestionId}
                    setSelectedQuestionId={setSelectedQuestionId}
                    setCurrentPage={setCurrentPage}
                    onFocus={onFocus}
                    {...other}
                />
            </div>
        </>
    );
});

export default withRouter(withTheme(withStyles(styles)(QuestionsWrapperDuplicated)));

export default theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.grey[900],
        '& img': {
            marginRight: 12
        }
    },
    button: {
        padding: 0,
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight: 700,
        marginLeft: 3,
        marginRight: 3,
        marginBottom: 1,
        '&:hover, &:active, &:focus': {
            color: theme.palette.grey[900],
            textDecoration: 'underline',
            outline: 'none'
        }
    }
});

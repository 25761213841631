import React, { useEffect, useState } from 'react';
import { loadAssessmentSettings } from 'requests/ScriptRequests';
import InnerContentWrapper from 'pages/assessment/components/InnerContentWrapper';
import PreviewInvitationEmailButton from 'pages/assessment/candidate_invite/EmailInvitation/PreviewInvitationEmailButton';
import InstructionToCandidateInput from './components/InstructionToCandidateInput';
import InvitationButtonInput from './components/InvitationButtonInput';


const InstructionToCandidates = ({ slug, audition, setSaving }) => {
    const [settings, setSettings] = useState({
        instructionToCandidates: '',
        customInvitationEmailButtonText: '',
        equipmentCheckForced: false,
        hideCompleteLaterButton: false
    });

    useEffect(() => {
        loadAssessmentSettings(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setSettings({
                        ...data,
                        instructionToCandidates: data.message || ''
                    });
                }
            });
    }, []);

    const { instructionToCandidates, customInvitationEmailButtonText } = settings;

    return (
        <InnerContentWrapper
            header="Instruction to candidates"
            className="u-mrg--x0 u-pdn--x0 u-brdr--none"
            headerActions={(
                <PreviewInvitationEmailButton
                    className="u-mrg--rx2"
                    audition={audition}
                    text="Preview and edit invitation email"
                />
            )}
        >
            <InstructionToCandidateInput
                slug={slug}
                defaultValue={instructionToCandidates}
                setSaving={setSaving}
            />
            <InvitationButtonInput
                slug={slug}
                defaultValue={customInvitationEmailButtonText}
                setSaving={setSaving}
            />
        </InnerContentWrapper>
    );
};

export default InstructionToCandidates;

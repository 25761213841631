import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import ArrowImg from './arrow.png';

import styles from '../styles';

const ControlArrows = ({ classes, contentRef, left, right }) => {
    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollLeft = 0;
        }
    }, [contentRef]);

    const handleClick = () => {
        const statChildren = contentRef.current.children;
        const contentBounds = contentRef.current.getBoundingClientRect();
        const threshold = 20;
        const rightScrollOffset = 5;

        let scrollValue = 0;

        if (left) {
            // eslint-disable-next-line no-plusplus
            for (let i = statChildren.length - 1; i >= 0; i--) {
                const child = statChildren[i];
                const childBounds = child.getBoundingClientRect();
                if (childBounds.x < contentBounds.x - threshold) {
                    scrollValue = childBounds.x - contentBounds.x;
                    break;
                }
            }
        } else if (right) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < statChildren.length; i++) {
                const child = statChildren[i];
                const childBounds = child.getBoundingClientRect();
                if (childBounds.right > contentBounds.right + threshold) {
                    scrollValue = childBounds.right - contentBounds.right + rightScrollOffset;
                    break;
                }
            }
        }
        contentRef.current.scrollTo({
            left: contentRef.current.scrollLeft + scrollValue,
            behavior: 'smooth'
        });
    };

    return (
        <div onClick={handleClick} className={classes.arrow}>
            <img className={clsx(left && classes.leftArrow, right && classes.rightArrow)} src={ArrowImg} alt="" />
        </div>
    );
};

export default withStyles(styles)(ControlArrows);

import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import LimitBanner from '../LimitBanner';
import { foldersCtx } from '../../../AssessmentFolders/store';

import styles from './styles';
import { AssessmentQueryCtx } from '../../queryStore';
import AssessmentTagsList from './AssessmentTagsList';

const TabsSearchHeader = observer(({
    classes, query, currentCount, loadingAssessments,
    loadingSearchAssessments, totalCount, requestCounts
}) => {
    const { container, folderTitle } = classes;
    const { activeFolderId, folders } = useContext(foldersCtx);
    const { query: folderQuery } = useContext(AssessmentQueryCtx);

    if (!folders) return null;

    const folderName = activeFolderId ? folders.find(folder => folder.id === activeFolderId).name : 'All Assessments';

    return (
        <div className={container}>
            <div className="u-dsp--f u-ai--center">
                <div className={folderTitle}>
                    {folderQuery.length ? `Results for '${query}' ` : `${folderName} `}
                    { !loadingSearchAssessments && !loadingAssessments && <span className={classes.greyText}>({totalCount || currentCount})</span>}
                    { requestCounts && requestCounts.sent > 0 && (
                        <span className={classes.requestedText}>Requested <span className={classes.greyText}>({ requestCounts.sent })</span></span>
                    )}
                </div>
                <AssessmentTagsList />
            </div>
            <div className="u-dsp--f">
                <LimitBanner requestCounts={requestCounts} />
            </div>
        </div>
    );
});

export default withRouter(withStyles(styles)(TabsSearchHeader));

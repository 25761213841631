import React, { useState } from 'react';
import Button from '@mui/material/Button';

import Dialog from 'libraries/Dialog';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';

const styles = () => ({
    deactivateButton: {
        paddingRight: 40,
        paddingLeft: 40,
        fontWeight: 700,
        minWidth: 174
    },
    loader: {
        color: '#fff',
        '& svg': {
            margin: 0
        }
    }
});

const DeleteDialog = observer(({ open, privateAssessments, onClose, classes, title, id, deleteFolder }) => {
    const [loading, setLoading] = useState(false);

    const eraseFolder = () => {
        setLoading(true);
        if (!deleteFolder) return;
        deleteFolder(id, title)
            .finally(() => {
                onClose();
                setLoading(false);
            });
    };


    return (
        <Dialog
            maxWidth="sm"
            actionComponent={(
                <>
                    <Button
                        onClick={onClose}
                        className="u-txt--bold"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        className={classes.deactivateButton}
                        onClick={eraseFolder}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={22}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : `Delete ${privateAssessments ? 'Folder' : 'Category'}`
                        }
                    </Button>
                </>
            )}
            open={open}
            onClose={onClose}
            handleClose={onClose}
            titleComponent={`Are you sure you want to delete this ${privateAssessments ? 'folder' : 'category'}?`}
        >
            Assessments in this {privateAssessments ? 'folder' : 'category'} won’t be deleted.
        </Dialog>
    );
});

export default withStyles(styles)(DeleteDialog);

import React from 'react';
import { withStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import clsx from 'clsx';

import styles from './styles';

const ActiveFab = ({ open, classes, handleDrawerClose, handleDrawerOpen }) => (
    open ? (
        <Fab
            className={classes.fab}
            onClick={handleDrawerClose}
        >
            <svg style={{ marginTop: '20px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="white" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                <path fill="none" d="M0 0h24v24H0V0z" />
            </svg>
        </Fab>
    ) : (
        <Fab
            className={clsx(classes.fab, classes.fabOpen)}
            onClick={handleDrawerOpen}
        >
            <svg style={{ marginTop: '-20px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="white" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
                <path d="M0 0h24v24H0z" fill="none" />
            </svg>
        </Fab>
    )
);

export default withStyles(styles)(ActiveFab);

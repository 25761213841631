import { observer } from 'mobx-react-lite';
import React, { createContext, useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import InviteForm from 'components/job_or_assessment_settings/InviteForm';
import ImportCSVForm from 'components/job_or_assessment_settings/InviteForm/ImportCSVForm';
import CircularProgress from '@mui/material/CircularProgress';
import { checkPluralNew } from 'helper/commonFunctions';
import { InviteCandidatesTabStore } from 'components/job_or_assessment_settings/InviteForm/store';
import InviteResultsDialog from 'components/job_or_assessment_settings/InviteResultsDialog';
import AddNewTagsDialog from 'components/dialogs/AddNewTagsDialog';
import JOEvents from 'events/JOEvents';
import TestToMyselfButton from '../TestToMyselfButton';
import { statsCtx } from '../../../statsStore';
import { assessmentSettingsCtx } from '../../../store';
import styles from './styles';

export const inviteCandidatesTabStore = new InviteCandidatesTabStore();
export const inviteCandidatesTabCtx = createContext(inviteCandidatesTabStore);

const EmailAssessmentInviteForm = observer(({ classes, history }) => {
    const [showNewTagsDialog, setShowNewTagsDialog] = useState(false);
    const [uploadedCSVData, setUploadedCSVData] = useState(null);
    const [tagsToCreate, setTagsToCreate] = useState([]);
    const [totalTags, setTotalTags] = useState(0);

    const [csvError, setCsvError] = useState('');

    const { audition, loadEditScript, companyTags } = useContext(assessmentSettingsCtx);
    const { loadAssessmentStats } = useContext(statsCtx);
    const { setFormInvite, setRowCount, isCSVInvitation } = useContext(inviteCandidatesTabCtx);
    const { slug: scriptSlug, expired, valid, uuid: ttId, name: ttName } = audition;

    const {
        toggleResponseInviteDialog, errorMessage, errorLimit,
        showResponseInviteDialog, successfullySentData,
        inviteViaEmailForm, loading, hasInvalidPhoneNumbers, onSubmitValidate
    } = useContext(inviteCandidatesTabCtx);

    const goToAssessmentResults = () => {
        history.push(`/script/invite/${scriptSlug}/invite-candidates`);
        toggleResponseInviteDialog();
    };

    const onAllInvitationsSent = () => {
        loadAssessmentStats();
        loadEditScript();
    };

    const onSubmit = () => {
        onSubmitValidate({
            onAllInvitationsSent,
            assessment: audition,
            context: 'Invite page'
        });
    };

    const onCloseNewTagsDialog = (clearInviteForm = true) => {
        if (clearInviteForm) {
            inviteCandidatesTabStore.cleanForm();
        }
        setShowNewTagsDialog(false);
    };

    const onAddNewTags = () => {
        loadValuesToInviteLinksForm(uploadedCSVData);
    };

    const onUploadCSV = (newFormValues, tags) => {
        setUploadedCSVData(newFormValues);
        if (!tags) {
            loadValuesToInviteLinksForm(newFormValues);
            return;
        }

        const newTags = tags.filter(tag => !companyTags.some(existTag => existTag.title.toLowerCase() === tag.toLowerCase()));

        if (newTags.length > 0) {
            setTagsToCreate(newTags);
            setTotalTags(companyTags.length + newTags.length);
            setShowNewTagsDialog(true);
        } else {
            loadValuesToInviteLinksForm(newFormValues);
        }
    };

    const loadValuesToInviteLinksForm = (newFormValues) => {
        if (newFormValues.error) {
            setCsvError(newFormValues.error);
            return;
        }

        const rows = Object.keys(newFormValues).length;
        const numberOfRows = !rows ? 2 : rows + 1;
        setRowCount(numberOfRows);
        setCsvError('');

        handleFormInvite(newFormValues);
        JOEvents.CANDIDATE_IMPORTED_CSV({ ttId, ttName, totalImported: rows });
    };

    const handleFormInvite = (newFormInvite) => {
        setFormInvite(newFormInvite, isCSVInvitation);
    };

    return (
        <div>
            <div className={classes.inviteWrapper}>
                <InviteForm
                    slug={scriptSlug}
                    assessment={audition}
                    context={inviteCandidatesTabCtx}
                    defaultRowCount={3}
                    onAllInvitationsSent={loadAssessmentStats}
                />
            </div>
            <div className={classes.inviteButtonsWrapper}>
                <ImportCSVForm
                    csvError={csvError}
                    onUpload={onUploadCSV}
                    assessment={audition}
                    context={inviteCandidatesTabCtx}
                />
                <div className={classes.inviteByEmailActionRoot}>
                    <TestToMyselfButton className="u-mrg--rx2" audition={audition} slug={scriptSlug} />
                    {
                        errorMessage && (
                            <div className={classes.errorMessage}>
                                <i className="material-icons">
                                    info_outline
                                </i> {errorMessage}
                            </div>
                        )
                    }
                    <Button
                        className={classes.inviteButton}
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={inviteViaEmailForm.length === 0 || loading || hasInvalidPhoneNumbers || expired || !valid}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    className={classes.loader}
                                    size={25}
                                    thickness={3}
                                />
                            ) : (
                                <>
                                    Send {inviteViaEmailForm.length || ''}&nbsp;{checkPluralNew(inviteViaEmailForm.length, 'invitation')}
                                </>
                            )
                        }
                    </Button>
                </div>
            </div>
            <InviteResultsDialog
                errorLimit={errorLimit}
                open={showResponseInviteDialog}
                sentData={successfullySentData}
                onClose={toggleResponseInviteDialog}
            >
                <Button
                    color="primary"
                    className="u-txt--bold u-mrg--tx2"
                    onClick={goToAssessmentResults}
                >
                    Go to Candidates
                </Button>
            </InviteResultsDialog>
            <AddNewTagsDialog
                open={showNewTagsDialog}
                onClose={onCloseNewTagsDialog}
                onAddTags={onAddNewTags}
                newTags={tagsToCreate}
                totalTags={totalTags}
            />
        </div>
    );
});

export default withStyles(styles)(withRouter(EmailAssessmentInviteForm));

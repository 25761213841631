import React, { useEffect, useState } from 'react';

// material components
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import AddIcon from 'img/add.svg';

import { clone } from 'helper/commonFunctions';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import Typography from '@mui/material/Typography';
import {
    changeScreeningMultipleChoiceAnswer,
    createScreeningMultipleChoiceAnswer,
    removeScreeningMultipleChoiceAnswer
} from 'requests/QuestionsRequests';
import MultipleChoiceInput from './MultipleChoiceInput';

import styles from './styles';

let timerId = null;
let timerIdQuestion = null;

let prevKey = '';

const MultipleChoiceAnswerType = ({
    question: { id, answers: answersProps, singleChoice, valid: questionValid },
    fetchQuestion, classes, setSaving, changeQuestion
}) => {
    const [answers, setAnswers] = useState([]);
    const [autoGraded, setAutoGrade] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);

    useEffect(() => {
        setAutoGrade(singleChoice);
        setAnswers(answersProps || []);
    }, [id, singleChoice]);

    const setChoiceByIndex = (index, newValue) => {
        setAnswers((oldAnswers) => {
            const newAnswers = clone(oldAnswers);
            newAnswers[index] = { ...newAnswers[index], ...newValue };
            return newAnswers;
        });
    };

    const onChange = (index, key, newValue, withoutRequest = false) => {
        const { id: answerId } = answers[index];
        setChoiceByIndex(index, { [key]: newValue });
        if (withoutRequest) return;
        if (prevKey === key) clearTimeout(timerId);
        prevKey = key;
        setSaving(true);
        timerId = setTimeout(async () => {
            clearTimeout(timerIdQuestion);
            timerIdQuestion = setTimeout(fetchQuestion, 1000);
            const { data } = await changeScreeningMultipleChoiceAnswer(id, answerId, key !== 'ziggeo' ? { [key]: newValue } : { ziggeoToken: newValue.token });
            const changedKeys = { valid: data.valid };
            if (key !== 'title') changedKeys[key] = data[key];
            setChoiceByIndex(index, changedKeys);
            setSaving(false);
        }, 300);
    };

    const addAnswer = (e) => {
        e.preventDefault();
        setActionLoading(true);
        setSaving(true);
        createScreeningMultipleChoiceAnswer(id)
            .then(({ success, data }) => {
                if (success && data) {
                    const newAnswers = clone(answers);
                    newAnswers.push(data);
                    setAnswers(newAnswers);
                    fetchQuestion();
                }
                setActionLoading(false);
                setSaving(false);
            })
            .catch(() => {
                setActionLoading(false);
                setSaving(false);
            });
    };

    const removeAnswer = (e, key) => {
        e.preventDefault();
        const { id: answerId } = answers[key];
        setActionLoading(true);
        setSaving(true);
        removeScreeningMultipleChoiceAnswer(id, answerId)
            .then(() => {
                const newAnswers = clone(answers);
                newAnswers.splice(key, 1);
                setAnswers(newAnswers);
                fetchQuestion();
                setActionLoading(false);
                setSaving(false);
            })
            .catch(() => {
                setActionLoading(false);
                setSaving(false);
            });
    };

    const onChangeAutograded = async ({ target: { checked } }) => {
        setAutoGrade(!checked);
        await changeQuestion({ singleChoice: !checked });
        fetchQuestion();
    };

    const countValid = answers.filter(({ title, correct }) => (autoGraded ? (title && correct !== null) : title)).length;

    return (
        <div className="u-mrg--tx5 u-mrg--bx5">
            <Typography variant="h6">Answer Choices</Typography>
            <div className={classes.warningText}>
                Select one or multiple correct answers for this question
            </div>
            <SwitchBrandWithLabel
                label="Enable multiple correct answers"
                checked={!autoGraded}
                className="u-mrg--tx1 u-mrg--bx1"
                onChange={onChangeAutograded}
            /><br />
            {
                Boolean(answers && answers.length) && answers.map((item, index) => {
                    const { id: answerId } = item;
                    return (
                        <MultipleChoiceInput
                            key={answerId}
                            item={item}
                            index={index}
                            answers={answers}
                            onChange={onChange}
                            autoGraded={autoGraded}
                            removeAnswer={removeAnswer}
                            actionLoading={actionLoading}
                        />
                    );
                })
            }
            <div className="u-mrg--bx2 u-mrg--tx2">
                <Button
                    onClick={addAnswer}
                    id="qa-add-mlc-ans"
                    color="primary"
                    disabled={actionLoading}
                    className={classes.addBtn}
                >
                    <img src={AddIcon} alt="x" />Add Choice
                </Button>
            </div>
            {
                !questionValid && (
                    <>
                        {
                            countValid < 2 && (
                                <p className={classes.labelError}>Please add {countValid ? 'more ' : ''}valid answer choices.</p>
                            )
                        }
                        {
                            autoGraded && countValid >= 2 && !answers.some(({ correct }) => correct) && (
                                <p className={classes.labelError}>Please add correct choices.</p>
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

export default withStyles(styles)(MultipleChoiceAnswerType);

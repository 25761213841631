import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import WarningBanner from 'components/assessments_pages/WarningBanner';
import ButtonBase from '@mui/material/ButtonBase';

const CandidateFeedbackBanner = observer(({ interviewSlug, history, setShowFeedbackBanner }) => {
    const storageKey = `${interviewSlug}-feedback-banner-closed`;
    const [opened, setOpened] = useState(false);

    const { loggedUser: { roleType: userRoleType } } = useContext(appCtx);

    const hasEditPermissions = (userRoleType === 'owner' || userRoleType === 'admin');

    useEffect(() => {
        const item = localStorage.getItem(storageKey);
        if (!item) {
            setOpened(true);
        }
        setShowFeedbackBanner(!item);
    }, [interviewSlug]);

    if (!opened) return null;

    const onButtonClick = () => {
        history.push({
            pathname: '/company-account',
            state: { scrollToEnd: true }
        });
    };

    return (
        <WarningBanner
            label={
                hasEditPermissions
                    ? (
                        <>
                            <b>Candidate Feedback feature has been turned off.</b>&nbsp;
                            <ButtonBase onClick={onButtonClick}>
                                Authorize from the company account.
                            </ButtonBase>
                        </>
                    )
                    : (
                        <>
                            <b>Candidate Feedback feature has been turned off.</b>&nbsp;
                            <span>
                                        Contact your admin to authorize.
                            </span>
                        </>
                    )

            }
            onClose={() => {
                setOpened(false);
                localStorage.setItem(storageKey, true);
                setShowFeedbackBanner(false);
            }}
        />
    );
});

export default CandidateFeedbackBanner;

import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import 'react-circular-progressbar/dist/styles.css';
import TextInput from 'libraries/TextInput';

import styles from './styles';

const TTBuilderInput = ({ classes, value, onChange }) => (
    <TextInput
        label="Role Title"
        multiline
        maxLength={80}
        InputLabelProps={{
            classes: {
                shrink: classes.roleInputLabelShrink,
                root: classes.roleInputLabel
            }
        }}
        InputProps={{
            classes: {
                root: classes.roleInputRoot,
                input: classes.roleInputInput,
                notchedOutline: classes.roleInputNotchedOutline
            }
        }}
        helperTextClassName={classes.roleInputHelperText}
        helperText="eg. Admin Assistant, Data Analyst or Sales Manager"
        onChange={e => onChange(e.target.value)}
        value={value}
        variant="outlined"
    />
);

export default withTheme(withStyles(styles)(TTBuilderInput));

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    appBar: {
        height: 64,
        background: '#FFFFFF',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    desktopLink: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
        cursor: 'pointer',
        color: theme.palette.grey[600],
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: 14,
        '&:first-child': {
            paddingLeft: 0
        },
        '&:hover, &:focus, &:active': {
            color: theme.palette.grey[900],
            background: 'none'
        }
    },
    desktopLinkActive: {
        color: theme.palette.grey[900]
    },
    left: {
        display: 'flex',
        alignItems: 'center'
    },
    right: {
        display: 'flex',
        alignItems: 'center'
    },
    desktopLinkDisabled: {
        display: 'flex',
        alignItems: 'center',
        padding: 16,
        color: theme.palette.grey[600],
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: 14,
        userSelect: 'none'
    }
}));

export default useStyles;

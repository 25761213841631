export default theme => ({
    currentPlanLabel: {
        fontSize: 18,
        marginBottom: 12
    },
    planName: {
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    infoLabel: {
        display: 'inline-block',
        marginRight: 24,
        fontWeight: 600,
        color: theme.palette.grey[500],
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    },
    infoValue: {
        color: theme.palette.grey[900]
    },
    actionWrapper: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        paddingTop: 26,
        marginTop: 30
    },
    creditCard: {
        marginBottom: 0,
        fontWeight: 600,
        color: theme.palette.grey[900]
    },
    actionButtonsWrapper: {
        marginTop: 7,
        marginLeft: -8
    }
});

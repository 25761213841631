import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { getJobAdderCustomFields, setJobAdderCustomField } from 'requests/SubscriptionRequests';
import SelectOutlined from 'libraries/Select';

const styles = () => ({
    select: {
        maxWidth: 380,
        marginBottom: 35
    }
});


const SelectCustomScoreField = ({ classes, flashMessage, scoreFieldId, setScoreFieldId }) => {
    const [scoreFields, setScoreFields] = useState([]);

    useEffect(() => {
        getScoreFields();
    }, []);

    const getScoreFields = () => {
        getJobAdderCustomFields()
            .then(({ success, data }) => {
                if (data && success) {
                    data.unshift({ id: null, name: 'Not selected' });
                    setScoreFields(data);
                }
            })
            .catch(() => { flashMessage('Something went wrong', 'error'); });
    };

    const handleChangeScoreField = (e) => {
        const { value } = e.target;
        setJobAdderCustomField(value)
            .then(({ success }) => {
                if (success) {
                    setScoreFieldId(value);
                }
            })
            .catch(() => { flashMessage('Something went wrong', 'error'); });
    };

    return (
        <SelectOutlined
            disabled={!scoreFields.length}
            className={classes.select}
            variant="outlined"
            value={scoreFieldId}
            onChange={handleChangeScoreField}
            label="Score Custom Field"
            options={scoreFields.map(({ name, id }) => ({ value: id, label: name }))}
        />
    );
};

export default withStyles(styles)(SelectCustomScoreField);

export default theme => ({
    wrapper: {
        padding: '20px 30px 40px 23px'
    },
    header: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center'
    },
    answer: {
        fontSize: 14,
        padding: '12px 18px'
    },
    answerWrapper: {
        marginBottom: 30,
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    boardNoContent: {
        marginTop: -80
    },
    boardImg: {
        width: 50
    }
});

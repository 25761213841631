import { makeStyles } from '@mui/styles';

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            height: 56
        }
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    mobileLink: {
        fontSize: 14,
        width: '100%',
        padding: '8px 16px',
        color: theme.palette.grey[700],
        '&:hover, &:focus': {
            color: theme.palette.grey[900]
        }
    },
    mobileLinkActive: {
        fontWeight: 600,
        color: theme.palette.grey[900]
    },
    nestedList: {
        marginTop: 5
    },
    nestedItem: {
        color: theme.palette.grey[700],
        whiteSpace: 'normal',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderTop: `1px solid ${theme.palette.grey[200]}`
    },
    nestedItemHeader: {
        fontWeight: 600,
        color: theme.palette.grey[900]
    },
    popoverHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '18px 15px 15px'
    },
    userInfo: {
        marginLeft: 10,
        fontSize: 14,
        color: theme.palette.grey[700]
    },
    userInfoName: {
        color: theme.palette.grey[900],
        fontSize: 16,
        fontWeight: 'bold'
    },
    userInfoEmail: {
        color: theme.palette.grey[700],
        fontWeight: 600,
        fontSize: 12,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 170
    }
}));

export default useStyles;

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import Tooltip from 'libraries/Tooltip';
import HelpIcon from 'img/helpIcon.svg';
import { subscriptionDialogStoreCtx } from '../../store';
import styles from './styles';

import DoneIcon from './icon.svg';

import {
    enterpriseFeatures,
    growthFeatures,
    payAndGoFeatures,
    pilotFeatures,
    plusFeatures,
    candidatePacksFeatures,
    professionalFeatures,
    starterFeatures,
    premiumFeatures,
    premiumPlusFeatures
} from './features';

const PlanFeatureList = observer(({ classes }) => {
    const { selectedPlan } = useContext(subscriptionDialogStoreCtx);
    if (!selectedPlan) return null;

    const { name: planName } = selectedPlan;
    const featureLabel = 'Assessment';

    let listOfFeatures = [];

    switch (planName.toLowerCase()) {
        case 'free trial':
        case 'pilot':
            listOfFeatures = pilotFeatures(featureLabel);
            break;
        case 'starter':
            listOfFeatures = starterFeatures(featureLabel);
            break;
        case 'plus':
            listOfFeatures = plusFeatures(featureLabel);
            break;
        case 'pay & go':
            listOfFeatures = payAndGoFeatures(featureLabel);
            break;
        case 'candidate pack':
            listOfFeatures = candidatePacksFeatures(featureLabel);
            break;
        case 'growth':
            listOfFeatures = growthFeatures(featureLabel);
            break;
        case 'professional':
            listOfFeatures = professionalFeatures(featureLabel);
            break;
        case 'enterprise':
            listOfFeatures = enterpriseFeatures(featureLabel);
            break;
        case 'premium':
            listOfFeatures = premiumFeatures(featureLabel);
            break;
        case 'premium+':
            listOfFeatures = premiumPlusFeatures(featureLabel);
            break;
        default:
            break;
    }

    const getFeatureLabel = feature => (typeof feature === 'string'
        ? feature
        : (
            <>
                <span>{feature.label}</span>
                <Tooltip label={feature.tooltip}>
                    <img className="u-mrg--lx2" src={HelpIcon} alt="" />
                </Tooltip>
            </>
        ));

    const renderFeatureList = categories => categories.map(category => (
        <div key={category}>
            <div className={classes.categoryName}>{category}</div>
            {
                listOfFeatures[category].map(item => (
                    <div className={classes.feature} key={item.label || item}>
                        <img src={DoneIcon} alt="!" />
                        {getFeatureLabel(item)}
                    </div>
                ))
            }
        </div>
    ));

    const rangeIndex = Math.ceil(Object.keys(listOfFeatures).length / 2);
    const firstSet = Object.keys(listOfFeatures).splice(0, rangeIndex);
    const secondSet = Object.keys(listOfFeatures).splice(rangeIndex);

    return (
        <div className={clsx('u-txt--nowrap', classes.featuresWrapper)}>
            <div>
                {renderFeatureList(firstSet)}
            </div>
            <div>
                {renderFeatureList(secondSet)}
            </div>
        </div>
    );
});

export default withStyles(styles)(PlanFeatureList);

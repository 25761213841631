import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import DuplicatesContent from './DuplicatesContent';
import QuestionsWrapperDuplicated from './QuestionsWrapperDuplicated';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        position: 'relative',
        overflow: 'auto',
        minHeight: 400,
        width: '100%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'block'
        }
    },
    leftPanel: {
        width: '50%',
        minHeight: 550,
        minWidth: 650,
        paddingBottom: 50,
        backgroundColor: theme.palette.grey[50],
        position: 'relative',
        padding: '16px 0',
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderRight: 'none',
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        }
    },
    contentPanel: {
        width: '50%',
        position: 'relative',
        padding: '16px 0 40px',
        minWidth: 550,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '26px 30px 40px'
        }
    }
});

const ContentDuplicatedQuestions = observer(({ classes, theme, ...other }) => (
    <div className={classes.wrapper}>
        <div className={classes.leftPanel}>
            <QuestionsWrapperDuplicated {...other} />
        </div>
        <div className={classes.contentPanel}>
            <DuplicatesContent {...other} />
        </div>
    </div>
));

export default withTheme(withStyles(styles)(ContentDuplicatedQuestions));

export default (theme => ({
    closeIcon: {
        padding: 0,
        height: 34,
        width: 34
    },
    textInput: {
        '&:not(:first-child)': {
            marginLeft: 8
        }
    },
    textInputLabel: {
        fontSize: 12
    },
    expandIcon: {
        color: theme.palette.primary.main
    },
    pointsLabel: {
        marginRight: 5,
        color: theme.palette.grey[500]
    },
    input: {
        margin: '5px 0'
    },
    pointsInput: {
        maxWidth: 120,
        marginLeft: 10
    },
    attachmentWrapper: {
        margin: '0 6px',
        width: '100%'
    },
    noTicketsLabel: {
        fontWeight: 700,
        color: theme.palette.grey[500]
    }
}));

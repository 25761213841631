export default theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: 20
        }
    },
    folderTitle: {
        margin: '6px 0',
        fontWeight: 700,
        fontSize: 16,
        whiteSpace: 'pre-wrap'
    },
    greyText: {
        color: theme.palette.grey[500],
        fontWeight: 700
    },
    requestedText: {
        fontSize: 12,
        color: theme.palette.grey[900],
        fontWeight: 600,
        marginLeft: 10
    }
});

// node modules
import React, { Component } from 'react';
import Truncate from 'react-truncate';
import nl2br from 'react-nl2br';
import io from 'socket.io-client';

// material components
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { withStyles } from '@mui/styles';

// local files and components
import { clone, generateLanguageArray } from 'helper/commonFunctions';
import { editCodeChallengeQuestion } from 'requests/QuestionsRequests';
import { runTestCases } from 'requests/ScriptRequests';

import CodeEditorQuestion from 'components/marketplace/CodeEditorQuestion';
import DropDownLanguages from './components/DropDownLanguages';
import CodeSettings from './components/CodeSettings';
import ResultPanel from './components/ResultPanel';
import GeneratorAndTestCasesPanel from './components/GeneratorAndTestCasesPanel';
import DeleteTestCaseDialog from './components/ModalWindows/DeleteTestCaseConfirmationDialog';
import ReviewTheFollowingDialog from './components/ModalWindows/ReviewTheFollowingDialog';

import styles from './styles';


const TEXT_HEIGHT = 280;
const SCROLL_DISTANCE = 10;

const LANGUAGE_TO_IDENTIFIRE = {
    nodejs: 'nodejs',
    php: 'php',
    'c++': 'cpp',
    python3: 'python37',
    ruby: 'ruby',
    go: 'go',
    c: 'c',
    java: 'java',
    'c#': 'dotnet'
};

function removeHTMLTags(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const textContent = doc.body.textContent || '';
    return textContent.trim(); // Trim any leading or trailing whitespace
}

class CodeEditor extends Component {
    socket = {};

    runCodeMassageId = '';

    waitingCodeChallengeResultTimer = null;

    timerId = null;

    state = {
        options: {
            lineNumbers: true,
            theme: 'material',
            keyMap: 'sublime',
            mode: '',
            readOnly: false,
            autoCloseBrackets: false,
            firstLineNumber: 1,
            lineWrapping: false,
            tabSize: 4,
            extraKeys: {
                Ctrl: 'autocomplete'
            }
        },
        activeLanguage: '',
        activeLanguageIndex: 0,
        codeEditor: {
            id: null,
            header: '',
            body: '',
            footer: '',
            language: ''
        },
        editedCase: null,
        currentCase: 'default',
        activeCase: 0,
        titleOpened: false,
        truncated: false,
        shadow: false,
        runningTest: false,
        error: false,
        testCasesDrawerOpen: false,
        deleteTestCaseDialogOpen: false,
        reviewTheFollowingDialogOpen: false,
        testCaseToDeleteFromTable: null
    };

    componentDidMount() {
        const { activeLanguageIndex, options } = this.state;
        const { codeChallengeQuestions } = this.props;
        this.setState({
            codeEditor: codeChallengeQuestions[activeLanguageIndex],
            activeLanguage: codeChallengeQuestions[activeLanguageIndex].language
        });

        if (!options.mode) {
            options.mode = generateLanguageArray(null, codeChallengeQuestions[activeLanguageIndex].language);
            this.setState({ options });
        }

        this.connectToCodeChallengeSocket();
    }

    componentWillUnmount() {
        const { clearTestCases } = this.props;

        if (this.scroll) {
            this.scroll.removeEventListener('scroll', this.scrollFunction);
        }

        clearTestCases();

        this.closeCodeChallengeSocket();
        this.setState({ error: false, runningTest: false });
    }

    closeCodeChallengeSocket = () => {
        if (this.socket) {
            this.socket.close();
            this.setState({ runningTest: false });
        }
    };

    setLangParams = (activeLanguage) => {
        const { options } = this.state;
        options.mode = generateLanguageArray(null, activeLanguage);
        this.setActiveLanguage(activeLanguage);
        this.setState({ options });
    };

    changeAutoCloseBrackets = (closeBracketsState) => {
        const { options } = this.state;
        options.autoCloseBrackets = closeBracketsState;
        this.setState({ options });
    }

    changeTabSpacing = (tabSize) => {
        const { options } = this.state;
        options.tabSize = tabSize;
        this.setState({ options });
    }

    changeKeyMaps = (keyMap) => {
        const { options } = this.state;
        options.keyMap = keyMap;
        this.setState({ options });
    }

    changeActiveCase = activeCase => this.setState({ activeCase })

    chooseCase = (currentCase, idEdit) => {
        if (idEdit) {
            const { cases } = this.props;
            const editedCase = cases.find(({ id }) => id === idEdit);
            this.setState({ currentCase, editedCase });
        } else {
            this.setState({ currentCase, editedCase: null });
        }
    }

    toggleTitleOpen = (e) => {
        const { titleOpened } = this.state;
        e.preventDefault();
        this.setState({ titleOpened: !titleOpened });
        setTimeout(() => {
            if (this.paper.offsetHeight >= TEXT_HEIGHT) {
                this.setState({ shadow: true });
            }
            this.scroll = document.querySelector('.collap').querySelectorAll('div')[1];
            this.scroll.addEventListener('scroll', this.scrollFunction);
        }, 100);
    };

    scrollFunction = () => {
        const defaultScrollPosition = this.scroll.scrollTop + this.scroll.offsetHeight;
        const currentScrollPosition = this.scroll.scrollHeight - defaultScrollPosition;
        if (currentScrollPosition <= SCROLL_DISTANCE) {
            this.setState({ shadow: false });
        } else {
            this.setState({ shadow: true });
        }
    }


    handleTruncate = (truncated) => {
        const { truncated: truncatedState } = this.state;
        if (truncated && !truncatedState) {
            this.setState({ truncated, titleOpened: !truncated });
        } else if (!truncated) {
            this.setState({ titleOpened: !truncated });
        }
    };

    saveCode = () => {
        const { codeEditor, activeLanguage } = this.state;
        const { codeChallengeQuestions, onClose } = this.props;
        const newCodeChallenge = [...codeChallengeQuestions];
        const index = newCodeChallenge.findIndex(({ language }) => (language === activeLanguage));
        newCodeChallenge.splice(index, 1, { ...codeEditor });
        const isNoOneFieldEmpty = newCodeChallenge.every(({ header, body, footer }) => (header.trim() || footer.trim() || body.trim()));

        if (!isNoOneFieldEmpty) {
            this.setState({ reviewTheFollowingDialogOpen: true });
        } else {
            onClose();
        }
    };

    setActiveLanguage = (name) => {
        const { codeChallengeQuestions } = this.props;
        const index = codeChallengeQuestions.findIndex(({ language: languageItem }) => (languageItem === name.toLowerCase()));
        const { language, ...codeEditorCurrent } = codeChallengeQuestions[index];

        this.setState({
            activeLanguage: language,
            activeLanguageIndex: index,
            codeEditor: codeEditorCurrent
        });
    };

    runCode = () => {
        const { codeEditor, activeLanguage } = this.state;
        const { flashMessage, question, isCMS } = this.props;

        this.setState({ runningTest: true });

        this.waitingCodeChallengeResultTimer = setTimeout(() => {
            this.setState({ runningTest: false });
            flashMessage('Something went wrong. Try again.');
        }, 600000);

        if (this.socket) {
            runTestCases(question.id, {
                socketId: this.socket.id,
                activeLanguage: LANGUAGE_TO_IDENTIFIRE[activeLanguage],
                codeEditor
            }, isCMS).then(({ success, data }) => {
                if (success && data.messageId) {
                    this.runCodeMassageId = data.messageId;
                }
            }).catch(() => {
                flashMessage('Something went wrong');
                this.setState({ runningTest: false });
            }).finally(() => {
                clearTimeout(this.waitingCodeChallengeResultTimer);
            });
        } else {
            flashMessage('Something went wrong.');
        }
    };

    connectToCodeChallengeSocket = () => {
        const { setTestCases } = this.props;
        this.socket = io(process.env.REACT_APP_CODE_CHALLENGE_SOCKETIO_URL);
        this.socket.on('connect', () => {
            console.log('connect', this.socket.connected, this.socket.id);
        });
        this.socket.on('code-run-result', (data) => {
            const runCodeResponse = JSON.parse(data);
            if (runCodeResponse.submitId === this.runCodeMassageId) {
                const { runningTest } = this.state;
                const { cases } = this.props;
                const { tests, error } = runCodeResponse;
                clearTimeout(this.waitingCodeChallengeResultTimer);
                if (runningTest && tests) {
                    const newCases = cases.map((item) => {
                        const i = tests.findIndex(({ caseNo }) => caseNo === item.id);
                        item.stderr = tests[i].stderr;
                        item.pass = tests[i].pass;
                        item.stdout = tests[i].stdout;
                        item.completed = true;
                        return item;
                    });
                    this.setState({
                        error: false,
                        runningTest: false
                    });
                    setTestCases(newCases);
                } else if (error) {
                    this.setState({ error: error.errorDescription, runningTest: false });
                } else {
                    const { flashMessage } = this.props;
                    this.setState({ runningTest: false });
                    flashMessage('Something went wrong');
                }
            } else {
                console.log('Outdated response', runCodeResponse.submitId);
            }
        });
    };

    onChangeCodeEditor = (data, key) => {
        const { codeEditor, activeLanguageIndex } = this.state;
        const { onChangeCodeEditorQuestion, setCodeChallengeQuestions } = this.props;
        const newCodeEditor = clone(codeEditor);
        newCodeEditor[key] = data;

        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
            setCodeChallengeQuestions((prevCodeChallengeQuestions) => {
                const newCodeChallengeQuestions = clone(prevCodeChallengeQuestions);
                newCodeChallengeQuestions[activeLanguageIndex] = { ...newCodeChallengeQuestions[activeLanguageIndex], ...newCodeEditor };
                return newCodeChallengeQuestions;
            });
            onChangeCodeEditorQuestion(newCodeEditor.id, { [key]: data });
        }, 500);

        this.setState({ codeEditor: newCodeEditor });
    };

    codeGeneratorCallback = (data) => {
        const { activeLanguageIndex, codeEditor } = this.state;
        const { codeChallengeQuestions, setCodeChallengeQuestions, isCMS } = this.props;

        const newQuestions = codeChallengeQuestions.map((question, index) => {
            editCodeChallengeQuestion(question.id, data[index], isCMS);
            return { ...question, ...data[index] };
        });
        setCodeChallengeQuestions(newQuestions);
        this.setState({ codeEditor: { ...codeEditor, ...data[activeLanguageIndex] } });
    }

    handleDrawerOpen = () => {
        this.setState({ testCasesDrawerOpen: true });
    };

    handleDrawerClose = () => {
        this.setState({ testCasesDrawerOpen: false });
    };

    setEditedCase = (testCase) => {
        this.setState({ editedCase: testCase });
    }

    setDeleteTestCaseDialogOpen = (value) => {
        this.setState({ deleteTestCaseDialogOpen: value });
    }

    setTestCaseToDeleteFromTable = (id) => {
        this.setState({ testCaseToDeleteFromTable: id });
    }

    handleCloseDeleteTestCaseDialog = () => {
        this.setDeleteTestCaseDialogOpen(false);
        this.setTestCaseToDeleteFromTable(null);
    }

    removeTestCase = (caseToDelete, questionId) => {
        const {
            props: { deleteTestCase },
            state: { editedCase },
            setTestCaseToDeleteFromTable
        } = this;
        deleteTestCase(caseToDelete, questionId);
        const editedCaseDeleted = editedCase && editedCase.id === caseToDelete.id;
        if (editedCaseDeleted) this.setState({ currentCase: 'default' });
        setTestCaseToDeleteFromTable(null);
    }

    render() {
        const {
            props: {
                questionsNumber, setTestData, cases,
                editTestCase, question, question: { sort, description },
                languages, codeChallengeQuestions, classes, onClose,
                dataForEvents, isCMS
            },
            state: {
                options, currentCase,
                activeCase, editedCase, titleOpened,
                truncated, shadow, runningTest,
                error, codeEditor, activeLanguage, testCasesDrawerOpen,
                deleteTestCaseDialogOpen, reviewTheFollowingDialogOpen,
                testCaseToDeleteFromTable
            },
            setLangParams, chooseCase,
            changeActiveCase, changeTabSpacing, handleTruncate,
            changeKeyMaps, changeAutoCloseBrackets, toggleTitleOpen, codeGeneratorCallback,
            setDeleteTestCaseDialogOpen, setEditedCase, removeTestCase, saveCode, setActiveLanguage, setTestCaseToDeleteFromTable
        } = this;

        const codeChallengeIsNotEmpty = codeChallengeQuestions.some(({ header, body, footer }) => (header || footer || body));

        return (
            <Dialog
                fullWidth
                fullScreen
                open
            >
                <div className="u-dsp--f">
                    <GeneratorAndTestCasesPanel
                        {...{
                            isCMS,
                            question,
                            cases,
                            setTestData,
                            editTestCase,
                            setDeleteTestCaseDialogOpen,
                            codeGeneratorCallback,
                            editedCase,
                            runningTest,
                            setEditedCase,
                            chooseCase,
                            currentCase,
                            codeChallengeIsNotEmpty,
                            dataForEvents
                        }}
                        handleDrawerOpen={this.handleDrawerOpen}
                        runTest={this.runCode}
                    />
                    <div className={classes.codeEditorContainer}>
                        <Paper classes={{ root: (titleOpened && truncated) ? classes.paperShadow : classes.paper }}>
                            <div className={classes.collapWrapper}>
                                <Typography
                                    classes={{ root: classes.typeHeader }}
                                    variant="h4"
                                    component="h3"
                                >
                                    Question {sort}/{questionsNumber}
                                </Typography>
                                <Collapse
                                    classes={{
                                        root: classes.collapse,
                                        wrapperInner: classes.collapse
                                    }}
                                    className="collap"
                                    in={titleOpened || !truncated}
                                    collapsedSize={truncated ? '42px' : '0px'}
                                    style={{ maxWidth: '820px' }}
                                >
                                    {!titleOpened && (
                                        <div>
                                            <Truncate
                                                lines={2}
                                                ellipsis={(
                                                    <span>...
                                                        <button
                                                            type="button"
                                                            onClick={toggleTitleOpen}
                                                            className="c-button-link c-link--blue u-txt--bold"
                                                        >
                                                            (show more)
                                                        </button>
                                                    </span>
                                                )}
                                                onTruncate={handleTruncate}
                                            >
                                                {description && nl2br(removeHTMLTags(description))}
                                            </Truncate>
                                        </div>
                                    )}
                                    <div ref={(paper) => { this.paper = paper; }}>
                                        {titleOpened && (description && nl2br(removeHTMLTags(description)))}
                                    </div>
                                    {titleOpened && truncated && (
                                        <button
                                            style={{
                                                position: 'absolute',
                                                zIndex: 1,
                                                bottom: 5
                                            }}
                                            type="button"
                                            onClick={toggleTitleOpen}
                                            className="c-button-link c-link--blue u-txt--bold u-mrg--lx1"
                                        >
                                            {' (show less)'}
                                        </button>
                                    )}
                                    {!titleOpened && !truncated && (
                                        description && nl2br(removeHTMLTags(description))
                                    )}
                                </Collapse>
                            </div>
                            <div className={classes.buttonWrapper}>
                                <div className={classes.betaButton}>
                                    BETA
                                </div>
                                <Button
                                    onClick={this.saveCode}
                                    classes={{
                                        root: classes.saveButton,
                                        label: classes.labelBtnCenter
                                    }}
                                >
                                    Save Code
                                </Button>
                            </div>
                            {
                                titleOpened && truncated && shadow && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            bottom: 19,
                                            height: 80,
                                            width: '100%',
                                            backgroundImage: 'linear-gradient(to bottom, rgba(47, 53, 69, 0.48), #2f3545)'
                                        }}
                                    />
                                )
                            }
                        </Paper>
                        <div className={classes.langPanel}>
                            {activeLanguage && (
                                <DropDownLanguages {...{ activeLanguage, languages, setLangParams }} />
                            )}
                            <CodeSettings
                                changeTabSpacing={changeTabSpacing}
                                changeKeyMaps={changeKeyMaps}
                                changeAutoCloseBrackets={changeAutoCloseBrackets}
                            />
                        </div>
                        <ResultPanel
                            open={testCasesDrawerOpen}
                            handleDrawerOpen={this.handleDrawerOpen}
                            handleDrawerClose={this.handleDrawerClose}
                            error={error}
                            runningTest={runningTest}
                            cases={cases}
                            activeCase={activeCase}
                            changeActiveCase={changeActiveCase}
                            setTestCaseToDeleteFromTable={setTestCaseToDeleteFromTable}
                            runTest={this.runCode}
                            setDeleteTestCaseDialogOpen={setDeleteTestCaseDialogOpen}
                        >
                            <CodeEditorQuestion
                                options={options}
                                header={codeEditor.header}
                                body={codeEditor.body}
                                footer={codeEditor.footer}
                                onChange={this.onChangeCodeEditor}
                            />
                        </ResultPanel>
                        <ReviewTheFollowingDialog
                            codeChallengeQuestions={codeChallengeQuestions}
                            open={reviewTheFollowingDialogOpen}
                            handleClose={() => { this.setState({ reviewTheFollowingDialogOpen: false }); }}
                            closeCodeChallengeModal={onClose}
                            saveCode={saveCode}
                            setActiveLanguage={setActiveLanguage}
                        />
                        <DeleteTestCaseDialog
                            open={deleteTestCaseDialogOpen}
                            handleClose={this.handleCloseDeleteTestCaseDialog}
                            caseToDelete={testCaseToDeleteFromTable || editedCase}
                            {...{
                                question,
                                setEditedCase,
                                removeTestCase,
                                changeActiveCase,
                                setTestCaseToDeleteFromTable
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(CodeEditor);

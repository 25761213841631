import React from 'react';

const Icon = ({ color = '#4a4b4f', ...other }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M2 12.2727C2 12.2727 5.63636 5 12 5C18.3636 5 22 12.2727 22 12.2727C22 12.2727 18.3636 19.5455 12 19.5455C5.63636 19.5455 2 12.2727 2 12.2727Z" stroke={color} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.9999 15C13.5062 15 14.7272 13.7789 14.7272 12.2727C14.7272 10.7665 13.5062 9.54544 11.9999 9.54544C10.4937 9.54544 9.27267 10.7665 9.27267 12.2727C9.27267 13.7789 10.4937 15 11.9999 15Z" stroke={color} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Icon;

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import SelectOutlined from 'libraries/Select';
import Tooltip from 'libraries/Tooltip';
import { getCyberSimulatorUseCases } from 'requests/QuestionsRequests';

const styles = () => ({
    wrapper: {
        position: 'relative',
        marginTop: 30
    },
    wrapperBanner: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 1000,
        background: 'rgba(255, 255, 255, 0.6)'
    },
    tooltip: {
        textAlign: 'center',
        width: 210
    }
});


const SimulationDescription = ({
    classes, isCMS, changeQuestion, question: { linuxSimulationUseCase }
}) => {
    const [simulationUseCase, setSimulationUseCase] = useState(linuxSimulationUseCase);
    const [useCasesList, setUseCasesList] = useState([]);
    const disabled = !isCMS;

    useEffect(() => {
        getUseCases();
    }, []);

    const getUseCases = () => {
        getCyberSimulatorUseCases()
            .then(({ data, success }) => {
                if (data && success) {
                    setUseCasesList(data);
                }
            });
    };

    const onChange = (e) => {
        if (disabled) return;
        const { value } = e.target;
        setSimulationUseCase(value);
        changeQuestion({ linuxSimulationUseCase: value });
    };

    return (
        <div className={classes.wrapper}>
            <Typography variant="h6">Simulation task</Typography>
            {
                disabled && (
                    <Tooltip
                        tooltipClass={classes.tooltip}
                        label="Task is unable to be changed for simulations"
                    >
                        <div className={classes.wrapperBanner} />
                    </Tooltip>
                )
            }
            <SelectOutlined
                fullwidth
                className="u-mrg--bx5 u-mrg-tx0"
                variant="outlined"
                label="Task"
                isError={!simulationUseCase}
                value={simulationUseCase || ''}
                options={useCasesList.map(item => ({
                    value: item, label: item
                }))}
                onChange={onChange}
            />
        </div>
    );
};

export default withStyles(styles)(SimulationDescription);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import BoardNoContent from 'components/board/board_no_content';
import { checkHasUserPermission } from 'helper/commonFunctions';
import Button from '@mui/material/Button';
import { appCtx } from 'components/appStore';

import EmptyIcon from './icon.svg';
import EmptyShortlistIcon from './EmptyShortlistIcon.svg';

const useStyles = makeStyles(() => ({
    button: {
        minWidth: 'auto',
        height: 21,
        marginLeft: 5,
        padding: 0
    }
}));

const NoInvitationsContent = observer(({
    history, slug, hasCandidates,
    hasCompleted, hasArchived,
    audition, shortList = false
}) => {
    if (!audition) return null;
    const { company } = useContext(appCtx);
    const classes = useStyles();
    const { permissions } = audition;
    const invite = checkHasUserPermission(company, permissions, 'invite');
    const goToInvitations = () => {
        history.push(`/script/invite/${slug}/invitation`);
    };

    const goToArchived = () => {
        history.push(`/script/select/${slug}/archived`);
    };

    let header = 'No Candidates Invited';
    let subheader = (
        <>
            Invite your first candidates
            <Button
                color="primary"
                className={classes.button}
                onClick={goToInvitations}
            >
                here
            </Button>
        </>
    );

    if (hasCandidates && !hasCompleted && !hasArchived) {
        header = 'No Candidates Completions';
        subheader = (
            <>
                Review your candidate status
                <Button
                    color="primary"
                    className={classes.button}
                    onClick={goToInvitations}
                >
                    here
                </Button>
            </>
        );
    }

    if (hasCandidates && !hasCompleted && hasArchived) {
        header = 'No Candidates';
        subheader = (
            <>
                Check your archived candidates
                <Button
                    color="primary"
                    className={classes.button}
                    onClick={goToArchived}
                >
                    here
                </Button>
            </>
        );
    }

    if (shortList) {
        header = 'No candidates shortlisted';
        subheader = 'Candidates that you’ve shortlisted will show up here. Once shortlisted you can compare their assessment results and answers.';
    }

    return (
        <BoardNoContent
            header={header}
            classNameText="u-dsp--centered"
            text={(invite && audition.active) ? subheader : null}
            hideIconWrapper
            icon={<img src={shortList ? EmptyShortlistIcon : EmptyIcon} alt="empty-content" />}
        />
    );
});

export default withRouter(NoInvitationsContent);

import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import CandidatesSearch from 'components/job_or_assessment_settings/CandidatesSearch';

import ExportButton from '../../../assessment/components/ExportButton';
import { hiredCandidateCtx } from '../../HiredCandidates/hiredCandidateStore';
import HiredTableFilter from '../HiredTableFilter';
import useStyles from '../CandidatesSearchFilterBlock/styles';


const HiredSearchFilterBlock = observer(() => {
    const classes = useStyles();
    const {
        query, handleChangeFilter, fetchFiltersForHired,
        totalCount
    } = useContext(hiredCandidateCtx);

    useEffect(() => {
        fetchFiltersForHired();
    }, []);

    return (
        <div className={clsx(classes.infoWrapper)}>
            <CandidatesSearch
                className={classes.search}
                onChange={handleChangeFilter}
                value={query}
            />
            <div className="u-dsp--f u-ai--center">
                <ExportButton
                    isHired
                    disabled={!totalCount}
                    candidatesCount={totalCount}
                />
                <HiredTableFilter />
            </div>
        </div>
    );
});

export default HiredSearchFilterBlock;

export default theme => ({
    wrapper: {
        display: 'flex',
        minHeight: '100vh'
    },
    vervoeLogo: {
        position: 'fixed',
        left: 40,
        top: 23,
        zIndex: 101
    },
    banner: {
        display: 'none',
        backgroundColor: '#fff',
        zIndex: 100,
        top: 0,
        left: 0,
        position: 'fixed',
        width: 'calc(100% - 600px)',
        height: 65,
        [theme.breakpoints.down('lg')]: {
            width: 'calc(100% - 20px)'
        },
        '@media only screen and (min-width: 1280px) and (max-width: 1420px)': {
            display: 'block'
        },
        '@media only screen and (max-width: 820px)': {
            display: 'block'
        }
    },
    main: {
        flexGrow: 1,
        backgroundColor: '#fff',
        padding: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            padding: 48
        }
    },
    content: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: 430
        }
    },
    sidebar: {
        position: 'relative',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        width: 580,
        padding: '50px 69px',
        color: '#fff',
        backgroundColor: theme.palette.blue[800],
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        }
    },
    premiumSidebar: {
        padding: 0,
        '& > img': {
            height: '100%'
        }
    },
    header: {
        fontWeight: 700,
        fontSize: '32px',
        fontFamily: 'Gellix-Bold',
        color: theme.palette.yellow[300],
        maxWidth: 250,
        lineHeight: '40px',
        marginTop: 25
    },
    subheader: {
        fontSize: 18,
        fontWeight: 600,
        fontFamily: 'Gellix',
        margin: '18px 0',
        color: theme.palette.yellow[50]
    },
    feature: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        fontFamily: 'Gellix',
        fontWeight: 500,
        color: theme.palette.yellow[300],
        '&:not(:last-child)': {
            marginBottom: 10
        }
    },
    img: {
        position: 'absolute'
    },
    pic1: {
        top: 0,
        left: 0,
        width: 200
    },
    pic2: {
        top: 70,
        right: 0,
        width: 210
    },
    pic3: {
        bottom: 0,
        left: 0,
        width: 330
    },
    pic4: {
        bottom: 0,
        right: 0,
        width: 220
    }
});

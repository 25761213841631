import { action, observable, computed } from 'mobx';
import { arrayMove } from 'react-sortable-hoc';
import { clone } from 'helper/commonFunctions';
import { TYPING_TEST } from 'helper/constants';
import { addQuestion, deleteQuestion, loadAssessmentQuestions } from 'requests/ScriptRequests';
import { reorderQuestionAfter, reorderQuestionBefore } from 'requests/QuestionsRequests';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import AssessmentEvents from 'events/AssessmentEvents';
import { modifyScreeningQuestion } from 'helper/questionSettingsFunctions';

import { appStore } from '../../../../components/appStore';
import { assessmentSettingsStore } from '../../store';

class QuestionList { // the common class common for general and srceening questions
    @observable list = [];

    @observable activeItemIndex = 0;

    @observable isSavingItem = false;

    @observable isScreening = false;

    mode;

    constructor(mode) {
        this.mode = mode;
        this.isScreening = mode === 'screening';
    }

    @computed get hasTypingTest() {
        return this.list?.some(assessmentQuestion => assessmentQuestion.answerType === TYPING_TEST);
    }

    @action setSavingItem = (value) => {
        this.isSavingItem = value;
    };

    @action loadList = slug => loadAssessmentQuestions(slug, this.isScreening)
        .then(({ data, success }) => {
            if (success && data) {
                this.list = data;
                return data;
            }
            appStore.flashMessage(data.message);
        });


    @action setActiveItemIndex = (index) => {
        this.activeItemIndex = index;
    };

    @action setList = (newList) => {
        this.list = newList;
    };

    @action addItem = (scriptSlug, groupTitle, onSuccess, isScreening) => {
        this.setSavingItem(true);

        return addQuestion(scriptSlug, { groupTitle }, isScreening)
            .then(({ success, data }) => {
                const newList = clone(this.list);
                newList.push(data);
                if (success) {
                    this.setList(newList);
                    this.setActiveItemIndex(newList.length - 1);
                    this.setSavingItem(false);
                    assessmentSettingsStore.loadEditScript();
                    // assessmentSettingsStore.changeAuditionParameter('countManuallyAddedQuestions', assessmentSettingsStore.audition.countManuallyAddedQuestions + 1);
                    if (onSuccess) onSuccess();
                } else {
                    this.setSavingItem(false);
                    appStore.flashMessage(data.message);
                }
            })
            .catch(() => {
                this.setSavingItem(false);
                appStore.flashMessage('Something went wrong');
            });
    };


    @action updateList = (newList, newActiveItemIndex) => {
        this.setList(newList);
        this.setActiveItemIndex(newActiveItemIndex);
    };

    @action getDataForEvents = () => {
        const question = clone(this.list[this.activeItemIndex]);
        const { name: ttName, uuid: ttId } = assessmentSettingsStore.audition;
        if (!question) return;
        const { type, answerType, timerEnabled, required, correctAnswer } = modifyScreeningQuestion(question);
        return {
            ttId,
            ttName,
            questionType: type,
            answerType,
            timerEnabled,
            required,
            questionNumber: this.activeItemIndex + 1,
            correctAnswer: Boolean(correctAnswer)
        };
    };

    @action deleteItem = (key, isScreening) => {
        const deletedQuestion = this.list[key];
        if (!deletedQuestion) return;

        this.setSavingItem(true);

        if (isScreening) {
            AssessmentEvents.SCREENER_QUESTION_DELETED(this.getDataForEvents());
        } else {
            AssessmentSettingsEvents.QUESTION_DELETED(this.getDataForEvents());
        }


        return deleteQuestion(deletedQuestion.id, isScreening)
            .then((data) => {
                this.list.splice(key, 1);
                if (data.success || isScreening) {
                    this.setSavingItem(false);
                    this.setActiveItemIndex(0);
                    this.setList(this.list);
                    assessmentSettingsStore.loadEditScript();

                    // if (deletedQuestion.addedManually && deletedQuestion.valid) {
                    //     assessmentSettingsStore.changeAuditionParameter('countManuallyAddedQuestions', assessmentSettingsStore.audition.countManuallyAddedQuestions - 1);
                    // }

                    // if (assessmentSettingsStore.loadEditScript && deletedQuestion.valid) assessmentSettingsStore.loadEditScript();
                    appStore.flashMessage(`${isScreening ? 'Screening ' : ''}Question ${key + 1} deleted`, 'done');
                }
            })
            .catch(() => {
                this.setSavingItem(false);
                appStore.flashMessage('Something went wrong');
            });
    };

    @action reorderItems = (questionId, targetQuestionIndex, questionIndex, newIndex, position, isScreening) => {
        const { id: targetQuestionId } = this.list[targetQuestionIndex];
        const reorderFunc = position === 'after' ? reorderQuestionAfter : reorderQuestionBefore;
        return reorderFunc(questionId, targetQuestionId, isScreening)
            .then(({ success }) => {
                if (success) {
                    this.onReorder(questionIndex, newIndex);
                }
            });
    };


    @action onReorder = (oldIndex, newIndex) => {
        if (oldIndex === newIndex) return;
        let newQuestions = clone(this.list);
        newQuestions = arrayMove(newQuestions, oldIndex, newIndex);
        const firstQuestion = newQuestions[oldIndex];
        const secondQuestion = newQuestions[newIndex];
        if (!firstQuestion || !secondQuestion) return;
        newQuestions[oldIndex].sort = oldIndex + 1;
        newQuestions[newIndex].sort = newIndex + 1;
        this.updateList(newQuestions, newIndex);
        appStore.flashMessage('Question reordered.', 'done');
    };

    @action updateList = (newQuestions, newActiveItemIndex) => {
        this.setList(newQuestions);
        this.setActiveItemIndex(newActiveItemIndex);
    };

    @action updateItem = (question) => {
        const questionIndex = this.list.findIndex(({ id }) => question.id === id);
        if (questionIndex === -1) return;
        const clonedQuestions = clone(this.list);
        const oldQuestion = clonedQuestions[questionIndex];
        clonedQuestions[questionIndex] = { ...oldQuestion, ...question };
        this.setList(clonedQuestions);
    };

    @action reset = () => {
        this.list = [];
        this.activeItemIndex = 0;
        this.isSavingItem = false;
    };
}


export default QuestionList;

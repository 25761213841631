import React, { useEffect, useState } from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { withStyles } from '@mui/styles';
import EditError from 'components/marketplace/EditError';

import clsx from 'clsx';
import styles from './styles';

const AdditionalConstrains = ({
    question, classes, audition, changeQuestionSettings
}) => {
    const [value, setValue] = useState('none');
    const [timerEnabled, setTimerEnabled] = useState(false);
    const [timeLimit, setTimeLimit] = useState('');
    const { id, timerEnabled: questionTimerEnabled, timeLimit: questionTimeLimit, answerType } = question;

    useEffect(() => {
        setTimeLimit(questionTimeLimit);
        setTimerEnabled(questionTimerEnabled);
        setValue(questionTimerEnabled ? 'runTime' : 'none');
    }, [id, answerType]);

    const handleChange = (e) => {
        handleChangeTimeLimitEnabled(e);
        setValue(e.target.value);
    };

    const handleChangeTimeLimitEnabled = () => {
        const newValue = !timerEnabled;
        setTimerEnabled(newValue);
        changeQuestionSettings({ timerEnabled: newValue });
    };

    const changeSliderValue = (e) => {
        const { value: inputValue } = e.target;
        let newValue = inputValue;
        if (newValue < 0) {
            newValue = 0;
        } else if (newValue > 4320) {
            newValue = 4320;
        } else {
            newValue = parseInt(newValue, 10);
        }
        setTimeLimit(newValue);
        changeQuestionSettings({ timeLimit: newValue });
    };

    return (
        <FormControl component="fieldset">
            <h2 className={classes.title}>Additional constraints for this question</h2>
            <RadioGroup
                aria-label="Gender"
                name="gender1"
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel
                    value="runTime"
                    control={(
                        <Radio disabled={audition.timerEnabled} />
                    )}
                    label={
                        (
                            <div className={clsx(classes.radioLabel, audition.timerEnabled && classes.radioLabelDisabled)}>
                                Limit candidates to
                                <TextField
                                    value={timeLimit}
                                    onChange={changeSliderValue}
                                    InputProps={{
                                        classes: { input: classes.input }
                                    }}
                                    disabled={value !== 'runTime' || audition.timerEnabled}
                                    classes={{ root: classes.fieldRoot }}
                                    variant="outlined"
                                    type="number"
                                    min="0"
                                    max="4320"
                                />
                                minutes to submit an answer
                            </div>
                        )
                    }
                />
                {
                    value === 'runTime' && (parseInt(timeLimit, 10) < 5) && (
                        <EditError
                            className={classes.errorMessage}
                            message={(
                                <span>
                                    The minimum time limit for this question type is 5 minutes.
                                    <br />
                                    <a
                                        href="https://vervoe.zendesk.com/hc/en-us/articles/360029181252-Best-Practices"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                    See our candidate experience best practices.
                                    </a>
                                </span>
                            )}
                        />
                    )
                }
                <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label={
                        (
                            <div className={classes.radioLabel}>
                                None
                            </div>
                        )
                    }
                />
            </RadioGroup>
        </FormControl>
    );
};

export default withStyles(styles)(AdditionalConstrains);

import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter, Route, Switch } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';

import { observer } from 'mobx-react-lite';
import Layout from 'components/layout';
import { appCtx } from 'components/appStore';

import Dialog from 'libraries/Dialog';

import { isEmptyObject } from 'helper/validationFunctions';
import { skipOnBoarding } from 'requests/APIrequests';
import OnboardingAssessmentList from './onboarding_assessments';
import OnboardingAssessmentSelect from './onboarding_assessment_select';
import OnboardingAssessmentStart from './onboarding_assessment_start';
import OnboardingHeader from './onboarding_header';


const styles = () => ({
    loader: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto'
    }
});

const Onboarding = observer(({ classes, history, location }) => {
    const { company, isLoadingCompany, isLoadingUser, updateCompany } = useContext(appCtx);
    const { onboarding } = company;

    useEffect(() => {
        if (!onboarding) {
            history.push('/welcome');
            return;
        }
        const { activeStage, auditionSlug } = onboarding;
        switch (activeStage) {
            case 'assessment_list':
                history.push('/onboarding/assessments');
                break;
            case 'candidate_experience':
                history.push('/onboarding/start-assessment');
                break;
            case 'faked_select':
                if (!location.pathname.includes(`/onboarding/select/${auditionSlug}`)) {
                    history.push(`/onboarding/select/${auditionSlug}`);
                }
                break;
            default:
                history.push('/onboarding/assessments');
                break;
        }
    }, [location.pathname]);

    const onSkip = () => {
        skipOnBoarding()
            .then(() => {
                updateCompany({ ...company, onboarding: null });
                history.push('/marketplace');
            });
    };

    if (isLoadingCompany || isLoadingUser || isEmptyObject(company)) {
        return (
            <Layout>
                <CircularProgress className={classes.loader} color="primary" />
            </Layout>
        );
    }

    if (!onboarding) return null;

    return (
        <div>
            <OnboardingHeader />
            <Switch>
                <Route
                    exact
                    path="/onboarding/assessments"
                    render={props => <OnboardingAssessmentList {...props} />}
                />
                <Route
                    exact
                    path="/onboarding/start-assessment"
                    render={props => <OnboardingAssessmentStart {...props} />}
                />
                <Route
                    path="/onboarding/select/:scriptSlug/candidate/:user_id"
                    render={props => <OnboardingAssessmentSelect {...props} />}
                />
                <Route
                    path="/onboarding/select/:scriptSlug"
                    render={props => <OnboardingAssessmentSelect {...props} />}
                />
            </Switch>
            {
                isMobile && (
                    <Dialog
                        open
                        handleClose={onSkip}
                        titleComponent={(
                            <p>
                                <span role="img" aria-label="warning">⚠️</span>
                                &nbsp;&nbsp;Message
                            </p>
                        )}
                    >
                            We've got an amazing product tour ready to blow your mind. However, we've noticed you're on a mobile device, and the tour loses its magic on smaller screens. Switch to a desktop for the full experience, or if you can't wait, jump straight into your free trial, sans tour <span role="img" aria-label="cry">😭</span>, on mobile.
                    </Dialog>
                )
            }
        </div>
    );
});

export default withRouter(withStyles(styles)(Onboarding));

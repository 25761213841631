import { del, get, patch, post, put } from '../helper/API';

export function getMultipleChoiceAnswers(id, isCMS, isScreening) {
    if (isScreening) return get(`employer/screening-questions/${id}/answers`);
    return get(`employer/${isCMS ? 'company-' : ''}questions/${id}/answers`);
}

export function createMultipleChoiceAnswer(id, isCMS, isScreening) {
    if (isScreening) return post(`employer/screening-questions/${id}/answers`);
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/answers`);
}

export function changeExistingMultipleChoiceAnswer(answerId, data, isCMS, isScreening) {
    if (isScreening) return patch(`employer/screening-questions/answers/${answerId}`, data);
    return patch(`employer/${isCMS ? 'company-' : ''}questions/answers/${answerId}`, data);
}

export function removeMultipleChoiceAnswer(answerId, isCMS, isScreening) {
    if (isScreening) return del(`employer/screening-questions/answers/${answerId}`);
    return del(`employer/${isCMS ? 'company-' : ''}questions/answers/${answerId}`);
}

export function getExampleAnswers(id, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${id}/example-answers`);
}

export function changeExistingExampleAnswer(answerId, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/example-answers/${answerId}`, data);
}

export function createExampleAnswer(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/example-answers`, data);
}

export function removeExampleAnswer(answerId, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/example-answers/${answerId}`);
}

export function saveQuestionSettings(id, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/${id}/question-settings`, data);
}

export function changeQuestionParams(id, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/${id}`, data);
}

export function changeScreeningQuestionParams(id, data) {
    return patch(`employer/screening-questions/${id}`, data);
}

export function getSpreadsheetTestCases(id, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${id}/spredsheet-test-cases`);
}

export function changeSpreadsheetTestCase(test_case_id, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/spredsheet-test-cases/${test_case_id}`, data);
}

export function deleteSpreadsheetTestCase(test_case_id, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/spredsheet-test-cases/${test_case_id}`);
}

export function addSpreadsheetTestCase(id, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/spredsheet-test-cases`);
}

export function getSpreadsheetNames(questionId, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${questionId}/spreadsheets`);
}

export function getSpreadsheetRange(questionId, sheetName, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${questionId}/spreadsheets/value-range`, { sheetName });
}

export function evaluateSpreadsheetTestCase(questionId, testCase, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${questionId}/spreadsheet-test-cases/evaluate`, testCase);
}

export function getGoogleDriveLink(questionId, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${questionId}/google-drive-file`);
}

export function getTestCases(id, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${id}/test-cases`);
}

export function getCodeChallengeQuestions(id, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${id}/code-challenge-questions`);
}

export function addCodeChallengeQuestion(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/code-challenge-questions`, data);
}

export function deleteCodeChallengeQuestion(questionId, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/code-challenge-questions/${questionId}`);
}

export function editCodeChallengeQuestion(questionId, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/code-challenge-questions/${questionId}`, data);
}

export function getQuestionInfo(id, isCMS, isScreening) {
    if (isScreening) return get(`employer/screening-questions/${id}`);
    return get(`employer/${isCMS ? 'company-' : ''}questions/${id}`);
}

export function changeGoogleDriveLink(id, googleDriveFileId, isCMS, data) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/${id}/google-drive-files/${googleDriveFileId}`, data);
}


// question banks requests

export function getQuestionCategoriesList() {
    return get('employer/company-questions/categories');
}

export function getQuestionsListByCategory(categoryId) {
    return get(`employer/company-questions/categories/${categoryId}`);
}

export function getScreeningSavedQuestions(data) {
    return get('employer/company-screening-questions', data);
}

export function createCompanyQuestion(id) {
    return post(`employer/questions/${id}/company-questions`);
}

export function createCompanyScreeningQuestion(id) {
    return post(`employer/screening-questions/${id}/company-screening-questions`);
}

export function getCompanyScreeningQuestion(id) {
    return post(`employer/screening-questions/${id}/company-screening-questions`);
}

export function createCompanyQuestionFromSuggested(uuid, skill) {
    return post(`employer/public-questions/${uuid}/company-question`, { skill });
}

export function createCompanyQuestionFromScreeningSuggested(id) {
    return post(`employer/suggested-screening-questions/${id}/company-screening-questions`);
}

export function createQuestionFromCompanyQuestion(slug, companyQuestionId) {
    return post(`employer/auditions/${slug}/company-questions/${companyQuestionId}/questions`);
}

export function replaceQuestionWithBankQuestion(companyQuestionId, questionId) {
    return post(`employer/company-questions/${companyQuestionId}/questions/${questionId}`);
}

export function replaceScreeningQuestionWithBankQuestion(companyQuestionId, questionId) {
    return patch(`employer/company-screening-questions/${companyQuestionId}/screening-questions/${questionId}`);
}

export function searchByCompanyQuestionCategory(categoryId, query) {
    return get(`employer/company-questions/categories/${categoryId}/search`, { value: query });
}

export function changeQuestionGroup(questionId, questionGroupId) {
    return patch(`employer/question-groups/${questionGroupId}/questions/${questionId}/change-group`);
}

export function findKeyWords(roleName) {
    return post('employer/ttbuilder/skills/recommended', { roleName });
}

export function getQuestionsBySkill(data) {
    return get('employer/publiclibrary/questions/search-by-skill', data);
}

export function getSuggestedScreeningQuestions(data) {
    return get('employer/suggested-screening-questions', data);
}

export function replaceQuestionWithSuggestedQuestion(uuid, questionId, data) {
    return post(`employer/publiclibrary/questions/${uuid}/questions/${questionId}`, data);
}

export function replaceScreeningQuestionWithSuggestedQuestion(id, questionId) {
    return patch(`employer/suggested-screening-questions/${id}/screening-questions/${questionId}`);
}

export function reorderQuestionBefore(id, questionId, isScreening) {
    if (isScreening) return patch(`employer/screening-questions/${id}/before/${questionId}/sort`);
    return post(`employer/questions/${id}/before/${questionId}/sort`);
}

export function reorderQuestionAfter(id, questionId, isScreening) {
    if (isScreening) return patch(`employer/screening-questions/${id}/after/${questionId}/sort`);
    return post(`employer/${isScreening ? 'screening-' : ''}questions/${id}/after/${questionId}/sort`);
}

export function addQuestionGroup(slug, data) {
    return post(`employer/auditions/${slug}/question-groups`, data);
}

export function deleteQuestionGroup(id) {
    return del(`employer/auditions/question-groups/${id}`);
}

export function editQuestionGroup(id, data) {
    return patch(`employer/auditions/question-groups/${id}`, data);
}

// groups score ranges
export function addGroupScoreRange(id) {
    return post(`employer/auditions/question-groups/${id}/score-ranges`);
}

export function removeGroupScoreRange(id) {
    return del(`employer/auditions/question-groups/score-ranges/${id}`);
}

export function editGroupScoreRange(id, data) {
    return patch(`employer/auditions/question-groups/score-ranges/${id}`, data);
}

export function updateGroupScoreRange(id, data) {
    return put(`employer/auditions/question-groups/${id}/score-ranges`, data);
}

// assessment score ranges
export function addAssessmentScoreRange(slug) {
    return post(`employer/auditions/${slug}/score-ranges`);
}

export function removeAssessmentScoreRange(id) {
    return del(`employer/auditions/score-ranges/${id}`);
}

export function editAssessmentScoreRange(id, data) {
    return patch(`employer/auditions/score-ranges/${id}`, data);
}

export function createNumberCorrectAnswer(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/correct-numbers`, data);
}

export function changeNumberCorrectAnswer(correctNumberId, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/correct-numbers/${correctNumberId}`, data);
}

export function uploadFileMultipleChoiceAnswer(answerId, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}answers/${answerId}/file`, data);
}

export function removeFileFromMultipleChoiceAnswer(answerId, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}answers/${answerId}/delete-file`);
}

export function removeZiggeoFromMultipleChoiceAnswer(answerId, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}answers/${answerId}/delete-ziggeo`);
}

// ------------------------------------------------------------------------------------- //
export function getSimulatorTickets(id, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${id}/cs-simulator-tickets`);
}

export function changeSimulatorTicket(id, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/${id}`, data);
}

export function reorderSimulatorTicket(id, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/${id}/reorder`, data);
}

export function deleteSimulatorTicket(id, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/${id}`);
}

export function addSimulatorTicket(id, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/cs-simulator-tickets`);
}

export function duplicateSimulatorTicket(id, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/${id}/duplicate`);
}

export function uploadFileForSimulatorTicket(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/${id}/file`, data);
}

export function deleteFileFromSimulatorTicket(id, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/${id}/delete-file`);
}

export function deleteZiggeoFromSimulatorTicket(id, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/${id}/delete-ziggeo`);
}

// ------ //

export function addSimulatorTicketResponse(id, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/${id}/cs-simulator-answers`);
}

export function changeSimulatorTicketResponse(id, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/cs-simulator-answers/${id}`, data);
}

export function deleteSimulatorTicketResponse(id, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/cs-simulator-answers/${id}`);
}

export function uploadFileForSimulatorTicketResponse(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/cs-simulator-answers/${id}/file`, data);
}

export function deleteFileFromSimulatorTicketResponse(id, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/cs-simulator-answers/${id}/delete-file`);
}

export function deleteZiggeoFromSimulatorTicketResponse(id, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/cs-simulator-answers/${id}/delete-ziggeo`);
}

// ------ //

export function changeSimulatorArticle(id, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-articles/${id}`, data);
}

export function reorderSimulatorArticle(id, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-articles/${id}/reorder`, data);
}

export function deleteSimulatorArticle(id, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-articles/${id}`);
}

export function addSimulatorArticle(id, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/cs-simulator-articles`);
}

export function getSimulatorArticles(id, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${id}/cs-simulator-articles`);
}

export function duplicateSimulatorArticle(id, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-articles/${id}/duplicate`);
}

// ------ //

export function addSimulatorOrder(id, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/${id}/cs-simulator-orders`);
}

export function changeSimulatorOrder(id, data, isCMS) {
    return patch(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/cs-simulator-orders/${id}`, data);
}

export function deleteSimulatorOrder(id, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/cs-simulator-tickets/cs-simulator-orders/${id}`);
}

// ------ //

export function getCyberSimulatorUseCases() {
    return get('employer/linux-simulation-use-cases');
}

export function getSimulationQuestionList(data) {
    return get('employer/publiclibrary/questions/search-by-answer-type', data);
}

// ------ //

export function saveStatisticsTimerForQuestion(id, data) {
    return patch(`employer/question-results/${id}/increase-time`, data);
}

// ----- //

export function changeScreeningMultipleChoiceAnswer(id, answerId, data) {
    return patch(`employer/screening-questions/${id}/answers/${answerId}`, data);
}

export function createScreeningMultipleChoiceAnswer(id) {
    return post(`employer/screening-questions/${id}/answers`);
}

export function removeScreeningMultipleChoiceAnswer(id, answerId) {
    return del(`employer/screening-questions/${id}/answers/${answerId}`);
}

export function deleteAllScreeningQuestions(slug) {
    return del(`employer/auditions/${slug}/screening-questions`);
}

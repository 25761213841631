import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';

import stylesJS from './styles';

const ItemAttachmentsWrapper = ({ classes, className = '', children }) => (
    <div className={clsx(classes.wrapper, className)}>
        {children}
    </div>
);

export default withStyles(stylesJS)(ItemAttachmentsWrapper);

export default theme => ({
    loaderWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    emptyBoard: {
        padding: '35px 0',
        textAlign: 'center'
    },
    select: {
        width: 300,
    },
    paper: {
        '& ul': {
            paddingBottom: 0
        }
    },
    addBranding: {
        padding: '10px 15px',
        borderTop: '1px solid #ebdcee',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    footer: {
        fontWeight: 600,
        fontSize: 12,
        padding: 15,
        backgroundColor: theme.palette.grey[200],
        '& a': {
            color: theme.palette.primary.main
        }
    }
});

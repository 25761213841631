import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route } from 'react-router-dom';
import { appCtx } from '../../appStore';
import { checkHasCompanyFeature } from '../../../helper/commonFunctions';
import { PERMISSIONS } from '../../../helper/constants';

const ProtectedWithAdminPermissions = observer(({ render, ...other }) => {
    const { loggedUser, isLoadingUser, company, isLoadingCompany } = useContext(appCtx);
    if (isLoadingUser || isLoadingCompany) return null;
    const { roleType } = loggedUser;
    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);
    if ((roleType === 'team' && hasPermissionsFeature) || roleType === 'integration') return <Redirect to="/forbidden" />;
    return (
        <Route
            {...other}
            render={render}
        />
    );
});

export default ProtectedWithAdminPermissions;

import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Dialog from 'libraries/Dialog';

import Button from '@mui/material/Button';
import CheckIcon from '../Check.svg';

import styles from '../styles';

const GoBackToAssessmentDialog = ({ classes, open, handleNext }) => (
    <Dialog
        open={open}
        maxWidth="sm"
        className={classes.dialog}
        handleClose={handleNext}
        contentClassName={classes.dialogContent}
    >
        <div>
            <img src={CheckIcon} alt="check" className={classes.icon} />
            <Typography variant="h3" className={classes.successHeader}>
                No Answers to Grade
            </Typography>
            Re-calculating can take around 24 hours.
        </div>
        <Button color="primary" onClick={handleNext} className="u-mrg--tx2">
            Go back to Assessment
        </Button>
    </Dialog>
);

export default withStyles(styles)(GoBackToAssessmentDialog);

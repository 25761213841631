import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';

const styles = {
    button: {
        minHeight: 36,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold'
    },
    upgradePlanButton: {
        minHeight: 36,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold',
        outline: 'none',
        color: '#fff',
        '&:hover, &:active, &:focus': {
            color: '#fff',
            outline: 'none'
        }
    }
};

const ActivateLimitDialog = ({ open, classes, onClose }) => (
    <Dialog
        open={open}
        maxWidth="sm"
        onClose={onClose}
        handleClose={onClose}
        titleComponent="Open Assessment"
        actionComponent={(
                <>
                    <Button
                        className={classes.button}
                        color="primary"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <SubscriptionsLink
                        label={(
                            <Button
                                className={classes.upgradePlanButton}
                                color="primary"
                                variant="contained"
                                onClick={onClose}
                            >
                            Upgrade Plan
                            </Button>
                        )}
                        segmentLocation="activate assessment limit dialog"
                    />
                </>
        )}
    >
            You have reached the limit of open assessments on your plan. Please close an assessment or choose another plan.
    </Dialog>
);

export default withStyles(styles)(ActivateLimitDialog);

import React from 'react';
import { withStyles } from '@mui/styles';
import AddIcon from 'img/add.svg';
import AlertIcon from 'components/icons/AlertIcon';
import DoneOutlinedIcon from 'components/icons/DoneOutlinedIcon';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#fff',
        fontSize: 16,
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.2px',
        textAlign: 'center',
        color: theme.palette.grey[900],
        borderRadius: 8,
        padding: '15px 20px',
        margin: '15px 0',
        border: `2px solid ${theme.palette.grey[200]}`,
        '&.clickable': {
            cursor: 'pointer'
        },
        '&.clickable:hover': {
            backgroundColor: theme.palette.grey[50]
        },
        '& img': {
            marginBottom: 5,
            width: 20
        }
    }
});

const ScreeningButton = ({ clickable, createNewQuestion, hasErrors, classes, questionsLength }) => {
    const returnIcon = () => {
        if (!questionsLength) return <img src={AddIcon} alt="+" />;
        if (hasErrors) return <AlertIcon />;
        return <DoneOutlinedIcon color="#00B898" size={20} />;
    };

    const onClick = () => {
        if (!questionsLength) {
            createNewQuestion();
        }
    };

    return (
        <div role="presentation" onClick={onClick} className={`js-add-question ${classes.wrapper} ${clickable && 'clickable'}`}>
            {returnIcon()}
            Screener
        </div>
    );
};

export default withStyles(styles)(ScreeningButton);

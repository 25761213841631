import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    filterOption: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textTransform: 'capitalize',
        marginBottom: 0,
        padding: '2px 0',
        width: '100%',
        color: '#3A3C43',
        fontWeight: 600
    },
    checkbox: {
        padding: 0,
        marginRight: 10
    },
    countNumber: {
        minWidth: 25,
        height: 17,
        padding: '0 7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 700,
        borderRadius: 70,
        fontSize: 10,
        marginLeft: 20,
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary.main
    }
}));

import React from 'react';
import { withRouter } from 'react-router-dom';

import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

import TableCell from 'components/table_components/table_cell';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import { observer } from 'mobx-react-lite';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import { formatTimestamp } from 'helper/commonFunctions';
import StatusLabel, { getStatusMenuOptions } from './StatusLabel';

const useStyles = makeStyles(theme => ({
    tableRow: {
        cursor: 'pointer'
    },
    limitedColumn: {
        '@media only screen and (max-width: 1000px)': {
            maxWidth: '120px!important'
        },
        '@media only screen and (max-width: 950px)': {
            maxWidth: '80px!important'
        }
    }
}));

const RequestRow = observer(({ requestItem, changeStatus, showRequestInfo }) => {
    const { tableRow, limitedColumn } = useStyles();
    const { companyName, category, companySlug, createdAt, id, role, status } = requestItem;

    return (
        <>
            <TableRow
                className={tableRow}
                onClick={showRequestInfo}
                hover
            >
                <TableCell align="left"><TruncateWithTooltip className={limitedColumn} text={companyName} /></TableCell>
                <TableCell align="left"><TruncateWithTooltip className={limitedColumn} text={companySlug} /></TableCell>
                <TableCell align="left"><TruncateWithTooltip text={formatTimestamp(createdAt)} /></TableCell>
                <TableCell align="left"><TruncateWithTooltip text={category} /></TableCell>
                <TableCell align="left"><TruncateWithTooltip className={limitedColumn} text={role} /></TableCell>
                <TableCell align="right">
                    <StatusLabel text={status} />
                    <BoardItemMenu
                        options={getStatusMenuOptions(id, status, changeStatus)}
                    />
                </TableCell>
            </TableRow>
        </>
    );
});

export default withRouter(RequestRow);

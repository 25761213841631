import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import { withStyles, withTheme } from '@mui/styles';
import InnerTabs from 'components/layout/InnerTabs';
import UsersIcon from 'components/icons/UsersIcon';
import { checkHasUserPermission } from 'helper/commonFunctions';

import { appCtx } from 'components/appStore';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import CloseIcon from 'img/close.svg';

import MailIcon from './mail';

import { statsCtx } from '../../statsStore';
import { inviteTabCtx } from '../store';

const styles = {
    allCandidatesTab: {
        width: 240,
        paddingLeft: 0,
        paddingRight: 0
    },
    shortlistTab: {
        width: 180,
        paddingLeft: 0,
        paddingRight: 0
    },
    innerTabs: {
        width: '100%',
        margin: 0
    },
    closeIcon: {
        padding: 4,
        marginLeft: 10,
        '& > img': {
            width: 16
        }
    }
};

const InviteTabs = observer(({ classes, theme, match, history, audition }) => {
    const { loadAssessmentStats, totalCount } = useContext(statsCtx);
    const { company } = useContext(appCtx);
    const { setCandidateTabs, candidateTabs, removeCandidateTab } = useContext(inviteTabCtx);

    const { permissions, active } = audition;
    const edit = checkHasUserPermission(company, permissions, 'edit');

    const { scriptSlug } = match.params;

    useEffect(() => {
        setCandidateTabs(scriptSlug);
    }, [scriptSlug]);

    const tabs = [{
        label: (<>
            <MailIcon color={theme.palette.green[600]} /> Invite
        </>),
        className: classes.shortlistTab,
        url: `/script/invite/${scriptSlug}/invitation`
    }, {
        label: (<>
            <UsersIcon color={theme.palette.primary.main} /> Candidates{totalCount ? ` (${totalCount})` : ''}
        </>),
        className: classes.allCandidatesTab,
        url: `/script/invite/${scriptSlug}/invite-candidates`
    }];

    if (!active || !edit) {
        tabs.shift();
    }

    const onRemove = (e, id) => {
        e.stopPropagation();
        removeCandidateTab(id, scriptSlug);
        history.push(`/script/invite/${scriptSlug}/invite-candidates`);
    };

    const candidateTabsElements = candidateTabs.map(({ id, name }) => ({
        label: (<>
            <div>
                <TruncateWithTooltip text={name} width={250} />
            </div>
            <IconButton className={classes.closeIcon} onClick={e => onRemove(e, id)} size="large">
                <img src={CloseIcon} alt="x" />
            </IconButton>
        </>),
        url: `/script/invite/${scriptSlug}/candidate/${id}`
    }));


    return (
        <InnerTabs
            loadAssessmentStats={loadAssessmentStats}
            className={classes.innerTabs}
            tabList={tabs.concat(candidateTabsElements)}
        />
    );
});

export default withTheme(withStyles(styles)(withRouter(InviteTabs)));

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import InfoIcon from './infoIcon.svg';

import stylesJS from './styles';

const NotificationRow = ({ classes, className = '', notifications = [] }) => {
    const notification = notifications.find(el => el.isActive);

    return (
        <div className={clsx(classes.sectionRow, className)}>
            {notification ? (
                <div className={classes.body}>
                    <img src={InfoIcon} alt="info" />
                    <div className={classes.title}>{notification.title} </div>
                    <button className={classes.actionButton} onClick={notification.action}>{notification.actionText}</button>
                </div>
            ) : null}
        </div>
    );
};

export default withStyles(stylesJS)(withRouter(NotificationRow));

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Tooltip from 'libraries/Tooltip';
import { removeCandidateFromCompare } from 'requests/AssessmentRequests';
import { SCORE_TYPE_AI } from 'helper/constants';

import ExportIcon from './Export.svg';
import ShortlistButton from '../../../common/CandidateActions/ShortlistButton';

const useStyles = makeStyles(theme => ({
    closeIcon: {
        color: theme.palette.grey[500]
    },
    closeIconButton: {
        borderRadius: 4,
        padding: 6
    }
}));

const CandidateActions = observer(({
    candidate, onRemoveFromShortlist, removeFromCompare, audition
}) => {
    if (!candidate) return null;
    const {
        rejected, completionsPerOpportunityLimitExceeded,
        shortlisted, candidateId, candidateStatus, rank,
        candidateUuid, score, scoreType, shortlistedAt, shortlistedBy
    } = candidate;
    const { slug, uuid: ttId, name: ttName } = audition;
    const [candidateShortlisted, setCandidateShortlisted] = useState(shortlisted);
    const classes = useStyles();

    const candidateHired = candidateStatus === 'Hired';

    useEffect(() => {
        setCandidateShortlisted(shortlisted);
    }, [shortlisted]);

    const dataForEvents = {
        candidateId: candidateUuid,
        context: 'Compare modal',
        ttId,
        ttName,
        score: score ? score.score : undefined,
        scoreIsAI: score ? score.type === SCORE_TYPE_AI : undefined,
        scoreType,
        shortlistedDate: shortlistedAt,
        shortlistedBy: shortlistedBy && shortlistedBy.id,
        rank,
        candidateHired
    };

    const onRemove = () => {
        if (onRemoveFromShortlist) onRemoveFromShortlist();
        onRemoveFromCompare();
        setCandidateShortlisted(false);
    };

    const onRemoveFromCompare = async () => {
        await removeCandidateFromCompare(slug, +candidateId);
        removeFromCompare(`+${candidateId}`);
    };

    return (
        <div className="u-dsp--f u-fdir--row u-ai--center u-jc--end">
            {
                !rejected && (
                    <ShortlistButton
                        candidate={candidate}
                        shortlisted={candidateShortlisted}
                        disabled={completionsPerOpportunityLimitExceeded}
                        dataForEvents={dataForEvents}
                        onRemove={onRemove}
                    />
                )
            }
            <Tooltip
                label="Open Candidate"
            >
                <IconButton className={classes.closeIconButton} size="large">
                    <Link
                        to={`/script/select/${slug}/candidate/${candidateId}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="u-txt--lh1 u-dsp--f"
                    >
                        <img src={ExportIcon} alt="Open" />
                    </Link>
                </IconButton>
            </Tooltip>
            <Tooltip
                label="Remove from Compare"
            >
                <IconButton
                    onClick={onRemoveFromCompare}
                    className={clsx(classes.closeIconButton, 'u-mrg--lx1')}
                    size="large">
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </Tooltip>
        </div>
    );
});

export default withRouter(CandidateActions);

export default theme => ({
    cardJudges: {
        display: 'flex',
        marginRight: 8
    },
    cardJudgeCommon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        marginRight: -8,
        borderRadius: '50%',
        overflow: 'auto',
        fontWeight: 'bold',
        border: '2px solid #fff',
        zIndex: '1'
    },
    cardJudge: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        fontSize: 12,
        width: 30,
        height: 30
    },
    cardJudgeRest: {
        backgroundColor: 'white',
        position: 'relative',
        color: theme.palette.primary.main,
        fontSize: 10,
        overflow: 'hidden',
        width: 32,
        height: 32,
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            left: 0,
            top: 0,
            height: '100%',
            borderRadius: '50%',
            border: `2px solid ${theme.palette.primary.main}`
        }
    },
    cardJudgeRestNew: {
        position: 'relative',
        color: theme.palette.grey[700],
        fontSize: 10,
        fontWeight: 'bold',
        lineHeight: '20px',
        overflow: 'hidden',
        backgroundColor: theme.palette.grey[200],
        borderRadius: 20,
        width: 74,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 13
    }
});

import React, { useState } from 'react';

// material components
import { withStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReorderDialog from 'components/dialogs/ReorderDialog';
import SimulatorAccordionMenu from './SimulatorAccordionMenu';
import styles from './styles';

const SimulatorAccordion = ({
    classes, entity = 'ticket',
    entityIndex, onRemove, onReorder,
    onDuplicate, children, entityList,
    expanded = false
}) => {
    const [reorderDialogOpened, setReorderDialog] = useState(false);

    const handleReorderDialog = (e) => {
        e.stopPropagation();
        setReorderDialog(!reorderDialogOpened);
    };

    return (
        <Accordion
            id={`${entity}_${entityIndex}`}
            defaultExpanded={expanded}
            classes={{
                root: classes.accordion,
                expanded: classes.accordionExpanded
            }}
        >
            <AccordionSummary
                classes={{
                    root: classes.summaryRoot,
                    content: classes.summaryContent,
                    disabled: classes.summaryDisabled,
                    expanded: classes.summaryExpanded
                }}
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <span>{entity} {entityIndex + 1}</span>
                <SimulatorAccordionMenu
                    hideReorder={entityList.length < 2}
                    onReorder={handleReorderDialog}
                    onRemove={onRemove}
                    onDuplicate={onDuplicate}
                    className={classes.ticketMenu}
                />
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
            <ReorderDialog
                entity={entity}
                open={reorderDialogOpened}
                onReorder={onReorder}
                entityList={entityList}
                entityIndex={entityIndex}
                onClose={handleReorderDialog}
            />
        </Accordion>
    );
};

export default withStyles(styles)(SimulatorAccordion);

export default theme => ({
    popperPaper: {
        padding: '20px 22px',
        maxWidth: '420px',
        boxShadow: '0 10px 50px 0 rgba(0, 0, 0, 0.24)'
    },
    closeIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: '0',
        top: '7px'
    },
    linkPermissionsLabel: {
        fontSize: 14,
        marginBottom: 5,
        fontWeight: 600,
        color: theme.palette.grey[600]
    },
    switch: {
        marginTop: 0,
        marginBottom: 10
    },
    iconWithLabelWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px'
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        marginRight: '12px',
        minHeight: '24px'
    },
    lockImg: {
        marginRight: '7px'
    },
    link: {
        color: theme.palette.grey[900],
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        fontWeight: 600,
        fontSize: 14,
        cursor: 'pointer',
        marginBottom: 10,
        overflow: 'hidden'
    },
    linkDescription: {
        marginBottom: 13,
        fontWeight: 600,
        color: theme.palette.grey[600],
        fontSize: 12,
        marginTop: 7
    }
});

export default theme => ({
    loaderWrapper: {
        textAlign: 'center',
        marginTop: 35
    },
    wrapper: {
        width: 370,
        borderRight: '2px solid #F3F3F3',
        paddingBottom: 20,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderBottom: '2px solid #F3F3F3',
            borderRight: 0
        }
    },
    headerIconBlock: {
        padding: '10px 15px'
    }
});

import React from 'react';

import { withStyles } from '@mui/styles';

import CustomInput from './components/CustomInput';
import ChipsWrapper from './components/ChipsWrapper';
import {
    SkillChip,
    ServerChip
} from '../Chunks/Chips';

import styles from './styles';
import { ErrorIcon } from '../Chunks/Icons';


const KeyWordsContent = ({
    addSkill, keyWordsWithTitle,
    addedSkills, classes,
    addServerSkill,
    removeSkill,
    title
}) => {
    const isError = addedSkills && addedSkills.length > 5;

    if ((!(addedSkills && addedSkills.length) && !(keyWordsWithTitle && keyWordsWithTitle.length)) || !title) return null;

    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>Add the skills this role will require</div>
            <div className={classes.subheader}>
                We recommend having at least 3 more skills per assessment. Click the next button to start adding your questions
            </div>
            {
                isError && (
                    <span className={classes.error}>
                        <ErrorIcon />
                        Too many skills selected. <br />
                        We suggest assessing between 3-5 skills.
                    </span>
                )
            }

            {
                Boolean(keyWordsWithTitle && keyWordsWithTitle.length) && (
                    <ChipsWrapper>
                        {
                            keyWordsWithTitle.map(token => !addedSkills[token] && (
                                <ServerChip
                                    key={token}
                                    light
                                    title={token}
                                    onClick={() => addServerSkill(token)}
                                />
                            ))
                        }
                    </ChipsWrapper>
                )
            }
            <CustomInput
                className={classes.skillInput}
                addSkill={addSkill}
            />
            {Boolean(addedSkills && addedSkills.length)
            && (<>
                <div className={classes.header}>Skills you are testing for</div>
                <ChipsWrapper>
                    {
                        addedSkills.map((token, index) => (
                            <SkillChip
                                className={classes.chip}
                                index={index}
                                key={token}
                                title={token}
                                onClick={() => removeSkill(token)}
                            />
                        ))
                    }
                </ChipsWrapper>
                </>)
            }
        </div>
    );
};

export default withStyles(styles)(KeyWordsContent);

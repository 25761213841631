import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import Checkbox from 'libraries/Checkbox';
import SocialMediaIcons from 'components/icons/social_media_icons';
import { loadWelcomeScreenData, changeWelcomeScreenData } from 'requests/AssessmentRequests';

const styles = () => ({
    wrapper: {
        display: 'flex',
        '& > img': {
            marginRight: 15,
            width: 32,
            height: 32,
            cursor: 'pointer'
        }
    },
    editButton: {
        fontWeight: 700
    },
    itemTitle: {
        marginTop: 20,
        fontSize: 14,
        fontWeight: 700,
        color: ' #3A3C43',
        letterSpacing: '0.2px',
        display: 'flex',
        alignItems: 'center',
        '& > div:first-child': {
            marginRight: 16
        }
    }
});


const SocialLinkButtons = ({ classes, opportunityOrAssessment = {}, onClick }) => {
    const [prohibitToShare, setProhibit] = useState(false);
    const [activeAccess, setActiveAccess] = useState(true);
    const {
        twitterShareLink, facebookShareLink,
        linkedinShareLink, slug, description
    } = opportunityOrAssessment;


    useEffect(() => {
        loadWelcomeScreenData(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setActiveAccess(data.active);
                }
            });
    }, []);

    const goToEdit = () => {
        setProhibit(false);
    };

    const handleClick = (network) => {
        const networkObj = {
            twitter: twitterShareLink,
            facebook: facebookShareLink,
            linkedin: linkedinShareLink
        };
        if (onClick) onClick(network);
        handleClickShareLink(networkObj[network]);
    };


    const handleClickShareLink = (shareLink) => {
        setTimeout(() => {
            if (description && shareLink) {
                window.open(shareLink, '_blank');
            } else {
                setProhibit(true);
            }
        }, 200);
    };

    const handleChangeDisableAppPage = () => {
        setActiveAccess(!activeAccess);
        changeWelcomeScreenData(slug, { active: !activeAccess });
    };

    const media = ['linkedin', 'facebook', 'twitter'];

    return (
        <>
            <div className={classes.itemTitle}>
                <div>Post to social networks</div>
                <div className={classes.wrapper}>
                    {
                        media.map(source => (
                            <SocialMediaIcons
                                active
                                socialMedia={source}
                                className="u-mrg--rx2 u-cursor--p"
                                onClick={() => handleClick(source)}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="u-mrg--tx3">
                <Checkbox
                    onChange={handleChangeDisableAppPage}
                    checked={!activeAccess}
                    label="Disable candidate access to application page"
                    name="deactivate_job_page"
                />
            </div>

            <Dialog
                maxWidth="sm"
                open={prohibitToShare}
                handleClose={() => { setProhibit(false); }}
                titleComponent="Your assessment post is missing some key details before its shareable"
                actionComponent={(
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.editButton}
                        onClick={goToEdit}
                    >
                        Edit assessment post
                    </Button>
                )}
            >
                To get high application completion rates you need to make sure you fill in at the very least a title, description and location.
            </Dialog>
        </>
    );
};

export default withStyles(styles)(SocialLinkButtons);

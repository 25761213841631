export default theme => ({
    wrapper: {
        padding: '13px 0',
        margin: '0 18px',
        fontWeight: 700,
        fontSize: 12,
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        }
    },
    date: {
        fontWeight: 40,
        color: theme.palette.grey[500]
    }
});

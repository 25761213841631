import React, { useState, useContext } from 'react';
import Dialog from 'libraries/Dialog';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { removeFraudDetectionFlag } from 'requests/AssessmentRequests';
import AssessmentEvents from 'events/AssessmentEvents';
import { appCtx } from 'components/appStore';


const RemoveFraudDetectionFlagDialog = observer(({
    candidate, open, onClose, getUserInfo, audition
}) => {
    const { flashMessage } = useContext(appCtx);
    const [loading, setLoading] = useState(false);
    const {
        candidateUuid: candidateId,
        userAuditionId
    } = candidate;
    const { name: ttName, uuid: ttId } = audition;

    const removeFlag = async () => {
        setLoading(true);
        try {
            await removeFraudDetectionFlag(userAuditionId);
            AssessmentEvents.GEOLP_FLAG_REMOVED({
                candidateId,
                ttId,
                ttName
            });
            flashMessage('Multiple locations flag removed', 'done');
            setLoading(false);
            onClose();
            getUserInfo();
        } catch (e) {
            setLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            titleComponent="Remove Flag"
            open={open}
            onClose={onClose}
            handleClose={onClose}
            actionComponent={
                <>
                    <Button
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        onClick={onClose}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={removeFlag}
                        disabled={loading}
                        className="u-pdn--lx8 u-pdn--rx8"
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    sx={{ color: '#fff' }}
                                />
                            ) : 'Remove Flag'
                        }
                    </Button>
                </>
            }
        >
            Do you want to remove the flag? You will not be able to flag this again.
        </Dialog>
    );
});

export default RemoveFraudDetectionFlagDialog;

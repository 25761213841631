import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import AnswerContent from 'components/grade_components/AnswerContent';
import QuestionCompletedInLabel from 'components/candidate_cards/QuestionCompletedInLabel';

import { getCandidateAnswerByHashUuid } from 'requests/AssessmentRequests';

import { CODE_TEST_AUTOGRADED, EXCEL_AUTO_GRADED, SIMULATION_GROUP, SPREADSHEET_AUTO_GRADED, TYPING_TEST } from 'helper/constants';
import NoQuestion from './no_question.svg';
import useStyles from './styles';

const NoAnswerComponent = () => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.wrapper, classes.noAnswerWrapper, 'u-dsp--centered u-fdir--column')}>
            <img src={NoQuestion} className={classes.noAnswerIcon} alt="No Answer" />
            <Typography variant="h6">No candidate answer</Typography>
        </div>
    );
};

const NoAnswerRequiredComponent = () => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.wrapper, classes.noAnswerWrapper, 'u-dsp--centered u-fdir--column')}>
            <img src={NoQuestion} className={classes.noAnswerIcon} alt="No Answer Required" />
            <Typography variant="h6">No answer required</Typography>
        </div>
    );
};

const Answers = observer(({ auditionSlug, candidateId, hashUuid, questionIndex, userName, propPublic }) => {
    const classes = useStyles();
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getGradeQuestion();
    }, []);

    const getGradeQuestion = () => {
        setIsLoading(true);
        getCandidateAnswerByHashUuid(auditionSlug, candidateId, hashUuid, propPublic)
            .then(({ data, success }) => {
                if (success) {
                    setCurrentQuestion(data);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (isLoading) {
        return (
            <div className={classes.wrapper}>
                <CircularProgress
                    size={15}
                    className={classes.loader}
                    color="primary"
                />
            </div>
        );
    }

    if (!currentQuestion) {
        return (
            <div className={clsx(classes.wrapper, 'u-dsp--centered u-fdir--column')}>
                <img src={NoQuestion} className={classes.noAnswerIcon} alt="No Answer" />
                <Typography variant="h6">Question not asked</Typography>
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            {
                currentQuestion && (
                    <>
                        <QuestionCompletedInLabel
                            className="u-mrg--bx2"
                            question={currentQuestion}
                        />
                        <AnswerContent
                            question={currentQuestion}
                            userName={userName}
                            hasTruncate
                            questionIndex={questionIndex}
                            NoAnswerComponent={NoAnswerComponent}
                            NoAnswerRequiredComponent={NoAnswerRequiredComponent}
                        />
                        {
                            [...SIMULATION_GROUP.autogradedTypes, CODE_TEST_AUTOGRADED, TYPING_TEST, EXCEL_AUTO_GRADED, SPREADSHEET_AUTO_GRADED].includes(currentQuestion.type)
                            && (
                                <div className="u-txt--right  u-txt--nowrap  u-muted-6">
                                    <small>
                                        Question score: <b>{currentQuestion.grade || 0}</b> of <b>10</b> points
                                    </small>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    );
});

export default withRouter(Answers);

export default {
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 15
    },
    changes: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#676a78'
    },
    paper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        minHeight: 100,
        backgroundColor: '#2f3545 !important',
        borderRadius: 0,
        padding: '15px 15px 15px 20px',
        position: 'relative'
    },
    paperShadow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        minHeight: 100,
        backgroundColor: '#2f3545 !important',
        borderRadius: 0,
        padding: '15px 15px 30px 20px',
        boxShadow: '0 2px 25px 0 rgba(0, 0, 0, 0.2) !important',
        position: 'relative'
    },
    saveButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgb(255,255,255)',
        width: 200,
        height: 32,
        fontSize: 14,
        textTransform: 'capitalize',
        marginLeft: 20,
        '&:hover': {
            color: 'white !important',
            background: 'rgba(255,255,255,0.3)'
        }
    },
    collapse: {
        color: 'white',
        fontSize: 14,
        lineHeight: '20px',
        fontFamily: 'Open Sans',
        maxHeight: 250,
        overflowY: 'auto',
        '& span': {
            lineHeight: '20px',
            color: 'white',
            fontSize: 14
        }
    },
    typeHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.63,
        color: '#ffffff'
    },
    typeParagraph: {
        fontSize: 14,
        lineHeight: 1.43,
        letterSpacing: 0.2,
        color: 'rgba(255, 255, 255, 0.9) !important',
        maxWidth: 820,
        height: 42,
        overflow: 'hidden'
    },
    langPanel: {
        height: 60,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 25,
        background: '#282b34'
    },
    show: {
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white !important',
        minHeight: 'auto',
        lineHeight: '14px',
        padding: 0,
        marginTop: -3
    },
    collapWrapper: {
        '& *::-webkit-scrollbar': {
            display: 'none'
        }
    },
    labelBtnCenter: {
        lineHeight: 1
    },
    betaButton: {
        color: 'rgba(255,255,255,0.9)',
        background: 'rgba(26,17,30, 0.5)',
        width: 60,
        height: 30,
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        fontFamily: 'Open Sans',
        fontWeight: 'bold'
    },
    codeEditorContainer: {
        flexGrow: 1,
        overflowX: 'auto'
    }
};

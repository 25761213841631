
import { get, post } from '../helper/API';

export function loginUser(form) {
    return post('public/login/employer', {
        ...JSON.parse(form)
    });
}

export function loginMfaUser(data) {
    return post('public/login/mfa', data);
}

export function registerEmployer(form, needToRedeemCoupon) {
    const gaClientId = localStorage.getItem('gaClientId');
    return post('public/registration/employer', {
        ...form,
        needToRedeemCoupon,
        gaClientId
    });
}

export function resetPassword(form) {
    return post('public/reset/password', {
        form
    });
}

export function changeForgottenPassword(form, token) {
    return post('public/choose/password', {
        form,
        token
    });
}

export function changePassword(form) {
    return post('employer/change-password', {
        form
    });
}

export function applyCompany(hashCode, email) {
    return get('public/company/apply', {
        hashCode,
        email
    });
}

export function getToken(refreshToken) {
    return post('public/refresh/token', {
        refreshToken
    });
}

export function authGuestForPublicCard(email, fullName) {
    return post('public/guest/authorization', {
        fullName,
        email
    });
}

export function atsLogin(token, userAuditionUuid) {
    return post('public/ats/login', {
        token,
        userAuditionUuid
    });
}

export function resendEmailConfirmation(employerEmail) {
    return post('public/registration/employer/resend-email-confirmation', { employerEmail });
}

export function resendMfaEmailConfirmation(token) {
    return post('public/login/mfa/re-send', { token });
}

export function confirmEmail(employerEmail, confirmationCode) {
    return post('public/registration/employer/confirm-email', { employerEmail, confirmationCode });
}

export function ssoLogin(email) {
    return post('public/saml', { email });
}

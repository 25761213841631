import React from 'react';
import GroupItem from './GroupItem';

const GroupScoreRange = ({
    scoreGroups, handleMove,
    removeGroup, editGroupName,
    changeScoreType, editGroup,
    scoreType, reorderGroups
}) => {
    if (!scoreGroups || scoreType !== 3) return null;

    return (
        <>
            {
                scoreGroups.map((group, index) => (
                    <GroupItem
                        key={group.id}
                        group={group}
                        index={index}
                        groups={scoreGroups}
                        editGroup={editGroup}
                        handleMove={handleMove}
                        removeGroup={removeGroup}
                        reorderGroups={reorderGroups}
                        editGroupName={editGroupName}
                        changeScoreType={changeScoreType}
                    />
                ))
            }
        </>
    );
};

export default GroupScoreRange;

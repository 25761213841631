import React, { useContext } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';

import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

import GeneralEvents from 'events/GeneralEvents';
import CandidatePhoto from 'components/photos/candidate_photo';
import TableCell from 'components/table_components/table_cell';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

import LockIcon from 'img/lock.svg';
import TooltipBlack from 'libraries/Tooltip';
import { observer } from 'mobx-react-lite';
import AssessmentPopoverSelect from './AssessmentPopoverSelect';
import { candidatesCtx } from '../../../../../store';

const useStyles = makeStyles(theme => ({
    tableRow: {
        cursor: 'pointer'
    },
    jobs: {
        maxWidth: 350,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        padding: 10,
        borderRadius: 4
    },
    jobsActive: {
        '&:hover': {
            backgroundColor: theme.palette.grey[400]
        }
    },
    lockedJob: {
        color: theme.palette.yellow[900],
        cursor: 'pointer'
    },
    lockIcon: {
        marginBottom: 4,
        marginLeft: 5,
        width: 20
    },
    hiredLabel: {
        color: theme.palette.yellow[800],
        background: theme.palette.yellow[200],
        borderRadius: 4,
        padding: '5px 10px'
    },
    more: {
        marginLeft: 8,
        color: theme.palette.grey[500]
    },
    jobName: {
        maxWidth: 300,
        display: 'flex',
        '& > span': {
            whiteSpace: 'pre'
        }
    },
    tooltip: {
        whiteSpace: 'pre-wrap'
    }
}));

const CandidateRow = observer(({ candidate, history }) => {
    const { addCandidateTab } = useContext(candidatesCtx);
    const { tableRow, jobs, lockedJob, lockIcon, hiredLabel, more, jobName, jobsActive, tooltip } = useStyles();
    const { fullName, email, candidateId, uuid, auditions, hired } = candidate;


    const goToCard = (slug) => {
        const dataForEvents = {
            candidateId: uuid,
            context: 'candidates tab'
        };
        if (slug) {
            const { uuid: ttId, name: ttName } = auditions.find(({ slug: listSlug }) => listSlug === slug);
            dataForEvents.ttId = ttId;
            dataForEvents.ttName = ttName;
        }
        GeneralEvents.CANDIDATE_VIEWED(dataForEvents);
        addCandidateTab(candidateId, fullName);
        history.push(`/all-candidates/candidate/${candidateId}${slug ? `?assessment=${slug}` : ''}`);
    };

    const isCompletionLimitExceeded = () => {
        if (!auditions || !auditions.length) return false;
        return auditions.some(item => item.isCompletionLimitExceeded);
    };

    const showRecentOpportunities = () => {
        if (!auditions || !auditions.length) return null;
        const { name } = auditions[0];
        return (
            <AssessmentPopoverSelect assessmentsList={auditions} onSelect={slug => goToCard(slug)}>
                <div className={clsx(jobs, auditions.length > 1 && jobsActive)}>
                    <span className={clsx(jobName, isCompletionLimitExceeded() && auditions.length > 1 && lockedJob)}>
                        <TruncateWithTooltip width={280} tooltipClassName={tooltip} text={name} />
                        {isCompletionLimitExceeded() && <img src={LockIcon} className={lockIcon} alt="lock" />}
                        {
                            auditions.length > 1 && (
                                <TooltipBlack
                                    label={
                                        auditions.map((audition, index) => {
                                            if (index === 0) return null;
                                            return <><span>{audition.name}</span><br /></>;
                                        })
                                    }
                                >
                                    <span className={more}>
                                        +{auditions.length - 1} more
                                    </span>
                                </TooltipBlack>
                            )
                        }
                    </span>
                </div>
            </AssessmentPopoverSelect>
        );
    };

    return (
        <>
            <TableRow className={clsx(!isCompletionLimitExceeded() && tableRow)} onClick={() => (isCompletionLimitExceeded() ? null : goToCard())} hover>
                <TableCell align="left">
                    <div className="u-dsp--centered u-jc--start">
                        <CandidatePhoto user={candidate} />
                        <TruncateWithTooltip text={fullName} />
                    </div>
                </TableCell>
                <TableCell align="left"><TruncateWithTooltip text={email} /></TableCell>
                <TableCell align="left">{showRecentOpportunities()}</TableCell>
                <TableCell align="right">
                    {hired && <span className={hiredLabel}>Hired</span>}
                </TableCell>
            </TableRow>
        </>
    );
});

export default withRouter(CandidateRow);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';
import SortActiveIcon from 'img/icons/sort-active.svg';
import Tooltip from 'libraries/Tooltip';
import { candidateResultCtx } from '../CandidateListWrapper/candidatesStore';

const useStyles = makeStyles(theme => ({
    sortLabel: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginTop: 6,
        marginLeft: 15,
        whiteSpace: 'nowrap',
        '& img': {
            marginRight: 10
        }
    }
}));

const SortLabel = observer(() => {
    const { sortLoading, isEmployerSortEnabled } = useContext(candidateResultCtx);
    const classes = useStyles();

    return (
        <div className="u-dsp--f u-ai--start u-jc--sb">
            { sortLoading && <div className={classes.sortLabel}>Recalculating</div> }
            {
                !sortLoading && isEmployerSortEnabled && (
                    <Tooltip
                        label="Sorting allows score recalculation and ranking based on different mixes of skills and questions, giving deeper candidate insights."
                    >
                        <div className={classes.sortLabel}>
                            <img src={SortActiveIcon} alt="!" />
                            Sort is active
                        </div>
                    </Tooltip>
                )
            }
        </div>
    );
});

export default SortLabel;

import React from 'react';

import { withStyles } from '@mui/styles';

import styles from './styles';

const Wrapper = ({ children, classes, title }) => (
    <>
        <h3 className={classes.title}>
            {title}
        </h3>
        <div className={classes.wrapper}>
            { children }
        </div>
    </>
);

export default withStyles(styles)(Wrapper);

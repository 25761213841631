import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        padding: '26px 33px'
    },
    infoItemWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:not(:last-child)': {
            marginBottom: 20
        }
    },
    header: {
        color: theme.palette.grey[500],
        fontSize: 12,
        fontWeight: 600,
        minWidth: 120
    },
    text: {
        color: theme.palette.grey[900],
        fontSize: 14,
        fontWeight: 600,
        minHeight: 20
    },
    hiredDateInput: {
        minWidth: 166
    },
    hiddenBlock: {
        height: 18,
        borderRadius: 30,
        backgroundColor: theme.palette.grey[300],
        width: 175
    }
}));

export default theme => ({
    expansionPanel: {
        boxShadow: 'none',
        marginBottom: 0,
        '&:before': {
            display: 'none'
        }
    },
    expansionPanelSummary: {
        width: '100%',
        fontSize: 14,
        fontWeight: 700,
        textTransform: 'uppercase',
        backgroundColor: theme.palette.grey[100],
        padding: '0 21px'
    },
    expansionPanelDetails: {
        padding: '15px 23px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    expanded: {
        margin: '0!important'
    }
});

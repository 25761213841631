import React from 'react';

export const PlusIcon = ({ color = 'none', marginLeft, width, height }) => (
    <svg style={{ marginLeft, width, height }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill={color} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const CloseIcon = ({ color = 'none', onClick, width = 18, height = 18 }) => (
    <svg onClick={onClick} style={{ marginLeft: 10, width, height }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill={color} d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const ErrorIcon = () => (
    <svg style={{ marginRight: 10 }} width="16" height="16" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27 52.5C41.0833 52.5 52.5 41.0833 52.5 27C52.5 12.9167 41.0833 1.5 27 1.5C12.9167 1.5 1.5 12.9167 1.5 27C1.5 41.0833 12.9167 52.5 27 52.5Z" stroke="#F87E30" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27 29.8334L27 12.8334" stroke="#f02e2e" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <ellipse cx="27" cy="38.3333" rx="2.83333" ry="2.83333" fill="#f02e2e" />
    </svg>
);

import { createContext } from 'react';
import { action, observable } from 'mobx';
import { loadCandidates, loadFiltersCountForAllCandidates } from 'requests/CandidatesRequests';
import MiscellaneousEvents from 'events/MiscellaneousEvents';

const FILTER_STATUS_SLUG = 'filter-candidates-status';
const SORT_PAGE_IDENTIFIER = 'sort-all-candidates-page';

class CandidateStore {
    @observable allCandidates = [];

    @observable allCandidatesStats = {};

    @observable totalCount = 0;

    @observable allCount = 0;

    @observable page = 1;

    @observable hasMore = false;

    @observable query = '';

    @observable filterCategory = 'all';

    @observable isLoading = true;

    @observable sortValue = null;

    @observable isLoadingLocal = false;

    @observable isNoCandidates = false;

    timerId = null;

    @action cleanFilterSettings = (except = 'all') => {
        const localStorageItems = Object.keys({ ...localStorage });
        const localStorageItemsStatuses = localStorageItems.filter(item => item.includes(FILTER_STATUS_SLUG));
        localStorageItemsStatuses.forEach((item) => {
            if (item !== `${FILTER_STATUS_SLUG}-${except}`) {
                localStorage.removeItem(item);
            }
        });
    };

    @action setFilterCategory = () => {
        const localStorageItems = Object.keys({ ...localStorage });
        const localStorageItemsStatuses = localStorageItems.filter(item => item.includes(FILTER_STATUS_SLUG));
        if (localStorageItemsStatuses.length) {
            if (localStorageItemsStatuses.includes(`${FILTER_STATUS_SLUG}-active`)) {
                this.filterCategory = 'active';
            } else if (localStorageItemsStatuses.includes(`${FILTER_STATUS_SLUG}-rejected`)) {
                this.filterCategory = 'rejected';
            } else if (localStorageItemsStatuses.includes(`${FILTER_STATUS_SLUG}-hired`)) {
                this.filterCategory = 'hired';
            }
            this.cleanFilterSettings(this.filterCategory);
        }
    };

    @action loadCandidates = () => {
        if (localStorage.getItem(SORT_PAGE_IDENTIFIER)) {
            const sortValue = localStorage.getItem(SORT_PAGE_IDENTIFIER);
            this.sortValue = sortValue;
        }

        loadCandidates(this.page, this.filterCategory, this.query, this.sortValue)
            .then(({ success, data }) => {
                if (success && data) {
                    const { items, hasMorePages, total, currentPage } = data;
                    this.allCandidates = items;
                    this.totalCount = total;
                    this.page = currentPage;
                    this.hasMore = hasMorePages;
                    if (this.filterCategory === 'all' && !this.query.length && !this.sortValue) this.allCount = total;
                }
                const isNoCandidates = !this.query && !this.totalCount && this.filterCategory === 'all';
                this.isNoCandidates = this.isNoCandidates || isNoCandidates;
            })
            .finally(() => {
                this.isLoading = false;
                this.isLoadingLocal = false;
            });
    };


    @action fetchAllCandidatesStats = () => {
        loadFiltersCountForAllCandidates()
            .then(({ success, data }) => {
                if (success && data) {
                    this.allCandidatesStats = data;
                }
            });
    }

    @action setLoading = (value) => {
        this.isLoading = value;
    };

    @action handleChangeFilter = (e) => {
        const { value } = e.target;
        this.query = value;
        this.page = 1;
        this.isLoadingLocal = true;
        clearTimeout(this.timerId);
        this.timerId = setTimeout(async () => {
            await this.loadCandidates();
            MiscellaneousEvents.CANDIDATE_SEARCH_CONDUCTED({
                searchTerm: value,
                context: 'All candidates'
            });
        }, 300);
    };

    @action handleChangePage = (page) => {
        this.page = page;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };

    @action handleChangeFilterSelect = (filterCategory = 'all') => {
        this.filterCategory = filterCategory;
        this.cleanFilterSettings();
        if (this.filterCategory !== 'all') {
            localStorage.setItem(`${FILTER_STATUS_SLUG}-${filterCategory}`, 'true');
        }
        this.page = 1;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };

    @action handleChangeSort = (sort) => {
        this.sortValue = sort;
        if (sort) {
            localStorage.setItem(SORT_PAGE_IDENTIFIER, sort);
        } else {
            localStorage.removeItem(SORT_PAGE_IDENTIFIER);
        }

        this.page = 1;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };

    @action cleanState = () => {
        this.allCandidates = [];
        this.totalCount = 0;
        this.page = 1;
        this.hasMore = false;
        this.query = '';
        this.filterCategory = 'all';
        this.isLoading = true;
        this.isNoCandidates = false;
    }
}

export const candidateStore = new CandidateStore();
export const candidateCtx = createContext(candidateStore);

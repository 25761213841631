export default theme => ({
    wrapper: {
        marginTop: 20,
        color: theme.palette.yellow[900],
        fontWeight: 600,
        fontSize: 12,
        '& > div:not(:last-child)': {
            marginBottom: 10
        }
    }
});

import React from 'react';
import { withStyles } from '@mui/styles';
import Checkbox from 'libraries/Checkbox';
import AlertIcon from '../IncludeShareLinkCheckbox/alert.svg';
import styles from '../IncludeShareLinkCheckbox/styles';

const IncludeRankCheckbox = ({ classes, checked, onChange }) => (
    <Checkbox
        label="Export rank"
        name="includeRank"
        checked={checked}
        styles={{ label: classes.checkboxLabel }}
        value="includeRank"
        onChange={onChange}
        helperText={(
            <span>
                <img src={AlertIcon} alt="!" className={classes.alertIcon} />
                Please note this can slow down csv generation.
            </span>
        )}
    />
);

export default withStyles(styles)(IncludeRankCheckbox);

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import CopyToClipboard from 'react-copy-to-clipboard';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';

import Switch from 'libraries/SwitchBrandWithLabel';
import SettingsEvents from 'events/SettingsEvents';

import {
    getLeverAuthLink, updateLeverSettings,
    unauthorizeLever, getLeverPostings
} from 'requests/SubscriptionRequests';
import TextInput from 'libraries/TextInput';
import Posting from './Posting';

let debounceTimeout;

const styles = () => ({
    heading: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '28px',
        marginBottom: 20
    },
    button: {
        height: 40,
        marginRight: 20,
        fontWeight: 'bold',
        paddingLeft: 20,
        paddingRight: 20
    },
    input: {
        maxWidth: 500
    }
});

const LeverContent = ({ classes, flashMessage, updateCompanyProperty }) => {
    const [webhookToken, setWebhookToken] = useState('');
    const [leverData, setLeverData] = useState({
        enabled: false,
        authLink: '',
        authorized: false
    });
    const [postings, setPostings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [onRemoveLoading, setRemoveLoading] = useState(false);

    const { enabled, authorized, authLink, webhookLink } = leverData;

    useEffect(() => {
        getLeverAuthLink()
            .then(({ data }) => {
                if (data) {
                    setLeverData(data);
                    updateCompanyProperty('isShowLeverUnauthorizedBanner', data.enabled && !data.authorized);
                    setWebhookToken(data.webhookSignatureToken);
                }
            }).catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                } else {
                    flashMessage('Something went wrong', 'error');
                }
            }).catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                } else {
                    flashMessage('Something went wrong', 'error');
                }
            });

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, []);

    useEffect(() => {
        if (authorized && enabled) {
            setLoading(true);
            loadPostings()
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [authorized, enabled]);

    const loadPostings = () => getLeverPostings()
        .then(({ data }) => {
            if (data) {
                setPostings(data);
            }
        });

    const updateLeverConfig = (key, value) => {
        setLeverData({ ...leverData, authLink: '' });
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            updateLeverSettings({ enabled, [key]: value })
                .then(({ success, data }) => {
                    if (data && success) {
                        setLeverData({ ...leverData, ...data });
                        updateCompanyProperty('isShowLeverUnauthorizedBanner', data.enabled && !data.authorized);
                    }
                })
                .catch((err) => {
                    if (err.response && err.response.data.msg) {
                        flashMessage(err.response.data.msg, 'error');
                    } else {
                        flashMessage('Something went wrong', 'error');
                    }
                });
        }, 200);
    };

    const removeLevel = () => {
        setRemoveLoading(true);
        unauthorizeLever()
            .then(() => {
                SettingsEvents.LEVER_RESETED();
                setLeverData({
                    enabled: false,
                    authorized: false,
                    authLink: ''
                });
                updateCompanyProperty('isShowLeverUnauthorizedBanner', false);
                flashMessage('Integration removed', 'done');
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setRemoveLoading(false);
            });
    };

    const activateLever = () => {
        // eslint-disable-next-line no-restricted-globals
        const left = (screen.width / 2) - (window.innerWidth / 2);
        // eslint-disable-next-line no-restricted-globals
        const top = (screen.height / 2) - (window.innerHeight / 2);
        const w2 = window.open(
            '',
            '_blank',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=550, height=750, top=${top}, left=${left}`
        );

        setTimeout(() => { w2.location.href = authLink; }, 2000);
        window.addEventListener('message', (event) => {
            const { code, state, error } = event.data;
            if (error) {
                flashMessage(error, 'error');
            } else if (code && state && !error) {
                flashMessage('Authorized successfully', 'done');
                setLeverData({ ...leverData, authorized: true });
                updateCompanyProperty('isShowLeverUnauthorizedBanner', false);
            }
        });
    };

    const handleLeverSwitch = () => {
        SettingsEvents.LEVER_ENABLED({ enabled: !enabled });
        setLeverData({ ...leverData, enabled: !enabled });
        updateLeverConfig('enabled', !enabled);
    };

    return (
        <div>
            <h3 className={classes.heading}>Connect Vervoe to Lever</h3>
            <Switch
                label="Enable Lever Integration"
                checked={enabled}
                value={enabled}
                onChange={handleLeverSwitch}
            />
            <div className="u-dsp--f u-ai--center u-mrg--bx5">
                <Button
                    color="primary"
                    variant="contained"
                    style={{ width: 200 }}
                    className={classes.button}
                    onClick={activateLever}
                    disabled={!authLink || !enabled || authorized}
                >
                    Authorize
                </Button>
                {(authorized && enabled) && (
                    <Button
                        color="primary"
                        onClick={removeLevel}
                        className={classes.button}
                        disabled={onRemoveLoading}
                    >
                        Reset integration
                        {
                            onRemoveLoading && (
                                <CircularProgress className="u-mrg--lx2" size={16} />
                            )
                        }
                    </Button>
                )}
            </div>
            {
                authorized && (
                    <>
                        <div>
                            <TextInput
                                label="Candidate stage change Webhook"
                                type="text"
                                variant="outlined"
                                value={webhookLink}
                                className={classes.input}
                                helperText="Please copy and paste the Candidate stage change Webhook to Lever settings in order to set the integration."
                                endAdornment={(
                                    <CopyToClipboard text={webhookLink} onCopy={() => flashMessage('Webhook Copied')}>
                                        <Button className="u-txt--bold" color="primary">Copy</Button>
                                    </CopyToClipboard>
                                )}
                            />
                        </div>
                        <div className="u-mrg--bx7">
                            <TextInput
                                label="Signature token"
                                type="text"
                                variant="outlined"
                                value={webhookToken}
                                className={classes.input}
                                helperText="Please copy and paste the Webhook Signature token from Lever settings in order to set the integration."
                                onChange={(e) => {
                                    setWebhookToken(e.target.value);
                                    updateLeverConfig('webhookSignatureToken', e.target.value || '');
                                }}
                            />
                        </div>
                    </>
                )
            }
            {
                (authorized && enabled) && (
                    <>
                        <Typography variant="h5" className="u-mrg--bx3">
                            Lever postings
                            {
                                loading && (
                                    <CircularProgress className="u-mrg--lx2" size={23} />
                                )
                            }
                        </Typography>
                        <TableContainer className={classes.tableContainer}>
                            <Table className={classes.table}>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCellHead className={clsx(classes.tableCellHead, 'u-pdn--lx3')} label="Posting Name" hideSort />
                                        <TableCellHead className={classes.tableCellHead} label="Stages used" hideSort />
                                        <TableCellHead className={classes.tableCellHead} label="Assessments used" hideSort />
                                        <TableCellHead hideSort align="right" />
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {
                                        postings.map(posting => (
                                            <Posting
                                                key={posting.postingId}
                                                posting={posting}
                                                loadPostings={loadPostings}
                                            />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )
            }
        </div>
    );
};

export default withStyles(styles)(LeverContent);

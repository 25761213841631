import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'libraries/Tooltip';
import { appCtx } from 'components/appStore';
import Zendesk from 'helper/zendeskFunctions';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import { checkCompanyCanBeUpgraded } from 'helper/commonFunctions';
import DiamondIcon from './icon.svg';


const styles = theme => ({
    iconButton: {
        backgroundColor: theme.palette.yellow[50],
        borderRadius: 14,
        padding: 6,
        '&:hover': {
            backgroundColor: theme.palette.yellow[100]
        },
        '& > img': {
            width: 26
        }
    },
    talkToUsLabel: {
        textDecoration: 'underline',
        marginTop: 12,
        cursor: 'pointer',
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: 0.2,
        color: theme.palette.primary.main
    }
});

const DiamondTooltip = withStyles(styles)(observer(({ classes }) => {
    const { company } = useContext(appCtx);
    const canUpgrade = checkCompanyCanBeUpgraded(company);

    const openZendesk = () => {
        Zendesk.open(true);
    };

    return (
        <Tooltip
            label={(
                <>
                    {
                        canUpgrade
                            ? <SubscriptionsLink label="Upgrade" className={classes.talkToUsLabel} segmentLocation="diamond icon question header" />
                            : <div role="presentation" className={classes.talkToUsLabel} onClick={openZendesk}>Talk to us.</div>
                    } to unlock question
                </>
            )}
        >
            <IconButton className={classes.iconButton}>
                <img src={DiamondIcon} alt="^" />
            </IconButton>
        </Tooltip>
    );
}));

export default DiamondTooltip;

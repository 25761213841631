import React from 'react';
import { makeStyles, withTheme } from '@mui/styles';
import Tick from 'components/grade_components/ALProgressBar/tick';
import Chip from 'components/job_or_assessment_settings/Chip';
import WarningIcon from './question-warning';

const useStyles = makeStyles(theme => ({
    modelItem: {
        display: 'flex',
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.grey[900],
        '&:not(:last-child)': {
            marginBottom: 30
        }
    },
    iconWrapper: {
        width: 16,
        marginRight: 10,
        marginTop: 4
    },
    modelItemHeader: {
        color: props => (props.active ? theme.palette.green[600] : theme.palette.yellow[900]),
        fontSize: 14,
        fontWeight: 700,
        marginRight: 10,
        whiteSpace: 'nowrap'
    }
}));

const InsightsModelItem = ({ active = false, heading, explanation, theme }) => {
    const classes = useStyles({ active });

    return (
        <div className={classes.modelItem}>
            <div className={classes.iconWrapper}>
                {active ? <Tick /> : <WarningIcon />}
            </div>
            <div className="u-dsp--f u-fdir--column">
                <div className="u-dsp--f u-ai--center u-mrg--bx1">
                    <div className={classes.modelItemHeader}>
                        {heading}
                    </div>
                    <Chip
                        text={active ? 'Activated' : 'Grade'}
                        color={active ? theme.palette.green[900] : theme.palette.yellow[900]}
                        backgroundColor={active ? theme.palette.green[50] : theme.palette.yellow[50]}
                    />
                </div>
                <div>
                    {explanation}
                </div>
            </div>
        </div>
    );
};

export default withTheme(InsightsModelItem);

import React, { useContext, useState } from 'react';
import { withTheme } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import { appCtx } from 'components/appStore';
import { checkHasCompanyFeature, checkHasUserPermission, copyTextToClipboard } from 'helper/commonFunctions';
import ResetAuditionDialog from 'components/dialogs/ExtendDialog';
import RejectDialog from 'components/dialogs/RejectDialog';
import UnrejectDialog from 'components/dialogs/UnrejectDialog';
import { extendCandidates } from 'requests/AssessmentRequests';
import CandidateMessaging from 'components/dialogs/CandidateMessaging';
import ShareCandidateDialog from 'components/dialogs/ShareCandidateDialog';
import HireCandidateDialog from 'components/dialogs/HireCandidateDialog';
import RemoveFromHiredDialog from 'components/dialogs/RemoveFromHiredDialog';
import CandidateDetailsDialog from 'components/dialogs/CandidateDetailsDialog';
import DeleteCandidateDialog from 'components/dialogs/DeleteCandidateDialog';
import ArchiveCandidateDialog from 'components/dialogs/ArchiveCandidateDialog';
import GeneralEvents from 'events/GeneralEvents';
import MessageIcon from 'components/icons/MessageIcon';
import EditIcon from 'components/icons/EditIcon';
import TrashIcon from 'components/icons/TrashIcon';
import HiddenEye from 'components/icons/HiddenEye';
import ShownEye from 'components/icons/ShownEye';
import FeedbackIcon from 'img/icons/feedback.svg';

import { CANDIDATE_SHARING } from 'helper/constants';
import ShareIcon from 'img/candidateCard/share.svg';

import { sendCandidateFeedback } from 'requests/EmailRequests';
import ExtendIcon from './extend.svg';
import RemoveIcon from './remove.svg';
import HireIcon from './hire.svg';
import LinkIcon from './share_link.svg';

import useStyles from './styles';

const CandidateMenu = observer(({
    audition, theme, candidate, company,
    dataForEvents = {}, callback, shownOptions,
    resetAuditionCallback, getUserProfile, onDeleteCallback
}) => {
    const [messageDialogOpened, setMessageDialogOpened] = useState(false);
    const [resetDialogOpened, setResetDialogOpened] = useState(false);
    const [rejectDialogOpened, setRejectDialogOpened] = useState(false);
    const [unrejectDialogOpened, setUnrejectDialogOpened] = useState(false);
    const [shareDialogOpened, setShareDialogOpened] = useState(false);
    const [hireDialogOpened, setHireDialogOpened] = useState(false);
    const [removeHiredDialogOpen, setRemoveHiredDialogOpen] = useState(false);
    const [candidateDetailsDialogOpened, setCandidateDetailsDialogOpened] = useState(false);
    const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
    const [archiveDialogOpened, setArchiveDialogOpened] = useState(false);

    const { menuButton, menuIcon } = useStyles();
    const {
        rejected, completionsPerOpportunityLimitExceeded,
        userAuditionId, candidateId, candidateStatus,
        columnUserId, invited, candidateUuid, invitationLink,
        canBeUnRejected, canReceiveCandidateFeedbackEmail,
        canBeDeleted, archived, completed, canBeReset
    } = candidate;

    const hired = candidateStatus === 'Hired';

    const { flashMessage } = useContext(appCtx);

    if (!audition) return null;

    const {
        permissions, slug, uuid: ttId, name: ttName,
        opportunitySlug, expired, active, onboarding,
        valid
    } = audition;
    const { candidateFeedbackEnabled } = company;

    const scoreShare = checkHasUserPermission(company, permissions, 'agencyShare');
    const message = checkHasUserPermission(company, permissions, 'sendMessage');
    const reset = checkHasUserPermission(company, permissions, 'reset');
    const reject = checkHasUserPermission(company, permissions, 'reject');
    const hire = checkHasUserPermission(company, permissions, 'hire');
    const edit = checkHasUserPermission(company, permissions, 'edit');

    const handleMessageDialog = () => setMessageDialogOpened(!messageDialogOpened);

    const handleResetDialog = () => setResetDialogOpened(!resetDialogOpened);

    const handleRejectDialog = () => setRejectDialogOpened(!rejectDialogOpened);

    const handleShareDialog = () => setShareDialogOpened(!shareDialogOpened);

    const handleHireDialog = () => setHireDialogOpened(!hireDialogOpened);

    const handleRemoveHiredDialog = () => setRemoveHiredDialogOpen(!removeHiredDialogOpen);

    const handleCandidateDetailsDialog = () => setCandidateDetailsDialogOpened(!candidateDetailsDialogOpened);

    const handleDeleteDialog = () => setDeleteDialogOpened(!deleteDialogOpened);

    const handleArchiveDialog = () => setArchiveDialogOpened(!archiveDialogOpened);

    const handleUnrejectDialog = () => setUnrejectDialogOpened(!unrejectDialogOpened);

    const extendFunc = () => {
        if (userAuditionId) return extendCandidates(opportunitySlug, [userAuditionId]);
        return () => {};
    };

    const addSendEvent = (data) => {
        GeneralEvents.MESSAGE_SEND(data);
    };

    const copyInvitationLink = () => {
        copyTextToClipboard(invitationLink, () => { flashMessage('Link copied to clipboard', 'done'); });
    };

    const onResetAudition = (candidates) => {
        if (callback) callback();
        if (resetAuditionCallback && candidates && candidates.length && candidates.includes(userAuditionId)) {
            resetAuditionCallback();
        }
        GeneralEvents.DEADLINE_EXTENDED({
            ttId,
            ttName,
            candidateId: candidateUuid
        });
    };

    const onSendFeedback = () => {
        sendCandidateFeedback(userAuditionId).then(({ success }) => {
            if (success) flashMessage('Message sent', 'done');
        });
    };

    const allOptions = {
        message: {
            label: <><MessageIcon color={theme.palette.grey[500]} /> Message</>,
            onClick: handleMessageDialog,
            disabled: !message || completionsPerOpportunityLimitExceeded
        },
        extend: {
            label: <><img src={ExtendIcon} alt="->" /> {invited ? 'Re-Invite' : 'Extend'}</>,
            onClick: handleResetDialog,
            disabled: !reset || completionsPerOpportunityLimitExceeded
                || rejected || hired
                || expired || !active
                || !valid || !canBeReset || archived
        },
        feedback: {
            label: <><img src={FeedbackIcon} alt="->" /> Send candidate feedback</>,
            onClick: onSendFeedback,
            disabled: !canReceiveCandidateFeedbackEmail || !active || !edit || !candidateFeedbackEnabled || archived
        },
        share: {
            label: <><img src={ShareIcon} alt="->" /> Share</>,
            onClick: handleShareDialog,
            disabled: !checkHasCompanyFeature(company, CANDIDATE_SHARING) || completionsPerOpportunityLimitExceeded || archived
        },
        invitation_link: {
            label: <><img src={LinkIcon} alt="->" /> Copy Invitation Link</>,
            onClick: copyInvitationLink,
            disabled: !invitationLink || rejected || hired || completionsPerOpportunityLimitExceeded
        },
        hire: {
            label: <><img src={HireIcon} alt="->" /> Hire candidate</>,
            onClick: handleHireDialog,
            disabled: !hire || rejected || hired || completionsPerOpportunityLimitExceeded || !active
        },
        unhire: {
            label: <><img src={RemoveIcon} alt="->" /> Remove from Hired</>,
            onClick: handleRemoveHiredDialog,
            disabled: !hire || rejected || !hired || completionsPerOpportunityLimitExceeded
        },
        reject: {
            label: <><img src={RemoveIcon} alt="->" /> Reject</>,
            onClick: handleRejectDialog,
            disabled: rejected || hired || !reject || completionsPerOpportunityLimitExceeded || !active
        },
        unreject: {
            label: <><img src={RemoveIcon} alt="->" /> Un-Reject</>,
            onClick: handleUnrejectDialog,
            disabled: !rejected || !canBeUnRejected || !active
        },
        details: {
            label: <><EditIcon color={theme.palette.grey[500]} /> Edit details</>,
            onClick: handleCandidateDetailsDialog,
            disabled: false
        },
        delete: {
            label: <><TrashIcon color={theme.palette.grey[500]} /> Delete candidate</>,
            onClick: handleDeleteDialog,
            disabled: !canBeDeleted || !edit || archived
        },
        archive: {
            label: <><HiddenEye color={theme.palette.grey[500]} /> Archive</>,
            onClick: handleArchiveDialog,
            disabled: archived || !completed || !edit
        },
        unarchive: {
            label: <><ShownEye color={theme.palette.grey[500]} /> Unarchive</>,
            onClick: handleArchiveDialog,
            disabled: !archived || !completed || !edit
        }
    };

    const options = !shownOptions || !shownOptions.map ? Object.values(allOptions) : shownOptions.map(item => allOptions[item]);


    return (
        <div>
            <BoardItemMenu
                iconClassName={menuIcon}
                iconMenuClassName={menuButton}
                options={options}
                disabled={onboarding}
            />
            <div role="presentation" onClick={e => e.stopPropagation()}>
                {
                    messageDialogOpened && (
                        <CandidateMessaging
                            flashMessage={flashMessage}
                            open={messageDialogOpened}
                            sendCallback={addSendEvent}
                            targetCandidates={[{ ...candidate, id: candidateId }]}
                            handleClickMessageModalClose={handleMessageDialog}
                        />
                    )
                }
                <ResetAuditionDialog
                    audition={audition}
                    requestFunc={extendFunc}
                    slug={opportunitySlug}
                    open={resetDialogOpened}
                    handleClose={handleResetDialog}
                    user={candidate}
                    userUuid={candidateUuid}
                    callback={onResetAudition}
                    isInvited={invited}
                />
                <RejectDialog
                    callback={callback}
                    user={candidate}
                    notifyRejectEmail={audition.sendRejectEmail}
                    dataForEvent={dataForEvents}
                    slug={opportunitySlug}
                    open={rejectDialogOpened}
                    assessmentSlug={slug}
                    handleClose={handleRejectDialog}
                />
                <UnrejectDialog
                    callback={callback}
                    user={candidate}
                    dataForEvent={dataForEvents}
                    open={unrejectDialogOpened}
                    flashMessage={flashMessage}
                    handleClose={handleUnrejectDialog}
                />
                <ShareCandidateDialog
                    open={shareDialogOpened}
                    opportunitySlug={opportunitySlug}
                    onClose={handleShareDialog}
                    disabledScoring={!scoreShare}
                    company={company}
                    flashMessage={flashMessage}
                    userIds={[candidateId]}
                    user={candidate}
                    userUuid={candidateUuid}
                    assessment={audition}
                />
                <HireCandidateDialog
                    open={hireDialogOpened}
                    onClose={handleHireDialog}
                    flashMessage={flashMessage}
                    slug={slug}
                    columnUserId={columnUserId}
                    callback={callback}
                    dataForEvent={dataForEvents}
                    assessment={audition}
                    candidate={candidate}
                />
                <RemoveFromHiredDialog
                    open={removeHiredDialogOpen}
                    onClose={() => setRemoveHiredDialogOpen(false)}
                    candidate={{ ...candidate, assessmentTitle: ttName, assessmentUuid: ttId }}
                    callback={callback}
                />
                <CandidateDetailsDialog
                    open={candidateDetailsDialogOpened}
                    onClose={() => setCandidateDetailsDialogOpened(false)}
                    candidate={candidate}
                    callback={() => {
                        handleCandidateDetailsDialog();
                        callback();
                        if (getUserProfile) {
                            getUserProfile(candidate.candidateUuid);
                        }
                    }}
                    company={company}
                    flashMessage={flashMessage}
                    candidateUuid={candidateUuid}
                    audition={audition}
                />
                <DeleteCandidateDialog
                    open={deleteDialogOpened}
                    onClose={handleDeleteDialog}
                    columnUserIds={[columnUserId]}
                    callback={onDeleteCallback || callback}
                    audition={audition}
                />
                <ArchiveCandidateDialog
                    open={archiveDialogOpened}
                    onClose={handleArchiveDialog}
                    userAuditionIds={[userAuditionId]}
                    audition={audition}
                    callback={callback}
                    isArchive={!archived}
                    dataForEvents={dataForEvents}
                />
            </div>
        </div>
    );
});

export default withTheme(withRouter(CandidateMenu));

import React from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { isEmptyObject } from 'helper/validationFunctions';
import Icon from './icon.svg';

const styles = {
    blockWrapper: {
        padding: '45px 0',
        textAlign: 'center'
    }
};

const NotCompletedBlock = observer(({ classes, audition }) => (
    <div className={classes.blockWrapper}>
        <img className="u-mrg--bx3" src={Icon} alt="icon" />
        <Typography variant="h5" className="u-txt--lh14">
            { !isEmptyObject(audition) ? <>Candidate has not completed the assessment. <br /></> : null }
            Results are not available.
        </Typography>
    </div>
));

export default withStyles(styles)(NotCompletedBlock);

import React from 'react';
import { withTheme } from '@mui/styles';

const RegenerateIcon = ({ theme, color = theme.palette.grey[500], ...other }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M4.46154 5H15.5385C16.8979 5 18 6.10207 18 7.46154V18.5385C18 19.8979 16.8979 21 15.5385 21H4.46154C3.10207 21 2 19.8979 2 18.5385V7.46154C2 6.10207 3.10207 5 4.46154 5Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.5385 19C19.1913 19 19.8174 18.7407 20.279 18.279C20.7407 17.8174 21 17.1913 21 16.5385V5.46154C21 4.8087 20.7407 4.1826 20.279 3.72097C19.8174 3.25934 19.1913 3 18.5385 3H7.46154C6.8087 3 6.1826 3.25934 5.72097 3.72097C5.4479 3.99404 5.24561 4.32466 5.12592 4.68433" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 13H7" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 10V16" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(RegenerateIcon);

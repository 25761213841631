import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import EditPanelSection from '../EditPanelSection';

const AutoExtendSettings = ({
    settings: { approveFirstResetRequest },
    changeAssessmentSettings, audition: { name, uuid }
}) => {
    const [approveFirstResetRequestValue, setApproveFirstResetRequestValue] = useState(false);

    useEffect(() => {
        setApproveFirstResetRequestValue(approveFirstResetRequest);
    }, [approveFirstResetRequest]);

    const handleChangeAutoExtendLimit = (e) => {
        const { checked } = e.target;
        changeAssessmentSettings({ approveFirstResetRequest: checked });
        setApproveFirstResetRequestValue(checked);
        AssessmentSettingsEvents.APPROVE_AUTO_EXTEND({ enabled: checked, ttId: uuid, ttName: name });
    };

    return (
        <EditPanelSection
            className="u-pdn--tx0"
            header={(
                <>
                    Always approve first extension request
                    <Switch
                        checked={approveFirstResetRequestValue}
                        onChange={handleChangeAutoExtendLimit}
                    />
                </>
            )}
        >
            <p style={{ marginBottom: 0, marginTop: -20 }}>The initial extension request made by candidates will be automatically granted.</p>
        </EditPanelSection>
    );
};

export default AutoExtendSettings;

export default theme => ({
    leftPanel: {
        minWidth: 400,
        width: 400,
        paddingBottom: 50,
        backgroundColor: theme.palette.grey[50],
        position: 'relative',
        minHeight: '100%',
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderRight: 'none',
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        }
    },
    contentPanel: {
        position: 'relative',
        minWidth: 600,
        minHeight: 700,
        width: 'calc(100% - 400px)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '26px 30px 40px'
        }
    }
});
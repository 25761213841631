import React from 'react';

const StarHalfIcon = ({ color = '#1247F6', size = 16 }) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.9483 2.5L12.2498 7.16264L17.3966 7.91491L13.6724 11.5422L14.5513 16.6667L9.9483 14.246L5.34525 16.6667L6.22415 11.5422L2.5 7.91491L7.64677 7.16264L9.9483 2.5Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.9483 2.5L10.5 10.5V14.246L5.34525 16.6667L6.22415 11.5422L2.5 7.91491L7.64677 7.16264L9.9483 2.5Z" fill={color} />
    </svg>

);


export default StarHalfIcon;

export default theme => ({
    groupSkill: {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.grey[900],
        '& img': {
            marginRight: 10
        }
    },
    groupSkillActive: {
        color: theme.palette.green[600]
    },
    loaderSkill: {
        position: 'absolute',
        right: 20,
        top: 10
    }
});

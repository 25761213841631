import React from 'react';

function DefaultCross({ close, fill = '#3a3c43', className = '' }) {
    return (
        <svg
            role="presentation"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={close}
            className={className}
        >
            <path fill={fill} fillRule="evenodd" d="M10.94 12L3.97 5.03a.75.75 0 0 1 1.06-1.06L12 10.94l6.97-6.97a.75.75 0 0 1 1.06 1.06L13.06 12l6.97 6.97a.75.75 0 0 1-1.06 1.06L12 13.06l-6.97 6.97a.75.75 0 0 1-1.06-1.06L10.94 12z" />
        </svg>
    );
}
export default DefaultCross;


import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import styles from './styles';

const OracleIcon = withStyles(styles)(withTheme(({ theme, isActive, ...other }) => {
    const fillColor = isActive ? theme.palette.primary.main : '#A9AAAE';
    return (
        <svg width="142" height="18" viewBox="0 0 142 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fill={fillColor}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M99.0781 0.261614H101.988V15.1012H114.602L112.749 18H99.0775V0.261614H99.0781ZM76.2313 18L65.4689
             1.09593C64.8073 -0.00185546 63.0434 -0.00185534 62.2936 1.13984L51.62 18H55.1483L58.3683 12.9063H65.249L67.0581 10.0081H60.1775L63.9269 4.16912L72.7043 17.9994H76.2313V18ZM82.7795 0.26718C77.8628 0.26718 74.6259 4.23529 74.6259 9.13111C74.6259 14.0269 77.8634 17.9957 82.7795 17.9957L94.736 17.9814L96.6073 15.108H82.8678C79.5539 15.108 77.6143 12.4319 77.6143 9.13111C77.6143 5.83033 79.5533 3.15421 82.8678 3.15421H95.1069L96.9136 0.258521L82.7802 0.26718H82.7795ZM8.15426 3.15421H20.768C24.0838 3.15421 26.0228 5.83033 26.0228 9.13111C26.0228 12.4319 24.085 15.108 20.768 15.108H8.15426C4.8385 15.108 2.90074 12.4319 2.90074 9.13111C2.90074 5.83033 4.8385 3.15421 8.15426 3.15421ZM8.15426 0.26718C3.23685 0.26718 0 4.23529 0 9.1305C0 14.0257 3.23685 17.9951 8.15426 17.9951H20.768C25.6854 17.9951 28.9223 14.0251 28.9223 9.1305C28.9223 4.23591 25.6861 0.26718 20.768 0.26718H8.15426ZM117.712 10.5363C118.186 13.1592 119.999 15.1074 122.829 15.1074H136.568L134.696 17.9808L122.741 17.9951C117.823 17.9951 114.588 14.0251 114.588 9.1305C114.588 4.23591 117.823 0.26718 122.741 0.26718L136.874 0.25914L135.069 3.15482H122.83C120.039 3.15482 118.218 5.07023 117.713 7.63874H134.98L133.128 10.5369H117.711V10.5363H117.712ZM45.3793 10.7929C48.2993 10.7422 50.6247 8.34504 50.5738 5.43822C50.5241 2.60315 48.2272 0.316657 45.3793 0.26718L30.5376 0.260995V17.9994H33.8018V3.15482H45.3793C46.697 3.15606 47.7644 4.22045 47.7631 5.53223C47.7619 6.84215 46.6952 7.90345 45.3793 7.9053H36.0949L46.5684 17.9796H50.7819L43.2844 10.7998L45.3793 10.7936V10.7929ZM139.922 0.277694C140.679 0.277694 141.278 0.872663 141.278 1.66245C141.278 2.45224 140.68 3.05525 139.922 3.05525C139.164 3.05525 138.56 2.46461 138.56 1.66245C138.56 0.860294 139.156 0.277694 139.922 0.277694V0C139.001 0 138.223 0.705058 138.223 1.66121C138.223 2.61737 139.002 3.33233 139.922 3.33233C140.842 3.33233 141.614 2.62727 141.614 1.66121C141.614 0.695162 140.835 0 139.922 0V0.277694Z"
            />
        </svg>
    );
}));

export default OracleIcon;

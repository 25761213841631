import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import LockIcon from 'img/lock.svg';
import UpgradeDialog from 'components/dialogs/UpgradeDialog';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.yellow[900],
        marginLeft: -8,
        '& img': {
            marginRight: 10,
            width: 20
        }
    }
}));

const UpgradeButton = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { button } = classes;

    const onClick = (e) => {
        e.stopPropagation();
        handleDialog();
    };

    const handleDialog = () => {
        setOpen(!open);
    };

    return (
        <div
            role="presentation"
            onClick={e => e.stopPropagation()}
        >
            <Button
                onClick={onClick}
                className={button}
            >
                <img src={LockIcon} alt="lock" />
                Upgrade to unlock
            </Button>
            <UpgradeDialog
                open={open}
                onClose={handleDialog}
            />
        </div>
    );
};

export default UpgradeButton;

import React from 'react';
import { CircularProgress, Button } from '@mui/material';
import Dialog from 'libraries/Dialog';

const ConfirmDialog = ({
    open,
    loading,
    onClose,
    onAction,
    title,
    children,
    disabledAction,
    isDelete = false,
    actionButtonText,
    cancelButtonText = 'Cancel',
    ...other
}) => {
    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    return (
        <Dialog
            maxWidth="sm"
            onClose={handleClose}
            handleClose={handleClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={handleClose}
                        disabled={loading}
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        className="u-pdn--lx7 u-pdn--rx7 u-wdt--200"
                        color={isDelete ? 'secondary' : 'primary'}
                        variant="contained"
                        onClick={onAction}
                        disabled={loading || disabledAction}
                    >
                        {loading ? <CircularProgress size={24} /> : <>
                            {actionButtonText || (isDelete && 'Delete') || 'Confirm'}
                        </>}
                    </Button>
                </>
            )}
            titleComponent={title}
            open={open}
            {...other}
        >
            {children}
        </Dialog>
    );
};

export default ConfirmDialog;

import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import CompanyLogo from 'components/logos/company_logo';
import { appCtx } from 'components/appStore';
import clsx from 'clsx';
import TimerLabel from './timer_label';
import InterviewStatus from './interview_status';
import useStyles from './styles';

const DialogHeader = observer((props) => {
    const { company } = useContext(appCtx);
    const {
        dialogHeader, dialogHeaderLabel,
        button, interviewStatus, warningTime,
        dialogRightPart, timeLabel
    } = useStyles({});
    const { question, toggleDialogOpen, interview } = props;
    const { timerEnabled, timeLimit } = question;

    return (
        <div className={dialogHeader}>
            <div className={dialogHeaderLabel}>
                <CompanyLogo
                    sizeHeight={25}
                    company={company}
                />
            </div>
            <div className={dialogRightPart}>
                {
                    timerEnabled && (
                        <TimerLabel
                            className={clsx(timeLabel, timeLimit * 60 < 60 && warningTime)}
                            rightTimePart
                            value={timeLimit * 60}
                            totalValue={timeLimit * 60}
                        />
                    )
                }
                {
                    !timerEnabled && (
                        <InterviewStatus
                            interview={interview}
                            className={interviewStatus}
                        />
                    )
                }
                <Button
                    variant="outlined"
                    onClick={toggleDialogOpen}
                    className={button}
                >
                    Close Simulation
                </Button>
            </div>
        </div>
    );
});

export default DialogHeader;

import React from 'react';
import { withStyles } from '@mui/styles';
import QuestionsListItem from './QuestionListItem';

const styles = theme => ({
    list: {
        position: 'relative',
        marginBottom: '25px',
        maxHeight: '225px',
        overflow: 'auto',
        borderRadius: 8,
        backgroundColor: theme.palette.grey[200],
        border: `2px solid ${theme.palette.grey[300]}`
    }
});


function QuestionsList({ classes, list, handleMove, groups, currentGroup }) {
    return (
        <div className="u-pos--relative">
            <div className={classes.list}>
                {
                    (list && list.length) ? list
                        .sort((a, b) => a.sort - b.sort)
                        .map(value => (
                            <QuestionsListItem
                                groups={groups}
                                key={value.id}
                                question={value}
                                handleMove={handleMove}
                                currentGroup={currentGroup}
                                noOtherItems={false}
                            />
                        ))
                        : (
                            <QuestionsListItem
                                question={{
                                    descriptionPlainText: 'There are no questions in this skill group. Move a question to this group in order to apply a score to this group.'
                                }}
                                noOtherItems
                            />
                        )
                }
            </div>
        </div>
    );
}

export default withStyles(styles)(QuestionsList);

import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import React from 'react';
import DiamondIcon from 'img/diamond.svg';
import Tooltip from 'libraries/Tooltip';

const DisabledButton = ({ buttonLabel, className, linkClassName }) => (
    <Tooltip
        boxShadow
        placement="left"
        label={(
            <div>
                <ButtonBase
                    className={linkClassName}
                    onClick={() => window.open('/subscriptions', '_blank')}
                >
                        Upgrade&nbsp;
                </ButtonBase>
                    to unlock
            </div>
        )}
    >
        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="text"
                color="primary"
                className={className}
                disabled
            >
                <img className="u-mrg--rx2" src={DiamondIcon} alt="" />
                {buttonLabel}
            </Button>
        </div>
    </Tooltip>
);

export default DisabledButton;

import React from 'react';
import clsx from 'clsx';

// material components
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { withStyles, withTheme } from '@mui/styles';

import infoIcon from 'img/candidateCard/info.svg';
import Zendesk from 'helper/zendeskFunctions';
import styles from './styles';

const chipData = ['NodeJs', 'PHP', 'C++', 'Python3', 'Ruby', 'Go', 'C', 'Java', 'C#'];


class ListLanguages extends React.Component {
    clickChip = (lang) => {
        const { toggleLanguage } = this.props;
        toggleLanguage(lang.toLowerCase());
    };

    render() {
        const { classes, theme, activeLanguages } = this.props;
        return (
            <div>
                <h3 className={classes.title}>
                    Allow candidates to code in...
                </h3>
                {
                    chipData.map((chip) => {
                        const isActive = activeLanguages.includes(chip.toLowerCase());
                        return (
                            <Chip
                                onClick={() => this.clickChip(chip)}
                                classes={{
                                    root: clsx(isActive ? classes.chipRootActive : classes.chipRoot)
                                }}
                                key={chip}
                                label={(
                                    <div
                                        className={classes.chipsWrapper}
                                        style={{ color: isActive ? '#ffffff' : '#3a3c43' }}
                                    >
                                        {chip}
                                        {
                                            isActive
                                                ? (
                                                    <svg style={{ marginLeft: '10px' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                                                        <path fill="white" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                    </svg>
                                                ) : (
                                                    <svg style={{ marginLeft: '10px' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                                                        <path fill={theme.palette.primary.main} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                    </svg>
                                                )
                                        }
                                    </div>
                                )}
                                className={classes.chip}
                            />
                        );
                    })
                }
                <p className={classes.helperText}>
                    Interested in automatically grading other languages?
                    <Button
                        onClick={() => Zendesk.open(true)}
                        classes={{ root: classes.btnLink }}
                    >
                        Let us know!
                    </Button>
                </p>
                {
                    !activeLanguages.length && (
                        <p className={classes.errorLanguage}>
                            <img style={{ marginRight: 10 }} src={infoIcon} alt="" />
                            Please select at least one language.
                        </p>
                    )
                }
            </div>
        );
    }
}


export default withTheme(withStyles(styles)(ListLanguages));

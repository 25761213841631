import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroller';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import CircularProgress from '@mui/material/CircularProgress';
import { clone } from 'helper/commonFunctions';


import QuestionItem from './QuestionItem';

const styles = () => ({
    questionWrapper: {
        maxHeight: 900,
        padding: '16px 0',
        overflow: 'auto'
    }
});

const QuestionList = ({
    classes, loadQuestions, updateQuestions, saveQuestionsSort,
    onFocus, selectedDuplicatedQuestionId,
    loading, questions, loadMore, threshold = 350,
    hasMorePages, hideReorder, ...other
}) => {
    const [focusedIndex, setFocusedIndex] = useState(null);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;
        let newQuestions = clone(questions);
        newQuestions = arrayMove(newQuestions, oldIndex, newIndex);
        const question = questions[oldIndex];
        if (updateQuestions) updateQuestions(newQuestions, true);
        if (saveQuestionsSort) saveQuestionsSort(question, newIndex);
    };

    const shouldCancelStart = (e) => {
        const { dataset, parentElement } = e.target;
        const { dataset: datasetParent } = parentElement;
        return dataset.drag !== 'drop' && datasetParent.drag !== 'drop';
    };

    const returnSortableItem = (item, index) => (
        <QuestionItem
            question={item}
            questionIndex={index}
            index={index}
            key={item.id}
            hideReorder={hideReorder}
            loadQuestions={loadQuestions}
            onFocus={onFocus}
            selectedDuplicatedQuestionId={selectedDuplicatedQuestionId}
            setFocusedIndex={setFocusedIndex}
            focusedIndex={focusedIndex}
            {...other}
        />
    );

    const SortableItem = SortableElement(({ value, questionIndex }) => returnSortableItem(value, questionIndex));

    const SortableList = SortableContainer(({ items }) => (
        <div>
            {items.map((value, index) => (
                <SortableItem key={value.id} {...{ value, index, questionIndex: index }} />
            ))}
        </div>
    ));


    return (
        <>
            <div className={classes.questionWrapper}>
                <InfiniteScroll
                    pageStart={1}
                    useWindow={false}
                    threshold={threshold}
                    loadMore={!loading && loadMore}
                    hasMore={hasMorePages && !loading}
                    loader={(
                        <div className="u-txt--center">
                            <CircularProgress color="primary" size={20} />
                        </div>
                    )}
                >
                    {
                        hideReorder ? (
                            (questions || []).map((item, questionIndex) => returnSortableItem(item, questionIndex))
                        ) : (
                            <SortableList
                                items={questions || []}
                                onSortEnd={onSortEnd}
                                shouldCancelStart={shouldCancelStart}
                            />
                        )
                    }
                </InfiniteScroll>
            </div>
            <div className="u-txt--center u-mrg--tx2">
                {
                    loading && !hasMorePages && (
                        <CircularProgress size={22} />
                    )
                }
            </div>
        </>
    );
};

export default withStyles(styles)(QuestionList);

import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import styles from './styles';

const JobViteIcon = withStyles(styles)(withTheme(({ theme, isActive, ...other }) => {
    const fillColor = isActive ? theme.palette.primary.main : '#A9AAAE';
    return (
        <svg width="127" height="25" viewBox="0 0 127 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M16.8459 0.436857V16.3423C16.8459 21.4456 14.4756 25 8.35944 25C2.5819 25 0 21.5052 0 16.8388V14.8531H5.24846V16.5409C5.24846 19.3407 6.09498 20.7506 8.33828 20.7506C10.4969 20.7506 11.28 19.3407 11.28 16.4615V0.436857H16.8459ZM39.2576 11.7355C39.2576 4.56712 36.0408 0 29.4803 0C22.9197 0 19.7029 4.56712 19.7029 11.7355V13.2645C19.7029 20.4329 22.9197 25 29.4803 25C36.0408 25 39.2576 20.4329 39.2576 13.2645V11.7355ZM33.6282 14.3169C33.6282 18.606 32.2526 20.7109 29.4803 20.7109C26.7079 20.7109 25.3323 18.606 25.3323 14.3169V10.7029C25.3323 6.41382 26.7079 4.28912 29.4803 4.28912C32.2526 4.28912 33.6282 6.39397 33.6282 10.7029V14.3169ZM50.7069 24.583C56.7384 24.583 59.5108 22.1207 59.5108 17.911C59.5108 14.4956 57.3521 12.6291 54.3258 12.1128C57.0558 11.6561 58.8124 9.80937 58.8124 6.93011C58.8124 3.01827 56.5691 0.416999 50.6646 0.416999H42.2628V24.583H50.7069ZM47.4901 4.52741H49.9873C52.802 4.52741 53.5427 5.79826 53.5427 7.52582C53.5427 9.13424 52.802 10.3654 49.9873 10.3654H47.4901V4.52741ZM50.4318 14.2772C53.3311 14.2772 54.22 15.4686 54.22 17.3749C54.22 19.3407 53.3311 20.4527 50.4741 20.4527H47.4901V14.2772H50.4318ZM73.034 24.583L80.4623 0.416999H75.2773L70.5579 16.7196H70.3886L65.7115 0.416999H60.0822L67.5104 24.583H73.034ZM88.1022 0.436857H82.5997V24.6029H88.1022V0.436857ZM102.493 24.583V4.68626H109.138V0.436857H90.5148V4.68626H97.033V24.583H102.493ZM127 20.3336H116.969V14.3963H124.545V10.2264H116.969V4.70612H126.915V0.456715H111.593V24.6227H127V20.3336Z" fill={fillColor} />
        </svg>
    );
}));

export default JobViteIcon;

export default theme => ({
    moreButton: {
        padding: '22px 2px'
    },
    menuItem: {
        fontWeight: 600,
        fontSize: 12,
        color: theme.palette.grey[700],
        '& > img, & > svg': {
            marginRight: 9,
            width: 20,
            height: 20
        }
    },
    menuItemDisabled: {
        opacity: 0.5,
        cursor: 'default',
        '&:hover, &:focus, &:active': {
            backgroundColor: '#fff'
        }
    },
    menuIconButton: {
        padding: 5
    },
    menuIcon: {
        color: 'rgba(0, 0, 0, 0.2)'
    },
    divider: {
        height: 1,
        width: '100%',
        padding: 0,
        marginTop: 5,
        marginBottom: 5,
        backgroundColor: theme.palette.grey[200]
    }
});

export default theme => ({
    accordion: {
        boxShadow: 'none',
        borderBottom: `2px solid ${theme.palette.grey[200]}`,
        margin: '0 !important',
        '&:before': {
            display: 'none'
        }
    },
    accordionSummary: {
        padding: '15px 20px',
        '&.Mui-expanded': {
            minHeight: 'auto'
        }
    },
    accordionSummaryActive: {
        backgroundColor: theme.palette.grey[100]
    },
    accordionSummaryExpanded: {
        minHeight: 'auto'
    },
    accordionSummaryContent: {
        margin: '0 !important',
        fontWeight: 600,
        color: theme.palette.grey[900],
        fontSize: 12
    },
    accordionDetails: {
        padding: '0 12px'
    },
    popoverRoot: {
        padding: 0
    },
    opportunitiesWrapper: {
        maxHeight: 250,
        overflow: 'auto',
        paddingBottom: 7
    },
    subtopic: {
        color: theme.palette.grey[600],
        fontSize: 12,
        marginBottom: 0
    },
    name: {
        color: theme.palette.grey[900],
        fontSize: 14
    },
    date: {
        color: theme.palette.grey[500],
        fontSize: 12
    },
    headerWrapper: {
        padding: '21px 24px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    assessmentWrapper: {
        position: 'relative',
        fontWeight: 600,
        padding: '12px 40px 12px 24px',
        display: 'block',
        '&:not(:first-child)': {
            borderTop: `2px solid ${theme.palette.grey[200]}`
        }
    },
    assessmentWrapperActive: {
        backgroundColor: theme.palette.grey[100]
    },
    icon: {
        color: theme.palette.grey[600]
    },
    iconButton: {
        width: 40,
        height: 40,
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto 0'
    },
    status: {
        fontSize: 12,
        marginLeft: 10,
        padding: '0 6px',
        borderRadius: 4
    },
    activeStatus: {
        background: theme.palette.green[600],
        color: 'white'
    },
    inActiveStatus: {
        color: theme.palette.grey[400],
        background: theme.palette.grey[200]
    }
});

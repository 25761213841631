import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import PhoneNumber from 'awesome-phonenumber';
import InviteForm from 'components/job_or_assessment_settings/InviteForm';
import PhoneInput from 'components/job_or_assessment_settings/InviteForm/PhoneInput';
import { appCtx } from 'components/appStore';
import { invitationsCtx } from 'pages/assessment/candidate_invite/invitationsStore';

import { clone } from 'helper/commonFunctions';
import { statsCtx } from '../../../../../../statsStore';


const stylesJs = {
    phoneButton: {
        margin: 0,
        height: 40
    }
};


const QuickInviteForm = observer(({
    history, slug, closeDialog, onSentComp,
    onClose, assessment, classes, context
}) => {
    const {
        loading, onValueChange, formRef,
        inviteViaEmailForm, defaultFormInvite,
        onSubmitValidate
    } = useContext(context);
    const { loadAssessmentStats } = useContext(statsCtx);
    const { company } = useContext(appCtx);
    const { loadCandidates } = useContext(invitationsCtx);

    const onHandleChange = (fieldName, value, index) => {
        onValueChange({
            fieldName, value, index
        }, () => {
            // Clear errors
            formRef.current.childs.map(el => el.makeValid());
        });
    };

    const handleMoreCandidates = () => {
        if (closeDialog) closeDialog();
        history.push(`/script/invite/${slug}/invitation`);
    };

    const onAllInvitationsSent = () => {
        closeDialog();
        loadAssessmentStats();
        loadCandidates();
    };

    const getContextPage = () => {
        const stepPages = {
            edit: 'Create',
            invite: 'Invite',
            grading: 'Optimize',
            select: 'Select'
        };

        const { pathname } = history.location;
        return `${stepPages[pathname.split('/')[2]]} page`;
    };

    const onSubmit = () => {
        onSubmitValidate({
            isQuick: true,
            onAllInvitationsSent,
            assessment,
            context: getContextPage()
        });
    };

    const formInvite = inviteViaEmailForm[0] ? inviteViaEmailForm[0] : clone(defaultFormInvite);

    return (
        <>
            <InviteForm
                isQuick
                slug={slug}
                id="quickFormInvite"
                assessment={assessment}
                closeDialog={onClose}
                hideAddMoreRowsButton
                defaultRowCount={1}
                onAllInvitationsSent={onAllInvitationsSent}
                onSentComp={onSentComp}
                context={context}
            />
            {
                formInvite && (
                    <div className="u-dsp--f u-jc--sb u-ai--center">
                        <PhoneInput
                            value={(formInvite.phone && formInvite.phone.value) || ''}
                            isPhoneValid={formInvite.isPhoneValid?.value}
                            onHandleChange={onHandleChange}
                            company={company}
                            indexKey={0}
                            context={context}
                            buttonClassName={classes.phoneButton}
                        />
                        <div className="u-dsp--f">
                            <div className="u-mrg--rx3">
                                <Button
                                    color="primary"
                                    onClick={handleMoreCandidates}
                                    variant="text"
                                    className="u-txt--bold"
                                >
                                    Invite more candidates
                                </Button>
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onSubmit}
                                disabled={loading || !formInvite.email.value || !formInvite.name.value
                                || (formInvite.phone && formInvite.phone.value && !PhoneNumber(`+${formInvite.phone.value}`).isValid())}
                            >
                                {
                                    loading ? (
                                        <CircularProgress
                                            size={24}
                                            thickness={3}
                                            style={{ color: 'white' }}
                                        />
                                    ) : 'Send Invitation'
                                }
                            </Button>
                        </div>
                    </div>
                )
            }
        </>
    );
});

export default withStyles(stylesJs)(withRouter(QuickInviteForm));

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import { getNotInvitedTeamMembersByAssessment } from 'requests/ScriptRequests';

import ExistingUsersList from './ExistingUsersList';
import InviteBlock from './InviteBlock';

import styles from './styles';

const PermissionUsersDialog = observer(({
    open, onClose, users, classes,
    opportunitySlug, onAddTeamMember, onRemoveTeamMember,
    onChangeTeamMember
}) => {
    const [notInvitedList, setNotInvitedList] = useState([]);

    useEffect(() => {
        if (open) {
            loadNotInvitedList();
        }
    }, [open]);

    const loadNotInvitedList = () => {
        getNotInvitedTeamMembersByAssessment(opportunitySlug)
            .then(({ success, data }) => {
                if (success && data) {
                    setNotInvitedList(data);
                }
            });
    };

    return (
        <Dialog
            titleComponent="Assessment Team"
            maxWidth="sm"
            handleClose={onClose}
            onClose={onClose}
            open={open}
        >
            <InviteBlock
                notInvitedList={notInvitedList}
                onAddTeamMember={onAddTeamMember}
                opportunitySlug={opportunitySlug}
                loadNotInvitedList={loadNotInvitedList}
            />
            <div className={classes.label}>
                Anyone on your team can view your assessment and candidates. Only editors can amend.
            </div>
            <ExistingUsersList
                users={users}
                onChangeTeamMember={onChangeTeamMember}
                onRemoveTeamMember={onRemoveTeamMember}
                opportunitySlug={opportunitySlug}
                loadNotInvitedList={loadNotInvitedList}
            />
        </Dialog>
    );
});

export default withStyles(styles)(PermissionUsersDialog);

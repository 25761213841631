export default {
    immersiveImg: {
        cursor: 'pointer',
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        position: 'relative',
        padding: 10,
        borderRadius: 4,
        boxShadow: '0 3px 15px 0 rgba(144, 144, 144, 0.17);'
    },
    immersiveImgBlocked: {
        cursor: 'default'
    },
    customButtonEdit: {
        width: 160,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#3a3c43',
        borderRadius: 4,
        position: 'absolute',
        boxShadow: '0 3px 15px 0 rgba(144, 144, 144, 0.17);',
        left: 30,
        bottom: 20,
        paddingLeft: 10,
        backgroundColor: '#ffffff'
    },
    noEdit: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    }
};

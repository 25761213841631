import React from 'react';
import { withRouter } from 'react-router-dom';
import NoBoardContent from 'components/board/board_no_content';
import EmptyIcon from './icon.svg';
import AddNewSkillButton from '../AddNewSkillButton';


const NoGroupsContent = ({ changeScoreType }) => (
    <NoBoardContent
        header={<span className="u-txt--16">You have no Skills Groups</span>}
        icon={<img src={EmptyIcon} alt="empty-content" />}
        text="Create skills groups for questions to test your candidates"
        onClick={changeScoreType}
        classNameButton="u-txt--16 u-pos--relative"
        hideIconWrapper
        classNameText="u-mrg--tx1 u-mrg--bx2"
        className="u-wdt--100p"
        buttonLabel="Create your first skill"
        ButtonComp={AddNewSkillButton}
        changeScoreType={changeScoreType}
    />
);

export default withRouter(NoGroupsContent);

export default () => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 15,
        marginBottom: 24,
        marginTop: 20
    },
    previewContentWrapper: {
        position: 'relative',
        minHeight: 300,
        maxHeight: 900,
        overflowY: 'auto'
    }
});

export default theme => ({
    header: {
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
        padding: '20px 40px'
    },
    questionsWrapper: {
        padding: '26px 60px 40px',
    },
    skillIcon: {
        minWidth: 24
    }
});
import React from 'react';


const VideoIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M28.4668 25.8729C29.0225 25.3172 29.3346 24.5636 29.3346 23.7778V16.8796L29.1957 6H5.63093C4.84511 6 4.09146 6.31217 3.5358 6.86783C2.98014 7.42349 2.66797 8.17714 2.66797 8.96296V26.7407H26.3717C27.1575 26.7407 27.9111 26.4286 28.4668 25.8729Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.649 14.3234L12.9484 10.7103V17.9364L20.649 14.3234Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.66797 22.6665H29.3346" stroke={color} strokeWidth="1.2" />
    </svg>
);

export default VideoIcon;

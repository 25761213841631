export default theme => ({
    popoverPaper: {
        minWidth: 285,
        fontSize: 14
    },
    organiseIcon: {
        padding: 6
    },
    tooltip: {
        fontSize: 12,
        textAlign: 'center',
        maxWidth: 210
    },
    popoverRoot: {
        padding: 0,
        width: 280
    },
    popoverHeader: {
        padding: '11px 19px 5px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    popoverContent: {
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    addSection: {
        padding: '15px 19px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    skillInput: {
        margin: 0,
        marginRight: 10,
        '& input': {
            padding: 15
        }
    },
    loaderWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    list: {
        maxHeight: 210,
        overflow: 'auto'
    },
    emptyBoard: {
        padding: '35px 0',
        textAlign: 'center'
    },
    listItem: {
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'space-between',
        lineBreak: 'anywhere'
    },
    deleteIcon: {
        padding: 0
    },
    btnWrapper: {
        width: '100%',
        marginLeft: 8
    },
    buttonFakeSelect: {
        width: '100%',
        fontSize: 14,
        fontWeight: 600,
        height: 53.4
    },
    helperText: {
        fontSize: 10,
        fontWeight: 600,
        color: theme.palette.grey[700],
        marginLeft: 10
    },
    buttonErr: {
        color: theme.palette.red[800],
        borderColor: theme.palette.red.primary,
        '&:hover, &:active, &:focus': {
            color: theme.palette.red[800]
        }
    },
    innerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
});

import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import Button from '@mui/material/Button';
import PlanEvents from 'events/PlanEvents';
import Zendesk from 'helper/zendeskFunctions';

import clsx from 'clsx';
import styles from './styles';
import { subscriptionDialogStoreCtx } from '../../../store';

const PlanInfoBlock = observer(({ classes }) => {
    const { company, flashMessage } = useContext(appCtx);
    const {
        interval, showSecret,
        period, companyPlan, isSelectedCurrent,
        selectedPlan, handleOpenPaymentDetails,
        selectedPrice, isPlanHasPrices
    } = useContext(subscriptionDialogStoreCtx);

    if (!selectedPlan) return;

    const { name, publicAuditionPlans, level, id, defaultCompanySettings } = selectedPlan;
    const auditionPlan = publicAuditionPlans.find(({ currency, period: periodAP }) => currency === company.currencyStripe && periodAP === interval);
    const isCurrent = (period === interval.slice(0, -2) || (companyPlan && !period)) && companyPlan.id === id;

    const inquireTrigger = () => {
        Zendesk.open(true);
    };

    const selected = {
        id,
        name,
        defaultCompanySettings,
        ...auditionPlan
    };

    const handleSelectPlan = () => {
        PlanEvents.BUY_PLAN_CLICKED({ selectedPlan: selectedPlan.name });
        if (companyPlan.level > level) {
            PlanEvents.PLAN_CONTACT();
            flashMessage('You can’t downgrade from a high plan to a lower plan. Please contact support.', 'error');
        } else {
            handleOpenPaymentDetails(selected);
        }
    };
    const buyNowDisabled = isPlanHasPrices ? !selectedPrice : false;

    const selectButton = (
        <Button
            variant="outlined"
            fullWidth
            className={clsx(classes.button, isSelectedCurrent && classes.buyButtonCurrent)}
            onClick={handleSelectPlan}
            disabled={buyNowDisabled}
        >
            Buy Now
        </Button>
    );

    let renderedButton = null;

    if ((isCurrent && !isPlanHasPrices) || (name.toLowerCase() === 'enterprise' && !showSecret)) {
        renderedButton = null;
    } else {
        renderedButton = selectButton;
    }

    return (
        <div className="u-fdir--column u-dsp--f">
            {renderedButton}
            <Button
                variant="contained"
                fullWidth
                className={clsx(classes.talkButton, isSelectedCurrent && classes.talkButtonCurrent, name.toLowerCase() === 'enterprise' && !isSelectedCurrent && classes.talkButtonEnterprise)}
                onClick={inquireTrigger}
            >
                Talk To Sales
            </Button>
        </div>
    );
});

export default withStyles(styles)(PlanInfoBlock);

import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Layout from 'components/layout';
import InnerHeader from 'components/layout/InnerHeader';
import DrawerPageWrapper from 'components/layout/DrawerPageWrapper';

import ContentLibraryTabs from '../common/ContentLibraryTabs';
import ContentDuplicatedQuestions from './content_duplicated_questions';
import QuestionDrawer from '../common/QuestionDrawer';


import styles from './styles';

const QuestionDuplicates = observer(({ classes }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedDuplicatedQuestionId, setSelectedDuplicatedQuestionId] = useState(null); // value for duplicates sorting opening
    const [selectedQuestionId, setSelectedQuestionId] = useState(null); // value for drawer opening

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const onDeleteQuestion = (id) => {
        if (selectedDuplicatedQuestionId === id) setSelectedDuplicatedQuestionId(null);
    };

    const handleSelectedSkillId = (id) => {
        handleOpenDrawer();
        setSelectedQuestionId(id);
    };

    return (
        <Layout>
            <InnerHeader
                header="Question Duplicates"
                className={classes.wrapper}
            />
            <DrawerPageWrapper contentWrapperCls="u-fdir--column" className="u-pdn--bx5">
                <ContentLibraryTabs />
                <ContentDuplicatedQuestions
                    openDrawer={openDrawer}
                    setSelectedQuestionId={handleSelectedSkillId}
                    handleOpenDrawer={handleOpenDrawer}
                    selectedQuestionId={selectedQuestionId}
                    selectedDuplicatedQuestionId={selectedDuplicatedQuestionId}
                    setSelectedDuplicatedQuestionId={setSelectedDuplicatedQuestionId}
                />
                <QuestionDrawer
                    open={openDrawer}
                    selectedQuestionId={selectedQuestionId}
                    setSelectedQuestionId={setSelectedQuestionId}
                    toggleDrawer={toggleDrawer}
                    onDelete={onDeleteQuestion}
                />
            </DrawerPageWrapper>
        </Layout>
    );
});

export default withRouter(withStyles(styles)(QuestionDuplicates));

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import VervoeLogo from 'components/layout/Header/components/VervoeLogo';
import CheckIcon from './check.svg';
import Img1Trial from './pic1_trial.png';
import Img2Trial from './pic2_trial.png';
import Img3Trial from './pic3_trial.png';
import Img4Trial from './pic4_trial.png';
import Img1 from './pic1.png';
import Img2 from './pic2.png';
import Img3 from './pic3.png';
import Img4 from './pic4.png';
import PremiumImg from './premium2.png';

import styles from './styles';

const RegistrationSidebarWrapper = ({ classes, children, noSidebar = false, isPremium, isTrial }) => {
    const getSidebarImage = () => {
        if (isPremium) {
            return (
                <div className={clsx(classes.premiumSidebar, classes.sidebar)}>
                    <img className="" src={PremiumImg} alt="v" />
                </div>
            );
        }

        return (
            <div className={classes.sidebar}>
                <img className={clsx(classes.img, classes.pic1)} src={isTrial ? Img1Trial : Img1} alt="Vervoe" />
                <img className={clsx(classes.img, classes.pic2)} src={isTrial ? Img2Trial : Img2} alt="Vervoe" />
                <img className={clsx(classes.img, classes.pic3)} src={isTrial ? Img3Trial : Img3} alt="Vervoe" />
                <img className={clsx(classes.img, classes.pic4)} src={isTrial ? Img4Trial : Img4} alt="Vervoe" />
                <div className="u-dsp--f u-fdir--column">
                    <div className={classes.header}>See people do the job before they get the job</div>
                    <div className={classes.subheader}>Get started for free today</div>
                    <div className={classes.features}>
                        {[
                            'No credit card required',
                            '300+ role-specific tests',
                            'Automatic grading by AI'
                        ]
                            .map(feature => (
                                <div key={feature} className={classes.feature}>
                                    <img className="u-mrg--rx2" src={CheckIcon} alt="v" />
                                    {feature}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    };

    return (
        (
            <div className={classes.wrapper}>
                <div className={classes.banner} />
                <div className={classes.main}>
                    <a href="https://vervoe.com"><VervoeLogo className={classes.vervoeLogo} /></a>
                    <div className={classes.content}>
                        { children }
                    </div>
                </div>
                { !noSidebar && getSidebarImage()}
            </div>
        )
    );
};

export default withStyles(styles)(RegistrationSidebarWrapper);

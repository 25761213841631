export default theme => ({
    testRunRow: {
        width: '100%',
        '& > div:last-child': {
            minWidth: 190
        }
    },
    testRunRowExposed: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    testRunOrder: {
        color: theme.palette.blue[500]
    },
    testRunResult: {
        width: 90,
        height: 30,
        color: 'white',
        borderRadius: 4
    },
    greenBg: {
        backgroundColor: theme.palette.green[800]
    },
    redBg: {
        backgroundColor: theme.palette.red[500]
    },
    greyBg: {
        backgroundColor: theme.palette.grey[500]
    },
    rotated: {
        transform: 'rotate(180deg)'
    }
});

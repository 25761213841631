import React from 'react';
import LinkifyComponent from 'libraries/LinkifyComponent';
import HtmlQuestionDescription from 'components/assessments_pages/HtmlDescription';

const TextQuestion = ({ description, className }) => (
    <LinkifyComponent className={className}>
        <HtmlQuestionDescription htmlDescription={description} />
    </LinkifyComponent>
);

export default TextQuestion;

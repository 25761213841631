export default theme => ({
    wrapper: {
        width: '100%',
        backgroundColor: theme.palette.blue[50],
        padding: '35px 60px 35px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 165,
        color: theme.palette.grey[900],
        '@media only screen and (max-width: 1200px)': {
            flexWrap: 'wrap',
            paddingRight: 50,
            paddingTop: 15
        }
    },
    wrapperCurrent: {
        backgroundColor: theme.palette.blue[500],
        color: 'white'
    },
    planName: {
        fontSize: 38,
        fontWeight: 700
    }
});

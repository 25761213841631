export default theme => ({
    listItem: {
        marginBottom: 16,
        color: '#3A3C43'
    },
    listItemHeader: {
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '33px',
        letterSpacing: 0.6,
        textTransform: 'uppercase',
        color: '#9FA0A5'
    },
    text: {
        marginBottom: 15
    },
    contactLink: {
        cursor: 'pointer',
        color: theme.palette.primary.main
    },
    progressWrapper: {
        textAlign: 'center',
        marginTop: 25
    },
    progress: {
        color: 'rgba(0, 0, 0, 0.5)'
    },
    input: {
        maxWidth: 500
    },
    textFieldWrapper: {
        position: 'relative',
        maxWidth: 500,
        marginTop: 30
    },
    buttonRoot: {
        backgroundColor: 'rgb(245, 245, 245)',
        position: 'absolute',
        right: 13,
        top: 29,
        zIndex: 1,
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 1.17,
        letterSpacing: 0.2,
        color: '#676a78',
        '&:hover': {
            backgroundColor: 'rgb(245, 245, 245)'
        }
    },
    saveButton: {
        padding: '10px 100px',
        fontWeight: 'bold',
        marginTop: 10
    }
});

import React from 'react';


const ImageIcon = ({ color = '#A9AAAE', ...other }) => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M51.6782 15.2383L52.7146 14.154L51.6782 15.2383C51.7769 15.3326 51.8327 15.4632 51.8327 15.5997V56.6663C51.8327 56.9425 51.6088 57.1663 51.3327 57.1663H12.666C12.3899 57.1663 12.166 56.9425 12.166 56.6663V7.33301C12.166 7.05687 12.3899 6.83301 12.666 6.83301H42.6844C42.8131 6.83301 42.9369 6.88264 43.0299 6.97157L51.6782 15.2383Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25.332 24C27.5412 24 29.332 22.2091 29.332 20C29.332 17.7909 27.5412 16 25.332 16C23.1229 16 21.332 17.7909 21.332 20C21.332 22.2091 23.1229 24 25.332 24Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M42.7542 24L43.8199 22.9444C43.5314 22.6531 43.1363 22.4926 42.7263 22.5003C42.3164 22.5079 41.9275 22.6829 41.65 22.9847L42.7542 24ZM53.0644 32.2778L43.8199 22.9444L41.6885 25.0556L50.9329 34.3889L53.0644 32.2778ZM41.65 22.9847L12.2278 54.9847L14.4362 57.0153L43.8584 25.0153L41.65 22.9847Z" fill={color} />
    </svg>
);

export default ImageIcon;

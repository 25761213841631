import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        padding: '10px 0',
        marginTop: 20,
        borderTop: `2px solid ${theme.palette.grey[200]}`
    },
    iconWrapper: {
        display: 'flex',
        width: 33,
        minWidth: 33,
        height: 33,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 2
    },
    achievementWrapper: {
        display: 'flex',
        overflow: 'auto'
    },
    achievement: {
        minWidth: 190,
        fontSize: 12,
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'space-between',
        '&:not(:last-child)': {
            marginRight: 15
        }
    },
    achievementLong: {
        minWidth: 270
    },
    achievementSuperLong: {
        minWidth: 350
    }
}));

import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import ChevronIcon from '../icons/chevron.svg';
import PassedIcon from '../icons/passed_icon.svg';
import FailedIcon from '../icons/failed_icon.svg';
import styles from '../styles';

export default withStyles(styles)(({ answer, classes, order }) => {
    const [isExposed, setIsExposed] = useState(false);

    const toggleExposed = () => setIsExposed(!isExposed);
    let answerText = 'To Do';
    let answerBgClass = classes.greyBg;
    if (answer.changedChecked) {
        answerText = answer.checked ? 'Passed' : 'Failed';
        answerBgClass = answer.checked ? classes.greenBg : classes.redBg
    }

    return (
        <div className={clsx(classes.testRunRowExposed, 'u-pdn--bx2 u-mrg--tx2')}>
            <div className={clsx(classes.testRunRow, 'u-dsp--f u-jc--sb ')}>
                <div className={clsx(classes.testRunOrder, 'u-txt--12 u-dsp--f u-ai--center')}>TC-{order}</div>
                <div className="u-dsp--f u-jc--sb">
                    <div className={clsx(classes.testRunResult, answerBgClass, 'u-dsp--f u-ai--center u-jc--center')}>
                        {answerText}
                    </div>
                    <img src={answer.changedChecked && answer.correctChoice === answer.checked ? PassedIcon : FailedIcon} alt="" />
                    <img className={clsx(isExposed && classes.rotated, 'u-cursor--p')} src={ChevronIcon} alt="" onClick={toggleExposed} />
                </div>
            </div>
            {isExposed && <div className="u-mrg--tx2 u-txt--13">{answer.title}</div>}
        </div>
    );
});

import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import VervoeLogo from 'components/layout/Header/components/VervoeLogo';

import styles from './styles';

const NotFound = ({ classes, mainText, additionalText, icon }) => (
    <div className={classes.wrapper}>
        <Container className={classes.container}>
            <NavLink className={classes.vervoeLogo} to="/">
                <VervoeLogo />
            </NavLink>
            <div className={classes.content}>
                <img src={icon} className={classes.icon} alt="404" />
                <p className={classes.mainText}>{mainText}</p>
                <div className={classes.additionalText}>
                    {additionalText}
                </div>
            </div>
        </Container>
    </div>
);

export default withStyles(styles)(NotFound);

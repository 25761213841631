export default theme => ({
    userGrade: {
        fontSize: '18px',
        textAlign: 'right',
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    questionTimerWrapper: {
        display: 'flex',
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 700,
        marginBottom: 0,
        alignItems: 'center',
        color: theme.palette.grey[600]
    },
    timerIcon: {
        marginLeft: '-2px',
        marginRight: '2px'
    },
    employersWrapper: {
        width: '32px',
        height: '32px'
    },
    scoreWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontSize: 11,
        fontWeight: 600,
        color: theme.palette.grey[700]
    }
});

import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import TooltipBlack from 'libraries/Tooltip';
import useStyles from './styles';

const PermanentDrawer = observer(({
    open, permanentDrawerWidth = 55,
    permanentDrawerWidthMini = permanentDrawerWidth,
    menuItems, anchor = 'right', isMiniVariant, tooltipProps = {},
    hideTooltip
}) => {
    const classes = useStyles({ permanentDrawerWidth, permanentDrawerWidthMini, isRight: anchor === 'right' });
    return (
        <Drawer
            anchor={anchor}
            open={open}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open && isMiniVariant,
                [classes.drawerClose]: !open && isMiniVariant
            })}
            classes={{
                paper: clsx(classes.drawerPaper, {
                    [classes.drawerOpen]: open && isMiniVariant,
                    [classes.drawerClose]: !open && isMiniVariant
                })
            }}
            onClose={() => {}}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
                style: { position: 'absolute' }
            }}
            variant="permanent"
        >
            <List className={classes.list}>
                {
                    menuItems.map(({ active, name, onClick, icon, text, tooltipText }) => {
                        const tooltipElement = (
                            <ListItemIcon className={clsx(isMiniVariant ? classes.listIconMini : classes.listIcon)}>
                                {icon}
                            </ListItemIcon>
                        );

                        return (
                            <ListItem
                                key={name}
                                className={clsx(classes.listRoot, active && classes.activeListRoot)}
                                button
                                onClick={onClick}
                            >
                                {
                                    hideTooltip ? (
                                        tooltipElement
                                    ) : (
                                        <TooltipBlack
                                            label={tooltipText}
                                            {...tooltipProps}
                                        >
                                            {tooltipElement}
                                        </TooltipBlack>
                                    )
                                }
                                {
                                    text && (
                                        <ListItemText
                                            classes={{ primary: clsx(classes.listText, active && classes.listTextActive) }}
                                            primary={text}
                                        />
                                    )
                                }
                            </ListItem>
                        );
                    })
                }
            </List>
        </Drawer>
    );
});

export default PermanentDrawer;

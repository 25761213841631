export default theme => ({
    parameter: {
        color: theme.palette.grey[500],
        fontWeight: 600
    },
    switch: {
        marginTop: 20,
        marginBottom: 0
    }
});

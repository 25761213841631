import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Pagination from 'components/board/board_pagination';
import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';
import { CANDIDATES_PER_ALL_CANDIDATES_PAGE } from 'helper/constants';

import CandidateRow from './CandidateRow';
import { hiredCandidateCtx } from '../../../../HiredCandidates/hiredCandidateStore';
import { candidateCtx } from '../../../../AllCandidates/candidateStore';


const HiredCandidatesTable = observer(() => {
    const {
        handleChangePage, hiredCandidates,
        totalCount: candidatesLength,
        page, handleChangeSort, sortOrder,
        sortBy
    } = useContext(hiredCandidateCtx);
    const { fetchAllCandidatesStats } = useContext(candidateCtx);
    const totalPagesCount = Math.ceil(candidatesLength / CANDIDATES_PER_ALL_CANDIDATES_PAGE);

    const sortProps = {
        onSort: handleChangeSort,
        sortOrder,
        sortCategory: sortBy,
        hideSort: !candidatesLength
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellHead label="Name" {...sortProps} labelComponent="Candidate" />
                        <TableCellHead label="Email" hideSort />
                        <TableCellHead label="Assessment" hideSort />
                        <TableCellHead label="Date" {...sortProps} labelComponent="Hired Date" />
                        <TableCellHead label="Hiring Manager" hideSort />
                        <TableCellHead align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object
                            .keys(hiredCandidates)
                            .map(key => (
                                <CandidateRow
                                    key={key}
                                    candidate={hiredCandidates[key]}
                                    callback={fetchAllCandidatesStats}
                                />
                            ))
                    }
                </TableBody>
            </Table>
            <Pagination
                currentPage={page}
                totalPages={totalPagesCount}
                handleChangePage={handleChangePage}
            />
        </TableContainer>
    );
});

export default HiredCandidatesTable;

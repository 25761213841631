import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { checkHasUserPermission } from 'helper/commonFunctions';

import PermissionUsersList from 'components/job_or_assessment_settings/PermissionUsersList';
import AssessmentHeaderWithStepper from 'components/assessments_pages/AssessmentHeaderWithStepper';
import { appCtx } from 'components/appStore';

import ActiveLabel from 'components/assessments_pages/ActiveLabel';
import PreviewButton from './components/PreviewButton';
import CandidateButton from './components/CandidateButton';


import { assessmentSettingsCtx } from '../../store';
import ShareButton from './components/ShareButton';

const EditAssessmentHeader = observer(({ match }) => {
    const { company } = useContext(appCtx);
    const {
        setAuditionIsActive, setAudition,
        audition, loadEditScript
    } = useContext(assessmentSettingsCtx);

    const { interviewSlug } = match.params;

    const { countCompleteQuestions, name, permissions } = audition;
    const edit = checkHasUserPermission(company, permissions, 'edit');

    return <>
        <AssessmentHeaderWithStepper
            audition={audition}
            header={(
                <>
                    {name}
                    <ActiveLabel
                        audition={audition}
                        loadEditScript={loadEditScript}
                        setAuditionIsActive={setAuditionIsActive}
                    />
                </>
            )}
            rightPart={(
                <>
                    <PermissionUsersList
                        hasEditPermission={edit}
                        audition={audition}
                        loadRoles={loadEditScript}
                        setAudition={setAudition}
                    />
                    <ShareButton
                        audition={audition}
                        slug={interviewSlug}
                        disabled={!countCompleteQuestions}
                    />
                    <PreviewButton
                        audition={audition}
                        slug={interviewSlug}
                        disabled={!countCompleteQuestions}
                    />
                    <CandidateButton
                        audition={audition}
                        disabled={!edit || !audition.active}
                    />
                </>
            )}
        />
    </>;
});

export default withRouter(EditAssessmentHeader);

import React, { Fragment } from 'react';

// material components
import Button from '@mui/material/Button';
import { withStyles, withTheme } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

// local files and components
import AddCircle from 'img/add_circle.svg';
import Pencil from 'img/pencil.svg';
import DropZone from '../../../../../DropZone';
import styles from './styles';

const ExcelCase = ({
    excelLink,
    title, loading,
    handleExcelDialog,
    createExcel,
    onDrop, classes
}) => {
    const link = excelLink && excelLink.previewUrl;

    const onClick = () => {
        if (loading) return;
        if (link) {
            handleExcelDialog();
        } else {
            createExcel();
        }
    };

    return (
        <Fragment>
            <div
                className="o-box o-box--whitefull u-cursor--p u-mrg--bx1 c-img-upload-box"
                onClick={onClick}
                style={{
                    marginTop: '25px',
                    boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.13)'
                }}
                role="presentation"
            >
                <div className="u-txt--center">
                    {
                        loading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <>
                                <img src={link ? Pencil : AddCircle} alt="" />
                            </>
                        )
                    }
                    <br />
                    <p className={classes.btnLink}>
                        {`${link ? 'Edit ' : 'Create new '} ${title}`}
                    </p>
                </div>
            </div>
            {
                (!link) && (
                    <DropZone
                        onDrop={onDrop}
                        disabled={loading}
                        minSize={1}
                        maxSize={10485760}
                        label={(
                            <p className={classes.linkWrapper}>
                                <Button
                                    color="primary"
                                    className={classes.uploadExist}
                                >
                                    {`or upload existing ${title}`}
                                </Button>
                                (max file size 10MB)
                            </p>
                        )}
                    />
                )
            }
        </Fragment>
    );
};

export default withTheme(withStyles(styles)(ExcelCase));

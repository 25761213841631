import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';

const styles = {
    submitButton: {
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 180
    },
    loader: {
        color: '#fff'
    }
};

const ActivateAssessmentLowPlanDialog = ({
    open, classes, onClose, onClickActivate
}) => (
    <Dialog
        maxWidth="sm"
        titleComponent="Re-open assessment"
        actionComponent={(
            <>
                <Button
                    className={classes.submitButton}
                    variant="contained"
                    color="primary"
                    onClick={(e) => { onClickActivate(e); onClose(e); }}
                >
                    Reopen now
                </Button>
            </>
        )}
        open={open}
        handleClose={onClose}
        onClose={onClose}
    >
        <p>
            Re-open assessment and start inviting new candidates.
        </p>
    </Dialog>
);

export default withStyles(styles)(withRouter(ActivateAssessmentLowPlanDialog));

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    inputRoot: {
        margin: 0
    },
    input: {
        maxWidth: 30,
        padding: '6px 0 6px 12px'
    },
    orLabel: {
        fontSize: 14,
        color: '#3a3c43',
        fontWeight: 600,
        marginLeft: 120
    },
    actionButton: {
        paddingTop: 5,
        marginLeft: -8,
        color: theme.palette.primary
    },
    agreementText: {
        paddingTop: 7,
        color: theme.palette.blue[500],
        fontWeight: 600,
        '& a': {
            color: theme.palette.blue[500],
            textDecoration: 'underline'
        }
    }
}));

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { checkHasCompanyFeature, checkCompanyCanBeUpgraded } from 'helper/commonFunctions';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import Zendesk from 'helper/zendeskFunctions';
import NumberInput from 'components/inputs/NumberInput';
import { appCtx } from 'components/appStore';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import { assessmentSettingsCtx } from 'pages/assessment/store';

import EditPanelSection from '../EditPanelSection';
import DateTimeDeadline from './DateTimeDeadline';

import styles from './styles';

const MIN_DEADLINE = 1;
const MAX_DEADLINE = 29;
const DEFAULT_DEADLINE = 4;
let timedId;

const DeadlineSettings = observer(({ changeAssessmentSettings, setAudition, audition, settings, classes }) => {
    const [daysCount, setDaysCount] = useState(daysCount);
    const [expirationDate, setExpirationDate] = useState(expirationDate);
    const [showError, setShowError] = useState(false);
    const [deadlineType, setDeadlineType] = useState(null); // 'days', 'date' or null
    const { company } = useContext(appCtx);
    const { setAudition: setAssessment, audition: assessment } = useContext(assessmentSettingsCtx);

    useEffect(() => {
        setDaysCount(settings.daysToExpiration > -1 ? settings.daysToExpiration : DEFAULT_DEADLINE);
        setExpirationDate(settings.expirationDate);
        if (settings.daysToExpiration !== -1) setDeadlineType('days');
        if (settings.expirationDate) setDeadlineType('date');
    }, [settings]);

    const handleChangeDaysCount = (e) => {
        e.preventDefault();
        const newValue = +e.target.value;
        const newShowError = newValue < MIN_DEADLINE || newValue > MAX_DEADLINE;
        setDaysCount(newValue);
        setShowError(newShowError);
        if (!newShowError) {
            clearTimeout(timedId);
            timedId = setTimeout(() => {
                changeAssessmentSettings({ daysToExpiration: newValue, expirationDate: null })
                    .then(() => setAudition({ ...audition, daysToExpiration: newValue, expirationDate: null }));
            }, 300);
        }
    };

    const handleChangeDeadlineDate = (newDate, isExpired) => {
        setExpirationDate(newDate);
        if (!isExpired) {
            changeAssessmentSettings({
                daysToExpiration: -1,
                expirationDate: newDate
            })
                .then(() => setAudition({
                    ...audition,
                    daysToExpiration: -1,
                    expirationDate: newDate,
                    expired: isExpired
                }));
        }
    };

    const handleToggleDeadline = () => {
        const newDaysToExpiration = deadlineType ? -1 : DEFAULT_DEADLINE;
        changeAssessmentSettings({ daysToExpiration: newDaysToExpiration, expirationDate: null })
            .then(() => {
                setAudition({ ...audition, daysToExpiration: newDaysToExpiration, expired: false });
                setAssessment({ ...assessment, daysToExpiration: newDaysToExpiration, expired: false });
                setDeadlineType(deadlineType ? null : 'days');
            });
    };

    const handleChangeDeadlineType = (e) => {
        const { value } = e.target;
        setDeadlineType(value);
        if (value === 'days') {
            setDaysCount(DEFAULT_DEADLINE);
            changeAssessmentSettings({ daysToExpiration: DEFAULT_DEADLINE, expirationDate: null })
                .then(() => {
                    setAudition({ ...audition, daysToExpiration: DEFAULT_DEADLINE, expired: false });
                    setAssessment({ ...assessment, daysToExpiration: DEFAULT_DEADLINE, expired: false });
                });
        }
    };

    const openZendesk = () => {
        Zendesk.open(true);
    };

    const { isAtsEnabled } = company;
    const { apiIntegration } = company.companySettings;

    if (settings.daysToExpiration < -1) return null;

    if (isAtsEnabled) {
        return (
            <EditPanelSection
                className={classes.wrapper}
                header={(
                    <>
                        Deadline
                        <InfoTooltip
                            id="integrations-deadline"
                            isBlack
                            placement="left"
                            text="Deadlines are deactivated when using the PageUp integration. Use the PageUp interface to customize Skills Assessment deadlines."
                        />
                    </>
                )}
            >
                <p className="u-mrg--tx3">No deadline</p>
            </EditPanelSection>
        );
    }

    if (checkHasCompanyFeature(company, 'CHANGE_AUDITION_DEADLINE')) {
        return (
            <EditPanelSection
                className={classes.wrapper}
                header={(
                    <>
                        Set Deadline
                        <div>
                            <InfoTooltip
                                id="integrations-deadline"
                                isBlack
                                placement="left"
                                text="Set a custom deadline for candidates to complete their Skills Assessment by."
                            />
                            {apiIntegration && (
                                <Switch
                                    checked={deadlineType}
                                    onChange={handleToggleDeadline}
                                />
                            )}
                        </div>
                    </>
                )}
            >
                <RadioGroup
                    row
                    disabled={!deadlineType}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={deadlineType}
                    onChange={handleChangeDeadlineType}
                >
                    <FormControlLabel classes={{ label: classes.formControlLabel }} value="date" control={<Radio disabled={!deadlineType} />} label="Date and Time" />
                    <FormControlLabel classes={{ label: classes.formControlLabel }} value="days" control={<Radio disabled={!deadlineType} />} label="No. of Days" />
                </RadioGroup>
                {deadlineType === 'date' ? <>
                    <DateTimeDeadline
                        expirationDate={expirationDate}
                        handleChangeDeadlineDate={handleChangeDeadlineDate}
                    />
                    <p className="u-mrg--tx3">Candidate deadline for submission from the moment they apply or are invited.</p>
                </> : <>
                    <div className="u-mrg--tx2" style={{ minHeight: 100 }}>
                        {deadlineType === 'days'
                            ? <>
                                <div className="u-dsp--f u-ai--center">
                                    <NumberInput
                                        name="timerCount"
                                        value={daysCount}
                                        onChange={handleChangeDaysCount}
                                        type="number"
                                        min={MIN_DEADLINE}
                                        max={MAX_DEADLINE}
                                        className="u-mrg--rx2"
                                        disabled={!deadlineType}
                                    />
                                    <Typography variant="h6">Days</Typography>
                                </div>
                                <p className="u-mrg--tx3">Candidate deadline for submission from the moment they apply or are invited.</p>
                            </> : <>No deadline</>
                        }
                        {
                            showError && (
                                <p className={classes.errorStyle}>Deadline must be greater than 0 days and less than 30 days</p>
                            )
                        }
                    </div>
                </>
                }
            </EditPanelSection>
        );
    }

    return (
        <EditPanelSection
            className={classes.wrapper}
            header={(
                <>
                    Deadline
                    <InfoTooltip
                        id="integrations-deadline"
                        isBlack
                        placement="left"
                        text="Set a custom deadline for candidates to complete their Skills Assessment by."
                    />
                </>
            )}
        >
            <RadioGroup
                disabled
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={deadlineType}
            >
                <FormControlLabel disabled classes={{ label: classes.formControlLabel }} value="date" control={<Radio />} label="Date and Time" />
                <FormControlLabel disabled classes={{ label: classes.formControlLabel }} value="days" control={<Radio />} label="No. of Days" />
            </RadioGroup>
            <div className="u-dsp--f u-ai--center">
                <NumberInput
                    name="timerCount"
                    value={daysCount}
                    disabled
                    className="u-mrg--rx2"
                />
                <Typography variant="h6">Days</Typography>
            </div>
            <p className="u-mrg--tx3">Candidate deadline for submission from the moment they apply or are invited.</p>
            <p className={classes.labelStyle}>Want to enable custom deadlines?&nbsp;
                {
                    checkCompanyCanBeUpgraded(company)
                        ? <SubscriptionsLink label="Upgrade account now" className={classes.talkToUsLink} segmentLocation="custom deadlines for candidates field" />
                        : <span role="presentation" className={classes.talkToUsLink} onClick={openZendesk}>Talk to us.</span>
                }
            </p>
        </EditPanelSection>
    );
});

export default withStyles(styles)(DeadlineSettings);

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { loadCompletedQuestions } from 'requests/ScriptRequests';

import Layout from 'components/layout';
import { appCtx } from 'components/appStore';
import LoaderBounce from 'components/loaders/LoaderBounce';
import ActiveLabel from 'components/assessments_pages/ActiveLabel';
import AssessmentHeaderWithStepper from 'components/assessments_pages/AssessmentHeaderWithStepper';
import QuestionWrapper from 'components/marketplace/Preview/common/QuestionWrapper';
import QuestionHeader from 'components/marketplace/Preview/SimplePreview/components/QuestionHeader';
import EditIcon from 'img/edit.svg';

import { assessmentSettingsCtx } from '../assessment/store';

const styles = {
    wrapper: {
        backgroundColor: '#fff'
    },
    previewContentWrapper: {
        width: 750,
        margin: '0 auto',
        padding: '70px 0',
        '@media only screen and (max-width: 767px)': {
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '20px'
        }
    }
};

const AssessmentSettingsPreview = observer(({ history, classes, match }) => {
    const [questions, setQuestions] = useState([]);
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const { flashMessage } = useContext(appCtx);
    const {
        audition, loadEditScript,
        setInterviewSlug, isLoading, cleanScript
    } = useContext(assessmentSettingsCtx);
    const { interviewSlug } = match.params;

    useEffect(() => {
        if (!interviewSlug) return;
        loadQuestions();
        setInterviewSlug(interviewSlug);
        loadEditScript(history);
        return () => {
            cleanScript();
        };
    }, [interviewSlug]);

    const loadQuestions = () => {
        loadCompletedQuestions(interviewSlug)
            .then(({ data, success }) => {
                if (success && data) {
                    setQuestions(data);
                }
            })
            .finally(() => {
                setLoadingQuestions(false);
            });
    };

    const onEdit = () => {
        history.push(`/script/edit/${interviewSlug}`);
    };

    const onInvite = () => {
        history.push(`/script/invite/${interviewSlug}/invitation`);
    };

    if (isLoading || loadingQuestions) {
        return (
            <Layout>
                <LoaderBounce className="u-pos--overlay" />
            </Layout>
        );
    }

    if (!audition) return null;

    const { publish, name, active } = audition;

    if (publish) {
        history.push('/marketplace');
        flashMessage('This script can\'t be edited.');
    }

    return (
        <Layout>
            {
                audition && (
                    <AssessmentHeaderWithStepper
                        audition={audition}
                        header={(
                            <>
                                {name}
                                <ActiveLabel
                                    disabled
                                    audition={audition}
                                />
                            </>
                        )}
                        rightPart={(
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={onEdit}
                                    className="u-mrg--rx2 u-mrg--lx3 u-txt--nowrap"
                                >
                                    <img src={EditIcon} className="u-mrg--rx1" alt="" />
                                    Edit Questions
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={onInvite}
                                    className="u-mrg--rx2"
                                    disabled={!active}
                                >
                                    Invite
                                </Button>
                            </>
                        )}
                    />
                )
            }
            <div className={classes.wrapper}>
                <div className={classes.previewContentWrapper}>
                    <QuestionWrapper
                        QuestionHeaderComp={QuestionHeader}
                        interview={audition}
                        questions={questions}
                    />
                    {
                        loadingQuestions && (
                            <div className="u-txt--center">
                                <LoaderBounce />
                            </div>
                        )
                    }
                </div>
            </div>
        </Layout>
    );
});

export default withStyles(styles)(withRouter(AssessmentSettingsPreview));

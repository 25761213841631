import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import {
    addHiringManager,
    assignHiringManagerOrDate
} from 'requests/CandidatesRequests';
import { hiredCandidateCtx } from 'pages/candidates/HiredCandidates/hiredCandidateStore';
import HiredEvents from 'events/HiredEvents';
import HiringManagerContent from './HiringManagerContent';

import { appCtx } from '../../appStore';
import styles from './styles';


const AssignHiringManagerDialog = observer(({
    classes, open, onClose, candidate,
    hiringManagerFullName, setFullName,
    assessmentSlug, hiringManager,
    setHiringManager, dataForEvents,
    onSuccess
}) => {
    const { flashMessage } = useContext(appCtx);
    const { loadHiredCandidates, fetchFiltersForHired, handleChangeFilterSelect } = useContext(hiredCandidateCtx);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({});
    const [managerDeleted, setManagerDeleted] = useState(false);

    const { hiredDate, hiringManager: oldHiringManager, columnUserUuid } = candidate;

    const handleClose = () => {
        onClose();
        fetchFiltersForHired();
        if (managerDeleted) {
            handleChangeFilterSelect(null, 'hiringManager');
            loadHiredCandidates();
        }
        setSelected({});
    };

    const assignHiringManager = async () => {
        setLoading(true);
        const { doesNotExist, email, fullName, uuid } = selected;
        let managersUuidToAssign = uuid;
        if (doesNotExist) {
            const { data } = await addHiringManager(assessmentSlug, email, fullName);
            managersUuidToAssign = data.uuid;
        }
        assignHiringManagerOrDate(assessmentSlug, hiredDate, managersUuidToAssign, [columnUserUuid])
            .then(({ success, data }) => {
                if (success && data) {
                    flashMessage(`Hiring manager ${oldHiringManager ? 'changed' : 'added'}`, 'done');
                    dataForEvents.hiringManagerEmail = email;
                    dataForEvents.hiringManagerId = managersUuidToAssign;
                    if (hiringManager.email) {
                        dataForEvents.oldHiringManagerName = hiringManager.fullName;
                        dataForEvents.newHiringManagerName = fullName;
                        HiredEvents.CANDIDATE_HIRING_MANAGER_CHANGED(dataForEvents);
                    } else {
                        dataForEvents.hiringManagerName = fullName;
                        HiredEvents.CANDIDATE_HIRING_MANAGER_ADDED(dataForEvents);
                    }
                    setHiringManager({ ...selected, uuid: managersUuidToAssign });
                    if (onSuccess) {
                        onSuccess({ hiringManager: { ...selected, uuid: managersUuidToAssign } });
                    }
                }
            })
            .finally(() => {
                handleClose();
                setLoading(false);
            });
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={handleClose}
            handleClose={handleClose}
            titleComponent="Who is the hiring manager?"
            actionComponent={(
                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.secondaryNoBorderButton}
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={clsx(classes.mainButton, !selected.email && classes.greyButton)}
                        color="primary"
                        variant="contained"
                        disabled={!selected.email}
                        onClick={assignHiringManager}
                    >
                        Finish
                    </Button>
                </div>
            )}
        >
            <HiringManagerContent
                hiringManager={hiringManager}
                setHiringManager={setHiringManager}
                hiringManagerFullName={hiringManagerFullName}
                setFullName={setFullName}
                selected={selected}
                assessmentSlug={assessmentSlug}
                setManagerDeleted={setManagerDeleted}
                setSelected={setSelected}
                dataForEvents={dataForEvents}
            />
        </Dialog>

    );
});

export default withStyles(styles)(withRouter(AssignHiringManagerDialog));

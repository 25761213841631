import React from 'react';

import { withStyles } from '@mui/styles';

import styles from './styles';

const Recorder = ({ video, classes, recordingTimeLimit }) => (
    <div>
        <div className={classes.helpText}>Record your answer below</div>
        {
            Boolean(recordingTimeLimit)
            && (
                <div className={classes.helpText}>
                    Note: Your recording length cannot exceed&nbsp;
                    <span className={classes.limitLabel}>
                        {recordingTimeLimit} MINUTES
                    </span>
                </div>
            )
        }
        <div className={classes.recordWrapper}>
            <div className={classes.record}>
                Record {video ? 'Video' : 'Audio'}
                {
                    video
                        ? <i className="ba-commoncss-icon-videocam" ba-if="videocam" />
                        : <i className="ba-commoncss-icon-volume-up" ba-if="volume-up" />
                }
            </div>
            {
                video && (
                    <div className={classes.upload}>
                        Upload Video <i className="ba-commoncss-icon-upload" ba-if="upload" />
                    </div>
                )
            }
        </div>
    </div>
);


export default withStyles(styles)(Recorder);

import React from 'react';
import RetinaImage from 'react-retina-image';
import { getInitials } from 'helper/commonFunctions';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';
import useStyles from './styles';

const CandidatePhotoImg = ({ user, inverted }) => {
    const classes = useStyles({ inverted });
    const { fullName, candidateFullName, initials, candidateInitials, imageUrl, candidateImageUrl, invited } = user;
    if ((imageUrl && imageUrl.length) || (candidateImageUrl && candidateImageUrl.length)) {
        return (
            <div
                role="presentation"
                className={classes.imgWrapper}
            >
                <RetinaImage
                    alt={fullName}
                    src={imageUrl || candidateImageUrl}
                />
            </div>
        );
    }

    return (
        <div
            role="presentation"
            className={clsx(classes.initialsWrapper, invited && classes.initialsWrapperMuted)}
        >
            {initials || candidateInitials || getInitials(fullName || candidateFullName)}
        </div>
    );
};

const CandidatePhoto = ({ user, shownBatch, inverted, checked, onClick, size = 36 }) => {
    const classes = useStyles({ size });
    return (
        <div className={classes.wrapper}>
            {
                checked || shownBatch ? (
                    <Checkbox
                        color="primary"
                        className={classes.checkbox}
                        onClick={onClick}
                        checked={Boolean(checked)}
                    />
                ) : (
                    <CandidatePhotoImg
                        user={user}
                        classes={classes}
                        inverted={inverted}
                    />
                )
            }
        </div>
    );
};

export default CandidatePhoto;

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';
import { appCtx } from 'components/appStore';
import { getTeamMemberAssessments, updateTeamRoles, getTeamClosedAssessmentsCounter } from 'requests/APIrequests';

import SettingsEvents from 'events/SettingsEvents';
import styles from './styles';
import PermissionAssessments from '../PermissionAssessments';

const PermissionsDialog = observer(({ classes, open, onClose, teamUuid }) => {
    const [closedCount, setClosedCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [assessmentList, setAssessmentList] = useState([]);
    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        if (open) {
            setLoading(true);
            getTeamMemberAssessments(teamUuid).then(({ data }) => {
                setAssessmentList(data.map(el => ({
                    ...el,
                    checked: el.role !== null
                })));
            }).finally(() => {
                setLoading(false);
            });

            getTeamClosedAssessmentsCounter(teamUuid).then(({ data, success }) => {
                if (success) setClosedCount(data);
            });
        }
    }, [open]);

    const changeTeamRole = (audition) => {
        updateRoles('Team permission updated');
        if (!audition) return;

        SettingsEvents.ASSESSMENT_TEAM_ROLE_CHANGED({
            ttId: audition.uuid,
            ttName: audition.name,
            userId: teamUuid,
            newAssessmentRole: audition.role
        });
    };

    const onRemoveTeam = (audition) => {
        updateRoles('Team member was removed');
        if (!audition) return;

        SettingsEvents.ASSESSMENT_TEAM_REMOVED({
            ttId: audition.uuid,
            ttName: audition.name,
            userId: teamUuid,
            assessmentRole: audition.role
        });
    };

    const updateRoles = (msg) => {
        const preparedAssessments = assessmentList.filter(el => el.role && el.checked).map(el => ({
            role: el.role,
            slug: el.slug
        }));
        updateTeamRoles(teamUuid, { auditions: preparedAssessments }).then(({ success }) => {
            if (success) {
                flashMessage(msg);
            }
        });
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            color="primary"
            onClose={onClose}
            handleClose={onClose}
            titleComponent="Team Permissions"
        >
            {
                loading ? (
                    <CircularProgress
                        size={24}
                        thickness={3}
                        classes={{ root: classes.loader }}
                    />
                ) : (
                    <ValidatorForm
                        id="permissionAssessments"
                    >
                        <PermissionAssessments
                            topOffset={false}
                            assessmentList={assessmentList}
                            setAssessmentList={setAssessmentList}
                            onChangeRole={changeTeamRole}
                            onRemoveTeam={onRemoveTeam}
                            closedCount={closedCount}
                        />
                    </ValidatorForm>
                )
            }
        </Dialog>
    );
});

export default withStyles(styles)(PermissionsDialog);

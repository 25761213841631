export default () => ({
    saveChangesButton: {
        fontWeight: 700,
        minWidth: 170
    },
    loader: {
        margin: 0
    },
    resultsButton: {
        fontWeight: 700,
        marginTop: 20
    }
});

export default theme => ({
    headerWrapper: {
        padding: '25px 50px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    closeIcon: {
        position: 'absolute',
        left: 8,
        top: 21
    },
    drawerPaper: {
        width: 660,
        zIndex: 100,
        maxWidth: '100%'
    },
    questionWrapper: {
        position: 'relative',
        padding: '0 50px'
    }
});

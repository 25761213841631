import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ({ red }) => theme.palette.red[red ? 50 : 100],
        width: '100%',
        padding: '10px 30px',
        fontSize: 14,
        borderRadius: 4,
        textAlign: 'center',
        '& img': {
            marginRight: 5
        },
        '& a': {
            padding: 0,
            color: theme.palette.yellow[800],
            display: 'inline-block',
            marginLeft: 4,
            fontSize: 14,
            fontWeight: 600,
            verticalAlign: 'baseline',
            lineHeight: 1.43,
            '&:hover, &:focus, &:active': {
                outline: 'none',
                color: theme.palette.yellow[800],
                backgroundColor: 'transparent'
            }
        },
        '& button': {
            fontSize: 14,
            fontWeight: 700,
            color: ({ red }) => (red ? theme.palette.red[700] : theme.palette.yellow[800]),
            borderRadius: 5
        }
    },
    warningText: {
        display: 'inline'
    },
    closeIcon: {
        position: 'absolute',
        right: 10,
        top: 9,
        padding: '5px !important',
        marginBottom: '0 !important',
        '& img': {
            marginRight: 0
        }
    }
}));

import React from 'react';
import { withStyles } from '@mui/styles';
import Icon from 'img/icons/search.svg';
import clsx from 'clsx';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '45px 0'
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '36px',
        margin: '10px 0 0',
        color: theme.palette.grey[900],
        textAlign: 'center'
    },
    text: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '36px',
        color: theme.palette.grey[600]
    }

});

const NoResultsBanner = ({ classes, header, text, className = '' }) => {
    return (
        <div className={clsx(classes.wrapper, className)}>
            <img src={Icon} alt="Search" />
            <h1 className={classes.title}>
                {header}
            </h1>
            <p className={classes.text}>
                {text}
            </p>
        </div>
    );
};

export default withStyles(styles)(NoResultsBanner);

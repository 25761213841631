import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    infoWrapper: {
        marginBottom: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    searchField: {
        height: 40
    }
}));

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    filterHeader: {
        color: theme.palette.grey[800],
        padding: '9px 15px',
        '&:not(:first-child)': {
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            marginTop: 10
        }
    }
}));

import React from 'react';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';

import TableCell from 'components/table_components/table_cell';
import Truncate from 'react-truncate';
import TooltipBlack from 'libraries/Tooltip';
import PopularIcon from './popular-icon.svg';
import NoPopularIcon from './no-popular-icon.svg';
import styles from '../styles';

const SkillRow = ({ classes, popularSkill, handleClickPopular }) => {
    const { popular } = popularSkill;
    return (
        <>
            <TableRow>
                <TableCell className={classes.tableCellName}>
                    <div className={classes.title}>
                        <Truncate lines={1}>
                            {popularSkill.title}
                        </Truncate>
                    </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <TooltipBlack
                        label={popular ? 'Remove from Popular' : 'Add to Popular'}
                        className={classes.icon}
                        onClick={handleClickPopular}
                    >
                        <img src={popular ? PopularIcon : NoPopularIcon} alt="popular icon" />
                    </TooltipBlack>
                </TableCell>
            </TableRow>
        </>
    );
};

export default withStyles(styles)(SkillRow);

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { withStyles, withTheme } from '@mui/styles';
import styles from './styles';

const PageUpIcon = withStyles(styles)(withTheme(({ theme, isActive, ...other }) => {
    const fillColor = isActive ? theme.palette.primary.main : '#A9AAAE';
    return (
        <SvgIcon
            width="104"
            height="34"
            viewBox="0 0 104 34"
            fill="none"
            {...other}
        >
            <path d="M0 9.30411H8.08625C12.9734 9.30411 14.5971 10.4777 14.5971 15.5898C14.5971 20.7503 12.8608 21.6505 8.08625 21.6505H3.50458V27.5665H0V9.30411ZM8.08625 18.6604C10.5941 18.6121 11.0764 18.1459 11.0764 15.5898C11.0764 13.0016 10.7066 12.2782 8.08625 12.2943H3.50458V18.6604H8.08625Z" fill={fillColor} />
            <path d="M24.8375 25.9589H24.7571C24.1462 27.663 22.2814 27.7112 20.7863 27.7112C17.8926 27.7112 16.0117 27.1003 16.0117 23.6439C16.0117 20.5734 17.6193 19.7214 20.7863 19.7214C22.3457 19.7214 23.8729 19.7535 24.6124 21.1843H24.7089V19.5285C24.7089 17.4547 24.532 16.7312 21.9759 16.7312C20.6416 16.7312 19.5645 16.8599 19.5645 18.5157H16.494C16.494 14.786 19.0501 14.6414 21.9759 14.6414C26.0593 14.6414 27.7633 15.2683 27.7633 19.5606V27.5665H24.6928L24.8375 25.9589ZM19.0822 23.6439C19.0822 25.4445 19.8057 25.6213 21.8795 25.6213C23.5032 25.6213 24.7089 25.4766 24.7089 23.6439C24.7089 21.8595 23.3906 21.8113 21.8795 21.8113C20.3201 21.8274 19.0822 21.8274 19.0822 23.6439Z" fill={fillColor} />
            <path d="M38.7272 25.7499H38.6629C37.8591 27.4379 36.2354 27.7273 34.676 27.7273C30.2873 27.7273 29.7728 25.3159 29.7728 21.5701C29.7728 17.6476 29.9175 14.6574 34.676 14.6574C36.4765 14.6574 37.8912 15.0433 38.6629 16.7313H38.7593L38.6307 14.8021H41.7013V27.5665C41.7013 32.1643 40.2383 33.2574 35.5602 33.2574C32.4415 33.2574 30.1104 32.7269 30.3033 28.9008H33.1809C33.1809 30.6531 33.8401 30.9264 35.5602 30.9264C38.1645 30.9264 38.7272 30.3959 38.7272 28.1935V25.7499ZM32.8434 20.7985C32.8434 23.6922 32.8434 25.3802 35.5763 25.3802C38.6468 25.3802 38.6468 23.8529 38.6468 20.7985C38.6468 18.0656 38.2288 16.9724 35.5763 16.9724C33.4542 16.9724 32.8434 17.5833 32.8434 20.7985Z" fill={fillColor} />
            <path d="M55.7196 23.4993C55.7196 27.5183 53.4046 27.7112 49.7554 27.7112C44.8522 27.7112 43.8555 26.1518 43.8555 21.2004C43.8555 16.6187 44.5789 14.6253 49.7554 14.6253C55.0122 14.6253 55.7196 16.1686 55.7196 21.7309H46.926C46.926 24.4799 47.0064 25.3641 49.7554 25.3641C51.4594 25.3641 52.6491 25.3963 52.6491 23.4832L55.7196 23.4993ZM52.6491 19.8018C52.6491 17.1653 52.2632 16.9724 49.7554 16.9724C47.1028 16.9724 46.926 17.5351 46.926 19.8018H52.6491Z" fill={fillColor} />
            <path d="M72.7602 9.30411V22.4543C72.7602 27.3414 69.8344 27.7112 65.526 27.7112C61.5391 27.7112 58.2917 27.6147 58.2917 22.4543V9.30411H60.3656V22.4543C60.3656 25.4284 61.0729 25.8946 65.526 25.8946C69.2074 25.8946 70.6864 25.8624 70.6864 22.4543V9.30411H72.7602Z" fill={fillColor} />
            <path d="M76.3292 16.3454L76.3774 16.3936C77.1651 14.9307 78.8852 14.6414 80.4928 14.6414C85.1388 14.6414 85.4764 17.3743 85.4764 21.2004C85.4764 26.2643 84.2386 27.7273 80.4928 27.7273C79.0299 27.7273 77.1008 27.5504 76.3774 26.0714H76.3292V33.1127H74.4V14.786H76.3292V16.3454ZM76.3292 21.1843C76.3292 24.2066 76.5221 26.1518 79.9623 26.1518C82.9364 26.1518 83.4991 25.3159 83.4991 21.1843C83.4991 17.3582 83.2258 16.1847 79.9623 16.1847C76.3291 16.2007 76.3292 17.8083 76.3292 21.1843Z" fill={fillColor} />
            <path d="M95.2989 18.0495C94.6076 18.0495 93.9485 17.7762 93.4662 17.2939L87.3734 11.2011C86.3606 10.1883 86.3606 8.53245 87.3734 7.51966L93.4662 1.42684C93.9645 0.928481 94.6076 0.671265 95.3149 0.671265C96.0062 0.671265 96.6653 0.944558 97.1476 1.42684L103.24 7.51966C104.253 8.53245 104.253 10.1883 103.24 11.2011L97.1476 17.2939C96.6492 17.7762 96.0062 18.0495 95.2989 18.0495Z" fill={fillColor} />
        </SvgIcon>
    );
}));

export default PageUpIcon;

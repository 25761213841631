import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { withStyles, withTheme } from '@mui/styles';
import styles from './styles';

const VervoeAPIIcon = withStyles(styles)(withTheme(({ theme, isActive, ...other }) => {
    const fillColor = isActive ? theme.palette.primary.main : '#A9AAAE';
    return (
        <SvgIcon
            width="118"
            height="17"
            viewBox="0 0 115 17"
            fill="none"
            {...other}
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M21.8783 2.98392C24.2191 2.98392 25.3309 4.55277 25.3893 6.30625H18.3964C18.5135 4.70654 19.7424 2.98392 21.8783 2.98392ZM22.2 15.9038C25.6234 15.9038 27.8764 13.7814 28.6372 11.1666L25.7698 10.2129C25.3016 11.6896 24.2483 12.8277 22.2 12.8277C20.0934 12.8277 18.3964 11.1973 18.3087 8.92104H28.7249C28.7542 8.85945 28.8126 8.36727 28.8126 7.78278C28.8126 3.04552 26.238 0 21.8489 0C18.2501 0 14.9144 3.13783 14.9144 7.8751C14.9144 12.9508 18.3379 15.9038 22.2 15.9038ZM63.5614 3.23009C65.668 3.23009 67.5406 4.82967 67.5406 7.93663C67.5406 11.0742 65.668 12.7046 63.5614 12.7046C61.484 12.7046 59.5822 11.0435 59.5822 7.93663C59.5822 4.82967 61.484 3.23009 63.5614 3.23009ZM63.5614 15.9039C67.8333 15.9039 70.964 12.5509 70.964 7.93663C70.964 3.35314 67.8333 8.49555e-05 63.5614 8.49555e-05C59.3188 8.49555e-05 56.1881 3.35314 56.1881 7.93663C56.1881 12.5509 59.3188 15.9039 63.5614 15.9039ZM79.8097 2.98392C82.1504 2.98392 83.2623 4.55277 83.3207 6.30625H76.3278C76.4449 4.70654 77.6738 2.98392 79.8097 2.98392ZM80.1314 15.9038C83.5547 15.9038 85.8078 13.7814 86.5685 11.1666L83.7012 10.2129C83.233 11.6896 82.1797 12.8277 80.1314 12.8277C78.0248 12.8277 76.3278 11.1973 76.2401 8.92104H86.6563C86.6856 8.85945 86.744 8.36727 86.744 7.78278C86.744 3.04552 84.1693 0 79.7803 0C76.1815 0 72.8458 3.13783 72.8458 7.8751C72.8458 12.9508 76.2693 15.9038 80.1314 15.9038ZM31.2789 5.546C31.2789 2.21985 32.7855 0.461549 35.6676 0.461549H39.4916V3.9065H36.4748C34.8939 3.9065 34.6261 4.15952 34.6261 6.12511V15.4425H31.2789V5.546ZM8.10215 15.4424H6.63007L0.03479 0.461507H3.76652L7.39479 9.02786L10.9372 0.461507H14.4971L8.10215 15.4424ZM47.9249 15.4424H49.397L55.792 0.461507H52.232L48.6896 9.02786L45.0614 0.461507H41.3296L47.9249 15.4424Z" fill={fillColor} />
            <path d="M99.6137 12.9179H94.7158L93.5692 16H92.1932L96.403 4.88428H97.9592L102.153 16H100.76L99.6137 12.9179ZM99.1386 11.6548L97.6807 7.71374L97.1729 6.16427H97.1402L96.6651 7.66322L95.1909 11.6548H99.1386Z" fill={fillColor} />
            <path d="M108.167 4.88428C109.259 4.88428 110.138 5.19305 110.804 5.81059C111.47 6.41691 111.804 7.23655 111.804 8.26953C111.804 9.30251 111.47 10.1278 110.804 10.7453C110.138 11.3516 109.259 11.6548 108.167 11.6548H105.661V16H104.35V4.88428H108.167ZM107.97 10.3916C108.811 10.3916 109.428 10.2176 109.821 9.86953C110.226 9.51023 110.428 8.9769 110.428 8.26953C110.428 7.56217 110.226 7.03445 109.821 6.68638C109.428 6.32708 108.811 6.14743 107.97 6.14743H105.661V10.3916H107.97Z" fill={fillColor} />
            <path d="M115.659 16H114.348V4.88428H115.659V16Z" fill={fillColor} />
        </SvgIcon>
    );
}));

export default VervoeAPIIcon;

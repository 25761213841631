import React from 'react';
import useStyles from './styles';

const TableContent = ({ question, NoAnswerComponent }) => {
    const classes = useStyles({});

    if (!question.table?.items || !question.table.items.length) return <NoAnswerComponent />;

    return (
        <div className={classes.tableWrapper}>
            <table className={classes.table}>
                {
                    question.question?.table?.items?.map(items => (
                        <tr>
                            {items.map(item => (<th key={item}>{item}</th>))}
                        </tr>
                    ))
                }
                {
                    question.table?.items?.map((items, index) => (
                        <tr>
                            {items.map((item, itemIndex) => (
                                <td key={`${index} ${itemIndex}`}>
                                    {item}
                                </td>
                            ))}
                        </tr>
                    ))
                }
            </table>
        </div>
    );
};

export default TableContent;

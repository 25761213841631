export default theme => ({
    errorWrapper: {
        marginTop: 5,
        fontSize: 12,
        marginBottom: 9,
        color: 'rgb(244, 67, 54)'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    column: {
        width: '47%',
        maxWidth: 595,
        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
            margin: 'auto',
            width: '100%'
        }
    },
    submitButton: {
        padding: '13px 50px',
        marginTop: 50
    },
    subHeader: {
        color: theme.palette.grey[900],
        fontWeight: 600
    },
    companyLink: {
        fontWeight: 600,
        fontSize: 18,
        marginBottom: 50,
        color: theme.palette.grey[900]
    },
    phoneHelperText: {
        margin: '5px 14px',
        fontSize: 10,
        fontWeight: 600,
        color: theme.palette.red[700]
    },
    colorPickerWrapper: {
        opacity: 0.5,
        zIndex: 99999999,
        '& input': {
            display: 'none'
        }
    },
    feedbackAgreementText: {
        paddingTop: 5,
        lineHeight: '20px',
        '& a': {
            fontWeight: 600,
            color: theme.palette.blue[500]
        },
        '& dl': {
            listStyle: 'none',
            paddingLeft: 20
        },
        '& dl li': {
            position: 'relative'
        },
        '& dl li:before': {
            content: '"•"',
            position: 'absolute',
            left: '-1em',
            marginRight: '10px',
            verticalAlign: 'top'
        }
    }
});

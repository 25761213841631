import React, { useContext } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import GeneralEvents from 'events/GeneralEvents';
import { appCtx } from '../../../appStore';
import styles from '../styles';


const CreateNewAssessmentButton = observer(({
    light, buttonLabel = 'Create New Assessment', className = '', classes, disabled
}) => {
    const { openTTBuilderDialog } = useContext(appCtx);
    const { button } = classes;

    const goToTTBuilder = () => {
        localStorage.removeItem('assessmentName');
        openTTBuilderDialog();
        GeneralEvents.TB_INTENT({ context: 'create new assessment' });
    };

    return (
        <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant={!light ? 'contained' : 'text'}
            color="primary"
            onClick={goToTTBuilder}
            className={clsx(button, className)}
            disabled={disabled}
        >
            {buttonLabel}
        </Button>
    );
});

export default withRouter(withStyles(styles)(CreateNewAssessmentButton));

export default theme => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
        }
    },
    cardDetails: {
        [theme.breakpoints.up('sm')]: {
            flexBasis: '55%'
        }
    }
});

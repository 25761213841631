import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import SkillsGroups from 'components/assessments_pages/SkillsGroups';

import ButtonBase from '@mui/material/ButtonBase';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { ACCESS_PREMIUM_CONTENT } from 'helper/constants';
import Tooltip from 'libraries/Tooltip';
import styles from './styles';
import CategoryIcon from './CategoryIcon';
import { appCtx } from '../../appStore';

const SectionHeader = withStyles(styles)(({ classes, title, count }) => (
    <div className={classes.sectionHeaderWrapper}>
        <span className={classes.sectionHeaderTitle}>{title}</span>
        <span className={classes.sectionHeaderCount}>{count}</span>
    </div>
));

const AssessmentPreviewDialog = ({
    assessment, open, onClose,
    handleOpenPreview, copyScriptAction,
    classes, isLoading
}) => {
    if (!assessment) return null;

    const { company } = useContext(appCtx);
    const premiumContentBlocked = !checkHasCompanyFeature(company, ACCESS_PREMIUM_CONTENT) && assessment.premiumContent;
    const {
        summary, name, companyName, category,
        countCompleteQuestions, questionGroups = [],
        scoreType, questionsAnswerTypes = [],
        skillsTestedFlavorText, whatToTestWithFlavorText
    } = assessment;
    const categoryIcon = <CategoryIcon category={category} />;

    const loaderProps = {
        color: 'primary',
        size: 22,
        className: classes.loader
    };

    const returnTypeImg = type => getQuestionTypeInfo(type, renderIconAndLabel);

    const renderIconAndLabel = (Icon, label, labelShort = label) => (
        <>
            {
                Icon && (
                    <span>
                        <Icon color="#292A2D" className={classes.svgIconStyle} />
                    </span>
                )
            }
            {labelShort}
        </>
    );

    return (
        <Dialog
            maxWidth="md"
            open={open}
            scroll="body"
            onClose={onClose}
            handleClose={onClose}
            contentClassName={classes.dialogContent}
        >
            <div className={classes.statsBlock}>
                <div className={classes.assessmentName}>
                    { categoryIcon }
                    <div className={classes.name}>{name}</div>
                </div>
                <div className={classes.info}>
                    <div className={classes.infoInner}>
                        <div className={classes.infoHeader}>Assessment Details</div>
                        <SectionHeader
                            title="Author"
                            count={companyName}
                        />
                        <SectionHeader
                            title="Questions"
                            count={countCompleteQuestions}
                        />
                        <div className={classes.skillsGroupWrapper}>
                            {
                                questionsAnswerTypes && questionsAnswerTypes.map((answerType, index) => (
                                    <div key={index} className={classes.questionLabel}>
                                        {returnTypeImg(answerType)}
                                    </div>
                                ))
                            }
                        </div>
                        <SectionHeader
                            title="Skills"
                            count={questionGroups.length}
                        />
                        {
                            Boolean(questionGroups.length) && (
                                <SkillsGroups
                                    items={questionGroups}
                                    bigCard
                                    skillItemClassName="u-txt--12"
                                    scoreType={scoreType}
                                />
                            )
                        }
                    </div>
                    <div className={classes.infoFooter}>
                        <Button
                            variant="outlined"
                            className={classes.previewButton}
                            onClick={handleOpenPreview}
                        >
                            Preview Assessment
                        </Button>
                        {
                            premiumContentBlocked ? (
                                <Tooltip
                                    label={(
                                        <>
                                            <ButtonBase className={classes.tooltipLink} onClick={() => window.open('/subscriptions', '_blank')}>Upgrade</ButtonBase> to unlock assessment
                                        </>
                                    )}
                                >
                                    <span>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={classes.activateButton}
                                            disabled
                                        >
                                            Add to Assessments
                                        </Button>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.activateButton}
                                    onClick={copyScriptAction}
                                    disabled={isLoading}
                                >
                                    {
                                        isLoading ? (
                                            <CircularProgress {...loaderProps} />
                                        ) : <>Add to Assessments</>
                                    }
                                </Button>
                            )
                        }

                    </div>
                </div>
            </div>
            <div className="u-wdt--100p">
                <Typography variant="h4" className="u-mrg--bx2 u-mrg--tx6">
                    Assessment Summary
                </Typography>
                <div className={classes.description}>{summary}</div>
                {
                    skillsTestedFlavorText && (
                        <>
                            <Typography variant="h5" className="u-mrg--bx2">
                                Skills tested in this assessment
                            </Typography>
                            <div className={classes.description}>{skillsTestedFlavorText}</div>
                        </>
                    )
                }
                {
                    whatToTestWithFlavorText && (
                        <>
                            <Typography variant="h5" className="u-mrg--bx2">
                                What to test with this assessment
                            </Typography>
                            <div className={classes.description}>{whatToTestWithFlavorText}</div>
                        </>
                    )
                }
            </div>
        </Dialog>
    );
};

export default withRouter(withStyles(styles)(AssessmentPreviewDialog));

import React from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';

const ShortlistDialog = observer(({ open, handleDialog, removeFromShortlist }) => (
    <Dialog
        open={open}
        maxWidth="sm"
        onClick={e => e.stopPropagation()}
        onClose={handleDialog}
        titleComponent="Remove candidate from shortlist"
        handleClose={handleDialog}
        actionComponent={(
            <>
                <Button
                    color="primary"
                    className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                    onClick={handleDialog}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={removeFromShortlist}
                    className="u-pdn--lx5 u-pdn--rx5"
                >
                    Remove
                </Button>
            </>
        )}
    >
            Are you sure you want to remove candidate from your shortlist?<br />
            You can restore them anytime.
    </Dialog>
));

export default ShortlistDialog;

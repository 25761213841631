export default theme => ({
    iconWrapper: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    upgradeLabel: {
        textDecoration: 'underline',
        marginTop: 12,
        cursor: 'pointer',
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: 0.2,
        color: theme.palette.primary.main
    }
});

import React from 'react';
import { makeStyles } from '@mui/styles';

import BoardItemPhotos from 'components/board/board_photos';

const useStyles = makeStyles(theme => ({
    tooltipHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '13px 24px',
        textTransform: 'uppercase',
        color: theme.palette.grey[600],
        fontWeight: 700,
        fontSize: 12,
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    peopleList: {
        padding: '10px 24px 6px'
    },
    graderName: {
        display: 'flex',
        alignItems: 'center',
        color: '#3A3C43',
        fontWeight: 600,
        '& > span': {
            marginLeft: 5,
            maxWidth: 145,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    scoreValue: {
        fontWeight: 700
    }
}));

const GradedTeamList = ({ gradedBy }) => {
    const classes = useStyles();
    const { tooltipHeader, peopleList, graderName, scoreValue } = classes;

    return (
        <>
            <div className={tooltipHeader}>
                <span>Graded by</span>
                <span>Score</span>
            </div>
            <div className={peopleList}>
                {
                    gradedBy.map(item => (
                        <div key={item.fullName} className="u-dsp--distribute u-mrg--bx2">
                            <div className={graderName}>
                                <BoardItemPhotos users={[item]} />
                                <span>{item.fullName}</span>
                            </div>
                            <div
                                className={scoreValue}
                            >
                                {item.score}%
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
};

export default GradedTeamList;

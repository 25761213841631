export default theme => ({
    sectionRow: {
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        padding: 0,
        fontWeight: 'bold',
        fontSize: 16,
        flexWrap: 'wrap',
        minHeight: 48
    },
    body: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        backgroundColor: theme.palette.grey[200],
        borderRadius: 4,
        padding: '8px 6px',
        width: '100%'
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        marginLeft: 5,
        color: theme.palette.yellow[900]
    },
    actionButton: {
        fontSize: 12,
        fontWeight: 600,
        marginLeft: 5,
        textDecoration: 'underline',
        border: 'none',
        background: 'transparent',
        color: theme.palette.grey[900]
    }
});

import React from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';

import Icon from './question-warning.svg';

import styles from './styles';

const SkillWarning = observer(({ classes, companySkills }) => {
    if (!companySkills || !companySkills.length) return null;
    return (
        <div className={classes.wrapper}>
            <img src={Icon} alt="!" />
            Editing this question will update it in skills <span className={classes.skill}>{companySkills[0].title} </span>
            {Boolean(companySkills.length > 1) && <>and {companySkills.slice(1).map(({ title }, index) => <>{index !== 0 ? ', ' : ''}<span className={classes.skill}>{title}</span></>)}</>}
        </div>
    );
});

export default withRouter(withStyles(styles)(SkillWarning));

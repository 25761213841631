import React from 'react';
import Dropzone from 'react-dropzone';
import { updateEmployerLogo } from 'requests/APIrequests';
import clsx from 'clsx';
import Photo from './Photo';

class UploadUserPhotoAlt extends React.Component {
    onOpenClickDropzone = (e) => {
        e.preventDefault();
        this.refs.dropzone.open();
    };

    onDrop = (files) => {
        const { updateLoggedUser, flashMessage, loggedUser } = this.props;
        const formData = new FormData();
        files.forEach((value, key) => {
            formData.append(key, value);
        });
        updateEmployerLogo(formData)
            .then(({ success, data }) => {
                if (success && data) {
                    updateLoggedUser({ ...loggedUser, image_url: data.imageUrl });
                    flashMessage('Photo saved');
                }
            })
            .catch(() => {
                flashMessage('Invalid file type or size');
            });
    };

    render() {
        const { loggedUser, size = 80, hasUpload } = this.props;
        return (
            <div className="c-upload-photo  u-txt--0">
                <Photo
                    user={loggedUser}
                    size={size}
                    initialsSize={16}
                    className="c-img--circle"
                />
                {
                    hasUpload && (
                        <>
                            <button
                                onClick={this.onOpenClickDropzone}
                                style={{ width: size, height: size }}
                                className="u-txt--14"
                            >
                                {
                                    loggedUser.image_url
                                        ? 'Update photo'
                                        : 'Add photo'
                                }
                            </button>
                            <Dropzone
                                className="hide"
                                ref="dropzone"
                                multiple={false}
                                accept="image/*"
                                onDrop={this.onDrop}
                            >
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div
                                        {...getRootProps()}
                                        className={clsx('dropzone', isDragActive && 'dropzone--isActive')}
                                    >
                                        <input {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        </>
                    )
                }
            </div>
        );
    }
}

export default UploadUserPhotoAlt;

export default theme => ({
    root: {},
    paper: {
        '&$paperScrollBody': {
            [theme.breakpoints.down('sm')]: {
                maxWidth: 'none'
            }
        },
        '@media (max-width: 768px)': {
            margin: 0,
            maxHeight: '100%',
            height: '100%'
        }
    },
    actions: {
        display: 'flex',
        background: 'rgba(243, 243, 243, 0.75)',
        margin: 0,
        padding: '20.5px 24px',
        '& button:not(:first-child)': {
            marginLeft: 20
        },
        '@media (max-width: 768px)': {
            flexDirection: 'column-reverse',
            '& button': {
                width: '100%'
            },
            '& button:not(:first-child)': {
                marginLeft: 0,
                marginBottom: 15
            }
        }
    },
    content: {
        padding: '20px 24px',
        fontSize: 16,
        marginTop: 15
    },
    title: {
        position: 'relative',
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: '24px',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: theme.palette.grey[900],
        paddingTop: 22,
        paddingBottom: 22,
        paddingRight: 55,
        justifyContent: 'space-between',
        display: 'flex',
        cursor: 'pointer',
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: 'calc(100% - 50px)',
            height: 1,
            backgroundColor: theme.palette.grey[300]
        }
    },
    paperWidthMd: {
        '&$paperScrollBody': {
            '@media (max-width: 1055.95px)': {
                margin: 'auto'
            }
        }
    },
    paperFullWidth: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    paperScrollBody: {},
    closeIconButton: {
        position: 'absolute',
        cursor: 'pointer',
        right: '25px',
        top: 18,
        zIndex: 101,
        padding: 5,
        borderRadius: 4
    },
    closeIcon: {
        height: 24
    }
});

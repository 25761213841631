import React from 'react';
import clsx from 'clsx';
import { withStyles, withTheme } from '@mui/styles';
import { getRatingScoreColor } from 'helper/commonFunctions';

import styles from './styles';


const RatingItem = ({
    label, value, totalCount,
    valueLabel, classes, onClick
}) => (
    <div className={clsx(classes.wrapper, totalCount && 'u-cursor--p')} onClick={totalCount ? onClick : undefined}>
        <div className={classes.value}>
            <span style={{ color: getRatingScoreColor(value, totalCount) }}>{ (!totalCount || (!value && value !== 0)) ? '-' : value }</span>
            { valueLabel && <span className={classes.valueLabel}>{valueLabel}</span> }
            <span
                role="presentation"
                className={classes.countLabel}
            >
                ({totalCount})
            </span>
        </div>
        <div className={classes.label}>{label}</div>
    </div>
);

export default withTheme(withStyles(styles)(RatingItem));

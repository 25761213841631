import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Dropzone from 'react-dropzone';
import CompanyLogo from 'components/logos/company_logo';
import { updateCompanyLogo } from 'requests/APIrequests';
import TeamEvents from 'events/SettingsEvents';
import UpgradeToUnlockTooltip from 'components/tooltips/UpgradeToUnlockTooltip';

const styles = {
    dropzone: {
        outline: 'none',
        cursor: 'pointer'
    },
    wrapper: {
        marginBottom: 13
    }
};

class UploadCompanyPhoto extends React.Component {
    onOpenClickDropzone = (e) => {
        e.preventDefault();
        this.refs.dropzone.open();
    };

    onDrop = (files) => {
        const {
            updateCompany, isForm,
            flashMessage, setCompanyColor,
            openDialog
        } = this.props;
        const formData = new FormData();
        if (!files[0]) return;
        formData.append('logo', files[0]);
        updateCompanyLogo(formData)
            .then(({ success, data }) => {
                if (success) {
                    TeamEvents.LOGO_ADDED({ clearbit: false });
                    updateCompany(data);
                    const message = isForm ? 'Photo saved.' : 'Photo saved. We have automatically set your company color based on your logo.';
                    flashMessage(message);
                    if (isForm) {
                        setCompanyColor(data.companyColor);
                        openDialog(true);
                    }
                } else {
                    flashMessage('Succeed failed: Invalid file type or size');
                }
            })
            .catch(() => {
                flashMessage('Error: Invalid file type or size');
            });
    };

    returnDefaultDropZone = () => {
        const { company: { companyImageUrl }, classes, hasCompanyBranding } = this.props;

        if (!(companyImageUrl && companyImageUrl.length) && !hasCompanyBranding) {
            return <>
                <UpgradeToUnlockTooltip>
                    <div
                        className={clsx(classes.dropzone)}
                    >
                        <i className="c-img-dropzone__icon  icon-upload-cloud u-mrg--lx8" />
                        <span className="c-img-dropzone__title">DRAG &amp; DROP</span><br />
                        <span className="c-img-dropzone__txt">your logo to upload, or <span className="u-txt--underline  c-link">browse</span></span>
                    </div>
                </UpgradeToUnlockTooltip>
            </>;
        }

        return (
            <Dropzone
                className="c-img-dropzone"
                activeClassName="c-img-dropzone--active"
                ref="dropzone"
                multiple={false}
                onDrop={this.onDrop}
            >
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                        className={clsx(classes.dropzone, isDragActive && classes.dropzoneActive)}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <i className="c-img-dropzone__icon  icon-upload-cloud u-mrg--lx8" />
                        <span className="c-img-dropzone__title">DRAG &amp; DROP</span><br />
                        <span className="c-img-dropzone__txt">your logo to upload, or <span className="u-txt--underline  c-link">browse</span></span>
                    </div>
                )}
            </Dropzone>
        );
    };

    render() {
        const { company, company: { companyImageUrl }, classes, hasCompanyBranding } = this.props;

        return (
            <div className={classes.wrapper}>
                {
                    companyImageUrl && companyImageUrl.length ? (
                        <div className="c-upload-photo  c-upload-photo_rectangle">
                            <CompanyLogo
                                company={company}
                                size={200}
                            />
                            {hasCompanyBranding ? (
                                <button
                                    onClick={this.onOpenClickDropzone}
                                    className="c-rectangle u-wdt--100p u-hgt--100p"
                                >
                                    Update logo
                                </button>
                            ) : (
                                <UpgradeToUnlockTooltip>
                                    <button className="c-rectangle u-wdt--100p u-hgt--100p" />
                                </UpgradeToUnlockTooltip>
                            )}
                            <Dropzone
                                className="hide"
                                ref="dropzone"
                                accept="image/*"
                                multiple={false}
                                onDrop={this.onDrop}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    ) : this.returnDefaultDropZone()
                }
            </div>
        );
    }
}

export default withStyles(styles)(UploadCompanyPhoto);

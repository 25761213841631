export default (theme => ({
    subTitle: {
        fontSize: 14,
        marginBottom: 20,
        color: theme.palette.grey[900]
    },
    tag: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        marginLeft: 15,
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.grey[900],
        '& img': {
            width: 16,
            height: 16,
            marginRight: 10
        }
    },
    tagsLimitContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingBottom: '10px 20px 30px',
        '& img': {
            width: 48,
            height: 48,
            marginTop: 20
        },
        '& button': {
            marginTop: `${35}px !important`,
            marginBottom: 40,
            maxWidth: 250
        }
    },
    limitTitle: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.grey[900],
        marginTop: 10
    },
    limitMessage: {
        fontSize: 16,
        color: theme.palette.grey[900],
        marginTop: 10
    }
}));

import { useEffect, useRef, useState } from 'react';

const useTimeout = (callback, time) => {
    const [expired, setExpired] = useState(false);
    const timedIdRef = useRef(null);

    useEffect(() => {
        setExpired(false);
        if (timedIdRef.current) {
            clearTimeout(timedIdRef.current);
        }
        timedIdRef.current = setTimeout(() => {
            setExpired(true);
            if (callback) callback();
        }, time);
        return clear;
    }, []);

    const clear = () => {
        if (timedIdRef.current) {
            clearTimeout(timedIdRef.current);
        }
    };

    const restartWithTime = (newTime) => {
        clear();
        setExpired(false);
        timedIdRef.current = setTimeout(() => {
            setExpired(true);
            if (callback) callback();
        }, newTime);
    };

    return { restartWithTime, expired };
};

export default useTimeout;

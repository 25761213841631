export default theme => ({
    wrapper: {
        height: 150,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    label: {
        position: 'absolute',
        left: 33,
        bottom: 33,
        fontSize: 14,
        color: theme.palette.grey[900],
        lineHeight: '28px',
        textAlign: 'left',
        maxWidth: 250,
        marginLeft: 12,
        [theme.breakpoints.down('md')]: {
            position: 'static',
            maxWidth: 'none'
        }
    }
});

import React from 'react';
import { withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Checkbox from 'libraries/Checkbox';
import TypesFactory from 'components/marketplace/EditQuestion/components/AnswerAttachment/components/TypesFactory';
import CheckIcon from 'components/icons/CheckIcon';

import clsx from 'clsx';
import styles from './styles';

export default withStyles(styles)(({
    question: { csSimulatorTicketsResults }, NoAnswerComponent, classes
}) => {
    const noAnswer = csSimulatorTicketsResults.every(({ hasCheckedAnswer }) => !hasCheckedAnswer);
    const noNotes = csSimulatorTicketsResults.every(({ note }) => !note);

    if (noAnswer) return <NoAnswerComponent />;

    return (
        <div>
            <div className={clsx(classes.headerWrapper, 'u-dsp--distribute')}>
                <div>Ticket</div>
                <div className={clsx(classes.headerColumnsWrapper, 'u-dsp--distribute')}>
                    <div>{!noNotes ? 'Skills' : ''}</div>
                    <div className={classes.pointsColumnHeader}>Points</div>
                </div>
            </div>
            {
                csSimulatorTicketsResults.map((ticket, ticketIndex) => {
                    const { id, csSimulatorAnswersResults, hasCheckedAnswer, note } = ticket;
                    let points = 0;
                    if (hasCheckedAnswer) {
                        points = csSimulatorAnswersResults.find(({ checked }) => checked).point;
                    }
                    return (
                        <>
                            <Accordion
                                key={id}
                                classes={{
                                    root: classes.accordion,
                                    expanded: classes.accordionExpanded
                                }}
                            >
                                <AccordionSummary
                                    classes={{ root: classes.summaryRoot, content: classes.summaryContent, disabled: classes.summaryDisabled }}
                                    expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={classes.ticketNumberColumn}>{ticketIndex + 1}</div>
                                    <div className={clsx(classes.headerColumnsWrapper, 'u-dsp--distribute')}>
                                        {
                                            !noNotes ? (
                                                <div className={classes.skillsColumn}>
                                                    {note}
                                                </div>
                                            ) : <div />
                                        }
                                        <div className={classes.pointsColumn}>
                                            {points} {points === 10 && <CheckIcon className="u-mrg--lx1" /> }
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        csSimulatorAnswersResults.map(({
                                            text: itemText,
                                            checked: itemChecked,
                                            point, ...itemOther
                                        }) => (
                                            <div className="u-mrg--bx2">
                                                <Checkbox
                                                    label={itemText}
                                                    checked={itemChecked}
                                                    disabled
                                                    styles={{ label: classes.checkboxLabel }}
                                                />
                                                <TypesFactory
                                                    {...itemOther}
                                                    isSimulator
                                                    codeEditorValue={itemOther.codeEditor}
                                                />
                                            </div>
                                        ))
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </>
                    );
                })
            }
        </div>
    );
});

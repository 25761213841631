export default theme => ({
    wrapper: {},
    header: {
        fontSize: 20,
        fontWeight: 700,
        padding: '23px 40px',
        color: theme.palette.grey[900],
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    content: {
        padding: '20px 40px'
    },
    error: {
        color: theme.palette.red.primary,
        fontWeight: 600,
        fontSize: 12,
        margin: 0,
        minHeight: 20
    },
    proceedButton: {
        position: 'relative',
        marginTop: 15,
        marginBottom: 15,
        paddingTop: 10,
        paddingBottom: 10
    },
    formRow: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 22
        }
    },
    formPart: {
        width: '100%',
        textAlign: 'right',
        [theme.breakpoints.up('sm')]: {
            width: '30%'
        }
    },
    taxesLabel: {
        fontSize: 12,
        marginBottom: 20,
        lineHeight: '24px',
        marginTop: 30,
        color: theme.palette.grey[900],
        '& a': {
            color: theme.palette.primary.main
        }
    },
    formHeading: {
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 0,
        color: theme.palette.grey[700]
    },
    loader: {
        color: '#fff'
    }
});

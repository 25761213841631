import React from 'react';
import { withStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';

const styles = () => ({
    dropzone: {
        outline: 'none',
        cursor: 'pointer'
    },
    label: {
        fontSize: 16,
        fontWeight: 600
    }
});

const DropZone = ({ label, classes, onDrop, className, disabled, maxSize, accept, minSize, ...other }) => {
    const {
        getRootProps, getInputProps,
        isDragActive
    } = useDropzone({ onDrop, maxSize, minSize, accept, disabled: Boolean(disabled) });

    return (
        <div {...getRootProps()} className={clsx(classes.dropzone, className)} {...other}>
            <input {...getInputProps()} />
            {
                isDragActive
                    ? <p className={classes.label}>Drop files here...</p>
                    : <p className={classes.label}>{ label || 'Try dropping some files here, or click to select files to upload.'}</p>
            }
        </div>
    );
};

export default withStyles(styles)(DropZone);

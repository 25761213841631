export default theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 120,
        overflow: 'auto',
        padding: '0 48px',
        overflowX: 'hidden'
    },
    item: {
        borderRadius: 6,
        cursor: 'pointer',
        marginBottom: 32,
        minHeight: 200,
        width: 372,
        display: 'flex',
        alignItems: 'flex-start',
        color: theme.palette.grey[900],
        flexDirection: 'column',
        backgroundColor: theme.palette.grey[200],
        overflow: 'hidden',
        paddingBottom: 20,
        '&:hover': {
            backgroundColor: theme.palette.grey[300]
        }
    },
    itemSelected: {
        backgroundColor: theme.palette.blue[50],
        '&:hover': {
            backgroundColor: theme.palette.blue[50]
        }
    },
    currentPlan: {
        backgroundColor: theme.palette.blue[500],
        width: '100%',
        color: 'white',
        padding: '8px 17px'
    },
    changePlanText: {
        color: theme.palette.grey[600],
        fontSize: 20
    },
    outdated: {
        marginTop: 20
    }
});

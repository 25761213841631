import React, { useEffect, useState } from 'react';

// material components
import { withStyles } from '@mui/styles';
import { TEXT, ANY_FILE, VIDEO, IMAGE, AUDIO } from 'helper/constants';
import TextInputOutlined from 'libraries/TextInput';
import clsx from 'clsx';
import {
    uploadFileForSimulatorTicketResponse,
    deleteFileFromSimulatorTicketResponse,
    deleteZiggeoFromSimulatorTicketResponse
} from 'requests/QuestionsRequests';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { isEmptyObject } from 'helper/validationFunctions';

import AnswerAttachment from '../../../../../../../AnswerAttachment';
import MediaChoiceTypes from '../../../../../MediaChoiceTypes';

import styles from './styles';

const AnswersItem = ({
    classes, uploadingFiles, answerItem,
    flashMessage, setUploadingFiles, index,
    isCMS, handleChangeResponse, removeResponse
}) => {
    const [response, setResponse] = useState(answerItem);

    useEffect(() => {
        setResponse({ ...response, valid: answerItem.valid });
    }, [answerItem.valid]);

    const {
        text: itemText, type, id, point: itemPoint,
        title, valid, ...otherProps
    } = response;

    const handleChangeText = (event) => {
        const { value } = event.target;
        setResponse({ ...response, text: value });
        handleChangeResponse(index, 'text', value);
    };

    const handleChangeToken = (token) => {
        handleChangeResponse(index, 'ziggeoToken', token);
        setResponse({ ...response, ziggeo: { token } });
    };

    const onDropFile = (files) => {
        const formData = new FormData();
        if (!files.length) {
            flashMessage('The file should not be empty and its size should be 10MB or less', 'error');
            return;
        }
        files.forEach((f, ind) => formData.append(ind, f));
        setUploadingFiles(oldUploadingFiles => ({ ...oldUploadingFiles, [id]: true }));

        uploadFileForSimulatorTicketResponse(id, formData, isCMS)
            .then(({ success, data }) => {
                if (success) {
                    const { fileName, fileUrl } = data;
                    setResponse({ ...response, file: { fileName, fileUrl } });
                    handleChangeResponse(index, 'file', { fileName, fileUrl });
                }
            })
            .catch((jqXHR, textStatus) => {
                flashMessage(textStatus);
            })
            .finally(() => {
                setUploadingFiles((oldUploadingFiles) => {
                    delete oldUploadingFiles[id];
                    return ({ ...oldUploadingFiles });
                });
            });
    };

    const handleChangeCodeEditor = (event, value) => {
        setResponse({ ...response, codeEditor: value });
        handleChangeResponse(index, 'codeEditor', value);
    };


    const handleChangePoints = (e) => {
        const { value } = e.target;
        let newValue = parseInt(value, 10);
        if (Number.isNaN(newValue) || newValue <= 0) {
            newValue = 0;
        } else if (newValue > 10) {
            newValue = 10;
        }
        setResponse({ ...response, point: newValue });
        handleChangeResponse(index, 'point', newValue);
    };

    const onChangeType = async (newType) => {
        const newProps = { file: response.file, ziggeo: response.ziggeo };
        if (type === ANY_FILE || type === IMAGE) {
            await deleteFileFromSimulatorTicketResponse(id, isCMS);
            newProps.file = null;
        } else if (type === AUDIO || type === VIDEO) {
            await deleteZiggeoFromSimulatorTicketResponse(id, isCMS);
            newProps.ziggeo = null;
        }
        handleChangeResponse(index, 'type', newType);
        setResponse({ ...response, ...newProps, type: newType });
    };

    return (
        <div>
            <div className="-pos--relative">
                <div className="c-multicol__col">
                    <div className="u-dsp--f u-ai--start u-wdt--100p u-mrg--bx1 u-pos--relative">
                        {
                            type === TEXT ? (
                                <TextInputOutlined
                                    className={classes.input}
                                    value={itemText}
                                    name="text"
                                    isError={itemPoint === null || !valid}
                                    variant="outlined"
                                    label="Response"
                                    onChange={handleChangeText}
                                    fullWidth
                                    maxLength={25000}
                                />
                            ) : (
                                <div className={classes.attachmentWrapper}>
                                    <AnswerAttachment
                                        {...response}
                                        value={itemText}
                                        handleChangeToken={handleChangeToken}
                                        codeEditorValue={otherProps.codeEditor}
                                        onChange={handleChangeText}
                                        onDrop={files => onDropFile(files, index)}
                                        isError={itemPoint === null || !valid}
                                        isUploadingFile={id in uploadingFiles}
                                        className="u-mrg--bx3 u-mrg--tx0"
                                        placeholder="Response"
                                        handleChangeCodeEditor={(e, value) => handleChangeCodeEditor(e, value, index)}
                                        handleChangeType={(e, newType) => onChangeType(newType, index)}
                                    />
                                </div>
                            )
                        }
                        <TextInputOutlined
                            className={clsx(classes.input, classes.pointsInput)}
                            value={Number.isInteger(itemPoint) && !Number.isNaN(itemPoint) ? parseInt(itemPoint, 10) : ''}
                            label="Points (max 10)"
                            variant="outlined"
                            isError={itemPoint === ''}
                            onChange={handleChangePoints}
                            type="number"
                            min="0"
                            max="10"
                            name="point"
                        />
                        <IconButton
                            type="button"
                            onClick={() => removeResponse(index)}
                            className={classes.closeIcon}
                            disabled={!isEmptyObject(uploadingFiles)}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <MediaChoiceTypes
                onChangeType={onChangeType}
                type={type}
                id={id}
            />
        </div>
    );
};

export default withStyles(styles)(AnswersItem);

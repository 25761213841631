import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { loadContentLibraryAssessment, reorderCompanySet } from 'requests/CMSRequests';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';

import QuestionDrawer from '../../common/QuestionDrawer';

import SetDrawer from './SetDrawer';
import StatisticsBlock from './StatisticsBlock';
import QuestionsList from './QuestionsListWrapper';
import SetsList from './SetsList';
import styles from './styles';


const EditQuestionsContentAssessment = observer(({
    classes, audition, setAudition,
    history, match, setSaving
}) => {
    const { auditionUuid } = match.params;
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openSetDrawer, setOpenSetDrawer] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [selectedSet, setSelectedSet] = useState(null);
    const [addToSelectedSkill, setAddToSelectedSkill] = useState(false);
    const [setsList, setSetsList] = useState([]);
    const [addToSetQuestionUuid, setAddToSetQuestionUuid] = useState(null);
    const [removeFromQuestionUuid, setRemoveFromSetQuestionUuid] = useState(null);

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        if (selectedSet) setSelectedSet({ ...selectedSet });
        getAssessment();
    }, [addToSelectedSkill, removeFromQuestionUuid, addToSetQuestionUuid]);

    const getAssessment = () => {
        if (!auditionUuid) return;
        loadContentLibraryAssessment(auditionUuid)
            .then(({ data, success }) => {
                if (data && success && data.companySets) {
                    setSetsList(data.companySets);
                    setAudition(data);
                }
            })
            .catch(() => {
                goToLibrary();
            });
    };

    const goToLibrary = () => {
        history.push('/content-library');
    };


    const toggleDrawer = () => {
        setAddToSelectedSkill(false);
        setOpenDrawer(!openDrawer);
    };

    const toggleSetDrawer = () => {
        setOpenSetDrawer(!openSetDrawer);
    };

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleSelectedSkillId = (id) => {
        handleOpenDrawer();
        setSelectedQuestionId(id);
    };

    const addSet = (set) => {
        setSetsList([...setsList, set]);
        setSelectedSet(set);
    };

    const updateSets = (newSetsList) => {
        setSetsList(newSetsList);
    };

    const saveSetsSort = (set, order) => {
        setSaving(true);
        reorderCompanySet(auditionUuid, set.uuid, { order })
            .then(() => {
                setAudition({ ...audition, synchronized: false });
                flashMessage('Skill reordered', 'done');
            })
            .finally(() => {
                setSaving(false);
            });
    };

    return (
        <>
            <div className="u-dsp--f u-ovf--a u-wdt--100p">
                <div className={classes.leftPanel}>
                    <StatisticsBlock
                        auditionUuid={auditionUuid}
                        selectedSet={selectedSet}
                    />
                    <SetsList
                        addSet={addSet}
                        setsList={setsList}
                        selectedSet={selectedSet}
                        auditionUuid={auditionUuid}
                        setSelectedSet={setSelectedSet}
                        updateSets={updateSets}
                        saveSetsSort={saveSetsSort}
                    />
                </div>
                <div className={classes.contentPanel}>
                    {
                        audition && (
                            <QuestionsList
                                numberOfSets={setsList.length}
                                openDrawer={openDrawer}
                                selectedSet={selectedSet}
                                getAssessment={getAssessment}
                                setSelectedQuestionId={handleSelectedSkillId}
                                toggleDrawer={toggleDrawer}
                                selectedQuestionId={selectedQuestionId}
                                toggleSetDrawer={toggleSetDrawer}
                                handleOpenDrawer={handleOpenDrawer}
                                setSelectedSet={setSelectedSet}
                                setAddToSelectedSkill={setAddToSelectedSkill}
                                setAddToSetQuestionUuid={setAddToSetQuestionUuid}
                                setRemoveFromSetQuestionUuid={setRemoveFromSetQuestionUuid}
                                setSaving={setSaving}
                                audition={audition}
                                setAudition={setAudition}
                            />
                        )
                    }
                </div>
            </div>
            <QuestionDrawer
                open={openDrawer}
                selectedSet={selectedSet}
                selectedSkill={selectedSet ? selectedSet.companySkill : null}
                addToSelectedSkill={addToSelectedSkill}
                selectedQuestionId={selectedQuestionId}
                setSelectedQuestionId={setSelectedQuestionId}
                toggleDrawer={toggleDrawer}
                setSaving={setSaving}
                getAssessment={getAssessment}
            />
            <SetDrawer
                open={openSetDrawer}
                selectedSet={selectedSet}
                selectedSkill={selectedSet ? selectedSet.companySkill : null}
                toggleDrawer={toggleSetDrawer}
                setSelectedSet={setSelectedSet}
                getAssessment={getAssessment}
                addToSetQuestionUuid={addToSetQuestionUuid}
                removeFromQuestionUuid={removeFromQuestionUuid}
                setAddToSetQuestionUuid={setAddToSetQuestionUuid}
                setRemoveFromSetQuestionUuid={setRemoveFromSetQuestionUuid}
            />
        </>
    );
});

export default withRouter(withStyles(styles)(EditQuestionsContentAssessment));

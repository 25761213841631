import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';

import stylesJS from './styles';

const InfoRow = ({ classes, items = [], className = '' }) => (
    <div className={clsx(classes.sectionRow, className)}>
        {
            items.map(({ label, value, rate, className: itemClassName, lastTime, width, completed, isSmallValueSize }, key) => (
                <div
                    className={clsx(
                        classes.sectionItem,
                        itemClassName,
                        classes[`${width}Width`]
                    )}
                    style={{
                        width
                    }}
                    key={key}
                >
                    <div className={classes.sectionItemHeader}>
                        {label}
                    </div>
                    <div className={clsx(classes.sectionItemNumber, isSmallValueSize ? 'smaller' : '')}>
                        {value}
                        {Boolean(rate) && <span className={classes.rate}>{rate}%</span>}
                        {Boolean(lastTime) && completed && <span className={clsx(classes.lastTime, isSmallValueSize ? 'smaller' : '')}> ({ completed })</span>}
                    </div>
                </div>
            ))
        }
    </div>
);

export default withStyles(stylesJS)(withRouter(InfoRow));

export default theme => ({
    section: {
        position: 'relative',
        padding: '16px 16px 13px',
        borderBottom: `3px solid ${theme.palette.grey[300]}`,
        userSelect: 'none',
        transition: 'all 0.3s',
        backgroundColor: theme.palette.grey[300],
        zIndex: 1,
        '&:first-child': {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            borderRight: '2px solid #fff'
        },
        '&:last-child': {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            borderLeft: '2px solid #fff'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginBottom: 27,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8
        }
    },
    sectionActive: {
        borderColor: theme.palette.primary.main
    },
    sectionDisabled: {
        '&:hover': {
            backgroundColor: theme.palette.grey[300]
        }
    },
    sectionHeader: {
        position: 'absolute',
        left: 17,
        bottom: -27,
        color: theme.palette.grey[700],
        letterSpacing: 0.2,
        fontWeight: 700,
        fontSize: 12,
        lineHeight: '20px',
        whiteSpace: 'nowrap'
    },
    sectionActiveHeader: {
        color: theme.palette.primary.main
    },
    sectionHeaderDisabled: {
        color: theme.palette.grey[600]
    },
    sectionSubHeader: {
        color: theme.palette.grey[600],
        fontWeight: 600,
        fontSize: 14,
        whiteSpace: 'nowrap',
        marginBottom: -2
    },
    gradeWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    grade: {
        borderRadius: '50%',
        width: 32,
        height: 32,
        marginRight: 8,
        fontSize: 14,
        fontWeight: 700,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        color: theme.palette.grey[800],
        border: '2px solid #fff',
        transition: 'all 0.3s',
        '&:last-child': {
            marginRight: 0
        },
        '&_not($gradeSelected):hover': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        }
    },
    gradeActive: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderColor: theme.palette.primary.main
    },
    active: {
        cursor: 'pointer'
    },
    gradeSelected: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderColor: theme.palette.primary.main
    },
    scaleWrapper: {
        maxWidth: 'fit-content',
        margin: '20px 0 30px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            maxWidth: '100%',
            margin: '20px 0'
        }
    },
    wrapper: {
        padding: '21px 24px',
        textAlign: 'center',
        borderRadius: 8,
        backgroundColor: theme.palette.grey[100]
    },
    header: {
        marginBottom: 5,
        fontWeight: 800,
        fontSize: 15,
        color: theme.palette.primary.main
    },
    headerDisabled: {
        color: theme.palette.grey[700]
    },
    headerLabel: {
        marginBottom: 0,
        fontWeight: 700,
        fontSize: 12,
        color: theme.palette.grey[700]
    },
    backArrow: {
        transform: 'rotate(180deg)'
    },
    actionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center'
    },
    arrowButtonWrapper: {
        margin: '-10px 15px 0',
        position: 'relative'
    },
    skipButton: {
        position: 'absolute',
        bottom: -40,
        left: 0,
        fontSize: 11,
        fontWeight: 700,
        minWidth: 'auto',
        color: theme.palette.grey[600],
        textDecoration: 'underline',
        '&:disabled': {
            color: theme.palette.grey[400]
        }
    },
    arrowButton: {
        width: 40,
        height: 40,
        textAlign: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[400],
        '&:hover': {
            backgroundColor: theme.palette.grey[500]
        },
        '&:disabled': {
            backgroundColor: theme.palette.grey[300]
        }
    }
});

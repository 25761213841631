import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    hireBanner: {
        zIndex: 10,
        padding: '25px 26px',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#fff'
    },
    hireBannerButton: {
        fontSize: 12,
        width: '48%',
        fontWeight: 700,
        backgroundColor: theme.palette.grey[200]
    },
    hireBannerHeader: {
        marginBottom: 25,
        fontWeight: 700,
        fontSize: 15
    }
}));

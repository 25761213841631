export default {
    radioLabel: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        color: '#3a3c43',
        letterSpacing: '0.2px'
    },
    radioLabelDisabled: {
        opacity: 0.5
    },
    fieldRoot: {
        width: 60,
        height: 30,
        marginLeft: 6,
        marginRight: 6,
        minHeight: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        width: 60,
        height: 30,
        padding: 5,
        boxSizing: 'border-box',
        fontSize: 14,
        color: 'rgba(61, 63, 71, 0.9)',
        letterSpacing: '0.2px',
        textAlign: 'right',
        paddingRight: 10,
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none'
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none'
        }
    },
    title: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 20
    },
    errorMessage: {
        marginLeft: 34
    }

};

export default theme => ({
    categoryName: {
        marginTop: 48,
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.grey[900],
        marginBottom: 23
    },
    feature: {
        marginBottom: 20,
        '& img': {
            marginRight: 18
        }
    },
    featuresWrapper: {
        padding: '0 80px',
        display: 'flex',
        justifyContent: 'flex-start',
        '& > div': {
            width: '50%'
        },
        '@media only screen and (max-width: 1200px)': {
            flexWrap: 'wrap',
            '& > div': {
                width: 'auto'
            }
        }
    }
});

import React, { PureComponent } from 'react';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import { checkPluralNew } from 'helper/commonFunctions';
import { rejectCandidates } from 'requests/AssessmentRequests';
import { timeToCloseFlashMessageCancel, BATCH_STEP } from 'helper/constants';
import SnackbarProgress from 'libraries/SnackbarProgress';
import AssessmentEvents from 'events/AssessmentEvents';

class RejectUsers extends PureComponent {
    state = {
        showProgress: false,
        canceled: false,
        progress: 0,
        error: false
    };

    rejectUsers = () => {
        const { canceled } = this.state;
        const { slug, userIds, handleClose, onSuccess, audition } = this.props;
        const { uuid: ttId, name: ttName } = audition;

        this.setState({ showProgress: true, error: false });

        handleClose();
        const max = Math.ceil(userIds.length / BATCH_STEP);
        let i = 0;

        AssessmentEvents.CANDIDATES_BATCH_ACTIONED({
            actionType: 'reject',
            numberCandidatesActioned: userIds.length,
            ttId,
            ttName
        });

        const handleError = () => {
            this.setState({ error: true }, () => {
                setTimeout(() => {
                    this.setState({ showProgress: false });
                }, 2000);
            });
        };

        const requestCycle = async () => {
            if (i < max && !canceled) {
                this.setState({ progress: parseInt((i * 100) / max, 10) });
                const startPos = i * BATCH_STEP;
                const endPos = startPos + BATCH_STEP;
                try {
                    const slicedArr = userIds.slice(startPos, endPos);
                    const result = await rejectCandidates(slug, slicedArr);
                    if (result.success) {
                        i += 1;
                        setTimeout(() => {
                            if (onSuccess) onSuccess();
                            this.setState({ showProgress: false });
                        }, 2000);
                        handleClose();
                        requestCycle();
                    } else {
                        handleError();
                    }
                } catch (e) {
                    handleError();
                }
            } else if (!canceled) {
                this.setState({ progress: 100 });
            }
        };
        requestCycle();
    };

    handleCancel = () => {
        this.setState({ canceled: true }, () => {
            const { handleClose } = this.props;
            setTimeout(handleClose, timeToCloseFlashMessageCancel);
        });
    };

    render() {
        const { handleClose, userIds, open } = this.props;
        const { showProgress, progress, error, canceled } = this.state;
        const candidateFormatted = `${userIds.length} ${checkPluralNew(userIds.length, 'candidate')}`;

        if (showProgress) {
            return (
                <SnackbarProgress
                    open
                    showSuccessIcon
                    message={`Rejecting ${candidateFormatted}`}
                    onCancel={this.handleCancel}
                    percents={progress}
                    error={error}
                    errorLabel="ERROR - INTERNET CONNECTION TIMED OUT"
                    canceled={canceled}
                />
            );
        }

        return (
            <Dialog
                open={open}
                maxWidth="sm"
                actionComponent={(
                    <>
                        <Button
                            className="u-txt--bold"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            className="u-pdn--rx4 u-pdn--lx4"
                            disabled={showProgress}
                            onClick={this.rejectUsers}
                        >
                            Reject {candidateFormatted}
                        </Button>
                    </>
                )}
                titleComponent={<>Reject {userIds.length} {checkPluralNew(userIds.length, 'Candidate')}</>}
                handleClose={handleClose}
                onClose={handleClose}
            >
                <p>Rejecting candidates can't be undone and may result in an email to the candidate. You can configure this email by clicking Settings and then Candidate Communication.</p>
                <p>Are you sure you’d like to reject {candidateFormatted}?</p>
            </Dialog>
        );
    }
}

export default RejectUsers;

import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import RemoveIcon from 'img/remove.svg';

const styles = () => ({
    actionButton: {
        fontWeight: 700,
        paddingRight: 20,
        paddingLeft: 20
    },
    deleteButton: {
        fontWeight: 700,
        minWidth: 174
    },
    modalContentItem: {
        margin: '10px 0',
        fontWeight: 'bold',
        fontSize: 20,
        textAlign: 'center'
    },
    icon: {
        width: 60
    }
});

const DeleteQuestionDialog = observer(({
    classes, open, onClose,
    deleteQuestion, questionIndex
}) => {
    const [loading, setLoading] = useState(false);

    const onDelete = () => {
        setLoading(true);
        deleteQuestion(questionIndex)
            .then(() => {
                onClose();
                setLoading(false);
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            actionComponent={(
                <>
                    <Button
                        className={classes.actionButton}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        className={classes.deleteButton}
                        onClick={onDelete}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                />
                            ) : 'Delete Question'
                        }
                    </Button>
                </>
            )}
            open={open}
            onClose={onClose}
            handleClose={onClose}
        >
            <div className="u-dsp--f u-fdir--column u-ai--center">
                <img className={classes.icon} src={RemoveIcon} alt="x" />
                <div className={classes.modalContentItem}>Are you sure you want to delete <br /> this question?</div>
                You will not be able to undo this action.
            </div>
        </Dialog>
    );
});

export default withStyles(styles)(DeleteQuestionDialog);

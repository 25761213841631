import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import EditPanelSection from 'components/assessments_pages/asessment_settings_components/EditPanelSection';


const EquipmentCheckSettings = ({
    settings: { equipmentCheckForced },
    changeAssessmentSettings
}) => {
    const [checked, setChecked] = useState(equipmentCheckForced);

    const handleSwitch = (e) => {
        const { checked: newChecked } = e.target;
        setChecked(newChecked);
        changeAssessmentSettings({ equipmentCheckForced: newChecked });
    };

    return (
        <EditPanelSection
            header={(
                <>
                    Mandatory equipment check
                    <Switch
                        checked={checked}
                        onChange={handleSwitch}
                    />
                </>
            )}
        >
            Candidates will be required to test their audio and video equipment before starting the assessment
        </EditPanelSection>
    );
};

export default withRouter(EquipmentCheckSettings);

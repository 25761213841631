import React from 'react';
import { withTheme } from '@mui/styles';

const SeekIcon = ({ theme, active, size = 32, ...other }) => (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect width="32" height="31.9887" rx="4" fill={active ? theme.palette.primary.main : theme.palette.grey[400]} />
        <path d="M26.6668 15.5826C26.6668 16.195 26.1603 16.6912 25.5373 16.6912C24.9134 16.6912 24.4078 16.195 24.4078 15.5826C24.4078 14.971 24.9134 14.4747 25.5373 14.4747C26.1612 14.4739 26.6668 14.9701 26.6668 15.5826Z" fill="white" />
        <path d="M22.6377 16.5899C23.2044 16.5899 23.6639 16.1389 23.6639 15.5825C23.6639 15.0262 23.2044 14.5752 22.6377 14.5752C22.0709 14.5752 21.6115 15.0262 21.6115 15.5825C21.6115 16.1389 22.0709 16.5899 22.6377 16.5899Z" fill="white" />
        <path d="M22.7358 13.878C23.3 13.8251 23.7137 13.3332 23.6598 12.7794C23.6059 12.2255 23.1049 11.8194 22.5406 11.8723C21.9764 11.9252 21.5627 12.4171 21.6166 12.971C21.6705 13.5248 22.1716 13.9309 22.7358 13.878Z" fill="white" />
        <path d="M22.6377 19.3507C23.2044 19.3507 23.6639 18.8997 23.6639 18.3433C23.6639 17.787 23.2044 17.336 22.6377 17.336C22.0709 17.336 21.6115 17.787 21.6115 18.3433C21.6115 18.8997 22.0709 19.3507 22.6377 19.3507Z" fill="white" />
        <path d="M20.6091 18.3433C20.6091 18.8142 20.2201 19.1952 19.7413 19.1952C19.2624 19.1952 18.8744 18.8142 18.8744 18.3433C18.8744 17.8733 19.2624 17.4924 19.7413 17.4924C20.2201 17.4915 20.6091 17.8733 20.6091 18.3433Z" fill="white" />
        <path d="M20.6091 21.1033C20.6091 21.5742 20.2201 21.9551 19.7413 21.9551C19.2624 21.9551 18.8744 21.5742 18.8744 21.1033C18.8744 20.6332 19.2624 20.2523 19.7413 20.2523C20.2201 20.2514 20.6091 20.6332 20.6091 21.1033Z" fill="white" />
        <path d="M20.6091 15.5825C20.6091 16.0534 20.2201 16.4344 19.7413 16.4344C19.2624 16.4344 18.8744 16.0534 18.8744 15.5825C18.8744 15.1125 19.2624 14.7316 19.7413 14.7316C20.2201 14.7307 20.6091 15.1125 20.6091 15.5825Z" fill="white" />
        <path d="M20.6091 12.875C20.6091 13.3459 20.2201 13.7268 19.7413 13.7268C19.2624 13.7268 18.8744 13.3459 18.8744 12.875C18.8744 12.405 19.2624 12.024 19.7413 12.024C20.2201 12.0232 20.6091 12.405 20.6091 12.875Z" fill="white" />
        <path d="M20.6091 10.0941C20.6091 10.565 20.2201 10.9459 19.7413 10.9459C19.2624 10.9459 18.8744 10.565 18.8744 10.0941C18.8744 9.62402 19.2624 9.2431 19.7413 9.2431C20.2201 9.2431 20.6091 9.62402 20.6091 10.0941Z" fill="white" />
        <path d="M17.8829 10.0942C17.8829 10.5057 17.5429 10.8385 17.1237 10.8385C16.7045 10.8385 16.3654 10.5048 16.3654 10.0942C16.3654 9.68266 16.7045 9.34979 17.1237 9.34979C17.542 9.34979 17.8829 9.68266 17.8829 10.0942Z" fill="white" />
        <path d="M17.8829 12.875C17.8829 13.2865 17.5429 13.6194 17.1237 13.6194C16.7045 13.6194 16.3654 13.2857 16.3654 12.875C16.3654 12.4635 16.7045 12.1307 17.1237 12.1307C17.542 12.1298 17.8829 12.4635 17.8829 12.875Z" fill="white" />
        <path d="M15.2129 12.875C15.2129 13.2384 14.9129 13.5311 14.5435 13.5311C14.1742 13.5311 13.8751 13.2376 13.8751 12.875C13.8751 12.5124 14.1742 12.2189 14.5435 12.2189C14.9129 12.218 15.2129 12.5124 15.2129 12.875Z" fill="white" />
        <path d="M15.2129 15.5826C15.2129 15.946 14.9129 16.2387 14.5435 16.2387C14.1742 16.2387 13.8751 15.9452 13.8751 15.5826C13.8751 15.22 14.1742 14.9265 14.5435 14.9265C14.9129 14.9256 15.2129 15.22 15.2129 15.5826Z" fill="white" />
        <path d="M15.2129 18.3433C15.2129 18.7068 14.9129 18.9995 14.5435 18.9995C14.1742 18.9995 13.8751 18.7059 13.8751 18.3433C13.8751 17.9808 14.1742 17.6872 14.5435 17.6872C14.9129 17.6863 15.2129 17.9808 15.2129 18.3433Z" fill="white" />
        <path d="M13.0251 12.875C13.0251 13.2079 12.75 13.477 12.4118 13.477C12.0727 13.477 11.7986 13.2079 11.7986 12.875C11.7986 12.543 12.0727 12.2731 12.4118 12.2731C12.75 12.2722 13.0251 12.543 13.0251 12.875Z" fill="white" />
        <path d="M13.0251 15.5825C13.0251 15.9154 12.75 16.1845 12.4118 16.1845C12.0727 16.1845 11.7986 15.9154 11.7986 15.5825C11.7986 15.2505 12.0727 14.9805 12.4118 14.9805C12.75 14.9805 13.0251 15.2505 13.0251 15.5825Z" fill="white" />
        <path d="M13.0251 18.3434C13.0251 18.6762 12.75 18.9453 12.4118 18.9453C12.0727 18.9453 11.7986 18.6762 11.7986 18.3434C11.7986 18.0114 12.0727 17.7414 12.4118 17.7414C12.75 17.7405 13.0251 18.0114 13.0251 18.3434Z" fill="white" />
        <path d="M10.9211 12.875C10.9211 13.1563 10.6888 13.3844 10.4022 13.3844C10.1156 13.3844 9.8833 13.1563 9.8833 12.875C9.8833 12.5937 10.1147 12.3657 10.4022 12.3657C10.6879 12.3657 10.9211 12.5937 10.9211 12.875Z" fill="white" />
        <path d="M10.9211 15.5825C10.9211 15.8639 10.6888 16.0919 10.4022 16.0919C10.1156 16.0919 9.8833 15.8639 9.8833 15.5825C9.8833 15.3012 10.1147 15.0732 10.4022 15.0732C10.6879 15.0732 10.9211 15.3012 10.9211 15.5825Z" fill="white" />
        <path d="M10.9211 18.3433C10.9211 18.6246 10.6888 18.8526 10.4022 18.8526C10.1156 18.8526 9.8833 18.6246 9.8833 18.3433C9.8833 18.062 10.1147 17.8339 10.4022 17.8339C10.6879 17.8339 10.9211 18.062 10.9211 18.3433Z" fill="white" />
        <path d="M9.12588 12.875C9.12588 13.1109 8.93096 13.3014 8.69154 13.3014C8.45123 13.3014 8.2572 13.11 8.2572 12.875C8.2572 12.64 8.45123 12.4487 8.69154 12.4487C8.93096 12.4478 9.12588 12.64 9.12588 12.875Z" fill="white" />
        <path d="M9.12588 15.5825C9.12588 15.8184 8.93096 16.0088 8.69154 16.0088C8.45123 16.0088 8.2572 15.8175 8.2572 15.5825C8.2572 15.3466 8.45123 15.1561 8.69154 15.1561C8.93096 15.1561 9.12588 15.3466 9.12588 15.5825Z" fill="white" />
        <path d="M9.12588 18.3433C9.12588 18.5792 8.93096 18.7697 8.69154 18.7697C8.45123 18.7697 8.2572 18.5784 8.2572 18.3433C8.2572 18.1083 8.45123 17.917 8.69154 17.917C8.93096 17.917 9.12588 18.1083 9.12588 18.3433Z" fill="white" />
        <path d="M7.32076 12.875C7.32076 13.055 7.17212 13.2 6.98966 13.2C6.80632 13.2 6.65857 13.055 6.65857 12.875C6.65857 12.6959 6.80632 12.55 6.98966 12.55C7.17212 12.5491 7.32076 12.695 7.32076 12.875Z" fill="white" />
        <path d="M7.32076 15.5825C7.32076 15.7625 7.17212 15.9075 6.98966 15.9075C6.80632 15.9075 6.65857 15.7625 6.65857 15.5825C6.65857 15.4025 6.80632 15.2575 6.98966 15.2575C7.17212 15.2566 7.32076 15.4025 7.32076 15.5825Z" fill="white" />
        <path d="M7.32076 18.3433C7.32076 18.5233 7.17212 18.6683 6.98966 18.6683C6.80632 18.6683 6.65857 18.5233 6.65857 18.3433C6.65857 18.1642 6.80632 18.0183 6.98966 18.0183C7.17212 18.0174 7.32076 18.1642 7.32076 18.3433Z" fill="white" />
        <path d="M5.80421 12.875C5.80421 13.0034 5.69829 13.1065 5.56834 13.1065C5.4384 13.1065 5.33337 13.0026 5.33337 12.875C5.33337 12.7475 5.4384 12.6435 5.56834 12.6435C5.69829 12.6435 5.80421 12.7475 5.80421 12.875Z" fill="white" />
        <path d="M5.80421 15.5825C5.80421 15.711 5.69829 15.814 5.56834 15.814C5.4384 15.814 5.33337 15.711 5.33337 15.5825C5.33337 15.455 5.4384 15.3519 5.56834 15.3519C5.69829 15.351 5.80421 15.4541 5.80421 15.5825Z" fill="white" />
        <path d="M5.80421 18.3433C5.80421 18.4709 5.69829 18.5749 5.56834 18.5749C5.4384 18.5749 5.33337 18.4709 5.33337 18.3433C5.33337 18.2158 5.4384 18.1118 5.56834 18.1118C5.69829 18.1118 5.80421 18.2158 5.80421 18.3433Z" fill="white" />
        <path d="M17.8829 15.5825C17.8829 15.994 17.5429 16.3277 17.1237 16.3277C16.7045 16.3277 16.3654 15.994 16.3654 15.5825C16.3654 15.1719 16.7045 14.8381 17.1237 14.8381C17.542 14.8381 17.8829 15.171 17.8829 15.5825Z" fill="white" />
        <path d="M17.8829 18.3434C17.8829 18.7549 17.5429 19.0877 17.1237 19.0877C16.7045 19.0877 16.3654 18.754 16.3654 18.3434C16.3654 17.9327 16.7045 17.599 17.1237 17.599C17.542 17.5981 17.8829 17.9319 17.8829 18.3434Z" fill="white" />
        <path d="M17.8829 21.1033C17.8829 21.5148 17.5429 21.8485 17.1237 21.8485C16.7045 21.8485 16.3654 21.5148 16.3654 21.1033C16.3654 20.6926 16.7045 20.3589 17.1237 20.3589C17.542 20.3589 17.8829 20.6918 17.8829 21.1033Z" fill="white" />
        <path d="M17.8829 23.7828C17.8829 24.1943 17.5429 24.5272 17.1237 24.5272C16.7045 24.5272 16.3654 24.1943 16.3654 23.7828C16.3654 23.3713 16.7045 23.0376 17.1237 23.0376C17.542 23.0376 17.8829 23.3713 17.8829 23.7828Z" fill="white" />
        <path d="M17.8829 7.41106C17.8829 7.82256 17.5429 8.15543 17.1237 8.15543C16.7045 8.15543 16.3654 7.82168 16.3654 7.41106C16.3654 7.00043 16.7045 6.66669 17.1237 6.66669C17.542 6.66669 17.8829 7.00043 17.8829 7.41106Z" fill="white" />
        <path d="M17.8829 21.1033C17.8829 21.5148 17.5429 21.8485 17.1237 21.8485C16.7045 21.8485 16.3654 21.5148 16.3654 21.1033C16.3654 20.6926 16.7045 20.3589 17.1237 20.3589C17.542 20.3589 17.8829 20.6918 17.8829 21.1033Z" fill="white" />
    </svg>
);

export default withTheme(SeekIcon);

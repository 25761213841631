import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

const getRect = (element) => {
    if (!element) {
        return {
            bottom: 0,
            left: 0,
            right: 0,
            top: 0
        };
    }

    return element.getBoundingClientRect();
};

const useRect = (ref) => {
    const [rect, setRect] = useState(getRect(ref ? ref.current : null));

    const handleResize = useCallback(() => {
        if (!ref.current) return;

        setRect(getRect(ref.current));
    }, [ref]);

    useEffect(() => {
        window.addEventListener('scroll', handleResize);
        return () => {
            window.removeEventListener('scroll', handleResize);
        };
    }, []);

    useLayoutEffect(() => {
        const element = ref.current;
        if (!element) return;

        handleResize();

        if (typeof ResizeObserver === 'function') {
            let resizeObserver = new ResizeObserver(() => handleResize());
            resizeObserver.observe(element);

            return () => {
                if (!resizeObserver) return;

                resizeObserver.disconnect();
                resizeObserver = null;
            };
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref.current]);

    return rect;
};

export default useRect;

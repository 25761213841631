import React from 'react';
import { withStyles } from '@mui/styles';
import { AUDIO, VIDEO } from 'helper/constants';
import clsx from 'clsx';


// local files and component
import TimerIcon from './TimerIcon';
import styles from './styles';

const TimerInfo = ({ question, audition, classes }) => {
    const {
        answerType, timerEnabled,
        timerValid, timeLimit,
        recordingTimeLimit
    } = question;

    // Answer all types, colors, Icons, creating correct html for answer type
    const isMediaAnswer = answerType === AUDIO || answerType === VIDEO;
    const hasRecordingLimit = Boolean(recordingTimeLimit) && isMediaAnswer;
    if (!(!audition.timerEnabled && (timerEnabled || hasRecordingLimit))) return null;

    return (
        <div className={classes.timerWrapper}>
            <TimerIcon isError={!timerValid} />
            {
                timerEnabled && (
                    <div className={classes.timeLimitWrapper}>
                        <span className={clsx(classes.timeLimitValue, !timerValid && classes.timeLimitError)}>
                            {` ${timeLimit}`}
                        </span>&nbsp;Min Timer
                    </div>
                )
            }
            {
                hasRecordingLimit && (
                    <div className={classes.timeLimitWrapper}>
                        <span className={classes.timeLimitValue}>{` ${recordingTimeLimit}`}</span>&nbsp;Min recording time limit
                    </div>
                )
            }
        </div>
    );
}
export default withStyles(styles)(TimerInfo);

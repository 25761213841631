export default theme => ({
    emailLogoWrapper: {
        textAlign: 'center',
        borderTop: `2px solid ${theme.palette.grey[300]}`,
        borderLeft: `2px solid ${theme.palette.grey[300]}`,
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    firstNameWrapper: {
        padding: '15px',
        textAlign: 'left',
        borderTop: `2px solid ${theme.palette.grey[300]}`
    },
    emailContent: {
        fontSize: 16,
        fontWeight: 'normal',
        letterSpacing: 0.2,
        marginBottom: 30
    }
});

import { createContext } from 'react';
import { action, observable } from 'mobx';
import { getCompanyFolders, createFolder, removeFolder, editFolderName } from 'requests/FoldersRequests';
import MiscellaneousEvents from 'events/MiscellaneousEvents';
import { appStore } from '../../appStore';
import { assessmentQueryStore } from '../AssessmentList/queryStore';

class FoldersStore {
    @observable folders = [];

    @observable activeFolderId = null;

    @action getFolders = () => {
        getCompanyFolders()
            .then(({ success, data }) => {
                if (success && data) {
                    this.folders = data;
                }
            });
    }

    @action addFolder = name => createFolder(name)
        .then(({ success, data }) => {
            if (success && data) {
                this.folders.push({ auditionsCount: 0, ...data });
                MiscellaneousEvents.FOLDER_CREATED({ folderName: name });
            }
            return ({ success, data });
        })

    @action renameFolder = (id, name) => editFolderName(id, name)
        .then(({ success, data }) => {
            if (success && data) {
                const index = this.folders.findIndex(folder => id === folder.id);
                const { name: folderNameOld, uuid } = this.folders[index];
                this.folders[index] = {
                    ...data,
                    auditionsCount: this.folders[index].auditionsCount
                };
                MiscellaneousEvents.FOLDER_NAME_EDITED({ folderId: uuid, folderNameOld, folderNameNew: name });
            }
            return ({ success, data });
        })

    @action deleteFolder = id => removeFolder(id)
        .then((response) => {
            if (response && response.status === 204) {
                this.getFolders();

                if (id === this.activeFolderId) this.activeFolderId = null;
                const index = this.folders.findIndex(folder => id === folder.id);
                const { name, uuid, auditionsCount } = this.folders[index];
                this.folders.splice(index, 1);
                appStore.flashMessage(`${name} folder deleted`, 'done');
                MiscellaneousEvents.FOLDER_DELETED({ folderName: name, folderId: uuid, assessmentsNumber: auditionsCount });
            }
        })
        .catch(() => appStore.flashMessage('Something went wrong', 'error'));

    @action setActiveFolder = (id) => {
        assessmentQueryStore.setQuery('');
        this.activeFolderId = id;
    }
}

export const foldersStore = new FoldersStore();
export const foldersCtx = createContext(foldersStore);

import { makeStyles } from '@mui/styles';
import { getScoreColorByParam } from 'helper/commonFunctions';

export default makeStyles(theme => ({
    progress: {
        width: 'calc(100% - 55px)'
    },
    rowContainer: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: 9
    },
    definition: {
        fontSize: 12,
        fontWeight: 600,
        marginBottom: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
        width: '80%'
    },
    root: {
        height: 8,
        borderRadius: 56
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[300]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: props => getScoreColorByParam(props.rejected ? 'LOW' : props.scoreColorRange)
    },
    trophy: {
        margin: '0 0 -3px 5px'
    },
    rankContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 8,
        zIndex: 2,
        '& > span': {
            fontSize: 12,
            fontWeight: 700
        }
    },
    tooltipSpan: {
        fontWeight: 400
    }
}));

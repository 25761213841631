import React from 'react';
import clsx from 'clsx';
import ReactHtmlParser from 'react-html-parser';
import {
    MARKETING_SIM_CREATE_ICP,
    MARKETING_SIM_FLOW_BUILDER,
    MARKETING_SIM_FLOW_CONTENT,
    MARKETING_SIM_FORECASTING_SHEET,
    MARKETING_SIM_TEXT_WYSIWYG,
    MARKETING_SIM_COMPOSE_BUSINESS_PLAN,
    MARKETING_SIM_BUSINESS_PLAN_EMAIL,
    MARKETING_SIM_SEO_TRAFFIC,
    MARKETING_SIM_SEO_TECHNICAL,
    MARKETING_SIM_SEO_SITEMAP,
    MARKETING_SIM_SEO_EMAIL_TO_MARKETING,
    MARKETING_SIM_SEO_DOCUMENT_SUGGESTIONS,
    MARKETING_SIM_SEO_AUDIO_CALL,
    MARKETING_SIM_SEO_SOLUTION_PAGE,
    MARKETING_SIM_SEO_OFF_PAGE,
    MARKETING_SIM_SEO_COLD_OUTREACH,
    MARKETING_SIM_CM_STRATEGY,
    MARKETING_SIM_CM_EMAIL,
    MARKETING_SIM_CM_TABLE,
    MARKETING_SIM_CM_AUDIO_CALL,
    MARKETING_SIM_CM_VIDEO_CALL,
    MARKETING_SIM_CM_TEXT_WYSIWYG,
    MARKETING_SIM_CM_SPREADSHEET
} from 'helper/constants';
import { nl2brCustom } from 'helper/commonFunctions';
import { Button } from '@mui/material';
import ScoringTips from '../../candidate_cards/CandidateCardWrapper/GradeBlock/components/QuestionsContent/ScoringTips';
import BottomContainer
from '../../candidate_cards/CandidateCardWrapper/GradeBlock/components/QuestionsContent/BottomContainer';
import AnswerContent from '../AnswerContent';
import WF1Icon from './icons/workflow1.svg';
import WF2Icon from './icons/workflow2.svg';
import WF3Icon from './icons/workflow3.svg';
import WF4Icon from './icons/workflow4.svg';
import WF5Icon from './icons/workflow5.svg';
import WF6Icon from './icons/workflow6.svg';
import WorkflowAnswer from './WorkflowAnswer';
import NoAnswerProvided from '../AnswerContent/NoAnswerProvided';
import RichTextEditorAnswer from './RichTextEditorAnswer';
import { NO_ANSWER } from '../../../helper/constants';
import useStyles from './styles';


const MarketingSimTasksAnswerContent = ({
    userName = '', setGradeQuestion,
    candidate, audition, propPublic,
    updateUserAuditionAndBox, canGrade, currentTask,
    isIntegration, questionNumber, saveGrade, currentGrade, gradeLoading, questionCount,
    goToPreviousQuestion, goToNextQuestion, gradeableCard, taskNumber, candidateTasks
}) => {
    const currentQuestionIndex = questionNumber + 1;
    const { type, questionResults, taskScore } = currentTask;
    const { instructions, answeredText, scoreText, workFlowWrapper, simStrategyDescription } = useStyles();
    let currentTaskGrade = currentGrade;

    if ([MARKETING_SIM_COMPOSE_BUSINESS_PLAN, MARKETING_SIM_SEO_DOCUMENT_SUGGESTIONS,
        MARKETING_SIM_SEO_OFF_PAGE, MARKETING_SIM_SEO_COLD_OUTREACH].includes(type)) {
        currentTaskGrade = (gradeableCard || !propPublic) ? questionResults[1].userGrade : questionResults[1].grade;
    }
    const saveGradeFunction = (value) => {
        switch (type) {
            case MARKETING_SIM_COMPOSE_BUSINESS_PLAN:
            case MARKETING_SIM_SEO_DOCUMENT_SUGGESTIONS:
            case MARKETING_SIM_SEO_OFF_PAGE:
            case MARKETING_SIM_SEO_COLD_OUTREACH:
                return saveGrade(value, questionResults[1].id, 1);
            default: return saveGrade(value);
        }
    };

    const returnQuestionResultIndex = () => {
        switch (type) {
            case MARKETING_SIM_FLOW_CONTENT:
            case MARKETING_SIM_BUSINESS_PLAN_EMAIL:
            case MARKETING_SIM_TEXT_WYSIWYG:
            case MARKETING_SIM_SEO_SITEMAP:
            case MARKETING_SIM_SEO_EMAIL_TO_MARKETING:
            case MARKETING_SIM_SEO_AUDIO_CALL:
            case MARKETING_SIM_CM_EMAIL:
            case MARKETING_SIM_CM_TABLE:
            case MARKETING_SIM_CM_VIDEO_CALL:
            case MARKETING_SIM_CM_AUDIO_CALL:
            case MARKETING_SIM_CM_TEXT_WYSIWYG:
            case MARKETING_SIM_CM_SPREADSHEET:
                return 0;
            case MARKETING_SIM_FORECASTING_SHEET:
            case MARKETING_SIM_SEO_TRAFFIC:
                return 2;
            default: return 1;
        }
    };

    const questionResultIndex = returnQuestionResultIndex();
    const taskQuestionResult = questionResults[questionResultIndex];

    const returnContent = () => {
        switch (type) {
            case MARKETING_SIM_CREATE_ICP:
            case MARKETING_SIM_SEO_SOLUTION_PAGE: {
                return <>
                    {questionResults.map((taskQResult, index) => {
                        if (!index || index === 1) return null;
                        return (
                            <div className={instructions}>
                                {ReactHtmlParser(nl2brCustom(taskQResult?.question.scoringInstruction))}
                            </div>
                        );
                    })}
                    <hr />
                    <div className="u-mrg--bx4 u-txt--bold">Candidate Answer</div>
                    {type === MARKETING_SIM_CREATE_ICP && <div className="u-mrg--bx2 u-txt--bold">Ideal Customer Profile:</div>}
                    {
                        questionResults.map((taskQResult, index) => {
                            if (!index) return null;
                            return (
                                <div className="u-mrg--bx3" key={index}>
                                    <div className="u-mrg--bx2">
                                        {ReactHtmlParser(taskQResult.question.description)}
                                    </div>
                                    <AnswerContent
                                        canGrade={canGrade}
                                        audition={audition}
                                        candidate={candidate}
                                        question={taskQResult}
                                        questionIndex={questionNumber}
                                        userName={userName}
                                        setGradeQuestion={setGradeQuestion}
                                        propPublic={propPublic}
                                        updateUserAuditionAndBox={updateUserAuditionAndBox}
                                        isIntegration={isIntegration}
                                    />
                                    <BottomContainer
                                        activeAssessment={audition?.active}
                                        withHeader={false}
                                        canGrade={canGrade}
                                        candidate={candidate}
                                        saveGrade={value => saveGrade(value, questionResults[index].id, index)}
                                        question={questionResults[index]}
                                        currentGrade={(gradeableCard || !propPublic) ? questionResults[index].userGrade : questionResults[index].grade}
                                        gradeLoading={gradeLoading}
                                        {...{
                                            gradeableCard,
                                            propPublic
                                        }}
                                    />
                                </div>
                            );
                        })
                    }
            </>;
            }

            case MARKETING_SIM_CM_STRATEGY: {
                return <>
                    {
                        questionResults.map((taskQResult, index) => (
                            <div className="'u-mrg--bx3'" key={index}>
                                <div className={clsx('u-mrg--bx2', simStrategyDescription)}>
                                    {ReactHtmlParser(taskQResult.question.description)}
                                </div>
                                {
                                    taskQResult.question?.answerType !== NO_ANSWER && (
                                        <>
                                            <AnswerContent
                                                canGrade={canGrade}
                                                audition={audition}
                                                candidate={candidate}
                                                question={taskQResult}
                                                questionIndex={questionNumber}
                                                userName={userName}
                                                setGradeQuestion={setGradeQuestion}
                                                propPublic={propPublic}
                                                updateUserAuditionAndBox={updateUserAuditionAndBox}
                                                isIntegration={isIntegration}
                                            />
                                            <BottomContainer
                                                activeAssessment={audition?.active}
                                                withHeader={false}
                                                canGrade={canGrade}
                                                className="u-mrg--bx4"
                                                candidate={candidate}
                                                saveGrade={value => saveGrade(value, questionResults[index].id, index)}
                                                question={questionResults[index]}
                                                currentGrade={(gradeableCard || !propPublic) ? questionResults[index].userGrade : questionResults[index].grade}
                                                gradeLoading={gradeLoading}
                                                {...{
                                                    gradeableCard,
                                                    propPublic
                                                }}
                                            />
                                        </>
                                    )
                                }
                            </div>
                        ))
                    }
            </>;
            }
            case MARKETING_SIM_FLOW_BUILDER:
                return <>
                    <div className={workFlowWrapper}>
                        <img src={WF1Icon} alt="*" />
                        <img src={WF6Icon} style={{ marginTop: -12, marginBottom: -25 }} alt="*" />
                        <WorkflowAnswer text="Options" questionResults={questionResults} index={0} />
                        <img src={WF3Icon} style={{ marginTop: -25 }} alt="*" />
                        <img src={WF5Icon} style={{ width: 500, marginBottom: -26 }} alt="*" />
                        <div className="u-dsp--f">
                            <WorkflowAnswer text="Response 1" questionResults={questionResults} index={1} />
                            <WorkflowAnswer text="Response 2" questionResults={questionResults} index={2} />
                            <WorkflowAnswer text="Response 3" questionResults={questionResults} index={3} />
                        </div>
                        <img src={WF4Icon} style={{ width: 500, marginTop: -25, marginBottom: -26 }} alt="*" />
                        <div className="u-dsp--f">
                            <WorkflowAnswer text="Location 1" questionResults={questionResults} index={4} />
                            <WorkflowAnswer text="Location 2" questionResults={questionResults} index={5} />
                            <WorkflowAnswer text="Location 3" questionResults={questionResults} index={6} />
                        </div>
                    </div>
                    <br />
                </>;

            case MARKETING_SIM_FLOW_CONTENT:
                return (
                    <div className={workFlowWrapper}>
                        <img src={WF2Icon} alt="*" />
                        <div className={answeredText}>
                            {
                                questionResults[questionResultIndex]?.answeredText
                                    ? ReactHtmlParser(questionResults[questionResultIndex]?.answeredText)
                                    : <NoAnswerProvided />
                            }
                        </div>
                    </div>
                );

            case MARKETING_SIM_COMPOSE_BUSINESS_PLAN:
            case MARKETING_SIM_SEO_DOCUMENT_SUGGESTIONS:
            case MARKETING_SIM_CM_TEXT_WYSIWYG:
                return (
                    <>
                        <hr />
                        <div className="u-mrg--bx4 u-txt--bold">Candidate Answer</div>
                        {
                            type === MARKETING_SIM_CM_TEXT_WYSIWYG && questionResults[questionResultIndex]?.marketingSimulationMetaData?.websiteUrl && (
                                <Button
                                    color="primary"
                                    className="u-mrg--bx2"
                                    onClick={() => {
                                        window.open(questionResults[questionResultIndex]?.marketingSimulationMetaData?.websiteUrl, '_blank');
                                    }}
                                >
                                    Blog Article
                                </Button>
                            )
                        }
                        {
                            questionResults[questionResultIndex]?.answeredText
                                ? (
                                    <>
                                        <RichTextEditorAnswer
                                            content={questionResults[questionResultIndex].richTextEditorContent}
                                        />
                                    </>
                                ) : <NoAnswerProvided />
                        }
                    </>
                );

            default:
                return (
                <>
                    <hr />
                    <div className="u-mrg--bx2 u-txt--bold">Candidate Answer</div>
                    <AnswerContent
                        canGrade={canGrade}
                        audition={audition}
                        candidate={candidate}
                        question={taskQuestionResult}
                        questionIndex={questionNumber}
                        userName={userName}
                        setGradeQuestion={setGradeQuestion}
                        propPublic={propPublic}
                        updateUserAuditionAndBox={updateUserAuditionAndBox}
                        isIntegration={isIntegration}
                    />
                    <br />
                    <br />
                </>
                );
        }
    };

    return <>
        { Boolean(taskQuestionResult.question?.scoringInstruction) && <hr /> }
        <ScoringTips isTask question={taskQuestionResult} hideExampleAnswers />
        {returnContent()}
        {![MARKETING_SIM_CREATE_ICP,
            MARKETING_SIM_SEO_SOLUTION_PAGE,
            MARKETING_SIM_FLOW_BUILDER,
            MARKETING_SIM_FORECASTING_SHEET,
            MARKETING_SIM_SEO_TRAFFIC,
            MARKETING_SIM_SEO_TECHNICAL,
            MARKETING_SIM_SEO_SITEMAP,
            MARKETING_SIM_CM_STRATEGY,
            MARKETING_SIM_CM_SPREADSHEET
        ].includes(type) && (
            <BottomContainer
                withHeader={false}
                canGrade={canGrade}
                saveGrade={saveGradeFunction}
                candidate={candidate}
                question={questionResults[returnQuestionResultIndex()]}
                currentGrade={currentTaskGrade}
                gradeLoading={gradeLoading}
                totalCount={questionCount}
                questionNumber={currentQuestionIndex}
                goToPreviousQuestion={goToPreviousQuestion}
                goToNextQuestion={goToNextQuestion}
                previousQuestionDisabled={questionNumber === 0 && taskNumber === 0}
                nextQuestionDisabled={questionNumber === questionCount - 1 && taskNumber === candidateTasks.length - 1}
                activeAssessment={audition?.active}
                {...{
                    gradeableCard,
                    propPublic
                }}
            />
        )}
        {[MARKETING_SIM_FLOW_BUILDER,
            MARKETING_SIM_FORECASTING_SHEET,
            MARKETING_SIM_SEO_TRAFFIC,
            MARKETING_SIM_SEO_TECHNICAL,
            MARKETING_SIM_SEO_SITEMAP,
            MARKETING_SIM_CM_SPREADSHEET
        ].includes(type) && (
            <div className={clsx(scoreText, 'u-txt--right u-txt--nowrap u-txt--bold u-mrg--tx2')}>
                {taskScore === null
                    ? 'Calculating Score...'
                    : `Question score: ${taskScore} of 10 points`
                }
            </div>
        )}
    </>;
};

export default MarketingSimTasksAnswerContent;

import React from 'react';

import { withStyles } from '@mui/styles';

import styles from './styles';

const NoAnswer = ({ classes }) => (
    <div className={classes.noAnswer}>
        No answer is required for this question.
    </div>
);

export default withStyles(styles)(NoAnswer);

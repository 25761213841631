export function defaultContent(type, opportunity, company, isFirstProgressColumn, columnType, columnName) {
    if (!company || !opportunity) return;
    switch (type) {
        case 'reminder':
            return defaultReminderPageContent(opportunity, company);
        case 'rejection':
            return defaultRejectionPageContent(opportunity, company);
        case 'feedback':
            return defaultFeedbackPageContent(opportunity, company);
        default:
            return defaultJobPageContent(opportunity, company, isFirstProgressColumn, columnType, columnName);
    }
}

export function defaultSubject(type, company) {
    const isES = company.candidateAppLanguage === 'es';
    switch (type) {
        case 'reminder':
            return isES ? '{first-name}, nos encantaría saber más sobre ti, ¡no pierdas la oportunidad de trabajar con nosotros!'
                : '{first-name}, we’d love to find out more about you, don’t miss your chance to work with us!';
        case 'invitation':
            return isES ? `{first-name}, tus próximos pasos para tu puesto en ${company.name} están aquí.`
                : `{first-name}, Your next steps for the role with ${company.name} are here!`;
        case 'feedback':
            return `${isES ? 'Los comentarios sobre la prueba para' : 'Feedback on Your Skills Assessment for'} ${company.name}`;
        default:
            return `${isES ? 'Mensaje de' : 'Message from'} ${company.name}`;
    }
}

function defaultReminderPageContent(opportunity, company) {
    const isES = company.candidateAppLanguage === 'es';
    return (
        isES
            ? (
                '<p>'
                + 'Hola, <b>{first-name}</b>:'
                + '</p>'
                + '\n'
                + '<p>'
                    + `Es hora de comenzar tu <strong>entrevista online</strong> en <strong>${company.name}</strong>, ya que el proceso de presentación se cerrará en <strong>2</strong> días.`
                + '</p>'
                + '\n'
                + '<p>'
                    + `Queremos saber de ti y de las excelentes habilidades que aportarás a nuestro puesto de <strong>${opportunity.jobTitle}</strong>.`
                + '</p>'
                + '\n'
                + '<p>'
                   + '¡Haz clic a continuación para comenzar!'
                + '</p>'
                + '\n'
                + '<p>'
                    + 'Muchas gracias, <strong>{first-name}</strong>.'
                + '</p>'
            ) : (
                '<p>'
                + 'Hi <b>{first-name}</b>,'
                + '</p>'
                + '\n'
                + '<p>'
                + `It’s time to start your <strong>online assessment</strong> for <strong>${company.name}</strong> as submissions close in <strong>2</strong> days.`
                + '</p>'
                + '\n'
                + '<p>'
                    + `We’re looking forward to finding out more about you and the excellent skills you’ll bring to our <strong>${opportunity.jobTitle}</strong> role.`
                + '</p>'
                + '\n'
                + '<p>'
                    + 'Please click below to get started.'
                + '</p>'
                + '\n'
                + '<p>'
                   + 'Thanks in advance <strong>{first-name}</strong>!'
                + '</p>'
            )
    );
}

function defaultRejectionPageContent(opportunity, company) {
    const isES = company.candidateAppLanguage === 'es';
    return (
        isES
            ? (
                '<p>'
                + 'Hola, <b>{first-name}</b>:'
                + '</p>'
                + '\n'
                + '<p>'
                    + `Gracias por tu interés en <strong>${opportunity.jobTitle}</strong>.`
                + '</p>'
                + '\n'
                + '<p>'
                    + 'Apreciamos tu interés en trabajar con nosotros y el esfuerzo que has puesto en tu solicitud hasta hoy. '
                    + 'Sabemos que hay muchas empresas contratando en este momento y te agradecemos por tomarte el tiempo para considerarnos.'
                + '</p>'
                + '\n'
                + '<p>En esta etapa, sin embargo, no podemos avanzar con tu solicitud.</p>'
                + '\n'
                + '<p>'
                    + 'Nos gustaría mantenerte informado de cualquier oportunidad futura con nosotros, ya que estamos en constante crecimiento.'
                + '</p>'
                + '\n'
                + '<p>¡Gracias una vez más!</p>'
                + '\n'
                + `<p><strong>${company.name}</strong></p>`
            ) : (
                '<p>'
                + 'Hi <b>{first-name}</b>,'
                + '</p>'
                + '\n'
                + '<p>'
                    + `Thanks for your interest in <strong>${opportunity.jobTitle}</strong>.`
                + '</p>'
                + '\n'
                + '<p>'
                    + 'We really appreciate your interest in the role with us and the effort you have put into your application to date. '
                    + 'We know there are many companies hiring at the moment and we thank you for taking the time to consider us.'
                + '</p>'
                + '\n'
                + '<p>At this stage, however, we are unable to progress with your application.</p>'
                + '\n'
                + '<p>'
                    + 'We’d like to keep you informed of any future opportunities with us though as we are constantly growing.'
                + '</p>'
                + '\n'
                + '<p>Thank you once again!</p>'
                + '\n'
                + `<p><strong>${company.name}</strong></p>`
            )
    );
}

function defaultFeedbackPageContent(opportunity, company) {
    const isES = company.candidateAppLanguage === 'es';
    return (
        isES
            ? (
                '<p>'
                + 'Hola, <b>{first-name}</b>:'
                + '</p>'
                + '\n'
                + '<p>'
                + `Descargue el archivo adjunto para ver los comentarios sobre la prueba que completaste recientemente para <b>${company.name}</b>.`
                + '</p>'
                + '\n'
                + '<p>'
                + 'El informe ofrece un análisis de tu desempeño, brindando información específica sobre tus fortalezas y áreas de desarrollo.'
                + '</p>'
                + '\n'
                + `<p>Si tienes alguna pregunta o necesitas más aclaraciones sobre los comentarios, comunícate con <b>${company.name}</b>.</p>`
            ) : (
                '<p>'
                + 'Hi <b>{first-name}</b>,'
                + '</p>'
                + '\n'
                + '<p>'
                + `Please download the attached file to view your feedback on the <b>${opportunity.jobTitle}</b> you recently completed for <b>${company.name}</b>.`
                + '</p>'
                + '\n'
                + '<p>'
                + 'The feedback report offers an analysis of your performance, providing specific insights into your strengths and areas for development.'
                + '</p>'
                + '\n'
                + `<p>If you have any questions or require further clarification regarding the feedback, please contact <b>${company.name}</b>.</p>`
            )
    );
}

const defaultJobPageContent = (opportunity, company, isFirstProgressColumn, columnType, columnName) => {
    const isES = company.candidateAppLanguage === 'es';
    const { deadline } = opportunity;
    const hasDeadline = (deadline && deadline > -1);
    // different default templates for type progress and other types
    const companySign = isES ? (
        `<p><strong>- El equipo de ${company.name}</strong></p>`
    ) : (
        `<p><strong>- The ${company.name} Team</strong></p>`
    );

    if (isFirstProgressColumn) {
        return (
            isES
                ? (
                    '<p>'
                    + 'Hola, <b>{first-name}</b>:'
                    + '</p>'
                    + '\n'
                    + '<p>'
                        + `¡Felicitaciones, tu solicitud de empleo en <strong>${company.name}</strong> está en camino!`
                    + '</p>'
                    + '\n'
                    + '<p>'
                       + 'Hacemos las cosas un poco diferente aquí, así que en esta etapa, completarás una entrevista online, que te dará la oportunidad de contarnos un poco más sobre ti y mostrar tu talento.'
                    + '</p>'
                    + '\n'
                    + '<p>'
                        + 'En esta etapa online, se te harán una serie de preguntas interactivas que pueden requerir respuestas en varios formatos como opción múltiple, '
                        + 'video y texto. Esto nos permite conocer un poco más sobre ti y ver cómo te desarrollas en ciertos escenarios.'
                    + '</p>'
                    + '\n'
                    + '<p>'
                        + 'También es una excelente manera de ver cómo será trabajar con nosotros, '
                        + 'ya que las preguntas están relacionadas con las tareas diarias que realizarás en el trabajo.'
                    + '</p>'
                    + '\n'
                    + '<p>'
                        + `Por favor, completa esta entrevista en el momento y lugar que mejor funcione para ti${hasDeadline ? `<strong> en los próximos ${deadline} ${+deadline === 1 ? 'día' : 'días'} </strong>` : ''}. `
                        + 'Y asegúrate de poder grabar el video en el dispositivo que elijas usar.'
                    + '</p>'
                    + '\n'
                    + '<p>Esperamos conocerte más.</p>'
                    + '\n'
                    + '<p>¡Buena suerte!</p>'
                ) : (
                    '<p>'
                    + 'Hi <b>{first-name}</b>,'
                    + '</p>'
                    + '\n'
                    + '<p>'
                        + `Congratulations, your application with <strong>${company.name}</strong> is progressing!`
                    + '</p>'
                    + '\n'
                    + '<p>'
                        + `We do things a little differently here so in this stage, you’ll complete the <strong>${opportunity.jobTitle}</strong> that will give you the opportunity to tell us a bit more about yourself and showcase your talent.`
                    + '</p>'
                    + '\n'
                    + '<p>'
                        + 'In this online stage, you’ll be asked a series of interactive questions that may require answers in several formats like multiple choice, video, and text. This enables us to find out a little more about you and see how you approach certain scenarios.'
                    + '</p>'
                    + '\n'
                    + '<p>'
                        + 'It’s also a great way for you to see what it’ll be like working with us as the questions are related to the day-to-day tasks you’ll be doing on the job.'
                    + '</p>'
                    + '\n'
                    + '<p>'
                        + `Please complete this at a time and place that works for you${hasDeadline ? `<strong> in the next ${deadline} ${+deadline === 1 ? 'day' : 'days'}</strong>` : ''}. `
                        + 'And make sure you have the ability to record video on the device you choose to use.'
                    + '</p>'
                    + '\n'
                    + '<p>We look forward to finding out more about you.</p>'
                    + '\n'
                    + '<p>Good luck!</p>'
                )
        );
    } if (columnType === 'registered') {
        return (
            `<p>Thanks for your interest in the <strong>${opportunity.jobTitle}</strong>.</p>`
            + '\n'
            + '<p>Your application was received. After we review it, we’ll get back to you with next steps.</p>'
            + '\n'
            + `${companySign}`
        );
    } if (columnType === 'custom') {
        return (
            `<p>You've progressed to the next stage - ${columnName}.</p>`
            + '\n'
            + '<p>We\'ll be in touch very shortly.</p>'
            + '\n'
            + `${companySign}`
        );
    }
    return (
        `<p>Thank you for your interest in the <strong>${opportunity.jobTitle}</strong> by <strong>${company.name}</strong>.`
            + 'You have progressed to the next online stage - well done! Get ready to start your next assessment.'
        + '</p>'
        + '\n'
        + `${companySign}`
    );
};

export default theme => ({
    wrapper: {
        padding: 23
    },
    set: {
        cursor: 'pointer',
        padding: '16px 19px',
        textTransform: 'capitalize',
        marginTop: 16,
        borderRadius: 8,
        background: '#fff',
        fontWeight: 700,
        fontSize: 16,
        color: theme.palette.grey[900],
        border: `2px solid ${theme.palette.grey[300]}`
    },
    setSelected: {
        borderColor: theme.palette.blue[600]
    },
    badge: {
        width: 60,
        height: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.blue[50],
        borderRadius: 50,
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.primary.main,
        '& svg': {
            width: 16,
            height: 16,
            marginRight: 3
        }
    },
    icon: {
        padding: 0,
        backgroundColor: '#fff',
        width: 32,
        height: 32,
        marginLeft: 8,
        borderRadius: 4,
        border: `1px solid ${theme.palette.grey[300]}`,
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    }
});

import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { withTheme } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import SortIcon from 'components/icons/SortIcon';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from 'libraries/Tooltip';
import InfoTooltip from 'components/tooltips/InfoTooltip';

import {
    getAssessmentSortSettings,
    saveAssessmentSortSettings,
    removeAssessmentSortSettings,
    calculateAssessmentEmployerSortSettings
} from 'requests/AssessmentRequests';
import AssessmentEvents from 'events/AssessmentEvents';
import Button from '@mui/material/Button';
import { clone } from 'helper/commonFunctions';

import { candidateResultCtx } from 'pages/assessment/results_assessment/common/CandidateListWrapper/candidatesStore';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import { statsCtx } from 'pages/assessment/statsStore';

import GroupItem from './GroupItem';
import ScoreFilterToggle from './ScoreFilterToggle';
import CustomMenuItem from './MenuItem';

import useStyles from './styles';


const TableSort = observer(({ theme, disabled, disabledLabel }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [sortSettings, setSortSettings] = useState({});
    const [savedSortSettings, setSavedSortSettings] = useState({});
    const {
        setSortParams, setEmployerSortEnabled,
        isEmployerSortEnabled, loadCandidates
    } = useContext(candidateResultCtx);
    const { audition } = useContext(assessmentSettingsCtx);
    const { statsCount } = useContext(statsCtx);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (audition && audition.slug && !open) {
            loadAssessmentSortSettings();
        }
    }, [audition && audition.slug, open]);

    const { questionGroups, questions = [], exists, enabled, filterDisabled } = sortSettings;
    const isSortDisabled = disabled || filterDisabled;

    const dataForEvents = {
        ttId: audition ? audition.uuid : '',
        ttName: audition ? audition.name : ''
    };

    const loadAssessmentSortSettings = async () => {
        const { data } = await getAssessmentSortSettings(audition.slug);
        setSortSettings(data);
        setSavedSortSettings(data);
        setEmployerSortEnabled(data.enabled);
    };

    const handleClick = (event) => {
        if (isSortDisabled) return;
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickGroup = (index) => {
        const newGroups = clone(questionGroups);
        const { id: groupId } = newGroups[index];
        const newCheckedValue = !newGroups[index].checked;
        newGroups[index].checked = newCheckedValue;

        const newQuestions = questions.map((item) => {
            const { questionGroupId } = item;
            if (questionGroupId === groupId) {
                return { ...item, checked: newCheckedValue };
            }
            return item;
        });

        setSortSettings(oldSortSettings => ({
            ...oldSortSettings,
            questions: newQuestions,
            questionGroups: newGroups
        }));
    };

    const onClickQuestion = (questionIndex, groupIndex) => {
        const newQuestions = clone(questions);
        const newCheckedValue = !newQuestions[questionIndex].checked;
        const newGroups = clone(questionGroups);

        newQuestions[questionIndex].checked = newCheckedValue;

        if (groupIndex !== null) {
            const { id } = newGroups[groupIndex];
            const groupQuestions = newQuestions.filter(({ questionGroupId }) => questionGroupId === id);
            const hasUnchecked = groupQuestions.some(({ checked }) => !checked);
            newGroups[groupIndex].checked = !hasUnchecked;
        }

        setSortSettings(oldSortSettings => ({
            ...oldSortSettings,
            questions: newQuestions,
            questionGroups: newGroups
        }));
    };


    const renderGroups = () => {
        if (!questionGroups || !questionGroups.length) return null;
        return questionGroups.map((group, index) => (
            <GroupItem
                key={group.id}
                group={group}
                allQuestions={questions}
                classes={classes}
                onClickGroup={() => onClickGroup(index)}
                onClickQuestion={questionIndex => onClickQuestion(questionIndex, index)}
            />
        ));
    };


    const renderQuestions = (questionList) => {
        if (!questionList || !questionList.length) return null;
        return questionList.map((question, questionIndex) => (
            <CustomMenuItem
                question={question}
                key={question.id}
                classes={classes}
                onClickQuestion={() => onClickQuestion(questionIndex, null)}
            />
        ));
    };

    const updateSortSettings = async (updatedObj) => {
        setIsLoading(true);
        const { data } = await saveAssessmentSortSettings(audition.slug, updatedObj);
        setEmployerSortEnabled(data.enabled);
        setSortSettings(data);
        setSavedSortSettings(data);
        if (data.enabled) {
            await calculateSort();
        } else {
            await loadCandidates();
            setSortParams(0, false);
        }
        setIsLoading(false);
    };

    if (!audition) return null;

    const allSelected = questions.every(({ checked }) => checked);
    const someSelected = questions.some(({ checked }) => checked);

    const calculateSort = (progress = 0, totalAmount = statsCount.completed) => {
        setSortParams(Math.min(Math.round(progress * 100 / totalAmount), 100), true);

        return calculateAssessmentEmployerSortSettings(audition.slug)
            .then(async ({ data, success }) => {
                if (success && data) {
                    const { totalProcessed, total } = data;
                    if (totalProcessed < total) {
                        calculateSort(totalProcessed, total);
                    } else {
                        await loadCandidates();
                        setIsLoading(false);
                        setSortParams(0, false);
                    }
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const getCheckedIdsIntoString = (accumulator, { id, checked }) => accumulator + (checked ? id : '');

    const checkIfWasChanged = () => {
        if (!savedSortSettings.questions || !sortSettings.questions || savedSortSettings.enabled !== sortSettings.enabled) return true;
        const savedIds = savedSortSettings.questions.reduce(getCheckedIdsIntoString, '');
        const currentIds = sortSettings.questions.reduce(getCheckedIdsIntoString, '');
        return savedIds !== currentIds;
    };

    const onToggle = () => {
        setSortSettings({
            ...savedSortSettings,
            enabled: !sortSettings.enabled
        });
        const { exists: existsSort, ...other } = savedSortSettings;
        updateSortSettings({ ...other, enabled: !sortSettings.enabled });
        dataForEvents.scoreFilterEnabled = !sortSettings.enabled;
        AssessmentEvents.CUSTOM_SCORE_FILTER_CHANGED(dataForEvents);
    };


    const onSave = () => {
        const { exists: existsSort, ...other } = sortSettings;
        updateSortSettings({ ...other, enabled: true });
        AssessmentEvents.CUSTOM_SCORE_APPLIED({ ...dataForEvents,
            questionsNumber: questions.filter(question => question.checked).length,
            questionGroupsNumber: questionGroups.filter(group => group.checked).length });
        handleClose();
    };

    const onDefaultView = async () => {
        await onDelete();
        loadCandidates();

        const newQuestionGroups = clone(questionGroups);
        const newQuestions = clone(questions);
        newQuestionGroups.forEach((item) => { item.checked = true; });
        newQuestions.forEach((item) => { item.checked = true; });

        setSortSettings({
            exists: false,
            enabled: false,
            questions: newQuestions,
            questionGroups: newQuestionGroups
        });

        setSortParams(0, false);
        AssessmentEvents.CUSTOM_SCORE_REMOVED(dataForEvents);
        handleClose();
    };

    const onDelete = async () => {
        if (exists) {
            await removeAssessmentSortSettings(audition.slug);
            setSortSettings(oldSortSetting => ({
                ...oldSortSetting,
                enabled: false,
                exists: false
            }));
            setEmployerSortEnabled(false);
        }
    };

    return (
        <>
            {
                isSortDisabled ? (
                    <Tooltip
                        tooltipClass={classes.tooltip}
                        label={disabledLabel || 'Score Filters are not available for turned on group randomization with the limit number of questions.'}
                    >
                        <div
                            role="presentation"
                            className={clsx(classes.root, isSortDisabled && classes.rootDisabled)}
                        >
                            <SortIcon /> Sort
                        </div>
                    </Tooltip>
                ) : (
                    <div
                        role="presentation"
                        className={clsx(classes.root, open && classes.rootOpened, isEmployerSortEnabled && classes.rootActive, isLoading && classes.rootDisabled)}
                        onClick={handleClick}
                    >
                        <SortIcon color={(open || isEmployerSortEnabled) && theme.palette.primary.main} /> Sort
                        {
                            isEmployerSortEnabled && (
                                <div className={classes.activeWrapper}>
                                    <FiberManualRecordIcon color="primary" />
                                </div>
                            )
                        }
                    </div>
                )
            }
            <Popover
                anchorEl={anchorEl}
                keepMounted
                open={open}
                classes={{ root: classes.popover, paper: classes.menu }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Typography variant="h6" className={classes.filterHeader}>
                    <div className="u-ai--center u-dsp--f">
                        Sort
                        <InfoTooltip
                            isBlack
                            className={classes.infoTooltip}
                            classNameWrapper={classes.iconWrapper}
                            iconColor={theme.palette.grey[800]}
                            text={<>Sorting allows score recalculation and ranking based
                                on different mixes of skills and questions,
                                giving deeper candidate insights.</>}
                        />
                    </div>
                    <Button
                        color="primary"
                        className="u-txt--12 u-txt--semibold"
                        disabled={allSelected && !exists}
                        onClick={onDefaultView}
                    >
                        Reset
                    </Button>
                </Typography>
                {
                    exists && (
                        <ScoreFilterToggle
                            checked={enabled}
                            onToggle={onToggle}
                        />
                    )
                }
                {
                    (!exists || (exists && enabled)) && (
                        <>
                            <div className={classes.questionsWrapper}>
                                {
                                    (questionGroups && questionGroups.length)
                                        ? renderGroups() : renderQuestions(questions)
                                }
                            </div>
                            <div className={classes.bottomWrapper}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={onSave}
                                    disabled={isLoading || (!checkIfWasChanged() && enabled) || (allSelected && !exists) || !someSelected}
                                    className={classes.applyButton}
                                >
                                    {
                                        isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                className={classes.loader}
                                            />
                                        ) : 'Apply'
                                    }
                                </Button>
                            </div>
                        </>
                    )
                }
            </Popover>
        </>
    );
});

export default withTheme(TableSort);

import React, { useEffect } from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import clsx from 'clsx';

import useStyles from './styles';

export default function BasicDatePicker({
    defaultHiredDate, hiredDate, setHiredDate,
    pickerOpen, setPickerOpen, className,
    assignHiringDate, disabled,
    showHireBanner, setShowHireBanner
}) {
    const { textField } = useStyles();
    const today = new Date();

    useEffect(() => {
        if (pickerOpen) {
            setHiredDate(hiredDate || today);
        }
    }, [pickerOpen]);

    const handleClose = () => {
        setPickerOpen(false);
        setHiredDate(defaultHiredDate);
    };

    const handleAccept = (newValue) => {
        const formattedDate = moment(newValue.setHours(
            today.getHours(),
            today.getMinutes(),
            today.getSeconds(),
            today.getMilliseconds()
        )).format();
        assignHiringDate(formattedDate);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                showToolbar={false}
                inputFormat="MMM, dd yyyy"
                open={pickerOpen}
                onClose={handleClose}
                reduceAnimations
                disabled={disabled}
                value={hiredDate}
                minDate={new Date('2015-01-01')}
                onChange={() => {}}
                onAccept={newValue => handleAccept(newValue)}
                renderInput={(props) => {
                    const {
                        inputProps: { value }
                    } = props;
                    const formattedValue = value ? moment(value).format('MMM Do, YYYY') : '';
                    return (
                        <TextField
                            onClick={(e) => {
                                e.stopPropagation();
                                if (showHireBanner) setShowHireBanner(false);
                                if (disabled) return;
                                setPickerOpen(true);
                            }}
                            placeholder="Add hired date"
                            helperText={null}
                            className={clsx(textField, className)}
                            inputProps={{
                                value: formattedValue
                            }}
                            {...props}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
}

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import TextInput from 'libraries/TextInput';
import { changeCompanySlug, changeAuditionSlug } from 'requests/APIrequests';
import SettingsEvents from 'events/SettingsEvents';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import { getErrorMessagesToField } from 'helper/messageFunctions';
import { removeTokensFromLocalStorage, getBaseUrlForRedirect } from 'helper/commonFunctions';

const ChangeSlugDialog = ({ open, onClose, oldSlug, flashMessage, audition, company: { slug: companySlug } }) => {
    const [newSlug, setNewSlug] = useState('');
    const [slugError, setSlugError] = useState('');

    useEffect(() => {
        if (open) {
            setNewSlug(oldSlug);
            setSlugError('');
        }
    }, [open]);

    const handleCompanySlug = (e) => {
        const { value } = e.target;
        setNewSlug(value);
    };

    const updateAssessmentSlug = () => {
        changeAuditionSlug(oldSlug, newSlug)
            .then((data) => {
                if (data.success) {
                    setSlugError('');
                    AssessmentSettingsEvents.ASSESSMENT_SLUG_CHANGED({
                        ttUUID: audition.uuid,
                        ttName: audition.name,
                        oldSlug,
                        newSlug
                    });
                    flashMessage('Your assessment slug has been changed', 'done');
                    onClose();
                    window.location = `${getBaseUrlForRedirect(companySlug)}/script/settings/${newSlug}/details`;
                }
            })
            .catch(({ response }) => {
                const { data: { errors, error }, status } = response;

                if (status === 422 && errors && errors.slug) {
                    const messages = getErrorMessagesToField(errors, 'slug', 'assessment');
                    setSlugError(<div style={{ whiteSpace: 'pre' }}>{messages.join('\n')}</div>);
                } else {
                    flashMessage(error && error.message ? error.message : 'Something went wrong', 'error');
                    setSlugError('');
                }
            });
    };

    const updateCompanySlug = () => {
        changeCompanySlug(newSlug)
            .then((data) => {
                if (data.success) {
                    setSlugError('');
                    SettingsEvents.COMPANY_SLUG_UPDATED({ oldCompanySlug: oldSlug, newCompanySlug: newSlug });
                    flashMessage('Your company URL has been changed. You need to re-login.');
                    onClose();
                    removeTokensFromLocalStorage();
                    window.location = `${getBaseUrlForRedirect(companySlug)}/login?redirect=/company-account`;
                }
            })
            .catch(({ response }) => {
                const { data: { errors, error }, status } = response;

                if (status === 422 && errors && errors.slug) {
                    const messages = getErrorMessagesToField(errors, 'slug', 'company');
                    setSlugError(<div style={{ whiteSpace: 'pre' }}>{messages.join('\n')}</div>);
                } else {
                    flashMessage(error && error.message ? error.message : 'Something went wrong.');
                    setSlugError('');
                }
            });
    };

    const updateSlug = () => {
        if (newSlug.toLowerCase() === 'vervoe') {
            setSlugError(
                <div style={{ whiteSpace: 'pre' }}>
                    The use of the word Vervoe as your {audition ? 'assessment' : 'company'} slug is not authorised
                </div>
            );
            return;
        }
        if (audition) updateAssessmentSlug();
        else updateCompanySlug();
    };

    return (
        <Dialog
            maxWidth="sm"
            onClose={onClose}
            handleClose={onClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="u-pdn--lx5 u-pdn--rx5"
                        color="primary"
                        variant="contained"
                        onClick={updateSlug}
                        disabled={newSlug === oldSlug || !newSlug}
                    >
                        Change Url
                    </Button>
                </>
            )}
            titleComponent={audition ? 'Change your Assessment URL' : 'Change your Company URL'}
            open={open}
        >
            <div>
                {audition
                    ? 'Please note that once you click change, all existing links will automatically update to the new URL.'
                    : 'Please note that once you update your company URL, any application links for existing assessments will no longer work.'
                }
            </div>
            <div className="u-dsp--distribute u-mrg--tx4">
                <TextInput
                    className="u-mrg--tx0"
                    value={newSlug}
                    variant="outlined"
                    name="slug"
                    onChange={handleCompanySlug}
                    helperText={slugError}
                    isError={Boolean(slugError)}
                />
            </div>
        </Dialog>
    );
};

export default ChangeSlugDialog;

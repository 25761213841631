import React, { useEffect, useRef } from 'react';
import { withStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { subscribe, unsubscribe } from 'helper/event';

const styles = theme => ({
    typeWrapper: {
        width: 340,
        maxWidth: '100%'
    },
    summaryContent: {
        margin: 0
    },
    expandIcon: {
        color: theme.palette.primary.main
    },
    accordion: {
        marginLeft: '-50px !important',
        marginRight: '-50px !important',
        boxShadow: 'none',
        '&:before': {
            height: 2,
            backgroundColor: theme.palette.grey[200]
        },
        '&:last-child:after': {
            height: 2,
            zIndex: 10,
            backgroundColor: theme.palette.grey[200],
            content: '""',
            position: 'absolute',
            opacity: 1,
            bottom: 0,
            left: 0,
            right: 0,
            transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        },
        '&$accordionExpanded': {
            margin: 0,
            '&:after': {
                bottom: -10
            },
            '&:before': {
                content: '""',
                opacity: 1
            }
        }
    },
    accordionExpanded: {
        margin: 0
    },
    detailsRoot: {
        padding: '0 50px 20px'
    },
    summaryRoot: {
        minHeight: 70,
        padding: '0 50px',
        '&$summaryDisabled': {
            backgroundColor: '#fff',
            opacity: 1
        }
    },
    summaryDisabled: {}

});

export default withStyles(styles)(({
    header = null, content = null, classes, prefix = ''
}) => {
    const accordionRef = useRef();

    useEffect(() => {
        if (prefix !== 'advanced-') return;

        subscribe('goToView', () => {
            if (accordionRef && accordionRef.current) {
                if (accordionRef.current.getAttribute('aria-expanded') === 'false') {
                    accordionRef.current.click();
                    setTimeout(() => {
                        accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }, 200);
                } else {
                    accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        });

        return () => {
            unsubscribe('goToView');
        };
    }, []);

    return (
        <Accordion
            classes={{
                root: classes.accordion,
                expanded: classes.accordionExpanded
            }}
            id={`${prefix}question-settings`}
        >
            <AccordionSummary
                classes={{ root: classes.summaryRoot, content: classes.summaryContent }}
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                ref={accordionRef}
            >
                { header }
            </AccordionSummary>
            <AccordionDetails className={classes.detailsRoot}>
                {content}
            </AccordionDetails>
        </Accordion>
    );
});

import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { getHiringManagersList } from 'requests/CandidatesRequests';
import { checkHasCompanyFeature, clone } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';

import ManagerOption from './ManagerOption';
import EditManagerForm from './EditManagerForm';
import PlusIcon from './plus.svg';
import MinusIcon from './minus.svg';

import styles from './styles';


const HiringManagerContent = observer(({
    classes, hiringManagerFullName, setFullName,
    assessmentSlug, setManagerDeleted, selected,
    setSelected, hiringManager, setHiringManager,
    dataForEvents
}) => {
    const [editOpen, setEditOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { flashMessage, loggedUser } = useContext(appCtx);
    const [hiringManagers, setHiringManagers] = useState([]);
    const { email: loggedEmail, userSegmentTraits: { name: loggedFullName } } = loggedUser;
    const { company } = useContext(appCtx);

    const hasPostHireEmails = checkHasCompanyFeature(company, 'SEND_POST_HIRE_SURVEY_EMAIL');

    useEffect(() => {
        getHiringManagers();
    }, []);


    const getHiringManagers = () => {
        setLoading(true);
        getHiringManagersList(assessmentSlug)
            .then(({ success, data }) => {
                if (success && data) {
                    const loggedUserIndex = data.findIndex(manager => manager.email === loggedEmail);
                    if (loggedUserIndex !== -1) {
                        const currentUser = clone(data[loggedUserIndex]);
                        data.splice(loggedUserIndex, 1);
                        data.unshift(currentUser);
                    } else {
                        data.unshift({ email: loggedEmail, fullName: loggedFullName, doesNotExist: true });
                    }
                    setHiringManagers(data);

                    if (hiringManager && hiringManager.uuid) {
                        const preselected = data.find(item => hiringManager.uuid && item.uuid === hiringManager.uuid);
                        if (preselected) setSelected(preselected);
                    }
                }
            })
            .catch(({ response: { status } }) => {
                flashMessage(status === 403
                    ? 'You don’t have permission for this action. Please contact company admin.'
                    : 'Something went wrong',
                'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className={classes.optionsContainer}>
                {
                    loading && (
                        <div className="u-txt--center">
                            <CircularProgress
                                size={20}
                            />
                        </div>
                    )
                }
                {hiringManagers && hiringManagers.map((manager, index) => (
                    <ManagerOption
                        key={manager.email}
                        {...{ index,
                            manager,
                            selected,
                            setSelected,
                            hiringManagers,
                            setHiringManagers,
                            assessmentSlug,
                            getHiringManagers,
                            hiringManagerFullName,
                            setFullName,
                            setManagerDeleted,
                            setHiringManager,
                            dataForEvents
                        }}
                    />
                ))}
            </div>
            <div className="u-dsp--f u-jc--end">
                <Button
                    className={classes.secondaryNoBorderButton}
                    onClick={() => setEditOpen(!editOpen)}
                >
                    <img style={{ marginRight: 10 }} src={editOpen ? MinusIcon : PlusIcon} alt="+" />
                    Add new hiring manager
                </Button>
            </div>
            {editOpen
            && (
                <EditManagerForm
                    {...{ setEditOpen,
                        hiringManagers,
                        setHiringManagers,
                        assessmentSlug,
                        setSelected,
                        dataForEvents
                    }}
                />
            )}
            { hasPostHireEmails && (
                <div className={classes.text}>
                    Hiring managers will receive a short questionnaire after <b>120 days</b> to review the quality of hire.
                </div>
            )}
        </>
    );
});

export default withStyles(styles)(withRouter(HiringManagerContent));

export default theme => ({
    root: {
        display: 'flex'
    },
    title: {
        marginRight: 10,
        fontSize: 10,
        lineHeight: '32px',
        minWidth: 90
    },
    quickSearchItem: {
        padding: '5px 12px',
        border: '1px solid transparent',
        borderRadius: '16px',
        marginRight: 16,
        cursor: 'pointer',
        background: 'white',
        marginBottom: 13,
        '&:hover': {
            border: `1px solid ${theme.palette.grey[700]}`
        }
    },
    quickSearchItemActive: {
        background: theme.palette.blue[700],
        border: `1px solid ${theme.palette.blue[700]}`,
        color: 'white'
    }
});

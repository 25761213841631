import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import PreviewRejectionEmailButton from 'components/assessments_pages/asessment_settings_components/InstructionToCandidatesSection/components/PreviewRejectionEmailButton';
import PreviewRemindEmailButton from 'components/assessments_pages/asessment_settings_components/InstructionToCandidatesSection/components/RemindEmailFooter/PreviewRemindEmailButton';
import { switchAssessmentSendReminderEmail, switchAssessmentSendRejectionEmail } from 'requests/EmailRequests';
import { saveAssessmentSettings } from 'requests/ScriptRequests';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import Checkbox from 'libraries/Checkbox';
import CandidateFeedback from './CandidateFeedback';

const CommunicationPage = ({ setSaving, settings, audition, setAudition, setSettings, company, userRoleType }) => {
    const [notifyRejectEmail, setNotifyRejectEmail] = useState(false);
    const [notifyReminderEmail, setRemindEmail] = useState(false);
    const [candidateFeedbackEmail, setCandidateFeedbackEmail] = useState(false);
    const [includeChecked, setIncludeChecked] = useState(false);
    const { slug, sendRejectEmail, sendReminderEmail, includeRankInCandidateFeedback } = audition;
    const { daysToExpiration, expirationDate, daysToSendCandidateFeedback, sendCandidateFeedback } = settings;
    const { candidateFeedbackEnabled } = company;

    useEffect(() => {
        setNotifyRejectEmail(sendRejectEmail);
        setRemindEmail(sendReminderEmail);
        setCandidateFeedbackEmail(sendCandidateFeedback);
        setIncludeChecked(includeRankInCandidateFeedback);
    }, [slug]);

    const handleChangeRemind = async (e) => {
        const { checked } = e.target;
        setSaving(true);
        setRemindEmail(checked);
        await switchAssessmentSendReminderEmail(slug);
        setSaving(false);
        setAudition({ ...audition, sendReminderEmail: checked });
    };

    const handleChangeNotifyAboutReject = async (e) => {
        const { checked } = e.target;
        setSaving(true);
        setNotifyRejectEmail(checked);
        await switchAssessmentSendRejectionEmail(slug);
        setAudition({ ...audition, sendRejectEmail: checked });
        setSaving(false);
    };

    const handleChangeCandidateFeedback = async (checked, otherSettings) => {
        setSaving(true);
        setCandidateFeedbackEmail(checked);
        await saveAssessmentSettings(slug, { sendCandidateFeedback: checked, ...otherSettings });
        setAudition({ ...audition, sendCandidateFeedback: checked });
        updateSettings({ sendCandidateFeedback: checked, ...otherSettings });
        setSaving(false);
    };

    const updateSettings = (otherSettings) => {
        setSettings({ ...settings, ...otherSettings });
    };

    const handleIncludeCheckbox = async (e) => {
        const { checked } = e.target;
        setSaving(true);
        setIncludeChecked(checked);
        await saveAssessmentSettings(slug, { includeRankInCandidateFeedback: checked });
        setAudition({ ...audition, includeRankInCandidateFeedback: checked });
        setSaving(false);
        AssessmentSettingsEvents.FEEDBACK_RANK_INCLUDED({
            ttUUID: audition.uuid,
            ttName: audition.name,
            enabled: checked
        });
    };

    return (
        <div>
            <div className="u-dsp--distribute u-fw--wrap u-mrg--bx3">
                <div className="u-mrg--bx2">
                    <Typography variant="h5" className="u-pdn--lx1 u-mrg--bx1">Candidate Rejection</Typography>
                    <div className="u-pdn--lx1">Email candidate when rejecting?</div>
                    <PreviewRejectionEmailButton audition={audition} />
                </div>
                <Switch
                    checked={notifyRejectEmail}
                    onChange={handleChangeNotifyAboutReject}
                    value="reminder"
                />
            </div>
            <div className="u-dsp--distribute u-fw--wrap u-mrg--bx3">
                <div className="u-mrg--bx2">
                    <Typography variant="h5" className="u-pdn--lx1 u-mrg--bx1">Candidate Reminder</Typography>
                    <div className="u-pdn--lx1">Send email reminder if no activity by candidate</div>
                    <PreviewRemindEmailButton audition={audition} />
                </div>
                <Switch
                    disabled={daysToExpiration === -1 && !expirationDate}
                    checked={notifyReminderEmail && (daysToExpiration > -1 || expirationDate)}
                    onChange={handleChangeRemind}
                    value="reminder"
                />
            </div>
            <CandidateFeedback
                switchValue={candidateFeedbackEmail}
                daysToSendCandidateFeedback={daysToSendCandidateFeedback}
                handleChangeCandidateFeedback={handleChangeCandidateFeedback}
                slug={slug}
                audition={audition}
                updateSettings={updateSettings}
                isAgreement={candidateFeedbackEnabled}
                userRoleType={userRoleType}
            />
            <Checkbox
                label="Include Rank in Feedback"
                name="include_rank"
                onChange={handleIncludeCheckbox}
                checked={includeChecked}
                disabled={!candidateFeedbackEnabled}
            />
        </div>
    );
};

export default CommunicationPage;

import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'libraries/Tooltip';
import { observer } from 'mobx-react-lite';
import SaveIcon from 'components/icons/SaveIcon';

import { capitalizeEachFirstLetter } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import AssessmentEvents from 'events/AssessmentEvents';
import { modifyScreeningQuestion } from 'helper/questionSettingsFunctions';

import { assessmentSettingsCtx } from 'pages/assessment/store';

import { MARKETING_SIMULATOR, QA_SIMULATOR, SALES_SIMULATOR } from 'helper/constants';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import styles from './styles';

const AddToSavedButton = observer(({ question, activeQuestion, classes, onSave, disabled, missFeature }) => {
    const [loading, setLoading] = useState(false);
    const { flashMessage } = useContext(appCtx);
    const { audition } = useContext(assessmentSettingsCtx);
    if (!question) return null;


    const {
        answerType, type, timerEnabled,
        required, exampleAnswers = [],
        questionGroup, screeningQuestion
    } = modifyScreeningQuestion(question);

    const segmentData = {
        ttId: audition?.uuid || '',
        ttName: audition?.name || '',
        skill: questionGroup ? questionGroup.title : '',
        questionType: type,
        answerType,
        timerEnabled,
        required,
        questionNumber: activeQuestion + 1,
        correctAnswer: Boolean(exampleAnswers.length),
        questionSuggested: false
    };

    const addQuestionToQuestionBank = () => {
        setLoading(true);
        if (screeningQuestion) {
            AssessmentEvents.SCREENER_QUESTION_SAVED(segmentData);
        } else {
            AssessmentEvents.QUESTION_SAVED(segmentData);
        }

        onSave()
            .then(({ data: { companyQuestionCategory } }) => {
                if (companyQuestionCategory) {
                    const { title } = companyQuestionCategory;
                    flashMessage(`Question added to ${capitalizeEachFirstLetter(title)}`, 'done');
                } else {
                    flashMessage('Question added to Saved', 'done');
                }
            })
            .catch(() => {
                flashMessage('Something went wrong.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if ([SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR].includes(answerType)) return null;

    const getTooltip = () => {
        if (missFeature) {
            return (
                <>
                    <SubscriptionsLink label="Upgrade" className={classes.upgradeLabel} segmentLocation="diamond icon question header" /> to unlock question
                </>
            );
        }

        return 'Add to Saved Questions';
    };

    return (
        <Tooltip
            label={getTooltip()}
        >
            <div className={classes.iconWrapper}>
                {loading
                    ? <CircularProgress size={25} />
                    : (
                        <IconButton
                            onClick={addQuestionToQuestionBank}
                            disabled={disabled || loading || missFeature}
                            size="medium"
                        >
                            <SaveIcon color={disabled ? '#C7C8CC' : '#7F8084'} />
                        </IconButton>
                    )}
            </div>
        </Tooltip>
    );
});

export default withStyles(styles)(AddToSavedButton);

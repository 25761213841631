export default theme => ({
    timeInput: {
        width: 200
    },
    formLabelSwitch: {
        marginBottom: 0
    },
    formLabelSwitchError: {
        '& > span': {
            color: theme.palette.red.primary
        }
    },
    tooltip: {
        textAlign: 'center'
    },
    wrapper: {
        width: 'fit-content'
    }
});

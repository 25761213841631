import React, { useState } from 'react';

// material components
import { withStyles } from '@mui/styles';
import {
    addSimulatorTicketResponse, addSimulatorOrder,
    deleteSimulatorTicketResponse, deleteSimulatorOrder
} from 'requests/QuestionsRequests';
import DescriptionField from './DescriptionField';
import AnswersList from './AnswersList';
import OrdersList from './OrdersList';
import CustomerDetails from './CustomerDetails';

import TicketDatePicker from './TicketDatePicker';
import TicketNumberField from './TicketNumberField';
import NoteField from './NotesField';

import styles from './styles';

const SimulatorTicket = ({
    item, classes, uploadingFiles, onChange, entityIndex,
    flashMessage, setUploadingFiles, isCMS, setSaving
}) => {
    const [ticket, setTicket] = useState(item);
    const {
        companyQuestionCsSimulatorAnswers, companyQuestionCsSimulatorCustomer,
        id, ticketDateAsText, note, ticketNumber,
        companyQuestionCsSimulatorOrders
    } = ticket;

    const onChangeTicket = (name, value) => {
        setTicket({ ...ticket, [name]: value });
        onChange(entityIndex, name, value);
    };

    const addResponse = () => {
        setSaving(true);
        addSimulatorTicketResponse(id, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    const newCompanyQuestionCsSimulatorAnswers = companyQuestionCsSimulatorAnswers.slice();
                    newCompanyQuestionCsSimulatorAnswers.push(data);
                    setTicket({ ...ticket, companyQuestionCsSimulatorAnswers: newCompanyQuestionCsSimulatorAnswers });
                }
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const removeResponse = (responseIndex) => {
        setSaving(true);
        const { id: responseId } = companyQuestionCsSimulatorAnswers[responseIndex];
        deleteSimulatorTicketResponse(responseId, isCMS)
            .then(() => {
                const newCompanyQuestionCsSimulatorAnswers = companyQuestionCsSimulatorAnswers.slice();
                newCompanyQuestionCsSimulatorAnswers.splice(responseIndex, 1);
                setTicket({ ...ticket, companyQuestionCsSimulatorAnswers: newCompanyQuestionCsSimulatorAnswers });
            })
            .finally(() => {
                setSaving(false);
            });
    };


    const addOrder = () => {
        setSaving(true);
        addSimulatorOrder(id, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    const newCompanyQuestionCsSimulatorOrders = companyQuestionCsSimulatorOrders.slice();
                    newCompanyQuestionCsSimulatorOrders.push(data);
                    setTicket({ ...ticket, companyQuestionCsSimulatorOrders: newCompanyQuestionCsSimulatorOrders });
                }
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const removeOrder = (responseIndex) => {
        setSaving(true);
        const { id: responseId } = companyQuestionCsSimulatorOrders[responseIndex];
        deleteSimulatorOrder(responseId, isCMS)
            .then(() => {
                const newCompanyQuestionCsSimulatorOrders = companyQuestionCsSimulatorOrders.slice();
                newCompanyQuestionCsSimulatorOrders.splice(responseIndex, 1);
                setTicket({ ...ticket, companyQuestionCsSimulatorOrders: newCompanyQuestionCsSimulatorOrders });
            })
            .finally(() => {
                setSaving(false);
            });
    };

    return (
        <>
            <div className={classes.attachmentWrapper}>
                <DescriptionField
                    question={item}
                    isCMS={isCMS}
                    onChange={onChangeTicket}
                />
            </div>
            <AnswersList
                {...{
                    uploadingFiles,
                    flashMessage,
                    setUploadingFiles,
                    isCMS,
                    companyQuestionCsSimulatorAnswers,
                    setSaving,
                    addResponse,
                    removeResponse,
                    ticket,
                    setTicket
                }}
            />
            <CustomerDetails
                customer={companyQuestionCsSimulatorCustomer}
                onChangeTicket={onChangeTicket}
            />
            <TicketDatePicker
                value={ticketDateAsText}
                onChangeTicket={onChangeTicket}
            />
            <TicketNumberField
                value={ticketNumber}
                onChangeTicket={onChangeTicket}
            />
            <NoteField
                value={note}
                onChangeTicket={onChangeTicket}
            />
            <OrdersList
                {...{
                    flashMessage,
                    isCMS,
                    companyQuestionCsSimulatorOrders,
                    setSaving,
                    addOrder,
                    removeOrder,
                    ticket,
                    setTicket
                }}
            />
        </>
    );
};

export default withStyles(styles)(SimulatorTicket);

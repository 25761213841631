import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import InnerHeader from 'components/layout/InnerHeader';
import Layout from 'components/layout';
import PageWrapper from 'components/layout/PageWrapper';
import { getAssessmentTagsList } from 'requests/CMSRequests';
import InnerContentWrapper from '../../assessment/components/InnerContentWrapper';
import ContentLibraryTabs from '../common/ContentLibraryTabs';
import TagsHeader from './header';
import AssessmentTagsTable from './table';
import styles from './styles';


const AssessmentTags = ({ classes }) => {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [tags, setTags] = useState([]);
    const [totalTagsCount, setTotalTagsCount] = useState(null);

    useEffect(() => {
        loadTags();
        return () => {
            setPage(1);
        };
    }, []);

    const loadTags = (newPage) => {
        setLoading(true);
        getAssessmentTagsList(newPage || page)
            .then(({ data: { items, total } }) => {
                setTags(items);
                setTotalTagsCount(total);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const changePage = (newPage) => {
        loadTags(newPage);
        setPage(newPage);
    };

    return (
        <Layout>
            <InnerHeader header="Assessment Tags" />
            <PageWrapper className="u-pdn--bx5">
                <InnerContentWrapper className={classes.contentWrapper} contentClassName="u-pdn--x0">
                    <ContentLibraryTabs />
                    <div className="u-mrg--tx5 u-pdn--bx5">
                        <TagsHeader {...{ tags, setTags, loading, totalTagsCount, setTotalTagsCount }} />
                        <AssessmentTagsTable {...{ tags, setTags, page, loading, changePage, totalTagsCount, setTotalTagsCount }} />
                    </div>
                </InnerContentWrapper>
            </PageWrapper>
        </Layout>
    );
};
export default withStyles(styles)(AssessmentTags);

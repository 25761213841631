import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Select from 'libraries/Select';

const useStyles = makeStyles(theme => ({
    root: {
        width: 210,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 7
        }
    }
}));

const SkillsFilter = observer(({ onChange, options, filterCategory }) => {
    const { root } = useStyles();
    const [filter, setFilter] = useState(filterCategory);

    const handleChange = (event) => {
        const { value } = event.target;
        onChange(value);
        setFilter(value);
    };

    return (
        <div className="u-txt--right u-mrg--tx3">
            <Select
                className={root}
                value={filter}
                margin="none"
                onChange={handleChange}
                variant="outlined"
                options={options}
            />
        </div>
    );
});

export default SkillsFilter;

import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import CardEditDialog from 'pages/settings/billing/SubscriptionInfoBlock/ActionsBlock/CardEditDialog';

import CancelSubscriptionDialog from '../CancelSubscriptionDialog';
import PauseButton from './PauseButton';
import PauseBlock from './PauseBlock';

import styles from './styles';

const SubscriptionInfoBlock = ({
    companyPlan, cards, cancelAtPeriodEnd,
    classes, remainingDays,
    updateCompany: customUpdateCompany,
    subscriptionId, getCompanyPlan, period,
    isSubscriptionPaused, pausesCount, daysOfPauseLeft,
    pauseAllowed, isStripeSubscription
}) => {
    const [paymentDetailsDialogOpen, setPaymentDetailsDialogOpen] = useState(false);
    const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = useState(false);

    const handlePaymentDetailsDialog = () => {
        setPaymentDetailsDialogOpen(!paymentDetailsDialogOpen);
    };

    const handleCancelSubscriptionDialog = () => {
        setCancelSubscriptionDialogOpen(!cancelSubscriptionDialogOpen);
    };

    const { name, level, slug } = companyPlan;

    const isPremiumPlan = slug === 'premium';
    const notCustomPlan = isPremiumPlan || (name !== 'Enterprise' && level !== 1);

    const showCancel = notCustomPlan && !cancelAtPeriodEnd && isStripeSubscription;

    if (!((cards && cards.length) || showCancel)) return null;

    return (
        <>
            <div className={classes.actionWrapper}>
                {
                    cards && cards.map((card) => {
                        const { brand, last4, id } = card;
                        return (
                            <p key={id} className={classes.creditCard}>Credit
                                card: {brand} xxxx - xxxx - xxxx
                                - {last4}
                            </p>
                        );
                    })
                }
                <div className={classes.actionButtonsWrapper}>
                    {
                        Boolean(cards && cards.length) && (
                            <Button
                                className="u-txt--bold u-mrg--rx2"
                                color="primary"
                                onClick={handlePaymentDetailsDialog}
                            >
                                Update your credit card
                            </Button>
                        )
                    }
                    {
                        period === 'month' && notCustomPlan && (
                            <PauseButton
                                pauseAllowed={pauseAllowed}
                                getCompanyPlan={getCompanyPlan}
                            />
                        )
                    }
                    {
                        showCancel && (
                            <Button
                                className="u-txt--bold"
                                color="primary"
                                onClick={handleCancelSubscriptionDialog}
                            >
                                Cancel subscription
                            </Button>
                        )
                    }
                </div>
            </div>
            { isSubscriptionPaused && (
                <PauseBlock
                    pausesCount={pausesCount}
                    showCancel={showCancel}
                    getCompanyPlan={getCompanyPlan}
                    companyPlan={companyPlan}
                    daysOfPauseLeft={daysOfPauseLeft}
                    handleCancelSubscriptionDialog={handleCancelSubscriptionDialog}
                />
            )}

            <CardEditDialog
                open={paymentDetailsDialogOpen}
                onClose={handlePaymentDetailsDialog}
                updateCompany={customUpdateCompany}
            />

            <CancelSubscriptionDialog
                open={cancelSubscriptionDialogOpen}
                subscriptionId={subscriptionId}
                getCompanyPlan={getCompanyPlan}
                remainingDays={remainingDays}
                period={period}
                pausesCount={pausesCount}
                isSubscriptionPaused={isSubscriptionPaused}
                onClose={handleCancelSubscriptionDialog}
            />
        </>
    );
};

export default withStyles(styles)(SubscriptionInfoBlock);

export default (() => ({
    wrapper: {
        display: 'none',
        right: 75,
        top: 9,
        position: 'absolute'
    },
    icon: {
        padding: 0,
        backgroundColor: '#fff',
        width: 32,
        height: 32,
        marginLeft: 5,
        borderRadius: 4
    }
}));

import React from 'react';

const InsightsIcon = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.604 20V10" stroke="#6B6B70" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.604 20L12.604 4" stroke="#6B6B70" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.604 20L6.604 14" stroke="#6B6B70" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default InsightsIcon;

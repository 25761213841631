import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    tableRow: {
        cursor: 'pointer'
    },
    statusDate: {
        color: theme.palette.grey[900],
        fontWeight: 600,
        fontSize: 14,
        marginLeft: 5,
        whiteSpace: 'nowrap'
    },
    rankCell: {
        background: theme.palette.grey[100],
        borderRight: `1px solid ${theme.palette.grey[300]}`
    },
    tableCell: {
        borderColor: `${theme.palette.grey[300]}!important`
    }
}));

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import CandidateListWrapper from '../common/CandidateListWrapper';
import AssessmentStats from '../../components/AssessmentStats';

import CandidatesList from './CandidatesList';
import { statsCtx } from '../../statsStore';
import { candidateResultCtx } from '../common/CandidateListWrapper/candidatesStore';

const CandidatesListPage = observer(({ audition }) => {
    const {
        statsArr, loading, activeLearningStatistic,
        requestedMoreTimeCount, extensionRequestedCandidates,
        setExtensionRequestedCandidates, loadExtensionsRequests,
        loadAssessmentStats
    } = useContext(statsCtx);

    const { isLoading: isLoadingCandidateResults } = useContext(candidateResultCtx);

    return (
        <CandidateListWrapper>
            <AssessmentStats
                statsArr={statsArr}
                audition={audition}
                loading={loading || isLoadingCandidateResults}
                activeLearningStatistic={activeLearningStatistic}
                requestedMoreTimeCount={requestedMoreTimeCount}


                onExtendRequestSuccess={loadAssessmentStats}
                extensionRequestedCandidates={extensionRequestedCandidates}
                setExtensionRequestedCandidates={setExtensionRequestedCandidates}
                loadExtensionsRequests={loadExtensionsRequests}
            />
            <CandidatesList />
        </CandidateListWrapper>
    );
});

export default CandidatesListPage;

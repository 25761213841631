import React from 'react';
import clsx from 'clsx';
import TooltipBlack from 'libraries/Tooltip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    tooltipClass: {
        textAlign: 'center',
        textTransform: props => props.capitalize
    },
    text: {
        display: props => (props.rows > 1 ? '-webkit-box' : 'block'),
        whiteSpace: props => (props.rows > 1 ? 'normal' : 'nowrap'),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: props => props.rows,
        WebkitBoxOrient: 'vertical',
        maxWidth: props => props.width,
        textTransform: props => props.capitalize
    }
}));

const TruncateWithTooltip = ({ text, width = 226, rows = 1, className = '', tooltipClassName = '', capitalize = false, ...other }) => {
    const capitalizeStr = capitalize ? 'capitalize' : 'none';
    const classes = useStyles({ width, rows, capitalize: capitalizeStr });
    if (!text) return null;
    if (text.length < width / 8) return <span className={clsx(classes.text, className)}>{text}</span>;
    return (
        <TooltipBlack
            label={text}
            tooltipClass={clsx(classes.tooltipClass, tooltipClassName)}
            {...other}
        >
            <span className={clsx(classes.text, className)}>{text}</span>
        </TooltipBlack>
    );
};

export default TruncateWithTooltip;

export default () => ({
    newGroupButton: {
        padding: 0,
        backgroundColor: '#fff',
        fontWeight: 700,
        position: 'absolute',
        right: 0,
        top: 5,
        height: 25,
        bottom: 0
    },
    actionButton: {
        marginLeft: 8,
        fontWeight: 600,
        letterSpacing: '0.2px'
    },
    actionDialogButton: {
        fontWeight: 700,
        paddingLeft: 50,
        paddingRight: 50
    }
});

export default theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '15px'
    },
    value: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        color: theme.palette.grey[900],
        fontSize: 16,
        fontWeight: 700,
        marginLeft: 9
    },
    valueLabel: {
        marginLeft: 4
    },
    label: {
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        color: theme.palette.grey[600],
        fontSize: 10,
        fontWeight: 700,
        lineHeight: '18px'
    },
    countLabel: {
        color: theme.palette.grey[600],
        fontSize: 12,
        fontWeight: 400,
        marginLeft: 9
    }
});

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';
import EditPanelSection from 'components/assessments_pages/asessment_settings_components/EditPanelSection';


const PopularSettings = ({
    audition: { popular },
    changeAssessmentSettings
}) => {
    const [isPopular, setIsPopular] = useState(popular);

    const handleSwitch = (e) => {
        const { checked } = e.target;
        setIsPopular(checked);
        changeAssessmentSettings({ popular: checked });
    };

    return (
        <EditPanelSection
            header={(
                <>
                    Popular Assessment
                    <Switch
                        checked={isPopular}
                        onChange={handleSwitch}
                    />
                </>
            )}
        >
            Add to popular assessments
        </EditPanelSection>
    );
};

export default withRouter(PopularSettings);

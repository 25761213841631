import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextInput from 'libraries/TextInput';
import * as qs from 'query-string';
import { checkHasUserPermission } from 'helper/commonFunctions';
import GeneralEvents from 'events/GeneralEvents';
import PostItem from 'components/candidate_cards/PostItem';
import CommentsIcon from 'components/icons/CommentsIcon';

import { appCtx } from 'components/appStore';

import styles from './styles';
import AuthDialog from '../../AuthDialog';

const MAX_LENGTH = 500;

const CommentsInfo = observer(({
    user: { candidateUuid, candidateId, id, fullName },
    location, classes, audition,
    opportunitySlug, loadCandidatePosts,
    propPublic, authorizeGuest, addCandidateComment
}) => {
    const [formValue, setFormValue] = useState('');
    const [errorValue, setErrorValue] = useState('');
    const [blockPosting, setBlockPosting] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [posts, setCandidatePosts] = useState([]);
    const [postPermissions, setPostPermissions] = useState(null);
    const [dialogAuthOpen, setDialogAuthOpen] = useState(false);
    const [notSavedPost, setNotSavedPost] = useState(null);

    const { flashMessage, company } = useContext(appCtx);
    const { uuid: ttId, name: ttName } = audition;

    const userId = candidateId || id;

    useEffect(() => {
        setCandidatePosts([]);
        getUserPosts();
        setErrorValue('');
        setFormValue('');
    }, [userId, opportunitySlug]);

    const getUserPosts = () => {
        if (opportunitySlug && userId && loadCandidatePosts) {
            setLoading(true);
            return loadCandidatePosts(userId, opportunitySlug)
                .then((response) => {
                    const { success, data: { candidatePosts: newCandidatePosts, permissions } } = response;
                    if (success) {
                        setCandidatePosts(newCandidatePosts);
                        setPostPermissions(permissions);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleChangeContent = (e) => {
        const { value } = e.target;
        let newErrorValue = '';
        if (value.trim().length < 1) {
            newErrorValue = 'Comment content field is required.';
        } else if (value.length > MAX_LENGTH) {
            newErrorValue = 'Comment content cannot be longer than 500 characters length';
        }

        setFormValue(value);
        setErrorValue(newErrorValue);
    };

    const handlePostSubmit = (e) => {
        e.preventDefault();
        if (!formValue) {
            setErrorValue('Required field.');
            return;
        }

        if (propPublic && !localStorage.getItem('token')) {
            openAuthorisationDialog(formValue);
            return;
        }

        if (!propPublic) {
            GeneralEvents.COMMENT_POSTED({
                candidateId: candidateUuid,
                ttId,
                ttName
            });
        }

        setBlockPosting(true);
        addComment(formValue);
    };

    const updateCandidatePosts = (post) => {
        const newPosts = JSON.parse(JSON.stringify(posts));
        newPosts.unshift(post);
        setCandidatePosts(newPosts);
    };


    const openAuthorisationDialog = (post) => {
        setNotSavedPost(post);
        setDialogAuthOpen(true);
    };


    const addComment = (value) => {
        const parsed = qs.parse(location.search);
        const { opportunity } = parsed;
        return addCandidateComment(value, userId, opportunity || opportunitySlug)
            .then((response) => {
                const {
                    success,
                    data: {
                        candidatePost,
                        message,
                        errorForm
                    }
                } = response;
                setBlockPosting(false);
                if (success) {
                    updateCandidatePosts(candidatePost);
                    clearFormField();
                    flashMessage('Information saved and comment submitted.', '');
                } else {
                    flashMessage(message);
                    setErrorValue(errorForm);
                }
            })
            .catch(() => {
                flashMessage('Something went wrong.');
            });
    };


    const handleCancelSubmit = (e) => {
        e.preventDefault();
        clearFormField();
    };

    const clearFormField = () => {
        setFormValue('');
        setErrorValue('');
    };

    const closeAuthorisationDialog = () => {
        setDialogAuthOpen(false);
    };

    const renderPosts = () => {
        if (!posts.length) return null;
        const commentsArray = posts.map((comment, index) => (
            <PostItem key={index} post={comment} />
        ));

        return (
            <div>
                {commentsArray}
            </div>
        );
    };

    if (!loadCandidatePosts || !userId || !opportunitySlug) return null;

    const comment = checkHasUserPermission(company, postPermissions, 'comment');
    return (
        <>
            <div className={classes.blockWrapper}>
                <Typography variant="h5">Notes and Comments</Typography>
                {
                    isLoading ? (
                        <div className="u-txt--center">
                            <CircularProgress
                                size={22}
                                thickness={3}
                            />
                        </div>
                    ) : (
                        <>
                            {
                                !posts.length ? (
                                    <div className={classes.firstCommentWrapper}>
                                        <CommentsIcon width={30} height={30} className="u-mrg--bx1 u-mrg--tx3" />
                                        <Typography variant="h6">Be the first to comment</Typography>
                                    </div>
                                ) : renderPosts()
                            }
                        </>
                    )
                }
            </div>
            <div className={classes.blockWrapper}>
                <Typography variant="h5">Add a comment</Typography>
                <div>
                    <TextInput
                        maxLength={MAX_LENGTH}
                        minLength={1}
                        hasCounter
                        placeholder="Enter your thoughts here"
                        fullWidth
                        multiline
                        disabled={!comment}
                        name="comment_content"
                        value={formValue}
                        onChange={handleChangeContent}
                        referent="contentError"
                        variant="outlined"
                        isError={Boolean(errorValue)}
                        helperText={errorValue}
                    />
                    {
                        formValue && (
                            <div className="u-txt--right  u-mrg--tx3">
                                <Button
                                    className={classes.cancelButton}
                                    color="primary"
                                    onClick={handleCancelSubmit}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={handlePostSubmit}
                                    disabled={blockPosting}
                                    variant="contained"
                                >
                                    Post
                                </Button>
                            </div>
                        )
                    }
                </div>
            </div>
            <AuthDialog
                open={dialogAuthOpen}
                comment
                notSavedValue={notSavedPost}
                saveValue={addComment}
                closeDialog={closeAuthorisationDialog}
                candidateName={fullName}
                flashMessage={flashMessage}
                authorizeGuest={authorizeGuest}
            />
        </>
    );
});

export default withStyles(styles)(withRouter(CommentsInfo));

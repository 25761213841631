import React, { useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRow from '@mui/material/TableRow';

import TableCellCustom from 'components/table_components/table_cell';

import ExtentionButtons from '../ExtentionButtons';

import styles from './styles';


const ExtensionsRequestsRow = observer(({
    candidate, disabled, setDisabled,
    onSingleRequestSuccess, audition,
    classes
}) => {
    const [openRow, setOpenRow] = useState(false);
    const {
        resetReasonType, resetReason,
        name,
        userAuditionId, candidateUuid
    } = candidate;


    const handleRow = () => {
        if (!resetReason) return;
        setOpenRow(!openRow);
    };

    if (!audition) return null;

    return (
        <TableRow
            onClick={handleRow}
            classes={{
                root: clsx(classes.tableRow, openRow && classes.opened),
                hover: classes.hoverable
            }}
            hover={Boolean(resetReason && !openRow)}
        >
            <TableCellCustom className={classes.tableCell} align="left">
                <p className="u-txt--bold u-mrg--bx0">{name}</p>
            </TableCellCustom>
            <TableCellCustom align="left" className={clsx(classes.tableCell, classes.reasonColumn)}>
                <div className="u-dsp--distribute">
                    {resetReasonType || '-'}
                    { Boolean(resetReason) && (
                        <ExpandMoreIcon
                            className={clsx(classes.icon, openRow && classes.openedIcon)}
                        />
                    )}
                </div>
                {Boolean(openRow) && <p className="u-mrg--tx1 u-mrg--bx0">{resetReason}</p>}
            </TableCellCustom>
            <TableCellCustom align="right" className={clsx(classes.tableCell, classes.actionsColumn)}>
                <ExtentionButtons
                    disabled={disabled}
                    audition={audition}
                    setDisabled={setDisabled}
                    userAuditionId={userAuditionId}
                    candidateUuid={candidateUuid}
                    onExtendRequestSuccess={onSingleRequestSuccess}
                />
            </TableCellCustom>
        </TableRow>
    );
});

export default withStyles(styles)(ExtensionsRequestsRow);

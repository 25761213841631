export default theme => ({
    roleSelect: {
        width: 128
    },
    fullName: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.grey[900]
    },
    roleWrapper: {
        borderRadius: 4,
        padding: '9px 16px',
        border: `2px solid ${theme.palette.grey[300]}`,
        color: theme.palette.grey[500],
        fontWeight: 600,
        fontSize: 12,
        width: 128,
        textTransform: 'capitalize'
    },
    selectedMenu: {
        padding: '11px 19px',
        fontSize: 12
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0',
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '&:last-child': {
            marginBottom: -16
        }
    },
    removeButton: {
        padding: 5,
        marginRight: 10
    }
});

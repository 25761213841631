import React from 'react';
import { withTheme } from '@mui/styles';


const FolderIcon = ({ theme, color: propsColor, ...other }) => {
    const color = propsColor || theme.palette.grey[500];
    return (
        <svg width="20" height="18" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M1.5 7.15L1.5 8.44231L1.5 10.75C1.5 11.8546 2.39543 12.75 3.5 12.75L14.5 12.75C15.6046 12.75 16.5 11.8546 16.5 10.75L16.5 4.25C16.5 3.14543 15.6046 2.25 14.5 2.25L10.3235 2.25L8.25 2.25" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.5 7.5L1.5 2.75C1.5 1.64543 2.39543 0.75 3.5 0.75L6.75 0.75L8.25 2.25" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default withTheme(FolderIcon);

export default theme => ({
    wrapper: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[200],
        [theme.breakpoints.up('sm')]: {
            flexBasis: '45%'
        }
    },
    header: {
        fontSize: 20,
        fontWeight: 700,
        padding: '23px 40px',
        color: theme.palette.grey[900],
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    content: {
        padding: '0 40px',
        '& > div:first-child': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        }
    },
    planSection: {
        padding: '23px 0 12px'
    },
    totalWrapper: {
        display: 'flex',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '35px 40px 50px',
        fontSize: 16,
        lineHeight: 1.2,
        fontWeight: 600
    },
    totalLabel: {
        lineHeight: 1.5,
        fontWeight: 700
    },
    totalValue: {
        marginLeft: 7,
        fontWeight: 700,
        fontSize: 26
    }
});

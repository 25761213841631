import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';

const styles = {
    progress: {
        color: '#fff'
    },
    submitButton: {
        minWidth: 124,
        paddingLeft: 40,
        paddingRight: 40
    },
    cancelButton: {
        fontWeight: 700,
        paddingLeft: 30,
        paddingRight: 30
    }
};

const RejectDialog = observer(({ handleClose, classes, open, removeAutoProgress }) => {
    const [pending, setPending] = useState(false);

    const RemoveAP = async () => {
        setPending(true);
        removeAutoProgress()
            .finally(() => {
                setPending(false);
                handleClose();
            });
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={handleClose}
            titleComponent="Remove Auto Progress Link"
            handleClose={handleClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className={classes.cancelButton}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={RemoveAP}
                        disabled={pending}
                        className={classes.submitButton}
                    >
                        {
                            pending ? (
                                <CircularProgress
                                    size={22}
                                    className={classes.progress}
                                />
                            ) : 'Delete Auto Progress'
                        }
                    </Button>
                </>
            )}
        >
            <p>Are you sure you want to remove this auto progress link? Candidate data will not be lost and this action can be re-enabled at any time.</p>
        </Dialog>
    );
});

export default withStyles(styles)(RejectDialog);

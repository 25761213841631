import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles, withTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import styles from './styles';

const filterPages = (pages, total) => pages.filter(page => page <= total);

const getVisiblePages = (page, total) => {
    if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
    } if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5, total];
};

const BoardPagination = ({ theme, classes, currentPage = null, totalPages, handleChangePage }) => {
    const [visiblePages, setVisiblePages] = useState(getVisiblePages(currentPage, totalPages));

    useEffect(() => {
        setVisiblePages(getVisiblePages(currentPage, totalPages));
    }, [totalPages, currentPage]);

    const changePage = (page) => {
        if (page === currentPage) {
            return;
        }

        const newVisiblePages = getVisiblePages(page, totalPages);

        setVisiblePages(filterPages(newVisiblePages, totalPages));

        if (handleChangePage) handleChangePage(page);
    };

    const handleBackButtonClick = () => {
        if (currentPage === 1) return;
        changePage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
        if (currentPage === totalPages) return;
        changePage(currentPage + 1);
    };

    if (totalPages <= 1) return null;

    return (
        <div className={classes.wrapper}>
            <IconButton
                className={classes.navArrows}
                onClick={handleBackButtonClick}
                aria-label="previous page"
                size="large">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            {
                visiblePages.map((page, index, array) => (
                    <div key={index}>
                        {
                            array[index - 1] + 2 < page && (
                                <IconButton
                                    key="dots"
                                    className={clsx(classes.item, currentPage === page && classes.itemActive)}
                                    onClick={() => changePage(page - 1)}
                                    size="large">
                                    ...
                                </IconButton>
                            )
                        }
                        <IconButton
                            key={index}
                            className={clsx(classes.item, currentPage === page && classes.itemActive)}
                            onClick={() => changePage(page)}
                            size="large">
                            {page}
                        </IconButton>
                    </div>
                ))
            }
            <IconButton
                className={classes.navArrows}
                onClick={handleNextButtonClick}
                aria-label="next page"
                size="large">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
        </div>
    );
};

export default withTheme(withStyles(styles)(BoardPagination));

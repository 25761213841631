import React, { Fragment } from 'react';

// material components
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';

// local files and components
import { checkPluralNew } from 'helper/commonFunctions';
import clsx from 'clsx';
import Icon from './icon.svg';

import styles from './styles';

const ResultHeader = ({
    cases,
    runTest,
    open,
    classes,
    handleDrawerOpen,
    runningTest
}) => {
    const passed = cases.some(({ pass }) => typeof pass === 'boolean');
    let text = null;

    if (passed) {
        const testPassed = cases.reduce((count, { pass }) => (pass && (count += 1)) || count, 0);
        text = `${testPassed}/${cases.length} test ${checkPluralNew(testPassed, 'case')} passed`;
    } else {
        text = `${cases.length} test ${checkPluralNew(cases.length, 'case', 'cases')}`;
    }

    return (
        <div className={classes.wrapperPanel}>
            <div className={clsx(classes.casesFunctionality, open && classes.casesFunctionalityOpen)}>
                <p className={classes.cases}>
                    {text}
                </p>
            </div>
            <div className={classes.wrapperTestSection}>
                <Button
                    classes={{
                        root: classes.runBtn,
                        label: runningTest && classes.runningBtn
                    }}
                    variant="outlined"
                    onClick={() => {
                        runTest();
                        handleDrawerOpen();
                    }}
                    disabled={runningTest || !cases.length}
                >
                    {
                        runningTest ? (
                            <Fragment>
                                <span className="u-mrg--lx1">Running tests...</span>
                                <CircularProgress
                                    size={24}
                                    thickness={2}
                                    classes={{ root: classes.loader }}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <img className="u-mrg--rx1" src={Icon} alt="" />
                                Run Tests
                            </Fragment>
                        )
                    }
                </Button>
            </div>
        </div>
    );
};

export default withStyles(styles)(ResultHeader);

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextInput from 'libraries/TextInput';
import { retrieveCoupon } from 'requests/SubscriptionRequests';
import PlusIcon from './plus.svg';
import MinusIcon from './minus.svg';

const styles = {
    showButton: {
        fontWeight: 700,
        marginLeft: -8,
        marginBottom: 5,
        height: 40,
        '& img': {
            marginRight: 5
        }
    },
    textField: {
        marginTop: 10,
        marginBottom: 10,
        marginRight: 20
    },
    textFieldInput: {
        backgroundColor: '#fff'
    },
    validateButton: {
        fontWeight: 700,
        marginBottom: 18
    }
};

const Coupon = ({ applyCoupon, flashMessage, className = '', defaultCouponString = '', classes }) => {
    const [coupon, setCoupon] = useState(defaultCouponString);
    const [showCoupon, setShowCoupon] = useState(Boolean(defaultCouponString));
    const [chargedWithCoupon, setChargedWithCoupon] = useState(false);
    const [showCouponValidation, setShowCouponValidation] = useState(false);

    useEffect(() => {
        if (defaultCouponString) refreshCoupon();
    }, []);

    const handleChangeCoupon = (e) => {
        setCoupon(e.target.value);
        setShowCouponValidation('');
        setChargedWithCoupon(false);
    };

    const showCouponField = () => {
        setShowCoupon(!showCoupon);
    };

    const refreshCoupon = () => {
        retrieveCoupon(coupon)
            .then(({ success, coupon: dataCoupon, message }) => {
                if (success && dataCoupon) {
                    if (dataCoupon.amount_off || dataCoupon.percent_off) {
                        setChargedWithCoupon(true);
                        setShowCouponValidation('Your coupon was applied successfully');
                        applyCoupon(dataCoupon);
                    } else {
                        setChargedWithCoupon(false);
                        setShowCouponValidation('Your coupon is invalid');
                        applyCoupon(false);
                        flashMessage('Coupon with different currency.');
                    }
                } else {
                    setChargedWithCoupon(false);
                    setShowCouponValidation('Your coupon is invalid');
                    applyCoupon(false);
                    flashMessage(message);
                }
            });
    };

    return (
        <div className={className}>
            <Button
                color="primary"
                className={classes.showButton}
                onClick={showCouponField}
            >
                <img src={showCoupon ? MinusIcon : PlusIcon} alt="+" />
                Coupon Code
            </Button>
            {
                showCoupon && (
                    <div className="u-dsp--distribute">
                        <TextInput
                            className={classes.textField}
                            InputProps={{
                                className: classes.textFieldInput
                            }}
                            label="Enter coupon code"
                            variant="outlined"
                            value={coupon}
                            isError={Boolean(!chargedWithCoupon && showCouponValidation)}
                            helperText={showCouponValidation || ' '}
                            onChange={handleChangeCoupon}
                        />
                        <Button
                            color="primary"
                            className={classes.validateButton}
                            disabled={!coupon}
                            onClick={refreshCoupon}
                        >
                            Validate
                        </Button>
                    </div>
                )
            }
        </div>
    );
};

export default withStyles(styles)(Coupon);

import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';

const styles = () => ({
    submitButton: {
        minWidth: 124,
        paddingLeft: 40,
        paddingRight: 40
    },
    cancelButton: {
        fontWeight: 700,
        paddingLeft: 30,
        paddingRight: 30
    }
});

const DeactivateLinkedAssessmentDialog = observer(({ open, onClose, classes, audition, location, history }) => {
    const { linkedSlugs } = audition;

    const handleGoToSettings = () => {
        history.push({ pathname: `/script/settings/${linkedSlugs[0]}`, state: { from: location.pathname } });
        onClose();
    };


    if (!linkedSlugs || !linkedSlugs.length) return null;

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}
            titleComponent="Deactivate linked assessment"
            handleClose={onClose}
            actionComponent={(
                    <>
                        <Button
                            color="primary"
                            className={classes.cancelButton}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleGoToSettings}
                            className={classes.submitButton}
                        >
                            Go to assessment settings
                        </Button>
                    </>
            )}
        >
            <p>This action can't be performed. The assessment is part of an autoprogress. Please unlink your assessment before deactivating.</p>
        </Dialog>
    );
});

export default withRouter(withStyles(styles)(DeactivateLinkedAssessmentDialog));

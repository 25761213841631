import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';
import { loadAssessmentSettings, saveAssessmentSettings } from 'requests/ScriptRequests';
import { appCtx } from 'components/appStore';
import { assessmentSettingsCtx } from '../../../store';

import Content from './Content';
import SideBar from './SideBar';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        position: 'relative',
        overflow: 'auto',
        minHeight: 400,
        width: '100%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'block'
        }
    }
}));

const AssessmentSettingsMain = observer(({ routes, setSaving, match }) => {
    const classes = useStyles();
    const { scriptSlug } = match.params;
    const [settings, setSettings] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const { audition, setAudition, loadEditScript: loadAudition } = useContext(assessmentSettingsCtx);
    const { company, loggedUser: { roleType: userRoleType } } = useContext(appCtx);

    useEffect(() => {
        loadAudition();
        loadEditScript();
    }, []);

    const loadEditScript = () => {
        setLoading(true);
        loadAssessmentSettings(scriptSlug)
            .then(({ data, success }) => {
                if (success) {
                    setSettings(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const changeAssessmentSettings = (form, callback) => {
        setSaving(true);
        return saveAssessmentSettings(scriptSlug, form)
            .then((response) => {
                const { success, data } = response;
                if (success && data) {
                    setSettings(data);
                    setAudition({ ...audition, ...data });
                    if (callback) {
                        callback();
                    }
                }
                setSaving(false);
                return response;
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const childProps = {
        isLoading,
        changeAssessmentSettings,
        setSaving,
        audition,
        setAudition,
        settings,
        setSettings,
        loadEditScript: loadAudition,
        company,
        userRoleType
    };

    return (
        <div className={classes.wrapper}>
            <SideBar routes={routes} {...childProps} />
            <Content routes={routes} {...childProps} />
        </div>
    );
});

export default withRouter(AssessmentSettingsMain);

import React from 'react';
import { withStyles } from '@mui/styles';
import OptimizedIcon from './optimizedIcon.svg';

const styles = theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.palette.purple[900],
        padding: '25px 50px',
        textAlign: 'center',
        color: '#ffffff',
        minHeight: 200,
        [theme.breakpoints.down('md')]: {
            minHeight: 150
        }
    },
    mainText: {
        fontWeight: 700,
        fontSize: 18
    },
    secondaryText: {
        fontSize: 12
    }
});

const OptimizedResultStatus = ({ classes }) => (
    <div className={classes.container}>
        <img src={OptimizedIcon} alt="Optimized" />
        <div className={classes.mainText}>
            Assessment Optimized!
        </div>
        <div className={classes.secondaryText}>
            <b>Personalized AI is enabled.</b> Further grading improves scoring for your preferred answers, but is not required.
        </div>
    </div>
);

export default withStyles(styles)(OptimizedResultStatus);

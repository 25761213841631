import React from 'react';

const CloseIconWithRoundCorners = ({ className, onClick }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        className={className}
        onClick={onClick}
    >
        <defs>
            <path id="cross-desctop-1" d="M8 6.386L14.052.334a1.141 1.141 0 0 1 1.614 1.614L9.614 8l6.052 6.052a1.141 1.141 0 1 1-1.614 1.614L8 9.614l-6.052 6.052a1.141 1.141 0 1 1-1.614-1.614L6.386 8 .334 1.948A1.141 1.141 0 0 1 1.948.334L8 6.386z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="cross-desctop-2" fill="#fff">
                <use xlinkHref="#cross-desctop-1" />
            </mask>
            <use fill="#000" fillRule="nonzero" xlinkHref="#cross-desctop-1" />
            <g fill="#BBB" mask="url(#cross-desctop-2)">
                <path d="M0 0h16v16H0z" />
            </g>
        </g>
    </svg>
);

export default CloseIconWithRoundCorners;

export default theme => ({
    sendButton: {
        width: 130,
        height: 52,
        whiteSpace: 'nowrap',
        padding: '14px 52px',
        [theme.breakpoints.down('sm')]: {
            width: '47%'
        }
    },
    memberSelect: {
        width: 260,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 12
        }
    },
    roleSelect: {
        width: 135,
        [theme.breakpoints.down('sm')]: {
            width: '47%',
            marginRight: '6%'
        }
    },
    label: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.grey[600]
    },
    actionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15,
        paddingTop: 5,
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    loader: {
        color: '#fff'
    }
});

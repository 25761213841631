export default theme => ({
    questionTimerWrapper: {
        display: 'flex',
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 700,
        marginBottom: 0,
        alignItems: 'center',
        color: theme.palette.grey[600]
    },
    timerIcon: {
        marginLeft: '-2px',
        marginRight: '2px'
    }
});

export default theme => ({
    input: {
        margin: '0 5px'
    },
    pointsInput: {
        maxWidth: 135
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.grey[700],
        fontWeight: 700,
        fontSize: 12,
        paddingLeft: 45,
        marginBottom: 20,
        marginTop: 5
    },
    iconButton: {
        padding: 5,
        marginLeft: 5
    },
    icon: {
        color: theme.palette.grey[700],
        width: 25,
        height: 25
    },
    attachmentWrapper: {
        margin: '0 6px',
        width: '100%'
    },
    attachmentWrapperWithPoints: {
        width: 'calc(100% - 200px)'
    }
});

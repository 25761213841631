import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    tag: {
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.grey[300],
        marginRight: 12,
        marginBottom: 12,
        padding: '5px 15px 6px',
        fontWeight: 700,
        fontSize: 11,
        textTransform: 'capitalize',
        borderRadius: 4,
        maxWidth: 150,
        position: 'relative'
    },
    closeButton: {
        padding: 0,
        position: 'absolute',
        top: 6,
        right: 6
    },
    closeIcon: {
        width: 13,
        height: 13,
        color: theme.palette.grey[500]
    },
    noTagsLabel: {
        fontSize: 13,
        fontWeight: 700,
        minHeight: 30,
        display: 'flex',
        alignItems: 'center'
    }
}));

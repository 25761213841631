export default theme => ({
    switchFormLabel: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.grey[900]
    },
    description: {
        lineHeight: '24px',
        marginBottom: 30,
        paddingBottom: 20,
        color: theme.palette.grey[900],
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    innerWrapper: {
        maxWidth: 500
    }
});

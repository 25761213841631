import IconButton from '@mui/material/IconButton';
import React from 'react';
import { withStyles } from '@mui/styles';

const styles = () => ({
    button: {
        marginRight: 6
    }
});

const ClearCross = ({ classes, onClear }) => (
    <IconButton className={classes.button} onClick={onClear} size="large">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M11 1L1 11" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 1L11 11" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </IconButton>
);

export default withStyles(styles)(ClearCross);

export default () => ({
    innerTabs: {
        width: '100%',
        margin: 0
    },
    content: {
        overflow: 'auto',
        padding: '0 30px',
        minHeight: 250
    }
});

export default theme => ({
    popoverPaper: {
        minWidth: 285,
        fontSize: 14
    },
    organiseIcon: {
        padding: 6
    },
    tooltip: {
        fontSize: 12,
        textAlign: 'center',
        maxWidth: 210
    },
    popoverRoot: {
        padding: 0,
        width: 280
    },
    popoverHeader: {
        padding: '11px 19px 5px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    popoverContent: {
        position: 'relative',
        padding: '15px 2px 12px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    addSection: {
        padding: '15px 19px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    footer: {
        fontWeight: 600,
        fontSize: 12,
        padding: '11px 18px 14px',
        backgroundColor: theme.palette.grey[200],
        '& a': {
            color: theme.palette.primary.main
        }
    },
    skillInput: {
        margin: 0,
        marginRight: 10,
        '& input': {
            padding: 15
        }
    },
    loaderWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    iconStar: {
        borderRadius: 4,
        padding: 6,
        marginRight: 5
    },
    emptyTags: {
        padding: '15px 0',
        textAlign: 'center'
    },
    list: {
        maxHeight: 210,
        overflow: 'auto'
    }
});

export default theme => ({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    gradingIcon: {
        position: 'absolute',
        right: -1,
        top: -18
    },
    gradingIconTooltip: {
        bottom: -20,
        fontSize: 12,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        color: theme.palette.yellow[900]
    },
    comingSoon: {
        background: theme.palette.grey[400],
        borderRadius: 18,
        color: '#ffffff',
        marginLeft: 10,
        padding: '0 10px',
        height: 22,
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: 10,
        fontWeight: 700
    },
    insights: {
        display: 'flex',
        alignItems: 'center'
    },
    atsStepperLabel: {
        width: 145
    }
});

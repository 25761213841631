export function convertMinutes(min) {
    let result = '';

    if (min > 1439) {
        const days = Math.floor(min / 1440);
        const hours = Math.floor((min - (days * 1440)) / 60);
        const minutes = min - (days * 1440) - (hours * 60);

        const daysFormatted = `${days} days`;
        const hoursFormatted = `${hours} hours`;
        const minutesFormatted = `${minutes} minutes`;

        if (hours > 0) {
            result = minutes > 0
                ? `${daysFormatted} ${hoursFormatted} ${minutesFormatted}`
                : `${daysFormatted} ${hoursFormatted}`;
        } else {
            result = daysFormatted;
        }
    } else if (min > 59) {
        const hours = Math.floor(min / 60);
        const minutes = min - (hours * 60);
        const hoursFormatted = `${hours} hours`;
        const minutesFormatted = `${minutes} minutes`;

        result = minutes > 0 ? `${hoursFormatted} ${minutesFormatted}` : hoursFormatted;
    } else {
        result = `${min} minutes`;
    }
    return result;
}

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import TextInputOutlined from 'libraries/TextInput';
import { saveAssessmentSettings } from 'requests/ScriptRequests';
import { appCtx } from 'components/appStore';

import styles from './styles';

let timedIdForJobInput = null;

const InvitationButtonInput = observer(({ classes, slug, defaultValue, setSaving }) => {
    const [customButtonText, setCustomButtonText] = useState('');
    const [customButtonTextError, setCustomButtonTextError] = useState('');

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setCustomButtonText(defaultValue || '');
    }, [defaultValue]);

    const onChangeButtonText = (e) => {
        const { value } = e.target;
        clearTimeout(timedIdForJobInput);
        setCustomButtonText(value);
        setCustomButtonTextError('');
        timedIdForJobInput = setTimeout(() => {
            setSaving(true);
            saveAssessmentSettings(slug, { customInvitationEmailButtonText: value })
                .then(({ success }) => {
                    if (success) {
                        setCustomButtonTextError('');
                        setSaving(false);
                    }
                })
                .catch(({ response }) => {
                    if (response && response.data && response.data.errors) {
                        const { customInvitationEmailButtonText: errCustom } = response.data.errors;
                        setCustomButtonTextError(errCustom ? errCustom[0].message : '');
                    } else {
                        flashMessage('Something went wrong');
                    }
                });
        }, 500);
    };

    return (
        <div className="u-mrg--tx5">
            Change text on the email invitation button<br />
            <TextInputOutlined
                label="Email button text"
                hasCounter
                className={classes.field}
                variant="outlined"
                maxLength={50}
                helperText={customButtonTextError}
                isError={Boolean(customButtonTextError)}
                value={customButtonText}
                onChange={onChangeButtonText}
                placeholder="View Interview"
            />
        </div>
    );
});

export default withStyles(styles)(InvitationButtonInput);

export default {
    listItem: {
        background: 'rgba(0, 0, 0, 0.1);',
        color: 'white',
        fontWeight: 'bold',
        borderBottomLeftRadius: '6px',
        borderTopLeftRadius: '6px',
        height: '47px',
        width: '100%'
    },
    listItemDisactive: {
        height: '47px',
        color: 'white',
        fontWeight: 'bold',
        background: 'transparent',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        width: '100%'
    },
    listItemDisactiveBottomEmpty: {
        height: '47px',
        color: 'white',
        fontWeight: 'bold',
        background: 'transparent',
        borderBottom: 'none',
        width: '100%'
    },
    list: {
        overflowY: 'auto',
        marginTop: 50,
        width: 355,
        marginRight: 1,
        overflowX: 'hidden',
        minHeight: 260,
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    table: {
        width: '100%',
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 9,
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    row: {
        overflowY: 'hidden',
        verticalAlign: 'baseline',
        display: 'flex',
        alignItems: 'stretch',
        height: '100%',
        width: 'calc(100% - 300px)'
    },
    cell: {
        fontSize: '14px',
        fontWeight: 600,
        color: '#ffffff',
        border: 'none',
        minHeight: 200,
        width: '100%',
        height: '100%',
        whiteSpace: 'pre-line',
        borderRight: '1px solid #2f3545',
        display: 'block',
        padding: '0 15px',
        overflowY: 'auto',
        wordBreak: 'break-all',
        maxHeight: 250,
        lineHeight: 3,
        paddingTop: 0,
        '&:first-child': {
            width: 180
        },
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    head: {
        display: 'flex',
        height: 47,
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.9px',
        color: 'rgba(255, 255, 255, 0.37)'
    },
    cellH: {
        width: '100%',
        border: 'none',
        padding: '0 15px',
        '&:first-child': {
            width: 180
        }
    },
    wrapperStyle: {
        height: '100%',
        overflowY: 'hidden',
        overflowX: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        '& *': {
            fontFamily: 'Open Sans'
        }
    },
    errIcon: {
        marginRight: 10
    },
    wrapper: {
        padding: '0 20px',
        marginTop: 30,
        width: '100%'
    },
    label: {
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 'normal',
        letterSpacing: 0.9,
        color: '#ffffff',
        padding: '0 10px',
        textTransform: 'uppercase'
    }
};

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, withRouter } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';

import Layout from 'components/layout';
import { appCtx } from 'components/appStore';
import LoaderBounce from 'components/loaders/LoaderBounce';
import ProtectedWithAssessmentFlow from 'components/routes/protected_with_assessment_flow';
import ProtectedWithSpecificValue from 'components/routes/protected_with_specific_value';
import WarningBanner from 'components/assessments_pages/WarningBanner';
import { checkHasUserPermission } from 'helper/commonFunctions';

import EditAssessmentSettings from './edit_assessment';
import EditAssessmentHeader from './components/EditAssessmentHeader';
import CandidateInvite from './candidate_invite';
import AssessmentSettings from './assessment_settings';

import { assessmentSettingsCtx } from './store';
import CandidateResults from './results_assessment';
import AssessmentInsights from './assessment_insights';
import CandidatesLimitBlock from './components/CandidatesLimitBlock';
import CandidateFeedbackBanner from './components/CandidateFeedbackBanner';
import { statsCtx } from './statsStore';

const AssessmentWrapper = observer(({ history, match, location }) => {
    const [showFeedbackBanner, setShowFeedbackBanner] = useState(true);
    const { flashMessage, company } = useContext(appCtx);
    const {
        audition, loadEditScript, setInterviewSlug, cleanScript,
        isLoading, setAudition
    } = useContext(assessmentSettingsCtx);
    const { interviewSlug } = match.params;

    const {
        completionsLeft, limitOfCompletions,
        loadAssessmentStats, setSlug: setSlugStats,
        cleanStatsData
    } = useContext(statsCtx);

    useEffect(() => {
        cleanStatsData();
        setInterviewSlug(interviewSlug);
        loadEditScript(history);

        if (interviewSlug) {
            setSlugStats(interviewSlug);
            loadAssessmentStats();
        }

        return () => {
            cleanScript();
            cleanStatsData();
        };
    }, []);

    useEffect(() => {
        if (!interviewSlug) return;
        setInterviewSlug(interviewSlug);
        loadEditScript(history);
    }, [interviewSlug]);

    if (isLoading) {
        return (
            <Layout>
                <LoaderBounce className="u-pos--overlay" />
            </Layout>
        );
    }

    if (!audition) return null;

    const { publish, permissions, active, expired, archived, valid } = audition;
    const edit = checkHasUserPermission(company, permissions, 'edit');

    if (publish) {
        history.push('/marketplace');
        flashMessage('This script can\'t be edited.');
    }

    const goToEdit = () => {
        history.push({ pathname: `/script/edit/${interviewSlug}` });
    };

    const goToSettings = () => {
        history.push({ pathname: `/script/settings/${interviewSlug}/limit-and-deadline`, state: { from: location.pathname } });
    };

    const commonProps = { audition, company, match, setAudition, flashMessage, loadEditScript };

    const returnBanner = () => {
        if (audition.sendCandidateFeedback && !company.candidateFeedbackEnabled && showFeedbackBanner) {
            return (
                <CandidateFeedbackBanner
                    interviewSlug={interviewSlug}
                    history={history}
                    setShowFeedbackBanner={setShowFeedbackBanner}
                />
            );
        }
        if (!valid) {
            return (
                <WarningBanner
                    red
                    label={
                        <>
                            <b>The assessment is invalid.</b>&nbsp;
                            <ButtonBase onClick={goToEdit}>
                                Check questions in Create to continue
                            </ButtonBase>
                        </>
                    }
                />
            );
        }
        if (!active) return null;
        if (expired) {
            return (
                <WarningBanner
                    red
                    label={
                        <>
                            <b>The deadline for this assessment has passed.</b>
                            &nbsp;To invite more candidates&nbsp;
                            <ButtonBase onClick={goToSettings}>
                                change the due date in the Assessment Settings
                            </ButtonBase>
                        </>
                    }
                />
            );
        }
        if (active) {
            return (
                <CandidatesLimitBlock
                    interviewSlug={interviewSlug}
                    completionsLeft={completionsLeft}
                    limitOfCompletions={limitOfCompletions}
                />
            );
        }
        return null;
    };

    return (
        <Layout>
            {
                audition && <>
                    {returnBanner()}
                    <EditAssessmentHeader />
                    <Switch>
                        <ProtectedWithAssessmentFlow path="/script/edit/:scriptSlug" render={props => (<EditAssessmentSettings {...props} {...commonProps} />)} />
                        <ProtectedWithAssessmentFlow value={edit} path="/script/invite/:scriptSlug" render={props => (<CandidateInvite {...props} {...commonProps} />)} />
                        <ProtectedWithAssessmentFlow path="/script/select/:scriptSlug" render={props => (<CandidateResults {...props} {...commonProps} />)} />
                        <ProtectedWithAssessmentFlow path="/script/select/:scriptSlug/shortlist" render={props => (<CandidateResults {...props} {...commonProps} />)} />
                        <ProtectedWithAssessmentFlow path="/script/select/:scriptSlug/archived" render={props => (<CandidateResults {...props} {...commonProps} />)} />
                        <ProtectedWithSpecificValue value={edit && !archived} path="/script/settings/:scriptSlug" render={props => (<AssessmentSettings {...props} {...commonProps} />)} />
                        <ProtectedWithAssessmentFlow path="/script/insights/:scriptSlug" render={props => (<AssessmentInsights {...props} {...commonProps} />)} />
                    </Switch>
                </>
            }
        </Layout>
    );
});

export default withRouter(AssessmentWrapper);

export default theme => ({
    flatButton: {
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#3a3c43',
        textDecoration: 'underline',
        '&:hover': {
            background: 'transparent'
        }
    },
    browseFile: {
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#3a3c43',
        textDecoration: 'underline',
        minHeight: 'auto',
        padding: 0,
        lineHeight: '14px',
        '&:hover': {
            background: 'transparent'
        }
    },
    mainText: {
        color: theme.palette.primary.main,
        fontSize: 14,
        margin: 5
    },
    secondaryText: {
        fontSize: 12
    },
    button: {
        width: 75,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 12,
        fontWeight: 600,
        position: 'absolute',
        top: -33,
        right: 77,
        padding: '0 3px',
        zIndex: 3
    },
    fileBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #EBECEE',
        borderRadius: 4,
        background: '#C4C4C4',
        minHeight: 200,
        minWidth: 230
    },
    fileName: {
        padding: 10,
        color: theme.palette.grey[900],
        wordBreak: 'break-word',
        '&:hover, &:focus, &:active': {
            color: theme.palette.grey[900]
        }
    },
    dropZoneBox: {
        border: '2px solid #EBECEE',
        borderRadius: 4,
        background: '#F8F8FA'
    },
    root: {
        marginTop: 15
    }
});

import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
    addSpreadsheetTestCase,
    changeSpreadsheetTestCase,
    deleteSpreadsheetTestCase,
    getSpreadsheetNames,
    getSpreadsheetRange,
    getSpreadsheetTestCases
} from 'requests/QuestionsRequests';
import AddIcon from 'img/add.svg';
import styles from './styles';
import EditWarning from '../../../../../EditWarning';
import SpreadsheetTestCase from './SpreadsheetTestCase';

let timerId = null;
let timerQuestionId = null;

const AutoGraded = ({
    viewLink, question, fetchQuestion, isCMS, setSaving, flashMessage, classes, isOpen
}) => {
    const [testCases, setTestCases] = useState([]);
    const [sheetNames, setSheetNames] = useState([]);
    const [sheetRanges, setSheetRanges] = useState({});
    const { id, answerType } = question;

    useEffect(() => {
        if (!isOpen) {
            fetchSpreadsheetTestCases();
        }
    }, [answerType, id, isOpen]);

    useEffect(() => {
        if (!isOpen) {
            getSpreadsheets();
        }
    }, [answerType, viewLink, id, isOpen]);

    useEffect(() => {
        sheetNames.forEach((item) => {
            if (sheetRanges[item]) return;
            getSpreadsheetRange(question.id, item, isCMS)
                .then(({ data, success }) => {
                    if (data && success) {
                        sheetRanges[item] = data;
                        setSheetRanges({ ...sheetRanges, item: data });
                    }
                });
        });
    }, [sheetNames]);

    const getSpreadsheets = () => {
        if (!viewLink) return;
        return getSpreadsheetNames(id, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    setSheetRanges({});
                    setSheetNames(data);
                }
            });
    };

    const fetchSpreadsheetTestCases = () => {
        getSpreadsheetTestCases(id, isCMS)
            .then(({ success, data }) => {
                if (success) {
                    if (data && data.length) {
                        setTestCases(data);
                    } else {
                        addTestCase();
                    }
                }
            });
    };

    const addTestCase = () => {
        setSaving(true);
        addSpreadsheetTestCase(question.id, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    const newTestCases = testCases.slice();
                    newTestCases.push(data);
                    setTestCases(newTestCases);
                }
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const removeTestCase = (e, index) => {
        const newTestCases = testCases.slice();
        const { id: testCaseId } = newTestCases[index];
        setSaving(true);
        deleteSpreadsheetTestCase(testCaseId, isCMS)
            .then(() => {
                newTestCases.splice(index, 1);
                setTestCases(newTestCases);
                clearTimeout(timerQuestionId);
                timerQuestionId = setTimeout(fetchQuestion, 1500);
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const onChange = (e, index) => {
        const { value, name } = e.target;
        let newValue = value;
        const newTestCases = testCases.slice();
        const { id: testCaseId } = newTestCases[index];
        if (name === 'score') {
            newValue = onChangeScore(value).toString();
        }
        newTestCases[index][name] = newValue;
        setTestCases(newTestCases);
        clearTimeout(timerId);
        setSaving(true);
        timerId = setTimeout(() => {
            changeSpreadsheetTestCase(testCaseId, { [name]: newValue }, isCMS)
                .then(({ data, success }) => {
                    if (data && success) {
                        const newTestCases2 = testCases.slice();
                        newTestCases2[index].valid = data.valid;
                        setTestCases(newTestCases2);
                        timerQuestionId = setTimeout(fetchQuestion, 1500);
                    }
                    setSaving(false);
                })
                .catch((err) => {
                    if (err.response && err.response.status === 422) {
                        flashMessage("'Correct value' is too long. It should have 499 characters or less.", 'error');
                    }
                    setSaving(false);
                });
        }, 300);
    };

    const onChangeScore = (value) => {
        let newValue = value;
        if (newValue < 0) { // rewrite
            newValue = 0;
        } else if (newValue > 10) {
            newValue = 10;
        } else {
            newValue = parseInt(newValue, 10);
        }
        return newValue;
    };

    const noValidTestCases = !testCases.filter(testCase => testCase.valid).length;


    return (
        <>
            {
                Boolean(testCases && testCases.length) && (
                    <>
                        <div className={classes.header}>Correct Answers</div>
                        <EditWarning
                            className={classes.warning}
                            message="By adding correct answers you enable autograding of this question."
                        />
                        {
                            testCases.map((item, index) => (
                                <SpreadsheetTestCase
                                    key={index}
                                    row={index}
                                    isCMS={isCMS}
                                    sheetNames={sheetNames}
                                    sheetRange={sheetRanges[item.sheetName]}
                                    testCase={item}
                                    question={question}
                                    onChange={onChange}
                                    testCases={testCases}
                                    removeTestCase={removeTestCase}
                                />
                            ))
                        }
                        {
                            noValidTestCases && (
                                <Typography
                                    className="u-mrg--bx2"
                                    variant="h6"
                                    color="secondary"
                                >
                                    Please add a valid correct answer
                                </Typography>
                            )
                        }
                    </>
                )
            }
            <div className="u-mrg--bx2">
                <Button
                    onClick={addTestCase}
                    id="qa-add-mlc-ans"
                    color="primary"
                    className={classes.addBtn}
                >
                    <img src={AddIcon} alt="x" />&nbsp; Add answer
                </Button>
            </div>
        </>
    );
};

export default withStyles(styles)(AutoGraded);

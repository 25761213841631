import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import FolderIcon from 'components/icons/FoldersIcon';
import BoardItemMenu from 'components/board/board_item/board_item_menu';

import DeleteIcon from 'img/trash.svg';
import EditIcon from 'img/edit.svg';

import DeleteFolderDialog from '../DeleteDialog';
import RenameFolderDialog from '../RenameDialog';

import styles from '../styles';
import CardMenuItem from '../CardMenuItem';


const FolderCard = observer(({
    classes, id, auditionsCount, name, renameFolder,
    activeFolderId, setActiveFolder, query, deleteFolder
}) => {
    const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
    const [renameFolderDialogOpen, setRenameFolderDialogOpen] = useState(false);

    const active = id === activeFolderId && !query.length;

    const handleClick = () => {
        if (setActiveFolder) setActiveFolder(id);
    };

    return <>
        <div
            role="presentation"
            className={clsx(classes.card, active && classes.active)}
            onClick={handleClick}
        >
            <div className={classes.header}>
                <TruncateWithTooltip text={name === 'All' ? 'All Assessments' : name} width={100} />
                <div>
                    {Boolean(id) && (
                        <div className={classes.menu}>
                            <BoardItemMenu
                                iconMenuClassName={classes.menuIcon}
                                options={[
                                    {
                                        label: <CardMenuItem label="Edit Name" icon={EditIcon} />,
                                        onClick: () => { setRenameFolderDialogOpen(!renameFolderDialogOpen); }
                                    },
                                    {
                                        label: <CardMenuItem label="Delete Folder" icon={DeleteIcon} />,
                                        onClick: () => { setDeleteFolderDialogOpen(!deleteFolderDialogOpen); } }
                                ]}
                            />
                            <IconButton className={classes.organiseButton} disabled size="large">
                                <FolderIcon color={active && '#1247F6'} />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
            <div className={classes.footer}>
                {auditionsCount} <span>Assessment{auditionsCount !== 1 && 's'}</span>
            </div>
        </div>
        <DeleteFolderDialog
            open={deleteFolderDialogOpen}
            onClose={() => setDeleteFolderDialogOpen(false)}
            id={id}
            name={name}
            privateAssessments
            deleteFolder={deleteFolder}
        />
        <RenameFolderDialog
            name={name}
            open={renameFolderDialogOpen}
            onClose={() => setRenameFolderDialogOpen(false)}
            id={id}
            privateAssessments
            renameFolder={renameFolder}
        />
    </>;
});

export default withStyles(styles)(FolderCard);

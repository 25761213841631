import React, { useContext } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';

import { isCalculating, returnScoreLabel } from 'helper/assessmentFunctions';
import { returnCandidateLabel } from 'helper/commonFunctions';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import CalculatingLabel from 'components/scores/CalculatingLabel';
import { candidateResultCtx } from '../../common/CandidateListWrapper/candidatesStore';

import styles from './styles';

const ShortlistCardHeader = observer(({
    candidate, classes, className, propPublic,
    gradeableCard
}) => {
    const { name, description, scoreLabel, calculatingLabel, wrapper } = classes;
    const { isEmployerSortEnabled } = useContext(candidateResultCtx);

    if (!candidate) return null;

    const {
        fullName, rank, rankTotal, score, topPerformer,
        employerScore, scoreExists, candidateStatus, manualGradeMissing
    } = candidate;

    const hired = candidateStatus === 'Hired';

    const scoreObj = isEmployerSortEnabled ? employerScore : score;

    const renderedScoreLabel = returnScoreLabel(scoreObj, candidate, isEmployerSortEnabled);

    const renderScore = () => {
        if (isCalculating({ ...candidate, score: scoreObj }, (isEmployerSortEnabled && scoreExists) || !isEmployerSortEnabled)) {
            return (
                <>
                    <div className={scoreLabel}>{renderedScoreLabel}</div>
                    <CalculatingLabel className={calculatingLabel} />
                </>
            );
        }

        if (scoreObj !== null && scoreObj.score !== null) {
            return (
                <div className="u-dsp--f">
                    <span className={scoreLabel}>{renderedScoreLabel}</span>
                    <div>{scoreObj.score}%</div>
                </div>
            );
        }
        return (
            <div>
                <span className={scoreLabel}>Score</span> -
            </div>
        );
    };

    return (
        <div className={clsx(wrapper, className)}>
            <div className={name}><TruncateWithTooltip width={350} text={fullName} /></div>
            {
                !(propPublic && !scoreObj) && (
                    <div className={description}>
                        {
                            !gradeableCard && (
                                <div>
                                    <span className={scoreLabel}>Rank</span> {rank || '-'} / {rankTotal || '-'}
                                </div>
                            )
                        }
                        {!manualGradeMissing && renderScore()}
                        {returnCandidateLabel(hired, topPerformer)}
                    </div>
                )
            }
        </div>
    );
});

export default withStyles(styles)(withRouter(ShortlistCardHeader));

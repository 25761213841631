import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import SelectOutlined from 'libraries/Select';
import { capitalizeEachFirstLetter } from 'helper/commonFunctions';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import { changeQuestionGroup } from 'requests/QuestionsRequests';
import styles from './styles';


const SkillInput = ({ classes, location, isCMS, audition, updateQuestion, setSaving, match, history, question }) => {
    const [skillInputValue, setSkillInputValue] = useState('');
    const { questionGroup, id } = question;
    const { questionGroups, scoreType, name, uuid } = audition;

    useEffect(() => {
        if (!questionGroup) return;
        const { id: questionGroupId } = questionGroup;
        setSkillInputValue(questionGroupId);
    }, [id]);

    const selectSkill = (e) => {
        const { value } = e.target;
        setSkillInputValue(value);
        setSaving(true);
        changeQuestionGroup(id, value, isCMS)
            .then(({ data, success }) => {
                if (data && success) {
                    updateQuestion(data);
                    AssessmentSettingsEvents.GROUP_QUESTION_MOVED({
                        ttId: uuid,
                        ttName: name,
                        questionId: data.uuid,
                        oldGroupName: capitalizeEachFirstLetter(questionGroup.title),
                        newGroupName: capitalizeEachFirstLetter(data.questionGroup.title)
                    });
                }
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const goToSkillSettings = () => {
        const { scriptSlug } = match.params;
        history.push({ pathname: `/script/settings/${scriptSlug}/skill-settings`, state: { from: location.pathname } });
    };

    if (scoreType !== 3 || !(questionGroups && questionGroups.length)) return null;

    const helperText = (
        <>
            Used for score breakdowns per skill.
            Update and add new skills in&nbsp;
            <span
                role="presentation"
                onClick={goToSkillSettings}
                className="u-cursor--p u-txt--bold u-txt--underline"
            >
                skill groups
            </span>
        </>
    );

    return (
        <SelectOutlined
            label="Skill assessed"
            variant="outlined"
            menuPaperClassName={classes.selectPaper}
            selectMenuClassName={classes.selectMenu}
            className={classes.selectOutlined}
            value={skillInputValue}
            options={(
                questionGroups
                    .map(({ title, id: questionGroupId }) => ({ value: questionGroupId, label: capitalizeEachFirstLetter(title) }))
            )}
            onChange={selectSkill}
            helperText={helperText}
        />
    );
};

export default withRouter(withStyles(styles)(SkillInput));

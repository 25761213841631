export const pilotFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `1 Open ${featureLabel}`,
        '1 User Seat',
        '50 candidate limit'
    ],
    'Assessment creation': [
        'Instant grading and ranking',
        'Smart assessment builder',
        'Expansive assessment library',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Branded assessment platform',
        'Branded candidate emails',
        'Send invitation SMS',
        'Customizable candidate messaging',
        'Trackable assessment links'
    ],
    'Streamline and collaboration': [
        'Assessment notifications',
        'Share candidate cards'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles',
        'Sourcing channel analytics'
    ]
}
);

export const starterFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `2 Open ${featureLabel}s`,
        '3 Team Seats',
        '100 candidates per assessment per month'
    ],
    'Assessment creation': [
        'Instant grading and ranking\n',
        'Smart assessment builder\n',
        'Expansive assessment library\n',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Branded assessment platform\n',
        'Branded candidate emails\n',
        'Send invitation SMS\n',
        'Customizable candidate messaging\n',
        'Trackable assessment links\n',
        'Searchable candidate pools'
    ],
    'Streamline and collaboration': [
        'Assessment notifications\n',
        'Share candidate cards\n',
        'Team notes on candidate cards\n',
        'Allow your team to grade candidates\n',
        'Candidate Deadline extension'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles\n',
        'Sourcing channel analytics'
    ],
    Integrations: [
        'Zapier\n',
        'Greenhouse\n',
        'SmartRecruiters\n',
        'JobAdder'
    ]
});

export const plusFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `3 Open ${featureLabel}s`,
        '4 Team Seats',
        '100 candidates per assessment per month'
    ],
    'Assessment creation': [
        'Instant grading and ranking\n',
        'Smart assessment builder\n',
        'Expansive assessment library\n',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Branded assessment platform\n',
        'Branded candidate emails\n',
        'Send invitation SMS\n',
        'Customizable candidate messaging\n',
        'Trackable assessment links\n',
        'Searchable candidate pools'
    ],
    'Streamline and collaboration': [
        'Assessment notifications\n',
        'Share candidate cards\n',
        'Team notes on candidate cards\n',
        'Allow your team to grade candidates\n',
        'Candidate Deadline extension'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles\n',
        'Sourcing channel analytics'
    ],
    Integrations: [
        'Zapier\n',
        'Greenhouse\n',
        'SmartRecruiters\n',
        'JobAdder'
    ]
});

export const payAndGoFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `1 Open ${featureLabel}\n`,
        '10 Team Seats\n',
        '100 candidates per year'
    ],
    'Assessment creation': [
        'Instant grading and ranking\n',
        'Expansive assessment library\n',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Trackable assessment links\n',
        'Searchable candidate pools'
    ],
    'Anti Cheating features': [
        'Candidate geolocation tracking\n',
        'Plagiarism checker'
    ],
    'Streamline and collaboration': [
        'Assessment notifications\n',
        'Allow your team to grade candidates\n',
        'Candidate Deadline extension'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles\n',
        'Sourcing channel analytics'
    ]
});

export const candidatePacksFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `10 Open ${featureLabel}s\n`,
        '10 Team Seats\n'
    ],
    'Assessment creation': [
        'Instant grading and ranking\n',
        'Smart assessment builder\n',
        'Expansive assessment library\n',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Branded assessment platform\n',
        'Branded candidate emails\n',
        'Customizable candidate messaging\n',
        'Searchable candidate pools'
    ],
    'Anti Cheating features': [
        'Candidate geolocation tracking\n',
        'Plagiarism checker\n',
        'Question randomization'
    ],
    'Streamline and collaboration': [
        'Assessment notifications\n',
        'Share candidate cards\n',
        'Team notes on candidate cards\n',
        'Allow your team to grade candidates\n',
        'Guest grading\n',
        'Candidate Deadline extension'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles\n',
        'Sourcing channel analytics\n',
        'Advanced CSV Export\n',
        '12 months of data retention'
    ],
    Integrations: [
        'Zapier\n',
        'Greenhouse\n',
        'JobAdder\n',
        'Comeet\n',
        'Lever'
    ]
});

export const growthFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `10 Open ${featureLabel}s\n`,
        '10 Team Seats\n',
        '100 candidates per assessment per year\n'
    ],
    'Assessment creation': [
        'Instant grading and ranking\n',
        'Expansive assessment library\n',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Branded assessment platform\n',
        'Branded candidate emails\n',
        'Send invitation SMS\n',
        'Customizable candidate messaging\n',
        'Trackable assessment links\n',
        'Searchable candidate pools'
    ],
    'Anti Cheating features': [
        'Candidate geolocation tracking\n',
        'Plagiarism checker\n',
        'Question randomization'
    ],
    'Streamline and collaboration': [
        'Assessment notifications\n',
        'Share candidate cards\n',
        'Team notes on candidate cards\n',
        'Allow your team to grade candidates\n',
        'Group Messaging\n',
        'Private Assessments\n',
        'Auto Reject\n',
        'Guest grading\n',
        'Candidate Deadline extension'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles\n',
        'Sourcing channel analytics\n',
        'CSV Data Exports'
    ],
    Integrations: [
        'Zapier\n',
        'Greenhouse\n',
        'JobAdder\n',
        'Comeet\n',
        'Lever'
    ]
});

export const professionalFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `4 Open ${featureLabel}s\n`,
        '6+ Team Seats\n',
        '300 Candidates per assessment per month\n'
    ],
    'Assessment creation': [
        'Instant grading and ranking\n',
        'Smart assessment builder\n',
        'Expansive assessment library\n',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Branded assessment platform\n',
        'Branded candidate emails\n',
        'Send invitation SMS\n',
        'Customizable candidate messaging\n',
        'Trackable assessment links\n',
        'Searchable candidate pools'
    ],
    'Streamline and collaboration': [
        'Assessment notifications\n',
        'Share candidate cards\n',
        'Team notes on candidate cards\n',
        'Allow your team to grade candidates\n',
        'Group Messaging\n',
        'Private Assessments\n',
        'Auto Progress and Reject\n',
        'Guest grading\n',
        'Candidate Deadline extension'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles\n',
        'Sourcing channel analytics\n',
        'CSV Data Exports'
    ],
    Integrations: [
        'Zapier\n',
        'Greenhouse\n',
        'SmartRecruiters\n',
        'JobAdder'
    ]
});

export const enterpriseFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `Custom Open ${featureLabel}s\n`,
        'Custom Team Seats\n',
        'Custom Candidate Volumes\n'
    ],
    'Assessment creation': [
        'Instant grading and ranking\n',
        'Smart assessment builder\n',
        'Expansive assessment library\n',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Branded assessment platform\n',
        'Branded candidate emails\n',
        'Send invitation SMS\n',
        'Customizable candidate messaging\n',
        'Trackable assessment links\n',
        'Searchable candidate pools'
    ],
    'Support & Security': [
        'Enterprise grade dedicated support',
        'Enterprise Grade Security',
        'Regional Data Storage',
        'Dedicated Client Executive'
    ],
    'Anti Cheating features': [
        'Candidate geolocation tracking\n',
        'Plagiarism checker\n',
        'Question randomization'
    ],
    'Streamline and collaboration': [
        'Assessment notifications\n',
        'Share candidate cards\n',
        'Team notes on candidate cards\n',
        'Allow your team to grade candidates',
        'Group Messaging\n',
        'Private Assessments\n',
        'Auto Progress and Reject\n',
        'Guest grading\n',
        'Candidate Deadline extension'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles\n',
        'Sourcing channel analytics',
        'Advanced CSV Export'
    ],
    'Integrations Available': [
        'Zapier\n',
        'Greenhouse\n',
        'PageUp\n',
        'SmartRecruiters\n',
        'JobAdder\n',
        'Comeet\n',
        'Oracle\n',
        'Lever\n',
        'Access to Vervoe API'
    ]
});

export const premiumFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `2 Open ${featureLabel}s\n`,
        '5 Team Seats\n',
        '1000 Candidate Volumes'
    ],
    'Assessment creation': [
        'Instant grading and ranking\n',
        'Smart assessment builder\n',
        'Expansive assessment library\n',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Branded assessment platform\n',
        'Branded candidate emails\n',
        'Send invitation SMS\n',
        'Customizable candidate messaging\n',
        'Searchable candidate pools'
    ],
    'Streamline and collaboration': [
        'Assessment notifications\n',
        'Share candidate cards\n',
        'Team notes on candidate cards\n',
        'Allow your team to grade candidates',
        'Auto Progress and Reject\n',
        'Guest grading\n',
        'Candidate Deadline extension'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles\n',
        'Sourcing channel analytics\n',
        'Advanced CSV Export'
    ],
    'Integrations Available': [
        'Zapier\n',
        'Greenhouse\n',
        'SmartRecruiters\n',
        'JobAdder\n',
        'Comeet\n',
        'Lever'
    ]
});

export const premiumPlusFeatures = (featureLabel = 'Assessment') => ({
    [`${featureLabel} limits`]: [
        `5 Open ${featureLabel}s\n`,
        '5 Team Seats\n',
        '2000 Candidate Volumes'
    ],
    'Assessment creation': [
        'Instant grading and ranking\n',
        'Smart assessment builder\n',
        'Expansive assessment library\n',
        'Customizable assessments'
    ],
    'Candidate engagement': [
        'Branded assessment platform\n',
        'Branded candidate emails\n',
        'Send invitation SMS\n',
        'Customizable candidate messaging\n',
        'Searchable candidate pools'
    ],
    'Streamline and collaboration': [
        'Assessment notifications\n',
        'Share candidate cards\n',
        'Team notes on candidate cards\n',
        'Allow your team to grade candidates',
        'Auto Progress and Reject\n',
        'Guest grading\n',
        'Candidate Deadline extension'
    ],
    'Reporting and analytics': [
        'Candidate skill profiles\n',
        'Sourcing channel analytics\n',
        'Advanced CSV Export'
    ],
    'Integrations Available': [
        'Zapier\n',
        'Greenhouse\n',
        'SmartRecruiters\n',
        'JobAdder\n',
        'Comeet\n',
        'Lever'
    ]
});

import { tracking } from 'helper/eventSegment';

export default {
    CANDIDATES_EXPORTED: (data) => {
        tracking('CT-candidates-exported', data);
    },
    CANDIDATES_FILTERED: (filterType, context) => {
        tracking('CT-candidates-filtered', { filterType, context });
    }
};

import React from 'react';
import AllCandidatesExportDialog from './AllCandidatesExportDialog';
import AssessmentExportDialog from './AssessmentExportDialog';

const ExportCSVDialog = ({ isAssessment, ...other }) => {
    if (isAssessment) {
        return (
            <AssessmentExportDialog {...other} />
        );
    }
    return <AllCandidatesExportDialog {...other} />;
};

export default ExportCSVDialog;

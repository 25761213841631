import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        padding: 26,
        flexWrap: 'wrap',
        height: 'calc(100vh - 170px)',
        alignContent: 'space-between',
        overflowY: 'auto'
    },
    initialsCandidate: {
        borderRadius: '50%',
        width: 40,
        height: 40,
        textAlign: 'center',
        lineHeight: '36px',
        color: theme.palette.blue[500],
        borderWidth: 2,
        borderColor: theme.palette.blue[500],
        borderStyle: 'solid',
        fontWeight: 700,
        marginRight: 16,
        minWidth: 40
    },
    nameLabel: {
        fontSize: '14px',
        fontWeight: 700,
        marginRight: 10
    },
    dateLabel: {
        fontSize: '14px',
        fontWeight: 600,
        color: theme.palette.grey[600]
    },
    header: {
        display: 'flex',
        alignItems: 'center'
    },
    ticketTextBlock: {
        flex: 1,
        display: 'flex',
        marginBottom: 32
    },
    ticketText: {
        marginTop: 13,
        fontSize: 13,
        width: '100%',
        marginBottom: 16
    },
    ticketBody: {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.grey[300],
        borderBottomStyle: 'solid',
        paddingBottom: 40,
        marginRight: 57,
        width: '100%'
    },
    actionBlock: {
        height: 85,
        width: '100%',
        backgroundColor: theme.palette.grey[100],
        padding: '0 28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    responseButton: {
        color: theme.palette.blue[600],
        minWidth: 180
    },
    submittedFromBlock: {
        fontSize: 13,
        display: 'flex',
        paddingTop: 15,
        '& > img': {
            margin: '0 5px',
            width: 19,
            height: 19
        },
        '& > .source': {
            color: theme.palette.blue[600],
            fontWeight: 600
        },
        '& > .gap': {
            marginLeft: 5
        }
    },
    ticketTextBlockWrapper: {
        width: '100%'
    }
}));

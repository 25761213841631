export default theme => ({
    previewContentWrapper: {
        width: 750,
        margin: '0 auto',
        paddingBottom: 130,
        '@media only screen and (max-width: 767px)': {
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '20px'
        }
    },
    loaderWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: 40,
        height: 40
    },
    headerPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 29,
        paddingBottom: 29,
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 75,
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 8,
        background: 'white',
        '@media only screen and (max-width: 767px)': {
            paddingLeft: 20,
            paddingRight: 20
        }
    },
    logoWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    companyLogo: {
        fontSize: 25,
        fontWeight: 800,
        maxWidth: 200,
        maxHeight: 100
    },
    timeLimit: {
        marginTop: 5,
        marginLeft: 20,
        '@media only screen and (max-width: 767px)': {
            fontSize: 11
        }
    },
    link: {
        fontWeight: 'bold',
        color: theme.palette.blue[600]
    },
    notFoundContent: {
        maxWidth: 620
    },
    logo: {
        '& img': {
            cursor: 'pointer',
            height: 36,
            objectFit: 'contain'
        }
    },
    poweredImgContainer: {
        position: 'fixed',
        left: 40,
        bottom: 40,
        height: 28,
        '& svg': {
            height: '100%'
        }
    }
});

export default theme => ({
    wrapper: {
        padding: '0 7px 20px 10px'
    },
    header: {
        color: theme.palette.grey[900],
        fontWeight: 700,
        fontSize: 16,
        padding: '18px 19px'
    },
    questionList: {
        padding: '0 15px',
        maxHeight: 500,
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            maxHeight: 280,
            display: 'flex',
        }
    }
});

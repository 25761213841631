import React from 'react';
import { withTheme } from '@mui/styles';


const DoneOutlinedIcon = ({ theme, color = theme.palette.green[400], size = 16, colorCheck = '#fff', ...other }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9999 2.3999C6.7007 2.3999 2.3999 6.7007 2.3999 11.9999C2.3999 17.2991 6.7007 21.5999 11.9999 21.5999C17.2991 21.5999 21.5999 17.2991 21.5999 11.9999C21.5999 6.7007 17.2991 2.3999 11.9999 2.3999Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.48527 14.6324L16.224 7.47911C16.5568 7.12577 17.1175 7.10508 17.4763 7.4329C17.8351 7.76072 17.8561 8.31291 17.5232 8.66625L10.1239 16.5208C9.76929 16.8972 9.16291 16.8923 8.8147 16.51L6.46631 13.9322C6.13948 13.5734 6.16984 13.0217 6.53413 12.6998C6.89841 12.3779 7.45867 12.4078 7.7855 12.7666L9.48527 14.6324Z"
            fill={colorCheck}
        />
    </svg>

);

export default withTheme(DoneOutlinedIcon);

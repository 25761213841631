import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';

import PurchaseDialog from '../PlanInfoBlock/PurchaseDialog';
import { subscriptionDialogStoreCtx } from '../store';

const styles = theme => ({
    content: {
        padding: 0,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    }
});

const PurchaseWrapper = observer(({ history, match, location }) => {
    const {
        getCompanyPlan, cards, selectedPlan,
        findPlansBySlug, availablePlans,
        getAvailablePlans
    } = useContext(subscriptionDialogStoreCtx);

    useEffect(() => {
        const { planSlug } = match.params;
        if (!availablePlans.length) return;
        const plan = findPlansBySlug(planSlug);
        if (!plan) onClose();
    }, [availablePlans.length]);

    const parsed = qs.parse(location.search);
    const { coupon = '' } = parsed;

    const onClose = () => {
        getAvailablePlans();
        history.push('/subscriptions');
    };

    if (!selectedPlan) return null;

    const cardProps = {
        onClose,
        subscriptionPlan: selectedPlan,
        getCompanyPlan,
        cards
    };

    return (
        <PurchaseDialog
            open
            defaultCouponString={coupon}
            {...cardProps}
        />
    );
});

export default withRouter(withStyles(styles)(PurchaseWrapper));

import React, { useContext } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import CandidatesSearch from 'components/job_or_assessment_settings/CandidatesSearch';
import { candidateCtx } from '../../AllCandidates/candidateStore';
import TableFilterAllCandidates from '../AllCandidatesTableFilter';
import ExportButton from '../../../assessment/components/ExportButton';

import useStyles from './styles';

const SearchFilterBlock = observer(() => {
    const classes = useStyles();
    const {
        query, handleChangeFilterSelect,
        filterCategory, handleChangeFilter,
        totalCount, isNoCandidates
    } = useContext(candidateCtx);

    return (
        <div className={clsx(classes.infoWrapper)}>
            <CandidatesSearch
                className={classes.search}
                onChange={handleChangeFilter}
                value={query}
            />
            <div className="u-dsp--f u-ai--center">
                <ExportButton
                    disabled={isNoCandidates}
                    candidatesCount={totalCount}
                />
                <TableFilterAllCandidates
                    filterCategory={filterCategory}
                    handleChangeFilterSelect={handleChangeFilterSelect}
                />
            </div>
        </div>
    );
});

export default SearchFilterBlock;

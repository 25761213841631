import React from 'react';
import { observer } from 'mobx-react-lite';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCellHead from 'components/table_components/table_cell_head';
import TableBody from '@mui/material/TableBody';
import Table from 'components/table_components/table';
import TableCell from 'components/table_components/table_cell';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

const UnsuccessfulTable = observer(({ data, hasData, getAuditionStatus }) => (
    <Table className="u-mrg--bx0">
        <TableHead>
            <TableRow>
                <TableCellHead className="u-pdn--lx3" label="Assessment" hideSort />
                <TableCellHead label="Invited" hideSort align="center" />
                <TableCellHead label="Failed Screening" hideSort align="center" />
                <TableCellHead label="Expired" hideSort align="center" />
                <TableCellHead label="Rejected" hideSort align="center" extraPadding={false} />
            </TableRow>
        </TableHead>
        <TableBody>
            {
                hasData && data?.items.map((audition) => {
                    const { name, invited, failedScreening, expired, rejected, id } = audition;
                    return (
                        <TableRow key={id}>
                            <TableCell align="left" className="u-dsp--f">
                                <TruncateWithTooltip text={name} width={250} />
                                &nbsp;{getAuditionStatus(audition)}
                            </TableCell>
                            <TableCell align="center">{invited}</TableCell>
                            <TableCell align="center">{failedScreening}</TableCell>
                            <TableCell align="center">{expired}</TableCell>
                            <TableCell align="center">{rejected}</TableCell>
                        </TableRow>
                    );
                })
            }
        </TableBody>
    </Table>
));

export default UnsuccessfulTable;

import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';

import { foldersCtx } from '../../components/assessments_pages/AssessmentFolders/store';
import MyAssessments from './assessments';

const MyAssessmentsWrapper = observer(({ ...other }) => {
    const { setActiveFolder } = useContext(foldersCtx);

    useEffect(() => setActiveFolder(null), []);

    return (
        <Switch>
            <Route exact path="/my-assessments" render={props => (<MyAssessments {...props} {...other} />)} />
        </Switch>
    );
});

export default MyAssessmentsWrapper;

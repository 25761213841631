export default (theme => ({
    closeIcon: {
        padding: 0,
        height: 34,
        margin: '8px 0 0 5px'
    },
    textInput: {
        '&:not(:first-child)': {
            marginLeft: 8
        }
    },
    textInputLabel: {
        fontSize: 12
    },
    expandIcon: {
        color: theme.palette.primary.main
    },
    accordion: {
        boxShadow: 'none',
        '&$accordionExpanded': {
            '&:after': {
                content: '""',
                position: 'absolute',
                opacity: 1,
                bottom: -10,
                left: 0,
                right: 0,
                height: 1,
                transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundColor: 'rgba(0, 0, 0, 0.12)'
            },
            '&:before': {
                opacity: 1
            }
        },
        '&:last-of-type': {
            marginBottom: 30
        }
    },
    ticketMenu: {},
    summaryExpanded: {},
    summaryRoot: {
        position: 'relative',
        textTransform: 'capitalize',
        color: theme.palette.grey[900],
        fontWeight: 700,
        padding: '5px 50px',
        '&$summaryDisabled': {
            backgroundColor: '#fff',
            opacity: 1
        },
        '&$summaryExpanded': {
            minHeight: 'auto',
            height: 48,
            '& $ticketMenu': {
                display: 'block'
            }
        },
        '&:hover': {
            '& $ticketMenu': {
                display: 'block'
            }
        }
    },
    accordionExpanded: {
        margin: '0 !important'
    },
    summaryDisabled: {},
    pointsLabel: {
        marginRight: 5,
        color: theme.palette.grey[500]
    },
    input: {
        margin: '0 5px'
    },
    pointsInput: {
        maxWidth: 120
    }
}));

import { identify, pagevisit, tracking, group } from 'helper/eventSegment';

export default {
    VIEWED_PAGE: () => {
        pagevisit(window.location.pathname.split('/')[1] ? window.location.pathname : 'home');
    },
    USER_LOGIN: (data) => {
        tracking(
            'A-user-signedin',
            {
                page_path: window.location.hostname + window.location.pathname,
                ...data
            }
        );
    },
    CREATE_ACCOUNT: (data) => {
        tracking(
            'A-account-created',
            {
                page_path: window.location.hostname + window.location.pathname,
                ...data
            }
        );
    },
    IDENTIFY: (uuid, data, options = {}) => {
        identify(uuid, data, options);
        const { companyName, companySlug, company_segmentGroupId: segmentGroupId } = data;
        if (segmentGroupId) {
            group(segmentGroupId, { companyName, companySlug, segmentGroupId });
        }
    },
    SEEK_REGISTRATION: () => {
        tracking('SeekRegistrationCompleted');
    },
    EMPLOYER_REGISTRATION_VIEWED: () => {
        tracking('M-Registration-Viewed');
    }
};

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';

import stylesJS from './styles';

const BoardItem = ({
    disabled, classes,
    children, className, onClick
}) => (
    <div className={clsx(classes.wrapper, disabled && classes.wrapperDisabled, className)}>
        <div role="presentation" className="c-button-link u-wdt--100p" onClick={!disabled ? onClick : () => {}}>
            {children}
        </div>
    </div>
);

export default withStyles(stylesJS)(withRouter(BoardItem));

import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    button: {
        minHeight: 36,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold'
    },
    saveButton: {
        minHeight: 36,
        minWidth: 140,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 'bold',
        outline: 'none',
        color: '#fff',
        '&:hover, &:active, &:focus': {
            color: '#fff',
            outline: 'none'
        }
    },
    disabledInput: {
        width: '100%',
        marginTop: 15,
        marginBottom: 15
    },

    resendText: {
        margin: '22px 0',
        fontSize: 14
    },
    link: {
        color: theme.palette.blue[500],
        fontWeight: 700
    },
    tooltipBox: {
        maxWidth: 380
    },
    tooltipText: {
        fontSize: 13,
        '& > b': {
            color: theme.palette.secondary.main
        }
    },
    loader: {
        color: 'white'
    }
}));

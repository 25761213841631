import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

const stylesJS = theme => ({
    list: {
        whiteSpace: 'nowrap',
        textTransform: 'capitalize'
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        minHeight: 22,
        color: theme.palette.grey[900]
    },
    wrapperBigCard: {
        flexWrap: 'wrap'
    },
    skillItem: {
        display: 'inline-block',
        background: theme.palette.grey[300],
        borderRadius: '30px',
        fontWeight: 700,
        padding: '4px 12px',
        fontSize: 10,
        marginRight: 5,
        letterSpacing: '0.2px',
        color: theme.palette.grey[900]
    },
    skillItemBigCard: {
        marginBottom: 7
    }
});


const SkillsGroup = ({ classes, items = [], bigCard, scoreType, className, skillItemClassName = '' }) => {
    const sortedItems = [...items].sort((a, b) => a.title.length - b.title.length);
    const [numberOfDisplayedSkills, setNumberOfDisplayedSkills] = useState(sortedItems.length);
    const containerRef = useRef(null);


    useEffect(() => {
        const calculateMaxItems = () => {
            if (bigCard) {
                setNumberOfDisplayedSkills(sortedItems.length);
                return;
            }

            if (containerRef.current) {
                const containerWidth = containerRef.current.clientWidth;
                let totalWidth = 0;
                let count = 0;

                const items = Array.from(containerRef.current.children).filter(
                    child => child.classList.contains('skill-item')
                );

                // Iterate through each skill item to measure its width
                for (let i = 0; i < items.length; i++) {
                    const itemWidth = items[i].clientWidth + 5;
                    totalWidth += itemWidth;

                    // Check if the total width exceeds the container width
                    if (totalWidth <= containerWidth) {
                        count++;
                    } else {
                        break;
                    }
                }

                setNumberOfDisplayedSkills(count);
            }
        };

        calculateMaxItems();

        // Optional: Add a resize event listener
        window.addEventListener('resize', calculateMaxItems);
        return () => {
            window.removeEventListener('resize', calculateMaxItems);
        };
    }, []);

    return (
        <>
            {
                sortedItems.length && scoreType && scoreType === 3 ? (
                    <div ref={containerRef} className={clsx(classes.wrapper, bigCard && classes.wrapperBigCard, className)}>
                        {
                            sortedItems
                                .map(({ title }, index) => (
                                    <div
                                        key={`${title}_${index}`}
                                        className={clsx(classes.skillItem,
                                            bigCard && classes.skillItemBigCard,
                                            skillItemClassName,
                                            'skill-item')}
                                    >
                                        <TruncateWithTooltip text={title} width={containerRef?.current?.clientWidth - 20} />
                                    </div>
                                )).slice(0, numberOfDisplayedSkills)
                        }
                        {
                            !bigCard && (items.length - numberOfDisplayedSkills) > 0 && (
                                <div className="u-txt--10 u-txt--bold u-dsp--ib">
                                    +{items.length - numberOfDisplayedSkills}
                                </div>
                            )
                        }
                    </div>
                ) : <div className={clsx(classes.wrapper, className)} />
            }
        </>
    );
};

export default withStyles(stylesJS)(withRouter(SkillsGroup));

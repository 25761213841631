import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import styles from './styles';

const InvitingProgressDialog = ({ classes, open, error, percents, onCancel, canceled }) => (
    <Dialog
        open={open}
        maxWidth="sm"
        handleClose={onCancel}
        contentClassName={classes.dialogContent}
    >
        <div>
            <Typography variant="h3" className={classes.successHeader}>
                Inviting Candidates
            </Typography>
                Inviting candidates. Please keep this modal open.
        </div>
        <div className={classes.progressWrapper}>
            <LinearProgress
                variant="determinate"
                value={percents}
                className={classes.linearProgressRoot}
            />
            {
                !error && !canceled && (
                    <div className={classes.subHeader}>
                        {percents}% COMPLETE
                    </div>
                )
            }
            {
                !error && percents < 100 && onCancel && (
                    <div>
                        <Button
                            fullWidth
                            color="primary"
                            className="u-txt--bold u-mrg--tx2"
                            onClick={onCancel}
                        >
                            Cancel Invite
                        </Button>
                    </div>
                )
            }
        </div>

    </Dialog>
);
export default withStyles(styles)(InvitingProgressDialog);

import React from 'react';
import { withStyles } from '@mui/styles';
import LockIcon from 'img/lockGrey.svg';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        fontSize: 16,
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.2px',
        textAlign: 'center',
        color: theme.palette.primary.main,
        borderRadius: 8,
        padding: 20,
        border: `2px solid ${theme.palette.grey[200]}`,
        '&:hover': {
            backgroundColor: theme.palette.grey[50]
        },
        '& img': {
            marginRight: 15
        }
    }
});

const UpgradeBox = ({ onClick, classes }) => (
    <div role="presentation" onClick={onClick} className={`js-add-question ${classes.wrapper}`}>
        <img src={LockIcon} alt="+" />
        Upgrade to add more questions
    </div>
);

export default withStyles(styles)(UpgradeBox);

export default (() => ({
    tooltipDark: {
        backgroundColor: '#1f2032',
        fontSize: 11,
        opacity: 1,
        fontWeight: 600,
        padding: '8px 20px 10px'
    },
    tooltipWhite: {
        backgroundColor: 'white',
        fontSize: 11,
        opacity: 1,
        fontWeight: 600,
        padding: '8px 20px',
        color: '#3a3c43'
    },
    arrowPopperDark: {
        color: '#1f2032'
    },
    arrowPopperWhite: {
        color: 'white'
    },
    arrow: {
        position: 'absolute',
        fontSize: 11,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },
    boxShadow: {
        boxShadow: '0 0 25px 5px rgba(0, 0, 0, 0.06)'
    }
}));

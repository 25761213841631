import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';

import clsx from 'clsx';
import MenuItemAction from 'components/board/board_item/menu_item_action';
import RequestDoneIcon from 'img/request_done.svg';
import CloseRoundIcon from 'img/close_round.svg';
import Popover from '@mui/material/Popover';
import styles from '../../../../../styles';

export const STATUS_LABEL = {
    TODO: 'To Do',
    REJECT: 'Rejected',
    DONE: 'Done'
};

export const getStatusMenuOptions = (id, status, changeStatus) => {
    const STATUS = {
        todo: 'TODO',
        reject: 'REJECT',
        done: 'DONE'
    };
    switch (status) {
        case STATUS.todo:
            return [
                {
                    label: <MenuItemAction label="Request done" icon={RequestDoneIcon} />,
                    onClick: () => { changeStatus(id, STATUS.done, 'Request done'); },
                    value: STATUS.done
                },
                {
                    label: <MenuItemAction label="Reject" icon={CloseRoundIcon} />,
                    onClick: () => {
                        changeStatus(id, STATUS.reject, 'Request rejected');
                    },
                    value: STATUS.reject
                }
            ];
        case STATUS.done:
            return [
                {
                    label: <MenuItemAction label="Unmark request" icon={CloseRoundIcon} />,
                    onClick: () => {
                        changeStatus(id, STATUS.todo, 'Request unmarked');
                    },
                    value: STATUS.todo
                }
            ];

        case STATUS.reject:
            return [
                {
                    label: <MenuItemAction label="Un-Reject" icon={CloseRoundIcon} />,
                    onClick: () => {
                        changeStatus(id, STATUS.todo, 'Request un-rejected');
                    },
                    value: STATUS.todo
                }
            ];
        default:
            return [];
    }
};

const StatusLabel = ({ classes, text, dark, id, changeStatus }) => {
    const [actualStatus, setActualStatus] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const renderMenuItems = () => getStatusMenuOptions(id, actualStatus || text, changeStatus).map(({ value, label = value, onClick }, index) => (
        <div
            onClick={() => {
                onClick();
                setAnchorEl(null);
                setActualStatus(value);
            }}
            role="button"
            tabIndex={index}
            className={classes.popoverItem}
        >
            {label}
        </div>
    ));

    return (
        <>
            <div
                className={clsx(classes.status, classes[`status${actualStatus || text}${dark ? '_dark' : ''}`])}
                onClick={e => (dark ? setAnchorEl(e.currentTarget) : null)}
            >
                {STATUS_LABEL[actualStatus || text]}
            </div>
            <Popover
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={() => setAnchorEl(null)}
                classes={{ root: classes.popoverRoot, paper: classes.popoverPaper }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {renderMenuItems()}
            </Popover>
        </>
    );
};

export default withStyles(styles)(StatusLabel);

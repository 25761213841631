import React, { useContext, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import InnerHeader from 'components/layout/InnerHeader';
import Layout from 'components/layout';
import PageWrapper from 'components/layout/PageWrapper';
import styles from './styles';
import InnerContentWrapper from '../../assessment/components/InnerContentWrapper';
import ContentLibraryTabs from '../common/ContentLibraryTabs';
import SkillsHeader from './header';
import PopularSkillsTable from './table';
import { skillsCtx } from './store';

const PopularSkills = ({ classes }) => {
    const { loadSkills, setLoading, cleanStore } = useContext(skillsCtx);
    const header = 'Popular Skills';
    useEffect(() => {
        setLoading(true);
        loadSkills();
        return () => {
            cleanStore();
        };
    }, []);
    return (
        <Layout>
            <InnerHeader header={header} />
            <PageWrapper className="u-pdn--bx5">
                <InnerContentWrapper className={classes.contentWrapper} contentClassName="u-pdn--x0">
                    <ContentLibraryTabs />
                    <div className="u-mrg--tx5 u-pdn--bx5">
                        <SkillsHeader />
                        <PopularSkillsTable />
                    </div>
                </InnerContentWrapper>
            </PageWrapper>
        </Layout>
    );
};
export default withStyles(styles)(PopularSkills);

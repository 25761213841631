import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    letsGoButton: {
        marginTop: 30,
        backgroundColor: '#fff',
        color: theme.palette.grey[900],
        paddingLeft: 45,
        paddingRight: 45
    },
    backdrop: {
        backgroundColor: 'transparent'
    },
    contentWrapper: {
        maxWidth: 725
    },
    dialogPaper: {
        boxShadow: 'none',
        height: '80vh',
        width: '90vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 28,
        color: '#fff',
        backgroundColor: theme.palette.grey[900],
        opacity: 0.9,
        padding: 30,
        fontWeight: 600
    }
}));

export default useStyles;

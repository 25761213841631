import React from 'react';
import { withStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from './searchIcon';
import ClearCross from './ClearCross';

const styles = theme => ({
    inputRoot: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 8,
        marginBottom: 49,
        marginTop: 49,
        border: `2px solid ${theme.palette.grey[300]}`,
        backgroundColor: '#fff',
        fontSize: 14,
        paddingLeft: 12,
        height: 50,
        color: theme.palette.grey[700],
        fontWeight: 600
    },
    inputInput: {
        '&::placeholder': {
            opacity: 1,
            color: theme.palette.grey[500]
        }
    }
});

const SearchField = ({
    classes, onChange, placeholder,
    className = '', value, hideCross = false,
    ...other
}) => (
    <InputBase
        className={className}
        classes={{
            root: classes.inputRoot,
            input: classes.inputInput
        }}
        variant="outlined"
        onChange={onChange}
        onPaste={onChange}
        value={value}
        placeholder={placeholder}
        startAdornment={(
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        )}
        endAdornment={!hideCross && value && (
            <ClearCross onClear={(e) => {
                e.target.value = '';
                onChange(e);
            }}
            />
        )}
        {...other}
    />
);

export default withStyles(styles)(SearchField);

import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';

const styles = () => ({
    loader: {
        color: '#fff',
        '& svg': {
            margin: 0
        }
    }
});

const RemoveJobAdderDialog = ({
    open, onClose, callback, loading, classes
}) => (
    <Dialog
        open={open}
        maxWidth="sm"
        onClick={e => e.stopPropagation()}
        onClose={onClose}
        titleComponent="Remove Integration"
        handleClose={onClose}
        actionComponent={(
            <>
                <Button
                    color="primary"
                    className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={callback}
                    disabled={loading}
                    style={{ width: 120 }}
                    className="u-pdn--lx5 u-pdn--rx5"
                >
                    {
                        loading ? (
                            <CircularProgress
                                size={24}
                                thickness={3}
                                classes={{ root: classes.loader }}
                            />
                        ) : 'Remove'
                    }
                </Button>
            </>
        )}
    >
        Are you sure you want to remove JobAdder integration?
    </Dialog>
);

export default withStyles(styles)(RemoveJobAdderDialog);

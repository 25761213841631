import { makeStyles } from '@mui/styles';

export default makeStyles({
    timerWrapper: {
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0.2,
        color: '#3a3c43',
        paddingLeft: 15,
        paddingRight: 15,
        textTransform: 'uppercase'
    },
    timerWrapperLeft: {
        color: '#f02e2e'
    }
});

export default {
    iconClose: {
        color: 'grey',
        height: 45,
        width: 45
    },
    header: {
        color: 'rgba(0,0,0,0.63)',
        fontSize: '14px',
        lineHeight: '16px',
        padding: '16px 20px 11px'
    }
};

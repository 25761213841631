import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withTheme, withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import SkillsOrTagsMenuItem from 'components/assessments_pages/asessment_settings_components/SkillsOrTagsMenuItem';
import Popover from 'libraries/Popover';
import Tooltip from 'libraries/Tooltip';
import TagIcon from 'components/icons/TagIcon';
import TagIconActive from 'components/icons/TagIconActive';

import styles from '../styles';


const TagButton = observer(({
    classes, allTags, addTag, removeTag
}) => {
    const [loadingName, setLoadingName] = useState(false);

    const onTag = async (tag) => {
        setLoadingName(tag.title);
        const action = tag.isActive ? removeTag : addTag;
        try {
            await action(tag);
            setLoadingName(null);
        } catch (e) {
            setLoadingName(null);
        }
    };

    return (
        <Popover
            className={classes.popoverRoot}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            label={(
                <Tooltip
                    label="Add Tags"
                >
                    <div>
                        <IconButton className={classes.iconStar} size="large">
                            { allTags.some(tag => tag.isActive) ? <TagIconActive /> : <TagIcon /> }
                        </IconButton>
                    </div>
                </Tooltip>
            )}
        >
            <div className={classes.popoverHeader}>
                <Typography variant="h6">Add tag to assessment</Typography>
            </div>
            <div className={classes.popoverContent}>
                <List className={classes.list}>
                    {
                        allTags && allTags.length ? allTags.map((tag) => {
                            const { id, isActive } = tag;
                            return (
                                <SkillsOrTagsMenuItem
                                    key={id}
                                    group={tag}
                                    disabled={loadingName}
                                    isActive={isActive}
                                    loadingName={loadingName}
                                    onClick={() => onTag(tag)}
                                />
                            );
                        }) : (
                            <div className={classes.emptyTags}>
                                <TagIcon width="32" height="30" /> <br />
                                <Typography variant="h6">
                                    No tags created
                                </Typography>
                            </div>
                        )
                    }
                </List>
            </div>
            <div className={classes.footer}>
                Manage tags in <Link to="/assessment-tags">content library</Link>
            </div>
        </Popover>
    );
});

export default withStyles(styles)(withTheme(TagButton));

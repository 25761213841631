import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { assignHiringManagerOrDate } from 'requests/CandidatesRequests';
import { appCtx } from 'components/appStore';

import HiredEvents from 'events/HiredEvents';
import Tooltip from 'libraries/Tooltip';
import deleteIcon from '../deleteIcon.svg';

import CalendarIcon from './calendar';
import DatePicker from './DatePicker';

import useStyles from './styles';


const HiringDateButton = observer(({
    candidate, onSuccess, assessmentSlug,
    className = '', disabled, dataForEvents,
    showHireBanner, setShowHireBanner
}) => {
    const { hiredDate, columnUserUuid, hiringManager } = candidate;
    const [hovered, setHovered] = useState(false);
    const [date, setDate] = useState(hiredDate);
    const [pickerOpen, setPickerOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { button, activeButton } = useStyles();
    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setDate(hiredDate);
    }, [hiredDate]);

    const handleClick = (e) => {
        if (showHireBanner) setShowHireBanner(false);
        e.stopPropagation();
        if (date) {
            assignHiringDate(null);
        } else {
            setPickerOpen(true);
        }
    };

    const returnMessage = (newDate) => {
        if (!newDate) return 'Hire date was removed';
        if (date && newDate) return 'Hire date changed';
        return 'Hire date added';
    };

    const assignHiringDate = (newDate) => {
        setLoading(true);
        assignHiringManagerOrDate(assessmentSlug, newDate, hiringManager ? hiringManager.uuid : null, [columnUserUuid])
            .then(({ success, data }) => {
                if (success && data) {
                    flashMessage(returnMessage(newDate), 'done');
                    setDate(newDate);
                    if (onSuccess) {
                        onSuccess({ hiredDate: newDate });
                    }
                    if (date && newDate) {
                        dataForEvents.oldHireDate = date;
                        dataForEvents.newHireDate = newDate;
                        HiredEvents.CANDIDATE_HIRE_DATE_CHANGED(dataForEvents);
                    } else if (!newDate) {
                        dataForEvents.hireDate = date;
                        HiredEvents.CANDIDATE_HIRE_DATE_REMOVED(dataForEvents);
                    } else {
                        dataForEvents.hireDate = newDate;
                        HiredEvents.CANDIDATE_HIRE_DATE_ADDED(dataForEvents);
                    }
                }
            })
            .catch(({ response: { status } }) => {
                if (status === 403) flashMessage('You don’t have permission for this action. Please contact company admin.', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Tooltip
            label={disabled ? 'You don\'t have permission to edit these details' : ''}
        >
            <div>
                <DatePicker
                    showHireBanner={showHireBanner}
                    setShowHireBanner={setShowHireBanner}
                    className={className}
                    hiredDate={date}
                    setHiredDate={setDate}
                    disabled={disabled}
                    defaultHiredDate={hiredDate}
                    {...{ pickerOpen, setPickerOpen, assignHiringDate }}
                />
                <Button
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    onClick={handleClick}
                    disabled={loading || disabled}
                    className={clsx(button, hiredDate && activeButton)}
                >
                    {
                        (date && hovered)
                            ? <img src={deleteIcon} alt="+" />
                            : <CalendarIcon color={date ? '#00B898' : '#FC9355'} />
                    }
                </Button>
            </div>
        </Tooltip>
    );
});

export default HiringDateButton;

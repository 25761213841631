import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import StatsItem from './StatsItem';
import { hiredCandidateCtx } from '../../HiredCandidates/hiredCandidateStore';
import styles from './styles';


const HiredStats = observer(({ classes }) => {
    const { isLoading, statistics, statistics: { averageScore, count, countOfAuditions } } = useContext(hiredCandidateCtx);

    return (
        <div className={classes.wrapper}>
            <div className={classes.content}>
                {
                    isLoading || !Object.keys(statistics).length ? (
                        <div className={classes.loaderWrapper}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : (
                        <div className="u-dsp--f">
                            <StatsItem
                                label="Hired Candidates"
                                value={count}
                            />
                            <StatsItem
                                label="Assessments"
                                value={countOfAuditions}
                            />
                            <StatsItem
                                label="Average Score"
                                value={`${averageScore}%`}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
});

export default withStyles(styles)(HiredStats);

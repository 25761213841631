export default theme => ({
    tableCell: {
        fontSize: 14,
        whiteSpace: 'nowrap',
        color: theme.palette.grey[900],
        fontWeight: 600,
        '&:first-child': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)'
        },
        '&:last-child': {
            borderRight: '1px solid rgba(224, 224, 224, 1)'
        }
    }
});

import React, { useState } from 'react';

// material components
import { withStyles } from '@mui/styles';

import TextInputOutlined from 'libraries/TextInput';

import TextEditor from './TextEditor';
import styles from './styles';


const SimulatorArticle = ({ item, entityIndex, onChange }) => {
    const [article, setArticle] = useState(item);
    const { title, content } = article;

    const handleChangeText = (event) => {
        const { value: eValue } = event.target;
        setArticle({ ...article, title: eValue });
        onChange(entityIndex, 'title', eValue);
    };

    const handleChangeContent = (eValue) => {
        setArticle({ ...article, content: eValue });
        onChange(entityIndex, 'content', eValue.toString('html'));
    };

    return (
        <>
            <TextInputOutlined
                maxLength={255}
                hasCounter
                variant="outlined"
                isError={Boolean(!title)}
                name="title"
                label="Article title"
                multiline
                className="u-mrg--bx5"
                helperText={!title ? 'Please add the article title.' : ''}
                fullWidth
                value={title}
                onChange={handleChangeText}
            />
            <TextEditor
                value={content}
                name="content"
                maxCharacters={25000}
                onChange={handleChangeContent}
                placeholder="Article Content..."
            />
        </>
    );
};

export default withStyles(styles)(SimulatorArticle);

import React from 'react';
import { withStyles } from '@mui/styles';
import RichTextEditor from 'react-rte';
import clsx from 'clsx';

const MAX_CHAR = 20000;

const styles = theme => ({
    textEditor: {
        border: `2px solid ${theme.palette.grey[300]}`,
        '& a': {
            color: theme.palette.primary.main
        }
    },
    textEditorError: {
        borderColor: theme.palette.red.primary
    },
    counterLabel: {
        marginTop: 3,
        color: theme.palette.grey[500],
        width: '100%',
        fontSize: 10,
        textAlign: 'right',
        fontWeight: 600
    },
    errorLabel: {
        color: `${theme.palette.red.primary} !important`,
        fontSize: 10,
        fontWeight: 600
    },
    excludeImg: {
        '& .EditorToolbar__root___3_Aqz :nth-child(5)': {
            display: 'none'
        }
    }
});

class RichTextEditorCustom extends React.Component {
    getMessageLength = value => value.toString('html').replace(/<(?:.|\n)*?>/gm, '').length;

    render() {
        const { classes, value, placeholder = '', required, className, onChange, error, maxCharacters = MAX_CHAR, excludeImg, noCounter } = this.props;
        const charactersLeft = maxCharacters - this.getMessageLength(value);

        return (
            <div className={excludeImg && classes.excludeImg}>
                <RichTextEditor
                    className={clsx(classes.textEditor, error && classes.textEditorError, 'c-text-editor', className)}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
                <div className="u-dsp--distribute u-mrg--tx1">
                    {
                        error ? (
                            <span className={classes.errorLabel}>{error}</span>
                        ) : <span />
                    }
                    {!noCounter && (
                        <span className={clsx(classes.counterLabel, (required && charactersLeft === maxCharacters) && classes.errorLabel)}>
                            {charactersLeft} characters remaining
                        </span>
                    )}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(RichTextEditorCustom);

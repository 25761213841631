import React from 'react';

const PlusIcon = ({ color = 'white', ...other }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M17.0711 10H2.92893" stroke={color} strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 2.92893V17.0711" stroke={color} strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default PlusIcon;

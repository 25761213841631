import React from 'react';
import { withStyles } from '@mui/styles';
import SkillsIcon from './skillsIcon.svg';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    text: {
        margin: '15px auto',
        color: theme.palette.grey[900],
        fontWeight: 600,
        fontSize: 12
    },
    img: {
        margin: '35px auto 0'
    }
});

const NoSkillsContent = ({ classes }) => (
    <div className={classes.wrapper}>
        <img className={classes.img} src={SkillsIcon} alt="empty-content" />
        <div className={classes.text}>
            No skills created <br /> for this assessment
        </div>
    </div>
);

export default withStyles(styles)(NoSkillsContent);

export const wrapperStyles = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
};

export const wrapperBodyStyles = {
    flex: '1 0 auto',
    background: '#282b34',
    paddingTop: '30px',
    paddingBottom: '30px',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    position: 'relative'
};

export const wrapperBodyStylesPreView = {
    background: '#282b34',
    paddingTop: '30px',
    paddingBottom: '30px',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    position: 'relative'
};

export const wrapperHeader = {
    paddingTop: '22px',
    background: '#282b34',
    paddingBottom: '30px',
    flexShrink: '0',
    minHeigth: '100px',
    position: 'relative'
};
export const wrapperFooter = {
    paddingTop: '30px',
    background: '#282b34',
    paddingBottom: '30px',
    flexShrink: '0',
    minHeigth: '100px',
    position: 'relative'
};
export const titleHeader = {
    top: '0',
    position: 'absolute',
    left: '15px',
    zIndex: '1',
    color: '#9fa0a5',
    opacity: '.3',
    fontSize: '10px',
    fontWeight: '600'
};

export const titleFooterBody = {
    top: '15px',
    position: 'absolute',
    left: '15px',
    zIndex: '1',
    color: '#9fa0a5',
    opacity: '.3',
    fontSize: '10px',
    fontWeight: '600'
};

export const commentStyle = {
    color: 'rgba(255,255,255,.3)',
    marginBottom: -6,
    paddingLeft: '16px',
    fontFamily: 'monospace, sans-serif'
};

import React from 'react';
import RichTextEditor from 'react-rte';
import RichTextEditorCustom from 'components/inputs/RichTextEditorCustom';

class ProfileMessageModal extends React.Component {
    constructor(props) {
        super(props);
        const { value } = this.props;
        const messageDefault = RichTextEditor.createValueFromString(value || '', 'html');
        this.state = {
            message: messageDefault
        };
    }

    handleChangeMessage = (value) => {
        const { onChange } = this.props;
        this.setState({
            message: value
        });
        if (onChange) onChange(value);
    };

    getMessageLength = value => value.toString('html').replace(/<(?:.|\n)*?>/gm, '').length;

    render() {
        const { message } = this.state;
        const { placeholder = '', name } = this.props;

        return (
            <RichTextEditorCustom
                placeholder={placeholder}
                value={message}
                name={name}
                required
                onChange={this.handleChangeMessage}
            />
        );
    }
}

export default ProfileMessageModal;

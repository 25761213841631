import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import SidebarBox from '../../SidebarBox';
import { assessmentEditCtx } from '../../../store';
import { assessmentSettingsCtx } from '../../../../../store';

import styles from './styles';


const QuestionsList = observer(({ classes }) => {
    const { generalQuestions, selectedSkill } = useContext(assessmentEditCtx);
    const { audition } = useContext(assessmentSettingsCtx);
    const { scoreType } = audition;

    return (
        <div className={classes.wrapper}>
            <Typography variant="h5" className="u-mrg--bx0">
                Assessment
            </Typography>
            <div className={classes.scrollbars}>
                {
                    generalQuestions.map((item, index) => {
                        const { questionGroup } = item;
                        const skillName = questionGroup ? questionGroup.title : '';
                        if (scoreType === 3 && selectedSkill !== 'all' && skillName !== selectedSkill) {
                            return null;
                        }
                        return (
                            <SidebarBox
                                key={item.id}
                                question={item}
                                questionIndex={index}
                                audition={audition}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
});

export default withStyles(styles)(QuestionsList);

import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';

import { loadContentLibraryAssessmentCompletedQuestions, loadContentLibraryAssessmentPreview } from 'requests/CMSRequests';

import {
    loadPreviewInterview, loadCompletedQuestions,
    loadPublicCompletedQuestions, loadPublicPreviewInterview,
    loadCompletedScreeningQuestions
} from 'requests/ScriptRequests';
import BoardNoContent from 'components/board/board_no_content';
import EmptyIcon from 'img/emptyDetailsIcon.svg';
import { modifyScreeningQuestion } from 'helper/questionSettingsFunctions';

import MiscellaneousEvents from 'events/MiscellaneousEvents';
import PoweredBy from 'img/poweredBy';
import QuestionWrapperAssessmentPreview from '../common/QuestionWrapperAssessmentPreview';
import PreviewHeader from './components/PreviewHeader';
import QuestionHeader from './components/QuestionHeader';
import InterviewStepper from './components/Stepper';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import { appCtx } from '../../../appStore';

import styles from './styles';


const PreviewScript = observer(({
    interviewSlug, onClose,
    classes, open, isDialog = true,
    isPublic, uuid, isCMS, hideHeader, isLibrary, isClosedAssessment
}) => {
    const [loading, setLoading] = useState(true);
    const [interview, setInterview] = useState(null);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
    const { company, company: { companyColor } } = useContext(appCtx);

    const questions = interview?.completedQuestions;

    const brandColor = interview?.brand?.color || companyColor;

    useEffect(() => {
        if (!interviewSlug && !uuid) return;

        if (open || !isDialog) {
            setLoading(true);
            loadInterview({ interviewSlug, uuid })
                .then((data) => {
                    setInterview(data);
                    setLoading(false);
                })
                .catch((err) => {
                    if (err && err.response && (String(err.response.status)[0] === '4' || '5')) {
                        MiscellaneousEvents.PREVIEW_VIEW_FAILED();
                    }
                    setLoading(false);
                });
        }
    }, [open, isDialog, interviewSlug, uuid]);

    const getLoadInterviewFunction = async (params) => {
        if (isCMS) {
            return loadContentLibraryAssessmentPreview(params.uuid);
        }
        if (isPublic) {
            return loadPublicPreviewInterview(params.uuid);
        }
        return loadPreviewInterview(params.interviewSlug);
    };

    const getLoadCompletedFunctionsFunction = (params) => {
        if (isCMS) {
            return [loadContentLibraryAssessmentCompletedQuestions(params.uuid)];
        }
        if (isPublic) {
            return [loadPublicCompletedQuestions(params.uuid)];
        }
        return [loadCompletedQuestions(params.interviewSlug), loadCompletedScreeningQuestions(params.interviewSlug)];
    };

    const loadInterview = async (params) => {
        const { data: dataInterview } = await getLoadInterviewFunction(params);
        const loadFunctions = getLoadCompletedFunctionsFunction(params);
        const [{ data: completedQuestions }, { data: completedScreeningQuestions = [] } = {}] = await Promise.all(loadFunctions);
        dataInterview.completedQuestions = [
            ...completedScreeningQuestions.map(question => modifyScreeningQuestion(question)),
            ...completedQuestions
        ];
        return dataInterview;
    };

    const handleClosePreview = () => {
        if (onClose) {
            onClose(false);
        }
    };

    const returnContentWrapper = (content) => {
        if (isDialog) {
            return (
                <Dialog
                    scroll="body"
                    onClose={handleClosePreview}
                    open={open}
                    fullScreen
                >
                    {content}
                </Dialog>
            );
        }
        return <div className={classes.contentWrapper}>{content}</div>;
    };


    if ((!questions || !questions.length) && !loading) {
        return returnContentWrapper((
            <BoardNoContent
                header="Questions preview"
                text="Valid questions you've added will show up here."
                hideIconWrapper
                className="u-pdn--tx0"
                icon={<img src={EmptyIcon} alt="empty-content" />}
            />
        ));
    }

    return returnContentWrapper(
        <>
            {
                interview && !loading && (
                    <>
                        {
                            !hideHeader && (
                                <PreviewHeader
                                    company={company}
                                    interview={interview}
                                    handleClosePreview={handleClosePreview}
                                    brand={interview?.brand}
                                />
                            )
                        }
                        <div className={classes.previewContentWrapper}>
                            <QuestionWrapperAssessmentPreview
                                QuestionHeaderComp={QuestionHeader}
                                interview={interview}
                                questions={questions}
                                selectedQuestionIndex={selectedQuestionIndex}
                                question={interview.completedQuestions[selectedQuestionIndex]}
                                setSelectedQuestionIndex={setSelectedQuestionIndex}
                                brandColor={brandColor}
                                isPreviewPage
                                isLibrary={isLibrary}
                                isClosedAssessment={isClosedAssessment}
                            />
                        </div>
                        <InterviewStepper
                            questions={interview.completedQuestions}
                            assessmentTimed={interview.timerEnabled}
                            selectedQuestionIndex={selectedQuestionIndex}
                            setSelectedQuestionIndex={setSelectedQuestionIndex}
                            color={brandColor}
                            isAbsolute={!isDialog}
                        />
                    </>
                )
            }
            {
                loading && (
                    <>
                        {
                            !hideHeader && (
                                <PreviewHeader
                                    loading={loading}
                                    company={company}
                                    interview={interview}
                                    handleClosePreview={handleClosePreview}
                                />
                            )
                        }
                        <div className={classes.loaderWrapper}>
                            <CircularProgress />
                        </div>
                    </>
                )
            }
            {
                !loading && company && isDialog && (
                    <div className={classes.poweredImgContainer}>
                        <PoweredBy color={brandColor} />
                    </div>
                )
            }
        </>
    );
});

export default withRouter(withStyles(styles)(PreviewScript));

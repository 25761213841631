import React from 'react';

const CorrectAnswerIcon = ({ noExampleAnswers }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 10.6375L12.5385 13L21 4" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 3.65625H5.09375C4.56332 3.65625 4.05461 3.86696 3.67954 4.24204C3.30446 4.61711 3.09375 5.12582 3.09375 5.65625V21.6562L7.09375 17.6562H19.0938C19.6242 17.6562 20.1329 17.4455 20.508 17.0705C20.883 16.6954 21.0938 16.1867 21.0938 15.6562V11" stroke="#6B6B70" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        {noExampleAnswers && <>
            <circle cx="19.104" cy="4" r="6.5" fill="#F4F4F7" />
            <circle cx="19.104" cy="4" r="4.0625" fill="#F87E30" />
        </>}
    </svg>
);

export default CorrectAnswerIcon;

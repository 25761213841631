import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Switch from 'libraries/SwitchBrandWithLabel';
import TextInput from 'libraries/TextInput';
import { getIcimsParams, setIcimsParams, getIcimsAssessmentList } from 'requests/SubscriptionRequests';
import SettingsEvents from 'events/SettingsEvents';
import { clone } from 'helper/commonFunctions';
import IcimsAssessmentRow from './AssessmentRow';

const styles = () => ({
    heading: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '28px',
        marginBottom: 20
    },
    input: {
        maxWidth: 500,
        marginBottom: 10
    }
});


const IcimsContent = ({ classes, flashMessage }) => {
    const [activated, setActivated] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [basicAuthLogin, setBasicAuthLogin] = useState('');
    const [basicAuthPass, setBasicAuthPass] = useState('');
    const [icimsAssessments, setIcimsAssessments] = useState([]);

    useEffect(() => {
        getIcimsParams()
            .then(({ success, data }) => {
                if (data && success) {
                    setCustomerId(data.customerId);
                    setBasicAuthLogin(data.basicAuthLogin);
                    setBasicAuthPass(data.basicAuthPass);
                    setActivated(data.enabled);
                }
            });
        getIcimsAssessmentList()
            .then(({ success, data }) => {
                if (data && success) {
                    setIcimsAssessments(data);
                }
            });
    }, []);

    const changeAssessmentID = (assessmentSlug, id) => {
        const newIcimsAssessments = clone(icimsAssessments);
        const index = newIcimsAssessments.findIndex(({ slug }) => assessmentSlug === slug);
        newIcimsAssessments[index].icimsAssessmentNameId = id;
        setIcimsAssessments(newIcimsAssessments);
    };

    const handleChangeSwitch = () => {
        if ([customerId, basicAuthLogin, basicAuthPass].some(field => field === '')) {
            flashMessage('Please fill in Customer Id, Basic Auth Login and Basic Auth Pass', 'error');
        } else {
            SettingsEvents.ICIMS_ENABLED({ enabled: !activated });
            setIcimsParams({ enabled: !activated, customerId, basicAuthLogin, basicAuthPass })
                .then(({ success, data }) => {
                    if (success && data) {
                        setCustomerId(data.customerId);
                        setBasicAuthLogin(data.basicAuthLogin);
                        setBasicAuthPass(data.basicAuthPass);
                        setActivated(data.enabled);
                        flashMessage(`Integration has been turned ${data.enabled ? 'on' : 'off'}`, 'done');
                    }
                })
                .catch(() => { flashMessage('Something went wrong', 'error'); });
        }
    };

    return <>
        <h3 className={classes.heading}>Connect Vervoe to iCIMS</h3>
        <Switch
            label="Enable iCIMS Integration"
            checked={activated}
            value={activated}
            onChange={handleChangeSwitch}
        />
        <br />
        <TextInput
            label="Customer ID"
            fullWidth
            className={classes.input}
            type="text"
            variant="outlined"
            value={customerId}
            onChange={e => setCustomerId(e.target.value)}
            disabled={activated}
        />
        <p className="u-mrg--bx4">Please paste Customer ID in order to allow the integration.</p>
        <TextInput
            label="Basic Auth Login"
            fullWidth
            className={classes.input}
            type="text"
            variant="outlined"
            value={basicAuthLogin}
            onChange={e => setBasicAuthLogin(e.target.value)}
            disabled={activated}
            maxLength={1000}
        />
        <br />
        <TextInput
            label="Basic Auth Pass"
            fullWidth
            className={classes.input}
            type="text"
            variant="outlined"
            value={basicAuthPass}
            onChange={e => setBasicAuthPass(e.target.value)}
            disabled={activated}
            maxLength={1000}
        />
        {!!icimsAssessments.length && <>
            <p className="u-mrg--bx3 u-mrg--tx10">Assessment List</p>
            {icimsAssessments.map(icimsAssessment => <IcimsAssessmentRow {...{ flashMessage, changeAssessmentID }} {...icimsAssessment} />)}
            </>
        }
    </>;
};

export default withStyles(styles)(IcimsContent);

import React from 'react';
import { withStyles } from '@mui/styles';
import InfoIcon from 'img/icons/icon-info.svg';
import clsx from 'clsx';

import styles from './styles';

const EditWarning = ({ classes, message, className }) => (
    <span className={clsx(classes.root, className)}>
        <img src={InfoIcon} alt="info" />
        <span>
            { message }
        </span>
    </span>
);

export default withStyles(styles)(EditWarning);

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { withStyles } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';

import Pagination from 'components/board/board_pagination';
import Table from 'components/table_components/table';
import BoardNoContent from 'components/board/board_no_content';
import { ASSESSMENT_TAGS_PER_PAGE } from 'helper/constants';
import EmptyIcon from 'img/emptyContentWithPlus.svg';

import TagRow from './row';
import CreateAssessmentTagButton from '../header/CreateAssessmentTagButton';

import styles from './styles';


const AssessmentTagsTable = observer(({ classes, tags, setTags, loading, page, changePage, totalTagsCount, setTotalTagsCount }) => {
    const totalPagesCount = useMemo(
        () => Math.ceil(totalTagsCount / ASSESSMENT_TAGS_PER_PAGE),
        [tags, totalTagsCount]
    );

    if (loading) {
        return (
            <div className={classes.loaderWrapper}>
                <CircularProgress color="primary" />
            </div>
        );
    }

    if (!tags.length) {
        return (
            <BoardNoContent
                header="Create an assessment tag"
                hideIconWrapper
                icon={<img src={EmptyIcon} alt="empty-content" />}
                text={(
                    <div className="u-dsp--f u-ai--center">
                        You haven't added any assessment tags.&nbsp;
                        <CreateAssessmentTagButton isBoardNoContent {...{ tags, setTags, setTotalTagsCount, totalTagsCount }} />
                    </div>
                )}
            />
        );
    }

    return (
        <TableContainer>
            <Table className={classes.table}>
                <TableBody>
                    {tags.map((tag, index) => (
                        <TagRow
                            tag={tag}
                            tags={tags}
                            setTags={setTags}
                            key={index}
                            changePage={changePage}
                            totalTagsCount={totalTagsCount}
                            setTotalTagsCount={setTotalTagsCount}
                        />
                    ))}
                </TableBody>
            </Table>
            <Pagination
                currentPage={page}
                totalPages={totalPagesCount}
                handleChangePage={changePage}
            />
        </TableContainer>
    );
});

export default withStyles(styles)(AssessmentTagsTable);

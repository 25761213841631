export default theme => ({
    container: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: '30px 0'
    },
    titleClosed: {
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: '22px',
        color: theme.palette.grey[900],
        fontWeight: 700
    },
    iconClosed: {
        width: 24,
        height: 24
    },
    textClosed: {
        fontSize: 12,
        lineHeight: '28px',
        color: theme.palette.grey[900],
        fontWeight: 600,

        '& > button': {
            color: theme.palette.blue[400],
            background: 'transparent',
            border: 'none'
        }
    }
});

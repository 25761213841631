import React from 'react';
import withStyles from '@mui/styles/withStyles';
import VervoeLogo from 'components/layout/Header/components/VervoeLogo';

const styles = {
    authWrapper: {
        margin: '100px auto',
        width: 450
    },
    wrapper: {
        backgroundColor: '#ffffff',
        width: '100%',
        height: '100%',
        overflow: 'auto'
    }
};

const IntegrationAuthWrapper = ({ classes, children }) => (
    <div className={classes.wrapper}>
        <div className={classes.authWrapper}>
            <div className="u-dsp--f u-jc--center u-mrg--bx8"><VervoeLogo /></div>
            {children}
        </div>
    </div>
);

export default withStyles(styles)(IntegrationAuthWrapper);

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import CandidateTabEvents from 'events/CandidateTabEvents';
import TableFilter from 'components/table_components/table_filter';

import { customRequestsStoreCtx } from '../../store';

const RequestsTableFilter = observer(() => {
    const { allStats, handleFilter, filterCategory } = useContext(customRequestsStoreCtx);
    const { ALL, DONE, TODO, REJECT } = allStats;

    const options = [{
        value: 'all',
        label: 'Show all',
        count: ALL
    }, {
        value: 'TODO',
        label: 'To Do',
        count: TODO
    }, {
        value: 'DONE',
        label: 'Done',
        count: DONE
    }, {
        value: 'REJECT',
        label: 'Rejected',
        count: REJECT
    }];

    options.forEach((item) => { item.checked = filterCategory === item.value; });

    const handleChange = (value) => {
        const filterOption = options.find(item => item.value === value);
        handleFilter(value, filterOption?.label);
        CandidateTabEvents.CANDIDATES_FILTERED(value, 'All candidates page');
    };

    return (
        <TableFilter
            isFilterActive={filterCategory !== 'all'}
            isCheckbox={false}
            showCountNumber
            options={options}
            innerHeader="Candidates"
            handleChange={handleChange}
        />
    );
});

export default RequestsTableFilter;

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { withStyles } from '@mui/styles';
import SwitchBrandWithLabel from 'libraries/SwitchBrandWithLabel';
import Tooltip from 'libraries/TooltipWhite';
import TextInputOutlined from 'libraries/TextInput';

import {
    AUDIO, VIDEO,
    ANY_FILE, GOOGLE_DOC,
    PRESENTATION, SPREADSHEET,
    CODE_TEST, CODE_TEST_AUTOGRADED, CYBER_SIMULATOR
} from 'helper/constants';

import { checkPluralNew } from 'helper/commonFunctions';
import EditError from 'components/marketplace/EditError';
import EditWarning from 'components/marketplace/EditWarning';
import styles from './styles';

const TimerLimitSwitch = ({
    question, audition, changeQuestionSettings, classes
}) => {
    const [timerEnabled, setTimerEnabled] = useState(false);
    const [timeLimit, setTimeLimit] = useState('');
    const { id, timerEnabled: questionTimerEnabled, answerType, timeLimit: questionTimeLimit } = question;

    useEffect(() => {
        setTimeLimit(questionTimeLimit);
        setTimerEnabled(questionTimerEnabled);
    }, [id, answerType]);


    let minTimeLimit = 1;
    let maxTimeLimit = null;
    let isShowWarning = false;
    if (answerType === AUDIO || answerType === VIDEO
        || answerType === ANY_FILE || answerType === GOOGLE_DOC
        || answerType === PRESENTATION || answerType === SPREADSHEET
        || answerType === CYBER_SIMULATOR) {
        minTimeLimit = 3;
        isShowWarning = true;
    } else if (answerType === CODE_TEST || answerType === CODE_TEST_AUTOGRADED) {
        minTimeLimit = 5;
    }

    if (answerType === CYBER_SIMULATOR) {
        maxTimeLimit = 12 * 60;
    }

    const timerCyber = answerType === CYBER_SIMULATOR;
    const timerIfOff = !timerEnabled || !timeLimit;

    const showError = (timerCyber && timerIfOff) || (timerCyber && (timeLimit < minTimeLimit || (maxTimeLimit && timeLimit > maxTimeLimit)));

    if (answerType === CODE_TEST_AUTOGRADED) return null;

    const handleChangeTimeLimitEnabled = () => {
        const newValue = !timerEnabled;
        setTimerEnabled(newValue);
        changeQuestionSettings({ timerEnabled: newValue });
    };

    const changeSliderValue = (e) => {
        const { value } = e.target;
        let newValue = value;
        if (newValue < 0) {
            newValue = 0;
        } else if (newValue > 4320) {
            newValue = 4320;
        } else {
            newValue = parseInt(newValue, 10);
        }
        setTimeLimit(newValue);
        changeQuestionSettings({ timeLimit: newValue });
    };

    if (audition.timerEnabled) {
        return (
            <div>
                <SwitchBrandWithLabel
                    label="Set answer time limit"
                    disabled
                    data-tip
                    data-for="timer-tooltip"
                    className="u-mrg--bx0"
                />
                <Tooltip id="timer-tooltip">
                    <p className="u-mrg--bx0">
                        You&apos;ve set a time limit already for the entire Skills Assessment.
                        Disable it if you&apos;d like to have time limits for specific questions.
                    </p>
                </Tooltip>
            </div>
        );
    }

    return (
        <div>
            <SwitchBrandWithLabel
                label="Set answer time limit"
                checked={timerEnabled}
                className={clsx(classes.formLabelSwitch, showError && classes.formLabelSwitchError)}
                onChange={handleChangeTimeLimitEnabled}
            />
            <br />
            {
                timerEnabled && (
                    <TextInputOutlined
                        className={classes.timeInput}
                        variant="outlined"
                        value={parseInt(timeLimit, 10) > 4320 ? 4320 : parseInt(timeLimit, 10)}
                        label="Answer time limit (min)"
                        onChange={changeSliderValue}
                        disabled={audition.timerEnabled}
                        type="number"
                        min="0"
                        max="4320"
                        isError={timeLimit < minTimeLimit || showError}
                    />
                )
            }
            {
                timerEnabled && ((timeLimit < minTimeLimit) || (maxTimeLimit && (timeLimit > maxTimeLimit))) && (
                    <EditError
                        className="u-mrg--bx2"
                        message={(
                            <span>
                                The minimum time limit for this question type is { minTimeLimit } { checkPluralNew(minTimeLimit, 'minute') }.
                                { Boolean(maxTimeLimit) && <>The maximum time limit for this question type is { maxTimeLimit / 60 } { checkPluralNew(maxTimeLimit / 60, 'hour') }.</> }
                                <br />
                                {
                                    !timerCyber && (
                                        <a
                                            href="https://vervoe.zendesk.com/hc/en-us/articles/360029181252-Best-Practices"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            See our candidate experience best practices.
                                        </a>
                                    )
                                }
                            </span>
                        )}
                    />
                )
            }
            {
                isShowWarning && (timeLimit === 3 || timeLimit === 4) && (
                    <EditWarning
                        className="u-mrg--bx2"
                        message={(
                            <span>
                                Short timers will result in low completion rates and a poor candidate experience.
                                <br />We highly recommend giving candidates at least 5 minutes to answer a question. <br />
                                <a
                                    href="https://vervoe.zendesk.com/hc/en-us/articles/360029181252-Best-Practices"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    See our candidate experience best practices.
                                </a>
                            </span>
                        )}
                    />
                )
            }
        </div>
    );
};

export default withStyles(styles)(TimerLimitSwitch);

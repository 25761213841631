import React from 'react';

const Trophy = ({ isActive, rank, scorePercentage, className, ...other }) => {
    if (scorePercentage < 20) return null;

    let color = '';
    switch (rank) {
        case 1: color = '#EEBB76'; break;
        case 2: color = '#A9AAAE'; break;
        case 3: color = '#A68F8F'; break;
        default: return null;
    }

    return (
        <svg className={className} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M0 5.08621C0 5.7904 0.289731 6.46576 0.805456 6.9637C1.32118 7.46164 2.02065 7.74138 2.75 7.74138H3.6325C3.91643 8.34703 4.34851 8.877 4.89156 9.2857C5.43462 9.6944 6.07239 9.96959 6.75 10.0876V13.0517H3.75V14.5H11.25V13.0517H8.25V10.0876C8.92761 9.96959 9.56538 9.6944 10.1084 9.2857C10.6515 8.877 11.0836 8.34703 11.3675 7.74138H12.25C12.6111 7.74138 12.9687 7.6727 13.3024 7.53927C13.636 7.40583 13.9392 7.21025 14.1945 6.9637C14.4499 6.71714 14.6525 6.42444 14.7907 6.1023C14.9289 5.78016 15 5.43489 15 5.08621V1.7069H11.75V0.5H3.25V1.7069H0V5.08621ZM13.5 3.15517V5.08621C13.5 5.4063 13.3683 5.71327 13.1339 5.93961C12.8995 6.16595 12.5815 6.2931 12.25 6.2931H11.75V3.15517H13.5ZM10.25 6.05172C10.25 6.75592 9.96027 7.43127 9.44454 7.92921C8.92882 8.42716 8.22935 8.7069 7.5 8.7069C6.77065 8.7069 6.07118 8.42716 5.55546 7.92921C5.03973 7.43127 4.75 6.75592 4.75 6.05172V1.94828H10.25V6.05172ZM1.5 3.15517H3.25V6.2931H2.75C2.41848 6.2931 2.10054 6.16595 1.86612 5.93961C1.6317 5.71327 1.5 5.4063 1.5 5.08621V3.15517Z" fill={color} />
        </svg>
    );
};

export default Trophy;

import { createContext } from 'react';
import { action, observable } from 'mobx';
import { getCodeGenerationSnippetDraft } from 'requests/ScriptRequests';
import { appStore } from 'components/appStore';

class CodeGeneratorStore {
    @observable funcName = '';

    @observable funcReturnType = '';

    @observable funcParameters = [{ name: '', type: '' }];

    @action saveCodeGenerationDraft = (data) => {
        this.funcName = data.functionName;
        this.funcReturnType = data.returnType;
        this.funcParameters = data.functionParameters;
    };

    @action getCodeGenerationDraft = (questionId, isCMS) => getCodeGenerationSnippetDraft(questionId, isCMS)
        .then(({ success, data, data: { name, returnType, functionParameters } }) => {
            if (success) {
                this.funcName = name;
                this.funcReturnType = returnType;
                this.funcParameters = Object.values(functionParameters);
            }
            return Promise.resolve(data);
        })
        .catch(() => {
            appStore.flashMessage('Something went wrong', 'error');
            return Promise.reject();
        });
}

export const codeGeneratorStore = new CodeGeneratorStore();
export const codeGeneratorCtx = createContext(codeGeneratorStore);

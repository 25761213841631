import React, { useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';

import stylesJS from './styles';

const BoardItemMenu = observer(({
    options, classes, iconMenuClassName = '',
    disabled, IconComp = MoreHorizIcon,
    children, iconClassName = ''
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleOnClick = (e, onClick, disabledMenuItem) => {
        e.stopPropagation();
        if (onClick && !disabledMenuItem) {
            handleClose(e);
            onClick(e);
        }
    };

    if (!options || !options.length) return null;

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                size="large"
                disabled={disabled}
                className={`${classes.menuIconButton} ${iconMenuClassName}`}
                onClick={handleOpen}
            >
                <IconComp className={clsx(classes.menuIcon, iconClassName)} />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: 135
                    }
                }}
            >
                {
                    options && options.length
                        ? options.map(({ label, icon, onClick, disabled: disabledMenuItem, className: itemClassName = '', divider = false }, key) => (divider ? (
                            <MenuItem
                                key={key}
                                className={clsx(classes.divider)}
                            />
                        ) : (
                            <MenuItem
                                key={key}
                                disableRipple={disabledMenuItem}
                                className={clsx(classes.menuItem, disabledMenuItem && classes.menuItemDisabled, itemClassName)}
                                onClick={e => handleOnClick(e, onClick, disabledMenuItem)}
                            >
                                {icon && (<img src={icon} alt="" />)}
                                {label}
                            </MenuItem>
                        ))) : children
                }
            </Menu>
        </>
    );
});

export default withStyles(stylesJS)(withRouter(BoardItemMenu));

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import styles from 'pages/assessment/components/TagsList/styles';

import { foldersCtx } from '../../../../AssessmentFolders/store';
import { AssessmentTagsListCtx } from '../store';


const AssessmentTagsList = observer(({ classes }) => {
    const { assessmentsFilter, setAssessmentsFilter } = useContext(AssessmentTagsListCtx);
    const { activeFolderId } = useContext(foldersCtx);

    if (!assessmentsFilter) return null;

    return (
        <div className="u-mrg--lx3">
            <div className={classes.tag}>
                <TruncateWithTooltip capitalize text={assessmentsFilter} width={130} />
                <IconButton
                    className={classes.closeButton}
                    onClick={() => setAssessmentsFilter('', activeFolderId)}
                    size="large"
                >
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </div>
        </div>
    );
});

export default withStyles(styles)(AssessmentTagsList);

import React from 'react';
import { withStyles } from '@mui/styles';
import InnerTabs from 'components/layout/InnerTabs';
import BookmarkIcon from 'components/icons/BookmarkIcon';
import DocsIcon from 'components/icons/DocsIcon';

import styles from './styles';

const ContentLibraryTabs = ({ classes }) => (
    <InnerTabs
        className={classes.innerTabs}
        tabList={[{
            label: (<>
                <DocsIcon />Assessments
            </>),
            url: '/content-library'
        }, {
            label: (<>
                <DocsIcon />Assessment Tags
            </>),
            url: '/assessment-tags'
        }, {
            label: (<>
                <BookmarkIcon />Question Library
            </>),
            url: '/questions-library'
        }, {
            label: (<>
                <BookmarkIcon />Question Duplicates
            </>),
            url: '/question-duplicates'
        }, {
            label: (<>
                <BookmarkIcon />Popular Skills
                </>
            ),
            url: '/popular-skills'
        }]}
    />
);

export default withStyles(styles)(ContentLibraryTabs);

import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';

import styles from './styles';

const ArchiveResultsDialog = ({ classes, open, failed = [], onClose, actionLabel }) => (
    <Dialog
        open={open}
        maxWidth="sm"
        className={classes.dialog}
        handleClose={onClose}
        contentClassName={classes.dialogContent}
    >
        <div>
            <Typography variant="h3" className={classes.successHeader}>
                Candidates Not {actionLabel}d
            </Typography>
            The following candidates were not {actionLabel.toLowerCase()}d:
        </div>
        {
            failed.map((email, index) => (
                <Fragment key={email}>
                    {Boolean(index) && ', '}
                    <b key={index}>
                        { email }
                    </b>
                </Fragment>
            ))
        }
        <div className="u-mrg--tx3">
            <Button
                color="primary"
                onClick={onClose}
            >
                Go to Candidates
            </Button>
        </div>
    </Dialog>
);

export default withStyles(styles)(ArchiveResultsDialog);

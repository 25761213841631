import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import TooltipBlack from 'libraries/Tooltip';
import InfoSvg from './info.svg';

const styles = {
    iconWrapper: {
        padding: '0 8px'
    },
    tooltipClass: {
        paddingTop: 15
    }
};

const QuestionTooltipBlack = ({ id, label, classes, className = '', iconClassName = '', ...other }) => (
    <TooltipBlack
        label={label}
        tooltipClass={clsx(classes.tooltipClass, className)}
        {...other}
    >
        <img className={iconClassName} src={InfoSvg} alt="info" />
    </TooltipBlack>
);

export default withStyles(styles)(QuestionTooltipBlack);

import React from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const styles = theme => ({
    pagination: {
        borderRadius: 4,
        display: 'flex',
        fontWeight: 700,
        color: theme.palette.grey[900],
        border: `2px solid ${theme.palette.grey[300]}`
    },
    paginationIndex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px 24px',
        borderLeft: `2px solid ${theme.palette.grey[300]}`,
        borderRight: `2px solid ${theme.palette.grey[300]}`
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.grey[50]
        }
    },
    icon: {
        color: theme.palette.grey[600]
    }
});

const Paginator = observer(({
    classes, onNext, onPrev,
    selectedIndex, totalNumber
}) => (
    <div className={classes.pagination}>
        <div role="presentation" className={classes.button} onClick={onPrev}>
            <KeyboardArrowLeft className={classes.icon} />
        </div>
        <div className={classes.paginationIndex}>
            {selectedIndex + 1}/{totalNumber}
        </div>
        <div role="presentation" className={classes.button} onClick={onNext}>
            <KeyboardArrowRight className={classes.icon} />
        </div>
    </div>
));

export default withStyles(styles)(Paginator);

import React from 'react';
import { withTheme } from '@mui/styles';

const SearchIcon = ({ theme, fill = theme.palette.grey[400] }) => (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.71512 13.1719C4.75881 17.6923 10.1853 19.8508 14.8356 17.993C15.7082 17.6444 16.4915 17.1791 17.1747 16.6234L23.1138 22.2831C23.4212 22.5761 23.9196 22.5761 24.227 22.2832C24.5344 21.9903 24.5344 21.5155 24.2271 21.2226L18.2764 15.5518C20.3766 13.1088 20.9894 9.61643 19.5553 6.44424C17.5116 1.92382 12.085 -0.234651 7.43476 1.62315C2.78448 3.48095 0.671426 8.65151 2.71512 13.1719ZM16.8638 14.7924C18.7629 12.759 19.3544 9.7454 18.121 7.01723C16.4254 3.26682 11.9232 1.47601 8.06508 3.01736C4.20693 4.5587 2.45381 8.84852 4.14938 12.5989C5.84495 16.3493 10.3471 18.1402 14.2053 16.5988C15.0229 16.2722 15.746 15.8221 16.3618 15.2798C16.3985 15.1678 16.4634 15.0623 16.5566 14.9736C16.646 14.8884 16.7515 14.8281 16.8638 14.7924Z"
            fill={fill}
        />
    </svg>
);

export default withTheme(SearchIcon);

import React, { useState, useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import NumberInput from 'components/inputs/NumberInput';
import Switch from '@mui/material/Switch';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import Tooltip from 'libraries/Tooltip';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { ButtonBase } from '@mui/material';
import { appCtx } from 'components/appStore';

const styles = theme => ({
    count: {
        marginLeft: 10,
        color: theme.palette.grey[600],
        fontWeight: 700,
        width: 25,
        textAlign: 'right'
    },
    header: {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
        maxWidth: 'calc(100% - 190px)',
        overflow: 'hidden'
    },
    title: {
        marginTop: 9
    },
    upgradeButton: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        marginTop: -3
    }
});

let timerId = null;

const GroupRandOption = observer(({
    classes, isCMS, group: {
        id, uuid, title, completeQuestions,
        companyQuestions, limitNumberOfQuestions, limitSize
    }, editSkillGroup, changeSkillGroup, groupIndex
}) => {
    const [checked, setChecked] = useState(limitNumberOfQuestions);
    const [limitValue, setLimitValue] = useState(limitSize);
    const { company } = useContext(appCtx);
    const questionList = isCMS ? companyQuestions : completeQuestions;
    const hasQuestionRandomization = checkHasCompanyFeature(company, 'QUESTION_RANDOMIZATION');
    if (!questionList) return null;
    const questionsCount = questionList.length;

    const handleChangeSwitch = async () => {
        const newValue = !checked;
        const newLimitSize = newValue ? (Number(limitValue) || questionsCount) : Number(limitValue);
        setChecked(newValue);
        setLimitValue(newLimitSize);

        const changedGroup = await editSkillGroup(isCMS ? uuid : id, {
            limitNumberOfQuestions: newValue,
            randomiseQuestions: newValue,
            limitSize: newLimitSize
        });

        changeSkillGroup(changedGroup, groupIndex);
    };

    const handleChangeNumber = (e) => {
        const { value } = e.target;
        let newValue = parseInt(value, 10);
        if (Number.isNaN(newValue) || newValue <= 0) {
            newValue = 1;
        }
        clearTimeout(timerId);
        setLimitValue(newValue);
        timerId = setTimeout(async () => {
            const changedGroup = await editSkillGroup(isCMS ? uuid : id, { limitSize: newValue });
            changeSkillGroup(changedGroup, groupIndex);
        }, 300);
    };

    return (
        <div className="u-dsp--distribute u-txt--semibold">
            <Typography variant="h6" className={classes.header}>
                <TruncateWithTooltip capitalize text={title} className={classes.title} width={144} />
            </Typography>
            <div className="u-dsp--f u-ai--center u-jc--end">
                <NumberInput
                    className="u-mrg--rx2"
                    disabled={!checked}
                    value={Math.min(Number(limitValue), questionsCount)}
                    max={questionsCount}
                    min={1}
                    onChange={handleChangeNumber}
                />
                of
                <div className={classes.count}>{questionsCount}</div>
                {
                    hasQuestionRandomization || (!hasQuestionRandomization && checked) ? (
                        <Switch
                            margin="none"
                            className="u-mrg--tx1"
                            checked={checked}
                            onChange={handleChangeSwitch}
                            disabled={!questionsCount}
                        />
                    ) : (
                        <Tooltip
                            label={<><ButtonBase type="text" className={classes.upgradeButton} onClick={() => window.open('/subscriptions', '_blank')}>Upgrade</ButtonBase> to unlock</>}
                            tooltipClass={classes.tooltip}
                        >
                            <div>
                                <Switch
                                    margin="none"
                                    className="u-mrg--tx1"
                                    checked={checked}
                                    onChange={handleChangeSwitch}
                                    disabled={!checked || !questionsCount}
                                />
                            </div>
                        </Tooltip>
                    )
                }
            </div>
        </div>
    );
});

export default withStyles(styles)(GroupRandOption);

import { get, patch, post, del } from '../helper/API';

export function requestExportCsvPerCompany(options, page = 1, offset = 100) {
    const {
        exclude: non_exported,
        candidateLinkType: candidate_link_type
    } = options;
    return get('employer/candidates/export', {
        non_exported,
        candidate_link_type,
        page,
        offset
    });
}


export function requestExportCsvHired(page = 1, offset = 100) {
    return get('employer/candidates/hired/export', {
        page,
        offset
    });
}

export function getCandidateInfoOLD(userId, isShared) { // remove later
    const publicPrefix = isShared ? 'public/' : '';
    return get(`${publicPrefix}candidates/${userId}`);
}

export function getCandidateInfo(uuid, isAgency) { // remove later
    const publicPrefix = isAgency ? 'guest/' : 'public/';
    return get(`${publicPrefix}candidates/${uuid}`);
}

export function getCandidateProfile(userUUid, isShared) {
    const publicPrefix = isShared ? 'public/' : 'employer/';
    return get(`${publicPrefix}candidates/${userUUid}/profile`);
}
export function getPublicCompany() {
    return get('public/company');
}

export function loadCompany() {
    return get('employer/company');
}

export function loadLoggedUser() {
    return get('user/logged');
}

export function inviteTeamMember(form) {
    return post('employer/invite', {
        form
    });
}

export function goBackToAdmin() {
    return post('back-to-admin');
}

export function changeCompanySlug(slug) {
    return patch('employer/company/slug', {
        slug
    });
}

export function changeAuditionSlug(oldSlug, newSlug) {
    return patch(`employer/auditions/${oldSlug}/slug`, {
        slug: newSlug
    });
}

export function getCompanyAccount(data) {
    return post('employer/company-account', data);
}

export function updateEmployerLogo(data) {
    return post('employer/logo', data);
}

export function updateCompanyLogo(data) {
    return post('employer/company/logo', data);
}

export function deleteCompanyLogoAndColor() {
    return del('employer/company/logo');
}

export function getAssessmentsForTeamInvite() {
    return get('employer/for-team-invite');
}

export function getTeamMemberAssessments(teamUuid) {
    return get(`employer/teams/${teamUuid}/auditions  `);
}

export function updateTeamRoles(teamUuid, data) {
    return post(`/employer/teams/${teamUuid}/update-roles-on-auditions`, data);
}

export function skipOnBoarding() {
    return del('employer/onboarding');
}

export function createOnBoardingAudition(uuid) {
    return post(`employer/public-auditions/${uuid}/create-onboarding-audition`);
}

export function getTeamClosedAssessmentsCounter(uuid) {
    return get(`employer/teams/${uuid}/auditions/closed`);
}

export default theme => ({
    loader: {
        color: '#fff',
        '& svg': {
            margin: 0
        }
    },
    previewButton: {
        padding: '8px 25px',
        fontWeight: 600,
        marginRight: 21,
        backgroundColor: '#fff'
    },
    statsBlock: {
        minWidth: 445,
        maxWidth: 460,
        marginRight: 30,
        padding: '15px 0 0',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            maxWidth: 'none'
        }
    },
    statsHeader: {
        color: theme.palette.grey[500],
        fontSize: 10,
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    statsValue: {
        marginTop: 3,
        fontSize: 14,
        lineHeight: 1.2,
        color: theme.palette.grey[900],
        fontWeight: 700
    },
    description: {
        fontSize: 14,
        lineHeight: '25px',
        color: theme.palette.grey[900],
        marginBottom: 30
    },
    name: {
        maxWidth: 475,
        whiteSpace: 'pre-wrap'
    },
    dialogContent: {
        display: 'flex',
        paddingTop: 0,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    activateButton: {
        minWidth: 150,
        padding: '9px 20px',
        '&:disabled': {
            backgroundColor: theme.palette.primary.main
        }
    },
    sectionHeaderWrapper: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: '14px 0',
        fontSize: 14,
        letterSpacing: '0.2px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sectionHeaderTitle: {
        fontWeight: 600,
        color: theme.palette.grey[600]
    },
    sectionHeaderCount: {
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    info: {
        backgroundColor: theme.palette.grey[100],
        borderRadius: 16,
        marginTop: 33
    },
    infoInner: {
        padding: '20px 24px'
    },
    infoHeader: {
        fontSize: 14,
        color: theme.palette.grey[600],
        fontWeight: 700,
        marginBottom: 16
    },
    infoFooter: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: '38px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& > button': {
                width: '100%',
                marginBottom: 10,
                marginRight: 0
            }
        }
    },
    assessmentName: {
        padding: '20px 18px',
        border: `8px solid ${theme.palette.grey[100]}`,
        borderRadius: 16,
        fontWeight: 700,
        fontSize: 26,
        color: theme.palette.grey[900]
    },
    skillsGroupWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 35
    },
    questionLabel: {
        marginRight: 7,
        marginBottom: 10,
        height: 28,
        padding: '4px 9px',
        borderRadius: 4,
        fontSize: 12,
        lineHeight: 1,
        display: 'flex',
        color: theme.palette.grey[900],
        fontWeight: 700,
        alignItems: 'center',
        backgroundColor: theme.palette.grey[300]
    },
    svgIconStyle: {
        maxWidth: 17,
        maxHeight: 17,
        marginRight: 6
    },
    tooltipLink: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginTop: -2
    }
});

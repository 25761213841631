import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';

const stylesJS = theme => ({
    wrapper: {
        cursor: 'pointer',
        padding: '3px 8px',
        fontWeight: 700,
        borderRadius: '60px',
        fontSize: 10,
        textTransform: 'capitalize',
        backgroundColor: theme.palette.red[200],
        color: theme.palette.red[600],
        marginRight: 8
    }
});

const DuplicateStatus = ({ classes, history, questionId, className = '' }) => {
    const onClick = (e) => {
        e.stopPropagation();
        history.push(`/question-duplicates?questionId=${questionId}`);
    };

    return (
        <>
            <div
                onClick={onClick}
                role="presentation"
                className={clsx(
                    classes.wrapper,
                    className
                )}
            >
                Duplicate
            </div>
        </>
    );
};

export default withRouter(withStyles(stylesJS)(DuplicateStatus));

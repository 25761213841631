export default theme => ({
    rejectedLabel: {
        color: theme.palette.red.primary,
        backgroundColor: theme.palette.red[50],
        fontSize: 12,
        fontWeight: 700,
        width: 115,
        textAlign: 'center',
        marginLeft: 7,
        borderRadius: 4,
        padding: '4px 15px'
    },
    trophyNumber: {
        display: 'inline-block',
        position: 'relative',
        fontSize: 11,
        color: theme.palette.grey[800],
        padding: 5,
        marginRight: 5,
        textDecoration: 'underline',
        fontWeight: 700
    },
    tooltip: {
        padding: 0
    },
    demoScoreLabel: {
        color: '#fff',
        backgroundColor: theme.palette.yellow[900],
        padding: '4px 10px',
        borderRadius: 4,
        textAlign: 'center',
        fontSize: 12,
        marginRight: 5,
        fontWeight: 700,
        whiteSpace: 'nowrap'
    }
});

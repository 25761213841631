import { useEffect, useRef, useState } from 'react';

const useTimer = (initialValue = 0, interval = 1000) => {
    const [time, setTime] = useState(initialValue);
    const timerId = useRef(null);

    const startTimer = () => {
        if (!timerId.current) {
            timerId.current = setInterval(() => {
                setTime(prevTime => prevTime + 1);
            }, interval);
        }
    };

    const stopTimer = () => {
        if (timerId.current) {
            clearInterval(timerId.current);
            timerId.current = null;
        }
    };

    const resetTimer = () => {
        stopTimer();
        setTime(initialValue);
        startTimer();
    };

    useEffect(() => () => {
        stopTimer();
    }, []);

    return { time, startTimer, resetTimer, stopTimer };
};

export default useTimer;
